import React, { Component } from 'react';
import { FieldSelect, FieldInput, FieldDate } from '../../../../contracts/Elements';
import { imgStoragePath } from '../../../../../constants';
import  TextareaField  from '../../../Elements/TextareaField';
import {  TextInputField,GetInputField } from '../../../Elements';
 class LlpDetails extends Component {
  constructor(props) {
    super(props);
   
  }
  
  render (){
   
    const {content,error} = this.props;

    return(
    <tr>
      <td>
        {this.props.index+1}
      </td>
      <td>
      <div >
        <TextInputField
              value={content.description}
              type={'edit'}
              keyParam="description"
              updateForm={(value)=>this.props.updateForm({type: 'description', value:value, sectionType: this.props.section, index:this.props.index})}
        />
        </div>
      </td>
      <td>
        <div >
        <TextInputField
              value={content.part_number}
              type={'edit'}
              keyParam="part_number"
              updateForm={(value)=>this.props.updateForm({type: 'part_number', value:value, sectionType: this.props.section, index:this.props.index})}
        />
        </div>
      </td>
      <td>
      <div >
       <TextInputField
          value={content.serial_number}
          type={'edit'}
          keyParam="serial_number"
          updateForm={(value)=>this.props.updateForm({type: 'serial_number', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
       </div>                   
      </td>
      <td>
      <div>
       <TextInputField
          value={content.cycles_remaining}
          type={'edit'}
          keyParam="cycles_remaining"
          error={error.cycles_remaining}
          updateForm={(value)=>this.props.updateForm({type: 'cycles_remaining', value:value==""?null:value, sectionType: this.props.section, index:this.props.index})}
       />  
      </div>
      </td>
      <td>
      <div >
       <TextareaField
          value={content.acumen_remarks}
          type={'edit'}
          keyParam="acumen_remarks"
          updateForm={(value)=>this.props.updateForm({type: 'acumen_remarks', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
        </div>      
      </td>
      <td width='150px'>
				{
            GetInputField(
              'dropdown',
              null,
              { type: 'edit',
							  value:content.status ? content.status: '',
								error: '',
								labelKey:'label',
                valueKey: 'value',
								options: this.props.techConstants.filter(item => item.type=="engine_gap_report_status"),
								updateForm:(value)=>this.props.updateForm({type: 'status', value:value, sectionType:this.props.section, index:this.props.index})
              }
            )
          }
				</td>	
      <td>
      <div >
       <TextareaField
          value={content.comments}
          type={'edit'}
          keyParam="comments"
          updateForm={(value)=>this.props.updateForm({type: 'comments', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
        </div>      
      </td>
      <td>
      <div >
       <TextareaField
          value={content.ref_document}
          type={'edit'}
          keyParam="ref_document"
          updateForm={(value)=>this.props.updateForm({type: 'ref_document', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
        </div>      
      </td>
      <td>
      <div >
       <TextareaField
          value={content.operator_comments}
          type={'edit'}
          keyParam="operator_comments"
          updateForm={(value)=>this.props.updateForm({type: 'operator_comments', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
        </div>      
      </td>
      <td>
      <div style={{textAlign:'center',cursor:'pointer'}}>
      <img width="15" onClick={()=>''} src={imgStoragePath+"delete_red.png"} onClick={this.props.toggleComDelModal} />
      </div>
      </td>
    </tr>
    )
}
}
export default LlpDetails;
