const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// login validation
export const checkLoginForm = (userInfo) => {
  return checkLoginFormErrors(checkLoginFormData(userInfo));
}

function checkLoginFormData(userInfo) {
  return  {
      emailVal: (EMAIL_RE.test(String(userInfo.email).toLowerCase())),
      passwordVal: (userInfo.password != ''),
    }
}

function checkLoginFormErrors(val) {
  return {
      emailMsg: val.emailVal ? '' : 'Please enter a valid Email Address',
      passwordMsg: val.passwordVal ? '' : 'Please enter the Password'
    }
}


// reset password validation

export const checkResetPasswordForm = (userInfo) => {
  return checkResetFormErrors(checkResetFormData(userInfo));
}

function checkResetFormData(userInfo){
  return {
    passwordVal: (userInfo.password != ''),
    confirmPasswordVal: (userInfo.confirmPassword == '' ? 'blank_password_field' : userInfo.password === userInfo.confirmPassword ? true : 'doesnot_match_password' ),
  }
}

function checkResetFormErrors(val){
    return {
      passwordMsg: val.passwordVal ? '' : 'Please enter the New Password',
      confirmPasswordMsg: val.confirmPasswordVal == 'blank_password_field' ? 'Please enter the Confirm Password' : val.confirmPasswordVal == 'doesnot_match_password' ? 'Password doesn\'t match' : '',
    }
}
