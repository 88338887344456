import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea,  FieldInput } from '../../../contracts/Elements';
import { checkString, checkIntNumber } from '../../../../formValidator';

export default class AddEditAvionics extends Component {
  constructor(props){
    super(props);
    this.state = {
      avionics: {},
      error:{}
    }
  }
  componentDidMount(){
    this.setState({
      avionics: this.props.avionicObj
    });
  }
  updateForm = (type, value) => {
     if(value != undefined){
       if(value.length == 0 || undefined){
         this.setState(prevState => ({
           ...prevState,
           avionics: {
             ...prevState.avionics,
             [type]: value
           },
           error:{
             ...prevState.error,
             [type]:''
           }
         }))
       }
       if (value.length > 0) {
         if(type == "ata_code" ||'manufacturer' || "part_number"  ) {
           this.setState(prevState => ({
             ...prevState,
             avionics: {
               ...prevState.avionics,
               [type]: value.charAt(0).toUpperCase() + value.slice(1)
             },
             error:{
               ...prevState.error,
               [type]:''
             }
           }))
         }else {
           this.setState(prevState => ({
             ...prevState,
             avionics: {
               ...prevState.avionics,
               [type]: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
             },
             error:{
               ...prevState.error,
               [type]:''
             }
           }))
         }
        }
     }
   }


  addEditAvionic = (e) => {
    e.preventDefault();
    let data = {
      ata_code: this.state.avionics.ata_code,
      item: this.state.avionics.item.charAt(0).toUpperCase() + this.state.avionics.item.slice(1).toLowerCase(),
      item_description:this.state.avionics.item_description.charAt(0).toUpperCase() +this.state.avionics.item_description.slice(1).toLowerCase(),
      manufacturer:this.state.avionics.manufacturer,
      model: this.state.avionics.model.charAt(0).toUpperCase() + this.state.avionics.model.slice(1).toLowerCase(),
      part_number:this.state.avionics.part_number,
      quantity: this.state.avionics.quantity,
      slug: this.state.avionics.slug
    }

    data = {
      ...data,
      aircraft: this.props.asset_id
    }
    this.setState((prevState)=>({
      ...prevState,
      avionics:data

    }))



    const validateNewInput = {
      item: checkString({value: data.item, required: true, minLength: '', maxLength: '', message: 'Please enter Avionics item'}),
      quantity: checkIntNumber({value: data.quantity, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Quantity'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditAvionic(this.state.avionics, this.props.type, 'avionicList','avionicCrud');

    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { avionics, error } = this.state;
    const { type } = this.props;
    if(!Object.keys(avionics).length){
      return <h4 className="text-center">Loading ...</h4>
    }
    return (
      <form onSubmit={(e) => this.addEditAvionic(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">ATA Code</FieldLabel>
                <FieldInput
                  value={avionics.ata_code}
                  type={type}
                  keyParam="ata_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Item
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={avionics.item }
                  type={type}
                  keyParam="item"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.item}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Description</FieldLabel>
                <FieldTextarea
                  value={avionics.item_description}
                  type={type}
                  keyParam="item_description"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Manufacturer</FieldLabel>
                <FieldInput
                  value={avionics.manufacturer}
                  type={type}
                  keyParam="manufacturer"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Model</FieldLabel>
                <FieldInput
                  value={ avionics.model}
                  type={type}
                  keyParam="model"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          { /* <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={avionics.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
    </FieldCol>*/}
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={avionics.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Quantity
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}

                </FieldLabel>
                <FieldInput
                  value={avionics.quantity}
                  type={type}
                  keyParam="quantity"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.quantity}</h6>
              </FieldGroup>
            </FieldCol>

          </Row>
        </div>
        { this.props.type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
