import React, { Component } from 'react';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import LogAppliedFilter from '../containers/LogAppliedFilter'
import LogFilterField from '../containers/LogFilterField'
import '../../../../User/assets/styles/assetListing.scss';
import { imgStoragePath } from '../../../../constants';
export default class LogFilter extends Component {
    constructor(props) {
        super(props);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.updateSlectedItem = this.updateSlectedItem.bind(this);
        this.state = {
            filterDropDown: false,
            filterSelected : false,
            selectedFilterTitle: ''
        };
        this.displayFilter = {
            'display':'block'
        }
        this.hideFilter = {
            'display':'none'
        }
        this.dispalySelectedCard = {
            'display':'block'
        }
        this.hideSelectedCard = {
            'display':'none'
        }
    }

    toggleFilter(){
        this.setState(prevState => ({
          ...prevState,
          filterDropDown : !prevState.filterDropDown
        }));
      }

      updateSlectedItem(value){
          if(value){
            this.setState(prevState => ({
                ...prevState,
                selectedFilterTitle: value,
                filterSelected: !prevState.filterSelected,
                filterDropDown : !prevState.filterDropDown
            }));
          }else{
            this.setState(prevState => ({
                ...prevState,
                filterSelected: !prevState.filterSelected,
            }));
          }
      }


    render() {
        const { filterData, engineTypeList, aircraftTypeList } = this.props;
        const filterMenu = {
            'Aircraft Type': {
                'inputType': 'dropdown',
                'keyParam': 'aircraft_type',
                'placeholder': 'Select Aircraft Type',
                'options': aircraftTypeList,
                'labelKey': 'name',
                'valueKey': 'id',
              },
              'Engine Type': {
                'inputType': 'dropdown',
                'keyParam': 'engine_type',
                'placeholder': 'Select Engine Type',
                'options': engineTypeList,
                'labelKey': 'name',
                'valueKey': 'id',
              },
              'Generated / Updated on' : {
                'inputType': 'date',
                'keyParam': 'date'
              },
              'Log Id' : {
                'inputType': 'text',
                'keyParam': 'log_id'
              },
        }
        return (
            <div className="asset-list-filter-sort">
                <Row className="add-filter-type">
                    <div className="col-md-12">
                        <div className="add-filter">
                            <p onClick={this.toggleFilter} style={{fontSize:"12px"}}>
                              <span>
                                {this.state.filterDropDown ? <img className="close-block" src={imgStoragePath+"black_close.png"} alt="close"/> : <img className="plus-icon" src={imgStoragePath+"black_plus.png"} alt="img" width="10"/> }
                                {this.state.filterDropDown ?  ' Close Filter': ' Add a Filter'}
                              </span>
                            </p>
                            {Object.keys(filterData).length ? <LogAppliedFilter />:null}

                            <ul className="list-unstyled filter-list"  style={this.state.filterDropDown ? this.displayFilter : this.hideFilter }>
                             {Object.keys(filterMenu).map((menu, index) =>
                                 <li className="para-ui th-highlight" onClick = {() => {this.updateSlectedItem(menu)}} key={index}>{menu}</li>
                                )}
                             </ul>
                            <div className="match-block" style={this.state.filterSelected?this.dispalySelectedCard:this.hideSelectedCard}>
                                <div className="match-header">
                                    <h4>
                                         {this.state.selectedFilterTitle}
                                        <span onClick={() => this.updateSlectedItem('')}><img className="close-block" src={imgStoragePath+"white_close.png"}  alt="img"/></span>
                                    </h4>
                                </div>
                                {this.state.selectedFilterTitle?<LogFilterField updateSlectedItem={this.updateSlectedItem} filterData={filterData} fieldObj={filterMenu[this.state.selectedFilterTitle]} />:null}
                            </div>
                        </div>
                    </div>
                </Row>

            </div>

        );
    }

}
