import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import Onboarding from '../phase2/auth/containers/Onboarding'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import { setGlobalCookie, getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin, checkEnvironment, getLessorsList } from './index'
import TrialExpired from '../shared/containers/TrialExpired'
window.addEventListener('visibilitychange', checkLogin)

function checkLogin () {
    if(document.visibilityState || document.hasFocus()) {
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      let location = browserHistory.getCurrentLocation().pathname
      let lessorAccess = ''
      let redirectURI = browserHistory.getCurrentLocation().pathname
      if(redirectURI && redirectURI != '' && redirectURI !== '/login' && redirectURI !== '/select-lessor' && redirectURI !== '/signup'){
        setGlobalCookie('redirectURI', browserHistory.getCurrentLocation().pathname, 3)
      }
      if(getGlobalCookie('lessorAccess')){
        lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
      }
      setTimeout(() =>{
        if(!lessorAccess){
          localStorage.clear();
          sessionStorage.removeItem('initial_pop');
          if(!location.includes('login') && !location.includes('signup') && !location.includes('password/create') && !location.includes('create-successfully') && !location.includes('reset-successfully') && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('user/activate') && !location.includes('otp-verification') && !location.includes('forgot') && !location.includes('view-shared')){
             browserHistory.push('/login');
             window.location.reload()
           }
        }else{
          if(checkEnvironment() === lessorAccess.environment){
            if(lessorAccess.id){
              if(!getLocalStorageInfo() || (getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor)){
                 authorizeAutoLogin(lessorAccess)
               }else if(!location.includes('select-lessor') && !location.includes('otp')){
                 if(userDetail && userDetail.defaultLessor && parseInt(lessorAccess.id) !== userDetail.defaultLessor.id){
                   authorizeAutoLogin(lessorAccess)
                 }
               }
            }else {
              if((userDetail && userDetail.defaultLessor == undefined) || (!userDetail && lessorAccess)){
                // getLessorsList()
              }
            }
          }
        }
      })
    }
}
function Authenticate(ComposedComponent, extraInfo) {
 class authenticateApp extends Component {
   constructor(props) {
     super(props);
     this.state = {
       videoDialog: false
     }
   }

   componentDidMount(){
     if(getGlobalCookie('MRCalcPromoVideo') === undefined || getGlobalCookie('MRCalcPromoVideo') === null && !window.location.href.includes('select-lessor')){
      this.setState({videoDialog: true})
    }
   }

   componentWillMount(){
     checkLogin()
   }
   render() {

     let userDetail = JSON.parse(localStorage.getItem('userInfo'));
     let location = browserHistory.getCurrentLocation().pathname
     let { notifications } = this.props
     if(localStorage.getItem('userInfo')) {
       {
         if((notifications.overdue == 3) && !location.includes('billing') && !location.includes('user-profile') && !location.includes('notifications') ){
           return(<TrialExpired/>)
         }else {
          return(
            <React.Fragment>
              <ComposedComponent {...this.props}/>
                <Modal
                  isOpen={this.state.videoDialog}
                  toggle={() => this.setState({videoDialog: false}, () => setGlobalCookie('MRCalcPromoVideo', true, 730))}
                  size="lg"
                >
                  <ModalHeader>Introduction</ModalHeader>
                  <ModalBody style={{padding:'8px 10px'}}>
                    <video style={{width: '100%'}} src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/videos/mr_calculator_promo.mp4" autoPlay={true} muted controls/>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="contained"  onClick={() => this.setState({videoDialog: false}, () => setGlobalCookie('MRCalcPromoVideo', true, 730))}>Okay</Button>
                  </ModalFooter>
                </Modal>
            </React.Fragment>
          )
         }
       }
     }else if(!userDetail && getGlobalCookie('lessorAccess') && window.location.href.includes('select-lessor')) {
       return <Onboarding/>
     }else{
       return null
     }
   }
 }

 const mapStateToProps = (state) =>({
   notifications: state.sharedReducers.notifications,
 })
  return withRouter(connect(mapStateToProps, null)(authenticateApp));
}
export default Authenticate;
