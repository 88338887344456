import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import { InvoiceConfigCol } from '../../Elements';
import { ordinalSuffixOf, getLocalStorageInfo } from '../../../../utils';
export default class LeaseRentalInvoice extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { rentalInvoiceView } = this.props;
    return(
      <div className="data-invoice-block" >
        {
          getLocalStorageInfo().user.permission['contracts']['lease_rental_rate'].indexOf('U') != -1 ?
          <span  style={{float:'right',marginRight:'23px'}}>{rentalInvoiceView.requested_data && Object.keys(rentalInvoiceView.requested_data).length ? '( Pending Review )':''}
          <img onClick={this.props.toggleEditSideBar} src={imgStoragePath + "icon_edit_white.png"} alt="edit" className="edit-card-icon"   width="16"/></span>:null
        }
        <Row>
          <InvoiceConfigCol
            size="4"
            title="Invoice Generation Day"
            value={rentalInvoiceView.rental_invoice_day_of_month ? ordinalSuffixOf(rentalInvoiceView.rental_invoice_day_of_month) + ' of Month' : '--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Invoice Due Days"
            value={rentalInvoiceView.rental_invoice_due_day_of_month ? ordinalSuffixOf(rentalInvoiceView.rental_invoice_due_day_of_month) + ' of Month': '--' }
          />
          <InvoiceConfigCol
            size="2"
            title="Invoice Due Month"
            value={rentalInvoiceView.rental_invoice_is_due_day_next_month ? 'Next Month':'Same Month'}
          />

        </Row>
        <Row>
        <InvoiceConfigCol
            size="10"
            title="Description"
            value={rentalInvoiceView.rental_description ? rentalInvoiceView.rental_description:'--'}
          />
        </Row>
        <p>Invoice Sender</p>
        <Row>
        <InvoiceConfigCol
            size="4"
            title="Invoice From"
            value={rentalInvoiceView.rental_from_company_name ? rentalInvoiceView.rental_from_company_name: '--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Company Address"
            value={rentalInvoiceView.rental_from_company_address ? rentalInvoiceView.rental_from_company_address: '--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Contact Details"
            value={rentalInvoiceView.rental_from_company_contact ? rentalInvoiceView.rental_from_company_contact: '--' }
          />
        </Row>
        <p>Invoice Receiver</p>
        <Row>
          <InvoiceConfigCol
            size="4"
            title="Invoice To"
            value={rentalInvoiceView.rental_to_company_name ? rentalInvoiceView.rental_to_company_name: '--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Company Address"
            value={rentalInvoiceView.rental_to_company_address ? rentalInvoiceView.rental_to_company_address: '--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Contact Details"
            value={rentalInvoiceView.rental_to_company_contact ? rentalInvoiceView.rental_to_company_contact: '--' }
          />
        </Row>
        <Row className="invoice-last-block" style={{display:'none'}}>
          <InvoiceConfigCol
            size="4"
            title="Bank Name"
            value={ rentalInvoiceView.rental_bank_name ? rentalInvoiceView.rental_bank_name:'--' }
          />

          <InvoiceConfigCol
            size="4"
            title="Bank Account Name"
            value={ rentalInvoiceView.rental_bank_account_name ? rentalInvoiceView.rental_bank_account_name:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank Account Number"
            value={ rentalInvoiceView.rental_bank_account_number ? rentalInvoiceView.rental_bank_account_number:'--' }
          />

          <InvoiceConfigCol
            size="4"
            title={getLocalStorageInfo().defaultLessor.id==14?"Bank ABA":"Bank IBAN"}
            value={ rentalInvoiceView.rental_bank_iban ? rentalInvoiceView.rental_bank_iban:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank Swift Code"
            value={ rentalInvoiceView.rental_bank_swift_code ? rentalInvoiceView.rental_bank_swift_code:'--' }
          />
          <InvoiceConfigCol
            size="4"
            title="Bank SORT Code"
            value={ rentalInvoiceView.rental_bank_sort_code ? rentalInvoiceView.rental_bank_sort_code:'--' }
          />
        {
          [14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
          <Fragment>
            <InvoiceConfigCol
              size="4"
              title="Hub Account"
              value={rentalInvoiceView.hub_number ? rentalInvoiceView.hub_number :'--'}
            />
            <InvoiceConfigCol
              size="4"
              title="VAT Number"
              value={rentalInvoiceView.vat_number ? rentalInvoiceView.vat_number : '--'}
            />
        </Fragment> :null

        }
          <InvoiceConfigCol
            size="4"
            title="Footer Note"
            value={ rentalInvoiceView.rental_footer_note ? rentalInvoiceView.rental_footer_note:'--' }
          />
        </Row>
     </div>
    )
  }
}
