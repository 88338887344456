import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, UncontrolledTooltip, Col } from 'reactstrap';
import classnames from 'classnames';
import { EngineTab,AddEditEngine, EditEngineLLP } from '../components';
import { techSummaryCrudAc, viewLLPInfoAc, addEditTechSummaryAc, addEditEngineLLPAc, fetchTechSummaryListAc, exportLLPsAc, importLLPsAc, deleteMsnAssetAc } from '../actionCreators';
import { TECH_SMRY_CRUD,TOGGLE_TECH_SMRY_VIEW, LLP_UPDATE_FIELD_TOGGLE} from '../actions';
import { EditSidebar,FormModeType, FieldSelect, FieldCol, FieldGroup, FieldLabel } from '../../../contracts/Elements'
import { sManufacturerAc,sLesseeListAc } from '../../../../shared/actionCreators';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { NoRecordFound  } from '../../../../shared';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { addEngine } from '../createTechSummary';
import { getLocalStorageInfo } from '../../../../utils';
import { globalGetService, globalPostService } from '../../../../globalServices';
class EngineContent extends Component{
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			error: '',
			positionError: '',
			engines: [],
			linkEnigne: {
				flag: false,
				engine: {
					stand_alone_link: true,
					stand_alone_asset_id: null,
					position: 1,
					is_fitted: true,
					is_titled: false
				}
			},
			deleteEngine:{
				flag: false,
				engine: {}
			}
		};
	}

	getEnignesList = () =>{
		globalGetService(`console/stand-alone-asset/`, {asset_type: 'engine',aircraft_slug: this.props.asset_slug})
		.then(response => {
			if(response.data.statusCode === 200){
				this.setState({
					engines: response.data.data.engine
				})
			}
		})
	}

	deleteMsnEngine = () =>{
		this.props.deleteMsnEngine(this.state.deleteEngine.engine.id)
		this.setState({
			deleteEngine: {flag: false, engine:{}}
		})
	}

	linkEnigne = () =>{
		if(this.state.linkEnigne.engine.stand_alone_asset_id === null || this.state.linkEnigne.engine.stand_alone_asset_id === ""){
			this.setState({error: 'Please select engine'})
		}
		if(this.state.linkEnigne.engine.position === null || this.state.linkEnigne.engine.position === ""){
			this.setState({positionError: 'Please select engine position'})
		}
		setTimeout(() => {
			if(this.state.error != '' || this.state.positionError != ''){
				return
			}else{
				setTimeout(() => {
					globalPostService(`console/aircraft/${this.props.asset_slug}/engine/`, this.state.linkEnigne.engine)
					.then(response => {
						if(response.data.statusCode === 200){
							this.setState({linkEnigne: {flag: false, engine: {
								stand_alone_link: true,
								stand_alone_asset_id: null,
								position: 1
							}}})
							this.getEnignesList()
							this.props.fetchTechSummaryList(this.props.asset_slug, 'engineList');
						}
					})
				}, 500)
			}
		}, 300)
	}

	updateEngineField = (key, value) => {
		this.setState({
			error: '',
			positionError: ''
		})
		this.setState({
			linkEnigne:{
				...this.state.linkEnigne,
				engine: {...this.state.linkEnigne.engine, [key]: value}
			}
		})
	}

	componentDidMount(){
		this.props.getOwnerList()
		if(!this.props.engineManufacturerList.length){
			this.props.fetchManufacturer('engine', 'engineManufacturerList');
		}
		this.props.fetchTechSummaryList(this.props.asset_slug, 'engineList');
		this.getEnignesList()
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}
	render(){
		const { engineList, engineCrud, sEngineTypes, sLessorList, sLesseeList, sOperatorList, sRegionList, engineManufacturerList, asset_slug, overviewTechInfo, techSpecsLoader,techConstants,llpCrud, llpToggleState, acceptExtensions, ownerList } = this.props;
		var showTabs = false
		let lessorIds = []
		 if(window.location.href.includes('qa')){
			 lessorIds = [241, 86]
		 }else if(window.location.href.includes('dev')){
			 lessorIds = [289,89]
		 }else{
			 lessorIds = [86]
		 }
		if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
			showTabs = true
		}
		return(
			<div>
				<div className="tech-engine-tab">
					{
							getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('C') != -1) ?
							<p style={{overflow: 'hidden'}}>
							<span style={{ float: 'right'}}>
							{
		 					showTabs ?
							 <button style={{marginRight: '20px'}} className="btn btn-primary add-new" onClick={() => this.setState({linkEnigne: {flag: true, engine: {stand_alone_link: true,
							 stand_alone_asset_id: null,
							 is_fitted: true,
							 is_titled: false,
							 position: 1}}, error: '', positionError: ''})}> + Link Engine</button>
		 					 :null
		 				 }
							{!showTabs && <button style={{float: 'right'}} className="btn btn-primary add-new" onClick={() => this.props.techSummaryCrud('add', {}, 'engineCrud',addEngine)}> + Add Engine</button>}
							</span>
							</p>:null
					}
					{engineList.length ?
						<Nav tabs>
							{engineList.map((engineList, index) =>
								<NavItem key={index}>
									<NavLink
										className={classnames({ active: this.state.activeTab === (parseInt(index)+1) })}
										onClick={() => { this.toggle((parseInt(index)+1)); }}
										>
										Engine {'#' + engineList.position.value } ( {(engineList.esn ? engineList.esn : null )} )
										<p>
											{engineList.is_titled ?
												<label className="titled-fitted">Titled</label>:
													engineList.is_fitted ?
												<label className="titled-fitted">Fitted</label>:
												null
											}
										</p>
									</NavLink>
								</NavItem>
							)}
						</Nav>:
						null
					}
					<TabContent activeTab={this.state.activeTab}>
						{engineList.map((engineList, index) =>
							<TabPane key={parseInt(index)} tabId = {parseInt(index)+ 1}>
								<EngineTab
									techConstants={techConstants}
									llpToggleState={llpToggleState}
									editLlpFn = {this.props.updateLlpFieldToggleFn}
									techSpecsLoader={techSpecsLoader}
									asset_slug={asset_slug}
									viewLLPInfo={this.props.viewLLPInfo}
									viewEngine={this.props.techSummaryCrud}
									engineList={engineList}
									index={index}
									key={index}
									assetType= "aircraft"
									addEditEngineLLP = {this.props.addEditEngineLLP}
									exportLLPs = {(slug, activeTab) => this.props.exportLLPs(slug, activeTab)}
									importLLPs = {this.props.importLLPs}
									showTabs={showTabs}
									acceptExtensions={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx'}
									deleteMsnEngine={() => this.setState({deleteEngine: {flag: true, engine: engineList}})}
								/>
							</TabPane>
						)}
						{!techSpecsLoader && !engineList.length ?
							<NoRecordFound description="Get Started by clicking '+ Add Engine' button above" /> :  null
						}
					</TabContent>
				</div>
				<EditSidebar width="940px" right= "-940px" title={engineCrud.type == 'add' ? 'Add Engine' : 'Edit Engine'} editSideBar={engineCrud.flag} toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'engineCrud')}>
					{Object.keys(engineCrud.data).length ?
						<AddEditEngine
							asset_slug = {asset_slug}
						 	type={engineCrud.type}
						 	addEditTechSummary={this.props.addEditTechSummary}
						 	engineObj={engineCrud.data}
						 	sEngineTypes={sEngineTypes}
						 	sLessorList={sLessorList}
						 	sLesseeList={sLesseeList}
						 	sOperatorList={sOperatorList}
						 	sRegionList={sRegionList}
							manufacturerList={engineManufacturerList}
							techConstants={techConstants}
						 	toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'engineCrud')}
						 	overview = {overviewTechInfo}
							showAdvanced={showTabs}
							ownerList={ownerList}
				 		/> : null
			 		}
				</EditSidebar>
				<EditSidebar title={llpCrud.type != '' ?
          (llpCrud.type == 'add') ? 'Add LLP':
          (llpCrud.type == 'edit') ? 'Edit LLP': 'View LLP' : ''
          }
          editSideBar={llpCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'llpCrud')}>
					<div>
						<FormModeType
							type={llpCrud.type}
							changeViewMode={()=>this.props.changeViewMode(llpCrud.type,'llpCrud')}
							editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('U') != -1 ? true:false}
						/>
						{  Object.keys(llpCrud.data).length && llpCrud.flag ?
							<EditEngineLLP
								asset_slug = {asset_slug}
								type={llpCrud.type}
								llpInfoObj={llpCrud.data}
								addEditEngineLLP = {this.props.addEditEngineLLP}
								engineId={llpCrud.engineId}
								toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'llpCrud')}
							/> : null
						}
					</div>
				</EditSidebar>
				<DeleteModal isOpen={this.state.linkEnigne.flag} toggle={() => this.setState({linkEnigne: {flag: false, engine: {stand_alone_link: true,
				stand_alone_asset_id: null,
				position: 1}}})} title="Link Engine" className="modal-tform-delete-pictures">
					<div>
					<Row>
					<FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Engine</FieldLabel>
								<FieldSelect
									placeholder='Select Engine'
									keyParam="stand_alone_asset_id"
									value={this.state.linkEnigne.engine.stand_alone_asset_id}
									type='edit'
									options={this.state.engines.map(item => {return {...item, esn: item.esn + ': ' + item.engine_type}})}
									updateField={this.updateEngineField}
									labelKey= 'esn'
									valueKey='id'
								/>
								<h6 className="error-msg">{this.state.error}</h6>
							</FieldGroup>
		         </FieldCol>
					<FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Engine Position</FieldLabel>
								<FieldSelect
									placeholder='Select Position'
									keyParam="position"
									value={this.state.linkEnigne.engine.position}
									type='edit'
									options={this.props.techConstants.filter(item => item.type === 'engine_position')}
									updateField={this.updateEngineField}
									labelKey= 'label'
									valueKey='value'
								/>
								<h6 className="error-msg">{this.state.positionError}</h6>
							</FieldGroup>
		         </FieldCol>
					<FieldCol md="6">
					<div className="form-group" style={{display: "block",margin: "25px 0px"}}>
						<label className="label">
							<input
								type="checkbox"
								checked={this.state.linkEnigne.engine.is_fitted}
							 onChange={(e) =>{ this.updateEngineField('is_fitted', e.target.checked);  setTimeout(() => this.updateEngineField('is_titled', false), 300)}}
							/>
							<span>
								Is Engine fitted on this MSN ?
								<i class="fa fa-info-circle" aria-hidden="true" id="engine-field2" style={{paddingLeft: '4px'}}></i>
								<UncontrolledTooltip placement="right" target="engine-field2">
									Tick if the major assembly is temporarily installed
								</UncontrolledTooltip>
							</span>
						</label>

					</div>
		         </FieldCol>
					<FieldCol md="6">
					<div className="form-group" style={{display: "block",margin: "25px 0px"}}>
						<label className="label">
							<input
								type="checkbox"
								checked={this.state.linkEnigne.engine.is_titled}
								onChange={(e) =>{ this.updateEngineField('is_titled', e.target.checked); setTimeout(() => this.updateEngineField('is_fitted', false), 300)}}
							/>
							<span>
								Is Engine titled on this MSN ?
								<i class="fa fa-info-circle" aria-hidden="true" id="engine-field3" style={{paddingLeft: '4px'}}></i>
								<UncontrolledTooltip placement="right" target="engine-field3">
									  Tick if the major assembly is Titled
								</UncontrolledTooltip>
							</span>
						</label>

					</div>
		         </FieldCol>
					</Row>
					</div>
          <ul className="list-inline" style={{textAlign: 'right'}}>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.linkEnigne()}>Add</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-secondary" onClick={() => this.setState({linkEnigne: {flag: false, engine: {
									stand_alone_link: true,
									stand_alone_asset_id: null,
									position: 1
								}}})}>Cancel</button>
              </li>
          </ul>
          </DeleteModal>
					<DeleteModal isOpen={this.state.deleteEngine.flag} toggle={() => this.setState({deleteEngine: {flag: false, engine: {}}})} title={`Remove Engine ${this.state.deleteEngine.engine.esn}`} className="modal-tform-delete-pictures">
					<ul className="list-inline" style={{textAlign: 'right'}}>
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deleteMsnEngine()}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-secondary" onClick={() => this.setState({deleteEngine: {flag: false, engine: {}}})}>Cancel</button>
              </li>
          </ul>
					</DeleteModal>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	engineManufacturerList: state.sharedReducers.engineManufacturerList,
	engineList: state.TechSummaryReducer.engineList,
	sEngineTypes: state.sharedReducers.sEngineTypes,
	sLessorList: state.sharedReducers.sLessorList,
	sLesseeList: state.sharedReducers.sLesseeList,
	sOperatorList: state.sharedReducers.sOperatorList,
	sRegionList: state.sharedReducers.sRegionList,
	engineCrud: state.TechSummaryReducer.engineCrud,
	llpCrud: state.TechSummaryReducer.llpCrud,
	engineList: state.TechSummaryReducer.engineList,
	techConstants: state.sharedReducers.techConstants,
	llpToggleState: state.TechSummaryReducer.llpToggleState,
	ownerList:state.confReducer.ownerDropdownList
});
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
		fetchLessee:()=>dispatch(sLesseeListAc()),
		getOwnerList:()=> dispatch(getOwnerDropdownListAc()),
		fetchManufacturer: (group_type, reducerType) => dispatch(sManufacturerAc(group_type, reducerType)),
		addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
		addEditEngineLLP: (asset_slug, data, operation, type, viewType, llpType) => dispatch(addEditEngineLLPAc(asset_slug, data, 'aircraft', ownProps.params, operation, type, viewType, llpType)),
		viewLLPInfo: (asset_slug, id, index, childIndex) => dispatch(viewLLPInfoAc(asset_slug, id,index, childIndex)),
		fetchTechSummaryList: (asset_slug, type) => dispatch(fetchTechSummaryListAc(asset_slug, type)),
		exportLLPs: (engine_slug, llpType) => dispatch(exportLLPsAc(ownProps.params, engine_slug, llpType)),
		importLLPs: (file, engine_slug, llpType) => dispatch(importLLPsAc(ownProps.params, file, engine_slug, llpType)),
		deleteMsnEngine: (engineId) => dispatch(deleteMsnAssetAc(ownProps.params,engineId, 'engine')),
		changeViewMode: (type,actionType) => dispatch({
			type:TOGGLE_TECH_SMRY_VIEW,
			payload:{type},
			actionType
		}),
		toggleEditBar: (data, actionType) => dispatch({
			type:TECH_SMRY_CRUD,
			payload:data,
			actionType
		}),
		updateLlpFieldToggleFn: (flag) => dispatch({
			type: LLP_UPDATE_FIELD_TOGGLE,
			payload: flag
		}),
		techSummaryCrud: (type, id, actionType,dataObject, assetType) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject, assetType)),
		}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineContent));
