import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements'

export default class CrewSeatTab extends Component {
 constructor(props){
  super(props);
 }
  render() {
  const { crewSeat, index, asset_slug } = this.props;
  const crewSeatObj = [
    {value: crewSeat.type_of_seat && crewSeat.type_of_seat.label ? crewSeat.type_of_seat.label : '--'},
    {value: crewSeat.location  ? crewSeat.location : '--'},
    {value: crewSeat.part_number ? crewSeat.part_number : '--'},
    {value: crewSeat.serial_number ? crewSeat.serial_number : '--'},
    {value: crewSeat.manufacturer ? crewSeat.manufacturer : '--'},
    {value: crewSeat.remarks ? crewSeat.remarks : '--'}
  ]
  return (
    <tr onClick={() => this.props.viewCrewSeat(asset_slug, crewSeat.id, index, 'crewSeatView')}>
      {crewSeatObj.map((crewSeat,index) =>
        <ContrListCol key={index} colObj={crewSeat}   />
      )}
    </tr>
    )
  }
}
