import * as actions from '../actions';
export default function( state={
  apuEventList:[],
  apuEventHistory:[],
  apuClaims: [],
  apuLoader: false,
  apuShopVisit: {
    type: '',
    data: {},
    flag: false
  },
  apuClaimInfo: {
    type: '',
    data:{},
    flag: false
  },
  apuPayoutInfo:{
    type: '',
    data:{},
    flag:false
  },
},action){
  switch (action.type) {
    case actions.APU_EVENT_LIST:
      return {
        ...state,
        apuEventList: action.payload
      }
    case actions.APU_EVENT_HISTORY:
      return {
        ...state,
        apuEventHistory: action.payload
      }
    case actions.APU_SHOP_VIST_ADD_SUCCESS:
      return {
        ...state,
        apuEventHistory:[action.payload, ...state.apuEventHistory]
      }
    case actions.APU_SHOP_VISIT_CRUD:
      return {
        ...state,
        apuShopVisit: action.payload
      }
    case actions.APU_CLAIM_LIST:
      return {
        ...state,
        apuClaims: action.payload
      }
    case actions.APU_CLAIM_ADD_SUCCESS:
      return {
        ...state,
        apuClaims: [action.payload, ...state.apuClaims]
      }
    case actions.APU_CLAIM_EDIT_SUCCESS:
      return {
        ...state,
        apuClaims: state.apuClaims.map(list => list.id == action.payload.id ? action.payload : list)
      }
    case actions.APU_CLAIM_CRUD:
      return {
        ...state,
        apuClaimInfo: action.payload
      }
    case actions.APU_PAYOUT_CRUD:
      return {
        ...state,
        apuPayoutInfo: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        apuShopVisit: {
          ...state.apuShopVisit,
          type: 'edit',
          flag:true
        }
      }
    case actions.APU_LOADER:
      return {
        ...state,
        apuLoader: action.payload
      }
    default:
      return state;
  }
}
