import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormat, imgStoragePath } from '../../../../constants';
import { UPDATE_SIMULATION_FIELDS } from '../actions';
class GrpDateField extends Component {
  constructor(props){
    super(props);
    this.state = {
      fieldObj:{},
      invalidDate: false
    };
  }
  componentDidMount(){
    this.updateFieldState(this.props);
  }
  componentWillReceiveProps(nextProps){
    this.updateFieldState(nextProps);
  }
  updateFieldState = (obj) => {
    this.setState(prevState => ({
      ...prevState,
      fieldObj: {
        ...prevState.fieldObj,
        metrixType: obj.metrixType,
        eventIndex: obj.eventIndex,
        programType: obj.programType,
        keyParam: obj.keyParam,
        value: obj.value
      }
    }));
  }
  updateMRSimilation = (value) => {
    const mrData = this.props.mrFinanceGraph.data;
    let dateFlag = [];
    if(this.props.metrixType == 'airframe'){
      Object.keys(mrData.airframe[this.props.programType].events).map(event => {
        if(event < this.props.eventIndex){
          dateFlag.push(moment(value).isAfter(mrData.airframe[this.props.programType].events[event].eventDate));
        }else if(event > this.props.eventIndex){
          dateFlag.push(moment(value).isBefore(mrData.airframe[this.props.programType].events[event].eventDate));
        }
      });
    }else{
      Object.keys(mrData[this.props.metrixType].events).map(event => {
        if(event < this.props.eventIndex){
          dateFlag.push(moment(value).isAfter(mrData[this.props.metrixType].events[event].eventDate));
        }else if(event > this.props.eventIndex){
          dateFlag.push(moment(value).isBefore(mrData[this.props.metrixType].events[event].eventDate));
        }
      });
    }
    if(!(dateFlag.indexOf(false) >= 0)){
      this.props.updateSimulationField({
        metrixType: this.state.fieldObj.metrixType,
        programType: this.state.fieldObj.programType,
        eventIndex: this.state.fieldObj.eventIndex,
        keyParam: this.state.fieldObj.keyParam,
        value: value
      });
    }else{
      this.setState({
        invalidDate: true
      })
    }
  }
  render(){
    const { simulationType, newvalue, value, metrixType, programType, eventIndex } = this.props;
    if(simulationType == 0){
      return (
        <div>
          <h6>{moment(value).format(displayDateFormat)}</h6>
        </div>
      )
    } else if(simulationType == 1){
      return(
        <div className="editable-field">
          <DatePicker
            selected={moment(value).isValid() ? moment(value): ''}
            dateFormat = {fieldDateFormat}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            onChange= {(e) => this.updateMRSimilation(moment(e).format(backendDateFormat))}
            />
            <span className="field-edit-icn">
              <img src={imgStoragePath+"edit-field.png"} alt="ic" />
            </span>
            {this.state.invalidDate ? <p className="mr-error-field">Invalid Date</p>:null}
        </div>
      )
    }else if(simulationType == 2){
      return(
        <div className="override-value">
          {
            value == newvalue ?
            <h6>{moment(value).format(displayDateFormat)}</h6>
            :
            <div>
              <h6 className="changed-value">
                  {moment(newvalue).format(displayDateFormat)}
              </h6>
              <p className="old-value">
                  {moment(value).format(displayDateFormat)}
              </p>
            </div>
          }
        </div>
      )
    }else{
      return null
    }
  }
}
const mapStateToProps = state => ({
  simulationType: state.MRFinanceGraphReducer.simulationType,
  mrFinanceGraph: state.MRFinanceGraphReducer.mrFinanceGraph
});
const mapDispatchToProps = dispatch => {
  return {
    updateSimulationField: (data) => dispatch({
      type: UPDATE_SIMULATION_FIELDS,
      payload: data
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GrpDateField)
