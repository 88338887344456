import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {  GetInputField } from '../../Elements';
import BulkOperationAttachment from "./BulkOperationAttachment";
import EngineFileList from "./EngineFileList";
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp,NoRecordFound } from '../../../../shared';


export default class Attachments  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = () => {

  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row style={{margin: '0px'}}>
          <Col>
          <div className='tableInspec'>
            <TableUIComp
                noStickyHeader={true}
                hover={true}
                tableBulkOp = {<BulkOperationAttachment GenUrlFn = {this.props.GenUrlFn} addText= "Create" addComponent= {() => ''}/>}
                filter={false}
                tableHeight= "auto"
                theads={[{label:'File Name', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'Uploaded on', sortKey: '' },{label:'Uploaded by', sortKey: ''}, {label: 'Action', sortKey: ''}]}
                bulkOperation={false}
                pagination={false}
                content={content.attachments&& content.attachments.length ? content.attachments.map((contentDetails, index) => <EngineFileList index={index} shopVisit = {contentDetails} key={index} index={index} updateForm = {this.props.updateForm}  toggleDelModal={this.props.toggleDelModal} />)  : null} 
                recordsFound = {content.attachments&& content.attachments.length ?false:true}
              />
          </div>
          </Col>
         </Row> 
   
    </div>
    );
  }
}
