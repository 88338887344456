import React from 'react';
const EventCardInfo = (props) => {
  return(
    <li>
      <h6>{props.title}</h6>
      <p>{props.value}</p>
    </li>
  )
}
export default EventCardInfo;
