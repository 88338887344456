import React, { Component } from 'react';

let flag = 1;
export default class FleetViewScrollHeader  extends Component {
  constructor(props){
    super(props);
    this.state = {
      positionHeader: 0
    }
}

componentDidMount(){
  document.addEventListener('scroll', this.handleScroll, true);
}

componentWillUnmount(){
  window.removeEventListener('scroll', this.handleScroll);
}

handleScroll = (e) => {
   let left = e.srcElement.scrollLeft;
   if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight) {
    flag = 1
  } else if (e.srcElement.scrollTop <= 0) {
    flag = 0
  } else {
    flag = 1;
  }
   if(!e.srcElement.scrollTop  && flag){
    this.setState(prevState => ({
      ...prevState,
      positionHeader : left
   }));
   flag = 1;

  }
}

  render() {
    const { cashFleetView,fleetViewWidth  } = this.props;
      return(
        <div style={{ width: ( window.innerWidth - (300 + 175) + 'px'), overflow: 'hidden' }}>
            <div className="flex-shrink-flow" style={{ width: fleetViewWidth, flexBasis: fleetViewWidth,  position: 'relative', right: this.state.positionHeader }}>
              {
              cashFleetView.major_assemblies.map((cashFleetView, index) =>
                <div key={index} className="flex-centered spacing-cls " style={{ width: '300px', background: '#fff', flexBasis: '300'}}>
                  <div className="first-block">
                      <p >{cashFleetView.name}</p>
                    </div>
                  </div>
                )
              }
          </div>
        </div>
      )
    }
  }
