import React from 'react';
class TextareaField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value
    })
  }
  componentWillReceiveProps(nextProps){

    if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value
      })
    }
  }
  updateForm = (value) => {
    this.setState({
      value: value,
    });
    if(this.props.typingOnchange!=null && this.props.typingOnchange){
      this.props.updateForm(value);
    }
  }
  onBlur = () => {
    if(this.props.typingOnchange == null){
    this.props.updateForm(this.state.value);
    }
  }
  render(){
    const { type, isDisabled } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
      <div>
        <div className="form-group-edit">
          <label className="label">
            {this.props.children} { this.props.required ? <sup className="reqStar"> * </sup> :null}
          </label>
          {type == 'view' ?
            <h6>{this.props.value ? this.props.value:'--'}</h6> :
          <textarea
            style={{ height: 'auto !important'}}
            rows="4"
            placeholder= {this.props.placeholder}
            value={this.state.value}
            onChange={(evt) => this.updateForm(evt.target.value)}
            onBlur={this.onBlur}
            disabled = {isDisabled}
          >{this.props.value}</textarea>}
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default TextareaField;
