import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { imgStoragePath, assetsType } from '../../../../constants';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { BatchList } from '../components'
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { RecordsSideBar } from '../../Elements';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { getBatchesListAc } from '../actionCreators';
import { EditSidebarIcon } from '../../../contracts/Elements'
import CautionReadMore from '../../../../shared/components/CautionReadMore';

class AutoRenamingandOrg extends Component{
  constructor(props){
    super(props);
    this.state ={
      readMoreFlag: false
    }
  }
  componentDidMount(){
    this.props.fetchAssetInfo()
    this.props.getBatchesList()
  }

  changeMsn = (evt) =>{
    let url = ``;
    if(evt.records){
      if(evt.asset_type == 1){
        url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/auto-organiser-and-renaming';
      }else{
        url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dashboard';
      }
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    browserHistory.push(url)
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }

  toggleReadMore=()=>{
    this.setState(prevState => ({
      ...prevState,
      readMoreFlag: !prevState.readMoreFlag,
    }));
  }


  render(){
      const { ctrShortInfo, batchLoader, bathcesList, notificationOpen } = this.props;
    return (
        <div className="auto-renamig-block" style={{ margin: '54px -15px 0px 255px', padding: '30px'}}>
            <RecordsSideBar />
            { Object.keys(ctrShortInfo).length ?
              <ShortHead
                headingText="Records"
                changeMsn={this.changeMsn}
                subHeaderTopMargin={notificationOpen == true ? 74 : 40}
                contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
            }
            <p className="notice-info" style={{marginLeft:'5px',marginRight:'5px'}}>
              <img className="caution-img" src={imgStoragePath+"caution_sign.png"} alt="img"/>
              <span>CAUTION!</span> Please read the instructions for the optimum results
              <Link onClick={()=>this.toggleReadMore()} className="pull-right">Read More &rarr;</Link>
            </p>
            <div className="to-be-reviewed">
              {
                !batchLoader ?
                  <h4>To be Reviewed</h4>
                :null
              }
              {
                bathcesList.filter(item => item.status < 2).length ?
                  bathcesList.map((batch, index) => batch.status < 2 && batch.file_count > 0 ? <BatchList index={index} getBatchView={() => browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/auto-organiser-and-renaming/view/${batch.uuid}`)}  key={`tr_${index}`} batch={batch}/> : null)
                : !batchLoader ? <NoRecordFound/> : null
              }
             </div>
        <div className="reviewed">
          {
            !batchLoader ?
              <h4>Reviewed</h4>
            :null
          }
            {
              bathcesList.filter(item => item.status == 2).length ?
                bathcesList.map((batch, index) => batch.status == 2  && batch.file_count > 0 ? <BatchList index={index}  key={`r_${index}`} batch={batch}/> : null)
              : !batchLoader ? <NoRecordFound/> : null
            }
        </div>
        <EditSidebarIcon
                title="AI Assistant - Instructions"
                editSideBar={this.state.readMoreFlag}
                toggleEditSideBar={() => this.toggleReadMore()}
            >
              <CautionReadMore
              contentflag={false}
              />
          </EditSidebarIcon>
        {
          batchLoader ? <ListLoader/> : null
        }
    </div>
    )
  }
}
const mapStateToProps = state => ({
    ctrShortInfo: state.ContractListReducer.ctrShortInfo,
    batchLoader: state.AutoRenamingReducer.batchLoader,
    bathcesList: state.AutoRenamingReducer.bathcesList,
    notificationOpen:state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return{
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Auto Renaming and Organiser')),
    getBatchesList: () => dispatch(getBatchesListAc(ownProps.params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AutoRenamingandOrg);
