import * as actions from '../actions'

export default function(state={
  storageUsageLoader:false,
  storageUsageList:{
    usage: [],
    pagination: {total: 0, number_of_pages: 1, per_page: 10, page: 1}
  },
}, action){
  switch (action.type) {
    case actions.STORAGE_USAGE_LOADER:
      return{
        ...state,
        storageUsageLoader: action.payload
      }
    case actions.STORAGE_USAGE_LIST:
      return{
        ...state,
        storageUsageList: action.payload
      }
    default:
      return state
  }
}
