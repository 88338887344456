import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import GrpAmountField from '../containers/GrpAmountField';
import GrpDateField from '../containers/GrpDateField';
import { prettyNumberParamsL, imgStoragePath } from '../../../../constants';
export default class GrpTableRow extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { eventInfo, neweventInfo, childIndex, metrixType, programType, eventIndex, simulationType } = this.props;
    return(
      <tr>
        <td>#{childIndex+1}</td>
        <td>
          <GrpDateField
            metrixType={metrixType}
            programType={programType}
            eventIndex={eventIndex}
            newvalue={simulationType==2 && neweventInfo ? neweventInfo.eventDate:''}
            value={eventInfo.eventDate ? eventInfo.eventDate: ''}
            keyParam="eventDate"
          />
        </td>
        <td>
          {
            simulationType == 2 && neweventInfo ?
            eventInfo.mr == neweventInfo.mr ?
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {eventInfo.mr}
              </NumericLabel>
              {
                (eventInfo.mr_interval!='' && eventInfo.mr_interval!=undefined && eventInfo.mr_interval != null)?
                <span className="interval-unit">
                  &nbsp;{"/"+eventInfo.mr_interval}
                </span>:null
              }
            </h6>
             :
            <div className="override-value">
              <h6 className="changed-value">
                <NumericLabel params={prettyNumberParamsL}>
                  {neweventInfo ? neweventInfo.mr : 0}
                </NumericLabel>
                {
                  (neweventInfo && neweventInfo.mr_interval!='' && neweventInfo.mr_interval!=undefined && neweventInfo.mr_interval != null)?
                  <span className="interval-unit">
                    &nbsp;{"/"+neweventInfo.mr_interval}
                  </span>:null
                }
              </h6>
              <p className="old-value">
                <NumericLabel params={prettyNumberParamsL}>
                  {eventInfo.mr}
                </NumericLabel>
                {
                  (eventInfo.mr_interval!='' && eventInfo.mr_interval!=undefined && eventInfo.mr_interval != null)?
                  <span className="interval-unit">
                    &nbsp;{"/"+eventInfo.mr_interval}
                  </span>:null
                }
              </p>

            </div>
            :
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {eventInfo.mr}
              </NumericLabel>
              {
                (eventInfo.mr_interval!='' && eventInfo.mr_interval!=undefined && eventInfo.mr_interval != null)?
                <span className="interval-unit">
                  &nbsp;{"/"+eventInfo.mr_interval}
                </span>:null
              }
            </h6>
          }
        </td>
        <td>
          <GrpAmountField metrixType={metrixType} programType={programType} eventIndex={eventIndex} newvalue={simulationType==2 && neweventInfo ?neweventInfo.eventCost:''} value={eventInfo.eventCost} keyParam="eventCost" />
        </td>
        <td>
          {
            simulationType == 2 && neweventInfo ?
            eventInfo.fundCollected == neweventInfo.fundCollected ?
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {eventInfo.fundCollected}
              </NumericLabel>
            </h6>
             :
            <div className="override-value">
                <h6 className="changed-value">
                  <NumericLabel params={prettyNumberParamsL}>
                    {neweventInfo ? neweventInfo.fundCollected : 0}
                  </NumericLabel>
                </h6>
                <p className="old-value">
                  <NumericLabel params={prettyNumberParamsL}>
                    {eventInfo.fundCollected}
                  </NumericLabel>
                </p>
            </div>
            :
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {eventInfo.fundCollected}
              </NumericLabel>
            </h6>
          }

        </td>
        <td>
            {
              simulationType == 2 && neweventInfo ?
              eventInfo.surPlus == neweventInfo.surPlus ?
              <h6>
                <NumericLabel params={prettyNumberParamsL}>
                  {eventInfo.surPlus}
                </NumericLabel>
                <img className="profile-loss-icon" src={ imgStoragePath + (eventInfo.surPlus >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
              </h6>

               :
              <div className="override-value">
                  <h6 className="changed-value" style={neweventInfo && parseFloat(parseFloat(eventInfo.surPlus).toFixed(2)) != neweventInfo.surPlus ? {color: '#2a93f5'} : {color: '#000'}}>
                    <NumericLabel params={prettyNumberParamsL}>
                      {neweventInfo ? neweventInfo.surPlus : 0}
                    </NumericLabel>
                    <img className="profile-loss-icon" src={ imgStoragePath + (neweventInfo && neweventInfo.surPlus >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
                  </h6>
                  {
                    neweventInfo && parseFloat(parseFloat(eventInfo.surPlus).toFixed(2)) != neweventInfo.surPlus ?
                    <p className="old-value">
                      <NumericLabel params={prettyNumberParamsL}>
                        {eventInfo.surPlus}
                      </NumericLabel>
                      <img className="profile-loss-icon" src={ imgStoragePath + (eventInfo.surPlus >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
                    </p>
                    :null
                  }
              </div>
              :
              <h6>
                <NumericLabel params={prettyNumberParamsL}>
                  {eventInfo.surPlus}
                </NumericLabel>
                <img className="profile-loss-icon" src={ imgStoragePath + (eventInfo.surPlus >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
              </h6>
            }
        </td>
      </tr>
    )
  }
}
