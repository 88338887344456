export const EXTRACT_FILE_LIST = 'EXTRACT_FILE_LIST';
export const EXTRACT_FILE_INFO = 'EXTRACT_FILE_INFO';
export const UPDATE_DATA_IDS = 'UPDATE_DATA_IDS';
export const CHANGE_DATA_POINT_STATUS = 'CHANGE_DATA_POINT_STATUS';
export const ML_LIST_LOADER = 'ML_LIST_LOADER';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const PROGRESS_PERCENTAGE = 'PROGRESS_PERCENTAGE';
export const SELECT_ALL_FILE = 'SELECT_ALL_FILE';
export const DELETE_ML_FILE= 'DELETE_ML_FILE';
export const CLOSE_DATALOG_SIDE_BAR = 'CLOSE_DATALOG_SIDE_BAR';
export const TOGGLE_DATA_EXT_BAR = 'TOGGLE_DATA_EXT_BAR';
