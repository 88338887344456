import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea } from '../../../contracts/Elements';
export default function AddEditRemarks(props){
  return(
    <form onSubmit={(e) => props.saveRemarks(e)}>
      <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
      <Row>
        <FieldCol md="12">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Remark<span className="reqStar" style={{color: 'red'}}>*</span></FieldLabel>
            <FieldTextarea
              value={props.remark.remarks}
              type={props.type}
              keyParam="remark"
              updateField={(key, value) => props.setRemark(value)}
              style={{height:'87px'}}
              maxLength={255}
            />
              <h6 className="error-msg">{props.error}</h6>
          </FieldGroup>
        </FieldCol>
      </Row>
      </div>
      <div className="submit-block">
        <input type="submit" className="primary-btn" value="Save Changes"/>
        <Link onClick={() =>props.toggleEditSideBar()} >Cancel</Link>
      </div>
    </form>
  )
}
