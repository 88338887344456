import React, { Component } from 'react';
import { Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
export default class PopoverLeadInfo extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
    };
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }



  render() {
    const {  tagetId, placement, leadInfo } = this.props;
    return (
           <span>
            <span className="more-lead-info" style={{background:'#94c0f7'}} onClick={(e) => this.toggle(e)} id={tagetId}> <b> + { leadInfo.filter(leads => leads).length - 2 } </b></span>
            <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody className="lead-info-pop">
								{ leadInfo.map((lead,index) =>
										index > 1 ?
                    <div className="popover-div flex-centered" key={index}>
                       	<span style={{marginBottom:'5px'}} onClick = {()=>this.props.getUserDetailFn(lead.slug, true)}>
                          {
                            lead.profile_pic ?
                              <img src={lead.profile_pic}  alt="img"/>
                            : <img src={imgStoragePath + 'user.png'} alt="logo"/>
                            }
                        </span>
                        <span style={{marginBottom:'5px'}} >
                            {lead.name ? lead.name : '-'}
                        </span>

                    </div> : null
                )}
            </PopoverBody>
        </Popover>
        </span>



    );
  }
}
