import React, { Component } from 'react';
export default class FieldTextarea extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { type, value, keyParam } = this.props;
    if(type != 'view'){
      return(
        <textarea defaultValue={value}
          value = {value}
          onChange={(e) => this.props.updateField(e.target.value)}
          >
        </textarea>
      )
    }else{
      return(
        <h6>{value ? value : '--'}</h6>
      )
    }
  }
}
