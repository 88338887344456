import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import { imgStoragePath } from '../../constants';
class ErrorPage extends Component{
  constructor(props){
    super(props);
  }
  render(){

    return(
      <Row className="error-page flex-centered">
        <Col xs="12">
          <img className="error-image" src={imgStoragePath+'something_went_wrong.png'}/>
          <h2>Oops!! Something Went Wrong.</h2>
          <p>SPARTA has encountered an error. Please try again or contact support@sparta.aero.</p>
          <Button className="go-back-button"  color="primary"><a onClick={() => window.location.reload()}>Go Back To Homepage</a></Button>
        </Col>
      </Row>
    )
  }
}
export default withRouter(ErrorPage);
