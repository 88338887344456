import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import MjrAssyList from './MjrAssyList';
import YetTobeSentList from './YetTobeSentList';
import InShopUnservList from './InShopUnservList';
import InShopServList from './InShopServList';
import OffWingServList from './OffWingServList';
import OffWingUnservList from './OffWingUnservList';
import { addApu } from '../createTechSummary';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
export default class APUtab extends Component{
  constructor(props){
    super(props)
  }
 render(){
  const { apu, index, asset_slug, techConstants } = this.props;
  return(
  <div className="horizontal-card card-block">
    {
      getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['apu'].indexOf('U') != -1 ?
      <img onClick = {() => this.props.viewApuSummery('edit',apu, 'apuCrud',addApu, index)} src={imgStoragePath + "icon_editblock.png"} alt="img" className="blue-img" width="16" />:null
    }
    {getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['apu'].indexOf('D') != -1) && apu.is_fitted && this.props.showTabs ?
      <img onClick={() => this.props.deleteMsnEngine()} style={{color: 'red', marginRight: '10px'}} src={imgStoragePath+"delete_red.png"} alt="img" className="blue-img" width="16" />
      :null
    }

    <Row>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={apu.manufacturer}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={apu.serial_number}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Part Number" text={apu.part_number}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Is APU titled for this MSN ?" text={apu.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Is APU fitted for this MSN ?" text={apu.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Is APU on MSN ?"   text={apu.on_wing_status ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
      {
        apu.on_wing_lessor  ?
        <GenTabField grid="3" classText = "horizontal-inner-block " label="APU MSN"   text={apu.on_wing_msn}/>
        : null
      }
        {
        apu.on_wing_msn   ?
        <GenTabField grid="3" classText = "horizontal-inner-block " label="On MSN APU Owner"   text={apu.on_wing_lessor}/>
        : null
      }

        {
        apu.apu_type   ?
        <GenTabField grid="3" classText = "horizontal-inner-block " label="APU Type"   text={this.props.apuTypesList.filter(item => item.id == apu.apu_type).length ? this.props.apuTypesList.filter(item => item.id == apu.apu_type)[0].name : '--'}/>
        : null
      }




    </Row>
          {
            apu.off_wing_status.value ?
            <MjrAssyList dataList={apu} />
            : null
          }
          {
            apu.off_wing_status.value == 1 ?
          <YetTobeSentList dataList={apu} /> : null
          }

          {
          apu.off_wing_status.value == 2 ?
          <InShopServList techConstants={techConstants} dataList={apu} /> : null
          }

          {
          apu.off_wing_status.value == 3 ?
          <InShopUnservList techConstants={techConstants} dataList={apu} /> : null
          }

          {
          apu.off_wing_status.value == 4 ?
          <OffWingServList techConstants={techConstants} dataList={apu} /> : null
          }

          {
          apu.off_wing_status.value == 5 ?
          <OffWingUnservList techConstants={techConstants} dataList={apu} /> : null
          }

   </div>
  )
  }
}
