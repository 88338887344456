import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import {  displayDateFormat, prettyNumberParamsL } from '../../../constants';

export default class FleetViewList extends Component{
  constructor(props){
    super(props);

  }

  getAmount = (date) => {
    let cashflowdata = this.props.cashFleetView.cashflow.filter(cashflow => cashflow.date == date );
    return cashflowdata.length ? cashflowdata[0].amount : null ;
  }

  render(){
    const { cashFleetView, monthList } = this.props;
    return(
     <div className="item-block" >
         {
          monthList.map((date, index) => {
            let sum = this.getAmount(date);
            return (
              <div key={index} className={ sum < 0 ? 'neg-value item-detail-block': 'item-detail-block'}>
              <p className="para-ui month-detail">
                { sum ? <span className="price-block">  <NumericLabel params={prettyNumberParamsL}>{ sum }</NumericLabel> </span> : <span> -- </span> } 
              </p>
          </div>
            )
          }
         
        )}


      </div>
    )
  }
}
