import * as actions from '../actions';
import { globalGetService, globalDeleteService, globalPostService, globalPutService, globalExportService } from '../../../../globalServices';
import { browserHistory } from 'react-router';
import { toastFlashMessage, downloadFileType } from '../../../../utils';

export const getBatchesListAc = (props) =>{
  return(dispatch)=>{
    dispatch(triggerLoader(true))
    globalGetService(`records/${props.type}/${props.aircraft_slug}/batches/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BATCHES_LIST,
          payload: response.data.data
        })
      }
      dispatch(triggerLoader(false))
    })
  }
}
export const getOrganisedListAc = (props, filters) =>{
  return(dispatch)=>{
    dispatch(triggerLoader(true))
    globalGetService(`/records/batch/${props.batchID}/organised-files/`, filters)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ORGANISSED_BATCHES_LIST,
          payload: response.data.data
        })
      }
      dispatch(triggerLoader(false))
    })
  }
}
export const getUnorganisedListAc = (props, filters) =>{
  return(dispatch)=>{
    dispatch(triggerLoader(true))
    globalGetService(`/records/batch/${props.batchID}/unorganised-files/`, filters)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UNORGANISSED_BATCHES_LIST,
          payload: response.data.data
        })
      }
      dispatch(triggerLoader(false))
    })
  }
}

export const getBatchDetailAc = (params) =>{
  return(dispatch) =>{
    dispatch(triggerLoader(true))
    globalGetService(`records/batch/${params.batchID}/detail/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BATCH_DETAIL,
          payload: response.data.data
        })
      }
      dispatch(triggerLoader(false))
    })
  }
}

export const deleteBatchFileAc = (params, record, filters, type) =>{
  return(dispatch)=>{
    dispatch(triggerLoader(true))
    globalPostService(`/records/batch/${params.batchID}/batch-file-recycle/action/`,{action:1,items:[{uuid:record.uuid,type:record.type}]})
    .then(response=>{
      if(response.data.statusCode == 200){
        if(type == 'unorganised' || filters == 'unorganised'){
          dispatch(getUnorganisedListAc(params))
        }else{
          dispatch(getOrganisedListAc(params))
        }
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch(triggerLoader(false))
    })
  }
}

export const publishBatchAc = (params) =>{
  return(dispatch)=>{
    dispatch(triggerLoader(true))
    globalGetService(`/records/batch/${params.batchID}/publish/`)
    .then(response => {
      dispatch(triggerLoader(false))
      if(response.data.statusCode == 200){
        browserHistory.push(`/${params.type}/${params.aircraft_slug}/records/auto-organiser-and-renaming`)
      }
    })
  }
}

export const getFoldersToMoveAc = (params, rootId, source) =>{
  return(dispatch)=>{
    globalGetService(`/records/${params.type}/${params.aircraft_slug}/batch/get-folders-for-move/folder/${rootId}/`,source)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BATCH_DIRECTORY,
          payload: response.data.data
        })
      }
    })
  }
}

export const moveRecordToDestAc = (params, source, filters, type) => {
  return (dispatch) => {
    dispatch(triggerLoader(true))
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/batch/move/', source)
    .then(response => {
      if(response.data.statusCode == 200){
        if(type === 'unorganised'){
          dispatch(getUnorganisedListAc(params, filters))
        }else{
          dispatch(getOrganisedListAc(params, filters))
        }
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch(triggerLoader(false))
    })
  }
}

export const renameFileAc = (props, item, filters, type) =>{
  return(dispatch)=>{
    dispatch(triggerLoader(true))
    globalPostService(`/records/batch/${props.batchID}/file-rename/${item.uuid}/`,{"new_name":item.name})
    .then(response =>{
      dispatch(triggerLoader(false))
      if(response.data.statusCode == 200){
        if(type == 'unorganised'){
          dispatch(getUnorganisedListAc(props, filters))
        }else{
          dispatch(getOrganisedListAc(props, filters))
        }
        toastFlashMessage(response.data.message, 'success')
      }
    })
  }
}

export const triggerLoader = (flag) =>{
  return(dispatch) => {
    dispatch({
      type: actions.BATCH_LOADER,
      payload: flag
    })
  }
}


export const unOrgExportAc = (fileType, param) => {
  let queryParam = {'download': fileType};
  return (dispatch) => {
    dispatch({
      type: actions.BATCH_LOADER,
      payload: true
    });
    globalExportService('/records/batch/'+param.params.batchID+'/unorganised-files/', queryParam)
    .then(response => {
      dispatch({
        type: actions.BATCH_LOADER,
        payload: false
      });
      downloadFileType(response, 'Unorganised_'+param.params.batchID, '.'+fileType)
    });
  }
}
