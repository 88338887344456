import React, {Component} from 'react';
import { Row, Col, Input, FormGroup} from 'reactstrap';
import { Link } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL, assetStatus } from '../../../constants';
import OperatorLessor from '../../../User/components/OperatorLessor';
import { getLocalStorageInfo } from '../../../utils';
export default class AssetsList extends Component{
    constructor(props) {
      super(props);
    }

    render(){
      const { assetList,type,checkedList } = this.props;
      return(
        <div className="asset-list">
            <Row className="flex-centered" onClick={() => (assetList.asset && assetList.asset.ownership_type != 2) || (!assetList.asset) ? this.props.addToCompare(assetList,this.props.index,type) : null}>
                <Col md="2">
                  <div className="flex-centered msn-block">
                    <FormGroup check>
                      {
                        (assetList.asset && assetList.asset.ownership_type != 2) || (!assetList.asset) ? <Input type="checkbox" id={"asset_"+assetList.id} checked={checkedList.filter(item =>  item.asset_id == assetList.id).length ? true:false}/> :null
                      }
                    </FormGroup>
                        <div>
                          {type == 'existing'?
                            <div>
                              <span className="asset-model">
                                {assetList.asset.aircraft_type.name}{assetList.asset.model?'-' + assetList.asset.model:''}
                              </span>
                              <h4>{assetList.asset.asset_type == 1  ? "MSN" : "ESN"}&nbsp;{assetList.asset.msn}</h4>
                              {assetList.asset.notional_mr ? <span style={{background: '#777', color: '#fff', borderRadius: '.25em', textAlign: 'center', padding: '.2em .6em .3em', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-SemiBold'}}>Non MR Paying Operator </span>:null }
                            </div>
                            :
                            <div>
                              <span className="asset-model">
                              {assetList.asset_type == 1  ?assetList.aircraft_type.name:assetList.engine_type.name}{assetList.model?'-'+assetList.model:''}
                              </span>
                              <h4>{assetList.asset_type == 1  ? "MSN" : "ESN"}&nbsp;{assetList.asset_type == 1?assetList.msn:assetList.esn}</h4>
                              {assetList.notional_mr ? <span style={{background: '#777', color: '#fff', borderRadius: '.25em', textAlign: 'center', padding: '.2em .6em .3em', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-SemiBold'}}>Non MR Paying Operator </span>:null }
                          </div>}
                          {
                            assetList.asset && assetList.asset.ownership_type == 2 ?
                              <span className="portfolio-type" style={{marginTop: '5px', display: 'block', textAlign: 'center'}}>Managed</span>
                            :null
                          }
                        </div>
                  </div>
                </Col>
                {type == 'existing'?
                assetList.asset.lessee ?  <OperatorLessor slug={assetList.asset.lessee.slug} obj={assetList.asset.lessee}/>:<Col xs= "3" md="2">  --  </Col>
                : assetList.lessee != null ? <OperatorLessor slug={assetList.lessee.slug} obj={assetList.lessee}/>:<Col xs= "3" md="2"> -- </Col>}
                <Col md="2">
                  <h6 className="theta asset-list-width">
                    {type == 'existing'?
                    <NumericLabel params={prettyNumberParamsL}>{assetList.asset.cash_inflow}</NumericLabel>
                    :<NumericLabel params={prettyNumberParamsL}>{assetList.cash_inflow}</NumericLabel>}
                  </h6>
                </Col>
                <Col md="2">
                  <h6 className="theta asset-list-width">
                    {type == 'existing'?
                    <NumericLabel params={prettyNumberParamsL}>{assetList.asset.cash_outflow}</NumericLabel>
                    :<NumericLabel params={prettyNumberParamsL}>{assetList.cash_outflow}</NumericLabel>}
                  </h6>
                </Col>
                <Col md="2">
                  <h6 className="theta asset-list-width">
                    {type == 'existing'?
                    <NumericLabel params={prettyNumberParamsL}>{assetList.asset.cumulative_cash_flows}</NumericLabel>
                    :<NumericLabel params={prettyNumberParamsL}>{assetList.cumulative_cash_flows}</NumericLabel>}
                  </h6>
                </Col>
                <Col md="2">
                  <h6 className="theta asset-list-width" >
                    {type == 'existing'?
                    <NumericLabel params={prettyNumberParamsL}>{assetList.asset.half_life_adjustment}</NumericLabel>
                    :<NumericLabel params={prettyNumberParamsL}>{assetList.half_life_adjustment}</NumericLabel>}
                  </h6>
                </Col>
            </Row>
        </div>
      )
    }
}
