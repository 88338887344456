import React, { Component } from 'react';
import { ContrListCol } from '../../Elements';
export default class DelCondRow extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { deliveryCond } = this.props;
    const deliveryCondObj = [
      { value: deliveryCond.asset_conditions_for_contract.name ? deliveryCond.asset_conditions_for_contract.name : '--' },
      { value: deliveryCond.terms ? deliveryCond.terms.length > 120 ? deliveryCond.terms.substring(0,117) + ' ...' : deliveryCond.terms: '--' },
      { value: deliveryCond.actual ? deliveryCond.actual.length > 120 ? deliveryCond.actual.substring(0,118) + ' ...' : deliveryCond.actual : '--' },
      { value: deliveryCond.exceptions_done ? deliveryCond.exceptions_done.length > 120 ? deliveryCond.exceptions_done.substring(0,117) + ' ...' : deliveryCond.exceptions_done : '--' }
    ]
    return(
      <tr onClick={this.props.viewDeliveryCond}>
        { deliveryCondObj.map((deliveryCond,index) => <ContrListCol colObj={deliveryCond}  key={index} />)}
      </tr>
    )
  }
}
