import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class CustomCycleRemainingModal extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.customCycleRemainFn('close','','');
  }

  render() {
    const { customCycleRemain } = this.props;
    return (
      <Modal isOpen={customCycleRemain.modalStatus} toggle={this.toggle} className="llp-brekup-modal custom-llp-cycle">
      <h6 className="error-msg text-center">{customCycleRemain.wrongEntry}</h6>
       <div className="modal-header">
           <h5 className="cc-reminaing-heading">
              Edit LLP limits if required
           </h5>
           <h6 className="para-ui">
                These values will be used to set the starting point of an LLPs Life during operation
            </h6>
        </div>
        <ModalBody style={{maxHeight: '440px'}}>
            <table>
              <thead>
                <tr>
                  <th className="caption-ui">LLPs Type</th>
                  <th className="caption-ui">Cycles Limit</th>
                  <th className="caption-ui ">Remaining Cycles</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(customCycleRemain.list).map((label, index) => {
                    return (
                      <tr key={index}>
                        <td className="caption-ui">
                          {customCycleRemain.list[label].description}
                        </td>
                        <td className="caption-ui">
                          <input type="text" onChange={(e) => this.props.updateCustomCycleRemainFn(e.target.value, 'max_limit', label)} value={customCycleRemain.list[label].max_limit}/>
                        </td>
                        <td className="caption-ui">
                          <span className="input-wrap">
                            <input type="text" onChange={(e) => this.props.updateCustomCycleRemainFn(e.target.value, 'remaining', label)}  value={customCycleRemain.list[label].remaining}/>
                          </span>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
           </table>

        </ModalBody>
        <ModalFooter>
          <button onClick={this.props.compareCustomCRFn}>SAVE</button>
          <span onClick={this.toggle}>Cancel</span>
        </ModalFooter>
      </Modal>
    );
  }
}
