import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import {Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {
  EngineGenInfo,
  EngineHeader,
  EngineGraphTable,
  GrpEngineRate
} from './'
export default class EngineSummeryCard extends Component{
  constructor(props){
    super(props);
    this.state = {
      activeTab: 1
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    const  { engines, enginesInfo, simulationType, newenginesInfo, newengines } = this.props;
    return(
      <div className="engine-block">
        <h5>Engine Shop Visits</h5>
        <EngineGenInfo simulationType={simulationType} metrixType="engines" programType="general_info" newenginesInfo={simulationType == 2 ? newenginesInfo: {}}  enginesInfo={enginesInfo} />
        <Nav tabs>
          { Object.keys(engines).map((label, index) => {
            return(
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: this.state.activeTab === index+1 })}
                  onClick={() => { this.toggle(index+1); }}
                >
                  {engines[label].name ? engines[label].name: 'Engine ' + (index + 1) }
                </NavLink>
              </NavItem>
            )
          } )}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          { Object.keys(engines).map((label, index) => {
            return(
              <TabPane key={index} tabId={index+1}>
                {
                  !engines[label].name ?<button className="loi-btn" onClick={() => this.props.toggleModal(label)}>LOI MR Table</button>:null
                }
                <div className="shop-visit-block">
                  <GrpEngineRate simulationType={simulationType} newengine={simulationType == 2 ? newengines[label]: {}} engine={engines[label]}  metrixType="engines" programType={label} />
                  <div className="overflow-auto-xs">
                  <table>
                    <EngineHeader />
                    <tbody>
                      { Object.keys(engines[label].shop_visits).map((sv,index) => {
                        return(<EngineGraphTable
                          key={index}
                          label={sv}
                          metrixType="engines"
                          programType={label}
                          simulationType={simulationType}
                          newshopVisit={simulationType == 2 ? newengines[label].shop_visits[sv]:{}}
                          shopVisit={engines[label].shop_visits[sv]} />)
                      }) }
                    </tbody>
                  </table>
                  </div>
                </div>
              </TabPane>
            )
          } )}
        </TabContent>
      </div>
    )
  }
}
