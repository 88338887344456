import React, { Component } from 'react';

export default class TableList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="global-table-list">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Designation</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>Vijay Kumar</td>
              <td>vijay@designstring.com</td>
              <td>Software Engg</td>
              <td>testing</td>
            </tr>
            <tr>
              <td></td>
              <td>Vijay Kumar</td>
              <td>vijay@designstring.com</td>
              <td>Software Engg</td>
              <td>testing</td>
            </tr>
            <tr>
              <td></td>
              <td>Vijay Kumar</td>
              <td>vijay@designstring.com</td>
              <td>Software Engg</td>
              <td>testing</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
