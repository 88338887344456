import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory, withRouter } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,  Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FileDrop from '../components/FileDrop';
import UploadedFileModal from '../components/UploadedFileModal';
import { Header, ContractSideBar, ContractBarLoader } from '../../Elements';
import { EditSidebarIcon } from '../../../contracts/Elements';
import { ListLoader } from '../../../../shared/';
import { extractFileListAc,uploadMLFileAc, deleteFileAc } from '../actionCreators';
import { TOGGLE_MODAL } from '../actions';
import FileList from '../components/FileList';
import CautionReadMore from '../../../../shared/components/CautionReadMore';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import '../../assets/styles/mlUpload.scss';
 class DataLoglist extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      dropdownOpen: false,
      file: [],
      sameKindFile: false,
      readMoreFlag:false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount(){
    this.props.extractFileListFn(this.props.params);
  }

  toggle() {
    this.props.toggleModalFn(!this.props.modalStatus);
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/extract-file-list';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.props.extractFileListFn(this.props.params);
    }
  }

  confirmModal = (file) => {
      let fileInfo = [];
      file.map(f => {
        if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
          fileInfo.push({file: f, fileType: '' })
        }else{
          fileInfo.push({ file: f, error: true });

        }
      })
      this.setState(prevState => ({
        ...prevState,
        file: fileInfo,
      }));
      this.props.toggleModalFn(!this.props.modalStatus);

  }

  confirmClickModal = (file) => {
    if(!file.length){
      return false;
    }
    let fileInfo = [];
    for(var i=0; i<file.length; i++) {
      var f = file[i];
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        fileInfo.push({file: f, fileType: ''})
      }else{
        fileInfo.push({ file: f, error: 1 });
      }
    }

    this.setState(prevState => ({
      ...prevState,
      file: fileInfo
    }));
    this.props.toggleModalFn(!this.props.modalStatus);
  }

  updateForm = (fileType, value, index) =>{
    this.setState(prevState => ({
      ...prevState,
      file: prevState.file.map((file, key) => key == index ? { ...file, [fileType] : value, error: '' } : file),
    }))
  }
  uploadMLFile = (params, file) => {
    let submitFileinfo =  file.map(file => file.fileType == '' ? { ...file, error : 2 } : file)
    this.setState(prevState => ({
      ...prevState,
      file: submitFileinfo,
    }))
    if(!submitFileinfo.filter(obj => obj.error && obj.error == 2 ).length){
      if(file.length > 1 && !(file[0].fileType != '' && file[file.length - 1].fileType != '') && file[0].fileType == file[file.length - 1].fileType){
        this.props.uploadMLFile(params, file);
      }else{
        this.props.uploadMLFile(params, file);
      }
   }
  }

  deleteFn = (index) => {
    var file = this.state.file.filter(( obj, key ) => {
      return key !== index;
    });

    this.setState(prevState => ({
      ...prevState,
      file: file,
    }));
    if(this.state.file.length <= 1){
      this.props.toggleModalFn(!this.props.modalStatus);
    }
  }

  replaceFile = (index, fileDetail) => {
    if((fileDetail.name.toLowerCase()).substr(((fileDetail.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
      this.setState(prevState => ({
        ...prevState,
        file: prevState.file.map((file, key) => key == index ?  { file: fileDetail, fileType : '', error: '' }  : file),
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        file: prevState.file.map((file, key) => key == index ?  { file: fileDetail, error: 1 }  : file),
      }))
    }
  }
  toggleReadMore=()=>{
    this.setState(prevState => ({
      ...prevState,
      readMoreFlag: !prevState.readMoreFlag,
    }));
  }




  render(){
    const { extractFileList, mlLoader, modalStatus, progressPercentage } = this.props;
    const { file } = this.state;
      return(
        <div className="generate-header-outer-wrap" style={{marginTop: '94px'}}>
          <Header  type="brief" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          <ContractSideBar application="Automated Content Extractor" type="brief" params={this.props.params} />
          <div onDrop={(evt) =>{evt.stopPropagation();evt.preventDefault()}} onDragOver={(evt) =>{evt.stopPropagation();evt.preventDefault()}}>
          { mlLoader ? <ListLoader/> : null}
          {
           extractFileList.length ?
            <div className="mluploaded-file data-log-list">
            <FileDrop confirmModal={this.confirmModal} confirmClickModal={this.confirmClickModal} contractSlug={this.props.params} type="listUpload" />
            <p className="notice-info" style={{marginLeft:'5px',marginRight:'5px'}}>
              <img className="caution-img" src={imgStoragePath+"caution_sign.png"} alt="img"/>
              <span>CAUTION!</span> Please read the instructions for the optimum results
              <Link onClick={()=>this.toggleReadMore()} className="pull-right">Read More &rarr;</Link>
            </p>
            <h4 className="para">Log History</h4>
            { extractFileList.map((item,index) => <FileList  deleModalFn ={this.props.deleModalFn} deleteFile={this.props.deleteFileFn}   key={index} fileInfo={item} mlLoader={mlLoader} />)}
           </div> :  null
          }

           {
            !extractFileList.length && !mlLoader ?
            <div className="mluploaded-file data-log-list" >
           <FileDrop confirmModal={this.confirmModal} confirmClickModal={this.confirmClickModal} contractSlug={this.props.params} type="newUpload"/>
            <p className="notice-info" style={{marginLeft:'5px',marginRight:'5px',marginTop:'-150px'}}>
              <img className="caution-img" src={imgStoragePath+"caution_sign.png"} alt="img"/>
              <span>CAUTION!</span> Please read the instructions for the optimum results
              <Link onClick={()=>this.toggleReadMore()} className="pull-right">Read More &rarr;</Link>
            </p>
            </div> : null
          }
        </div>

          <Modal size="lg" centered={true} className="mluploaded-file" isOpen={modalStatus} toggle={this.toggle} >
              <ModalBody className="">
                <div>
                  <div className="upload-image-block">
                     <img src={imgStoragePath+"upload _arrow.png"} alt="img"/>
                    <span>You are uploading <b>{file ? file.length: 0}</b> File(s)</span>
                  </div>
                  <div className="">
                    { file ?
                      file.map((fileDetail, index) =>
                          <UploadedFileModal
                            replaceFile = {this.replaceFile}
                            fileDetail={fileDetail}
                            updateField={this.updateForm}
                            key={index}
                            index={index}
                            deleteFn={this.deleteFn}
                            progressPercentage={progressPercentage}/>
                    ): null}
                  </div>
                  { file.length > 2 ? <p style= {{ color: '#ff0000', fontSize: '14px', marginRight:'6px' }}>You can upload only 2 files at a time</p> : file.length > 1 && (file[0].fileType != '' && file[file.length - 1].fileType != '' && file[0].fileType == file[file.length - 1].fileType) ? <p style= {{ color: '#ff0000', fontSize: '14px',  marginRight:'6px'  }}>Both File cannot be same Type</p> : ''}
                </div>
               </ModalBody>
                <div className="confirm-blk">
                  <button className="primary-btn"  disabled = {(file.filter(obj => obj.error && ( obj.error == 1 || obj.error == 2) ).length || file.length > 2 || progressPercentage > 0 || (file.length > 1 && (file[0].fileType != '' && file[file.length - 1].fileType != '' && file[0].fileType == file[file.length - 1].fileType))) ? true : false }   onClick={() => this.uploadMLFile(this.props.params, file)}>Confirm</button>
                  <span className="cancel-modal" onClick={this.toggle}>Cancel</span>
                  <p className="cstm-progress-bar" style={{ width: progressPercentage+ '%' }}></p>
                </div>
            </Modal>
            <EditSidebarIcon
                    title="AI Assistant - Instructions"
                    editSideBar={this.state.readMoreFlag}
                    toggleEditSideBar={() => this.toggleReadMore()}
                >
                  <CautionReadMore
                  contentflag={false}
                  />
              </EditSidebarIcon>
        </div>
      )


  }
}

const mapStateToProps = state => ({
  extractFileList: state.MLFileReducer.extractFileList,
  mlLoader: state.MLFileReducer.mlLoader,
  modalStatus: state.MLFileReducer.modalStatus,
  progressPercentage: state.MLFileReducer.progressPercentage,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    extractFileListFn: (params) => dispatch(extractFileListAc(params)),
    uploadMLFile: (params, file) => dispatch(uploadMLFileAc(params, file)),
    toggleModalFn: (status) => dispatch({
      type: TOGGLE_MODAL,
      payload: status
    }),
    deleModalFn: (status) => dispatch({
      type: TOGGLE_MODAL,
      payload: status
    }),
    deleteFileFn: (params, Fileid) => dispatch(deleteFileAc(params, Fileid))
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(DataLoglist, ['contracts', 'data_extract' ,'R'])));
