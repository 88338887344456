import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Header, ContractHeadertext, EditSidebar, CtrTableHd, ContractSideBar, FormModeType, FieldCol, FieldGroup, FieldLabel, FieldSelect, FieldTextarea } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { Obligation, AddEditObligation } from '../components';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { sAuditLogAc, sGlobalConstantAc, sExportContractsAc }  from '../../../../shared/actionCreators';
import { contrObligationsAc, obligationCrudFnAc, contrAddEditObligationsAc, getApprovedByUserAc, changeObligationStatusAc } from '../actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { OBLIGATION_CRUD, TOGGLE_VIEW_EDIT } from '../actions';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
class Obligations extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      approvalObj: {
        status: '',
        suggestion:''
      },
      id: '',
      error: {}
    }
  }
  componentDidMount(){
    this.props.fetchObligations(this.props.location.query);
    this.props.fetchTechConstant();
    this.props.fetchApprovedByUsers({group_ids: '23', filter_type: 'security_group'})
  }
  toggle = (id=null) => {
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      id: id,
      approvalObj: {
        status: '',
        suggestion:''
      },
      error: {}
    }))
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      approvalObj: {
        ...prevState.approvalObj,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  changeObligationStatus = () => {
    const { approvalObj, id } = this.state;
    if(approvalObj.status){
      if(approvalObj.status == '1'){
        this.props.changeObligationStatusFn(id, {status: '1'})
        this.toggle();
      }else{
        if(approvalObj.status == '2' && approvalObj.suggestion != ''){
          this.props.changeObligationStatusFn(id, approvalObj);
          this.toggle();
        }else{
          this.setState(prevState => ({
            ...prevState,
            error: {
              ...prevState.error,
              suggestion: 'Please enter reason of rejection'
            }
          }));
        }
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          status: 'Please select status'
        }
      }));
    }
  }
  changeMsn = (evt) => {
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/obligations';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }
  }
  render() {
    const { obligationList, assetViewId, obligationCrud, obligationsLoader, auditLogView, techConstants, approvedByUsers, contractSummaryLoader } = this.props;
    const  { modal, approvalObj, error } = this.state;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Contracts" params={this.props.params} pageTitle="Obligations" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar Obligations type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <ContractHeadertext
              title="Obligations"
              desc="Section holds information on lease related Exceptions, Lease Covenants and Conditions Precedent."
              addNew = {getLocalStorageInfo().user.permission['contracts']['parties'].indexOf('C') != -1 ? '+ Add Obligation':''}
              addAudit="Revision History"
              toggleAddEvent = {() => this.props.obligationCrudFn('add', {})}
              toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctobligations', 0, null, 'add', this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Obligations', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Obligation'})}}
           />
          <div className="list-container">
            { obligationsLoader || contractSummaryLoader ? <ListLoader /> : null }
            <div className="table-container">
              <table>
                <CtrTableHd theads={['Type', 'Detail', 'Commercial Value', 'Status', 'Created By', 'Approver']}/>
                <tbody>
                  {obligationList.map((obligation, index) =>
                    <Obligation
                      key={index}
                      item={obligation}
                      viewObligation={() => this.props.obligationCrudFn('view', obligation.id)}
                      changeStatus={() => this.toggle(obligation.id)}
                    />
                  )}
                </tbody>
              </table>
              {!obligationsLoader && !obligationList.length ?
                <NoRecordFound description="Get Started by clicking '+ Add Obligation' button above" />:null
              }
            </div>
          </div>
        </div>
        <EditSidebar
          title={
            obligationCrud.type != '' ?
            (obligationCrud.type == 'add') ? 'Add Obligation':
            (obligationCrud.type == 'edit') ? 'Edit Obligation': 'View Obligation' : ''
            }
          editSideBar={obligationCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
        >
          {Object.keys(obligationCrud.data).length && obligationCrud.flag ?
            <div>
                <FormModeType
                  auditLog={true}
                  auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctobligations', 1, obligationCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Obligations', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Obligation', item_id:obligationCrud.data.id})}}
                  type={obligationCrud.type}
                  changeViewMode={()=>this.props.changeViewMode(obligationCrud.type)}
                  editAble = {obligationCrud.data.status.value != 1 && getLocalStorageInfo().user.permission['contracts']['parties'].indexOf('U') != -1 ? true:false}
                  />
                <AddEditObligation
                  type={obligationCrud.type}
                  obligationObj={obligationCrud.data}
                  addEditObligation={(data, type)=>this.props.addEditObligation(data, type, assetViewId)}
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                  obligationsLoader={obligationsLoader}
                  techConstants={techConstants}
                  approvedByUsers={approvedByUsers}
                />
              </div>:null
            }
        </EditSidebar>
        <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
        >
          {Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
              <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
                {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index}/>
                  ) :
                  auditLogView.data.map((item, index) =>
                  <UpdateAuditLog auditLogView={item} key={index}/>
                  )
                }
              </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
          }
        </EditSidebar>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Obligation Change Status</ModalHeader>
          <ModalBody style={{minHeight: '200px'}}>
            <div>
              <form>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Status<span className="reqStar">*</span></FieldLabel>
                    <FieldSelect
                      placeholder='Select Status'
                      value={approvalObj.status}
                      type='edit'
                      keyParam="status"
                      options={[{label:'Approve', value: '1'}, {'label': 'Reject', value: '2'}]}
                      labelKey= 'label'
                      valueKey='value'
                      updateField={this.updateForm}
                    />
                  </FieldGroup>
                  <h6 className="error-msg">{error.status}</h6>
                </FieldCol>
                { approvalObj.status == '2' ?
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Reason of Rejection<span className="reqStar">*</span></FieldLabel>
                      <textarea
                        style={{width: '100%', display: 'block', borderBottom: '1px solid #8e8e8e', height: '65px', resize: 'none'}}
                        value={approvalObj.suggestion}
                        onChange={(e) => this.updateForm('suggestion', e.target.value)}
                        >
                      </textarea>
                    </FieldGroup>
                    <h6 className="error-msg">{error.suggestion}</h6>
                  </FieldCol>:null
                }
              </form>
            </div>

          </ModalBody>
          <ModalFooter>
            <button className="primary-btn" onClick={() => this.changeObligationStatus()}>Change</button>
            <span style={{color: '#2f8cff', fontSize: '12px', padding: '0 7px', cursor: 'pointer'}} onClick={this.toggle}>Cancel</span>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  obligationList: state.ContrObligationsReducer.obligationList,
  obligationCrud: state.ContrObligationsReducer.obligationCrud,
  obligationsLoader: state.ContrObligationsReducer.obligationsLoader,
  approvedByUsers: state.ContrObligationsReducer.approvedByUsers,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  contractSummaryLoader:state.sharedReducers.contractSummaryLoader,
  assetViewId: state.userAssetReducer.assetViewId
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchTechConstant : () => dispatch(sGlobalConstantAc({constant_types:['obligation_type', 'obligation_sub_type', 'obligation_status']})),
    fetchObligations : (query) => dispatch(contrObligationsAc(ownProps.params, query)),
    fetchApprovedByUsers : (query) => dispatch(getApprovedByUserAc(ownProps.params, query)),
    changeObligationStatusFn: (id, data) => dispatch(changeObligationStatusAc(ownProps.params, id, data)),
    addEditObligation: (data, type, assetViewId) => dispatch(contrAddEditObligationsAc(ownProps.params,data, type, assetViewId)),
    obligationCrudFn: (type, id) => dispatch(obligationCrudFnAc(ownProps.params, type, id)),
    toggleEditBar: (data) => dispatch({ type: OBLIGATION_CRUD, payload: data }),
    changeViewMode: (type) => dispatch({type: TOGGLE_VIEW_EDIT,payload: type}),
    toggleAuditlogFn: (application, content_type, action, objectId, type, paramValue=null, paramKey=null ) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramValue, paramKey)),
    closeAuditSidebar: (data) => dispatch({ type: S_AUDITLOG_ADD_DEL_VIEW, payload: data}),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Obligations, ['contracts', 'parties', 'R'])));
