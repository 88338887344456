import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addSecurityDeposit, addCashTx, addNonCashTx } from '../../contractAddObject';
export const securityDpListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.SECURITY_DEPOSIT_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/security-deposits/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.SECURITY_DEPOSIT_LIST,
          payload: response.data.data
        });
      }
      dispatch({
        type: actions.SECURITY_DEPOSIT_LOADER,
        payload: false
      });
    })
  }
}

export const securityDepositCrudFnAc = (params, type, id, actionType) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.SECURITY_DEPOSIT_CRUD,
        actionType: actionType,
        payload: {type: type, data:(actionType == 'securityDepositCrud' ?  addSecurityDeposit : actionType == 'cashTxCrud' ? {...addCashTx, ...id} : {...addNonCashTx, ...id}) , flag: true }
      });
    }else{
      dispatch({
        type: actions.SECURITY_DEPOSIT_LOADER,
        payload: true
      });
      let url = '';
      if(actionType == 'securityDepositCrud'){
        url = 'contracts/'+params.contract_slug+'/security-deposits/' + id + '/'
      }else{
        url = 'contracts/'+params.contract_slug+'/security-deposit/' + id.security_deposit_id + '/transactions/'+id.id+'/'
      }
      globalGetService(url,{})
      .then(response => {
        dispatch({
          type: actions.SECURITY_DEPOSIT_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.SECURITY_DEPOSIT_CRUD,
            actionType: actionType,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}

export const addEditSecurityDpAc = (params, data, type, module, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/security-deposits/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.SECURITY_DEPOSIT_ADD,
            payload: response.data.data
          });
          dispatch({
            type: actions.SECURITY_DEPOSIT_CRUD,
            actionType: 'securityDepositCrud',
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Security Deposit`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: module,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/security-deposits/' + data.id  +'/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.SECURITY_DEPOSIT_EDIT,
            payload: response.data.data
          });
          dispatch({
            type: actions.SECURITY_DEPOSIT_CRUD,
            actionType: 'securityDepositCrud',
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Subleases`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: module,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const addEditCashTxAc = (params, data, type, module, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/security-deposit/'+data.security_deposit_id+'/transactions/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.CASH_TRANSACTIONS_ADD,
            payload: response.data.data
          });
          dispatch({
            type: actions.SECURITY_DEPOSIT_CRUD,
            actionType: data.type_of_transaction == 1 ? 'cashTxCrud':'nonCashTxCrud',
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Security Deposit`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: module,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/security-deposit/' + data.security_deposit_id + '/transactions/'+data.id+'/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.CASH_TRANSACTIONS_EDIT,
            payload: response.data.data
          });
          dispatch({
            type: actions.SECURITY_DEPOSIT_CRUD,
            actionType: data.type_of_transaction == 1 ? 'cashTxCrud':'nonCashTxCrud',
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }
  }
}

export const securitydepositeMlAc = (params) => {
  return (dispatch) => {
    globalGetService('ml/contract/'+params.contract_slug+'/data-points/?feature=ctsecuritydeposits', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ML_SECURITY_DEPOSIT_DATA,
          payload: response.data.data
        });
      }
    })

  }
}
