import React, { Component } from 'react';
import { imgStoragePath } from '../../../constants';


class EngineEditableTableContent extends Component {
	render(){
		const { content } = this.props;
 		return(

			<tr>
				<td>24-00</td>
				<td>INTEGRATED DRIVE </td>
				<td>761574/4B</td>
				<td>761574/4B</td>
				<td>Test Text</td>
				<td>
					<img width="12" className="" src={imgStoragePath+"delete_red.png"} />
					</td>
		</tr>
		)
	}
}

export default EngineEditableTableContent;
