import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements'

export default class LavTab extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { lavatory, index, asset_slug } = this.props;
    const lavObj = [
      {value: lavatory.name ? lavatory.name : '--'},
      {value: lavatory.location && lavatory.location.label ? lavatory.location.label : '--'},
      {value: lavatory.part_number ? lavatory.part_number : '--'},
      {value: lavatory.serial_number ? lavatory.serial_number : '--'},
      {value: lavatory.manufacturer ? lavatory.manufacturer : '--'},
      {value: lavatory.remarks ? lavatory.remarks : '--'} 
    ]
    
    return (
      <tr onClick={() => this.props.viewLavatory(asset_slug, lavatory.id, index, 'lavatoryView')}>
        {lavObj.map((lavatory,index) =>
        <ContrListCol key={index} colObj={lavatory}   />
        )}
    </tr>
    )
  }
}
