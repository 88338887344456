import { getLocalStorageInfo } from '../../../../utils';
import { globalGetService, globalPostService, globalPutService, globalExportService, globalDownloadService, globalFileUploadService, globalDeleteService } from '../../../../globalServices';
import { toastFlashMessage, downloadFileType } from '../../../../utils';
import { sEngineTypeAc } from '../../../../shared/actionCreators';
import { addCrewSeat,addLavatory,addPassengerSeat,addGalley,addEquipment,addAvionic,addApu,addLg,addEngine, addlopa } from '../createTechSummary';
import * as actions from '../actions';
import { browserHistory } from 'react-router'
import { trackActivity } from '../../../../utils/mixpanel'
const actionUrl=[
  {type:'crewSeatCrud',url:'crew-seating',list:'seatingList',dataObject:addCrewSeat},
  {type:'lavatoryCrud',url:'lavatories',list:'lavatoryList',dataObject:addLavatory},
  {type:'passengerSeatCrud',url:'passenger-seating',list:'paxSeatList',dataObject:addPassengerSeat},
  {type:'galleyCrud',url:'galley',list:'galleyList',dataObject:addGalley},
  {type:'equipmentCrud',url:'cabin-equipments',list:'equipmentList',dataObject:addEquipment},
  {type:'lopacrud',url:'lopa-history',list:'lopaHistoryList',dataObject:addlopa},
  {type:'avionicCrud',url:'avionics',list:'avionicList',dataObject:addAvionic},
  {type:'apuCrud',url:'apu',list:'apuList',dataObject:addApu},
  {type:'lgCrud',url:'landing-gear',list:'lgList',dataObject:addLg},
  {type:'engineCrud',url:'engine',list:'engineList',dataObject:addEngine},
  {type:'propellerCrud',url:'propeller',list:'propellerList',dataObject:addEngine},
]

export const deleteMsnAssetAc = (params, engineId, assetType) =>{
  let slug=actionUrl.filter(url => url.url==assetType);
  return(dispatch)=>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalDeleteService(`console/aircraft/${params.aircraft_slug}/${assetType}/${engineId}/`)
    .then(response => {
      if(response.data.statusCode === 200){
        dispatch(fetchTechSummaryListAc(params.aircraft_slug, slug[0].list))
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
      }
    })
  }
}

export const overviewListAc = (aircraft_slug) => {
  return (dispatch) => {
    dispatch({
      type: actions.OVERVIEW_TECH_INFO,
      payload:{}
    })
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalGetService('console/aircraft-overview/'+aircraft_slug+'/', {})
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.OVERVIEW_TECH_INFO,
          payload:response.data.data
        });
        trackActivity('Page Visited', {
    			application_module: 'Console',
    			asset: response.data.data.msn ? `MSN ${response.data.data.msn}` : `ESN ${response.data.data.esn}`,
    			page_title: 'Technical Specification'
    		})
        dispatch(sEngineTypeAc({aircraft_type: response.data.data.aircraft_type.id}));
        dispatch(aircraftMaintenanceAc(response.data.data.aircraft_type.id));
      }
    })
  }
}
export const addEditEngineLLPAc = (engineId, data, assetType, params, llpType) => {
  let url = 'console/engine/'+engineId+'/engine-llp/'
  if(llpType === 2){
    url = 'console/engine/'+engineId+'/engine-llp-secondary/'
  }
  if(['fan', 'hpc', 'hpt', 'lpt'].includes(params.type)){
    url = `console/${params.type}/${engineId}/llp/`
  }
  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalPutService(url,data)
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LLP_EDIT_SUCCESS,
          payload: { data : response.data.data, engineId: engineId }
        })
        dispatch({
          type: actions.LLP_UPDATE_FIELD_TOGGLE,
          payload: false
        })
        if(params.type == 'aircraft'){
          dispatch(fetchTechSummaryListAc(params.aircraft_slug, 'engineList'))
        }else{
          dispatch(EngineDetailAc(params, 'engineDetails'))
        }
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
export const addEditModuleLLpAc = (params,data) => {
  return (dispatch) => {
    return dispatch({
      type:actions.TECHSPEC_LOADER,
      payload:true
    });
    globalPutService(`console/${params.type}/${data.id}/llp/`, data)
    .then(response => {
      return dispatch({
        type:actions.TECHSPEC_LOADER,
        payload:false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LLP_EDIT_SUCCESS,
          payload: { data : response.data.data, engineId:data.id}
        })
        dispatch({
          type: actions.LLP_UPDATE_FIELD_TOGGLE,
          payload:false
        })
        dispatch(EngineDetailAc(params, 'engineDetails'))
      }
    })
  }


}
export const viewLLPInfoAc = (engineId, id, type) => {
  return (dispatch) => {
    globalGetService('console/engine/'+engineId+'/engine-llp/'+id+'/', { })
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LLP_VIEW_INFO,
          payload: {
            res: response.data.data,
            type:'view',
            flag: true,
            engineId: engineId
          }
        })
      }
    })
  }
}
export const overviewEditAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalPutService(`console/aircraft-overview/${data.slug}/`, data)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.OVERVIEW_TECH_INFO,
            payload:response.data.data
          });
          dispatch(overviewListAc(data.slug))
          toastFlashMessage(response.data.message, 'success');
        }
      })

  }
}
export const disclaimerEditAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    dispatch({
      type: actions.OVERVIEW_TECH_INFO,
      payload:{}
    });
    globalPutService('console/aircraft-disclaimer/'+ data.slug + '/', {technical_disclaimer: data.technical_disclaimer, marketing_disclaimer: data.marketing_disclaimer})
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.OVERVIEW_TECH_INFO,
            payload:response.data.data
          });
          toastFlashMessage(response.data.message, 'success');
        }
      })

  }
}
export const exportTechSummeryAc = (param,aircraft,type) => {
  let name='';
  if(type=='aircraft'){
    name='MSN '+aircraft.msn+'_overview_summary.';
  }else if(type=='engine'){
    name='ESN '+aircraft.esn+'_overview_summary.';
  }else if(type=='apu'){
    name='APU '+aircraft.serial_number+'_overview_summary.';
  }else if(type=='lg'){
    name='LG '+ aircraft.serial_number+'_overview_summary.';
  }else if(type=='propeller'){
    name='PROPELLER '+aircraft.serial_number+'_overview_summary.';
  }

  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalExportService('console/'+type+'/'+aircraft.slug+'/export-tech-summary/',{download: 'pdf'})
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(['fan', 'hpc', 'lpt', 'hpt'].includes(type)){
        downloadFileType(response, `ESN ${aircraft.esn}_overview_summary.` , 'pdf')
      }else {
        downloadFileType(response, name,'pdf')
      }

    });
  }
}
export const exportMarkeTechSummeryAc = (param,aircraft) => {
  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalExportService('console/aircraft/'+aircraft.slug+'/export-tech-marketing-summary/',{download: 'pdf'})
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      downloadFileType(response, 'MSN'+aircraft.msn+'_marketing_summary.', 'pdf')
    });
  }
}

export const exportLLPsAc = (param,engine, llpType) => {
  let url = 'console/engine/'+engine.slug+'/llp/export/'
  if(llpType === 2){
    url = 'console/engine/'+engine.slug+'/llp-secondary/export/'
  }

  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalExportService(url,{download: 'xls'})
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.type === 'application/json'){
        toastFlashMessage('LLP data not found', 'error')
      }else{
        downloadFileType(response, llpType === 1 ? `ESN ${engine.esn} Primary LLP List.` : `ESN ${engine.esn} Secondary LLP List.`, 'xls')
      }
    });
  }
}
export const exportModuleLLpsAc = (params, module) => {
  return (dispatch) => {
    dispatch({
      type:actions.TECHSPEC_LOADER,
      payload:true
    });
    globalExportService(`console/${params.type}/${params.aircraft_slug}/llp/export/`, {download:'xls'})
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      })
      if(response.type == 'application/json'){
        toastFlashMessage("LLP data not found", 'error')
      }else {
        downloadFileType(response, `${module.esn}_LLP_LIST.`, 'xls')
      }
    })
  }
}


export const importLLPsAc = (params, file, engine_slug, llpType)=> {
  let url = `console/engine/${engine_slug}/llp/import/`
  if(llpType === 2){
    url = `console/engine/${engine_slug}/llp-secondary/import/`
  }
  return(dispatch) => {
    if(['xls','xlsx'].includes(file[0].name.split('.').reverse()[0].toLowerCase())){
      let formData = new FormData()
      formData.append('file',file[0])
      dispatch({
        type:actions.TECHSPEC_LOADER,
        payload: true
      })
      globalFileUploadService(url, formData)
      .then(response => {
        dispatch({
          type:actions.TECHSPEC_LOADER,
          payload: false
        })
        if(response.data.statusCode == 200 && (response.data.error_message == undefined || response.data.error_message == null || response.data.error_message == '')){
          dispatch(fetchTechSummaryListAc(params.aircraft_slug, 'engineList'))
          // dispatch({
          //   type: actions.UPDATE_IMPORT_LLP,
          //   engineSlug: engine_slug,
          //   assetType: params.type,
          //   llpType: llpType == 1 ? 'llp' : 'llp_secondary',
          //   payload: response.data.data
          // });
          toastFlashMessage(response.data.message, 'success');
        }else{
          toastFlashMessage(response.data.error_message, 'error');
        }
      })
    }else{
      toastFlashMessage('We are accepting only xls & xlsx format only', 'error');
    }
  }
}

export const aircraftMaintenanceAc = (id) => {
  return (dispatch) => {
    if(id){
      globalGetService('console/aircraft-type/' + id + '/events/', { })
      .then(response => {
        if(response.data.statusCode == 200){
          let maintanenceList = [];
          const data = response.data.data;
          Object.keys(data).map((item,index) => {
            maintanenceList.push({label: data[item].replace(/,/g, '/') + '', value: item})
          });
          dispatch({
            type: actions.AIRCRAFT_MAIN_PROG,
            payload: maintanenceList
          });
        }
      })
    }else{
      dispatch({
        type: actions.AIRCRAFT_MAIN_PROG,
        payload: []
      });
    }
  }
}

export const fetchTechSummaryListAc = (asset_slug, type,query) => {
  let slug=actionUrl.filter(url => url.list==type);
  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalGetService('/console/aircraft/'+asset_slug+'/'+slug[0].url+'/', query)
    .then(response => {
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECH_SMRY_LIST,
          payload: {
            res: response.data.data,
            type: type
          }
        })
      }
    })
  }
}
export const addEditLopaDetailsAc = (params, data, file,type, viewType,operation) => {
  var formdata = new FormData();
  formdata.append('remarks', data.remarks?data.remarks:'');
  formdata.append('file_name', data.file_name?data.file_name:'');
  if(file[0]!=null){
  formdata.append('image',file[0]);
  }

  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    if(operation == 'add'){
      globalPostService('console/aircraft/'+params.aircraft_slug+'/lopa-history/', formdata)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.TECH_SMRY_ADD_SUCCESS,
            payload: {
              type: type,
              viewType: viewType,
              res: response.data.data
            }
          });

          toastFlashMessage(response.data.message, 'success');
        }
      })
    }else{
      globalPutService('console/aircraft/'+params.aircraft_slug+'/lopa-history/'+data.id+'/', formdata)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.TECH_SMRY_EDIT_SUCCESS,
            payload: {res: response.data.data, type, viewType}
          });

          toastFlashMessage(response.data.message, 'success');
        }
      })
    }

  }
}


export const updateProperllerAc = (params, data, operation, type, viewType) => {
  delete data.lessor
  // if(data.off_wing_detail && Object.keys(data.off_wing_detail).length === 0){
  //   delete data.off_wing_detail
  // }
  return (dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    if(operation === 'add'){
      globalPostService('/console/aircraft/'+params.aircraft_slug+'/propeller/' , data)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.TECH_SMRY_EDIT_SUCCESS,
            payload: {res: response.data.data, type, viewType}
          });
          dispatch(fetchTechSummaryListAc(params.aircraft_slug, type))
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }else{
      globalPutService('/console/aircraft/'+params.aircraft_slug+'/propeller/'+data.slug+'/' , data)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.TECH_SMRY_EDIT_SUCCESS,
            payload: {res: response.data.data, type, viewType}
          });
          dispatch(fetchTechSummaryListAc(params.aircraft_slug, type))
          toastFlashMessage(response.data.message, 'success');
        }
      })
      .catch(error => {
        dispatch(fetchTechSummaryListAc(params.aircraft_slug, type))
      })
    }
  }
}

export const addEditTechSummaryAc = (params, data, operation, type, viewType) => {
  let interiorSlug=actionUrl.filter(url => url.type==viewType);
  return (dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    if(operation == 'add'){
      globalPostService('/console/'+params.type+'/'+params.aircraft_slug+'/'+interiorSlug[0].url+'/', data)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.TECH_SMRY_ADD_SUCCESS,
            payload: {
              type: type,
              viewType: viewType,
              res: response.data.data
            }
          });
          dispatch(fetchTechSummaryListAc(params.aircraft_slug, type))
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }else{
      globalPutService('/console/aircraft/'+params.aircraft_slug+'/'+interiorSlug[0].url+'/'+data.id+'/' ,data)
      .then(response => {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.TECH_SMRY_EDIT_SUCCESS,
            payload: {res: response.data.data, type, viewType}
          });
          dispatch(fetchTechSummaryListAc(params.aircraft_slug, type))
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }
  }
}


export const EngineDetailAc = (params, type) => {
  return(dispatch) => {
    globalGetService(`/console/${params.type}/${params.aircraft_slug}/details/`, {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
        type: actions.TECH_SMRY_LIST,
        payload: {
          res: response.data.data,
          type: type
        }
      });
    }
    })
  }
}

export const EngineUpdateAc = (engineData) => {
  return(dispatch) => {
    dispatch({
      type:actions.TECHSPEC_LOADER,
      payload:true
    })
    globalPutService('/console/engine/'+engineData.slug+'/details/', engineData)
    .then(response => {
      dispatch({
        type:actions.TECHSPEC_LOADER,
        payload:false
      })
      if(response.data.statusCode == 200){
      dispatch({
        type: actions.UPDATE_ENGINE_DETAILS,
        payload: response.data.data
      });
      toastFlashMessage(response.data.message, 'success');
      setTimeout(() => {
        window.location.reload(false)
      },500)
    }
    })
  }
}
export const editModuleAc = (params, data) => {
  let payload = data
    payload = {
      ...data,
      region:data.region ? data.region :'',
      aircraft:data.aircraft ? data.aircraft.id : '',
      engine_type: data.engine_type ? data.engine_type.id : '',
      portfolio: data.portfolio ? { id: data.portfolio} : ''
    }
    delete payload.portfolio_id
  return(dispatch) => {
    dispatch({
      type:actions.TECHSPEC_LOADER,
      payload:true
    })
    globalPutService(`/console/${params.type}/${data.slug}/details/`, payload)
    .then(response => {
      dispatch({
        type:actions.TECHSPEC_LOADER,
        payload:false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UPDATE_ENGINE_DETAILS,
          payload:response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
export const updateDisclaimerAc = (params,data) => {
  return(dispatch) => {
    dispatch({
      type:actions.TECHSPEC_LOADER,
      payload:true
    })
    globalPutService(`/console/${params.type}/${data.slug}/details/`, data)
    .then(response => {
      dispatch({
        type:actions.TECHSPEC_LOADER,
        payload:false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UPDATE_ENGINE_DETAILS,
          payload:response.data.data
        });
        toastFlashMessage('Technical Disclaimer updated successfully', 'success');
        setTimeout(() => {
          window.location.reload(false)
        }, 500)
      }
    })
  }
}


export const techSummaryCrudAc = (params,type,list,actionType,dataObject, assetType) => {
  let interiorSlug=actionUrl.filter(url => url.type==actionType);
  return (dispatch) => {
    if(assetType == 'engine'){
      dispatch({
        type: actions.TECH_SMRY_CRUD,
        actionType:actionType,
        payload:{
          data:list,
          type:type,
          flag:true
        }
      })
    }else{
      if(type=='add'){
        dispatch({
          type:actions.TECH_SMRY_CRUD,
          actionType:actionType,

          payload:{
            data:dataObject,
            type:type,
            flag:true
          }
        })
      }else {
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: true
        });
        globalGetService('/console/aircraft/'+params.aircraft_slug+'/'+interiorSlug[0].url+'/'+list.id+'/', { })
        .then(response => {
          dispatch({
            type: actions.TECHSPEC_LOADER,
            payload: false
          });
          if(response.data.statusCode == 200){
            dispatch({
              type:actions.TECH_SMRY_CRUD,
              actionType:actionType,
              payload:{
                data:response.data.data,
                type:type,
                flag:true
              }
            })
          }
        })
      }
    }

  }
}

export const getAssetTranferUsageAc = (params) =>{
  return(dispatch) => {
    globalGetService(`/console/license/asset-data-transfer/usage/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ASSET_TRANSFER_USAGE,
          payload: response.data.data
        })
      }
    })
  }
}

export const transferAssetAc = (params, applications, asset) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalDownloadService(`/console/${params.type}/${params.aircraft_slug}/get-data-transfer-file/`, applications)
    .then(response => {
        dispatch({
          type: actions.ASSET_TRANSFER_MODAL,
          payload: false
        })
        if(!response.data.statusCode){
          let fileName = asset.msn ? `MSN_${asset.msn}_private_key.` : `ESN_${asset.esn}_private_key.`
          downloadFileType(response.data, fileName, 'txt')
          browserHistory.push('/assets-listing')
        }
      dispatch(getAssetTranferUsageAc())
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
    })
  }
}

export const getApuDetailsAc = (params) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_APU_LOADER,
      payload: true
    });
    dispatch({
      type: actions.APU_DETAIL,
      payload: {}
    })
    globalGetService(`/console/apu/${params.aircraft_slug}/`)
    .then(response =>{
      dispatch({
        type: actions.TECHSPEC_APU_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_DETAIL,
          payload: response.data.data
        })
        trackActivity('Page Visited', {
    			application_module: 'Console',
    			asset: `APU ${response.data.data.serial_number}`,
    			page_title: 'Technical Specification'
    		})
      }
    })
  }
}

export const editApuAc = (apu) =>{
  let payload = apu
  return(dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalPutService(`/console/apu-update/${apu.slug}/`, payload)
    .then(response =>{
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.APU_DETAIL,
          payload: response.data.data
        })
        dispatch({
          type:actions.TECH_SMRY_CRUD,
          payload:{type: 'edit',data: response.data.data, flag: false},
          actionType: 'apuCrud'
        })
      }
    })
  }
}
export const editApuDisclaimerAc = (apu) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalPutService(`/console/apu-disclaimer/${apu.slug}/`, {technical_disclaimer: apu.technical_disclaimer})
    .then(response =>{
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        // dispatch({
        //   type: actions.APU_DETAIL,
        //   payload: response.data.data
        // })
        dispatch({
          type:actions.TECH_SMRY_CRUD,
          payload:{type: 'edit',data: response.data.data, flag: false},
          actionType: 'apuCrud'
        })
      }
    })
  }
}
export const getLgDetailsAc = (params) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    dispatch({
      type: actions.APU_DETAIL,
      payload: {}
    })
    globalGetService(`/console/${params.type == 'landing-gear' ? 'lg' : params.type}/${params.aircraft_slug}/`)
    .then(response =>{
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_DETAIL,
          payload: response.data.data
        })
        trackActivity('Page Visited', {
    			application_module: 'Console',
    			asset: `${(params.type === "landing-gear" || params.type === "lg") ? "LG" : "Propeller"} ${response.data.data.serial_number}` ,
    			page_title: 'Technical Specification'
    		})
      }
    })
  }
}

export const editLgAc = (params, lg) =>{
  let payload = lg
  return(dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    let slug = lg.slug
    delete lg.slug
    globalPutService(`/console/${params.type}-update/${slug}/`, payload)
    .then(response =>{
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.APU_DETAIL,
          payload: response.data.data
        })
        dispatch({
          type:actions.TECH_SMRY_CRUD,
          payload:{type: 'edit',data: response.data.data, flag: false},
          actionType: 'lgCrud'
        })
      }
    })
  }
}


export const saveRemarksAc = (data, params) => {
  return (dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    if(data.type === 'add'){
      globalPostService(`console/${params.type}/${params.aircraft_slug}/remarks/`,{remarks: data.data.remarks})
      .then(response =>{
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.REMARKS_CRUD,
            payload: {
              flag: false,
              type: 'add',
              data: {
                remarks: ''
              }
            }
          })
          dispatch(getRemarksListAc(params))
          toastFlashMessage('Added Remarks successfully', 'success')
        }
      })
    }else{
      globalPutService(`console/${params.type}/${params.aircraft_slug}/remarks/${data.data.id}/`,{remarks: data.data.remarks})
      .then(response =>{
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.REMARKS_CRUD,
            payload: {
              flag: false,
              type: 'add',
              data: {
                remarks: ''
              }
            }
          })
          dispatch(getRemarksListAc(params))
          toastFlashMessage('Updated Remarks successfully', 'success')
        }
      })
    }
  }
}

export const getRemarksListAc = (params) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalGetService(`/console/${params.type}/${params.aircraft_slug}/remarks/`)
    .then(response =>{
      dispatch({
        type: actions.TECHSPEC_LOADER,
        payload: false
      });
      if(response.data.statusCode === 200){
        dispatch({
          type:actions.REMARKS_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const deleteRemarkAc = (params, remark) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalDeleteService(`/console/${params.type}/${params.aircraft_slug}/remarks/${remark.id}/`)
    .then(response =>{
      if(response.data.statusCode === 200){
        dispatch(getRemarksListAc(params))
      }
    })
  }
}

export const exportApuAc = (params, apu_slug) => {
  return (dispatch) => {
    globalExportService(`/console/apu/${apu_slug}/llp/export/`, {download:'xls'})
      .then(response => {
        downloadFileType(response, 'APU_llp_list.', 'xls')

      })
  }
}
export const exportLgLLpsAc = (params, lg_slug) => {
  return (dispatch) => {
    globalExportService(`console/lg/${lg_slug}/llp/export/`, {download:'xls'})
      .then(response => {
        downloadFileType(response, 'Lg_LLP_list.', 'xls')
      })
  }
}
export const exportPropellerLLpsAc = (params, propeller_slug) => {
  return (dispatch) => {
    globalExportService(`console/propeller/${propeller_slug}/llp/export/`, {download:'xls'})
      .then(response => {
        downloadFileType(response, 'Propeller_LLP_list.','xls')
      })
  }
}

export const importApuLLPsAc = (params, file, apu_slug) => {
  return (dispatch) => {
    if(['xls','xlsx'].includes(file[0].name.split('.').reverse()[0].toLowerCase())) {
      let formData = new FormData()
      formData.append('file',file[0])
      globalFileUploadService(`console/apu/${apu_slug}/llp/import/`, formData)
        .then(response => {
          if(response.data.statusCode == 200) {
            dispatch({
              type:actions.IMPORT_APU_LLP,
              payload:response.data.data,
              apuSlug:apu_slug,
              assetType:params.type
            })
            toastFlashMessage(response.data.message, 'success')
          }

        })
    }else {
      toastFlashMessage('We are accepting only xls & xlsx format only', 'error');
    }
  }
}
export const importLgLLPsAc = (params, file, lg_slug) => {
  return (dispatch) => {
    if(['xls', 'xlsx'].includes(file[0].name.split('.').reverse()[0].toLowerCase())) {
      let formData = new FormData()
      formData.append('file', file[0])
      globalFileUploadService(`console/lg/${lg_slug}/llp/import/`, formData)
        .then(response => {
          if(response.data.statusCode == 200) {
            dispatch({
              type:actions.IMPORT_LG_LLP,
              payload:response.data.data,
              lgSlug:lg_slug,
              assetType:params.type
            })
            toastFlashMessage(response.data.message, 'success')
          }
        })
    }
  }
}
export const importPropellerLLPsAc = (params, file,propeller_slug) => {
  return(dispatch) => {
    if(['xls', 'xlsx'].includes(file[0].name.split('.').reverse()[0].toLowerCase())) {
      let formData = new FormData()
      formData.append('file', file[0])
      globalFileUploadService(`console/propeller/${propeller_slug}/llp/import/`, formData)
        .then(response => {
          if(response.data.statusCode == 200) {
            dispatch({
              type:actions.IMPORT_PROPELLER_LLP,
              payload:response.data.data,
              propellerSlug:propeller_slug,
              assetType:params.type
            })
            toastFlashMessage(response.data.message, 'success')
          }
          setTimeout(()=>{
            window.location.reload(false)
          }, 1000)
        })
    }
  }
}

export const deleteApuLLPsAc = (params,apu_slug, llp_id ) => {
  return(dispatch) => {
    globalDeleteService(`console/apu/${apu_slug}/llp/${llp_id}/`)
      .then(response => {
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success')
        }
        setTimeout(()=> {
          window.location.reload(false)
        }, 1000)
      })
  }
}

export const deleteLgLLPAc = (params, lg_slug, llp_id) => {
  return(dispatch) => {
    globalDeleteService(`console/lg/${lg_slug}/llp/${llp_id}/`)
      .then(response => {
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success')
        }
        setTimeout(()=>{
          window.location.reload(false)
        }, 1000)
      })
  }
}

export const deletePropellerLLPAc = (params,propeller_slug, llp_id) => {
  return(dispatch)=> {
    globalDeleteService(`console/propeller/${propeller_slug}/llp/${llp_id}/`)
      .then(response => {
        if(response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
        }
        setTimeout(()=>{
          window.location.reload(false)
        }, 1000)
      })
  }
}
export const getTechspecRevisionHistoryAc = (props,id) => {
  let type = props.params.type
  if(['engine', 'hpc', 'hpt', 'lpt', 'fan'].includes(props.params.type)){
    type ='engine'
  }
  if(props.params.type == "lg"){
    type="landinggear"
  }
  return(dispatch) => {
    dispatch({
      type: actions.TECHSPEC_LOADER,
      payload: true
    });
    globalGetService(`audit/console/am${type}/?object_id=${id}&action=1`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.REVISION_HISTORY_TECHSPEC,
          payload:response.data.data
        })
        dispatch({
          type: actions.TECHSPEC_LOADER,
          payload: false
        });
      }
    })
  }
}
export function exportRevisionHistoryAc(props, id) {
  let type = props.params.type
  if(['engine','hpc', 'hpt','lpt','fan'].includes(props.params.type)){
      type ='engine'
  }
  if(props.params.type == "lg"){
    type="landinggear"
  }
  return(dispatch) => {
    globalGetService(`audit/console/am${type}/?object_id=${id}&action=1&download=xls`)
    .then(response => {
      toastFlashMessage(response.data.message, 'success')
    })
  }

  }
