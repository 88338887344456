import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { majorAssemblyWidgetAc, claimTrackerWidgetAc, assetsInfoWidgetAc, exportMntUpcomingEventsAc } from '../actionCreators';
import { MntCollapse, EventCard, DbBackCom, DbClaimList, AssetWidget } from '../components';
import { NoRecordFound,ListLoader } from '../../../shared';
import { assetsType,imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { trackActivity } from '../../../utils/mixpanel';
class MaintenanceFleetDb extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.props.fetchUpcomingEv()
    this.props.fetchClaimTracker();
    this.props.fetchAssetInfo({application: 2});
    trackActivity('Page Visited',{
      application_module: 'Dashboards',
      asset: 'All',
      page_title: 'Maintenance Dashboard'
    })
  }
  render(){
    const { majorAssmeblyWidget, claimsTrackerWidget, upcomingEventLoader, dbAssetLoader, assetsInfoWidget,exportLoader } = this.props;
    let statusOnLease = assetsInfoWidget.filter(asset => asset.status == 1);
    let statusOffLease = assetsInfoWidget.filter(asset => asset.status == 2);
    let statusOnGround= assetsInfoWidget.filter(asset => asset.status == 3);
    let statusWrittenOFF = assetsInfoWidget.filter(asset => asset.status == 4);
    let assetWidgetCard
    if(getLocalStorageInfo().defaultLessor.id === 348){
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'OPERATIONAL',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'AOG', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
      ]
    }else{
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'ON LEASE',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'OFF LEASE', ownedUrl: '/assets-listing?status=2&ownership_type=1',managedUrl: '/assets-listing?status=2&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 2).length, url: '/assets-listing?status=2', classes: 'widget-cards marginRight marginLeft flex-centered off-lease'},
        { title: 'ON GROUND', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
        { title: 'WRITTEN OFF / SOLD', ownedUrl: '/assets-listing?status=4&ownership_type=1',managedUrl: '/assets-listing?status=4&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing?status=4', classes: 'widget-cards marginLeft flex-centered written-off'}
      ]
    }
    var showSplitTabs = false
    assetWidgetCard.map(item => {
      showSplitTabs = (item.managed > 0) ? true : showSplitTabs
      return item
    })
    return(
      <div className="fleet-maintenance-db">
        <DbBackCom pageTitle="Maintenance Dashboard" />
      <Row className="assets-row" style={{marginBottom: '10px'}}>
          { assetWidgetCard.map((asset, index) =>
            <AssetWidget
              className={asset.classes}
              statusGrpTitle={asset.title}
              value={asset.value}
              url={asset.url}
              managedUrl={asset.managedUrl}
              dbAssetLoader={dbAssetLoader}
              dbAssetLoader={dbAssetLoader}
              key={index}
              index={index}
              owned={asset.owned}
              managed={asset.managed}
              showManaged={showSplitTabs}
            />
          )}
        </Row>
        <div className="claim-tracker-cn">
          <Row>
            <Col md="8" className="claim-list">
              <h2>Claims Tracker</h2>
              { !claimsTrackerWidget.claims ?  <p className="flex-centered loading-claim">Loading...</p> :
                <div>
                  <table>
                    <thead>
                      <tr>
                        {['MSN', 'Type', 'Claim Amount', 'Status', 'Payout Approved Amount', 'Payout Status'].map((label,index) => <th key={index}>{label}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      { (claimsTrackerWidget.claims && claimsTrackerWidget.claims.length) ?
                        claimsTrackerWidget.claims.map((claim, index) =>
                        <DbClaimList key={index} claim={claim} />
                      ):null}
                    </tbody>
                  </table>
                  {!(claimsTrackerWidget.claims && claimsTrackerWidget.claims.length) ? <NoRecordFound/>:null}
                </div>
              }
            </Col>
            <Col md="4" className="claim-stats">
              <Row className="row-class">
                {[
                    {title: 'Pending Payout', value: claimsTrackerWidget.pending_payout ? claimsTrackerWidget.pending_payout:0},
                    {title: 'Funds Shortage', value: claimsTrackerWidget.funds_shortage ? claimsTrackerWidget.funds_shortage:0},
                    {title: 'Claims under review', value: claimsTrackerWidget.claim_under_review ? claimsTrackerWidget.claim_under_review:0},
                    {title: 'Pending Approval', value: claimsTrackerWidget.claim_pending_approval ? claimsTrackerWidget.claim_pending_approval:0}
                  ].map((stats, index) =>
                  <Col md="6" className="stats-info">
                    <h6>{stats.value}</h6>
                    <p>{stats.title}</p>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="major-assembly-card">
          <h6>Upcoming Events
            <span style={{marginLeft:'10px',fontSize:'12px',color:'#2F8CFE',cursor:'pointer'}} onClick={()=>this.props.exportMntUpcomingEvents()} ><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/> Export </span>
          </h6>
          { !upcomingEventLoader && (majorAssmeblyWidget.upcomingEvents && !Object.keys(majorAssmeblyWidget.upcomingEvents).length) ? <NoRecordFound /> :null }
          { !majorAssmeblyWidget.upcomingEvents ?  <p className="flex-centered loading-mainataince">Loading...</p> : null }
          { majorAssmeblyWidget.upcomingEvents && Object.keys(majorAssmeblyWidget.upcomingEvents).length ?
            <Row>
              { majorAssmeblyWidget.upcomingEvents && majorAssmeblyWidget.upcomingEvents.airframe ?
                <Col md="3" className="paddingRight">
                  <div className="mj-assembly-card">
                    <h2>Airframe</h2>
                    <div className="mj-assembly-list">
                      {
                        majorAssmeblyWidget.upcomingEvents.airframe.map((event, index) =>
                        <MntCollapse key={index} url={'/' + assetsType[event.asset.asset_type]+'/' +event.asset.slug + '/maintenance/airframe'} type="Event" date={event.eventDate} name={event.name} assetInfo={event.asset} msn={event.asset.msn} exceeding_contract={event.exceeding_contract}>
                          <EventCard type="Event" event={event} />
                        </MntCollapse>
                      )}
                    </div>
                  </div>
                </Col>:null
              }
              { majorAssmeblyWidget.upcomingEvents && majorAssmeblyWidget.upcomingEvents.engine ?
                 <Col md="3" className="paddingRight paddingLeft">
                   <div className="mj-assembly-card">
                     <h2>Engines</h2>
                     <div className="mj-assembly-list">
                       {
                         majorAssmeblyWidget.upcomingEvents.engine.map((event, index) =>
                         <MntCollapse key={index} type="Shop Visit" url={'/' + assetsType[event.asset.asset_type]+'/' +event.asset.slug + '/maintenance/engines'} date={event.eventDate} name={event.name} msn={event.asset.msn} assetInfo={event.asset} exceeding_contract={event.exceeding_contract}>
                           <EventCard type="Shop Visit" event={event} />
                         </MntCollapse>
                       )}
                     </div>
                   </div>
                 </Col>:null
              }
              { majorAssmeblyWidget.upcomingEvents && majorAssmeblyWidget.upcomingEvents.apu ?
                <Col md="3" className="paddingRight paddingLeft">
                  <div className="mj-assembly-card">
                    <h2>APU</h2>
                    <div className="mj-assembly-list">
                      {
                        majorAssmeblyWidget.upcomingEvents.apu.map((event, index) =>
                        <MntCollapse key={index} type="Shop Visit" url={'/' + assetsType[event.asset.asset_type]+'/' +event.asset.slug + '/maintenance/apu'} date={event.eventDate} assetInfo={event.asset} msn={event.asset.msn} exceeding_contract={event.exceeding_contract}>
                          <EventCard type="Shop Visit" event={event} />
                        </MntCollapse>
                      )}
                    </div>
                  </div>
                </Col>:null
              }
              { majorAssmeblyWidget.upcomingEvents && majorAssmeblyWidget.upcomingEvents.lg ?
                <Col md="3" className=" paddingLeft">
                  <div className="mj-assembly-card">
                    <h2>Landing Gears</h2>
                    <div className="mj-assembly-list">
                      {
                        majorAssmeblyWidget.upcomingEvents.lg.map((event, index) =>
                        <MntCollapse key={index} type="Shop Visit" url={'/' + assetsType[event.asset.asset_type]+'/' +event.asset.slug + '/maintenance/landing-gears'} date={event.eventDate} name={event.name} assetInfo={event.asset} msn={event.asset.msn} exceeding_contract={event.exceeding_contract}>
                          <EventCard type="Shop Visit" event={event} />
                        </MntCollapse>
                      )}
                    </div>
                  </div>
                </Col>:null
              }
            </Row>:null
          }
          {exportLoader?<ListLoader/>:null}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  assetsInfoWidget: state.DashboardReducer.assetsInfoWidget,
  majorAssmeblyWidget: state.DashboardReducer.majorAssmeblyWidget,
  claimsTrackerWidget: state.DashboardReducer.claimsTrackerWidget,
  upcomingEventLoader: state.DashboardReducer.upcomingEventLoader,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
  exportLoader: state.DashboardReducer.exportLoader,
})
const mapDispatchToProps  = (dispatch, ownProps) => {
  return {
    fetchAssetInfo :(queryParam) => dispatch(assetsInfoWidgetAc(queryParam)),
    fetchUpcomingEv: () => dispatch(majorAssemblyWidgetAc()),
    fetchClaimTracker: () => dispatch(claimTrackerWidgetAc()),
    exportMntUpcomingEvents: () => dispatch(exportMntUpcomingEventsAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(MaintenanceFleetDb,['console','dashboard','R']));
