import React, { Component } from 'react';
import { Link } from 'react-router';
import { NoRecordFound  } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';

export default class LinkRecords extends Component{
	constructor(props){
		super(props);
		this.state = {
			recordFiles: {},
			fileArr : [],
		}
	}

	componentDidMount(){
		const data = {...this.props.auditLinkrecords}
		this.setState({
			fileArr: [],
			recordFiles : data['list'],
		})
	}
	selectRecords = (fileId) => {
		if(this.state.fileArr.indexOf(fileId)== -1){
			this.setState(prevState => ({
				...prevState,
				fileArr : [...prevState.fileArr, fileId]
			}));
		}else{
			this.setState(prevState => ({
				...prevState,
				fileArr : [...prevState.fileArr].filter(data => data != fileId)
			}));
		}
	}

	updateLinkRecords = (e) => {
		e.preventDefault();
		//this.props.toggleEditSideBar();
		this.props.updateLinkRecords(this.props.auditLinkrecords.answer, this.state.fileArr);
	}

	render(){
		const {  auditLinkrecords } = this.props;
		const { recordFiles } = this.state;
		return (
			<div>
				{/* <div>
					<input type="text"/>
					<Link>Search</Link>
				</div> */}
				<form onSubmit={(e) => this.updateLinkRecords(e)}>
					<div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
						<div className="table-height">
							<table className="sidebar-table table-sticky">
							<thead>
							<tr>
								<th>File Name</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								Object.keys(recordFiles).length ?
									recordFiles.map((file, key) => {
										return(
											<tr onClick={() => this.selectRecords(file.id)}>
											<td>
												<h3>
												<img className="img-attachment"  src={imgStoragePath+"pdf_icon.png"} alt="img"/>
												{file.name}
												</h3>
												<p>{file.location}</p>
											</td>
											<td className="text-right">
												<input type="checkbox" checked={this.state.fileArr.indexOf(file.id)!= -1 ? true :false }/>
											</td>
										</tr>
									)
								}) :<td colSpan="2"> <NoRecordFound/></td>
						}
						</tbody>
					</table>
						</div>
					</div>
					<div className="edit-sidebar-footer">
						<ul className="list-inline">
							<li className="list-inline-item">
								<button
								disabled={this.props.auditLoader || !this.state.fileArr.length}
									type="submit" className="btn btn-primary">
									{this.props.auditLoader ? 'Processing...':'Save Changes'}
									</button>
							</li>
							<li className="list-inline-item">
								<Link onClick={this.props.toggleEditSideBar}>
									Cancel</Link>
							</li>
							</ul>
					</div>
				</form>
			</div>
		)
	}
}
