import React, {Component, Fragment} from 'react';
import {Row} from 'reactstrap';
import {Link} from 'react-router';
import { EditSidebar, FieldSelect, FieldCol, FieldGroup, FieldLabel, FieldInput, FieldDate } from '../../../contracts/Elements';
import moment from 'moment'
const onlyNumberic = /^(\s*|\d+)$/;
const numberWithDot = /^[0-9]*(\.\d{0,2})?$/;
class AddLLP extends Component {
  constructor(props){
    super(props)
  }
  render() {
    const {addLLPForm, updateError, llpCrud, addLLP, error, heading} = this.props
    return(
      <form onSubmit={addLLP}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row style={{margin:"10px"}}>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Module</FieldLabel>
                <input
                  type="text"
                  maxLength={50}
                  value={llpCrud.module ? llpCrud.module:''}
                  onChange={(e) => addLLPForm('module', e.target.value)}
                  onFocus={(e) => updateError('module', '')}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">Description <sup className="reqStar"> * </sup> </FieldLabel>
                <input
                  type="text"
                  maxLength={50}
                  value={llpCrud.description ? llpCrud.description:''}
                  onChange={(e) => addLLPForm('description', e.target.value)}
                  onFocus={(e) => updateError('description', '')}
                />
                <h6 className="error-msg">{error.description}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number <sup className="reqStar"> * </sup> </FieldLabel>
                <input
                  type="text"
                  maxLength={50}
                  value={llpCrud.part_number?llpCrud.part_number:''}
                  onChange={(e) => addLLPForm('part_number', e.target.value)}
                  onFocus={(e) => updateError('part_number', '')}
                />
                <h6 className="error-msg">{error.part_number}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">Serial Number <sup className="reqStar"> * </sup> </FieldLabel>
                <input
                  type="text"
                  maxLength={50}
                  value={llpCrud.serial_number?llpCrud.serial_number:''}
                  onChange={(e) => addLLPForm('serial_number', e.target.value)}
                  onFocus={(e) => updateError('serial_number', '')}
                />
                <h6 className="error-msg">{error.serial_number}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">TSN</FieldLabel>
                <input
                  type="text"
                  maxLength={10}
                  value={llpCrud.tsn ? llpCrud.tsn:''}
                  onChange={(e) => { numberWithDot.test(e.target.value) ? addLLPForm('tsn', e.target.value):e.preventDefault()} }
                  onFocus={(e) => updateError('tsn', '')}
                />
              <h6 className="error-msg">{error.tsn}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">TSO</FieldLabel>
                <input
                  type="text"
                  maxLength={10}
                  value={llpCrud.tso?llpCrud.tso:''}
                  onChange={(e) => { numberWithDot.test(e.target.value) ? addLLPForm('tso', e.target.value):e.preventDefault()} }
                  onFocus={(e) => updateError('tso', '')}
                />
              <h6 className="error-msg">{error.tso}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">Cycle Limit </FieldLabel>
                <input
                  type="text"
                  maxLength={10}
                  value={llpCrud.cycles_max_limit ? llpCrud.cycles_max_limit:''}
                  onChange={(e) => { onlyNumberic.test(e.target.value) ? addLLPForm('cycles_max_limit', e.target.value):e.preventDefault()} }
                  onFocus={(e) => updateError('cycles_max_limit', '')}
                />

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">CSN</FieldLabel>
                <input
                  type="text"
                  maxLength={10}
                  value={llpCrud.csn?llpCrud.csn:''}
                  onChange={(e) => { onlyNumberic.test(e.target.value) ? addLLPForm('csn', e.target.value):e.preventDefault()} }
                  onFocus={(e) => updateError('csn', '')}
                />
                <h6 className="error-msg">{error.csn}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group" >
                <FieldLabel className="label">CSO</FieldLabel>
                <input
                  type="text"
                  maxLength={10}
                  value={llpCrud.cso?llpCrud.cso:''}
                  onChange={(e) => { onlyNumberic.test(e.target.value) ? addLLPForm('cso', e.target.value):e.preventDefault()} }
                  onFocus={(e) => updateError('cso', '')}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remaining Cycle</FieldLabel>
                <input
                  type="text"
                  maxLength={10}
                  value={llpCrud.cycles_remaining ? llpCrud.cycles_remaining:''}
                  onChange={(e) => { onlyNumberic.test(e.target.value) ? addLLPForm('cycles_remaining', e.target.value):e.preventDefault() } }
                  onFocus={(e) => updateError('cycles_remaining', '')}
                />
                <h6 className="error-msg">{error.cycles_remaining}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" style={{marginBottom:"40px"}} >
              <FieldGroup className="form-group">
                <FieldLabel className="label">LLPs as of Date </FieldLabel>
                  <FieldDate
                      value={llpCrud.as_of_date ? moment(llpCrud.as_of_date).format('MMM DD, YYYY') : null }
                      type=''
                      keyParam="as_of_date"
                      updateField={addLLPForm}
                   />
              </FieldGroup>
            </FieldCol>
          </Row>
    </div>
    <div className="submit-block" style={{position:"relative", top:'-41px'}} >
      <input type="submit" className="primary-btn" value="Save Changes"/>
      <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
    </div>
  </form>
    )
  }
}

export default AddLLP;
