import React, {Component} from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import AirframeMRRow from '../containers/AirframeMRRow';
import { imgStoragePath, intervalUnitLinking } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
export default class AirframeMRDetail extends Component{
  constructor(props) {
    super(props);
    this.state = { collapse: true };
  }
  onEntered = () => {
    this.setState({ status: 'opened' });
  }
  onExited = ()  => {
    this.setState({ status: 'closed' });
  }
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  render(){
    const airframeProgram = this.props.airframeConst;
    const unitType = airframeProgram[Object.keys(airframeProgram)[0]].interval_type ? intervalUnitLinking[ airframeProgram[Object.keys(airframeProgram)[0]].interval_type]: 'Month';
    const leftArr = [
      {label: 'Fund Date', keyParam: 'fundDate', editTable: true, symbol: '', inputType: 'date'},
      {label: 'Fund Status as of Date', keyParam: 'currentFundValue', editTable: true, symbol: '$', inputType: 'number', prefix: true},
      {label: 'Last Qualified Maintenance Event Date', keyParam: 'lastShopvisitDate', editTable: true, symbol: '', inputType: 'date'},
      {label: 'Next Qualified Maintenance Event Date', keyParam: 'nextShopvisitDate', editTable: true, symbol: '', inputType: 'date'},
    ];
    const middleArr = [
      {label: 'Lower Rate / ' + unitType, keyParam: 'minMR', editTable: false, symbol: '$', inputType: 'number'},
      {label: 'Upper Rate / ' + unitType, keyParam: 'maxMR', editTable: false, symbol: '$', inputType: 'number'},
    ];
    const rightArr = [
      {label: 'Target Rate / ' + unitType, keyParam: 'targetMR', editTable: false, symbol: '$', inputType: 'number'},
      {label: 'Actual Rate / ' + unitType, keyParam: 'actualMR', editTable: true, symbol: '$', inputType: 'number', prefix: true},
      {label: 'MR Rate Escalation', keyParam: 'escalation', editTable: true, symbol: '%', inputType: 'number', prefix: false},
      {label: 'Event Cost Escalation', keyParam: 'cost_escalation', editTable: true, symbol: '%', inputType: 'number', prefix: false}
    ];
    let airframeRows = [];
    if(getLocalStorageInfo().defaultLessor.id != 24){
      airframeRows = [...leftArr, ...middleArr, ...rightArr];
    }else{
      airframeRows = [...leftArr, ...rightArr];
    }


    return (
      <div className="common-mr-card">
        <h6 className="para"  onClick={this.toggle}>
          Airframe Maintenance Events
          <img style={this.state.collapse ? {transform: 'rotate(0deg)'}: {transform: 'rotate(180deg)'}} src={imgStoragePath + "collapse_arw.png"} alt="img"/>
        </h6>
        <Collapse className="airframe-mr-detail"
          isOpen={this.state.collapse}
          onEntered={this.onEntered}
          onExited={this.onExited}
        >
          <Card>
            <CardBody style={{overflow: 'auto'}}>
              <table width="100%" >
                <thead>
                  <tr>
                    <th className="caption-ui">Maintenance Event</th>
                    {Object.keys(airframeProgram).map((program, index) => <th key={index} className="para-ui th-highlight">{airframeProgram[program]['description']} <br/> <span style={{ color: '#8c9ba5', fontWeight:'300', fontSize:'11px'}}> ( Rates as per {airframeProgram[program]['year_of_cost'] ? airframeProgram[program]['year_of_cost'] : '2018 '} )</span></th>)}
                  </tr>
                </thead>
                <tbody>
                  {airframeRows.map((airframeRow,index) => <AirframeMRRow errors={this.props.errors} key={index} airframeRow={airframeRow} updateErrorCode={this.props.updateErrorCode} /> )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Collapse>
      </div>
      )
  }
}
