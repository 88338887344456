import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';


export default class GenralDetail06Wrapper extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { mrSectionDetails,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row>
      <Col sm="4" className="dropdown-margin"  style={{marginBottom: '40px'}}>
         {
            GetInputField(
              'dropdown',
              <label className="for-label">Type Of Report<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
							  value: mrSectionDetails.report_type ? mrSectionDetails.report_type: '',
                error: error && error.report_type ? error.report_type : '',
                placeholder:'Select Report Type',
								labelKey:'label',
                valueKey: 'value',
								options: this.props.techConstants.filter(item => item.type=="mr_type_of_report"),
								updateForm:(value)=>this.props.updateForm({type: 'report_type', value:value, sectionType: 'details'})
              }
            )
          }
          </Col>
         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Report Date<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
               value:mrSectionDetails.report_date,
               error: error && error.report_date ? error.report_date : '',
                updateForm: (value) => this.props.updateForm({ type: 'report_date', value: value, sectionType: 'details'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Report Issued Place<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: mrSectionDetails.report_issued_place ? mrSectionDetails.report_issued_place :'',
              error: error && error.report_issued_place ? error.report_issued_place : '',
               updateForm: (value) => this.props.updateForm({ type:'report_issued_place', value:value, sectionType: 'details'})
              }
            )
          }
         </Col>
      </Row>
    </div>
    );
  }
}
