import React, { Component } from 'react';
import { Link, browserHistory, withRouter } from 'react-router';
import { getLocalStorageInfo } from '../../utils';
class UserManageSideBar extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className="" >
        <div className="mr-calc-sidebar" style={getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.on_trail ? { marginTop : '-54px'} : {marginTop: '-54px'}}>
          <div className="mr-calc-heading">
           <h3>User Management</h3>
          </div>
          <ul className="list-unstyled">
            <li>
              <Link className={this.props.location.pathname.includes('/users/manage/active') ? 'active':'' } to="/users/manage/active">User's List</Link>
            </li>
            <li>
            { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
              getLocalStorageInfo().user.permission.console.user_invite &&
              getLocalStorageInfo().user.permission.console.user_invite.indexOf('R') != -1 ?
              <Link className={this.props.location.pathname.includes('/users/invites/') ? 'active':'' } to="/users/invites/">User's Invites</Link> :null
            }
            </li>
            <li>
              <Link className={this.props.location.pathname.includes('/users/permissions') ? 'active':'' } to="/users/permissions/"> Security Permissions</Link>
            </li>

          </ul>
        </div>
      </div>
    )
  }
}
export default withRouter(UserManageSideBar);
