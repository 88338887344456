import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../assets/styles/login.scss';
import TopMainLoggedOutHeader from '../../shared/components/TopMainLoggedOutHeader'
export default class TermsAndCondition extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return(
      <div>
        <TopMainLoggedOutHeader />
        <div className="terms-conditions-sections">
          <h4 className="gama">TERMS AND CONDITIONS</h4>
          <Row className="term-inner-block">
            <Col md="12">
                <h2 className="intro-heading">Introduction</h2>
                <p>Welcome to SPARTA Application. These terms of use govern your use of Acumen’s SPARTA application; by using Acumen’s SPARTA application, you accept these terms of use in full. If you disagree with these terms of use or any part of these terms of use, please do not use Acumen’s SPARTA application.</p>
                <h2>License To Use SPARTA application</h2>
                <p>Unless otherwise stated, Acumen Aviation group or its licensors, owns the intellectual property rights in the SPARTA application and material on the SPARTA application. All these intellectual property rights are reserved. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
                <h2>Acceptable Use</h2>
                <p>You must not use Acumen’s SPARTA application in any way that causes, or may cause, damage to the SPARTA application or impairment of the availability or accessibility of the SPARTA application; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
                <br/>
                <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to Acumen’s SPARTA application without Acumen Aviation express written consent.</p>
                <h2>Restricted Access</h2>
                <p>Access to certain areas of Acumen’s SPARTA application is restricted. Acumen Aviation reserve the right to restrict access to other areas of Acumen’s SPARTA application, or indeed the whole SPARTA application, at the discretion of Acumen Aviation.</p>
                <br/>
                <p>
                    If Acumen Aviation provides you with a user ID and password to enable you to access the restricted areas of Acumen’s SPARTA application or other content or services, you must ensure that the user ID and password is kept confidential.
                </p>
                <br/>
                <p>
                    You must notify Acumen Aviation in writing immediately if you become aware of any unauthorised use of your account or password.
                </p>
                <br/>
                <p>
                    You are responsible for any activity on Acumen’s SPARTA application arising out of any failure to keep your password confidential, and may be held liable for any losses arising out of such a failure.
                </p>
                <br/>
                <p>
                    You must not use any other person's user ID and password to access Acumen’s SPARTA application, unless you have that person's express permission to do so.
                </p>
                <br/>
                <p>
                    Acumen Aviation may disable your user ID and password at its sole discretion without notice or explanation.
                </p>
                <h2>Limited Warranties</h2>
                <p>Acumen Aviation do not warrant the completeness or accuracy of the information published on this SPARTA application; nor commit to ensuring that the SPARTA application remains available or that the material on the SPARTA application is kept up-to-date.</p>
                <br/>
                <p>To the maximum extent permitted by applicable law Acumen Aviation exclude all representations, warranties and conditions relating to this SPARTA application and the use of this SPARTA application (including, without limitation, any warranties implied by law of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</p>
                <h2>Confidentiality</h2>
                <p>The SPARTA application contains information that is commercially sensitive and proprietary. By accessing the Portal, each user agrees to maintain the confidentiality of such information.</p>
                <h2>Indemnity</h2>
                <p>You hereby, undertake to keep Acumen Aviation indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Acumen Aviation to a third party in settlement of a claim or dispute on the advice of the legal advisers) incurred or suffered by Acumen Aviation arising out of any breach by you of any provision of these terms of use or arising out of any claim that you have breached any provision of these terms of use.</p>
                <h2>Breaches of These Terms of Use</h2>
                <p>Without prejudice to Acumen Aviation other rights under these terms of use, if you breach these terms of use in any way, Acumen Aviation may take such action as it deems appropriate to deal with the breach, including suspending your access to the SPARTA application, prohibiting you from accessing the SPARTA application, blocking computers using your IP address from accessing the SPARTA application, contacting your internet service provider to request that they block your access to the SPARTA application and/or bringing court proceedings against you.</p>
                <h2>Variation</h2>
                <p>Acumen Aviation may revise these terms of use from time-to-time. Revised terms of use will apply to the use of Acumen’s SPARTA application from the date of the publication of the revised terms of use. Please check regularly to ensure you are familiar with the current version.</p>
                <h2>Assignment</h2>
                <p>Acumen Aviation may transfer, sub-contract or otherwise deal with the rights and/or obligations under these terms of use without notifying you or obtaining your consent.</p>
                <br/>
                <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms of use.</p>
                <h2>Severability</h2>
                <p>If a provision of these terms of use is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>
                <h2>Exclusion of Third Party Rights</h2>
                <p>These terms of use are for the benefit of Acumen Aviation and you, and are not intended to benefit any third party or be enforceable by any third party. The exercise of Acumen Aviation’s rights and your rights in relation to these terms of use is not subject to the consent of any third party.</p>
                <h2>Limitation and Exclusions of Liability</h2>
                <p>Nothing in these terms of use will: (a) limit or exclude Acumen Aviation’s liability or your liability for fraud or fraudulent misrepresentation; (b) limit any of Acumen Aviation’s liability or your liabilities in any way that is not permitted under applicable law; or (d) exclude any of Acumen Aviation’s liability or your liabilities that may not be excluded under applicable law.</p>
                <br/>
                <p>Acumen Aviation will not be liable to you in respect of any losses arising out of any event or events beyond its reasonable control.</p>
                <h2>Entire Agreement</h2>
                <p>These terms of use, together with Acumen Aviation’s privacy policy, constitute the entire agreement between you and Acumen Aviation in relation to your use of Acumen’s SPARTA application, and supersede all previous agreements in respect of your use of this SPARTA application.</p>
                <h2>Law and Jurisdiction</h2>
                <p>These terms of use will be governed by and construed in accordance with the Irish Laws and any disputes relating to these terms of use will be subject to the exclusive jurisdiction of the courts of Dublin, Ireland</p>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
