import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { Link } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import { ShortHead, ContractSideBar, ContractBarLoader } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { loadMrAccuralAc, accrualsSimulationAc,cashFlowListPostAc,monthlyBreakDownExportPostAc } from '../actionCreators';
import AccrulsWrapper from '../components/AccrulsWrapper';
import { START_GRAPH_SIMULATION, RESET_SIMULATION, CLEAR_SIMULATION_DATA, ACCRUAL_PROGRAM_UPDATE } from '../../../maintenance/MRFinanceGraph/actions';
import { MONTHLY_TOGGLE_DATA}from '../actions'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { assetsType } from '../../../../constants';
import { CashFlowModal } from '../../../maintenance/MRFinanceGraph/components'
import {
  MRFundProjection,
  SummeryGraphCard,
  EngineSummeryCard,
  GraphCard,
  EngineHeader,
  EngineGraphTable,
  GrpALGAPUWrapper,
  EngineGenInfo,
  GrpEngineRate,
  GrpEventTopHd,
  LifeOnWings
} from '../../../maintenance/MRFinanceGraph/components';
// Imported the Simulation Component From  Mintenance
class MRAccural extends Component {
  constructor(props) {
    super(props);
    this.state={
      tabIndex: '1',
      activeTab: '1',
    };
  }
  componentDidMount(){
    this.props.fetchAssetInfo('mr-projection');
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/mr-accrual';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.resetSimlute(0);
      this.props.fetchAssetInfo('mr-projection');
    }

  }
  componentWillUnmount(){
    this.props.clearGrpSimulation();
  }
  tabIndex = (tabIndex) => {
    this.setState({
      tabIndex: tabIndex
    })
  }

  monthlytoggleModal=(flag)=>{
    if(flag){
      this.props.fetchCashFlow(this.props.ctrShortInfo.contract.slug,this.props.simulatedData);
    }else{
      this.props.cashFlowToggle();
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  downloadGraphPostMonthly=(filter)=>{
    this.props.downloadGraphPostMonthly(this.props.ctrShortInfo.contract.slug,this.props.simulatedData, filter);
  }

  handlecase=()=>{
    if(this.props.checkFlag){
     this.monthlytoggleModal(true)
    }else{
      browserHistory.push('/'+this.props.params.type+'/'+ this.props.params.aircraft_slug+'/contract/mr-accrual-cash-flow');
    }
  }

  getSimulateResult = () => {
    this.props.getSimulateResult(this.props.ctrShortInfo.contract.slug, this.props.mrFinanceGraph.data, this.props.ctrShortInfo);
  }
  render() {
    const { ctrShortInfo, mrFinanceGraph, simulatedData, simulationType, lifeOnWings, mrAccuralLoader, checkFlag } = this.props;
    const { airframe, engines, apu, landing_gears, landing_gear } = this.props.mrFinanceGraph.data;
    const { selectedAirframeEvent, selectedEngineNo, selectedLg } = this.props.mrFinanceGraph;
    return (
      <div className="contract-container">
        {mrAccuralLoader?<ListLoader />:null}
        <div >
          { Object.keys(ctrShortInfo).length ?
            <ShortHead contrAssetBasicInfo={ctrShortInfo} changeMsn={this.changeMsn} headingText="Contracts" /> : <ContractBarLoader />
          }
        </div>
          <ContractSideBar application={`MR Accruals`} type="shorter" params= {this.props.params} />
        <div>
          <p className="mr-accrual-link">
            {Object.keys(mrFinanceGraph.data).length && engines && engines.engine_data ?
          <Link
        onClick={()=>this.handlecase()}
        >Generate MR Accrual Cashflow</Link>:null}
          </p>
          {
            Object.keys(mrFinanceGraph.data).length ?
            <AccrulsWrapper>
              <MRFundProjection
                mrFinanceGraph={mrFinanceGraph}
                tabIndex={this.tabIndex}
                aircraftProps = {this.props.params}
                >
                <TabPane tabId="1">
                  <SummeryGraphCard
                    title="Summery"
                    color={'rgba(241,202,249,1)'}
                    newcolor="#BAB9D3"
                    lineColor="#BD10E0"
                    newlineColor="#150F78"
                    ctrShortInfo={ctrShortInfo}
                    report="Summery of"
                    simulationType={simulationType}
                    summeryCardData={mrFinanceGraph.data}
                    newsummeryCardData={ simulationType == 2 ? simulatedData : {} }
                  />
                  <div className="mobile-event-group">
                    <GrpEventTopHd />
                    { airframe ?
                      Object.keys(airframe).map((label,index) =>
                        <GrpALGAPUWrapper
                          key={index}
                          simulationType={simulationType}
                          newprogramInfo={simulationType == 2 ? simulatedData.airframe[label] :{}}
                          metrixType="airframe"
                          programType={label}
                          programInfo={airframe[label]} />
                      ):null
                    }
                    { landing_gears ?
                      Object.keys(landing_gears).map((label, index) =>
                      <GrpALGAPUWrapper
                        key={index}
                        simulationType={simulationType}
                        newprogramInfo={simulationType == 2 ? simulatedData.landing_gears[label] :{}}
                        metrixType="landing_gears"
                        programType={label}
                        programInfo={landing_gears[label]} />): null
                    }
                    { apu ?
                      <GrpALGAPUWrapper
                        simulationType={simulationType}
                        newprogramInfo={simulationType == 2 ? simulatedData.apu :{}}
                        metrixType="apu"
                        programType=""
                        programInfo={apu} /> :null
                    }
                    { engines ?
                      <EngineSummeryCard
                        toggleModal={this.toggleModal}
                        simulationType={simulationType}
                        newenginesInfo={simulationType == 2 ? simulatedData.engines.general_info :{}}
                        enginesInfo={engines.general_info}
                        newengines={simulationType == 2 && simulatedData.engines ? simulatedData.engines.engine_data :{}}
                        engines={engines && engines.engine_data ? engines.engine_data:''} />:null
                    }
                  </div>

                </TabPane>
                { airframe ?
                  <TabPane tabId="2">
                    <ul className="list-inline flex-centered other-options">
                      { Object.keys(airframe).map((label, index) => {
                        return(<li style={{marginBottom: '15px'}} key={index} className="list-inline-item"><label><input onChange={() => this.props.updateProgramSel('selectedAirframeEvent', label) } checked={ label == selectedAirframeEvent ? true:false} type="radio" name="airframe" />{airframe[label].name}</label></li>)
                      } )}
                    </ul>
                    <GraphCard
                      simulationType={simulationType}
                      type="airframe"
                      color={'rgba(255,218,218,1)'}
                      lineColor="#FF5E5E"
                      airframe={true}
                      ctrShortInfo={ctrShortInfo}
                      graphLabel={(ctrShortInfo.asset_type == 1 ? 'MR Fund Projection of MSN ' + ctrShortInfo.msn + " Airframe for " : 'MR Fund Projection of ESN ' + ctrShortInfo.esn + " Airframe for ") + selectedAirframeEvent + ' Years'}
                      events={airframe[selectedAirframeEvent].events}
                      plots={airframe[selectedAirframeEvent].plots}
                      newevents={simulationType == 2 ? simulatedData.airframe[selectedAirframeEvent].events :{}}
                      newplots={simulationType == 2 ? simulatedData.airframe[selectedAirframeEvent].plots :{}}
                    />
                     <div className="mobile-event-group">
                      <GrpEventTopHd />
                      <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.airframe[selectedAirframeEvent] :{}} metrixType="airframe" programType={selectedAirframeEvent} programInfo={airframe[selectedAirframeEvent]} />
                    </div>
                  </TabPane>:null
                }
                { engines ?
                  <TabPane tabId="3">
                  { engines && engines.engine_data?
                    <Row>
                      <Col sm="12">
                        <ul className="list-inline flex-centered other-options">
                          { Object.keys(engines.engine_data).map((label, index) => {
                            return(<li key={index} className="list-inline-item"><label><input onChange={() => this.props.updateProgramSel('selectedEngineNo',label)} checked={ label == selectedEngineNo ? true:false } type="radio" name="engine" />{engines.engine_data[label].name}</label></li>)
                          } )}
                        </ul>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '1' })}
                              onClick={() => { this.toggle('1'); }}
                            >
                              PRSV
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '2' })}
                              onClick={() => { this.toggle('2'); }}
                            >
                              LLP
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <GraphCard
                              simulationType={simulationType}
                              type="engines"
                              color='#F9CAE3'
                              lineColor="#E010A4"
                              ctrShortInfo={ctrShortInfo}
                              graphLabel={ctrShortInfo.asset_type == 1 ? 'MR Fund Projection of MSN ' + ctrShortInfo.msn + " Engines" : 'MR Fund Projection of ESN ' + ctrShortInfo.esn + " Engines"}
                              events={engines ? engines.engine_data[selectedEngineNo].shop_visits : []}
                              plots={engines ? engines.engine_data[selectedEngineNo].prsv_plots : []}
                              newplots={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].prsv_plots :{}}
                              newevents={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].shop_visits :{}}
                            />
                          </TabPane>
                          <TabPane tabId="2">
                            <GraphCard
                              simulationType={simulationType}
                              type="engines"
                              ctrShortInfo={ctrShortInfo}
                              color='#F9CAE3'
                              lineColor="#E010A4"
                              graphLabel={ctrShortInfo.asset_type == 1 ? 'MR Fund Projection of MSN ' + ctrShortInfo.msn + " Engines" : 'MR Fund Projection of ESN ' + ctrShortInfo.esn + " Engines"}
                              events={engines ? engines.engine_data[selectedEngineNo].shop_visits : []}
                              plots={engines ? engines.engine_data[selectedEngineNo].llp_plots : []}
                              newplots={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].llp_plots :{}}
                              newevents={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].shop_visits :{}}
                            />
                          </TabPane>
                        </TabContent>
                        <div className="shop-visit-block">
                          <EngineGenInfo simulationType={simulationType} metrixType="engines" programType="general_info" newenginesInfo={simulationType == 2 ? simulatedData.engines.general_info:{}} enginesInfo={engines.general_info} />
                          <GrpEngineRate simulationType={simulationType} metrixType="engines" programType={selectedEngineNo} newengine={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo]:{}} engine={engines.engine_data[selectedEngineNo]} />
                          <div className="overflow-auto-xs">
                            <table>
                              <EngineHeader />

                              <tbody>
                                { engines ? Object.keys(engines.engine_data[selectedEngineNo]['shop_visits']).map((label, index) => <EngineGraphTable urlLoad="Accrual" simulationType={simulationType} key={index} label={label} newshopVisit={ simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo]['shop_visits'][label]: {}} shopVisit={engines.engine_data[selectedEngineNo]['shop_visits'][label]} /> ) : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    :<NoRecordFound/>
                        }
                  </TabPane>:null
                }
                { landing_gears ?
                  <TabPane tabId="4">
                    <GraphCard
                      simulationType={simulationType}
                      type="landing_gear"
                      color='#D4F5F1'
                      lineColor="#41D6C3"
                      ctrShortInfo={ctrShortInfo}
                      graphLabel={ctrShortInfo.asset_type == 1 ? 'MR Fund Projection of MSN ' + ctrShortInfo.msn + " Landing Gears" : 'MR Fund Projection of ESN ' + ctrShortInfo.esn + " Landing Gears"}
                      events={landing_gears[selectedLg].events}
                      plots={landing_gears[selectedLg].plots}
                      newplots={simulationType == 2 ? simulatedData.landing_gears[selectedLg].plots :{}}
                      newevents={simulationType == 2 ? simulatedData.landing_gears[selectedLg].events :{}}
                    />
                    <div className="mobile-event-group">
                    <GrpEventTopHd />
                    <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.landing_gears[selectedLg] :{}} metrixType="landing_gears" programType={selectedLg} programInfo={landing_gears[selectedLg]} />
                  </div>
                  </TabPane>:null
                }
                { apu ?
                  <TabPane tabId="5">
                    <GraphCard
                      simulationType={simulationType}
                      type="apu"
                      color='#D5E8FF'
                      lineColor="#2F8CFF"
                      ctrShortInfo={ctrShortInfo}
                      graphLabel={ctrShortInfo.asset_type == 1 ? 'MR Fund Projection of MSN ' + ctrShortInfo.msn + " APU's" : 'MR Fund Projection of ESN ' + ctrShortInfo.esn + " APU's"}
                      events={apu.events}
                      plots={apu.plots}
                      newplots={simulationType == 2 ? simulatedData.apu.plots :{}}
                      newevents={simulationType == 2 ? simulatedData.apu.events :{}}
                    />
                    <div className="mobile-event-group">

                    <GrpEventTopHd />
                    <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.apu :{}} metrixType="apu" programType="" programInfo={apu} />
                  </div>
                  </TabPane>:null
                }
                {
                  getLocalStorageInfo().user.permission['contracts']['mr_accruals'].indexOf('U') != -1 ?
                  <span>
                    {
                      simulationType == 0 ?
                      <button className="simulate-scenarios" onClick={() => this.props.startSimulation(1)}>Simulate Scenarios</button>
                      :
                      <div className="simulate-now">
                        <ul className="list-inline">
                          <li className="list-inline-item" onClick={() => this.props.resetSimlute('reset')}>Reset to default values</li>
                          <li className="list-inline-item">
                            {
                              simulationType == 1 ?
                              <button onClick={this.getSimulateResult} disabled={mrAccuralLoader}>{ mrAccuralLoader ? 'processing...':'Apply →'}</button>
                              :
                              <button onClick={() => this.props.resetSimlute('restart')}>Simulate Another Scenarios →</button>
                            }

                          </li>
                        </ul>
                      </div>
                    }
                  </span>:null
                }

              </MRFundProjection>
              { lifeOnWings && Object.keys(lifeOnWings).length && (this.state.tabIndex == '1' || this.state.tabIndex == '3') ? <LifeOnWings lifeOnWings={lifeOnWings} />:null }
            </AccrulsWrapper>
            :null
          }
        </div>
        <Modal size="lg" className="monthly-cashflow-modal" isOpen={this.props.monthlyModal} toggle={()=>this.monthlytoggleModal(false)} centered={true} style={{width:"60%"}}>
                    <ModalBody>
                    <CashFlowModal
                       cashFlow={this.props.cashflowMonthlyBreakup}
                       paddingSpace = {22}
                       monthlytoggleModal={this.monthlytoggleModal}
                       downloadGraphPostMonthly={(filter) => this.downloadGraphPostMonthly(filter)}
                      />
                    </ModalBody>
            </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  mrFinanceGraph: state.MRFinanceGraphReducer.mrFinanceGraph,
  simulatedData: state.MRFinanceGraphReducer.simulatedData,
  simulationType: state.MRFinanceGraphReducer.simulationType,
  lifeOnWings: state.MRFinanceGraphReducer.lifeOnWings,
  mrAccuralLoader: state.MRAccuralReducer.mrAccuralLoader,
  cashflowMonthlyBreakup: state.MRAccuralReducer.cashflowMonthlyBreakup,
  checkFlag: state.MRFinanceGraphReducer.checkFlag,
  monthlyModal:state.MRAccuralReducer.monthlyModal,
});
const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    fetchAssetInfo: (type) => dispatch(loadMrAccuralAc(ownProps.params,type)),
    getSimulateResult: (contract_slug, data, ctrShortInfo) => dispatch(accrualsSimulationAc(contract_slug, data, ctrShortInfo)),
    fetchCashFlow: (contract_slug,data) => dispatch(cashFlowListPostAc(contract_slug,data)),
    downloadGraphPostMonthly: (contract_slug,data, filter) => dispatch(monthlyBreakDownExportPostAc(contract_slug,data, filter)),
    cashFlowToggle:() => dispatch({
      type: MONTHLY_TOGGLE_DATA,
      payload:false
    }),
    updateProgramSel: (programType, label) => dispatch({
      type: ACCRUAL_PROGRAM_UPDATE,
      payload: {programType, label}
    }),
    startSimulation: (simulationType) => dispatch({
      type: START_GRAPH_SIMULATION,
      payload: simulationType
    }),
    resetSimlute: (type) => dispatch({
      type: RESET_SIMULATION,
      payload: type
    }),
    clearGrpSimulation: () => dispatch({
      type: CLEAR_SIMULATION_DATA,
      payload:''
    }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(MRAccural,['contracts','mr_accruals','R'])));
