import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DropDownField } from '../../../Elements';

export default class ComponentsInspected extends Component {
  constructor(props) {
    super(props);
  }


  render() {
  const { } = this.props;
  return(
    <div>
      <Row>
       <Col sm="12">
         <TextInputField type={'edit'} value={''} >Description</TextInputField>
         <TextInputField type={'edit'} value={''} >Findings</TextInputField>
         <DropDownField type={'edit'} >Status</DropDownField>
       </Col>
      </Row>  
    </div>
    );
  }
}
