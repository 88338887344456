import React from 'react'
import { Card, Col, CardTitle, CardText, Badge } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router'
export default function NewsFeedList({feed}){
  return(
    <Col sm="4">
      <Card body className="blog-card">
        <Link to={`/newsfeed/${feed.slug}`}>
          <img src={feed.cover_image} alt="img"/>
        </Link>
        <div style={{position:"relative", top:"-15px"}}>
          <p style={{fontSize:"10px"}}>{feed.category.name}, {moment(feed.published_date).format('MMM DD, YYYY')}</p>
          <ul className="list list-inline" style={{fontSize:"13px"}}>
            { feed.tag.slice(0,4).map((tag, index) =>
              <li  className="list-inline-item" key={index} style={{display:"inline"}}><Badge color="secondary">{tag.name}</Badge> </li>
            )}
            { feed.tag.length > 4 ?
              <li className="list-inline-item" style={{display:"inline"}}><Badge color="secondary">{feed.tag.length - 4} more</Badge></li> : null
            }
          </ul>
        </div>
        <Link to={`/newsfeed/${feed.slug}`} ><CardTitle  className="blog-card-title" >{ feed.title.length > 100 ? feed.title.slice(0,100)+'...':feed.title}</CardTitle></Link>
        <CardText  className="blog-card-text"  >{feed.description.length >200 ? feed.description.slice(0,200)+'...':feed.description}</CardText>
        <Link to={`/newsfeed/${feed.slug}`} className="view-more-news-feed">View more</Link>
      </Card>
    </Col>
  )
}
