import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { getLocalStorageInfo } from '../../utils';
import { imgStoragePath } from '../../constants';
export default class TopMainLoggedOutHeader extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const userInfo = getLocalStorageInfo();
    return(
      <div>
        {!(userInfo && userInfo.user && Object.keys(userInfo.user).length) ?
          <div style={{ height:'40px' }}></div>:null
        }
        <header className="logedout-header">
          <Row className="flex-centered">
            <Col sm="6">
              <ul className="list-inline header-logo-search">
               <li className="list-inline-item">
                  <Link to="/">
                    <img width="40" className="main-logo" src={imgStoragePath+"sparta_logo.png"} alt="logo" />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="6">
              {!(userInfo &&  userInfo.user &&  Object.keys(userInfo.user).length) ?
                <ul className="list-inline header-user-info flex-centered">
                  <li className="list-inline-item login-logout-link" style={{position:'absolute',right:'10px'}}>
                      <Link to="/login">Login</Link>
                  </li>
                </ul>:null
               }
            </Col>
          </Row>
        </header>
      </div>
    )
  }
}
