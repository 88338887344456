import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link,browserHistory } from 'react-router';
import { Row, Col } from 'reactstrap';
import { AssemblySideBar } from '../../Elements';
import { ShortHead, ContractHeadertext, ContractBarLoader } from '../../../contracts/Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { assetsType } from '../../../../constants';
import { mrClaimListAc } from '../actionCreators';
import ClaimList from '../components/ClaimList';
import { assetInfoAc } from '../../AirframeAssembly/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import Switcher from '../../../../shared/components/Switcher';
import { trackActivity } from '../../../../utils/mixpanel'
class MaintenanceClaims extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.props.fetchClaim();
    this.props.fetchAssetInfo();
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Maintenance Claims'
    })
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/claims';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  render(){
    const { mrClaimList, mrClaimLoader, assetInfoObj } = this.props;
    return(
      <div className="mr-rate-wrapper claims-block maintainance-container" style={{marginLeft: '215px', marginTop: '94px'}}>
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} changeMsn={this.changeMsn} headingText={'Maintenance' && <Switcher app="Maintenance"   />} />:<ContractBarLoader />
        }
        <div className="mr-rate-inner-wrapper asset-listing-wrap " style={{minHeight: '500px'}}>
          <ContractHeadertext
            title="Maintenance Claims"
            desc="Section holds Maintenance claims of all the major assemblies of the asset"
           />
         <AssemblySideBar style={{marginLeft: '30px'}} params={this.props.params} assetInfo={assetInfoObj} />
          <div className="asset-list-filter-sort maintainenace-listing-blk">
            <Row className="filter-block" >
              <Col md="3">
                <h6>
                  <span>Event / Shop Visit</span>
                </h6>
              </Col>
              <Col md="3">
                <h6>
                  <span>Submission date</span>
                </h6>
              </Col>
              <Col md="3">
                <h6>
                  <span>Amount</span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span>Status</span>
                </h6>
              </Col>
            </Row>
            {
              mrClaimList && mrClaimList.length ?
              mrClaimList.map((claim,index) =>
              <ClaimList key={index} claim={claim} />
              ):null
            }
            {
              !mrClaimLoader && !mrClaimList.length ?
              <NoRecordFound description="Update claim details under Maintenance event / Shop visit" />:null
            }
          </div>
        </div>
        {mrClaimLoader?<ListLoader />:null}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  mrClaimList: state.MrClaimsReducer.mrClaimList,
  mrClaimLoader: state.MrClaimsReducer.mrClaimLoader,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchClaim: () => dispatch(mrClaimListAc(ownProps.params)),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params, 'MR Claims')),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(MaintenanceClaims, ['maintenance','claims','R']));
