import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ordersListAc, exportInvoiceAc } from '../actionCreators';
import { BillingSidebar, BillingFilter, InvoiceList } from '../components';
import { imgStoragePath, displayDateFormatShort } from '../../../constants';
import { TableUIComp, ListLoader } from '../../../shared'
import moment from 'moment'
import { toastFlashMessage } from '../../../utils'
import { globalPostService } from '../../../globalServices'
import { PAYMENT_FAILED } from '../../../shared/actions'
import { LICENSE_LOADER } from '../../licenses/actions'
import { getSavedCardsAc } from '../../licenses/actionCreators'
import { getNotificationBarDataAc } from '../../../shared/actionCreators'
import { PaymentFailed, CardList } from '../../licenses/components'

class InvoiceIndex extends Component{
    constructor(props){
        super(props);
        this.state = {
          paymentModal: false,
          modalObj: {
            card_id: '',
            customer: ''
          }
        }
        this.toggle = this.toggle.bind(this);
    }

    payInvoiceDue = (payment) =>{
      let location = browserHistory.getCurrentLocation();
      this.props.toggleLoader(true)
      globalPostService(`console/order/${this.props.ordersList.orders[0].id}/confirm-pay`, payment)
      .then(response => {
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success')
          setTimeout(() => this.props.fetchOrdersList(location.query), 2000)
        }else{
          this.props.cloaseFailedModal(true)
        }
        this.setState({paymentModal: false})
        this.props.toggleLoader(false)
        setTimeout(() => this.props.getNotificationBarData(), 2000)
      })
    }

    componentDidMount(){
        let location = browserHistory.getCurrentLocation();
        location.query?
        this.props.fetchOrdersList(location.query):
        this.props.fetchOrdersList({})
        this.props.getSavedCards()
    }

    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    sortTable = (sortKey) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
          query: {
            ...location.query,
            sort: sortKey,
            page:1,
            per_page: this.props.ordersList.pagination.per_page,
            sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
          }
        }

      browserHistory.push(location);
      this.props.fetchOrdersList(location.query);
    }

    changePerPage=(count) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
        query: {
          ...location.query,
          'per_page': count,
            'page': 1
        }
      }
      browserHistory.push(location);
      this.props.fetchOrdersList(location.query);

    }

    changePagination = (type, currentPage) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
        query: {
          ...location.query,
          'page': currentPage
        }
      }
      browserHistory.push(location);
      this.props.fetchOrdersList(location.query);
    }
    changeFilterFn = (filterParams) => {
      this.props.fetchOrdersList(filterParams);
    }



    render(){
        const { ordersList, orderLoader, dueAmount, cardsList, licenseLoader  } = this.props;
        const menuList = {
          'Invoice Number': {
            'inputType': 'text',
            'keyParam': 'reference_number',
            'placeholder': 'Search by invoice number',
            'label': 'Invoice Number'
          },
          'Invoice Date': {
            'inputType': 'date',
            'keyParam': 'created_at',
            'placeholder': 'Search by invoice generation date',
            'label': 'Invoice Date'
          }
        }

        let invoiceDue = false
        if(ordersList.orders && ordersList.orders.length && ordersList.orders[0].status && ordersList.orders[0].status.value != 1 && ordersList.orders[0].total != 0){
          invoiceDue = true
        }

        return(
            <div className="billing-container">
                <BillingSidebar />
                <div className="container-fluid billing-content-wrapper">
                    <h1 className="page-title">Billing History</h1>
                    <p className="block-subs">Consolidates the list of payments made by the user for various licenses and access to the invoices against these payments</p>
                    <Row>
                        <Col md="8">
                            <ul className="list-inline tab-right flex-centered export-list">
                                <li className="list-inline-item download-block" style={{ display: 'none' }}>
                                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                        <DropdownToggle caret size="sm">
                                        <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export List </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                                            <DropdownItem><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </li>
                                <li className="list-inline-item count-block">
                                    <span> {ordersList && ordersList.orders ? ordersList.orders.length: 0} item(s) </span>
                                </li>
                            </ul>
                            <TableUIComp
                              theads={[{label:'Date', sortKey: 'created_at'}, {label:'Description', sortKey: ''}, {label:'Amount', sortKey: 'total'},{label:'', sortKey: ''}]}
                              bulkOperation={false}
                              filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
                              content={ordersList && ordersList.orders ?
                                ordersList.orders.map((order, index) => <InvoiceList order={order} exportInvoice={this.props.exportInvoice} key={index}/>)
                              : null
                              }
                              pagination = {ordersList.pagination}
                              sortTable={this.sortTable}
                              changePerPage ={this.changePerPage}
                              changePagination = {this.changePagination}
                              closeFilter = {this.closeFilter}
                              searchQuery = {browserHistory.getCurrentLocation().query}
                              changeFilterFn={this.changeFilterFn}
                              recordsFound = { orderLoader ? false : ordersList && ordersList.orders && ordersList.orders.length ? false:true}
                              queryParamsEnabled={true}
                              tableHeight = {window.innerHeight-215}
                            />
                        </Col>
                        <Col md="4">
                        {
                          invoiceDue ?
                            <div className="current-amount-due" style={ invoiceDue ? {background: 'rgb(255, 255, 194)'} : {}}>
                                <Row className="flex-centered">
                                    <Col md="6" >
                                        <h3>Current amount due</h3>
                                        <h5>
                                            $ { ordersList.orders[0].status && ordersList.orders[0].status.value != 1 ? ordersList.orders[0].total : 0}
                                        </h5>
                                    </Col>
                                    <Col md="6 text-center" style={ordersList.orders[0].status && ordersList.orders[0].status.value != 1 &&  ordersList.orders[0].sub_total > 0 ? {} : {display: 'none'}}>
                                        <Link onClick={(evt) => this.setState({paymentModal: true})}>Make a Payment</Link>
                                    </Col>
                                </Row>
                                {/* <p className="due-date" >
                                    Payment due on 2nd May 2019
                                </p> */}
                            </div>
                            :dueAmount.dueAmount ?
                            <div className="current-amount-due">
                                <Row className="flex-centered">
                                  {
                                    dueAmount && Object.keys(dueAmount).length ?
                                    <Col md="12" >
                                        <h3>Next billing on <strong>{dueAmount.dueDate ? moment.unix(dueAmount.dueDate).format(displayDateFormatShort) : moment().format(displayDateFormatShort)}</strong></h3>
                                        <h5>
                                            $ {parseFloat(dueAmount.dueAmount).toFixed(2)}
                                        </h5>
                                    </Col>
                                    :orderLoader ? <Col md="12" ><h3>Loading...</h3> </Col> : null
                                  }
                                    <Col md="6 text-center" style={{display: 'none'}}>
                                        <a onClick={(evt) => {evt.preventDefault();evt.stopPropagation()}}>Make a Payment</a>
                                    </Col>
                                </Row>
                                {/* <p className="due-date">
                                    Payment due on 2nd May 2019
                                </p> */}
                            </div>
                            :null
                          }
                            <div className="billing-detail">
                                <h3><img src={imgStoragePath+'phase-2/info.png'} />About Invoices</h3>
                                <p>
                                    Invoices are generated on 1st of every month and to be paid within 7 days
                                    <br/>
                                    <br/>
                                    If auto debit is enabled, amount will be debited from Card/Account automatically
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                  orderLoader ? <ListLoader/> : null
                }
                <Modal isOpen={this.state.paymentModal} centered={true} toggle={() => !this.props.licenseLoader ? this.setState({paymentModal: false, showNewCard: false, modalObj: {card_id: '', customer: ''}}) : null} className="payment-modal" size="sm">
                  <ModalHeader toggle={() => !this.props.licenseLoader ? this.setState({paymentModal: false, showNewCard: false, modalObj: {card_id: '', customer: ''}}) : null}>
                    {
                      this.state.showNewCard ? 'New Credit / Debit Card' : 'Credit / Debit Card'
                    }
                  </ModalHeader>
                  <ModalBody>
                    {
                      !this.state.showNewCard ?
                      <div className="cardsList">
                        <div className="inner-con">
                          {
                            cardsList && cardsList.list && cardsList.list.length ?
                              cardsList.list.map((card, key) => <CardList key={key} selected={this.state.modalObj.card_id} selectCard={() => this.setState({modalObj: {card_id: card.id, customer: card.customer}})} card={card}/>)
                            :null
                          }
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <button disabled={licenseLoader || this.state.modalObj.card_id == ''} onClick={() => this.payInvoiceDue({card_token: this.state.modalObj.card_id})} className="add-new btn-primary"><i className="fa fa-lock"></i> {licenseLoader ? 'Processing....' : 'Pay'}</button>
                          </div>
                        </div>
                      </div>
                      :null
                    }
                  </ModalBody>
                </Modal>
                <PaymentFailed
                  isOpen={this.props.failedPayment}
                  toggle={this.props.cloaseFailedModal}
                  retry={() => {this.props.cloaseFailedModal(false);this.setState({
                    paymentModal: true
                  })}}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ordersList: state.BillingReducer.ordersList,
    orderLoader: state.BillingReducer.orderLoader,
    dueAmount: state.BillingReducer.dueAmount,
    licenseLoader: state.LicenseReducer.licenseLoader,
    failedPayment: state.sharedReducers.failedPayment,
    cardsList: state.LicenseReducer.cardsList,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        fetchOrdersList : (filters) => dispatch(ordersListAc(filters)),
        exportInvoice : (e, orderId, type) => dispatch(exportInvoiceAc(e, orderId, type)),
        toggleLoader: (flag) => dispatch({
          type: LICENSE_LOADER,
          payload: flag
        }),
        cloaseFailedModal : (flag) => dispatch({
          type: PAYMENT_FAILED,
          payload: flag
        }),
        getSavedCards : () => dispatch(getSavedCardsAc()),
        getNotificationBarData: () => dispatch(getNotificationBarDataAc())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceIndex);
