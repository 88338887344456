import React, { Component } from 'react';
import { imgStoragePath } from '../../../constants';
export default class AddFilterBtn extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropDownOpen: false
    }
  }
  toggleFilter = () => {
    this.setState(prevState => ({
      ...prevState,
      dropDownOpen: !prevState.dropDownOpen
    }))
  }
  render(){
    return(
      <p onClick={this.props.toggleFilterList} style={{fontSize:"12px"}}>
        <span>
          { this.props.isFilter ? <img className="close-block" src={imgStoragePath +"black_close.png"} alt="close"/> : <img className="plus-icon" src={imgStoragePath+"black_plus.png"} alt="img" width="10"/> }
          { this.props.isFilter ?  ' Close Filter': ' Add a Filter'}
        </span>
      </p>
    )
  }
}
