import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { displayDateFormatShort, imgStoragePath } from '../../../constants';
const LastAssetInfoCard = (props) => {
  return(
    <div className="asset-info-card">
      <div className="asset-detail">
        <img src={props.workOrder.lessee.logo && props.workOrder.lessee.logo != '' ? props.workOrder.lessee.logo : imgStoragePath+'icon_Aircraft.png'} alt="" />
        <div className="">
          <h6>{props.workOrder.asset.aircraft_type}</h6>
          <h2>
            <Link target="_blank" to={'/technical/project/view/'+props.workOrder.project_slug}>{props.workOrder.asset.asset_type == 1 ? `MSN ${props.workOrder.asset.msn}` : props.workOrder.asset.asset_type == 2 ? `ESN ${props.workOrder.asset.esn}` : props.workOrder.asset.asset_type == 3 ? `APU ${props.workOrder.asset.serial_number}` : props.workOrder.asset.asset_type == 4 ? `LG ${props.workOrder.asset.serial_number}` : props.workOrder.asset.asset_type == 5 ? `PROPELLER ${props.workOrder.asset.serial_number}` : ''}</Link>
          </h2>
          <span>{props.workOrder.status.label}</span>
          <p>Last inspection on</p>
          <p>{moment(props.workOrder.last_inspection_date).format(displayDateFormatShort)}</p>
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <td>
              <h4>{props.workOrder.findings_count}</h4>
              <h6>Findings in last Inspection</h6>
            </td>
            <td>
              <h4>{props.workOrder.inspection_types.length}</h4>
              <h6>No. of Services Rendered</h6>
            </td>
          </tr>
          <tr>
            <td colSpan="2" align="left">
              <p>Services rendered so far</p>
              { props.workOrder.inspection_types.length ?
                props.workOrder.inspection_types.map((inspection, index) =>
                <span key={index}>{inspection.name}</span> ):null}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  )
}
export default LastAssetInfoCard;
