import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { Row, Col } from 'reactstrap';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
export default class DashboardCashFlowList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { cashFlow, label } = this.props;
    return(
      <li>
        <span className="blue-dot"></span>
        <span className="caption-ui date-text">{label}</span>
        <Row>
          {
            cashFlow.mr ?
            <Col>
            <p className="para-ui">Maintenance Reserve</p>
            <NumericLabel params={showCurrencyFormat()}>{cashFlow.mr}</NumericLabel>
          </Col> : null
          }
          {
            cashFlow.rental ?
            <Col>
            <p className="para-ui">Rentals</p>
              { cashFlow.rental_new ?
                cashFlow.rental_new.length?
                cashFlow.rental_new.map((rental, index) =>
                <div>
                <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                </div>
                ): '$0.00': '$0.00'
              }
          </Col> : null
          }


        </Row>
      </li>
    )
  }
}
