import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
export default class OffWingUnservList extends Component{
  constructor(props){
    super(props)
  }
 render(){
  const { dataList, techConstants } = this.props;
  let data = dataList.off_wing_detail;
  return(
    <Row>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Shop Visit Requirement"   text={data.shop_visit_requirement && techConstants.length ? techConstants.find(item => item.type == 'sv_requirement' && item.value == data.shop_visit_requirement) ? techConstants.find(item => item.type == 'sv_requirement' && item.value == data.shop_visit_requirement).label : '--':'--'}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Expected Date of Shipment to Shop"   text={data.expected_date_of_shipment_to_shop ? moment(data.expected_date_of_shipment_to_shop).format(displayDateFormat):'--'}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Selected Shop Visit"   text={data.selected_shop}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Asset Location"   text={data.off_wing_location}/>
    </Row>
    )
  }
}
