import { userAssetReducer } from '../User';
import {
  MRFinanaceRptReducer,
  MRFinanceGraphReducer,
  AirframeAssemblyReducer,
  ApuAssemblyReducer,
  EngineAssemblyReducer,
  LangingGearAssemblyReducer,
  MrClaimsReducer
} from '../applications/maintenance';
import {
  ContractListReducer,
  ContrHdlineReducer,
  ContrMRRateReducer,
  ContrFinanceReducer,
  ContrPartiesReducer,
  ContrObligationsReducer,
  ContrLesRentalReducer,
  ContrSubLeaseReducer,
  ContrDeliCondReducer,
  ContrInducCondReducer,
  ContrReturnCondReducer,
  ContrLeaseOptReducer,
  ContrInsuraneReducer,
  ContrQfdMntReducer,
  // ContrUtilsReducer,
  ContrDashboardReducer,
  ContrCashFlowReducer,
  SecurityDepositsReducer,
  ContrWorkingGrpReducer,
  InvoiceReducer,
  UtilsReducer,
  MRAccuralReducer,
  HalfLifeReducer,
  MLFileReducer,
  CapeTownConventionReducer
 } from '../applications/contracts';
 import { DashboardReducer } from '../applications/dashboards';
 import { ADReducer } from '../applications/MlModel';
 import { TechPointsReducer } from '../applications/MlModel/';
 import {
   PorjectsReducer,
   FormT001Reducer,
   FormT002Reducer,
   FormT003Reducer,
   FormT004Reducer,
   FormT005Reducer,
   FormT006Reducer,
   FormT007Reducer,
   FormT008Reducer,
   FormT009Reducer,
   FormT011Reducer,
   FormTE001Reducer,
   FormTE002Reducer,
   FormTE003Reducer,
   FormTE004Reducer,
   FormTE005Reducer,
   FormTE006Reducer,
   TrackerReducer,
   ProgramPlannerReducer,
   FormReviewReducer

 } from '../applications/technicalInspection';
import { LicenseReducer } from '../phase2/licenses/';
import { BillingReducer } from '../phase2/billing/';
import { confReducer } from '../phase2/ConfigureSettings/';
import { StorageListingReducer } from '../phase2/storage/';
import { NotificationReducer } from '../phase2/notification';
import { sharedReducers } from '../shared';
import globalSearchReducer  from '../shared/GlobalSearch/reducer';
import { FleetAnalysisReducer } from '../applications/fleetAnalysis';
import { RecordDBReducer, AutoRenamingReducer, DataRoomReducer, ActionDockReducer, RecycleBinReducer, ResourcesReducer, ReportsReducer } from '../applications/records';
import { TechSummaryReducer } from '../applications/console';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
  userAssetReducer,
  MRFinanaceRptReducer,
  MRFinanceGraphReducer,
  AirframeAssemblyReducer,
  ApuAssemblyReducer,
  EngineAssemblyReducer,
  LangingGearAssemblyReducer,
  MrClaimsReducer,
  ContractListReducer,
  ContrHdlineReducer,
  ContrMRRateReducer,
  ContrFinanceReducer,
  ContrPartiesReducer,
  ContrObligationsReducer,
  ContrLesRentalReducer,
  ContrSubLeaseReducer,
  ContrDeliCondReducer,
  ContrInducCondReducer,
  ContrReturnCondReducer,
  ContrLeaseOptReducer,
  ContrInsuraneReducer,
  ContrQfdMntReducer,
  MLFileReducer,
  // ContrUtilsReducer,
  ContrDashboardReducer,
  ContrCashFlowReducer,
  SecurityDepositsReducer,
  ContrWorkingGrpReducer,
  sharedReducers,
  InvoiceReducer,
  UtilsReducer,
  MRAccuralReducer,
  TechSummaryReducer,
  FleetAnalysisReducer,
  HalfLifeReducer,
  RecordDBReducer,
  DataRoomReducer,
  ActionDockReducer,
  RecycleBinReducer,
  ResourcesReducer,
  globalSearchReducer,
  CapeTownConventionReducer,
  ReportsReducer,
  PorjectsReducer,
  FormT001Reducer,
  FormT002Reducer,
  FormT003Reducer,
  FormT004Reducer,
  FormT005Reducer,
  FormT006Reducer,
  FormT007Reducer,
  FormT008Reducer,
  FormT009Reducer,
  FormT011Reducer,
  FormTE001Reducer,
  FormTE002Reducer,
  FormTE003Reducer,
  FormTE004Reducer,
  FormTE005Reducer,
  FormTE006Reducer,
  DashboardReducer,
  ADReducer,
  TrackerReducer,
  ProgramPlannerReducer,
  AutoRenamingReducer,
  FormReviewReducer,
  LicenseReducer,
  BillingReducer,
  confReducer,
  StorageListingReducer,
  NotificationReducer,
  TechPointsReducer,
});
export default rootReducer;
