import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../../../constants';
import { TextInputField, GetInputField, DeleteModal } from'../../../Elements';

export default class DetailSidebar extends Component{
    constructor(props){
			super(props);
			this.state = {
				delModal: false,

			}
		}

		toggleDelModal = () => {
			this.setState({
				delModal: !this.state.delModal,
			});
		}
		selectedQuestion = (id, name) => {
			this.props.addFindingFn({'id': id, 'name': name})

		}

		addEditFindingQues = (e) => {
			e.preventDefault();
			if(this.props.seatWindFlag){
				this.props.updateSeatFindingUpdate({seats: this.props.seatarr, findings : this.props.findingArr })
			}else{
				this.props.updateWindowFindingUpdate({windows: this.props.windowArr,	 findings : this.props.findingArr })
			}
		}

	render(){
		const headerHeight = document.body.contains(document.getElementById('sidebar-header')) ? (document.getElementById('sidebar-header').clientHeight - 25) : 0 ;
		
		return(
			<div className="seat-techspec">
				<form className="config-side-bar" onSubmit={(e) => this.addEditFindingQues(e)}>

					<div className="sidebar-form" style={{ height: (window.innerHeight -  headerHeight), paddingBottom: '40px'}}>
					<ul className="list-unstyled">
            {
							this.props.seatQuestions.data ?
							this.props.seatQuestions.data.map((question, index) => {
								let checkExistingFinding = this.props.findingArr && this.props.findingArr.length ? this.props.findingArr.filter(findingData => findingData.id == question.id) : [];
								return(
									<li className="flex-centered" onClick = {() => this.selectedQuestion(question.id, question.name)} key={index}>
									<span>{question.name}</span>

										{ checkExistingFinding.length && [checkExistingFinding[0]['id']].includes(question.id) ?
										<img className="checkbox-img" src={imgStoragePath + 'select_user_checked.png'} alt="img"/>  :
										<b className="unselected-checkbox"></b> }
								</li>
								)
							}
							) : null
						}
						</ul>
					</div>

					<div className="edit-sidebar-footer">
          <ul className="list-inline">
            <li className="list-inline-item">
              <input  disabled={this.props.paxSeatingLoader}
							className="primary-btn" type="submit"  value="Save Changes"
							value={this.props.paxSeatingLoader ?  "Processing..." : "Save Changes" }
							/>
            </li>
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
						<li className="list-inline-item" style={{ position: 'absolute', right: '9px', top: '21px'}}>
							{ this.props.seatWindFlag  ?
								<button disabled="" onClick={(e) => { e.preventDefault(); this.toggleDelModal()}} className="delete-picture" >
									{ this.props.seatStatus == 1 ? 'Disable' : 'Enable' }
								</button>
        	  		: null
							 }

						</li>
          </ul>
        </div>
				</form>
				<DeleteModal isOpen={this.state.delModal}
        toggle={() => this.toggleDelModal()}
        title={this.props.seatStatus == 1 ? 'Are you sure to  Disable?' : 'Are you sure to  Enable'} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal();this.props.disableSeatFn({seats: this.props.seatarr, status : this.props.seatStatus == 1 ? 0 : 1})}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
			</div>
		)
	}
}
