import * as actions from '../actions';
export default function(state = {
  partyList: [],
  partyCrud: {
    type: '',
    data: {},
    flag: false
  },
  partiesLoader: true,
}, action){
  switch (action.type) {
    case actions.CONTR_PARTIES_LIST:
      return{
        ...state,
        partyList: action.payload
      }
    case actions.ADD_PARTY_SUCCESS:
      return {
        ...state,
        partyList: [ action.payload, ...state.partyList]
      }
    case actions.EDIT_PARTY_SUCCESS:
      return {
        ...state,
        partyList: state.partyList.map(list => list.id == action.payload.id ? action.payload : list)
      }
    case actions.PARTY_CRUD:
      return {
        ...state,
        partyCrud: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        partyCrud: {
          ...state.partyCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.PARTIES_LOADER:
      return {
        ...state,
        partiesLoader: action.payload
      }
    default:
      return state;
  }
}
