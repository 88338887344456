import React, { Component } from 'react';
import { Link } from 'react-router';
import CKeditor from "../../../../../shared/CKEditor";
export default class IntroductionEditor extends Component {
  constructor(props){
		super(props);
		this.state = {
			formIntroduction : {}
		}
	}
	componentDidMount(){
		let dataObj = Object.assign({}, this.props.formIntroduction);
		if(!Object.keys(dataObj.answer).length){
			dataObj = {
				...dataObj,
				answer : {
					value : dataObj.prefill
					}
				}
			}
		this.setState({
			formIntroduction: dataObj,
			})
		}
		componentWillReceiveProps(nextProps){
			this.setState(prevState => ({
			  ...prevState,
			  formIntroduction: {
				...prevState.formIntroduction,
				title: nextProps.formIntroduction.title
			  }
			}));
	  }
  	updateForm = (value) => {
  		this.setState(prevState => ({
  			...prevState,
  			formIntroduction : {
  				...prevState.formIntroduction,
  				title: value
  			}
  		}))
  	}

    updateIntroduction = (evt) =>{
      var newContent = evt.editor.getData();
	    this.setState(prevState => ({
        ...prevState,
        formIntroduction : {
          ...prevState.formIntroduction,
		      answer: {
            ...prevState.formIntroduction.answer,
	          value: newContent
          }
        }
      }))
    }
  	updateFormIntroduction = (data) => {
  		this.props.updateFormIntroduction(data)
  	}
    render(){
      const { formIntroduction } = this.state;
      const { formInfo } = this.props;
  		if(!(formIntroduction && Object(formIntroduction.length))){
  			return false
  		}
      return(
        <div>
          <div className="sidebar-form introduction-editor" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
        		<CKeditor
        			events={{"change": this.updateIntroduction}}
        			content= {formIntroduction.answer  ? formIntroduction.answer.value : ''}
        		/>
          </div>
          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <li className="list-inline-item">
      						<input className="primary-btn" disabled={this.props.findingLoader} onClick={() => this.updateFormIntroduction(this.state.formIntroduction)}  type="button" value={this.props.findingLoader ? "Processing..." : "Save Changes"} />
      					</li>:null
              }
    					<li className="list-inline-item">
    						<Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
    					</li>
            </ul>
          </div>
        </div>
	    )
   }
}
