import React, { Component } from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import { ContrListCol } from '../../Elements';
export default class InsuranceRow extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { insurance } = this.props;
    const insuranceObj = [
      { value: insurance.insurance_type && insurance.insurance_type.label ? insurance.insurance_type.label : '--'},
      { value: insurance.insurance_number ? insurance.insurance_number : '--' },
      { value: insurance.insurer ? insurance.insurer : '--' },
      { value: insurance.insuree ? insurance.insuree : '--' },
      { value: moment(insurance.policy_start_date).format(displayDateFormat) + ' to ' + moment(insurance.policy_end_date).format(displayDateFormat)},
      { value: insurance.insurance_amount ? <NumericLabel params={showCurrencyFormat(insurance.currency)}>{insurance.insurance_amount}</NumericLabel>:'--', style: {width: '150px', wordBreak: 'break-all'} }
    ];
    return(
      <tr onClick={this.props.insuranceView}>
        { insuranceObj.map((insurance,index) => <ContrListCol colObj={insurance} key={index}/>) }
      </tr>
    )
  }
}
