import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numberWithCommas } from '../../../utils';
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

function genNum(num) {
  return num.toFixed(2)
}

function customToolTip (point){

  let tlTip = '';
  tlTip += '<b>' + point.name + '</b>:<br/>';
  tlTip += '<br/><b>Fund</b>:'+ (point.name.includes('EUR') ? '€ ':'$') +  numberWithCommas(parseFloat(point.y).toFixed(2)) + '<br/>';
  return tlTip
}

const DbPieChart = (props) => {
  const options = {
    chart: {
      type: 'pie',
      backgroundColor:'rgba(255, 255, 255, 0.0)',
      height: 150,
      margin: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 0
      }
    },
    title: {
      text: props.title
    },
    lang: {
      noData: "No Records Found"
    },
    noData: {
      style: {
          fontWeight: 'normal',
          fontSize: '18px',
          color: '#fff',
          fontFamily: 'Conv_IBMPlexSans-Regular'

      }
    },
    subtitle: {
      text: props.subTitle
    },
    tooltip: {
      formatter: function () {
        return customToolTip(this.point)
      },
      shared: true
  },
    plotOptions: {
      pie: {
        depth: 10,
        size:'100%',
        dataLabels: {
          connectorWidth: 0
        }
      },
      series: {
        animation: false,
        dataLabels: {
          enabled: false,
          format: '{point.name}: {point.y:.1f}%'
        }
      }
    },
    series: props.series,
    exporting: { enabled: false },
    credits: { enabled: false },
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default DbPieChart;
