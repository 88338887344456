import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchReviewDataAc, fetchFormsFeedbackAc,addIssuesAc,fetchIssueDetailAc,changeIssueStatusAc,updateCommentsAc,addCommentsAc,notifyClientAc, auditSectionInfoAc } from '../actionCreators';
import {imgStoragePath} from "../../../../../constants";
import {AircraftBluePrints, SecondaryTechHeaderMid, SecondaryTechHeaderRH,EditFormBar} from "../../../Elements";
import {fetchFindingSectionsAc} from "../../T007/actionCreators";
import { Row, Col } from 'reactstrap';
import {ReviewIssueDrawer,ReviewHeader,IssueDetail} from "../components";
import {MSNSwitcherHeader,ListLoader} from "../../../../../shared";
import { TOGGLE_DETAILS, TOOGLE_ADD_ISSUES, TOOGLE_ISSUES_LIST,TOOGLE_EDIT_ISSUES } from '../actions';
import {getFormDetailsAc} from "../../T001/actionCreators";
import AddIssues from '../components/AddIssues';
import EditIssues from '../components/EditIssues';
class ReviewT007 extends Component {
  constructor(props){
    super(props);
    this.state = {
      aircraftSectionId:'',
      editIssue:'',
    };
  }
  componentDidMount(){
    this.props.fetchReviewData();
    this.props.getFormDetails();
    this.props.fetchFormsFeedback();
  }
  toggleComments=(id)=>{
    this.setState({

      aircraftSectionId: id?id:'',

      });
      this.props.toggleComment();
  };
  toggleIssues=()=>{
    this.props.toggleIssue();
  };
  updateComments=(value)=>{
    let data={
      description:value,
      form_id:this.props.formInfo.current_form.id,
      aircraft_section_id:this.state.aircraftSectionId,
  }
    this.props.addIssue(data);
  }

  toggleDetail=(issue) => {
   issue?this.props.reviewFeedBackDetail(issue.location_slug):this.props.toggleDetails();
  };

  toggleEditIssues=()=>{
    this.props.toggleEditIssue();
  };

  toggleEdits=(e,issue,status)=>{
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      editIssue:issue,
    });
    this.props.toggleEditIssue();
  };

  changeStatus = (e,issue,status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(issue.location_slug, status);
  };

  changeStatusDetails = (e,location_slug,status) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.reviewIssueStatusChange(location_slug, status,'details');
};

deleteStatus = (e,issue,status) => {
  e.stopPropagation();
  e.preventDefault();
  this.props.reviewIssueStatusChange(issue.location_slug, status,'delete');
};

updateIssues=(value)=>{
  this.props.reviewIssueStatusChange(this.state.editIssue.location_slug, value,'edit');
}

updateCommentStatus=(e,location_slug,commentId)=>{
  e.stopPropagation();
  e.preventDefault();
  this.props.updateCommentStatus(location_slug, commentId);
}


  reviewUIComp = (issue={},id) => {

    if(issue.order_number==null||issue.order_number==undefined){
      return (
          <span  onClick={()=>this.toggleComments(id)} className="comment-icon" >
              <img src={imgStoragePath+"cmnt_active.svg"} className="blue-icon" alt=""/>
              <img src={imgStoragePath+"cmnt-inactive.svg"} className="grey-icon" alt=""/>
          </span>

      )
    }else{
      return (
          <span  onClick={()=>this.toggleIssues()} className="issue-number" >
          <span>#{issue.order_number}</span>
        </span>
      )
    }
  }
  render(){
    const { t007ReviewList,notifyFlag } = this.props;
    let customClass = '';
    if(Object.keys(t007ReviewList).length && t007ReviewList.list.length){
      switch(t007ReviewList.aircraft_model.blueprint.id){
        case 1 :
          customClass = 'model-two';
          break;
        case 2 :
          customClass = 'model-three';
          break;
        case 3:
          customClass = 'model-four';
          break;
        case 4:
          customClass = 'model-eight';
          break;
        case 5:
          customClass = 'model-eight';
          break;
        case 6:
          customClass = 'model-one';
          break;
        case 7:
          customClass = 'model-one';
          break;
        case 8:
          customClass = 'model-nine';
          break;
        case 9:
          customClass = 'model-nine';
          break;
        case 10:
          customClass='model-seven';
        default:
        case 11:
          customClass ='model-eleven';
        case 12:
          customClass ='model-eleven model-embarier-165';
          break;
      }
    }
    
    return (
      <div className="technical-review-model">
        {Object.keys(this.props.formInfo).length ?
            <MSNSwitcherHeader
                SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
                SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset}/>}
                activeAsset={this.props.formInfo.asset}
                projectSlug = {Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug: ''}
            />:null
        }
        <ReviewHeader
            formInfo = {this.props.formInfo}
            notifyFlag={notifyFlag}
            notifyClient={this.props.notifyClient}
            workOrderSlug={this.props.params.workOrderSlug}
        />
        {this.props.commentsLoader?<ListLoader/>:null}
        <div className="form-t007">
            <div className="technical-inspection-cn">
              <div className="form-t004-cn">
                <div className="indicater-detail-block">
                  { Object.keys(t007ReviewList).length && t007ReviewList.list.length ?
                      <ul className="list-unstyled indicater-block">
                        <li>
                          <span className="poor"></span> Findings ({t007ReviewList.list.reduce((total, sectionList) => total + sectionList.findings.length, 0)})
                        </li>
                      </ul>
                      :null
                  }
                </div>
                <div className={customClass+' model-wrapper'} >
                  { Object.keys(t007ReviewList).length && t007ReviewList.list.length ?
                      <div className="model-image" >
                        <img className="model-image" src={imgStoragePath + t007ReviewList.aircraft_model.blueprint.image} alt="img" />
                        { t007ReviewList['list'].map((item, index) =>
                            <AircraftBluePrints
                                auditSectionInfo={() => this.props.auditSectionInfo(item)}
                                sectionDetail={item}
                                key={index}
                                findingClickable={false}
                            />
                        )}
                      </div>
                      :null
                  }
                </div>
              </div>
              <Row className="inspection-info-row">
                <Col md="12">
                  <h2>Place of Survey</h2>
                  { t007ReviewList.place_of_survey ?
                    <p>{t007ReviewList.place_of_survey} </p>
                    : null
                  }
                </Col>
                <Col md="12">
                  <h2>Performed By</h2>
                  { t007ReviewList.performed_by_user ?
                      <p>{t007ReviewList.performed_by_user}</p>
                    : null
                  }
                </Col>
                <Col md="12">
                  <h2>Date of Survey</h2>
                  { t007ReviewList.date_of_survey ?
                      <p>{t007ReviewList.date_of_survey}</p>
                    : null
                  }
                </Col>
              </Row>
              <Row className="inspection-list">
                {  t007ReviewList.list ?  t007ReviewList.list.map((section, sectionIndex) =>
                    (() => {
                      if(section.findings.length){
                        return(
                            <Col md="12" key={sectionIndex}>
                              <h2>
                                {section.section_name} {this.reviewUIComp(section.issue,section.id)}
                              </h2>
                              {section.findings ? section.findings.map((findings, index) =>
                                  <div className="finding-row" key={index}>
                                    <p><span>#{index+1}</span></p>
                                    <div className="finding-list">
                                      <table>
                                        <tr>
                                          <th><b>Remark</b></th>
                                          <td>:</td>
                                          <td>
                                            <span>{findings.remarks}</span>
                                            <span className="review-btn-blk">

                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th><b>Type Of Damage</b></th>
                                          <td>:</td>
                                          <td>
                                            <span>{findings.finding_type}</span>
                                            <span className="review-btn-blk">

                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th><b>Damage Dimension</b> </th>
                                          <td>:</td>
                                          <td>
                                            <span>{findings.damage_dimension}</span>
                                            <span className="review-btn-blk">

                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th><b>Damage Limit</b> </th>
                                          <td>:</td>
                                          <td>
                                            <span>{findings.damage_limit}</span>
                                            <span className="review-btn-blk">

                                            </span>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <Row className="finding-row">
                                      {findings.images ? findings.images.map((image, imageIndex) =>
                                          <Col md="6" key={imageIndex}>
                                            <img className="findings-img" src={image.image} alt=""/>

                                          </Col>
                                      ):null}
                                    </Row>
                                  </div>
                              ):null}
                            </Col>
                        )
                      }
                    })()
                ):null}
              </Row>
            </div>
        </div>
        <a href="javascript:void(0)" className="view-issues-btn" onClick={()=>this.toggleIssues()} >
                <img src={imgStoragePath+'icon_CommentDash.svg'}  alt="" />
            </a>
            <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Post Issues'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.props.toggleComments}
          style={this.props.toggleComments ? {width: '400px'} : {width: '0px'}}>
            {this.props.toggleComments?
                <AddIssues
                  updateComments={this.updateComments}
                  toggleEditSideBar={() => this.toggleComments()}
                />:null
            }
        </EditFormBar>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Edit Issue'} </h3></div>}
          toggleEditSideBar={() => this.toggleEditIssues()}
          isOpen={this.props.toggleEditIssues}
          style={this.props.toggleEditIssues ? {width: '400px'} : {width: '0px'}}>
            {this.props.toggleEditIssues?
                <EditIssues
                  updateComments={this.updateIssues}
                  issueData={this.state.editIssue}
                  toggleEditSideBar={() => this.toggleEditIssues()}
                />:null
            }
        </EditFormBar>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Issues List'} </h3></div>}
           toggleEditSideBar={() => this.toggleIssues()}
            isOpen={this.props.toggleIssues}
             style={this.props.toggleIssues ? {width: '400px'} : {width: '0px'}}>
             {this.props.toggleIssues?
              <ReviewIssueDrawer
                issueList = { this.props.reviewFeedbacksList }
                toggleEditSideBar={() => this.toggleComments()}
                toggleDetailBar = {this.toggleDetail}
                changeStatus = {this.changeStatus}
                deleteStatus={this.deleteStatus}
                editStatus={this.toggleEdits}
               />:null}
          </EditFormBar>
          <EditFormBar
              titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Issues Discussion'} </h3></div>}
              toggleEditSideBar={() => this.toggleDetail()}
              isOpen={this.props.toggleDetail}
              style={ this.props.toggleDetail ? {width: '400px'} : {width: '0px'}}>
              {this.props.toggleDetail?
                <IssueDetail
                    issueDetailInfo={this.props.issueDetailInfo}
                    toggleEditSideBar={() => this.toggleDetail()}
                    toggleSideBars={() => this.toggleIssues()}
                    addComments={this.props.addComments}
                    changeStatus = {this.changeStatusDetails}
                    updateCommentStatus={this.updateCommentStatus}
                />
              :null}
          </EditFormBar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  t007ReviewList: state.FormReviewReducer.t007ReviewList,
  formInfo:state.FormT001Reducer.formInfo,
  reviewFeedbacksList: state.FormReviewReducer.reviewFeedbacksList,
  toggleComments: state.FormReviewReducer.toggleComments,
  toggleIssues: state.FormReviewReducer.toggleIssues,
  toggleDetail: state.FormReviewReducer.toggleDetail,
  toggleEditIssues: state.FormReviewReducer.toggleEditIssues,
  issueDetailInfo:state.FormReviewReducer.issueDetailInfo,
  commentsLoader:state.FormReviewReducer.commentsLoader,
  notifyFlag:state.FormReviewReducer.notifyFlag
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return {
    auditSectionInfo: (item) => dispatch(auditSectionInfoAc(ownProps, item)),
    fetchReviewData: () => dispatch(fetchReviewDataAc(ownProps, 't007')),
    getFormDetails: () => dispatch(getFormDetailsAc(ownProps)),
    fetchFormsFeedback: () => dispatch(fetchFormsFeedbackAc(ownProps, 't007')),
    reviewFeedBackDetail: (locationSlug) => dispatch(fetchIssueDetailAc(ownProps, locationSlug, 't007')),
    addIssue:(data)=>dispatch(addIssuesAc(ownProps,data,'t007')),
    notifyClient:()=>dispatch(notifyClientAc(ownProps)),
    addComments:(location_slug,message)=> dispatch(addCommentsAc(location_slug,message)),
    updateCommentStatus:(locationSlug,commentID)=>dispatch(updateCommentsAc(ownProps,locationSlug,commentID)),
    reviewIssueStatusChange: (locationSlug,status,flag='') => dispatch(changeIssueStatusAc(ownProps, locationSlug,status, 't007',flag)),
    toggleComment: () => dispatch({
      type: TOOGLE_ADD_ISSUES,
      payload: {}
    }),
    toggleIssue: () => dispatch({
      type: TOOGLE_ISSUES_LIST,
      payload: {}
    }),
    toggleEditIssue: () => dispatch({
      type: TOOGLE_EDIT_ISSUES,
      payload: {}
    }),
    toggleDetails: () => dispatch({
      type: TOGGLE_DETAILS,
      payload: {}
    }),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewT007);
