import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BillingSidebar, InvoiceDetailList } from '../components';
import { ordersDetailtAc, exportInvoiceAc } from '../actionCreators';
import { imgStoragePath } from '../../../constants';
import { ListLoader } from '../../../shared'
import { toastFlashMessage } from '../../../utils'
import { globalPostService } from '../../../globalServices'
import { PAYMENT_FAILED } from '../../../shared/actions'
import { getNotificationBarDataAc } from '../../../shared/actionCreators'
import { LICENSE_LOADER } from '../../licenses/actions'
import { getSavedCardsAc } from '../../licenses/actionCreators'
import { PaymentFailed, CardList } from '../../licenses/components'

import moment from 'moment';
class InvoiceDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
          paymentModal: false,
          modalObj: {
            card_id: '',
            customer: ''
          }
        }
        this.toggle = this.toggle.bind(this);
    }

    payInvoiceDue = (payment) =>{
      this.props.toggleLoader(true)
      globalPostService(`console/order/${this.props.params.id}/confirm-pay`, payment)
      .then(response => {
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success')
          setTimeout(() => this.props.fetchOrdersDetail(this.props.params.id), 2000)
        }else{
          this.props.cloaseFailedModal(true)
        }
        this.setState({paymentModal: false})
        this.props.toggleLoader(false)
        setTimeout(() => this.props.getNotificationBarData(), 2000)
      })
    }

    componentDidMount(){
        let orderId = this.props ? this.props.routeParams.id  :  null ;
        if(orderId){
            this.props.fetchOrdersDetail(orderId);
        }
        this.props.getSavedCards()
    }
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }
    render(){
        const { orderDetail, orderLoader, cardsList, licenseLoader } = this.props;
        return(
            <div className="billing-container">
                <BillingSidebar />
                { Object.keys(orderDetail).length ?
                <div className="container-fluid billing-content-wrapper">
                    <h1 className="page-title">Invoices</h1>
                    <Row>
                        <Col md="8">
                            <div className="invoice-description">
                                <Link to="/billing/invoices" className="back-to-invoices">&larr; Return to Billing History</Link>
                                <Row>
                                    <Col md="6">
                                        <p className="from-address">
                                            <span>From,</span>
                                            Acumen Aviation Europe Limited
                                            Canal House, Northumberland Road,
                                            Dublin 4, D04 P9V4
                                        </p>
                                        <p className="from-address" style={{ marginTop: '30px' }}>
                                              <span>For,</span>
                                            { orderDetail.name ? orderDetail.name :  orderDetail.billing_address.name ? orderDetail.billing_address.name : null } <br/>
                                              {
                                                orderDetail.contact_address ?
                                                <span>{orderDetail.contact_address}</span>
                                                :<span>
                                                  { orderDetail.billing_address.address_line1 } <br/>
                                                  { orderDetail.billing_address.address_line2 } <br/>
                                                  { orderDetail.billing_address.city } <br/>
                                                  { orderDetail.billing_address.state } <br/>
                                                  { orderDetail.billing_address.country } <br/>
                                                  { orderDetail.billing_address.postal_code } <br/>
                                                </span>
                                              }
                                          </p>
                                        <ul className="list-inline">
                                            <li className="list-inline-item">{orderDetail.billing_address.phone}</li>
                                            {
                                                (orderDetail.billing_address.phone && orderDetail.billing_address.email) ?
                                                <li className="list-inline-item">|</li>
                                                : null
                                            }
                                            <li className="list-inline-item">{orderDetail.billing_address.email}</li>
                                        </ul>
                                    </Col>
                                    <Col md="6" className="export-grid">
                                        <p className="donwload-invoice">Download: <span className="export-link" onClick={(e)=> { this.props.exportInvoice(e, orderDetail.id, 'pdf') }}>PDF</span> - <span className="export-link" onClick={(e)=> { this.props.exportInvoice(e, orderDetail.id, 'csv') }}>CSV</span></p>
                                        <div className="invoice-card">
                                            <Row>
                                                <Col md="12">
                                                    <span>Invoice Number</span>
                                                    <h5>#{orderDetail.reference_number}</h5>
                                                </Col>
                                            </Row>
                                            {
                                                orderDetail.status.value == 1 ?
                                                <Row>
                                                    <Col md="6">
                                                        <span>Invoice date</span>
                                                        {
                                                            orderDetail.generated_for ?
                                                            <p>{moment(orderDetail.generated_for).format('MMMM DD, YYYY')}</p>
                                                            :<p></p>
                                                        }
                                                    </Col>
                                                    {
                                                        <Col md="6">
                                                            <span>Payment date</span>
                                                            {
                                                                orderDetail.payment_date ?
                                                                <p>{moment(orderDetail.payment_date).format('MMMM DD, YYYY')}</p>
                                                                :<p></p>
                                                            }
                                                        </Col>
                                                    }
                                                </Row>:
                                                <Row>
                                                    <Col md="6">
                                                        <span>Invoice date</span>
                                                        {
                                                            orderDetail.generated_for ?
                                                            <p>{moment(orderDetail.generated_for).format('MMMM DD, YYYY')}</p>
                                                            :<p></p>
                                                        }
                                                    </Col>
                                                    {
                                                        <Col md="6">
                                                            <span>Due date</span>
                                                            {
                                                                orderDetail.due_date ?
                                                                <p className="error-class">{moment(orderDetail.due_date).format('MMMM DD, YYYY')}</p>
                                                                :<p></p>
                                                            }
                                                        </Col>
                                                    }
                                                </Row>
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {
                                orderDetail.items && orderDetail.items.length ?
                                <InvoiceDetailList items={orderDetail.items} totalCost={orderDetail.total} />:
                                null
                            }
                        </Col>
                        <Col md="4">
                            <div className="current-amount-due" style={ orderDetail.status && orderDetail.status.value != 1 ? {background: 'rgb(255, 255, 194)'} : {}}>
                                <Row className="flex-centered">
                                    <Col md="6" >
                                        <h3>Current amount due</h3>
                                        <h5>
                                            $ { orderDetail.status && orderDetail.status.value != 1 ? orderDetail.sub_total : 0}
                                        </h5>
                                    </Col>
                                    <Col md="6 text-center" style={orderDetail.status && orderDetail.status.value != 1 &&  orderDetail.sub_total > 0 ? {} : {display: 'none'}}>
                                        <Link onClick={(evt) => this.setState({paymentModal: true})}>Make a Payment</Link>
                                    </Col>
                                </Row>
                                {/* <p className="due-date" >
                                    Payment due on 2nd May 2019
                                </p> */}
                            </div>
                            <div className="billing-detail">
                                <h3><img src={imgStoragePath+'phase-2/info.png'} />About Invoices</h3>
                                <p>
                                    Invoices are generated on 1st of every month and to be paid within 7 days
                                    <br/>
                                    <br/>
                                  If auto debit is enabled, amount will be debited from Card/Account automatically
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                :null}
                {
                  orderLoader ?
                    <ListLoader/>
                  :null
                }
                <Modal isOpen={this.state.paymentModal} centered={true} toggle={() => !this.props.licenseLoader ? this.setState({paymentModal: false, showNewCard: false, modalObj: {card_id: '', customer: ''}}) : null} className="payment-modal" size="sm">
                  <ModalHeader toggle={() => !this.props.licenseLoader ? this.setState({paymentModal: false, showNewCard: false, modalObj: {card_id: '', customer: ''}}) : null}>
                    {
                      this.state.showNewCard ? 'New Credit / Debit Card' : 'Credit / Debit Card'
                    }
                  </ModalHeader>
                  <ModalBody>
                    {
                      !this.state.showNewCard ?
                      <div className="cardsList">
                        <div className="inner-con">
                          {
                            cardsList && cardsList.list && cardsList.list.length ?
                              cardsList.list.map((card, key) => <CardList key={key} selected={this.state.modalObj.card_id} selectCard={() => this.setState({modalObj: {card_id: card.id, customer: card.customer}})} card={card}/>)
                            :null
                          }
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <button disabled={licenseLoader || this.state.modalObj.card_id == ''} onClick={() => this.payInvoiceDue({card_token: this.state.modalObj.card_id})} className="add-new btn-primary"><i className="fa fa-lock"></i> {licenseLoader ? 'Processing....' : 'Pay'}</button>
                          </div>
                        </div>
                      </div>
                      :null
                    }
                  </ModalBody>
                </Modal>
                <PaymentFailed
                  isOpen={this.props.failedPayment}
                  toggle={this.props.cloaseFailedModal}
                  retry={() => {this.props.cloaseFailedModal(false);this.setState({
                    paymentModal: true
                  })}}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orderDetail: state.BillingReducer.orderDetail,
    orderLoader: state.BillingReducer.orderLoader,
    licenseLoader: state.LicenseReducer.licenseLoader,
    failedPayment: state.sharedReducers.failedPayment,
    cardsList: state.LicenseReducer.cardsList,
});

const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        fetchOrdersDetail : (orderId) => dispatch(ordersDetailtAc(orderId)),
        exportInvoice : (e, orderId, type) => dispatch(exportInvoiceAc(e, orderId, type)),
        toggleLoader: (flag) => dispatch({
          type: LICENSE_LOADER,
          payload: flag
        }),
        cloaseFailedModal : (flag) => dispatch({
          type: PAYMENT_FAILED,
          payload: flag
        }),
        getSavedCards : () => dispatch(getSavedCardsAc()),
        getNotificationBarData: () => dispatch(getNotificationBarDataAc())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
