import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, prettyNumberParamsL } from '../../../../constants';
const UpcomingEvents = (props) => {
  return(
    <div className="events-list">
      <span className="event-date">{moment(props.event.eventDate).format(displayDateFormat)}</span>
      <span className="dots-marker"></span>
      <Row className="flex-centered">
        <Col>
          <h4>{props.event.name}</h4>
          { moment() > moment(props.event.eventDate)?
            <p className="error-msg" style={{fontSize: '12px'}}>Shop visit is overdue! Please update historical shop visit data under Maintenance of the asset.</p>:null
          }
          {
            props.event.exceeding_contract ?
            <p style={{color: '#DFD700', fontSize: '12px', marginTop: '6px'}}>Upcoming shop visit exceeds active contract end date</p>:null
          }
        </Col>
        <Col>
          <span>Escalated Event cost as of Event Date</span>
          <h6>
            <NumericLabel params={prettyNumberParamsL}>
              {props.event.eventCost}
            </NumericLabel>
          </h6>
        </Col>
        <Col>
          <span>Escalated MR Rate as of Event Date</span>
          <h6>
            <NumericLabel params={prettyNumberParamsL}>
              {props.event.mr}
            </NumericLabel>
          </h6>
        </Col>
        {
          props.event.fundCollected ?
          <Col>
            <span>Fund Status at the time of event</span>
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {props.event.fundCollected}
              </NumericLabel>
            </h6>
          </Col>:null
        }
        {
          props.event.surPlus ?
          <Col>
            <span>Fund Balance</span>
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {props.event.surPlus}
              </NumericLabel>
            </h6>
          </Col>:null
        }
      </Row>
    </div>
  )
}
export default UpcomingEvents;
