import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addLeaseOption } from '../../contractAddObject';

export const leaseOptionListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.LEASE_OPTION_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/lease-options/', {})
    .then(response => {
      dispatch({
        type: actions.LEASE_OPTION_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LEASE_OPTION_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const leaseOptionAddEditAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/lease-options/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LEASE_OPTION_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.LEASE_OPTION_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `EOL Options`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `EOL Options`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/lease-options/' + data.id + '/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LEASE_OPTION_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.LEASE_OPTION_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `EOL Options`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `EOL Options`,
            item_id: data.id
          })
        }
      })
    }
  }
}


export const leaseOptionCrudFnAc = (params, type, id) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.LEASE_OPTION_CRUD,
        payload: {type: type, data:addLeaseOption , flag: true }
      });
    }else{
      dispatch({
        type: actions.LEASE_OPTION_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/lease-options/' + id +'/',{})
      .then(response => {
        dispatch({
          type: actions.LEASE_OPTION_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LEASE_OPTION_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}
