import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
const DateDisplayCard = (props) => {
  return (
    <Col md={props.flag?"4":"6"}>
      <div className="project-date-card">
        <label className="for-label">{props.label}</label>
        {props.date?
        <div>
        <h6>{moment(props.date).format('MMM').toUpperCase()}</h6>
        <h3 style={{fontWeight:'bold'}}>{moment(props.date).date()}</h3>
        <h6>{moment(props.date).year()}</h6></div>
        :<div style={{height:'50px'}}>
           <h3 style={{fontWeight:'bold',marginTop:'20px'}}>{'--'}</h3>
        </div>
        }
      </div>
    </Col>
  )
}
export default DateDisplayCard;
