import React, { Component } from 'react';
export default class UtilizationRow extends Component {
 constructor(props) {
  super(props);
 }
 render() {
  return (
   <thead>
    <tr>
     <th>Major Assembly</th>
     <th>Avg .FH</th>
     <th>Avg. FC</th>
     <th>TSN ( Hrs )</th>
     <th>CSN ( Cycles )</th>
    </tr>
   </thead>
  )
 }
}
