import React from 'react';
import { Link } from 'react-router';
import { ProcessingPopover } from './'
import { imgStoragePath } from '../../../../constants';
import { getFileSize, localTimeFn } from '../../../../utils';

const BatchList = (props) =>{
  return(
    <div>
        <Link onClick={props.getBatchView && props.batch.status == 1 ? props.getBatchView : null}>
           <ul className="list-inline row">
               <li  className="col-md-2 list-inline-item flex-centered">
                   <div>
                       <label>Batch No</label>
                       <p>{props.batch.name}</p>
                   </div>
               </li>
               <li  className="col-md-2 list-inline-item flex-centered">
                   <div>
                       <label>No of files uploaded</label>
                       <p>{props.batch.file_count}</p>
                   </div>
               </li>
               <li  className="col-md-1 list-inline-item flex-centered">
                   <div>
                   <label>Size</label>
                       <p>{getFileSize(props.batch.size)}</p>
                   </div>
               </li>
               <li className="col-md-2 list-inline-item flex-centered">
                   <div>
                   <label>Uploaded By</label>
                   <div className="flex-centered user-block">
                       <img src= {props.batch.created_by.profile_pic != null ? props.batch.created_by.profile_pic :  imgStoragePath + "user.png"} />
                       <p>{props.batch.created_by.first_name} {props.batch.created_by.last_name}</p>
                   </div>
                   </div>
               </li>
               <li  className="col-md-2 list-inline-item flex-centered">
                   <div>
                   <label>Uploaded On</label>
                   <p>{localTimeFn(props.batch.created_at)}</p>
                   </div>
               </li>
               <li  className="col-md-2 list-inline-item flex-centered">
                   <div>
                       <label>Status</label>
                       {
                         props.batch.status == 0 ?
                         <p>
                             <img className="loading-img" src={imgStoragePath+"rolling.gif"} alt="img" width="16" style={{marginRight: '6px' }} id={'Popover-'+props.index}/>
                             Processing
                             <ProcessingPopover
                                 placement= 'top'
                                 tagetId={'Popover-'+props.index}
                                 assets = {[]}
                             />
                         </p>
                         :props.batch.status == 1 ?
                           <p>
                              <img className="processed-img" src={imgStoragePath + 'file_completed.png'} alt="img"/>
                               Processed
                           </p>
                          :<p>
                              Published
                          </p>
                       }
                   </div>
               </li>
           </ul>
       </Link>
   </div>
  )
}
export default BatchList
