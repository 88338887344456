import { globalGetService, globalPutService, globalPostService, globalStaticPostService, globalDeleteService, globalExportService } from '../../../../globalServices/';
import { toastFlashMessage, downloadFileType} from '../../../../utils'
import { trackActivity } from '../../../../utils/mixpanel'
import moment from 'moment'
import { displayDateTimeFormatShort } from '../../../../constants'
import { T011SectionCrud } from './';
const sectionIds = [5,6,7,8,9,10,14,15,16,17,21,22,23,24,25];
const assetTypes = {
  1: 'MSN',
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export function exportReport(aircraft){
  let formInfo = this.state.formInfo
  this.setState({ pageLoader: true });
  globalExportService(`technical/workorder/${this.props.params.workOrderSlug}/gap-report-sections/?download=xls`)
  .then(response => {
    this.setState({ pageLoader: false });
    let formInfo = this.state.formInfo
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    trackActivity('Report Exported',{
      application_module: 'Inspection',
      asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3,4,5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
      page_title: formInfo.current_form.name.toUpperCase(),
      file_name: `PRS Report`,
      file_type: 'XLS'
    })
    downloadFileType(response, assetTypes[formInfo.asset.asset_type]+'_'+(formInfo.asset.msn ? formInfo.asset.msn : formInfo.asset.serial_number) +'_T011.', 'xls');
  })
}

export function getTableContentsApi(props, queryParam={}, source){
  this.setState({
    t011ReportInfo:{items:null, list:[]},
    pageLoader: source === 'init'
  })
  let queryFilter = {}
  Object.keys(queryParam).map(key => {
    if(queryParam[key] !== undefined && queryParam[key] !== null && queryParam[key] !== ""){
      queryFilter = {...queryFilter, [key]:queryParam[key]}
    }
  })
  globalGetService('technical/workorder/'+props.params.workOrderSlug+'/gap-report-sections/', queryFilter)
  .then(response => {
    this.setState({pageLoader: false})
    if(response.data.statusCode == 200){
      if(this.props.params.tab_name && this.props.params.tab_name !== ''){
        this.setState({
          t011ReportInfo:response.data.data,
          fullScreen: this.state.fullScreen,
          gap_report_ids: [],
          // sectionId:response.data.data.list && response.data.data.list.length && response.data.data.list.filter(item => item.name === this.props.params.tab_name).length ? response.data.data.list.filter(item => item.name === this.props.params.tab_name)[0].id : 0}, () => this.getTableSectionsApi(props,{ section: this.state.sectionId
          sectionId:this.props.params.tab_name}, () => this.getTableSectionsApi(props,{ section: this.state.sectionId
            ,...queryFilter}, source));
            this.getUserStatsApi(this.props);
      }else{
        this.setState({t011ReportInfo:response.data.data, sectionId:0, fullScreen: this.state.fullScreen,});
      }
    }
  });
}

export function fetchActivityLogs(queryParams){
  this.setState({ pageLoader: true });
  let params = queryParams && queryParams.gap_report_section_id !== undefined && queryParams.gap_report_section_id !== 0 ? {per_page: 200, ...queryParams} : {per_page: 200}
  globalGetService(`technical/workorder/${this.props.params.workOrderSlug}/deleted-gap-reports/`, params)
  .then(response => {
    this.setState({ pageLoader: false });
    if(response.data.statusCode === 200){
      this.setState({searchLog:'',activityLogs: response.data.data.list.filter(item => item.deleted_by !== undefined && item.deleted_by !== null && item.deleted_by !== '').map(log => `<span style="font-weight: 600;font-family: system-ui;">${log.deleted_by}</span> deleted item <span style="font-weight: 600;font-family: system-ui;">${log.description}</span> from Section: <span style="font-weight: 600;font-family: system-ui;">${log.gap_report_section}</span>, <span style="font-weight: 600;font-family: system-ui;">${moment(log.deleted_at).format(displayDateTimeFormatShort) + ' UTC'}</span>`), showActivityLogs: true})
    }
  })
}

export function getTableSectionsApi(props, queryParam, source){
  let queryFilter = {}
  Object.keys(queryParam).map(key => {
    if(queryParam[key] !== undefined && queryParam[key] !== null && queryParam[key] !== ""){
      queryFilter = {...queryFilter, [key]:queryParam[key]}
    }
  })
  if(queryFilter.section !== undefined && queryFilter.section !== null && queryParam.section >= 0){
    this.setState({ pageLoader: source === 'init', t011SectionInfo: {items:null, list:[]} });
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/gap-report/', {...queryFilter, per_page: 1500})
    .then(response => {
      this.setState({ pageLoader: false });
      if(response.data.statusCode == 200){
        this.setState({gap_report_ids: [],t011SectionInfo:{...response.data.data, list: response.data.data.list.map((item, itemIndex) => {
          let innerItem = {...item, order: itemIndex + 1}
          return innerItem
        })}, t011SectionInfoFiltered: response.data.data.list.map((item, itemIndex) => {
          let innerItem = {...item, order: itemIndex + 1}
          return innerItem
        }), sectionId: queryParam.section, fullScreen: this.state.fullScreen}, () => this.applyFilter())
        this.getUserStatsApi(this.props);
      }
    });
  }
}
export function updateTableSectionsFieldApi(props, data, id, sectionId){
  let form_data = new FormData();
  let isFile = false
  let keyParam
    for ( var key in data ) {
      keyParam = key !== 'gap_report_section_id' ? key : keyParam
      if(key !== 'files'){
        form_data.append(key, data[key]);
      }else{
        for(let f of data.files){
          form_data.append('files', f)
        }
        isFile = true
      }
   }
  globalPutService(`technical/workorder/${props.params.workOrderSlug}/gap-report/${id}/`, form_data)
  .then(response => {
    let innerListData = this.state.t011SectionInfo
    if(isFile){
      this.setState({pageLoader: true, t011SectionInfo :{pagination: {}, list: []}, t011SectionInfoFiltered: []})
    }else{
      this.setState({t011SectionInfo :{pagination: {}, list: []}, t011SectionInfoFiltered: []})
    }
    this.setState({pageLoader: false})
    if(response.data.statusCode == 200){
      // this.setState({t011SectionInfo:response.data.data, sectionId: queryParam.section})
      toastFlashMessage(response.data.message, 'success');
      this.setState({t011SectionInfo :{pagination: {}, list: []}}, () => {
        this.setState({t011SectionInfo: {...innerListData, list: innerListData.list.map(item => {
          if(item.id === id){
            return {...response.data.data, order: item.order}
          }else{
            return item
          }
        })}, t011SectionInfoFiltered: innerListData.list.map((item, itemIndex) => {
          let innerItem = item
          if(item.id === id){
            innerItem = {...response.data.data, order: itemIndex + 1}
          }
          return innerItem
        })}, () => this.applyFilter())
      })
      if(keyParam === 'disposition'){
        this.getTableContentsApi(this.props);
      }
      // if(sectionId){
      //   this.getTableContentsApi(props, {section:sectionId, per_page: this.state.t011SectionInfo.pagination.per_page, page:this.state.t011SectionInfo.pagination.page});
      // }
    }else{
      this.getTableSectionsApi(props, {section:data.gap_report_section_id});
    }
  });
}
export function deleteTableSectionRowApi(props, id, sectionId, deleteType){
  let gap_report_ids = deleteType === 'single' ? [id] : this.state.deleteType === 'section' ? this.state.t011SectionInfo.list.map(gapList => gapList.id) : this.state.gap_report_ids
  let url = this.state.deleteType === 'prs' ? `technical/workorder/${props.params.workOrderSlug}/gap-reports/delete/?is_reset=true` : `technical/workorder/${props.params.workOrderSlug}/gap-reports/delete/`
  this.setState({pageLoader: true})
  globalDeleteService(url, {gap_report_ids})
  .then(response => {
    this.setState({pageLoader: false})
    if(response.data.statusCode == 200){
      // this.setState({t011SectionInfo:response.data.data, sectionId: queryParam.section})
      this.getTableContentsApi(this.props);
      this.setState({t011SectionInfo: {...this.state.t011SectionInfo, list: this.state.t011SectionInfo.list.filter(item => item.id !== id).map((item, itemIndex) => {
        let innerItem = {...item, order: itemIndex + 1}
        return innerItem
      })}}, () => this.applyFilter())
      this.setState({gap_report_ids: []})
      toastFlashMessage(response.data.message, 'success')
    }
  });
}
export function downloadAllFilesApi(props={},gapReportId){
  this.setState({pageLoader:true});
  globalGetService(`/technical/workorder/${props.params.workOrderSlug}/gap-report/${gapReportId}/?download=all_files`)
  .then(response => {
    this.setState({pageLoader:false});
    toastFlashMessage(response.data.message, 'success')
  })
}

export function deleteFileApi(props={}, gapReportId, deleteFileId, sectionId){
  return new Promise(function(resolve, reject){
    globalDeleteService(`technical/workorder/${props.params.workOrderSlug}/gap-report/${parseInt(gapReportId)}/file/${deleteFileId}/`)
    .then(response => {
      resolve(response);
    })
  })
}
export function updateTechnicalFormDataApi(props, data={}, sectionId, update){
  var formdata = new FormData();
  for ( var key in data ) {
    if(key == 'files' && data[key].length){
      for(let f of data[key]){
        formdata.append('files', f)
      }
    }else {
      formdata.append([key],data[key] !== undefined && data[key] !== null ? data[key]:'');
    }
  }
  formdata.append('gap_report_section_id', sectionId)
  if(!sectionIds.includes(sectionId)){
    delete data['install_date']
    delete data['position']
    delete data['part_number']
    delete data['serial_number']
  }
    globalPostService(`/technical/workorder/${props.params.workOrderSlug}/gap-report/`,formdata)
    .then(response => {
      if(response.data.statusCode === 200){
        if(update){
          this.props.closeModalOnReturn();
        }else{
          this.setState(prevState => ({
            ...prevState,
            item:T011SectionCrud
          }));
        }
        this.props.refreshList();
        toastFlashMessage(response.data.message, 'success');
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        let formInfo = this.props.formInfo
        trackActivity('Item Added',{
          application_module: 'Inspection',
          asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3,4,5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
          page_title: formInfo.current_form.name.toUpperCase(),
          item_type: 'PRS',
          item_id: response.data.data.id
        })
      }
      this.setState({ pageLoader: false });
    });
}
export function getFormInfoApi(props) {
  globalGetService('technical/workorder/' + props.params.workOrderSlug + '/forms/', { form: props.params.formSlug})
    .then(response => {
      if (response.data.statusCode == 200) {
        this.setState({ formInfo: response.data.data,});
        let formInfo = response.data.data
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        trackActivity('Page Visited',{
          application_module: 'Inspection',
          asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3,4,5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
          page_title: formInfo.current_form.name.toUpperCase()
        })
      }
    });
}
export function updateFormStatusApi(data){
  this.setState({ pageLoader: true });
  globalPostService(`technical/workorder/${this.props.params.workOrderSlug}/form/${ this.props.params.formSlug}/status-update/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
        this.getFormInfoApi(this.props);
      }
    })
}
export function importExcelApi(files) {
  var formdata = new FormData();
  formdata.append('file', files[0])
  this.setState({ pageLoader: true });
  globalStaticPostService(`technical/workorder/${this.props.params.workOrderSlug}/import-gap-report/`, formdata)
    .then(response => {
      this.setState({ pageLoader: false });
      if (response.data.statusCode == 200) {
        toastFlashMessage(response.data.message, 'success');
        let formInfo = this.props.formInfo
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        trackActivity('Report Imported',{
          application_module: 'Inspection',
          asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3,4,5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
          page_title: formInfo.current_form.name.toUpperCase(),
          file_name: `PRS Report`
        })
        this.setState({files:{}});
        this.props.closeTechForm(true);
        this.getTableSectionsApi(this.props, {section: this.props.sectionId});
        this.props.getTableContentsApi(this.props);
      }
    })
}
export function getUserListApi(props){
  this.setState({ pageLoader: true });
  globalGetService('technical/workorder/'+props.params.workOrderSlug+'/project-users/')
    .then(response => {
      this.setState({ pageLoader: false });
      if(response.data.statusCode == 200){
        this.setState({
          userList:response.data.data,
        })
      }
    });
}
export function getUserStatsApi(props){
  this.setState({ pageLoader: true });
  globalGetService('technical/workorder/'+props.params.workOrderSlug+'/assignee-plot-data/')
    .then(response => {
      this.setState({ pageLoader: false });
      if(response.data.statusCode == 200){
        this.setState({
          userStats:response.data.data,
        })
      }
    });
}

export function moveGapIdsApi(props, section, gapReportIds){
  this.setState({pageLoader: true})
  globalPostService(`technical/workorder/${props.params.workOrderSlug}/move-items/`, {item_ids:gapReportIds, to_section_id:section.id})
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState({prsDropdownModal:false})
      toastFlashMessage(response.data.message, 'success')
      setTimeout(()=> {
        window.location.reload()
      },1000)
    }
  })
}
export function bulkAssigneeApi(props, section, gapReportIds){
  this.setState({pageLoader: true})
  globalPostService(`technical/workorder/${props.params.workOrderSlug}/multiple-assignee/`, {item_ids:gapReportIds, assignee_id:section.id})
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState({prsAssigneeModal:false})
      toastFlashMessage(response.data.message, 'success')
      setTimeout(()=> {
        window.location.reload()
      },1000)
    }
  })
}
