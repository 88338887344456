import React, {Component} from 'react';
import Pagination from "react-js-pagination";
export default class AssetsPagination extends Component{
    constructor(props) {
      super(props);
    }
    handlePageChange = (pageNumber) => {
      this.props.updateAssetPageNo(pageNumber);
    }
    render(){
      if( this.props.pagintionInfo && Object.keys(this.props.pagintionInfo).length){
        return(
          <div>
             <Pagination
              activePage={this.props.currentPage ? parseInt(this.props.currentPage):1}
              itemsCountPerPage={10}
              totalItemsCount={this.props.pagintionInfo.total}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            />
          </div>
        )
      }else{
        return null;
      }

    }
}
