import moment from 'moment';
import {globalGetService} from '../../globalServices';
import {removeEmptyKey} from '../../utils';
import { browserHistory } from 'react-router';
export function getCategoriesApi(){
  globalGetService(`console/pass/get-news-feed-category/`)
  .then(response => {
    if(response.data.statusCode === 200){
      this.setState(prevState => ({
        ...prevState,
        categories: response.data.data
      }))
    }
  })
}

export function getTagsApi(){
  globalGetService(`console/pass/get-news-feed-tag/`)
  .then(response => {
    if(response.data.statusCode === 200){
      this.setState(prevState => ({
        ...prevState,
        tags: response.data.data
      }))
    }
  })
}
export function getNewsFeedApi(query, contentType=''){
  this.setState({
    newsFeedLoader :true
  })
  let filterQuery = Object.assign({}, removeEmptyKey(query));
  globalGetService(`console/pass/get-news-feed-list/`, filterQuery)
  .then(response => {
    if(response.data.statusCode === 200){
      let location = Object.assign({},browserHistory.getCurrentLocation());
      location.query = query;
      browserHistory.push(location)
      if(contentType == 'loadmore'){
        this.setState(prevState =>
          ({
          ...prevState,
          newsFeedLoader: false,
          newsFeedInfo: {
            ...prevState.newsFeedInfo,
            list: Object.keys(response.data.data).length ?[...prevState.newsFeedInfo.list, ...response.data.data.list]:prevState.newsFeedInfo.list,
            pagination: Object.keys(response.data.data).length ? response.data.data.pagination:prevState.newsFeedInfo.pagination
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          newsFeedLoader: false,
          newsFeedInfo: Object.keys(response.data.data).length ? response.data.data:{list:[], pagination:{}},
          filter: filterQuery
        }))
      }

    }
  })
}
