import React from 'react'
import { imgStoragePath, displayDateFormatShort } from '../../constants';
import { Row, Col } from 'reactstrap'
import moment from 'moment'

const ContractList = (props) =>{
  return(
    <Row className="users-contract-list">
      <Col md="1">{props.index + 1}</Col>
      <Col md="3">{props.contract.name ? props.contract.name : '--'}</Col>
      <Col md="3">{props.contract.start_date ? moment(props.contract.start_date).format(displayDateFormatShort) : '--'}</Col>
      <Col md="3">{props.contract.end_date ? moment(props.contract.end_date).format(displayDateFormatShort) : '--'}</Col>
      <Col md="2" style={{textAlign: 'right'}}>
        {props.contract.url ? <a download={`${props.contract.start_date}_${props.contract.end_date}`} href={props.contract.url}><i className="fa fa-download"></i></a> : ''}
        {props.addEditContract ? <a onClick={() => props.addEditContract()}><i style={{color: '#4382f9'}} className="fa fa-edit"></i></a> : ''}
        {props.deleteContract ? <a onClick={() => props.deleteContract()}><i style={{color: 'red'}} className="fa fa-trash"></i></a> : ''}
      </Col>
    </Row>
  )
}

export default ContractList
