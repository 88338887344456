import React from 'react';
import { Link } from 'react-router';
import { ToolTipHover } from '../../../../shared'
const ProjectCordetails = (props) => {
  const { projectDetails } = props;
  return (
    <div className="name-image-block flex-centered">
      <div style={{width: '25%'}}>
        { projectDetails.lessor &&	projectDetails.lessor.logo ?
          <img id={`lessor-name_${projectDetails.id}`} src={projectDetails.lessor.logo} alt="" />:
          null
        }
        { projectDetails.lessor &&	projectDetails.lessor.logo ?
        <ToolTipHover placement="top" tagetId={`lessor-name_${projectDetails.id}`}>
          {projectDetails.lessor.name}
        </ToolTipHover>:null
        }
      </div>
      <div className="lessor-info" style={{width: '70%', marginLeft: '9px'}}>
        { projectDetails.project_number ?
          <span className="project-num"> #{projectDetails.project_number} </span>
          : null
        }
        <h4>
          <Link id={`projectDetails-name-${projectDetails.id}`}>
            { projectDetails.name.length > 20 ?
              projectDetails.name.substring(0,17)+'...':projectDetails.name
            }
          </Link>
          { projectDetails.name.length > 20 ?
            <ToolTipHover placement="top" tagetId={`projectDetails-name-${projectDetails.id}`}>
              {projectDetails.name}
            </ToolTipHover>
          : null }
        </h4>
        { projectDetails.leads && projectDetails.leads[0] && projectDetails.leads[0].user && projectDetails.leads[0].user.name ?
          <p className="lead-by">
            Lead By <span onClick = {(e) =>   this.props.getUserDetailFn(projectDetails.leads[0].user.slug, true)}>{projectDetails.leads[0].user.name}</span>
          </p>:null
        }
        {
          projectDetails.department && projectDetails.department.label ?
          <span className="lead-by" style={{ display: 'block', position: 'relative', top: '-3px'}}>  {projectDetails.department.label }</span>
          : null
        }
      </div>
    </div>
  )
}

export default ProjectCordetails;
