import React, { Component } from 'react';
import { Row, Col,  Modal, ModalHeader, ModalBody } from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils'
export default class LLPList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);

  }
  deleteLLP = (e, llpId) => {
    e.preventDefault();
    e.stopPropagation();
    this.toggle(e);
    this.props.delLLp(llpId);

  }


  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    const { llpList } = this.props;
    return (
      <div className="asset-list">
      <Row className=" flex-not-centered" onClick={this.props.viewLLPEngine}>
      <Col md="1">
        <h6>{llpList.module ? llpList.module : '--'}</h6>
        </Col>
        <Col md="3">
        <h6> {llpList.description ? llpList.description : '--'} </h6>
        </Col>
        <Col md="2">
        <h6>{llpList.part_number ? llpList.part_number: '--'}</h6>
        </Col>
        <Col md="2">
        <h6>{llpList.cycles_max_limit ? llpList.cycles_max_limit : '--'}</h6>
        </Col>
        <Col md="2">
        <h6>{llpList.cycles_remaining ? llpList.cycles_remaining : '--'}</h6>
        </Col>
        <Col md="1">
        <h6>{llpList.list_price ? llpList.list_price : '--'} </h6>
        </Col>
        <Col md="1" className="text-right">
          {
            getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.engines && (getLocalStorageInfo().user.permission.maintenance.engines.indexOf('D') !== -1) ?
            <img width="12" onClick={(e) => this.toggle(e)} src={imgStoragePath+"del_icon.png"} alt="img"/>
            :null
          }
        </Col>
      </Row>


       <Modal isOpen={this.state.modal} centered={true} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Delete LLP</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to delete LLP:<b></b></h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={(e) => this.deleteLLP(e, [llpList.id])}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={this.toggle}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
