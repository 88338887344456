import React, { Component } from 'react';
import { Link } from 'react-router';
import { Collapse } from 'reactstrap';
import CKeditor from "../../../shared/CKEditor";
class ReviewCollapse extends Component{
  constructor(props){
    super(props);
  }
  updateCkEditer = (evt) =>{
		var newContent = evt.editor.getData();
    this.props.updateCkEditer(this.props.type, 'content', newContent, this.props.index)
  }
  render(){
    const { review, index, formInfo,reviewIndex } = this.props;
    return(
      <div className="collapse-editor">
        <div className="collapse-header">
        {reviewIndex? null:
          <input type="text" placeholder="Order" onChange={(evt) => this.props.updateReviewCollapse(this.props.type, 'index', evt.target.value, index)} className="review-index" value={review.index}/>
        }
          <input placeholder="Enter the section title here" type="text" onChange={(evt) => this.props.updateReviewCollapse(this.props.type, 'title', evt.target.value, index)} className="review-title" value={review.title}/>
          <Link onClick={this.props.toggleReviewCollapse}><span className="fa fa-angle-down"></span></Link>
        </div>
        <Collapse isOpen={review.flag}>
          <CKeditor
            events={{"change": this.updateCkEditer}}
            content={review && review.content ? review.content : ''}
          />
          { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
            <Link className="remove-review-section" onClick={this.props.removeReviewCollapse}><i className="fa fa-trash"></i> Remove this section</Link>:null
          }

        </Collapse>
      </div>
    )
  }
}

export default ReviewCollapse
