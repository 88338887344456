import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { TextInputField,DateInputField, GetInputField } from '../../Elements';
import { imgStoragePath, displayDateFormat } from '../../../../constants';
export default class ShopVisitView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
  const { shopVisit, type, svError,index } = this.props;
  return(
    <div style={{ position:'relative'}}>
      <Row>
				<Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Shop Visit Number{ type == 'edit' ? <sup className="reqStar"> * </sup> : null}</label>,
              { type: type,
               value: shopVisit.shop_visit_number,
               error:svError&&svError.length?svError[index].shop_visit_number?svError[index].shop_visit_number:'':'',
               updateForm: (value) => this.props.updateForm({ type: 'shop_visit_number', value: value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Date of Removal</label>,
              { type: type,
              value: shopVisit.date_of_removal,
              error:svError&&svError.length?svError[index].date_of_removal?svError[index].date_of_removal:'':'',
              updateForm: (value) => this.props.updateForm({ type:'date_of_removal', value:value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Reason of Removal </label>,
              { type: type,
              value: shopVisit.reason_for_removal,
              error:'',
               updateForm: (value) => this.props.updateForm({ type: 'reason_for_removal', value: value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="1"  style={{marginBottom: '20px'}}>
         <img width="12" onClick = {() => this.props.toggleDelModal(shopVisit.id, 'shop_visit', this.props.index)} className="" src={imgStoragePath+"delete_red.png"}/>
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Shop Facility</label>,
              { type: type,
              value: shopVisit.shop_facility,
              error: '',
              updateForm: (value) => this.props.updateForm({ type:'shop_facility', value:value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSN<sup className="reqStar"> * </sup></label>,
              { type: type,
               value: shopVisit.tsn,
               error:svError&&svError.length?svError[index].tsn?svError[index].tsn:'':'',
               updateForm: (value) => this.props.updateForm({ type: 'tsn', value: value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSN<sup className="reqStar"> * </sup></label>,
              { type: type,
              value: shopVisit.csn,
              error:svError&&svError.length?svError[index].csn?svError[index].csn:'':'',
              updateForm: (value) => this.props.updateForm({ type:'csn', value:value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSLSV</label>,
              { type: type,
              value: shopVisit.tslsv,
              error:svError&&svError.length?svError[index].tslsv?svError[index].tslsv:'':'',
               updateForm: (value) => this.props.updateForm({ type: 'tslsv', value: value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSLSV</label>,
              { type: type,
              value: shopVisit.cslsv,
              error:svError&&svError.length?svError[index].cslsv?svError[index].cslsv:'':'',
              updateForm: (value) => this.props.updateForm({ type:'cslsv', value:value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>


         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSR</label>,
              { type: type,
              value: shopVisit.tsr,
              error:svError&&svError.length?svError[index].tsr?svError[index].tsr:'':'',
              updateForm: (value) => this.props.updateForm({ type:'tsr', value:value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSR</label>,
              { type: type,
               value: shopVisit.csr,
               error:svError&&svError.length?svError[index].csr?svError[index].csr:'':'',
               updateForm: (value) => this.props.updateForm({ type: 'csr', value: value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSO</label>,
              { type: type,
              value: shopVisit.tso,
              error:svError&&svError.length?svError[index].tso?svError[index].tso:'':'',
              updateForm: (value) => this.props.updateForm({ type:'tso', value:value, sectionType: 'shop_visit', index:this.props.index})
              }
            )
          }
         </Col>




         <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSO </label>,
                    { type: type,
                      value: shopVisit.cso,
                      error:svError&&svError.length?svError[index].cso?svError[index].cso:'':'',
                      updateForm: (value) =>  this.props.updateForm({ type:'cso', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSHSI </label>,
                    { type: type,
                      value: shopVisit.tshi,
                      error:svError&&svError.length?svError[index].tshi?svError[index].tshi:'':'',
                      updateForm: (value) =>  this.props.updateForm({ type:'tshi', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSHSI </label>,
                    { type: type,
                      value: shopVisit.cshi,
                      error:svError&&svError.length?svError[index].cshi?svError[index].cshi:'':'',
                      updateForm: (value) =>  this.props.updateForm({ type:'cshi', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Engine Induction Date </label>,
                    { type: type,
                      value:shopVisit.induction_date,
                      error:svError&&svError.length?svError[index].dateinduction_date_of_removal?svError[index].induction_date:'':'',
                      updateForm: (value) =>  this.props.updateForm({ type:'induction_date', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Engine Workscope</label>,
                    { type: type,
                      value:shopVisit.final_workscope,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'final_workscope', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Engine Build Target </label>,
                    { type: type,
                      value:shopVisit.build_target,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'build_target', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Test Run EGT Margin </label>,
                    { type: type,
                      value:shopVisit.test_run_egt_margin,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'test_run_egt_margin', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>
							<Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">LLP Status </label>,
                    { type: type,
                      value:shopVisit.llp_status,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'llp_status', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">ARC Date ( EASA/FAA) </label>,
                    { type: type,
                      value:shopVisit.arc_date,
                      error:svError&&svError.length?svError[index].arc_date?svError[index].arc_date:'':'',
                      updateForm: (value) =>  this.props.updateForm({ type:'arc_date', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TAT </label>,
                    { type: type,
                      value:shopVisit.total_air_temperature,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'total_air_temperature', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

              <Col md="3" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Date of Reinstallation </label>,
                    { type: type,
                      value:shopVisit.date_of_reinstallation,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'date_of_reinstallation', value:value, sectionType: 'shop_visit', index:this.props.index})
                    }
                  )
                }
              </Col>

      </Row>
      </div>
    );
  }
}
