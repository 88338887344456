import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import { getAccessToken, localTimeFn } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
class FileList extends Component{
  constructor(props){
    super(props)
    this.state = {
      modal: false
    }
    this.toggle = this.toggle.bind(this);
  }


  toggle(e=null) {
    if(e){
      e.preventDefault();
    }
    this.setState({
      modal: !this.state.modal
    });
  }


  deleteFile = (params, fileId) => {
    this.toggle();
    this.props.deleteFile(params, fileId);
  }

  render(){
    const { fileInfo, params } = this.props;
    return(
      <Link className="list-row" to={fileInfo.status == '1' ? "/" + params.type+"/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/extract-file/" + fileInfo.id: ''}>
        <Row className="upload-row  flex-centered">
          <Col md="3">
              <img width="15" src={imgStoragePath+"pdf.png"} alt="img"/>
              <span className="caption-ui wrapword">{fileInfo.name ? fileInfo.name.replace(/^.*[\\\/]/, '') : '--'}</span>
          </Col>
          <Col md="2">
            <span className="file-type-status">{fileInfo.file_type.label}</span>
          </Col>
          <Col md="1">
            <span className="caption-ui">{fileInfo.size}</span>
          </Col>
          <Col md="2" className="">
            <span className="caption-ui">{ localTimeFn(fileInfo.uploaded_on) }</span>
          </Col>
          <Col md="2" className="">
            <div className="flex-centered upload-by">
            {
              fileInfo.uploaded_by && fileInfo.uploaded_by.img ?
              <img src={fileInfo.uploaded_by.img} alt="img"/>
              :  <img src={imgStoragePath+"user.png"} alt="img"/>
            }
              <span className="caption-ui">{fileInfo.uploaded_by.name}</span>
            </div>
          </Col>
          <Col md="2" className="text-right">
            {
              fileInfo.status == '0' ?
              <span className="caption-ui">
                <img  width="20" src={imgStoragePath+"rolling.gif"} alt="img" style={{marginRight: '8px'}}/>
                  Processing
              </span>: 
              fileInfo.status == '2' ?  <span className="caption-ui">
              <img  width="15" src={imgStoragePath+"warn_check.png"} alt="img"/>
            </span>:
              <span className="caption-ui">
                <img  width="15" src={imgStoragePath+"file_completed.png"} alt="img"/>
                  Processed
              </span>
            }
             <Link style={{ marginLeft: '10px', display: 'inline-block', cursor: 'pointer' }} onClick={(e) => this.toggle(e)}><img width="15" src={imgStoragePath+"icon_trash.png"} alt="img"/></Link>

          </Col>
        </Row>
        {
          fileInfo.status == '1' ?
          (fileInfo.pending_entries > 0 || fileInfo.issue_entries > 0) ?
          <Row className="margin-bottom-10 pending-status-data">
            <Col md="12">
              <p className="caption-ui">
                {
                  (fileInfo.pending_entries > 0 && fileInfo.issue_entries > 0) ?
                  <span><b>{fileInfo.pending_entries} entries</b> are pending for approval & <b>{ fileInfo.issue_entries } entries</b> couldn't be applied <Link>View</Link></span>
                  : fileInfo.pending_entries > 0 ?
                  <span><b>{fileInfo.pending_entries} entries</b> are pending for approval <Link>View</Link></span>:
                  <span><b>{ fileInfo.issue_entries } entries</b> couldn't be applied <Link>View</Link></span>
                }

              </p>
            </Col>
          </Row>:
          null:fileInfo.status == '2' ?
          <Row className="margin-bottom-10 awiting-for-approval">
            <Col md="12">
              <p>
              {fileInfo.response_text ? fileInfo.response_text : 'File is Corrupted' }
              </p>
            </Col>
          </Row>:
          <Row className="margin-bottom-10 awiting-for-approval">
            <Col md="12">
              <p>
                  Document is under processing. We will notify you via email once the process is completed. You can proceed with other actions on system till then.
              </p>
            </Col>
          </Row>
        }


        <Modal isOpen={this.state.modal} centered={true} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Delete File</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to delete File: <b className="bold wrapword">{fileInfo.name.replace(/^.*[\\\/]/, '')}</b></h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick = {(e) => this.deleteFile( params, fileInfo.id)}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={this.toggle}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>

      </Link>
    )
  }
}
export default withRouter(FileList);
