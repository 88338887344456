import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { TabPane } from 'reactstrap';
import { AddEditAvionics,AvionicsTab,TableHeadingTechSummary } from '../components';
import { fetchTechSummaryListAc, addEditTechSummaryAc, techSummaryCrudAc } from '../actionCreators';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { EditSidebar, CtrTableHd, FormModeType } from '../../../contracts/Elements'
import { TOGGLE_TECH_SMRY_VIEW,TECH_SMRY_CRUD } from '../actions';
import { NoRecordFound, TableUIComp  } from '../../../../shared';
import { addAvionic } from '../createTechSummary';
import { getLocalStorageInfo } from '../../../../utils';
class AvionicContent extends Component{
	constructor(props) {
		super(props);
	}
  componentDidMount() {
		this.props.fetchTechSummaryList(this.props.asset_slug, 'avionicList');
		this.props.getOwnerlist()
  }
  sortTable = (sortKey) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
        query: {
          ...location.query,
          sort: sortKey,
          sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }

    browserHistory.push(location);
   	this.props.fetchTechSummaryList(this.props.asset_slug,'avionicList',location.query)
	}
	render(){
		const { avionicList, techSmryView, asset_slug, techSpecsLoader, avionicCrud, ownerList } = this.props;


		return(
			<TabPane tabId="5" className="general-block">
				<div className="contr-table-blk">
					<TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'avionicCrud',addAvionic)} title='Avionics' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['avionics'].indexOf('C') != -1) ? true: false} ownerList={ownerList}/>
					<TableUIComp
					theads={[{label:"ATA Code",sortKey:"ata_code"},{label:"Item",sortKey:"item"},{label:"Description",sortKey:"item_description"},{label:"Manufacturer",sortKey:"manufacturer"},{label:"Model",sortKey:"model"},{label:'Part Number',sortKey:"part_number"}, {label:'Quantity', sortKey:"quantity"}]}
					bulkOperation={false}
					content={avionicList.map((avionicList, index) =>
						<AvionicsTab key={index} index={index} asset_slug={asset_slug}  avionicList={avionicList} viewAvionic={() => this.props.techSummaryCrud('view', avionicList, 'avionicCrud')} />
					)}
					sortTable={this.sortTable}
					searchQuery = {browserHistory.getCurrentLocation().query}
					queryParamsEnabled={true}
					pagination={false}
					tableHeight = {'(window.innerHeight - 285)px'}
					/>



					{!techSpecsLoader && !avionicList.length ?
						<NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
					}
				</div>
				<EditSidebar
				title={
					avionicCrud.type != '' ?
					(avionicCrud.type == 'add') ? 'Add Avionics':
					(avionicCrud.type == 'edit') ? 'Edit Avionics': 'View Avionics' : ''
					}
				editSideBar={avionicCrud.flag}
				toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'avionicCrud')}>
					<div>
						<FormModeType
							type={avionicCrud.type}
							changeViewMode={()=>this.props.changeViewMode(avionicCrud.type,'avionicCrud')}
							editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['avionics'].indexOf('U') != -1 ? true:false}
						/>
						{Object.keys(avionicCrud.data).length && avionicCrud.flag ?
							<AddEditAvionics
								addEditAvionic={this.props.addEditTechSummary}
								type={avionicCrud.type}
								avionicObj={avionicCrud.data}
								toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'avionicCrud')}/>
							:null
						}
					</div>
				</EditSidebar>
			</TabPane>
		)
	}
}
const mapStateToProps = state => ({
	techSmryView: state.TechSummaryReducer.techSmryView,
	avionicList: state.TechSummaryReducer.avionicList,
	avionicCrud: state.TechSummaryReducer.avionicCrud,
	ownerList: state.confReducer.ownerDropdownList
});
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
	fetchTechSummaryList: (asset_slug, type,query) => dispatch(fetchTechSummaryListAc(asset_slug, type,query)),
	getOwnerlist:() => dispatch(getOwnerDropdownListAc()),
    addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
    techSummaryCrud: (type, id, actionType,dataObject) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject)),
    changeViewMode: (type,actionType) => dispatch({
        type:TOGGLE_TECH_SMRY_VIEW,
        payload:{type},
        actionType
      }
    ),
    toggleEditBar: (data, actionType) => dispatch({
      type:TECH_SMRY_CRUD,
      payload:data,
      actionType
    }),
	}
}
 export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvionicContent));
