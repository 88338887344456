import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getLocalStorageInfo, removeEmptyKey } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel'
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { imgStoragePath, displayDateTimeFormatShort } from '../../../../../constants';
import { TableUIComp, TableUICompList, ExportFiles, MSNSwitcherHeader, ListLoader, LicenceModal, ToolTipHover, NoRecordFound} from '../../../../../shared';
import { DeleteModal, EditFormBar, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, DownloadedLogs, } from '../../../Elements';
import { FieldCol, FieldGroup, FieldLabel} from '../../../../contracts/Elements';
import { HeaderCountCards, List, FinalSummeriesListV1, TableHeaderDropdown, TechnicalInspectionFormV1, UploadExcel, ActivityLog } from '../components';
import { gapReportHd, gapReporATAtHd, tableContentHd, gapSecReportHd, gapSecReportATAHd, gapSecReportCyclesHd, sectionIds } from '../';
import { getTableContentsApi, getTableSectionsApi, updateTableSectionsFieldApi, fetchActivityLogs, deleteTableSectionRowApi, getFormInfoApi, exportReport, updateFormStatusApi, getUserListApi, getUserStatsApi, moveGapIdsApi, bulkAssigneeApi } from '../apiServices';
import moment from 'moment';
import { browserHistory } from 'react-router';
require("highcharts/highcharts-3d")(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
class InspectionFormT011v1 extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      tabIndex:0,
      formTabIndex: 0,
      sectionId:0,
      addSectionId:0,
      editableRow:true,
      openTechForm:false,
      downloadFormLogs:false,
      uploadExcelSidebar: false,
      toggleUploadLogs:false,
      t011ReportInfo: {items:null, list:[]},
      t011SectionInfo: {list:[], pagination:null},
      deleteModal:false,
      prsDropdownModal:false,
      prsAssigneeModal:false,
      deleteItem:'',
      fullScreen: false,
      formInfo:{},
      filter:{
        priority:'',
        disposition:'',
        assignee:''
      },
      gap_report_ids: [],
      hideTitleContent: true,
      operatorFlag:false,
      deleteType: 'single',
      showActivityLogs: false,
      activityLogs: [],
      searchLog: '',
      userList:{},
      t011SectionInfoFiltered: [],
      userStats:{},
      sectionMoveId:'',
      assigneeId:''
    }
    this.getTableContentsApi = getTableContentsApi.bind(this);
    this.getTableSectionsApi = getTableSectionsApi.bind(this);
    this.updateTableSectionsFieldApi = updateTableSectionsFieldApi.bind(this);
    this.deleteTableSectionRowApi = deleteTableSectionRowApi.bind(this);
    this.getFormInfoApi = getFormInfoApi.bind(this);
    this.exportReport = exportReport.bind(this);
    this.updateFormStatusApi = updateFormStatusApi.bind(this);
    this.fetchActivityLogs = fetchActivityLogs.bind(this);
    this.getUserListApi = getUserListApi.bind(this);
    this.getUserStatsApi = getUserStatsApi.bind(this);
    this.moveGapIdsApi = moveGapIdsApi.bind(this)
    this.bulkAssigneeApi = bulkAssigneeApi.bind(this)
  }
  componentDidMount(){
    this.getTableContentsApi(this.props, {}, 'init');
    this.getUserListApi(this.props);
    this.getFormInfoApi(this.props);
    this.getUserStatsApi(this.props);
    if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
      this.props.usageDetail()
    }
    this.setState(prevState => ({
      ...prevState,
      operatorFlag: getLocalStorageInfo().user.security_groups.filter(item => item.id == 16).length ? false : true
    }));
  }

  componentWillReceiveProps(nextProps){
    if(this.props.params.tab_name !== nextProps.params.tab_name){
      // this.getTableSectionsApi(nextProps, { section: this.state.t011ReportInfo.list.filter(item => item.name === nextProps.params.tab_name).length ? this.state.t011ReportInfo.list.filter(item => item.name === nextProps.params.tab_name)[0].id : ""})
      this.getTableSectionsApi(nextProps, { section: nextProps.params.tab_name}, 'init')
      this.setState({filter:{
        priority:'',
        disposition:'',
        assignee: ''
      }})
    }
  }

  fetchSectionInformation = (sectionId) => {
    if(parseInt(sectionId) != 0){
      // browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t011/${this.state.t011ReportInfo.list.filter(item => item.id === sectionId)[0].name}`)
      browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t011/${sectionId}`)
    }else{
      browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t011`)
      this.getTableContentsApi(this.props,{}, 'init')
    }
  }
  onChangeField = (value, keyParam, index) => {
    this.setState(prevState => ({
      ...prevState,
      t011SectionInfo: {
        ...prevState.t011SectionInfo,
        list: prevState.t011SectionInfo.list.map((section, sectionIndex) => sectionIndex === index ? {
          ...section, [keyParam]:value
        }:section)
      }
    }))
  }
  toggleDownloadedLogs = () =>{
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggleUploadExcelSidebar = () =>{
    this.setState(prevState => ({
      ...prevState,
      uploadExcelSidebar: !prevState.uploadExcelSidebar
    }))
  }
  uploadAttachment = (files, id) => {
    const { sectionId } = this.state;
    this.updateTableSectionsFieldApi(this.props, {files: files, gap_report_section_id: sectionId}, id, sectionId);
  }
  openDeleteModal = (id) => {
    this.setState(prevState => ({
      ...prevState,
      deleteItem:id,
      deleteModal:true,
      deleteType: 'single'
    }))
  }

  changeFilter=(value, key)=> {
    let queryObj = {}
    const { filter, t011SectionInfo, sectionId } = this.state
    queryObj = {
      ...filter,
      [key]:value
    }
    this.setState(prevState =>({
      ...prevState,
      filter:{
        ...prevState.filter,
        [key]:value
      },
      gap_report_ids: []
    }), () => this.applyFilter());

  }

  applyFilter = () => {
    let t011SectionInfo = this.state.t011SectionInfo
    let filter = this.state.filter
    let t011SectionInfoFiltered = []
    if(filter.priority !== null && filter.disposition !== null && filter.priority !== '' && filter.disposition !== '' && filter.assignee !== ''  && filter.assignee !== null){
      if(filter.priority !== 3){
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === filter.priority && item.disposition === parseInt(filter.disposition) && (item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee : 0) === parseInt(filter.assignee))
      }else{
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === null && item.disposition === parseInt(filter.disposition) && (item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee : 0) === parseInt(filter.assignee))
      }
    }else if(filter.priority !== null && filter.disposition !== null && filter.priority !== '' && filter.disposition !== ''){
      if(filter.priority !== 3){
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === filter.priority && item.disposition === parseInt(filter.disposition))
      }else{
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === null && item.disposition === parseInt(filter.disposition))
      }
    }else if(filter.priority !== null && filter.assignee !== null && filter.priority !== '' && filter.assignee !== '' ){
      if(filter.priority !== 3){
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === filter.priority && (item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee : 0) === parseInt(filter.assignee))
      }else{
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === null && (item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee : 0) === parseInt(filter.assignee))
      }
    }else if(filter.disposition !== null && filter.assignee !== null && filter.disposition !== '' && filter.assignee !== '' ){
      t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.disposition === parseInt(filter.disposition) && (item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee : 0) === parseInt(filter.assignee))
    }else if(filter.disposition !== null && filter.disposition !== ''){
      t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.disposition === parseInt(filter.disposition))
    }else if(filter.assignee !== null && filter.assignee !== ''){
      t011SectionInfoFiltered = t011SectionInfo.list.filter(item => (item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee :  0) === parseInt(filter.assignee))
    }else if(filter.priority !== null && filter.priority !== ''){
      if(filter.priority !== 3){
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === filter.priority)
      }else{
        t011SectionInfoFiltered = t011SectionInfo.list.filter(item => item.priority === null)
      }
    }else{
      t011SectionInfoFiltered = t011SectionInfo.list
    }
    this.setState({t011SectionInfoFiltered: t011SectionInfoFiltered})
  }

  changePerPage=(count) => {
    const { t011SectionInfo, filter } = this.state;
    let queryParam = {
      ...filter,
      per_page: count,
      page: 1,
      section:this.state.sectionId
    }
    this.getTableSectionsApi(this.props, removeEmptyKey(queryParam));
  }

  changePagination = (type, currentPage) => {
    const { t011SectionInfo, filter } = this.state;
    let queryParam = {
      ...filter,
      page: currentPage,
      per_page: t011SectionInfo.pagination.per_page,
      section:this.state.sectionId
    }
    this.getTableSectionsApi(this.props, removeEmptyKey(queryParam))
  }

  graphToTabMove = (title) => {

  }
  moveGapIds = (section) => {
    this.setState({sectionMoveId:section})
    this.moveGapIdsApi(this.props,section, this.state.gap_report_ids)
  }
  assignGapIds = (assignee) => {
    this.setState({assigneeId:assignee})
    this.bulkAssigneeApi(this.props, assignee, this.state.gap_report_ids)
  }

  render(){
    const { userList, userStats, hideTitleContent, activityLogs, sectionId, editableRow, t011ReportInfo, t011SectionInfo, deleteModal, deleteItem, pageLoader, openTechForm, addSectionId, tabIndex, formInfo, type,filter, t011SectionInfoFiltered, prsDropdownModal, sectionMoveId, prsAssigneeModal } = this.state;
    console.log('props', this.props);
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    const graphData = {
      chart: {
        type: 'column',
        height: 550,
        options3d: {
            enabled: true,
            alpha: 10,
            beta: 10,
            viewDistance: 55,
            depth: 50
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 || item.reopen_item !== 0).map(item => item.name)
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: 'Total: {point.stackTotal} <br/> Open: {point.open} <br/> Reopen: {point.reopen} <br/> Closed: {point.close}'
      },
      plotOptions: {
        column: {
            stacking: 'normal',
            depth: 40
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (e) => {
                this.graphToTabMove(e.point.category)
              }
            }
          }
        }
      },
      series: [{
        name: 'Reopen',
        type: 'column',
        color: '#f25d80',
        data: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 ||  item.reopen_item != 0).map(item => (item.name == this.state.sectiontitle ? { y: item.reopen_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item} : { y: item.reopen_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item}))
      },{
        name: 'Open',
        type: 'column',
        color: '#7bb4ec',
        data: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 ||  item.reopen_item != 0).map(item => (item.name == this.state.sectiontitle ? { y: item.open_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item } : { y: item.open_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item }))
      }, {
        name: 'Closed',
        type: 'column',
        color: '#90ed7d',
        data: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 ||  item.reopen_item != 0).map(item => (item.name == this.state.sectiontitle ? { y: item.close_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item } : { y: item.close_item, open: item.open_item,close: item.close_item, reopen: item.reopen_item }))
      }],
      exporting: {
        chartOptions: {
          title: {
            text: (formInfo.asset ? 'MSN ' + formInfo.asset.msn : ' ') + ' - ' + (formInfo.project ? 'Technical: ' + formInfo.project.name : ' ') + ' PRS Report Summary'
          }
        },
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      },
      credits: {
        enabled: false
      }
    }
    const graphPieData = {
      chart: {
        type: 'pie',
        height: 550,
        options3d: {
          enabled: true,
          alpha: 55,
          beta: 0
        }
      },
      title: {
        text: null
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
                enabled: true,
                format: '{point.name}'
            }
        }
    },
      series: [{
        name: 'Item',
        colorByPoint: true,
        data: [{
          name: 'Open',
          color: '#7bb4ec',
          y: t011ReportInfo.list.reduce((result, content) => result + content.open_item, 0),
          sliced: true,
          selected: true
        }, {
          name: 'Closed',
          color: '#90ed7d',
          y: t011ReportInfo.list.reduce((result, content) => result + content.close_item, 0)
        },
        {
          name: 'Reopen',
          color: '#f25d80',
          y: t011ReportInfo.list.reduce((result, content) => result + content.reopen_item, 0)
        }],
      }],
      exporting: {
        chartOptions: {
          title: {
            text: (formInfo.asset ? 'MSN ' + formInfo.asset.msn : ' ') + ' - ' + (formInfo.project ? 'Technical: ' + formInfo.project.name : ' ') + ' PRS Report Summary'
          }

        },
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      },
      credits: {
        enabled: false
      }
    }
    const graphDataUserStats = {
      chart: {
        type: 'column',
        height: 550,
        options3d: {
            enabled: true,
            alpha: 10,
            beta: 10,
            viewDistance: 55,
            depth: 50
        }
      },
      title: {
        text: "User Wise Stats"
      },
      xAxis: {
        categories: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened !== 0).map(item => item.user.name)
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: 'Total: {point.stackTotal} <br/> Open: {point.open} <br/> Reopen: {point.reopen} <br/> Closed: {point.close}'
      },
      plotOptions: {
        column: {
            stacking: 'normal',
            depth: 40
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (e) => {
                this.graphToTabMove(e.point.category)
              }
            }
          }
        }
      },
      series: [{
        name: 'Reopen',
        type: 'column',
        color: '#f25d80',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 ||  item.reopened != 0).map(item => ({ y: item.reopened, open: item.open,close: item.closed, reopen: item.reopened}))
      },{
        name: 'Open',
        type: 'column',
        color: '#7bb4ec',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 ||  item.reopened != 0).map(item => ({ y: item.open, open: item.open,close: item.closed, reopen: item.reopened }))
      }, {
        name: 'Closed',
        type: 'column',
        color: '#90ed7d',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 ||  item.reopened != 0).map(item => ({ y: item.closed, open: item.open,close: item.closed, reopen: item.reopened }))
      }],
      exporting: {
        chartOptions: {
          title: {
            text: (formInfo.asset ? 'MSN ' + formInfo.asset.msn : ' ') + ' - ' + (formInfo.project ? 'Technical: ' + formInfo.project.name : ' ') + ' PRS Report Summary'
          }
        },
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      },
      credits: {
        enabled: false
      }
    }
    return(
      <div className="technical-inspection-form-v1">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset} />}
          activeAsset={formInfo.asset}
          projectSlug={Object.keys(formInfo).length ? formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={<ExportFiles exportFile={(fileType) => this.exportReport(formInfo.asset)} exportName={'Export Report '} files={[{ img: 'excel_icon.png', label: 'Excel', type: 'xls' }]} />}
          showUploadLogs={() => this.setState({ toggleUploadLogs: true })}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
          showActivity={true}
          exportActivityLogName="Deleted Logs"
          showActivityLogs={() => this.setState({addSectionId: 0}, () => this.fetchActivityLogs())}
          updateFormStatus={(data) => this.updateFormStatusApi(data)}
          importReport={() => this.setState({ hideTitleContent: false, addSectionId: parseInt(this.state.sectionId), formTabIndex: 1, openTechForm: t011SectionInfo.authorized_operator === 0 || t011SectionInfo.authorized_operator == null ? true : false })}
          showImport={(t011SectionInfo.authorized_operator==undefined || t011SectionInfo.authorized_operator === 0 || t011SectionInfo.authorized_operator == null) && (formInfo.current_form && formInfo.current_form.user_security_group !== 'operator')}
        />
        {pageLoader ? <ListLoader />:null}
        <div className="zoom-content">
          <div className="display-tabs">
            <ul className="list-inline">
              <li className={`list-inline-item ${tabIndex == 0 ? 'active' : 'inactive-bg'}`} onClick={() => this.setState({ tabIndex: 0 }, () => this.getTableContentsApi(this.props, this.state.sectionId) )}>
                <img src={tabIndex == 0 ? imgStoragePath + 'regular_list.svg' : imgStoragePath + 'regular_list_inactive.svg'} alt="Icon" />
              </li>
              {t011ReportInfo.items && t011ReportInfo.items.total>0 ?
                <li className={`list-inline-item ${tabIndex == 1 ? 'active' : 'inactive-bg'}`} onClick={() => this.setState({ tabIndex: 1 }, () => {this.getTableContentsApi(this.props);trackActivity('Charts Viewed',{
                  application_module: 'Inspection',
                  asset: this.state.formInfo.asset.asset_type === 1 ? `MSN ${this.state.formInfo.asset.msn}` : [3,4,5].includes(this.state.formInfo.asset.asset_type) ? `${assetTypeNames[this.state.formInfo.asset.asset_type]} ${this.state.formInfo.asset.serial_number}` : `ESN ${this.state.formInfo.asset.esn}`,
                  page_title: this.state.formInfo.current_form.name.toUpperCase(),
                })})} disabled={t011ReportInfo.items && t011ReportInfo.items.total == 0 ? true : false}><img src={tabIndex == 1 ? imgStoragePath + 'pie-chart_active.svg' : imgStoragePath + 'pie-chart.svg'} alt="Icon" />
                 </li>:null
              }
            </ul>
          </div>
          {
            tabIndex === 0 ?
            <div style={{position: 'absolute', right:'7px', marginTop: '2px'}}>
              <span style={{borderRight: '2px solid #368FFB', marginRight:'7px', verticalAlign: 'text-top', padding: '6px'}}></span>
              <img id="fullscreen-tab" onClick={() => this.setState({fullScreen: !this.state.fullScreen}, () => trackActivity('Full Screen Mode Viewed',{
                application_module: 'Inspection',
                asset: this.state.formInfo.asset.asset_type === 1 ? `MSN ${this.state.formInfo.asset.msn}` : [3,4,5].includes(this.state.formInfo.asset.asset_type) ? `${assetTypeNames[this.state.formInfo.asset.asset_type]} ${this.state.formInfo.asset.serial_number}` : `ESN ${this.state.formInfo.asset.esn}`,
                page_title: this.state.formInfo.current_form.name.toUpperCase(),
              }))} src={this.state.fullScreen == false ? imgStoragePath + 'maximize.svg' : imgStoragePath + 'zoom-in.svg'} style={{ width: '20px', marginRight: '8px', cursor: 'pointer' }} alt="Zoom Icon" />
              <ToolTipHover placement="top" tagetId={"fullscreen-tab"}>
                Full Screen Mode
              </ToolTipHover>
            </div>
            : null
          }
          {
            tabIndex == 1 ?
              <Fragment>
                <h4 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '20px', color: '#BD10E0' }}>Problem Resolution Sheet</h4>
                <Row>
                  <Col md="8" style={{marginBottom: '20px'}}>
                    <div className="">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={graphData}
                      />
                    </div>
                  </Col>
                  <Col md="4" style={{marginBottom: '20px'}}>
                    <div className="">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={graphPieData}
                      />
                    </div>
                  </Col>
                  {
                    userStats && userStats.length ?
                      <Col md="12">
                        <div className="">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={graphDataUserStats}
                          />
                        </div>
                      </Col>
                    :null
                  }
                </Row>
              </Fragment> : null
          }
          {
            tabIndex == 0 ?
              <div>
                <h4 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '20px', color: '#BD10E0'}}>Problem Resolution Sheet</h4>
                <HeaderCountCards data={t011ReportInfo.items} />
                <div className="zoomAnimation" style={this.state.fullScreen ? {width: '100%', height: '100vh',zIndex: '999', position: 'fixed', padding: '10px 10px 10px 10px', top: '0px', left: '0px', background: '#fff'} :{}}>
                  <TableHeaderDropdown
                    onSectionChange={(section) => this.fetchSectionInformation(section.id)}
                    dropdownMenuList={[{ id: 0, name: 'Table of Content' }, ...t011ReportInfo.list]}
                    value={sectionId}
                    hideAddButton={() => this.setState({hideAddItem: true})}
                    openTechForm={() => this.setState({ openTechForm: true, addSectionId: parseInt(sectionId), formTabIndex: 0, hideTitleContent: true })}
                    changeFilter={(value, key)=>this.changeFilter(value, key)}
                    filterOptions={filter}
                    gap_report_ids={this.state.gap_report_ids}
                    gapList={this.state.t011SectionInfo.list && this.state.t011SectionInfo.list.length}
                    // emptySection={() => this.setState({deleteType: 'section', deleteModal: true})}
                    emptyPrs={() => this.setState({deleteType: 'prs', deleteModal: true})}
                    deleteType={this.state.deleteType}
                    deleteGapIds={() => this.setState({deleteType: 'multiple'}, () => this.setState({deleteModal: true}))}
                    formInfo={formInfo}
                    fullScreen={this.state.fullScreen}
                    toggleFullScreen={() => this.setState({fullScreen: !this.state.fullScreen})}
                    operatorFlag={this.state.operatorFlag}
                    userList = {userList.length ? [{ id: 0, name: 'Unassigned' }, ...userList] : []}
                    moveGapIds={() =>  this.setState({prsDropdownModal:true})}
                    assignGapIds={() =>  this.setState({prsAssigneeModal:true})}
                  />
                  {
                    sectionId == 0 ?
                      <TableUICompList
                        theads={tableContentHd}
                        bulkOperation={false}
                        pagination={[]}
                        tableHeight={this.state.fullScreen ? window.innerHeight -100 : window.innerHeight -390}
                        content={t011ReportInfo.list.map((item, index) =>
                          <List
                            item={item}
                            index={index}
                            fetchSectionInformation={(sectionId) => this.fetchSectionInformation(sectionId)}
                          />
                        )}
                        recordsFound={false}
                      /> :
                      <TableUIComp
                        theads={sectionIds.includes(parseInt(sectionId)) ? parseInt(sectionId) === 26 ? gapSecReportCyclesHd : [2,5,6,7,8,9,10,11,21,22,14,15,16,17,23,24,25,26,27,28,29,30,31,32,33,34,35].includes(parseInt(sectionId)) ? gapSecReportATAHd : gapSecReportHd : [2,5,6,7,8,11,14,21,22,9,10].includes(parseInt(sectionId)) ? gapReporATAtHd : gapReportHd}
                        bulkOperation={this.state.t011SectionInfo.list.length > 0 ? true : false}
                        checkedAll={t011SectionInfoFiltered.length && this.state.gap_report_ids.length === t011SectionInfoFiltered.length}
                        selectAllItem={() => this.setState({gap_report_ids: this.state.gap_report_ids.length === t011SectionInfoFiltered.length ? [] : t011SectionInfoFiltered.map(gapList => gapList.id)})}
                        recordsFound={t011SectionInfoFiltered.length ? false : true}
                        noRecordStyle={{width: window.innerWidth - 130 + 'px', height: (this.state.fullScreen ? window.innerHeight - 160 : window.innerHeight - 480) + 'px'}}
                        changePerPage={this.changePerPage}
                        changePagination={this.changePagination}
                        tableHeight={this.state.fullScreen ? window.innerHeight -70 :window.innerHeight - 400}
                        content={t011SectionInfoFiltered.map((item, index) =>
                          <FinalSummeriesListV1
                            item={item}
                            key={`summary_${index}`}
                            index={index}
                            editableRow={editableRow}
                            checked={this.state.gap_report_ids.indexOf(item.id) !== -1}
                            selectItem={() => this.setState({gap_report_ids: this.state.gap_report_ids.indexOf(item.id) !== -1 ? this.state.gap_report_ids.filter(gapId => gapId !== item.id) :[...this.state.gap_report_ids, item.id]})}
                            toggleDelete={(id) => this.openDeleteModal(id)}
                            onChangeField={(value, keyParam) => this.onChangeField(value, keyParam, index)}
                            uploadAttachment={(files) => this.uploadAttachment(files, item.id)}
                            onUpdateField={(value, keyParam) => this.updateTableSectionsFieldApi(this.props, { [keyParam]: value, 'gap_report_section_id': sectionId }, item.id, sectionId)}
                            sectionId={parseInt(sectionId)}
                            fullScreen={this.state.fullScreen}
                            formInfo={formInfo}
                            editable={t011SectionInfo.authorized_operator}
                            operatorFlag={this.state.operatorFlag}
                            fetchSectionOnReturn={this.fetchSectionInformation}
                            userList = {[{ id: 0, name: 'Unassigned' }, ...userList.filter(user => user.is_project_user)]}
                          />
                        )
                        }
                      />
                  }
                </div>
                <EditFormBar
                  titleContent={
                    hideTitleContent === true ?
                      <div>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            Add to
                          </li>
                          <li style={{ width: '80%', display: 'inline-flex' }}>
                            <FieldCol md="6">
                              <FieldGroup className="form-group" style={{ marginBottom: 'auto' }}>
                                <Select
                                  name="form-field-name"
                                  value={addSectionId}
                                  options={t011ReportInfo.list}
                                  onChange={(section) => this.setState({ addSectionId: section.id })}
                                  labelKey="name"
                                  valueKey="id"
                                  style={{ border: 'none', borderBottom: '1px solid #717171', borderRadius: '0' }}
                                />
                              </FieldGroup>
                            </FieldCol>
                          </li>
                        </ul>
                      </div> : <h4 style={{fontSize: '18px'}}>Import Excel</h4>
                  }
                  toggleEditSideBar={() => this.setState({ openTechForm: false })}
                  isOpen={openTechForm}
                  style={openTechForm ? { width: '800px' } : { width: '0px' }}
                >
                  <div className="sidebar-form">
                    {openTechForm ?
                      <TechnicalInspectionFormV1
                        closeTechForm={() => this.setState({ openTechForm: false })}
                        sectionId={addSectionId}
                        exportReport={() => this.exportReport(formInfo.asset)}
                        sectionIds={sectionIds}
                        tabIndex={this.state.formTabIndex}
                        refreshList={() => this.getTableContentsApi(this.props)}
                        closeModalOnReturn={() => this.setState({ openTechForm: false })}
                        updateTitleContent={(flag, tabIndex) => this.setState({ hideTitleContent: flag, formTabIndex: tabIndex })}
                        formInfo={formInfo}
                        dropdownMenuList={[{ id: 0, name: 'Table of Content' }, ...t011ReportInfo.list]}
                        operatorFlag={this.state.operatorFlag}
                        getTableContentsApi={() => this.getTableContentsApi(this.props)}
                        userList = {[{ id: 0, name: 'None' }, ...userList.filter(user => user.is_project_user)]}
                      />
                      : null
                    }
                  </div>
                </EditFormBar>
              </div> : null
          }
        </div>

        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Upload Logs</h3></div>}
          toggleEditSideBar={() => this.setState({ toggleUploadLogs: false })}
          isOpen={this.state.toggleUploadLogs}
          style={this.state.toggleUploadLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.toggleUploadLogs ?
            <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px' }}>
              <ul className="list-unstyled" >
                {formInfo.current_form.upload_report.map((user, index) =>
                  <li style={{ borderBottom: '1px solid #d7d7d7', padding: '8px 0', fontSize: '14px' }} key={index}>{user.user}, <b style={{ fontSize: '15px' }}>{moment(user.created_at).format(displayDateTimeFormatShort) + ' UTC'}</b></li>
                )}
              </ul>
            </div> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={
            <div style={{marginTop: '10px'}}>
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item" style={{width: '20%'}}>
                  <h3 style={{ textTransform: 'capitalize' }}>Deleted Logs for</h3>
                </li>
                <li className="list-inline-item" style={{width: '75%'}}>
                  <Select
                    name="form-field-name"
                    value={addSectionId}
                    options={[{id: 0, name: 'All'},...t011ReportInfo.list]}
                    onChange={(section) => this.setState({ addSectionId: section.id }, () => this.fetchActivityLogs({gap_report_section_id: this.state.addSectionId}))}
                    labelKey="name"
                    valueKey="id"
                    style={{ border: 'none', borderBottom: '1px solid #717171', borderRadius: '0' }}
                  />
                </li>
              </ul>
            </div>
          }
          toggleEditSideBar={() => this.setState({ showActivityLogs: false, addSectionId: 0 })}
          isOpen={this.state.showActivityLogs}
          style={this.state.showActivityLogs ? { width: '800px' } : { width: '0px' }}
        >
          <div className="sidebar-form" style={{padding:'0 20px 10px'}}>
            <input placeholder="type to search" style={{border: "1px solid rgb(176 168 168)", padding: '10px', borderRadius: '5px', marginBottom: '10px'}} type="text" onChange={(event) => this.setState({searchLog: event.target.value})} value={this.state.searchLog}/>
          </div>
          {this.state.showActivityLogs ?
            <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px' }}>
              <ul className="list-unstyled">
                {
                  activityLogs && activityLogs.length ?
                    this.state.searchLog.trim() === '' ?
                    activityLogs.map((log, logIndex) => <ActivityLog log={log} key={`activity_log_${logIndex}`}/>)
                    :activityLogs.filter(log => log.toLowerCase().includes(this.state.searchLog.toLowerCase().trim())).map((log, logIndex) => <ActivityLog log={log} key={`activity_log_${logIndex}`}/>)
                  :<NoRecordFound/>
                }
              </ul>
            </div> : null
          }
        </EditFormBar>
        <DeleteModal isOpen={deleteModal} toggle={() => this.setState({ deleteModal: false  })} title={this.state.deleteType === 'single' ? "Are you sure to delete ?" : this.state.deleteType === 'section' ? `Are you sure to empty ${t011ReportInfo.list.filter(sec => parseInt(sec.id) === parseInt(sectionId)).length > 0 ? t011ReportInfo.list.filter(sec => parseInt(sec.id) === parseInt(sectionId))[0].name :'selected'}?` : this.state.deleteType === 'prs' ? `Are you sure to empty complete PRS?` : 'Are you sure to delete selected rows?'} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.setState({ deleteModal: false }); this.deleteTableSectionRowApi(this.props, deleteItem, sectionId, this.state.deleteType) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.setState({ deleteModal: false })}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <DeleteModal isOpen={prsDropdownModal} toggle={() => this.setState({ prsDropdownModal: false  })} title="Move Items To" style={{height:'300px'}}>
          <FieldCol md="12">
            <FieldGroup className="form-group" >
              <Select
                name="form-field-name"
                value={sectionMoveId}
                options={t011ReportInfo.list.map(item => {return {name:item.total_item ? `${item.name} (${item.total_item})` : item.name, id:item.id}})}
                labelKey ="name"
                valueKey ="id"
                onChange={(section) => this.moveGapIds(section)}
              />
            </FieldGroup>
          </FieldCol>
        </DeleteModal>
        <DeleteModal isOpen={prsAssigneeModal} toggle={() => this.setState({ prsAssigneeModal: false  })} title="Select Assignee" style={{height:'300px'}}>
          <FieldCol md="12">
            <FieldGroup className="form-group" >
              <Select
                name="form-field-name"
                value={sectionMoveId}
                options={userList.length ? [{ id: 0, name: 'Unassigned' }, ...userList] : []}
                labelKey ="name"
                valueKey ="id"
                onChange={(assignee) => this.assignGapIds(assignee)}
              />
            </FieldGroup>
          </FieldCol>
        </DeleteModal>
     </div>
    )
  }
}
const mapStateToProps = (state) => ({
  storageUsage: state.DataRoomReducer.storageUsage,
});

const mapDispatchToProps = (dispatch,ownProps) =>{
  return {
    usageDetail: () => dispatch(usageDetailAc()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT011v1);
