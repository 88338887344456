import React, {Component} from 'react';
import { Row, Col} from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { browserHistory } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
export default class InvoiceList extends Component{
    constructor(props) {
        super(props);
    }
    render(){
        const{ order } = this.props;
        return(
            <tr className="invoice-lists" onClick={() => order.status && order.status.value == 1 ? null : browserHistory.push("/billing/invoice/detail/"+order.id)}>
              <td>
                {
                  order.status && order.status.value == 1 ?
                    moment.utc(order.payment_date).format('MMMM DD, YYYY')
                  : moment(order.generated_for).format('MMMM DD, YYYY')
                }
              </td>
              <td>
                {
                  order.status && order.status.value == 1 ?
                    `Payment (${ order.card_brand } ${ order.card_last })`
                  :<span style={{color: '#4282f9'}}>{`Invoice for ${moment(order.generated_for).format('MMMM, YYYY')}`}</span>
                }
              </td>
              <td>
                  $ <NumericLabel>{ order.total }</NumericLabel>
              </td>
              {
                order.status && order.status.value != 1 ?
                <td className="donwload-invoice">
                  <span className="export-link" onClick={(e)=> { this.props.exportInvoice(e, order.id, 'pdf') }}><img src={imgStoragePath +'pdf_file.png'}/></span> &nbsp; <span className="export-link" onClick={(e)=> { this.props.exportInvoice(e, order.id, 'csv') }}><img src={imgStoragePath +'xls_icon.png'}/></span>
                </td>
                :<td className="donwload-invoice"></td>

              }
          </tr>
        )
    }
}
