import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import RegionSlide from './RegionSlide';
import { UPDATE_SIMULATION_FIELDS, CANCEL_REGION_BAR } from '../actions';
import { mrCutsomRegionAc } from '../actionCreators';
import { sRegionListAc } from '../../../../shared/actionCreators';
import { imgStoragePath } from '../../../../constants';
class GrpDropdownField extends Component{
  constructor(props){
    super(props);
    this.state = {
      toggleStatus: false
    }
  }
  componentDidMount(){
    if(!this.props.regionList.length){
      this.props.fetchRegions();
    }
  }
  updateField = (option) => {
    if(option){
      this.props.updateSimulationField({
        metrixType: this.props.metrixType,
        programType: this.props.programType,
        eventIndex: this.props.eventIndex,
        keyParam: this.props.keyParam,
        value: option
      });
    }
  }
  clearCustomVal = () => {
    this.props.updateSimulationField({
      metrixType: this.props.metrixType,
      programType: this.props.programType,
      eventIndex: this.props.eventIndex,
      keyParam: 'variable_conditions',
      value: []
    });
  }
  updateRegion = (e) => {
    e.preventDefault();
    let variable_conditions = [];
    Object.keys(this.props.mrCustomRegion.variable_condition).map(re => {
      if(this.props.mrCustomRegion.variable_condition[re].default){
        variable_conditions.push(this.props.mrCustomRegion.variable_condition[re].default);
      }
    });
    this.props.updateSimulationField({
      metrixType: this.props.metrixType,
      programType: this.props.programType,
      eventIndex: this.props.eventIndex,
      keyParam: 'variable_conditions',
      value: variable_conditions
    });
  }
  render(){
    const {
      simulationType,
      newvalue,
      value,
      metrixType,
      programType,
      eventIndex,
      regionList,
      newdropDownValue,
      dropDownValue,
      engine_type,
      mrCustomRegion,
      regionFlag,
      enginesInfo,
      newenginesInfo
    } = this.props;
    if(simulationType == 0){
      return(
        <div>
          <h6 className="para-ui">{dropDownValue}</h6>
        </div>
      )
    } else if(simulationType == 1){
      return(
        <div className="editable-field">
          <Select
            name="form-field-name"
            placeholder = 'Select Region'
            className="custom-select-block"
            name="form-field-name"
            value={value}
            labelKey="name"
            valueKey="id"
            options={regionList}
            onChange={this.updateField}
            disabled={ enginesInfo.variable_conditions && enginesInfo.variable_conditions.length ? true:false }
          />
          <span className="field-edit-icn">
            <img src={imgStoragePath+"edit-field.png"} alt="ic" />
          </span>
          <ul className="list-inline">
            <li className="list-inline-item" style={{fontSize: '10px',color:'#1C97FB'}} onClick={() => this.props.customRegion(value, engine_type)}>Adjust Region Variables</li>
            {
              enginesInfo.variable_conditions && enginesInfo.variable_conditions.length ?
              <li className="list-inline-item" style={{fontSize: '10px'}} onClick={this.clearCustomVal}>Clear</li>:null
            }
          </ul>
          <div style={{zIndex: '1001'}} className={ regionFlag ? 'assign-sidebar-block show-regionbar' : 'assign-sidebar-block hide-regionbar'} >
            {
              Object.keys(mrCustomRegion).length ?
              <form onSubmit={(e) => this.updateRegion(e)}>
                <ul className="list-unstyled">
                {Object.keys(mrCustomRegion.variable_condition).map((item, index) => <RegionSlide  regionCondition={mrCustomRegion.variable_condition[item]} item={item} key={index} />)}
                <li className="submit-block">
                  <input type="submit" className="primary-btn" value="Update"/>

                  <a href="javascript:void(0)" onClick={() => this.props.toggleRegion(false) }>Cancel</a>
                </li>
                </ul>
              </form>:null
            }
          </div>
        </div>
      )
    }else if(simulationType == 2){
      return(
        <div className="override-value">
          {
            newenginesInfo.variable_conditions && newenginesInfo.variable_conditions.length ?
            <h6>Customzed Region</h6> :
              value == newvalue ?
              <h6>
                  {dropDownValue}
              </h6>:
              <div>
                <h6 className="changed-value">
                    {newdropDownValue}
                </h6>
                <p className="old-value">
                    {dropDownValue}
                </p>
              </div>
          }
        </div>
      )
    }else{
      return null
    }
  }
}
const mapStateToProps = state => ({
  simulationType: state.MRFinanceGraphReducer.simulationType,
  regionList: state.sharedReducers.sRegionList,
  mrCustomRegion: state.MRFinanceGraphReducer.mrCustomRegion,
  regionFlag: state.MRFinanceGraphReducer.regionFlag
});
const mapDispatchToProps = dispatch => {
  return {
    fetchRegions:() => dispatch(sRegionListAc()),
    customRegion: (region_id, engine_type) => dispatch(mrCutsomRegionAc(region_id, engine_type)),
    toggleRegion: () => dispatch({
      type: CANCEL_REGION_BAR,
      payload: false
    }),
    updateSimulationField: (data) => dispatch({
      type: UPDATE_SIMULATION_FIELDS,
      payload: data
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GrpDropdownField)
