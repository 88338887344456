import React from 'react';
import { Link } from 'react-router';
import { Container, Row, Col} from 'reactstrap';
import { imgStoragePath } from '../../constants';

const Plans = () => {
    return(
        <div className="plans-wrapper ">
            <Container>
                <Row>
                    <Col lg="4" md="6" className="outer-wrapper">
                        <div className="card-wrapper">
                            <div className="card-title">
                                <h4>Free</h4>
                                <p>No credit card required</p>
                            </div>
                            <div className="card-features">
                               <ul className="list-unstyled">
                                    <li>Data Storage - Upto 5GB</li>
                                    <li>No. of users - One</li>
                                    <li>All Core features</li>
                                    <li>Technical support - In app chat</li>
                               </ul>
                               <Link to="/signup" target="_blank" className="sec-btn">SIGN UP</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4" md="6" className="outer-wrapper">
                        <div className="card-wrapper">
                            <div className="card-title">
                                <h4>Pay-As-You-Go</h4>
                                <p>Monthly and yearly plans available</p>
                            </div>
                            <div className="card-features">
                               <ul className="list-unstyled">
                                    <li>Data Storage - Unlimited</li>
                                    <li>No. of users - Unlimited</li>
                                    <li>All Core features</li>
                                    <li>Technical support - 24/7</li>
                               </ul>
                               <Link to="/signup" target="_blank" className="sec-btn">SIGN UP</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{ size: 4, offset: 0 }} md={{ size: 6, offset: 3 }} className="outer-wrapper">
                        <div className="card-wrapper">
                            <div className="card-title">
                                <h4>Enterprise</h4>
                                <p>Custom Pricing</p>
                            </div>
                            <div className="card-features">
                               <ul className="list-unstyled">
                                    <li>Data Storage - Unlimited</li>
                                    <li>No. of users - Unlimited</li>
                                    <li>All Core features, Advanced tools and customised features</li>
                                    <li>Dedicated Technical Support</li>
                               </ul>
                               <Link to="mailto:sales@acumen.aero" target="_blank" className="sec-btn">CONTACT US</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Plans;
