
import React, { Component } from 'react';
import { Link } from 'react-router';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from "react-datepicker";
import NumericLabel from 'react-pretty-numbers';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select/dist/react-select.css';
import {  fieldDateFormat, backendDateFormat, prettyNumberParamsL, imgStoragePath } from '../../../constants';
import {  FieldGroup, FieldLabel, FieldDate } from '../../contracts/Elements';
export default class FleetViewHeader extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false,
      filterType: '',
      startDate: this.props.cashSimulationViewFilter.startDate,
      endDate: this.props.cashSimulationViewFilter.endDate
  }
  this.toggle = this.toggle.bind(this);
  this.updateField = this.updateField.bind(this);
  }

  componentDidMount(){
    this.setState({
      filterType: this.props.cashSimulationViewFilter.filter_type
    });
  }

  updateField(type, value){
    this.props.updateField(type, value);
    this.setState({
      filter_type: value
    })
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  updateField = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: value
    }))
    this.props.updateField(type, value);
  }

  render(){
    const { cashSimulationViewFilter, cashFleetView } = this.props;
    return(
      <div className="simulation-header"  id="simulation-header" style={this.props.style}>

      <h3 className="gama">
       <Select
          name="form-field-name"
          className="custom-select-block"
          value= {cashSimulationViewFilter.filter_type}
          options = {[{value:'cash_inflow', label:'Lessor Revenues By Aircraft'}, {value:'cash_outflow', label:'Lessor Expense By Aircraft'}, {value:'cumulative_cashflow', label:'Cumulative Cash Flow Summary By Asset'}, {value:'halflife_main', label:'Value Adjustment from  Half-Life'}, {value:'eol_compensation', label:'EOL Compensation'}]}
          labelKey="label"
          valueKey="value"
          onChange = {(target) => this.updateField('filter_type',target.value ? target.value : '' )}
        />
        <span onClick={() => this.props.exportMsnFleetCashFlow(cashFleetView, this.state.filterType, this.state.startDate, this.state.endDate, 'xls')} style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px', position: 'absolute', right:'0', top:'10px', color: '#2f8cff'}}><img width="18" src={imgStoragePath+"export_icon.png"} alt="export" /> Export</span>
      </h3>
      {
         cashSimulationViewFilter.filter_type != "eol_compensation" ?
           <div className="flex-centered simulation-date-block">
      <FieldGroup className="form-group date-block">
          <FieldLabel className="label">From</FieldLabel>
          <DatePicker
          dateFormat = {fieldDateFormat}
          selected={ cashSimulationViewFilter.startDate &&  moment(cashSimulationViewFilter.startDate).isValid() ? moment(cashSimulationViewFilter.startDate): null }
          onChange={(e) => this.updateField('startDate',moment(e).format(backendDateFormat))}
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          minDate= {cashFleetView.minDate ? moment(cashFleetView.minDate) : moment()}
          maxDate={cashFleetView.maxDate ? moment(cashFleetView.maxDate) : moment()}
          onFocus={() => this.handleFocus()}
          onBlur={() => this.handleBlur()}
        />
      </FieldGroup>
        <FieldGroup className="form-group  date-block">
          <FieldLabel className="label">To</FieldLabel>
          <DatePicker
          dateFormat = {fieldDateFormat}
          selected={ cashSimulationViewFilter.endDate &&  moment(cashSimulationViewFilter.endDate).isValid() ? moment(cashSimulationViewFilter.endDate): null }
          onChange={(e) => this.updateField('endDate',moment(e).format(backendDateFormat))}
          showYearDropdown
          showMonthDropdown
          minDate= {cashFleetView.minDate ? moment(cashFleetView.minDate) : moment()}
          maxDate={cashFleetView.maxDate ? moment(cashFleetView.maxDate) : moment()}
          useShortMonthInDropdown
          onFocus={() => this.handleFocus()}
          onBlur={() => this.handleBlur()}
        />
        </FieldGroup>
        <div>
          <Link className="update-list" style={moment(this.state.startDate).isValid() && moment(this.state.endDate).isValid() ? {opacity: 1} : {opacity: 0.5, cursor:'not-allowed'}}  onClick={() => this.props.updateField('submit', cashSimulationViewFilter)}>Update Listing</Link>
        </div>
        <div className="total-value">
          <h6 className="caption-ui">Total Value</h6>
          <span><NumericLabel params={prettyNumberParamsL}>{cashFleetView.total_value}</NumericLabel></span>
        </div>
      </div>
      : null
      }
    </div>
    )
  }
}
