import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { numberRegx, imgStoragePath } from '../../../../constants';
import { ListLoader } from '../../../../shared';
export default class DeRatePopUp extends Component{
  constructor(props){
    super(props);
    this.state = {
      derate:{
        incRatio: '',
        ratios: [],
        derates: []
      },
      error: ''
    }
  }
  calculateMrRate = (type) => {
    if(numberRegx.test(this.state.derate.incRatio) && this.state.derate.derates.length > 0){
      let data = Object.assign({}, this.state.derate);
      if(type == 'xls'){
        data = {
          ...data,
          download: 'xls'
        }
      }
      this.props.calculateDeRate(data, type);
    }else{
      this.setState({
        error: 'Enter Valid Incremental Ratio and Selet atleast one De-Rate'
      })
    }
  }
  updateCondition = (condition) => {

    if(this.state.derate.derates.indexOf(condition.id) == -1){
      if(this.state.derate.derates.length < 4){
        this.setState(prevState => ({
          ...prevState,
          derate: {
            ...prevState.derate,
            derates: [...prevState.derate.derates, condition.id]
          },
          error: ''
        }));
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        derate: {
          ...prevState.derate,
          derates: prevState.derate.derates.filter(id => id != condition.id)
        },
        error: ''
      }));
    }
  }
  updateIncRatio = (e) => {
    let rationRange = [this.props.engineInfo.utilization_ratio];
    const value = e.target.value;
    let basicRatio = this.props.engineInfo.utilization_ratio;
    if(numberRegx.test(value) && value > 0 && value < this.props.engineInfo.utilization_max_ratio){
      for(var i =0; i < 4; i++){
        if(basicRatio > this.props.engineInfo.utilization_min_ratio){
          basicRatio -= value;
          if(basicRatio > 0 && basicRatio > this.props.engineInfo.utilization_min_ratio){
            rationRange.push(parseFloat(basicRatio.toFixed(1)));
          }
        }
      }
      basicRatio = parseFloat(this.props.engineInfo.utilization_ratio);
      for(var j =0; j<4; j++){
        if(basicRatio < this.props.engineInfo.utilization_max_ratio){
          basicRatio += parseFloat(value);
          if(basicRatio > 0 && basicRatio < this.props.engineInfo.utilization_max_ratio){
            rationRange.push(parseFloat(basicRatio.toFixed(1)));
          }
        }
      }
    }
    this.setState(prevState => ({
      ...prevState,
      derate:{
        ...prevState.derate,
        incRatio:value,
        ratios: rationRange.sort(function(a, b){return a - b}),
      },
      error: ''
    }));
  }
  render(){
    const { engineInfo, deRateList, deRateMatrix } = this.props;
    const { derate  } = this.state;
    return(
      <div className="engine-de-rate-modal">
        {deRateMatrix.flag ? <ListLoader />:null}
        <h3 className="flex-centered">
          {
            deRateMatrix.data.length ? <img onClick={() => this.props.updateDeRateOp({engineNo: deRateMatrix.engineNo,flag:false, data:[]})} className="back-icon" src={imgStoragePath+"back_arw.png"} alt="" width="16"/>:null
          }

          MR Calculator for Asset Leases
          {
            deRateMatrix.data.length ? <span onClick={() => this.calculateMrRate('xls')}><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>Export</span>:null
          }

        </h3>

        {
          !deRateMatrix.data.length ?
          <div>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label className="label">Base Utilization Ratio</label>
                  <input readOnly type="text" defaultValue={engineInfo.utilization_ratio} />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="label">Enter Increment Ratio</label>
                  <input
                    type="text"
                    placeholder="Example 0.2"
                    onChange={this.updateIncRatio}
                    value={derate.incRatio}
                  />
                </div>
              </Col>
            </Row>
            {
              derate.ratios.length ?
              <Row className="loi-row">
                <Col md="12">
                  <label className="label">Projected Ratio Range</label>
                  <table>
                    <tr>
                      {derate.ratios.map((ratio,index) => <td className={ratio == engineInfo.utilization_ratio?'base-ratio':''} key={index}>{ratio}</td>)}
                    </tr>
                  </table>
                </Col>
              </Row>:null
            }
            <Row className="loi-row">
              <Col md="12">
                <label className="label">Select upto 4 De-rate value</label>
                <table>
                  <tr>
                    {deRateList.map((deRate, index) => <td onClick={() => this.updateCondition(deRate)} className={ derate.derates.indexOf(deRate.id) != -1 ? 'selected':'' } key={index}>{deRate.condition}</td>)}
                  </tr>
                </table>
                <span style={{color: '#ffc107', fontSize: '12px'}}>Note: Maximum 4 De-Rate values can be selected for calculation</span>
              </Col>
            </Row>
            <h6 className="error-msg" style={{marginBottom: '16px'}}>{this.state.error}</h6>
            <button className="btn primary-btn" disabled={deRateMatrix.flag} onClick={() => this.calculateMrRate('table')}>{deRateMatrix.flag ? 'processing...':'Calculate MR Rates' }</button>
            <span className="cancel-derate-modal" onClick={this.props.toggleModal}>Cancel</span>
          </div>:
          <div className="derate-matrix-blk">
            <table>
                <thead>
                  <tr>
                    <th>Ratio / Derate</th>
                    {deRateMatrix.data[0].ratios.map((ratio,index) => <th className="text-center">{ratio.ratio}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {
                    deRateMatrix.data.map((deRate,index) =>
                      <tr>
                        <td>{deRate.percentage}</td>
                        {
                          deRate.ratios.map( (ratio, childIndex) =>
                          <td className="text-center">${ratio.mr}</td>
                         )
                        }
                      </tr>
                    )
                  }
                </tbody>
              </table>
              <span className="cancel-derate-modal" onClick={this.props.toggleModal}>Cancel</span>
          </div>
        }
      </div>
    )
  }
}
