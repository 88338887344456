import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-rangeslider/lib/index.css'
import Slider from 'react-rangeslider';
import { UDPATE_CUSTOM_REGION } from '../actions';
class RegionSliderChange extends Component{
    constructor(props, context) {
      super(props, context)
    }
    handleOnChange = (value) => {
      if(this.props.mode == 'edit'){
        const selectedValue = this.props.regionCondition['ranges'].filter(item => item.order === value)
        this.props.changeRegionCond(this.props.item, selectedValue[0].id);
      }
    }
    handleFormat = (value) => {
      return this.props.regionCondition['ranges'][value];
    }
    render(){
      var { regionCondition, item } = this.props;
      const statLabels = regionCondition['ranges'].sort(function(a,b) {return a.order - b.order})
      var labels = {}
      statLabels.map(item => {
        labels = {...labels, [item.order]: item.value}
      })
      const title = regionCondition['name']
      const minValue = Object.keys(labels)[0]
      const maxValue = Object.keys(labels)[Object.keys(labels).length - 1];
      const value = regionCondition['default'] ? statLabels.filter(item => item.id === regionCondition['default'])[0].order: minValue;
      return(
        <li>
          <h6 className="caption-ui"><span style={{display:'inline-block',maxWidth:'40%'}}>{title}</span> <span style={{float: 'right',display:'inline-block',maxWidth:'40%'}}>{labels[value] ? labels[value] : ''}</span></h6>
          <Slider
            disabled={ this.props.mode == 'view' ? 'disabled' : ''}
            value={parseInt(value)}
            orientation="horizontal"
            min = {parseInt(minValue)}
            max = {parseInt(maxValue)}
            tooltip={false}
            labels={ labels }
            onChange={this.handleOnChange}
            format  = {this.handleFormat}
            />
        </li>
      );
    }
}
const mapStateToProps = state => {

}
const mapDispatchToProps = dispatch => {
  return {
    changeRegionCond:(index, value) => dispatch({
      type: UDPATE_CUSTOM_REGION,
      payload: {index, value}
    })
  }
}
export default connect(null, mapDispatchToProps)(RegionSliderChange);
