export const ADD_CONFIG = 'ADD_CONFIG';
export const SEATING_LOADER = 'SEATING_LOADER';
export const GET_CONFIG = 'GET_CONFIG';
export const TOGGLE_LOPA_SIDEBAR = 'TOGGLE_LOPA_SIDEBAR';
export const TOGGLE_TECH_SIDEBAR = 'TOGGLE_TECH_SIDEBAR';
export const TOGGLE_DETAIL_SIDEBAR = 'TOGGLE_DETAIL_SIDEBAR';
export const UPDATE_TECH_SIDEBAR = 'UPDATE_TECH_SIDEBAR';
export const SEATS_ARR = 'SEATS_ARR';
export const FINDING_ARR = 'FINDING_ARR';
export const WINDOW_ARR = 'WINDOW_ARR';
export const UPDATE_WINDOW_FINDING = 'UPDATE_WINDOW_FINDING';
export const ALL_EXISTING_FINDINGS = 'ALL_EXISTING_FINDINGS';
export const PAX_SEATING_LOADER = 'PAX_SEATING_LOADER';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const FINDING_ARR_UPDATE_AC = 'FINDING_ARR_UPDATE_AC';
export const SEAT_ARR_UPDATE_AC = 'SEAT_ARR_UPDATE_AC';
export const CLEAR_ARRAYS = 'CLEAR_ARRAYS';
export const WINDOW_ARR_UPDATE_AC = 'WINDOW_ARR_UPDATE_AC';
export const DELETE_INTERIOR_CONFIG = 'DELETE_INTERIOR_CONFIG';
