import * as actions from '../actions';
export default function(state = {
  qfdMntEventList: [],
  reserveAcntList: [],
  qfdMntEventCrud: {
    flag: false,
    type: '',
    data: {}
  },
  qfdMntEventLoader: true
}, action){
  switch (action.type) {
    case actions.CONTR_QFT_MNT_EVENT_LIST:
      return{
        ...state,
        qfdMntEventList: action.payload
      }
    case actions.ADD_QFT_MNT_EVENT_SUCCESS:
      return {
        ...state,
        qfdMntEventList: [ action.payload, ...state.qfdMntEventList]
      }
    case actions.EDIT_QFT_MNT_EVENT_SUCCESS:
      return {
        ...state,
        qfdMntEventList: state.qfdMntEventList.map(list => list.id == action.payload.id ? action.payload : list)
      }
    case actions.QFT_MNT_COND_CRUD:
      return {
        ...state,
        qfdMntEventCrud:action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        qfdMntEventCrud: {
          ...state.qfdMntEventCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.QFD_MNT_LOADER:
      return {
        ...state,
        qfdMntEventLoader: action.payload
      }
    case actions.RESERVE_ACNT_LIST:
      return {
        ...state,
        reserveAcntList: action.payload
      }
    default:
      return state;
  }
}
