import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { imgStoragePath } from '../../../constants';
import { Link } from 'react-router';
import { SignupForm, Features, Footer, LoginForm, SelectLessor, ForgotPassword, EmailVerification, OTPVerification } from '../components';
import { authorizationAc } from '../../../User/actionCreators';
import { ListLoader} from '../../../shared';
import { browserHistory } from 'react-router'

class Onboarding extends Component{
    constructor(props){
        super(props);
        this.state = {
            signupType : "1",
            verticalMiddle : false,
        }
    }

    signupTypeChange = (value) => {
        this.setState(prevState => ({
            ...prevState,
            signupType : value,
        }));
    }

    componentWillMount(){
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      if(window.location.href.includes('select-lessor') && userDetail && userDetail.defaultLessor){
        if(userDetail.user.permission && userDetail.user.permission.dashboard && userDetail.user.permission.dashboard.indexOf('R') != -1){
          browserHistory.push( "/" );
        }else {
          browserHistory.push( "/assets-listing" );
        }
      }
        if(window.location.href.includes('select-lessor') || window.location.href.includes('forgot-password') || window.location.href.includes('/user/activate/:token') ||window.location.href.includes('otp-verification')){
            this.setState(prevState => ({
                ...prevState,
                verticalMiddle : true,
            }));
        }
    }

    render(){
        return(
            <div>
              {this.props.loginLoader ? <ListLoader /> : null }
                <Row>
                    <div className="onboarding-outer-wrapper" style={ this.state.verticalMiddle ? {height : '100vh'} : {}}>
                        <div className="container">
                            <div className="row flex-centered navbar-row">
                                <Col md="4" xs="2">
                                    <img style={{cursor: 'pointer'}} onClick={() => browserHistory.push('/signup')} className="logo" src={imgStoragePath+'phase-2/sparta_logo.svg'} alt="logo image"/>
                                </Col>
                                {!window.location.href.includes('select-lessor') ?
                                    <Col md="8" xs="10">
                                        {window.location.href.includes('forgot-password') || window.location.href.includes('otp-verification') ?
                                            <p className="text-right sign-in-btn"><a href="/signup#features" className="pricing-button" style={{marginLeft: '5px'}}>Features</a><span style={{fontSize: '12px'}}>Already have an account ? </span> <Link to="/login" className="pricing-button" style={{marginLeft: '5px', fontWeight: '600'}}>Sign In</Link><Link to="/pricing" className="ternary-button" style={{marginLeft: '0px', borderRadius: '15px', border: '1.5px solid #fff'}}>Buy Now</Link></p>
                                        :null}
                                        {window.location.href.includes('login') ?
                                            <p className="text-right sign-in-btn" style={{fontSize: '12px'}}><a href="/signup#features" className="pricing-button" style={{marginLeft: '5px'}}>Features</a><span>Don’t have an account ? </span> <Link to="/signup" className="pricing-button" style={{marginLeft: '5px', fontWeight: '600'}}>Sign Up</Link><Link to="/pricing" className="ternary-button" style={{marginLeft: '0px', borderRadius: '15px', border: '1.5px solid #fff'}}>Buy Now</Link></p>
                                        :null}
                                     </Col>
                                :null}
                            </div>
                            {

                            }
                            <div className={this.state.verticalMiddle ? "flex-centered row hero-row": "row" } style={ this.state.verticalMiddle ? {height : '70vh'} : {}}>
                                <Col md="6" className="hero-text-wrapper" style={this.state.verticalMiddle ? {marginTop: '0'} : null}>
                                    <h1>
                                        Simplifying <br/>
                                        Asset Management
                                    </h1>
                                    <h2>
                                        SPARTA is a digital Asset Manager,<br/>
                                        an extension of the human workplace
                                    </h2>
                                </Col>
                                <Col md="6">
                                    <div className="form-wrapper">
                                        {
                                            window.location.href.includes('signup') ? <SignupForm authorization={this.props.authorization} signupTypeChange={this.signupTypeChange}  /> : null
                                        }
                                        {
                                            window.location.href.includes('login') ? <LoginForm authorization={this.props.authorization} /> : null
                                        }
                                        {
                                            window.location.href.includes('select-lessor') ? <SelectLessor authorization={this.props.authorization} loginLoader={this.props.loginLoader} /> : null
                                        }
                                        {
                                            window.location.href.includes('forgot-password') ? <ForgotPassword authorization={this.props.authorization} /> : null
                                        }
                                        {
                                            window.location.href.includes('user/activate/') ? <EmailVerification authorization={this.props.authorization} /> : null
                                        }
                                        {
                                            window.location.href.includes('otp-verification') ? <OTPVerification params={this.props} authorization={this.props.authorization}  /> : null
                                        }
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </Row>
                {
                    window.location.href.includes('signup') ? <Features signupType={this.state.signupType} /> : null
                }
                {
                    window.location.href.includes('signup') || window.location.href.includes('/login') ? <Footer /> : null
                }

            </div>
        )
    }
}

const mapStateToProps = (state) =>({
  loginLoader: state.userAssetReducer.loginLoader
})

const mapDispatchToProps = (dispatch) => {
  return{
    authorization: (data) => dispatch(authorizationAc(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
