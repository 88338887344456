import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
export const mrClaimListAc = (params, queryParam={}) => {
  return (dispatch) => {
    dispatch({
      type: actions.MR_CLAIM_LOADER,
      payload: true
    });
    globalGetService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/', queryParam)
    .then(response => {
      dispatch({
        type: actions.MR_CLAIM_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MR_CLAIM_LIST,
          payload: response.data.data
        });
      }
    });
  }
}
