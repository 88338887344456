import React, { Component } from 'react';
import { Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { displayDateFormat, fieldDateFormat, backendDateFormat, imgStoragePath } from '../../../constants';
export default class ContrDateField extends Component{
  constructor(props){
    super(props);
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
    if(this.props.error){
      this.props.updateErrorCode(this.props.keyParam);
    }
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){

    return(
      <Col md={this.props.md ? this.props.md:'12'}>
        <div className="form-group">
          <label className="label">{this.props.label}
          { this.props.valReq && this.props.type != 'view' ? <sup className="reqStar"> * </sup> : null } { this.props.valInfo && this.props.type != 'view' ? <img src={imgStoragePath+"info_icon.png"} alt="info"/> : null}
          </label>
          { this.props.type != 'view' ?
            <DatePicker
              dateFormat = {fieldDateFormat}
              selected={ this.props.value &&  moment(this.props.value).isValid() ? moment(this.props.value): null }
              onChange={(e) => this.props.updateField(this.props.keyParam, moment(e).format(backendDateFormat))}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              onFocus={() => this.handleFocus()}
              onBlur={() => this.handleBlur()}
            />:<h6>{this.props.value ? moment(this.props.value).format(displayDateFormat):'--'}</h6>
           }
           {
             this.props.error ?
             <h6 className="error-msg">{this.props.error}</h6>
            : null
           }

        </div>
      </Col>
    )
  }
}
