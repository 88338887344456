import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import '../../../assets/styles/technical_inspections.scss';
import { EditFormBar, ImageCard, ImageSlider, DeleteModal, ContentDrop, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, ImageAnnotator, DownloadedLogs } from '../../../Elements';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { fetchDataPlatePicsAc, editPlatePicAc, addAndChangePlateAc, deletePlateAc, saveEditedImageAc } from '../actionCreators';
import { DELETE_PLATE_PICTURES, PLATE_EDIT_PIC } from '../actions';
import {  MSNSwitcherHeader, ListLoader, ExportFiles,LicenceModal } from '../../../../../shared';
import { imgStoragePath,noteFormsText } from '../../../../../constants';
import { toastFlashMessage } from '../../../../../utils';
import {downLoadTechFormAc} from '../../../../../shared/actionCreators'
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { BUY_LICENCE } from '../../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../../utils'

class InspectionFormT002 extends Component {
  constructor(props){
    super(props);
    this.state = {
      dragOver: false,
      modalTitle: '',
      modal: false,
      delModal: false,
      sectionId: null,
      picId: null,
      sliderIndex: 0,
      imageSlider: {
        images: [],
        startIndex: 0
      },
      image: '',
      downloadFormLogs: false
    }
  }
  uploadFiles =(id, sectionId, pictureId, files) =>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    if(files.length > 1){
      toastFlashMessage('Only one file can be uploaded', 'error');
      return;
    }else {
      this.props.addAndChangePlate(id, sectionId, pictureId, files);
    }
  }

  saveImage = () =>{
    document.querySelector("#annotator").contentWindow.document.querySelector('#btn-download').click();
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        editedImage: document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src')
      }))
      this.props.saveEditedImage(this.props.platePicEditCrud.data.id, document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src'))
    }, 1000)
	 }

  componentDidMount(){
    this.props.fetchDataPlatePics();
    this.props.getFormDetails(1, 'T002');
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      modalTitle: '',
    });
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggleDelModal = (title='', sectionId=null, picId=null) => {
    this.setState({
      delModal: !this.state.delModal,
      modalTitle: title,
      sectionId: sectionId,
      picId: picId
    });
  }
  openSlider = (plates, startIndex,title) => {

    let items = [];
    let position=0;

    plates.map(plate => {
      if(plate.pictures !=null && plate.pictures.length >0 ){
        items.push({
          original: plate.pictures[0].image,
          thumbnail: plate.pictures[0].image,
          title:plate.name,
          id:plate.id,
        })
      }
    });

    items.map((item,index) => {
     if(item.id==plates[startIndex].id){
     position=index;
     }
    });

    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      sliderIndex: position,
      imageSlider: {
        ...prevState.imageSlider,
        images: items,
        startIndex: position
      }
    }));
  }

  onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }
  getCurrentIndex = (index) => {
    this.setState({
      sliderIndex: index
    })
  }
  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  onDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver: false,
    })
  }

  render(){
    const { platePictures, platePicEditCrud, dataPlateLoader, techExportLoader, formStatusLoader, formInfo } = this.props;
    return(
      <div className="technical-inspection-cn" onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('data-plate-pictures','MSN_'+formInfo.asset.msn+'_'+formInfo.current_form.name,fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />
        { techExportLoader || dataPlateLoader || formStatusLoader ? <ListLoader drawer="primary-nav" />:null}
        <div className="form-t001-cn">
        <Col sm="12">
            <p className="notice-info" style={{margin:'10px -15px'}}>{noteFormsText}</p>
          </Col>
          <Row>
            { platePictures.map((item, index) =>
              <Col className="image-row" key={index} md="3">
                <h4 style={{width: '212px'}}>{item.name}</h4>
                <ul className="list-inline">
                  {
                    item.pictures.length ?
                    <ImageCard
                      picture={item.pictures[0]}
                      toggleDelModal={() => this.toggleDelModal('Are you sure to delete?', item.id, item.pictures[0].id)}
                      editImage={() => this.props.editPlateImage({...item.pictures[0], name: item.name})}
                      openSlider = {() => this.openSlider(platePictures, index)}
                      changePicture= {(files) => this.uploadFiles(item.id, item.aircraft_section_id, item.pictures[0].id, files)}
                      options = { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? ['E', 'R', 'V', 'C']: ['V']}
                    />
                    : formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                    <li className="list-inline-item dropable-zone"
                      onDrop={(evt) => this.uploadFiles(item.id, item.aircraft_section_id, null, evt.dataTransfer.files)}
                      style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}} >
                      <ContentDrop acceptExtensions={'image/*'} mutlipleEnabled={false} filesUpload={(e) => this.uploadFiles(item.id, item.aircraft_section_id, null ,e.target.files)} />
                      <span className="img-info-msg">
                        Recommended Picture dimension 400 X 400 Pixels
                      </span>
                    </li>:<li style={{width: '250px', height: '250px'}}></li>
                  }
                </ul>
              </Col>
            )}
          </Row>
        </div>
        <EditFormBar
          titleContent={ <div><h3>{platePicEditCrud.data.name}</h3></div>}
          toggleEditSideBar={() => this.props.closeImageSideBar(PLATE_EDIT_PIC, {flag: false, data: {}})}
          isOpen={platePicEditCrud.flag}
          style={platePicEditCrud.flag ? {width: '840px'} : {width: '0px'}}
          >
          { platePicEditCrud.flag ?
           <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight - 40) + 'px', paddingBottom: '90px', margin: '0 auto'}}>
             <ImageAnnotator style={{marginLeft: '20px'}} markup={false} image={platePicEditCrud.data.image} width="800" height="500" color="ff0000" fontSize="12"/>
            </div>:null
          }
          <div className="edit-sidebar-footer" style={platePicEditCrud.flag ? {width: '840px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 840) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button disabled={this.props.dataPlateLoader} onClick={this.saveImage} type="submit" className="btn btn-primary">{this.props.dataPlateLoader ? 'Processing...':'Save Changes'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={() => this.props.closeImageSideBar(PLATE_EDIT_PIC, {flag: false, data: {}})}> Cancel</Link>
                </li>
              </ul>
            </div>
        </EditFormBar>
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal()} title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal();this.props.deleteGenPicture(this.state.sectionId, this.state.picId)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-tform-pictures">
          <ModalHeader toggle={this.toggle}>{this.state.imageSlider.images.length ? this.state.imageSlider.images[this.state.sliderIndex].title:''}</ModalHeader>
          <ModalBody>
            {
              this.state.modal ?
              <ImageSlider getCurrentIndex={this.getCurrentIndex} imageSlider={this.state.imageSlider} />:null
            }

          </ModalBody>
        </Modal>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="2"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  platePictures: state.FormT002Reducer.platePictures,
  platePicEditCrud: state.FormT002Reducer.platePicEditCrud,
  dataPlateLoader: state.FormT002Reducer.dataPlateLoader,
  techExportLoader:state.sharedReducers.techExportLoader,
  formInfo: state.FormT001Reducer.formInfo,
  formStatusLoader:state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.DataRoomReducer.storageUsage,
});
const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    fetchDataPlatePics: () => dispatch(fetchDataPlatePicsAc(ownProps)),
    editPlateImage: (data) => dispatch(editPlatePicAc(ownProps, data)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    addAndChangePlate: (id, sectionId, pictureId, files) => dispatch(addAndChangePlateAc(ownProps,id, sectionId, pictureId,files)),
    deleteGenPicture: (sectionId, pictureId) => dispatch(deletePlateAc(ownProps, sectionId, pictureId)),
    closeImageSideBar: (type, data) => dispatch({
      type: type,
      payload: data
    }),
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    saveEditedImage:(imageId, image) => dispatch(saveEditedImageAc(ownProps, imageId, image)),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT002);
