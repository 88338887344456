import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from "moment";
import {imgStoragePath} from "../../../../../constants";
import { localTimeFn } from '../../../../../utils'
import { TextInputField, GetInputField } from'../../../Elements';
import AddComment from "./AddComment";

export default class IssueDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            comment:'',
        }
    }
    updateComments = (value) => {
        this.setState({
            comment:value,
        });

    }
    addEditContact = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.addComments(this.props.issueDetailInfo.location_slug,this.state.comment)
        this.setState({
            comment:'',
        });
    }
    render(){
        const { issueDetailInfo } = this.props;
        if(!Object.keys(issueDetailInfo).length){
            return null;
        }
        
        return(
            <div>
            <form onSubmit={(e) => this.addEditContact(e)}>
            <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
            <div className="issue-detail-wrapper">
                <Row className="issue-detail-header">
                    <Col md="6">
                        <Link onClick={()=>this.props.toggleSideBars()} className="back-btn"><i className="fa fa-angle-left"></i> BACK</Link>
                    </Col>
                    <Col md="6">
                        { (issueDetailInfo.status === 0) ?
                            <a href="javascript:void(0)" onClick={(e) => this.props.changeStatus(e, issueDetailInfo.location_slug, 1)} className="resolve-issue">RESOLVE ALL</a>:
                            <a href="javascript:void(0)" onClick={(e) => this.props.changeStatus(e, issueDetailInfo.location_slug, 0)} className="re-open">REOPEN</a>
                        }
                    </Col>
                </Row>
                <Row className="reviewer-issue-drawer parent-issue">
                    <Col md="12">
                        <div className="issues-listing">
                            <ul className="list-unstyled">
                                <li>
                                    <Row>
                                      {
                                        issueDetailInfo.status == 1 ?
                                        <Col md="12">
                                          <span className="issued-date" style={{color: '#7ed321'}}>Resolved on  {issueDetailInfo.action_date ? `${localTimeFn(issueDetailInfo.action_date)}` : null} UTC
                                            {
                                              issueDetailInfo.action_performed_by && issueDetailInfo.action_performed_by.name ?
                                              ` by ${issueDetailInfo.action_performed_by.name}`
                                              :''
                                            }
                                          </span>
                                        </Col>
                                        :null
                                      }
                                        <Col md="2" className="text-right">
                                            <span className="issue-order-no" style={issueDetailInfo.status == 1 ? {background: '#7ed321', marginRight: '0'} : { marginRight: '0'}}>
                                              <b>#{issueDetailInfo.order_number ? issueDetailInfo.order_number: null}</b>
                                            </span>
                                        </Col>
                                        <Col md="10">
                                            <h3>{issueDetailInfo.user ? issueDetailInfo.user.name :null}</h3>
                                            <span className="issued-date">Posted on {issueDetailInfo.action_date ? `${moment(issueDetailInfo.action_date).format('MMM DD, YYYY')}` : null}</span>
                                            <p>{issueDetailInfo.description ? issueDetailInfo.description :null}</p>
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="associated-comments reviewer-issue-drawer">
                    <Col md="12">
                        <div className="issues-listing">
                            <ul className="list-unstyled">
                                {issueDetailInfo.issue_comments.map((item, index) =>
                                    <li key={index}>
                                        <Row>
                                            <Col md="12">
                                                <h3>{item.user ? item.user.name : null}</h3>
                                                <span className="issued-date">Posted on  {item.action_date ? `${moment(item.action_date).format('MMM DD, YYYY')}` : null}</span>
                                                <p>{item.message}</p>
                                                { item.status == 0 ?
                                                    <a href="javascript:void(0)" onClick={(e)=>this.props.updateCommentStatus(e,issueDetailInfo.location_slug,item.id)} className="resolve-issue">RESOLVE</a>:
                                                    <span className="resolved-issue">
                                                        <img src={imgStoragePath+'resolved.svg'} alt="" />
                                                    </span>
                                                }
                                            </Col>
                                        </Row>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </Col>
                </Row>
                {
                  issueDetailInfo.status != 1 ?
                  <div style={{position: 'absolute',bottom:'65px',left:'0px',width:'100%',backgroundColor:'#ffffff'}}>
                   <div style={{margin:'5px'}}>
                   {
                    GetInputField(
                      'textarea',
                      null,
                      { type: 'edit',
                        value: this.state.comment,
                        placeholder:'Post Comments',
                        updateForm: (value) => this.updateComments(value)
                      }
                    )
                  }
                   </div>
               </div>
                  :null
                }
             </div>
            </div>
            {
              issueDetailInfo.status != 1 ?
              <div className="edit-sidebar-footer">
               <ul className="list-inline">
                 <li className="list-inline-item">
                   <button
                   type="submit"
                   type="submit"
                   className="btn btn-primary">{'Post'}</button>
                 </li>
                 <li className="list-inline-item">
                   <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
                 </li>
                </ul>
             </div>
              :null
            }
            </form>
            </div>
        )
    }
}
