import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { ContextMenu, Item, Separator, Submenu, ContextMenuProvider } from 'react-contexify';
import { getLocalStorageInfo } from '../../../utils';
import 'react-contexify/dist/ReactContexify.min.css';


class RecordsMiniMenu extends Component{
  constructor(props){
    super(props);
  }

  render(){
    const {item, miniPackFolders} = this.props;
    return(
        <ContextMenu id='bulk_more_actions'>
          { getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1 ?
             <Item onClick={(evt) => this.props.moveRecord()}>Move to</Item>
             :null}
             { [6,7,8,9].indexOf(this.props.assetType) === -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPR') != -1 ?
               <Submenu label="Minipack">
                 {
                   miniPackFolders && miniPackFolders.length ?
                   miniPackFolders.map(folder =>
                     <Item onClick={() => this.props.addToCategoryBulk(3, folder.uuid)}>{folder.name}</Item>
                   ):null
                 }
               </Submenu>
               :null
             }
         {getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 ?
           <Submenu label="Add to Category">
                <Item onClick={() => this.props.addToCategoryBulk(1)}>Marketing Kit</Item>

            <Item onClick={() => this.props.addToCategoryBulk(2)}>Engineering Kit</Item>
           </Submenu>: null}
        </ContextMenu>
    )
  }
}

export default RecordsMiniMenu;
