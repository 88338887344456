import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { browserHistory, Link } from 'react-router'
import { TableUIComp, ListLoader } from '../../../shared';
import { imgStoragePath } from '../../../constants'
import { getLocalStorageInfo } from '../../../utils'
import { sHeaderConstantAc } from '../../../shared/actionCreators';
import { NotificationList } from '../components';
import { NOTIFICATIONS_COUNT_UPDATE } from '../../../shared/actions';
import { acceptRejectRequestAc, getNotificationListAc, markNotificationReadAc, markAllNotificationReadAc } from '../actionCreators'
import { connect } from 'react-redux'
class Notification extends Component{
    constructor(props){
        super(props);
        this.state = {
          requestModal: false,
          welcomeModal: false,
          requestObj: {},
          welcomeText: "",
          welcomeHeading: ''
        }
    }

    componentDidMount(){
      let location = browserHistory.getCurrentLocation();
      this.props.getNotificationList(location.query);
      this.props.updateNotification();
    }

    sortTable = (sortKey) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
          query: {
            ...location.query,
            sort: sortKey,
            page:1,
            per_page: this.props.notificationList.pagination.per_page,
            sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
          }
        }

      browserHistory.push(location);
      this.props.getNotificationList(location.query);
    }

    changePerPage=(count) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
        query: {
          ...location.query,
          'per_page': count,
            'page': 1
        }
      }
      browserHistory.push(location);
      this.props.getNotificationList(location.query);

    }

    changePagination = (type, currentPage) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
        query: {
          ...location.query,
          'page': currentPage
        }
      }
      browserHistory.push(location);
      this.props.getNotificationList(location.query);
    }
    changeFilterFn = (filterParams) => {
      this.props.getNotificationList(filterParams);
    }

    render(){
        const menuList = {
            'Status': {
              'inputType': 'dropdown',
              'keyParam': 'status',
              'placeholder': 'Search by status',
              'label': 'Status',
              'options': [{name: 'Read', id: 1},{name: 'UnRead', id: 0}],
              'labelKey': 'name',
              'valueKey': 'id',
            },
            'Date':{
              'inputType': 'date',
              'keyParam': 'created_at',
              'label': 'Date',
            }
        }

        const { notificationLoader, notificationList, techConstantsHeader } = this.props
        const userInfo = getLocalStorageInfo()
        return(
            <div className="billing-container notification-container">
                <div className="container-fluid billing-content-wrapper">
                    <h1 className="page-title">Notifications</h1>
                    <Row className="notifications-row">
                        <Col md="12">
                            <TableUIComp
                                theads={[{label:'Type', sortKey: ''}, {label:'Subject', sortKey: ''}, {label:'Date', sortKey: ''}, {label:'Status', sortKey: ''}]}
                                bulkOperation={false}
                                tableSpecialOp={
                                  notificationList && notificationList.notifications && notificationList.notifications.length && notificationList.notifications.filter(item => item.status != 1).length ?
                                  <Link style={{float: 'right'}} className="aims-primary-link" onClick={() => this.props.markAllNotificationRead()}>Mark All as Read</Link>
                                  :null
                                }
                                content={notificationList && notificationList.notifications && notificationList.notifications.length ? notificationList.notifications.map((item, index) =>
                                  <NotificationList
                                    markNotificationRead={() => this.props.markNotificationRead(item.notification_id, browserHistory.getCurrentLocation().query)}
                                    key={index}
                                    notification={item}
                                    notificationTypes={techConstantsHeader && techConstantsHeader.length && techConstantsHeader.filter(type => type.type == 'notification_type' && item.notification_type && type.value == item.notification_type.value).length ? techConstantsHeader.filter(type => type.type == 'notification_type' && item.notification_type && type.value == item.notification_type.value)[0] : []}
                                    getRequestModal={() => this.setState({requestModal: true, requestObj: item.request_access})}
                                    getWelcomeNote={() => this.setState({welcomeModal: true, welcomeText: item.description, welcomeHeading: item.notification_type.label})}
                                  />)
                                : null}
                                filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
                                pagination = {notificationList.pagination}
                                sortTable={this.sortTable}
                                changePerPage ={this.changePerPage}
                                changePagination = {this.changePagination}
                                closeFilter = {this.closeFilter}
                                searchQuery = {browserHistory.getCurrentLocation().query}
                                changeFilterFn={this.changeFilterFn}
                                recordsFound = { notificationLoader ? false : notificationList && notificationList.notifications && notificationList.notifications.length ? false:true}
                                queryParamsEnabled={true}
                                tableHeight = {window.innerHeight - 215}
                            />
                        </Col>
                    </Row>
                </div>
                {
                  notificationLoader ?
                    <ListLoader/>
                  :null
                }
                <Modal isOpen={this.state.requestModal} toggle={() => this.setState({requestModal: false})} className="transfer-modal">
                  <ModalHeader toggle={() => this.setState({requestModal: false})}>
                    <ul className="list-inline">
                      <li className="list-inline-item"><img src={`${imgStoragePath}warning.png`}/></li>
                      <li className="list-inline-item"><h2>Request to access {this.state.requestObj.asset_name ? this.state.requestObj.asset_name : ''} </h2></li>
                    </ul>
                  </ModalHeader>
        					<ModalBody>
                    {
                      this.state.requestModal ?
                      <Row className="transfer-modal-body" style={{width: '105%'}}>
                        <Row className="apps-container">
                          <Col md="12">
                            {
                              userInfo.defaultLessor.slug == this.state.requestObj.to_lessor.slug ?
                                <p>{this.state.requestObj.requested_by} has requested access to {this.state.requestObj.asset_name ? this.state.requestObj.asset_name : ''}</p>
                              :<p>You requested to access {this.state.requestObj.asset_name ? this.state.requestObj.asset_name : ''}</p>
                            }
                          </Col>
                        </Row>
                      </Row>
                      :null
                    }
        						<Row className="transfer-modal-footer">
        							<Col md="12">
                        {
                          this.state.requestModal && userInfo.defaultLessor.slug == this.state.requestObj.to_lessor.slug && this.state.requestObj.verification_status && this.state.requestObj.verification_status.value == 0 ?
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <button type="button" onClick={() => {this.setState({requestModal: false});this.props.acceptRejectRequest(this.state.requestObj.id,{verification_status: 1})}} className="aims-primary-button">Approve</button>
                            </li>
                            <li className="list-inline-item">
                              <Link className="aims-primary-link" onClick={() => {this.setState({requestModal: false});this.props.acceptRejectRequest(this.state.requestObj.id,{verification_status: 2})}}>Reject</Link>
                            </li>
                          </ul>
                          :null
                        }
        							</Col>
        						</Row>
        					</ModalBody>
        				</Modal>
                <Modal isOpen={this.state.welcomeModal} toggle={() => this.setState({welcomeModal: false})} className="transfer-modal">
                  <ModalHeader toggle={() => this.setState({welcomeModal: false})}>
                    <ul className="list-inline">
                      <li className="list-inline-item"><h2>{this.state.welcomeHeading}</h2></li>
                    </ul>
                  </ModalHeader>
        					<ModalBody>
                    {
                      this.state.welcomeModal ?
                      <Row className="transfer-modal-body" style={{width: '105%'}}>
                        <Row className="apps-container">
                          <Col md="12">
                            <div dangerouslySetInnerHTML={{__html: this.state.welcomeText}}></div>
                          </Col>
                        </Row>
                      </Row>
                      :null
                    }
        						<Row className="transfer-modal-footer">
        							<Col md="12">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <button type="button" onClick={() => this.setState({welcomeModal: false})} className="aims-primary-button">Ok</button>
                            </li>
                          </ul>
        							</Col>
        						</Row>
        					</ModalBody>
        				</Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) =>({
  notificationList: state.NotificationReducer.notificationList,
  notificationLoader: state.NotificationReducer.notificationLoader,
  techConstantsHeader: state.sharedReducers.techConstantsHeader,
})

const mapDispatchToProps = (dispatch) =>{
  return{
    getNotificationList: (filters) => dispatch(getNotificationListAc(filters)),
    updateNotification: () => dispatch({
      type: NOTIFICATIONS_COUNT_UPDATE,
      payload: {}
    }),
    markNotificationRead: (notificationId, filters) => dispatch(markNotificationReadAc(notificationId, filters)),
    fetchTechConstant : (constantTypes) => dispatch(sHeaderConstantAc(constantTypes)),
    markAllNotificationRead: () => dispatch(markAllNotificationReadAc()),
    acceptRejectRequest: (requestId, status) => dispatch(acceptRejectRequestAc(requestId, status))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification)
