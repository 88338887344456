import React, { Component } from 'react';
import GrpDropdownField from '../containers/GrpDropdownField';
import GrpPlainField from '../containers/GrpPlainField';
import Select from 'react-select';
import { connect } from 'react-redux';
import { UPDATE_SIMULATION_FIELDS } from '../actions'
import { regionOfOpAc } from '../../MRFinanaceRpt/actionCreators'
class EngineGenInfo extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.fetchRegionType()
  }

  updateField = (option) => {
    this.props.updateSimulationField({
      metrixType: this.props.metrixType,
      programType: this.props.programType,
      eventIndex: this.props.eventIndex,
      keyParam: 'region_country',
      value: option.id
    });
  }

  render(){
    const { enginesInfo, newenginesInfo, metrixType, programType, simulationType, regionsList } = this.props;
    let regionCountries = []
    if(regionsList.filter(region => region.id === enginesInfo.region.id).length){
      regionsList.filter(region => region.id === enginesInfo.region.id)[0].country.map(contry => {
        regionCountries.push({id: contry, label: contry})
      })
    }
    return(
      <div className="review-wrapper-block" style={{marginLeft: '15px', marginBottom: '25px'}}>
        <ul className="list-inline flex-centered">
          <li className="list-inline-item margin-bottom-15-xs">
            <span className="label ">Engine Type</span>
            <div><h6 className="para-ui">{enginesInfo.engine_type.name}</h6></div>
          </li>
          <li className="list-inline-item margin-bottom-15-xs">
            <span className="label">Region Of Operation</span>
            <GrpDropdownField
              metrixType={metrixType}
              keyParam="region"
              programType={programType}
              newvalue={simulationType == 2 ? newenginesInfo.region.id:''}
              value={enginesInfo.region.id}
              newdropDownValue={simulationType == 2 ? newenginesInfo.region.name:''}
              dropDownValue={enginesInfo.region.name}
              engine_type={enginesInfo.engine_type.id}
              enginesInfo = { enginesInfo }
              newenginesInfo = { simulationType == 2 ? newenginesInfo:{} }
            />
          </li>
          {
            simulationType == 2 ?
            <li className="list-inline-item margin-bottom-15-xs">
              <span className="label">Country Of Operation</span>
              <div><h6 className="para-ui">{newenginesInfo.region_country}</h6></div>
            </li>
            : simulationType === 0 && enginesInfo.region_country != undefined && enginesInfo.region_country != '' ?
            <li className="list-inline-item margin-bottom-15-xs">
              <span className="label">Country Of Operation</span>
              <div><h6 className="para-ui">{enginesInfo.region_country}</h6></div>
            </li>:
            regionsList.filter(region => region.id === enginesInfo.region.id).length && regionsList.filter(region => region.id === enginesInfo.region.id)[0].country.length ?
            <li className="list-inline-item margin-bottom-15-xs">
              <span className="label">Country Of Operation</span>
              {
                simulationType === 1 ?
                <Select
                  selectedValue= {enginesInfo.region_country}
                  options={regionCountries.length ? regionCountries : []}
                  type="region_country"
                  valueKey = "id"
                  labelKey = "label"
                  placeholder = "Select Country"
                  onChange={this.updateField}
                  value={enginesInfo.region_country}
                />
                :<div><h6 className="para-ui">---</h6></div>
              }
            </li>
            :null
          }
          <li className="list-inline-item margin-bottom-15-xs">
            <span className="label">Monthly Utilization in Hours</span>
            <GrpPlainField
              metrixType={metrixType}
              keyParam="hourly_utilization"
              programType={programType}
              newvalue={simulationType == 2 ? newenginesInfo.hourly_utilization:'' }
              value={enginesInfo.hourly_utilization}
            />
          </li>
          <li className="list-inline-item margin-bottom-15-xs">
            <span className="label">Monthly Utilization in Cycle</span>
            <GrpPlainField
              metrixType={metrixType}
              keyParam="cycle_utilization"
              programType={programType}
              newvalue={simulationType == 2 ? newenginesInfo.cycle_utilization:'' }
              value={enginesInfo.cycle_utilization}
            />
          </li>
          <li className="list-inline-item margin-bottom-15-xs">
            <span className="label">Utilization Ratio</span>
            { simulationType == 2 && newenginesInfo.utilization_ratio != enginesInfo.utilization_ratio ? <h6 className="changed-value" style={{color: '#2a9ef5', fontSize: '16px'}}>{newenginesInfo.utilization_ratio}:1</h6>:null }
            <div><h6 className="para-ui">{enginesInfo.utilization_ratio != 'Infinity' && enginesInfo.utilization_ratio != 'NaN'  ?enginesInfo.utilization_ratio:'--'}:1</h6></div>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  regionsList: state.MRFinanaceRptReducer.regionsList,
});
const mapDispatchToProps = dispatch => {
  return {
    updateSimulationField: (data) => dispatch({
      type: UPDATE_SIMULATION_FIELDS,
      payload: data
    }),
    fetchRegionType: () => dispatch(regionOfOpAc()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EngineGenInfo)
