import React from 'react';
import { Link, browserHistory } from 'react-router';
import { getAccessToken } from '../../utils';
import { imgStoragePath } from '../../constants';
const UserList = (props) => {
    return(
        <tr className={props.type == 'invite'? "tr-no-hover":props.user.is_active ?'':"tr-deactivate"}  onClick={() =>
          props.type == 'invite'? '':props.user.is_active ? browserHistory.push(`/users/manage/active/${props.user.slug}`):''}>
          <td>
          {
          props.type != 'invite' ?
          <img src={props.user.profile_pic != "" && props.user.profile_pic != null ? props.user.profile_pic : imgStoragePath+"default_lessor.png"}/>:null
          }
           {props.user.first_name} {props.user.last_name}</td>
          <td>{props.user.email}</td>
          {
          props.type == 'invite' ?
          <td>{props.user.contact_no && props.user.contact_no != null && props.user.contact_no != undefined ? props.user.contact_no : '--'}</td>:null
          }
          <td>{props.user.designation && props.user.designation != null && props.user.designation != undefined ? props.user.designation : '--'}</td>
          {
          props.type == 'invite' ?
          <td>{props.user.description && props.user.description != null && props.user.description != undefined ? props.user.description : '--'}</td>:
          null
          }

        {
          props.type == 'invite' ?
          <td>
            <Link style={{color: '#fe6465', fontSize: '12px'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.rejectRequest('Reject Invite Request',props.user.slug, 'reject');}}>Reject</Link>
           <Link style={{color: '#72ca2d', fontSize: '12px'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.aproveRequest('Approve Invite Request',props.user.slug, 'approve');}}>Approve</Link>

          </td>:
          <td>
          <Link className="deactivate" style={{color: '#000',fontSize: '14px'}}>{props.user.is_active ? props.user.is_new_user ? 'Invitation Sent': 'Active' :'Deactivated'}</Link>
          {props.superAdmin ? props.user.is_active ? null:
          <Link className="reactivate" style={{color: '#0070ff', fontSize: '14px',marginLeft:'10px'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.activateUser(props.user.slug);}}>Reactivate</Link>:null
          }
          </td>
          /*
          <td>
             <i className="fa fa-edit" onClick={() => browserHistory.push(`/users/manage/${props.user.id}`)}></i>
          </td>*/
        }
        </tr>
    )
}
export default UserList;
