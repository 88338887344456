import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, browserHistory, withRouter } from 'react-router';
export default class ReviewHeader extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    reviewForm = () =>{
        var location = `/aircraft/${this.props.formInfo.asset.slug}/technical/${this.props.workOrderSlug}/forms/${this.props.formInfo.current_form.slug}/${this.props.formInfo.current_form.name.toLowerCase()}`;
        browserHistory.push(location);
      }
    render() {
        const {formInfo} = this.props;
        var projectLocation = `/technical/project/view/${this.props.formInfo && this.props.formInfo.project ? this.props.formInfo.project.slug : ''}`
        return (
            <div className="review-header">
                {/*<div className="techMsn-switcher" id="techMsn-switcher">*/}
                    {/*<div className="flex-not-centered row">*/}
                        {/*<div className="nav-block flex-not-centered col-md-6">*/}
                            {/*<div className="msn-switcher flex-centered">*/}
                                {/*<img className="back-nav-img" src={imgStoragePath+'back_arrow.png'} alt="img" />*/}
                                {/*<span>MSN ...1083</span>*/}
                                {/*<span id="technical-asset-model">B777-300ER</span>*/}
                            {/*</div>*/}
                            {/*<div className="heading-block">*/}
                                {/*<h6 className="para">Technical :*/}
                                    {/*<p id="work-order-name" style={{textDecoration: 'underline', cursor: 'pointer'}}>MSN 41083 Full Appraisal</p>*/}
                                {/*</h6>*/}
                                {/*<Link to={`/technical-specification/aircraft/NrxwXM`}>*/}
                                    {/*<img width="15" src={imgStoragePath+'icon_Aircraft.png'} alt="img" />*/}
                                    {/*Technical Specification*/}
                                {/*</Link>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="detail-block flex-not-centered col-md-6">*/}
                            {/*<ul className="list-inline flex-centered">*/}
                                {/*<li className="list-inline-item">*/}
                                    {/*<h6>Project Lead</h6>*/}
                                    {/*<div className="lead-block">*/}
                                        {/*<div>*/}
                                        {/*<span id="user-info-name" className="flex-centered">*/}
                                            {/*<div>*/}
                                                {/*<img src="https://ams3.digitaloceanspaces.com/sparta-aims/media-dev/users/user1552730045.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20190321%2Fams3%2Fs3%2Faws4_request&amp;X-Amz-Date=20190321T095218Z&amp;X-Amz-Expires=259200&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Signature=00ebcda5e2c278a54f2c51bdc8c37a74d81166a48c3184ac5db710bc162e1ebe" alt="logo" id="img-0" style={{width: '20px', height: '20px', borderRadius: '50%'}} />*/}
                                            {/*</div>*/}
                                            {/*<span style={{marginLeft: '8px', fontSize: '10px'}}>Punith</span>*/}
                                        {/*</span>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</li>*/}
                                {/*<li className="list-inline-item lesse-block">*/}
                                    {/*<h6>Lessee</h6>*/}
                                    {/*<img id="lessee-tip" className="lessee-img" src="https://ams3.digitaloceanspaces.com/sparta-aims/media-dev/lessees/emiretes.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20190321%2Fams3%2Fs3%2Faws4_request&amp;X-Amz-Date=20190321T095218Z&amp;X-Amz-Expires=259200&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Signature=915c67f80f615610e0f8d6dd765b0ff9e15d6664c9ce5bb5b83639354cbb4f85" alt="img" />*/}
                                {/*</li>*/}
                                {/*<li className="list-inline-item">*/}
                                    {/*<h6>Project Start Date</h6>*/}
                                    {/*<p>Jan 01, 2019</p>*/}
                                {/*</li>*/}
                                {/*<li className="list-inline-item">*/}
                                    {/*<h6>Project End Date</h6>*/}
                                    {/*<p> Feb 28, 2019</p>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <div className="form-header" id="form-header">
                    <div className="row">
                        <div className="padding-left-0 flex-centered col-md-4">
                            <div className="select-form-block">
                                <div className="dropdown">
                                    {
                                        formInfo.current_form ?
                                         <h2>{formInfo.current_form.name}</h2>
                                        :null
                                    }
                                    {
                                        formInfo.current_form ?
                                        <p>{formInfo.current_form.title}</p>
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="detail-block flex-not-centered col-md-8">
                            <ul className="list-inline flex-centered">
                                <li className="list-inline-item" style={{width: '120px'}}>
                                    <h6>Project Engineer</h6>
                                    {
                                        formInfo.current_form ?
                                        <p id={'project-'+formInfo.current_form.engineer.id}>{formInfo.current_form.engineer.name}</p>
                                        :null
                                    }
                                </li>
                                <li className="list-inline-item lesse-block">
                                    <h6>Last Activity</h6>
                                    {
                                        formInfo.current_form ?
                                        <p>{`${moment(formInfo.current_form.updated_at).format('MMM DD, YYYY')}`}</p>
                                        :null
                                    }
                                </li>
                                <li className="list-inline-item">
                                    <h6>Form Status</h6>
                                    {
                                        formInfo.current_form ?
                                        <p>{formInfo.current_form.status.label}</p>
                                        :null
                                    }
                                </li>
                                {this.props.notifyFlag?
                                <li style={{width:'100px'}}>
                                    <p className="notify-btn" onClick={()=>this.props.notifyClient()} style={{color:'#3f8dfa',textAlign:"center",fontSize:'12px',padding:'3px'}}>
                                        Notify Client
                                    </p>
                                </li>:null}
                                <li style={{width:'122px'}}>
                                    <p className="review-edit-btn">
                                        <Link to="" className="active">Review</Link>
                                        {
                                          formInfo.current_form && formInfo.current_form.status && formInfo.current_form.status.id != 7 ?
                                            <Link onClick={()=>this.reviewForm()}className="">Edit</Link>
                                          :<Link onClick={()=>browserHistory.push(projectLocation)}className="">View</Link>
                                        }
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=""></div>
                </div>
            </div>

        )
    }
}
