import React from 'react'
import { imgStoragePath } from '../../../../constants';

const AiSupportFolder = (props) =>{
  return(
    <div className="ai-support-folder">
      <img src={imgStoragePath+"folder_icn_blue.png"} width="14" /> {props.folder}
    </div>
  )
}

export default AiSupportFolder
