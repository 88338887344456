import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {  GetInputField } from '../../Elements';
import CKeditor from "../../../../shared/CKEditor";

export default class ECTMdetails  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = (evt) => {
    this.props.updateForm({type: 'ectm_detail', value:evt.editor.getData(), sectionType: 'EngineCurrentDetails', index:this.props.index})
  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row  style={{margin: '0px'}}>
          <Col>
          <div style={{position:'relative',zIndex:'99'}}>
          <CKeditor
            events={{"change": this.updateForm}}
            content={content.ectm_detail?content.ectm_detail:''}
          />
          </div>
          <div>
          <p style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} >Loading...</p>
          </div>
          </Col>
         </Row>
    </div>
    );
  }
}
