import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class YetToBeSentFields extends Component {
  constructor(props) {
    super(props);
   
  }

  updateForm = (type, value) => {
    this.props.updateDetailData(type, value);

  }
 
  render() {
    const {type, yetToBeSend} = this.props;
    return (
      <Row>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Which shop has been selected?</FieldLabel>
          <FieldInput
            value={yetToBeSend.selected_shop}
            type={type}            
            keyParam="selected_shop"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Expected date of shipment to shop</FieldLabel>
            <FieldDate
                  value={yetToBeSend.expected_date_of_shipment_to_shop}
                  type={type}
                  keyParam="expected_date_of_shipment_to_shop"
                  updateField={this.updateForm}
                />
        </FieldGroup>
      </FieldCol>

      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Work Scope Status</FieldLabel>
          <FieldSelect
                  placeholder='Select Work Scope Status'
                  keyParam="work_scope_status"
                  value={yetToBeSend.work_scope_status}
                  type={type}
                  options={[{ value: 1, label: 'Approved' },{ value: 2, label: 'Rejected'},{ value: 3, label: 'Review in Progress'}, { value: 4, label:'submitted for review'}]}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name=""
                />
        </FieldGroup>
      </FieldCol>
      
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Expected Turn Around Time</FieldLabel>
          <FieldInput
            value={yetToBeSend.expected_turn_around_time}
            type={type}
            keyParam="expected_turn_around_time"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Remarks</FieldLabel>
          <FieldInput
            value={yetToBeSend.remarks}
            type={type}
            keyParam="remarks"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>
      
      </Row>
    )
  }
}
