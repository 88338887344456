import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, browserHistory } from 'react-router';
import { getSharedPreviewAc, downloadSharedViewAc } from './shared/actionCreators';
import { CLEAR_SHARED_PREVIEW } from './shared/actions';
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getFileSize, localTimeFn, toastFlashMessage } from './utils';
import './applications/records/assets/styles/records_a.scss';
import ToastNotify  from './shared/containers/ToastNotify';
import { mediaBaseUrl, imgStoragePath, fileIcons, displayDateTimeFormatShort, emailRegx } from './constants';
import { FileNavigation } from './applications/records/Elements';
import { NoRecordFound } from './shared';
import { ListLoader } from './shared';
import { globalPostService } from './globalServices';
import { sGlobalConstantAc } from './shared/actionCreators'
import Select from 'react-select'
import 'react-select/dist/react-select.css';
import moment from 'moment'
import axios from 'axios'

class FilePreview extends Component{
  constructor(props) {
    super(props);
    this.state = {
      hellobar: true,
      shareModal: false,
      loader: false,
      email: '',
      activeSort: {
        value: 'asc',
        label: '',
        sortKey: ''
      },
    }
    this.getFiles = this.getFiles.bind(this);
  }
  componentDidMount(){
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    if(location.query.type && location.query.type == 'msn'){
      this.props.getSharedPreview(this.props.uuid, 'msn','','initial');
    }else {
      this.props.getSharedPreview(this.props.key, 'share','','inital');
    }
    this.props.fetchTechConstant({constant_types: ['file_extension']})
    globalPostService('/records/share/activity/', {
         "user_id": null,
         "share_link": true,
         "activity_type": 30,
         "details": this.props.params.key
     })
    .then(response => {})
  }

  downloadFolder = (folder) =>{
    this.props.downloadFolder(this.props.sharedView.asset, folder);
  }

  downloadUsingEmail = () =>{
    this.setState({loader: true})
    axios({
      method: 'POST',
      url: mediaBaseUrl + '/records/v2/share-record/folder/' +this.props.params.key +'/zip-download-user/',
      data: {
        user_email: [this.state.email]
      }
    })
    .then(response => {
      this.setState({loader: false})
      if(response.data.statusCode === 200){
        this.setState({email: '', shareModal: false})
        toastFlashMessage(response.data.message, 'success');
      }else{
        toastFlashMessage(response.data.message, 'error');
      }
    })
    .catch(error => {
      this.setState({loader: false})
    })
  }

  previewRecord = (item) =>{
    var url = "";
    if(item.extension == 'pdf'){
      url = `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${item.path}`;
      window.open(url, '_blank').focus();
    }else if(['xls','docx','doc','xlsx','csv'].includes(item.extension)){
      url = `https://view.officeapps.live.com/op/embed.aspx?src=${item.path}`;
      window.open(url, '_blank').focus();
    }else {
      url = decodeURIComponent(item.path);
      url = url.replace('https://', '')
      const link = document.createElement('a');
      link.href = 'https://' + url;
      link.target='_blank';
      document.body.appendChild(link);
      link.click();
    }
    globalPostService('/records/share/activity/', {
         "user_id": null,
         "share_link": true,
         "activity_type": 31,
         "details": item.share_key
     })
    .then(response => {})
  }
  getFiles(type,key){
    if(type == 'msn'){
      this.props.getSharedPreviewInner(key, 'msn', this.props.sharedView.navigation[0].uuid,'initial');
    }else{
      this.props.getSharedPreviewInner(key, 'nav', this.props.sharedView.navigation[0].uuid,'nav');
    }
  }


  sortDataRoom = (sort) =>{
    this.setState({
      activeSort: sort
    })
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    if(location.query.type && location.query.type == 'msn'){
      this.props.getSharedPreview(this.props.uuid, 'msn','','initial', {sort_by: sort.value, sort: sort.sortKey});
    }else{
      this.props.getSharedPreview(this.props.uuid, 'share','','initial', {sort_by: sort.value, sort: sort.sortKey});
    }
  }

  render(){
    const { sharedView, techConstants, filepreviewLoader } = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    const sortOptions = [{value: 'asc', label: 'A to Z', sortKey: 'name'}, {value: 'desc', label: 'Z to A', sortKey: 'name'}]
    return(
      <div className="file-preview-block">
        {
          this.state.hellobar ?
          <div className="shared-hellobar">
            Welcome! Click <Link style={{fontWeight: '600', color: '#ffffff', textDecoration: 'underline'}} to="/signup" target="_blank">here</Link> to create your SPARTA account, or continue below to access your files.
            <span onClick={() => this.setState({hellobar: false})}>x</span>
          </div>
          :null
        }
        <header id="header" style={this.state.hellobar ? {marginTop: '25px', padding: '3.67px 3px'} : {padding: '3.67px 3px'}}>
          <Row className="flex-centered">
            <Col sm="2">
              <ul className="list-inline header-logo-search">
                <li className="list-inline-item">
                  <Link to="/">
                    <img width="32" className="main-logo" src={imgStoragePath+"sparta_logo.png"} alt="logo" />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md="8">

            </Col>
            <Col md="2" style={{textAlign: 'right'}}>
              {
                sharedView.asset ?
                <Link style={{cursor:'default'}}><img style={{height: '32px', marginRight: '5px'}} src={sharedView.asset.lessor_logo} /></Link>:null
              }

            </Col>
          </Row>
        </header>
        {
          sharedView && sharedView.list && sharedView.list.length && sharedView.list[0].valid_till != undefined && sharedView.list[0].valid_till != null ?
          <div className="shared-view-valid" style={this.state.hellobar ? {top: sharedView.navigation.length ? '120px' : '95px'} : {top: sharedView.navigation.length ? '' : '70px'}}>
            This link is valid upto {moment.utc(sharedView.list[0].valid_till).format(displayDateTimeFormatShort)} (UTC)
          </div>
          :null
        }
        <div style={ this.state.hellobar ? {marginTop: '170px'} : sharedView.navigation && sharedView.navigation.length ? {marginTop: '140px'} : {marginTop: '110px'}}>

              <div className="file-navigation" style={this.state.hellobar ? {top: '65px'} : {}}>
                <Row>
                  <Col md="11">
                    { sharedView.asset && location.query.type !='msn' ?
                      <h3 style={{color:'#bd10e0', fontSize:'18px'}}>{ sharedView.asset.msn}</h3>
                      : null
                    }
                    { sharedView.navigation ?
                      sharedView.navigation.length ?
                      sharedView.navigation.map( (item, index) =>  item.uuid ?
                        <div key={item.uuid} className='navigation-item'>
                          <Link onClick={() => this.getFiles(item.share_key ? 'share' : 'msn',item.share_key ? item.share_key : item.uuid) }>
                            { index != 0 ?
                              <span className="file-path">{item.name} /</span>:
                              <span className="file-path">{'.. /' +item.name+ '/'}</span>
                            }
                          </Link>
                        </div>: null): null
                        :<div className='navigation-item'><span onClick= {() => this.getFiles('share', sharedView.asset.uuid) }  className="file-path">... /</span> </div>
                    }
                  </Col>
                  <Col md="1">
                    { sharedView && sharedView.list && sharedView.list.length && sharedView.list.length > 1 && sharedView.navigation && sharedView.navigation.length ?
                      <h6 className="para-ui" style={{marginTop: '5px'}}>
                        <button className="btn btn-primary" onClick={() => this.setState({shareModal: true})}>Download</button>
                      </h6>
                      :null
                    }
                  </Col>
                </Row>
              </div>
              <Row style={{display: 'none'}}>
                <Col md="9"></Col>
                <Col md="1">
                <span style={{marginTop: '20px', display: 'block'}}>Sort By :
                </span>
                </Col>
                <Col md="2">
                <Select
                  value={this.state.activeSort.value}
                  options={sortOptions}
                  className="custom-select-block"
                  onChange={(option) => this.sortDataRoom(option)}
                  labelKey= 'label'
                  valueKey= 'value'
                  clearable={false}
                  style={{marginTop: '15px'}}
                  />
                </Col>
              </Row>
          {sharedView && sharedView.list && sharedView.list.length ?
            sharedView.list.map((item) =>
            <div key={item.uuid} className="asset-list" style={{marginRight: '20px'}}>
              <Row className="flex-centered">
                <Col md="10">
                  { item.type == "file" ?
                    <div className="flex-centered msn-block record-preview" onClick={() => this.previewRecord(item)}>
                      <div>
                        <img src={fileIcons[item.extension]} alt="img" width="20"/>
                      </div>
                      <Link>
                        <span style={{fontSize: '14px'}}>{item.name}</span>
                      </Link>
                    </div>
                    :
                    <div onClick={() => this.getFiles('share',item.share_key )} className="flex-centered msn-block record-preview">
                      <div><img src={imgStoragePath+"folder_icn3.png"} alt="img" width="20"/></div>
                      <Link>
                        <span style={{fontSize: '14px'}}>{item.name}</span>
                      </Link>
                    </div>
                  }
                </Col>
                { item.type == 'folder' ?
                  item.published_size !=null && item.published_size != 0 ?
                  <Col md="2">
                    <h6 className="para-ui">{getFileSize(item.published_size)}
                      <Link onClick={() => this.getFiles('share',item.share_key )} className="download-file"><img width='14' style={{float: 'right'}} src={imgStoragePath+'eye_color.png'}/></Link>
                    </h6>
                  </Col>:null
                  :
                  item.size != null && item.size != 0 ?
                  <Col md="2">
                    <h6 className="para-ui" onClick={() => globalPostService('/records/share/activity/', {
                         "user_id": null,
                         "share_link": true,
                         "activity_type": 33,
                         "details": this.props.params.key
                     })
                    .then(response => {})}>{getFileSize(item.size)} <Link to={decodeURIComponent(item.path)} target='_blank' download={item.name} className="download-file"><img width='14' style={{float: 'right'}} src={imgStoragePath+'eye_color.png'}/></Link></h6>
                  </Col>:null
                }
              </Row>
            </div>
          ):null}
          { !filepreviewLoader && sharedView.list ?
            !(sharedView.list && sharedView.list.length) ? <NoRecordFound/> : null :   <ListLoader  drawer="primary-nav"/>
          }
          {
            this.state.loader ?
              <ListLoader  drawer="primary-nav"/>
            :null
          }
          {
            this.props.downloadLoader ?
              <div className="list-loader" style={{position: 'fixed', left: '53%', top: '60%', transform: 'translate(-60%, -53%)', zIndex:10000, background: 'rgba(255,255,255,0.8)', boxShadow: '0 1px 1px rgba(0,0,0,0.2)', padding: '15px' }} ><img style={{width: '100px'}} src={imgStoragePath+"loader_v2.gif"} alt="icn" /><p className="text-center">Downloading...</p></div>
            :null
          }
        </div>
        <Modal isOpen={this.state.shareModal} size="sm" toggle={() => this.setState({shareModal: false, email: ''})}>
          <ModalHeader toggle={() => this.setState({shareModal: false, email: ''})}>Download {sharedView.asset ? sharedView.asset.msn : ''}</ModalHeader>
          <ModalBody>
            <span style={{fontSize: '14px', marginBottom: '10px'}} className="warning-info-text">Generating download is background process which might take time, please enter your email. We will send download link to your email once process is completed.</span>
            <label>Email</label>
            <input type="text" value={this.state.email} placeholder="johndoe@example.com" onChange={(evt) => this.setState({email: evt.target.value})}/>
            {emailRegx.test(this.state.email)}
            <h6 className="error-msg">{this.state.email.length > 0 && !emailRegx.test(this.state.email) ? 'Please enter proper email' : ''}</h6>
          </ModalBody>
          <ModalFooter>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.state.email.trim().length == 0 || !emailRegx.test(this.state.email)} className="btn btn-primary add-new btn-sm" onClick={() => this.downloadUsingEmail()}>Download</button>
              </li>
              <li className="list-inline-item">
                <button className="btn btn-secondary add-new btn-sm" onClick={() => this.setState({shareModal: false, email: ''})}>Cancel</button>
              </li>
            </ul>
          </ModalFooter>
        </Modal>
        <ToastNotify />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  sharedView: state.sharedReducers.sharedView,
  filepreviewLoader: state.sharedReducers.filepreviewLoader,
  downloadLoader: state.sharedReducers.downloadLoader,
  techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSharedPreview: (key, type, parent, level, filters) => dispatch(getSharedPreviewAc({key:ownProps.params.key, parent: parent, type: type, level: level}, filters)),
    getSharedPreviewInner: (key, type, parent, level) => dispatch(getSharedPreviewAc({key:key, parent: parent, type: type, level: level})),
    downloadFolder:(data, item) => dispatch(downloadSharedViewAc(data, item)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilePreview))
