import React, { Component } from 'react';
export default class FilterLabel extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <li {...this.props} onClick={this.props.selectFilter}>
        {this.props.children}
      </li>
    )
  }
}
