import React, { Component } from 'react';
import { FieldSelect, FieldInput, FieldDate } from '../../../../contracts/Elements';
import { imgStoragePath } from '../../../../../constants';
import {  TextInputField } from '../../../Elements';
 class DataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error:{},
    }
  }


  render (){
    const {error} = this.state;
    const {content} = this.props;
    return(
    <tr>
      <td>
      <div >
        <TextInputField
              value={this.props.section=='missing_hardware'? content.ata:content.item}
              type={'edit'}
              keyParam= {this.props.section=='missing_hardware'?"ata":"item"}
              error={this.props.section=='missing_hardware'? error.ata:error.item}
              updateForm={(value)=>this.props.updateForm({type:this.props.section=='missing_hardware'?'ata':'item', value:value, sectionType: this.props.section, index:this.props.index})}
        />
        </div>
      </td>
      <td>
        <div >
        <TextInputField
              value={content.description}
              type={'edit'}
              keyParam="description"
              error={error.description}
              updateForm={(value)=>this.props.updateForm({type: 'description', value:value, sectionType: this.props.section, index:this.props.index})}
        />
        </div>
      </td>
      <td>
      <div >
       <TextInputField
          value={content.part_number}
          type={'edit'}
          keyParam="part_number"
          updateForm={(value)=>this.props.updateForm({type: 'part_number', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
       </div>                   
      </td>
      <td>
      <div >
       <TextInputField
          value={content.serial_number}
          type={'edit'}
          keyParam="serial_number"
          updateForm={(value)=>this.props.updateForm({type: 'serial_number', value:value, sectionType: this.props.section, index:this.props.index})}
       />  
        </div>
      </td>
      <td>
      <div >
       <TextInputField
          value={content.remarks}
          type={'edit'}
          keyParam="remarks"
          updateForm={(value)=>this.props.updateForm({type: 'remarks', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
        </div>      
      </td>
      <td>
      <div style={{textAlign:'center', cursor:'pointer'}}>
      <img width="15" onClick={()=>''} src={imgStoragePath+"delete_red.png"} onClick={this.props.toggleComDelModal} />
      </div>
      </td>
    </tr>
    )
}
}
export default DataList;
