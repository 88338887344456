import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldTextarea} from '../../Elements';
import { checkString, checkIntNumber, checkEmail } from '../../../../formValidator';
export default class AddWorkingGrpTeam extends Component {
  constructor(props){
    super(props);
    this.state={
      workingGrpTeam:{},
      error: {}
    }
  }
  componentDidMount(){
    this.setState({
      workingGrpTeam: this.props.workingGrpTeamObj
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      workingGrpTeam: {
        ...prevState.workingGrpTeam,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditWorkingGrp = (e) => {
    e.preventDefault();
    const validateNewInput = {
      name: checkString({value: this.state.workingGrpTeam.name, required: true, minLength: '', maxLength: '', message: 'Please Enter The Working Group Name'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.addEditWorkingGrp(this.state.workingGrpTeam, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(props){
    const { type } = this.props;
    const { workingGrpTeam, error } = this.state;
    if(!Object.keys(workingGrpTeam).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditWorkingGrp(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Name <sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={workingGrpTeam.name}
                  type={type}
                  keyParam="name"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.name}</h6>
              </FieldGroup>
            </FieldCol>
          </Row>
          {
            type != 'view' ?
            <div className="submit-block">
              <input type="submit" className="primary-btn" value="Save Changes"/>
              <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
            </div>:null
          }
        </div>
     </form>
    )
  }
}
