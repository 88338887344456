import React, {Component} from 'react';
import { Link } from 'react-router';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col,Row} from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
export default class LogAddEnquiry extends Component{
    constructor(){
        super();
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false
        };
    }
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
      }
    render(){
        return(
            <Row>
                <Col md="5">
                <h1 className="block-title">Logs</h1>
                <p className="block-subs">Contains logs of previous Finance Reports that were created by the user</p>
                </Col>
                <Col md="3">
                <div className="download-links" style={{position:"relative",top:"-11px"}}>
                <span style={{float:'right',marginTop:'20px'}}>
                <a href="https://itunes.apple.com/za/app/empower/id1209983674" target="_blank" style={{display:'inline',padding:'0px',border:'none'}} >
                <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
                <a href="https://play.google.com/store/apps/details?id=com.emis.acumen"  target="_blank" style={{display:'inline',padding:'0px',border:'none'}}>
                <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a>
                </span>
                <p style={{fontSize:'13px',fontWeight:'300',float:"right", position:"relative", left:"-95px", top:"-65px"}}>Access EMPower App on</p>
                </div>
                </Col>
                <Col md="4">
                <div className="add-enquiry-block download-block" style={{position:"relative", top:"40px"}}>
                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret size="sm">
                        <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.props.downLoadLogListing('pdf')}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                            <DropdownItem onClick={() => this.props.downLoadLogListing('xls')} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                    <Link className="primary-btn" to="/mr-calculator">+ New Finance Report</Link>
                </div>
                </Col>               
            </Row>

        )
    }
}
