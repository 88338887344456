import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addAfClaim, addEngineAssembly } from '../../mntAddObject'
import { browserHistory } from 'react-router'
export const engineUpcomingEventsAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    globalGetService('maintenance/asset-type/engine/asset/'+params.aircraft_slug+'/new-upcoming-events/', {})
    .then(response => {
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_EVENT_LIST,
          payload: response.data.data.upcoming_events
        });
      }
    });
  }
}

export const engineEventHistoryAc = (params, queryParam={}) => {
  return (dispatch) => {
    globalGetService('maintenance/asset-type/engine/asset/'+params.aircraft_slug+'/shopvisit/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_EVENT_HISTORY,
          payload: response.data.data
        });
      }
    });
  }
}

export const addEditEngineShopVisitAc = (params, data, type, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    if(type == 'add'){
      globalPostService('maintenance/asset-type/engine/asset/'+params.aircraft_slug+'/shopvisit/',data)
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_SHOP_VIST_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.ENGINE_SHOP_VISIT_CRUD,
            payload: {type: '', data: {} , flag: false }
          });
          browserHistory.push(`/aircraft/${params.aircraft_slug}/maintenance/engine/view/${response.data.data.id}`)
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Major Assemblies Engine`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Shop Visit`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('maintenance/asset-type/engine/asset/'+params.aircraft_slug+'/shopvisit/' + data.id + '/',data)
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_SHOP_VISIT_CRUD,
            payload: {type: '', data: response.data.data , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Major Assemblies Engine`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Shop Visit`,
            item_id: data.id
          })
        }
      });
    }
  }
}

export const engineShopVisitCrudAc = (params, type, id) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.ENGINE_SHOP_VISIT_CRUD,
        payload: {type: type, data: addEngineAssembly , flag: true }
      });
    }else{
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: true
      });
      globalGetService('maintenance/asset-type/engine/asset/'+params.aircraft_slug+'/shopvisit/' + params.id + '/',{})
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_SHOP_VISIT_CRUD,
            payload:{type: '', data:response.data.data , flag: false }
          });
          dispatch({
            type: actions.GET_ENGINE_WORKSCOPES,
            payload:response.data.data.workscope_modules
          });
        }
      })
    }
  }
}

export const engineSVStatusAc = (params, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    globalPostService('maintenance/shopvisit/'+params.id+'/update-status/', data)
    .then(response => {
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_SHOP_VISIT_CRUD,
          payload: {type: '', data: response.data.data , flag: false }
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}

export const engineClaimsListAc = (params, queryParam={}) => {
  return (dispatch) => {
    globalGetService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_CLAIM_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const engineClaimInfoCrudAc = (params, type, id) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.ENGINE_CLAIM_CRUD,
        payload: {type: type, data:addAfClaim , flag: true }
      });
    }else{
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/induction-condition-items/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_CLAIM_CRUD,
            payload: {type: type, data:response.data.data , flag: true }
          });
        }
      })
    }
  }
}

export const addEditEngineClaimAc = (params, data, type, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    if(type == 'add'){
      let claim = data;
      claim = {
        ...data,
        shop_visit_id: params.id
      }
      globalPostService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/',claim)
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_CLAIM_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.ENGINE_CLAIM_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
        }
      })
    }else{
      globalPutService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/' + data.id + '/', data)
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_CLAIM_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.ENGINE_CLAIM_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          dispatch({
            type: actions.ENGINE_PAYOUT_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
        }
      })
    }
  }
}

export const engineClaimStatusAc = (params, id, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    globalPostService('maintenance/claim/'+id+'/update-status/', data)
    .then(response => {
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_CLAIM_EDIT_SUCCESS,
          payload: response.data.data
        })
      }
    })
  }
}

export const engineLLPListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    globalGetService('maintenance/shopvisit/'+params.id+'/llps/' ,{})
    .then(response => {
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_LLP_LIST,
          payload: response.data.data
        });
      }
    });
  }
}

export const engineLLPInfoAc = (params, engineId, flag) => {
  return (dispatch) => {
    if(flag == false){
      dispatch({
        type: actions.LLP_INFO,
        payload: {type: '', data: [] , flag: flag }
      });
    }
    globalGetService('console/engine/'+engineId+'/engine-llp/?shop_visit_id='+params.id ,{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LLP_INFO,
          payload: {type: '', data: response.data.data , flag: flag }
        });
      }
    });
  }
}
export const engineAddLLPAc = (params, data, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.ENGINE_LOADER,
      payload: true
    });
    globalPostService('maintenance/shopvisit/'+params.id+'/llps/' ,data)
    .then(response => {
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_LLP_ADD_SUCCESS,
          payload: response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.LLP_INFO,
          payload: {type: '', data: [] , flag: false }
        });
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        trackActivity('Item Added', {
          page_title: `Major Assemblies Engine`,
          application_module: `Maintenance`,
          asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
          item_type: `LLP`,
          item_id: response.data.data.id
        })
      }
    });
  }
}

export const editLLPAc = (params, data, type, assetInfoObj) => {
  return(dispatch) => {
    globalPutService('maintenance/shopvisit/'+params.id+'/llps/'+data.id+'/', data)
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_LLP_EDIT_SUCCESS,
            payload: response.data.data
          });
          toastFlashMessage(response.data.message, 'success');
          dispatch({
            type: actions.ENGINE_LLP_ASSY_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Major Assemblies Engine`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            item_type: `LLP`,
            item_id: data.id
          })
        }
      })
  }
}

export const engineLlpCrudFnAc = (params, llpId, type) => {
  return(dispatch) => {
      dispatch({
        type: actions.ENGINE_LOADER,
        payload: true
      });

      globalGetService('maintenance/shopvisit/'+params.id+'/llps/'+llpId+'/'  ,{})
      .then(response => {
        dispatch({
          type: actions.ENGINE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_LLP_ASSY_CRUD,
            payload: { data: response.data.data, flag: true, type:type }
          });

        }
      })
  }
}

export const delLLpAc = (params, llpId, assetInfoObj) => {
  return(dispatch) => {
    globalDeleteService('maintenance/shopvisit/'+params.id+'/llps/'+llpId+'/'  ,{})
    .then(response => {
      dispatch({
        type: actions.DELETE_ENGINE_LLP,
        payload: llpId
      });
      toastFlashMessage(response.data.message, 'success');
      let assetTypeNames = {
        3: 'APU',
        4: 'LG',
        5: 'PROPELLER'
      }
      trackActivity('Item Deleted', {
        page_title: `Major Assemblies Engine`,
        application_module: `Maintenance`,
        asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
        item_type: `LLP`,
        item_id: llpId
      })
    })
  }
}
