import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
import { checkString, checkNumber } from '../../../../formValidator';
import moment from 'moment';
import OffWingServiceable from './OffWingServiceable';
import InShopUnServiceable from './InShopUnServiceable';
import InShopServiceble from './InShopServiceble';
import YetToBeSentFields from './YetToBeSentFields';
import OffWingUnserviceble from './OffWingUnserviceble';
import AddEditMjrAssyfields from './AddEditMjrAssyfields';
import { getLocalStorageInfo } from '../../../../utils';

export default class AddEditAPU extends Component {
  constructor(props){
    super(props);
    this.state = {
      apuSummery: {},
      error: {},
      off_wing_status : false,
      on_wing_status : false,
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.apuSummeryObj));
    if(this.props.type == 'edit'){
      delete data['status'];
      delete data['off_wing_status'];
      delete data['lessor'];
      delete data['lessor_name'];
      delete data['owner']
      data = {
        ...data,
        aircraft: this.props.apuSummeryObj.aircraft.id,
        aircraft_name : this.props.apuSummeryObj.aircraft.msn,
        status:  this.props.apuSummeryObj.status && this.props.apuSummeryObj.status.value ? this.props.apuSummeryObj.status.value : null,
        state: this.props.apuSummeryObj.state && this.props.apuSummeryObj.state.value ? this.props.apuSummeryObj.state.value : null,
        off_wing_status:  this.props.apuSummeryObj.off_wing_status &&  this.props.apuSummeryObj.off_wing_status.value ? this.props.apuSummeryObj.off_wing_status.value : '',
        on_wing_lessor : (this.props.apuSummeryObj && this.props.apuSummeryObj.on_wing_lessor) ? this.props.apuSummeryObj.on_wing_lessor : (this.props.overview.lessor && this.props.overview.lessor.name ) ? this.props.overview.lessor.name : null,
        on_wing_msn : (this.props.apuSummeryObj && this.props.apuSummeryObj.on_wing_msn) ? this.props.apuSummeryObj.on_wing_msn : this.props.overview.msn ,
        owner_id:this.props.apuSummeryObj.owner && this.props.apuSummeryObj.owner.id ? this.props.apuSummeryObj.owner.id : getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : '',
        owner_level: this.props.apuSummeryObj.owner && this.props.apuSummeryObj.owner.lessor_level? this.props.apuSummeryObj.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
      }
    }else{
      data = {
        ...data,
        on_wing_lessor : (this.props.overview.lessor && this.props.overview.lessor.name ) ? this.props.overview.lessor.name : null,
        on_wing_msn :  this.props.overview.msn ,
      }
    }
    this.setState({
      apuSummery:data
    });
    if(data.on_wing_status == true){
      this.setState({
        on_wing_status: true
      })
    }
    if(data.off_wing_status){
      this.setState({
        off_wing_status: true
      })
    }

  }
  updateForm = (type, value) => {
    if(type == "lessor_name_id"){
      this.setState(prevState => ({
        ...prevState,
        apuSummery:{
          ...prevState.apuSummery,
          lessor_name_id:value,
          lessor_name:value
        }
      }))
    }
    if(type == "owner_id"){
      this.setState(prevState => ({
        ...prevState,
        apuSummery:{
          ...prevState.apuSummery,
          owner_id:value,
        }
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      apuSummery: {
        ...prevState.apuSummery,
        [type]: value,
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))

    if( type == 'on_wing_status' && !value) {
      this.setState(prevState => ({
        ...prevState,
        apuSummery: {
          ...prevState.apuSummery,
          on_wing_msn: '',
          on_wing_lessor: '',

        }
      }))
    }else if(type == 'on_wing_status' && value) {
      this.setState(prevState => ({
        ...prevState,
        apuSummery: {
          ...prevState.apuSummery,
          off_wing_status: {},
          on_wing_msn: this.state.apuSummery.on_wing_msn,
          on_wing_lessor: this.state.apuSummery.on_wing_lessor,

        },
        off_wing_status : false

      }))
    }else if(type == 'off_wing_status'){
      this.setState(prevState => ({
        ...prevState,
        apuSummery: {
          ...prevState.apuSummery,

        },
        off_wing_status : false
      }))
    }
    if(value){
      this.setState({
        [type]: true
      })
    }else {
      this.setState({
        [type]: false
      })

    }
  }

  updateDetailData = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      apuSummery: {
        ...prevState.apuSummery,
        off_wing_detail: {
          ...prevState.apuSummery.off_wing_detail,
            [type]: value
          }
      }
    }));
  }
  addEditApuSummery = (e) => {
    e.preventDefault();
    let validateNewInput = {
      part_number: checkString({value: this.state.apuSummery.part_number, required: true, minLength: '', maxLength: '', message: 'Please enter Part Number'}),
      serial_number: checkString({value: this.state.apuSummery.serial_number, required: true, minLength: '', maxLength: '', message: 'Please enter Serial Number'}),
      tsn: checkNumber({value: this.state.apuSummery.tsn, required: '', minLength: '', maxLength: '', message: 'Please enter TSN in numbers'}),
      csn: checkNumber({value: this.state.apuSummery.csn, required: '', minLength: '', maxLength: '', message: 'Please enter CSN in numbers'}),
      average_monthly_hours: checkNumber({value: this.state.apuSummery.average_monthly_hours, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly hours in numbers'}),
      average_monthly_cycles: checkNumber({value: this.state.apuSummery.average_monthly_cycles, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly cycles in numbers'}),
      tslsv: checkNumber({value: this.state.apuSummery.tslsv, required: '', minLength: '', maxLength: '', message: 'Please enter TSLSV in numbers'}),
      cslsv: checkNumber({value: this.state.apuSummery.cslsv, required: '', minLength: '', maxLength: '', message: 'Please enter CSLSV in numbers'})
    };

    if(this.state.apuSummery.is_titled || this.state.apuSummery.is_fitted){
      validateNewInput = {
        ...validateNewInput,
        titled_fitted: '',
      }
    }else{
      validateNewInput = {
        ...validateNewInput,
        titled_fitted: 'Please Specify APU is Titled or Fitted',
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.addEditTechSummary(this.state.apuSummery, this.props.type, 'apuList','apuCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }


  render() {
    const { apuSummery, error } = this.state;
    const { manufacturerList, type, techConstants, showAdvanced, ownerList } = this.props;
    if(!Object.keys(apuSummery).length){
      return <h4 className="text-center">Loading ...</h4>
    }
    return (
      <form onSubmit={(e) => this.addEditApuSummery(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
        <Row>

          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                Manufacturer
              </FieldLabel>
              <FieldInput
                value={apuSummery.manufacturer}
                type={type}
                keyParam="manufacturer"
                updateField={this.updateForm}
              />

            </FieldGroup>
          </FieldCol>

          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                Serial Number
                { type != 'view' ? <sup className="reqStar"> * </sup>:null}
              </FieldLabel>
              <FieldInput
                value={apuSummery.serial_number}
                type={type}
                keyParam="serial_number"
                updateField={this.updateForm}
              />
            <h6 className="error-msg">{error.serial_number}</h6>
            </FieldGroup>
          </FieldCol>

          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Part Number
              { type != 'view' ? <sup className="reqStar"> * </sup>:null}
              </FieldLabel>
              <FieldInput
                value={apuSummery.part_number}
                type={type}
                keyParam="part_number"
                updateField={this.updateForm}
              />
            <h6 className="error-msg">{error.part_number}</h6>
            </FieldGroup>
          </FieldCol>

          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Date of Manufacture</FieldLabel>
              <FieldDate
                value={apuSummery.date_of_manufacture}
                type={type}
                keyParam="date_of_manufacture"
                updateField={this.updateForm}
                readOnly={true}
                maxDate={moment()}
              />
            </FieldGroup>
          </FieldCol>
          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">APU Type</FieldLabel>
              <FieldSelect
                placeholder='Select APU Type'
                keyParam="apu_type"
                value={apuSummery.apu_type}
                type={type}
                options={this.props.apuTypesList}
                updateField={this.updateForm}
                labelKey= 'name'
                valueKey='id'
              />
            </FieldGroup>
          </FieldCol>
        </Row>
        <div className="tech-spec-utilization-edit-block">
          <h6 style={{marginTop:'0'}}>APU Utilization Details</h6>

          <Row>
            <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                Utilization as of date
              </FieldLabel>
              <FieldDate
                keyParam="last_utilization_updated"
                value={apuSummery.last_utilization_updated}
                type={type}
                updateField={this.updateForm}
                maxDate ={ moment()}
                readOnly={true}
              />
            </FieldGroup>
          </FieldCol>
          </Row>

          <Row>
            <FieldCol md="3">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                TSN
              </FieldLabel>
              <FieldInput
                value={apuSummery.tsn}
                type={type}
                keyParam="tsn"
                updateField={this.updateForm}
              />
              <h6 className="error-msg">{error.tsn}</h6>
            </FieldGroup>
          </FieldCol>

          <FieldCol md="3">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                CSN
              </FieldLabel>
              <FieldInput
                value={apuSummery.csn}
                type={type}
                keyParam="csn"
                updateField={this.updateForm}
              />
              <h6 className="error-msg">{error.csn}</h6>
            </FieldGroup>
          </FieldCol>

          <FieldCol md="3">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
              Avg. Monthly Hours
              </FieldLabel>
              <FieldInput
                value={apuSummery.average_monthly_hours}
                type={type}
                keyParam="average_monthly_hours"
                updateField={this.updateForm}
              />
              <h6 className="error-msg">{error.average_monthly_hours}</h6>
            </FieldGroup>
          </FieldCol>

          <FieldCol md="3">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
              Avg. Monthly Cycles
              </FieldLabel>
              <FieldInput
                value={apuSummery.average_monthly_cycles}
                type={type}
                keyParam="average_monthly_cycles"
                updateField={this.updateForm}
              />
              <h6 className="error-msg">{error.average_monthly_cycles}</h6>
            </FieldGroup>
          </FieldCol>

          </Row>
        </div>
        <Row>
          <Col md="6">
            <div className="form-group" style={{margin: "25px 0px"}}>
              <label className="label">
              {
                showAdvanced ?
                <input
                  type="checkbox"
                  checked={apuSummery.is_titled}
                    onChange={(e) => {this.updateForm('is_titled', true);this.updateForm('is_fitted', false);this.updateForm('on_wing_status', false);}}
                  />
                :<input
                  type="checkbox"
                  checked={apuSummery.is_titled}
                  onChange={(e) => this.updateForm('is_titled', e.target.checked)}
                  />
              }
                <span>
                  Is this the titled APU of this MSN ?
                  <i class="fa fa-info-circle" aria-hidden="true" id="apu-field1" style={{paddingLeft: '4px'}}></i>
                  <UncontrolledTooltip placement="right" target="apu-field1">
                    Tick if the major assembly is Titled
                  </UncontrolledTooltip>
                </span>
              </label>
            </div>
          </Col>

          <Col md="6">
            <div className="form-group" style={{display:"block", margin: "25px 0px"}}>
              <label className="label">
              {
                showAdvanced ?
                <input
                  type="checkbox"
                  checked={apuSummery.is_fitted}
                  onChange={(e) => {this.updateForm('is_fitted', true);this.updateForm('is_titled', false);this.updateForm('on_wing_status', true);this.updateForm('on_wing_msn',  this.props.overview.msn);this.updateForm('on_wing_lessor',  this.props.overview.lessor &&  this.props.overview.lessor.name ? this.props.overview.lessor.name : '')}}
                  />
                :<input
                  type="checkbox"
                  checked={apuSummery.is_fitted}
                  onChange={(e) => this.updateForm('is_fitted', e.target.checked)}
                  />
              }
                <span>
                  Is this APU fitted on this MSN ?
                  <i class="fa fa-info-circle" aria-hidden="true" id="apu-field2" style={{paddingLeft: '4px'}}></i>
                  <UncontrolledTooltip placement="right" target="apu-field2">
                    Tick if the major assembly is temporarily installed
                  </UncontrolledTooltip>
                </span>
              </label>
            </div>
          </Col>
          <Col md="12" style={{ margin: '-26px 19px 15px'}}>
               <h6 className="error-msg">{error.titled_fitted}</h6>

             </Col>
        </Row>

        <div className="tech-spec-off-wing-block">
          <Row>
            <Col md="12">
              <div className="form-group"  style={{margin: "20px 0px"}}>
                <label className="label">
                  {showAdvanced && apuSummery.is_fitted ?
                    <input
                    type="checkbox"
                    checked={apuSummery.on_wing_status}
                    />
                    :<input
                    type="checkbox"
                    checked={apuSummery.on_wing_status}
                    onChange={(e) => this.updateForm('on_wing_status', e.target.checked)}
                    />
                  }
                  <span>
                    Is APU On MSN ?
                    <i class="fa fa-info-circle" aria-hidden="true" id="apu-field3" style={{paddingLeft: '4px'}}></i>
                    <UncontrolledTooltip placement="right" target="apu-field3">
                      Tick if the Titled major assembly is installed on  aircraft
                    </UncontrolledTooltip>
                  </span>
                </label>
              </div>
            </Col>
            <FieldCol md="12" style={ ! this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Off MSN Status</FieldLabel>
                <FieldSelect
                  placeholder='Select Off MSN Status'
                  keyParam="off_wing_status"
                  value={apuSummery.off_wing_status}
                  type={type}
                  options={techConstants.filter(item => item.type=="off_wing_status")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  on_wing_status = {this.state.on_wing_status}
                />
              </FieldGroup>
            </FieldCol>
          </Row>

            {  apuSummery.off_wing_status && this.state.off_wing_status   ?
                      <AddEditMjrAssyfields updateDetailData={this.updateDetailData} mjrAssy = {apuSummery.off_wing_detail} type={type}/>
               : null
            }
            {
                apuSummery.off_wing_status == 1 ?
               <YetToBeSentFields assemblyName="APU"  updateDetailData={this.updateDetailData} yetToBeSend = {apuSummery.off_wing_detail} type={type}/> : null
            }
            {
               apuSummery.off_wing_status == 2 ?
               <InShopServiceble assemblyName="APU" techConstants={techConstants}  updateDetailData={this.updateDetailData} inShopserv = {apuSummery.off_wing_detail} type={type}/> : null
            }
            {
               apuSummery.off_wing_status == 3 ?
              <InShopUnServiceable assemblyName="APU" techConstants={techConstants}  updateDetailData={this.updateDetailData} inShopUnserv = {apuSummery.off_wing_detail} type={type}/> : null
            }
            {
               apuSummery.off_wing_status == 4 ?
              <OffWingServiceable assemblyName="APU" techConstants={techConstants}  updateDetailData={this.updateDetailData} offWingServ = {apuSummery.off_wing_detail} type={type}/> : null
            }

            {
              apuSummery.off_wing_status == 5 ?
              <OffWingUnserviceble assemblyName="APU" techConstants={techConstants} updateDetailData={this.updateDetailData} offWingUnServ = {apuSummery.off_wing_detail} type={type}/> : null
            }

            <Row>
              <FieldCol md="6" style={ this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    On MSN
                  </FieldLabel>
                  <FieldInput
                    value={apuSummery.on_wing_msn}
                    type={type}
                    keyParam="on_wing_msn"
                    updateField={this.updateForm}
                    disable={showAdvanced && apuSummery.is_fitted}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6" style={ this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  On MSN APU Owner
                  </FieldLabel>
                  <FieldInput
                    value={apuSummery.on_wing_lessor }
                    type={type}
                    keyParam="on_wing_lessor"
                    updateField={this.updateForm}
                    disable={showAdvanced  && apuSummery.is_fitted}
                  />
                </FieldGroup>
              </FieldCol>
          </Row>
          </div>


        </div>
        { type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
