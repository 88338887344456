import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../../../constants';
import { TextInputField, GetInputField, ImageCard, ContentDrop } from'../../../Elements';
export default class ViewLopasideBar extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  		dragOver: false,
  		lopaDetail : {},
  		file: []
  	}
  }
	componentDidMount(){
		let dataObj = Object.assign({}, this.props.lopaConfig)
		this.setState({
			lopaDetail : dataObj.data
		})
	}
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
			modalTitle: '',
		});
	}
	toggleDelModal = (title='', sectionId=null, picId=null) => {
		this.setState({
			delModal: !this.state.delModal,
			modalTitle: title,
			sectionId: sectionId,
			picId: picId
		});
	}
	onDragOver = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
			dragOver:true,
		});
	}
	getCurrentIndex = (index) => {
		this.setState({
			sliderIndex: index
		})
	}
	onFocusOut = () =>{
		this.setState({
			dragOver: false,
		})
	}
	onDrop = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
			dragOver: false,
		})
	}
	updateForm = (key, value) => {
		this.setState(prevState => ({
			...prevState,
			lopaDetail : {
				...prevState.lopaDetail,
				[key] : value
			}
		}))
	}
	uploadLopaImage = ( file) => {
		this.setState(prevState => ({
			...prevState,
			lopaDetail : {
				...prevState.lopaDetail,
				lopa_image_url : URL.createObjectURL(file[0])
			}
		}))
		this.setState(prevState => ({
			...prevState,
			file: file
		}))
	}
	addEditLopaInfo = (e) => {
		e.preventDefault();
		this.props.updateLopaDetail(this.state.lopaDetail, this.state.file);
	}
	render(){
		const headerHeight = document.body.contains(document.getElementById('sidebar-header')) ? (document.getElementById('sidebar-header').clientHeight - 5) : 0 ;
		const { lopaDetail } = this.state;
    const { formInfo } = this.props;
		return(
      <div>
        <form className="config-side-bar" onSubmit={(e) => this.addEditLopaInfo(e)}>
  	     <div className="sidebar-form" style={{ height: (window.innerHeight -  headerHeight)}}>
          <Row>
            <Col className="image-row" key="" md="3">
              <ul className="list-inline">
                { lopaDetail.lopa_image_url ?
                  <ImageCard
  									picture={{ id: 0 , image: lopaDetail.lopa_image_url}}
  									toggleDelModal={() => this.toggleDelModal('Are you sure to delete?', lopaDetail.id, lopaDetail.pictures[0].id)}
  									changePicture= {(files) => this.uploadLopaImage(files)}
  									options = { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? ['C']: []}
									/> :
                  formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                  <li
                    className="list-inline-item dropable-zone"
                    onDrop={(evt) => this.uploadLopaImage( evt.dataTransfer.files)}
                    style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}}>
                    <ContentDrop
      								acceptExtensions={'image/*'}
      								mutlipleEnabled={false}
      								filesUpload={(e) => this.uploadLopaImage(e.target.files)}
                    />
                    <span className="img-info-msg">
		                  Recommended Picture dimension 400 X 400 Pixels
                    </span>
                  </li>:null
		           }
						</ul>
					</Col>
					<Col md="12" style={{marginBottom: '20px'}}>
            {
							GetInputField(
								'textarea',
								<label className="for-label">Remarks </label>,
								{ type: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'edit': 'view',
									value: lopaDetail.lopa_remarks,
									error: '',
									updateForm: (value) => this.updateForm('lopa_remarks', value)
								}
							)
						}
					</Col>
				</Row>
				</div>
				<div className="edit-sidebar-footer">
          <ul className="list-inline">
            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
              <li className="list-inline-item">
                <input  disabled={this.props.paxSeatingLoader}
  							className="primary-btn" type="submit"  value="Save Changes"
  							value={this.props.paxSeatingLoader ?  "Processing..." : "Save Changes" }
  							/>
              </li>:null
            }
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
          </ul>
        </div>
			</form>
			</div>
		)
	}
}
