
import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class OffWingUnserviceble
 extends Component {
  constructor(props) {
    super(props);

  }

  updateForm = (type, value) => {
    this.props.updateDetailData(type, value);
  }

  render() {
    const { type, offWingUnServ, techConstants } = this.props;
    return (
      <Row>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Shop Visit Requirement </FieldLabel>
          <FieldSelect
            placeholder='Select Shop Visit Requirement'
            keyParam="shop_visit_requirement"
            value={offWingUnServ.shop_visit_requirement}
            type={type}
            options={techConstants.filter(item => item.type === 'sv_requirement')}
            updateField={this.updateForm}
            labelKey='label'
            valueKey='value'
          />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Expected Date of Shipment to Shop</FieldLabel>
          <FieldDate
            value={offWingUnServ.expected_date_of_shipment_to_shop}
            type={type}
            keyParam="expected_date_of_shipment_to_shop"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>

      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Which Shop has Been Selected?</FieldLabel>
          <FieldInput
              value={offWingUnServ.selected_shop}
              type={type}
              keyParam="selected_shop"
              updateField={this.updateForm}
            />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Asset Location</FieldLabel>
          <FieldInput
              value={offWingUnServ.off_wing_location}
              type={type}
              keyParam="off_wing_location"
              updateField={this.updateForm}
              />
        </FieldGroup>
      </FieldCol>
    </Row>
    )
  }
}
