import React, { Component } from 'react';
import Pagination from "react-js-pagination";
export default class LogsPagination extends Component {
    constructor(props) {
        super(props);
    }
    handlePageChange = (pageNumber) => {
        this.props.updateLogsPageNo(pageNumber, 'page');
    }
    render() {
        if (Object.keys(this.props.paginationInfo).length) {
            return (
                <div>
                    <Pagination
                        activePage={this.props.currentPage ? parseInt(this.props.currentPage) : 1}
                        itemsCountPerPage={20}
                        totalItemsCount={this.props.paginationInfo.total}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                    />
                </div>
            )
        } else {
            return null;
        }

    }
}
