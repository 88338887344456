import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Card } from 'reactstrap';
import classnames from 'classnames';
import { AssemblySideBar, UpcomingNotFound, EventsHeader, EventItemRow } from '../../Elements';
import { EditSidebar, ShortHead, ContractHeadertext, ContractBarLoader } from '../../../contracts/Elements';
import { ListLoader, NoRecordFound, LicenceModal } from '../../../../shared';
import { BUY_LICENCE } from '../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../utils'
import { assetsType } from '../../../../constants';
import '../../assets/styles/maintenance.scss';
import AddEditShopVisit from '../components/AddEditShopVisit';
import UpcomingEvents from '../components/UpcomingEvents';
import EventHistory from '../components/EventHistory';
import { displayDateFormat, prettyNumberParamsL } from '../../../../constants';
import Switcher from '../../../../shared/components/Switcher';
import {
  addEditAfShopVisitAc,
  airframeShopVisitCrudAc,
  airframeUpcomingEventsAc,
  airframeEventHistoryAc,
  assetInfoAc,
  eventTypeAc,
  getMaintenanceUsageAc
 } from '../actionCreators';
import { AF_SHOP_VISIT_CRUD, TOGGLE_VIEW_EDIT } from '../actions';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { trackActivity } from '../../../../utils/mixpanel';
class AirframeAssembly extends Component{
  constructor(props){
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      appliedFilter: {},
      activeTab: '1'
    };
  }
  componentDidMount(){
    this.props.upcomingEvents();
    this.props.eventHistoryList();
    this.props.eventType();
    this.props.fetchAssetInfo();
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
			this.props.getMaintenanceUsage()
		}
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Major Assemblies Airframe',
      item_type: 'Event Listing'
    })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  shopVisitCrud = (type, id) =>{
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
      if(this.props.maintenanceUsage.available == this.props.maintenanceUsage.used){
       if(this.props.maintenanceUsage.available!=0 && !this.props.maintenanceUsage.is_new){
          this.props.shopVisitCrud(type, id)
        }else{
        this.props.buyLicence({
          flag: true,
          action: '/licenses/maintenance',
          content: 'License to add maintenance event is expired.'
        })
        return;
      }
    }
    }
    this.props.shopVisitCrud(type, id)
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/' + (evt.asset_type == '1' ? 'airframe':'engines');
    browserHistory.push(url);
    if(evt.asset_type == '1'){
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.componentDidMount();
    }
  }
  render(){
    let arr = [1,1,1];
    const { shopVisit, airframeEventList, airframeEventHistory, eventTypes, assetInfoObj, airframeLoader } = this.props;
    return(
      <div className="mr-rate-wrapper maintainance-container" style={{marginLeft: '215px', background: '#f0f2f3', marginBottom: '40px', marginTop: '94px'}}>
        { airframeLoader ? <ListLoader />:null}
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} changeMsn={this.changeMsn} headingText={'Maintenance' && <Switcher app="Maintenance"   />} />:<ContractBarLoader />
        }
        <AssemblySideBar params={this.props.params} />
        <div className="mr-rate-inner-wrapper">
          <div className="assembly-view-layout">
            <Nav tabs>
              <NavItem>
                <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggleTab('1'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Events History Tab' })}}
                  >
                    Events History
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggleTab('2'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Upcoming Events Tab' })}}
                  >
                    Upcoming Events
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {
                  getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.airframe && getLocalStorageInfo().user.permission.maintenance.airframe.indexOf('C') !== -1 ?
                  <ContractHeadertext
                    title=""
                    desc="All major maintenance events performed on Airframe"
                    addNew = "+ Add Maintenance Event"
                    toggleAddEvent={() => this.shopVisitCrud('add', {})}
                   />
                  :<ContractHeadertext
                    title=""
                    desc="All major maintenance events performed on Airframe"
                   />
                }
                 <div className="maintainenace-listing-blk list-container">
                   <div className="table-container">
                     <table style={{width: '100%'}}>
                        <EventsHeader eventType="Maintenance" />
                        <tbody>
                          { airframeEventHistory && airframeEventHistory.length ? airframeEventHistory.map((shopVisit,index) =>
                            <EventItemRow previewDetail={() => browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/maintenance/airframe/view/${shopVisit.id}`)} params={this.props.params} assemblyType="airframe" key={index} item={shopVisit} />) : null
                          }
                        </tbody>
                     </table>
                   </div>

                   {!airframeLoader && airframeEventHistory && !airframeEventHistory.length ? <NoRecordFound description="Get Started by clicking '+ Add Maintenance Event' button above"/>:null}
                 </div>
              </TabPane>
              <TabPane tabId="2">
                <ContractHeadertext
                  title=""
                  desc="Projected upcoming maintenance events based on data available for this asset"
                 />
                <div className="assembly-timelinse-list" style={{paddingLeft: 0}}>
                  { airframeEventList && airframeEventList.length ?
                    <div className="list-container">
                      <div className="table-container">
                        <h6 className="para table-title">Airframe Maintenance Events</h6>
                        <table width="100%">
                          <thead style={{background:'#fff'}}>
                            <tr>
                                <th className="caption-ui">Maintenance Event</th>
                                { airframeEventList.map((event, index) =>
                                  <th className="para-ui th-highlight">{event.name}</th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{cursor:'default'}}>
                              <td className="caption-ui">Next Event Date</td>
                              { airframeEventList.map((event, index) =>
                                <td className="para-ui">{ event.eventDate ? moment(event.eventDate).format(displayDateFormat):'--'}</td>
                              )}
                            </tr>
                            <tr style={{cursor:'default'}}>
                              <td className="caption-ui">Next Event TSN(expected)</td>
                              { airframeEventList.map((event, index) =>
                                <td className="para-ui">{event.expected_tsn ? event.expected_tsn:'--'}</td>
                              )}
                            </tr>
                            <tr style={{cursor:'default'}}>
                              <td className="caption-ui">Next Event CSN(expected)</td>
                              { airframeEventList.map((event, index) =>
                                <td className="para-ui">{event.expected_csn ? event.expected_csn:'--'}</td>
                              )}
                            </tr>
                            <tr style={{cursor:'default'}}>
                              <td className="caption-ui">Escalated Event cost(expected)</td>
                              { airframeEventList.map((event, index) =>
                                <td className="para-ui">{event.eventCost ?
                                  <NumericLabel params={prettyNumberParamsL}>
                                    {event.eventCost}
                                  </NumericLabel>
                                  :'--'}</td>
                              )}
                            </tr>
                            <tr style={{cursor:'default'}}>
                              <td className="caption-ui">Escalated MR cost(expected)</td>
                              { airframeEventList.map((event, index) =>
                                <td className="para-ui">{event.mr ?
                                  <NumericLabel params={prettyNumberParamsL}>
                                    {event.mr}
                                  </NumericLabel>
                                  :'--'}
                                </td>
                              )}
                            </tr>
                            <tr style={{cursor:'default'}}>
                              <td className="caption-ui">Fund Balance(expected)</td>
                              { airframeEventList.map((event, index) =>
                                <td className="para-ui">{event.fundCollected ?
                                  <NumericLabel params={prettyNumberParamsL}>
                                    {event.fundCollected}
                                  </NumericLabel>
                                  :'--'}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>:null
                  }

                </div>
                {!airframeLoader && airframeEventList && !airframeEventList.length ? <UpcomingNotFound />:null}
              </TabPane>

            </TabContent>
          </div>
       </div>
       <EditSidebar
         title={
           shopVisit.type != '' ?
           (shopVisit.type == 'add') ? 'Add Maintenance Event':
           (shopVisit.type == 'edit') ? 'Edit Maintenance Event': 'View Maintenance Event' : ''
           }
         editSideBar={(shopVisit.flag && shopVisit.type == 'add')}
         toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
         >
         {
           Object.keys(shopVisit.data).length && shopVisit.flag && shopVisit.type == 'add' ?
           <div>
             <AddEditShopVisit
               type={shopVisit.type}
               shopVisitObj={shopVisit.data}
               eventTypes={eventTypes}
               addEditShopVisit={(data, type)=>this.props.addEditShopVisit(data, type, assetInfoObj)}
               assemblyType="airframe"
               toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
             />
           </div>:null
         }
       </EditSidebar>
       {
         this.props.buyLicenceModal.flag ?
         <LicenceModal
           toggleLicence={() => this.props.buyLicence({
             flag: false,
             action: '',
             content: ''
           })}
           planId="7"
           licenceModal={this.props.buyLicenceModal}
           />
         :null
       }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  shopVisit: state.AirframeAssemblyReducer.shopVisit,
  airframeEventList: state.AirframeAssemblyReducer.airframeEventList,
  airframeEventHistory: state.AirframeAssemblyReducer.airframeEventHistory,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  airframeLoader: state.AirframeAssemblyReducer.airframeLoader,
  maintenanceUsage: state.AirframeAssemblyReducer.maintenanceUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  assetViewId: state.userAssetReducer.assetViewId
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    upcomingEvents : () => dispatch(airframeUpcomingEventsAc(ownProps.params)),
    eventHistoryList: () => dispatch(airframeEventHistoryAc(ownProps.params)),
    addEditShopVisit: (data, type, assetInfoObj) => dispatch(addEditAfShopVisitAc(ownProps.params, data, type, assetInfoObj)),
    shopVisitCrud: (type, id) => dispatch(airframeShopVisitCrudAc(ownProps.params, type, id)),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params, 'Airframe Maintenance')),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'airframe')),
    toggleEditBar: (data) => dispatch({
      type: AF_SHOP_VISIT_CRUD,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getMaintenanceUsage: () => dispatch(getMaintenanceUsageAc(ownProps.params))
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(AirframeAssembly, ['maintenance', 'airframe', 'R']));
