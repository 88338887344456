export const TE004 = 'TE004';
export const ADD_EDIT_SHOPVISIT = 'ADD_EDIT_SHOPVISIT';
export const GET_SHOPVISIT_DETAILS = 'GET_SHOPVISIT_DETAILS';
export const UPDATE_GENERAL_DETLS_FORM = 'UPDATE_GENERAL_DETLS_FORM';
export const UPDATE_SHOPVISIT_DETAILS = 'UPDATE_SHOPVISIT_DETAILS';
export const UPDATE_SHOPVISIT = 'UPDATE_SHOPVISIT';
export const ADD_SHOPVISIT = 'ADD_SHOPVISIT';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPDATE_SHOPVISIT_FORM = 'UPDATE_SHOPVISIT_FORM';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const DELETE_SHOPVISIT = 'DELETE_SHOPVISIT';
export const DELETE_SHOPVISIT_ATTACHMENT = 'DELETE_SHOPVISIT_ATTACHMENT';
export const UPDATE_SHOP_VISIT_ERR = 'UPDATE_SHOP_VISIT_ERR';
export const GENERAL_ERR_UPDATE = 'GENERAL_ERR_UPDATE';
export const SHOP_VISIT_LOADER = 'SHOP_VISIT_LOADER';