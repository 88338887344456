import React from 'react';
import { imgStoragePath } from '../../../constants';
import { SortableElement } from 'react-sortable-hoc';
import  GetInputField  from './GetInputField';

const SortableImageCard = SortableElement((props) => {
  
  //style={ props.classCustom ? { height: '250px', overflow: 'hidden'} : {overflow: 'auto'}}
  return(
   <li id={`picture_${props.picture.id}`} className="list-inline-item form-img-card overlay"  style={ props.editSideBar?{height: 'auto', overflow: 'hidden',zIndex:'1000'}:{ height: 'auto',overflow: 'hidden'}}
      >
   <div style={{position:'relative' , overflow: 'hidden',   width: '200px', height: '200px', backgroundImage: "url(" + imgStoragePath + "lazy_load.png)"}}>
    <div style={{ backgroundImage: "url(" + props.picture.image +")", width: '200px', height: '200px', backgroundPosition: '50%',backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
    </div>
    <div className="storage-action-block" >
      <span className="image-card-action">
      { props.options.includes('E')?
        <span onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.editImage();}}>
          <img src={imgStoragePath + "edit_img.png"} width="20" /> Edit
        </span>:null
      }
      { props.options.includes('R')?
        <span onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.toggleDelModal()}}>
          <img src={imgStoragePath+"delete_img.png"} width="15" /> Remove
        </span>:null
      }
      { props.options.includes('V')?
        <span onClick={props.openSlider}>
          <img src={imgStoragePath + "white_view_icon.png"} width="20" /> View
        </span>:null
      }
      { props.options.includes('C')?
        <span style={{position: 'relative'}}>
          <input  multiple={false} onChange={props.changePicture} type="file" style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer'}} />
          <img src={imgStoragePath + "Change_Pic_icon.png"} width="18" /> Change Picture
        </span>:null
      }
    </span>
  </div>
  </div>


{ props.options.includes('D') && props.classCustom == '' ?
  GetInputField(
    'string',
    null,
    { type: props.type ? props.type : 'edit',
      value: props.picture.description,
      error: '',
      updateForm: (value) => props.updateForm('description', value)
    }
):null }
</li>
    )
})
export default SortableImageCard;
