import React from 'react';
import {  Row, Col } from 'reactstrap';
export default function RecordRpt({exportObj, onChangeExportFeature, onChangeExtension}){
  return(
    <div className="export-module-card">
      <div className="rpt-card-header">
        <h2>Records</h2>
      </div>
      <div className="rpt-card-body">
        <div className="export-items">
          <div className="">
            <Row className="flex-centered">
              <Col xs="5">
                <label className="flex-centered export-label">
                  <input type="checkbox"
                    onChange={(e) => onChangeExportFeature(e.target.checked, 'inventory_report')}
                    checked={exportObj.records && exportObj.records.inventory_report && exportObj.records.inventory_report.export ? true:false}
                  />
                  <span>Inventory Report</span>
                </label>
              </Col>
              <Col xs="4">
                <div className="export-avial">
                  <h6>Export Format</h6>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <label>
                        <input name="InventoryReport" type="radio" checked={true}/>
                        <span>XL</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="export-items">
          <div className="">
            <Row className="flex-centered">
              <Col xs="5">
                <label className="flex-centered export-label">
                  <input type="checkbox"
                    onChange={(e) => onChangeExportFeature(e.target.checked, 'lessor_template_sheet')}
                    checked={exportObj.records && exportObj.records.lessor_template_sheet && exportObj.records.lessor_template_sheet.export ? true:false}
                  />
                  <span>Lessor Asset Template</span>
                </label>
              </Col>
              <Col xs="4">
                <div className="export-avial">
                  <h6>Export Format</h6>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <label>
                        <input name="LessorAssetTemplate" type="radio" checked={true} />
                        <span>XL</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="export-items">
          <div className="">
            <Row className="flex-centered">
              <Col xs="5">
                <label className="flex-centered export-label">
                  <input type="checkbox"
                  onChange={(e) => onChangeExportFeature(e.target.checked, 'action_dock')}
                  checked={exportObj.records && exportObj.records.action_dock && exportObj.records.action_dock.export ? true:false}
                  />
                  <span>Action Dock</span>
                </label>
              </Col>
              <Col xs="4">
                <div className="export-avial">
                  <h6>Export Format</h6>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <label>
                        <input
                          name="action_dock"
                          checked={(exportObj.records && exportObj.records.action_dock && exportObj.records.action_dock.extension == 'pdf')}
                          onChange={() => onChangeExtension('pdf', 'action_dock')}
                          type="radio"
                        />
                        <span>PDF</span>
                      </label>
                    </li>
                    <li className="list-inline-item">
                      <label>
                        <input
                          name="action_dock"
                          checked={(exportObj.records && exportObj.records.action_dock && exportObj.records.action_dock.extension == 'xls')}
                          onChange={() => onChangeExtension('xls', 'action_dock')}
                          type="radio"
                        />
                        <span>XL</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
