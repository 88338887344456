import React, { Component } from 'react';
import Select, { Creatable } from 'react-select';
import 'react-select/dist/react-select.css';
export default class FieldSelect extends Component{
  constructor(props){
    super(props);
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){
    const { type, keyParam, value, labelKey, valueKey, options, placeholder, name, disabled,styleName, creatable } = this.props;
    if(type != 'view'){
      if(creatable){
        return(
          <Creatable
            name="form-field-name"
            placeholder={placeholder}
            className= {styleName !=null? styleName:"custom-select-block"}
            value={value}
            options = {options}
            labelKey={labelKey}
            valueKey={valueKey}
            disabled = {disabled}
            onChange = {(target) => this.props.updateField(keyParam,target?target[valueKey]:'')}
            onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
          />
        )
      }else{
        return(
          <Select
            name="form-field-name"
            placeholder={placeholder}
            className= {styleName !=null? styleName:"custom-select-block"}
            value={value}
            options = {options}
            labelKey={labelKey}
            valueKey={valueKey}
            disables = {disabled}
            onChange = {(target) => this.props.updateField(keyParam,target?target[valueKey]:'')}
            onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
          />
        )
      }
    }else{
      return(<h6>{ name ? name:value } </h6>)
    }
  }
}
