import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { FieldCol, FieldGroup, FieldLabel } from '../../Elements';
export default class EditDataPointInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      dataItem: {},
      height: '30px'
    }
  }
  componentDidMount(){
    let heightVar = '';
    if(!this.props.dataPoint.options){
      if((Math.round(this.props.dataPoint.value.length/120) > 1) && (Math.round(this.props.dataPoint.value.length/120) < 4)){
        heightVar = Math.round((this.props.dataPoint.value.length/120)*42) + 'px';
      }else if(Math.round(this.props.dataPoint.value.length/120) > 4){
        heightVar = '420px';
      }else{
        heightVar = '30px';
      }
    }
    this.setState({
      dataItem: this.props.dataPoint,
      height: heightVar,
      error: ''
    });
  }
  updateField = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      dataItem: {
        ...prevState.dataItem,
        [type]: value
      },
      error: ''
    }))
  }
  addEditDataPoint = (e) => {
    e.preventDefault();
    if((this.state.dataItem.options ? this.state.dataItem.option_value == '' ? true : false : this.state.dataItem.value == ''? true: false)){
      this.setState({
        error: 'This Field is Required'
      })
    }else{
      this.props.editDataPoint(this.state.dataItem);
    }

  }
  render(){
    const { dataItem, height, error } = this.state;
    if(!Object.keys(dataItem).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditDataPoint(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">{dataItem.label}</FieldLabel>
                {
                  dataItem.options ?
                  <Select
                    className="custom-select-block"
                    name="form-field-name"
                    value={dataItem.option_value}
                    options={dataItem.options}
                    onChange={(target) => this.updateField('option_value',target?target.value:'')}
                  />:
                  <textarea defaultValue={dataItem.value} style={{height: height}}
                    onChange={(e) => this.updateField('value',e.target.value)}
                    ></textarea>
                }
                <h6 className="error-msg">{error}</h6>
              </FieldGroup>

            </FieldCol>
          </Row>
        </div>
        <div className="submit-block">
           <input type="submit" className="primary-btn" value="Save Changes"/>
           <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
        </div>
      </form>
    )
  }
}
