import React from 'react';
import { Col, Row } from "reactstrap";
import moment from "moment";
import {imgStoragePath,displayDateTimeFormatShort} from "../../../../constants";


const HistoryList = (props) => {
    return (

        <li key={props.index}>
            {
            props.details.status && props.details.status == '1' ?
            <div>
                <p className="user-heading">Accepted By</p>
                <p className="user-details">
                    <span className="issued-date">
                    <img src={props.details.accepted_by.profile_pic?props.details.accepted_by.profile_pic:imgStoragePath+'user.png'} alt="img" />
                    {props.details.accepted_by.name ?props.details.accepted_by.name.charAt(0).toUpperCase() + props.details.accepted_by.name.slice(1):'--'}
                    </span>
                    <span className="edited-on">
                    Accepted on {props.details.updated_at ? `${moment(props.details.updated_at).format(displayDateTimeFormatShort)}` : null}
                    </span>
                </p>
            </div>
            : props.details.status && props.details.status == '2' ?<div>
                <p className="user-heading">Rejected By</p>
                <p className="user-details">
                    <span className="issued-date">
                    <img src={props.details.rejected_by.profile_pic?props.details.rejected_by.profile_pic:imgStoragePath+'user.png'} alt="img" />
                    {props.details.rejected_by.name ?props.details.rejected_by.name.charAt(0).toUpperCase() + props.details.rejected_by.name.slice(1):'--'}
                    </span>
                    <span className="edited-on">
                    Rejected on {props.details.updated_at ? `${moment(props.details.updated_at).format(displayDateTimeFormatShort)}` : null}
                    </span>
                </p>
            </div>
            : null
            }
            <p className="user-heading">Requested By</p>
            <p className="user-details" style={{ marginBottom: '0' }}>
                <span className="issued-date">
                <img src={props.details.created_by.profile_pic?props.details.created_by.profile_pic:imgStoragePath+'user.png'} alt="img" />
                {props.details.created_by.name ?props.details.created_by.name.charAt(0).toUpperCase() + props.details.created_by.name.slice(1):'--'}
                </span>
                <span className="edited-on">
                Requested on {props.details.created_at ? `${moment(props.details.created_at).format(displayDateTimeFormatShort)}` : null}
                </span>
            </p>
        </li>
    )
}

export default HistoryList;
