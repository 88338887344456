import React from 'react'
import { imgStoragePath, displayDateFormatShort, referalImages } from '../../../constants';
import moment from 'moment'

const ReferralHistoryList = (props)=> {
  return(
    <div className="flex-not-centered outer-wrapper">
        <div className="first-block">
            <div className="height-block">
                <img src={referalImages[props.referal.plan.id]?imgStoragePath+referalImages[props.referal.plan.id]:imgStoragePath+'phase-2/user_license.png'} />
                <div className="vl"></div>
            </div>
        </div>
        <div className="scnd-block">
            {props.referal.referral_count && props.referal.referral_count==1 ?
            <h2>Avail {props.referal.plan && props.referal.plan.id == 3 ? '': props.referal.plan.quantity} {props.referal.plan.name}  License by referring one more user.</h2>:
            <h2>{props.referal.plan && props.referal.plan.id == 3 ? '': props.referal.plan.quantity} {props.referal.plan.name} License credited to your account</h2>}
            <span>On {moment(props.referal.created_at).format(displayDateFormatShort)}</span>
            <p>{
              props.referal.referral_type == 2 ?
              `${props.referal.from_lessor.name} (${props.referal.from_lessor.email}) joined SPARTA using your referral program code.`
              :`You joined SPARTA using ${props.referal.from_lessor.name}'s (${props.referal.from_lessor.email}) referral program code.`
            }</p>
        </div>
    </div>
  )
}

export default ReferralHistoryList
