import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared'
import AddEditLeaseOption from '../components/AddEditLeaseOption';
import LeaseOptionRow from '../components/LeaseOptionRow';
import { leaseOptionListAc, leaseOptionCrudFnAc, leaseOptionAddEditAc } from '../actionCreators';
import { TOGGLE_VIEW_EDIT, LEASE_OPTION_CRUD } from '../actions';
import { sExportContractsAc, sGlobalConstantAc }  from '../../../../shared/actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { sAuditLogAc } from '../../../../shared/actionCreators';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import { withRouter, browserHistory } from 'react-router';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
class LeaseOption extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.props.fetchLeasOptions();
    this.props.fetchTechConstant({constant_types:['lease_option_type']});
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/lease-option';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }
  }
  render() {
    const { leaseOptionList, assetViewId, leaseOptionCrud, leaseOptionLoader, techConstants, auditLogView } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Contracts" params= {this.props.params} pageTitle="EOL (End of Lease) Options" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application={`EOL Options`} type="brief" params= {this.props.params} />
        <div className="contract-content-blk">
          <ContractHeadertext
            title="EOL (End of Lease) Options"
            desc="Section holds all options available at the End of Lease"
            addNew={getLocalStorageInfo().user.permission['contracts']['eol_options'].indexOf('C') != -1 ? '+ Add EOL Option': ''}
            toggleAddEvent = {() => this.props.leaseOptionCrudFn('add', {})}
            toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctleaseoptions', 0, null, 'add', this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'EOL Options', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'EOL Options'})}}
            addAudit= {auditLogView ?  "Revision History" : null}

          />
          <div className="list-container">
            {leaseOptionLoader ? <ListLoader />:null}
            <div className="table-container">
              <table>
                <CtrTableHd theads={['Option Type', 'Details', 'Validity Period', 'Notice Period']}/>
                <tbody>
                  { leaseOptionList.map((leaseOption,index) => <LeaseOptionRow key={index} leaseOption={leaseOption} viewLeaseOption={() => this.props.leaseOptionCrudFn('view',leaseOption.id)}  /> )}
                </tbody>
              </table>
              {!leaseOptionLoader && !leaseOptionList.length ?
                <NoRecordFound description="Get Started by clicking '+ Add EOL Option' button above" />:null
              }
            </div>
          </div>
        </div>
        <EditSidebar
          title={
            leaseOptionCrud.type != '' ?
            (leaseOptionCrud.type == 'add') ? 'Add Lease Option':
            (leaseOptionCrud.type == 'edit') ? 'Edit Lease Option': 'View Lease Option' : ''
            }
          editSideBar={leaseOptionCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
          >
          {
            Object.keys(leaseOptionCrud.data).length && leaseOptionCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctleaseoptions', 1, leaseOptionCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'EOL Options', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'EOL Options', item_id:leaseOptionCrud.data.id})}}
                type={leaseOptionCrud.type}
                changeViewMode={()=>this.props.changeViewMode(leaseOptionCrud.type)}
                editAble = {getLocalStorageInfo().user.permission['contracts']['eol_options'].indexOf('U') != -1 ? true:false}
              />
              <AddEditLeaseOption
                type={leaseOptionCrud.type}
                techConstants={techConstants}
                addEditLeaseOption={(data, type)=>this.props.addEditLeaseOption(data, type, assetViewId)}
                leaseOptionObj={leaseOptionCrud.data}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                leaseOptionLoader={leaseOptionLoader}
              />
            </div>:null
          }
          </EditSidebar>

            <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  leaseOptionList: state.ContrLeaseOptReducer.leaseOptionList,
  leaseOptionCrud: state.ContrLeaseOptReducer.leaseOptionCrud,
  leaseOptionLoader: state.ContrLeaseOptReducer.leaseOptionLoader,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchLeasOptions: () => dispatch(leaseOptionListAc(ownProps.params)),
    addEditLeaseOption: (data, type, assetViewId) => dispatch(leaseOptionAddEditAc(ownProps.params, data, type, assetViewId)),
    leaseOptionCrudFn: (type, id) => dispatch(leaseOptionCrudFnAc(ownProps.params, type, id)),
    toggleEditBar: (data) => dispatch({
      type: LEASE_OPTION_CRUD,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    toggleAuditlogFn: (application, content_type, action, objectId, type, contractSlig, cType) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, contractSlig, cType)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(LeaseOption, ['contracts', 'eol_options' ,'R'])));
