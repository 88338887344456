import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, browserHistory } from 'react-router';
import { Row, Col } from 'reactstrap';
import { mediaBaseUrl, imgStoragePath, fileIcons } from './constants';
import { technicalAssetRptAc, downloadWorkOrderFormRptAc } from './shared/actionCreators';
import { ListLoader } from './shared';
class TechnicalAssetReport extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    document.querySelector('body').style.padding = 0;
    this.props.technicalAssetRpt()
  }
  componentWillUnmount(){
    document.querySelector('body').style.paddingLeft = '20px';
    this.props.technicalAssetRpt()
  }
  render(){
    const { technicalAssetRptInfo, filepreviewLoader } = this.props;
    return(
      <div>
        { filepreviewLoader ? <ListLoader drawer="primary-nav" />:null }
        <header id="header" style={{padding: '3.67px 3px'}}>
          <Row className="flex-centered">
            <Col sm="8">
              <ul className="list-inline header-logo-search">
                <li className="list-inline-item">
                  <Link to="/">
                    <img width="32" className="main-logo" src={imgStoragePath+"sparta_logo.png"} alt="logo" />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md="4" style={{textAlign: 'right'}}>

            </Col>
          </Row>
        </header>
        <div className="technical-asset-report">
          <h2>{ Object.keys(technicalAssetRptInfo).length ? technicalAssetRptInfo.asset_type == 1 ? 'MSN ' + technicalAssetRptInfo.msn : 'ESN ' +technicalAssetRptInfo.esn : null} Report {technicalAssetRptInfo.forms ? <span>{technicalAssetRptInfo.forms.length} Forms</span>:null}</h2>
          <div className="asset-form-info">
            <div className="asset-project-info">
              <Row>
                <Col md="4" className="card-blk">
                  <label>Project Name</label>
                  <h6>{technicalAssetRptInfo.project ? technicalAssetRptInfo.project.name: '--' }</h6>
                </Col>
                <Col md="4" className="card-blk">
                  { technicalAssetRptInfo.project && technicalAssetRptInfo.project.lessor.logo ?
                    <img className="lessor-logo" src={technicalAssetRptInfo.project.lessor.logo} alt="" />:null
                  }
                  <div className="">
                    <label>Client</label>
                    <h6>{technicalAssetRptInfo.project ? technicalAssetRptInfo.project.lessor.name: '--' }</h6>
                  </div>
                </Col>
                <Col md="4" className="card-blk">
                  <label>Status</label>
                  <h6>{technicalAssetRptInfo.project ? technicalAssetRptInfo.project.status.label: '--' }</h6>
                </Col>
                <Col md="4" className="card-blk">
                  <img src={imgStoragePath + (technicalAssetRptInfo.asset_type == 1 ? 'icon_Aircraft.png': 'icon_engine.png')} alt="" />
                  <div className="">
                    <label>{Object.keys(technicalAssetRptInfo).length ? technicalAssetRptInfo.asset_type == 1 ? technicalAssetRptInfo.aircraft_type.name : technicalAssetRptInfo.engine_type.name :'--'}</label>
                    <h6>{Object.keys(technicalAssetRptInfo).length ? technicalAssetRptInfo.asset_type == 1 ? 'MSN ' + technicalAssetRptInfo.msn : 'ESN '+technicalAssetRptInfo.esn : '--'}</h6>
                  </div>
                </Col>
                <Col md="4" className="card-blk">
                  { Object.keys(technicalAssetRptInfo).length && technicalAssetRptInfo.project.leads.length ?
                    <img src={technicalAssetRptInfo.project.leads[0].profile_pic ? technicalAssetRptInfo.project.leads[0].profile_pic: imgStoragePath +'user.png' } alt="" />:null
                  }
                  <div className="">
                    <label>Lead By</label>
                    <h6>{Object.keys(technicalAssetRptInfo).length ? technicalAssetRptInfo.project.leads.length ? technicalAssetRptInfo.project.leads[0].name :'--'  :'--'}</h6>
                  </div>
                </Col>
              </Row>
            </div>
            <table>
              <thead>
                <tr>
                  {['Form Type', 'Project Engineer', ''].map((label, index) =>
                    <th>{label}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                { technicalAssetRptInfo.forms ?
                  technicalAssetRptInfo.forms.map((form, index) =>
                    <tr>
                      <td>
                        <h4>{form.name} - {form.title}</h4>
                      </td>
                      <td>
                        <span className="user-info"><img src={form.engineer.profile_pic ? form.engineer.profile_pic:imgStoragePath+'user.png'} alt="" /> {form.engineer.name}</span>
                      </td>
                      <td align="right">
                        <span onClick={() => this.props.downloadWorkOrderFormRpt((technicalAssetRptInfo.asset_type == 1 ? 'MSN_' + technicalAssetRptInfo.msn : 'ESN_'+technicalAssetRptInfo.esn)+'_'+form.name, form.share_key)}>Download Report</span>
                      </td>
                    </tr>
                ):null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  technicalAssetRptInfo: state.sharedReducers.technicalAssetRptInfo,
  filepreviewLoader: state.sharedReducers.filepreviewLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    technicalAssetRpt: () => dispatch(technicalAssetRptAc(ownProps)),
    downloadWorkOrderFormRpt: (name, shareKey) => dispatch(downloadWorkOrderFormRptAc(ownProps, name, shareKey))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TechnicalAssetReport);
