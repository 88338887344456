
import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class OffWingServiceable
 extends Component {
  constructor(props) {
    super(props);

  }

  updateForm = (type, value) => {
    this.props.updateDetailData(type, value);
  }

  render() {
    const { type, offWingServ, techConstants } = this.props;
    return (
      <Row>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Expected Date of Installation On Aircraft</FieldLabel>
          <FieldDate
                  value={offWingServ.expected_date_of_installation_on_wing}
                  type={type}
                  keyParam="expected_date_of_installation_on_wing"
                  updateField={this.updateForm}
                />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">SV Performed </FieldLabel>
            <div className="flex-centered radio-button">
            <label className="label">
                <input
                  type="radio"
                  name="shop_visit_performed"
                  value="yes"
                  checked={offWingServ.shop_visit_performed == 1 ? 'checked' : null}
                  onChange={(e) => this.updateForm('shop_visit_performed', true)}
                />
              <span>Yes</span>
              </label>
            <label className="label">
              <input
                type="radio"
                value="no"
                name="shop_visit_performed"
                checked={offWingServ.shop_visit_performed == 0 ? 'checked' : null}
                onChange={(e) => this.updateForm('shop_visit_performed', false)}
              />
              <span>No</span>
            </label>
          </div>
        </FieldGroup>
      </FieldCol>


      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">SV Report Status</FieldLabel>
          <FieldSelect
                  placeholder='Select Report Status'
                  keyParam="shop_visit_report_status"
                  value={offWingServ.shop_visit_report_status}
                  type={type}
                  options={techConstants.filter(item => item.type === 'sv_report_status')}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name=""
                />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Asset Location</FieldLabel>
          <FieldInput
              value={offWingServ.off_wing_location}
              type={type}
              keyParam="off_wing_location"
              updateField={this.updateForm}
              />
        </FieldGroup>
      </FieldCol>


      </Row>
    )
  }
}
