import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { SIDEBAR_TOGGLE } from '../actions';
import { Link, IndexLink } from 'react-router';
import { getLocalStorageInfo, eraseGlobalCookie } from '../../utils';
import { trackLogOut } from '../../utils/mixpanel'
import { imgStoragePath } from '../../constants';
import { licenseListAc } from '../../phase2/licenses/actionCreators'
import ToolTipHover from './ToolTipHover';
class PrySidebarExp extends Component{
  constructor(props){
    super(props);
  }
  logout = () => {
    trackLogOut(getLocalStorageInfo())
    localStorage.clear();
    eraseGlobalCookie('redirectURI')
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    this.props.sidebarToggleFn(false)
    browserHistory.push('/login');
  }

  componentDidMount(){
    if(getLocalStorageInfo() && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.license){
      this.props.fetchLicenses()
    }
  }

  checkTracker(){
    if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')){
      return  '/technical/projects/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')){
      return  '/technical/projects/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')){
      return  '/technical/man-power/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('CR')){
      return  '/technical/cor/tracker';
    }
  }

  primaryNavLink = (imgIcon, imgWidth, url, activeClassName, flag, targetId, placement, title, linkAccess, isBeta) => {

    if(linkAccess){
      return (
        <li>
          <Link to={url} onClick={() => this.props.sidebarToggleFn(false)} style={(title == 'Co') ? {cursor: 'not-allowed'} : {}} className={(targetId == 'userManagement' ? ' ': ' ') + (targetId == 'usersManager' ? 'utility-block ': ' ')  + (activeClassName ? 'flex-centered active' :'flex-centered')}>
            <img id={targetId} src={imgStoragePath+imgIcon} alt="nav-icon" width={imgWidth} />
            <br/>
            <span className="sideBarSubheading">{title}</span>
          {
            isBeta ? <sup style={{color: '#ff0000', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-Bold', padding: '0 2px'}}>BETA</sup> : null
          }
            {
              //this.showToolTip(flag, targetId, placement, title)
            }
          </Link>
        </li>
      )
    }else{
      return null
     }
  }
  showToolTip = (flag, targetId, placement, title) => {
    if(!flag){
      return(
        <ToolTipHover tagetId={targetId} placement={placement}> {title} </ToolTipHover>
      )
    }else{ return null }
  }
  render(){
    var { sideBarStatus, licenses, notificationOpen, dashSw } = this.props;
    sideBarStatus = false;
    const pathname = this.props.pathName;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    let is_saas = false
    Object.keys(userDetail.user.permission).map(permission => {
      if(userDetail.user.permission[permission].is_saas == true){
        is_saas = true
      }
      return permission
    })
    return(
      <div className="prySidebarExp" style={ notificationOpen || this.props.topNotice ? {marginTop: '30px'} : null }>
        <div className="dashboard-block">
          {
            getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id !== 348 ?
            <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0px'  }}>
                <li>
                  <IndexLink  onClick={() => this.props.sidebarToggleFn(false)}  activeClassName="active" className="flex-centered" to="/">
                    <img id="dashboard" src={imgStoragePath+ (getLocalStorageInfo().user.accept_old_dashboard ? 'dashboard_icon.png':'Home_Icon.svg')} alt="img" width={getLocalStorageInfo().user.accept_old_dashboard ? '20': '30'}/>
                    <br/>
                    <span className="sideBarSubheading ">
                      {getLocalStorageInfo().user.accept_old_dashboard ? 'Dashboard':'Home'}
                    </span>
                  </IndexLink>
                </li>

              { this.primaryNavLink('phase-2/asset_list_icon.png', 20, '/assets-listing', (pathname.includes('assets-listing') || pathname.includes('asset-view')), sideBarStatus, 'assetList', 'right', 'Assets', true) }
              { this.primaryNavLink('phase-2/technical_projects.png', 20, '/technical/projects?project_status=all', ((pathname.includes('technical/project') || pathname.includes('/forms/')) && !pathname.includes('/technical/projects/tracker')), sideBarStatus, 'technical-projects', 'right', 'Projects', getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects) }
              { this.primaryNavLink('phase-2/project_tracker.png', 20, this.checkTracker(), (pathname.includes(this.checkTracker())), sideBarStatus, 'technical-tracker', 'right', 'Trackers', (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && (getLocalStorageInfo().user.permission.technical.project_tracker.length ==1 && getLocalStorageInfo().user.permission.technical.project_tracker.includes('U')?false:true))) }
              { this.primaryNavLink('Bitmap.png', 20, '/technical/program-planner', (pathname.includes('/technical/program-planner')), sideBarStatus, 'program-planner', 'right', 'Program Planner', (getLocalStorageInfo().user.permission.program_planner && getLocalStorageInfo().user.permission.program_planner.dashboard)) }
              <li className="utility-block">
                  <li>
                    <Link to='https://qa.mr-calculator.sparta.aero/login' target="_blank" onClick={() => this.props.sidebarToggleFn(false)}>
                      <img id={'mrCalculator'} src={imgStoragePath+'mr_cal_icon.png'} alt="nav-icon" width={20} />
                      <br/>
                      <span className="sideBarSubheading">MR Calculator</span>
                      {
                        //this.showToolTip(flag, targetId, placement, title)
                      }
                    </Link>
                  </li>
                  {
                    // this.primaryNavLink('fleet_cashflow_icon.png', 20, '/fleet-cashflow/portfolios/', (pathname.includes('fleet-cashflow') && pathname.indexOf('licenses') == -1), true, 'fleetCashflow', 'right', 'Fleet Cashflow', getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3) 
                  }
                  { this.primaryNavLink('bulk_report-icon.svg', 20, '/export/reports/', (pathname.includes('export/reports')), true, 'exportReport', 'right', 'Reports', getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3) }
                  { this.primaryNavLink('tableau_analytics.svg', 20, '/analytics/', (pathname.includes('/analytics')), true, 'exportReport', 'right', 'Analytics', getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.analytics && getLocalStorageInfo().user.permission.analytics.dashboard , true) }
                  {
                    // this.primaryNavLink('bulk_report-icon.svg', 20,'/newsfeed', (pathname.includes('/newsfeed')),true, "newsfeed", "right",'News Feed', getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3)
                  }
              </li>
              { this.primaryNavLink('phase-2/users.png', 20, '/users/manage/active', (pathname.includes('/users/manage') || pathname.includes('/users/invites')), true, 'usersManager', 'right', 'Users', getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user) }
              { this.primaryNavLink('phase-2/storage.png', 20, '/storage-listing', pathname.includes('/storage-listing'), true, 'userManagement', 'right', 'Storage', getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas && userDetail.user.permission.console && userDetail.user.permission.console.storage) }
              { this.primaryNavLink('phase-2/licenses.png', 20, licenses.length > 0 ? '/licenses/' + licenses[0].name.toLowerCase().replace(' ','-') : '/licenses/user' , (pathname.includes('/license') && !pathname.includes('cart')), true, 'userManagement', 'right', 'Licenses', is_saas && userDetail.user.permission.console && userDetail.user.permission.console.license ) }
              { this.primaryNavLink('phase-2/billing.png', 20, '/billing/invoices', pathname.includes('/billing'), true, 'userManagement', 'right', 'Billing', is_saas && userDetail.user.permission.console && userDetail.user.permission.console.billing ) }
              {this.primaryNavLink('settings.png', 20, '/configure', pathname.includes('/configure'), true, 'userManagement', 'right', 'Configure', userDetail.user.permission.console && userDetail.user.permission.console.lessor_configuration)}
              { window.innerWidth <= 1199 ?
                <li>
                  <Link to="/user-profile">
                    <img src={imgStoragePath+"phase-2/users.png"} alt="profile" />
                    <br/>
                    <span className="sideBarSubheading">Profile</span>
                  </Link>
                </li> : null
              }
              { window.innerWidth <= 1199 ?
                <li>
                  <Link onClick = {this.logout}>
                    <img className="logout-icon" src={imgStoragePath+"logout_icon.png"} alt="logout"  width="20"/>
                    <br/>
                    <span className="sideBarSubheading">Logout</span>
                  </Link>
                </li> : null
              }
            </ul>
            :<ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0px'  }}>
                <li>
                  <IndexLink  onClick={() => this.props.sidebarToggleFn(false)}  activeClassName="active" className="flex-centered" to="/">
                    <img id="dashboard" src={imgStoragePath+ (getLocalStorageInfo().user.accept_old_dashboard ? 'dashboard_icon.png':'Home_Icon.svg')} alt="img" width={getLocalStorageInfo().user.accept_old_dashboard ? '20': '30'}/>
                    <br/>
                    <span className="sideBarSubheading ">
                      {getLocalStorageInfo().user.accept_old_dashboard ? 'Dashboard':'Home'}
                    </span>
                  </IndexLink>
                </li>

              { this.primaryNavLink('phase-2/asset_list_icon.png', 20, '/assets-listing', (pathname.includes('assets-listing') || pathname.includes('asset-view')), sideBarStatus, 'assetList', 'right', 'Assets', true) }
              { window.innerWidth <= 1199 ?
                <li>
                  <Link onClick = {this.logout}>
                    <img className="logout-icon" src={imgStoragePath+"logout_icon.png"} alt="logout"  width="20"/>
                    <br/>
                    <span className="sideBarSubheading">Logout</span>
                  </Link>
                </li> : null
              }
            </ul>
          }
        </div>
        {
          // <Link className="click-indicator" onClick={this.props.sideBarToggle}>
          //   <img src={imgStoragePath + (true ? 'tabpush.png': 'tabpull.png')} alt="slider" />
          // </Link>
        }
     </div>
    )
  }
}

const mapStateToProps = state => ({
  licenses: state.LicenseReducer.licensList,
  notificationOpen:state.sharedReducers.notificationOpen,
  dashSw:state.sharedReducers.dashSw,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sidebarToggleFn: (statusFlag) => dispatch({
      type: SIDEBAR_TOGGLE,
      payload: false
    }),
    fetchLicenses : () => dispatch(licenseListAc())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PrySidebarExp);
