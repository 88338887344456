import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { imgStoragePath } from '../../../constants';
export default class ContrSelectField extends Component{
  constructor(props){
    super(props);
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
    if(this.props.error){
      this.props.updateErrorCode(this.props.keyParam);
    }
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){
    const {  label, type, placeholder, value, options, keyParam, labelKey, valueKey, name, on_wing_status, valReq, valInfo } = this.props;
    return(
      <Col md={this.props.md ? this.props.md:'12'}>
        <div className="form-group" style={ on_wing_status ? { display:"none"} : {display: "block"}}>
          <label className="label">{label} { valReq && type != 'view' ? <sup className="reqStar"> * </sup> : null } { valInfo && type != 'view' ? <sup className="reqStar"><img width="14" src={imgStoragePath+"info_icon.png"} alt="info"/></sup> : null} </label>
          {
            type != 'view' ?
            <Select
              name="form-field-name"
              placeholder={placeholder}
              className="custom-select-block"
              value={value}
              options = {options}
              labelKey={labelKey}
              valueKey={valueKey}
              onChange = {(target) => this.props.updateField(keyParam,target?target[valueKey]:'')}
              onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
              />
            :
            <h6>{ name ? name:value }</h6>
          }
          {
             this.props.error ?
             <h6 className="error-msg">{this.props.error}</h6>
            : null
           }
        </div>
      </Col>
    )
  }
}
