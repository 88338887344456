import React from 'react'
import { Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { imgStoragePath, displayDateTimeFormat } from '../../../constants';
import { getFileSize } from '../../../utils'
import { browserHistory, withRouter } from 'react-router'
import moment from 'moment'

class CurrentPlan extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      dropdownOpen: false
    }
  }

  toggleDropdown = () =>{
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  render(){
    return(
      <Col md="12">
          <div className="wrapper">
              <Row>
                  <Col md="3">
                      <h4>Plan</h4>
                    <p>{this.props.plan.plan.name} (<span style={{fontSize: '12px'}}>{ this.props.plan.selected_type && this.props.plan.selected_type.value ? this.props.plan.selected_type.label : this.props.plan.plan.plan_type.label }</span>)</p>
                  </Col>
                  <Col md="3">
                    {
                      browserHistory.getCurrentLocation().pathname.includes('user') ?
                        <h4>No. of {this.props.plan.plan.units.label}</h4>
                      :browserHistory.getCurrentLocation().pathname.includes('storage') ? <h4>Storage</h4> :<h4>Quantity</h4>
                    }
                        {
                          Object.keys(this.props.plan.usage).length ?
                            browserHistory.getCurrentLocation().pathname.includes('storage') ?
                            this.props.plan.status.value == 1 ?
                            <p>
                              {getFileSize(this.props.plan.usage.total - this.props.plan.usage.remaining)}/{getFileSize(this.props.plan.usage.total)}
                            </p>
                            :<p>
                              {getFileSize(this.props.plan.usage.total)}
                            </p>
                            :this.props.plan.status.value == 1 ?
                            this.props.plan.quantity != -1 ?
                            <p>
                              {this.props.plan.quantity - this.props.plan.usage.remaining}/{this.props.params.id == 'fleet-cashflow' && this.props.plan.quantity < 0 ? 'Unlimited' :this.props.plan.quantity}
                            </p>:<p>Unlimited</p>
                            :<p>
                              {this.props.plan.quantity}
                            </p>
                          :<p>{this.props.plan.quantity}</p>
                        }
                  </Col>
                  <Col md="2">
                      <h4>Last Billed Date</h4>
                      <p>{ this.props.plan.plan && this.props.plan.plan.plan_type && this.props.plan.plan.plan_type.value == 1 ? '--' : this.props.plan.last_billing_date ? moment(this.props.plan.last_billing_date).format(displayDateTimeFormat) : this.props.plan.start_date ? moment(this.props.plan.start_date).format(displayDateTimeFormat) : '-' }</p>
                  </Col>
                  <Col md="2">
                      <h4>Next Billing Date</h4>
                      <p>{ this.props.plan.due_date ? moment(this.props.plan.due_date).format(displayDateTimeFormat) : '--' }</p>
                  </Col>
                  <Col md="1">
                      <h4>Status</h4>
                      <p>
                          <span className={this.props.plan.status && this.props.plan.status.value == 1 ? "success-class" : "error" }>{this.props.plan.status && this.props.plan.status.label  ? this.props.plan.status.label : "" } </span>
                          {/* ( MSN 12345 - Mid-Term Inspection - 12th May 2019 ) */}
                      </p>
                  </Col>
                  {
                    this.props.plan.status && !this.props.plan.is_deactivated && this.props.plan.status.value == 1 && this.props.plan.plan && this.props.plan.plan.plan_type && this.props.plan.plan.plan_type.value != 1 && this.props.plan.plan.is_subscription ?
                    <Col md="1" className="text-center">
                      <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown()}>
                        <DropdownToggle>
                          <img className="edit-icon" src={imgStoragePath+'phase-2/license_edit.png'} onClick={() => this.toggleDropdown()}/>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem style={{display: 'none'}} onClick={() => this.props.deactivateLicence()}><h4>Deactivate</h4></DropdownItem>
                        {
                          this.props.plan.usage.remaining > 1 && !browserHistory.getCurrentLocation().pathname.includes('storage')?
                            <DropdownItem divider/>
                          :null
                        }
                        <DropdownItem onClick={() => this.props.editLicence()}><h4>Edit</h4></DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    :null
                  }
              </Row>
              <Row style={{display: 'none'}}>
                <Col md="12">
                  <span className="warning-info-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                </Col>
              </Row>
          </div>
      </Col>
    )
  }
}

export default withRouter(CurrentPlan)
