import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import  AssetPopover from './AssetPopover';
let flag = 1;
export default class MonthAndDateHeader  extends Component {
  constructor(props){
    super(props);
	}
  render() {
		const { programTrackerList  } = this.props;
    return(
 			<div className="simulation-month-sidebar item-block">
				<div className="row-header">
					{programTrackerList && programTrackerList.length ?
						programTrackerList.map((project, index) =>
							<div className="item-detail-block flex-not-centered" style={{ height: '145px'}} key={index}>
								<div className="innerheading-block flex-centered" style={{ width: '243px', flexBasis: '243px'}}>
									<div className="para-ui month-detail flex-centered" style={{ paddingLeft: '3px'}}>
                   <div style={{position:'relative',width:'100%'}}>
                    <b className="comment-count comment-icon" onClick={() => this.props.toggleComments(project.id,'tracker')} ><span>{project.comments&&project.comments.length?project.comments.length:'0'}</span></b>
                    </div>
										<div  className="second-block">
											<span style={{fontSize:'14px'}} className="lessee-name">{ project.lessee ?  project.lessee : '--'}</span>
											<p className="proj-name-block" style={{paddingTop:'5px'}}>
												{ project.asset_name ? <span style={{color:'#000',fontSize:'16px'}}> {project.asset_name} 
                        <AssetPopover
													placement= 'right'
													tagetId={'planner-Popover-'+project.id+'-1'}
                          project={project}
												/>
                        </span>:null}
											</p>
										</div>
                                        <div className="table-data">
                                        <table>
                                            <thead>
                                              <tr>
                                               <th style={{fontSize:'11px',paddingRight:'10px',color:'#7f8fa4'}}> PLANNED DATE </th>
                                               <th style={{fontSize:'11px',paddingRight:'10px',color:'#7f8fa4'}}> QUARTER</th>
                                               <th style={{fontSize:'11px',color:'#7f8fa4'}}> RISK LEVEL </th>
                                               </tr>
                                               </thead>
                                               <tbody>
                                                 <tr>
                                                <td style={{fontSize:'11px',paddingRight:'10px'}}>
                                                  {
                                                    project.planned_date ?
                                                    moment(project.planned_date).format('DD MMM, YYYY')
                                                    : "To be planned"
                                                  }
                                                </td>
                                                 <td style={{fontSize:'11px',paddingRight:'10px'}}>
                                                 {project.quarter}
                                                 </td>
                                                 <td style={{fontSize:'11px'}} className={`status-val-${project.risk_level&&project.risk_level.value?project.risk_level.value:0}`}>
                                                 {project.risk_level&&project.risk_level.label?project.risk_level.label:''}
                                                 </td>
                                                 </tr>
                                              </tbody>
                                              </table>    
                                        </div>
                                        <Link to={'/technical/project/view/'+project.project_slug}>View Project</Link>
									</div>
								</div>
							</div>
							) : null
						}
				 </div>
			 </div>
      )
    }
  }
