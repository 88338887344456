import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getLocalStorageInfo, getGlobalCookie } from '../../../utils'
export default class SelectLessor extends Component {
  constructor(props){
    super(props);
    this.state = {
        activeTab: "0",
        userInfo: {
            email:'',
            password: '',
        },
        lessorId: ''
    };
  }

  signupUser = (e) => {
      e.preventDefault();
      if(this.state.lessorId == ''){
        this.setState({
          selectLessor: 'Please select lessor'
        })
      }else{
        this.setState({
          selectLessor: ''
        })
        this.props.authorization(getLocalStorageInfo().lessor_list.filter(item => item.id == this.state.lessorId)[0])
      }
  }

  selectLessor = (lessor) =>{
    this.setState({
      lessorId: lessor.id
    })
  }

  render(){
    const { userInfo } = this.state;
    const lessors = getLocalStorageInfo() ? getLocalStorageInfo().lessor_list.map(item => item.lessor_type && item.lessor_type.value == 3?{...item,
    name:item.owner.name}:item) : []
    return(
      <div className="login-form">
            <h2 className="form-title">You are signed in to SPARTA!</h2>
            <p className="form-subtitle">Please choose an account to proceed</p>
            <div>
                <form onSubmit={this.signupUser}>
                    <Row>
                        <Col md="12">
                            <label>Select Lessor/Inspector Account to access</label>
                            <Select
                                name="form-field-name"
                                value={lessors.filter(item => item.id == this.state.lessorId)[0]}
                                onChange={(evt) => this.selectLessor(evt)}
                                options={lessors}
                                placeholder = 'Select account'
                                className=""
                                labelKey = "name"
                                valueKey = "id"
                            />
                          {this.state.selectLessor ? <h6 className="error-msg">{this.state.selectLessor}</h6> : null }
                        </Col>
                        <Col md="12" className="text-center">
                            <input type="submit" value={this.props.loginLoader ? "Processing..." : "Continue"}/>
                        </Col>
                    </Row>
                </form>
            </div>
      </div>
    )
  }
}
