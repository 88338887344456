
import React, { Component } from 'react';
import { Link } from 'react-router';
import {  FieldGroup, FieldLabel, FieldTextarea,  FieldInput } from '../../contracts/Elements';
import {  fieldDateFormat, backendDateFormat, imgStoragePath } from '../../../constants';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-select/dist/react-select.css';
export default class FleetSimHeading extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false,
      filter_type: this.props.cashSimulationFilter.filter_type,
      startDate: this.props.cashSimulationFilter.startDate,
      endDate: this.props.cashSimulationFilter.endDate
    }
    this.toggle = this.toggle.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,

    });
  }

  updateField = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: value,
    }))
    this.props.updateField(type, value);
  }


  render(){
    const { cashSimulationFilter, cashSimulation } = this.props;
    return(
      <div className="simulation-header" style={this.props.style}>

      <h3 className="gama">
       <Select
          name="form-field-name"
          className="custom-select-block"
          value= {cashSimulationFilter.filter_type}
          options = {[{value:'cash_inflow', label:'Lessor Revenues By Aircraft'}, {value:'cash_outflow', label:'Lessor Expense By Aircraft'}, {value:'cumulative_cashflow', label:'Cumulative Cash Flow Summary of Assets'},  {value:'halflife_main', label:'Value Adjustment From Half-Life'}, {value:'eol_compensation', label:'EOL Compensation'}]}
          labelKey="label"
          valueKey="value"
          onChange = {(e) => this.updateField('filter_type',e.value)}
        />
      <span onClick={() => this.props.exportCashFlow(cashSimulation.portfolio, this.state.filter_type, this.state.startDate, this.state.endDate,  'xls')} style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px', position: 'absolute', right:'0', top:'10px', color: '#2f8cff'}}><img width="18" src={imgStoragePath+"export_icon.png"} alt="export" /> Export</span>
      </h3>
      {
         cashSimulationFilter.filter_type != "eol_compensation" ?
         <div className="flex-centered simulation-date-block">
            <FieldGroup className="form-group date-block">
              <FieldLabel className="label">From</FieldLabel>
                  <DatePicker
                      dateFormat = {fieldDateFormat}
                  selected={ cashSimulationFilter.startDate &&  moment(cashSimulationFilter.startDate).isValid() ? moment(cashSimulationFilter.startDate): null }
                  onChange={(e) => this.updateField('startDate', moment(e).format(backendDateFormat))}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  minDate= {cashSimulation.minDate ? moment(cashSimulation.minDate) : moment()}
                  maxDate={cashSimulation.maxDate ? moment(cashSimulation.maxDate) : moment()}
                  onFocus={() => this.handleFocus()} onBlur={() => this.handleBlur()} /></FieldGroup>


            <FieldGroup className="form-group  date-block">
              <FieldLabel className="label">To</FieldLabel>
                  <DatePicker dateFormat = {fieldDateFormat}
                      selected={ cashSimulationFilter.endDate &&  moment(cashSimulationFilter.endDate).isValid() ? moment(cashSimulationFilter.endDate): null }
                  onChange={(e) => this.updateField('endDate',moment(e).format(backendDateFormat))}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  minDate= {cashSimulation.minDate ? moment(cashSimulation.minDate) : moment()}
                  maxDate={cashSimulation.maxDate ? moment(cashSimulation.maxDate) : moment()}
                  onFocus={() => this.handleFocus()}
                  onBlur={() => this.handleBlur()}
                  />
              </FieldGroup>
           <div>
             <Link className="update-list" style={moment(this.state.startDate).isValid() && moment(this.state.endDate).isValid() ? {opacity: 1} : {opacity: 0.5, cursor:'not-allowed'}}  onClick={() => this.props.updateField('submit', cashSimulationFilter)}>Update Listing</Link>
            </div>
           <div>
         </div>
       </div>
       : null
      }
    </div>
    )
  }
}
