import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { AssemblySideBar, UpcomingNotFound, EventsHeader, EventItemRow } from '../../Elements';
import { ListLoader, NoRecordFound, LicenceModal } from '../../../../shared';
import { BUY_LICENCE } from '../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../utils'
import { imgStoragePath } from '../../../../constants'
import { getMaintenanceUsageAc } from '../../AirframeAssembly/actionCreators'
import { EditSidebar, ShortHead, ContractHeadertext, ContractBarLoader } from '../../../contracts/Elements';
import { lgUpcomingEventsAc, lgEventHistoryAc, lgShopVisitCrudAc, addEditLgShopVisitAc } from '../actionCreators';
import UpcomingEvents from '../../AirframeAssembly/components/UpcomingEvents';
import EventHistory from '../../AirframeAssembly/components/EventHistory';
import AddEditShopVisit from '../../AirframeAssembly/components/AddEditShopVisit';
import { eventTypeAc, assetInfoAc } from '../../AirframeAssembly/actionCreators';
import Switcher from '../../../../shared/components/Switcher';
import { LG_SHOP_VISIT_CRUD } from '../actions';
import { assetsType } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { trackActivity } from '../../../../utils/mixpanel';
class LandingGearAssembly extends Component{
  constructor(props){
    super(props);
    this.state = {
      activeTab:"1",
    };
  }

  shopVisitCrud = (type, id) =>{
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
      if(this.props.maintenanceUsage.available == this.props.maintenanceUsage.used){
        if(this.props.maintenanceUsage.available!=0 && !this.props.maintenanceUsage.is_new){
          this.props.shopVisitCrud(type, id)
        }else{
        this.props.buyLicence({
          flag: true,
          action: '/licenses/maintenance',
          content: 'License to add maintenance event is expired.'
        })
        return;
      }
    }
    }
    this.props.shopVisitCrud(type, id)
  }

  componentDidMount(){
    this.props.upcomingEvents();
    this.props.eventHistoryList();
    this.props.eventType();
    this.props.fetchAssetInfo();
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
			this.props.getMaintenanceUsage()
		}
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Major Assemblies LG',
      item_type: 'Event Listing'
    })
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/'+(evt.asset_type == '1' ? 'landing-gears':'engines');
    browserHistory.push(url);
    if(evt.asset_type == '1'){
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.componentDidMount();
    }
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render(){
    const { lgEventList, lgEventHistory, lgShopVisit, eventTypes, assetInfoObj, lgLoader } = this.props;
    return(
      <div className="mr-rate-wrapper maintainance-container" style={{ marginLeft: '215px',background: '#f0f2f3', marginBottom: '40px', marginTop: '94px'}}>
        {lgLoader ? <ListLoader />:null }
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} changeMsn={this.changeMsn} headingText={'Maintenance' && <Switcher app="Maintenance"   />} />:<ContractBarLoader />
        }
        <AssemblySideBar params={this.props.params} />
        <div className="mr-rate-inner-wrapper">
        <div className="assembly-view-layout">
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggleTab('1'); trackActivity('Item Clicked', { page_title: 'Major Assemblies LG', application_module: 'Maintenance', item_type:'Shop Visits History Tab' })}}
                >
                  Shop Visits History
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggleTab('2'); trackActivity('Item Clicked', { page_title: 'Major Assemblies LG', application_module: 'Maintenance', item_type:'Upcoming Shop Visits Tab' }) }}
                >
                  Upcoming Shop Visits
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {
                getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.landing_gears && getLocalStorageInfo().user.permission.maintenance.landing_gears.indexOf('C') !== -1 ?
                <ContractHeadertext
                  title=""
                  desc="All major shop visits performed on Landing Gears"
                  addNew = "+ Add Shop Visit"
                  toggleAddEvent={() => this.shopVisitCrud('add', {})}
                 />
                :<ContractHeadertext
                  title=""
                  desc="All major shop visits performed on Landing Gears"
                 />
              }
              <div className="maintainenace-listing-blk" style={{marginBottom:'60px'}}>
                <div className="table-container">
                 <table>
                   <EventsHeader eventType="Shop Visit" />
                   <tbody>
                     { lgEventHistory && lgEventHistory.length ? lgEventHistory.map((shopVisit,index) =>
                       <EventItemRow previewDetail={() => browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/maintenance/landing-gears/view/${shopVisit.id}`)} params={this.props.params} assemblyType="landing-gears" key={index} item={shopVisit} />) : null
                     }
                   </tbody>
                 </table>
                </div>
                {!lgLoader && lgEventHistory && !lgEventHistory.length ? <NoRecordFound description="Get Started by clicking '+ Add Shop Visit' button above"/>:null}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <ContractHeadertext
                title=""
                desc="Projected upcoming shop visits based on data available for Landing Gears"
               />
             <div className="assembly-timelinse-list">
               {lgEventList && lgEventList.length ? lgEventList.map((event,index) => <UpcomingEvents key={index} event={event}/>) : null}
             </div>
             {!lgLoader && lgEventList && !lgEventList.length ? <UpcomingNotFound />:null}
            </TabPane>
          </TabContent>
        </div>
        </div>
        <EditSidebar
          title={
            lgShopVisit.type != '' ?
            (lgShopVisit.type == 'add') ? 'Add Shop Visit for LG':
            (lgShopVisit.type == 'edit') ? 'Edit Shop Visit for LG': 'View Shop Visit for LG' : ''
            }
          editSideBar={(lgShopVisit.flag && lgShopVisit.type == 'add')}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
          >
          {
            Object.keys(lgShopVisit.data).length && lgShopVisit.flag && lgShopVisit.type == 'add' ?
            <div>
              {
                lgShopVisit.type != 'add' ?
                <span onClick={()=>this.props.changeViewMode(lgShopVisit.type)} style={{position: 'absolute', right: '50px', top: '26px', cursor: 'pointer'}}>
                  { lgShopVisit.type == 'view' ?
                    <img className="blue-icon" src={imgStoragePath+"view_icon_blue"} width="16" alt="icon" /> :
                    <img className="blue-icon" src={imgStoragePath+"icon_editblock.png"} width="12" alt="icon" />
                  }
                </span>:null
              }
              <AddEditShopVisit
                type={lgShopVisit.type}
                shopVisitObj={lgShopVisit.data}
                eventTypes={eventTypes}
                addEditShopVisit={(data, type)=>this.props.addEditShopVisit(data, type, assetInfoObj)}
                assemblyType="lg"
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
              />
            </div>:null
          }
        </EditSidebar>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="7"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  lgEventList: state.LangingGearAssemblyReducer.lgEventList,
  lgEventHistory: state.LangingGearAssemblyReducer.lgEventHistory,
  lgShopVisit: state.LangingGearAssemblyReducer.lgShopVisit,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  lgLoader: state.LangingGearAssemblyReducer.lgLoader,
  maintenanceUsage: state.AirframeAssemblyReducer.maintenanceUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
});

const mapStateToDispatch = (dispatch, ownProps) => {
  return {
    upcomingEvents : () => dispatch(lgUpcomingEventsAc(ownProps.params)),
    eventHistoryList: () => dispatch(lgEventHistoryAc(ownProps.params)),
    addEditShopVisit: (data, type, assetInfoObj) => dispatch(addEditLgShopVisitAc(ownProps.params, data, type, assetInfoObj)),
    shopVisitCrud: (type, id) => dispatch(lgShopVisitCrudAc(ownProps.params, type, id)),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'lg')),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params, 'LG Maintenance')),
    toggleEditBar: (data) => dispatch({
      type: LG_SHOP_VISIT_CRUD,
      payload: data
    }),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getMaintenanceUsage: () => dispatch(getMaintenanceUsageAc(ownProps.params))
  }
};
export default connect(mapStateToProps, mapStateToDispatch)(RoleBasedAccess(LandingGearAssembly, ['maintenance','landing_gears','R']));
