import * as actions from '../actions';
import { toastFlashMessage } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';

export const fetchGapReportDetailsAc = (props) => {
	return (dispatch) => {
        dispatch(triggerLoader(true));
		globalGetService('/technical/engine-workorder/'+props.params.workOrderSlug+'/gap-report-sections/', {})
		.then(response => {
            dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_GAPREPORT_INV,
					payload: response.data.data
				})
			}
		})		
	}
}


export const removeGapReportDetAc = (props, compoIndex, componentId,section) => {
	return (dispatch) => {
		if(!componentId){
			dispatch({
				type: actions.DELETE_GAP_COMPONENT,
				payload: {index:compoIndex,sectionType:section}
			})
		}else{
				dispatch(triggerLoader(true));
				globalDeleteService('technical/engine-workorder/'+props.params.workOrderSlug+'/gap-report/'+componentId+'/delete/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						dispatch(fetchGapReportDetailsAc(props));
						// toastFlashMessage(response.data.message, 'success');
					}
				})
			}
		}
	}

export const updateGapReportDetailsAc = (props,data) => {
	return (dispatch) => {
        dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/'+props.params.workOrderSlug+'/gap-report/', {data:data})
		.then(response => {
            dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
			 toastFlashMessage(response.data.message, 'success');
			 trackActivity('Gap Report Saved', {
				page_title: `TE003`,
				application_module: 'Inspection',
				workorder_slug: props.params.workOrderSlug,
				form_slug: props.params.formSlug,
			  })
			 dispatch(fetchGapReportDetailsAc(props));
				/*dispatch({
					type: actions.GET_POWERPLANT_INV,
					payload: response.data.data
				})*/
			}
		})		
	}
}

export const triggerLoader = (flag) => {
    return (dispatch) => {
		dispatch({
			type: actions.GAPREPORT_DETLS_LOADER,
			payload: flag
		});
    }
  }