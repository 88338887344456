import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
import { checkNumber } from '../../../../formValidator';


export default class EditEngineAsy extends Component {
  constructor(props){
    super(props);
    this.state = {
      engineLLP: {},
      error: {},

    }
  
  }
  componentDidMount(){
    this.setState({
      engineLLP: this.props.engineLLpCrudObj
    });
  }



  updateForm = (type, value) => {
  
    this.setState(prevState => ({
      ...prevState,
      engineLLP: {
        ...prevState.engineLLP,
        [type]: value,
      },
      error: {
        ...prevState.error,
        [type]: '' 
      }
    }))
  }

  editLLP = (e) => {
    e.preventDefault();
    const validateNewInput = {
      cycles_remaining: checkNumber({value: (this.state.engineLLP.cycles_remaining.toString()).trim(), required: '', minLength: '', maxLength: '', message: 'Please enter valid  Cycles Remaining'}),
      list_price: checkNumber({value: (this.state.engineLLP.list_price.toString()).trim(), required: '', minLength: '', maxLength: '', message: 'Please enter valid price'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.editLLP(this.state.engineLLP, 'engineLLPList');
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }


  render() {
    const {  type } = this.props;
    const { engineLLP, error } = this.state;
    if(!Object.keys(engineLLP).length){
      return <h4 className="text-center">Loading ...</h4>
    }
    return (
      <form onSubmit={(e) => this.editLLP(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
          <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Cycles Remaining
                </FieldLabel>
                <FieldInput
                  value={engineLLP.cycles_remaining}
                  type={type}
                  keyParam="cycles_remaining"
                  updateField={this.updateForm}
                />
                 <h6 className="error-msg">{error.cycles_remaining}</h6>
 
              </FieldGroup>
            </FieldCol>

              <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Price
                </FieldLabel>
                <FieldInput
                  value={engineLLP.list_price}
                  type={type}
                  keyParam="list_price"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.list_price}</h6>

              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Part Number
                </FieldLabel>
                <FieldInput
                  value={engineLLP.part_number}
                  type="view"
                  keyParam="part_number"
                  updateField={this.updateForm}
                />

              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Serial Number
                </FieldLabel>
                <FieldInput
                  value={engineLLP.serial_number}
                  type="view"
                  keyParam="serial_number"
                  updateField={this.updateForm}
                />

              </FieldGroup>
            </FieldCol>

             <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                   Module
                </FieldLabel>
                <FieldInput
                  value={engineLLP.module}
                  type="view"
                  keyParam="module"
                  updateField={this.updateForm}
                />

              </FieldGroup>
            </FieldCol>

             <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Description
                </FieldLabel>
                <FieldInput
                  value={engineLLP.description}
                  type="view"
                  keyParam="description"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                CSN
                </FieldLabel>
                <FieldInput
                  value={engineLLP.csn}
                  type="view"
                  keyParam="csn"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Cycles Max Limit
                </FieldLabel>
                <FieldInput
                  value={engineLLP.csn}
                  type="view"
                  keyParam="cycles_max_limit"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Remarks
                </FieldLabel>
                <FieldTextarea
                  value={engineLLP.remarks}
                  type="view"
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

        
          </Row>
        </div>
        { type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
