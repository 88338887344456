export const FORM_SUBMIT_LOADER = 'FORM_SUBMIT_LOADER';
export const CLOSE_AVIONIC_SIDE_BAR = 'CLOSE_AVIONIC_SIDE_BAR';
export const OVERVIEW_TECH_INFO = 'OVERVIEW_TECH_INFO';
export const LLP_VIEW_INFO = 'LLP_VIEW_INFO';
export const LLP_EDIT_SUCCESS = 'LLP_EDIT_SUCCESS';
export const AIRCRAFT_MAIN_PROG = 'AIRCRAFT_MAIN_PROG';
export const TECH_SMRY_LIST = 'TECH_SMRY_LIST';
export const TECH_SMRY_ADD_SUCCESS = 'TECH_SMRY_ADD_SUCCESS';
export const TECH_SMRY_EDIT_SUCCESS = 'TECH_SMRY_EDIT_SUCCESS';
export const TECH_SMRY_CRUD = 'TECH_SMRY_CRUD';
export const TOGGLE_TECH_SMRY_VIEW = 'TOGGLE_TECH_SMRY_VIEW';
export const TECHSPEC_LOADER = 'TECHSPEC_LOADER';
export const LLP_UPDATE_FIELD_TOGGLE = 'LLP_UPDATE_FIELD_TOGGLE';
export const LLP_EXPORT = "LLP_EXPORT";
export const GET_ENGINE_DETAILS = 'GET_ENGINE_DETAILS';
export const UPDATE_ENGINE_DETAILS = 'UPDATE_ENGINE_DETAILS';
export const PROPELLERS_LIST = 'PROPELLERS_LIST';
export const ASSET_TRANSFER_MODAL = 'ASSET_TRANSFER_MODAL';
export const ASSET_TRANSFER_USAGE = 'ASSET_TRANSFER_USAGE';
export const APU_DETAIL = 'APU_DETAIL';
export const UPDATE_IMPORT_LLP = 'UPDATE_IMPORT_LLP';
export const REMARKS_LIST = 'REMARKS_LIST';
export const REMARKS_CRUD = 'REMARKS_CRUD';
export const IMPORT_APU_LLP = 'IMPORT_APU_LLP';
export const IMPORT_LG_LLP = 'IMPORT_LG_LLP';
export const IMPORT_PROPELLER_LLP = 'IMPORT_PROPELLER_LLP';
export const TECHSPEC_APU_LOADER = 'TECHSPEC_APU_LOADER';
export const REVISION_HISTORY_TECHSPEC = 'REVISION_HISTORY_TECHSPEC';
