import React from 'react';
import { Row, Col } from 'reactstrap';
import { SortableContainer } from 'react-sortable-hoc';
import MiniPackFileList from './MiniPackFileList'
class SortableFilesContainer extends React.Component {
    constructor(props){
      super(props);
    }
    render(){
      const { activityOp } = this.props;
      return(
        <div className="image-row" style={this.props.style} >
          <ul className="list-inline flex-centered">
            { !this.props.resourceLoader ? this.props.list.map((item, index) =>
              <MiniPackFileList
                key={item.uuid}
                index={index}
                recycleBin = {false}
                dataKit = {true}
                listItem={item}
                removeRecord={() => this.props.removeRecord(item, 'Remove')}
                restoreRecord={() => this.props.deleteRecord(item, 'Restore')}
                addToRecycle={() => this.props.addToRecycle(item)}
                recycleList={this.props.recycleList}
                fileIcons={this.props.fileIcons}
              />
              ):
              null
            }
          </ul>
        </div>
      )
    }
  }
export default SortableContainer(SortableFilesContainer);
