import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { OverViewUtilization, LandingGearTab, EditLandingGear, MjrAssShopVisitHeader, MjrAssShopVisitDetail, GenericAssemblyLLPs } from '../components';
import { fetchTechSummaryListAc, techSummaryCrudAc,addEditTechSummaryAc, deleteMsnAssetAc, exportLgLLpsAc, deleteLgLLPAc, importLgLLPsAc } from '../actionCreators';
import { TECH_SMRY_CRUD } from '../actions';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { EditSidebar, FieldSelect, FieldCol, FieldGroup, FieldLabel } from '../../../contracts/Elements'
import { sManufacturerAc } from '../../../../shared/actionCreators';
import { NoRecordFound  } from '../../../../shared';
import { Row, UncontrolledTooltip, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { addLg } from '../createTechSummary';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { getLocalStorageInfo } from '../../../../utils';
import { globalGetService, globalPostService } from '../../../../globalServices';
class LgContent extends Component{
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			activeTab: 1,
			positionError: '',
			engines: [],
			linkEnigne: {
				flag: false,
				engine: {
					stand_alone_link: true,
					stand_alone_asset_id: null,
					position: 1
				}
			},
			deleteEngine:{
				flag: false,
				engine: {}
			}
		}
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	getEnignesList = () =>{
		globalGetService(`console/stand-alone-asset/`, {asset_type: 'lg',aircraft_slug: this.props.asset_slug})
		.then(response => {
			if(response.data.statusCode === 200){
				this.setState({
					engines: response.data.data.lg
				})
			}
		})
	}

	linkEnigne = () =>{
		if(this.state.linkEnigne.engine.stand_alone_asset_id === null || this.state.linkEnigne.engine.stand_alone_asset_id === ""){
			this.setState({error: 'Please select Landing Gear'})
		}
		if(this.state.linkEnigne.engine.position === null || this.state.linkEnigne.engine.position === ""){
			this.setState({positionError: 'Please select Landing Gear position'})
		}
		setTimeout(() => {
			if(this.state.error != '' || this.state.positionError != ''){
				return
			}else{
				setTimeout(() => {
					globalPostService(`console/aircraft/${this.props.asset_slug}/landing-gear/`, this.state.linkEnigne.engine)
					.then(response => {
						if(response.data.statusCode === 200){
							this.setState({linkEnigne: {flag: false, engine: {
								stand_alone_link: true,
								stand_alone_asset_id: null,
								position: 1
							}}})
							this.getEnignesList()
							this.props.fetchTechSummaryList(this.props.asset_slug, 'lgList');
						}
					})
				}, 500)
			}
		}, 300)
	}

	updateEngineField = (key, value) => {
		this.setState({
			error: '',
			positionError: ''
		})
		this.setState({
			linkEnigne:{
				...this.state.linkEnigne,
				engine: {...this.state.linkEnigne.engine, [key]: value}
			}
		})
	}

	deleteMsnEngine = () =>{
		this.props.deleteMsnEngine(this.state.deleteEngine.engine.id)
		this.setState({
			deleteEngine: {flag: false, engine:{}}
		})
	}

	componentDidMount() {
		if(!this.props.lgManufacturerList.length){
			this.props.fetchManufacturer('landing_gear', 'lgManufacturerList');
		}
		this.props.fetchTechSummaryList(this.props.asset_slug, 'lgList');
		this.getEnignesList()
		this.props.getOwnerList()
	}
  render(){
	 const { lgList, lgCrud, lgManufacturerList, asset_slug, overviewTechInfo, techSpecsLoader, techConstants, ownerList  } = this.props;
	 var showTabs = false
	 let lessorIds = []
		if(window.location.href.includes('qa')){
			lessorIds = [241, 86]
		}else if(window.location.href.includes('dev')){
			lessorIds = [289,89]
		}else{
			lessorIds = [86]
		}
	 if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
		 showTabs = true
	 }
   return(
		 <div>
			 {
				 getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['landing_gears'].indexOf('C') != -1 ?
				 <p style={{overflow: 'hidden'}}>
				 <span style={{float: 'right'}}>
				 {
					 showTabs ?
					 <button style={{marginRight: '20px'}} className="btn btn-primary add-new" onClick={() => this.setState({linkEnigne: {flag: true, engine: {stand_alone_link: true,
					stand_alone_asset_id: null,
					is_fitted: true,
					is_titled: false,
					position: 1}}, error: '', positionError: ''})}> + Link Landing Gear</button>
					 :null
				 }
					{!showTabs && <button className="btn btn-primary add-new" onClick={() => this.props.techSummaryCrud('add', {}, 'lgCrud',addLg)}> + Add Landing Gear</button>}
					</span>
				 </p>:null
			 }
			 {lgList.length ?
				 <Nav tabs>
					 {lgList.map((landingGear, index) =>
						 <NavItem key={index}>
							 <NavLink
								 className={classnames({ active: this.state.activeTab === (parseInt(index)+1) })}
								 onClick={() => { this.toggle((parseInt(index)+1)); }}
								 >
								 LG {landingGear.position ? '#' + ( techConstants.filter(item => item.type=="lg_position" && item.value == landingGear.position).length  ? techConstants.filter(item => item.type=="lg_position" && item.value == landingGear.position)[0].label: '') : '' } ( {(landingGear.serial_number ? landingGear.serial_number : null )} )
								 <p>
									 {landingGear.is_titled ?
										 <label className="titled-fitted">Titled</label>:
											 landingGear.is_fitted ?
										 <label className="titled-fitted">Fitted</label>:
										 null
									 }
								 </p>
							 </NavLink>
						 </NavItem>
					 )}
				 </Nav>:
				 null
			 }
			 <TabContent activeTab={this.state.activeTab}>
			 { lgList.map((landingGear, index) =>
				<TabPane key={parseInt(index)} tabId = {parseInt(index)+ 1}>
				 <div key={index}>
					 <LandingGearTab techConstants={techConstants} deleteMsnEngine={() => this.setState({deleteEngine: {flag: true, engine: landingGear}})} showTabs={showTabs} asset_slug={asset_slug} index={index} landingGear={landingGear} viewLg={this.props.techSummaryCrud} techConstants={techConstants} />
					 <OverViewUtilization index={index} utilization={landingGear} refresh={() => this.componentDidMount()} />
					 <GenericAssemblyLLPs
						 	llp={landingGear.llps}
							assemblyType='lg'
							assemblySlug={landingGear.slug}
							heading='landing Gear'
							assembly={landingGear}
					 />


					 <div className="contr-table-blk">
              <h5 style={{ borderBottom: '0'}}>Shop Visits History</h5>
							<div className="mobile-table-format">
								<MjrAssShopVisitHeader theads = {[{ label: 'For Shop Visit / Shop Visit Date', size: 2 }, { label: 'Shop Visit Cost', size: 2}, { label: 'Shop Visit Facility/ Location', size: 2}, { label: 'TSN at Shop Visit', size: 2}, { label: 'CSN at Shop Visit', size: 2},  {label: 'Status', size: 2}]}/>
									{
										landingGear.shopvisit && Object.keys(landingGear.shopvisit).length ?
										landingGear.shopvisit.map((item,index) =>
											<MjrAssShopVisitDetail shopvisit = {item} key={index}/>
										) : null
									}
								</div>
                {!techSpecsLoader && (!landingGear.shopvisit || !Object.keys(landingGear.shopvisit).length) ?
                        <NoRecordFound description="" /> :  null
                  }
            </div>

				 </div>
				 </TabPane>
 		   )}
			 </TabContent>
			 {!techSpecsLoader && !lgList.length ?
			  	<NoRecordFound description="Get Started by clicking '+ Add Landing Gear' button above" /> :  null
			 }
			 <EditSidebar title={lgCrud.type == 'add' ? 'Add Landing Gear' : 'Edit Landing Gear'} width="800px" right= "-800px" editSideBar={lgCrud.flag} toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lgCrud')}>
				 {Object.keys(lgCrud.data).length  && lgCrud.flag ?
					 <EditLandingGear
						 showAdvanced={true}
						 asset_slug={asset_slug}
						 overview={overviewTechInfo}
						 techConstants={techConstants}
						 type={lgCrud.type}
						 manufacturerList={lgManufacturerList}
						 lgSummeryObj={lgCrud.data}
						 addEditTechSummary={this.props.addEditTechSummary}
						 toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lgCrud')}
						 ownerList={ownerList}
					/>
				 : 'Loading...'}
			 </EditSidebar>
			 <DeleteModal isOpen={this.state.linkEnigne.flag} toggle={() => this.setState({linkEnigne: {flag: false, engine: {stand_alone_link: true,
			 stand_alone_asset_id: null,
			 position: 1}}})} title="Link Landing Gear" className="modal-tform-delete-pictures">
				 <div>
				 <Row>
				 <FieldCol md="12">
					 <FieldGroup className="form-group">
						 <FieldLabel className="label">Landing Gear</FieldLabel>
							 <FieldSelect
								 placeholder='Select Landing Gear'
								 keyParam="stand_alone_asset_id"
								 value={this.state.linkEnigne.engine.stand_alone_asset_id}
								 type='edit'
								 options={this.state.engines}
								 updateField={this.updateEngineField}
								 labelKey= 'serial_number'
								 valueKey='id'
							 />
							 <h6 className="error-msg">{this.state.error}</h6>
						 </FieldGroup>
						</FieldCol>
				 <FieldCol md="12">
					 <FieldGroup className="form-group">
						 <FieldLabel className="label">Landing Gear Position</FieldLabel>
							 <FieldSelect
								 placeholder='Select Position'
								 keyParam="position"
								 value={this.state.linkEnigne.engine.position}
								 type='edit'
								 options={this.props.techConstants.filter(item => item.type === 'lg_position')}
								 updateField={this.updateEngineField}
								 labelKey= 'label'
								 valueKey='value'
							 />
							 <h6 className="error-msg">{this.state.positionError}</h6>
						 </FieldGroup>
						</FieldCol>
						<FieldCol md="6">
						<div className="form-group" style={{display: "block",margin: "25px 0px"}}>
							<label className="label">
								<input
									type="checkbox"
									checked={this.state.linkEnigne.engine.is_fitted}
									 onChange={(e) =>{ this.updateEngineField('is_fitted', e.target.checked);  setTimeout(() => this.updateEngineField('is_titled', false), 300)}}
								/>
								<span>
									Is Landing Gear fitted on this MSN ?
									<i class="fa fa-info-circle" aria-hidden="true" id="engine-field2" style={{paddingLeft: '4px'}}></i>
									<UncontrolledTooltip placement="right" target="engine-field2">
										Tick if the major assembly is temporarily installed
									</UncontrolledTooltip>
								</span>
							</label>

						</div>
							 </FieldCol>
						<FieldCol md="6">
						<div className="form-group" style={{display: "block",margin: "25px 0px"}}>
							<label className="label">
								<input
									type="checkbox"
									checked={this.state.linkEnigne.engine.is_titled}
									onChange={(e) =>{ this.updateEngineField('is_titled', e.target.checked);  setTimeout(() => this.updateEngineField('is_fitted', false), 300)}}
								/>
								<span>
									Is Landing Gear titled on this MSN ?
									<i class="fa fa-info-circle" aria-hidden="true" id="engine-field3" style={{paddingLeft: '4px'}}></i>
									<UncontrolledTooltip placement="right" target="engine-field3">
											Tick if the major assembly is Titled
									</UncontrolledTooltip>
								</span>
							</label>

						</div>
							 </FieldCol>
				 </Row>
				 </div>
				 <ul className="list-inline" style={{textAlign: 'right'}}>
						 <li className="list-inline-item">
							 <button type="button" className="btn btn-primary" onClick={() => this.linkEnigne()}>Add</button>
						 </li>
						 <li className="list-inline-item">
							 <button type="button" className="btn btn-secondary" onClick={() => this.setState({linkEnigne: {flag: false, engine: {
								 stand_alone_link: true,
								 stand_alone_asset_id: null,
								 position: 1
							 }}})}>Cancel</button>
						 </li>
				 </ul>
				 </DeleteModal>
				 <DeleteModal isOpen={this.state.deleteEngine.flag} toggle={() => this.setState({deleteEngine: {flag: false, engine: {}}})} title={`Remove Landing Gear ${this.state.deleteEngine.engine.serial_number}`} className="modal-tform-delete-pictures">
				 <ul className="list-inline" style={{textAlign: 'right'}}>
						 <li className="list-inline-item">
							 <button type="button" className="btn btn-danger" onClick={() => this.deleteMsnEngine()}>Yes</button>
						 </li>
						 <li className="list-inline-item">
							 <button type="button" className="btn btn-secondary" onClick={() => this.setState({deleteEngine: {flag: false, engine: {}}})}>Cancel</button>
						 </li>
				 </ul>
				 </DeleteModal>
		 </div>
	 )
	}
}
const mapStateToProps = state => ({
	lgManufacturerList: state.sharedReducers.lgManufacturerList,
	lgList: state.TechSummaryReducer.lgList,
	lgCrud: state.TechSummaryReducer.lgCrud,
	techConstants: state.sharedReducers.techConstants,
	ownerList:state.confReducer.ownerDropdownList
})
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		deleteMsnEngine: (engineId) => dispatch(deleteMsnAssetAc(ownProps.params,engineId, 'landing-gear')),
		getOwnerList:() =>  dispatch(getOwnerDropdownListAc()),
		fetchManufacturer: (group_type, reducer_type) => dispatch(sManufacturerAc(group_type, reducer_type)),
		fetchTechSummaryList: (asset_slug, type) => dispatch(fetchTechSummaryListAc(asset_slug, type)),
		addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
    techSummaryCrud: (type, id, actionType, dataObject, index) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject, index)),
		exportLLPs:(lg_slug)=> dispatch(exportLgLLpsAc(ownProps.porams, lg_slug)),
		importLLPs:(file,lg_slug)=> dispatch(importLgLLPsAc(ownProps.params, file, lg_slug)),
		deleteLLP:(lg_slug, id) => dispatch(deleteLgLLPAc(ownProps.params, lg_slug, id)),
		toggleEditBar: (data, actionType) => dispatch({
      type:TECH_SMRY_CRUD,
      payload:data,
      actionType
    }),
	}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LgContent));
