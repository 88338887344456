export const GET_TECHNICAL_SPECS = 'GET_TECHNICAL_SPECS';
export const UPDATE_TECHNICAL_SPECS = 'UPDATE_TECHNICAL_SPECS';
export const TECHNICAL_SPECS_LOADER = 'TECHNICAL_SPECS_LOADER';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const REMOVE_COMPONENT = 'T005/REMOVE_COMPONENT';
export const ADD_SECTION = 'ADD_SECTION';
export const ADD_SUB_COMPONENT = 'ADD_SUB_COMPONENT';
export const T005_AIRCRAFT_MODEL = 'T005_AIRCRAFT_MODEL';
export const T005_OPERATOR_LIST = 'T005_OPERATOR_LIST';
export const T005_ENGINE_MODEL = 'T005_ENGINE_MODEL';
export const ADD_MAINTENANCE_CHECKS = 'T005/ADD_MAINTENANCE_CHECKS';
export const REMOVE_SUB_COMPONENT='REMOVE_SUB_COMPONENT';
