import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect } from '../../Elements';
import { checkString } from '../../../../formValidator';
export default class AddEditReturnCond  extends Component {
  constructor(props){
    super(props);
    this.state = {
      returnCond: {},
      error: {}
    }
  }
  componentDidMount(){
    let data  = Object.assign({}, this.props.returnCondObj);
    if(this.props.type != 'add'){
      data = {
        ...data,
        asset_conditions_for_contract_id: data.asset_conditions_for_contract.id,
      }
      delete data['asset_conditions_for_contract'];
    }
    this.setState({
      returnCond: data
    })
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      returnCond: {
        ...prevState.returnCond,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }
  addEditReturnCond = (e) => {
    e.preventDefault();
    const validateNewInput = {
      asset_conditions_for_contract_id: checkString({value: this.state.returnCond.asset_conditions_for_contract_id, required: true, minLength: '', maxLength: '', message: 'Please Select Clause'}),
      terms: checkString({value: this.state.returnCond.terms, required: true, minLength: '', maxLength: '', message: 'Please Enter The Terms'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditReturnCond(this.state.returnCond, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const {  type, contractCondn, returnCondLoader } = this.props;
    const { returnCond, error } = this.state;
    return (
      <form onSubmit={(e) => this.addEditReturnCond(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Clause
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Clause'
                  keyParam="asset_conditions_for_contract_id"
                  value={returnCond.asset_conditions_for_contract_id}
                  type={type}
                  options={contractCondn}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                  name = { Object.keys(returnCond).length && type != 'add' ? this.props.returnCondObj.asset_conditions_for_contract.name : ''}
                />
              <h6 className="error-msg">{error.asset_conditions_for_contract_id}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Terms 
                { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldTextarea
                  value={returnCond.terms}
                  type={type}
                  keyParam="terms"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.terms}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Exceptions</FieldLabel>
                <FieldTextarea
                  value={returnCond.exceptions_done}
                  type={type}
                  keyParam="exceptions_done"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={returnCond.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
            {
              type == 'view' ?
              <div className="form-group">
                <label className="label">Return Condition Flagged?</label>
                <h6>{this.state.returnCond.flags ? 'YES': 'NO'}</h6>
              </div>
              :
              <div className="form-group">
                <label className="label checkbox-block">
                  <input
                    type="checkbox"
                    value={this.state.returnCond.flags}
                    checked={this.state.returnCond.flags}
                    onChange={(e) => this.updateForm('flags', e.target.checked)}
                    style= {{ marginRight: '4px'}}
                  />
                  <span>Flag this Return Condition</span>
                </label>
              </div>
            }
             </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input disabled={returnCondLoader} type="submit" className="primary-btn" value={returnCondLoader ? 'processing...': 'Save Changes'} />
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
