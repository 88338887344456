import React, { Component } from 'react';
import { Link } from 'react-router';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';

export default class BulkOperation extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { generalDetails, error, detailErr } = this.props;
  return(
    <div className="bulk-operation">
        <Link>+ ADD ATTACHMENTS
          <input style={{cursor:'pointer'}} type="file" onChange={(e) =>  e.target.files && e.target.files.length ? this.props.GenUrlFn(e.target.files, 'attachment') : null}  onClick = {(e) => e.target.value = null} accept=".xls,.xlsx,application/pdf"/>
        </Link>
    </div>
    );
  }
}
