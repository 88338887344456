import React, { Component } from 'react';
import {Row, Col } from 'reactstrap';
import AppModuleWidget from './AppModuleWidget';
import NumericLabel from 'react-pretty-numbers';
import {  prettyNumberParamsDB, imgStoragePath } from '../../../constants';
import { getFileSize, showCurrencyFormat,getLocalStorageInfo } from '../../../utils'


const ApplicationDetail = (props) => {
	const { contractsAppWidget, maintenanceAppWidget, recordsAppWidget, technicalAppWiget } = props;
	return(
  	<Row className="subcomponent-detail-block">
	    <AppModuleWidget
	      classParam = 'paddingRight flex-not-centered'
	      appsModuleWidget={Object.keys(contractsAppWidget.data).length ? contractsAppWidget.data : ''}
	      imgsrc= {imgStoragePath+"contract.png"}
	      title="Contracts"
				assetParamOne={ Object.keys(contractsAppWidget.data).length && contractsAppWidget.data.totalPaidAmount_new ? contractsAppWidget.data.totalPaidAmount_new.length ?
					    contractsAppWidget.data.totalPaidAmount_new.map((value, index) =>
                <div>
                	<NumericLabel params={showCurrencyFormat(value.currency)}>{value.total_amount>= 0?value.total_amount:0}</NumericLabel>
                </div>
                ) : 0 : 0}
				assetParamTwo={Object.keys(contractsAppWidget.data).length && contractsAppWidget.data.pendingAmount_new ? contractsAppWidget.data.pendingAmount_new.length ?
					contractsAppWidget.data.pendingAmount_new.map((pendingValue, index) =>
					<div>
					<NumericLabel params={showCurrencyFormat(pendingValue.currency)}>{pendingValue.total_amount>= 0?pendingValue.total_amount:0}</NumericLabel>
					</div>
					)  :  0 :  0}
	      assetsCount = {Object.keys(contractsAppWidget.data).length && contractsAppWidget.data.assetsCount >= 0? contractsAppWidget.data.assetsCount : '-'}
				assetParamOneLabel = 'Total Paid Invoices as of today'
				assetParamTowLabel = 'Pending Invoice Payments'
				dbAppModLoader={contractsAppWidget.loader}
				url="/fleet/contracts/dashboard"
	      inactive = {getLocalStorageInfo().user.permission.contracts && Object.keys(contractsAppWidget.data).length && contractsAppWidget.data.enabled ? contractsAppWidget.data.enabled : false}
				toolTipText="Clicking here will direct you to the CONTRACTS Module dashboard which contains the fleet's financial and contractual information."
			/>
	    <AppModuleWidget
	      appsModuleWidget={ Object.keys(maintenanceAppWidget.data).length ? maintenanceAppWidget.data : ''}
	      classParam = 'paddingLeft paddingRight flex-not-centered'
	      imgsrc= {imgStoragePath+"empower.png"}
	      title="Maintenance"
	      assetParamOne={ Object.keys(maintenanceAppWidget.data).length && maintenanceAppWidget.data.upcomingEventCount >= 0 ? maintenanceAppWidget.data.upcomingEventCount : 0 }
	      assetParamTwo={ Object.keys(maintenanceAppWidget.data).length && maintenanceAppWidget.data.openClaims >= 0 ? maintenanceAppWidget.data.openClaims : 0}
	      assetsCount = { Object.keys(maintenanceAppWidget.data).length && maintenanceAppWidget.data.assetsCount >= 0 ? maintenanceAppWidget.data.assetsCount : '-'}
				assetParamOneLabel = 'Events approaching in next 6 months'
				assetParamTowLabel = 'Open Maintenance Claims'
				dbAppModLoader={maintenanceAppWidget.loader}
				url="/fleet/maintenance/dashboard"
	      inactive = { getLocalStorageInfo().user.permission.maintenance && Object.keys(maintenanceAppWidget.data).length && maintenanceAppWidget.data.enabled ? maintenanceAppWidget.data.enabled : false}
				toolTipText="Clicking here will direct you to the MAINTENANCE Module dashboard which provides the status of maintenance reserve claims and upcoming maintenance events."
			/>
	    <AppModuleWidget
	      appsModuleWidget={ Object.keys(recordsAppWidget.data).length ? recordsAppWidget.data : ''}
	      imgsrc= {imgStoragePath + "console.png"}
	      classParam = 'paddingLeft paddingRight flex-not-centered'
	      title="Records"
	      assetParamOne={ Object.keys(recordsAppWidget.data).length && recordsAppWidget.data.totalFiles >= 0 ? recordsAppWidget.data.totalFiles : 0 }
	      assetParamTwo={ Object.keys(recordsAppWidget.data).length && recordsAppWidget.data.totalSize >= 0 ? getFileSize(recordsAppWidget.data.totalSize) : 0}
	      assetsCount = { Object.keys(recordsAppWidget.data).length && recordsAppWidget.data.assetsCount >= 0 ? recordsAppWidget.data.assetsCount : '-'}
				assetParamOneLabel = 'Total Number of Files'
				assetParamTowLabel = 'Total Size of Repository'
				dbAppModLoader={recordsAppWidget.loader}
				url={getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id !== 348 ? "/fleet/records/dashboard" : 'assets-listing'}
	      inactive = { getLocalStorageInfo().user.permission.records && Object.keys(recordsAppWidget.data).length && recordsAppWidget.data.enabled ? recordsAppWidget.data.enabled : false}
				toolTipText="Clicking here will direct you to the RECORDS Module dashboard which provides status of the technical records available in the platform repository."
			/>
		 	<AppModuleWidget
	    	appsModuleWidget={Object.keys(technicalAppWiget.data).length ? technicalAppWiget.data : ''}
	      imgsrc= {imgStoragePath +"sparta.png"}
	      classParam = 'paddingLeft paddingRight flex-not-centered'
	      title="Technical"
	      assetParamOne={ Object.keys(technicalAppWiget.data).length && technicalAppWiget.data.total_projects >= 0 ? technicalAppWiget.data.total_projects : 0 }
	      assetParamTwo={ Object.keys(technicalAppWiget.data).length && technicalAppWiget.data.on_going_projects >= 0 ? technicalAppWiget.data.on_going_projects : 0}
	      assetsCount = { Object.keys(technicalAppWiget.data).length && technicalAppWiget.data.asset_count >= 0 ? technicalAppWiget.data.asset_count : '-'}
				assetParamOneLabel = 'Total Projects'
				assetParamTowLabel = 'On Going Projects'
	      inactive = {getLocalStorageInfo().user.permission.technical && Object.keys(technicalAppWiget.data).length ? technicalAppWiget.data.enabled:false}
				url="/fleet/technical/dashboard"
				dbAppModLoader={technicalAppWiget.loader}
				toolTipText="Clicking here will direct you to the TECHNICAL Module dashboard which provides status of the technical projects  delivered using the SPARTA platform."
			/>
		</Row>
	)
}
export default ApplicationDetail;
