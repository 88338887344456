import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class PrerequisiteModal extends Component {

	constructor(props){
		super(props);
	}


	

	render(){
		return(
			<div>
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
			  <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
			  <ModalBody>
					  <h1>{this.props.children}</h1>
			  </ModalBody>
			  <ModalFooter>
				<Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
				<Button color="secondary" onClick={this.toggle}>Cancel</Button>
			  </ModalFooter>
			</Modal>
		  </div>
		)
	}
}
