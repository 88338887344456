import React from 'react';
import { Col } from 'reactstrap';
const InvoiceConfigCol = (props) => {
  return(
    <Col md={props.size} className="invoice-inner-warp">
      <h6 className="caption-ui">
        {props.title}
      </h6>
      <span className="para-ui">
        {props.value}
      </span>
    </Col>
  )
}
export default InvoiceConfigCol;
