export const FORMS_REVIEW_MODEL_DATA = 'FORMS_REVIEW_MODEL_DATA';
export const FORMS_REVIEW_FEEDBACKS = 'FORMS_REVIEW_FEEDBACKS';
export const FORMS_REVIEW_FEEDBACKS_DETAIL = 'FORMS_REVIEW_FEEDBACKS_DETAIL';
export const CHANGE_ISSUE_STATUS = 'CHANGE_ISSUE_STATUS';
export const REVIEW_ADD_COMMENT='REVIEW_ADD_COMMENT';
export const TOOGLE_ADD_ISSUES='TOOGLE_ADD_ISSUES';
export const TOGGLE_DETAILS='TOGGLE_DETAILS';
export const TOOGLE_ISSUES_LIST='TOOGLE_ISSUES_LIST';
export const ADD_ISSUES_REVIEW='ADD_ISSUES_REVIEW';
export const UPDATE_FORMS_REVIEW_FEEDBACKS='UPDATE_FORMS_REVIEW_FEEDBACKS';
export const FORMS_REVIEW_FEEDBACKS_DELETE='FORMS_REVIEW_FEEDBACKS_DELETE';
export const REVIEW_UPDATE_COMMENT='REVIEW_UPDATE_COMMENT';
export const COMMENTS_LOADER='COMMENTS_LOADER';
export const NOTIFY_CLIENT='NOTIFY_CLIENT';
export const SELECT_REVIEW_TEXT='SELECT_REVIEW_TEXT';
export const EMPTY_SELECT_REVIEW_TEXT='EMPTY_SELECT_REVIEW_TEXT';
export const ISSUES_CRUD = 'ISSUES_CRUD';
export const ADD_ISSUE_COMMENTS = 'ADD_ISSUE_COMMENTS';
export const TOOGLE_EDIT_ISSUES='TOOGLE_EDIT_ISSUES';
export const UPDATE_EDIT_ISSUES='UPDATE_EDIT_ISSUES';
