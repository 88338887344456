import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
export default class OffWingServList extends Component{
  constructor(props){
    super(props)
  }
 render(){
  const { dataList, techConstants } = this.props;
  let data = dataList.off_wing_detail;
  return(
    <Row >
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Expected Date Of Installation On Wing"   text={data.expected_date_of_installation_on_wing}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="SV Performed" text={ data.shop_visit_performed ?  <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} alt="img" width="17"/>}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Shop Visit Report Status"   text={data.shop_visit_report_status && techConstants.length ? techConstants.find(item => item.type == 'sv_report_status' && item.value == data.shop_visit_report_status).label :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Asset Location"   text={data.off_wing_location}/>
    </Row>
  )
  }
}
