import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal, ModalBody,Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Popover, PopoverBody } from 'reactstrap';
import classnames from 'classnames';
import { imgStoragePath } from '../../../../constants';
import { AD_FILES_MODAL } from '../actions';
import { TableUIComp, ListLoader, SecondarySideBar, SecondaryHeader } from '../../../../shared';
import { adDataPointDetailAc, adDataPointsListAc, easaFaaExportAc,linkFilesToT011Ac,uploadOEMFileAc } from '../actionCreators';
import { localTimeFn } from '../../../../utils';
import { DataPointList, BulkOperation ,DragDropFiles,FilesUploaded} from '../components';
class AirworthinessView extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: '1',
      operatorFilter: {},
      oemFilter: {},
      file: [],
      easaFilter: {},
      filter: {}
    };
    this.toggle = this.toggle.bind(this);

  }
  componentDidMount(){
    this.props.adDataPointDetail();
    this.props.adDataPointsList({type: 'operator_status'}, 'initial');
    this.props.adDataPointsList({type: 'easa_faa_status'}, 'initial');
    this.props.adDataPointsList({type: 'oem_status'}, 'initial');
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  confirmModal = (file) => {
    let fileInfo = [];
    file.map(f => {
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        fileInfo.push({file: f, fileType: 'oem_uri' })
      }else{
        fileInfo.push({ file: f, error: true });

      }
    })
    this.setState(prevState => ({
      ...prevState,
      file: fileInfo,
    }));
    this.props.toggleViewModalFn(!this.props.modalStatus);
  }

  confirmClickModal = (file) => {
    if(!file.length){
      return false;
    }
    let fileInfo = [];
    for(var i=0; i<file.length; i++) {
      var f = file[i];
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        fileInfo.push({file: f, fileType: 'oem_uri'})
      }else{
        fileInfo.push({ file: f, error: 1 });
      }
    }

    this.setState(prevState => ({
      ...prevState,
      file: fileInfo
    }));
    this.props.toggleViewModalFn(!this.props.modalStatus);
  }


  // Table Filter and pagination Function
  changeFilterFn = (filter) => {
    if(this.state.activeTab == '1'){
      filter = {
        ...filter,
        type: 'operator_status'
      }
      this.setState({
        operatorFilter: filter
      });
    }else if (this.state.activeTab == '2') {
      filter = {
        ...filter,
        type: 'easa_faa_status'
      }
      this.setState({
        easaFilter: filter
      });
    }else{
      filter = {
        ...filter,
        type: 'oem_status'
      }
      this.setState({
        oemFilter: filter
      });
    }
    this.setState({filter})
    this.props.adDataPointsList(filter, 'later');
  }
  changePerPage=(count) => {
    let filter = {};
    if(this.state.activeTab == '1'){
      filter = this.state.operatorFilter;
      this.setState(prevState => ({
        ...prevState,
        operatorFilter: {
          ...prevState.operatorFilter,
          per_page: count
        }
      }))
    }else if (this.state.activeTab == '2') {
      filter = this.state.easaFilter;
      this.setState(prevState => ({
        ...prevState,
        easaFilter: {
          ...prevState.easaFilter,
          per_page: count
        }
      }))
    }else{
      filter = this.state.oemFilter;
      this.setState(prevState => ({
        ...prevState,
        oemFilter: {
          ...prevState.oemFilter,
          per_page: count
        }
      }))
    }
    filter = {
      ...filter,
      per_page: count,
      type: this.state.activeTab == '1' ? 'operator_status' : this.state.activeTab == '2' ? 'easa_faa_status': 'oem_status'
    }
    this.props.adDataPointsList(filter,'later');
  }
  sortTable = (sortKey) => {
    let filter = {};
    if(this.state.activeTab == '1'){
      filter = this.state.operatorFilter;
      this.setState(prevState => ({
        ...prevState,
        operatorFilter: {
          ...prevState.operatorFilter,
          sort: sortKey,
          page:1,
          per_page:10,
          sort_by: prevState.operatorFilter.sort && prevState.operatorFilter.sort ==  sortKey ? prevState.operatorFilter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }))
    }else if (this.state.activeTab == '2') {
      filter = this.state.easaFilter;
      this.setState(prevState => ({
        ...prevState,
        easaFilter: {
          ...prevState.easaFilter,
          sort: sortKey,
          page:1,
          per_page:10,
          sort_by:prevState.easaFilter.sort && prevState.easaFilter.sort ==  sortKey ? prevState.easaFilter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }))
    }else{
      filter = this.state.oemFilter;
      this.setState(prevState => ({
        ...prevState,
        oemFilter: {
          ...prevState.oemFilter,
          sort: sortKey,
          page:1,
          per_page:10,
          sort_by: prevState.oemFilter.sort && prevState.oemFilter.sort ==  sortKey ? prevState.oemFilter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }))
    }

    filter = {
      ...filter,
      sort: sortKey,
      page:1,
      per_page:10,
      sort_by: filter.sort && filter.sort ==  sortKey ? filter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc',
      type: this.state.activeTab == 1 ? 'operator_status' : this.state.activeTab == 2 ? 'easa_faa_status' : 'oem_status'
    }

    this.props.adDataPointsList(filter,'later');
  }

  deleteFn = (index) => {
    var file = this.state.file.filter(( obj, key ) => {
      return key !== index;
    });

    this.setState(prevState => ({
      ...prevState,
      file: file,
    }));

    if(this.state.file.length <= 1){
      this.props.toggleViewModalFn(!this.props.modalStatus);
    }
  }

  changePagination = (type, currentPage) => {
    let filter = {};
    if(this.state.activeTab == '1'){
      filter = this.state.operatorFilter;
    }else if (this.state.activeTab == '2') {
      filter = this.state.easaFilter;
    }else{
      filter = this.state.oemFilter;
    }
    filter = {
      ...filter,
      page: currentPage,
      type: this.state.activeTab == '1' ? 'operator_status' : this.state.activeTab == '2' ? 'easa_faa_status': 'oem_status'
    }
    this.props.adDataPointsList(filter, 'later');
  }
  uploadMLFile = (params, file) => {
    let submitFileinfo =  file.map(file => file.fileType == '' ? { ...file, error : 2 } : file)
    this.setState(prevState => ({
      ...prevState,
      file: submitFileinfo,
    }))
    if(!submitFileinfo.filter(obj => obj.error && obj.error == 2 ).length){
      if(file.length > 1 && !(file[0].fileType != '' && file[file.length - 1].fileType != '') && file[0].fileType == file[file.length - 1].fileType){
        this.props.uploadMLFile(params, file);
      }else{
        this.props.uploadMLFile(params, file);
      }
    }
  }

  render(){
    const menuList = {
      'AD Number': {
        'inputType': 'text',
        'keyParam': 'ad_name',
        'placeholder': 'Search by AD Number',
        'label': 'AD Number'
      },
      'Workorder Detail': {
        'inputType': 'text',
        'keyParam': 'work_order',
        'placeholder': 'Search by Workorder Detail',
        'label': 'Workorder Detail'
      },
      'EASA/FAA': {
        'inputType': 'dropdown',
        'keyParam': 'easa_faa',
        'placeholder': 'Search by EASA/FAA',
        'label': 'EASA/FAA',
        'options':[{label: 'EASA', value: 'EASA'}, {label: 'FAA', value:'FAA'}],
        'labelKey':'label',
        'valueKey':'value',
        'multi': false
      },
      'Operator Status': {
        'inputType': 'dropdown',
        'keyParam': 'operator_status',
        'placeholder': 'Search by Operator Status',
        'label': 'Operator Status',
        'options':[{label: 'Open', value: 'open'}, {label: 'Closed', value:'closed'}, {label: 'Superseded', value: 'superseded' }, {label:'Not Applicable', value: 'Not Applicable'}, {label: 'Repeat', value: 'repeat'}],
        'labelKey':'label',
        'valueKey':'value',
        'multi': false
      },
      'Select Assembly': {
        'inputType': 'dropdown',
        'keyParam': 'assembly',
        'placeholder': 'Search by Assembly',
        'label': 'Assembly',
        'options':[{label: 'Airframe', value: 'airframe'}, {label: 'Engine', value:'engine'}, {label: 'Appliance', value: 'appliance' }],
        'labelKey':'label',
        'valueKey':'value',
        'multi': false
      },
      'Publish Date' : {
        'inputType': 'date',
        'keyParam': 'publish_date',
        'placeholder': 'Search by Publish Date',
        'label': 'Publish Date'
      },
      'Updated Date' : {
        'inputType': 'date',
        'keyParam': 'issue_date',
        'placeholder': 'Search by Updated Date',
        'label': 'Updated Date'
      }
    }
    const { file, modal } = this.state;
    const {adModalStatuss, adReportDetail,adProgressPercentage, linkLoader, operatorDataPoints, oemDataPoints, easaAndFaaDataPoints, dataListLoader, adMLLoader, params } = this.props;
    return(
      <div className="">
        <SecondaryHeader assetInfo={adReportDetail.asset_detail ? adReportDetail.asset_detail:{}} />
        <SecondarySideBar />
        {linkLoader||dataListLoader || adMLLoader ? <ListLoader />:null}
        <div className="" style={{margin: '54px -15px 0 245px',  padding: '30px'}}>
        <div className="mluploaded-file data-log-list" style={{marginLeft: '0px'}}>
          <h4 className="title-back">
              <Link to={'/'+params.type + '/' + params.aircraft_slug + '/airworthiness-list'}>
                <img width="18" style={{marginRight:'10px'}} src={imgStoragePath + 'back_arrow.png'} alt="" />
                 All Logs
              </Link>
            </h4>
            { adReportDetail.files &&
                adReportDetail.files.length && adReportDetail.files.length < 2 ?
              <DragDropFiles
                confirmModal={this.confirmModal}
                oemUpload={true}
                confirmClickModal={this.confirmClickModal}
              />:null
            }
            </div>
          <div className="airworthiness-directive-view">
            <Row className="flex-centered ad-logs">
              { adReportDetail.files ?
                adReportDetail.files.map((file, index) =>
                <Col md="3" style={{marginRight:'10px'}} key={index}>
                  <a href={file.file_path} download>
                    <img width="16" src={imgStoragePath+'import_images_icons.png'} alt="" />
                  </a>
                  <div className="float-blk">
                    <h4>
                      <a href={file.file_path} className="break-words-all" download>
                        {file.name}
                      </a>
                    </h4>
                    <p>{file.label}</p>
                  </div>
                </Col>
              ):null}
              <Col md="3" style={{marginRight:'-20px'}}>
                <img
                  style={{width: '35px', height: '35px', borderRadius: '50%'}}
                  src={adReportDetail.uploaded_by && adReportDetail.uploaded_by.profile_pic ? adReportDetail.uploaded_by.profile_pic : imgStoragePath + 'user.png'} alt=""
                />
                <div className="float-blk user-info">
                  <h3>{adReportDetail.uploaded_by ? adReportDetail.uploaded_by.name: '--'}</h3>
                  <p>Uploaded on: {adReportDetail.updated_at ? localTimeFn(adReportDetail.updated_at): '--' }</p>
                </div>
              </Col>
              <Col md="3">
              {  adReportDetail.files &&
                adReportDetail.files.length && adReportDetail.files.length == 2 && adReportDetail.link_gap_report&&adReportDetail.link_gap_report && adReportDetail.is_gap_report && adReportDetail.form_slug ?
                 <div>
                 <Link style={{float:'right',fontSize:'12px',position: 'relative',right: '39px',top: '30px'}}to={'/aircraft/'+adReportDetail.asset_slug+'/technical/'+adReportDetail.work_order_slug+'/forms/'+adReportDetail.form_slug+'/t011'}>
                 <img width="13" style={{marginRight:'2px',marginTop:'5px'}} src={imgStoragePath+'blue_eye_icon.png'} alt="" />  view
                  </Link>
                </div>:null
              }
                {  adReportDetail.files &&
                adReportDetail.files.length && adReportDetail.files.length == 2 && adReportDetail.link_gap_report&&adReportDetail.link_gap_report?
                  <span style={{float:'right',right:'15px',padding:'5px 8px 5px 8px',fontSize:'11px',color:'#ffffff',backgroundColor:'#2F8CFE',boxShadow:'0 4px 6px rgba(0,0,0,0.2)',borderRadius:'.2rem',cursor:'pointer',opacity: adReportDetail.is_gap_report && adReportDetail.is_gap_report ?'0.5':'1'}} onClick={()=>adReportDetail.is_gap_report && adReportDetail.is_gap_report ?'':this.props.linkFilesToT011()}>{adReportDetail.is_gap_report && adReportDetail.is_gap_report ? 'Added to T011':'Add to T011' } </span>:null
                }
                <span><img width="15" src={imgStoragePath+ (adReportDetail.status && adReportDetail.status == 0 ? 'rolling.gif': 'file_completed.png')} alt="" />  {adReportDetail.status && adReportDetail.status == 0 ? 'Processing':'Processed' }  </span>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Nav tabs className="commons-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      In Operator AD status report ({adReportDetail.operator_status_count ? adReportDetail.operator_status_count: 0})
                      <img className="img_eye" onClick={() => this.setState({operatorAd: !this.state.operatorAd})} width="12" style={{ marginLeft: '5px'}} id="operatorAd"  src={ imgStoragePath + 'inv_info_icon.png'} alt="img"/>
                      <Popover placement ="bottom" isOpen={this.state.operatorAd} target="operatorAd" toggle={() => this.setState({operatorAd: !this.state.operatorAd})}>
                        <PopoverBody className="processing-popup">
            				        <p style={{fontSize: '12px'}}>
                                All the ADs parsed from the operator ad status report.
                            </p>
                        </PopoverBody>
                    </Popover>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      In EASA/FAA Database, not in Operator AD status report ({adReportDetail.EASA_status_count ? adReportDetail.EASA_status_count: 0})
                      <img className="img_eye" onClick={() => this.setState({easaFaa: !this.state.easaFaa})} width="12" style={{ marginLeft: '5px'}} id="easaFaa"  src={ imgStoragePath + 'inv_info_icon.png'} alt="img"/>
                      <Popover placement ="bottom" isOpen={this.state.easaFaa} target="easaFaa" toggle={() => this.setState({easaFaa: !this.state.easaFaa})}>
                        <PopoverBody className="processing-popup">
            				        <p style={{fontSize: '12px'}}>
                                This contains all the ADs marked as not_applicable as per the operator ad status report, but should be applicable according to FAA and EASA database.
                            </p>
                        </PopoverBody>
                      </Popover>
                    </NavLink>
                  </NavItem>
                  { adReportDetail.files &&
                 adReportDetail.files.length && adReportDetail.files.length == 2 ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}
                    >
                      In OEM AD status report not in Operator AD status report ({adReportDetail.OEM_status_count ? adReportDetail.OEM_status_count: 0})
                      <img className="img_eye" onClick={() => this.setState({oemAd: !this.state.easaFaa})} width="12" style={{ marginLeft: '5px'}} id="oemAd"  src={ imgStoragePath + 'inv_info_icon.png'} alt="img"/>
                    <Popover placement ="bottom" isOpen={this.state.oemAd} target="oemAd" toggle={() => this.setState({oemAd: !this.state.oemAd})}>
                        <PopoverBody className="processing-popup">
            				        <p style={{fontSize: '12px'}}>
                                This contains all the ADs marked as not_applicable as per the operator ad status report, but not present as not_applicable in OEM ad status report.
                            </p>
                        </PopoverBody>
                      </Popover>
                    </NavLink>
                  </NavItem>: null
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <TableUIComp
                      theads={[{label:'AD Number', sortKey: 'document_number'}, {label:'Assembly', sortKey: ''}, {label:'EASA/FAA', sortKey: ''},{label:'Operator Status', sortKey: ''}, {label:'Publish Date', sortKey: 'publish_date'},{label:'Updated Date', sortKey: 'issue_date'}, {label:'Workorder Detail', sortKey: ''}]}
                      bulkOperation={false}
                      tableBulkOp = {<BulkOperation easaFaaExport={(fileType) => this.props.easaFaaExport({...this.state.filter,download:fileType, type: 'operator_status'}, 'operator_status_report')} />}
                      filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
                      content={operatorDataPoints.list.map((item, index) => <DataPointList item={item} key={index} />)}
                      pagination = {operatorDataPoints.pagination}
                      changePerPage ={this.changePerPage}
                      changePagination = {this.changePagination}
                      changeFilterFn={this.changeFilterFn}
                      queryParamsEnabled={false}
                      sortTable={this.sortTable}
                      searchQuery =  {this.state.operatorFilter}
                      recordsFound = { adMLLoader ? false : operatorDataPoints.list.length ? false:true }
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <TableUIComp
                      theads={[{label:'AD Number', sortKey: 'document_number'}, {label:'Assembly', sortKey: ''}, {label:'EASA/FAA', sortKey: ''},{label:'Operator Status', sortKey: ''}, {label:'Publish Date', sortKey: 'publish_date'},{label:'Updated Date', sortKey: 'issue_date'}, {label:'Workorder Detail', sortKey: ''}]}
                      bulkOperation={false}
                      tableBulkOp = {<BulkOperation easaFaaExport={(fileType) => this.props.easaFaaExport({...this.state.filter, download:fileType, type: 'easa_faa_status'}, 'easa_faa_report')} />}
                      filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
                      content={easaAndFaaDataPoints.list.map((item, index) => <DataPointList item={item} key={index} />)}
                      pagination = {easaAndFaaDataPoints.pagination}
                      changePerPage ={this.changePerPage}
                      changePagination = {this.changePagination}
                      changeFilterFn={this.changeFilterFn}
                      queryParamsEnabled={false}
                      sortTable={this.sortTable}
                      searchQuery =  {this.state.easaFilter}
                      recordsFound = { adMLLoader ? false : easaAndFaaDataPoints.list.length ? false:true }
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <TableUIComp
                      theads={[{label:'AD Number', sortKey: 'document_number'}, {label:'Assembly', sortKey: ''}, {label:'EASA/FAA', sortKey: ''},{label:'Operator Status', sortKey: ''}, {label:'Publish Date', sortKey: 'publish_date'},{label:'Updated Date', sortKey: 'issue_date'},  {label:'Workorder Detail', sortKey: ''}]}
                      bulkOperation={false}
                      tableBulkOp = {<BulkOperation easaFaaExport={(fileType) => this.props.easaFaaExport({...this.state.filter, download:fileType, type: 'oem_status'}, 'oem_status_report')} />}
                      filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
                      content={oemDataPoints.list.map((item, index) => <DataPointList item={item} key={index} />)}
                      pagination = {oemDataPoints.pagination}
                      changePerPage ={this.changePerPage}
                      changePagination = {this.changePagination}
                      changeFilterFn={this.changeFilterFn}
                      queryParamsEnabled={false}
                      sortTable={this.sortTable}
                      searchQuery =  {this.state.oemFilter}
                      recordsFound = { adMLLoader ? false : oemDataPoints.list.length ? false:true }
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
          <Modal size="lg" centered={true} className="mluploaded-file" isOpen={adModalStatuss} toggle={this.props.toggleViewModalFn}>
              <ModalBody>
                <div>
                  <div className="upload-image-block">
                    <img src={imgStoragePath+"upload _arrow.png"} alt="img"/>
                    <span>You are uploading <b>{file ? file.length: 0}</b> File</span>
                  </div>
                  <div>
                    { file.map((fileDetail, index) =>
                      <FilesUploaded
                        key={index}
                        index={index}
                        fileDetail={fileDetail}
                        oemUpload={true}
                        replaceFile = {this.replaceFile}
                        updateField={this.updateForm}
                        deleteFn={this.deleteFn}
                        progressPercentage={adProgressPercentage}
                      />
                    )}
                  </div>
                  { file.length > 1 ? <p style= {{ color: '#ff0000', fontSize: '14px', marginRight:'6px' }}>You can upload only 1 file at a time</p> : file.length > 1 && (file[0].fileType != '' && file[file.length - 1].fileType != '' && file[0].fileType == file[file.length - 1].fileType) ? <p style= {{ color: '#ff0000', fontSize: '14px',  marginRight:'6px'  }}>Both File cannot be same Type</p> : ''}
                </div>
              </ModalBody>
              <div className="confirm-blk">
                <button className="primary-btn"  disabled = {(file.filter(obj => obj.error && ( obj.error == 1 || obj.error == 2) ).length || file.length > 1 || adProgressPercentage > 0 || (file.length > 1 && (file[0].fileType != '' && file[file.length - 1].fileType != '' && file[0].fileType == file[file.length - 1].fileType))) ? true : false }   onClick={() => this.uploadMLFile(this.props.params, file)}>Confirm</button>
                <span className="cancel-modal" onClick={this.props.toggleViewModalFn}>Cancel</span>
                <p className="cstm-progress-bar" style={{ width: adProgressPercentage+ '%', display: 'none' }}></p>
              </div>
            </Modal>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  adReportDetail: state.ADReducer.adReportDetail,
  operatorDataPoints: state.ADReducer.operatorDataPoints,
  oemDataPoints: state.ADReducer.oemDataPoints,
  easaAndFaaDataPoints: state.ADReducer.easaAndFaaDataPoints,
  dataListLoader: state.ADReducer.dataListLoader,
  adMLLoader: state.ADReducer.adMLLoader,
  linkLoader: state.ADReducer.linkLoader,
  adModalStatuss: state.ADReducer.adModalStatus,
  adProgressPercentage: state.ADReducer.adProgressPercentage,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleViewModalFn: () => dispatch({
      type: AD_FILES_MODAL,
      payload: ''
    }),
    adDataPointDetail: () => dispatch(adDataPointDetailAc(ownProps)),
    linkFilesToT011: () => dispatch(linkFilesToT011Ac(ownProps)),
    uploadMLFile: (params, file) => dispatch(uploadOEMFileAc(params, file)),
    adDataPointsList: (filter, type) => dispatch(adDataPointsListAc(ownProps, filter, type)),
    easaFaaExport: (query={}, fileName) => dispatch(easaFaaExportAc(ownProps, query, fileName))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AirworthinessView);
