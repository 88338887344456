import * as actions from '../actions';
import moment from 'moment';
export default function (state = {
  halfLifeList: {},
  eventDetail: {
    flag: false,
    type: '',
    data: {
      country: 'india'
    }
  },
  halfLifeLoader: true,

}, action){
  switch (action.type) {
    case actions.HALF_LIFE_LIST:
      return {
        ...state,
        halfLifeList: action.payload
      }
     case actions.EVENT_COND_CRUD:
      return {
        ...state,
        eventDetail: action.payload
      }
      case actions.EVENT_VIEW:
      return {
        ...state,
        eventDetail: action.payload
      }
      case actions.HALF_LIFE_MAINT_LOADER:
      return {
        ...state,
        halfLifeLoader: action.payload
      }
    default:
      return state
  }
}
