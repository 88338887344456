import * as actions from '../actions';
const crudObj = {
  type: '',
  flag: false,
  data: {}
};
export default function( state = {
  formT003FormDataList: [],
  auditLoader: false,
  editSideBar:false,
  // auditLinkrecords: crudObj,
  auditLinkrecords: []
}, action ){
  switch (action.type) {
    case actions.AUDIT_SECTION_LIST:
      return {
        ...state,
        formT003FormDataList: action.payload
      }
    case actions.STATUS_UPDATE:
       return {
         ...state,
          formT003FormDataList: state.formT003FormDataList.map((auditSection,index) => index == action.payload.index ? {
          ...auditSection, 
          sub_sections: auditSection.sub_sections.map((sections, childIndex) => childIndex == action.payload.childIndex ? {
          ...sections,
          questions:sections.questions.map((list, listIndex) => listIndex == action.payload.listIndex ? {
            ...list,
            answer: {
              ...list.answer,
              [action.payload.key]:action.payload.value
            }
          }:list)
        }:sections )}:auditSection)
      }
    case actions.LIST_UPDATE:    
      return {
        ...state,
        formT003FormDataList: state.formT003FormDataList.map((auditSection,index) => index == action.payload.sectionId ? {
        ...auditSection, 
        sub_sections: auditSection.sub_sections.map((sections, childIndex) => childIndex == action.payload.subSectionId ? {
        ...sections,
        questions:sections.questions.map((list, listIndex) => listIndex == action.payload.questionId? {
          ...list,
          answer: action.payload.data
        }:list)
      }:sections )}:auditSection)
    }
   case actions.AUDIT_LOADER:
    return {
      ...state,
      auditLoader: action.payload
    }
   case actions.FETCH_FILE_LIST:
    return {
      ...state,
      auditLinkrecords: action.payload,
      editSideBar:!state.editSideBar

    }
   case actions.LINKED_DOCS_EMPTY:
    return{
      ...state,
      auditLinkrecords: action.payload,
      editSideBar:!state.editSideBar
    }
    default:
       return state;
  }
}
