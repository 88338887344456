export const addPortfolio = {
  assets:[],
  created_at:'',
  created_by:{},
  credit_rating:'',
  id:'',
  description:'',
  lessor:{},
  modified_by:{},
  name:'',
  net_result:'',
  no_of_asset:'',
  portfolio_type:{},
  slug:'',
  status:{},
  total_inflow:'',
  total_outflow:'',
  updated_at:''
}
