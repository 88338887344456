import React, { Component } from 'react';
import RegionSliderChange from '../containers/RegionSliderChange.js'
export default class RegionSidebar extends Component{
  constructor(props) {
    super(props);
  }
  updateRegion = (e) => {
    e.preventDefault();
    this.props.updateRegionCutomValFn('', 'regionOfOperation')
  }
  render() {
    const  { regionsList, regionCondition } = this.props;
    return(
      <div className={this.props.toggleStatus ? 'assign-sidebar-block show-regionbar' : 'assign-sidebar-block hide-regionbar'} >
        <form onSubmit={(e) => this.updateRegion(e)}>
          <h5 style={{marginBottom: '20px'}}>{this.props.mode == 'edit' ? 'Customize the Region' : 'Customised Region Variables'}{this.props.mode == 'view' ? <strong onClick={() => this.props.toggleRegion()}>X</strong> : null}</h5>
          <ul className="list-unstyled">
            {regionCondition?Object.keys(regionCondition).map((item, index) => <RegionSliderChange mode={this.props.mode} regionCondition={regionCondition[item]} item={item} key={index} />):null}
            {
              this.props.mode == 'edit' ?
              <li className="submit-block">
                <input type="submit" className="primary-btn" value="Update"/>
                <a href="javascript:void(0)" onClick={() => this.props.toggleRegion(false) }>Cancel</a>
              </li>
              :null
            }
            </ul>
          </form>
        </div>
      )
  }
}
