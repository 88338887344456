import React, { Component } from 'react';
import { Link } from 'react-router';
import { fieldDateFormat, backendDateFormat, numberRegx } from '../../../../constants';
export default class UtilsFields extends Component{
  constructor(props){
    super(props);
    this.state = {
      value:''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value
    });
  }
  componentWillReceiveProps(nextProps){

    // if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value
      });
    // }
  }
  changeUtil = (value) => {
    if(this.props.keyParam == 'asset_location' || this.props.keyParam == 'remarks'){
      this.setState({
        value: value
      });
    }else{
      if(numberRegx.test(parseFloat(value)) || value == ''){
        this.setState({
          value: value
        });
      }
    }
  }
  updateUtil = () => {
    this.props.updateUtilField(this.props.index, this.state.value, this.props.keyParam);
  }
  render(){
    const { index, label, width, type } = this.props;
    if(type == 'textArea'){
      return(
        <div className="form-group">
          <textarea
            rows="3"
            type="text"
            value={this.state.value !== undefined && this.state.value !== null ? this.state.value:''}
            onChange={(e) => this.changeUtil(e.target.value)}
            onBlur={this.updateUtil}
            style={{width: width}}

          ></textarea>
        </div>
      )
    }else{
      return(
        <div className="form-group">
          <input
            type="text"
            value={this.state.value !== undefined && this.state.value !== null ? this.state.value:''}
            onChange={(e) => this.changeUtil(e.target.value)}
            onBlur={this.updateUtil}
            style={{width: width}}
          />
        </div>
      )
    }

  }
}
