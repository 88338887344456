import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { showCurrencyFormat } from '../../../../utils';
import { displayDateFormat, backendDateFormat, fieldDateFormat } from '../../../../constants';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class PenaltyDebitNote extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { type, invoiceInfo, invoice_item, index, error } = this.props
    if(type === 'view'){
      return(
        <tr>
          <td>
            <h6 className="para-ui">
            <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
             {invoiceInfo.parent_amount}
              </NumericLabel>
            </h6>
          </td>
          <td className="invoice-penalty-description-block">
            <h6 className="para-ui"> {invoiceInfo.description }</h6>
          </td>
          <td className="invoice-date-block">
            <h6 className="para-ui"> {invoiceInfo.penalty_due_date ? moment(invoiceInfo.penalty_due_date).format(displayDateFormat):'--' }</h6>
          </td>
          <td className="invoice-date-block">
            <h6 className="para-ui"> {invoiceInfo.parent_paid_date ? moment(invoiceInfo.parent_paid_date).format(displayDateFormat):'--' }</h6>
          </td>
          <td>
            <h6 className="para-ui">
              {moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days'):'0'}
            </h6>
          </td>
          <td className="invoice-usd-day">
            <h6 className="para-ui">{invoiceInfo.libor_value ? invoiceInfo.libor_value : '0'}%</h6>
          </td>
          <td>
          <h6 className="para-ui">{invoiceInfo.additional_rate ? invoiceInfo.additional_rate : '0'}%</h6>
          </td>
          <td>
            <h6 className="para-ui"> {invoiceInfo.penalty_interest_rate ? invoiceInfo.penalty_interest_rate:'0'}%</h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoiceInfo.total_amount ? invoiceInfo.total_amount: '0'}
              </NumericLabel>
            </h6>
          </td>
        </tr>
      )
    }else{
      return(
        <tr>
          <td className="invoice-type-block">
          <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat()}>
                {invoiceInfo.parent_amount}
              </NumericLabel>
            </h6>
          </td>
          <td className="invoice-penalty-description-block">
            <input type="text"  value={invoiceInfo.description}
              onChange={(e) => this.props.updateInvoiceField(e.target.value, 'description')}
            />
          </td>
          <td className="invoice-date-block">
            <DatePicker
            dateFormat = {fieldDateFormat}
            selected={invoiceInfo.penalty_due_date && moment(invoiceInfo.penalty_due_date).isValid() ? moment(invoiceInfo.penalty_due_date) : null}
            onChange={(e) => {this.props.updateInvoicePenaltyField(moment(e).format(backendDateFormat), 'penalty_due_date');this.props.updateInvoicePenaltyField(moment(invoiceInfo.parent_paid_date).diff(moment(e),'days')>0 ? moment(invoiceInfo.parent_paid_date).diff(moment(e),'days'):0 , 'over_due_days')}}
          //  maxDate={moment(moment(invoiceInfo.end_date).format('YYYY') + '-' + moment(invoiceInfo.end_date).format('MM')+'-'+ moment(invoiceInfo.end_date).format('DD')).subtract(1, "days")}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            onBlur={() => this.props.dateValidation(invoiceInfo.parent_paid_date ,invoiceInfo.penalty_due_date, 'penalty_due_date')}
            onFocus = {() => this.props.validationClear('penalty_due_date')}

         />
         <h6 className="error-msg">{error.penalty_due_date}</h6>


          </td>
          <td className="invoice-date-block">

            <DatePicker
            dateFormat = {fieldDateFormat}
            selected={invoiceInfo.parent_paid_date && moment(invoiceInfo.parent_paid_date).isValid() ? moment(invoiceInfo.parent_paid_date) : moment() }
            onChange={(e) => {this.props.updateInvoicePenaltyField(moment(e).format(backendDateFormat), 'parent_paid_date');this.props.updateInvoicePenaltyField(moment(e).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(e).diff(moment(invoiceInfo.penalty_due_date),'days'):0 , 'over_due_days')}}
           // minDate={moment(moment(invoiceInfo.due_date).format('YYYY') + '-' + moment(invoiceInfo.due_date).format('MM') + '-'+ moment(invoiceInfo.due_date).format('DD')).add(1, "days")}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            onBlur={() => this.props.dateValidation(invoiceInfo.parent_paid_date, invoiceInfo.penalty_due_date, 'parent_paid_date')}
            onFocus = {() => this.props.validationClear('parent_paid_date')}

          />
          <h6 className="error-msg">{error.parent_paid_date}</h6>

          </td>
          <td className="invoice-overdue-day" >
          <h6 className="para-ui">{invoiceInfo.over_due_days ? invoiceInfo.over_due_days:moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days'):0}</h6>
          </td>
          <td className="invoice-usd-day">
            <input type="text"  value={invoiceInfo.libor_value ? invoiceInfo.libor_value:''}
            onChange={(e) => this.props.updateInvoicePenaltyField(e.target.value, 'libor_value')}
            />
             <h6 className="error-msg">{error.libor_value}</h6>
          </td>
          <td>
            <input type="text"  value={invoiceInfo.additional_rate? invoiceInfo.additional_rate:''}
              onChange={(e) => this.props.updateInvoicePenaltyField(e.target.value, 'additional_rate')}
            />
            <h6 className="error-msg">{error.additional_rate}</h6>
          </td>
          <td>
            <h6 className="para-ui"> {invoiceInfo.penalty_interest_rate ? invoiceInfo.penalty_interest_rate:'0'}% </h6>
            </td>
          <td>
          <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat()}>
                {invoiceInfo.total_amount}
              </NumericLabel>
          </h6>
          </td>
        </tr>
      )
    }
  }
}
