import * as actions from '../actions';
import { globalGetService, globalExportService, globalPostService, globalDeleteService } from '../../../globalServices';
import { toastFlashMessage, downloadFileType } from '../../../utils'
import { browserHistory } from 'react-router'
import { PAYMENT_FAILED } from '../../../shared/actions'
import { LICENSE_LOADER } from '../../licenses/actions'

export const ordersListAc = (filters) => {
  return(dispatch) => {
    dispatch({
      type: actions.ORDERS_LOADER,
      payload: true
    })
    let filterObj = Object.assign({}, filters);
    if(filterObj.created_at){
      let issueDate = filters.created_at.split(',');
      delete filterObj['created_at'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            end_date:date
          }
        }
      })
    }
    globalGetService('/console/billing-history/', filterObj)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ORDERS_LIST,
          payload: response.data.data
        });
      }
      dispatch(getDueAmountAc())
    })
  }
}

export const getDueAmountAc = () =>{
  return(dispatch) =>{
    globalGetService(`/console/billing/due-amount/`)
    .then(response =>{
      if(response.data.statusCode == 200){
        var dueAmount = 0
        var dueDate = ''
        if(response.data.data.length){
          response.data.data.map(item => {
            if(!item.proration){
              dueAmount += (item.amount / 100)
            }
            dueDate = item.period.start
            return item
          })
        }
        dispatch({
          type: actions.ORDER_DUE_AMOUNT,
          payload: { dueAmount, dueDate }
        })
      }
      dispatch({
        type: actions.ORDERS_LOADER,
        payload: false
      })
    })
  }
}

export const ordersDetailtAc = (orderId) => {
    return(dispatch) => {
      dispatch({
        type: actions.ORDERS_LOADER,
        payload: true
      })
      globalGetService('/console/order/'+orderId + '/')
      .then(response => {
        dispatch({
          type: actions.ORDERS_LOADER,
          payload: false
        })
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ORDERS_DETAIL,
            payload: response.data.data
          });
        }
      })
    }
}

export const exportInvoiceAc = (e, orderId, type) => {
    e.preventDefault();
    e.stopPropagation();
    return (dispatch) => {
      dispatch({
        type: actions.ORDERS_LOADER,
        payload: true
      })
      globalExportService('console/order/'+orderId + '/', {download: type})
      .then(response => {
        dispatch({
          type: actions.ORDERS_LOADER,
          payload: false
        })
        downloadFileType(response, `Invoice #${orderId}.`, type);
      });
    }
}
