import * as actions from '../actions';
export default function(state = {
  financeLoader: true,
  financeDetails:{},
  financeCrud: {
    flag: false,
    otherFlag:false,
    data: {}
  }
}, action){
switch (action.type) {
  case actions.FINANCE_DETAILS:
    return {
      ...state,
      financeDetails:action.payload
    }
  case actions.FINANCE_LOADER:
    return {
      ...state,
      financeLoader:action.payload
    }
  case actions.TOGGLE_VIEW_EDIT:
    return {
      ...state,
      financeCrud:action.payload
    }
  default:
    return state;
  }
}
