import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { trackLogOut } from '../../utils/mixpanel'
import { getLocalStorageInfo } from '../../utils'
export default class UserSideBar extends Component {
  constructor(props){
    super(props)
  }
  logout = () => {
    trackLogOut(getLocalStorageInfo())
    localStorage.clear();
    browserHistory.push('/login');
  }
  render(){
    return(
      <div className="">
        <div className="mr-calc-sidebar" style={{marginTop: '-54px'}}>
          <ul className="list-unstyled" style={{marginTop: '140px'}}>
            <li>
              <Link activeClassName="active" to="/user-profile">Profile</Link>
            </li>
            <li>
              <Link activeClassName="active" to="/change-password">Change Password</Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
