import * as actions from '../actions';
export default function( state = {
  extractFileList: [],
  extractFileInfo: {},
  dataIds: [],
  mlLoader: false,
  modalStatus: false,
  progressPercentage: 0,
  checkboxStatus: false,
  dataLogFlag: false,
  dataPointCrud: {
    flag: false,
    data: {},
    type: 'edit'
  }
}, action){
  switch (action.type){
    case actions.EXTRACT_FILE_LIST:
      return {
        ...state,
        extractFileList: action.payload
      }
    case actions.EXTRACT_FILE_INFO:
      return {
        ...state,
        extractFileInfo: action.payload
      }
    case actions.UPDATE_DATA_IDS:
      if(state.dataIds.indexOf(action.payload.id) != -1){
        return {
          ...state,
          dataIds: state.dataIds.filter(id => id != action.payload.id),
          checkboxStatus: false
        }
      }else{
        return {
          ...state,
          dataIds: [...state.dataIds, action.payload.id],
          checkboxStatus: false
        }
      }
    case actions.CHANGE_DATA_POINT_STATUS:
      let ids = action.payload.ids;
      let status = action.payload.status;
      let data = JSON.parse(JSON.stringify(state.extractFileInfo.response_text));
      let newDataPoints = JSON.parse(JSON.stringify(state.extractFileInfo.response_text));
      let approvedStatus = state.extractFileInfo.approved_data_points;
      let pendingItems = state.extractFileInfo.pending_items.filter(item => ids.indexOf(item) === -1 );
      if(status == 1){
        approvedStatus += parseInt(ids.length);
      }
      Object.keys(data).map(label => {
        if(label == 'aircraft'){
          newDataPoints = {
            ...newDataPoints,
            aircraft: data.aircraft.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }
        if(label == 'apu'){
          newDataPoints = {
            ...newDataPoints,
            apu: data.apu.map(item => ids.indexOf(item.id) != -1 ? {...item, status: status } :item)
          }
        }
        if(label == 'landing_gear'){
          Object.keys(data.landing_gear).map(lb => {
            newDataPoints = {
              ...newDataPoints,
              landing_gear: {
                ...newDataPoints.landing_gear,
                [lb]: data.landing_gear[lb].map(item => ids.indexOf(item.id) != -1 ? {...item, status: status } :item)
              }
            }
          });
        }
        if(label == 'engine'){
          if(data.engine.engine_list){
            Object.keys(data.engine.engine_list).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                engine: {
                  ...newDataPoints.engine,
                  engine_list: {
                    ...newDataPoints.engine.engine_list,
                    [lb]: data.engine.engine_list[lb].map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                  }
                }
              }
            })
          }
          if(data.engine.general_info){
            newDataPoints = {
              ...newDataPoints,
              engine: {
                ...newDataPoints.engine,
                general_info: data.engine.general_info.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status}: item)
              }
            }
          }
        }



        if(label == 'interior'){
          if(data.interior.galley){
            Object.keys(data.interior.galley).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                interior: {
                  ...newDataPoints.interior,
                  galley: {
                    ...newDataPoints.interior.galley,
                    [lb]: data.interior.galley[lb].map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                  }
                }
              }
            })
          }


          if(data.interior.lavatory){
            Object.keys(data.interior.lavatory).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                interior: {
                  ...newDataPoints.interior,
                  lavatory: {
                    ...newDataPoints.interior.lavatory,
                    [lb]: data.interior.lavatory[lb].map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                  }
                }
              }
            })
          }
        }

        if(label == 'reserve_account'){

          if(data.reserve_account.landing_gear){
            Object.keys(data.reserve_account.landing_gear).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                reserve_account: {
                  ...newDataPoints.reserve_account,
                  landing_gear: data.reserve_account.landing_gear.map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                }
              }
            })
          }


          if(data.reserve_account.apu){
            Object.keys(data.reserve_account.apu).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                reserve_account: {
                  ...newDataPoints.reserve_account,
                  apu: data.reserve_account.apu.map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                }
              }
            })
          }

          if(data.reserve_account.engine){
            Object.keys(data.reserve_account.engine).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                reserve_account: {
                  ...newDataPoints.reserve_account,
                  engine: {
                    ...newDataPoints.reserve_account.engine,
                    [lb]: data.reserve_account.engine[lb].map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                  }
                }
              }
            })
          }


          if(data.reserve_account.airframe){
            Object.keys(data.reserve_account.airframe).map(lb => {
              newDataPoints = {
                ...newDataPoints,
                reserve_account: {
                  ...newDataPoints.reserve_account,
                  airframe: {
                    ...newDataPoints.reserve_account.airframe,
                    [lb]: data.reserve_account.airframe[lb].map(item => ids.indexOf(item.id) != -1 ? {...item, status: status}:item)
                  }
                }
              }
            })
          }
        }

        if(label == 'rental_rate'){
          newDataPoints = {
            ...newDataPoints,
            rental_rate: data.rental_rate.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }

        if(label == 'contract'){
          newDataPoints = {
            ...newDataPoints,
            contract: data.contract.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }

        if(label == 'lessor'){
          newDataPoints = {
            ...newDataPoints,
            lessor: data.lessor.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }
        if(label == 'lessee'){
          newDataPoints = {
            ...newDataPoints,
            lessee: data.lessee.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }

        if(label == 'return_condition_item'){
          newDataPoints = {
            ...newDataPoints,
            return_condition_item: data.return_condition_item.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }

        if(label == 'delivery_condition_item'){
          newDataPoints = {
            ...newDataPoints,
            delivery_condition_item: data.delivery_condition_item.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }

        if(label == 'security_deposit'){
          newDataPoints = {
            ...newDataPoints,
            security_deposit: data.security_deposit.map(item => ids.indexOf(item.id) != -1 ? { ...item, status: status } :item)
          }
        }

      });
      return {
        ...state,
        extractFileInfo: {
          ...state.extractFileInfo,
          response_text: newDataPoints,
          approved_data_points: approvedStatus,
          pending_items: pendingItems
        },
        dataIds: []
      };
    case actions.ML_LIST_LOADER:
      return {
        ...state,
        mlLoader: action.payload
      }
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        modalStatus: action.payload,
        progressPercentage: 0
      }
    case actions.PROGRESS_PERCENTAGE:
      return{
        ...state,
        progressPercentage: action.payload
      }
    case actions.SELECT_ALL_FILE:
      return {
        ...state,
        dataIds: action.payload ? state.extractFileInfo.pending_items : [],
        checkboxStatus: action.payload
      }
    case actions.DELETE_ML_FILE:
      return {
        ...state,
        extractFileList: state.extractFileList.filter(item => item.id  != action.payload ),
      }
    case actions.CLOSE_DATALOG_SIDE_BAR:
      return {
        ...state,
        dataLogFlag: action.payload
      }
    case actions.TOGGLE_DATA_EXT_BAR:
      return {
        ...state,
        dataPointCrud:action.payload
      }
    default:
      return state;
  }
}
