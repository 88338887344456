import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { licenseListAc } from '../actionCreators';
class LicenseSidebar extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    componentDidMount(){
        this.props.fetchLicenses();
    }
    render(){
        const{ notifications, notificationOpen } = this.props;
        const userDetail = JSON.parse(localStorage.getItem('userInfo'));
        return(
            <div className="license-sidebar" style={(notifications && notifications.overdue && notifications.overdue > 1 && notificationOpen) ? {paddingTop: '30px'} :  null }>
                 <ul className="list-unstyled">
                    {
                        this.props.licenses.map((license, key) =>
                            <li key={key}>
                                <Link to={"/licenses/" + license.name.toLowerCase().replace(/ /g, '-')} className={ this.props.licenseId == license.name.toLowerCase().replace(/ /g, '-') ? "active": ""}>{license.name}</Link>
                            </li>
                        )
                    }
                 </ul>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    licenses: state.LicenseReducer.licensList,
    notificationOpen:state.sharedReducers.notificationOpen,
    notifications: state.sharedReducers.notifications,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        fetchLicenses : () => dispatch(licenseListAc())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LicenseSidebar);
