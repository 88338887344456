export const WORKING_GROUP_LIST = 'WORKING_GROUPS_LIST';
export const WORKING_GROUP_VIEW = 'WORKING_GROUP_VIEW';
export const WORKING_GROUP_MEMBER_VIEW = 'WORKING_GROUP_MEMBER_VIEW';
export const WORKING_GROUP_ADD_SUCCESS = 'WORKING_GROUP_ADD_SUCCESS';
export const WORKING_GROUP_EDIT_SUCCESS = 'WORKING_GROUP_EDIT_SUCCESS';
export const WORKING_GROUP_MEMBER_ADD = 'WORKING_GROUP_MEMBER_ADD';
export const WORKING_GROUP_MEMBER_EDIT = 'WORKING_GROUP_MEMBER_EDIT';
export const CLOSE_TEAM_SIDE_BAR = 'CLOSE_TEAM_SIDE_BAR';
export const CLOSE_MEMBAR_SIDE_BAR = 'CLOSE_MEMBAR_SIDE_BAR';
export const WORKING_GRP_LOADER = 'WORKING_GRP_LOADER'
export const WORKING_GRP_CRUD = 'WORKING_GRP_CRUD';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const WORKING_GRP_MEM_CRUD= 'WORKING_GRP_MEM_CRUD';
