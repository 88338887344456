import React, { Component } from 'react';
import { ContrListCol } from '../../Elements';
export default class WorkingGrpRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { member, parentIndex, childIndex, workingGroupId } = this.props;
    const memberObj = [
      { value: member.first_name ? member.first_name + ' ' + member.last_name:member.last_name },
      { value: member.designation },
      { value: member.email },
      { value: member.mobile },
      { value: member.remarks }
    ]
    return(
      <tr onClick={() => this.props.viewMemberInfo(workingGroupId, member.id, parentIndex, childIndex)}>
        { memberObj.map((member,index) => <ContrListCol colObj={member} key={index}/>)}
      </tr>
    )
  }
}
