import React, { Component } from 'react';
import { Link } from 'react-router';
import { imgStoragePath } from '../../constants';
import '../assets/styles/login.scss';
export default class ResetPassword extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="reset-password-block text-center">
              <div className="reset-block">
                <img src={imgStoragePath+'phase-2/success.png'} style={{ marginBottom: '15px' }}/>
                <h2>Your password has been reset successfully</h2>
                <p className="paragraph" style={{ marginBottom: '15px' }}>
                    You have successfully reset your password, now you <br/>
                    can login with your user credentials.
                </p>
                <Link to="/login" className="ok-btn primary-btn">Ok</Link>
              </div>
            </div>
          )
    }
}
