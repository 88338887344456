import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {  GetInputField } from '../../Elements';
import CKeditor from "../../../../shared/CKEditor";

export default class Remarks  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = (evt) => {
    this.props.updateForm({type: 'remarks', value:evt.editor.getData(), sectionType: 'EngineCurrentDetails', index:this.props.index})
  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row  style={{margin: '0px'}}>
          <Col>
          <CKeditor
            events={{"change": this.updateForm}}
            content={content.remarks}
          />
          </Col>
         </Row>

    </div>
    );
  }
}
