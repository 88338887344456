import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField, DateInputField} from '../../../Elements';

export default class AddItemWrapper extends Component {
  constructor(props) {
    super(props);
  }


  render() {
  const { } = this.props;
  return(
    <div>
      <Row>
       <Col sm="6">
       <TextInputField type={'edit'} value={''} >ATA</TextInputField>
        <TextInputField type={'edit'} value={''} >Delivery P/N</TextInputField>
        <TextInputField type={'edit'} value={''} >Installed P/N</TextInputField>
        <TextInputField type={'edit'} value={''} >TSN</TextInputField>
        <TextInputField type={'edit'} value={''} >Remarks</TextInputField>
       </Col>
       <Col sm="6">
       <TextInputField type={'edit'} value={''} >Description</TextInputField>
       <TextInputField type={'edit'} value={''} >Delivery S/N</TextInputField>
       <TextInputField type={'edit'} value={''} >Installed S/N</TextInputField>
       <TextInputField type={'edit'} value={''} >CSN</TextInputField>
       <TextInputField type={'edit'} value={''} >Component View</TextInputField>
       </Col>
      </Row>
    </div>
    );
  }
}
