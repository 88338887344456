import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AIRFRAME_EVENT_CHANGE } from '../actions';
import { imgStoragePath } from '../../../../constants';
class GraphFieldType extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const  { inputTpye, eventType, value, type, label, mrFinanceGraph } = this.props;
    if(inputTpye == 'text'){
      return (
        <span>
          {value}
        </span>
      )
    }else if(inputTpye == 'date'){
      let maxEvent = '';
      if( type == 'airframe'){
        maxEvent = parseInt(Object.keys(mrFinanceGraph.data[type][label].events).length);
      }else if( type == 'apu' || type == 'landing_gear' ){
        maxEvent = parseInt(Object.keys(mrFinanceGraph.data[type].events).length);
      }
      return(
        <div className="event-date-col">
          {
            (eventType > 1) ?
            <img onClick={() => this.props.airframeEevent('prev', label, eventType, type)} src={imgStoragePath+"blue_left_icon.png"} width="18" alt="icon" />
            :null
          }
          <span>
            {value}
          </span>
          {
            (eventType < maxEvent) ?
            <img onClick={() => this.props.airframeEevent('next', label, eventType, type)} src={imgStoragePath+"blue_right_icon.png"} width="18" alt="icon" />
            :null
          }
        </div>
      )
    }else{
      return null
    }
  }
}
const mapStateToProps = state => ({
  mrFinanceGraph: state.MRFinanceGraphReducer.mrFinanceGraph
})
const mapDispatchToProps = dispatch => {
  return {
    airframeEevent: (dir, program, eventType, type) => dispatch({
      type: AIRFRAME_EVENT_CHANGE,
      payload: {dir, program, eventType, type}
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GraphFieldType);
