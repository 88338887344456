import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements';
import PaymentCard from '../components/PaymentCard'
import { stripeKey } from '../../../constants'

class PaymentContainer extends Component{
  constructor(props){
    super(props)
    this.state={}
  }

  render(){
    return(
      <StripeProvider apiKey={stripeKey}>
        <Elements>
          <PaymentCard licenseLoader={this.props.licenseLoader} toggleLoader={this.props.toggleLoader} defaultChecked={this.props.defaultChecked} saveCard={this.props.saveCard} makePayment={this.props.makePayment}/>
        </Elements>
      </StripeProvider>
    )
  }
}

const mapStateToProps = (state) =>({

})

const mapDispatchToProps = (dispatch, ownProps) =>{
  return{

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer)
