import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString,checkDateDiff,checkValidDate } from '../../../../formValidator';
import moment from 'moment'
export default class AddEditParty extends Component {
  constructor(props){
    super(props);
    this.state = {
      party:{},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.partyObj));
    delete data['role'];
    data = {
      ...data,
      role : this.props.partyObj.role && this.props.partyObj.role.value ? this.props.partyObj.role.value : '',
      role_name : this.props.partyObj && this.props.partyObj.role.label ? this.props.partyObj.role.label : ''
    }
    this.setState({
      party: data,
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      party: {
        ...prevState.party,
        [type]: (value == 'Invalid date' ? null:value)
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }

  addEditParty = (e) => {
    e.preventDefault();
    var validateNewInput = {
      role: checkString({value: this.state.party.role, required: true, minLength: '', maxLength: '', message: 'Please Select The Role'}),
      name: checkString({value: this.state.party.name, required: true, minLength: '', maxLength: '', message: 'Please Enter The Company Name'}),
      start_date: checkValidDate({value:this.state.party.start_date,required:true, message: 'Please select start date'}),
      end_date: checkValidDate({value:this.state.party.end_date,required:true, message: 'Please select end date'}),
    };
    if(validateNewInput.start_date.trim() == '' && validateNewInput.end_date.trim() == ''){
      validateNewInput = {...validateNewInput, end_date: checkDateDiff({value:this.state.party.start_date,required:true},{value:this.state.party.end_date,required:true}),}
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.addEditParty(this.state.party, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }
  render() {
    const { party, error } = this.state;
    const { type, techConstants } = this.props;
    return (
      <form onSubmit={(e) => this.addEditParty(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Role
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Role'
                  keyParam="role"
                  value={party.role}
                  type={ (party.role == 1 || party.role == 2 || party.role == 3) ? 'view' : type}
                  options={techConstants.filter(item => item.type == "party_role")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {party.role_name ? party.role_name : null}
                />
              <h6 className="error-msg">{error.role}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Name
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={party.name}
                  type={type}
                  keyParam="name"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.name}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Jurisdiction</FieldLabel>
                <FieldInput
                  value={party.jurisdiction}
                  type={type}
                  keyParam="jurisdiction"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Contact Address</FieldLabel>
                <FieldInput
                  value={party.contact_address}
                  type={type}
                  keyParam="contact_address"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Concerned Person</FieldLabel>
                <FieldInput
                  value={party.concerned_person_name}
                  type={type}
                  keyParam="concerned_person_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Concerned Person Designation</FieldLabel>
                <FieldInput
                  value={party.concerned_person_designation}
                  type={type}
                  keyParam="concerned_person_designation"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Start Date
                {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldDate
                  value={party.start_date}
                  type={type}
                  keyParam="start_date"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.start_date}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                End Date
                {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldDate
                  value={party.end_date}
                  type={type}
                  keyParam="end_date"
                  minDate={moment(party.start_date)}
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.end_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={party.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { type != 'view' ?
          <div className="submit-block">
             <input type="submit" className="primary-btn" value="Save Changes"/>
             <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
   )
 }
}
