import React, { Component } from 'react';
import { Link } from 'react-router';
import { Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
export default class ProcessingPopover extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false

    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }



  render() {
    const {  tagetId, placement, files } = this.props;
    return (
		<span onClick={() => this.toggle()}>
            <span className="more-lead-info" onClick={(e) => this.toggle(e)} id={tagetId}>
             <img className="img_eye" width="12" style={{ marginLeft: '5px'}} id={tagetId}  src={ imgStoragePath + (this.state.popoverOpen ? 'inv_info_icon.png' : 'inv_info_icon.png') } alt="img"/>
            </span>
            <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody className="processing-popup">
				        <p>
                    Notification will be sent once the process is done
                </p>
                <Link onClick={() => this.toggle()}>Got it</Link>
            </PopoverBody>
        </Popover>
        </span>

    );
  }
}
