import React from 'react';
import { Col } from 'reactstrap';
const WidgetCard = (props) => {
  return(
    <Col {...props} >
      <div className="invoice-inner-block">
        {props.children}
      </div>
    </Col>
  )
}
export default WidgetCard;
