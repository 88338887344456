import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumericLabel from 'react-pretty-numbers';

import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col } from 'reactstrap';
import '../assets/styles/fleet.scss';
import FleetViewList from '../components/FleetViewList';
import FleetViewHeader from '../components/FleetViewHeader';
import SimMonthView from '../components/SimMonthView';
import MonthSidebar from '../components/MonthSidebar';
import FleetViewScrollHeader from '../components/FleetViewScrollHeader';
import ShortHead from '../elements/ShortHead';
import { fleetCashflowViewAc, fleetCashflowViewFilterAc, exportMsnFleetCashFlowAc, fleetCashflowSimulationAc } from '../actionCreators';
import { ListLoader, NoRecordFound } from '../../../shared/';
import {  prettyNumberParamsL } from '../../../constants';
import { ContractBarLoader } from '../../contracts/Elements';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
class FleetView  extends Component {
  constructor(props){
    super(props);
    this.state = {
      width: 0,
      height: 0,
    }
  }
  componentDidMount(){
    this.setState({width: window.innerWidth + 'px'});
    this.props.fleetCashflowView();
    this.props.fleetCashflowSimulation()
    document.querySelector('body').style.overflow = 'hidden';
    this.setState({
       height : window.innerHeight - (41 + 78 + 112 + 60)
    });
  }
  componentWillUnmount(){
    document.querySelector('body').style.overflow = 'auto';
  }

  render() {
    const { cashFleetView, portListLoader, cashSimulationViewFilter, fleetViewWidth, cashSimulation } = this.props;
    if(!portListLoader){
      if(Object.keys(cashFleetView).length){
        return(
          <div style={{ padding: '15px 5px 0', marginLeft: '60px' }}>
            {portListLoader ? <ListLoader drawer="primary-nav"/>  : null}
            { Object.keys(cashFleetView).length && Object.keys(cashSimulation).length ?
              <ShortHead headingText="" backUrl={`/fleet-cashflow/portfolio/${cashSimulation.portfolio.slug}`}  portfolio={cashSimulation.portfolio} type="lease" fleetFlag="true" style={{marginTop: "-5px"}} contrAssetBasicInfo={cashFleetView}/>:<ContractBarLoader />
            }
            {
              cashFleetView.major_assemblies.length ?
              <FleetViewHeader  style={{marginTop: "80px"}} cashFleetView={cashFleetView} exportMsnFleetCashFlow={this.props.exportMsnFleetCashFlow} updateField={this.props.fleetCashflowViewFilter}  cashSimulationViewFilter={cashSimulationViewFilter}/>:null
            }

            { cashFleetView.major_assemblies.length ?
              <div>
                <div className="flex-not-centered simulation-block header-sep-block" >
                  <div className="inner-width-block">
                    <div className="row-header flex-not-centered">
                      <div className="flex-centered spacing-cls month-blk text-right">
                        <p className="para-ui ">Month</p>
                      </div>
                      <div className={window.innerWidth} style={{ width: (( (window.innerWidth - (300 + 175)) >= (cashFleetView.major_assemblies.length * 220) ? cashFleetView.major_assemblies.length * 220 : window.innerWidth - (475) + 'px')) , overflow: 'hidden' }}>
                        <FleetViewScrollHeader fleetViewWidth={fleetViewWidth} cashFleetView={cashFleetView}/>
                      </div>
                      <div className="flex-centered spacing-cls total-block text-right" >
                        <p style={{width:"100%"}} className="para-ui ">Total</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ height: this.state.height + 'px', overflow:'auto',marginBottom:'60px' }}>
                  <div className="flex-not-centered simulation-block"   >
                    <MonthSidebar customClass="flex-centered total-block view" monthList={cashFleetView.cashflowArr} />
                  <div className="test" style={{ width: (( (window.innerWidth - (300 + 175)) >= (cashFleetView.major_assemblies.length * 220) ? cashFleetView.major_assemblies.length * 220 : window.innerWidth - (475) + 'px')) , overflow: 'hidden' }}>
                      <div className="inner-width-block" style={{ width: ( window.innerWidth - (300 + 175) + 'px') }}>
                        <div className="row-header flex-not-centered " id="row-header"  style={{ width: this.props.fleetViewWidth, flexBasis: this.props.fleetViewWidth }} >
                          { cashFleetView.major_assemblies.length > 0 ?
                            cashFleetView.major_assemblies.map((data, index) =>
                              <FleetViewList  key={index} cashFleetView={data} monthList={cashFleetView.cashflowArr}/>
                            ) : <NoRecordFound description="No CashFlow Projection Record Available for Given Asse"/>
                          }
                        </div>
                      </div>
                    </div>
                    <SimMonthView customClass="flex-centered total-block view" monthList={cashFleetView.cashflowArr} cashSimulation={cashFleetView} heading="Total "/>
                  </div>
                </div>
              </div>
              :
              <div style={{paddingTop: '150px'}}><NoRecordFound/></div>
            }
          </div>
        )
      }else{
        return <div style={{paddingTop: '150px'}}><NoRecordFound/></div>
      }
    }else{
      return <ListLoader drawer="primary-nav"/> ;
    }
  }
}

const mapStateToProps = state => ({
  cashFleetView: state.FleetAnalysisReducer.cashFleetView,
  fleetViewWidth: state.FleetAnalysisReducer.fleetViewWidth,
  portListLoader: state.FleetAnalysisReducer.portListLoader,
  cashSimulationViewFilter: state.FleetAnalysisReducer.cashSimulationViewFilter,
  cashSimulation: state.FleetAnalysisReducer.cashSimulation,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportMsnFleetCashFlow: (asset, filterType, startDate, endDate, fileType) => dispatch(exportMsnFleetCashFlowAc(asset, filterType, startDate, endDate, fileType)),
    fleetCashflowView: () => dispatch(fleetCashflowViewAc(ownProps.params)),
    fleetCashflowViewFilter: (type, value) => dispatch(fleetCashflowViewFilterAc(ownProps.params, type, value)),
    fleetCashflowSimulation: (filterParam = {}) => dispatch(fleetCashflowSimulationAc({slug: ownProps.params.portfolio}, filterParam)),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(FleetView, ['fleet_cash_flow','dashboard','R'])));
