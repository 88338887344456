export const LEASE_HISTORIES = 'LEASE_HISTORIES';
export const CONTR_ASSET_BASIC_INFO = 'CONTR_ASSET_BASIC_INFO';
export const UPDATE_LEASE_CARD_POS = 'UPDATE_LEASE_CARD_POS';
export const CONTR_MAJOR_ASSEMBLY_SLIDER = 'CONTR_MAJOR_ASSEMBLY_SLIDER';
export const CONTR_DB_CASH_FLOW_LIST = 'CONTR_DB_CASH_FLOW_LIST';
export const CONTR_DB_INVOICE_LIST = 'CONTR_DB_INVOICE_LIST';
export const CONTR_DB_FLAGGED_COND = 'CONTR_DB_FLAGGED_COND';
export const CONTR_DB_LOADER = 'CONTR_DB_LOADER';
export const COMPARE_RETURN_COND = 'COMPARE_RETURN_COND';
export const CLEAR_RETURN_COND = 'CLEAR_RETURN_COND';
export const CONTRACT_WIDGET_LOADER = 'CONTRACT_WIDGET_LOADER';
export const CASHFLOW_WIDGET_LOADER = 'CASHFLOW_WIDGET_LOADER';
