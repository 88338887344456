import React from 'react';
import { ExportFiles } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
const BulkOperation = (props) => {
  return(
    <div className="add-bulk-operations">
      <ul className="list-inline flex-centered">
        <li className="list-inline-item operation-cta">
          <ExportFiles
            exportFile={(fileType) => props.easaFaaExport(fileType)}
            files={[{img:'pdf_icon.png', label: 'PDF', type:'pdf'}, {img:'excel_icon.png', label: 'Excel', type:'xls'}]} />
        </li>
      </ul>
    </div>
  )
}
export default BulkOperation;
