import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-rangeslider/lib/index.css'
import Slider from 'react-rangeslider';
import { MR_CUSTOM_REGION_CHA } from '../actions';
class RegionSlide extends Component{
  constructor(props){
    super(props);
  }
  handleOnChange = (value) => {
    const selectedValue = this.props.regionCondition['ranges'].filter(item => item.order === value)
    this.props.changeRegionCond(this.props.item, value);
  }
  handleFormat = (value) => {
    return this.props.regionCondition['ranges'][value];
  }
  render(){
    var { regionCondition, item } = this.props;
    const statLabels = regionCondition['ranges'].sort(function(a,b) {return a.order - b.order})
    var labels = {}
    statLabels.map(item => {
      labels = {...labels, [item.order]: item.value}
    })
    const title = regionCondition['name']
    const minValue = Object.keys(labels)[0]
    const maxValue = Object.keys(labels)[Object.keys(labels).length - 1];
    const value = regionCondition['default'] ? statLabels.filter(item => item.id === regionCondition['default'])[0].order: minValue;
    return(
      <li>
        <h6 className="caption-ui">{title} <span style={{float: 'right'}}>{labels[value] ? labels[value] : ''}</span></h6>
        <Slider
          value={parseInt(value)}
          orientation="horizontal"
          min = {parseInt(minValue)}
          max = {parseInt(maxValue)}
          labels={ labels }
          tooltip={false}
          onChange={this.handleOnChange}
          format  = {this.handleFormat}
          />
      </li>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeRegionCond:(index, value) => dispatch({
      type: MR_CUSTOM_REGION_CHA,
      payload: {index, value}
    })
  }
}
export default connect(null, mapDispatchToProps)(RegionSlide);
