import * as actions from '../actions';
export default function(state = {
  contrHdlineInfo: {
   hdlineInfo:{},
   portfolioList: []
 },
 hdLineLoader: false
}, action){
  switch (action.type) {
    case actions.HD_BASIC_INFO:
      return {
        ...state,
        contrHdlineInfo:{
          ...state.contrHdlineInfo,
          hdlineInfo: action.payload
        }
      }
    case actions.CLEAR_CONTRACT_VIEW:
      return {
        ...state,
        contrHdlineInfo:{
          ...state.contrHdlineInfo,
          hdlineInfo: {}
        }
      }
    case actions.PORTFOLIO_LIST:
      return {
        ...state,
        portfolioList: action.payload
      }
    case actions.HDLINE_LOADER:{
      return {
        ...state,
        hdLineLoader: action.payload
      }
    }
    default:
      return state;
  }
}
