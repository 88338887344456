import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import {
  TableHeadingTechSummary,
  AddEditSTCMod
} from '../components';
import { getSTCModsListApi, getSTCModApi, addEditSTCModApi, deleteSTCModApi } from '../apiServices';
import { REMARKS_CRUD } from '../actions';
import { EditSidebar, CtrTableHd, FormModeType } from '../../../contracts/Elements';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import { imgStoragePath, displayDateFormat  } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';

class STCMods extends Component{
  constructor(props) {
    super(props);
    this.state = {
      pageLoader:false,
      stcModsList:[],
      modal:false,
      mode:'',
      error:{},
      stcModCrud:{},
      deleteStcMod: {flag: false, stcMod:{}}
    }
    this.getSTCModsListApi = getSTCModsListApi.bind(this);
    this.getSTCModApi = getSTCModApi.bind(this);
    this.addEditSTCModApi = addEditSTCModApi.bind(this);
    this.deleteSTCModApi = deleteSTCModApi.bind(this);
  }
  componentDidMount() {
    this.getSTCModsListApi(this.props);
  }
  onFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      stcModCrud: {
        ...prevState.stcModCrud,
        [keyParam]:value
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]: message
      }
    }))
  }

  render(){
    const { stcModsList, stcModCrud, deleteStcMod, error, modal, mode, pageLoader } = this.state;
    return(
      <div>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.setState({stcModCrud:{description:'', stc_number:'', regulatory_approval:'', compliance_status:null, compliance_date:null}, error:{}, modal:true, mode:'Add'})} title='STCs & Mods' newFeature={this.props.params.type != 'aircraft' ? true : false} addButton={getLocalStorageInfo().user.permission['technical_specs'] ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['Sr No.','Description','STC Number', 'STC Compliance Status', 'Regulatory Approval', 'Compliance Date','']}/>
              <tbody>
                { stcModsList.map((stcMod, index) =>
                  <tr>
                    <td>{index+1}</td>
                    <td>{stcMod.description ? stcMod.description:'--'}</td>
                    <td>{stcMod.stc_number ? stcMod.stc_number:'--'}</td>
                    <td>{stcMod.stc_compliance_status && stcMod.stc_compliance_status.label ? stcMod.stc_compliance_status.label:'--'}</td>
                    <td>{stcMod.regulatory_approval ? stcMod.regulatory_approval:'--'}</td>
                    <td>{stcMod.compliance_date ? moment(stcMod.compliance_date).format(displayDateFormat):'--'}</td>
                    <td>
                      <img src={imgStoragePath + 'icon_editblock.png'} alt="Edit" width="15px" style={{marginRight:'10px'}} onClick={() => this.getSTCModApi(this.props, stcMod.id)} />
                      <img src={imgStoragePath + 'delete_red.png'} alt="delete" width="15px" onClick={() => this.setState({deleteStcMod:{flag:true, stcMod:stcMod}})} />
                    </td>
                  </tr>
                )}
                {!pageLoader && !stcModsList.length ?
      						<tr>
                    <td colSpan={7}>
                      <NoRecordFound description="Get Started by clicking '+ Add' button above" />
                    </td>
                  </tr> :  null
      		    	}
              </tbody>
            </table>
          </div>

        </div>
        <EditSidebar
          title={modal ? `${mode} STCs & Mods`:''}
          editSideBar={modal}
          toggleEditSideBar={() => this.setState({stcModCrud:{}, error:{}, modal:false})}
          >
          {
            Object.keys(stcModCrud).length && modal ?
            <div>
              <AddEditSTCMod
                stcModCrud={stcModCrud}
                error={error}
                onFieldChange={this.onFieldChange}
                updateErrorField={this.updateErrorField}
                addEditSTCModFn={(evt) => this.addEditSTCModApi(this.props, stcModCrud)}
                toggleEditSideBar={() => this.setState({stcModCrud:{}, error:{}, modal:false})}
              />
            </div>:null
          }
        </EditSidebar>
        <DeleteModal isOpen={deleteStcMod.flag} toggle={() => this.setState({deleteStcMod: {flag: false, stcMod: {}}})} title="Delete STCs & Mods" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deleteSTCModApi(this.props, deleteStcMod.stcMod)}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.setState({deleteStcMod: {flag: false, stcMod: {}}})}>No</button>
              </li>
          </ul>
          </DeleteModal>
          { pageLoader ? <ListLoader />:null}
      </div>
    )
  }
}
export default withRouter(connect(null, null)(STCMods));
