export const AD_FILES_MODAL = 'AD_FILES_MODAL';
export const AD_PROGRESS_PERCENTAGE = 'AD_PROGRESS_PERCENTAGE';
export const AD_LOGS_LIST = 'AD_LOGS_LIST';
export const AD_DATA_POINTS = 'AD_DATA_POINTS';
export const AD_POINTS_INFO = 'AD_POINTS_INFO';
export const UPDATE_AD_LOADER = 'UPDATE_AD_LOADER';
export const UPDATE_AD_LIST_LOADER = 'UPDATE_AD_LIST_LOADER';
export const EASA_DATA_LIST  = 'EASA_DATA_LIST';
export const AD_LINK_LOADER='AD_LINK_LOADER';
export const UPDATE_AD_LINK='UPDATE_AD_LINK';
export const TECHSPECS_DATA_POINTS_PLOT='TECHSPECS_DATA_POINTS_PLOT';
export const SELECT_DATAPOINT_LIST = 'SELECT_DATAPOINT_LIST'
export const SELECT_ALL_DATAPOINT_LIST = 'SELECT_ALL_DATAPOINT_LIST'
export const TOGGLE_DATA_EXT_BAR = 'TOGGLE_DATA_EXT_BAR'
