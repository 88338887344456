import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Row, Col, TabContent,TabPane,Nav,NavItem,NavLink } from 'reactstrap';
import classnames from 'classnames';
import { AssemblySideBar, UpcomingNotFound, EventsHeader, EventItemRow } from '../../Elements';
import { EditSidebar, ShortHead, ContractHeadertext, ContractBarLoader } from '../../../contracts/Elements';
import { ListLoader, NoRecordFound, LicenceModal } from '../../../../shared';
import { BUY_LICENCE } from '../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../utils'
import { getMaintenanceUsageAc } from '../../AirframeAssembly/actionCreators'
import UpcomingEvents from '../../AirframeAssembly/components/UpcomingEvents';
import EventHistory from '../../AirframeAssembly/components/EventHistory';
import AddEditShopVisit from '../../AirframeAssembly/components/AddEditShopVisit';
import { APU_SHOP_VISIT_CRUD } from '../actions';
import { apuUpcomingEventsAc, apuEventHistoryAc, apuShopVisitCrudAc, addEditApuShopVisitAc } from '../actionCreators';
import { eventTypeAc, assetInfoAc } from '../../AirframeAssembly/actionCreators';
import { assetsType } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import Switcher from '../../../../shared/components/Switcher';
import { trackActivity } from '../../../../utils/mixpanel';
class ApuAssembly extends Component{
  constructor(props){
    super(props);
    this.state = {
      activeTab:"1"
    };
  }

  shopVisitCrud = (type, id) =>{
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
      if(this.props.maintenanceUsage.available == this.props.maintenanceUsage.used){
        if(this.props.maintenanceUsage.available!=0 && !this.props.maintenanceUsage.is_new){
          this.props.shopVisitCrud(type, id)
        }else{
        this.props.buyLicence({
          flag: true,
          action: '/licenses/maintenance',
          content: 'License to add maintenance event is expired.'
        })
        return;
      }
    }
    }
    this.props.shopVisitCrud(type, id)
  }

  componentDidMount(){
    this.props.upcomingEvents();
    this.props.eventHistoryList();
    this.props.eventType();
    this.props.fetchAssetInfo();
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
			this.props.getMaintenanceUsage()
		}
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Major Assemblies APU',
      item_type: 'Event Listing'
    })
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/' + (evt.asset_type == '1' ? 'apu':'engines');
    browserHistory.push(url);
    if(evt.asset_type == '1'){
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.componentDidMount();
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render(){
    const { apuEventList, apuEventHistory, apuShopVisit, eventTypes, assetInfoObj, apuLoader } = this.props;
    return(
      <div className="mr-rate-wrapper maintainance-container" style={{marginLeft: '215px', background: '#f0f2f3', marginBottom: '40px', marginTop:'94px'}}>
        { apuLoader?<ListLoader/>:null}
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} headingText={'Maintenance' && <Switcher app="Maintenance"   />}  changeMsn={this.changeMsn} />:<ContractBarLoader />
        }
        <AssemblySideBar params={this.props.params} />
        <div style={{marginBottom:'60px'}} className="mr-rate-inner-wrapper">
            <div className="assembly-view-layout">
              <Nav tabs>
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggleTab('1'); trackActivity('Item Clicked', { page_title: 'Major Assemblies APU', application_module: 'Maintenance', item_type:'Shop Visits History Tab' })}}
                    >
                      Shop Visits History
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggleTab('2'); trackActivity('Item Clicked', { page_title: 'Major Assemblies APU', application_module: 'Maintenance', item_type:'Upcoming Shop Visits Tab' })}}
                    >
                      Upcoming Shop Visits
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {
                    getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.apu && getLocalStorageInfo().user.permission.maintenance.apu.indexOf('C') !== -1 ?
                    <ContractHeadertext
                      title=""
                      desc="All major shop visits performed on APU"
                      addNew = "+ Add Shop Visit"
                      toggleAddEvent={() => this.shopVisitCrud('add', {})}
                     />
                    :<ContractHeadertext
                      title=""
                      desc="All major shop visits performed on APU"
                     />
                  }
                   <div className="maintainenace-listing-blk list-container">
                     <div className="table-container">
                      <table>
                        <EventsHeader eventType="Shop Visit" />
                        <tbody>
                        { apuEventHistory && apuEventHistory.length ? apuEventHistory.map((shopVisit,index) =>
                          <EventItemRow previewDetail={() => browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/maintenance/apu/view/${shopVisit.id}`)} params={this.props.params} assemblyType="apu" key={index} item={shopVisit} />) : null
                        }
                        </tbody>
                      </table>
                     </div>

                    {!apuLoader && apuEventHistory && !apuEventHistory.length ? <NoRecordFound description="Get Started by clicking '+ Add Shop Visit' button above!"/>:null}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <ContractHeadertext
                    title=""
                    desc="Projected shop visits based on data available for APU"
                   />
                   <div className="assembly-timelinse-list">
                     {apuEventList && apuEventList.length ? apuEventList.map((event,index) => <UpcomingEvents key={index} event={event}/>) : null}
                   </div>
                   {!apuLoader && apuEventList && !apuEventList.length ? <UpcomingNotFound />:null}
                </TabPane>
              </TabContent>
            </div>
        </div>
        <EditSidebar
          title={
            apuShopVisit.type != '' ?
            (apuShopVisit.type == 'add') ? 'Add Shop Visit for APU':
            (apuShopVisit.type == 'edit') ? 'Edit Shop Visit for APU': 'View Shop Visit for APU' : ''
            }
          editSideBar={(apuShopVisit.flag && apuShopVisit.type == 'add')}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
          >
          {
            Object.keys(apuShopVisit.data).length && apuShopVisit.flag && apuShopVisit.type == 'add' ?
            <div>
              <AddEditShopVisit
                type={apuShopVisit.type}
                shopVisitObj={apuShopVisit.data}
                eventTypes={eventTypes}
                addEditShopVisit={(data, type)=>this.props.addEditShopVisit(data, type, assetInfoObj)}
                assemblyType="apu"
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
              />
            </div>:null
          }
        </EditSidebar>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="7"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  apuEventList: state.ApuAssemblyReducer.apuEventList,
  apuEventHistory: state.ApuAssemblyReducer.apuEventHistory,
  apuShopVisit: state.ApuAssemblyReducer.apuShopVisit,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  apuLoader: state.ApuAssemblyReducer.apuLoader,
  maintenanceUsage: state.AirframeAssemblyReducer.maintenanceUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
});

const mapStateToDispatch = (dispatch, ownProps) => {
  return {
    upcomingEvents: () => dispatch(apuUpcomingEventsAc(ownProps.params)),
    eventHistoryList: () => dispatch(apuEventHistoryAc(ownProps.params)),
    addEditShopVisit: (data, type, assetInfoObj) => dispatch(addEditApuShopVisitAc(ownProps.params, data, type, assetInfoObj)),
    shopVisitCrud: (type, id) => dispatch(apuShopVisitCrudAc(ownProps.params, type, id)),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'apu')),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params, 'APU Maintenance')),
    toggleEditBar: (data) => dispatch({
      type: APU_SHOP_VISIT_CRUD,
      payload: data
    }),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getMaintenanceUsage: () => dispatch(getMaintenanceUsageAc(ownProps.params))
  }
};
export default connect(mapStateToProps, mapStateToDispatch)(RoleBasedAccess(ApuAssembly,['maintenance','apu','R']));
