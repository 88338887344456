import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import TableauDashboard  from '../components/TableauDashboard';
import { globalGetService } from '../../../globalServices';
import { ListLoader, NoRecordFound } from '../../../shared'
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { trackActivity } from '../../../utils/mixpanel'
class Analytics extends Component {
  constructor(props){
    super(props);
    this.state = {
      list: [],
      activeTab: 0,
      loader: false
    }
  }

  getTableauList = () => {
    this.setState({loader: true})
    globalGetService('/console/tableau-analytics/')
    .then(response => {
      this.setState({loader: false})
      if(response.data.statusCode === 200){
        this.setState(prevState => ({
          ...prevState,
          list: response.data.data,
          activeTab: response.data.data[0] ? response.data.data[0].id : 0
        }))
      }
    })
  }
  componentDidMount(){
    this.getTableauList();
    trackActivity('Page Visited',{
      application_module: 'Tableu Analytics',
      page_title: 'Dashboards List'
    })
  }
  render() {
    const {activeTab, list, loader} =this.state;
    return(
      <div className="asset-listing-wrap" style={{padding: '10px 10px 5px 10px'}}>
        {
          loader ?
          <ListLoader/>
          :null
        }
        <Row>
          <Col md="12">
            <div className="title-block" style={{marginLeft:'auto'}}>
              <h1>Analytics
                <sup style={{color: '#ff0000', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-Bold', padding: '0 2px'}}>BETA</sup>
              </h1>
              <p>Offers interactive dashboards for analysis of complex data sets and market information.</p>
            </div>
            <div style={{position: 'relative'}}>
              <ul className="list-inline tab-left">
              {
                !loader && list.length ? list.map(item => <li className={this.state.activeTab == item.id ? "list-inline-item active" : "list-inline-item"} onClick={() => this.setState({activeTab: item.id})}>{item.title}</li>) : null
              }
              </ul>
              {
                !loader && list.length ? list.map(item => item.id === activeTab ? <TableauDashboard item={item} key={`tableau_${item.id}`}/> : null) : !loader ? <NoRecordFound/> : null
              }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default RoleBasedAccess(Analytics,['analytics', 'dashboard', 'R']);
