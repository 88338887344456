import React from 'react';
import { Row, Col } from 'reactstrap';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
const EventInfoCard = (props) => {
  const { halfLifeItm } = props;
  return(
    <div className="event-outer-wrap">
      <h4 className="event-heading">
      {halfLifeItm.name} {  halfLifeItm.type == 'airframe' ? 'Event' : null }
      </h4>
      <Row className="event-block">
        <Col md="4" className="adj-inner-block">
          <h6> {  halfLifeItm.type == 'airframe' ? 'Event' : 'Shop Visit'}  Interval</h6>
          <span>{halfLifeItm.interval ? halfLifeItm.interval : '--'}</span>
        </Col>
        <Col md="4" className="adj-inner-block">
          <h6>Next { halfLifeItm.type == 'airframe' ? 'Event ' : 'Shop Visit' } Date</h6>
          <span>
            { halfLifeItm.next_event_date ? moment(halfLifeItm.next_event_date).format(displayDateFormat) : '--'}
          </span>
        </Col>
        <Col md="4" className="adj-inner-block">
          <h6>Next { halfLifeItm.type == 'airframe' ? 'Event' : 'Shop Visit' } Cost as of Date</h6>
          { halfLifeItm.type == 'engine' ?
            halfLifeItm.prsv_cost ?
              <NumericLabel params={showCurrencyFormat()}>
                {halfLifeItm.prsv_cost}
              </NumericLabel> : '--'
          :
          halfLifeItm.event_cost ?
            <NumericLabel params={showCurrencyFormat()}>
              {halfLifeItm.event_cost}
            </NumericLabel> : '--'
          }
        </Col>
        <Col md="4" className="adj-inner-block margin-bottom-0">
          <h6>Date of Manufacture</h6>
          <span>{halfLifeItm.date_of_manufacture ? moment(halfLifeItm.date_of_manufacture).format(displayDateFormat) : '--'}</span>
        </Col>
        <Col md="4" className="adj-inner-block margin-bottom-0">
          <h6>Last { halfLifeItm.type == 'airframe' ? 'Event' : 'Shop Visit' } Date</h6>
          <span>{halfLifeItm.last_event_date ?  moment(halfLifeItm.last_event_date).format(displayDateFormat) : '--'}</span>
        </Col>
      </Row>
      </div>
  )
}
export default EventInfoCard;
