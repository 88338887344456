import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { EditFormBar } from '../../../applications/technicalInspection/Elements';
import { imgStoragePath } from '../../../constants';
import { NoRecordFound } from '../../../shared'
import ReferralHistoryList from './ReferralHistoryList'

export default  class ReferralHistory extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    render(){
      const { referralHistoryList } = this.props
        return(
            <div className="referral-history">
                <EditFormBar
                    titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Referral History</h3></div>}
                    toggleEditSideBar={this.props.toggleSideBar} isOpen={this.props.readInfo} style={this.props.readInfo ? {width: '480px'} : {width: '0px'}}>
                    <Row style={{height: window.innerHeight - 70, overflow: 'auto'}}>
                        <Col md="12">
                          {
                            referralHistoryList && referralHistoryList.length ?
                              referralHistoryList.map((referal, index) => <ReferralHistoryList referal={referal} key={`refer-${index}`}/>)
                            :<NoRecordFound/>
                          }
                        </Col>
                    </Row>
                </EditFormBar>
            </div>

        )
    }
}
