import React, {Component, Fragment} from 'react';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import { intervalList, displayDateFormat, imgStoragePath } from  '../../../../constants';
import { showCurrencyFormat } from  '../../../../utils';
import { ContrListCol } from '../../Elements';
export default class LeaseRentalList extends Component{
 constructor(props){
  super(props);
 }
 render(){
  const { rental, index, params } = this.props;
  const rentalObj = [
    { value: rental.rental_type && rental.rental_type.label ? rental.rental_type.label : '--' },
    { value: rental.currency  ? rental.currency : '--' },
    { value: rental.rental_type && (rental.rental_type.value === 3 || rental.rental_type.value === 4)&& rental.unit_of_measure && rental.unit_of_measure.label ? rental.unit_of_measure.label : rental.interval && rental.interval.label ? rental.interval.label  : '--' },
    { value: rental.amount?<NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.amount}</NumericLabel>:'--'},
    { value: rental.escalation_percentage  && rental.escalation_percentage ?  rental.escalation_percentage + '%': '--' },
    { value: rental.start_date ? moment(rental.start_date).format(displayDateFormat):'--'  },
    { value: rental.end_date ? moment(rental.end_date).format(displayDateFormat):'--' },
    { value:  <span>{index != 0 ?<p className="delete" onClick={(e) => this.props.deleteFn(e, rental.id)}>Delete</p>:null}
     <p  style={{fontSize:'10px',color:'#2F8CFE'}}>{rental.requested_data && Object.keys(rental.requested_data).length ? '( Pending Review )':''}</p>
    </span>} //<img  width="15" src={imgStoragePath+"icon_trash.png"} alt="icon"/>
  ];
  return(
    <tr onClick={() => this.props.viewRental(params, rental.id, 'view')}>
      {rentalObj.map((rental,index) => <ContrListCol colObj={rental} key={index}/>)}
    </tr>
  )
 }
}
