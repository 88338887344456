import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import moment from "moment";
import { browserHistory } from 'react-router';
import { logsListingAc } from '../actionCreators';
import { UPDATE_AIRFRAME_FIELD, LOG_FILTER_UPDATE, LOGS_LOADER } from '../actions';
import { dateFormat, imgStoragePath } from '../../../../constants';
import { removeEmptyKey } from '../../../../utils';
class LogAppliedFilter extends Component{
    constructor(props){
        super(props);
    }

    displayFilterTag = (tagType, tagValue) => {
        switch(tagType){
          case 'engine_type':
            return  this.tagLabel('Engine Type : ', tagValue, this.props.engineTypeList, 'name','id');
          case 'aircraft_type':
           return  this.tagLabel('Aircraft Type : ', tagValue, this.props.aircraftTypeList , 'name', 'id');
          case 'startDate':
            return 'Date : ' +  moment(this.props.logFilter['startDate']).format('YYYY-MM-DD') + ' to ' + moment(this.props.logFilter['endDate']).format('YYYY-MM-DD')
          case 'log_id':
            return 'Log Id :' +  this.props.logFilter.log_id
            default:
          break;
        }
      }

      tagLabel = (label, tagValue, list, name, slug) => {
        let str = label;
        list.map((data) => {
          if(tagValue.split(',').indexOf(data[slug].toString()) != -1){
            str += data[name] + ','
          }
        });
        return str;
      }

      removeAssetFilter = (type, value) => {
        let queryFilter = Object.assign({}, this.props.logFilter);
        if(type == 'startDate'){
          queryFilter = {
            ...queryFilter,
            endDate: '',
            startDate: ''
          };
        }else{
          queryFilter = {
            ...queryFilter,
            [type]: ''
          };
        }
        const filterParams = removeEmptyKey(queryFilter);

        this.props.updateLogFilter('', type);
        this.props.setLoader();
        this.props.fetchLogLists(filterParams, false);

      }


    loadFiltertag = (filter, index, value) => {
        if(['page','sort_by','sort', 'endDate'].indexOf(filter) == -1){
            return (<li key={index}>{this.displayFilterTag(filter, value)} <span  onClick={() => this.removeAssetFilter(filter, '')} ><img className="close-block" src={imgStoragePath+"black_close.png"} alt="close"/></span></li>)
        }else{
            return null;
        }
    }

    render(){
        const filters = removeEmptyKey(Object.assign({}, this.props.logFilter));
        return(
            <ul className="list-inline applied-filter">
              {Object.keys(filters).map((filter, index) =>
                this.loadFiltertag(filter,index, filters[filter])
              )}
            </ul>
          )
        }
}

const mapStateToProps = state => ({
    logFilter: state.MRFinanaceRptReducer.logFilter,
    aircraftTypeList: state.MRFinanaceRptReducer.aircraftTypeList,
    engineTypeList: state.MRFinanaceRptReducer.engineTypeList
});


const mapDispatchToProps = dispatch => {
    return {
      setLoader: () => dispatch({
        type: LOGS_LOADER,
        payload: true
      }),
      updateLogFilter: (value, type) => dispatch({
        type: LOG_FILTER_UPDATE,
        payload: {value, type},
      }),
      fetchLogLists: (filterParams, allTypeFlag) => dispatch(logsListingAc(filterParams, allTypeFlag))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(LogAppliedFilter);
