import React, { Component } from 'react';
export default class EditUitlField extends Component{
  constructor(props){
    super(props);
    this.state = {
      value: null
    }
  }
  updateValue = (e) => {
    let value = e.target.value;
    this.setState({
      value: value
    });
  }
  componentDidMount(){
    this.setState({
      value: this.props.value
    })
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.value
    })
  }
  passValue = () => {

    this.props.updateUtilsFields(this.props.keyParam, this.state.value)
  }
  render() {
    if(this.props.type == 'view'){
      return (<h6>{this.props.value}</h6>)
    }else{
      return(
        <input
          type="text"
          value={this.state.value}
          onChange = {this.updateValue}
          onBlur={this.passValue}
        />
      )
    }

  }
}
