import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL, numberRegx, imgStoragePath } from '../../../../constants';
import { UPDATE_SIMULATION_FIELDS } from '../actions';
class GrpAmountField extends Component {
  constructor(props){
    super(props);
    this.state = {
      fieldObj:{},
      invalidData: false
    };
  }
  componentDidMount(){
    this.updateFieldState(this.props);

  }
  componentWillReceiveProps(nextProps){
    this.updateFieldState(nextProps);
  }
  updateFieldState = (obj) => {
    this.setState(prevState => ({
      ...prevState,
      fieldObj: {
        ...prevState.fieldObj,
        metrixType: obj.metrixType,
        eventIndex: obj.eventIndex,
        programType: obj.programType,
        keyParam: obj.keyParam,
        value: obj.value,
        shopVisit: obj.shopVisit
      }
    }));
  }
  updateField = (e) => {
    const value = e.target.value
    this.setState(prevState => ({
      ...prevState,
      fieldObj: {
        ...prevState.fieldObj,
        value: value
      }
    }));
  }
  updateMRSimilation = () => {
    if(numberRegx.test(this.state.fieldObj.value)){
      this.props.updateSimulationField({
        metrixType: this.state.fieldObj.metrixType,
        programType: this.state.fieldObj.programType,
        eventIndex: this.state.fieldObj.eventIndex,
        keyParam: this.state.fieldObj.keyParam,
        value: this.state.fieldObj.value,
        shopVisit: this.state.fieldObj.shopVisit
      });
      this.setState({
        invalidData: false
      })
    }else{
      this.setState(prevState => ({
        ...prevState,
        fieldObj: {
          ...prevState.fieldObj,
          value: this.props.value
        },
        invalidData: true
      }))
    }

  }
  render(){
    const { simulationType, value, newvalue, metrixType, programType, eventIndex, intervalUnit, shopVist } = this.props;
    if(simulationType == 0){
      return(
        <div>
          <h6>
            <NumericLabel params={prettyNumberParamsL}>
              {value}
            </NumericLabel>
            {
              (intervalUnit!='' && intervalUnit!=undefined && intervalUnit != null)?
              <span className="interval-unit">
                &nbsp;{"/"+intervalUnit}
              </span>:null
            }
          </h6>
        </div>
      )
    } else if(simulationType == 1){
      return(
        <div className="editable-field">
          <span className="currency-symbol">$</span>
          <input
            type="text"
            value={this.state.fieldObj.value}
            onChange={this.updateField}
            onBlur={this.updateMRSimilation}
          />
          <span className="field-edit-icn">
            <img src={imgStoragePath+"edit-field.png"} alt="ic" />
          </span>
          {this.state.invalidData ? <p className="mr-error-field">Invalid Value</p>:null}
        </div>
      )
    }else if(simulationType == 2){
      return(
        <div className="override-value">
          {
            value == newvalue ?
            <h6>
              <NumericLabel params={prettyNumberParamsL}>
                {value}
              </NumericLabel>
              {
                (intervalUnit!='' && intervalUnit!=undefined && intervalUnit != null)?
                <span className="interval-unit">
                  &nbsp;{"/"+intervalUnit}
                </span>:null
              }
            </h6>:
            <div>
              <h6 className="changed-value">
                <NumericLabel params={prettyNumberParamsL}>
                  {newvalue}
                </NumericLabel>
                {
                  (intervalUnit!='' && intervalUnit!=undefined && intervalUnit != null)?
                  <span className="interval-unit">
                    &nbsp;{"/"+intervalUnit}
                  </span>:null
                }
              </h6>
              <p className="old-value">
                <NumericLabel params={prettyNumberParamsL}>
                  {value}
                </NumericLabel>
                {
                  (intervalUnit!='' && intervalUnit!=undefined && intervalUnit != null)?
                  <span className="interval-unit">
                    &nbsp;{"/"+intervalUnit}
                  </span>:null
                }
              </p>
            </div>
          }
        </div>
      )
    }else{
      return null
    }
  }
}
const mapStateToProps = state => ({
  simulationType: state.MRFinanceGraphReducer.simulationType,
  simulatedData: state.MRFinanceGraphReducer.simulatedData
});
const mapDispatchToProps = dispatch => {
  return {
    updateSimulationField: (data) => dispatch({
      type: UPDATE_SIMULATION_FIELDS,
      payload: data
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GrpAmountField)
