import React from 'react'
import { imgStoragePath } from '../../../constants'

const CardList = (props) =>{
  return(
    <div className={props.selected == props.card.id ? "card-list-item row active" : "card-list-item row"} onClick={props.selectCard}>
      <div className="col-md-2">
        <img src={`${imgStoragePath}phase-2/cards/${props.card.brand.toLowerCase().replace(' ','')}.png`} />
      </div>
      <div className="card-number col-md-6">
          <span>
            Ending with {props.card.last4}
            <span>{props.card.brand}</span>
          </span>
      </div>
      <div className="col-md-2 expiry-date">
        {props.card.exp_month > 9 ? props.card.exp_month : '0' + props.card.exp_month}/{props.card.exp_year}
      </div>
    <div className="col-md-1">
    <i className="fa fa-check-circle" style={props.selected == props.card.id ? {display: 'none'} : {display: 'none'}} ></i>
    </div>
    <div className="col-md-1">
    <i className="fa fa-pencil"></i>
    </div>

    </div>
  )
}

export default CardList
