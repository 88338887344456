import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../constants';

export default class BannerComponent extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="banner-section">
                <Container>
                    <Row className="banner-row flex-centered">
                        <Col lg="6" md="6" sm="12" xs="12" className="left-blk">
                            <p className="logo-blk">
                              <Link to="/" target="_blank" className="logo-link">
                                <img src={imgStoragePath+"landing/logo.png"} alt="logo" />
                              </Link>
                            </p>
                            <div className="content-blk">
                              <h1>{ this.props.title }</h1>
                              <h2>{ this.props.subtitle }</h2>
                              <p><span style={{padding: '0 15px 0px 0', color: '#fff', fontSize: '20px'}}>SPARTA can help</span> <Link to={ this.props.link } target="_blank" className="open-free-account">OPEN MY FREE ACCOUNT</Link></p>
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" className="right-blk">
                          <div className="img-blk">
                            <img className="banner-image" src={imgStoragePath+"landing/Group%2030.png"} alt="image" />
                          </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
