import React, { Component } from 'react';
import './assets/styles/switch.scss';

export default class Switch extends Component {
    constructor ( props ) {
        super( props );
    }
    render () {
        return(
            <div className="switch-container" onClick={this.props.disabled == 'disabled' ? null : this.props.onChange}>
                <label>
                    <input disabled={this.props.disabled} ref="switch" checked={ this.props.isChecked } className="switch" type="checkbox" />
                    <div>
                        <span><i className="icon icon-toolbar grid-view"></i></span>
                        <span><i className="icon icon-toolbar ticket-view"></i></span>
                        <div></div>
                    </div>
                </label>
                <div className="switch-content">{this.props.children}</div>
            </div>
        )
    }
}
