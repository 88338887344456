import * as actions from '../actions';
export default function( state = {
  licensList : [],
  plansList:{},
  selectedPlansList:{},
  cartList : {
    list:[]
  },
  licenseLoader: false,
  cardsLoader: false,
  cardsList:{
    list: [],
    default_card_id: ''
  },
  stripePreview:[],
  referralHistory: [],
  cardDetail: {
    flag: false,
    data:{
      brand: ''
    }
  }
}, action){
  switch (action.type) {
    case actions.UPDATE_CARD_DETAIL:
    return{
      ...state,
      cardDetail: {
        ...state.cardDetail,
        data:{
          ...state.cardDetail.data,
          [action.payload.key]: action.payload.value
        }
      }
    }
    case actions.INOVICE_PREVIEW:
     return {
      ...state,
      stripePreview: action.payload
    }
    case actions.CARD_DETAIL:
     return {
      ...state,
      cardDetail: action.payload
    }
    case actions.REFERRAL_HISTORY:
     return {
      ...state,
      referralHistory: action.payload
    }
    case actions.CARDS_LIST:
     return {
      ...state,
      cardsList: action.payload
    }
    case actions.LICENSE_LIST:
     return {
      ...state,
      licensList: action.payload
    }
    case actions.LICENSE_LOADER:
     return {
      ...state,
      licenseLoader: action.payload
    }
    case actions.CARDS_LOADER:
     return {
      ...state,
      cardsLoader: action.payload
    }
    case actions.PLANS_LIST:
     return {
      ...state,
      plansList: action.payload
    }
    case actions.SELECTED_PLANS_LIST:
     return {
      ...state,
      selectedPlansList: action.payload
    }
    case actions.INCREMENT:
      return{
        ...state,
        plansList: {...state.plansList, plans: state.plansList.plans.map((item, key) => action.payload.key == key ? {...item, quantity: action.payload.quantity} : item)}
    }
    case actions.DECREMENT:
      return{
        ...state,
        plansList: {...state.plansList, plans: state.plansList.plans.map((item, key) => action.payload.key == key ? {...item, quantity: action.payload.quantity} : item)}
    }
    case actions.INTERVAL_TOGGLE:
      return{
        ...state,
        plansList: {
          ...state.plansList,
          plans: state.plansList.plans.map((item, key) => action.payload.key == key ? {...item, details: item.details.map((detail,key2) => detail.is_default ? {...detail, is_default: false} : {...detail, is_default: true})} : item)
        }
    }
    case actions.CART_LIST:
     return {
      ...state,
      cartList: action.payload
    }
    default:
      return state;
  }
}
