import React, { Component } from 'react';
import { imgStoragePath } from '../../../../constants';
export default class EngineHeader extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return(
      <thead>
        <tr>
          <th className="padding-left-46">Shop Visits</th>
          <th>Shop Visit Date </th>
          <th>Escalated MR Rate <br/>as of Shop Visit Date</th>
          <th>Escalated Shop Visit Cost <br/>as of Shop Visit Date</th>
          <th>Fund Status</th>
          <th>
            Balance<br/>
            <span>
              <img style={{width: '20px'}} src={imgStoragePath+"Carryover.png"} alt="icon" /> Carried Over to NSV
            </span>
          </th>
        </tr>
      </thead>
    )
  }
}
