import React from 'react';
import { getLocalStorageInfo } from '../../../../utils';
const ActivityComponent = (props) =>{
  switch (props.id) {
    case 1:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name} uploaded {props.count > 1 ? props.count>3 ? '3+ files':props.count+' files': 'the file ' } {props.count > 1  ? null:<b className="last-bold">{props.fileName}</b>} to <b className="last-bold">{props.toFolder}</b> folder</td>
        )
      case 2:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' renamed the file from '}<b className="last-bold"><s>{props.oldName != null ?props.oldName:'--'}</s></b> to <b className="last-bold">{props.fileName}</b></td>
        )
      case 3:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name} published {props.count > 1 ? props.count>3 ? '3+ files':props.count+' files': 'the file ' } {props.count > 1  ? null:<b className="last-bold">{props.fileName}</b>}</td>
        )
      case 4:
      return (
      <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name} rejected {props.count > 1 ? props.count>3 ? '3+ files': props.count+' files' :'the file ' } {props.count > 1  ? null:<b className="last-bold">{props.fileName}</b>}</td>
        )
      case 5:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' restored the file '}<b className="last-bold">{props.fileName}</b> from Rejected Queue</td>
        )
      case 6:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' moved the file '}<b className="last-bold">{props.fileName}</b> to <b className="last-bold">{props.toFolder}</b> folder</td>
        )
      case 7:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' moved the folder '}<b className="last-bold">{props.fileName}</b> to <b className="last-bold">{props.toFolder}</b> folder</td>
        )
      case 8:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' deleted the file '}<b className="last-bold">{props.fileName}</b> </td>
        )
      case 9:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' deleted the folder '}<b className="last-bold">{props.fileName}</b> </td>
        )
      case 10:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' restored the file '}<b className="last-bold">{props.fileName}</b> {' from Recycle Bin'}</td>
        )
      case 11:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' restored the folder '}<b className="last-bold">{props.fileName}</b> {' from Recycle Bin'}</td>
        )
      case 12:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' permanently deleted the file '}<b className="last-bold">{props.fileName}</b></td>
        )
      case 13:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' permanently deleted the folder '}<b className="last-bold">{props.fileName}</b></td>
      )
      case 14:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' enabled share link for file '}<b className="last-bold">{props.fileName}</b>{props.oldName != null && props.oldName != undefined && parseInt(props.oldName) != 0 ? (props.oldName/24) > 1 ? ` for ${(props.oldName/24)} Days` : ` for ${(props.oldName/24)} Day` : ''}</td>
        )
      case 15:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' enabled share link for folder ' }<b className="last-bold">{props.fileName}</b>{props.oldName != null && props.oldName != undefined && parseInt(props.oldName) != 0 ? (props.oldName/24) > 1 ? ` for ${(props.oldName/24)} Days` : ` for ${(props.oldName/24)} Day` : ''}</td>
      )
      case 16:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' disabled share link for file ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 17:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' disabled share link for folder ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 18:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' updated share link duration for file '}<b className="last-bold">{props.fileName}</b>{props.oldName != null && props.oldName != undefined && parseInt(props.oldName) != 0 ? (props.oldName/24) > 1 ? ` to ${(props.oldName/24)} Days` : ` for ${(props.oldName/24)} Day` : ''}</td>
        )
      case 19:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' updated share link duration for folder ' }<b className="last-bold">{props.fileName}</b>{props.oldName != null && props.oldName != undefined && parseInt(props.oldName) != 0 ? (props.oldName/24) > 1 ? ` to ${(props.oldName/24)} Days` : ` for ${(props.oldName/24)} Day` : ''}</td>
      )
      case 20:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' unpublished ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 21:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' collaborated ' }<b className="last-bold">{props.fileName}</b> with {props.oldName}</td>
      )
      case 22:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' created folder ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 23:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' downloaded file ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 24:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' downloaded folder ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 25:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' added ' }<b className="last-bold">{props.fileName}</b> to {props.oldName}</td>
      )
      case 26:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' exported ' }<b className="last-bold">{props.fileName}</b></td>
      )
      case 27:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' Changed OCR Priority of ' }<b className="last-bold">{props.fileName}</b> to {props.oldName}</td>
      )
      case 28:
      return (
        <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' Removed the file ' }<b className="last-bold">{props.fileName}</b> from {props.oldName}</td>
      )
      case 30:
        return (
          <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' Visited ' }<b className="last-bold">{props.fileName}</b></td>
        )
      case 31:
        return (
          <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' Previewed ' }<b className="last-bold">{props.fileName}</b></td>
        )
      case 33:
        return (
          <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' Downloaded ' }<b className="last-bold">{props.fileName}</b></td>
        )
      case 34:
        return (
          <td>{getLocalStorageInfo().user.id==props.userId?'You':props.name}{' Downloaded ' }<b className="last-bold">{props.fileName}</b></td>
        )
      default:
      return(
        <td> -- </td>
      )
  }
}

export default ActivityComponent
