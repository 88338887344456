import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { getLocalStorageInfo } from '../../../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { displayDateFormat, backendDateFormat, days,securityGroups, imgStoragePath } from '../../../../constants';
export default class MRRentalInvoiceConf extends Component {
  constructor(props){
    super(props);
    this.state = {
      rentalInvoiceView: {},
      adminFlag:false,
      contracterFlag:false,
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.rentalInvoiceView));
    delete data['lease_type'];
    delete data['lease_type_accounting'];
    data = {
      ...data,
      rental_type : this.props.rentalInvoiceView.rental_type && this.props.rentalInvoiceView.rental_type.value ? this.props.rentalInvoiceView.rental_type.value : null,
      rental_interval : this.props.rentalInvoiceView.rental_interval && this.props.rentalInvoiceView.rental_interval.value ? this.props.rentalInvoiceView.rental_interval.value : null,
      rental_escalation_interval : this.props.rentalInvoiceView.rental_escalation_interval && this.props.rentalInvoiceView.rental_escalation_interval.value ? this.props.rentalInvoiceView.rental_escalation_interval.value : null,
      lease_status : this.props.rentalInvoiceView.lease_status && this.props.rentalInvoiceView.lease_status.value ? this.props.rentalInvoiceView.lease_status.value : null
    }
    this.setState({
      rentalInvoiceView: data
    });
    this.props.rentalInvoiceView.requested_data && Object.keys(this.props.rentalInvoiceView.requested_data).length ?
    getLocalStorageInfo().user.is_root_user?
    this.setState({
      adminFlag: true
    }):
    getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
    this.setState({
      adminFlag: true
    }):
    this.setState({
      contracterFlag: true
    }):
    this.setState({
      contracterFlag: false,
      adminFlag:false
    })
  }

  capitalizeFirstLetter=(str)=> {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      rentalInvoiceView: {
        ...prevState.rentalInvoiceView,
        [type]: value
      }
    }))
  }

  updateFormEdit = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      rentalInvoiceView: {
        ...prevState.rentalInvoiceView,
        requested_data:{
          ...prevState.rentalInvoiceView.requested_data,
          [type]: value
        }
      }
    }))
  }
  updateRentalInvoiceAccept = (e, flag) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.rentalInvoiceView);
    data = {
      ...data,
      change_request:flag?1:2,
      is_rental:true
    }
    if(flag==1){
      this.props.updateRentalInvoice(data);
    }else{
      this.props.toggleReason(data);
    }
  }
  updateRentalInvoice = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.rentalInvoiceView);
    data = {
      ...data,
      is_rental:true
    }
    this.props.updateRentalInvoice(data);
  }
  render(){
    const { rentalInvoiceView,adminFlag,contracterFlag } = this.state;
    const {type} = this.props;
    return(
      <form onSubmit={(e) => this.updateRentalInvoice(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_invoice_day_of_month ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">
                  Invoice Generation Day ( For Every Month )
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Day'
                  keyParam="rental_invoice_day_of_month"
                  value={rentalInvoiceView.rental_invoice_day_of_month}
                  type={type}
                  options={days}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                />
              </FieldGroup>

                { rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_invoice_day_of_month ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">
                   Invoice Generation Day ( For Every Month ) (Edited) <span className="highter"></span>
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Day'
                  keyParam="rental_invoice_day_of_month"
                  value={rentalInvoiceView.requested_data.rental_invoice_day_of_month}
                  type={type}
                  options={days}
                  updateField={this.updateFormEdit}
                  labelKey= 'label'
                  valueKey='value'
                />
              </FieldGroup>:null}

            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_invoice_due_day_of_month ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Invoice Due Day ( For Every Month )</FieldLabel>
                  <FieldSelect
                    placeholder='Select Day'
                    keyParam="rental_invoice_due_day_of_month"
                    value={rentalInvoiceView.rental_invoice_due_day_of_month}
                    type={type}
                    options={days}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
              </FieldGroup>

             {rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_invoice_due_day_of_month ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Invoice Due Day ( For Every Month ) (Edited) <span className="highter"></span></FieldLabel>
                  <FieldSelect
                    placeholder='Select Day'
                    keyParam="rental_invoice_due_day_of_month"
                    value={rentalInvoiceView.requested_data.rental_invoice_due_day_of_month}
                    type={type}
                    options={days}
                    updateField={this.updateFormEdit}
                    labelKey= 'label'
                    valueKey='value'
                  />
              </FieldGroup>:null}

            </FieldCol>
            <Col md="12">
              <div className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_invoice_is_due_day_next_month ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <label className="label">
                  <input type="checkbox" name="mr-invoice" checked={rentalInvoiceView.rental_invoice_is_due_day_next_month} onChange={(e) => this.updateForm('rental_invoice_is_due_day_next_month', e.target.checked)} />
                  <span> Invoice Due Day falls in next Month</span>
                </label>
              </div>
             {rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_invoice_is_due_day_next_month ?
              <div className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <label className="label">
                  <input type="checkbox" name="mr-invoice" checked={rentalInvoiceView.requested_data.rental_invoice_is_due_day_next_month} onChange={(e) => this.updateFormEdit('rental_invoice_is_due_day_next_month', e.target.checked)} />
                  <span>  Invoice Due Day falls in next Month (Edited) <span className="highter"></span></span>
                </label>
              </div>:null}

            </Col>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_description ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Description</FieldLabel>
                <FieldTextarea
                  value={rentalInvoiceView.rental_description}
                  type={type}
                  keyParam="rental_description"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_description ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Description (Edited) <span className="highter"></span></FieldLabel>
                <FieldTextarea
                  value={rentalInvoiceView.requested_data.rental_description}
                  type={type}
                  keyParam="rental_description"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}

            </FieldCol>
            <FieldCol md="12">
            <h5 className="para-ui escaltion-seperator">Invoice Sender </h5>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_from_company_name ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Invoice From</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_from_company_name}
                  type={type}
                  keyParam="rental_from_company_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_from_company_name ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Invoice From (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.requested_data.rental_from_company_name}
                  type={type}
                  keyParam="rental_from_company_name"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_from_company_address ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Company Address</FieldLabel>
                  <FieldTextarea
                  value={rentalInvoiceView.rental_from_company_address}
                  type={type}
                  keyParam="rental_from_company_address"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            {rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_from_company_address ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Company Address (Edited) <span className="highter"></span></FieldLabel>
                  <FieldTextarea
                  value={rentalInvoiceView.requested_data.rental_from_company_address}
                  type={type}
                  keyParam="rental_from_company_address"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_from_company_contact ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Contact Details</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_from_company_contact}
                  type={type}
                  keyParam="rental_from_company_contact"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_from_company_contact ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Contact Details (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.requested_data.rental_from_company_contact}
                  type={type}
                  keyParam="rental_from_company_contact"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12">
                <h5 className="para-ui escaltion-seperator">Invoice Receiver</h5>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_to_company_name ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Invoice To</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_to_company_name}
                  type={type}
                  keyParam="rental_to_company_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              {rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_to_company_name ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Invoice To (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.requested_data.rental_to_company_name}
                  type={type}
                  keyParam="rental_to_company_name"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_to_company_address ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Company Address</FieldLabel>
                <FieldTextarea
                  value={rentalInvoiceView.rental_to_company_address}
                  type={type}
                  keyParam="rental_to_company_address"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_to_company_address ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Company Address (Edited) <span className="highter"></span></FieldLabel>
                <FieldTextarea
                  value={rentalInvoiceView.requested_data.rental_to_company_address}
                  type={type}
                  keyParam="rental_to_company_address"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_to_company_contact ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Contact Details</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_to_company_contact}
                  type={type}
                  keyParam="rental_to_company_contact"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { rentalInvoiceView.requested_data && rentalInvoiceView.requested_data.rental_to_company_contact ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Contact Details (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.requested_data.rental_to_company_contact}
                  type={type}
                  keyParam="rental_to_company_contact"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <h5 className="para-ui escaltion-seperator"> Bank Details</h5>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Name</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_name}
                  type={type}
                  keyParam="rental_bank_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Edited Bank Name</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_name}
                  type={type}
                  keyParam="rental_bank_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Account Name</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_account_name}
                  type={type}
                  keyParam="rental_bank_account_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Account Name</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_account_name}
                  type={type}
                  keyParam="rental_bank_account_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Account Number</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_account_number}
                  type={type}
                  keyParam="rental_bank_account_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Account Number</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_account_number}
                  type={type}
                  keyParam="rental_bank_account_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank IBAN</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_iban}
                  type={type}
                  keyParam="rental_bank_iban"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank IBAN</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_iban}
                  type={type}
                  keyParam="rental_bank_iban"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Swift Code</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_swift_code}
                  type={type}
                  keyParam="rental_bank_swift_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank Swift Code</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_swift_code}
                  type={type}
                  keyParam="rental_bank_swift_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank SORT Code</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_sort_code}
                  type={type}
                  keyParam="rental_bank_sort_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank SORT Code</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_sort_code}
                  type={type}
                  keyParam="rental_bank_sort_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Hub Account</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_sort_code}
                  type={type}
                  keyParam="rental_bank_sort_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Bank SORT Code</FieldLabel>
                <FieldInput
                  value={rentalInvoiceView.rental_bank_sort_code}
                  type={type}
                  keyParam="rental_bank_sort_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12" style={{ display: 'none' }}>
              <FieldGroup className="form-group" >
                <FieldLabel className="label">Footer Note</FieldLabel>
                <FieldTextarea
                  value={rentalInvoiceView.rental_footer_note}
                  type={type}
                  keyParam="rental_footer_note"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              <FieldGroup className="form-group" >
                <FieldLabel className="label">Footer Note</FieldLabel>
                <FieldTextarea
                  value={rentalInvoiceView.rental_footer_note}
                  type={type}
                  keyParam="rental_footer_note"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
      {rentalInvoiceView.requested_data && Object.keys(rentalInvoiceView.requested_data).length ?
      getLocalStorageInfo().user.is_root_user?
      <div className="submit-block">
      <Link style={{marginRight:'10px'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,true)} className="primary-btn" >Accept</Link>
      <Link style={{marginRight:'10px',background:'#ff5e5e',border: '1px solid #ff5e5e'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,false)} className="primary-btn" >Reject</Link>
      <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
      <Link className="auditlog-btn" style={{float:'right',display:'none',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} />Revision History</Link>
      {rentalInvoiceView.requested_data?rentalInvoiceView.requested_data&&rentalInvoiceView.requested_data.created_by&&rentalInvoiceView.requested_data.created_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(rentalInvoiceView.requested_data.created_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',display:'none',
       marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {rentalInvoiceView.requested_data&&rentalInvoiceView.requested_data.created_by?this.capitalizeFirstLetter(rentalInvoiceView.requested_data.created_by.first_name+' '+rentalInvoiceView.requested_data.created_by.last_name)+' at '+ moment(rentalInvoiceView.requested_data.created_at).format(displayDateFormat):'--'}
      </div>:null
      }
      </div>:
      getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
      <div className="submit-block">
      <Link style={{marginRight:'10px'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,true)} className="primary-btn" >Accept</Link>
      <Link style={{marginRight:'10px',background:'#ff5e5e',border: '1px solid #ff5e5e'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,false)} className="primary-btn" >Reject</Link>
      <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
      <Link className="auditlog-btn" style={{float:'right',display:'none',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} />Revision History</Link>
      {rentalInvoiceView.requested_data?rentalInvoiceView.requested_data&&rentalInvoiceView.requested_data.created_by&&rentalInvoiceView.requested_data.created_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(rentalInvoiceView.requested_data.created_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',display:'none',
       marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {rentalInvoiceView.requested_data&&rentalInvoiceView.requested_data.created_by?this.capitalizeFirstLetter(rentalInvoiceView.requested_data.created_by.first_name+' '+rentalInvoiceView.requested_data.created_by.last_name)+' at '+ moment(rentalInvoiceView.requested_data.created_at).format(displayDateFormat):'--'}
      </div>:null
      }
      </div>:
      <div className="submit-block">
      <Link  style={{marginRight:'10px'}}  className="primary-btn" >In Review</Link>
      <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
      <Link className="auditlog-btn" style={{float:'right',display:'none',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} />Revision History</Link>
      {rentalInvoiceView.requested_data?rentalInvoiceView.requested_data&&rentalInvoiceView.requested_data.created_by&&rentalInvoiceView.requested_data.created_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(rentalInvoiceView.requested_data.created_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {rentalInvoiceView.requested_data&&rentalInvoiceView.requested_data.created_by?this.capitalizeFirstLetter(rentalInvoiceView.requested_data.created_by.first_name+' '+rentalInvoiceView.requested_data.created_by.last_name)+' at '+ moment(rentalInvoiceView.requested_data.created_at).format(displayDateFormat):'--'}
      </div>:null
      }
      </div>:
      getLocalStorageInfo().user.is_root_user?
      <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          <Link className="auditlog-btn" style={{float:'right',display:'none',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} />Revision History</Link>
          {rentalInvoiceView.rental_last_updated_by?rentalInvoiceView.rental_last_updated_by&&rentalInvoiceView.rental_last_updated_by.id&&rentalInvoiceView.rental_last_updated_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(rentalInvoiceView.rental_last_updated_by).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {rentalInvoiceView.rental_last_updated_by&&rentalInvoiceView.rental_last_updated_by.first_name?this.capitalizeFirstLetter(rentalInvoiceView.rental_last_updated_by.first_name+' '+rentalInvoiceView.rental_last_updated_by.last_name)+' at '+ moment(rentalInvoiceView.rental_last_updated_at).format(displayDateFormat):'--'}
      </div>:null
      }
       </div>:
       getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
       <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          <Link className="auditlog-btn" style={{float:'right',display:'none',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} />Revision History</Link>
          {rentalInvoiceView.rental_last_updated_by?rentalInvoiceView.rental_last_updated_by&&rentalInvoiceView.rental_last_updated_by.id&&rentalInvoiceView.rental_last_updated_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(rentalInvoiceView.rental_last_updated_by).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {rentalInvoiceView.rental_last_updated_by&&rentalInvoiceView.rental_last_updated_by.first_name?this.capitalizeFirstLetter(rentalInvoiceView.rental_last_updated_by.first_name+' '+rentalInvoiceView.rental_last_updated_by.last_name)+' at '+ moment(rentalInvoiceView.rental_last_updated_at).format(displayDateFormat):'--'}
      </div>:null
      }
       </div>:
     <div className="submit-block">
     <input type="submit" className="primary-btn" value="Submit"/>
     <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
     <Link className="auditlog-btn" style={{float:'right',display:'none',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} />Revision History</Link>
     {rentalInvoiceView.rental_last_updated_by?rentalInvoiceView.rental_last_updated_by&&rentalInvoiceView.rental_last_updated_by.id&&rentalInvoiceView.rental_last_updated_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(rentalInvoiceView.rental_last_updated_by).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',display:'none',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {rentalInvoiceView.rental_last_updated_by&&rentalInvoiceView.rental_last_updated_by.first_name?this.capitalizeFirstLetter(rentalInvoiceView.rental_last_updated_by.first_name+' '+rentalInvoiceView.rental_last_updated_by.last_name)+' at '+ moment(rentalInvoiceView.rental_last_updated_at).format(displayDateFormat):'--'}
      </div>:null
      }
    </div>
     }
     </form>
    )
  }
}
