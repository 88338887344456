import React, { Component } from 'react';
import { Link } from 'react-router';
import { ToolTipHover } from '../../../../shared';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../../assets/styles/records_v.scss';
import { displayDateTimeFormat, displayDateFormat, imgStoragePath } from '../../../../constants';
import moment from 'moment';
import { getFileSize, getAccessToken, getLocalStorageInfo } from '../../../../utils';
export default class MiniPackList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }


  render(){
    const { listItem, index } = this.props;
    return(
      <div className="asset-list" >
        <Row className="flex-centered">
        {
          listItem.type === 'file' ?
          <Col md="8">
              <div className="flex-centered msn-block record-preview">
              <div>
                  {this.props.recycleBin && !this.props.actions ? <input style={{ marginRight: '10px'}} onClick={this.props.addToRecycle} checked={this.props.recycleList.filter(item => item.uuid == listItem.uuid).length ? true : false } type="checkbox"/>:null}
                </div>
                <div><img src={listItem.type == "file" ? this.props.fileIcons[listItem.extension] : imgStoragePath+"folder_icn3.png"} alt="img" width="20"/></div>
              <Link to={(listItem.type == "file" && listItem.extension == 'pdf' && this.props.recycleBin) ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${listItem.path}`: (listItem.extension == 'csv' || listItem.extension == 'xls' || listItem.extension == 'xlsx') && this.props.recycleBin ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${listItem.path}` : ''} target="_blank"><span style={{fontSize:'14px', position: 'relative', top: '0px', right: '0px'}}>{listItem.name}</span></Link>
              </div>
              <h6 className="para-ui" style={{fontSize: '12px'}}>{listItem.location}</h6>
          </Col>
          :<Col md="8" onClick={() => this.props.getFilesList()}>
              <div className="flex-centered msn-block record-preview">
              <div>
                  {this.props.recycleBin && !this.props.actions ? <input style={{ marginRight: '10px'}} onClick={this.props.addToRecycle} checked={this.props.recycleList.filter(item => item.uuid == listItem.uuid).length ? true : false } type="checkbox"/>:null}
                </div>
                <div><img src={listItem.type == "file" ? this.props.fileIcons[listItem.extension] : imgStoragePath+"folder_icn3.png"} alt="img" width="20"/></div>
              <Link ><span style={{fontSize:'14px', position: 'relative', top: '0px', right: '0px'}}>{listItem.name}</span></Link>
              {
                listItem.share_enabled ?
                <span>
                <img id={"ShareLinkEnabled-"+listItem.id} src={imgStoragePath+"share_icon.png"} style={{height: '15px', marginLeft: '5px'}}/>
                  <ToolTipHover placement="top" tagetId={"ShareLinkEnabled-"+listItem.id}>Share Link Enabled</ToolTipHover>
                </span>
                :null
              }
              </div>
              <h6 className="para-ui" style={{fontSize: '12px'}}>{listItem.location}</h6>
          </Col>
        }
          <Col md="2">

            <h6 className="para-ui">
              {
                listItem.size != 0 && listItem.size != null && listItem.size != undefined?
                `${getFileSize(listItem.size)}`:'--'
              }
            </h6>
         </Col>
            {
              listItem.type === "folder" ?
              <Col md="1">
               <h6 className="para-ui">{listItem.file_count ? listItem.file_count : '--'}</h6>
              </Col>
              :null
            }
            {
              listItem.type === "folder" ?
              <Col md="1">
               <span onClick={() => this.props.shareRecord()} style={{color: '#308cfe', fontSize: '12px', cursor: 'pointer'}}>Share</span>
              </Col>
              :null
            }
         {this.props.dataKit ?
           <Col md="1" className="text-right status-block bucket-action" onClick={() => this.props.removeRecord()}>
             {getLocalStorageInfo().user.permission['records']['recycle_bin']&&
               getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('REJ') != -1 && listItem.type != 'folder' ?
            <span style={{color: '#ff5e5e'}} >
             Remove
           </span>:null}
           </Col> : null
         }
        </Row>
      </div>
    )
  }
}
