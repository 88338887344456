import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import GrpAmountField from '../containers/GrpAmountField';
import GrpEscalationField from '../containers/GrpEscalationField';
export default class GrpEngineRate extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { metrixType, programType, engine, newengine, simulationType } = this.props;
    return(
      <Row className="utilization-block">
        <Col md="6">
          <label>Performance Restoration Shop Visit ( PRSV )</label>
          <ul className="list-inline">
            <li className="list-inline-item margin-bottom-15-xs ">
              <span className="label">MR Rate</span>
              <GrpAmountField metrixType={metrixType} keyParam="engine_mr" programType={programType} newvalue={simulationType == 2 ? newengine.engine_mr:'' } value={engine.engine_mr} intervalUnit={engine.prsv_mr_interval}/>
            </li>
            <li className="list-inline-item margin-bottom-15-xs ">
              <span className="label">MR Rate Escalation </span>
              <GrpEscalationField metrixType={metrixType} keyParam="engine_escalation" programType={programType} newvalue={simulationType == 2 ? newengine.engine_escalation:'' } value={engine.engine_escalation} />
            </li>
            <li className="list-inline-item margin-bottom-15-xs ">
              <span className="label">PRSV Cost Escalation </span>
              <GrpEscalationField metrixType={metrixType} keyParam="prsv_cost_escalation" programType={programType} newvalue={simulationType == 2 ? newengine.prsv_cost_escalation:'' } value={engine.prsv_cost_escalation} />
            </li>
          </ul>
        </Col>
        <Col md="6">
          <label>LLP Replacement Shop Visit</label>
          <ul className="list-inline">
            <li className="list-inline-item margin-bottom-15-xs ">
              <span className="label">MR Rate</span>
              <GrpAmountField metrixType={metrixType} keyParam="llp_mr" programType={programType} newvalue={simulationType == 2 ? newengine.llp_mr:'' } value={engine.llp_mr} intervalUnit={engine.llp_mr_interval}/>
            </li>
            <li className="list-inline-item margin-bottom-15-xs ">
              <span className="label">LLP Escalation </span>
              <GrpEscalationField metrixType={metrixType} keyParam="llp_escalation" programType={programType} newvalue={simulationType == 2 ? newengine.llp_escalation:'' } value={engine.llp_escalation} />
            </li>
            <li className="list-inline-item margin-bottom-15-xs ">
              <span className="label">LLP Cost Escalation </span>
              <GrpEscalationField metrixType={metrixType} keyParam="llp_cost_escalation" programType={programType} newvalue={simulationType == 2 ? newengine.llp_cost_escalation:'' } value={engine.llp_cost_escalation} />
            </li>
          </ul>
        </Col>
      </Row>
    )
  }
}
