import React from 'react';
import moment from 'moment';
import { displayDateTimeFormatShort } from '../../../constants';
const DownloadedLogs = (props) => {
  return (
    <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
      <ul className="list-unstyled" >
        {props.logs.map((user, index) =>
          <li style={{borderBottom: '1px solid #d7d7d7', padding: '8px 0', fontSize: '14px'}} key={index}>{user.user}, <b style={{fontSize:'15px'}}>{moment(user.downloaded_at).format(displayDateTimeFormatShort)+' UTC'}</b></li>
        )}
      </ul>
    </div>

  )
}
export default DownloadedLogs;
