import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { invoiceDashboardStatus } from '../../../../constants';
import moment from 'moment';
import { displayDateFormat, displayDateFormatShort } from '../../../../constants';
import { browserHistory } from 'react-router';
import { dateTransform, showCurrencyFormat  } from '../../../../utils';
export default class DashboardInvoiceRow extends Component{
  constructor(props){
    super(props);
  }
  redirctInvView = () => {
    browserHistory.push('/'+this.props.params.type + '/' + this.props.params.aircraft_slug +'/contract/'+this.props.contract_slug+'/invoice/view/' + this.props.invoice.id);
  }


  render(){
    const { invoice } = this.props;
    const invoiceType = {
      1: 'Maintenance',
      2: 'Rental',
      3: 'Penalty',
      4: 'Debit Note',
      5: 'Credit Note',
      6: 'Security Deposit',
      7: 'Insurance',
      8: 'Insurance Claim',
    }
    return(
      <tr onClick={this.redirctInvView} className={invoice.in_due ? 'in-due' : ''}>
        <td>{invoiceType[invoice.invoice_type]}</td>
        <td>{invoice.invoice_number}</td>
        <td>{moment(invoice.date).format(displayDateFormatShort)}</td>
        <td>{invoice.due_date ? moment(invoice.due_date).format(displayDateFormatShort):'--'}</td>
        <td>{invoice.total_amount ?
              invoice.invoice_type==2?
              invoice.children.length>0?
              <div>
              <div>
              <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
              </div>
              <NumericLabel params={showCurrencyFormat(invoice.children[0].currency)}>{invoice.children[0].sub_total}</NumericLabel>
              </div>
              :
              <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
              :
              <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
             : '--' }
        </td>
        <td>
          <span className="over-due-text">
            {invoice.in_due
              ? 'Overdue By '+ dateTransform(invoice.due_date)
            :
            <span className={invoiceDashboardStatus[invoice.status] +' invoice-status'}>{ invoiceDashboardStatus[invoice.status]}</span>
            }
          </span>
          {
            invoice.status == 3 && invoice.paid_date ?
            <span style={{ display: 'block', marginTop:'4px', textTransform: 'none', fontSize: '10px', color: '#000', lineHeight: '12px', fontWeight: 700}}>
              Payment Date: {moment(invoice.paid_date).format(displayDateFormatShort)}
              {moment(invoice.paid_date).diff(moment(invoice.due_date), 'days') > 0 ? <span style={{ display: 'block', marginTop:'4px', color: '#ff0000', fontSize: '9px', lineHeight: '12px', textTransform: 'none', fontWeight: 700}}>{"Payment late by " + dateTransform(invoice.due_date, invoice.paid_date)}</span> :''}
            </span>:
            null
          }
        </td>
      </tr>
    )
  }
}
