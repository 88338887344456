import {globalGetService, globalExportService} from '../../../globalServices';
import { toastFlashMessage, downloadFileType} from '../../../utils';

export function getActivityLogsApi(props, query={}){
    this.setState({pageLoader:true});
    globalGetService(`/records/${props.params.type}/${props.params.aircraft_slug}/dashboard-activities/`, query)
    .then(response => {
        this.setState({pageLoader:false});
      if(response.data.statusCode === 200){
        this.setState({openFilterSidebar:false,})
        if((query.page && query.page == 1) || query.page === undefined || query.page === null){
          this.setState({
            activityList:response.data.data,
            appliedFilter: query
          })
        }else{
          this.setState(prevState=>({
            ...prevState,
            activityList:{
              ...prevState.activityList,
              list:[...prevState.activityList.list, ...response.data.data.list],
              pagination: response.data.data.pagination
            },
            appliedFilter: query
          }))
        }
      }
    })
  }

  export function exportActivityLogApi(props, query={}){
    this.setState({ pageLoader: true });
    let showToastFlash = true
    Object.keys(this.state.appliedFilter).map(key => {
      if(this.state.appliedFilter[key] !== null){
        showToastFlash = false
      }
    })
    if(showToastFlash){
      toastFlashMessage('Activity Export might take time, for quicker export apply filters for specifics.', 'success')
    }
    globalExportService(`records/${props.params.type}/${props.params.aircraft_slug}/dashboard-activities/`,query)
    .then(response => {
      this.setState({ pageLoader: false });
      toastFlashMessage('Activity Export will be shared to your registered eMail ID', 'success')
    })
  }

  export function getUsersListApi(){
    globalGetService(`/records/activity-user-dropdown/`)
    .then(response => {
      this.setState({
        users: response.data.data
      })
    })
  }
  export function getActivityTypeListApi(){
    globalGetService(`/records/get-activity-type-list/`)
    .then(response => {
      this.setState({
        activity: response.data.data
      })
    })
  }
