import React from 'react';
class ImageInputField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value
    })
  }
  componentWillReceiveProps(nextProps){

  }

  onChange = (value) => {
    this.setState({
      value: value,
      error: ''
    })
  }
  render(){
    const { type } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
        <div className="tech-sum-item form-group">
          <label className="label">
            {this.props.children} { this.state.required? <sup className="reqStar"> * </sup> :null}
            {this.props.error != '' ? <span className="reqStar">{this.props.error}</span> : null}
          </label>
          {type == 'View' ?
            <h6>{this.props.value}</h6> :
              <input
            type="file"
            placeholder= {this.props.placeholder}
            value={this.state.value}
            onChange={(evt) => this.onChange(evt.target.value)}
            onBlur={(evt) => this.props.onBlur(evt.target.value)}
          />
          }
        </div>
    )
  }
}
export default ImageInputField;
