import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import TableFilter from '../../../../shared/TableFilter';
import { displayDateFormat } from '../../../../constants';
require('highcharts/modules/exporting')(Highcharts);

export default class UtilizationGraph extends Component{
  constructor(props){
    super(props);
  }

  changeFilterFn=(filter)=>{
    this.props.fetchManPowerUtilization(filter);
  }

  render(){
    const { title, type, color, lineColor, plots, events, yType, legend, xLabel, yLabel, toolTipText, symbol,startDate } = this.props;
    let data = [];
    let categories = [];

    // Object.keys(plots).map( year => {
    //   let d = [];
    //   d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
    //   d.push(plots[year]);
    //   data.push(d);
    // });
    // let d = [];
    //   d.push(new Date('01/01/2019').getTime());
    //   d.push(50);
    //   data.push(d);
    let year=moment(startDate,"YYYY-MM-DD").year();
    for (let i = 1; i < 13; i++) {
       let d = [];
      if(plots[i]==null){
       categories.push(moment(new Date(year+"/"+i+"/01")).format('MMM YYYY'));
       data.push(0);
      }else{
        categories.push(moment(year+"/"+i+"/01").format('MMM YYYY'));
        
        data.push(parseFloat((plots[i].percentage).toFixed(2)));
      }
       // data.push(d);
    }

    const options = {
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: ''
      },
      xAxis: {
        title: {
          text: xLabel
        },
        categories: categories
      },
      yAxis:{
        title: {
          text: yLabel
        },
        labels: {
          formatter: function () {
            return this.value+"%";
          }
        },
        gridLineDashStyle: 'longdash'
      },
      tooltip: {
        formatter: function () {
            return 'Month <b>' + this.x +
                '<br/></b>Percentage(%) <b>' + this.y + '</b>';
        }
      },
      credits: {
        enabled: false
      },
      series:  [{
        showInLegend: false,
        name: legend,
        data: data,
        color: color,
        lineColor: lineColor,
        marker: {
          fillColor: lineColor
        }
      }],
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      }
    };
    const menuList = {
      'Year': {
        'inputType': 'text',
        'keyParam': 'utilization_year',
        'placeholder': 'Enter the year',
        'label': 'Year'
      }
    }

    return(
      <div className="finance-graph-blk">
       <div className="add-bulk-operations row" style={{marginBottom:'5px',padding:'0px', borderBottom: 'none'}} >
              <div className="table-list-filter col-md-12" style={{paddingBottom:'15px'}}>
              <TableFilter
                clearFilterValue={this.clearFilterValue}
                filter={{menuList: menuList, appliedFilter: {}}}
                changeFilterFn={this.changeFilterFn}
                queryParamsEnabled={false}
             />
            </div>
            </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    )
  }
}
