import React, { Component } from 'react';
import { connect } from 'react-redux';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { conventionAc, updateConventionAc } from '../actionCreators';
import { NoRecordFound, ListLoader } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils';
import { Link, withRouter, browserHistory } from 'react-router';
import { CONVENTION_EDIT } from '../actions';
import { EditSidebar, Header, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { sAuditLogAc } from '../../../../shared/actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import { imgStoragePath, assetsType } from '../../../../constants';
import { trackActivity } from '../../../../utils/mixpanel';
class CapeTownConvention extends Component {
    constructor(props) {
      super(props);
      this.state = ({
        capTowndesc : ''
      })
    }
    componentDidMount(){
        this.props.fetchConvention();
        const data = this.props.conventionData;
     }
     componentWillReceiveProps(nextProps){
      this.setState(prevState => ({
        ...prevState,
        capTowndesc: nextProps.conventionData.cape_town_clauses
      }));
    }
    updateText = (value) => {
      this.setState(prevState => ({
        ...prevState,
        capTowndesc: value
      }));
    }

    changeMsn =(evt) =>{
      if(evt.contracts == false){
        let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
        browserHistory.push(url)
      }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
        let url = '/'+ assetsType[evt.asset_type] +'/'+evt.slug+'/contracts';
        browserHistory.push(url);
      }else{
        let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/cape-town-convention';
        browserHistory.push(url);
        this.props.params.type = assetsType[evt.asset_type];
        this.props.params.aircraft_slug = evt.slug;
        this.props.params.contract_slug = evt.contract_slug;
        this.componentDidMount();
      }
    }

    editconventionFn = (e) => {
      e.preventDefault();
      const data  = {
        'cape_town_clauses' :this.state.capTowndesc
      }
      this.props.updateConventionFn(data, this.props.assetViewId);
    }
     render(){
         const {conventionData, assetViewId, conventionEditFlag,conventionLoader, auditLogView} = this.props;
         let textType = ""
         let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
         return(
        <div className="contract-container">
        <Header type="brief" headingText="Contracts" params={this.props.params} pageTitle="Cape Town Convention" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application="Capetowm Convention" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <ContractHeadertext
              title="Cape Town Convention"
              desc="Section holds Clauses under this Contract"
              addNew = ""
              addAudit="Revision History"
              toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctcontracts', 1, this.props.params.contract_slug, 'update', 'cape_town_clauses', 'params'); trackActivity('Revision History', { page_title: 'Cape Town Convention', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Cape Town Convention'})}}

           />
         { conventionLoader ? <ListLoader/>:null}
         <div className="list-container">
           <div className="table-container">
             <h5 className="capeTown-edit">
               Cape Town Convention
               {
                 getLocalStorageInfo().user.permission['contracts']['cape_town_convention'].indexOf('C') != -1 ?
                 <Link onClick = {() => this.props.editConventionFn(!conventionEditFlag)}>
                      {
                        conventionEditFlag  ?
                        <img src={imgStoragePath+"close_card.png"} alt="img"/>
                      : <img src={imgStoragePath+"icon_editblock.png"} alt="img"/>
                      }
                 </Link>
                 :null
               }
             </h5>
             <form onSubmit={(e) => this.editconventionFn(e)}>
             <table>
               <CtrTableHd theads={['Description']}/>
               <tbody >
                 <tr className="cp-convention">
                  <td>
                  <textarea  disabled={!conventionEditFlag} onChange={ conventionEditFlag ?(e) => this.updateText(e.target.value) : null} className={conventionEditFlag ? "convention-text" : "convention-text readonly"}  type="text"   value={this.state.capTowndesc != null? this.state.capTowndesc:''} >
                  {this.state.capTowndesc != null? this.state.capTowndesc:'' }
                   </textarea>
                  </td>
                 </tr>
               </tbody>
             </table>
             {
                conventionEditFlag  ?
                <div className="submit-block captwn-submit-block">
                  <Link onClick = {() => this.props.editConventionFn(!conventionEditFlag)}>
                     Cancel
                  </Link>
                  <input type="submit" className="primary-btn" value="Save Changes"/>
              </div>
              : null
              }
             </form>
           </div>
         </div>
        </div>

        <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
        >
         {(!Object.keys(auditLogView.data).length || auditLogView.data.length) && auditLogView.flag ?
           <div>
             <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index}/>
                  ) :
                  auditLogView.data.map((item, index) =>
                    <UpdateAuditLog auditLogView={item} key={index}/>
                  )
                }
            </div>
          </div>
          : <NoRecordFound title="No Revision History Found"/>
         }
        </EditSidebar>


           </div>
         )

         }

    }

      const mapStateToProps = state => ({
        conventionData: state.CapeTownConventionReducer.conventionData,
         conventionLoader: state.CapeTownConventionReducer.conventionLoader,
         conventionEditFlag:state.CapeTownConventionReducer.conventionEditFlag,
         auditLogView: state.sharedReducers.auditLogView,
         assetViewId: state.userAssetReducer.assetViewId
        })

      const mapDispatchToProps = (dispatch, ownProps) => {
        return {
        fetchConvention:() => dispatch(conventionAc(ownProps.params)),
        updateConventionFn: (data, assetViewId) => dispatch(updateConventionAc(ownProps.params, data, assetViewId)),
        toggleAuditlogFn: (application, content_type, action, objectId, type, paramType, paramKey) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramType, paramKey)),
        editConventionFn: (flag) => dispatch({
            type: CONVENTION_EDIT,
            payload: flag
          }),
        closeAuditSidebar: (data) => dispatch({
          type: S_AUDITLOG_ADD_DEL_VIEW,
          payload: data
        }),
        }
      }
      export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(CapeTownConvention, ['contracts','cape_town_convention','R']));
