import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class InShopServiceble extends Component {
  constructor(props) {
    super(props);

  }

  updateForm = (type, value) => {
    this.props.updateDetailData(type, value);
  }
  render() {
    const { type, assemblyName,inShopserv, techConstants } = this.props;
    return (
      <Row>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Release Date of the {assemblyName} </FieldLabel>
            <FieldDate
              value={inShopserv.release_date_of_the_engine}
              type={type}
              keyParam="release_date_of_the_engine"
              updateField={this.updateForm}
              />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">SV Report Provided </FieldLabel>
              <div className="flex-centered radio-button">
              <label className="label">
                  <input
                    type="radio"
                    name="shop_visit_report_status"
                    value="yes"
                    checked={inShopserv.shop_visit_report_status == 1 ? 'checked' : null}
                    onChange={(e) => this.updateForm('shop_visit_report_status', true)}
                  />
                <span>Yes</span>
                </label>
              <label className="label">
                <input
                  type="radio"
                  value="no"
                  name="shop_visit_report_status"
                  checked={inShopserv.shop_visit_report_status == 0 ? 'checked' : null}
                  onChange={(e) => this.updateForm('shop_visit_report_status', false)}
                />
                <span>No</span>
              </label>
            </div>
          </FieldGroup>
        </FieldCol>

        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Type of Shop Visit</FieldLabel>
            <FieldSelect
              placeholder='Select Type of Shop Visit'
              keyParam="type_of_shop_visit"
              value={inShopserv.type_of_shop_visit}
              type={type}
              options={techConstants.filter(item => item.type === 'shop_visit_type')}
              updateField={this.updateForm}
              labelKey='label'
              valueKey='value'
              name=""
            />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Expected Date of Shipment Back to Lessee</FieldLabel>
            <FieldDate
                value={inShopserv.expected_date_of_shipment_back_to_lessee}
                type={type}
                keyParam="expected_date_of_shipment_back_to_lessee"
                updateField={this.updateForm}
                />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Facility Name</FieldLabel>
            <FieldInput
                value={inShopserv.shop_visit_name}
                type={type}
                keyParam="shop_visit_name"
                updateField={this.updateForm}
                />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Facility Location</FieldLabel>
            <FieldInput
                value={inShopserv.shop_visit_location}
                type={type}
                keyParam="shop_visit_location"
                updateField={this.updateForm}
                />
          </FieldGroup>
        </FieldCol>
      </Row>
    )
  }
}
