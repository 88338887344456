import React from 'react';
import {  browserHistory } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsDB, imgStoragePath } from '../../../constants';
const DbClaimList = (props) => {
  let assetType = '';
  if(props.claim.asset.asset_type == 1){
    assetType = 'aircraft';
  }else if(props.claim.asset.asset_type == 2){
    assetType = 'engine';

  }
  return(
    <tr onClick={()=>{ props.claim.asset_type=='lg' ? browserHistory.push('/'+assetType+'/'+props.claim.asset.slug+'/maintenance/landing-gears/view/'+props.claim.shop_visit_id) : browserHistory.push('/'+assetType+'/'+props.claim.asset.slug+'/maintenance/'+props.claim.asset_type +'/view/'+props.claim.shop_visit_id)}}>
      <td>MSN {props.claim.asset.msn}</td>
      <td>{props.claim.name}</td>
      <td>{props.claim.claim_amount ? <NumericLabel params={prettyNumberParamsDB}>{props.claim.claim_amount}</NumericLabel>:'--'}</td>
      <td>{props.claim.status.label}</td>
      <td>{props.claim.amount_paid ? <NumericLabel params={prettyNumberParamsDB}>{props.claim.amount_paid}</NumericLabel>:'--'}</td>
      <td className="payout-status"><span className={props.claim.payout_status.label}>{props.claim.payout_status.label}</span></td>
      <td><img style= {{width: '21px', position: 'relative', top: '2px'}} src={imgStoragePath+"rightArrow.png"} alt="img"/></td>
    </tr>
  )
}
export default DbClaimList;
