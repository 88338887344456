import React from 'react';
import { Col } from 'reactstrap';
import { displayDateFormat } from '../../../constants';
import moment from 'moment';
const UtilsBlock = (props) => {
    return (
        <Col md="3" className="utils-ratio">
        <h6>
        {props.label}
        <span className="as-of-date">{ props.dateOfManu   ? ' ( As of '+ moment(props.dateOfManu).format(displayDateFormat) +' )' : null}</span>
        </h6>
        <h4>{props.value ? props.value : ''}</h4>
      </Col> 
  
    )
}

export default UtilsBlock;
