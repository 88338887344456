import React, { Component, Fragment } from 'react';
import { getFileSize } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';

class FilterList extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { item } = this.props;
    return(
      <Fragment>
        {
        item.type == 'file' ? null : 
            <div className="record-list">

              <Row>
                <Col md="1" style={{ marginRight: '-25px' }}>
                  {
                    item.type == 'file' ? null :
                      <input type="checkbox" onClick={() => this.props.addToExportList({ uuid: item.uuid, type: item.type, level: this.props.level })} checked={this.props.exportList.filter(exportItem => item.uuid == exportItem.uuid).length ? true : false} />
                  }
                </Col>
                <Col md="10" onClick={() => this.props.fetchDirTree(item)} >
                  {
                    item.type == "file" ? null : <img src={imgStoragePath + "folder_icn3.png"} width="14" />
                  }
                  <span>{item.type == 'file' ? null : item.name}</span>
                </Col>
                <Col md="1">
                  {
                    item.type == 'folder' ?
                      <img width="10" onClick={() => this.props.fetchDirTree(item)} src={imgStoragePath + "move_icon.png"} />
                      : null
                  }
                </Col>
              </Row>
            </div>
      }
      </Fragment>
    )
  }
}

export default withRouter(FilterList)
