import React from 'react';
import { ExportFiles } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
const BulkOperation = (props) => {
  return(
    <div className="add-bulk-operations">
      <ul className="list-inline flex-centered">
        <li className="list-inline-item operation-cta">
          <ExportFiles exportFile={(fileType) => props.contactListExport(fileType)} files={[{img:'excel_icon.png', label: 'Excel', type:'xls'}]} />
        </li>
      </ul>
      { getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects && getLocalStorageInfo().user.permission.technical.projects.includes('C') && props.addText ?
      <button onClick={() => props.buyLicence ? props.licenceModal() : props.toggleSidebar()} className="aims-primary-btn">
        {props.addText ? props.addText : null}
      </button>:null
    }
    </div>
  )
}
export default BulkOperation;
