import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {
  DeleteModal,
  PrerequisiteWrapper,
  PrerequisiteSubWrapper,
  EditFormBar,
  SecondaryTechHeaderRH,
  SecondaryTechHeaderMid,
  FormHeader,
  DownloadedLogs
  }from '../../../Elements';
import { getFormDetailsAc,updateFormStatusAc } from '../../T001/actionCreators';
import * as actions from '../actions';
import { imgStoragePath } from '../../../../../constants';
import { downLoadTechEngineFormAc } from '../../../../../shared/actionCreators'
import { GenralDetail03Wrapper,DataList,BulkOperation,LlpDetails,ItemCards } from '../components';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp } from '../../../../../shared';
import { fetchGapReportDetailsAc,updateGapReportDetailsAc,removeGapReportDetAc} from '../actionCreators';
import { recordsData} from '../../AddFormObjects';
import { checkValidDate ,checkString, checkIntNumber} from '../../../../../formValidator';
import { sGlobalConstantAc } from '../../../../../shared/actionCreators'
import { removeEmptyKey } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
class InspectionFormTE003 extends Component {
  constructor(props){
    super(props);
    this.state={
      activeTab: 1,
      preReqModal: false,
      preReqListModal:false,
      delCompModal:  false,
      deleteComponentIndex: '',
      deleteComponentTitle: '',
      deleteCompoId: '',
      deleteSection:'',
      downloadFormLogs: false,
      error:{}
    }
  }
  componentDidMount(){
    this.props.getFormDetails(2,'TE003');
    this.props.fetchGapReportFn();
    this.props.fetchTechConstant({constant_types:['engine_gap_report_status']});
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  toggleComDelModal = (title=null, index=null, compoId=null,section=null) => {
    this.setState({
      delCompModal: !this.state.delCompModal,
      deleteComponentTitle:title,
      deleteComponentIndex:index,
      deleteCompoId:compoId,
      deleteSection:section
    });
  }

  updateGapReportDetails = (gapReportInvDet) => {

    let validateNewInput = {};
    validateNewInput = Object.keys(gapReportInvDet).map(dataKey => {
      if(dataKey == 'general_details'){
        return(
          [dataKey]  = [{
            ...validateNewInput,
            report_date:  checkValidDate({value: gapReportInvDet.general_details.report_date, required: true, minLength: '', maxLength: '', message: 'Please enter valid date'}),
            report_issued_place: checkString({value: gapReportInvDet.general_details.report_issued_place, required: true, minLength: '', maxLength: '', message: 'Please enter Report Issued Place'}),
           }]
        )
      }else if(dataKey == 'list'){
        return(
          [dataKey]  =  gapReportInvDet.list.map((gapData, compIndex) => gapData?
            gapData.gap_reports.map((compData,index)=> compData? {
              cycles_remaining: checkIntNumber({value: compData.cycles_remaining, required: false, minLength: '', maxLength: '', message: 'Please enter valid data'}),
              description: checkString({value:compData.description, required:true,minLength:"", maxLength: "", message:"Please enter description"})
            }:compData
            ): gapData
          )
        )
        }
      })


      validateNewInput = removeEmptyKey(validateNewInput);

       let validationNewFlag = [];
       validateNewInput.map((validateInput,index)  => index==0?
       validateInput.map((innerArray) =>  Object.keys(innerArray).map((k) =>  innerArray[k] === ''? validationNewFlag.push(true) : validationNewFlag.push(false) )
       ):index==2?
       validateInput.map((innerArray) =>  innerArray.map(subInnerAray =>  Object.keys(subInnerAray).every((k) => {  subInnerAray[k] === ''? validationNewFlag.push(true):validationNewFlag.push(false) } )
       )
       ):validationNewFlag.push(true)
      );


      if(validationNewFlag.filter(validData => validData==false).length == 0) {
      this.props.updateGapReportDetailsFn(gapReportInvDet);
      }else{
        this.props.errFn(validateNewInput)
      }

  }

  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }
  togglePreList = () => {
    this.setState({
      preReqListModal: !this.state.preReqListModal,
    });
  }

  scrollToBottom() {
      this.te003End.scrollIntoView({ behavior: 'smooth' });
  }



  updateForm = (obj) => {
    this.props.updateForm(obj);
    this.props.clearErr({type: obj.type, sectionType: obj.sectionType, index: obj.index ? obj.index : 0});
  }

  render(){
    const{ error,gapReportInvDet,gepReportLoader,techConstants,techExportLoader,formStatusLoader, formInfo}=this.props;

    return(
      <div className="technical-inspection-cn">
      <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project}/>}
          activeAsset={this.props.formInfo.asset}
          projectSlug = {Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton = {<Link style={gepReportLoader ?  { pointerEvents : 'none', opacity: '0.5'} : { pointerEvents : 'auto', opacity: '1'}} onClick={() => this.updateGapReportDetails(gapReportInvDet)} className="primary-btn"> {gepReportLoader ? 'Processing...': 'Save Changes'} </Link>}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('gap-report-sections','ESN_'+this.props.formInfo.asset.esn+'_'+this.props.formInfo.current_form.name,fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />
        <div className="technical-inspection-ee">
          <h4 className="tech-heading-block flex-centered" style={{marginTop:"20px"}}>
            General Details
            <span onClick={() => {this.togglePrerequisiteModal(); trackActivity('Read Prerequisite Clicked', { page_title: 'TE003', application_module: 'Inspection',  item_type:'Gap Report' })}} className="pre-requisite flex-centered">

             <img  src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
             Read Prerequisite
            </span>
          </h4>
          {gapReportInvDet.general_details?
        <GenralDetail03Wrapper
          gapReportDetails ={gapReportInvDet.general_details}
          updateForm={this.updateForm}
          error= {error && error[0]  ? error[0][0] : ''}
        />:null}
        </div>
        <div className="form-te003-cn">
         {
          techExportLoader ?
          <ListLoader/>
          : null
          }
        {gapReportInvDet.summary?
        <Row>
          <Col md="2">
           <ItemCards value={gapReportInvDet.summary.open_items} label={'Total No. Of Open Items'}/>
          </Col>
          <Col md="2">
           <ItemCards value={gapReportInvDet.summary.closed_items} label={'Total No. Of Closed Items'}/>
          </Col>
          <Col md="3">
           <ItemCards value={gapReportInvDet.summary.unresolved_items} label={'Total No. Of Unresolved Items'}/>
          </Col>
        </Row>:null}
        { gapReportInvDet.list?
        <div className="edit-view-tech-spec">

              <Nav tabs>
             { gapReportInvDet.list.map((gapDetails,index)=>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab == index+1 })}
                    onClick={() => { this.toggle(index+1); trackActivity('Item Clicked', { page_title: 'TE003', application_module: 'Inspection',  item_type:`${gapDetails.name} Tab`})} }
                    >
                    {gapDetails.name}
                  </NavLink>
                </NavItem>
             )}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
              { gapReportInvDet.list.map((gapDetails,index)=>
                index==0?
                <TabPane tabId={1}>
                <TableUIComp
                    noStickyHeader={true}
                    hover={true}
                    tableBulkOp = {<BulkOperation addText= "+Add Item" addComponent= {()=>{this.props.addData(gapDetails.id);this.scrollToBottom()}} export={false} />}
                    filter={false}
                    tableScroll='none'
                    tableHeight= "auto"
                    theads={[{label:'Item No', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'Acumen Remarks', sortKey: ''},{label:'Status', sortKey: ''},
                    {label:'Comments/Notes', sortKey: ''},{label:'Ref Documents', sortKey: ''},{label:'Operator Comments', sortKey: ''},{label:'Action', sortKey: ''}]}
                    bulkOperation={false}
                    pagination={[]}
                    content={gapDetails.gap_reports.map((gapReport, index) => <DataList key={index} index={index} content={gapReport} techConstants={techConstants} updateForm={this.props.updateForm} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, gapReport.id,gapDetails.id)} section={gapDetails.id} deleteSectionData={this.props.deleteSectionData}/>) }
                    recordsFound = {gapDetails.gap_reports.length?false:true}
                  />
                </TabPane>:
                index==1?
                <TabPane tabId={2}>
                <TableUIComp
                    noStickyHeader={true}
                    hover={true}
                    tableHeight= "auto"
                    tableScroll='none'
                    tableBulkOp = {<BulkOperation addText= "+Add Item" addComponent= {()=>{this.props.addData(gapDetails.id);this.scrollToBottom()}} export={false}  />}
                    filter={false}
                    theads={[{label:'Item No', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'P/N', sortKey: ''},{label:'S/N', sortKey: ''}, {label:'Cycle Remaining', sortKey: ''},
                    {label:'Acumen Remarks', sortKey: ''},{label:'Status', sortKey: ''}, {label:'Comments/Notes', sortKey: ''},{label:'Ref Documents', sortKey: ''},{label:'Operator Comments', sortKey: ''},{label:'Action', sortKey: ''}]}
                    bulkOperation={false}
                    pagination={[]}
                    content={gapDetails.gap_reports.map((gapReport, index) => <LlpDetails key={index} index={index} content={gapReport} techConstants={techConstants} error= { error && error[2] ? error[2][1][index] : ''} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, gapReport.id,gapDetails.id)} updateForm={this.updateForm} section={gapDetails.id} deleteSectionData={this.props.deleteSectionData}/>) }
                    recordsFound = {gapDetails.gap_reports.length?false:true}
                  />
                </TabPane>:
                <TabPane tabId={index+1}>
                <TableUIComp
                    noStickyHeader={true}
                    hover={true}
                    tableHeight= "auto"
                    tableScroll='none'
                    tableBulkOp = {<BulkOperation addText= "+Add Item" addComponent= {()=>{this.props.addData(gapDetails.id);this.scrollToBottom()}} export={false}  />}
                    filter={false}
                    theads={[{label:'Item No', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'P/N', sortKey: ''},{label:'S/N', sortKey: ''}, {label:'Cycle Remaining', sortKey: ''},
                    {label:'Acumen Remarks', sortKey: ''},{label:'Status', sortKey: ''}, {label:'Comments/Notes', sortKey: ''},{label:'Ref Documents', sortKey: ''},{label:'Operator Comments', sortKey: ''},{label:'Action', sortKey: ''}]}
                    bulkOperation={false}
                    pagination={[]}
                    content={gapDetails.gap_reports.map((gapReport, index) => <LlpDetails key={index} index={index} techConstants={techConstants} content={gapReport} updateForm={this.props.updateForm} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, gapReport.id,gapDetails.id)} section={gapDetails.id} deleteSectionData={this.props.deleteSectionData}/>) }
                    recordsFound = {gapDetails.gap_reports.length?false:true}
                  />
                </TabPane>
                 )}
                  <div ref={el => { this.te003End = el; }} />
              </TabContent>

            </div>
            :null
            }
        </div>
        <DeleteModal isOpen={this.state.preReqModal}
         toggle={() => this.togglePrerequisiteModal()}
         title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te003'} togglePreagain={this.togglePreList}/>
        </DeleteModal>

        <DeleteModal isOpen={this.state.preReqListModal}
         toggle={() => this.togglePreList()}
         title={'Documents required for engine assessment - Check List'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteSubWrapper/>
        </DeleteModal>


        <DeleteModal isOpen={this.state.delCompModal}
        toggle={() => this.toggleComDelModal('')}
        title={this.state.deleteComponentTitle}
        className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleComDelModal('');this.props.removeGapReportDet(this.state.deleteComponentIndex, this.state.deleteCompoId,this.state.deleteSection)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleComDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        {gapReportInvDet && gapReportInvDet.list ? gapReportInvDet.list.map((data)=> {

          return (
            <div></div>
          )
        }):null}
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
        {
          gepReportLoader ?
            <ListLoader/> :  null }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  itemSideBar:state.FormTE003Reducer.itemSideBar,
  gapReportInvDet:state.FormTE003Reducer.gapReportInvDet,
  gepReportLoader: state.FormTE003Reducer.gepReportLoader,
  techConstants: state.sharedReducers.techConstants,
  techExportLoader:state.sharedReducers.techExportLoader,
  error : state.FormTE003Reducer.error,
  formStatusLoader:state.FormT001Reducer.formStatusLoader

});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    fetchGapReportFn : () => dispatch(fetchGapReportDetailsAc(ownProps)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data,2)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechEngineFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    removeGapReportDet: (compoIndex, compoId,section) =>  dispatch(removeGapReportDetAc(ownProps, compoIndex, compoId,section)),
    updateGapReportDetailsFn:(data)=>dispatch(updateGapReportDetailsAc(ownProps,data)),
    itemEditSideBar: () => dispatch({
      type: actions.ITEM_SIDE_BAR,
      payload: {}
    }),
    addData : (ID) => dispatch({
      type:  actions.ADD_DYNAMIC_TE003_DATA,
      payload: {id:ID,data:recordsData}
    }),
    updateForm : (data) => dispatch({
      type:  actions.UPDATE_TE003_FORM,
      payload: data
    }),
    errFn : (errMsg) => dispatch({
      type:  actions.ERROR_MESSAGE_03,
      payload: errMsg
    }),
    clearErr : (obj) => dispatch({
      type:  actions.CLEAR_ERR_MESSGAE_03,
      payload: obj
    }),


  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE003);
