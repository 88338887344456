import React from 'react';
const VertCardLabel = (props) => {
  return(
    <li>
      <h6>{props.label}</h6>
      <span>{props.value}</span>
    </li>
  )
}
export default VertCardLabel;
