import React, {Component} from 'react';
import { connect } from 'react-redux';
import { TabPane } from 'reactstrap';
import { GeneralTab,OverViewUtilization,EditGenInfo, MjrAssShopVisitHeader, MjrAssShopVisitDetail } from '../components';
import { EditSidebar, CtrTableHd } from '../../../contracts/Elements';
import { CLOSE_AVIONIC_SIDE_BAR, TECHSPEC_LOADER, OVERVIEW_TECH_INFO } from '../actions';
import { overviewListAc,  overviewEditAc, disclaimerEditAc,  aircraftMaintenanceAc } from '../actionCreators';
import { sLessorListAc, sLesseeListAc, sOperatorListAc, sRegionListAc, sArctTypesAc, sGlobalConstantAc  } from '../../../../shared/actionCreators'
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { sManufacturerAc } from '../../../../shared/actionCreators';
import { NoRecordFound  } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils'
import { fetchPortfolioListAC } from '../../../fleetAnalysis/actionCreators'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea } from '../../../contracts/Elements';
class OverviewContnt extends Component{
	constructor(props) {
		super(props);
		this.state = {
			overviewStatus: false,
		};
	}

	updateDisclaimer = (key, value) =>{
		let dataObject = this.props.overviewTechInfo
		dataObject = {...dataObject, [key]: value}
		this.props.overviewTechUpdate(dataObject)
	}

	toggleSideBar = (type) => {
		this.setState(prevState => ({
			...prevState,
			[type]: !prevState[type]
		}))
	}
	componentDidMount() {
		if(!this.props.aircraftManufacturerList.length){
			this.props.fetchManufacturer('aircraft','aircraftManufacturerList');
			this.props.fetchManufacturer('engine','engineManufacturerList');
		}
		this.props.fetchPortfolios({status: 3});
		this.props.fetchOverviewList(this.props.asset_slug);
		this.props.fetchLessor();
		this.props.fetchLessee();
		this.props.fetchOperator();
		this.props.fetchRegion();
		this.props.fetchArchType();
		this.props.getOwnerList();
	}

	render(){
		const {
			overviewTechInfo,
			assetId,
			aircraftManufacturerList,
			aircraftTypes,
			maintenanceProgGrp,
			techConstants,
			sAircarftOperatn,
			techSpecsLoader,
			ownerList
		} = this.props;
		const userInfo = getLocalStorageInfo()
			return(
				<TabPane tabId="1" className="general-block">
					{Object.keys(overviewTechInfo).length ?
						<div>
							<GeneralTab sWeightUnit = {techConstants.filter(item => item.type == "weight_unit")} creditClassification = {techConstants.filter(item => item.type == "credit_classification")} aircraftOperation = {techConstants.filter(item => item.type == "aircraft_operation")} toggleLoader={() => this.props.toggleLoader(true)} editOverview={this.props.editOverview} techConstants={techConstants.filter(item => item.type=="asset_status")} overview={overviewTechInfo} viewOverview={this.toggleSideBar} />
							<div className="contr-table-blk">
								<h5 style={{ borderBottom: '0'}}>Events History</h5>
								<div className="mobile-table-format">
								<MjrAssShopVisitHeader theads = {[{ label: 'Maintenance Event / Event Date', size: 2 }, { label: 'Event Cost', size: 2}, { label: 'Event Facility/ Location', size: 2}, { label: 'TSN at Event', size: 2}, { label: 'CSN at Event', size: 2},  {label: 'Status', size: 2}]}/>
									{
										Object.keys(overviewTechInfo.shopvisit).length ?
										overviewTechInfo.shopvisit.map((item,index) =>
											<MjrAssShopVisitDetail shopvisit = {item} key={index}/>
										) : null
									}
									</div>
									 {!techSpecsLoader && !Object.keys(overviewTechInfo.shopvisit).length ?
		  							<NoRecordFound description="" /> :  null
									 }
							</div>
							<h3 style={{fontSize: '16px', marginBottom: '10px'}}>Disclaimer</h3>
							<div className="horizontal-card card-block">
							<Row>
							<FieldCol md="6" className="horizontal-inner-block">
								<div>
									<h6 style={{marginBottom: '10px'}}>Technical</h6>
									{
										!getLocalStorageInfo().user.permission['technical_specs'] ?
										<p style={{border: '1px solid #d7d7d7', borderRadius: '4px', width: '100%', padding: '10px'}}>{overviewTechInfo.technical_disclaimer && overviewTechInfo.technical_disclaimer.length ? overviewTechInfo.technical_disclaimer.length : '--'}</p>
										:<textarea style={{border: '1px solid #d7d7d7', height: '115px', width: '100%', padding: '10px', resize: 'none', borderRadius: '4px'}} value={overviewTechInfo.technical_disclaimer} onChange={(evt) => this.updateDisclaimer('technical_disclaimer', evt.target.value)}></textarea>
									}
								</div>
							</FieldCol>
							<FieldCol md="6" className="horizontal-inner-block">
							<div>
								<h6 style={{marginBottom: '10px'}}>Marketing</h6>
								{
									!getLocalStorageInfo().user.permission['technical_specs'] ?
									<p style={{border: '1px solid #d7d7d7', borderRadius: '4px', width: '100%', padding: '10px'}}>{overviewTechInfo.marketing_disclaimer && overviewTechInfo.marketing_disclaimer.length ? overviewTechInfo.marketing_disclaimer : '--'}</p>
									:<textarea style={{border: '1px solid #d7d7d7', height: '115px', width: '100%', padding: '10px', resize: 'none', borderRadius: '4px'}} value={overviewTechInfo.marketing_disclaimer} onChange={(evt) => this.updateDisclaimer('marketing_disclaimer', evt.target.value)}></textarea>
								}
							</div>
							</FieldCol>
							{
								getLocalStorageInfo().user.permission['technical_specs'] ?
								<FieldCol md="12">
									  <input type="button" style={{float: 'right', marginTop: '15px', marginBottom: '20px'}} className="primary-btn" onClick={() => this.props.disclaimerEdit({technical_disclaimer: overviewTechInfo.technical_disclaimer, marketing_disclaimer: overviewTechInfo.marketing_disclaimer, slug: overviewTechInfo.slug})} value="Save"/>
								</FieldCol>
								:null
							}
							</Row>
							</div>
						</div> : null
					}
					<EditSidebar width="1200px" right= "-1200px" title="Edit Overview" editSideBar={this.state.overviewStatus} toggleEditSideBar={() => this.toggleSideBar('overviewStatus')}>
						{Object.keys(overviewTechInfo).length && this.state.overviewStatus ?
							<EditGenInfo
								type="edit"
								asset_id={assetId}
								manufacturerList={aircraftManufacturerList}
								aircraftTypes={aircraftTypes}
								editOverview={this.props.editOverview}
								overviewObj={overviewTechInfo}
								toggleEditSideBar={() => this.toggleSideBar('overviewStatus')}
								fetchMaintenanceGrp = {this.props.fetchMaintenanceGrp}
								maintenanceProgGrp = {maintenanceProgGrp}
								techConstants={techConstants}
								sWeightUnit = {techConstants.filter(item => item.type == "weight_unit")}
								creditClassification = {techConstants.filter(item => item.type == "credit_classification")}
								sAircarftOperatn = {techConstants.filter(item => item.type == "aircraft_operation")}
								sLesseeList = {this.props.sLesseeList}
								sOperatorList = {this.props.sOperatorList}
								transferAsset={this.props.transferAsset}
								portfolioList={this.props.portfolioList}
								ownerList={ownerList}
								/> : 'Loading...'
						 }
					</EditSidebar>
					{
						// !techSpecsLoader && userInfo.defaultLessor.lessor_type != 3 && (overviewTechInfo.data_transfer == undefined || overviewTechInfo.data_transfer == null || Object.keys(overviewTechInfo.data_transfer).length < 1) ?
						// <div className="contr-table-blk">
						// 	<h5 style={{ borderBottom: '0'}}>
						// 	<Row>
						// 		<Col md="6">Transfer Asset</Col>
						// 		<Col md="6"><Link className="action-link" onClick={() => this.props.toggleTransfer(true)}>Initiate Transfer</Link></Col>
						// 	</Row>
						// 	</h5>
						// 	<div className="mobile-table-format">
						// 		<p className="detail-text-content">
						// 			You can transfer complete or partial asset data to other lessor account on SPARTA platform. Get started by clicking on "Initiate Transfer"
						// 		</p>
						// 	</div>
						// </div>
						// :null
					}
				</TabPane>
			)
		}
}
const mapStateToProps = state => ({
	aircraftManufacturerList: state.sharedReducers.aircraftManufacturerList,
	engineManufacturerList: state.sharedReducers.engineManufacturerList,
	overviewTechInfo: state.TechSummaryReducer.overviewTechInfo,
	maintenanceProgGrp: state.TechSummaryReducer.maintenanceProgGrp,
	sEngineTypes: state.sharedReducers.sEngineTypes,
	sLessorList: state.sharedReducers.sLessorList,
	sLesseeList: state.sharedReducers.sLesseeList,
	sOperatorList: state.sharedReducers.sOperatorList,
	sRegionList: state.sharedReducers.sRegionList,
	aircraftTypes: state.sharedReducers.aircraftTypes,
	techConstants: state.sharedReducers.techConstants,
	clients: state.userAssetReducer.clients,
	operators: state.userAssetReducer.operators,
	portfolioList: state.FleetAnalysisReducer.portfolioList,
	ownerList:state.confReducer.ownerDropdownList,
})
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchManufacturer: (group_type, reducer_type) => dispatch(sManufacturerAc(group_type, reducer_type)),
		fetchOverviewList: (asset_slug) => dispatch(overviewListAc(asset_slug)),
		fetchLessor:() => dispatch(sLessorListAc()),
		fetchLessee:() => dispatch(sLesseeListAc()),
		getOwnerList:() => dispatch(getOwnerDropdownListAc()),
		fetchOperator:() => dispatch(sOperatorListAc()),
		fetchRegion: () => dispatch(sRegionListAc()),
		fetchArchType: () => dispatch(sArctTypesAc()),
		fetchMaintenanceGrp: (asset_type) => dispatch(aircraftMaintenanceAc(asset_type)),
		editOverview: (data) => dispatch(overviewEditAc(data)),
		disclaimerEdit: (data) => dispatch(disclaimerEditAc(data)),
		closeEditViewSideBar: (type, data) => dispatch({
			type: CLOSE_AVIONIC_SIDE_BAR,
			viewType: type,
			payload: data
		}),
		overviewTechUpdate: (dataObject) => dispatch({
			type: OVERVIEW_TECH_INFO,
			payload: dataObject
		}),
		fetchPortfolios: (filters) => dispatch(fetchPortfolioListAC(filters)),
		toggleLoader: (flag) => dispatch({
			type: TECHSPEC_LOADER,
			payload: flag
		})
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewContnt);
