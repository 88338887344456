import * as actions from '../actions';
export default function( state={
  lgEventList: [],
  lgEventHistory:[],
  lgClaims: [],
  lgShopVisit: {
    type: '',
    data: {},
    flag: false
  },
  lgClaimInfo: {
    type: '',
    data: {},
    flag: false
  },
  lgPayoutInfo:{
    type: '',
    data:{},
    flag: false
  },
  lgLoader: false
},action){
  switch (action.type) {
    case actions.LG_EVENT_LIST:
      return {
        ...state,
        lgEventList: action.payload
      }
    case actions.LG_EVENT_HISTORY:
      return {
        ...state,
        lgEventHistory: action.payload
      }
    case actions.LG_SHOP_VIST_ADD_SUCCESS:
      return {
        ...state,
        lgEventHistory:[action.payload, ...state.lgEventHistory]
      }
    case actions.LG_SHOP_VISIT_CRUD:
      return {
        ...state,
        lgShopVisit: action.payload
      }
    case actions.LG_CLAIM_LIST:
      return {
        ...state,
        lgClaims: action.payload
      }
    case actions.LG_CLAIM_ADD_SUCCESS:
      return {
        ...state,
        lgClaims: [action.payload, ...state.lgClaims]
      }
    case actions.LG_CLAIM_EDIT_SUCCESS:
      return {
        ...state,
        lgClaims: state.lgClaims.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.LG_CLAIM_CRUD:
      return {
        ...state,
        lgClaimInfo: action.payload
      }
    case actions.LG_PAYOUT_CRUD:
      return {
        ...state,
        lgPayoutInfo: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        lgShopVisit: {
          ...state.lgShopVisit,
          type: 'edit',
          flag:true
        }
      }
    case actions.LG_LOADER:
      return {
        ...state,
        lgLoader: action.payload
      }
    default:
      return state;
  }
}
