import React, { Component } from 'react';
import { EditFormBar } from '../../../applications/technicalInspection/Elements';
export default  class ReadMore extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    render(){
        return(
            <div className="readmore-sidebar">
                <EditFormBar
                    titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>SPARTA Referral Program </h3></div>}
                    toggleEditSideBar={this.props.toggleSideBar} isOpen={this.props.readInfo} style={this.props.readInfo ? {width: '480px'} : {width: '0px'}}>
                    <p>
                      Following terms and conditions are applicable under the program
                    </p>
                    <ul>
                        <li>This program cannot be clubbed with any other offers or discounts,</li>
                        <li>The program only applies to the following modules :
                        <ul style={{marginLeft:'15px'}}>
                            <li className="inner-child" style={{marginBottom:'1px',borderBottom:'none',paddingBottom:'5px',marginTop:'5px'}}>
                            Contracts &amp; Maintenance – additional assets,
                            </li>
                            <li className="inner-child" style={{marginBottom:'1px',borderBottom:'none',paddingBottom:'5px'}}>
                            Technical- additional inspections
                            </li>
                            <li className="inner-child" style={{marginBottom:'1px',borderBottom:'none',paddingBottom:'5px'}}>
                            Records- additional storage
                            </li>
                            <li className="inner-child" style={{marginBottom:'0px',borderBottom:'none',paddingBottom:'0px'}}>
                            Users- additional user licences
                            </li>
                        </ul>
                        </li>
                        <li>The program does not apply to MR Calculator and Fleet cashflow.</li>
                        <li>The user needs to invite atleast two other accounts to get a free award. The free award can be availed after the two invited users have accepted their invites and added their own accounts and assets.</li>
                        <li>The referral code should be applied when creating the account, as it cannot be applied later.</li>
                    </ul>
                </EditFormBar>
            </div>

        )
    }
}
