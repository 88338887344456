import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd,ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import AddEditDelCond from '../components/AddEditDelCond';
import DelCondRow from '../components/DelCondRow';
import { deliveryCondListAc, addEditDeliveryCondAc, deliveryConditionCrudAc,deliveryConditionDeleteAc } from '../actionCreators';
import { contractCondnAc } from '../../InductionConditions/actionCreators';
import { TOGGLE_VIEW_EDIT, DELIVERY_COND_CRUD } from '../actions';
import { sExportContractsAc, sAuditLogAc } from '../../../../shared/actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
class DeliveryCondition extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  componentDidMount(){
    if(this.props.contractCondn.length == 0){
      this.props.fetchcontractCondnList();
    }
    this.props.fetchDelConList();
  }
  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/delivery-condition';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }
  }
  render() {
    const { delCondLists, assetViewId, delCondView, delCondLoader, contractCondn, deliveryCondCrud, auditLogView } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Contracts" params={this.props.params} pageTitle="Delivery Conditions" changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Delivery Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <ContractHeadertext
            title="Delivery Conditions"
            desc="Section holds all delivery conditions covered in the Contract"
            addNew={getLocalStorageInfo().user.permission['contracts']['delivery_conditions'].indexOf('C') != -1 ? '+ Add Delivery Condition': ''}
            toggleAddEvent={() => this.props.deliveryConditionCrud('add', {})}
            addAudit= {auditLogView ?  "Revision History" : null}
            toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctdeliveryconditionitems', 0, null, 'add',this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Delivery Condition', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Delivery Condition'})}}
          />
          <div className="list-container">
            { delCondLoader ? <ListLoader />:null }
            <div className="table-container">
              <table>
                <CtrTableHd theads={['Clause', 'Terms', 'Post Delivery', 'Exception']}/>
                <tbody>
                  {delCondLists.map((deliveryCond,index) =>
                    <DelCondRow key={index} deliveryCond={deliveryCond} viewDeliveryCond= {() => this.props.deliveryConditionCrud('view', deliveryCond.id)}  />)
                  }
                </tbody>
              </table>
              {!delCondLoader && !delCondLists.length ?
                <NoRecordFound description="Get Started by clicking '+ Add Delivery Condition' button above" />:null
              }
            </div>
          </div>
        </div>
        <EditSidebar
          title={
            deliveryCondCrud.type != '' ?
            (deliveryCondCrud.type == 'add') ? 'Add Delivery Condition':
            (deliveryCondCrud.type == 'edit') ? 'Edit Delivery Condition': 'View Delivery Condition' : ''
            }
            editSideBar={deliveryCondCrud.flag}
            toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
          >
          {
            Object.keys(deliveryCondCrud.data).length && deliveryCondCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctdeliveryconditionitems', 1, deliveryCondCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Delivery Condition', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Delivery Condition', item_id:deliveryCondCrud.data.id})}}
                type={deliveryCondCrud.type}
                changeViewMode={()=>this.props.changeViewMode(deliveryCondCrud.type)}
                editAble = {getLocalStorageInfo().user.permission['contracts']['delivery_conditions'].indexOf('U') != -1 ? true:false}
              />
              <AddEditDelCond
                type={deliveryCondCrud.type}
                contractCondn={contractCondn}
                deliveryCondObj={deliveryCondCrud.data}
                addEditDeliveryCond={(data, type)=>this.props.addEditDeliveryCond(data, type, assetViewId)}
                deliveryConditionDeleteCrud={this.props.deliveryConditionDeleteCrud}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                delCondLoader={delCondLoader}
              />
            </div>:null
          }
        </EditSidebar>

            <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  delCondLists: state.ContrDeliCondReducer.delCondLists,
  contractCondn: state.ContrInducCondReducer.contractCondn,
  delCondLoader: state.ContrDeliCondReducer.delCondLoader,
  deliveryCondCrud: state.ContrDeliCondReducer.deliveryCondCrud,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDelConList: () => dispatch(deliveryCondListAc(ownProps.params)),
    addEditDeliveryCond: (data, type, assetViewId) => dispatch(addEditDeliveryCondAc(ownProps.params, data, type, assetViewId)),
    fetchcontractCondnList : () => dispatch(contractCondnAc(ownProps.params)),
    deliveryConditionCrud: (type, id) => dispatch(deliveryConditionCrudAc(ownProps.params, type, id)),
    deliveryConditionDeleteCrud: (id) => dispatch(deliveryConditionDeleteAc(ownProps.params, id)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    toggleAuditlogFn: (application, content_type, action, objectId, type,paramKey=null,paramValue=null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramKey,paramValue)),
    toggleEditBar: (data) => dispatch({
      type: DELIVERY_COND_CRUD,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    })
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(DeliveryCondition, ['contracts', 'delivery_conditions' ,'R'])));
