import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath, displayDateFormat, projectStatus } from '../../../../constants';

export default class AssetPopover extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {    
		this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  
  render() {
    const {  tagetId, placement, project } = this.props;
    return (
        <span>
       
       <span>
        <img width="12" className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath +'inv_info_icon.png'} alt="img" style={{ display: 'inline-block', marginLeft: '10px',paddingBottom:'5px'}}/>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
                <div className="program-tracker-popover">
                {project.planned_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Planned Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.planned_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                {project.operator_contacted_at?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Operator Contacted Date</h6>
                <p style={{fontSize:'12px'}}> {moment(project.operator_contacted_at).format('DD MMM, YYYY')}</p>
                </div>:null}
                 {project.scheduled_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Scheduled Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.scheduled_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                {project.inspection_start_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Inspection Start Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.inspection_start_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                {project.inspection_end_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Inspection Due Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.inspection_end_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                {project.actual_submission_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Submission Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.actual_submission_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                {project.submission_due_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Submission Due Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.submission_due_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                {project.closure_date?
                <div style={{paddingBottom:'5px'}}>
                <h6 style={{fontSize:'14px'}}>Closure Date</h6>
                <p style={{fontSize:'12px'}}>  {moment(project.closure_date).format('DD MMM, YYYY')}</p>
                </div>:null}
                </div>
            </PopoverBody>
        </Popover>
		</span>
     </span>

    );
  }
}
