import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {BannerComponent, DescriptionComponent, InvestmentComponent, FooterComponent, PlansComponent, FeatureComponent, ManageComponent}  from '../components';
import '../assets/management.scss';

export default class ProjectManagement extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const manageData = [
            'A single platform to schedule, plan and execute fleet-wide inspections',
            'Interconnected data links to other modules for seamless access to inspection history, reports and findings',
            'An intuitive view of datapoints from inspection findings',
            'Offers state of the art iOS and android mobile and web apps to inspect aircraft and aircraft records',
            'Interconnected data links to other modules for seamless time saving access to scanned records for assets',
            'Robust "search" features optimized using Natural Language Processing(NLP), Artificial Intelligence(AI) and Machine Learning(ML)',
            ' Intuitive interface and data indexing'
        ];

        const features = [
            {
                title : 'Technical Management',
                img : 'landing/technical_management.png',
                description : [
                    'A single platform to schedule, plan and execute fleet-wide',
                    'Interconnected data links to other modules for seamless time access to inspection history, reports and findings',
                    'An intuitive view of datapoints from inspection findings'
                ]
            },
            {
                title : 'Records Management',
                img : 'landing/record_management.png',
                description : [
                    'Interconnected data links to other modules for seamless access to scanned records for assets',
                    'Robust "search" features optimized using Natural Language Processing(NLP), Artificial Intelligence(AI) and Machine Learning(ML)',
                    'Intuitive interface and data indexing'
                ]
            },
            {
                title: 'Maintenance, Cash Flow & MR',
                img : 'landing/maintenance_flow.png',
                description : [
                    'Maintenance related cash flow analysis',
                    'Capability to undertake fleet wide cash flow analysis for different scenarios including Asset Backed Securitization (ABS)',
                    'Maintenance event tracking, status of findings, workscope approvals, Life Limited Parts (LLP) status, Airworthiness Directives (AD) and Service Bulletins (SB) status'
                ]
            }
        ]

        return(
            <div className="management-container sparta-staticpage-container">
                <BannerComponent
                title="Digital technologies transforming asset management."
                subtitle="Why allow yourself to be left behind?"
                link="/signup" />
                <DescriptionComponent
                  description="A digital asset manager, an extension of the human workplace.  Coupling Acumen's depth of knowledge in the aviation asset management sector with and innate inquisitive and problem-solving approach, it has successfully developed a universal and integrated asset management platform.  This platform uses embedded machine learning (ML) and artificial intelligence (AI) technologies and utilises full form robotic process automation (RPA)"
                />
                <InvestmentComponent />
                <ManageComponent
                    title="How does SPARTA help the engineering sector manager their assets?"
                    data={manageData}
                />
                <FeatureComponent features={features}/>
                <PlansComponent />
                <FooterComponent />
            </div>
        )
    }
}
