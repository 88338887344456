import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect } from '../../Elements';
import { checkString } from '../../../../formValidator';
export default class AddEditIndCond extends Component {
  constructor(props){
    super(props);
    this.state = {
      inductionCond: {},
      error: {}
    }
  }
  componentDidMount(){
    if(this.props.type == 'add'){
      this.setState({
        inductionCond: this.props.inductionCondObj
      });
    }else{
      let data  = Object.assign({}, this.props.inductionCondObj);
      data = {
        ...data,
        asset_conditions_for_contract_id: data.asset_conditions_for_contract.id,
      }
      delete data['asset_conditions_for_contract'];
      this.setState({
        inductionCond: data
      })
    }
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      inductionCond: {
        ...prevState.inductionCond,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditInductionCond = (e) => {
    e.preventDefault();
    const validateNewInput = {
      asset_conditions_for_contract_id: checkString({value: this.state.inductionCond.asset_conditions_for_contract_id, required: true, minLength: '', maxLength: '', message: 'Please Select Clause'}),
      terms: checkString({value: this.state.inductionCond.terms, required: true, minLength: '', maxLength: '', message: 'Please Enter The Terms'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditInductionCond(this.state.inductionCond, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { type, contractCondn, inductionCondLoader } = this.props;
    const { inductionCond, error } = this.state;
    return (
      <form onSubmit={(e) => this.addEditInductionCond(e)}>
       <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
         <Row>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                 Clause
                 { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldSelect
                 placeholder='Select Clause'
                 keyParam="asset_conditions_for_contract_id"
                 value={inductionCond.asset_conditions_for_contract_id}
                 type={type}
                 options={contractCondn}
                 updateField={this.updateForm}
                 labelKey= 'name'
                 valueKey='id'
                 name = { Object.keys(inductionCond).length && type != 'add' ? this.props.inductionCondObj.asset_conditions_for_contract.name : ''}
               />
             <h6 className="error-msg">{error.asset_conditions_for_contract_id}</h6>
             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                 Terms
                 {type != 'view' ? <sup className="reqStar"> * </sup>: null}
               </FieldLabel>
               <FieldTextarea
                 value={inductionCond.terms}
                 type={type}
                 keyParam="terms"
                 updateField={this.updateForm}
               />
             <h6 className="error-msg">{error.terms}</h6>
             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Remarks</FieldLabel>
               <FieldTextarea
                 value={inductionCond.remarks}
                 type={type}
                 keyParam="remarks"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>
         </Row>
        </div>
       {
         type != 'view' ?
         <div className="submit-block">
            <input disabled={inductionCondLoader} type="submit" className="primary-btn" value={inductionCondLoader? 'processing...':'Save Changes'}/>
            <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
         </div>:null
       }
     </form>
   )
 }
}
