import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {te001,te002,te003,te004,te005,te006} from '../../technicalInspection/Forms/AddFormObjects'

export default class 
PrerequisiteSubWrapper extends Component {
  constructor(props) {
    super(props);
    this.state={
      requisites:["Engine log books", "Engine manufacturer delivery documents.", "Engine shop visit documents", 
      "Engine BSI report (Latest, including video recording if available)",
      "Engine ECTM report (Last 6 months)",
      "Latest LLP status and back to birth history",
      "Engine Power Assurance check report",
      "Engine maintenance program (hard time / on condition) - For Turboprop engines",
      "Gaspath wash interval",
      "On-wing maintenance history for last 6 months",
      "Engine preservation details (if engine is not operating / unserviceable)",
      "AD, SB status",
      "Engine LRU list",
      "Engine statements (NIA, No PMA/DER part used statement, Engine oil and fuel used statement, Thrust statement) Propeller Documents (if applicable) - For Turboprop engines",
      "Propeller log books",
      "Propeller shop visit reports."],
      tempRequisites1:[],
      tempRequisites2:[]
    } 
  }


  render() {
  // const { } = this.props;
  return(
    <div className="prerequisite">
       <ul className="list-unstyled">
       {this.state.requisites.map((requisite,index)=>
           <li>
           {index+1}. {requisite}
          </li>
         )
       }
       </ul>
    </div>
    );
  }
}
