import React from 'react';
const GrpTableHd = (props) => {
  return(
    <thead>
      <tr>
        <th>{props.metrixType == 'airframe' ? 'Event': 'Shop Visits'}</th>
        <th>{props.metrixType == 'airframe' ? 'Event Date': 'Shop Visit Date'}</th>
        { props.metrixType == 'airframe' ?
          <th>Escalated MR Rate <br/>as of Event Date</th>
          :
          <th>Escalated MR Rate <br/>as of Shop Visit Date</th>
        }
        { props.metrixType == 'airframe' ?
          <th>Escalated Event Cost <br/>as of Event Date</th>
          :
          <th>Escalated Shop Visit Cost <br/>as of Shop Visit Date</th>
        }
        <th>Fund Status</th>
        <th>Balance</th>
      </tr>
    </thead>
  )
}
export default GrpTableHd;
