import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import CKeditor from "../../../../../shared/CKEditor";
import { InspectionPictures } from "../components"
import {  GetInputField, ContentDrop, DeleteModal, SortableImageCard } from '../../../Elements';
import { checkString } from '../../../../../formValidator'
import { arrayMove } from 'react-sortable-hoc';
// import { toastFlashMessage, getDataUri } from '../../../../../utils';
import { SortableContainer } from 'react-sortable-hoc';

class AddEditDisAssembly extends Component{
  constructor(props){
    super(props);
    this.state = {
      dragOver: false,
      disAssembly:{},
      delModal: false,
      modalTitle:'',
      fileArr: [],
      picId: '',
      picIndex: '',
      classCustom:'',
      error: {},

    }
  }
  componentDidMount(){
    this.setState({
      disAssembly: this.props.disAssemblyCrud.data
    })
  }

  sortFiles = (args, pictures) =>{
   if(args[0].oldIndex != args[0].newIndex){
      let sortedPictures = [];
       pictures = arrayMove(pictures, args[0].oldIndex, args[0].newIndex);
       pictures.map(picture => sortedPictures.push(picture))
       this.props.sortPictures(sortedPictures);
     }
     this.setState(prevState => ({
      ...prevState,
      classCustom: ''
    }))
  }

  sortStart = () => {
    this.setState(prevState => ({
      ...prevState,
      classCustom: 'hideActionblock'
    }))
  }



  toggleDelModal = (title='', itemId, index) => {
    this.setState(prevState => ({
      ...prevState,
      delModal: !this.state.delModal,
      modalTitle: title,
      picId: itemId,
      picIndex: index
    }));
  }


  // deleteImgFn = (picId, picIndex) => {
  //   this.props.deleteImgFn(picId, picIndex, this.props.auditFindingCrud.type)
  // }




  addEditDisAsemFinding = (e) => {
    e.preventDefault();
    const validateNewInput = {
      title: checkString({value: this.props.disAssemblyCrud.data.title, required: true, minLength: '', maxLength: '', message: 'Please enter Title'}),

    };

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditDisAsemFinding({data: this.props.disAssemblyCrud.data, flag: false, type: this.props.disAssemblyCrud.type})
		}else{
			this.setState(prevState => ({
        ...prevState,
        error: validateNewInput
      }));
		}
  }

   onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  onDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver: false,
    })


  }

  onDragOver = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver:true,
    });


  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  uploadFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dragOver: false,
    })

  }

  // toggleDelImageModal = (title, id, index) => {
  
  //   this.setState({
  //     delModal: !this.state.delAttachmentModal,
  //     modalTitle: title,
  //     deleteId: id,
  //     deleteIndex: index
  //   });
  // }


  updateForm = (obj) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        title: ''
      }
    }));
    this.props.updateForm(obj);

  }
  updateGenCkeditor = (evt) => {
    var newContent = evt.editor.getData();
    this.props.updateForm({type: 'remarks', value:newContent, sectionType: 'diss_fields'})
  }

  render(){
    const { disAssembly, fileArr } = this.state;

    if(!Object.keys(disAssembly).length ){
      return null
    }
    const { type } = this.props.disAssemblyCrud.type;

    return(
      <form className="technical-form" onSubmit={(e) => this.addEditDisAsemFinding(e)} onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()} >
        <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px',  paddingBottom: '90px'}}>
        <Row>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Title {this.props.disAssemblyCrud.type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  {
                    type: this.props.disAssemblyCrud.type,
                    // type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                    value: this.props.disAssemblyCrud.data ? this.props.disAssemblyCrud.data.title : null,
                    error: this.state.error.title,
                    updateForm: (value) => this.updateForm({type: 'title', value:value, sectionType: 'diss_fields'})
                  }
                )
              }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
            {this.props.disAssemblyCrud.type != 'view'?
              <CKeditor
                  events={{"change": (e) => this.updateGenCkeditor(e)}}
                  content= {this.props.disAssemblyCrud.data ? this.props.disAssemblyCrud.data.remarks : null}
                />
                :
                  GetInputField(
                    'string',
                    <label className="for-label">Description {this.props.disAssemblyCrud.type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                    {
                      type: 'view',
                      // type: (this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) ? 'edit':'view',
                      value: this.props.disAssemblyCrud.data ? this.props.disAssemblyCrud.data.remarks : null,
                      error: this.state.error.title,
                      updateForm: (value) => this.updateForm({type: 'remarks', value:value, sectionType: 'diss_fields'})
                    }
                  )
                 }
            </Col>
          </Row>
            <InspectionPictures
                  disAssemblyCrud = {this.props.disAssemblyCrud}
                  uploadDissAssembFiles = {this.props.uploadDissAssembFiles}
                  updateForm = {this.props.updateForm}
                  editImageFn = {this.props.editImageFn}
                  toggleDelModal = {this.toggleDelModal}
                  onSortEnd={(...args) => {this.setState({classCustom: ''});this.sortFiles(args, this.props.disAssemblyCrud.data.finding_pictures)}}
                  onSortStart	 = {(evt) => {this.sortStart()}}
                  axis="xy"
                  distance="100"
                  openSlider={this.props.openSlider}
                  classCustom	 = {this.state.classCustom}
              />
          {/*
          <div className={'inspection-pictures '+this.props.classCustom} onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
          <Row>
            <Col md="12" className="image-row">
              <ul className="list-inline image-list-block flex-not-centered"
              >
                {
                 this.props.disAssemblyCrud.data && this.props.disAssemblyCrud.data.finding_pictures && this.props.disAssemblyCrud.data.finding_pictures.length ?
                 this.props.disAssemblyCrud.data.finding_pictures.map((item, index) =>
                 <SortableImageCard
                  key={index}
								  index = {index}
                  picture={item}
                  type={this.props.disAssemblyCrud.type}
                  toggleDelModal={() => this.toggleDelModal('Are you sure to delete?', item.id, index)}
                  editImage={() => this.props.editImageFn({flag: true, data: item, index: index})}
                  openSlider = {() => this.props.openSlider(this.props.disAssemblyCrud.data.finding_pictures, index)}
                  //// changePicture= {(files) => this.uploadLopaImage(files)}
                  options = { this.props.disAssemblyCrud.type != 'view'?[ 'E', 'R', 'D', 'V']:['D', 'V']}
                  updateForm = {(description, value) => this.props.updateForm({type: 'description', value:value, sectionType: 'findingPicture' , index: index})}
                  // hideOverLay = {this.hideOverlay}
                  // classCustom = {this.props.classCustom}
								/>
                ) : null }
                { //(this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save) && parseInt(this.props.auditFindingCrud.data.images.length + this.state.fileArr.length) < 6 ?
                }
                {this.props.disAssemblyCrud.type != 'view'?
                  <li className="list-inline-item dropable-zone"
                  onDrop={(evt) => { evt.preventDefault(); evt.stopPropagation(); this.props.uploadDissAssembFiles(evt.dataTransfer.files, 'dissasembly')}}
                    style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)', verticalAlign: 'top'} : { background: 'transparent', verticalAlign: 'top'}} >
                    <ContentDrop
                    mutlipleEnabled={true} acceptExtensions={'image/*'}
                    mutliple={true}
                    filesUpload={(e) => this.props.uploadDissAssembFiles(e.target.files, 'dissasembly')}
                    />
                    <span className="img-info-msg">
                      Recommended Picture dimension 400 X 400 Pixels
                    </span>
                  </li> :null
                }
                  {// : null}
                }
              </ul>
            </Col>
          </Row>
              </div>*/}
        </div>

        <div className="edit-sidebar-footer" style={{zIndex: '1001'}}>
          <ul className="list-inline">
            {/* { this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? */}
              <li className="list-inline-item">
                <input  disabled={this.props.tblInpLoader}
                className="primary-btn" type="submit" value={this.props.tblInpLoader ?  "Processing..." : "Save Changes" }/>
              </li>
             {/* :null
            } */}
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
          </ul>
        </div>


        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal()} title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.props.deleteImgFn(this.state.picId, this.state.picIndex); this.toggleDelModal()}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
      </form>
    )
  }
}

export default SortableContainer(AddEditDisAssembly)
