import React, { Component } from 'react';
import {  Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ProjectCordetails,EnggCordetails } from '../components'
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchCorTrackerAc, exportCorProjectDetailAc ,downLoadTrackerFormAc, fetchCorTrackerExpAc} from '../actionCreators';
import { ListLoader, ToolTipHover, NoRecordFound,ExportFiles } from '../../../../shared';
import TableFilter from '../../../../shared/TableFilter';
import { removeEmptyKey, getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
import Switcher from '../../../../shared/components/Switcher'
const feedbackColorCode = ['#a45f5f', '#f9cd74', '#38a64f'];
class CorTracker  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      slug: '',
      queryParam:{},
      page:1,
    };
    this.toggle = this.toggle.bind(this);
  }

  moveNextPage = () =>{
    this.setState({
      page: this.state.page + 1
    })
    this.props.fetchCorTrackerDetailsExp({...this.state.queryParam, page: this.state.page})
  }
  componentDidMount(){
    this.props.fetchCorTrackerDetails({page: 1});
    document.querySelector('body').style.overflow = 'auto';
	}
  toggle(slug=null) {
    this.setState(prevState => ({
      modal: !prevState.modal,
      slug: slug
    }));
  }
  exportCorDetail = (slug) => {
    this.toggle(slug);
  }

  changeFilterFn=(filter)=>{
    this.setState({
      queryParam: filter,
      page: 1
    });
  	this.props.fetchCorTrackerDetails({...filter, page: 1});
  }

  render() {
    const menuList = {
      'COR Status': {
        'inputType': 'dropdown',
        'keyParam': 'status',
				'placeholder': 'Select status',
				'options': [ {id:0, name: 'Pending'}, {id:2 , name: 'Accepted'}, { id:1, name: 'Not Accepted'}],
        'labelKey': 'name',
        'valueKey': 'id',
        'label': 'COR Status'
			},
			'Search':{
        'inputType': 'text',
        'keyParam': 'search',
        'placeholder': 'Search',
        'label': 'Search'
      },
      'Date Range': {
        'inputType': 'date',
        'keyParam': 'date',
        'label': 'Date'
      }
    }
    const{ trackerLoader, corTrackerDtls,exportTrackerLoader} =this.props;
    return(
      <div className="tracker-block">
      <div className="title-block">
            <h1>Trackers</h1>
            {getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')) ? <p>Offers a view of the Projects status and Manpower status as applicable</p> : <p>Offers a view of the Projects status as applicable</p>}
        </div>
      <ul className="list-inline project-heading">
      { getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')) || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')) ?
          <li className="list-inline-item">
            <Link to={!trackerLoader ? '/technical/projects/tracker':''}>PROJECT</Link>
          </li>:null}
          { getLocalStorageInfo().user.is_root_user || (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')) ?
					<li className="list-inline-item">
					<Link to={!trackerLoader ? '/technical/man-power/tracker':''} >MAN POWER</Link>
			   	</li>:null
           }
          <li className="list-inline-item">
            <Link className="active">COR Tracker</Link>
          </li>
        </ul>
      <div className="core-details-block">
      <div className="add-bulk-operations" style={{margin:'0px'}}>
                <ul className="list-inline flex-centered">
                  <li className="list-inline-item operation-cta">
                    <ExportFiles exportFile={(fileType) => this.props.corTrackerExport('cor-tracker',fileType,'CORTracker',this.state.queryParam)} files={[{img:'xls_icon.png', label: 'XLS', type:'xls'},{img:'pdf_icon.png', label: 'PDF', type:'pdf'}]} />
                  </li>
                </ul>
              </div>
            <div className="table-list-filter" style={{margin:'0px -15px'}} >
              <TableFilter
                clearFilterValue={this.clearFilterValue}
                filter={{menuList: menuList, appliedFilter: {}}}
                changeFilterFn={this.changeFilterFn}
                queryParamsEnabled={false}
             />
            </div>
        {exportTrackerLoader?<ListLoader/>:null}
        {trackerLoader?<ListLoader drawer="primary-nav" />:null}
        <div className="core-wrapper">
          <Row className="core-heading flex-not-centered">
            <Col md="6" className="detail-left-block flex-not-centered">
              <Row className="flex-not-centered list-row">
                <Col md="4" className="flex-centered list-column">
                  <p>PROJECT NAME</p>
                </Col>
                <Col md="2" className="flex-centered list-column">
                  <p>OPERATOR</p>
                </Col>
                <Col md="3" className="flex-centered list-column">
                  <p>PROJECT ENGINEER</p>
                </Col>
                <Col md="3" className="flex-centered list-column">
                  <p>STATUS</p>
                </Col>
              </Row>
            </Col>
            <Col md="6" className="outer-detail-right-block">
              <div>
                <div>
                  <p className="report-block">
                    <span>REPORT DETAILS IN AVERAGE</span>
                  </p>
                </div>
                <div className="detail-right-block flex-not-centered">
                  <ul className="list-inline">
                    {['General', 'Physical Inspection', 'Maintenance', 'Technical Records', 'Operation Of Aircraft', 'GeoPolitical', 'Overall Rating', 'Download Report'].map((label, index) =>
                      <li key={index}>
                        <p style={index==2?{wordBreak:'break-all'}:{}}>{label}</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <InfiniteScroll
            dataLength={corTrackerDtls.projects ? corTrackerDtls.projects.length : 0} //This is important field to render the next data
            next={() => this.moveNextPage()}
            hasMore={corTrackerDtls.projects && corTrackerDtls.pagination && corTrackerDtls.projects.length < corTrackerDtls.pagination.total}
            height={window.innerHeight - 350}
            className="scrollbar-hidden"
            >
          { corTrackerDtls.projects ? corTrackerDtls.projects.length ?
            corTrackerDtls.projects.map((projectDetails,index) =>
            <Row className="core-details flex-not-centered">
              <Col md="6" style={{padding: '0'}}>
                <Row className="margin-row">
                  <Col md="12" className="db-height-block">
                    <Row className="flex-not-centered list-data-row">
                      <Col md="4" className="flex-centered list-data-col">
                        <ProjectCordetails
                          projectDetails={projectDetails}
                        />
                      </Col>
                      <Col md="2" className="flex-centered list-data-col">
                        { projectDetails.lessee && projectDetails.lessee.name ?
                          <span id={`lessee-name_${projectDetails.id}`} className="operator-name">{projectDetails.lessee.name.length > 20 ? projectDetails.lessee.name.substring(0,17)+'...': projectDetails.lessee.name}</span>:null
                        }
                        { projectDetails.lessee && projectDetails.lessee.name && projectDetails.lessee.name.length > 20 ?
                          <ToolTipHover placement="top" tagetId={`lessee-name_${projectDetails.id}`}>
                            {projectDetails.lessee.name}
                          </ToolTipHover>:null
                        }
                      </Col>
                      <Col md="3" className="flex-centered project-engineer-block list-data-col">
                        <EnggCordetails id={projectDetails.id} engineers={projectDetails.engineers} />
                      </Col>
                      <Col md="3" className="flex-centered status-block list-data-col">
                        { projectDetails.engineers.length ?
                          projectDetails.engineers.map((engineers,engIndex)=>
                          <div className="status-single">
                            <h4>
                              <span id={`engineer-name_${projectDetails.id}_${engIndex}`}>{engineers.user.name.length > 20 ? engineers.user.name.substring(0,17)+'...': engineers.user.name }</span>
                              { engineers.user.name.length > 20 ?
                                <ToolTipHover placement="top" tagetId={`engineer-name_${projectDetails.id}_${engIndex}`}>
                                  {engineers.user.name}
                                </ToolTipHover>:null
                              }
                            </h4>
                            <Link>
                              <span style={{width: '6px', height: '6px', display: 'inline-block', position: 'relative', top: '-1px', background: feedbackColorCode[engineers.feedback_status]}}></span> {engineers.feedback_status ? engineers.feedback_status==1 ? 'Submitted':'Accepted':'Pending'}
                            </Link>
                          </div>
                        ):null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md="6" style={{padding: '0'}}>
                <Row className="db-left-row">
                  <Col md="12" className="db-left-height-block">
                    <ul className="list-inline cd-detail-wrapper flex-not-centered">
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[0].average_rating?projectDetails.cor[0].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[1].average_rating?projectDetails.cor[1].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[2].average_rating?projectDetails.cor[2].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[3].average_rating?projectDetails.cor[3].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[4].average_rating?projectDetails.cor[4].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[5].average_rating?projectDetails.cor[5].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered">
                        <span>
                          { projectDetails.cor && projectDetails.cor.length && projectDetails.cor[6].average_rating?projectDetails.cor[6].average_rating+'/5':'-'}
                        </span>
                      </li>
                      <li className="flex-centered" style={{justifyContent: 'center'}}>
                        { projectDetails.engineers.length &&  projectDetails.engineers.find(engineer => engineer.feedback_status == 1 || engineer.feedback_status == 2) ?
                          <span style={{cursor: 'pointer'}} onClick={() => this.exportCorDetail(projectDetails.slug)}>
                            <img src={imgStoragePath + 'import_images_icons.png'} width="16" alt="" />
                          </span>:null
                        }
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          ): <NoRecordFound style={{margin:'0px'}}/>:null }
        </InfiniteScroll>
        </div>
        {
          this.props.infiniteLoader ?
            <div style={{paddingLeft: '45%'}} className="data-room-expansion-loader"><img src={imgStoragePath + "spinner.gif"}/></div>
          :null
        }
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Download Report</ModalHeader>
          <ModalBody>
            <div className="text-center cor-tracker-export-popup">
              <ul className="list-inline">
                <li className="list-inline-item" onClick={() => this.props.exportCorProjectDetail(this.state.slug, {download:'pdf'})}>
                  <img src={imgStoragePath + 'pdf_icon.png'} alt="" width="16" /> <span>Download as PDF</span>
                </li>
                <li className="list-inline-item" onClick={() => this.props.exportCorProjectDetail(this.state.slug, {download:'xls'})}>
                  <img src={imgStoragePath + 'docx_exp_icon.png'} alt="" width="16" /> <span>Download as XLSX </span>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
	corTrackerDtls : state.TrackerReducer.corTrackerDtls,
  trackerLoader: state.TrackerReducer.trackerLoader,
  exportTrackerLoader:state.TrackerReducer.exportTrackerLoader,
  infiniteLoader : state.TrackerReducer.infiniteLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    corTrackerExport: (url,type,name,queryParam={}) =>dispatch(downLoadTrackerFormAc(url,type, name, queryParam)),
		fetchCorTrackerDetails : (queryParam={}) => dispatch(fetchCorTrackerAc(ownProps, queryParam)),
		fetchCorTrackerDetailsExp : (queryParam={}) => dispatch(fetchCorTrackerExpAc(ownProps, queryParam)),
    exportCorProjectDetail: (projectSlug, queryParams) => dispatch(exportCorProjectDetailAc(projectSlug, queryParams))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CorTracker);
