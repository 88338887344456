import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkNumber  } from '../../../../formValidator';
import { showCurrencyFormat } from '../../../../utils';
import { float2DecRegx } from '../../../../constants'
export default class AddSecurityDeposit extends Component {
  constructor(props){
    super(props);
    this.state = {
      securityDeposit: {},
      error: {},
      existinAmount: 0
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.securityDepositObj));
    data = {
      ...data,
      security_deposit_type : this.props.securityDepositObj.security_deposit_type && this.props.securityDepositObj.security_deposit_type.value ? this.props.securityDepositObj.security_deposit_type.value : '',
      security_deposit_type_name : this.props.securityDepositObj && this.props.securityDepositObj.security_deposit_type.label ? this.props.securityDepositObj.security_deposit_type.label : ''
    }
    if(this.props.type === 'add'){
      this.setState({
        securityDeposit: data,
        existinAmount: data.amount
      })
    }else{
      this.setState({
        securityDeposit: data,
      })
    }
  }
  updateForm = (type, value) => {
    if(type === 'amount'){
      if(float2DecRegx.test(value)){
        this.setState(prevState => ({
          ...prevState,
          securityDeposit: {
            ...prevState.securityDeposit,
            [type]: value
          },
          error: {
            ...prevState.error,
            [type]:''
          }
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        securityDeposit: {
          ...prevState.securityDeposit,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]:''
        }
      }))
    }
  }

  addEditSecurityDeposit = (e) => {
    e.preventDefault();
    var validateNewInput = {
      security_deposit_type: checkString({value: this.state.securityDeposit.security_deposit_type, required: true, minLength: '', maxLength: '', message: 'Please Select The Security Deposit Type'}),
      security_against: checkString({value: this.state.securityDeposit.security_against, required: true, minLength: '', maxLength: '', message: 'Please Enter The Security Against'}),
    };
    if(this.props.type === 'add'){
      validateNewInput = {
        ...validateNewInput,
        amount: checkNumber({value: this.state.securityDeposit.amount, required: true, minLength: '', maxLength: '', message: 'Please Enter The Valid Amount'})
      }
    }else{
      var total_amount = 0
      this.state.securityDeposit.security_deposit_transactions.map(item =>{
        total_amount += item.amount
      })
      if(total_amount > this.state.securityDeposit.amount){
        validateNewInput = {
          ...validateNewInput,
          amount: 'Amount should be greater than or equal to total amount already consumed.'
        }
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditSecurityDeposit(this.state.securityDeposit, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { type, techConstants, securityDepositMLData } = this.props;
    const { securityDeposit, error } = this.state;
    return(
      <form onSubmit={(e) => this.addEditSecurityDeposit(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <div>
            { type == 'add' && securityDepositMLData.length  ?
              <div className="ml-security-data">
                <h4 className="para"> Latest ML Retrieved Data </h4>
                { securityDepositMLData.map((data, index) =>
                  <p className="security-ml-data para-ui">{data.value}</p>
                )}
              </div>: null
            }
          </div>
          <Row>
            {
              securityDeposit.is_editable === false ?
                <FieldCol md="12"><FieldGroup className="form-group"><span className="warning-info-text">Please note Security Deposit is non editable post invoice generation.</span></FieldGroup></FieldCol>
              :null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Security Deposit Type <sup className="reqStar"> * </sup></FieldLabel>
                <FieldSelect
                  placeholder='Select Security Deposit Type'
                  keyParam="security_deposit_type"
                  value={securityDeposit.security_deposit_type}
                  type={type}
                  options={techConstants.filter(item => item.type == "security_deposit_type")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name= {securityDeposit.security_deposit_type_name}
                />
              <h6 className="error-msg">{error.security_deposit_type}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Security Against <sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={securityDeposit.security_against}
                  type={type}
                  keyParam="security_against"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.security_against}</h6>
              </FieldGroup>
            </FieldCol>
            {
              type == 'view' ?
                <Col md="6">
                  <div className="form-group">
                    <label className="label">Amount (USD) <sup className="reqStar"> * </sup></label>
                    <h6>{ securityDeposit.amount? <NumericLabel params={showCurrencyFormat(securityDeposit.currency)}>{securityDeposit.amount}</NumericLabel> :'--'}</h6>
                  </div>
                </Col>
              :
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Amount (USD) <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldInput
                    value={securityDeposit.amount}
                    type={type}
                    keyParam="amount"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.amount}</h6>
                </FieldGroup>
              </FieldCol>
            }

            {
              type == 'view' ?
              <Col md="6">
                <div className="form-group">
                  <label className="label">Security Deposit Refundable?</label>
                  <h6>{securityDeposit.is_refundable?'YES':'NO'}</h6>
                </div>
              </Col>
              :
              <Col md="6">
                <div className="form-group">
                  <label className="label">
                    <input type="checkbox" checked={securityDeposit.is_refundable} onChange={(e) => this.updateForm('is_refundable', e.target.checked)} />
                    <span> Is Security Deposit Refundable?</span>
                  </label>
                </div>
              </Col>
            }
            {
              type == 'view' ?
              <Col md="6">
                <div className="form-group">
                  <label className="label">Interest Refundable?</label>
                  <h6>{securityDeposit.is_interest_refundable?'YES':'NO'}</h6>
                </div>
              </Col>
              :
              <Col md="6">
                <div className="form-group">
                  <label className="label">
                    <input type="checkbox" checked={securityDeposit.is_interest_refundable} onChange={(e) => this.updateForm('is_interest_refundable', e.target.checked)} />
                    <span> Is Interest Refundable?</span>
                  </label>
                </div>
              </Col>
            }

            {
              type == 'view' ?
                <Col md="6">
                <div className="form-group">
                  <label className="label">Interest Rate</label>
                  <h6>{securityDeposit.interest_rate? securityDeposit.interest_rate + '%':'--'}</h6>
                </div>
                </Col>
              :
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Interest Rate</FieldLabel>
                  <FieldInput
                    value={securityDeposit.interest_rate}
                    type={type}
                    keyParam="interest_rate"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>

            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={securityDeposit.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
