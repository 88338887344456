import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect } from '../../Elements';
import { checkString } from '../../../../formValidator';
import { getLocalStorageInfo } from '../../../../utils';
export default class AddEditDelCond  extends Component {
  constructor(props){
    super(props);
    this.state = {
      deliveryCond: {},
      error:{}
    }
  }
  componentDidMount(){
    let data  = Object.assign({}, this.props.deliveryCondObj);
    if(this.props.type != 'add'){
      data = {
        ...data,
        asset_conditions_for_contract_id: data.asset_conditions_for_contract.id
      }
    }
    delete data['asset_conditions_for_contract'];
    this.setState({
      deliveryCond: data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      deliveryCond: {
        ...prevState.deliveryCond,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditDeliveryCond = (e) => {
    e.preventDefault();
    const validateNewInput = {
      asset_conditions_for_contract_id: checkString({value: this.state.deliveryCond.asset_conditions_for_contract_id, required: true, minLength: '', maxLength: '', message: 'Please Select Clause'}),
      terms: checkString({value: this.state.deliveryCond.terms, required: true, minLength: '', maxLength: '', message: 'Please Enter The Terms'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditDeliveryCond(this.state.deliveryCond, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { type, contractCondn, delCondLoader } = this.props;
    const { deliveryCond, error } = this.state;
    return (
      <form onSubmit={(e) => this.addEditDeliveryCond(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Clause
                  { type !='view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Clause'
                  keyParam="asset_conditions_for_contract_id"
                  value={deliveryCond.asset_conditions_for_contract_id}
                  type={type}
                  options={contractCondn}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                  name = { Object.keys(deliveryCond).length && type != 'add' ? this.props.deliveryCondObj.asset_conditions_for_contract.name : ''}
                />
              <h6 className="error-msg">{error.asset_conditions_for_contract_id}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Terms
                  { type !='view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldTextarea
                  value={deliveryCond.terms}
                  type={type}
                  keyParam="terms"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.terms}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Post Delivery</FieldLabel>
                <FieldTextarea
                  value={deliveryCond.actual}
                  type={type}
                  keyParam="actual"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Exceptions	Done</FieldLabel>
                <FieldTextarea
                  value={deliveryCond.exceptions_done}
                  type={type}
                  keyParam="exceptions_done"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={deliveryCond.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view'?
          <div className="submit-block">
            <input disabled={delCondLoader} type="submit" className="primary-btn" value={delCondLoader ? 'processing...':'Save Changes' }/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
            { type == 'edit' && getLocalStorageInfo().user.permission['contracts']['delivery_conditions'].indexOf('D') != -1 ?
              <Link style={{color:'#dc3545',marginTop:'10px',float:'right'}}onClick={() => {this.props.deliveryConditionDeleteCrud(this.state.deliveryCond.id);this.props.toggleEditSideBar()}} >Delete</Link>:null
            }
          </div>:null
        }
      </form>
   )
 }
}
