import * as actions from '../actions';
export default function( state={
  mrClaimList: [],
  mrClaimLoader: false
},action){
  switch (action.type) {
    case actions.MR_CLAIM_LIST:
      return {
        ...state,
        mrClaimList: action.payload
      }
    case actions.MR_CLAIM_LOADER:
      return {
        ...state,
        mrClaimLoader: action.payload
      }
    default:
      return state;
  }
}
