import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';

export default class NonCashTxRow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { nonCashFlow } = this.props;
    if(nonCashFlow.type_of_transaction == 2){
    return (
      <Row className="cash-non-cash-trxn-row" onClick={this.props.viewNonCashTransaction}>
         <Col md="3" xs="3">
          <span className="para-ui">{nonCashFlow.sub_type_of_transaction ? nonCashFlow.sub_type_of_transaction : '--'}</span>
        </Col>
        <Col md="3" xs="3">
          <span className="para-ui">{nonCashFlow.retention_period_start_date && nonCashFlow.retention_period_end_date ? `${moment(nonCashFlow.retention_period_start_date).format(displayDateFormat)} To ${moment(nonCashFlow.retention_period_end_date).format(displayDateFormat)}` : '--'}</span>
        </Col>

        <Col md="3" xs="3">
          <span className="para-ui">
          {nonCashFlow.amount ?
            <NumericLabel params={showCurrencyFormat(nonCashFlow.currency)}>{nonCashFlow.amount}</NumericLabel>
             : '--'}
          </span>
        </Col>
        <Col md="3" xs="3">
          <span className="para-ui">{nonCashFlow.purpose ? nonCashFlow.purpose : '--'}</span>
        </Col>
      </Row>
      )
    }else{
      return false
    }
  }
}
