import * as actions from '../actions';

export default function( state = {
	projectTrackerDtls: {},
	corTrackerDtls: {},
	manPowerTrackerDtls: {},
	trackerLoader: true,
	exportTrackerLoader:false,
	infiniteLoader: false,
	utilizationData:{}
}, action ){
	switch(action.type){
		case actions.INFINITE_LOADER:
		return {
			...state,
			infiniteLoader: action.payload
		}
		case actions.GET_PRJ_TRACKER_DETLS:
		return {
			...state,
			projectTrackerDtls: action.payload
		}
		case actions.UPDATE_REMARKS:
		return {
			...state,
			projectTrackerDtls: {
				...state.projectTrackerDtls,
				projects: state.projectTrackerDtls.projects.map(project => project.slug==action.payload.slug ? {...project,
					project_remarks:action.payload.remarks}:project)
			}
		}
		case actions.GET_PRJ_TRACKER_DETLS_EXPANSION:
		return {
			...state,
			projectTrackerDtls: {...state.projectTrackerDtls, projects: [...state.projectTrackerDtls.projects, ...action.payload]}
		}
		case actions.GET_COR_TRACKER_DETLS:
			return {
				...state,
				corTrackerDtls: action.payload
			}
		case actions.GET_COR_TRACKER_DETLS_EXP:
			return {
				...state,
				corTrackerDtls: {...state.corTrackerDtls, projects: [...state.corTrackerDtls.projects,...action.payload]}
			}
		case actions.GET_MAN_POWER_TRACKER_DETLS:
			return {
				...state,
				manPowerTrackerDtls: action.payload
			}
		case actions.GET_MAN_POWER_TRACKER_DETLS_EXPANSION:
			return {
				...state,
				manPowerTrackerDtls:{...state.manPowerTrackerDtls, users: [...state.manPowerTrackerDtls.users, ...action.payload.users]}
			}
		case actions.TRACKER_LOADER:
		return{
			...state,
			trackerLoader: action.payload
		}

		case actions.TRACKER_EXPORT_LOADER:
		return{
			...state,
			exportTrackerLoader: action.payload
		}

		case actions.UTILIZATION_GRAPH:
		return{
			...state,
			utilizationData: action.payload
		}



	default:
		return state
	}
}
