import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getDataUri, dataURItoBlob } from '../../../../../utils';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { triggerLoader } from '../../../Projects/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
export const fetchGenPicturesAc = (props) =>  {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/general-pictures/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FORM_GENPICTURES,
          payload: response.data.data
        });
        dispatch(triggeLoader(false));
      }
    })
  }
}

export const addGenPicturesAc = (props, status, id, files) => {
 return (dispatch) => {
    var formData = new FormData();
    formData.append('aircraft_section_id', id);
    formData.append('status', status);
    for (let i = 0; i < files.length; i++) {
       let file = files[i];
       if (!file.type.match('image.*')) {
         toastFlashMessage( file.name + ' is not Valid Format', 'error');
          continue;
        }
      formData.append('files[]', file, file.name);
     }
     // if(!formData.get('files[]')){
     //  return false;
     // }
     var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          // dispatch({
          //   type: actions.PROGRESS_PERCENTAGE,
          //   payload: percentage
          // });
        }
    }
    dispatch(triggeLoader(true));
    globalPostService('technical/workorder/'+props.params.workOrderSlug+'/general-pictures/',formData, config)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        if(status == 1){
          dispatch({
            type: actions.UPDATE_FORM_GENPICTURES,
            payload: {id: id, pictures: response.data.data.pictures}
          })
        }else{
          dispatch({
            type: actions.ADD_IMPORT_GEN_PIC,
            payload: {pictures: response.data.data.pictures}
          })
        }
        dispatch(usageDetailAc())
        trackActivity('File uploaded', {
          page_title: `T001`,
          application_module: 'Inspection',
          item_type: files[0].type,
          file_name: files[0].name,
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_id:response.data.data.pictures[0].id
        })
      }
      dispatch(getFormDetailsAc(props));
      dispatch(triggeLoader(false));
    })
  }
}

// Picture Segregation
export const addPictureSegregationAc = (props, files) => {
  return(dispatch) => {
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!file.type.match('image.*')) {
        toastFlashMessage( file.name + ' is not Valid Format', 'error');
        continue;
      }
      formData.append('files[]', file, file.name);
    }

    globalPostService(`technical/workorder/${props.params.workOrderSlug}/ai-segregator/`, formData)
    .then(response => {
      dispatch({
        type: actions.AI_WAIT_TIMER,
        payload: {flag: false}
      });
      if(response.data.statusCode == 200) {
        dispatch({
          type:actions.FORM_PICTURE_SEGREGATION,
          payload:response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
        trackActivity('File uploaded', {
          page_title: `T001`,
          application_module: 'Inspection',
          item_type: files[0].type,
          file_name: files[0].name,
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_id:response.data.data.id
        })
      }
    })
  }
}
export const  fetchPictureSegregationAc = (props) => {
  return(dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService(`technical/workorder/${props.params.workOrderSlug}/ai-segregator/`, {})
    .then(response => {
      dispatch(triggeLoader(false));
      if(response.data.statusCode == 200 || response.data.statusCode == 204) {
        dispatch({
          type:actions.FORM_PICTURE_SEGREGATION,
          payload:response.data.data
        });
      }
    })
  }
}
export const  deletePictureSegregationAc = (props, data) => {
  return(dispatch) => {
    dispatch(triggeLoader(true));
    globalPostService(`technical/workorder/${props.params.workOrderSlug}/ai-segregator/delete_images`, {image_id:data.imageIds})
    .then(response => {
      dispatch(triggeLoader(false));
      if(response.data.statusCode == 200) {
        dispatch(fetchPictureSegregationAc(props));
        dispatch({type: actions.TOGGLE_MOVE_SECTION, payload: {flag: false}});
        toastFlashMessage(response.data.message, 'success');
        trackActivity('File Deleted', {
          page_title: `T001`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_id:data.imageIds
        })
      }
    })
  }
}

export const moveSegregationImagesAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalPostService(`technical/workorder/${props.params.workOrderSlug}/ai-segregator/move_image`, {image_id: data.imageIds,aircraft_section_id: data.sectionId})
    .then(response => {
      dispatch(triggeLoader(false));
      if(response.data.statusCode == 200) {
        dispatch({
          type:actions.FORM_PICTURE_SEGREGATION,
          payload:response.data.data
        });
        dispatch({type: actions.TOGGLE_MOVE_SECTION, payload: {flag: false}});
        toastFlashMessage(response.data.message, 'success');
        trackActivity('File Deleted', {
          page_title: `T001`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          aircraft_section_id: data.sectionId,
          file_id: data.imageIds
        })
      }
    })
  }
}

export const publishSegregationImagesAc = (props, data) => {
  console.log('data', data);
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalPostService(`technical/workorder/${props.params.workOrderSlug}/ai-segregator/publish_images`, {batch: data.filter(batch => batch.status).map(bt => { return bt.id})})
    .then(response => {
      dispatch(triggeLoader(false));
      if(response.data.statusCode == 200) {
        dispatch({
          type:actions.FORM_PICTURE_SEGREGATION,
          payload:{predicted_sections:[], batches: []}
        });
        toastFlashMessage(response.data.message, 'success');
        trackActivity('File Published', {
          page_title: `T001`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          name: data[0].name,
          status: data[0].status,
          file_id: data[0].id
        })
      }
    })
  }
}


export const editGenPictureAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    let fileInfo = Object.assign({}, data);

    globalGetService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/get-pictures-url/`, {pic_id: data.id})
    .then(response => {
      if(response.data.statusCode == 200){
        fileInfo = {
          ...fileInfo,
          image: response.data.data
        }
        dispatch({
          type: actions.EDIT_GEN_IMAGE,
          payload: {flag: true, data:fileInfo}
        })
        trackActivity('File edited', {
          page_title: `T001`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug:props.params.formSlug,
          file_id:data.id,
          file_name: data.name
        })
      }
      dispatch(triggeLoader(false));
    })
  }
}

export const importGeneralPicAc = (props, inReportCount, queryParams={}) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/general-pictures/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.IMPORT_GEN_PIC,
          payload: {
            flag: true,
            inReportCount: inReportCount,
            data: response.data.data,
            selected:[]
          }
        })
      }
      dispatch(triggeLoader(false));
    })
  }
}
export const importGenInReprtAc = (props, importGnPicCrud) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalPostService('technical/workorder/'+props.params.workOrderSlug+'/publish-pictures/', {aircraft_section_id: importGnPicCrud.data.id, pictures: importGnPicCrud.selected})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.IMPORT_GEN_PIC,
          payload: {
            flag: false,
            data: {},
            selected:[]
          }
        });
        dispatch({
          type: actions.UPDATE_FORM_GENPICTURES,
          payload: {id: importGnPicCrud.data.id, pictures: response.data.data.pictures}
        })
      }
      dispatch(triggeLoader(false));
      dispatch(getFormDetailsAc(props));
    })
  }
}
export const removeGenPicAc = (props, id, sectionId) => {
  return (dispatch) => {
    var formData = new FormData();
    formData.append('status', 0);
    dispatch(triggeLoader(true));
    globalPutService('technical/workorder/'+props.params.workOrderSlug+'/general-pictures/' +id+ '/', formData)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.REMOVE_GEN_PIC,
          payload:{sectionId: sectionId, picId: id}
        })
        trackActivity('File removed', {
          page_title: `T001`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug:props.params.formSlug,
          file_id:id
        })
      }
      dispatch(triggeLoader(false));
      dispatch(getFormDetailsAc(props));
    })
  }
}
export const deleteGenPicAc = (props, importGnPicCrud) => {
  return (dispatch) => {
    dispatch(triggeLoader(true));
    globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/delete-pictures/', {aircraft_section_id: importGnPicCrud.data.id, pictures: importGnPicCrud.selected})
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.IMPORT_GEN_PIC,
          payload:{flag: false, data: {}, selected: []}
        })
      }
      dispatch(triggeLoader(false));
      dispatch(getFormDetailsAc(props));
    })
  }
}


export const saveEditedImageAc = (props, imageId, image) =>{
  var formData = new FormData();
  return (dispatch) =>{
    dispatch(triggeLoader(true));
    dataURItoBlob(image).then(editedImage => {
      formData.append('file', editedImage);
      globalPutService(`technical/workorder/${props.params.workOrderSlug}/general-pictures/${imageId}/`,formData)
      .then(response =>{
        if(response.data.statusCode == 200 ){
          dispatch({
            type: actions.EDIT_GEN_PIC,
            payload:response.data.data
          });
          dispatch({
            type: actions.EDIT_GEN_IMAGE,
            payload: { flag: false, data: {} }
          });
        }
        dispatch(triggeLoader(false));
        toastFlashMessage(response.data.message, 'success');
        dispatch(getFormDetailsAc(props));
      })
    })
  }
}

export const sortPicturesAc = (props, sortedPictures, listId, pictures) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.GEN_SORT_LOADER,
      payload: {[listId]: true}
    })
    globalPostService('/technical/workorder/'+props.params.workOrderSlug+'/reorder-pictures/',{pictures: sortedPictures})
    .then(response => {
      dispatch({
        type: actions.SORT_GEN_IMAGES,
        payload:{
          listId,
          sortedPictures: pictures
        }
      })
      setTimeout(() => dispatch({
        type: actions.GEN_SORT_LOADER,
        payload: {[listId]: false}
      }), 1000);
      dispatch(getFormDetailsAc(props));
    })
  }
}
export const triggeLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.GEN_PIC_LOADER,
      payload: flag
    })
  }
}

export const getFormDetailsAc = (props,type=1, application) =>{
  let url='';
  if(type==2){
    url=`technical/engine-workorder/${props.params.workOrderSlug}/forms/?form=${props.params.formSlug}`
  }else{
    url= `technical/workorder/${props.params.workOrderSlug}/forms/?form=${props.params.formSlug}`
  }
  return (dispatch) =>{
    globalGetService(url)
    .then(response =>{
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.GET_FORM_INFO,
          payload: response.data.data
        })
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        if(application !==undefined || application !==null){
          trackActivity('Page Visited', {
      			application_module: 'Inspection',
      			asset: response.data.data.asset.asset_type === 1 ? `MSN ${response.data.data.asset.msn}` : [3,4,5].includes(response.data.data.asset.asset_type) ? `${assetTypeNames[response.data.data.asset.asset_type]} ${response.data.data.asset.serial_number}` : `ESN ${response.data.data.asset.esn}`,
      			page_title: application
      		})
        }
      }
    })
  }
}


export const updateFormStatusAc = (props,data,type=1) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.TOGGLE_FORM_STATUS_LOADER,
      payload: true
    });
    globalPostService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`, data)
    .then(response =>{
      dispatch({
        type: actions.TOGGLE_FORM_STATUS_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch(getFormDetailsAc(props,type));
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
