import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../constants';
// import '../assets/management.scss';
// import { ListGroup, ListGroupItem } from 'reactstrap';


export default class InvestmentComponent extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="investment-section">
              <Container>
                <Row className="investment-row">
                  <Col lg="12">
                    <h3>As featured in</h3>
                    <ul className="list-inline">
                      <li>
                        <img src={imgStoragePath+"landing/logo2.png"} alt="aviator" />
                      </li>
                      <li>
                        <img src={imgStoragePath+"landing/logo1.png"} alt="irish tech news" />
                      </li>
                      <li>
                        <img src={imgStoragePath+"landing/logo3.png"} alt="flying in ireland" />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
        )
    }
}
