import React , {Component} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router';
import { getCookie, setGlobalCookie } from '../../utils'
import './../assets/styles/featuremodal.scss'

import moment from 'moment';

class FeaturesModal extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    // setCookie = ()=>{
    //     setGlobalCookie('newFeatures','yes', 40)
    // }

    render(){
       return(
            <div>
                <Modal isOpen={this.props.flag} className="modal-container" onClick={this.props.featureModalToggle}>
                    <ModalHeader onClick={this.props.featureModalToggle} >What's new</ModalHeader>
                    <ModalBody onClick={this.props.featureModalToggle}>
                    <ul className="features-list" >
                        {this.props.featuresList.map((feature,index)=>{
                        return(
                            <div key={index} >
                            <li  className="feature-item">
                            <p className="feature-release-time">{ feature.release_date ?   moment(feature.release_date).format('MMM DD, YYYY') : '-'}</p>
                            <div >
                            <h2 className="feature-title">{feature.title}</h2>
                            <div className="dangerouslySetInnerHTML">
                            <div dangerouslySetInnerHTML={{__html: feature.description?feature.description:''}} ></div>
                            </div>
                            </div>
                            </li>
                            <hr/>
                            </div>
                        )
                        })}
                        </ul>
                    </ModalBody>
                    <ModalFooter onClick={this.props.featureModalToggle}>
                    <Button color="primary" onClick={this.props.featureModalToggle} >OK</Button>
                    </ModalFooter>
                </Modal>
             </div>
        )
    }
}

export default withRouter(FeaturesModal);
