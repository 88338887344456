import React, { Component } from 'react';
import { ContrListCol } from '../../Elements';
import { imgStoragePath } from '../../../../constants';
export default class ReturnCondRow extends Component{
  constructor(props){
    super(props);
  }
  render() {
    const { returnCond } = this.props;
    const returnCondObj = [
      { value: returnCond.asset_conditions_for_contract.name ? returnCond.asset_conditions_for_contract.name:'--' },
      { value: returnCond.terms ? returnCond.terms.length > 120 ? returnCond.terms.substring(0,117) + ' ...' : returnCond.terms :'--'},
      { value: returnCond.flags ? <img width="15" src={imgStoragePath+"red_flag.png"} alt="img"/> : '--' }
    ]
    return(
      <tr onClick={this.props.viewReturnCond}>
        {returnCondObj.map((returnCond, index) => <ContrListCol colObj={returnCond} key={index}/>)}
      </tr>
    )
  }
}
