import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { workingGrpTeam, addMember } from '../../contractAddObject';
export const workingGroupListAc = (params) =>  {
  return (dispatch) => {
    dispatch({
      type: actions.WORKING_GRP_LOADER,
      payload: true
    });
    globalGetService('contracts/' + params.contract_slug + '/working-group-master-list/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.WORKING_GROUP_LIST,
          payload: response.data.data
        })
      }
      setTimeout(() => {
        dispatch({
          type: actions.WORKING_GRP_LOADER,
          payload: false
        },1500);
      });
    })
  }
}

// export const workingGroupViewAc = (params,id,index) =>  {
//   return (dispatch) => {
//     dispatch({
//       type: actions.WORKING_GRP_LOADER,
//       payload: true
//     });
//     globalGetService('contracts/'+params.contract_slug+'/working-group/' + id + '/', {})
//     .then(response => {
//       dispatch({
//         type: actions.WORKING_GRP_LOADER,
//         payload: false
//       });
//       if(response.data.statusCode == 200){
//         dispatch({
//           type: actions.WORKING_GROUP_VIEW,
//           index: index,
//           payload: response.data.data
//         })
//       }
//     })
//   }
// }

export const addEditWorkingGrpAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/working-group/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.WORKING_GROUP_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.WORKING_GRP_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Working Groups`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug:`${params.contract_slug}`,
            item_type: `Working Group`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+ params.contract_slug +'/working-group/' + data.id + '/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.WORKING_GROUP_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.WORKING_GRP_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Working Groups`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Working Group`,
            item_id: data.id
          })
        }
      })
    }
  }
}
export const addEditGrpMemberAc = (params, data, type, workingGroupId, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+ params.contract_slug +'/working-group/' + workingGroupId + '/member/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.WORKING_GROUP_MEMBER_ADD,
            payload:response.data.data
          });
          dispatch({
            type: actions.WORKING_GRP_MEM_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Working Groups`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Team Member`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/working-group/' + data.working_group_id + '/member/' + data.id + '/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.WORKING_GROUP_MEMBER_EDIT,
            payload: response.data.data
          });
          dispatch({
            type: actions.WORKING_GRP_MEM_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Working Groups`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Team Member`,
            item_id: data.id
          })
        }
      })
    }
  }
}




export const workingGroupCrudFnAc = (params, type, id) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.WORKING_GRP_CRUD,
        payload: {type: type, data:workingGrpTeam , flag: true }
      });
    }else{
      dispatch({
        type: actions.WORKING_GRP_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/working-group/' + id + '/', {})
      .then(response => {
        dispatch({
          type: actions.WORKING_GRP_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.WORKING_GRP_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });

        }
      })
    }
  }
}



export const workingGroupMemCrudFnAc = (params, type, workingGroupId, id) => {
    return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.WORKING_GRP_MEM_CRUD,
        payload: {type: type, data:addMember , flag: true }
      });
    }else{
      dispatch({
        type: actions.WORKING_GRP_LOADER,
        payload: true
      });
      globalGetService('contracts/'+ params.contract_slug +'/working-group/' + workingGroupId + '/member/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.WORKING_GRP_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.WORKING_GRP_MEM_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}
