import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getDataUri, dataURItoBlob } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
export const fetchDataPlatePicsAc = (props, queryParams={}) =>{
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('/technical/workorder/'+props.params.workOrderSlug+'/data-plate-pictures/', queryParams)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.DATA_PLATE_LIST,
          payload: response.data.data
        });
      }
    })
  }
}
export const editPlatePicAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    let fileInfo = Object.assign({}, data);
    globalGetService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/get-pictures-url/`, {pic_id: data.id})
    .then(response => {
      if(response.data.statusCode == 200){
        fileInfo = {
          ...fileInfo,
          image: response.data.data
        }
        dispatch({
          type: actions.PLATE_EDIT_PIC,
          payload: {flag: true, data:fileInfo}
        })
        trackActivity('File Edited', {
          page_title: `T002`,
          application_module: 'Inspection',
          item_type: data.type,
          file_name: data.name,
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_id: data.id,
          data_plate: data.data_plate
        })
      }
      dispatch(triggerLoader(false));
    })
  }
}
export const addAndChangePlateAc = (props, id, sectionId, pictureId, files) => {
  return (dispatch) => {
    var formData = new FormData();
    formData.append('data_plate_id', id);
    formData.append('aircraft_section_id', sectionId);
    if(pictureId != null){
      formData.append('picture_id', pictureId);
    }
    formData.append('file', files[0], files[0].name);
    if (!files[0].type.match('image.*')) {
      toastFlashMessage( files[0].name + ' is not Valid Format', 'error');
       return false;
     }
    var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          // dispatch({
          //   type: actions.PROGRESS_PERCENTAGE,
          //   payload: percentage
          // });
        }
    }
    dispatch(triggerLoader(true));
    globalFileUploadService('technical/workorder/'+props.params.workOrderSlug+'/data-plate-pictures/upload/', formData, config)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.ADD_REPLACE_DATE_PLATE,
          payload: response.data.data
        });
        dispatch(usageDetailAc())
        dispatch(getFormDetailsAc(props));
        trackActivity('File uploaded / Changed', {
          page_title: `T002`,
          application_module: 'Inspection',
          item_type: files[0].type,
          file_name: files[0].name,
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_id:id,
        })
      }
    })
  }
}

export const deletePlateAc = (props, id, pictureId) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/delete-pictures/', {
      pictures: [pictureId],
      id: id,
    })
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.DELETE_PLATE_PICTURES,
          payload: {id: id}
        })
        trackActivity('File Deleted', {
          page_title: `T002`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_id: pictureId
        })
      }
    })
  }
}

export const saveEditedImageAc = (props, imageId, image) =>{
  return (dispatch) =>{
    var formData = new FormData();
    dispatch(triggerLoader(true));
    dataURItoBlob(image).then(editedImage => {
      formData.append('file', editedImage);
      globalPutService('technical/workorder/'+props.params.workOrderSlug+'/general-pictures/'+imageId+'/',formData)
      .then(response =>{
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200 ){
          dispatch({
            type: actions.ADD_REPLACE_DATE_PLATE,
            payload: response.data.data
          });
          dispatch({
            type: actions.PLATE_EDIT_PIC,
            payload: { flag: false, data: {} }
          });
        }
        dispatch(triggerLoader(false));
        toastFlashMessage(response.data.message, 'success');
      })
    })
  }
}
export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.DATA_PLATE_LOADER,
      payload: flag
    })
  }
}
