import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { CardElement, injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';


class PaymentCard extends Component{
  constructor(props){
    super(props)
    this.state={
      clientSecret: false,
      saveCard: true,
      address:{
        name: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        phone: '',
        email:''
      },
      error:{},
      card_id: ''
    }
  }

  updateAddress = (key, value) =>{
    this.setState({
      address: {...this.state.address, [key]: value}
    })
  }

  componentDidMount(){

  }

  submit = (ev) => {
      ev.preventDefault();
      this.props.stripe.createToken().then(payloadInner => {})
      let validateNewInput = {
        name: this.state.address.name.trim() == '' ? 'First name is required' : '',
        address_line1: this.state.address.address_line1.trim() == '' ? 'Address is required' : '',
        city: this.state.address.city.trim() == '' ? 'City is required' : '',
        state: this.state.address.state.trim() == '' ? 'State is required' : '',
        country: this.state.address.country.trim() == '' ? 'Country is required' : '',
        postal_code: this.state.address.postal_code.trim() == '' ? 'Postal Code is required' : '',
        phone: this.state.address.phone.trim() == '' ? 'Phone is required' : '',
      }

      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        if (this.props.stripe) {
          this.props.toggleLoader(true)
          this.setState({clientSecret: true})
          if(this.state.saveCard){
            this.props.stripe.createToken().then(payloadInner => {
              if(payloadInner.error){
                this.props.toggleLoader(false)
                this.setState({clientSecret: false, stripeError: payloadInner.error.message})
              }else{
                this.props.stripe.createToken().then(payload => {
                  if(payload.error){
                    this.props.toggleLoader(false)
                    this.setState({clientSecret: false, stripeError: payload.error.message})
                  }else{
                    this.setState({card_id: payloadInner.token.card.id})
                    this.props.saveCard(payloadInner.token.id, { card_token: payload.token.id}, {...this.state.address,stripe_card_id : this.state.card_id},true)
                  }
                }).catch(error => {})
              }
            }).catch(error => {})
          }else{
            this.props.stripe.createToken().then(payload => {
              if(payload.error){
                this.props.toggleLoader(false)
                this.setState({clientSecret: false, stripeError: payload.error.message})
              }else{
                this.props.saveCard(payload.token.id, {card_token: payload.token.id}, {...this.state.address,stripe_card_id : this.state.card_id},false)
                this.setState({clientSecret: false})
              }
            }).catch(error => {

            })
          }
        } else {

        }
      }else{
        this.setState({
          error: validateNewInput
        });
      }
  }

  render(){
    return(
      <div className="payment-con checkout">
        <label className="label">Enter Card Details<span className="reqStar">*</span></label>
      <div className="payment-card">
        <CardElement/>
        <h6 className="error-msg">{this.state.stripeError}</h6>
      </div>
        <Row>
          <Col md="12"><label className="label">Billing Address<span className="reqStar">*</span></label></Col>
        <Col md="6" className="margin-bottom-20">
            <input type="text" name="first_name" onChange={(evt) => this.updateAddress('name', evt.target.value)} placeholder="First Name"/>
            <h6 className="error-msg">{this.state.error.name}</h6>
          </Col>
          <Col md="6" className="margin-bottom-20">
            <input type="text" placeholder="Last Name"/>
          </Col>
          <Col md="12" className="margin-bottom-20">
            <input type="text" onChange={(evt) => this.updateAddress('address_line1', evt.target.value)} placeholder="Address"/>
          <h6 className="error-msg">{this.state.error.address_line1}</h6>
          </Col>
          <Col md="4" className="margin-bottom-20">
            <input type="text" onChange={(evt) => this.updateAddress('city', evt.target.value)} placeholder="City"/>
          <h6 className="error-msg">{this.state.error.city}</h6>
          </Col>
          <Col md="4" className="margin-bottom-20">
            <input type="text" onChange={(evt) => this.updateAddress('state', evt.target.value)} placeholder="State / Region"/>
          <h6 className="error-msg">{this.state.error.state}</h6>
          </Col>
          <Col md="4" className="margin-bottom-20">
            <input type="text" onChange={(evt) => this.updateAddress('postal_code', evt.target.value)} placeholder="Postal Code"/>
          <h6 className="error-msg">{this.state.error.postal_code}</h6>
          </Col>
          <Col md="6" className="margin-bottom-20">
            <input type="text" onChange={(evt) => this.updateAddress('country', evt.target.value)} placeholder="Country"/>
          <h6 className="error-msg">{this.state.error.country}</h6>
          </Col>
          <Col md="6" className="margin-bottom-20">
            <input type="text" onChange={(evt) => this.updateAddress('phone', evt.target.value)} placeholder="Phone Number"/>
          <h6 className="error-msg">{this.state.error.phone}</h6>
          </Col>
          <Col md="12" className="margin-bottom-20">
            <input checked={this.state.saveCard} disabled={this.props.defaultChecked} onChange={(evt) => this.setState({saveCard: evt.target.checked})} type="checkbox" name="save_card" style={{height: '15px'}}/> <span style={{color: '#808080', cursor: 'pointer'}} onClick={() => !this.props.defaultChecked ? this.setState({saveCard: !this.state.saveCard}) : null }>Consider this card as default for payments</span>
          </Col>
          <Col md="12">
            <button disabled={this.props.licenseLoader} onClick={this.submit} className="add-new btn-primary"><i className="fa fa-lock"></i> {this.props.licenseLoader ? 'Processing....' : 'Pay'}</button>
          </Col>
          <Col md="12">
            <p>* All fields are required</p>
          </Col>
        </Row>
      </div>
    )
  }
}

export default injectStripe(PaymentCard)
