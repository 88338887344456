import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { withRouter, Link } from 'react-router';
import { imgStoragePath } from '../../../../constants'
class DataRoomTile extends Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <Link to={this.props.url} className="record-list" style={this.props.style}>
        <Row>
          <Col md="8">
            <img src={imgStoragePath+"folder_icn2.png"} style={{padding:'5px', width: '38px'}}/>
          <span style={{fontSize: '18px'}}>{this.props.title}</span>
          </Col>
          <Col md="4"></Col>
        </Row>
      </Link>
    )
  }
}

export default withRouter(DataRoomTile)
