export const addCrewSeat = {
  location: '',
  name: '',
  part_number: '',
  serial_number: '',
  manufacturer: '',
  remarks: '',
}

export const addlopa = {
  name: '',
  image: '',
}

export const addLavatory = {
  name: '',
  location: '',
  part_number: '',
  serial_number: '',
  manufacturer: '',
  remarks: '',
}

export const addPassengerSeat = {
  seat_type: '',
  no_of_seats: '',
  model: '',
  part_number: '',
  convertable_or_fixed: '',
  manufacturer: '',
  remarks: ''
}

export const addGalley = {
  name: '',
  location: '',
  part_number: '',
  manufacturer: '',
  quantity: '',
  configuration: '',
  galley_type: '',
  remarks: '',
  configuration: '',
}

export const addEquipment = {
  equipment: '',
  manufacturer: '',
  part_number: '',
  serial_number: '',
  equipment_type: '',
  equipment_type_other: null,
  remarks: '',
}

export const addAvionic = {
  ata_code: '',
  item: '',
  item_description: '',
  manufacturer: '',
  model: '',
  part_number: '',
  quantity: '',
}

export const addPropeller = {
  serial_number: '',
  model: '',
  manufacturer: '',
  thrust: 0,
  is_fitted: 0,
  is_titled: 0,
  installed_position: 1,
  off_wing_detail: {},
}

export const addEngine = {
  esn: '',
  position: '',
  region: '',
  country: '',
  credit_classification: '',
  manufacturing_date: null,
  constructed_model: '',
  status: '',
  off_wing_status: '',
  on_wing_status: '',
  is_fitted: '',
  is_titled: '',
  engine_type: '',
  manufacturer: '',
  aircraft: '',
  tsn: null,
  csn: null,
  llp:{},
  average_monthly_hours: null,
  average_monthly_cycles: null,
  operating_thrust: '',
  purchase_thrust: '',
  off_wing_detail: {},
  match_csn_to_llp:false
}

export const addApu ={
  serial_number: '',
  part_number: '',
  last_shop_visit_date: null,
  status: '',
  date_of_manufacture: null,
  manufacturer: '',
  aircraft: '',
  state: '',
  off_wing_status: '',
  on_wing_status: '',
  is_fitted: '',
  is_titled: '',
  tsn: null,
  csn: null,
  average_monthly_hours: null,
  average_monthly_cycles: null,
  off_wing_detail: {}
}

export const addLg ={
  serial_number: '',
  part_number: '',
  last_shop_visit_date: null,
  position: '',
  status: '',
  date_of_manufacture: '',
  manufacturer: '',
  aircraft: '',
  state: '',
  off_wing_status: '',
  on_wing_status: '',
  is_fitted: '',
  is_titled: '',
  tsn: null,
  csn: null,
  average_monthly_hours: null,
  average_monthly_cycles: null,
  off_wing_detail: {}
}
export const addLLP =  {
  module:null,
  description:null,
  part_number:null,
  serial_number:null,
  tsn:null,
  tso:null,
  csn:null,
  cso:null,
  cycles_max_limit:null,
  cycles_remaining:null,
  as_of_date:null
}
