import React from 'react';
import { Col } from 'reactstrap';
const GenTabField = (props) => {
  return(
    <Col md={props.grid} className={props.classText}>
      <h6>{props.label ? props.label : ''}</h6>
      <span>{props.text !== undefined && props.text !== null? props.text : '--'}</span>
    </Col>
  )
}
export default GenTabField;
