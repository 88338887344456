import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements'
export default class GalleyTab extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { galley, index, asset_slug } = this.props;
    const galleyObj = [
      {value: galley.name},
      {value: galley.part_number},
      {value: galley.manufacturer},
      {value: galley.quantity},
      {value: galley.configuration &&  galley.configuration.label ? galley.configuration.label : '--'},
      {value: galley.galley_type && galley.galley_type.label ? galley.galley_type.label: '--'},
      {value: galley.location && galley.location.label ? galley.location.label : '--'} 

    ]
    return (
      <tr onClick={() => this.props.viewGalley(asset_slug, galley.id, index, 'galleyView')}>
         {galleyObj.map((galley,index) =>
           <ContrListCol key={index} colObj={galley}   />
        )}
      </tr>
    )
  }
}
