import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PopoverNote from './PopoverNote';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';
import moment from "moment";
import Select , { Creatable } from 'react-select';
import { checkString, checkValidDate, checkIntNumber, checkNumber } from '../../formValidator';
import { getLocalStorageInfo, toastFlashMessage } from '../../utils'
import { trackActivity } from '../../utils/mixpanel'
import { globalPostService } from '../../globalServices'
import { uploadLesseeLogoAc } from '../../shared/actionCreators';
import { imgStoragePath } from '../../constants';
export default class AddEditAsset extends Component {
  constructor(props){
    super(props);
    this.state = {
      asset: {ownership_type: 1},
      error:{},
      disabled: false,
      assetTransferModal: false,
      owner: {},
      newLessee:false
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.assetObj));
    trackActivity('Clicked', {event: 'Add Asset'})
    this.setState({
      asset:data
    });
    if(getLocalStorageInfo().defaultLessor.lessor_type != 1){
      this.setState({
        asset: {...this.state.asset, status: 2}
      })
    }
    //asset: {...this.state.asset, lessor_name: getLocalStorageInfo().defaultLessor.name, lessor_domain: getLocalStorageInfo().defaultLessor.company_domain}
    if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
      this.setState({
        disabled: true,
      })
    }
  }
  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      asset: {
        ...prevState.asset,
        location : address
      }
    }));
  };
  handleSelect = address => {
    let location =  geocodeByAddress(address);

    location.then(results =>
         getLatLng(results[0])
         )
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          asset: {
            ...prevState.asset,
            location : address,
            latitude: latLng['lat'],
            longitude: latLng['lng'],
          }
        })))
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
              asset: {
              ...prevState.asset,
                country: place.length ? place[0]['long_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));

        location.then(results =>
          results[0]
          ).then(country => {
            let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
            this.setState(prevState => ({
              ...prevState,
                asset: {
                ...prevState.asset,
                  country_code: place.length ? place[0]['short_name']:address
                }
              }))
          }

          )
          .catch(error => console.error('Error', error));
    };
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      asset: {
        ...prevState.asset,
        [type]: value
      },
      error:{
        ...prevState.error,
        [type]:''
      }
    }));
    if(type == 'status' && value != 2){
        this.setState(prevState => ({
          ...prevState,
          asset:{
            ...prevState.asset,
            off_lease_status:null
          }
        }))
    }
    if(type == 'aircraft_type'){
      this.props.fetchMaintenanceGrp(value);
      this.props.fetchManufacturer('aircraft_type_id', value);
    }else if(type == 'lessee_id'){
      if(value == ""){
        this.setState(prevState => ({
          ...prevState,
          asset: {
            ...prevState.asset,
            same_operator_lessee: false,
          },
        }));
      }
    }
    else if(type == 'same_operator_lessee'){
      this.setState(prevState => ({
        ...prevState,
        asset: {
          ...prevState.asset,
          same_operator_lessee:  !this.state.asset.same_operator_lessee,
          operator_id: ''
        },
      }));
    }else if(type == 'portfolio'){
      this.setState(prevState => ({
        ...prevState,
        asset: {
          ...prevState.asset,
          portfolio: typeof value == 'string' && value != '' ? {id: value, new: true} : {id: value},
        },
      }))
    }
    const userInfo = getLocalStorageInfo()
    let checkAsset = false
    Object.keys(userInfo.user.permission).map(key => {
      if(userInfo.user.permission[key].is_saas){
        checkAsset = false
      }
      return key
    })
    // if(checkAsset){
    //   setTimeout(() => {
    //       if(this.state.asset.msn){
    //         globalPostService('/console/check-aircraft/aircraft/',{msn: this.state.asset.msn,aircraft_type: this.state.asset.aircraft_type != '' ? this.state.asset.aircraft_type : null,date_of_manufacture: this.state.asset.date_of_manufacture,registration: this.state.asset.current_registration_number})
    //         .then(response => {
    //             if(response.data.data && response.data.data.exist_lessor_slug && getLocalStorageInfo().defaultLessor.slug != response.data.data.exist_lessor_slug){
    //               this.setState({
    //                 disabled: true,
    //                 asset: {...this.state.asset, lessor_type: response.data.data.lessor_type.value, exist_lessor_slug: response.data.data.exist_lessor_slug, lessor_domain: response.data.data.lessor_domain},
    //                 owner: response.data.data
    //               })
    //             }else if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
    //               this.setState({
    //                 disabled: this.state.disabled,
    //                 asset: {...this.state.asset, lessor_type: null, lessor_domain: getLocalStorageInfo().defaultLessor.company_domain, exist_lessor_slug: getLocalStorageInfo().defaultLessor.slug},
    //                 owner: {}
    //               })
    //             }else{
    //               this.setState({
    //                 disabled: false,
    //                 asset: {...this.state.asset, lessor_type: null},
    //                 owner: {}
    //               })
    //             }
    //         })
    //       }
    //
    //   }, 300)
    // }
  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          asset:{
            ...prevState.asset,
            lessee_id:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          asset:{
            ...prevState.asset,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        asset:{
          ...prevState.asset,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      asset:{
        ...prevState.asset,
        lessee_id:{
          ...prevState.asset.lessee_id,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          asset:{
            ...prevState.asset,
            lessee_id:{
              ...prevState.asset.lessee_id,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

  createAsset = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.asset);
    if(!data.location){
      data = {
        ...data,
        latitude: '',
        longitude: '',
        country: ''
      }
    }
    var validateNewInput = {
      aircraft_type: checkString({value: this.state.asset.aircraft_type, required: true, minLength: '', maxLength: '', message: 'Please select Aircraft Type'}),
      msn: checkString({value: this.state.asset.msn, required: true, minLength: '', maxLength: '', message: 'Please enter Manufacture Serial Number (MSN)'}),
      date_of_manufacture: checkValidDate({value: this.state.asset.date_of_manufacture, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
      maintenance_event_program_group: checkString({value: this.state.asset.maintenance_event_program_group, required: false, minLength: '', maxLength: '', message: 'Please select Maintenance Program group'}),
      status: getLocalStorageInfo().defaultLessor.lessor_type == 1 ? checkString({value: this.state.asset.status, required: true, minLength: '', maxLength: '', message: 'Please select Lease Status'}) : '',
      lessee_id:  checkString({value: this.state.asset.lessee_id, required: this.state.asset.status == 1 ? true : false, minLength: '', maxLength: '', message: 'Please select Lessee'}),
    };

    if(!this.state.asset.own_asset && (this.state.asset.exist_lessor_slug == undefined || this.state.asset.exist_lessor_slug == null || this.state.asset.exist_lessor_slug == '') && getLocalStorageInfo().defaultLessor.lessor_type == 3){
      validateNewInput = {...validateNewInput, exist_lessor_slug: 'Lessor is required'}
    }
    if(this.state.asset.exist_lessor_slug && this.state.asset.exist_lessor_slug == 'newlessor' && !this.state.asset.own_asset && (this.state.asset.lessor_domain == undefined || this.state.asset.lessor_domain == null || this.state.asset.lessor_domain == '') && getLocalStorageInfo().defaultLessor.lessor_type == 3){
      validateNewInput = {...validateNewInput, lessor_domain: 'Lessor email is required'}
    }
    if( this.state.asset.exist_lessor_slug && this.state.asset.exist_lessor_slug == 'newlessor' && !this.state.asset.own_asset && (this.state.asset.lessor_name == undefined || this.state.asset.lessor_name == null || this.state.asset.lessor_name == '') && getLocalStorageInfo().defaultLessor.lessor_type == 3){
      validateNewInput = {...validateNewInput, lessor_name: 'Lessor name is required'}
    }

    if(this.state.asset.ownership_type == 2 && ( this.state.asset.portfolio == undefined || this.state.asset.portfolio == null || this.state.asset.portfolio.id == '' || this.state.asset.portfolio.id == undefined || this.state.asset.portfolio.id == null)){
      validateNewInput = {...validateNewInput, portfolio: 'Portfolio is required'}
    }

    if(data.same_operator_lessee){
      data = {
        ...data,
        operator_id: data.lessee_id
      };
    }
    data = {
      ...data,
      asset_slug: this.props.slug,
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.state.disabled && Object.keys(this.state.owner).length){
        this.setState({
          assetTransferModal: true,
          asset: data
        })
        return
      }
      this.props.toggleEditSideBar();
      if(data.exist_lessor_slug == 'newlessor'){
        delete data.exist_lessor_slug
      }
      this.props.createAsset(data);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  addNewAndSave = () =>{
    this.props.toggleEditSideBar();
    let data = this.state.asset
    if(data.exist_lessor_slug == 'newlessor'){
      delete data.exist_lessor_slug
    }
    this.props.createAsset({...data,from_scratch: true});
  }

  requesteAccess = () => {
    this.props.toggleEditSideBar();
    let data = this.state.asset
    if(data.exist_lessor_slug == 'newlessor'){
      delete data.exist_lessor_slug
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
      this.props.createAsset({...data, notify: true});
    }else{
      this.props.createAsset({...data, request_for_access: true});
    }
  }

  render(){
    const { lessorList, aircraftTypes, clients, techConstants, type, aircraftManufacturerList, maintenanceProgGrp, sManufacruerList, operators } = this.props;
    const { asset, error, newLessee } =  this.state;
    const userInfo = getLocalStorageInfo()
    var showTabs = true
    // Object.keys(userInfo.user.permission).map(key => {
    //   showTabs = userInfo.user.permission[key].is_saas ? false : showTabs
    //   return key
    // })
    return(
      <form onSubmit={(e) => this.createAsset(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row >
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">MSN<sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={asset.msn}
                  type={type}
                  keyParam="msn"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.msn}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Type<sup className="reqStar"> * </sup></FieldLabel>
                <FieldSelect
                  placeholder='Select Type'
                  keyParam="aircraft_type"
                  value={asset.aircraft_type}
                  type={type}
                  options={aircraftTypes}
                  updateField={this.updateForm}
                  labelKey="name"
                  valueKey="id"
                />
              <h6 className="error-msg">{error.aircraft_type}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Registration</FieldLabel>
                <FieldInput
                  value={asset.current_registration_number}
                  type={type}
                  keyParam="current_registration_number"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.current_registration_number}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Date of Manufacture
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  <PopoverNote
                     placement= 'top'
                     tagetId={'Popover-asset-aircraft'}
                    />
                </FieldLabel>
                <FieldDate
                  keyParam="date_of_manufacture"
                  value={asset.date_of_manufacture}
                  type={type}
                  updateField={this.updateForm}
                  maxDate={moment().subtract(1, 'day')}
                />
                <h6 className="error-msg">{error.date_of_manufacture}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Maintenance Program Group
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Maintenance Program Group'
                  keyParam="maintenance_event_program_group"
                  value={asset.maintenance_event_program_group}
                  type={type}
                  options={asset.aircraft_type == '' ? [] : maintenanceProgGrp}
                  updateField={this.updateForm}
                  labelKey = "label"
                  valueKey = "value"
                />
                <h6 className="error-msg">{error.maintenance_event_program_group}</h6>

              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                 Credit Classification
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Credit Classification'
                  keyParam="credit_classification"
                  value={asset.credit_classification}
                  type={type}
                  options = {techConstants.filter(item => item.type == "credit_classification")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                />
                <h6 className="error-msg">{error.credit_classification}</h6>
              </FieldGroup>
            </FieldCol>
             }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Location</FieldLabel>
                  {
   								 window.google !== undefined && window.google !== null ?
                   <PlacesAutocomplete
                     value={asset.location}
                     onChange={this.handleChange}
                     onSelect={this.handleSelect}
                   >
                   {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                     <div style={{position: 'relative'}}>
                       <input
                         {...getInputProps({
                           placeholder: 'Search Location',
                           className: 'location-search-input',
                         })}
                       />
                       {
                         asset.location ?
                         <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                         {loading && <div>Loading...</div>}
                         {suggestions.map(suggestion => {
                           const className = suggestion.active
                             ? 'suggestion-item--active'
                             : 'suggestion-item';
                           // inline style for demonstration purpose
                           const style = suggestion.active
                             ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                             : { backgroundColor: '#ffffff', cursor: 'pointer' };
                           return (
                             <div
                               {...getSuggestionItemProps(suggestion, {
                                 className,
                                 style,
                               })}
                             >
                               <span>{suggestion.description}</span>
                             </div>
                           );
                         })}
                       </div> :  null
                       }

                     </div>
                   )}
                 </PlacesAutocomplete>
   								 :<FieldInput
                     value={asset.location}
                     type={type}
                     keyParam="location"
                     updateField={this.updateForm}
                   />
   							 }
            </FieldGroup>
          </FieldCol>
          {
            showTabs ?
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Ownership</FieldLabel>
                <div>
                  <label className="label" style={{ cursor: 'pointer', marginRight: '10px'}}>
                     <input type="radio"
                      value={asset.ownership_type}
                      onChange = {(e) => this.updateForm('ownership_type', 1)}
                      style={{ marginRight: '4px'}}
                      checked = {asset.ownership_type === 1 ? true : false}
                     />
                   <span>Owned</span>
                  </label>
                  <label className="label" style={{ cursor: 'pointer'}}>
                     <input type="radio"
                      value={asset.ownership_type}
                      onChange = {(e) => this.updateForm('ownership_type', 2)}
                      style={{ marginRight: '4px'}}
                      checked = {asset.ownership_type === 2 ? true : false}
                     />
                   <span>Managed</span>
                  </label>
                </div>
              </FieldGroup>
            </FieldCol>
            :null
          }
          {
            asset.ownership_type == 2 ?
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Portfolio { type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                  <FieldSelect
                    placeholder='Create/Select Portfolio'
                    keyParam="portfolio"
                    value={asset.portfolio ? asset.portfolio.id : ''}
                    type={type}
                    options = {this.props.portfolioList}
                    updateField={this.updateForm}
                    labelKey= 'name'
                    valueKey='id'
                    creatable={true}
                  />
                <h6 className="error-msg">{error.portfolio}</h6>
              </FieldGroup>
            </FieldCol>
            :null
          }

            <FieldCol md="12">
              <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Lease Status
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Lease Status'
                    keyParam="status"
                    value={asset.status}
                    type={type}
                    options={clients}
                    options = {getLocalStorageInfo().defaultLessor.id === 348 ? [{label: 'Operational', value: '1'}, {label: 'AOG', value: '3'}] : [{label: 'On Lease', value: '1'}, {label: 'Off Lease', value: '2'}, {label: 'On Ground', value: '3'}, {label: 'Written Off / Sold', value: '4'}]}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                  <h6 className="error-msg">{error.status}</h6>
                </FieldGroup>
              </FieldCol>
          {asset.status == 2 ?
            <FieldCol md="12">
              <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Off Lease Status
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Off Lease Status'
                    keyParam="off_lease_status"
                    value={asset.off_lease_status}
                    type={type}
                    options={clients}
                    options = {techConstants.filter(item => item.type == "off_lease_status")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                  <h6 className="error-msg">{error.status}</h6>
                </FieldGroup>
              </FieldCol>
            :null
          }
          <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '45px', paddingTop: '20px'} : {width: '100%'}}>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Lessee
                { type != 'view' && asset.status== 1 ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <Creatable
                  value={asset.lessee_id}
                  options={clients}
                  isClearable={true}
                  onChange={this.updateLesseeForm}
                  className="custom-select-block"
                  placeholder='Add or Select Lessee'
                  valueKey="id"
                  labelKey="name"
                  type={type}
                />
                 <h6 className="error-msg">{error.lessee_id}</h6>
              </FieldGroup>
            </FieldCol>
          {
            newLessee ?
            <Fragment>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Contact Address
                  </FieldLabel>
                  <FieldInput
                    value={asset.lessee_id ? asset.lessee_id.contact_address:'' }
                    type={type}
                    keyParam="contact_address"
                    updateField={this.updateNewLessee}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Country
                  </FieldLabel>
                  <FieldInput
                    value={asset.lessee_id ? asset.lessee_id.country:'' }
                    type={type}
                    keyParam="country"
                    updateField={this.updateNewLessee}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                    Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                    <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                  </FieldLabel>
                </FieldGroup>
              </FieldCol>
            </Fragment> : null
          }
        </div>
            <Col md="12">
              <div className="form-group">
               <label className="label" style={{ cursor: 'pointer'}}>
                  <input type="checkbox"
                   value={asset.same_operator_lessee}
                   onChange = {(e) => this.updateForm('same_operator_lessee', e.target.checked)}
                   style={{ marginRight: '4px'}}
                   disabled= {asset.lessee_id ? false : true}
                   checked = {asset.same_operator_lessee}
                  />
                <span>Operator is same as Lessee</span>
               </label>
              </div>
            </Col>

            {
              !asset.same_operator_lessee ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Operator</FieldLabel>
                  <FieldSelect
                    placeholder='Select Operator'
                    keyParam="operator_id"
                    value={asset.operator_id}
                    type={type}
                    options={operators}
                    updateField={this.updateForm}
                    labelKey="name"
                    valueKey="id"
                  />
                </FieldGroup>
              </FieldCol> : null
            }
            { this.state.asset.msn && this.state.asset.aircraft_type && this.state.asset.date_of_manufacture ?
              userInfo.defaultLessor.lessor_type != 3 ?
              null:
              <div style={{padding: '0 40px', width: '100%'}}>
                <div style={{width: '100%', padding: '10px 20px', background: '#f2f2f2', marginBottom: '50px'}}>
                  {
                    userInfo.defaultLessor.lessor_type != 1 ?
                    <Col md="12" style={{borderBottom: '1px solid #d3d3d3', marginBottom: '20px',paddingLeft:'0px'}}>
                      <div className="form-group" style={{marginBottom: '0px',padding:'0px'}}>
                       <label className="label" style={{ cursor: 'pointer'}}>
                        <span style={{color:'#9439e4',display:'block',padding:'5px 0px'}}>Ownership Details</span>
                       </label>
                      </div>
                    </Col>
                    :null
                  }
                  {
                  userInfo.defaultLessor.lessor_type == 3  && !this.state.disabled ?
                    <Col md="12" style={{padding:'0px'}}>
                      <div className="form-group" style={{padding:'0px'}}>
                       <label className="label" style={{ cursor: 'pointer'}}>
                          <input type="checkbox"
                           value={asset.own_asset}
                           onChange = {(e) => this.updateForm('own_asset', e.target.checked)}
                           style={{ marginRight: '4px'}}
                           checked = {asset.own_asset}
                           disabled={this.state.disabled}
                          />
                        <span>I would like to continue without notifying/inviting the lessor</span>
                       </label>
                      </div>
                    </Col>
                    :null
                  }
                  {!asset.own_asset && !this.state.disabled ? <p className="text-center"><span>Or Select the lessor by entering details below</span></p>:null}

                  { !asset.own_asset ?
                    <div>
                      {!asset.own_asset && userInfo.defaultLessor.lessor_type == 3 ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor<sup className="reqStar"> * </sup></FieldLabel>
                          {
                            this.state.disabled ?
                            <FieldInput
                              value={asset.lessor_name}
                              type={type}
                              keyParam="lessor_name"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            />
                            :<FieldSelect
                              placeholder='Select Lessor'
                              keyParam="exist_lessor_slug"
                              value={asset.exist_lessor_slug}
                              type={type}
                              options={lessorList.filter(item => item.lessor_type.value != 3)}
                              updateField={this.updateForm}
                              labelKey="name"
                              valueKey="slug"
                              styleName="custom-select-block custom-select-block-white"
                            />
                          }
                          <h6 className="error-msg">{error.exist_lessor_slug}</h6>
                          </FieldGroup>
                        </FieldCol> : null
                      }
                      { !asset.own_asset && userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled && asset.exist_lessor_slug && asset.exist_lessor_slug == 'newlessor'?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor Name<sup className="reqStar"> * </sup></FieldLabel>
                            {/* <FieldInput
                              value={asset.lessor.name}
                              type={type}
                              keyParam="lessor.name"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            /> */}
                              <FieldSelect
                                placeholder='Select Lessor'
                                keyParam="name"
                                value={asset.lessor.name}
                                type={type}
                                // options = {techConstants.filter(item => item.type == "name")}
                                updateField={this.updateForm}
                                labelKey='label'
                                valueKey='value'
                                disable={this.state.disabled}
                              />
                          <h6 className="error-msg">{error.lessor.name}</h6>
                          </FieldGroup>
                        </FieldCol>
                        :null
                      }
                        <FieldCol md="4">
                          <FieldGroup className="form-group">
                            <FieldLabel className="label">
                              Owner {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                            </FieldLabel>
                            <FieldSelect
                              placeholder='Select Owner'
                              keyParam="name"
                              value={asset.owner}
                              type={type}
                              // options = {techConstants.filter(item => item.type == "name")}
                              updateField={this.updateForm}
                              labelKey='label'
                              valueKey='value'
                            />
                            <h6 className="error-msg">{error.owner}</h6>
                          </FieldGroup>
                        </FieldCol>
                      { !asset.own_asset && userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled && asset.exist_lessor_slug && asset.exist_lessor_slug == 'newlessor'?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor Email<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={asset.lessor_domain}
                              type={type}
                              keyParam="lessor_domain"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            />
                          <h6 className="error-msg">{error.lessor_domain}</h6>
                          </FieldGroup>
                        </FieldCol>
                        :null
                      }
                    </div>:null
                  }

                </div>
              </div>
              :null
            }

          </Row>
        </div>
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
        <Modal isOpen={this.state.assetTransferModal} toggle={() => this.setState({assetTransferModal: false})} className="transfer-modal">
          <ModalHeader toggle={() => this.setState({assetTransferModal:  false})}>
            Asset already exists
          </ModalHeader>
          <ModalBody>
            <Row className="transfer-modal-body">
              <Row className="apps-container">
                <Col md="12">
                  <p>
                    Asset you are trying to add is owned by an another account. Please take necessary actions below to proceed further with 'Add Asset'.
                  </p>
                </Col>
              </Row>
            </Row>
            <Row className="transfer-modal-footer">
							<Col md="12">
                {
                  getLocalStorageInfo().defaultLessor.lessor_type != 3 ?
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a className="aims-primary-button" onClick={() => {this.setState({assetTransferModal: false, disabled: false, });this.props.createAsset({...this.state.asset, notify: true});}}>Request Transfer</a>
                    </li>
                      <li className="list-inline-item">
                        <Link style={{border: '1px solid', borderRadius: '3px', padding: '5px 20px', marginLeft: '5px'}} className="aims-primary-link" className="aims-primary-link" onClick={() => {this.setState({assetTransferModal: false, disabled: false, }); this.addNewAndSave();}}>Ignore & Setup New</Link>
                      </li>
                    <li className="list-inline-item">
                      <Link className="aims-primary-link" onClick={() => this.setState({assetTransferModal: false})}>Cancel</Link>
                    </li>
                  </ul>
                  :null
                }
                {
                  getLocalStorageInfo().defaultLessor.lessor_type == 3 ?
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button type="button" className="aims-primary-button" onClick={() => {this.setState({assetTransferModal: false, disabled: false, }); this.requesteAccess();}}>Request Access</button>
                    </li>
                    <li className="list-inline-item">
                      <Link className="aims-primary-link" onClick={() => this.setState({assetTransferModal: false})}>Cancel</Link>
                    </li>
                  </ul>
                  :null
                }
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </form>
    )
  }
}
