import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { resetPwdAc } from '../actionCreators';
import { UncontrolledTooltip } from 'reactstrap';
import { imgStoragePath } from '../../constants';
import '../assets/styles/login.scss';
import { FORM_SUBMIT_LOADER } from '../actions';
import { checkString } from '../../formValidator';
class ResetPassword extends Component{
  constructor(props){
    super(props);
    this.state = {
      userInfo:{
        password: '',
        confirm_password: '',
        showPassword:false,
        showCnfPassword:false
      },
      errors : {}
    }
  }
  componentDidMount(){
    this.props.formSubmitLoader(false);
  }

  handleUserChange = (key, value) =>{
    value = value.trim();
    this.setState(prevState => ({
        ...prevState,
        userInfo: {
            ...prevState.userInfo,
            [key]: value
        },
        errors: {
            ...prevState.error,
            [key]: ''
        }
    }));
  }

  resetPassword = (e) => {
    e.preventDefault();
    let validate = {};
    validate = {
      password: checkString({value: e.target.password.value, required: true, minLength: '', maxLength: '', message: 'Please enter Password'}),
      confirm_password: checkString({value: e.target.confirm_password.value, required: true, minLength: '', maxLength: '', message: 'Please enter Confirm Password'}),
    };
    if(!validate.password){
        let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        if(!passwordRegex.test(e.target.password.value)){
            validate = {
                ...validate,
                password: 'Password does not meet the policy requirements'
            }
        }
    }
    if(!validate.password && !validate.confirm_password){
        if(e.target.password.value !== e.target.confirm_password.value){
            validate = {
                ...validate,
                confirm_password: 'Passwords do not match. Try Again'
            }
        }
    }

    if (Object.keys(validate).every((k) => { return validate[k] === '' })) {
      this.props.resetPassword({
        password: e.target.password.value.trim(),
        confirm_password : e.target.confirm_password.value.trim(),
        uSlug: this.props.params.token.split('_')[0],
        token: this.props.params.token.split('_')[1]
      }, this.props.route.path.includes('/password/reset/') ? 'reset':'created' );
    }else{
      this.setState({
        errors: validate
      });
    }
  }
  render(){
    const { loginLoader } = this.props;
    const path = this.props.route.path
    return(
      <div className="reset-password-block">
        <div className="reset-block">
          <h6 className="caption-ui back-icon"><Link to="/">← Back to Login</Link></h6>
          <h2>Password Reset</h2>
          <h6 className="password-subheading">
            {path.includes('/password/reset') ?
            <span>
              Please set your new password. Ensure the new password is <br />following our Password Policy.
            </span>:
            <span>
              Set the Password to access your account
            </span>
            }

          </h6>
          <form onSubmit={this.resetPassword}>
            <div className="form-group password-grid">
              <label>
                New Password
                <span id="passwordToolTip"><img src={imgStoragePath+'phase-2/help.svg'} style={{ position: 'relative', bottom: '2px', left: '2px' }} /></span>
                <UncontrolledTooltip className="text-left" placement="right" target="passwordToolTip">
                  <div style={{ textAlign: "left" }}>
                      Password rules  <br/>
                      <ul className="list-unstyled password-tooltip">
                          <li>Password must contain minimum of 8 characters</li>
                          <li>Password must contain at least 1 digit</li>
                          <li>Password must contain at least 1 uppercase character</li>
                          <li>Password must contain at least 1 special character</li>
                      </ul>
                  </div>
                </UncontrolledTooltip>
              </label>
              <input className=""
                 type={this.state.showPassword ? "text" : "password" }
                 name="password" value={this.state.userInfo.passsword}
                 onChange={(e) => {this.handleUserChange('password', e.target.value)}}
              />
              {!this.state.showPassword ?<i  onClick={(e) => {this.setState({showPassword: !this.state.showPassword})}} className="fa fa-eye-slash" aria-hidden="true"></i> : <i  onClick={(e) => {this.setState({showPassword: !this.state.showPassword})}} className="fa fa-eye" aria-hidden="true"></i>}
              <h6 className="error-msg">{this.state.errors.password}</h6>
            </div>
            <div className="form-group password-grid">
              <label>Confirm New Password </label>
              <input className=""
                type={this.state.showCnfPassword ? "text" : "password" }
                name="confirm_password" value={this.state.userInfo.confirm_password}
                 onChange={(e) => {this.handleUserChange('confirm_password', e.target.value)}}
              />
              {!this.state.showCnfPassword ? <i onClick={(e) => {this.setState({showCnfPassword: !this.state.showCnfPassword})}} className="fa fa-eye-slash" aria-hidden="true"></i> : <i onClick={(e) => {this.setState({showCnfPassword: !this.state.showCnfPassword})}} className="fa fa-eye" aria-hidden="true"></i>}
              <h6 className="error-msg">{this.state.errors.confirm_password}</h6>
            </div>
            <div className="input-group">
              <input type="submit" disabled={loginLoader}  className="primary-btn" value={loginLoader ? 'Processing...': path.includes('/password/reset/') ? 'Reset Password': 'Set Password'  } />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loginLoader: state.userAssetReducer.loginLoader
});
const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (data, type) => dispatch(resetPwdAc(data, type)),
    formSubmitLoader: (flag) => dispatch({
      type:FORM_SUBMIT_LOADER,
      payload: flag
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
