import * as actions from '../actions';
import { browserHistory } from 'react-router';
import { globalGetService, globalPostService, globalExportService, globalPutService, globalDownloadService, globalFileUploadService, globalDownloadGetService, globalUnlimitedDownloadService } from '../../../../globalServices/';
import { downloadFileType, toastFlashMessage, getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
import { assetsType } from '../../../../constants'
import { RESOURCE_LOADER } from '../../Resources/actions'
import { getMiniPackListAc } from '../../Resources/actionCreators'
import { loadAssetViewAc } from '../../Dashboard/actionCreators'

export const getMiniPackFoldersAc = (params) => {
  return (dispatch) =>{
    globalGetService(`/records/${params.type}/${params.aircraft_slug}/get-minipack-dropdown/`)
    .then(response => {
      if(response.data.statusCode === 200){
        dispatch({
          type: actions.MINPACK_FOLDERS,
          payload: response.data.data
        })
      }
    })
  }
}

export const getAiSupportedFoldersAc = (props) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalGetService(`records/${props.type == 'aircraft' ? 1 : 2}/get-ai-folders/`)
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      dispatch({
        type: actions.AI_SUPPORTED_FOLDERS,
        payload: response.data.data
      });
      dispatch({
        type: actions.AI_SUPPORTED_FOLDERS_FLAG,
        payload: true
      });
    })
  }
}

export const dirTreeExpandAc = (params, filters) => {
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_EXPANSION_LOADER,
      payload: true
    });
    globalGetService('records/'+params.type+'/'+ params.aircraft_slug+'/data-room/'+params.id+'/',filters)
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_EXPANSION_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ASSET_DIR_TREE_EXPAND,
          payload: response.data.data
        });
      }
    });
  }
}

export const dirTreeAc = (props, filters) => {
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    dispatch({
      type: actions.ASSET_DIR_TREE,
      payload: {},
    });
    globalGetService('records/'+props.params.type+'/'+ props.params.aircraft_slug+'/data-room/'+props.params.id+'/',{...filters, ...props.location.query})
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 4001 && response.data.message == 'Folder does not exist.'){
        browserHistory.push('/'+props.params.type+'/'+props.params.aircraft_slug+'/records/dataRoom/')
      }
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ASSET_DIR_TREE,
          payload: response.data.data
        });
      }
    });
  }
}
export const shareRecordsAc = (params,data,status, hours, minipack, root) => {
  const shareData ={
    type: data.type,
    object_uuid: data.uuid,
    share: !status,
    hour: hours
  };
  return (dispatch) => {
    if(minipack){
      dispatch({
        type: RESOURCE_LOADER,
        payload: true
      })
    }else{
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: true
      });
    }
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/share/', shareData)
    .then(response => {
      if(minipack){
        dispatch({
          type: RESOURCE_LOADER,
          payload: false
        })
      }else{
        dispatch({
          type: actions.DIRECTORY_LOADER,
          payload: false
        });
      }
      if(response.data.statusCode == 200){
        if(minipack){
          dispatch({
            type: actions.SHARE_DATA,
            payload: {shareKey: response.data.data.share_key, shareValidity: response.data.data.valid_till},
            status: !status,
            itemId: data.uuid,
            minipack
          })
          if(root){
            dispatch(loadAssetViewAc(params, 'Resources'))
          }else{
            dispatch(getMiniPackListAc(params))
          }
        }else{
          dispatch({
            type: actions.SHARE_DATA,
            payload: {shareKey: response.data.data.share_key, shareValidity: response.data.data.valid_till},
            status: !status,
            itemId: data.uuid
          })
        }
      }
    })
  }
}

export const deleteBulkRecordAc = (params, data) => {
  let folder=[];
  let file=[];

  data.map(item => {
    if(item.type == 'file'){
      file.push(item.uuid);
  }else{
      folder.push(item.uuid)
  }
 })

   let deleteData={
    files_uuid:file,
    folders_uuid:folder
  }
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalPutService('/records/'+params.type+'/'+params.aircraft_slug+'/data-room/delete-multiple/', deleteData)
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      trackActivity('File Deleted', {
        application_module: 'Records',
        page_title: 'DataRoom',
        item_type: 'multiple'
      })
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        window.location.reload();
      }

    })
  }
}

export const getUsersAc = (folderId) => {
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalGetService('/records/user-folder-share/'+folderId + "/", {add_users: true})
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.USER_SHARE_EMAIL_LIST,
          payload: response.data.data
        })
       dispatch(getUsersSelectedAc(folderId));
      }
    })
  }
}

export const shareUserEmailsAc = (ids,folderId,emails,flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalPostService('/records/user-folder-share/'+folderId+'/', {user_id_list: ids,new_user_email:emails,can_edit:flag})
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        if(ids.length || emails.length){
          dispatch({
            type: actions.SHARE_DATA_USER,
            status: true,
            itemId: folderId
          })
        }else{
          dispatch({
            type: actions.SHARE_DATA_USER,
            status: false,
            itemId: folderId
          })
        }
      }
    })
  }
}



export const getUsersSelectedAc = (folderId) => {
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalGetService('/records/user-folder-share/'+folderId+'/',)
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        let emails=[]
        response.data.data.map(item => {
          emails.push(item.email);
        })
        dispatch({
          type: actions.USER_SELECTED_EMAIL_LIST,
          payload: emails
        })
        dispatch({
          type: actions.UPDATE_SELECTED_PERMISSION,
          payload:response.data.can_edit==null?true:response.data.can_edit
        })
        dispatch({
          type: actions.USER_SELECTED_EMAIL_OBJECT,
          payload:response.data.data
        })
      }
    })
  }
}



export const deleteRecordAc = (params, item) => {
  const data={
    type: item.type,
    object_uuid:[item.uuid],
    new_status: "7"
  };
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalPutService('/records/'+params.type+'/'+params.aircraft_slug+'/data-room/delete/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.DELETE_RECORD,
          payload: response.data.data,
          itemId: item.uuid,
        })
        trackActivity('File Deleted', {
          item_id: item.uuid,
          application_module: 'Records',
          page_title: 'DataRoom',
          item_type: item.type
        })
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
    })
  }
}

export const moveRecordToDestAc = (params, source, destination) => {
  let moveObj={
    type: source.type,
    move_to: destination,
    object_uuid:[source.uuid]
  };
  return (dispatch) => {
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/move/', moveObj)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MOVE_RECORD,
          payload: source.uuid
        })
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}

export const moveRecordBulkAc = (params, source, destination) => {
  let folderName=[];
  let fileName=[];
  let mainFile=[];
  let folder=[];
  let file=[];

  source.map(item => {
    if(item.type == 'file'){
      file.push(item.uuid);
      folderName.push(item.name);
  }else{
      folder.push(item.uuid)
      fileName.push(item.name);
  }
 })

  return (dispatch) => {
      if(file.length){
      let moveObj={
        type:'file',
        move_to: destination,
        object_uuid:file
      };
      mainFile.push(moveObj)
    }
    if(folder.length){
      let moveObj={
        type:'folder',
        move_to: destination,
        object_uuid:folder
      };
      mainFile.push(moveObj)
    }
      globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/move-multiple/', mainFile)
      .then(response => {
        dispatch({
          type: actions.DIRECTORY_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          window.location.reload();
        }
      })
  }
}

export const moveRecordToDestBulkAc = (params, source, destination) => {
  let folderName=[];
  let fileName=[];
  let mainFile=[];
  let folder=[];
  let file=[];

  source.map(item => {
    if(item.type == 'file'){
      file.push(item.uuid);
      fileName.push(item.name);
  }else{
      folder.push(item.uuid)
      folderName.push(item.name);
  }
 })

   let moveObj={
    destination_uuid:destination,
    files:fileName,
    folders:folderName
  }
  return (dispatch) => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    if(file.length){
    let moveObj={
      type:'file',
      move_to: destination,
      object_uuid:file
    };
    mainFile.push(moveObj)
  }
  if(folder.length){
    let moveObj={
      type:'folder',
      move_to: destination,
      object_uuid:folder
    };
    mainFile.push(moveObj)
  }
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/move-multiple/', mainFile)
    .then(response => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        window.location.reload();
      }
    })
  }
}

export const getFolderMoveRecordAc = (params, item, level) => {
  return (dispatch) => {
    globalGetService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/get-folders-for-move/'+item.type+'/'+item.uuid+'/', level)
    .then(response => {
      dispatch({
        type: actions.POPOVER_LOADER,
        payload: true,
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FOLDER_TO_MOVE,
          payload: response.data.data
        })
        dispatch({
          type: actions.POPOVER_LOADER,
          payload: false,
        })
      }
    })
  }
}

export const downloadFilesAc = (params, item, msn) => {
  const data={
    type: item.type,
    object_uuid:[item.uuid]
  };
  let type =  params.type == 'aircraft' ? 'MSN' : params.type == 'engine' ? 'ESN' : params.type.toUpperCase();
  return (dispatch) => {
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });
    globalUnlimitedDownloadService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/download/', data)
    .then(response => {
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
      if(response.status != 206){
        if(response.data.statusCode){
          toastFlashMessage("Can not download file", "error");
        }else{
          const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
          const link = document.createElement('a');
          link.href = url;
          if(item.type == "file"){
            link.setAttribute('download',item.name);
          }else {
            link.setAttribute('download',type +'_'+ msn + '.zip');
          }
          document.body.appendChild(link);
          link.click();
        }
      }else{
        // trigger the modal
        dispatch({
          type: actions.BULK_DOWNLOAD_POPUP,
          payload: true
        })
      }
    })
  }
}

export const uploadFilesToDestAc = (params, files, destination, improperFiles, type, uploadStack, proper_files, assetInfo, navigation) => {
  if(getLocalStorageInfo().user.permission['records']['data_room'].indexOf('UP') == -1 ){
    toastFlashMessage("You don't have upload rights", "error");
    return;
  }
  let navigationPath = ''
  navigation.map(nav => {
    navigationPath += nav.name + '/'
  })
  let asset_serial_number = parseInt(assetInfo.asset_type) === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(parseInt(assetInfo.asset_type)) !== -1 ? `${assetsType[parseInt(assetInfo.asset_type)].toUpperCase()} ${assetInfo.serial_number}` : `${parseInt(assetInfo.asset_type) === 2 ? 'ESN ' : ''}${assetInfo.esn}`
  trackActivity('Files Uploaded', {
    application_module: 'Records',
    asset: asset_serial_number,
    source : 'Web',
    upload_path: navigationPath,
    files_count: files.length,
    page_title: 'DataRoom'
  })
  let uploadFiles = new FormData();
  uploadFiles.append('folder_uuid', destination);
  let index =1;
  let tempFiles = [];
  if(type == 'all'){
    for(let file of files)
    {

      if(file.type != ""){
        if(uploadStack.indexOf(file.name) != -1 || proper_files.indexOf(file.name)){
          uploadFiles.append('file_'+index, file, file.name)
        }
      }else{
        toastFlashMessage("You cannot upload folder", "error");
      }
      index++;
    }
    tempFiles = files;
  }else {
    for(let file of files)
    {
      if(file.type != "" || file.name != ''){
        if(!improperFiles.filter((item) => item == file.name).length){
          uploadFiles.append('file_'+index, file, file.name)
          tempFiles.push(file);
          index++;
        }
      }else{
        toastFlashMessage("You cannot upload folder", "error");
      }
    }
  }
  return (dispatch) => {
    dispatch({
      type: actions.FILES_UPLOAD,
      payload: tempFiles,
    });
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });
    var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          if(percentage <= 95){
            dispatch({
              type: actions.PROGRESS_PERCENTAGE,
              payload: percentage
            });
          }else{
            dispatch({
              type: actions.PROGRESS_PERCENTAGE,
              payload: parseInt(Math.random() * 9) + 90
            });
          }
        }
    };
    dispatch({
      type: actions.PROGRESS_PERCENTAGE,
      payload: 0
    });

    globalFileUploadService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/upload/', uploadFiles, config)
    .then(response => {
      dispatch({
        type: actions.GET_VALIDATED_FILES,
        payload: []
      });
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.PROGRESS_PERCENTAGE,
          payload: 100
        });
        setTimeout(() => dispatch({
          type: actions.PROGRESS_PERCENTAGE,
          payload: 0
        }), 2000)
        // dispatch({
        //   type: actions.UPLOAD_FILE_DATA_ROOM,
        //   payload: response.data.data
        // })
        dispatch(dirTreeAc({params: params, location: {}}))
        dispatch(usageDetailAc())
      }
    })
    .catch(error => {
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
    })
  }
}
export const getValidatedFilesAc = (params, files, destination, assetInfo, navigation) => {
  return (dispatch) => {
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });
    if(getLocalStorageInfo().user.permission['records']['data_room'].indexOf('UP') == -1 ){
      toastFlashMessage("You don't have upload rights", "error");
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
      return null;
    }
    let index = 1;
    let wrongFileCount = 0
    let validatedFiles = [];
    Array.from(files).forEach(file => {
      if(file.type != "" || file.name != ''){
          validatedFiles.push(file.name);
      }else{
        wrongFileCount++
      }
      index++;
    });

    if(wrongFileCount){
      toastFlashMessage("You cannot upload folder", "error");
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
    }else{
      if(validatedFiles.length){
        dispatch({
          type: actions.FILES_UPLOAD,
          payload: [],
        });
        dispatch({
          type: actions.FILES_UPLOAD,
          payload: files,
        });
        globalPostService('records/file-name-checker/'+destination+'/',{files: validatedFiles})
        .then((response) => {
          if(response.data.statusCode!=3003){
          if(response.data.data.improper_files.length || response.data.data.overwrite_files.length || response.data.data.unsupported_files.length){
            dispatch({
              type: actions.GET_VALIDATED_FILES,
              payload: response.data.data
            });
          }else {
            dispatch(uploadFilesToDestAc(params, files, destination, response.data.data.improper_files,'all',[],[], assetInfo, navigation));
          }

        }else{
          dispatch({
            type: actions.FILES_UPLOAD,
            payload: [],
          });
        }
        dispatch({
          type: actions.DOWNLOAD_LOADER,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.DOWNLOAD_LOADER,
          payload: false
        });
      })
      }
    }
  }
}

export const captureActivityLogAc = (uuid) => {
  return (dispatch) => {
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });
    globalPostService('/records/activity_log_capture/', {category: "", uuid: uuid})
    .then(response => {
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      })
    })
  }
}

export const addFileToCategoryAc = (params, category, uuid) =>{
  let uuids = [];
  uuids.push(uuid);
  return(dispatch => {
    globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/change-file-category/',{category: category, flag: true, files: uuids})
    .then((response) => {
      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
      }
    })
  })
}


export const addFileToMiniPackBulkAc = (params, category, source, uuid) =>{
  let folder=[];
  let file=[];
  source.map(item => {
    if(item.type == 'file'){
      file.push(item.uuid);
  }else{
      folder.push(item.uuid)
  }
 })
  return(dispatch => {
    let url = `/records/${params.type}/${params.aircraft_slug}/change-multiple-file-category/`
    let paramObj = {}
    if(file.length === 1 && folder.length === 0){
      url = `/records/${params.type}/${params.aircraft_slug}/change-file-category/`
      paramObj = {category: category, flag: true,  files: file, to_folder: uuid}
    }else if((folder.length > 0 && file.length === 0) || (folder.length > 0 && file.length > 0) || (folder.length === 0 && file.length > 0)){
      paramObj = {category: category, flag: true,  files_uuid: file, folders_uuid: folder, to_folder: uuid}
    }
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalPostService(url,paramObj)
    .then((response) => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
      }
    })
  })
}
export const addFileToCategoryBulkAc = (params, category, source) =>{
  let folder=[];
  let file=[];
  source.map(item => {
    if(item.type == 'file'){
      file.push(item.uuid);
  }else{
      folder.push(item.uuid)
  }
 })
  return(dispatch => {
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/change-multiple-file-category/',{category: category, flag: true,  files_uuid: file,folders_uuid:folder})
    .then((response) => {
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
      }
    })
  })
}


export const changeActionDockStatusAc = (params, fileObject) => {
  return (dispatch) => {
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/change-files-status/', fileObject)
    .then(response => {
      if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Item Status Changed',{
            item_type: 'File',
            page_title: 'DataRoom',
            application_module: 'Records',
            file_id: fileObject.objects[0],
            status: {
              1: 'OCR In Queue',
              2: 'Processing',
              3: 'Completed',
              4: 'Issue',
              5: 'OCR Not Applicable'
            }[fileObject.new_status]
          })
      }
    })
  }
}

export const changeActionDockBulkStatusAc = (params, fileObject) => {
  return (dispatch) => {
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/change-files-status/', fileObject)
    .then(response => {
      if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Item Status Changed',{
            item_type: 'File',
            page_title: 'DataRoom',
            application_module: 'Records',
            file_id: 'multiple',
            status: {
              1: 'OCR In Queue',
              2: 'Processing',
              3: 'Completed',
              4: 'Issue',
              5: 'OCR Not Applicable'
            }[fileObject.new_status]
          })
          window.location.reload();
      }
    })
  }
}

export const createNewFolderAc = (params, folderName, sort) =>{
  return (dispatch)=>{
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    globalPostService('records/' + params.params.type + '/' + params.params.aircraft_slug + '/folder/' + params.params.id + '/create-folder/',{name: folderName})
    .then(response =>{
      if(response.data.statusCode == 200){
          // dispatch({
          //   type: actions.CREATE_FOLDER,
          //   payload: response.data.data
          // })
          dispatch(dirTreeAc(params, {page: 1, sort: 'name', sort_by: sort}))
          toastFlashMessage(response.data.message, 'success');
      }
      dispatch({
        type: actions.DIRECTORY_LOADER,
        payload: false
      });
    })
  }
}

export const getRecordDetailsAc = (params, record) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.RECORD_DETAIL,
      payload: {}
    })
    globalPostService(`/records/${params.type}/${params.aircraft_slug}/get-properties/`,record)
    .then(response =>{
      if(response.data.statusCode == 200 ){
        dispatch({
          type: actions.RECORD_DETAIL,
          payload: response.data.data
        })
      }
    })
  }
}


export const moveBulkOperationAC = (params, data,uuid) =>{
  return (dispatch) =>{

     let moveData={
      destination_uuid:"",
      files:[],
      folders:[]
    }

    globalPostService(`records/${params.type}/${params.aircraft_slug}/data-room/move-multiple-check/`,data)
    .then(response =>{
      if(response.data.statusCode == 200 ){

      }
    })
  }
}
export const downloadBulkOperationAC = (params, data,msn) =>{

    let folder=[];
    let file=[];

    data.map(item => {
      if(item.type == 'file'){
        file.push(item.uuid);
    }else{
        folder.push(item.uuid)
    }
   })

     let moveData={
      files_uuid:file,
      folders_uuid:folder
    }
  let type =  params.type == 'aircraft' ? 'MSN' : 'ESN';
  return (dispatch) => {
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });

    globalUnlimitedDownloadService(`records/${params.type}/${params.aircraft_slug}/data-room/download-multiple/`,moveData)
    .then(response =>{
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
      if(response.status != 206){
        if(response.data.statusCode){
          toastFlashMessage("Can not download file", "error");
        }else{
          const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download',type +'_'+msn+'.zip');

          document.body.appendChild(link);
          link.click();
        }
      }else{
        // toastFlashMessage("Use Drive Tool to download bulk data", 'error')
              dispatch({
                type: actions.BULK_DOWNLOAD_POPUP,
                payload: true
              })

      }
    })
  }
}

export const renameRecordAc = (params, item, sortType) =>{
  return(dispatch)=>{
    dispatch({
      type: actions.DIRECTORY_LOADER,
      payload: true
    });
    if(item.type == 'file'){
      let fileObject = {
        new_name: item.name,
        object_uuid: item.uuid,
        type: item.type
      }
      globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/rename/', fileObject)
      .then(response =>{
        if(response.data.statusCode == 200){
          trackActivity("Item Renamed", {
            item_type: item.type,
            new_name: item.name,
            item_id: item.uuid,
            application_module: 'Records',
            page_title: 'DataRoom'
          })
          dispatch(dirTreeAc({params: params, location: {}, sort: 'name', sort_by: sortType}));
        }
        dispatch({
          type: actions.DIRECTORY_LOADER,
          payload: false
        });
      })
    }else{
      let fileObject = {
        new_name: item.name,
        uuid: item.uuid,
        type: item.type
      }
      globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/update-folder-name/', fileObject)
      .then(response =>{
        if(response.data.statusCode == 200){
          dispatch(dirTreeAc({params: params, location: {}, sort: 'name', sort_by: 'asc'}));
        }
        dispatch({
          type: actions.DIRECTORY_LOADER,
          payload: false
        });
      })
    }
  }
}

export const usageDetailAc = () =>{
  return(dispatch) =>{
    globalGetService('/console/license/storage/usage/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.STORAGE_USAGE,
          payload: response.data.data
        })
      }
    })
  }
}
