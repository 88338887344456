import React from 'react';
import { Link } from 'react-router';
import { Container, Row, Col} from 'reactstrap';
import { imgStoragePath } from '../../constants';

const Features = (props) => {
    return(
        <div className="manage-features-wrapper">
            <Container>
                <Row>
                    {
                        props.features.map((val, key) => {
                            return(
                                <Col key={key} lg="4">
                                    <div className="card-wrapper">
                                        <div className="card-title">
                                            <img src={imgStoragePath+val.img} />
                                            <h4>{val.title}</h4>
                                        </div>
                                        <div className="card-features">
                                        <ul className="list-unstyled">
                                            {
                                                val.description.map((feature, key2) => {
                                                    return(
                                                        <li key={key2}>{feature}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Link to="/signup" target="_blank" className="prim-btn text-center">GET STARTED NOW WITH A FREE ACCOUNT</Link>
            </Container>
        </div>
    )
}

export default Features;
