import React, { Component } from 'react';
import { toolsBaseUrl } from '../../../constants'

class ImageAnnotator extends Component{
  constructor(props){
    super(props)
    this.state ={
      editedImage: ''
    }
  }

  render(){
    return(
      <div className="image-annotator" style={this.props.style}>
        {
          this.props.markup ?
            <iframe width={this.props.width}
            height={this.props.height}
            id="annotator"
            src={`${toolsBaseUrl}/tools/annotate/index.html?file=${this.props.image}&color=${this.props.color}&font_color=${this.props.color}&width=${this.props.width-100}&height=${this.props.height-100}&font_size=${this.props.fontSize}&markup=${this.props.markup}`}
            />
          :<iframe
          width={this.props.width}
          height={this.props.height}
          id="annotator"
          src={`${toolsBaseUrl}/tools/annotate/index.html?file=${this.props.image}&color=${this.props.color}&font_color=${this.props.color}&width=${this.props.width-100}&height=${this.props.height-100}&font_size=${this.props.fontSize}`}
          />
        }
      </div>
    )
  }
}
export default ImageAnnotator;
