import * as actions from '../actions';
const crudObj = {
  type: '',
  flag: false,
  data: {}
};
export default function( state = {
  contactList: [],
  pagination: {},
  contactCrud: crudObj,
  selectedIds: [],
  contactLoader: false
}, action ){
  switch (action.type) {
    case actions.CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload.list,
        pagination: action.payload.pagination,
        selectedIds:[]
      }
    case actions.TOGGLE_SIDE_BAR:
      return {
        ...state,
        contactCrud: action.payload

      }
    case actions.ADD_CONTACT:
      return{
        ...state,
        contactList: [action.payload, ...state.contactList],
        pagination: {
          ...state.pagination,
          page: state.pagination.page ? state.pagination.page: 1,
          total: 1 + (state.pagination.total ? state.pagination.total:0),
          per_page: state.pagination.per_page ? state.pagination.per_page:10
        },
        contactCrud: crudObj
      }
    case actions.UPDATE_CONTACT :
      return{
        ...state,
        contactList: state.contactList.map((item, index) => item.id == action.payload.id ? action.payload : item),
        contactCrud: crudObj
      }
    case actions.DELETE_CONTACT:
      return{
      ...state,
        contactList: state.contactList.filter((item, index) => !(action.payload.includes(item.id))),

      }
    case actions.UPDATE_SELECTED_IDS:
      if(action.payload.type == 'all'){
        if(action.payload.flag){
          return {
            ...state,
            selectedIds: state.contactList.map(contact => { return contact.id})
          }
        }else{
          return {
            ...state,
            selectedIds: []
          }
        }
      }else{
        if(state.selectedIds.includes(action.payload.id)){
          return {
            ...state,
            selectedIds: state.selectedIds.filter(id => id != action.payload.id)
          }
        }else{
          return {
            ...state,
            selectedIds: [...state.selectedIds, action.payload.id],
          }
        }
      }
    case actions.UPDATE_CONTACT_LOADER:
      return {
        ...state,
        contactLoader: action.payload
      }
    default:
      return state;

  }
}
