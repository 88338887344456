
import React from 'react';
import { Modal, ModalBody,ModalHeader, Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../constants';
class RatingInfoModal extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    
    return (
      <div className="">
        <Modal isOpen={this.props.showRating} toggle={this.toggle} className="modal-md profile-skills-list modal-dialog-centered">
        <ModalHeader toggle={() => this.props.closeRatingModal()}>
                    Rating
            </ModalHeader>
            <ModalBody style={{ height: (window.innerHeight - 257) + 'px', overflow:'auto', position: 'relative',paddingTop:'0px' }}>
                  <div className="users-contract-list-con">
                    <Row className="users-contract-list-con-header">
                      <Col md="3">SL No.</Col>
                      <Col md="6">Project Name</Col>
                      <Col md="3">Rating</Col>
                    </Row>
                    {this.props.ratings.map((rating, index) =>
                        <Row className="users-contract-list">
                          <Col md="3">{index+1}</Col>
                          <Col md="6">{rating.project ? rating.project: null}</Col>
                          <Col md="3">{rating.rating ? parseFloat(rating.rating.toFixed(2)) : '0'}</Col>
                        </Row>
                      )
                  }
                  </div>
                </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default RatingInfoModal;
