import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';
import moment from 'moment';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';


export default class ShopvisitFileList extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { shopVisit, error, index, detailErr } = this.props;
  let type = 'edit';
  return(
    <tr className="shopvisit-acctacment-row">
			<td>{shopVisit.name ?shopVisit.name.length >40 ?shopVisit.name.substring(0,37) + '...' : shopVisit.name :'--' }</td>
      <td>
      {
       GetInputField(
          'string',
          null,
          { type: 'edit',
            value: shopVisit.description,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'description', value: value, sectionType: 'shop_visit', index: index})
          }
        )
        }
      </td>
      <td>
      { shopVisit.created_at ? moment(shopVisit.created_at).format(displayDateFormat) : null}
      </td>
			<td>
         <div>
          <img className="profile-pic" src={shopVisit.user.profile_pic} alt="img"/>
          <span>{shopVisit.user.name}</span>
				</div>
      </td>
			<td className="download-block">
        <Link  target="_blank" to = {shopVisit.attachment}>
          <img width="12" className="" src={imgStoragePath+"import_images_icons.png"} onClick={this.props.toggleComDelModal}/>
        </Link>
		  	<img width="12" onClick = {() => this.props.toggleDelModal(shopVisit.id, 'attachment', index)} className="" src={imgStoragePath+"delete_red.png"}/>
      </td>
      </tr>
    )
  }
}
