import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService,globalExportService,globalFileUploadService } from '../../../../globalServices/';
import { toastFlashMessage,downloadFileType,getLocalStorageInfo } from '../../../../utils';
import { createProject } from '../../Forms/AddFormObjects';
import { browserHistory } from 'react-router';
import { getMsnSwitcherAc } from '../../../../shared/actionCreators';
import moment from 'moment';
import { trackActivity } from '../../../../utils/mixpanel';
const userInfo = getLocalStorageInfo();
export const fetchProjectListAc = (params, queryParams, initalCall=false) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if(initalCall){
        globalGetService('/technical/projects/', {})
      .then(response => {
        dispatch(triggerLoader(false));
        trackActivity('Page Visited', {
          application_module: 'Inspection',
          page_title: `Project List`
        })
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.PROJECTS_LIST,
            payload: response.data.data
          });
        }
      })
    }else{
      globalGetService('/technical/projects/',queryParams)
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.PROJECTS_LIST,
            payload: response.data.data
          });
        }
      })
    }
  }
}


export const fetchEngineDetailsAc = (param, data, type) => {
  return(dispatch) => {
    dispatch(triggerEngineLoader(true));
    if(type == 'fetch'){
      globalGetService('/technical/project/'+param.project_slug+'/engine-workorder/'+param.workOrderSlug+'/',{})
      .then(response => {
        dispatch(triggerEngineLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_DETAILS,
            payload: response.data.data
          });
        }
      })
    }else if(type == 'update'){
      globalPutService('/technical/project/'+param.project_slug+'/engine-workorder/'+param.workOrderSlug+'/', {data:data})
      .then(response => {
        dispatch(triggerEngineLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }else{
      globalPostService('/technical/project/'+param.project_slug+'/create-engine-workorder/', {data:data})
      .then(response => {
        dispatch(triggerEngineLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          browserHistory.push('/technical/project/view/'+param.project_slug);
        }
      })
    }
  }
}


export const addEditProjectAc = (param, data, type) => {
  return(dispatch) => {
    dispatch(triggerLoader(true));
    if(type == 'add'){
      delete data.status
      let formData = new FormData()
      Object.keys(data).map(item => {
        if(typeof data[item] == 'object' && item != 'files'){
          formData.append(item, JSON.stringify(data[item]))
        }else if(item === 'files'){
          let index = 1
          for(let file of data.files){
            formData.append('file_' + index, file)
            index++
          }
        }else{
          formData.append(item, data[item])
        }
      })
      globalPostService('/technical/projects/', formData)
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ADD_PROJECT,
            payload:response.data.data
          })
          dispatch(getUsageDetailAc())
          trackActivity('Project Created', {
            page_title: `Project Listing`,
            application_module: 'Inspection',
            project_slug: data.slug,
            item_type: `Add Project`,
            item_id: data.id,
            project_name: data.name
          })
        }
      })
    }else{
      globalPutService('/technical/projects/'+ data.slug+'/', data)
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: 'UPDATE_PROJECT',
            payload: response.data.data
            // payload:response.data.data
          })
          toastFlashMessage(response.data.message, 'success')
        }
        if(data.status == 8){
          browserHistory.push('/technical/projects');
        }else {
          dispatch(projectViewAc({project_slug:  data.slug}))
          trackActivity('Project Edited', {
            page_title: `Project Listing`,
            application_module: 'Inspection',
            project_slug: data.slug,
            item_type: `Edit Project`,
            item_id: data.id,
            project_name: data.name
          })
        }
      })
    }
  }
}

export const closeProjectAc=(projet_slug)=>{
  return (dispatch) => {
  dispatch(triggerLoader(true));
  globalGetService('/technical/project/'+projet_slug+'/close-project/', {})
  .then(response => {
    dispatch(triggerLoader(false));
    if(response.data.statusCode == 200){
     window.location.reload();
    }
  })
}
}

export const projectViewCrudAc = (params,data, slug) => {
  return (dispatch) => {
    dispatch({
      type:  actions.TOGGLE_PROJECT_SIDEBAR,
      payload: createProject
    });
    if(data.type == 'add'){
      dispatch(getGeneralInstructionAc())
      dispatch({
        type: actions.TOGGLE_PROJECT_SIDEBAR,
        payload: data
      })
    }else{
      dispatch(triggerLoader(true));
      globalGetService('/technical/projects/'+slug+'/', {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type:  actions.TOGGLE_PROJECT_SIDEBAR,
            payload: {flag: data.flag, data: response.data.data, type: data.type}
          });
        }
      })
    }
  }
}


export const workOrderAddEditAc = (params, data, type) => {
console.log('data', data)
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if(type == 'add'){
      globalPostService('technical/project/'+ params.project_slug+'/workorder/', data)
      .then(response => {
        if(response.data.statusCode == 200){
         toastFlashMessage(response.data.message, 'success');
          dispatch({
            type: actions.CREATE_WORKORDER,
            payload: response.data.data
          })
          trackActivity('Workorder Added', {
            page_title: `Project Detail`,
            application_module: 'Inspection',
            item_type: type,
            aircraft_id: data.aircraft_id,
            project_slug: params.project_slug,
          })
        }
        dispatch(triggerLoader(false));
        dispatch(getMsnSwitcherAc());
      })
    }else{
      globalPutService('technical/project/'+ params.project_slug+'/workorder/'+data.slug+'/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(triggerLoader(false));
          dispatch({
            type: 'UPDATE_WORKORDER',
            payload: response.data.data
          });
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Workorder Edited', {
            page_title: `Project Detail`,
            application_module: 'Inspection',
            item_type: type,
            aircraft_id: data.aircraft_id,
            project_slug: params.project_slug,
          })
        }
      })
    }
  }
}


export const GenUrlAc = (props, files, type,index) => {

	return (dispatch) => {
		dispatch(triggerAttachLoader(true));
		var formData = new FormData();

			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!(file.type.match('application/pdf.*')||file.type.match('application/vnd.ms-excel.*')||file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*'))) {
					toastFlashMessage( file.name + ' is not Valid Format', 'error');
					 continue;
				 }
			 formData.append('file', file, file.name);
			}
			formData.append('shop_visit', true);


		var config = {
			onUploadProgress: function(progressEvent) {
			 var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
					// dispatch({
					//   type: actions.PROGRESS_PERCENTAGE,
					//   payload: percentage
					// });
				}
    }
    let url='';
    if(props.params.workOrderSlug==undefined){
      url='/technical/engine-workorder/file-upload/';
    }else{
      url='/technical/engine-workorder/'+props.params.workOrderSlug+'/file-upload/'
    }
		globalFileUploadService(url, formData, config)
		.then(response => {
			dispatch(triggerAttachLoader(false));
			if(response.data.statusCode == 200){
        if(type=='shop_visit'){
          dispatch({
						type: actions.ATTACH_UPLOAD_FILE_SHOP,
						payload:{data: {attachment : response.data.url, name: response.data.name, created_at: moment(), user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic  }},index:index}
          })
        }else{
					dispatch({
						type: actions.ATTACH_UPLOAD_FILE_CREATE,
						payload: {attachment : response.data.url, name: response.data.name, created_at: moment(), user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic  }}
          })
        }
			}
		})
	}
}

export const workOrderViewCrudAc = (params, data) => {

  return (dispatch) => {
    if(data.type == 'add'){
      dispatch({
        type: actions.TOGGLE_WORKORDER_SIDEBAR,
        payload: data
      })
    }else{
      dispatch(triggerLoader(true));

      globalGetService('technical/project/'+ params.project_slug+'/workorder/'+data.data.slug+"/", {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          if(data.data.asset_type == 1){
            dispatch({
              type: actions.TOGGLE_WORKORDER_SIDEBAR,
              payload: { type: data.type, data: response.data.data, flag: true}
            })
          }else{
            dispatch({
              type: actions.TOGGLE_APUWORKORDER_SIDEBAR,
              payload: { type: data.type, data: response.data.data, flag: true}
            })
          }
        }
      })
    }
  }
}


export const fetchFormsAc = () => {
  return (dispatch) => {
      globalGetService('technical/forms/?form_type=2', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FETCH_ENGINE_FORMS,
            payload: response.data.data
          })
        }
      })
    }
}

export const fetchApuFormsAc = () => {
  return (dispatch) => {
      globalGetService('technical/forms/?apu_type=True&lg_type=True', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FETCH_APU_FORMS,
            payload: response.data.data
          })
        }
      })
    }
}

export const fetchExistingEngineAc = () => {
  return (dispatch) => {
      globalGetService('console/engine-esn-list/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FETCH_EXISTING_ENGINES,
            payload: response.data.data
          })
        }
      })
    }
}
export const fetchEngineAc = (engine_slug) => {
  return (dispatch) => {
    dispatch(triggerEngineLoader(true));
      globalGetService('technical/engine/'+engine_slug+'/details/', {})
      .then(response => {
        dispatch(triggerEngineLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ENGINE_DETAILS,
            payload: response.data.data
          })
        }
      })
    }
}





export const fetchProjectUserAc = (queryParams, type) => {
  return (dispatch) => {
   // dispatch(triggerLoader(true));
      globalGetService('technical/users/', queryParams)
      .then(response => {
       // dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.PROJECT_MEMBERS,
            payload: {type: type, data:response.data.data}
          });
        }
      })
  }
}

export const fetchQuestionsAc = (params) => {
  return (dispatch) => {
    dispatch(triggerQuesLoader(true));
      globalGetService(`/technical/project/${params.project_slug}/cor/`,{} )
      .then(response => {
        dispatch(triggerQuesLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.PROJECT_QUESTIONS,
            payload: response.data.data
          });
        }
      })
  }
}

export const feedbackListAc = (params) => {
  return (dispatch) => {
    dispatch(triggerQuesLoader(true));
    globalGetService(`/technical/project/${params.project_slug}/feedback_list/`,{} )
      .then(response => {
        dispatch(triggerQuesLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FEEDBACKLIST,
            payload: response.data.data
          });
        }
    });
  }
}

export const submitAnswersAc = (params,answers) => {
  return (dispatch) => {
    dispatch(triggerQuesLoader(true));
      globalPostService(`/technical/project/${params.project_slug}/save-cor/`,{data:answers})
      .then(response => {
        dispatch(triggerQuesLoader(false));
        if(response.data.statusCode == 200){
          //browserHistory.push(`/technical/project/view/${params.project_slug}/project-cor-form`);
          dispatch(fetchQuestionsAc(params));
        }
      })
  }
}

export const submitFeedbackAnswersAc = (params,answers) => {
  return (dispatch) => {
    dispatch(triggerQuesLoader(true));
      globalPostService(`/technical/project/${params.project_slug}/feedback_update/`,{data:answers})
      .then(response => {
        dispatch(triggerQuesLoader(false));
        if(response.data.statusCode == 200){
          //browserHistory.push(`/technical/project/view/${params.project_slug}/project-cor-form`);
          toastFlashMessage(response.data.message, 'success')
          dispatch(feedbackListAc(params));
        }
      })
  }
}

export const submitAnswerStatusAc = (params,data) => {
  return (dispatch) => {
    dispatch(triggerQuesLoader(true));
      globalPostService(`/technical/project/${params.project_slug}/cor/status/`,data)
      .then(response => {
        dispatch(triggerQuesLoader(false));
        if(response.data.statusCode == 200){
          //browserHistory.push(`/technical/project/view/${params.project_slug}/project-cor-form`);
          dispatch(fetchQuestionsAc(params));
        }
      })
  }
}


export const clientCorShowAc = (params,data) => {
  return (dispatch) => {
    //dispatch(triggerQuesLoader(true));
    dispatch({
      type: actions.COR_EXPORT_LOADER,
      payload: true
    });
      globalPutService(`/technical/project/${params.project_slug}/cor-client/status/`,{client_cor_show:data})
      .then(response => {
       // dispatch(triggerQuesLoader(false));
       dispatch({
        type: actions.COR_EXPORT_LOADER,
        payload: false
       });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.COR_CLIENT_STATUS,
            payload: data
          })
        }
      })
  }
}


export const launchWorkOrderAc = (param,work_order_slug,type) => {
  let url=''
  if(type!=2){
   url= '/technical/workorder/'+work_order_slug+'/launch/';
  }else{
   url='/technical/engine-workorder/'+work_order_slug+'/launch/';
  }

  return (dispatch) => {
    dispatch(triggerLoader(true));
      globalGetService(url, {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch(projectViewAc(param))
        }
      })
  }
}

export const launcht012Ac = (param) => {
  let url= '/technical/project/'+param.project_slug+'/launch/';

  return (dispatch) => {
    dispatch(triggerLoader(true));
      globalGetService(url, {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch(projectViewAc(param))
        }
      })
  }
}


export const exportcontactListExportAc = (fileType,query) => {
  let filterParam = query;
  filterParam = {
    ...filterParam,
    download : fileType
  }
  delete filterParam['page'];
  return (dispatch) => {
    dispatch({
      type: actions.PROJECT_EXPORT_LOADER,
      payload: true
    });
    globalExportService('technical/projects/', filterParam)
    .then(response => {
      dispatch({
        type: actions.PROJECT_EXPORT_LOADER,
        payload: false
      });
      downloadFileType(response, 'project-listing.', fileType)
      trackActivity('Report Exported', {
        page_title: `Project Listing`,
        application_module: 'Inspection',
        file_type: fileType,
        file_name: 'Project List'
      })
    })
  }
}
export const exportCorAc = (params,fileType) => {
  let filterParam = {
    download : fileType
  }
  delete filterParam['page'];

  return (dispatch) => {
    dispatch({
      type: actions.COR_EXPORT_LOADER,
      payload: true
    });
    globalExportService('/technical/project/'+params.project_slug+'/cor/', filterParam)
    .then(response => {
      dispatch({
        type: actions.COR_EXPORT_LOADER,
        payload: false
      });
      trackActivity('Report Exported',{
        application_module: 'Inspection',
        page_title: 'COR Tracker',
        file_name: 'COR Tracker',
        file_type: fileType.toUpperCase()
      })
      downloadFileType(response, 'project-cor.', fileType)
    })
  }
}

export const exportt012Ac = (params, name) => {
return (dispatch) => {
  dispatch(triggerLoader(true));
  globalExportService('/technical/project/'+params.project_slug+'/misc-form/', {download: 'pdf'})
  .then(response => {
    dispatch(triggerLoader(false));
    downloadFileType(response, name + '_T012.', 'pdf')
  })
}
}


export const technicalInspectionTypeAc = (type) => {
  return (dispatch) => {
      globalGetService('technical/inspection-types/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.INSPECTION_TYPE,
            payload: response.data.data
          });
        }
      })
  }
}





export const fetchProjectFormAc = (techFormObj = {}) => {
  return(dispatch) => {
    //dispatch(triggerLoader(true));

    globalGetService('technical/forms/', techFormObj)
    .then(response => {
      //dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        if(Object.keys(techFormObj).length){
          if(techFormObj.both){
            dispatch({
              type: actions.FORM_LIST,
              payload: response.data.data
            })
          }else{
          dispatch({
            type: actions.FORM_UPDATED_LIST,
            payload: { data: response.data.data, inspection_type: [techFormObj.inspection_type] }
          })
        }
        }else{
          dispatch({
            type: actions.FORM_LIST,
            payload: response.data.data
          })
        }
      }
    })
  }
}


export const getGeneralInstructionAc = () => {
  return (dispatch) => {
    globalGetService('/technical/project-initiate/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.GET_GEN_INFO,
          payload: response.data.data
        });
      }
    })
}
}


export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROJECT_LOADER,
      payload: flag
    })
  }
}
export const triggerAttachLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.ATTCHMENT_ENGINE_LOADER,
      payload: flag
    })
  }
}

export const triggerEngineLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.CREATE_ENGINE_LOADER,
      payload: flag
    })
  }
}

export const triggerQuesLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.QUESTION_LOADER,
      payload: flag
    })
  }
}

export const aircraftTypeAc = () => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AIRCRAFT_TYPE,
          payload: response.data.data.aircraftTypes
        });
      }
    })
  }
}

export const fetchBluePrintsAc = () => {
  return(dispatch) => {
    globalGetService('/technical/blueprints/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BLUE_PRINT_INFO,
          payload: response.data.data
        })
      }
    })
  }
}

export const getT012FormDataAc = (param, data, type) =>{
  return(dispatch) => {
    dispatch(triggerLoader(true));
    if(type){
      let formData = {title: data.title, comment: data.comments ? data.comments : ''}
      globalPostService('/technical/project/'+param.project_slug+'/misc-form/', formData)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(projectViewAc(param))
        }
        dispatch(triggerLoader(false));
      })
    }else{
      globalGetService('/technical/project/'+param.project_slug+'/misc-form/')
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.T012_DATA,
            payload: response.data.data
          })
        }
        dispatch(triggerLoader(false));
      })
    }

  }
}
export const updateT012FormStatusAc = (param, status) =>{
  return(dispatch) => {
    dispatch(triggerLoader(true));
    globalPostService('/technical/project/'+param.project_slug+'/status-update/', status)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(projectViewAc(param))
      }
      dispatch(triggerLoader(false));
    })
  }
}

export const projectViewAc = (param) => {
  console.log('params', param);
  return(dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('/technical/project/'+param.project_slug+'/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PROJECT_VIEW_DATA,
          payload: response.data.data
        })
        trackActivity('Page Visited', {
          application_module: 'Inspection',
          page_title: `Project View`,
          project_name: `${response.data.data.name}`
        })
        if(response.data.data.project_form === 1){
          dispatch(getT012FormDataAc(param))
        }
      }
      dispatch(triggerLoader(false));
    })
  }
}

export const msnDetailsAc = (aircraftSlug, type) => {
  return(dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('/technical/' + type + '/'+aircraftSlug+'/work-order-details/', {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MSN_DETAILS,
          payload: response.data.data
        })
      }
    })
  }
}

export const deleteItemsAc =  (props,id, type, index,childIndex) => {
  return(dispatch) => {
    if(type == 'shop_visit'){
      if(!id){
        dispatch({
          type: actions.DELETE_ENG_SHOPVISIT,
          payload: index
        });
      }else{
      dispatch(triggerAttachLoader(true));
      globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${id}/delete/`,{})
      .then(response => {
        dispatch(triggerAttachLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage('Shop visit deleted successfully', 'success');
          dispatch({
            type: actions.DELETE_ENG_SHOPVISIT,
            payload: index
          });
            // toastFlashMessage(response.data.message, 'success');
        }
      })
    }
    }else if(type == 'attachment'){
      if(!id){
        dispatch({
          type: actions.DELETE_ENG_ATTACHMENT,
          payload: index
        });
      }else{
      dispatch(triggerAttachLoader(true));
      globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/attachment/${id}/`,{})
      .then(response => {
        dispatch(triggerAttachLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage('Attachment deleted successfully', 'success');
          dispatch({
            type: actions.DELETE_ENG_ATTACHMENT,
            payload: index
          });
            // toastFlashMessage(response.data.message, 'success');
        }
      })
    }
  }else if(type == 'reference_links'){
      if(!id){
        dispatch({
          type: actions.DELETE_ENG_REFERENCE,
          payload: index
        });
      }else{
      dispatch(triggerAttachLoader(true));
      globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/reference/${id}/`,{})
      .then(response => {
        dispatch(triggerAttachLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage('Reference deleted successfully', 'success');
          dispatch({
            type: actions.DELETE_ENG_REFERENCE,
            payload: index
          });
            // toastFlashMessage(response.data.message, 'success');
        }
      })
    }
    }else if(type == 'shop_visit_attachment'){
      if(!id){
        dispatch({
          type: actions.DELETE_ENG_SHOPVISIT_ATTACHMENT,
          payload: {parentIndex:index,index:childIndex}
        });
      }else{
        dispatch(triggerAttachLoader(true));
        globalDeleteService(`technical/shop-visit-attachment/${id}/delete/`,{})
        .then(response => {
          dispatch(triggerAttachLoader(false));
          if(response.data.statusCode == 200){
            toastFlashMessage('Shop visit attachment deleted successfully', 'success');
            dispatch({
              type: actions.DELETE_ENG_SHOPVISIT_ATTACHMENT,
              payload: {parentIndex:index,index:childIndex}
            });

          }
        })
      }
    }
  }
}

export const removeEngineerAc = (params, engineer, project_form) =>{
  return (dispatch) =>{
    dispatch(triggerLoader(true));
    globalDeleteService(`technical/project/${params.project_slug}/remove-user/`,{user_id: engineer.id, project_role: engineer.project_role, project_form: project_form})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.REMOVE_ENGINEER,
          payload: engineer.id
        })
        dispatch(projectViewAc(params))
      }
      toastFlashMessage(response.data.message, 'success');
      dispatch(triggerLoader(false));
      trackActivity('User Removed', {
        page_title: `Project Detail`,
        application_module: 'Inspection',
        project_slug: params.project_slug,
        project_role: engineer.project_role,
        user_name: engineer.name,
        operator_level: engineer.operator_level,
        user_id: engineer.id
      })
    })
  }
}
export const changeUserAc = (params, addUser, removeUser, project_form) =>{
  return (dispatch) =>{
    dispatch(triggerLoader(true));
    globalPostService(`technical/project/${params.project_slug}/change-user/`,{add_user_id: removeUser[0], remove_user_id: addUser , project_role: 1, project_form: project_form})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(projectViewAc(params))
      }
      toastFlashMessage(response.data.message, 'success');
      dispatch(triggerLoader(false));
      trackActivity('User Changed', {
        page_title: `Project Detail`,
        application_module: 'Inspection',
        project_slug: params.project_slug,
        user_name: response.data.data[0].name,
        project_role: response.data.data.project_role,
        operator_level: response.data.data.operator_level,
        user_id: response.data.data.id
      })
    })
  }
}
export const addUsersAc = (params, addUsers, projectRole, operator_level, flag) =>{
  let users = [];
  addUsers.map(user => users.push({user_id: user, project_role: projectRole, operator_level: operator_level}));
  return (dispatch) =>{
    dispatch(triggerLoader(true));
    if(flag){
      globalPostService(`technical/project/${params.project_slug}/assign-user/`,{add_user_list: users})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(projectViewAc(params))
        }
        toastFlashMessage(response.data.message, 'success');
        dispatch(triggerLoader(false));
        trackActivity('User Assigned', {
          page_title: `Project Detail`,
          application_module: 'Inspection',
          project_slug: params.project_slug,
          user_name: response.data.data[0].name,
          project_role: response.data.data[0].project_role,
          operator_level: response.data.data[0].operator_level,
          user_id: response.data.data[0].id
        })
      })
    }else{
      globalPostService(`technical/project/${params.project_slug}/add-user/`,{add_user_list: users})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(projectViewAc(params))
        }
        toastFlashMessage(response.data.message, 'success');
        dispatch(triggerLoader(false));
        trackActivity('User added', {
          page_title: `Project Detail`,
          application_module: 'Inspection',
          project_slug: params.project_slug,
          user_name: response.data.data[0].name,
          project_role: response.data.data[0].project_role,
          operator_level: response.data.data[0].operator_level,
          user_id: response.data.data[0].id
        })
      })
    }
  }
}
export const addEngineerToFormAc = (params, addUsers, formSlug, projectSlug, addToAllForm,type, flag) =>{
  let url='';
  if(flag){
    url=`technical/project/${params.project_slug}/assign-user/`;
  }else{
    if(type!=2){
       url=`technical/workorder/${projectSlug}/form/${formSlug}/assign-user/`;
    }else{
       url=`technical/engine-workorder/${projectSlug}/form/${formSlug}/assign-user/`;
    }
  }

  return (dispatch) =>{
    dispatch(triggerLoader(true));
    globalPostService(url,{user_id: addUsers[0], assign_to_all: addToAllForm })
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(projectViewAc(params))
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch(triggerLoader(false));
    })
  }
}


export const getUserDetailAc = (userFlug, flag) => {
  return(dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/users/'+userFlug+'/details/', {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.USER_DETAIL,
          payload: {data: response.data.data, flag: flag}
        })
      }
    })
  }
}

export const getUsageDetailAc = () =>{
  return(dispatch)=>{
    globalGetService(`/console/license/inspection/usage/`)
    .then(response =>{
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INSP_USAGE,
          payload: response.data.data
        })
      }
    })
  }
}


export const getWorkOrderDetailAc = (data) => {
  const assetTypes = {
    "3": "apu",
    "4": "lg",
    "5": "propeller"
  }
  return(dispatch)=>{
    if(data.type == 'add'){
      dispatch({
        type: actions.WORKORDER_SIDEBAR,
        payload: data
      })
    }else{
      dispatch({
        type: actions.PROJECT_LOADER,
        payload: true
      })
      if(data.flag){
        globalGetService(`/technical/${assetTypes[data.data.asset_type]}/${data.data.asset.slug}/work-order-details/`)
        .then(response => {
          if(response.data.statusCode == 200){
            let workData = data
            delete response.data.data.slug
            workData = {...workData, data:{...workData.data, ...response.data.data}}
            workData = {...workData, data: {...workData.data, forms: workData.data.forms.map(item => item.id), status: workData.data.status.id, inspection_types: workData.data.inspection_types.map(item => item.id) }}
            dispatch({
              type: actions.WORKORDER_SIDEBAR,
              payload: workData
            })
            dispatch({
              type: actions.PROJECT_LOADER,
              payload: false
            })
          }
        })
      }else{
        dispatch({
          type: actions.WORKORDER_SIDEBAR,
          payload: data
        })
        dispatch({
          type: actions.PROJECT_LOADER,
          payload: false
        })
      }
    }
  }
}
