import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class LLPToBeReplacedModal extends Component {
  constructor(props){
    super(props);
    this.state={};
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.props.llpToBeReplacedFn('','', '', '');
  }
  render() {
    const { llpToBeReplaced } = this.props;
    return(
      <div className="">
        <Modal isOpen={llpToBeReplaced.modalStatus} toggle={this.toggle} className="llp-brekup-modal">
          <div className="modal-header">
            <p>Shop Visit #{llpToBeReplaced.runId}</p>
            <h5>
              LLPs to be replaced on {llpToBeReplaced.svd}
            </h5>
          </div>
          <ModalBody style={{maxHeight: '440px'}}>
            <table>
              <thead>
                <tr>
                  <th className="caption-ui">LLP Type</th>
                  <th className="caption-ui">Remaining</th>
                  <th className="caption-ui  text-right">Cost</th>
                </tr>
              </thead>
              <tbody>
              {llpToBeReplaced.list.map((llpList, index) => {
                return (
                  <tr key={index}>
                    <td className="caption-ui"> {llpList.part_name} </td>
                    <td className="caption-ui"> {llpList.cycles_remaining}/{llpList.max_limit} </td>
                    <td className="caption-ui text-right">$ {llpList.price} </td>
                  </tr>
                )
              })}

              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <p>Total Replacement Cost</p>
            <span>$
              {
                llpToBeReplaced.list.reduce( (total, next) => total + next.price, 0 )
              }
            </span>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
