import React from 'react'
import { Row, Col } from 'reactstrap'

const InvoicePreviewItem = (props) =>{
  return(
    <Row className="invoice-preview-item">
      <Col md="9">{props.description}</Col>
      <Col md="3" style={(props.amount) < 0 ? {color: 'red'} :{}}>$ {parseFloat(props.amount).toFixed(2)}</Col>
    </Row>
  )
}

export default InvoicePreviewItem
