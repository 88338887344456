import * as actions from '../actions';
export default function( state = {
  delCondLists: [],
  deliveryCondCrud: {
    flag: false,
    type: '',
    data: {}
  },
  delCondLoader: true
}, action){
  switch (action.type) {
    case actions.DELIVERY_COND_LIST:
      return {
        ...state,
        delCondLists: action.payload
      }
    case actions.DELIVERY_COND_ADD_SUCCESS:
      return {
        ...state,
        delCondLists: [ action.payload, ...state.delCondLists]
      }
    case actions.DELIVERY_COND_EDIT_SUCCESS:
      return {
        ...state,
        delCondLists: state.delCondLists.map(list => list.id == action.payload.id ? action.payload : list)
      }
    case actions.DELIVERY_COND_CRUD:
      return {
        ...state,
        deliveryCondCrud:action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        deliveryCondCrud: {
          ...state.deliveryCondCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.DEL_COND_LOADER:
      return {
        ...state,
        delCondLoader: action.payload
      }
    case actions.DELIVERY_COND_DELETE:
      return {
        ...state,
        delCondLists: state.delCondLists.filter(list => list.id != action.payload)
      }
      
    default:
      return state;
  }
}
