import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';
import { showCurrencyFormat } from '../../../../../utils';
import { ToolTipHover } from '../../../../../shared';

const List = (props) => {
  return (
    <tr className={ props.selectedIds.includes(props.data.id) ? 'selected-row': ''}>
      { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
        <td className="check-opt">
          <input checked={props.selectedIds.includes(props.data.id)} onChange={(e) => props.selectItem(e)} type="checkbox" />
        </td>:null
      }
      <td>
        { props.data.part_number ? props.data.part_number : '--'}
        { props.data.removal_date ? '' :
          <span className="highter" id={"highligther-"+props.data.id}></span>
        }
        { props.data.removal_date ? '' :
          <ToolTipHover placement="top" tagetId={"highligther-"+props.data.id}>
            No Track of Removal Date
          </ToolTipHover>
        }
      </td>
      <td>{props.data.part_description ? props.data.part_description : '--' }</td>
      <td>{props.data.part_location ? props.data.part_location : '--' }</td>
      <td>{props.data.removal_date ? moment(props.data.removal_date).format(displayDateFormat): '--'}</td>
      <td>{props.data.reason_for_removal ? props.data.reason_for_removal: '--'}</td>
      <td>{props.data.date_verified_removed_from_aircraft ? moment(props.data.date_verified_removed_from_aircraft).format(displayDateFormat): '--'}</td>
      <td>{props.data.date_verified_in_store_house ? moment(props.data.date_verified_in_store_house).format(displayDateFormat): '--' }</td>
      <td>{props.data.date_verified_installed_on_aircraft ? moment(props.data.date_verified_installed_on_aircraft).format(displayDateFormat): '--' }</td>
      <td>
        { props.data.price ?
          <NumericLabel params={showCurrencyFormat()}>{props.data.price}</NumericLabel>
          :'--'
        }
      </td>
      <td className="crud-block">
        <ul className="list-inline table-action-col">
          <li className="list-inline-item" onClick={() => props.rowActivity('view')}> <img src={imgStoragePath + "blue_eye_icon.png"} width="22" /></li>
          { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
            <li className="list-inline-item" onClick={() => props.rowActivity('edit')}> <img src={imgStoragePath + "blue_pencil_icon.png"} width="20" /></li>:null
          }
          { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
            <li className="list-inline-item" onClick={() => props.rowActivity('delete')}> <img src={imgStoragePath + "blue_delete_icon.png"} width="15" /></li>:null
          }

        </ul>
      </td>
    </tr>
  )
}
export default List;
