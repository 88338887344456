export const LG_EVENT_LIST = 'LG_EVENT_LIST';
export const LG_EVENT_HISTORY = 'LG_EVENT_HISTORY';
export const LG_SHOP_VISIT_CRUD = 'LG_SHOP_VISIT_CRUD';
export const LG_SHOP_VIST_ADD_SUCCESS = 'LG_SHOP_VIST_ADD_SUCCESS';
export const LG_CLAIM_LIST = 'LG_CLAIM_LIST';
export const LG_CLAIM_CRUD = 'LG_CLAIM_CRUD';
export const LG_PAYOUT_CRUD = 'LG_PAYOUT_CRUD';
export const LG_CLAIM_ADD_SUCCESS = 'LG_CLAIM_ADD_SUCCESS'
export const LG_CLAIM_EDIT_SUCCESS = 'LG_CLAIM_EDIT_SUCCESS'
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const LG_LOADER = 'LG_LOADER';
