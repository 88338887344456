export const ASSET_LIST = 'ASSET_LIST';
export const LIST_LOADER = 'LIST_LOADER';
export const ASSET_DETAIL = 'ASSET_DETAIL';
export const ASSET_DETAIL_ID = 'ASSET_DETAIL_ID';
export const ASSET_FILTER_UPDATE = 'ASSET_FILTER_UPDATE';
export const ASSET_FILTER_EMPTY = 'ASSET_FILTER_EMPTY';
export const ASSET_SORT_UPDATE = 'ASSET_SORT_UPDATE';
export const FORM_SUBMIT_LOADER = 'FORM_SUBMIT_LOADER';
export const RECORDS_INIT = 'RECORDS_INIT';
export const ADD_ASSET = 'ADD_ASSET';
export const EDIT_USER_INFO = ' EDIT_USER_INFO';
export const USER_PROFILE_INFO = 'USER_PROFILE_INFO';
export const USER_PROFILE_LOADER='USER_PROFILE_LOADER';
export const PROFILE_DOWNLOAD_LOADER='PROFILE_DOWNLOAD_LOADER';
export const PROFILE_PROCESSING='PROFILE_PROCESSING';
export const TYPE_UPDATE='TYPE_UPDATE';
export const GET_USERS_LIST='GET_USERS_LIST';
export const GET_INVITE_LIST='GET_INVITE_LIST';
export const GET_LESSOR_LIST='GET_LESSOR_LIST';
export const GET_GROUPS_LIST='GET_GROUPS_LIST';
export const DELETE_USER_GROUP='DELETE_USER_GROUP';
export const ADD_USER_GROUP='ADD_USER_GROUP';
export const DELETE_USER_LESSOR='DELETE_USER_LESSOR';
export const DELETE_USER_ASSET='DELETE_USER_ASSET';
export const ADD_USER_LESSOR='ADD_USER_LESSOR';
export const ADD_USER_ASSETS='ADD_USER_ASSETS';
export const REJECT_INVITE_REQUEST = 'REJECT_INVITE_REQUEST';
export const USER_CRUD_BAR = 'USER_CRUD_BAR';
export const USER_ADD_CRUD_BAR = 'USER_ADD_CRUD_BAR';
export const GET_USER_CRUD = 'GET_USER_CRUD';
export const SET_USER_DATA = 'SET_USER_DATA';
export const GROUP_LOADER_UPDATE = 'GROUP_LOADER_UPDATE';
export const GET_ASSETS_LIST = 'GET_ASSETS_LIST';
export const FILTER_ASSET_LIST = 'FILTER_ASSET_LIST';
export const FILTER_SECURITY_GRP_LIST = 'FILTER_SECURITY_GRP_LIST';
export const LESSOR_LOADER_UPDATE = 'LESSOR_LOADER_UPDATE';
export const ASSET_LOADER_UPDATE='ASSET_LOADER_UPDATE';
export const USER_EMAIL_LIST = 'USER_EMAIL_LIST';
export const USER_LIST_EXPORT_LOADER='USER_LIST_EXPORT_LOADER';
export const USER_CONTRACTS = 'USER_CONTRACTS'
export const USER_CONTRACT_CRUD = 'USER_CONTRACT_CRUD'
export const USERS_SKILL = 'USERS_SKILL'
export const EXPORT_CV = 'EXPORT_CV'
export const USERS_USAGE = 'USERS_USAGE'
export const USER_PROJECT_RATING = 'USER_PROJECT_RATING';
export const ADD_UPDATE_EXPEREIENCE='ADD_UPDATE_EXPEREIENCE';
export const ADD_EXPERIENCE='ADD_EXPERIENCE';
export const UPDATE_EXPERIENCE='UPDATE_EXPERIENCE';
export const DELETE_EXPERIENCE='DELETE_EXPERIENCE';
export const DELETE_EXP_LOADER='DELETE_EXP_LOADER';
export const LESSOR_LIST='LESSOR_LIST';
export const APU_TYPES_LIST='APU_TYPES_LIST';
export const USER_AVAILABILITY_CRUD='USER_AVAILABILITY_CRUD';
export const USER_AVAILABILITY_LIST='USER_AVAILABILITY_LIST';
export const UPDATE_USER_FIELD='UPDATE_USER_FIELD';
