import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getLocalStorageInfo } from '../../../../../utils';
import moment from 'moment';
import { trackActivity } from '../../../../../utils/mixpanel';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
const userInfo = getLocalStorageInfo();

export const getMrSectionDetailsAC = (props) => {
	return (dispatch) => {
        dispatch(triggerLoader(true));
		globalGetService('/technical/engine-workorder/'+props.params.workOrderSlug+'/mr-sections/', {})
		.then(response => {
            dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_MRSECTION_DETAILS,
					payload: response.data.data
				})
			}
		})
	}
}


export const deleteMrAttachmentAc = (props, id, type, index) => {
	return(dispatch) => {

		if(!id){
			dispatch({
				type: actions.MR_DELETE,
				payload: index
			});
		}else{
			dispatch(triggerLoader(true));
			globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/delete/attachment/`,{id:id})
			.then(response => {
				dispatch(triggerLoader(false));
				if(response.data.statusCode == 200){
					dispatch({
						type: actions.MR_DELETE,
						payload: index
					});
					trackActivity('File Deleted', {
						page_title: `TE006`,
						application_module: `MR Claim & Invoice Review`,
						workorder_slug: props.params.workOrderSlug,
						form_slug: props.params.formSlug,
						file_id: id,
					  })
				}
			})
		}
	}
}


export const deleteMrSectionAc = (props, id, type, index) => {
	return(dispatch) => {

		if(!id){
			dispatch({
				type: actions.MR_SECTION_DELETE,
				payload: index
			});
		}else{
			dispatch(triggerLoader(true));
			globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/mr-sections/delete/section/`,{id:id})
			.then(response => {
				dispatch(triggerLoader(false));
				if(response.data.statusCode == 200){
					dispatch({
						type: actions.MR_SECTION_DELETE,
						payload: index
					});

				}
			})
		}
	}
}


export const updateMrSectionDetailsAc = (props,data) => {
	return (dispatch) => {
        dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/'+props.params.workOrderSlug+'/mr-sections/create/', {data:data})
		.then(response => {
            dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
             toastFlashMessage(response.data.message, 'success');
			 trackActivity('Table Inspection Details Saved', {
				page_title: `TE006`,
				application_module: `MR Claim & Invoice Review`,
				workorder_slug: props.params.workOrderSlug,
				form_slug: props.params.formSlug,
			  })
				dispatch({
		 			type: actions.GET_MRSECTION_DETAILS,
		 			payload: response.data.data
		 		})
			}
		})
	}
}


export const GenUrlAc = (props, files, type) => {
	console.log(files);
	return (dispatch) => {
		dispatch(triggerLoader(true));
		var formData = new FormData();

			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!file.type.match('application/pdf.*')) {
					toastFlashMessage( file.name + ' is not Valid Format', 'error');
					 continue;
				 }
			 formData.append('file', file, file.name);
			}
			formData.append('mr', true);


		var config = {
			onUploadProgress: function(progressEvent) {
			 var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
					// dispatch({
					//   type: actions.PROGRESS_PERCENTAGE,
					//   payload: percentage
					// });
				}
		}
		globalFileUploadService('/technical/engine-workorder/'+props.params.workOrderSlug+'/file-upload/', formData, config)
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
					dispatch({
						type: actions.ATTACH_UPLOAD_FILE,
						payload: {attachment : response.data.url, name: response.data.name, size: response.data.size, updated_at: moment(), user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic  }}
					})
					trackActivity('File Uploaded', {
						page_title: `TE006`,
						application_module: `MR Claim & Invoice Review`,
						workorder_slug: props.params.workOrderSlug,
						form_slug: props.params.formSlug,
						file_name: files[0].name,
						file_type: files[0].type
					  })
			}
			dispatch(usageDetailAc())
		})
	}
}

export const triggerLoader = (flag) => {
    return (dispatch) => {
		dispatch({
			type: actions.MRSECTION_DETLS_LOADER,
			payload: flag
		});
    }
  }
