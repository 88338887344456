export const AIRFRAME_EVENT_LIST = 'AIRFRAME_EVENT_LIST';
export const AIRFRAME_EVENT_HISTORY = 'AIRFRAME_EVENT_HISTORY';
export const AF_SHOP_VISIT_CRUD = 'AF_SHOP_VISIT_CRUD';
export const AF_SHOP_VIST_ADD_SUCCESS = 'AF_SHOP_VIST_ADD_SUCCESS';
export const AF_CLAIM_LIST = 'AF_CLAIM_LIST';
export const AF_CLAIM_CRUD = 'AF_CLAIM_CRUD';
export const AF_PAYOUT_CRUD = 'AF_PAYOUT_CRUD';
export const AF_CLAIM_ADD_SUCCESS = 'AF_CLAIM_ADD_SUCCESS';
export const AF_CLAIM_EDIT_SUCCESS = 'AF_CLAIM_EDIT_SUCCESS';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const AIRFRAME_LOADER = 'AIRFRAME_LOADER';
export const EVENT_TYPES = 'EVENT_TYPES';
export const ASSET_INFO = 'ASSET_INFO';
export const MAINTENANCE_USAGE = 'MAINTENANCE_USAGE'
