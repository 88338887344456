import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../../constants';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea } from '../../../contracts/Elements';
import { TextInputField, GetInputField, ImageCard, ContentDrop } from'../../../technicalInspection/Elements';
import { toastFlashMessage } from '../../../../utils';
export default class AddEditLopa extends Component{
  constructor(props){
  	super(props);
  	this.state = {
  		dragOver: false,
          lopaDetail : {},
          type:'',
  		  file: []
  	}
  }
	componentDidMount(){
		let dataObj = Object.assign({}, this.props.lopa)
		this.setState({
            lopaDetail : dataObj.data,
            type:this.props.type
		})
    }
     componentWillReceiveProps(nextprops){
        let dataObj = Object.assign({}, nextprops.lopa)
		this.setState({
            lopaDetail : dataObj.data,
            type:nextprops.type
		})
     }
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
			modalTitle: '',
		});
	}
	toggleDelModal = (title='', sectionId=null, picId=null) => {
		this.setState({
			delModal: !this.state.delModal,
			modalTitle: title,
			sectionId: sectionId,
			picId: picId
		});
	}
	onDragOver = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
			dragOver:true,
		});
	}
	getCurrentIndex = (index) => {
		this.setState({
			sliderIndex: index
		})
	}
	onFocusOut = () =>{
		this.setState({
			dragOver: false,
		})
	}
	onDrop = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
			dragOver: false,
		})
	}
	updateForm = (key, value) => {
		this.setState(prevState => ({
			...prevState,
			lopaDetail : {
				...prevState.lopaDetail,
				[key] : value
			}
		}))
	}
	uploadLopaImage = ( file) => {
		if(["jpeg", "jpg", "png","JPEG", "JPG", "PNG"].includes(file[0].name.split('.').reverse()[0].toLowerCase())){
			this.setState(prevState => ({
				...prevState,
				lopaDetail : {
					...prevState.lopaDetail,
					image : URL.createObjectURL(file[0])
				}
			}))
			this.setState(prevState => ({
				...prevState,
				file: file
			}))
		}else {
			toastFlashMessage('You can upload only image file', "warning")
		}

	}
	addEditLopaInfo = (e) => {
		e.preventDefault();
		this.props.updateLopaDetail(this.state.lopaDetail, this.state.file,'lopaHistoryList','lopacrud',this.state.lopaDetail.id ?'edit':'add');
	}
	render(){
		const headerHeight = document.body.contains(document.getElementById('sidebar-header')) ? (document.getElementById('sidebar-header').clientHeight - 5) : 0 ;
        const { lopaDetail,type } = this.state;

		return(
        <form onSubmit={(e) => this.addEditLopaInfo(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px', paddingLeft: '20px' }}>
          <Row>
            <Col className="image-row" key="" md="12">
              <ul className="list-inline">
                { lopaDetail.image ?
                  <ImageCard
                    picture={{ id: 0 , image: lopaDetail.image}}
                    toggleDelModal={() => this.toggleDelModal('Are you sure to delete?', lopaDetail.id, lopaDetail.pictures[0].id)}
                    changePicture= {(files) => this.uploadLopaImage(files)}
					options = { type !== 'view' ? ['C']:[]}
					style={{backgroundSize: "cover"}}
                    /> :
                  <li
                    className="list-inline-item dropable-zone"
                    onDrop={(evt) => this.uploadLopaImage( evt.dataTransfer.files)}
                    style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)', width: '200px', height: '200px'} : { background: 'transparent', width: '200px', height: '200px'}}>
                    <ContentDrop
									acceptExtensions={'image/*'}
      								mutlipleEnabled={false}
      								filesUpload={(e) => this.uploadLopaImage(e.target.files)}
                    />
                    <span className="img-info-msg">
		                  Recommended Picture dimension 400 X 400 Pixels
                    </span>
                  </li>
                }
						</ul>
					</Col>
                    <Col md="12" style={{marginBottom: '20px'}}>
                        {
							GetInputField(
								'string',
								<label className="for-label">Name </label>,
								{ type: type,
									value: lopaDetail.file_name,
									error: '',
									updateForm: (value) => this.updateForm('file_name', value)
								}
							)
						}
					</Col>
					<Col md="12" style={{marginBottom: '20px'}}>
                        {
							GetInputField(
								'string',
								<label className="for-label">Remarks </label>,
								{ type: type,
									value: lopaDetail.remarks,
									error: '',
									updateForm: (value) => this.updateForm('remarks', value)
								}
							)
						}
					</Col>
				</Row>
				</div>
				{ type !== 'view' ?
                <div className="submit-block">
                <input type="submit" className="primary-btn" value="Save Changes"/>
                <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
                </div> : null }
			</form>
		)
	}
}
