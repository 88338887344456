import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addSubLease } from '../../contractAddObject';
export const contrSubleaseListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.SUBLEASE_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/sub-lease/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.SUBLEASE_LIST,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.SUBLEASE_LOADER,
        payload: false
      });
    })
  }
}
export const subLeaseFnCrudAc = (params, type, id) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.SUBLEASE_CRUD,
        payload: {type: type, data:addSubLease , flag: true }
      });
    }else{
      dispatch({
        type: actions.SUBLEASE_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/sub-lease/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.SUBLEASE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.SUBLEASE_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}

export const  contrAddEditSubleaseAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/sub-lease/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.ADD_SUBLEASE_LIST,
            payload: response.data.data
          });
          dispatch({
            type: actions.SUBLEASE_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Subleases`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Sublease`,
            item_id: response.data.data.id
          })
        }
       })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/sub-lease/' + data.id + '/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.EDIT_SUBLEASE_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.SUBLEASE_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Subleases`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Sublease`,
            item_id: data.id
          })
        }
      })
    }
  }
}
