import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col, Popover, PopoverBody, PopoverHeader, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { ShortHead } from '../../../contracts/Elements';
import { RecordsSideBar } from '../../Elements';
import { BucketList } from '../components';
import { getFolderMoveRecordAc, moveRecordToDestAc } from '../actionCreators';
import '../../assets/styles/records_a.scss';
import { getItemObjects, removeItemObject, removeItem } from '../../../../indexedDB';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import { emptyBucketAc, downloadBucketAc, moveBucketToDestAc } from '../../../../shared/actionCreators';
import { Separator } from 'react-contexify';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, assetsType } from '../../../../constants';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';

class DataBucket extends Component{
  constructor(props) {
    super(props);
    this.state ={
      popoverOpen: false,
      destination:'',
      delModalFlag: false,
      previewCard:{},
      empBucketFlag: false,
    };
    this.togglePopover = this.togglePopover.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.empBucket = this.empBucket.bind(this);
  }
  empBucket(){
    this.setState({
      empBucketFlag: !this.state.empBucketFlag,
    })
  }

  deleteRecord(item){
    this.setState({
      delModalFlag: !this.state.delModalFlag,
      previewCard: item,
    })
  }

  togglePopover(){
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
    this.props.getFoldersToMove({type: 'folder', uuid: this.props.ctrShortInfo.records.uuid},{level: 1, source: this.props.ctrShortInfo.records.uuid});
  }

  componentDidMount(){
    this.props.fetchAssetInfo();
    getItemObjects(this.props.params.aircraft_slug);
    this.props.fetchTechConstant({constant_types: ['file_extension']})
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dataRoom/';
    }else{
      url = '/asset-view/'+this.props.params.type+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }

  render(){
    const { ctrShortInfo, techConstants, dataBucket, dockLoader, folderDirTree, directoryLoader } = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    return(
     <div className="records-list-wrap" style={{  marginTop: '94px' }}>
       {
         Object.keys(ctrShortInfo).length ?
           <RecordsSideBar ctrShortInfo={ctrShortInfo}/>:null
       }
       { Object.keys(ctrShortInfo).length ?
         <ShortHead headerHeight='70px' headingText="Records" changeMsn={this.changeMsn} contrAssetBasicInfo={ctrShortInfo} />:null
       }
       <Row>
         <Col md="2">
           <h6 className="data-bucket-heading">File Bucket</h6>
         </Col>
         {
           (dataBucket && dataBucket.length > 0) ?
             <Col md="10" className="status-block text-right bucket-action">
               {/* {getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1 ?
               <Link style={{color: '#007bff'}} onClick={() => this.togglePopover()} id="dataBucket">Move All</Link>:null} */}
               <Link style={{color: '#ff5e5e'}} onClick={() => this.empBucket()}>Clear All</Link>
               {getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
               <Link style={{color: '#73cd1f'}} onClick={() => {this.props.downloadBucket(dataBucket, this.props.ctrShortInfo.msn?this.props.ctrShortInfo.msn:this.props.ctrShortInfo.esn);removeItemObject(this.props.params.aircraft_slug);}} >Download All</Link>:null}
            </Col>:null
          }
        </Row>
        <div className="asset-list-filter-sort" style={{ margin: '0 15px'}}>
          <Row className="filter-block">
            <Col md="9">
              <h6>Name</h6>
            </Col>
            <Col md="2">
              <h6>Size</h6>
            </Col>
            <Col md="1"></Col>
          </Row>
        </div>
        <div className="list-wrapper file_bucket_block" style={{marginTop: '0px'}}>
          {
            dataBucket ?
            dataBucket.length > 0 ?
            dataBucket.map((item) =>
            <BucketList
              key={item.uuid}
              item={item}
              type={item.type}
              deleteRecord={() => this.deleteRecord(item)}
              fileIcons={fileIcons}
            />
          ):<NoRecordFound/>:<ListLoader/>
          }
          {
            directoryLoader ? <ListLoader/> : null
          }
        </div>
        {/* {
          (dataBucket && dataBucket.length > 0) ?
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target="dataBucket" toggle={this.togglePopover}>
          <PopoverHeader className="popover-header">
            Move To
            <span className="close-popover" onClick={this.togglePopover}>X</span>
          </PopoverHeader>
          <PopoverBody>
            <ul className="list-inline">
              {
                this.props.popoverLoader ?
                <img src={imgStoragePath + "wait.gif"} style={{width:'40px', position: 'relative', left: '45%'}}/>:
                folderDirTree ?
                folderDirTree.length > 0 ?
                <li>
                  <img src={imgStoragePath+"popover_back.png"} onClick={() => this.props.getFoldersToMove(folderDirTree[0],{level: folderDirTree[0].level - 1, source: this.props.ctrShortInfo.records.uuid})}/> Parent Folder
                </li>
                :<li>
                  <img src={imgStoragePath+"popover_back.png"} onClick={() => this.props.getFoldersToMove(this.props.item,{level: this.props.item.level - 1, source: this.props.ctrShortInfo.records.uuid})}/> Parent Folder
                </li>:null
              }
              {
                folderDirTree ?
                folderDirTree.length > 0 ?
                folderDirTree.map((folderItem, index) => <li className={this.state.destination == folderItem.uuid ? 'active' : ''} key={folderItem.uuid}>
                  <img src={imgStoragePath+"folder_icn3.png"}/><span onClick={() => this.setState({destination: folderItem.uuid})}>{folderItem.name}</span>{folderItem.leaf == false ? <img src={imgStoragePath+"move_icon.png"} width="15" onClick={() => this.props.getFoldersToMove(folderItem,{select: folderItem.level, source: this.props.ctrShortInfo.records.uuid})} className='sub-folder'/>:null}
                </li>) :<li>--</li>:null
              }
            </ul>
          </PopoverBody>
          <Separator />
          {
            this.state.destination != '' ?
            <Button type="button" onClick={() => {this.props.moveRecordToDest(dataBucket,this.state.destination);this.togglePopover();}} color="primary" className="add-new">Move Here</Button>:null
          }
        </Popover>:null
      } */}
        <Modal isOpen={this.state.delModalFlag} centered={true} toggle={this.deleteRecord} className={this.props.className}>
          <ModalHeader toggle={this.deleteRecord}>Remove</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to Remove {this.state.previewCard.name} from bucket?</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {removeItem(this.state.previewCard, this.props.params.aircraft_slug); this.deleteRecord(this.state.previewCard)}}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={this.deleteRecord}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.empBucketFlag} centered={true} toggle={this.empBucket} className={this.props.className}>
          <ModalHeader toggle={this.empBucket}>Clear</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to clear all files from bucket?</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.emptyBucket(dataBucket);removeItemObject(this.props.params.aircraft_slug); this.empBucket();}}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.empBucket()}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  dataBucket: state.sharedReducers.dataBucket,
  folderDirTree: state.DataRoomReducer.folderDirTree,
  popoverLoader: state.DataRoomReducer.popoverLoader,
  directoryLoader: state.DataRoomReducer.directoryLoader,
  techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Data Bucket')),
    emptyBucket: (dataBucket) => dispatch(emptyBucketAc(ownProps.params, dataBucket)),
    downloadBucket: (dataBucket, msn) => dispatch(downloadBucketAc(ownProps.params, dataBucket, msn)),
    getFoldersToMove: (item, level) => dispatch(getFolderMoveRecordAc(ownProps.params, item, level)),
    moveRecordToDest: (source, destination) => dispatch(moveBucketToDestAc(ownProps.params, source, destination)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(DataBucket,['records','data_room','R'])))
