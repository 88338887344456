import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Button, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, Popover, PopoverBody, PopoverHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { Separator } from 'react-contexify';
import { imgStoragePath, assetsType } from '../../../../constants';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { BatchFileList, BatchList } from '../components'
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { RecordsSideBar, FileNavigation } from '../../Elements';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { getBatchDetailAc, getUnorganisedListAc, renameFileAc, getOrganisedListAc, deleteBatchFileAc, publishBatchAc, getFoldersToMoveAc, moveRecordToDestAc, unOrgExportAc } from '../actionCreators';
import Pagination from "react-js-pagination";
import { sGlobalConstantAc } from '../../../../shared/actionCreators';

class RenameAndOrgView extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state={
      activeTab: 0,
      dropdownOpen: false,
      publishBatchFlag: false,
      popoverOpen: false,
      popoverIndex: 0,
      destination: '',
      activeFolder: '',
      moveType: ''
    }
  }

  togglePopover = (item, index, type) => {
    this.setState(prevState => ({
      ...prevState,
      popoverOpen: !this.state.popoverOpen,
      popoverIndex: index,
      popoverItem: item,
      moveType: type
    }));
  }

  componentDidMount(){
    this.props.fetchAssetInfo()
    this.props.getUnorganisedList({})
    this.props.getOrganisedList({})
    this.props.getBatchDetail();
    this.props.fetchTechConstant({constant_types: ['file_extension']})
  }

  changeMsn = (evt) =>{
    let url = ``;
    if(evt.asset_type == 1){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/auto-organiser-and-renaming';
    }else{
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dashboard/';
    }
    browserHistory.push(url)
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render(){
      const { ctrShortInfo, techConstants, batchLoader, organisedList, unorganisedList, folderDirTree, batchDetail, notificationOpen } = this.props;
      var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
      techConstants.map(item => {
        if(item.type == "file_extension"){
          fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
        }
      });

    return (
        <div className="auto-renamig-block" style={{ margin: '54px -15px 0px 255px', padding: '30px'}}>
            <RecordsSideBar />
            { Object.keys(ctrShortInfo).length ?
              <ShortHead
                headingText="Records"
                changeMsn={this.changeMsn}
                subHeaderTopMargin={notificationOpen == true ? 74 : 40}
                contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
            }
            <div className="to-be-reviewed">
             <Row className="asset-listing-wrap">
               <Col md="12">
                 <Link onClick={() => browserHistory.goBack()} className="back-nav flex-centered">
                     <img width="18" src={imgStoragePath + 'back_arrow.png'} alt="" />
                     <span>All Files</span>
                 </Link>
               </Col>
               <Col md="12">
                 {
                   Object.keys(batchDetail).length ?
                    <BatchList batch={batchDetail}/>
                   :null
                 }
               </Col>
             </Row>
                <div className="asset-listing-wrap">
                  <Row>
                    <Col md="12">
                      <div style={{position: 'relative'}}>
                        <ul className="list-inline tab-left" style={{background: 'transparent'}}>
                          <li className={this.state.activeTab == 0 ? "inline-item active" : "inline-item"} onClick={() => this.setState({activeTab: 0})}>Organised</li>
                          <li className={this.state.activeTab == 1 ? "inline-item active" : "inline-item"} onClick={() => this.setState({activeTab: 1})}>Unorganised ({unorganisedList.pagination && unorganisedList.pagination.total ? unorganisedList.pagination.total : 0}) </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  {
                    this.state.activeTab == 1 ?
                    <Row>
                      <Col md="4">
                        <div className='action-dock-search'>
                          <form style={{height: '35px'}}>
                            <input type="search" placeholder="Search..." style={{marginTop: '5px'}}/>
                            <button type="submit"><img src={`${imgStoragePath}search_icn.png`} alt="icon"/></button>
                          </form>
                        </div>
                      </Col>
                      <Col md="8">
                        <ul className="list-inline tab-right flex-centered export-unorg-rec" style={{display: 'inline-block', marginTop: '10px'}}>
                          <li className="list-inline-item download-block">
                            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                              <DropdownToggle caret size="sm">
                              <span style={{fontSize: '16px'}}><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export</span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem onClick={}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem> */}
                                <DropdownItem onClick={() => this.props.exportUnOrg('xls')}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    :null
                  }
                  {
                    this.state.activeTab == 1 ?
                    <div className="asset-list-filter-sort" style={{marginTop: '20px'}}>
                      <Row className="filter-block">
                        <Col md="5">
                            <h6>
                              Name
                            </h6>
                        </Col>
                        <Col md="1">
                            <h6>
                              <span>
                                Size
                              </span>
                            </h6>
                        </Col>
                          <Col md="2">
                              <h6><span>Uploaded By</span></h6>
                          </Col>
                          <Col md="2">
                              <h6><span>Last Modified</span></h6>
                          </Col>
                          <Col md="2">
                            <h6><span>Actions</span></h6>
                          </Col>
                      </Row>
                    </div>
                    :null
                  }
                  {
                    this.state.activeTab == 0 ?
                      <div>
                        <FileNavigation
                          canCreateFolder={false}
                          getNavigationTree={this.getNavigationTree}
                          goBackDirectory={this.goBackDirectory}
                          changeDirectory={(uuid) => {this.props.getOrganisedList({folder_uuid: uuid});this.setState({activeFolder: uuid})}}
                          navigateToRoot={(uuid) => this.props.getOrganisedList({folder_uuid: uuid})}
                          navigation={organisedList.navigation}
                          style={{position: 'relative',top: '-20px', left: '0px'}}
                        />
                        {
                          organisedList.list && organisedList.list.length ?
                            organisedList.list.map((item, index) => <BatchFileList fileIcons={fileIcons} renameRecord={this.props.renameFile} currentPage={unorganisedList.pagination.current_page} type="dataroom" changeDirectory={() => {this.props.getOrganisedList({folder_uuid: item.uuid});this.setState({activeFolder: item.uuid})}} popoverOpen={() => {this.togglePopover(item, index, 'organised');this.props.getFoldersToMove(ctrShortInfo.records.technical_records.uuid,{source: item.uuid})}} deleteBatchFile={(file, type) => this.props.deleteBatchFile(file, {folder_uuid: this.state.activeFolder}, type)} listItem={item} index={index}/>)
                          :<NoRecordFound/>
                        }
                      </div>
                    :null
                  }
                  {
                    this.state.activeTab == 1 ?
                      <div>
                        {
                          unorganisedList.files && unorganisedList.files.length ?
                            unorganisedList.files.map((item, index) => <BatchFileList fileIcons={fileIcons} index={index} renameRecord={this.props.renameFile} popoverOpen={() => {this.togglePopover(item, index, 'unorganised');this.props.getFoldersToMove(ctrShortInfo.records.technical_records.uuid,{source: item.uuid})}} deleteBatchFile={this.props.deleteBatchFile} index={index} listItem={item}/>)
                          :<NoRecordFound/>
                        }
                      </div>
                    :null
                  }

                </div>
                {
                  this.state.activeTab == 1 && unorganisedList.files && unorganisedList.files.length ?
                  <Pagination
                    activePage={unorganisedList.pagination.current_page ? parseInt(unorganisedList.pagination.current_page):1}
                    itemsCountPerPage={20}
                    totalItemsCount={unorganisedList.pagination.total}
                    pageRangeDisplayed={10}
                    onChange={(pageNumber) => this.props.getUnorganisedList({page: pageNumber})}
                  />
                  :null
                }
             </div>
             {
               batchLoader ? <ListLoader/> : null
             }
             <Row className="notice-footer">
               <Col md="12">
                 By publishing the above, the organised files will go to Data Room & Action Dock
                 <button className="btn btn-primary" onClick={() => this.setState({publishBatchFlag: true})}>Publish</button>
               </Col>
             </Row>
             {
               this.state.publishBatchFlag ?
               <Modal isOpen={this.state.publishBatchFlag} centered={true} toggle={() => this.setState({publishBatchFlag: false})} className="modal-dialog-centered">
                 <ModalHeader toggle={() => this.setState({publishBatchFlag: false})}>Publish</ModalHeader>
                 <ModalBody>
                   <div className="delete-logs">
                      <h4>After publishing all the unorganised files will be deleted.</h4>
                       <ul className="list-inline">
                       <li className="list-inline-item">
                         <button className="btn btn-danger" onClick={() => {this.setState({publishBatchFlag: false});this.props.publishBatch()}}>Yes</button>
                       </li>
                       <li className="list-inline-item">
                         <button className="btn btn-secondary" onClick={() => this.setState({publishBatchFlag: false})}>No</button>
                       </li>
                     </ul>
                   </div>
                 </ModalBody>
               </Modal>
               :null
             }
             {
               this.state.popoverOpen ?
               <Popover placement="bottom" isOpen={this.state.popoverOpen} target={"info_" + this.state.popoverIndex} toggle={this.togglePopover}>
                 <PopoverHeader className="popover-header">
                   Move To
                   <span className="close-popover" onClick={() =>this.togglePopover()}>X</span>
                 </PopoverHeader>
                 <PopoverBody>
                   <ul className="list-inline">
                     {
                       this.props.popoverLoader ?
                       <img src={imgStoragePath + "wait.gif"} style={{width:'40px', position: 'relative', left: '45%'}}/>:
                       folderDirTree ?
                       folderDirTree.length > 0 && Object.keys(this.state.popoverItem).length ?
                       <li>
                         <img src={imgStoragePath+"popover_back.png"} onClick={() => this.props.getFoldersToMove(folderDirTree[0].uuid,{level: folderDirTree[0].level > 0 ? folderDirTree[0].level - 1 : 0, source: this.state.popoverItem.uuid})}/> Parent Folder
                       </li>
                       :<li>
                         <img src={imgStoragePath+"popover_back.png"} onClick={() => this.props.getFoldersToMove(ctrShortInfo.records.technical_records.uuid,{level: this.state.popoverItem.level > 0 ? this.state.popoverItem.level : 0 , source: this.state.popoverItem.uuid})}/> Parent Folder
                       </li>:null
                     }
                     {
                       folderDirTree ?
                       folderDirTree.length > 0 ?
                       folderDirTree.map((folderItem, index) => <li className={this.state.destination == folderItem.uuid ? 'active' : ''} key={folderItem.uuid}>
                         <img src={imgStoragePath+"folder_icn3.png"}/><span onClick={() => this.setState({destination: folderItem.uuid})}>{folderItem.name}</span>{folderItem.leaf == false ? <img src={imgStoragePath+"move_icon.png"} onClick={() => this.props.getFoldersToMove(folderItem.uuid,{select: folderItem.level, source: this.state.popoverItem.uuid})} className='sub-folder'/>:null}
                       </li>) :<li>--</li>:null
                     }
                   </ul>
                 </PopoverBody>
                 <Separator />
               <Button type="button" disabled={ this.state.destination != '' ? false : true} onClick={() => {this.props.moveRecordToDest({type:"file",move_to:this.state.destination,object_uuid:[this.state.popoverItem.uuid]}, this.state.moveType == 'unorganised' ? {page: unorganisedList.pagination.current_page} : {folder_uuid: this.state.activeFolder}, this.state.moveType);this.togglePopover();}} color="primary" className="add-new">Move Here</Button>
               </Popover>
               :null
             }
        </div>
    )
  }
}
const mapStateToProps = state => ({
    ctrShortInfo: state.ContractListReducer.ctrShortInfo,
    unorganisedList: state.AutoRenamingReducer.unorganisedList,
    organisedList: state.AutoRenamingReducer.organisedList,
    batchLoader: state.AutoRenamingReducer.batchLoader,
    folderDirTree: state.AutoRenamingReducer.folderDirTree,
    batchDetail: state.AutoRenamingReducer.batchDetail,
    techConstants: state.sharedReducers.techConstants,
    notificationOpen:state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return{
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Auto Renaming and Organiser Data View')),
    getUnorganisedList: (filters) => dispatch(getUnorganisedListAc(ownProps.params, filters)),
    getOrganisedList: (filters) => dispatch(getOrganisedListAc(ownProps.params, filters)),
    deleteBatchFile: (record, filters, type) => dispatch(deleteBatchFileAc(ownProps.params, record, filters, type)),
    publishBatch: () => dispatch(publishBatchAc(ownProps.params)),
    getFoldersToMove: (rootId, source) => dispatch(getFoldersToMoveAc(ownProps.params, rootId, source)),
    moveRecordToDest : (source, filters, type) => dispatch(moveRecordToDestAc(ownProps.params, source, filters, type)),
    getBatchDetail: () => dispatch(getBatchDetailAc(ownProps.params)),
    renameFile: (item, filters, type) => dispatch(renameFileAc(ownProps.params, item, filters, type)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    exportUnOrg:(filetype) => dispatch(unOrgExportAc(filetype, ownProps)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RenameAndOrgView);
