import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';

export default class GeneralDetail extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { generalDetail, error, detailErr } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row> 
      <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Inspection Performed From</label>,
              { type: 'edit',
               value: generalDetail.inspection_performed_from,
               error:'',
               updateForm: (value) => this.props.updateForm({ type: 'inspection_performed_from', value: value, sectionType: 'general_details'})
              }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Inspection Performed Till</label>,
              { type: 'edit',
               value: generalDetail.inspection_performed_till,
               error:'',
               updateForm: (value) => this.props.updateForm({ type: 'inspection_performed_till', value: value, sectionType: 'general_details'})
              }
            )
          }
         </Col>
				<Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Report Date</label>,
              { type: 'edit',
               value: generalDetail.report_date,
               error:'',
               updateForm: (value) => this.props.updateForm({ type: 'report_date', value: value, sectionType: 'general_details'})
              }
            )
          }
         </Col>

         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Report Issued Place</label>,
              { type: 'edit',
              value: generalDetail.report_issued_place,
              error: '',
              updateForm: (value) => this.props.updateForm({ type:'report_issued_place', value:value, sectionType: 'general_details'})
              }
            )
          }
         </Col>
      </Row>
    </div>
    );
  }
}
