import React from 'react';
const CtrTableHd = (props) => {
  return(
    <thead>
      <tr>
        {
          props.theads.map((title,index)=> <th className="sticky-background" key={index}>{title}</th>)
        }
      </tr>
    </thead>
  )
}

export default CtrTableHd;
