import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath, displayDateFormat } from '../../../../constants';

export default class ManPowerPopOver extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {
		this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  toggleDelModal = ( ) => {
    this.setState(prevState =>({
      ...prevState,
      delModal: !prevState.delModal,
      modalTitle: 'Are you sure to delete?',
    }));
  }

  render() {
    const {  tagetId, placement, assets } = this.props;
    return (
        <span>
       { assets.length > 0 ?
       <span>
        <img width="12" className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath +'inv_info_icon.png'} alt="img" style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '2px'}}/>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
							<div className="project-tracker-popover">
              { assets.map((assetData,index) =>
							<div className="msn-outer-wrapper">
								<Row className="flex-not-centered engineer-block">
									<Col xs="12" className="	">
                                         <span className="status-title">
                                         <img width="15" src={ imgStoragePath +'project-name.png'} alt="img" style={{ display: 'inline-block', marginRight: '5px'}}/>
                                         {assetData.name  ? assetData.name : null}</span>
									</Col>
									<Col xs="6">
										<div className="start-date-wrapper">
												<span style={{color:'#000'}}>Start Date</span>
												<p>
												{ assetData.start_date ? moment(assetData.start_date).format(displayDateFormat) : '--'}
												</p>
										</div>
									</Col>
									<Col xs="6">
										<div className="end-date-wrapper">
												<span style={{color:'#000'}}>End Date</span>
												<p>
												{assetData.expected_end_date ? moment(assetData.expected_end_date).format(displayDateFormat) : '--'}
												</p>
										</div>
									</Col>
									<Col xs="6">
										<div className="start-date-wrapper">
                       <span style={{color:'#000'}} >Department</span>
												<p>
											  {assetData.department &&assetData.department.label ? assetData.department.label : '--'}
												</p>
										</div>
									</Col>
                  <Col xs="6">
										<div className="end-date-wrapper">
                     <span style={{color:'#000'}} >Location</span>
												<p>
											{assetData.location ? assetData.location : '--'}
												</p>
										</div>
									</Col>
							</Row>
							</div>
							)}
							</div>
            </PopoverBody>
        </Popover>
				</span>

        :  null }




     </span>

    );
  }
}
