import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { GenFinanceRpt, ReviewFinanceRpt, LogListing, CashFlow, MRFinanceGraph, MaintenanceClaims, AirframeAssembly, AirframeView, ApuAssembly, ApuView, EngineAssembly, EngineView, LangingGearAssembly, LgView } from './applications/maintenance';
import { Login, ChangePassword, UserProfile, AssetsListing, AssetView, ForgotPassword, ResetPassword, ResetSuccessfull, SelectLessor, PrivacyPolicy, TermsAndCondition, UsersList, EditUser, UserInviteList } from './User';
import { Listing, HeadLines, MRRate, FinanceDetail, ReturnCondition, LeaseRentalRates, QualifiedMaintenance, DeliveryCondition, InductionCondition, LeaseOption, Insurance, SubLease, Party, Obligations, WorkingGroup, SecurityDeposit, CapeTownConvention, Utilization, AddEditUtils, ContractCashFlow, ContractDashboard, ViewInvoice, EditInvoice, Invoice, MRAccural, MrAccrualCashFlow, HalfLifeAdjustment, DataLoglist, DataLogView } from './applications/contracts';
import { FleetSimulation, FleetAnalysis, FleetView, PortfolioContainer, PortfolioAssetContainer } from './applications/fleetAnalysis';
import { ActionDock, RecordDB, DataRoom, DataBucket, InQueue, RecordResources, FileList, RecycleBin, DataKit, DataRoomDash, RecordReportInfo, MiniKit } from './applications/records';
import {
	ProjectLists,
	ProjectView,
	ProjectCorForm,
	ProjectFeedbackForm,
	CreateTechnicalEngine,
	InspectionFormT001,
	InspectionFormT002,
	InspectionFormT003,
	InspectionFormT013,
	InspectionFormT004,
	InspectionFormT005,
	InspectionFormT006,
	InspectionFormT007,
	InspectionFormT008,
	InspectionFormT009,
	InspectionFormT011,
	InspectionFormTE001,
	InspectionFormTE002,
	InspectionFormTE003,
	InspectionFormTE004,
	InspectionFormTE005,
	InspectionFormTE006,
	ProjectTracker,
	CorTracker,
	ManPowerTracker,
	ProgramScheduler,
	ProgramTracker,
	ReviewT001,
  ReviewT002,
  ReviewT003,
  ReviewT004,
  ReviewT005,
  ReviewT006,
  ReviewT007,
  ReviewT009
 } from './applications/technicalInspection';
import {
	InvestmentManagement,
	ProjectManagement,
	AircraftManagement
} from './staticPages'
import { FleetDashborad, ContractsFleetDb, RecordsFleetDb, MaintenanceFleetDb, InspectionFleetDb } from './applications/dashboards';
import { AircraftTechSummary } from './applications/console';
import { ADReviewLogs, AirworthinessView, EasaDatabaseList, TechSpecsLogs, TechSpecsLogView } from './applications/MlModel';
import { AutoRenamingandOrg, RenameAndOrgView } from './applications/records/AutoRenaming';
import { PageNotFound, NoAccessApps } from './shared';
import Faqs from './shared/components/Faqs';
import FeaturesList from './shared/components/FeaturesList';
import GlobalSearch from './shared/GlobalSearch/containers';
import App from './App';
import FilePreview from './FilePreview';
import TechnicalAssetReport from './TechnicalAssetReport';
import requireAuth from './utils/requireAuth';
import LoggedUserRed from './utils/LoggedUserRed';
import { Onboarding } from './phase2/auth';
import {Pricing} from './phase2/auth';
import { Users, Storage, Inspections, FleetCashflow, Contracts, Maintenance, DataTransfer, MRCalculator, Cart, PaymentMethods } from './phase2/licenses';
import { InvoiceIndex, InvoiceDetail } from './phase2/billing';
import { ConfIndex } from './phase2/ConfigureSettings';
import { StorageListing } from './phase2/storage';
import { Notification } from './phase2/notification';
import { ReportExport } from './applications/reports';
import NewsFeed from './applications/newsFeed/containers/index';
import BlogShow from './applications/newsFeed/containers/Blog';
import SecurityPermissions from './User/containers/SecurityPermissions';
// import ErrorPage from './shared/components/ErrorPage';
import Analytics from './applications/analytics/containers/Analytics';
import Activity from './applications/records/activityLog/containers/Activity';

const store = configureStore();
ReactDOM.render(
	<div>
	<Provider store={store}>
		<Router history={browserHistory}>
			<Route path="/" component={App}>
				<IndexRoute component={requireAuth(FleetDashborad)}/>
				<Route path="/reset-successfully" component={LoggedUserRed(ResetSuccessfull)}/>
				<Route path="/create-successfully" component={LoggedUserRed(ResetSuccessfull)}/>
				<Route path="/password/reset/:token" component={LoggedUserRed(ResetPassword)}/>
				<Route path="/password/create/:token" component={LoggedUserRed(ResetPassword)}/>
				<Route path="/signup" component={LoggedUserRed(Onboarding)} />
				<Route path="/otp-verification/:token" component={LoggedUserRed(Onboarding)} />
				<Route path="/login" component={LoggedUserRed(Onboarding)} />
				<Route path="/termscondition" component={LoggedUserRed(Pricing)} />
				<Route path="/data-policy" component={LoggedUserRed(Pricing)} />
				<Route path="/select-lessor" component={requireAuth(Onboarding)} />
				<Route path="/forgot-password" component={LoggedUserRed(Onboarding)} />
				<Route path="/user/activate/:token" component={LoggedUserRed(Onboarding)} />
				<Route path="/pricing" component={Pricing} />
				<Route path="/change-password" component={requireAuth(ChangePassword)} />
				<Route path="/user-profile" component={requireAuth(UserProfile)} />
				<Route path="/privacy-policy" component={PrivacyPolicy}/>
				<Route path="/terms" component={TermsAndCondition}/>
				<Route path="/assets-listing" component={requireAuth(AssetsListing)} />
				<Route path="/mr-calculator" component={requireAuth(GenFinanceRpt)}/>
				<Route path="/asset-view/:type/:aircraft_slug(/:action)" component={requireAuth(AssetView)} />
				<Route path="/review-finance/:id" component={requireAuth(ReviewFinanceRpt)} />
				<Route path="/log/:id" component={requireAuth(ReviewFinanceRpt)} />
				<Route path="/logs" component={requireAuth(LogListing)} />
				<Route path="/financial-plots/:id" component={requireAuth(MRFinanceGraph)} />
				<Route path="/mr-calculator/cash-flow/:id" component={requireAuth(CashFlow)}/>
				<Route path="/:type/:aircraft_slug/maintenance/claims" component={requireAuth(MaintenanceClaims)} />
				<Route path="/:type/:aircraft_slug/maintenance/airframe" component={requireAuth(AirframeAssembly)} />
				<Route path="/:type/:aircraft_slug/maintenance/airframe/view/:id" component={requireAuth(AirframeView)} />
				<Route path="/:type/:aircraft_slug/maintenance/apu" component={requireAuth(ApuAssembly)} />
				<Route path="/:type/:aircraft_slug/maintenance/apu/view/:id" component={requireAuth(ApuView)} />
				<Route path="/:type/:aircraft_slug/maintenance/landing-gears" component={requireAuth(LangingGearAssembly)} />
				<Route path="/:type/:aircraft_slug/maintenance/landing-gears/view/:id" component={requireAuth(LgView)} />
				<Route path="/:type/:aircraft_slug/maintenance/engines" component={requireAuth(EngineAssembly)} />
				<Route path="/:type/:aircraft_slug/maintenance/engine/view/:id" component={requireAuth(EngineView)} />
				<Route path='/:type/:aircraft_slug/contracts' component={requireAuth(Listing)}/>
				<Route path='/:type/:aircraft_slug/contract/list/:contract_slug' component={requireAuth(HeadLines)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/working-group' component={requireAuth(WorkingGroup)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/parties' component={requireAuth(Party)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/obligations' component={requireAuth(Obligations)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/sub-lease' component={requireAuth(SubLease)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/insurance' component={requireAuth(Insurance)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/security-deposit' component={requireAuth(SecurityDeposit)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/cape-town-convention' component={requireAuth(CapeTownConvention)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/lease-option' component={requireAuth(LeaseOption)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/induction-condition' component={requireAuth(InductionCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/delivery-condition' component={requireAuth(DeliveryCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/return-condition' component={requireAuth(ReturnCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/mr-rate' component={requireAuth(MRRate)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/finance-detail' component={requireAuth(FinanceDetail)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/lease-rental-condition' component={requireAuth(LeaseRentalRates)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/qualified-maintenance' component={requireAuth(QualifiedMaintenance)}/>
				<Route path='/:type/:aircraft_slug/contract/half-life-maintenance-adjustments' component={requireAuth(HalfLifeAdjustment)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/extract-file-list' component={requireAuth(DataLoglist)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/extract-file/:id' component={requireAuth(DataLogView)} />
				<Route path='/:type/:aircraft_slug/contract/utilization' component={requireAuth(Utilization)} />
				<Route path='/:type/:aircraft_slug/contract/utilization/add' component={requireAuth(AddEditUtils)} />
				<Route path='/:type/:aircraft_slug/contract/cash-flow' component={requireAuth(ContractCashFlow)}/>
				<Route path='/:type/:aircraft_slug/contract/mr-accrual' component={requireAuth(MRAccural)}/>
				<Route path='/:type/:aircraft_slug/contract/mr-accrual-cash-flow' component={MrAccrualCashFlow} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice/edit/:id' component={requireAuth(EditInvoice)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice/view/:id' component={requireAuth(ViewInvoice)}/>
		 		<Route path='/:type/:aircraft_slug/contract/dashboard' component={requireAuth(ContractDashboard)}/>
				<Route path='/technical-specification/:type/:aircraft_slug' component={requireAuth(AircraftTechSummary)}/>
				<Route path='/:type/:aircraft_slug/contract/invoice' component={requireAuth(Invoice)}/>
				{
					//removed on permanent basis, but code is stayed for future references
					// <Route path='/fleet-cashflow/portfolios/' component={requireAuth(FleetAnalysis)}/>
					// <Route path='/fleet-cashflow/portfolio/:slug' component={requireAuth(PortfolioContainer)}/>
					// <Route path='/fleet-cashflow/portfolios/:slug/add/asset' component={requireAuth(PortfolioAssetContainer)}/>
					// <Route path='/fleet-cashflow/portfolios/:slug/simulation' component={requireAuth(FleetSimulation)}/>
					// <Route path='/fleet-cashflow/:assetType/:assetSlug/portfolio/:portfolio' component={requireAuth(FleetView)}/>
				}
				<Route path="/restricted-access/:type" component={requireAuth(NoAccessApps)} />
				<Route path="/:type/:aircraft_slug/records/dashboard" component={requireAuth(RecordDB)}/>
				<Route path="/:type/:aircraft_slug/records/resources" component={requireAuth(RecordResources)}/>
				<Route path="/:type/:aircraft_slug/records/data-room/:application/:id" component={requireAuth(DataRoom)}/>
				<Route path="/:type/:aircraft_slug/records/report" component={requireAuth(RecordReportInfo)}/>
				<Route path="/:type/:aircraft_slug/records/dataRoom" component={requireAuth(DataRoomDash)}/>
				<Route path="/:type/:aircraft_slug/records/bucket" component={requireAuth(DataBucket)}/>
				<Route path="/:type/:aircraft_slug/records/data-room/files" component={requireAuth(FileList)}/>
				<Route path="/:type/:aircraft_slug/records/action-dock" component={requireAuth(ActionDock)}/>
				<Route path="/:type/:aircraft_slug/records/recycle-bin" component={requireAuth(RecycleBin)}/>
				<Route path="/:type/:aircraft_slug/records/activity-log" component={requireAuth(Activity)}/>
				<Route path="/:type/:aircraft_slug/records/resources/data-kit/:id" component={requireAuth(DataKit)}/>
				<Route path="/:type/:aircraft_slug/records/resources/mini-pack/" component={requireAuth(MiniKit)}/>
				<Route path="/:type/:aircraft_slug/records/in-queue" component={requireAuth(InQueue)}/>
				<Route path="/:type/:aircraft_slug/records/auto-organiser-and-renaming" component={requireAuth(AutoRenamingandOrg)} />
				<Route path="/:type/:aircraft_slug/records/auto-organiser-and-renaming/view/:batchID" component={requireAuth(RenameAndOrgView)} />
				<Route path="/:type/:aircraft_slug/search/" component={requireAuth(GlobalSearch)}/>
				<Route path="/technical/projects" component={requireAuth(ProjectLists)} />
				<Route path="/technical/project/view/:project_slug" component={requireAuth(ProjectView)} />
				<Route path="/technical/project/create-engine/:project_slug" component={requireAuth(CreateTechnicalEngine)}/>
				<Route path="/technical/project/:project_slug/engine/edit/:workOrderSlug" component={requireAuth(CreateTechnicalEngine)}/>
				<Route path="/technical/project/view/:project_slug/project-cor-form" component={requireAuth(ProjectCorForm)} />
				<Route path="/technical/project/view/:project_slug/project-feedback-form" component={requireAuth(ProjectFeedbackForm)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t001" component={requireAuth(InspectionFormT001)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t002" component={requireAuth(InspectionFormT002)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t003" component={requireAuth(InspectionFormT003)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t013" component={requireAuth(InspectionFormT013)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t004" component={requireAuth(InspectionFormT004)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t005" component={requireAuth(InspectionFormT005)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t006" component={requireAuth(InspectionFormT006)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t007" component={requireAuth(InspectionFormT007)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t008" component={requireAuth(InspectionFormT008)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t009" component={requireAuth(InspectionFormT009)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t011(/:tab_name)" component={requireAuth(InspectionFormT011)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te001" component={requireAuth(InspectionFormTE001)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te002" component={requireAuth(InspectionFormTE002)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te003" component={requireAuth(InspectionFormTE003)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te004" component={requireAuth(InspectionFormTE004)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te005" component={requireAuth(InspectionFormTE005)} />
				<Route path="/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te006" component={requireAuth(InspectionFormTE006)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t001" component={requireAuth(ReviewT001)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t002" component={requireAuth(ReviewT002)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t003" component={requireAuth(ReviewT003)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t004" component={requireAuth(ReviewT004)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t005" component={requireAuth(ReviewT005)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t006" component={requireAuth(ReviewT006)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t007" component={requireAuth(ReviewT007)} />
				<Route path="/:type/:aircraft_slug/technical/review/:workOrderSlug/forms/:formSlug/t009" component={requireAuth(ReviewT009)} />
				<Route path="/technical/projects/tracker" component={requireAuth(ProjectTracker)} />
				<Route path="/technical/cor/tracker" component={requireAuth(CorTracker)} />
				<Route path="/technical/man-power/tracker" component={requireAuth(ManPowerTracker)} />
				<Route path="/technical/program-planner" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/create/program" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/assets" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/assets/program/:programId" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/program/:programId" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/program/:programId/details" component={requireAuth(ProgramScheduler)} />
				<Route path="/technical/program-planner/program/:programId/tracker" component={requireAuth(ProgramTracker)} />
				<Route path="/fleet/dashboard" component={requireAuth(FleetDashborad)} />
				<Route path="/fleet/records/dashboard" component={requireAuth(RecordsFleetDb)} />
				<Route path="/fleet/maintenance/dashboard" component={requireAuth(MaintenanceFleetDb)} />
				<Route path="/fleet/technical/dashboard" component={requireAuth(InspectionFleetDb)} />
				<Route path="/fleet/contracts/dashboard" component={requireAuth(ContractsFleetDb)} />
				<Route path="/users/manage/active" component={requireAuth(UsersList)} />
				<Route path="/users/manage/inactive" component={requireAuth(UsersList)} />
				<Route path="/users/manage/active/:id" component={requireAuth(EditUser)} />
				<Route path="/users/invites/" component={requireAuth(UserInviteList)} />
				<Route path="/users/permissions" component={requireAuth(SecurityPermissions)} />
				<Route path="/:type/:aircraft_slug/airworthiness-list" component={requireAuth(ADReviewLogs)} />
				<Route path="/:type/:aircraft_slug/techspecs-logs" component={requireAuth(TechSpecsLogs)} />
				<Route path="/:type/:aircraft_slug/easa-list" component={requireAuth(EasaDatabaseList)} />
				<Route path="/:type/:aircraft_slug/airworthiness-detail/:id" component={requireAuth(AirworthinessView)} />
				<Route path="/:type/:aircraft_slug/techspecs-logs/:id" component={requireAuth(TechSpecsLogView)} />
				<Route path="/license" component={requireAuth(Users)} />
				<Route path="/licenses/:id" component={requireAuth(Users)} />
				<Route path="/license/cart" component={requireAuth(Cart)} />
				<Route path="/billing/invoices" component={requireAuth(InvoiceIndex)} />
				<Route path="/configure" component={requireAuth(ConfIndex)} />
				<Route path="/billing/payment-methods" component={requireAuth(PaymentMethods)} />
				<Route path="/billing/invoice/detail/:id" component={requireAuth(InvoiceDetail)} />
				<Route path="/storage-listing" component={requireAuth(StorageListing)} />
				<Route path="/notifications" component={requireAuth(Notification)} />
				<Route path="/export/reports" component={requireAuth(ReportExport)} />
				<Route path="/newsfeed" component={requireAuth(NewsFeed)} />
				<Route path="/newsfeed/:slug" component={requireAuth(BlogShow)} />
				<Route path="/faqs" component={requireAuth(Faqs)} />
				<Route path="/whats-new" component={requireAuth(FeaturesList)} />
				<Route path="/analytics" component={requireAuth(Analytics)} />
				{/* <Route path="/activity-log" component={requireAuth(Activity)} /> */}

			</Route>
			<Route path="/banking" component={InvestmentManagement} />
			<Route path="/engineering" component={ProjectManagement} />
			{/* <Route path="/error" component={ErrorPage} /> */}
			<Route path="/aviation" component={AircraftManagement} />
			<Router path="/techical/asset/report/:shareKey" component={TechnicalAssetReport}/>
			<Router path="/records/view-shared/:key" component={FilePreview}/>
			<Router path="/records/view-shared-items/:uuid" component={FilePreview}/>
			<Route path='*' exact={true} component={PageNotFound} />
		</Router>
	</Provider></div>,
	document.querySelector('.root')
);
