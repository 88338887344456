import React from 'react';
import { Link } from 'react-router';
import { getLocalStorageInfo } from '../../utils';
import { imgStoragePath} from '../../constants';
import {UncontrolledTooltip} from 'reactstrap';

const GroupList = (props) =>{
  return(
    <li>
       { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user &&
         getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 ?
         <input checked={props.deleteObject.indexOf(props.userGroup.id) != -1 ? 'checked' : ''} type="checkbox" onClick={() => props.addUserGroup(props.userGroup)}/>:null
       }
      {props.userGroup.logo ?
         <img src={props.userGroup.logo} alt={props.userGroup.name} />
         :  null

       }
      {props.userGroup.name  == 'Global Projects Tracker'  ? <span >{props.userGroup.name}<span id="global" > <img src={imgStoragePath+"caution_sign.png"} alt="caution" style={{height: "20px", marginLeft:"5px"}}/><UncontrolledTooltip placement="right" target="global">Only for Acumen Users</UncontrolledTooltip></span></span> : props.userGroup.name  == 'Project Lead - Acumen' ? <span>{props.userGroup.name }<span id="acumen"><img src={imgStoragePath+"caution_sign.png"} alt="caution" style={{height: "20px", marginLeft:"5px"}}/><UncontrolledTooltip placement="right" target="acumen">Only for Acumen Users</UncontrolledTooltip></span></span>:props.userGroup.name == "Project Engineer - Acumen"? <span>{props.userGroup.name} <span id="engineer"><img src={imgStoragePath+"caution_sign.png"} alt="caution" style={{height: "20px", marginLeft:"5px"}}/><UncontrolledTooltip placement="right" target="engineer">Only for Acumen Users</UncontrolledTooltip></span> </span>:props.userGroup.name }
      {getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user &&
        getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 ?
      <Link className="delete-link delete-group" onClick={() => props.deleleGroup('Delete Group - "' + props.userGroup.name+'"', 'group', props.userGroup.id)}>Delete</Link>:null
      }
   </li>
  )
}
export default GroupList;
