import React from 'react';
class TextInputField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value
    })
  }
  componentWillReceiveProps(nextProps){

    if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value
      })
    }
  }
  updateForm = (value) => {
    this.setState({
      value: value,
     // error: ''
    });
    // if(!delay){
    //   this.props.updateForm(value);
    // }
    if(this.props.typingOnchange!=null && this.props.typingOnchange){
      this.props.updateForm(value);
    }
  }
  onBlur = () => {
    if(this.props.typingOnchange == null){
    this.props.updateForm(this.state.value);
    }
  }
  onFocus=()=>{
    if(this.props.focusOnchange != null){
      this.props.updateForm(this.state.value);
      }
  }
  render(){
    const { type, isDisabled } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
      <div>
        <div className="form-group-edit">
          {/* <label className="label">{this.props.children}</label> */}
          {this.props.children}
          { type == 'view' ? <h6>{this.props.value ? this.props.value:this.props.value===0?0:'--'}</h6> :
            <input
              type="text"
              placeholder= {this.props.placeholder}
              value={this.state.value?this.state.value:this.state.value===0?0:''}
              onChange={(evt) => this.updateForm(evt.target.value)}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              disabled = {isDisabled}
              pattern={this.props.pattern}
            />
          }
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default TextInputField;
