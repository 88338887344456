import React, { Component, Fragment } from 'react';
import moment from 'moment';
import {Col, Row, Card, Button, CardTitle, CardText, FormGroup, Label, Input, Badge} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, withRouter, browserHistory } from 'react-router';
import { imgStoragePath, fieldDateFormat, backendDateFormat } from '../../../constants';
import { ListLoader, NoRecordFound } from '../../../shared';
import { FieldCol, FieldGroup, FieldLabel } from '../../contracts/Elements';
import Select from 'react-select';
import { getCategoriesApi, getTagsApi, getNewsFeedApi } from '../apiServices';
import '../assets/newsfeed.scss';
import NewsFeedList from '../components/NewsFeedList';
import Tag from '../components/Tag';
import SelectedTag from '../components/SelectedTag'
import { toastFlashMessage } from '../../../utils';
class NewsFeed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories:[],
      newsFeedLoader:false,
      filter:{
        category:null,
        tag:null,
        search:'',
        days:null,
        sort:null,
        sort_by:'title',
        page:1,
      },
      tags:[],
      newsFeedInfo:{
        list:[],
        pagination:{
          total:'',
          number_of_pages:"",
          per_page:12,
          current_page:1
        }
      }
    }
    this.getCategoriesApi = getCategoriesApi.bind(this);
    this.getTagsApi = getTagsApi.bind(this);
    this.getNewsFeedApi = getNewsFeedApi.bind(this);
  }
  componentDidMount () {
    let location = browserHistory.getCurrentLocation();
    this.getCategoriesApi();
    this.getTagsApi();
    this.getNewsFeedApi({...location.query, page:1});
  }
  onFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      filter:{
        ...prevState.filter,
        [keyParam]:value
      }
    }))
  }
  clearFilter = () => {
    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        search:'',
        from_date:null,
        sort:null,
        sort_by:null
      }
    }))
  }
  moveNextPage = () =>{
    const { filter, newsFeedInfo } = this.state;
    this.setState(prevState => ({
      ...prevState.filter,
      filter: {
        ...prevState.filter,
        page:prevState.filter.page + 1
      },
      newsFeedInfo : {
        ...prevState.newsFeedInfo,
        pagination:{
          ...prevState.pagination,
          current_page:prevState.newsFeedInfo.pagination.current_page + 1,
          per_page:12
        }
      }
    }))
    this.getNewsFeedApi({...filter, page: filter.page+1}, 'loadmore');
  }
  render() {
    const { filter, categories, tags, newsFeedInfo, newsFeedLoader } = this.state;
    return(
      <div className="report-export-lists" style={{marginLeft: '70px', paddingTop: '30px'}}>
        <Row className="report-export-title">
          <Col md="6">
            <h4>News Feed</h4>
            <p>Share your thoughts</p>
          </Col>
        </Row>
        <div>
          <Row>
            <Col md="10">
              <ul className="list-inline tab-list">
                <li className={`list-inline-item ${filter.category ? 'tab':' tab-active'}`} onClick={() => {this.getNewsFeedApi({...filter, page:1, category:null})}}>All</li>
                { categories.map((category, index) =>
                  <li  key={index} className={`list-inline-item ${category.id == filter.category ? 'tab-active':'tab'}`} onClick={() => {this.getNewsFeedApi({...filter, page:1, category: category.id})}}>{category.name}</li>
                )}
              </ul>
              <Row>
                <Col md="12">
                  <ul className="list-inline filter-section">
                    <li className="list-inline-item search-input ">
                      <Input
                        type="text"
                        placeholder="Title, Description or Content"
                        value={filter.search ? filter.search:''}
                        onChange={(e) => this.onFieldChange('search', e.target.value ? e.target.value:null)}
                      />
                    </li>
                    <li className="list-inline-item dropdown-filter">
                      <Select
                        options={[
                          {value:'7',label:'Last 7 Days'},
                          {value:'30',label:'Last 1 Month'},
                          {value:'90',label:'Last 3 Month'},
                          {value:'365',label:'Last 1 Year'}
                        ]}
                        onChange = {(value) => this.onFieldChange('days', value ? value.value :value)}
                        placeholder= {<p style={{fontSize:'12px'}}>Filter by Date</p>}
                        value={filter.days ? filter.days:null}
                        style={{fontSize:"12px !important"}}
                      />
                    </li>
                    <li className="list-inline-item dropdown-filter">
                      <Select
                        options={[
                          {value:'asc',label:'A-Z'},
                          {value:'dsc',label:'Z-A'}
                        ]}
                        onChange = {(value) => this.onFieldChange('sort_by', value ? value.value :value)}
                        placeholder= 'Sort by Title'
                        value={filter.sort_by ? filter.sort_by:null}
                        style={{fontSize:'12px !important'}}
                      />
                    </li>
                    {
                     filter.search != null || filter.days != null || filter.sort_by != null   ?
                    <li className="list-inline-item">
                      <Button onClick={() => this.getNewsFeedApi(filter)} className="btn btn-primary btn-sm filter-button" color="primary" type="button">Search</Button>
                      <Button onClick={() => this.getNewsFeedApi({...filter, search:'', sort:'', days:'', sort_by:''})} className="btn btn-primary btn-sm filter-button" color="primary" type="button">Clear Search</Button>
                    </li> : null
                    }
                  </ul>
                </Col>
              </Row>
              <InfiniteScroll
                dataLength={newsFeedInfo.list ? newsFeedInfo.list.length : 0} //This is important field to render the next data
                next={() => this.moveNextPage()}
                hasMore={newsFeedInfo.list && newsFeedInfo.pagination && newsFeedInfo.list.length < newsFeedInfo.pagination.total}
                className="scrollbar-hidden"
                endMessage={
                  this.state.newsFeedInfo.pagination.current_page > 1 ?
                  <p style={{textAlign:'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                  :null
                }
              >
              <Row style={{marginTop:"20px"}}>
                {  newsFeedLoader && filter.page == 1 ? <ListLoader /> :  newsFeedInfo.list.length  ? newsFeedInfo.list.map((feed, index) =>
                  <NewsFeedList feed={feed}/>
                ) : <NoRecordFound style={{width:"100%" ,height:"80vh"}} /> }
              </Row>
              </InfiniteScroll>
              { newsFeedInfo.pagination && newsFeedInfo.pagination.current_page && filter.page < newsFeedInfo.pagination.number_of_pages  ?
                  <div style={{textAlign: 'center'}}>
                  </div>
                  :null
              }
            </Col>
            { tags.length ?
              <Col md="2">
                <h5 style={{fontSize:"15px"}}>All Tags</h5>
                <ul className="list-inline" style={{margin:"10px 0px 0px -5px"}}>
                  {(() => {
                    let allTags = filter.tag ? tags.filter(item => !filter.tag.toString().split(',').includes((item.id).toString())) : tags;
                    return allTags.map((tag, index) =>
                      <Tag tag={tag} handleTag={(e)=> this.getNewsFeedApi({...filter, page:1, tag: filter.tag ? filter.tag+','+tag.id:tag.id})}  />
                    )
                  })()}
                </ul>
                { filter.tag ?
                  <Fragment>
                    <hr />
                    <h5 style={{fontSize:"15px", marginTop:"12px"}}>Selected Tags</h5>
                    <ul className="list-inline" style={{margin:"10px 0px 0px -2px"}}>
                      {
                        tags.filter(item => filter.tag.toString().split(',').includes((item.id).toString())).map((tag, index) =>
                          <SelectedTag tag={tag} handleSelectedTag={(e) =>this.getNewsFeedApi({ ...filter, page:1, tag:filter.tag.toString().split(',').filter(item => item !== tag.id.toString()).join(',')  })} />
                        )
                      }
                    </ul>
                  </Fragment>:null
                }
              </Col>:null
            }
          </Row>
        </div>
      </div>
    )
  }
}

export default NewsFeed
