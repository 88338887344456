import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import  MonthAndDateHeader from '../components/MonthAndDateHeader';
import CommentsEdit from '../components/CommentsEdit';
import AssetDetail from '../components/AssetDetail';
import { browserHistory } from 'react-router';
import TableFilter from '../../../../shared/TableFilter';
import { DeleteModal, EditFormBar,GetInputField } from'../../Elements';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import {fetchProgramTrackerAc,updateCommentAc,deleteCommentAc,fetchFIlterListAc,trackerExportAc} from '../actionCreators';
import { removeEmptyKey, getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
import { TableUIComp, ToolTipHover, ExportFiles, ListLoader, NoRecordFound } from '../../../../shared';
class ProgramTracker extends Component {
  constructor(props){
    super(props);
    this.state = {
        months: [],
        dates:[],
        positionHeader: 0,
        colWidth : 80,
        lhWidth: 0,
        windows:false,
        height: 0,
        appliedFilter: {},
        commentID:'',
        toggleComments:false,
        delModal:false,
        typeFlag:'',
        projectSlug:'',
        assetId:'',
        appliedFilter: {},
        queryString: {},
        page:1,
        currMonth:'',
    }
  }

  toggleDelModal=(id)=>{
    this.setState({
        delModal: !this.state.delModal,
        commentID: id
      });
  }

  updateComments=(value)=>{
    this.props.updateComment(this.state.assetId,value,'tracker');
  }

  deletePlanner=()=>{
    this.props.deleteComment(this.state.assetId,this.state.commentID,'tracker');
    this.toggleDelModal('')
}

  componentDidMount(){
    this.props.fetchTrackerDetails();
    let lhWidth = document.body.contains(document.getElementById('lh-project-list')) ? document.getElementById('lh-project-list').clientHeight : 0 ;
    this.setState({
        lhWidth: lhWidth
    })
    document.querySelector('body').style.overflow = 'hidden';
    this.setState({
    height : window.innerHeight - (41 + 78 + 112)
        });
        if(navigator.appVersion.indexOf("Win")!=-1){
    this.setState({
        windows : true
    });
    }
    this.generateMonth()
   }


    getDates = (monthData) => {
        var names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
            var date = new Date(monthData.year, monthData.month - 1, 1);
            var result = [];
            while (date.getMonth() == monthData.month - 1) {
                if((date.getMonth()+1) < 10){
                  if(date.getDate() < 10){
                    result.push(date.getFullYear()+'-0'+(date.getMonth()+1)+'-0'+date.getDate());
                  }else{
                    result.push(date.getFullYear()+'-0'+(date.getMonth()+1)+'-'+date.getDate());
                  }
                }else{
                  result.push(date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate());
                }
                date.setDate(date.getDate() + 1);
            }
            this.setState(prevState => ({
                ...prevState,
                dates:result
           }))
    }


  getProjectSlots = (csd, ced, psd, ped) => {
    /*
    PSD => Proejct Start Date
    PED => Proejct End Date
    CSD => Calendar Start Date
    CED => Calendar End Date
    */
    let initialSpace= 0;
    let finalSpace= 0;
    let duration = 0;
    let dataObj = {}
    if (( (moment(psd) == moment(csd)) && (moment(psd) == moment(ced)) )) {
        duration = moment.duration(moment(psd).diff(moment(ped)))
        initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
        finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
        dataObj = { duration: (duration.days()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
        
       return dataObj;
    }else if((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))){
         duration = moment.duration(moment(ped).diff(moment(psd)))
         initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
         finalSpace = moment.duration(moment(ced).diff(moment(ped))) ;
         dataObj = { duration: (duration.days() + 1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
         
         return dataObj;
    }else if ((moment(psd) <= moment(csd)) && (moment(ped) <= moment(ced) )) {
         duration = moment.duration(moment(ped).diff(moment(csd)))
         initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
         finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
         dataObj = { duration: (duration.days()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
         return dataObj;
    }else if ((moment(psd) <= moment(csd)) && (moment(ped) >= moment(ced) )) {
         duration = moment.duration(moment(ced).diff(moment(csd)))
         initialSpace = moment.duration(moment(csd).diff(moment(csd))) ;
         finalSpace = moment.duration(moment(ced).diff(moment(ced))) ;
         dataObj = { duration: (duration.days()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
         
         return dataObj;
    }else if (( (moment(psd) >= moment(csd)) && (moment(ped) >= moment(ced)) )) {
        duration = moment.duration(moment(ced).diff(moment(psd)))
         initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
         finalSpace = moment.duration(moment(ced).diff(moment(ced))) ;
         dataObj = { duration: (duration.days()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
         
         return dataObj;
    }
    else if ((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))) {
        duration = moment.duration(moment(ped).diff(moment(csd)))
        initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
        finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
        dataObj = { duration: (duration.days()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
        
        return dataObj;
    }
    else{
        return null
    }
  }

  toggleComments=(id,flag)=>{
    this.setState({
        toggleComments: !this.state.toggleComments,
        assetId:id,
        typeFlag:flag,
      });
  }

  monthcheck=(flag)=>{
   if(flag=='up'){
    if(moment(this.state.currMonth).format('MMM')=='Dec'){
        return;
    }
    this.getDates({month: moment(this.state.currMonth).add(1, 'months').format('M'), year: moment().format('YYYY')})
    this.setState(prevState => ({
        ...prevState,
     months:[{month: moment(this.state.currMonth).add(1, 'months').format('MMMM'), year: moment().format('YYYY')}],
     currMonth:moment(this.state.currMonth).add(1, 'months')
   }))

   }else{
    if(moment(this.state.currMonth).format('MMM')=='Jan'){
      return;
    }
    this.getDates({month: moment(this.state.currMonth).subtract(1, 'months').format('M'), year: moment().format('YYYY')})
    this.setState(prevState => ({
        ...prevState,
     months:[{month: moment(this.state.currMonth).subtract(1, 'months').format('MMMM'), year: moment().format('YYYY')}],
     currMonth:moment(this.state.currMonth).subtract(1, 'months')
   }))

   }
  }

  componentWillUnmount(){
     document.querySelector('body').style.overflow = 'auto';
  }

  generateMonth = () => {
     this.setState(prevState => ({
        ...prevState,
     months:[{month: moment().format('MMMM'), year: moment().format('YYYY')}],
     currMonth:moment()
   }))
   this.getDates({month: moment().format('M'), year: moment().format('YYYY')})
  }

  changeFilterFn=(filter)=>{
    this.props.fetchTrackerDetails(filter);
   }

checkDates=(date1,date2)=>{
    if(date2!=''){
      if(moment(date1).diff(moment(date2), 'days') < 0 ){
        return date1 ? date1 : date2
      }else{
        return date2 ? date2 : date1
      }
    }else{
        return date1 ? date1 : date2
    }
}

finalDate=(data)=>{
    let date =data.closure_date?data.closure_date:
    data.actual_submission_date?data.actual_submission_date:
    data.submission_due_date?data.submission_due_date:
    data.inspection_end_date?data.inspection_end_date:
    data.inspection_start_date?data.inspection_start_date:
    data.scheduled_date?data.scheduled_date:null;
return date
}
exportTracker=(name,type)=>{

    this.props.exportTracker(name,{download:type,date:moment(this.state.currMonth).format('YYYY-MM-01')});
}


  render(){
   const {programTrackerList}=this.props;

   let menuList = {
    'Search MSN/ESN':{
      'inputType': 'text',
      'keyParam': 'search',
      'placeholder': 'Search for MSN/ESN',
      'label':'MSN/ESN'
    },
    'Risk Category': {
        'inputType': 'dropdown',
        'keyParam': 'risk_category',
        'placeholder': 'Select Category',
        'options': this.props.techConstants? this.props.techConstants.filter(item => item.type == 'risk_level'):[],
        'labelKey': 'label',
        'valueKey': 'value',
        'label': 'Category'
      },
      'Quarter': {
        'inputType': 'dropdown',
        'keyParam': 'quarter',
        'placeholder': 'Select Quarter',
        'options': [{label:'Q1',value:'Q1'},{label:'Q2',value:'Q2'},{label:'Q3',value:'Q3'},{label:'Q4',value:'Q4'}],
        'labelKey': 'label',
        'valueKey': 'value',
        'label': 'Qaurter'
      },
      'Lessee Name': {
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'placeholder': 'Select Lessee Name',
        'options': this.props.filterLesseList?this.props.filterLesseList:[],
        'labelKey': 'label',
        'valueKey': 'label',
        'label': 'Lessee Name'
      },
      'Asset Type': {
        'inputType': 'dropdown',
        'keyParam': 'asset_type',
        'placeholder': 'Select Asset Type',
        'options': [{label:'Aircraft',value:'1'},{label:'Engine',value:'2'}],
        'labelKey': 'label',
        'valueKey': 'value',
        'label': 'Asset Type'
      },
  };
    return(
      <div className="program-planner-block">
      <div style={{margin:'20px',padding:'10px',background:'#fff',display:'none'}}>
      <Row>
        <Col md="3">
                {
                  GetInputField(
                    'dropdown',
                    null,
                    { type: 'edit',
                    value: '2019',
                    options: [{label:'2019'},{label:'2018'}],
                    labelKey: 'label',
                    valueKey: 'label',
                    updateForm: (value) => this.updateForm('name', value)
                    }
                  )
                }
              </Col>
        </Row>
    </div>
      <div>
        <ul className="list-inline project-heading">
          <li className="list-inline-item" onClick={() => browserHistory.push('/technical/program-planner/program/'+this.props.params.programId+'/details')}>
           <Link>SCHEDULER</Link>
           </li>
          <li className="list-inline-item">
           <Link className="active">TRACKER</Link>
           </li>
        </ul>
      </div>
      <div>
        <div className="add-month-type">
        <ul className="month-view">
        <li>Month in View</li>
        <li >
        <a>
        <span onClick={()=>this.monthcheck('down')}>
        <i class="fa fa-angle-left"></i>
        </span>
        </a>
        <span style={{padding:'7px 30px'}}>{moment(this.state.currMonth).format('MMM, YYYY')}</span>
         <a>
        <span onClick={()=>this.monthcheck('up')}>
         <i class="fa fa-angle-right"></i></span></a></li>
        </ul>

        </div>
        <div className="planner-tracker">
        <Row>
            <Col md="6" >
        <TableFilter
                clearFilterValue={this.clearFilterValue}
                filter={{menuList: menuList, appliedFilter: {}}}
                changeFilterFn={this.changeFilterFn}
                queryParamsEnabled={false}
             />
             </Col>
             <Col md="6">
        <div className="add-bulk-operations" style={{margin:'0px',padding:'0px',float:'right',border:'none'}}>
         <ul className="list-inline flex-centered">
            <li className="list-inline-item operation-cta">
            <ExportFiles exportFile={(fileType) => this.exportTracker('program-tracker',fileType)} files={[{img:'xls_icon.png', label: 'XLS', type:'xls'}]} />
          </li>
        </ul>
        </div>
        </Col>
        </Row>
        </div>
        <div className="flex-not-centered project-tracker-block header-sep-block">
            <div className="inner-width-block">
                <div className="row-header flex-not-centered" style={{ height: '74px'}}>
                    <div id="lh-project-list" className="flex-not-centered spacing-cls project-heading-block text-right" style={{ flexFlowL: 'nowrap'}}>
                        <div className="innerheading-block flex-centered" style={{ width:'242.5px', flexBasis: '242.5px'}}>
                        <p className="para-ui ">Asset List</p>
                        </div>
                    </div>
                    <div style={{ width: (window.innerWidth - 408+242) + 'px', overflow: 'hidden' }}>
                        <MonthAndDateHeader
                            months ={this.state.months}
                            DateList = {this.state.dates}
                            colWidth = {this.state.colWidth}
                            lhWidth = {this.state.lhWidth}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div>
        {this.props.commonLoader?<ListLoader/>:null}
        {this.props.statusLoader?<ListLoader/>:
        <div style={{ height: this.state.height - 70 + 'px', overflow:'auto', overflow: 'auto', marginBottom: '60px' }} className="scrollbar-hidden">
        {programTrackerList && programTrackerList.assets && programTrackerList.assets.length ? null : <NoRecordFound/> }
                <div className="flex-not-centered project-range-block">
                    <AssetDetail
                        toggleComments={this.toggleComments}
                        programTrackerList = {programTrackerList.assets}
                    />
                  <div style={{ width: (window.innerWidth - (this.state.windows?640-242:610-242)) + 'px', overflow: 'auto'}}>
                        <div className="inner-width-block" style={{width:  (window.innerWidth -  (this.state.windows?640+242:610+242)) + 'px'}}>
                            {programTrackerList.assets && programTrackerList.assets.length ?
                                programTrackerList.assets.map((project, index) => {
                                let projectDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], this.checkDates(project.planned_date,project.operator_contacted_at?project.operator_contacted_at:''),this.finalDate(project))
                                let projectIntialDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.planned_date, project.planned_date)
                                let projectContactDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.operator_contacted_at, project.operator_contacted_at)
                                let projectscheduleDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.scheduled_date, project.scheduled_date)
                                let projectInsStartDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.inspection_start_date, project.inspection_start_date)
                                let projectInsEndDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.inspection_end_date, project.inspection_end_date)
                                let projectSubDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.actual_submission_date, project.actual_submission_date)
                                let projectSubDueDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.submission_due_date, project.submission_due_date)
                                let projectClosureDays = this.getProjectSlots(this.state.dates[0], this.state.dates[this.state.dates.length-1], project.closure_date, project.closure_date)
                                return(
                                    <div key={index} className="rh-project-block" style={{ width: ( this.state.dates.length * this.state.colWidth) + 'px' }}>
                                        { this.state.dates.map((date, index) =>
                                            <div className="inner-row-block ">
                                                <ul className="list-inline">
                                                    <li style={{width: this.state.colWidth+'px', margin: 0, textAlign: 'center',height:'145px'}}  className="list-inline-item inner-square-block"></li>
                                                </ul>
                                            </div>
                                        )}
                                        <span>
                                            <span id ={`project-status-${project.project_slug}`} className= {`project-dates status${3}`}
                                            style={{ backgroundColor:'#87e665',
                                                    display: 'inline-block',
                                                    width: projectDays && projectDays.duration > 0 ? projectDays.duration * this.state.colWidth : 0,
                                                    left: projectDays && projectDays.initialSpace > 0 ? projectDays.initialSpace * this.state.colWidth : 0,
                                                    right: projectDays && projectDays.finalSpace > 0 ? (projectDays.finalSpace * this.state.colWidth) : 0
                                                    }}
                                                >
                                            { projectDays && projectDays.duration >  3 ?
                                                <b style={{display:'none'}}className={projectDays.duration}>{ (project.planned_date > this.state.dates[0] || project.planned_date < this.state.dates[this.state.dates.length-1]) ? moment(project.planned_date).format('DD MMM, YYYY') : '--'} </b>
                                                : null
                                            }
                                            </span>
                                            {project.planned_date && moment(project.planned_date).isSame(moment(this.state.currMonth), 'month')?
                                                <img id ={`planner-planned-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                display: 'inline-block',
                                                left: projectIntialDays && projectIntialDays.initialSpace > 0 ? projectIntialDays.initialSpace * this.state.colWidth : 0,
                                                right: projectIntialDays && projectIntialDays.finalSpace > 0 ? (projectIntialDays.finalSpace * this.state.colWidth) : 0
                                                }} src={imgStoragePath + 'program_planner/projected_start.png'} alt="img"/>:null}
                                            {project.operator_contacted_at  && moment(project.operator_contacted_at).isSame(moment(this.state.currMonth), 'month')?
                                              <img id ={`planner-operator-contacted-at${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectContactDays && projectContactDays.initialSpace > 0 ? (projectContactDays.initialSpace * this.state.colWidth) : 0,
                                                        right: projectContactDays && projectContactDays.finalSpace > 0 ? (projectContactDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/call.png'} alt="img"/>:null}
                                            {project.scheduled_date  && moment(project.scheduled_date).isSame(moment(this.state.currMonth), 'month') ?
                                              <img id ={`planner-scheduled-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectscheduleDays && projectscheduleDays.initialSpace > 0 ? (projectscheduleDays.initialSpace * this.state.colWidth) - 35 : 0,
                                                        right: projectscheduleDays && projectscheduleDays.finalSpace > 0 ? (projectscheduleDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/scheduled_date.png'} alt="img"/>:null}
                                              {project.inspection_start_date && moment(project.inspection_start_date).isSame(moment(this.state.currMonth), 'month')?
                                              <img id ={`planner-inspection-start-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectInsStartDays && projectInsStartDays.initialSpace > 0 ? (projectInsStartDays.initialSpace * this.state.colWidth) + 33 : 0,
                                                        right: projectInsStartDays && projectInsStartDays.finalSpace > 0 ? (projectInsStartDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/inspector.png'} alt="img"/>:null}
                                          {project.inspection_end_date  && moment(project.inspection_end_date).isSame(moment(this.state.currMonth), 'month')?
                                              <img id ={`planner-inspection-end-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectInsEndDays && projectInsEndDays.initialSpace > 0 ? (projectInsEndDays.initialSpace * this.state.colWidth) - 25 : 0,
                                                        right: projectInsEndDays && projectInsEndDays.finalSpace > 0 ? (projectInsEndDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/insp-end-date.png'} alt="img"/>:null}
                                            {project.actual_submission_date  && moment(project.actual_submission_date).isSame(moment(this.state.currMonth), 'month')?
                                              <img id ={`planner-actual-submission-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectSubDays && projectSubDays.initialSpace > 0 ? (projectSubDays.initialSpace * this.state.colWidth) - 22: 0,
                                                        right: projectSubDays && projectSubDays.finalSpace > 0 ? (projectSubDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/projected_start.png'} alt="img"/>:null}
                                              {project.submission_due_date && moment(project.submission_due_date).isSame(moment(this.state.currMonth), 'month')?
                                              <img id ={`planner-submission-due-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectSubDueDays && projectSubDueDays.initialSpace > 0 ? (projectSubDueDays.initialSpace * this.state.colWidth) + 60 : 0,
                                                        right: projectSubDueDays && projectSubDueDays.finalSpace > 0 ? (projectSubDueDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/report-due-date.png'} alt="img"/>:null}
                                              {project.closure_date && moment(project.closure_date).isSame(moment(this.state.currMonth), 'month')?
                                              <img id ={`planner-closure-date-${project.project_slug}`} className= {`project-dates`} style={{
                                                        display: 'inline-block',
                                                        left: projectClosureDays && projectClosureDays.initialSpace > 0 ? (projectClosureDays.initialSpace * this.state.colWidth) - 5 : 0,
                                                        right: projectClosureDays && projectClosureDays.finalSpace > 0 ? (projectClosureDays.finalSpace * this.state.colWidth) : 0
                                                        }} src={imgStoragePath + 'program_planner/report-submitted.png'} alt="img"/>:null}

                                        </span>
                                        <ToolTipHover placement="top" tagetId={`project-status-${project.project_slug}`}>
                                            {moment(this.checkDates(project.planned_date,project.operator_contacted_at)).format('DD MMM, YYYY')} to {moment(this.finalDate(project)).format('DD MMM, YYYY')}
                                        </ToolTipHover>
                                        {project.planned_date && moment(project.planned_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-planned-date-${project.project_slug}`}>
                                            {'Planned Date: '+moment(project.planned_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.operator_contacted_at && moment(project.operator_contacted_at).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-operator-contacted-at${project.project_slug}`}>
                                            {'Operator Contacted Date: '+moment(project.operator_contacted_at).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.scheduled_date && moment(project.scheduled_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-scheduled-date-${project.project_slug}`}>
                                            {'Scheduled Date: '+moment(project.scheduled_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.inspection_start_date && moment(project.inspection_start_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-inspection-start-date-${project.project_slug}`}>
                                            {'Inspection Start Date: '+moment(project.inspection_start_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.inspection_end_date && moment(project.inspection_end_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-inspection-end-date-${project.project_slug}`}>
                                            {'Inspection End Date: '+moment(project.inspection_end_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.actual_submission_date && moment(project.actual_submission_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-actual-submission-date-${project.project_slug}`}>
                                            {'Actual Submission Date: '+moment(project.actual_submission_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.submission_due_date && moment(project.submission_due_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-submission-due-date-${project.project_slug}`}>
                                            {'Submission Due Date: '+moment(project.submission_due_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}
                                        {project.closure_date && moment(project.closure_date).isSame(moment(this.state.currMonth), 'month')?
                                        <ToolTipHover placement="top" tagetId={`planner-closure-date-${project.project_slug}`}>
                                            {'Closure Date: '+moment(project.closure_date).format('DD MMM, YYYY')}
                                        </ToolTipHover>:null}

                                    </div>
                                )
                            }): null}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
      </div>
      <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={'Are you sure you want to delete ?'} className="modal-tform-delete-pictures modal-dialog-centered">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deletePlanner('')}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
              </li>
            </ul>
        </DeleteModal>
      <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Comments'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.state.toggleComments}
          style={this.state.toggleComments ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.toggleComments?
          <CommentsEdit
           comments={programTrackerList.assets.filter(item=>item.id==this.state.assetId)[0].comments}
           toggleDelModal={this.toggleDelModal}
           updateComments={this.updateComments}
           toggleEditSideBar={() => this.toggleComments()}
          />:null}

        </EditFormBar>
    </div>

    )
  }
}
const mapStateToProps = (state) => ({
    programTrackerList:state.ProgramPlannerReducer.programTrackerList,
    commonLoader:state.ProgramPlannerReducer.commonLoader,
    statusLoader: state.ProgramPlannerReducer.statusLoader,
    filterLesseList: state.ProgramPlannerReducer.filterLesseList,
    techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    deleteComment:(slug,id,flag) => dispatch(deleteCommentAc(slug,id,flag)),
    updateComment:(id,comment,typeFlag)=>dispatch(updateCommentAc(id,comment,typeFlag)),
    fetchTrackerDetails : (queryParam={}) => dispatch(fetchProgramTrackerAc(ownProps, queryParam)),
    fetchTechConstant: (type) => dispatch(sGlobalConstantAc(type)),
    fetchFilterList: (query) => dispatch(fetchFIlterListAc(query)),
    exportTracker:(name,query)=>dispatch(trackerExportAc(name,query))

   }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramTracker);
