import React from 'react';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import SortableImageCard from './SortableImageCard';
import { SortableContainer } from 'react-sortable-hoc';
class ImageListRow extends React.Component {
    constructor(props){
      super(props);
    }
    render(){
      const { activityOp } = this.props;
      let loaderWidth = 250 * this.props.items.length + 'px';
      return(
        <div className="image-row" style={this.props.style} >
          <h4>
            {this.props.list.name}
            <span style={{fontSize: '14px', color: '#368ffb', cursor: 'pointer'}} onClick={() => this.props.toggleEditSideBar(this.props.list.name)}><img src={imgStoragePath+"import_images_icons.png"} width="16"/> Import</span>
          </h4>
          <ul className="list-inline flex-centered" id={`list_${this.props.list.id}`}>
            { !this.props.genSortLoader[this.props.list.id] ? this.props.items.map((picture,childIndex) =>
            <SortableImageCard
                key={childIndex}
                 picture={picture}
                index={childIndex}
                toggleDelModal={() => this.props.toggleDelModal('Are you sure to remove?',picture.id, this.props.list.id)}
                editImage={() => this.props.editGenPicture({...picture, name:this.props.list.name})}
                openSlider = {() => this.props.openSlider(this.props.list.pictures, childIndex,this.props.list.name)}
                options = {activityOp}
                style={{  backgroundImage: "url(" + this.props.list.image + ")"}}
                />
              ):
              <li className="list-inline-item form-img-card" style={{width: loaderWidth, paddingTop: '110px', background: 'rgb(255, 255, 255, 0.4)', boxShadow: '1px 1px 1px 1px #d3d3d3'}}>
                Sorting...
              </li>
            }
            {this.props.children}
          </ul>
        </div>
      )
    }
  }
export default SortableContainer(ImageListRow);
