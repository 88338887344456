import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { getCookie } from '../../../utils'
class AssemblySideBar extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { params } = this.props;
    const pathname = this.props.location.pathname;

    return(
      <div className="mr-calc-sidebar">
        <div>
          <ul className="">
            <li>
              <Link activeClassName="active" to={'/' +params.type + '/' + params.aircraft_slug + '/maintenance/claims'}>
                Claims
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h5>Major Assemblies</h5>
          <ul className="list-unstyled">
            { params.type == 'aircraft' ?
              <li>
                <Link
                  activeClassName="active"
                  className={pathname.includes('maintenance/airframe') || pathname.includes('maintenance/airframe/view') ? 'active':'' }
                  to={'/' +params.type + '/' + params.aircraft_slug + '/maintenance/airframe'}>
                  Airframe
                </Link>
              </li>:null
            }
            { params.type == 'aircraft' ?
              <li>
                <Link
                  className={pathname.includes('maintenance/apu') || pathname.includes('maintenance/apu/view') ? 'active':'' }
                  to={'/' +params.type + '/' + params.aircraft_slug + '/maintenance/apu'}>
                  APU
                </Link>
              </li>:null
            }
            { params.type == 'aircraft' ?
              <li>
                <Link
                  className={pathname.includes('maintenance/landing-gears') || pathname.includes('maintenance/landing-gears/view') ? 'active':'' }
                  to={'/' +params.type + '/' + params.aircraft_slug + '/maintenance/landing-gears'}>
                  Landing Gears
                </Link>
              </li>:null
            }
            { params.type == 'aircraft' || params.type == 'engine' ?
              <li>
                <Link
                  className={pathname.includes('maintenance/engines') || pathname.includes('maintenance/engine/view') ? 'active':'' }
                  to={'/' +params.type + '/' + params.aircraft_slug + '/maintenance/engines'}>
                  Engines
                </Link>
              </li>:null
            }

          </ul>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({

	closeMaintenance: state.sharedReducers.closeMaintenance,

});

export default withRouter(connect(mapStateToProps, null)(AssemblySideBar));
