import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
const DeleteModal = (props) =>{
  return(
    <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className}>
      {props.title ? <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>:null}
      <ModalBody style={props.style}>
        {props.children}
      </ModalBody>
    </Modal>
  )
}
export default DeleteModal;
