import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
export default class FilterList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Row {...this.props}>
        <Col md="12">{this.props.children}</Col>
      </Row>
    )
  }
}
