import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { NoRecordFound } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
export default class EngineWorkScope extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { workScopes } = this.props;
    const canAddEdit = getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.engines && (getLocalStorageInfo().user.permission.maintenance.engines.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1)
    return(

      <div className="engine-workscope-table">
        {
          workScopes.length<1 ? <NoRecordFound description="No Major Module Found"/> :
            workScopes.map((workScope, index) =>
            <Row key={index} className="workscope-row">
              <Col md="2" className="module-col border-left">
                <div className="module-content">
                  <input disabled={!canAddEdit} type="text" value={workScope.name} onChange={evt => this.props.updateWorkScopes('majorModule','name',evt.target.value,index)}/>
                  {
                    canAddEdit && <span onClick={() => {this.props.addWorkscopeSubModule(index); trackActivity('Item Added', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Workscope Sub Module' })}} className="add-new-row">+ Add a Sub Module</span>
                  }
                </div>
              </Col>
              <Col md="10" className="padding-0">
                {
                  workScope.subModule.map((sb, childIndex) =>
                  <Row key={childIndex} className="workscope-sub-row margin-0">
                    <Col md="3" className="submodule-col border-right">
                      <div className="submodule-content">
                        <input disabled={!canAddEdit} type="text" value={sb.name} onChange={evt => this.props.updateWorkScopes('childModule','name',evt.target.value,index,childIndex)}/>
                      </div>
                    </Col>
                    <Col md="9" className="flex-centered padding-right-0">
                      {
                        sb.items.map((item, itemIndex) =>
                        <Row key={itemIndex} className="workscope-item-row flex-not-centered margin-0">
                          <Col md="2" className="item-col flex-centered border-right">
                            <input disabled={!canAddEdit} type="text" value={item.moduleNo} onChange={evt => this.props.updateWorkScopes('itemModule','moduleNo',evt.target.value,index,childIndex,itemIndex)}/>
                            {(sb.items.length - 1) == itemIndex && canAddEdit ?
                              <span onClick={() => {this.props.addWorkScopeItem(index, childIndex); trackActivity('Item Added', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Workscope Add Another' })}} className="add-new-row">+Add Another</span>:null
                              
                            }
                          </Col>
                          <Col md="5" className="item-col flex-centered border-right">
                            <input type="text" value={item.moduleDescription} onChange={evt => this.props.updateWorkScopes('itemModule','moduleDescription',evt.target.value,index,childIndex,itemIndex)}/>
                          </Col>
                          <Col md="4" className="item-col workscope-level border-right flex-not-centered">
                            <Row>
                              <Col md="6" className="border-right flex-centered">
                                <input disabled={!canAddEdit} type="text" value={item.initialLevel} onChange={evt => this.props.updateWorkScopes('itemModule','initialLevel',evt.target.value,index,childIndex,itemIndex)}/>
                              </Col>
                              <Col md="6" className="flex-centered">
                                <input disabled={!canAddEdit} type="text" value={item.finalLevel} onChange={evt => this.props.updateWorkScopes('itemModule','finalLevel',evt.target.value,index,childIndex,itemIndex)}/>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="1" className="item-col flex-centered flex-center">
                            <a onClick={() => this.props.delWorkScopeItem(index, childIndex,itemIndex)} className="del-row"><img width="12" src={imgStoragePath+"del_icon.png"} alt="img"/></a>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
        )}
      </div>
    )
  }
}
