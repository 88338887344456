import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { addCrewSeat,addLavatory,addPassengerSeat,addGalley,addEquipment,addAvionic,addlopa } from '../createTechSummary';
import {
  AddEditCrewSeat,
  AddEditLavatory,
  CrewSeatTab,
  LavTab,
  PassengerSeating,
  AddEditPassengerSeat,
  GalleyTab,
  AddEditGalley,
  EquipmentTab,
  LopaTab,
  AddEditLopa,
  AddEditEquipement,
  TableHeadingTechSummary
} from '../components';
import { techSummaryCrudAc, addEditTechSummaryAc,fetchTechSummaryListAc,addEditLopaDetailsAc } from '../actionCreators';
import { TECH_SMRY_CRUD,TOGGLE_TECH_SMRY_VIEW } from '../actions';
import { EditSidebar, CtrTableHd, FormModeType } from '../../../contracts/Elements';
import { NoRecordFound  } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils';
class InteriorContent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    }
  }

  toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

  componentDidMount() {
    this.props.fetchTechSummaryList(this.props.asset_slug, 'seatingList');
    this.props.fetchTechSummaryList(this.props.asset_slug, 'lavatoryList');
    this.props.fetchTechSummaryList(this.props.asset_slug, 'paxSeatList');
    this.props.fetchTechSummaryList(this.props.asset_slug, 'galleyList');
    this.props.fetchTechSummaryList(this.props.asset_slug, 'equipmentList');
    this.props.fetchTechSummaryList(this.props.asset_slug, 'lopaHistoryList');
  }
  render(){
    const {
      seatingList,
      galleyList,
      paxSeatList,
      lavatoryList,
      manufacturerList,
      asset_slug,
      techSpecsLoader,
      equipmentList,
      lopaHistoryList,
      techConstants,
      crewSeatCrud,
      lavatoryCrud,
      passengerSeatCrud,
      equipmentCrud,
      lopacrud,
      galleyCrud
    } = this.props;
    return(
      <div>
       <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === (1) })}
            onClick={() => { this.toggle(1); }}
          >
            Crew Seat
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === (2) })}
            onClick={() => { this.toggle(2); }}
          >
            Lavatories
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === (3) })}
            onClick={() => { this.toggle(3); }}
          >
            Passenger Cabin
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === (4) })}
            onClick={() => { this.toggle(4); }}
          >
            Galley
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === (5) })}
            onClick={() => { this.toggle(5); }}
          >
            Equipments
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: this.state.activeTab === (6) })}
            onClick={() => { this.toggle(6); }}
          >
            LOPA
          </NavLink>
        </NavItem>
       </Nav>
       <TabContent activeTab={this.state.activeTab}>
       <TabPane tabId={1}>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'crewSeatCrud',addCrewSeat)} title='Crew Seat' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('C') != -1) ? true: false}/>
          <div className="table-container">
            <table className="common-table">
             <CtrTableHd theads={['Seat Type', 'Location', 'Part Number', 'Serial Number', 'Manufacturer', 'Remarks']}/>
              <tbody>
                {seatingList.map((crewSeat, index) =>
                  <CrewSeatTab key={index} crewSeat={crewSeat} viewCrewSeat={() => this.props.techSummaryCrud('view', crewSeat, 'crewSeatCrud',addCrewSeat)} />
                )}
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !seatingList.length ?
            <NoRecordFound description="Get Started by clicking '+ Add' button above" /> :
          null}
        </div>
        </TabPane>
        <TabPane tabId={2}>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'lavatoryCrud',addLavatory)} title='Lavatories' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('C') != -1) ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['Name', 'Location', 'Part Number', 'Serial Number', 'Manufacturer', 'Remarks']}/>
              <tbody>
                {lavatoryList.map((lavatory, index) =>
                    <LavTab key={index} asset_slug={asset_slug}  index={index} lavatory={lavatory} viewLavatory={() => this.props.techSummaryCrud('view', lavatory, 'lavatoryCrud',addLavatory,'lavatories')} />
                )}
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !lavatoryList.length ?
						<NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
		    	}
        </div>
        </TabPane>
        <TabPane tabId={3}>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'passengerSeatCrud',addPassengerSeat)} title='Passenger Cabin' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('C') != -1) ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['Type', 'No of Seats', 'Model', 'Part Number', 'Convertible / Fixed', 'Manufacturer']}/>
              <tbody>
                {Object.keys(paxSeatList).length  ?
                  paxSeatList.map((paxSeat, index) =>
                    <PassengerSeating key={index} index={index} paxSeat={paxSeat} asset_slug={asset_slug} viewPaxSeat={() => this.props.techSummaryCrud('view', paxSeat, 'passengerSeatCrud',addPassengerSeat)}/>
                ):null}
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !paxSeatList.length ?
	           <NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
		    	}
        </div>
        </TabPane>
        <TabPane tabId={4}>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'galleyCrud',addGalley)} title='Galley' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('C') != -1) ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['Name', 'Part Number', 'Manufacturer', 'Quantity', 'Configuration ', 'Type', 'Location']}/>
              <tbody>
                {galleyList.map((galley, index) =>
                  <GalleyTab asset_slug={asset_slug} galley={galley} index={index} key={index} viewGalley={() => this.props.techSummaryCrud('view', galley, 'galleyCrud',addGalley)}/>
                )}
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !galleyList.length ?
	           <NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
		    	}
        </div>
        </TabPane>
        <TabPane tabId={5}>
         <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'equipmentCrud',addEquipment)} title='Equipments' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('C') != -1) ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['Equipment', 'Part Number', 'Serial Number', 'Manufacturer', 'Equipment Type']}/>
              <tbody>
                {equipmentList.map((equipment, index) =>
                  <EquipmentTab asset_slug={asset_slug} equipment={equipment} index={index} key={index} viewEquipment={() => this.props.techSummaryCrud('view', equipment, 'equipmentCrud',addEquipment)}/>
                )}
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !equipmentList.length ?
						<NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
		    	}
        </div>
        </TabPane>
        <TabPane tabId={6}>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'lopacrud',addlopa)} title='LOPA' addButton={getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('C') != -1) ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['File','Remarks','Image']}/>
              <tbody>
                {lopaHistoryList.map((lopa, index) =>
                  <LopaTab asset_slug={asset_slug} lopa={lopa} index={index} key={index} viewLopa={() => {this.props.techSummaryCrud('view', lopa, 'lopacrud',addlopa)}}/>
                )}
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !lopaHistoryList.length ?
						<NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
		    	}
        </div>
        </TabPane>
        </TabContent>
        <EditSidebar
          title={
            crewSeatCrud.type != '' ?
            (crewSeatCrud.type == 'add') ? 'Add Crew Seat':
            (crewSeatCrud.type == 'edit') ? 'Edit Crew Seat': 'View Crew Seat' : ''
            }
          editSideBar={crewSeatCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'crewSeatCrud')}
          >
          {
            Object.keys(crewSeatCrud.data).length && crewSeatCrud.flag ?
            <div>
              <FormModeType
                type={crewSeatCrud.type}
                changeViewMode={()=>this.props.changeViewMode(crewSeatCrud.type,'crewSeatCrud')}
                editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('U') != -1 ? true:false}
               />
              <AddEditCrewSeat
                type={crewSeatCrud.type}
                crewSeatObj={crewSeatCrud.data}
                addEditTechSummary={this.props.addEditTechSummary}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'crewSeatCrud')}
                techConstants={techConstants}
              />
            </div>:null
          }
        </EditSidebar>
        <EditSidebar title={
          lavatoryCrud.type != '' ?
          (lavatoryCrud.type == 'add') ? 'Add Lavatory':
          (lavatoryCrud.type == 'edit') ? 'Edit Lavatory': 'View Lavatory' : ''
          }
            editSideBar={lavatoryCrud.flag}
            toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'lavatoryCrud')}>
					<div>
						<FormModeType
              type={lavatoryCrud.type}
              changeViewMode={()=>this.props.changeViewMode(lavatoryCrud.type,'lavatoryCrud')}
              editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('U') != -1 ? true:false}
            />
						{Object.keys(lavatoryCrud.data).length && lavatoryCrud.flag ?
              <AddEditLavatory
                type={lavatoryCrud.type}
                lavatoryObj={lavatoryCrud.data}
                addEditTechSummary={this.props.addEditTechSummary}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lavatoryCrud')}
                techConstants={techConstants}
                /> : null
              }
					</div>
				</EditSidebar>
        <EditSidebar title={
          passengerSeatCrud.type != '' ?
          (passengerSeatCrud.type == 'add') ? 'Add Passenger Cabin':
          (passengerSeatCrud.type == 'edit') ? 'Edit Passenger Cabin': 'View Passenger Cabin' : ''
          }
            editSideBar={passengerSeatCrud.flag}
            toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'passengerSeatCrud')}>
					<div>
						<FormModeType
              type={passengerSeatCrud.type}
              changeViewMode={()=>this.props.changeViewMode(passengerSeatCrud.type,'passengerSeatCrud')}
              editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('U') != -1 ? true:false}
            />
						{Object.keys(passengerSeatCrud.data).length && passengerSeatCrud.flag ?
              <AddEditPassengerSeat
                type={passengerSeatCrud.type}
                addEditTechSummary={this.props.addEditTechSummary}
                passengerSeatObj={passengerSeatCrud.data}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'passengerSeatCrud')}
                techConstants={techConstants}
                /> : null
              }
					</div>
				</EditSidebar>
        <EditSidebar title={galleyCrud.type != '' ?
          (galleyCrud.type == 'add') ? 'Add Galley':
          (galleyCrud.type == 'edit') ? 'Edit Galley': 'View Galley' : ''
          }
          editSideBar={galleyCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'galleyCrud')}>
					<div>
						<FormModeType
              type={galleyCrud.type}
              changeViewMode={()=>this.props.changeViewMode(galleyCrud.type,'galleyCrud')}
              editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('U') != -1 ? true:false}
            />
						{Object.keys(galleyCrud.data).length && galleyCrud.flag ?
              <AddEditGalley
                type={galleyCrud.type}
                galleyObj={galleyCrud.data}
                addEditTechSummary={this.props.addEditTechSummary}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'galleyCrud')}
                techConstants={techConstants}
                /> : null
              }
					</div>
				</EditSidebar>
        <EditSidebar title={equipmentCrud.type != '' ?
          (equipmentCrud.type == 'add') ? <p>Add Equipment</p>:
          (equipmentCrud.type == 'edit') ? 'Edit Equipment': 'View Equipment' : ''
          }
          editSideBar={equipmentCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'equipmentCrud')}>
					<div>
						<FormModeType
              type={equipmentCrud.type}
              changeViewMode={()=>this.props.changeViewMode(equipmentCrud.type,'equipmentCrud')}
              editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('U') != -1 ? true:false}
            />
						{Object.keys(equipmentCrud.data).length && equipmentCrud.flag ?
              <AddEditEquipement
                addEditTechSummary={this.props.addEditTechSummary}
                type={equipmentCrud.type}
                equipmentObj={equipmentCrud.data}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'equipmentCrud')}
                /> : null
              }
					</div>
				</EditSidebar>



        <EditSidebar title={lopacrud.type != '' ?
          (lopacrud.type == 'add') ? 'Add LOPA':
          (lopacrud.type == 'edit') ? 'Edit LOPA': 'View LOPA' : ''
          }
          editSideBar={lopacrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'lopacrud')}>
					<div>
						<FormModeType
              type={lopacrud.type}
              changeViewMode={()=>this.props.changeViewMode(lopacrud.type,'lopacrud')}
              editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['interior'].indexOf('U') != -1 ? true:false}
            />
						{Object.keys(lopacrud.data).length && lopacrud.flag ?
              <AddEditLopa
                addEditTechSummary={this.props.addEditTechSummary}
                updateLopaDetail ={this.props.updateLopaDetail}
                type={lopacrud.type}
                lopa={lopacrud}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lopacrud')}
                /> : null
              }
					</div>
				</EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  seatingList: state.TechSummaryReducer.seatingList,
  lavatoryList: state.TechSummaryReducer.lavatoryList,
  paxSeatList: state.TechSummaryReducer.paxSeatList,
  galleyList: state.TechSummaryReducer.galleyList,
  equipmentList: state.TechSummaryReducer.equipmentList,
  lopaHistoryList:state.TechSummaryReducer.lopaHistoryList,
  crewSeatCrud: state.TechSummaryReducer.crewSeatCrud,
  lavatoryCrud: state.TechSummaryReducer.lavatoryCrud,
  passengerSeatCrud: state.TechSummaryReducer.passengerSeatCrud,
  galleyCrud: state.TechSummaryReducer.galleyCrud,
  equipmentCrud: state.TechSummaryReducer.equipmentCrud,
  lopacrud: state.TechSummaryReducer.lopacrud,
  techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchTechSummaryList: (asset_slug, type) => dispatch(fetchTechSummaryListAc(asset_slug, type)),
    addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
    updateLopaDetail : (data, file,type, viewType,operation) => dispatch(addEditLopaDetailsAc(ownProps.params , data, file,type, viewType,operation)),
    techSummaryCrud: (type, id, actionType,dataObject) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject)),
    changeViewMode: (type,actionType) => dispatch({
      type:TOGGLE_TECH_SMRY_VIEW,
      payload:{type},
      actionType
    }),
    toggleEditBar: (data, actionType) => dispatch({
      type:TECH_SMRY_CRUD,
      payload:data,
      actionType
    }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InteriorContent));
