import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
const shopVisitReqList = {
  1: 'Repair',
  2: 'PRSV',
}

const shopVisitReportStatusList = {
  1: 'Received',
  2: 'Not Received'
}
const workScopeStatusList = {
 1: 'Approved' ,
 2: 'Rejected',
 3: 'Review in Progress',
 4: 'submitted for review'
}

const typeOfShopVisitList = {
  1: 'Performance restoration',
  2: 'repair'
}


export default class YetTobeSentList extends Component{
  constructor(props){
    super(props)
  }
 render(){
  const { dataList } = this.props;
  let data = dataList.off_wing_detail;
  return(
    <Row>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Selected Shop Visit"   text={data.selected_shop}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Expected Date of Shipment to Shop"   text={data.expected_date_of_shipment_to_shop ? moment(data.expected_date_of_shipment_to_shop).format(displayDateFormat):'--'}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Workscope Status"   text={data.work_scope_status ? workScopeStatusList[data.work_scope_status] : '--'}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Expected Turn Around Time"   text={data.expected_turn_around_time}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Remarks"   text={data.remarks}/>
 </Row>
  )
  }
}
