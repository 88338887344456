import React,{ Component } from 'react';
import Select from 'react-select';
import { getLocalStorageInfo } from '../../../../utils';
import 'react-select/dist/react-select.css';
import '../../../../shared/assets/styles/CommonHeaderBlock.scss';

export default class SelectAssemblies extends Component{
    constructor(props){
      super(props);
    }
    handleLessorChange = (selectOption) => {
      this.props.changeLessorType(selectOption ? selectOption.id:'');
    }
    render() {

        const { genFinanceInfo, clients, errors, mrUsage } = this.props;
        return(
            <div style={{paddingBottom:"20px"}} className="generate-finance-heading">
                <h2 className="beta gen-finance-text" style={{marginBottom: '3px', float:'none'}}>Generate a Finance Report</h2>
                <p className="block-subs">Allows user to create a fresh Finance Report query</p>
                <div className="">
                    <h6 className="">Select the metrics you would like to include</h6>
                    <ul style={{float:"none"}} className="list-inline generate-select-boxes ">
                        <li className="list-inline-item">
                            <label>
                                <input type="checkbox" onChange={() => this.props.metrixChange('airframe')} checked={ genFinanceInfo.metricsInfo.indexOf('airframe') != -1 ? true:false  } />
                                <span>Airframe</span>
                            </label>
                        </li>
                        {

                        }
                        <li className="list-inline-item">
                            <label>
                                <input type="checkbox"
                                    onChange={() => this.props.metrixChange('engine')}
                                    checked={ genFinanceInfo.metricsInfo.indexOf('engine') != -1 ? true:false  } />
                                <span>Engine</span>
                            </label>
                        </li>
                        <li className="list-inline-item">
                            <label>
                                <input type="checkbox"
                                    onChange={() => this.props.metrixChange('lg')}
                                    checked={ genFinanceInfo.metricsInfo.indexOf('lg') != -1 ? true:false  } />
                                <span>Landing Gears</span>
                            </label>
                        </li>
                        <li className="list-inline-item">
                            <label>
                                <input type="checkbox"
                                    onChange={() => this.props.metrixChange('apu')}
                                    checked={ genFinanceInfo.metricsInfo.indexOf('apu') != -1 ? true:false  } />
                                <span>APU</span>
                            </label>
                        </li>
                        {
                          (getLocalStorageInfo().defaultLessor.id == 24)?
                          <li className="list-inline-item use-cdb-algo">
                            <h6 >Select Data Source Point</h6>
                            <label>
                                <input type="radio"
                                    name="dataSource"
                                    onChange={(evt) => this.props.useCDBAlgo(false)}
                                    checked = {!genFinanceInfo.use_acumen_algorithm}
                                    />
                                <span>Use CDB Data Points</span>
                            </label>
                            <label style={{marginLeft: '10px', opacity: 0.4, cursor: 'not-allowed'}}>
                                <input type="radio"
                                    name="dataSource"
                                    disabled={true}
                                    // onChange={(evt) => this.props.useCDBAlgo(true)}
                                    // checked = {genFinanceInfo.use_acumen_algorithm}
                                    />
                                <span>Use Acumen Data Points</span>
                            </label>
                          </li>
                          :null
                        }
                    </ul>
                </div>
            </div>
        )

    }
}
