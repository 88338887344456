import * as actions from '../actions';
import { createProject } from '../../Forms/AddFormObjects';

export default function( state = {
  programSchedulerList: [],
  programTrackerList:[],
  programAssetsList: [],
  programConfirmAssetsList: [],
  inspectionType: [],
  projectCrud: createProject,
  projectLoader: false,
  statusLoader:false,
  selectedIds: [],
  commonLoader:false,
  genInstruction: [],
  projectEngineer: [],
  projectMembers: [],
  plannerStatus:{},
  filterEngineList:[],
  filterAirCraftList:[],
  filterLesseList:[]

}, action ){
  switch (action.type) {
    case actions.STATUS_PLANNER_LOADER:
      return {
        ...state,
        statusLoader: action.payload
      }
    case actions.COMMON_PLANNER_LOADER:
      return {
        ...state,
        commonLoader: action.payload
      }

    case actions.TOGGLE_PROJECT_SIDEBAR:
      return {
        ...state,
        projectCrud: action.payload
      }
    case actions.PROGRAM_SCHEDULER_LIST:
      return {
        ...state,
        programSchedulerList: action.payload,
      }
    case actions.PROGRAM_TRACKER_LIST:
      return {
        ...state,
        programTrackerList: action.payload,
      }
    case actions.PROGRAM_PLANNER_STATUS:
      return {
        ...state,
        plannerStatus: action.payload,
      }
    case actions.PROGRAM_PLANNER_ASSETS:
      return {
        ...state,
        programAssetsList: action.payload,
      }
    case actions.PROGRAM_PLANNER_CONFIRM_ASSETS:
      return {
        ...state,
        programConfirmAssetsList: action.payload,
      }
    case actions.INSPECTION_TYPE:
      return {
        ...state,
        inspectionType: action.payload,
      }
    case actions.UPDATE_SCHEDULER_LIST:
      return {
        ...state,
        programSchedulerList: state.programSchedulerList.map(item => item.id == action.payload.id ? action.payload: item),
      }
    case actions.UPDATE_ASSETS_LIST:
      return {
        ...state,
        programAssetsList: state.programAssetsList.map(item => item.id == action.payload.id ? action.payload: item),
      }
    case actions.UPDATE_CONFIRM_LIST:
      return {
        ...state,
        programConfirmAssetsList: state.programConfirmAssetsList.map(item => item.id == action.payload.id ? action.payload: item),
      }
      case actions.UPDATE_SCHEDULER_COMMENTS:
      return {
        ...state,
        programSchedulerList: state.programSchedulerList.map(item => item.id == action.payload.id ? {
          ...item,
          comments: action.payload.data
         }:item)
      }
     
    case actions.UPDATE_ASSETS_COMMENTS:
      return {
        ...state,
        programAssetsList: state.programAssetsList.map(item => item.id == action.payload.id ? {
          ...item,
          comments: action.payload.data
         }:item)
      }
    case actions.UPDATE_CONFIRM_COMMENTS:
      return {
        ...state,
        programConfirmAssetsList: state.programConfirmAssetsList.map(item => item.id == action.payload.id ? {
          ...item,
          comments: action.payload.data
         }:item)
      }
    case actions.UPDATE_TRACKER_COMMENTS:
      return {
        ...state,
        programTrackerList:{
          ...state.programTrackerList,
          assets: state.programTrackerList.assets.map(item => item.id== action.payload.id ? {
          ...item,
          comments: action.payload.data
         }:item)
        }
      }
    
    case actions.DELETE_ASSETS_COMMENTS:
      return {
        ...state,
        programAssetsList: state.programAssetsList.filter(item => item.id != action.payload ) 
          
      }
    case actions.DELETE_CONFIRM_COMMENTS:
      return {
        ...state,
        programConfirmAssetsList: state.programConfirmAssetsList.filter(item => item.id != action.payload)
      }



    case actions.PROJECT_MEMBERS:
      return{
        ...state,
        [action.payload.type]: action.payload.data
      }
    case actions.UPDATE_SELECTED_ASSETS:
      if(action.payload.type == 'all'){
        if(action.payload.flag){
          if(action.payload.typeList=='assets'){
          return {
            ...state,
            selectedIds: state.programAssetsList.map(contact => { return contact.id})
          }
        }else if(action.payload.typeList=='scheduler'){
          return {
            ...state,
            selectedIds: state.programSchedulerList.map(contact => { return contact.id})
          }
        }
        }else{
          return {
            ...state,
            selectedIds: []
          }
        }
      }else{
        if(state.selectedIds.includes(action.payload.id)){
          return {
            ...state,
            selectedIds: state.selectedIds.filter(id => id != action.payload.id)
          }
        }else{
          return {
            ...state,
            selectedIds: [...state.selectedIds, action.payload.id],
          }
        }
      }

      case actions.GET_GEN_INFO:
      return{
        ...state,
        genInstruction: action.payload
      }

      case actions.LESSE_LIST:
      return{
        ...state,
        filterLesseList: action.payload
      }
      case actions.AIRCARAFT_LIST:
      return{
        ...state,
        filterAirCraftList: action.payload
      }
      case actions.ENGINE_LIST:
      return{
        ...state,
        filterEngineList: action.payload
      }

      case actions.CLEAR_SELECTED_IDS:
      return{
        ...state,
        selectedIds:[]
      }
    
    default:
      return state;

  }
}