import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addInsurance, addClaim } from '../../contractAddObject';
export const conventionAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONVENTION_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/cape_town_convention/', {})
    .then(response => {
      dispatch({
        type: actions.CONVENTION_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONVENTION_LIST,
          payload: response.data.data
        });
      }
    })
  }
}
export const updateConventionAc = (params,data, assetViewId) => {
    return (dispatch) => {
      dispatch({
        type: actions.CONVENTION_LOADER,
        payload: true
      });
      globalPutService('contracts/'+params.contract_slug+'/cape_town_convention/', data)
      .then(response => {
        dispatch({
          type: actions.CONVENTION_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.CONVENTION_LIST,
            payload: response.data.data
          });
          dispatch({
            type: actions.CONVENTION_EDIT,
            payload: false
          });
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Cape Town Convention`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Cape Town Convention`,
            item_id: data.id
          })
        }
      })
    }
  }