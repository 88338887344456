import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import '../assets/styles/SideBarMntCalc.scss';
import {  getCookie, getLocalStorageInfo } from '../../utils';

const SidebarMntcCalc = (props) => {
  return(
    <div className="mr-calc-sidebar" style={getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.on_trail ? {top: '40px', left: '70px'} : {top: '40px', left: '70px'}}>
      <div className="mr-calc-heading">
        <h3>MR Calculator</h3>
      </div>
      <ul className="list-unstyled">
        <li>
          <Link className={(props.path.includes('logs') || props.path.includes('log') || props.path.includes('financial-plots') || props.path.includes('cash-flow') )?'active':''} to="/logs">Logs</Link>
        </li>
        <li >
          <Link activeClassName="active" to="/mr-calculator" >New Finance Report</Link>
        </li>
      </ul>
    </div>
  )
}
export default SidebarMntcCalc;
