import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Row, Col, Link, browserHistory } from 'react-router'
import { connect } from 'react-redux';
import { Plans } from '../../phase2/licenses/components';
import { plansListAc, addLicenceToCartAc, selectedPlansListAc } from '../../phase2/licenses/actionCreators';
import { imgStoragePath } from '../../constants';
import { getLocalStorageInfo } from '../../utils'
class LicenceModal extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      showLicences: false
    }
  }

  componentDidMount(){
    if(this.props.licenceModal.content == undefined || this.props.licenceModal.content == null || this.props.licenceModal.content == ''){
      this.setState({showLicences: true})
    }
    this.props.fetchPlans(this.props.planId);
    this.props.fetchSelectedPlans(this.props.planId);
  }

  render(){
    const { planDetail, selectedPlansDetail } = this.props
    return(
      <Modal id="buyLicenseModal" size="lg" style={{ minWidth: '500px' }} isOpen={this.props.licenceModal.flag} centered={true} toggle={this.props.toggleLicence} >
        <img className="close-btn" src={imgStoragePath+'black_close.png'}  onClick={() => this.props.toggleLicence()} />
        <ModalBody>
          {
            !this.state.showLicences ?
            <div className="text-center licenses-modal">
                <h2>License Expired</h2>
                <p>
                    {this.props.licenceModal.content}
                </p>
                {
                  getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && (getLocalStorageInfo().user.permission.console.cart == undefined || getLocalStorageInfo().user.permission.console.cart == null || getLocalStorageInfo().user.permission.console.cart.indexOf('R') == -1) ?
                    <Link style={{background: '#f1f1f1', cursor: 'not-allowed'}} disabled={true} onClick={() => null} >Buy Now</Link>
                  :<Link style={{background: '#007bff',  color:'#ffffff', borderColor: '#007bff'}} onClick={() => {this.setState({showLicences: true})}} >Buy Now</Link>
                }
              {
                getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && (getLocalStorageInfo().user.permission.console.cart == undefined || getLocalStorageInfo().user.permission.console.cart == null || getLocalStorageInfo().user.permission.console.cart.indexOf('R') == -1) ?
                  <span style={{marginTop: '20px', width: '100%'}} className="warning-info-text">Please contact Lessor Administrator to Buy License</span>
                :null
              }
            </div>
            :<Plans
                planDetail={planDetail}
                addLicenceToCart={this.props.addLicenceToCart}
                modal={true}
                selectedPlans={selectedPlansDetail.hasOwnProperty('license') && selectedPlansDetail.license.hasOwnProperty('selected_plans') ? selectedPlansDetail.license.selected_plans : []}
            />
          }
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
    planDetail: state.LicenseReducer.plansList,
    selectedPlansDetail : state.LicenseReducer.selectedPlansList,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        fetchPlans : (licenseId) => dispatch(plansListAc(licenseId)),
        fetchSelectedPlans : (licenseId) => dispatch(selectedPlansListAc(licenseId)),
        addLicenceToCart: (licenceId, quantity) => dispatch(addLicenceToCartAc(licenceId, quantity))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenceModal)
