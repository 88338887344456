import * as actions from '../actions';

export default function (state = {
  securityDepositList: [],
  securityDepositCrud: {
    type: '',
    data: {},
    flag: false
  },
  cashTxCrud: {
    type: '',
    data:{},
    flag: false
  },
  nonCashTxCrud:{
    type:'',
    data: {},
    flag: false
  },
  securityDepositMLData: [],
  securityDepositLoader: true
}, action) {
  switch (action.type) {
    case actions.SECURITY_DEPOSIT_LIST:
      return {
        ...state,
        securityDepositList: action.payload
      }
    case actions.SECURITY_DEPOSIT_ADD:
      return {
        ...state,
        securityDepositList: [action.payload, ...state.securityDepositList]

      };
    case actions.SECURITY_DEPOSIT_EDIT:
      return {
        ...state,
        securityDepositList: state.securityDepositList.map(securityDeposit => securityDeposit.id == action.payload.id ? { ...securityDeposit, ...action.payload } : securityDeposit)
      }
    case actions.CASH_TRANSACTIONS_ADD:
      return {
        ...state,
        securityDepositList: state.securityDepositList.map((securityDeposit, index) => securityDeposit.id == action.payload.security_deposit_id ? { ...securityDeposit, security_deposit_transactions: [action.payload, ...securityDeposit.security_deposit_transactions] } : securityDeposit ),
      }
    case actions.CASH_TRANSACTIONS_EDIT:
      return {
        ...state,
        securityDepositList: state.securityDepositList.map(securityDeposit => securityDeposit.id == action.payload.security_deposit_id ? {...securityDeposit, security_deposit_transactions: securityDeposit.security_deposit_transactions.map(tx => tx.id == action.payload.id ? action.payload: tx)} : securityDeposit),
      }
    case actions.SECURITY_DEPOSIT_CRUD:
      return {
        ...state,
        [action.actionType]: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        [action.actionType]:{
          ...state[action.actionType],
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.SECURITY_DEPOSIT_LOADER:
      return {
        ...state,
        securityDepositLoader: action.payload
      }
   case actions.ML_SECURITY_DEPOSIT_DATA:
      return {
        ...state,
        securityDepositMLData: action.payload
      }
    default:
      return state;
  }
}
