import React from 'react';
import { imgStoragePath } from '../../../constants';
import  GetInputField  from './GetInputField';
const ImageCard = (props) => {
  return(
    <li id={`picture_${props.picture.id}`} className="list-inline-item form-img-card overlay" style={{backgroundImage: "url(" + imgStoragePath + "lazy_load.png)", overflow: 'hidden'}}>
      <div style={{ backgroundImage: "url(" + props.picture.image +")", width: '200px', height: '200px', backgroudPosition: 'center'}}></div>
      <div className="storage-action-block">
        <span className="image-card-action">
          { props.options.includes('E')  && props.actionType != 'add' ?
            <span onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.editImage()}}>
              <img src={imgStoragePath + "edit_img.png"} width="20" /> Edit
            </span>:null
          }
          { props.options.includes('R') ?
            <span onClick={(evt) => {evt.preventDefault();evt.stopPropagation();props.toggleDelModal()}}>
              <img src={imgStoragePath+"delete_img.png"} width="15" /> Remove
            </span>:null
          }
          { props.options.includes('V') ?
            <span onClick={props.openSlider}>
              <img src={imgStoragePath + "white_view_icon.png"} width="20" /> View
            </span>:null
          }
          { props.options.includes('C') ?
            <span style={{position: 'relative', cursor: 'pointer'}}>
              <input multiple={false} onChange={(e) => props.changePicture(e.target.files)} type="file" style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer'}} />
              <img src={imgStoragePath + "Change_Pic_icon.png"} width="18" /> Change
            </span>:null
          }
        </span>
      </div>

      { props.options.includes('D') ?
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: props.picture.description,
            error: '',
            updateForm: (value) => props.updateForm({type: 'description', value:value, sectionType: 'inspectionPicture' , index:props.index})
          }
        ):null }
    </li>
  )
}
export default ImageCard;
