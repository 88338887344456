import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
export default class ToolTipHover extends Component{
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  render(){
    const { toolTipContent, tagetId, placement } = this.props;
    return(
      <Tooltip placement={placement} isOpen={this.state.tooltipOpen} target={tagetId} toggle={this.toggle} className="content-toptip">
        {this.props.children}
      </Tooltip>
    )
  }
}
