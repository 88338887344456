import React, { Component, Fragment } from 'react';
import { Row, Col, Button, } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { ListLoader} from '../../../shared';
import { globalGetService } from '../../../globalServices';
import { FieldDate, FieldGroup, FieldLabel} from '../../contracts/Elements';
import { toastFlashMessage } from '../../../utils';
class AircraftReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked:'xlsx',
      lessor_name_id:[],
      updateForm:false,
      pageLoader:false
    }
  }
  aircraftReport = () => {
    const { checked, lessor_name_id } = this.state
    let lessorStr = lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
    this.setState({
      pageLoader:true
    })
    globalGetService(`/console/export-asset-report/aircraft/?file_type=${checked}&lessor_name_id=${lessorStr}`)
    .then(response => {
      this.setState({
        pageLoader:false
      })
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }
  updateOwner = (key, value) => {
    this.setState((prevState => ({
      ...prevState,
      [key]:value,
    })))

  }
  render() {
    const { checked, lessor_name_id, error, updateForm, pageLoader } = this.state
    const { ownerList } = this.props
    return (
      <Fragment>
        {pageLoader ? <ListLoader /> : null }
        <div className="export-module-card aircraft-report-card cust-cashflow-card" style={{height: updateForm && '400px'}}>
          <div className="rpt-card-header">
            <h2>Aircraft Report
              <Button color="primary" size="sm" style={{float:'right', marginTop:'-4px'}} onClick={() =>  this.aircraftReport()}>Export</Button>
            </h2>
          </div>
          <div className="rpt-card-body">
            <div className="export-items">
              <div className="">
                <Row className="flex-centered">
                  <Col xs="5">
                    <div className="export-avial">
                      <h6>Export Format</h6>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <label >
                              <input
                                name="aircraft"
                                checked={checked == 'xlsx' ?  true : false}
                                type="radio"
                                onChange={() =>  this.setState({checked:'xlsx'})}

                              />
                              <span>XL</span>
                            </label>
                          </li>
                          <li className="list-inline-item">
                            <label >
                              <input
                                name="aircraft"
                                checked={checked == "csv" ?  true :false}
                                type="radio"
                                onChange={() =>  this.setState({checked:'csv'})}
                              />
                              <span>CSV</span>
                            </label>
                          </li>
                        </ul>
                    </div>
                  </Col>
                </Row>
                <div className="filter-avail-custom">
                  <Row>
                    <Col md="12" >
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">Lessor</FieldLabel>
                          <Select
                            name="form-field-name"
                            placeholder={<p style={{marginLeft:'10px'}}>Select Lessors</p>}
                            className="custom-select-block"
                            value={lessor_name_id}
                            labelKey="name"
                            valueKey="id"
                            options = {ownerList}
                            multi={true}
                            onChange={(target) => this.updateOwner('lessor_name_id', target)}
                            style={{width:'500px', background:'#fff'}}
                            onBlur={() =>  this.setState({updateForm:false })}
                            onFocus={() =>  this.setState({updateForm:true, selectAll:false, error:{...error,asset_type_id:''}})}
                          />
                      </FieldGroup>
                  </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    )
  }
}
export default AircraftReport;
