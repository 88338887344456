import * as actions from '../actions';
import { toastFlashMessage } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';

export const fetchpowerPlantDetailsAc = (props) => {
	return (dispatch) => {
        dispatch(triggerLoader(true));
		globalGetService('/technical/engine-workorder/'+props.params.workOrderSlug+'/get-powerplant-accessories/', {})
		.then(response => {
            dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_POWERPLANT_INV,
					payload: response.data.data
				})
			}
		})
	}
}


export const deletePowerPlantDetAc = (props, compoIndex, componentId,section) => {
	return (dispatch) => {
		if(!componentId){
			dispatch({
				type: actions.DELETE_POWER_COMPONENT,
				payload: {index:compoIndex,sectionType:section}
			})
		}else{
				dispatch(triggerLoader(true));
				globalDeleteService('technical/engine-workorder/'+props.params.workOrderSlug+'/powerplant-accessory/'+componentId+'/delete/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						dispatch({
							type: actions.DELETE_POWER_COMPONENT,
							payload: {index:compoIndex,sectionType:section}
						})
						// toastFlashMessage(response.data.message, 'success');
					}
				})
			}
		}
	}

export const updatepowerPlantDetailsAc = (props,data) => {
	return (dispatch) => {
        dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/'+props.params.workOrderSlug+'/update-powerplant-accessories/', data)
		.then(response => {
            dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
             toastFlashMessage(response.data.message, 'success');
			 trackActivity('Power plant Inventory List Updated', {
				page_title: `TE002`,
				application_module: 'Inspection',
				workorder_slug: props.params.workOrderSlug,
				form_slug: props.params.formSlug,
			  })
				dispatch(fetchpowerPlantDetailsAc(props))
				/*dispatch({
					type: actions.GET_POWERPLANT_INV,
					payload: response.data.data
				})*/
			}
		})
	}
}

export const triggerLoader = (flag) => {
    return (dispatch) => {
      dispatch({
        type: actions.POWERPLANT_DETLS_LOADER,
        payload: flag
      });
    }
  }
