import React, {Component} from 'react';
import {withRouter, Link} from 'react-router';
import {Col, Modal} from 'reactstrap';
import {CtrTableHd} from '../../../contracts/Elements';
import {imgStoragePath} from '../../../../constants';
import LLPsList from './LLPsList';
import { EditFormBar, DeleteModal} from '../../../technicalInspection/Elements';
import { checkString, checkNumber } from '../../../../formValidator';
import {AddLLP} from './';
import { NoRecordFound, ListLoader } from '../../../../shared';
import { addLLP } from '../createTechSummary';
import { deleteGenericLLPApi,getGenericLLPApi,addGenericLLPApi, editGenericLLPsApi, exportGenericLLPsApi, importGenericLLPsApi } from '../apiServices';
class GenericAssemblyLLPs extends Component {
  constructor(props){
    super(props)
    this.state = {
      fileKey:"100",
      pageLoader:false,
      llpState: false,
      llpToggleDelete: false,
      llp:[],
      loader:false,
      genericAssemblyLLPs:{},
      toggleLLP: false,
      deleteLLPId:'' ,
      error:{},
      errorLit:[],
      llpCrud:{},
      prevLLPs:[]
    }
    this.addGenericLLPApi = addGenericLLPApi.bind(this);
    this.getGenericLLPApi = getGenericLLPApi.bind(this);
    this.editGenericLLPsApi = editGenericLLPsApi.bind(this);
    this.exportGenericLLPsApi = exportGenericLLPsApi.bind(this);
    this.importGenericLLPsApi = importGenericLLPsApi.bind(this);
    this.deleteGenericLLPApi = deleteGenericLLPApi.bind(this);
   }
   componentDidMount() {
     this.setState({
       llp:this.props.llp ? this.props.llp:[]
     })
   }

// edit llp
  updateForm = (key, value, id) => {

    this.setState(prevState => ({
      ...prevState,
      llp: prevState.llp.map((item, index) => item.id == id ? {
        ...item, [key]:value
      } :item)
    }));
  }
  updateError = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addLLPForm = (keyParam, value) => {
    const { llpCrud } = this.state;
    this.setState(prevState => ({
      ...prevState,
      llpCrud:{
        ...prevState.llpCrud,
        [keyParam]:value
      }
    }))
  }
  updateFormField = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      llpCrud:{
        ...prevState.llpCrud,
        [key]:value
      }
    }))
  }
  updateErrorList = (key, message, index) => {
    this.setState(prevState => ({
      ...prevState,
      errorLit: prevState.errorLit.map((item, itemIndex) => itemIndex === index ? {
        ...item, [key]:''
      } :item)
    }))
  }
  editLLP = (e) => {
    const { llp } = this.state;
    let editLLPFlag = true;
    let editLLPError = [];
    e.preventDefault();
    editLLPError = llp.map(item => {
      return {
        description:checkString({value: item.description, required:true, minLength:"", maxLength:"", message:"Please enter Description"}),
        part_number: checkString({value: item.part_number, required: true, minLength: '', maxLength: '', message: 'Please enter Part Number'}),
        serial_number: checkString({value: item.serial_number, required: true, minLength: '', maxLength: '', message: 'Please enter Serial Number'}),
      }
    });
    editLLPError.map(data => {
      if(!Object.keys(data).every((k) => { return data[k] === ''})){
        editLLPFlag = false
      }
    });
    if(editLLPFlag) {
      this.editGenericLLPsApi(this.props, this.props.assemblyType, this.props.assemblySlug, this.state.llp);
    }else {
      this.setState({
        errorLit: editLLPError
      });
    }
  }
// add llp

  addLLP = (e) => {
    e.preventDefault();
    const { llpCrud } = this.state;
    let validateNewInput = {
      description:checkString({value: llpCrud.description, required:true, minLength:"", maxLength:"", message:"Please enter Description"}),
      part_number: checkString({value: llpCrud.part_number, required: true, minLength: '', maxLength: '', message: 'Please enter Part Number'}),
      serial_number: checkString({value: llpCrud.serial_number, required: true, minLength: '', maxLength: '', message: 'Please enter Serial Number'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.addGenericLLPApi(this.props,this.props.assemblyType,this.props.assemblySlug,this.state.llpCrud)
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  deleteModal = (id) => {
    this.setState({
      llpToggleDelete:true,
      deleteLLPId:id
    })
  }
  deleteLLP = () => {
    this.deleteGenericLLPApi(this.props, this.props.assemblyType, this.props.assemblySlug,this.state.deleteLLPId)
  }
  render() {
    const { fileKey, error, errorLit, llpState, llp, llpCrud, prevLLPs, pageLoader } = this.state;
    return (
      <div className="contr-table-blk" style={{marginTop:"30px"}}>
        <div style={{position:'relative'}}>
        <h5>LLPs  {this.props.partNumber ? '-'+this.props.partNumber: null }</h5>
          <ul className="list-inline export-import-list">
            <li className="list-inline-item">
              <Link className="import-link" style='custom-file-input' style={{color:"#194886", cursor:"pointer"}}>
                <img className="export-icn" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/import_icon.png" />Import
                <input id={fileKey} type="file" onChange={(e)=>this.importGenericLLPsApi(this.props, this.props.assemblyType, this.props.assemblySlug, e.target.files) } className="custom-file-input" style={{cursor:"pointer"}} />
              </Link>
              &nbsp;|
            </li>
            <li className="list-inline-item">
              <Link onClick={() => this.exportGenericLLPsApi(this.props,this.props.assemblyType,this.props.assemblySlug, this.props.assembly)} style={{width:"50px"}}>
                <img className="export-icn" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/export_icon.png" />Export
              </Link> |
            </li>
            { llp && llp.length ?
              <li className="list-inline-item">
                <Link className="edit-table-block">
                   <img width="13" src= {llpState ? imgStoragePath+ "view_icon_blue.png": imgStoragePath + 'icon_editblock.png' } alt="img" onClick ={()=> this.setState({llpState: true, prevLLPs:llp})} />
                </Link>
              </li>:null
            }

            <li className="list-inline-item" style={{paddingLeft:"10px"}}>
            <button className="btn btn-primary btn-sm"  onClick={()=> this.setState({toggleLLP:!this.state.toggleLLP, llpCrud:addLLP})}>Add LLP</button>
            </li>
          </ul>

        </div>
        <div className="table-container" style={{marginBottom:"0px"}}>
          <form style={{overflow:"auto"}} onSubmit={(e) => this.editLLP(e)}>
            <table className="common-table">
              <thead>
                <tr>
                  { ['Module', 'Description', 'Part Number', 'Serial Number','TSN','TSO', 'CSN','CSO','Cycle Limit', 'Remaining Cycle','LLPs as of Date', 'Action'].map((label, index) =>
                    <th style={{maxWidth:'180px'}}>{label}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                { llp && llp.length ? llp.map((llp, index)=>
                  <LLPsList
                    type={this.state.llpState}
                    index={index}
                    key={index}
                    llp={llp}
                    updateForm={(key, value) => this.updateForm(key, value, llp.id)}
                    llpToggleDelete={this.state.llpToggleDelete}
                    deleteModal={this.deleteModal}
                    deleteLLP={this.deleteLLP}
                    heading={this.props.heading}
                    error={errorLit.length && errorLit[index] ? errorLit[index]:{}}
                    updateErrorList={this.updateErrorList}
                  />
                )
                :
                <tr>
                  <td colSpan={12}>
                    <NoRecordFound description="Get Started by clicking 'Add LLP' button above"  />
                  </td>
                </tr>
                }
              </tbody>
            </table>
            {
              llpState ?
              <div className="tableSubmit-block">
              <input className="primary-btn" type="submit" value="Save Changes"/>
              <Link onClick = {() => this.setState({llpState:false, errorLit:[], llp:prevLLPs})} >Cancel</Link>
            </div>: null
            }
          </form>
        </div>
        <EditFormBar
          titleContent="Add LLP"
          toggleEditSideBar={()=> this.setState({toggleLLP:!this.state.toggleLLP})}
          style={this.state.toggleLLP ? {width: '800px'} : {width: '0px'}}
          isOpen={this.state.toggleLLP}
          >
          <AddLLP
            addLLPForm={(key,value)=> this.addLLPForm(key, value)}
            updateError={this.updateError}
            addLLP = {this.addLLP}
            llpCrud={this.state.llpCrud}
            toggleEditSideBar={()=> this.setState({toggleLLP:!this.state.toggleLLP})}
            error={error}
            heading={this.props.heading}
          />
				</EditFormBar>
        <DeleteModal isOpen={this.state.llpToggleDelete} className="modal-dialog-centered" toggle={() => this.setState({deleteLLP: {flag: false, llp: {}}})} >
          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete </p>
            <ul className="list-inline" style={{textAlign: 'center'}}>
              <li className="list-inline-item">
                  <button className="btn btn-primary btn-sm add-new" onClick={this.deleteLLP}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button className="btn btn-danger btn-sm add-new" onClick={()=> this.setState({llpToggleDelete:!this.state.llpToggleDelete }) } >No</button>
              </li>
            </ul>
        </DeleteModal>
        { pageLoader ? <ListLoader />:null}
      </div>
    )
  }
}
export default withRouter(GenericAssemblyLLPs);
