import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../../../constants';
import { TextInputField, GetInputField, DeleteModal } from'../../../Elements';
import { checkString, checkNumber, checkIntNumber } from '../../../../../formValidator'

export default class AddEditConfig extends Component{
    constructor(props){
			super(props);
			this.state = {
				configDataObj: [],
				error: [],
				delModal: false,
				deleteConfigId: '',
				deleteConfigIndex: '',
				customAttr: false,
				customAdd : {
					columns: [['A', 'B'], [], []],
					starting_row: '',
					end_row: '',
					seat_type: '',
					section_order: '',
					is_custom: true,
					}
			}
		}




		componentDidMount(){
			let ObjRef = Object.assign([], this.props.configDataObj);
			this.setState({
				configDataObj: ObjRef
			});
		}



		genCharArray = (charA, charZ) => {
			var a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
			for (; i <= j; ++i) {
				a.push(String.fromCharCode(i));
			}
			return a;
		}

		updateForm = (key,value, confIndex) => {
			this.setState(prevState => ({
				...prevState,
				configDataObj : [...prevState.configDataObj].map((confData, confDataIndex) => confDataIndex == confIndex ? {
					...confData,
						[key]: value
				}: confData),
				error: [...prevState.error].map((errData, confDataIndex) => confDataIndex == confIndex ? {
					...errData,
					[key]: ''
				}	: errData)
			}))
		}

		addSeatsFn = (confIndex, index, minvalue) => {

			var array = this.genCharArray(minvalue, 'Z');
			this.setState(prevState => ({
				...prevState,
				configDataObj : [...prevState.configDataObj].map((confData, confDataIndex) => confDataIndex == confIndex ? {
					...confData,
					columns: confData.columns.map((data , key) => key == index ?
						[...confData.columns[key], array[0]]
					: data
					),
				}: confData),
				error: [...prevState.error].map((errData, confDataIndex) => confDataIndex == confIndex ? {
					...errData,
					col : '' } : errData)
			}))

		}


		subSeatsFn = (confIndex, index) => {
			this.setState(prevState => ({
				...prevState,
				configDataObj : [...prevState.configDataObj].map((confData, confDataIndex) => confDataIndex == confIndex ? {
					...confData,
					columns: confData.columns.map((data , key) => key == index ?
							[...confData.columns[key]].slice(0, -1)
					: data
					)
				}: confData)
			}))
		}

		updateColumns = (confIndex, index, value, arrIndex) => {
			this.setState(prevState => ({
				...prevState,
				configDataObj : [...prevState.configDataObj].map((confData, confDataIndex) => confDataIndex == confIndex ? {
					...confData,
					columns: confData.columns.map((data , key) => key == index ?
						data.map((arrData, arrayIndex) => arrayIndex == arrIndex ?
							data[arrIndex] = value.toUpperCase()
							: arrData
						)
					: data
					)
				}: confData),
				error: [...prevState.error].map((errData, confDataIndex) => confDataIndex == confIndex ? {
					...errData,
					col : '' } : errData)
			}))
		}

		addSectionFn = () => {
			this.setState(prevState =>({
				...prevState,
				configDataObj: [...prevState.configDataObj, this.state.customAdd]
			}))
		}

		deleteSeatRow = () => {
			this.setState(prevState => ({
				...prevState,
		  	deleteConfigIndex: '',
				configDataObj : [...prevState.configDataObj].filter((confData, confDataIndex) => confDataIndex != this.state.deleteConfigIndex )
			}))
			if(!this.state.customAttr && this.state.deleteConfigId){
				this.props.deleteFn(this.state.deleteConfigId);
			}

		}


		toggleDelModal = (confIndex, configId, isCustom) => {
			this.setState({
				delModal: !this.state.delModal,
				deleteConfigId: configId,
				deleteConfigIndex: confIndex,
				customAttr: isCustom
			});
		}


		addEditConfig = (e) => {
			e.preventDefault();
			let validateNewInput = [];
			let validateNewCheckInput = [];
			validateNewInput = this.state.configDataObj.map((data, index) => {
			 var regex=/^[a-zA-Z]+$/;
			return(
				{
				'seat_type': checkNumber({value: data.seat_type, required: true, minLength: '', maxLength: '', message: 'Please select Seat Type'}),
				'end_row': (parseInt(data.starting_row) > parseInt(data.end_row )) ? 'Please enter valid End Row 1' : checkNumber({value: data.end_row, required: true, minLength: '', maxLength: '', message: 'Please enter valid End Row'}) ,
				'starting_row': (index != 0 && ((parseInt(data.starting_row) - parseInt(this.state.configDataObj[index-1].end_row)) < 1 )) ? checkIntNumber({value: (data.starting_row - parseInt(this.state.configDataObj[index-1].end_row + 1)), required: true, minLength: '', maxLength: '', message: 'Please enter valid Start Row'}) : data.starting_row == 0 ? 'Please enter valid Start Row': checkNumber({value: data.starting_row, required: true, minLength: '', maxLength: '', message: 'Please enter valid Start Row'}),
				'col': (data['columns'].filter((colData, index) => Object.keys(colData).every((k) =>  colData[k] == true  )).length == 3)  ? "Please add atleast one Seat " : data['columns'].map((colData, index) => colData.every((col) => col )).includes(false) || data['columns'].map((colData, index) => colData.every((col) => col.match(regex))).includes(false) ? "Please enter valid Seats Number" : ""
				}
			 )
			})
			let validationFlag = validateNewInput.map(validateInput => Object.keys(validateInput).every((k) => { return validateInput[k] === '' }));
			if (!validationFlag.includes(false)) {
				this.props.addEditConfig(this.state.configDataObj, this.props.configDataObj.type, 'false')
			}else{
				this.setState(prevState => ({
					...prevState,
					error: validateNewInput
				}));
			}
		}

	  render(){
			    const { configDataObj } = this.state;
				const { type } = this.props.configDataObj;
				const headerHeight = document.body.contains(document.getElementById('sidebar-header')) ? (document.getElementById('sidebar-header').clientHeight - 5) : 0 ;

				if(!(configDataObj && configDataObj.length)){
					return false
				}
				return(
					<div>
						<form className="config-side-bar" onSubmit={(e) => this.addEditConfig(e)}>
							<div className="sidebar-form" style={{ height: (window.innerHeight -  headerHeight)}}>
							{
								configDataObj.map((configData, confIndex) =>
								<Row key={confIndex} style={{position: 'relative'}} className={ configDataObj.length-1 == confIndex ? "seatconfig-type-wrapper border-none" : "seatconfig-type-wrapper" }>
									{
											configDataObj.length <= 1 ? null :
											<Link className="delete-custom"
											onClick={() => this.toggleDelModal(confIndex, configData.id, configData.is_custom ? configData.is_custom: false)}
											> 	<img width="12" src={imgStoragePath+"delete_red.png"} />
											</Link>
									}



							  	<Col md="12" style={{marginBottom: '20px'}}>
									{
										GetInputField(
											'dropdown',
											<label className="for-label">Select Class <sup className="reqStar"> * </sup></label>,
											{ type: type,
												value:  configData.seat_type,
												options: this.props.techConstants.filter(item => item.type=="pax_seat_type"),
												labelKey:'label',
												valueKey: 'value',
												error: this.state.error.filter((errData, errIndex) => errIndex == confIndex) && this.state.error.filter((errData, errIndex) => errIndex == confIndex).length ?  this.state.error.filter((errData, errIndex) => errIndex == confIndex)[0]['seat_type'] : '',
												updateForm: (value) => this.updateForm('seat_type', value, confIndex)
											}
										)
									}
								</Col>
								<Col md="6" style={{marginBottom: '20px'}}>
									{
										GetInputField(
											'string',
											<label className="for-label">Enter Start Row Number <sup className="reqStar"> * </sup></label>,
											{ type: type,
												value: configData.starting_row,
												error: this.state.error.filter((errData, errIndex) => errIndex == confIndex) && this.state.error.filter((errData, errIndex) => errIndex == confIndex).length ? this.state.error.filter((errData, errIndex) => errIndex == confIndex)[0]['starting_row'] : '',
												updateForm: (value) => this.updateForm('starting_row', value, confIndex)
											}
										)
									}
								</Col>
								<Col md="6" style={{marginBottom: '20px'}}>
									{
										GetInputField(
											'string',
											<label className="for-label">Enter End Row Number <sup className="reqStar"> * </sup></label>,
											{ type: type,
												value: configData.end_row,
												error: this.state.error.filter((errData, errIndex) => errIndex == confIndex) && this.state.error.filter((errData, errIndex) => errIndex == confIndex).length ? this.state.error.filter((errData, errIndex) => errIndex == confIndex)[0]['end_row'] : '',
												updateForm: (value) => this.updateForm('end_row', value, confIndex)
											}
										)
									}
								</Col>
								{
									configData && configData['columns'] ?
										configData['columns'].map((data, index) => {
											let minvalue = 'A';
											let dataLength = (data.length)-1;
											if(data && dataLength >=  0){
												minvalue = data.length ? String.fromCharCode(data[dataLength].charCodeAt(0) + 1)  : 'A';

											}else{
												if(index == 0){
													if(configData['columns'][1] && configData['columns'][1].length){
														minvalue = configData['columns'][1] && configData['columns'][1].length ? String.fromCharCode(configData['columns'][1][configData['columns'][1].length-1].charCodeAt(0) + 1)  : 'A';
													}else{
														if(configData['columns'][2] && configData['columns'][2].length){

															minvalue = configData['columns'][2] && configData['columns'][2].length ? String.fromCharCode(configData['columns'][2][configData['columns'][2].length-1].charCodeAt(0) + 1)  : 'A';
														}
													}
												}else if(index == 1){
														if(configData['columns'][2] && configData['columns'][2].length){

															minvalue = configData['columns'][2] && configData['columns'][2].length ? String.fromCharCode(configData['columns'][2][configData['columns'][2].length-1].charCodeAt(0) + 1)  : 'A';
														}else{
															if(configData['columns'][0] && configData['columns'][0].length){

																minvalue = configData['columns'][0] && configData['columns'][0].length ? String.fromCharCode(configData['columns'][0][configData['columns'][0].length-1].charCodeAt(0) + 1)  : 'A';
															}
														}
												}else if(index == 2){
													if(configData['columns'][1] && configData['columns'][1].length){

														minvalue = configData['columns'][1] && configData['columns'][1].length ? String.fromCharCode(configData['columns'][1][configData['columns'][1].length-1].charCodeAt(0) + 1)  : 'A';
													}else{
														if(configData['columns'][0] && configData['columns'][0].length){

															minvalue = configData['columns'][0] && configData['columns'][0].length ? String.fromCharCode(configData['columns'][0][configData['columns'][0].length-1].charCodeAt(0) + 1)  : 'A';
														}
													}
												}
											}
											return(
												<Col md="4" style={{marginBottom: '20px'}} key={index}>
												<div className="seat-add-section">
													<Link style={ data.length > 3 ?  {opacity: '0.5', pointerEvents: 'none'} : {'opacity': 1, pointerEvents: 'auto'}}
													onClick={() => this.addSeatsFn(confIndex, index, minvalue)}>
														<i className="fa fa-plus-circle " aria-hidden="true"></i>
													</Link>
													<Link
													// style={ data.length > 1  ?  {'opacity': 1, pointerEvents: 'auto'} : {opacity: '0.5', pointerEvents: 'none'}}
													onClick={() => this.subSeatsFn(confIndex, index)}>
													<i className="fa fa-minus-circle " aria-hidden="true"></i>
													</Link>
													<div className="flex-centered	 seat-block">
														{
															data && data.length ?
																data.map((data, arrIndex) =>

																	<input key={arrIndex} style={{textTransform: 'uppercase'}} minLength={1} maxLength={1} className="seat-naming" type="text" onChange={(e) => this.updateColumns(confIndex, index, e.target.value , arrIndex)} value={data}/>
																) : null
														}

													</div>
												</div>
											</Col>
											)
										}
									) : null
								}
									<Col md="12">
										<h6 className="error-msg">{this.state.error.filter((errData, errIndex) => errIndex == confIndex) && this.state.error.filter((errData, errIndex) => errIndex == confIndex).length ? this.state.error.filter((errData, errIndex) => errIndex == confIndex)[0]['col'] : ''}</h6>
									</Col>
								</Row>
								)
							}

							<div className="add-section" style={{marginBottom: '20px'}}>
								<Link onClick={() => this.addSectionFn()}> + Add Section</Link>
							</div>
							</div>
							{ type != 'view' ?
								<div className="edit-sidebar-footer" style={{ zIndex: 1}}>
									<ul className="list-inline">
										<li className="list-inline-item">
											<button type="submit"
											disabled={this.props.paxSeatingLoader}
											type="submit"
											className="btn btn-primary">{this.props.paxSeatingLoader ? 'Processing...':'Save Changes'}</button>
										</li>
										<li className="list-inline-item">
											<Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
										</li>
									 </ul>
								</div>:null
							}
						</form>


						<DeleteModal isOpen={this.state.delModal}
						toggle={() => this.toggleDelModal()}
						title={'Are you sure to delete?'} className="modal-tform-delete-pictures modal-dialog-centered">
							<ul className="list-inline">
								<li className="list-inline-item">
									<button
									type="button"
									className="btn btn-danger"
									onClick={() => {this.toggleDelModal(); this.deleteSeatRow()}}>Yes</button>
								</li>
								<li className="list-inline-item">
									<button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
								</li>
							</ul>
						</DeleteModal>
					</div>
				)
			}
}
