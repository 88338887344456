import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { EmptyCart, CartList, CardList, PaymentFailed, InvoicePreviewItem } from '../components';
import { imgStoragePath , displayDateFormatShort } from '../../../constants';
import { cartListAc, removeItemAc, makePaymentAc, getSavedCardsAc, saveCardAc, updateCartAc } from '../actionCreators';
import PaymentContainer from '../../../shared/Payment/containers'
import { ListLoader } from '../../../shared'
import { prettyNumberParamsL } from '../../../constants'
import { PAYMENT_FAILED } from '../../../shared/actions'
import { LICENSE_LOADER } from '../actions'
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';

class Cart extends Component{
    constructor(props){
        super(props);
        this.state = {
          paymentModal: false,
          deleteModal: '',
          planId: '',
          showNewCard: false,
          modalObj: {
            card_id: '',
            customer: ''
          }
        }
    }
    componentDidMount(){
        this.props.fetchCartList()
        this.props.getSavedCards()
    }
    removeCartItem = (cartId) => {
        this.setState({planId: cartId,deleteModal: true})
    }
    render(){
        const{ cartList, cardsList, licenseLoader, updateCart, stripePreview } = this.props;
        let subTotal = 0;
        if(this.props.cartList.list && this.props.cartList.list.length){
            this.props.cartList.list.map((cart, key) =>
              {
                if(stripePreview && stripePreview.length){
                  if(!cart.plan.is_subscription || cart.quantity == -1){
                    subTotal = subTotal+cart.sub_total
                  }
                }else{
                  subTotal = subTotal+cart.sub_total
                }
              }
            )
        }
        if(this.props.stripePreview && this.props.stripePreview.length){
          this.props.stripePreview.map(item => {
            if(item.proration || !item.proration){
              subTotal = subTotal + (item.amount / 100)
            }
          })
        }
        return(
            <div className="cart-container" style={{paddingBottom:'30px'}}>
                {cartList.list ? !cartList.list.length ?
                  !this.props.licenseLoader ?
                <EmptyCart />: null :
                <div className="container-fluid billing-content-wrapper" >
                    <h1 className="page-title">License Cart ( {cartList.list.length} Items )</h1>
                    <Row>
                        <Col md="8">
                          <div className="invoice-lists">
                              { cartList.list.map((cart, key) =>
                                <CartList cart={cart} key={`item-${key + 101}`} removeCartItem={this.removeCartItem} updateCart={updateCart} />
                              )}
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="billing-detail billing-detail-cart">
                              <h3>Order Summary</h3>
                                  <Row style={{borderBottom: '1px solid #c7bebe', paddingBottom: '5px'}}>
                                    <Col md="9"><h6>Item(s)</h6></Col>
                                    <Col md="3" style={{marginLeft: '-5px'}}><h6>Sub-total</h6></Col>
                                  </Row>
                                  {
                                    licenseLoader ?
                                    <Row className="cart-detail-con">
                                      <Row style={{width: '100%'}}><Col style={{textAlign: 'center'}} md="12">Loading...</Col></Row>
                                    </Row>
                                    :<Row className="cart-detail-con">
                                      {
                                        stripePreview && stripePreview.length && stripePreview.filter(item => item.proration == true) ?
                                          stripePreview.map((item, index) => item.proration || !item.proration ? <InvoicePreviewItem description={item.description} amount={item.amount / 100} key={`item-${index}`}/> : null)
                                        :null
                                      }
                                      {
                                        stripePreview && stripePreview.length ?
                                        cartList.list.map((item, index) =>  !item.plan.is_subscription ? <InvoicePreviewItem description={item.quantity != -1 ? `${item.quantity} X ${item.description}` : item.description} amount={item.sub_total} key={`item-${index + 100}`}/> : null)
                                        :cartList.list.map((item, index) => <InvoicePreviewItem description={item.quantity != -1 ? `${item.quantity} X ${item.description}` : item.description} amount={item.sub_total} key={`item-${index + 100}`}/>)
                                      }
                                    </Row>
                                  }
                          </div>
                            <div className="current-amount-due current-amount-due-cart">
                                <Row className="flex-centered">
                                    <Col md="8" >
                                        <h3>Total</h3>
                                      {
                                        !licenseLoader ?
                                          <h5>$ <NumericLabel >{parseFloat(subTotal) > 0 ? parseFloat(subTotal).toFixed(2) : 0}</NumericLabel></h5>
                                        :null
                                      }
                                    </Col>
                                    <Col md="4 text-center" >
                                        <a onClick={() =>this.setState({paymentModal: true, showNewCard: cardsList && cardsList.list && cardsList.list.length ? false : true})}>Checkout</a>
                                    </Col>
                                </Row>
                            </div>

                          { cartList.next_bill_amount?
                          cartList.next_bill_amount.map((nextBill, key) =>
                          nextBill.amount?
                          <div className="current-amount-due next-billing">
                                <Row className="flex-centered">
                                    <Col md="12" >
                                    <span><img className="img_eye" width="12" style={{ marginLeft: '5px',marginRight:'5px'}}  src={ imgStoragePath + 'inv_info_icon.png' } alt="img"/> <h3 style={{display:'inline'}}>Next billing on {nextBill.next_date ? moment(nextBill.next_date).format(displayDateFormatShort) : moment().format(displayDateFormatShort)} {nextBill.interval_type == 1 ? `for monthly subscription` : `for yearly subscription` } <strong>  ${parseFloat(nextBill.amount).toFixed(2)}</strong></h3></span>
                                    </Col>
                                </Row>
                          </div>:null):null}
                        </Col>
                    </Row>
                </div> :<EmptyCart />
                }
                <Modal isOpen={this.state.paymentModal} centered={true} toggle={() => !this.props.licenseLoader ? this.setState({paymentModal: false, showNewCard: false, modalObj: {card_id: '', customer: ''}}) : null} className="payment-modal" size="sm">
                  <ModalHeader toggle={() => !this.props.licenseLoader ? this.setState({paymentModal: false, showNewCard: false, modalObj: {card_id: '', customer: ''}}) : null}>
                    {
                      this.state.showNewCard ? 'New Credit / Debit Card' : 'Credit / Debit Card'
                    }
                  </ModalHeader>
                  <ModalBody>
                    {
                      !this.state.showNewCard ?
                        <Link onClick={() => this.setState({showNewCard: true})}>Add New Card</Link>
                      :cardsList && cardsList.list && cardsList.list.length ? <Link onClick={() => this.setState({showNewCard: false})}>Saved Cards</Link> : null
                    }
                    {
                      !this.state.showNewCard ?
                      <div className="cardsList">
                        <div className="inner-con">
                          {
                            cardsList && cardsList.list && cardsList.list.length ?
                              cardsList.list.map((card, key) => <CardList key={key} selected={this.state.modalObj.card_id} selectCard={() => this.setState({modalObj: {card_id: card.id, customer: card.customer}})} card={card}/>)
                            :null
                          }
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <button disabled={licenseLoader || this.state.modalObj.card_id == ''} onClick={() => this.props.makePayment({...this.state.modalObj, invoice_items: this.props.stripePreview})} className="add-new btn-primary"><i className="fa fa-lock"></i> {licenseLoader ? 'Processing....' : 'Pay'}</button>
                          </div>
                        </div>
                      </div>
                      :null
                    }
                    {
                      this.state.paymentModal && this.state.showNewCard ?
                        <PaymentContainer licenseLoader={this.props.licenseLoader} toggleLoader={this.props.toggleLoader} defaultChecked={cardsList.list && cardsList.list.length ? false : true} saveCard={(cardToken, invoice, address,flag) => this.props.saveCard(cardToken, invoice, address, this.props.stripePreview,flag)} makePayment={(paymentObj, cardToken) => {this.props.makePayment({...paymentObj, invoice_items: this.props.stripePreview}, cardToken); this.setState({paymentModal: false, showNewCard: false})}}/>
                      :null
                    }
                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.deleteModal} toggle={() => this.setState({deleteModal: false})} className="payment-modal" size="sm">
                  <ModalHeader toggle={() => this.setState({deleteModal: false})}>
                    Remove this license from cart?
                  </ModalHeader>
                  <ModalBody>
                    <div className="delete-logs">
                      {
                        // <h4>Are you sure, you want to delete ?</h4>
                      }
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <button className="btn btn-danger" onClick={() => {this.props.removeItem(this.state.planId);this.setState({deleteModal: false});}}>YES</button>
                        </li>
                        <li className="list-inline-item">
                          <button className="btn btn-secondary" onClick={() => this.setState({deleteModal: false})}>NO</button>
                        </li>
                      </ul>
                    </div>
                  </ModalBody>
                </Modal>
                {
                  this.props.licenseLoader && !this.state.paymentModal ?
                    <ListLoader/>
                  :null
                }
                <PaymentFailed
                  isOpen={this.props.failedPayment}
                  toggle={this.props.cloaseFailedModal}
                  retry={() => {this.props.cloaseFailedModal();this.setState({
                    paymentModal: true
                  })}}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cartList: state.LicenseReducer.cartList,
    licenseLoader: state.LicenseReducer.licenseLoader,
    cardsList: state.LicenseReducer.cardsList,
    stripePreview: state.LicenseReducer.stripePreview,
    failedPayment: state.sharedReducers.failedPayment
});

const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        fetchCartList : () => dispatch(cartListAc()),
        removeItem : (cartId) => dispatch(removeItemAc(cartId)),
        makePayment : (paymentObj, address) => dispatch(makePaymentAc(paymentObj, address)),
        getSavedCards : () => dispatch(getSavedCardsAc()),
        saveCard : (cardToken, invoice, address, stripePreview,flag) => dispatch(saveCardAc(cardToken, invoice, address, stripePreview,flag)),
        cloaseFailedModal : () => dispatch({
          type: PAYMENT_FAILED,
          payload: false
        }),
        updateCart : (planId, quantity) => dispatch(updateCartAc(planId, quantity)),
        toggleLoader: (flag) => dispatch({
          type: LICENSE_LOADER,
          payload: flag
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
