import React from 'react';
const EventsHeader = ({eventType}) => {
  return(
    <thead>
      <tr>
        <th>{ eventType === 'Maintenance' ? 'Maintance Event':'Shop Visit For' }</th>
        <th>{ eventType === 'Maintenance' ? 'Event Date':'Shop Visit Date' }</th>
        <th>{ eventType === 'Maintenance' ? 'TSN at Event':'TSN at Shop Visit' }</th>
        <th>{ eventType === 'Maintenance' ? 'CSN at Event':'CSN at Shop Visit' }</th>
        <th>Interval</th>
        <th>{ eventType === 'Maintenance' ? 'Reason of Event':'Reason of Shop Visit' }</th>
        <th>{ eventType === 'Maintenance' ? 'Event Workscope':'Shop Visit Workscope' }</th>
        <th>{ eventType === 'Maintenance' ? 'Event Facility':'Shop Visit Facility' }</th>
        <th>{ eventType === 'Maintenance' ? 'Event Cost':'Shop Visit Cost' }</th>
      </tr>
    </thead>
  )
}
export default EventsHeader;
