import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { imgStoragePath } from '../../../../../constants';
import { toastFlashMessage, getDataUri, getLocalStorageInfo } from '../../../../../utils';
import {globalFileUploadService} from '../../../../../globalServices/';
import {  GetInputField, DeleteModal,ContentDrop,SortableImageCard } from '../../../Elements';
import { NoRecordFound , ListLoader } from '../../../../../shared';
import TextareaField from '../../../Elements/TextareaField';
import { Observations, FindingList } from './';
import { trackActivity } from '../../../../../utils/mixpanel';
export default class EditAuditsReports extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      auditsInfo: [],
      activeTab: 0,
      delModal: false,
      modalTitle:'',
      findingId: '',
      activeMainTab: 3,
      is_fitted: false,
      dragOver: false,
      lopaLoader:false,
      activeCabinTab: 'main'
    };
  }
  componentDidMount(){

    this.setState({
      auditsInfo: this.props.auditsInfo
    })


    let upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169]
    let upperCabinTab = false
    let initLower = []
    let initMain = []
    this.props.auditsInfo.map((item, index) => {
      if(upGalleys.indexOf(item.id) != -1){
        initLower.push({...item, index: index})
      }else{
        initMain.push({...item, index: index})
      }
    })
    this.props.auditsInfo.map(item => {
      upperCabinTab = upGalleys.indexOf(item.id) != -1 ? true : upperCabinTab
      return item
    })
    if(upperCabinTab){
      this.setState({ activeMainTab: initMain[0].index + 3})
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(prevState => ({
      ...prevState,
      auditsInfo: nextProps.auditsInfo
    }))
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeMainTab: tab,
        activeTab: 0
      });
    }
  }

  toggleDelModal = (title='', item = []) => {
    this.setState({
      delModal: !this.state.delModal,
      modalTitle: title,
      findingId: item.id,
      aircraft_section : item.aircraft_section
    });
  }


  readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].value
    }
  }

  readTheLabel = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].label
    }else{
      return ''
    }
  }

  updateLopa=(type,value)=>{

    this.setState(prevState => ({
      ...prevState,
        auditsInfo: [ ...prevState.auditsInfo].map((data,index)=> index == 0 ? {
          ...data,
          lopa: {
            ...data.lopa,
            [type]:value
          }
        }: data)
      }));

  }

  updateForm = (type, value, label, specType, sectionId, fieldType, comopnentId, subComponentId) => {
    if(label == 'is_fitted'){
      this.setState(prevState => ({
        ...prevState,
        is_fitted: value
      }))
    }

    if(specType == 'additional_remarks'){
     if(label){
      this.setState(prevState => ({
        ...prevState,
          auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
            ...data,
            answers: [...data.answers].map(answer => answer.label == label ? {
              ...answer,
              label: type,
              value: value,
            } : answer )
          }: data)
        }));
     }else{
      this.setState(prevState => ({
        ...prevState,
        auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
          ...data,
          answers:  [ ...data.answers, {
              label: type,
              value: value,
              is_maintenance : false,
              field_type: 'textarea'
            }]
          }: data)
        }));
     }
   }
    if(specType == 'specs'){
      if(label){
        this.setState(prevState => ({
          ...prevState,
            auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
              ...data,
              answers: [...data.answers].map(answer => answer.label == label ? {
                ...answer,
                label: type,
                value: value,
              } : answer )
            }: data)
          }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
            ...data,
            answers:  [ ...data.answers, {
                label: type,
                value: value,
                is_maintenance : false,
                field_type: fieldType
              }]
            }: data)
          }));
       }
    }


    if(specType == 'components'){
      if(label){
        this.setState(prevState => ({
          ...prevState,
          auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
            ...data,
            component_questions: [...data.component_questions].map((component,componentIndex) => componentIndex == comopnentId ? {
              ...component,
              answers: [...component.answers].map(answer => answer.label == label ? {
                ...answer,
                label: type,
                value: value,
              } : answer )
             }: component)
            }: data)
          }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
            ...data,
            component_questions: [...data.component_questions].map((component, compIndex) => compIndex == comopnentId ? {
              ...component,
              answers:  [...component.answers, {
                label: type,
                value: value,
                is_maintenance : false,
                field_type: fieldType
              }]
             }: component)
            }: data)
          }));
       }
    }

    if(specType == 'subcomponents'){
      if(label){
        this.setState(prevState => ({
          ...prevState,
          auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
            ...data,
            component_questions: [...data.component_questions].map((component, comopnentIndex) => component.id == comopnentId ? {
              ...component,
              sub_components : [...component.sub_components].map((subComponent, subIndex) => subIndex == subComponentId ? {
                ...subComponent,
                answers: [...subComponent.answers].map(answer => answer.label == label ? {
                  ...answer,
                  label: type,
                  value: value,
                } : answer )
              } : subComponent
             )
             }: component)
            }: data)
          }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
            ...data,
            component_questions: [...data.component_questions].map((component, componentIndex) => component.id == comopnentId ? {
              ...component,
              sub_components : [...component.sub_components].map((subComponent, subComponentIndex) => subComponentIndex == subComponentId ? {
                ...subComponent,
                answers: [...subComponent.answers, {
                  label: type,
                  value: value,
                  is_maintenance : false,
                  field_type: fieldType
                }]
              } : subComponent
             )
             }: component)
            }: data)
          }));
       }
    }


  }

  onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  onDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver: false,
    })


  }


  coverUpload = (file) =>{
    this.setState(prevState => ({
			...prevState,
      lopaLoader: true,
		}))
   // this.props.uploadLopa(image);
    if (!(file[0]['type']).match('image.*')) {
      toastFlashMessage( file.name + ' is not Valid Format', 'error');
     }
    var formData = new FormData();
    formData.append('file',file[0], file[0]['name']);
    formData.append('shop_visit', true);
    var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        }
    }

    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      if(this.props.storageUsage.used + file[0].size >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        this.setState(prevState => ({
    			...prevState,
          lopaLoader: false,
    		}))
        return;
      }
    }

    globalFileUploadService('/technical/engine-workorder/file-upload/', formData, config)
      .then(response => {
        this.setState(prevState => ({
          ...prevState,
          lopaLoader: false,
        }))
        if(response.data.statusCode == 200){
          this.updateLopa('image',response.data.url)
        }
      })
  }




  addEditFindingFn = (e) => {
    e.preventDefault();
    let data = [...this.state.auditsInfo];
    delete data['inspection_findings'];
    let physicalQuestions = data.map(question => question.physical_questions.filter(data => ((data.answer.remarks == '' || data.answer.remarks == null) && data.answer.value == 1)))
    if(!physicalQuestions[0].length){
      this.props.auditSectionInfoSaveFn(data)
    }
  }

  removeComponent = (sectionId, componentIndex, item = null) => {
    if(item && item['id']){
      this.props.removeComponentFn(sectionId, item.id);
    }else{
      this.setState(prevState => ({
        ...prevState,
        auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
          ...data,
          component_questions: [...data.component_questions].filter((component, index) => index != componentIndex)
       }: data)
      }))
    }
  }

  generateFieldDom = (item, sectionId, key, specType, componentId = '', subComponentId = '') => {
    let isLabel = this.readTheLabel(item.answers, key);
    if(item && item.specs && item.specs[key] && item.specs[key].is_fitted){

    if(this.readTheAnswer(item.answers, 'is_fitted')=='true' ){
        if(item.specs[key].is_fitted=='true'){
        return ( GetInputField(
          item.specs[key].field_type,
          <label className="for-label">{item.specs[key].label}</label>,
          { type: this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit': 'view',
           value:  this.readTheAnswer(item.answers, key),
            error: '',
            options: item.specs[key].field_type == 'dropdown' ? item.specs[key].api === true ? this.props[item.specs[key].api_code] :  item.specs[key].options:null,
            labelKey:'label',
            valueKey: 'value',
            updateForm: (value) => this.updateForm(key, value, isLabel, specType, sectionId, item.specs[key].field_type, componentId, subComponentId)
          }
        ))
        }
      }else{
        if(item.specs[key].is_fitted=='false'){
          return ( GetInputField(
            item.specs[key].field_type,
            <label className="for-label">{item.specs[key].label} </label>,
            { type: this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit': 'view',
              value:  this.readTheAnswer(item.answers, key),
              error: '',
              options: item.specs[key].field_type == 'dropdown' ? item.specs[key].api === true ? this.props[item.specs[key].api_code] :  item.specs[key].options:null,
              labelKey:'label',
              valueKey: 'value',
              updateForm: (value) => this.updateForm(key, value, isLabel, specType, sectionId, item.specs[key].field_type, componentId, subComponentId)
            }
          ))
        }
    }
    }else{
      if(key == 'additional_remarks'){
        return ( GetInputField(
          'textarea',
          <label className="for-label"> Additional Remarks </label>,
          { type: this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit': 'view',
           value:  this.readTheAnswer(item.answers, key),
            error: '',
            updateForm: (value) => this.updateForm(key, value, isLabel, specType, sectionId, 'textarea', componentId, subComponentId)
          }
        ))
      }else{
        return ( GetInputField(
          item.specs[key].field_type,
          <label className="for-label">{item.specs[key].label} </label>,
          { type: this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_save ? 'edit': 'view',
            value:  this.readTheAnswer(item.answers, key),
            error: '',
            options: item.specs[key].field_type == 'dropdown' ? item.specs[key].api === true ? this.props[item.specs[key].api_code] :  item.specs[key].options:null,
            labelKey:'label',
            valueKey: 'value',
            updateForm: (value) => this.updateForm(key, value, isLabel, specType, sectionId, item.specs[key].field_type, componentId, subComponentId)
          }
       ))
    }
    }
  }



  updateObservations = (questionId, value, label, sectionId) => {
    this.setState(prevState => ({
      ...prevState,
      auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
        ...data,
        physical_questions: [ ...data.physical_questions].map(question => question.id == questionId ? {
         ...question,
         answer: {
          ...question.answer,
          [label] : question.answer[label] == value ? null : value,
         }
        }: question)
      }: data)
    }));
  }



  generateDynamicContent = (dynamicType, dynamicData, sectionId,componentId) => {
  //  let regex = '\\b' + _fitted + '\\b';
   let specData = Object.assign({}, dynamicData.specs);

   delete specData['is_fitted'];
  //  delete specData[regex];
    if(dynamicType == 'section'){
      this.setState(prevState => ({
        ...prevState,
        auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
          ...data,
          component_questions: [...data.component_questions] && [...data.component_questions]  ?
          [
            ...data.component_questions,
            {
              specs: specData,
              section_id: sectionId,
              answers: [],
              is_custom_component: true
            }
          ]
          : [{
              specs: specData,
              answers: []
            }
          ]
        }: data)
      }));
    }else if(dynamicType == 'component'){
      this.setState(prevState => ({
        ...prevState,
        auditsInfo: [ ...prevState.auditsInfo].map(data => data.id == sectionId ? {
          ...data,
          component_questions: [...data.component_questions] && [...data.component_questions]  ?
          [
            ...data.component_questions,
            {
              specs: specData,
              section_id: sectionId,
              component_id:componentId,
              answers: [],
              is_custom_component: true
            }
          ]
          : [{
              specs: specData,
              answers: []
            }
          ]
        }: data)
      }));

    }
  }

  render(){
    const { physicalInspection, formInfo, sectionId } = this.props;
    const { auditsInfo,lopaLoader } = this.state;
    let upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169]
    let upperCabinTab = false
    let initLower = []
    let initMain = []
    auditsInfo.map((item, index) => {
      if(upGalleys.indexOf(item.id) != -1){
        initLower.push({...item, index: index})
      }else{
        initMain.push({...item, index: index})
      }
    })
    auditsInfo.map(item => {
      upperCabinTab = upGalleys.indexOf(item.id) != -1 ? true : upperCabinTab
      return item
    })
    if(!Object.keys(auditsInfo).length){ return null }
    return(
      <form className="physical-inspection-form technical-form" onSubmit={(e) => this.addEditFindingFn(e)}>
       { lopaLoader ? <ListLoader drawer="primary-nav" />:null}
        { auditsInfo.length <= 1 ?
          <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
            {Object.keys(auditsInfo[0].lopa).length ?
            <div>
            <h4 style={{fontSize:'16px',paddingBottom:'15px',color:'#9439e4'}}>LOPA</h4>
            <ul className="list-inline flex-centered">

            { auditsInfo[0].lopa.image ?
              <li className="lopa-photo-block" style={{ height: 'auto', overflow: 'hidden',background: '#ddd',marginBottom:'20px'}}>
                { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                  <span className="lopa-photo-hover overlay">
                    <b>
                      <img src={imgStoragePath + "edit_img.png"} width="10" />Change Picture
                      <input type="file"   accept={'image/*'} onChange={(evt) => {evt.preventDefault();evt.stopPropagation();this.coverUpload(evt.target.files)}}/>
                    </b>
                  </span>:null
                }
              <div style={{position:'relative' , overflow: 'hidden',   width: '200px', height: '200px', backgroundImage: "url(" + imgStoragePath + "lazy_load.png)"}}>
              <div style={{ backgroundImage: "url(" + auditsInfo[0].lopa.image  +")", width: '200px', height: '200px', backgroundPosition: '50%',backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
               </div>
                </div>
             </li> : null
           }
           { (formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save) && !auditsInfo[0].lopa.image ?
             <li className="list-inline-item dropable-zone lopa-photo"
             onDrop={(evt) => { evt.preventDefault(); evt.stopPropagation(); this.coverUpload(evt.dataTransfer.files)}}
             onDragOver={(evt) => this.onDragOver(evt)}
             onDragLeave={() => this.onFocusOut()}
             style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}} >
              <ContentDrop
                mutlipleEnabled={false}
                acceptExtensions={'image/*'}
                filesUpload={(evt) => this.coverUpload(evt.target.files)}
              />
            </li> : null

          }
          </ul>
          <div style={{marginBottom:'20px',width:'50%'}}>
          {
                GetInputField(
                  'string',
                  <label className="for-label">Remarks</label>,
                  {
                    type: 'edit',
                    value: auditsInfo[0].lopa.remarks ?auditsInfo[0].lopa.remarks : null,
                    updateForm: (value) => this.updateLopa('remarks',value)
                  }
                )
              }
            </div>
           </div>:null}


            { auditsInfo[0].specs && Object.keys(auditsInfo[0].specs).length && auditsInfo[0].show_specs_on_inspection ?
              <div className="audits-field-cn">
                <Row>
                  { Object.keys(auditsInfo[0].specs).filter(key => key == 'is_fitted').length?
                    <Col key={'is_fitted'} md="12" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                      { this.generateFieldDom(auditsInfo[0],auditsInfo[0]['id'], 'is_fitted', 'specs', 0) }
                    </Col>
                    :null
                  }
                  { Object.keys(auditsInfo[0].specs).map(key => key != 'is_fitted'?
                    <Col key={key} md="4" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                      { this.generateFieldDom(auditsInfo[0],auditsInfo[0]['id'], key, 'specs', 0) }
                    </Col>:null
                  )}
                </Row>
              </div>:null
            }
           { auditsInfo[0].component_questions && auditsInfo[0].component_questions.length ?
             auditsInfo[0].component_questions.map((item, componentIndex) =>
              <div className="audits-field-cn component" key={componentIndex}>
                <h4 className="para">{ item.name ? item.name: '' }</h4>
                <Row style={{position: 'relative'}}>
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && item.is_custom_component ?
                    <span className="custom-class" onClick={() => this.removeComponent(auditsInfo[0]['id'], componentIndex, item)} style={{position: 'absolute', cursor: 'pointer', right: '10px', 'top': '10px',zIndex: 9}}>
                      <img width="12" src={imgStoragePath+"delete_red.png"} />
                    </span> : ''
                  }
                  { item.specs ? Object.keys(item.specs).map(key =>
                    <Col key={key} md="4" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                      { this.generateFieldDom(item, auditsInfo[0]['id'], key, 'components', componentIndex)}
                    </Col>
                    ) : null
                  }
                  { item.sub_components && item.sub_components.length ?
                    item.sub_components.map((subComponent, subIndex) =>
                      <div className="audits-field-cn component" key={subIndex}>
                        <h4 className="para">{ subComponent.name }</h4>
                        <Row>
                          { subComponent.specs ? Object.keys(subComponent.specs).map(key =>
                            <Col key={key} md="4" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                              { this.generateFieldDom(subComponent, auditsInfo[0]['id'], key, 'subcomponents', item.id, subIndex)}
                            </Col>
                            ): null
                          }
                        </Row>
                      </div>
                    ):null
                  }
                </Row>

              </div>
            ):null
          }
            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && auditsInfo[0]['allow_dynamic'] ?
              <div className="add-finding-link" style={{textAlign: 'left', marginBottom: '10px'}}><Link onClick ={() => this.generateDynamicContent('section', auditsInfo[0], auditsInfo[0].id) }>+ Add Item</Link></div>
              : null
            }
            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && auditsInfo[0]['component_questions']&& auditsInfo[0]['component_questions'].length && auditsInfo[0]['component_questions'][0]['allow_dynamic'] ?
              <div className="add-finding-link" style={{textAlign: 'left', marginBottom: '10px'}}><Link onClick ={() => this.generateDynamicContent('component',  auditsInfo[0].component_questions[0], auditsInfo[0].id, auditsInfo[0].component_questions[0].id) }>+ Add Item</Link></div>
              : null
            }

            <Nav tabs className="commons-tabs">
              { ['Observations','Findings'].map((item, index) =>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === index })}
                    onClick={() => { this.toggle(index); trackActivity('Item Clicked', { page_title: 'T004', application_module: 'Inspection',  item_type:item })}}
                  >
                    {item}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={0}>
                <table className="observation-lists">
                  <tbody>
                    { auditsInfo[0].physical_questions.map((item,index) =>
                      <Observations
                        formInfo={formInfo}
                        item={item}
                        key={index}
                        findingId={item.id}
                        answer = {item.answer}
                        updateObservations = {(findingId, value, label) => this.updateObservations(findingId, value, label, auditsInfo[0].id)}
                      />
                    )}
                    <tr>
                      <td colSpan="2" className="additional-remark-block">
                        { this.generateFieldDom(auditsInfo[0], auditsInfo[0]['id'], 'additional_remarks', 'additional_remarks') }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TabPane>
              <TabPane tabId={1} style={{marginBottom: '30px'}}>
                { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                  <p className="add-finding-link">
                    <Link onClick={() => this.props.findingCrudFn('add', '', auditsInfo[0].id)}>+ Add Finding</Link>
                  </p>:null
                }
                <table className="finding-tables">
                  <thead>
                    <tr>
                      { ['SL No.', 'Type of Damage', 'Description',"Classification", "Category", "Repair type", 'Action'].map((item, index) => <th key={index}>{item}</th>)}

                    </tr>
                  </thead>
                  <tbody>
                    { auditsInfo[0].inspection_findings ?
                      auditsInfo[0].inspection_findings.map((item, index) =>
                      <FindingList
                        formInfo={formInfo}
                        index= {index}
                        addEditFindingFn={() => this.props.addEditFindingFn('edit', item, auditsInfo[0].id)}
                        item={item}
                        findingCrudFn={() => this.props.findingCrudFn('edit', item, item.aircraft_section)} item={item}
                        toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                      />
                    ): null}
                  </tbody>
                </table>
                { !(Object.keys(auditsInfo).length && auditsInfo[0].inspection_findings && auditsInfo[0].inspection_findings.length) ?
                  <NoRecordFound style={{ 'margin': '0 0 30px 0' , borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                  : null
                }
              </TabPane>
            </TabContent>
          <div style={{height:'60px'}}></div>
          </div> :
          upperCabinTab ?
          <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
            <Nav tabs className="commons-tabs">
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeCabinTab === 'main' })}
                  onClick={() => {
                    this.setState({
                      activeCabinTab: 'main',
                      activeMainTab: initMain[0].index + 3,
                      activeTab: 0
                    })
                  }}
                >
                  Main Deck
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeCabinTab === 'upper' })}
                  onClick={() => {
                    this.setState({
                      activeCabinTab: 'upper',
                      activeMainTab: initLower[0].index + 3,
                      activeTab: 0
                    })
                  }}
                >
                  Upper Deck
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeCabinTab}>
                <TabPane tabId="main">
                  <div>
                      <Nav tabs className="commons-tabs">
                        { auditsInfo.map((data, index) =>
                          upGalleys.indexOf(data.id) == -1 ?
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: this.state.activeMainTab === (index+3) })}
                              onClick={() => { this.toggleTab(index+3); }}
                            >
                              {data['name']}
                            </NavLink>
                          </NavItem>
                          :null
                        )}
                      </Nav>
                      <TabContent activeTab={this.state.activeMainTab}>
                        { auditsInfo.map((data, index) =>
                          upGalleys.indexOf(data.id) == -1 ?
                          <TabPane tabId={index+3} key={index}>
                            { data.specs && Object.keys(data.specs).length ?
                              <div className="audits-field-cn">
                                <Row>
                                  { Object.keys(data.specs).map(key =>
                                    <Col md="4"  style={{marginBottom: '20px'}}>
                                      {  this.generateFieldDom(data, data['id'], key, 'specs', 0) }
                                     </Col>
                                  )}
                                </Row>
                              </div>:null
                            }
                            { data.component_questions && data.component_questions.length ?
                              data.component_questions.map((item, componentIndex) =>
                                <div className="audits-field-cn component" key={componentIndex}>
                                  <h4 className="para">{ item.name ? item.name : ''}</h4>
                                  <Row style={{position: 'relative', zIndex:9}}>
                                    { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && item.is_custom_component ?
                                      <span className="custom-class" onClick={() => this.removeComponent(auditsInfo[0]['id'], componentIndex, item)}>
                                        <img width="12" src={imgStoragePath+"delete_red.png"} />
                                      </span> : ''
                                    }
                                    { item.specs ? Object.keys(item.specs).map(key => {
                                      return(
                                        <Col md="4"  style={{marginBottom: '20px'}}>
                                          { this.generateFieldDom(item, data['id'], key, 'components', componentIndex)}
                                         </Col>
                                        )}
                                      ): null
                                    }
                                    { item.sub_components && item.sub_components.length ?
                                      item.sub_components.map((subComponent, subIndex) =>
                                        <div className="audits-field-cn component">
                                          <h4 className="para">{ subComponent.name }</h4>
                                          <Row>
                                            { subComponent.specs ? Object.keys(subComponent.specs).map(key =>
                                              <Col md="4"  style={{marginBottom: '20px'}} key={key}>
                                                { this.generateFieldDom(subComponent, data['id'], key, 'subcomponents', item.id, subIndex)}
                                              </Col>
                                              ): null
                                            }
                                          </Row>
                                        </div>
                                        ):null
                                      }
                                    </Row>
                                  </div>
                                ):null
                              }
                              { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && auditsInfo[0]['allow_dynamic'] ?
                                <div className="add-finding-link" style={{textAlign: 'left', marginBottom: '10px'}}><Link onClick ={() => this.generateDynamicContent('section', data, data.id) }>+ Add Item</Link></div>
                                : null
                              }
                              <Nav tabs className="commons-tabs">
                                { ['Observations','Findings'].map((item, index) =>
                                  <NavItem key={index}>
                                    <NavLink
                                      className={classnames({ active: this.state.activeTab === index })}
                                      onClick={() => { this.toggle(index); }}
                                    >
                                      {item}
                                    </NavLink>
                                  </NavItem>
                                )}
                              </Nav>
                              <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={0}>
                                  <table className="observation-lists">
                                    <tbody>
                                      { data.physical_questions.map((item,index) =>
                                        <Observations
                                          formInfo={formInfo}
                                          item={item}
                                          key={index}
                                          findingId={item.id}
                                          answer = {item.answer}
                                          updateObservations = {(findingId, value, label) => this.updateObservations(findingId, value, label, data.id)}
                                        />
                                      )}
                                      <tr>
                                        <td colSpan="2" className="additional-remark-block">
                                          { this.generateFieldDom(data, data['id'], 'additional_remarks', 'additional_remarks') }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </TabPane>
                                <TabPane tabId={1}>
                                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                                    <p className="add-finding-link">
                                      <Link onClick={() => this.props.findingCrudFn('add', '', data.id)}>+ Add Finding</Link>
                                    </p>:null
                                  }

                                  <table className="finding-tables">
                                    <thead>
                                      <tr>
                                        { ['SL No.','Type of Damage', 'Details',''].map((item, index) => <th key={index}>{item}</th>)}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      { data.inspection_findings ?
                                        data.inspection_findings.map((item, index) =>
                                        <FindingList
                                          formInfo={formInfo}
                                          addEditFindingFn={() => this.props.addEditFindingFn('edit', item, data.id)}
                                          item={item}
                                          index = {index}
                                          findingCrudFn={() => this.props.findingCrudFn('edit', item, item.aircraft_section)} item={item}
                                          toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                                        />
                                      ): null}
                                    </tbody>
                                  </table>
                                  { !(Object.keys(data).length && data.inspection_findings && data.inspection_findings.length) ?
                                    <NoRecordFound style={{ 'margin': '0 0 30px 0', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                                    : null
                                  }
                                </TabPane>
                              </TabContent>
                            </TabPane>:null
                          )
                        }
                      </TabContent>
                    </div>
                </TabPane>
                <TabPane tabId="upper">
                  <div>
                      <Nav tabs className="commons-tabs">
                        { auditsInfo.map((data, index) =>
                          upGalleys.indexOf(data.id) != -1 ?
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: this.state.activeMainTab === (index+3) })}
                              onClick={() => { this.toggleTab(index+3); }}
                            >
                              {data['name']}
                            </NavLink>
                          </NavItem>
                          :null
                        )}
                      </Nav>
                      <TabContent activeTab={this.state.activeMainTab}>
                        { auditsInfo.map((data, index) =>
                          upGalleys.indexOf(data.id) != -1 ?
                          <TabPane tabId={index+3} key={index}>
                            { data.specs && Object.keys(data.specs).length ?
                              <div className="audits-field-cn">
                                <Row>
                                  { Object.keys(data.specs).map(key =>
                                    <Col md="4"  style={{marginBottom: '20px'}}>
                                      {  this.generateFieldDom(data, data['id'], key, 'specs', 0) }
                                     </Col>
                                  )}
                                </Row>
                              </div>:null
                            }
                            { data.component_questions && data.component_questions.length ?
                              data.component_questions.map((item, componentIndex) =>
                                <div className="audits-field-cn component" key={componentIndex}>
                                  <h4 className="para">{ item.name ? item.name : ''}</h4>
                                  <Row style={{position: 'relative', zIndex:9}}>
                                    { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && item.is_custom_component ?
                                      <span className="custom-class" onClick={() => this.removeComponent(auditsInfo[0]['id'], componentIndex, item)}>
                                        <img width="12" src={imgStoragePath+"delete_red.png"} />
                                      </span> : ''
                                    }
                                    { item.specs ? Object.keys(item.specs).map(key => {
                                      return(
                                        <Col md="4"  style={{marginBottom: '20px'}}>
                                          { this.generateFieldDom(item, data['id'], key, 'components', componentIndex)}
                                         </Col>
                                        )}
                                      ): null
                                    }
                                    { item.sub_components && item.sub_components.length ?
                                      item.sub_components.map((subComponent, subIndex) =>
                                        <div className="audits-field-cn component">
                                          <h4 className="para">{ subComponent.name }</h4>
                                          <Row>
                                            { subComponent.specs ? Object.keys(subComponent.specs).map(key =>
                                              <Col md="4"  style={{marginBottom: '20px'}} key={key}>
                                                { this.generateFieldDom(subComponent, data['id'], key, 'subcomponents', item.id, subIndex)}
                                              </Col>
                                              ): null
                                            }
                                          </Row>
                                        </div>
                                        ):null
                                      }
                                    </Row>
                                  </div>
                                ):null
                              }
                              { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && auditsInfo[0]['allow_dynamic'] ?
                                <div className="add-finding-link" style={{textAlign: 'left', marginBottom: '10px'}}><Link onClick ={() => this.generateDynamicContent('section', data, data.id) }>+ Add Item</Link></div>
                                : null
                              }
                              <Nav tabs className="commons-tabs">
                                { ['Observations','Findings'].map((item, index) =>
                                  <NavItem key={index}>
                                    <NavLink
                                      className={classnames({ active: this.state.activeTab === index })}
                                      onClick={() => { this.toggle(index); }}
                                    >
                                      {item}
                                    </NavLink>
                                  </NavItem>
                                )}
                              </Nav>
                              <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={0}>
                                  <table className="observation-lists">
                                    <tbody>
                                      { data.physical_questions.map((item,index) =>
                                        <Observations
                                          formInfo={formInfo}
                                          item={item}
                                          key={index}
                                          findingId={item.id}
                                          answer = {item.answer}
                                          updateObservations = {(findingId, value, label) => this.updateObservations(findingId, value, label, data.id)}
                                        />
                                      )}
                                      <tr>
                                        <td colSpan="2" className="additional-remark-block">
                                          { this.generateFieldDom(data, data['id'], 'additional_remarks', 'additional_remarks') }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </TabPane>
                                <TabPane tabId={1}>
                                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                                    <p className="add-finding-link">
                                      <Link onClick={() => this.props.findingCrudFn('add', '', data.id)}>+ Add Finding</Link>
                                    </p>:null
                                  }

                                  <table className="finding-tables">
                                    <thead>
                                      <tr>
                                        { ['SL No.','Type of Damage', 'Details',''].map((item, index) => <th key={index}>{item}</th>)}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      { data.inspection_findings ?
                                        data.inspection_findings.map((item, index) =>
                                        <FindingList
                                          formInfo={formInfo}
                                          addEditFindingFn={() => this.props.addEditFindingFn('edit', item, data.id)}
                                          item={item}
                                          index = {index}
                                          findingCrudFn={() => this.props.findingCrudFn('edit', item, item.aircraft_section)} item={item}
                                          toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                                        />
                                      ): null}
                                    </tbody>
                                  </table>
                                  { !(Object.keys(data).length && data.inspection_findings && data.inspection_findings.length) ?
                                    <NoRecordFound style={{ 'margin': '0 0 30px 0', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                                    : null
                                  }
                                </TabPane>
                              </TabContent>
                            </TabPane>:null
                          )
                        }
                      </TabContent>
                    </div>
                </TabPane>
            </TabContent>
            <div style={{height:'60px'}}></div>
          </div>
          :<div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
            <div>
              <Nav tabs className="commons-tabs">
                { auditsInfo.map((data, index) =>
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({ active: this.state.activeMainTab === (index+3) })}
                      onClick={() => { this.toggleTab(index+3); }}
                    >
                      {data['name']}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={this.state.activeMainTab}>
                { auditsInfo.map((data, index) =>
                  <TabPane tabId={index+3} key={index}>
                    { data.specs && Object.keys(data.specs).length ?
                      <div className="audits-field-cn">
                        <Row>
                          { Object.keys(data.specs).map(key =>
                            <Col md="4"  style={{marginBottom: '20px'}}>
                              {  this.generateFieldDom(data, data['id'], key, 'specs', 0) }
                             </Col>
                          )}
                        </Row>
                      </div>:null
                    }
                    { data.component_questions && data.component_questions.length ?
                      data.component_questions.map((item, componentIndex) =>
                        <div className="audits-field-cn component" key={componentIndex}>
                          <h4 className="para">{ item.name ? item.name : ''}</h4>
                          <Row style={{position: 'relative', zIndex:9}}>
                            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && item.is_custom_component ?
                              <span className="custom-class" onClick={() => this.removeComponent(auditsInfo[0]['id'], componentIndex, item)}>
                                <img width="12" src={imgStoragePath+"delete_red.png"} />
                              </span> : ''
                            }
                            { item.specs ? Object.keys(item.specs).map(key => {
                              return(
                                <Col md="4"  style={{marginBottom: '20px'}}>
                                  { this.generateFieldDom(item, data['id'], key, 'components', componentIndex)}
                                 </Col>
                                )}
                              ): null
                            }
                            { item.sub_components && item.sub_components.length ?
                              item.sub_components.map((subComponent, subIndex) =>
                                <div className="audits-field-cn component">
                                  <h4 className="para">{ subComponent.name }</h4>
                                  <Row>
                                    { subComponent.specs ? Object.keys(subComponent.specs).map(key =>
                                      <Col md="4"  style={{marginBottom: '20px'}} key={key}>
                                        { this.generateFieldDom(subComponent, data['id'], key, 'subcomponents', item.id, subIndex)}
                                      </Col>
                                      ): null
                                    }
                                  </Row>
                                </div>
                                ):null
                              }
                            </Row>
                          </div>
                        ):null
                      }
                      { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && auditsInfo[0]['allow_dynamic'] ?
                        <div className="add-finding-link" style={{textAlign: 'left', marginBottom: '10px'}}><Link onClick ={() => this.generateDynamicContent('section', data, data.id) }>+ Add Item</Link></div>
                        : null
                      }
                      <Nav tabs className="commons-tabs">
                        { ['Observations','Findings'].map((item, index) =>
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === index })}
                              onClick={() => { this.toggle(index); }}
                            >
                              {item}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={0}>
                          <table className="observation-lists">
                            <tbody>
                              { data.physical_questions.map((item,index) =>
                                <Observations
                                  formInfo={formInfo}
                                  item={item}
                                  key={index}
                                  findingId={item.id}
                                  answer = {item.answer}
                                  updateObservations = {(findingId, value, label) => this.updateObservations(findingId, value, label, data.id)}
                                />
                              )}
                              <tr>
                                <td colSpan="2" className="additional-remark-block">
                                  { this.generateFieldDom(data, data['id'], 'additional_remarks', 'additional_remarks') }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </TabPane>
                        <TabPane tabId={1}>
                          { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                            <p className="add-finding-link">
                              <Link onClick={() => this.props.findingCrudFn('add', '', data.id)}>+ Add Finding</Link>
                            </p>:null
                          }

                          <table className="finding-tables">
                            <thead>
                              <tr>
                                { ['SL No.','Type of Damage', 'Details',''].map((item, index) => <th key={index}>{item}</th>)}
                              </tr>
                            </thead>
                            <tbody>
                              { data.inspection_findings ?
                                data.inspection_findings.map((item, index) =>
                                <FindingList
                                  formInfo={formInfo}
                                  addEditFindingFn={() => this.props.addEditFindingFn('edit', item, data.id)}
                                  item={item}
                                  index = {index}
                                  findingCrudFn={() => this.props.findingCrudFn('edit', item, item.aircraft_section)} item={item}
                                  toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                                />
                              ): null}
                            </tbody>
                          </table>
                          { !(Object.keys(data).length && data.inspection_findings && data.inspection_findings.length) ?
                            <NoRecordFound style={{ 'margin': '0 0 30px 0', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                            : null
                          }
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  )
                }
              </TabContent>
            </div>
            <div style={{height:'60px'}}></div>
          </div>
        }
        <div className="edit-sidebar-footer">
          <ul className="list-inline">
            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
              <li className="list-inline-item">
                <input className="primary-btn" type="submit" value="Save Changes" />
              </li>:null
            }

            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
          </ul>
        </div>
        <DeleteModal isOpen={this.state.delModal}
          toggle={() => this.toggleDelModal()}
          title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal();this.props.deleteFindingFn(this.state.findingId, this.state.aircraft_section)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
      </form>
    )
  }
}
