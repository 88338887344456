import React, { Component } from 'react';
import { Link } from 'react-router';

export default class BulkOperationShopVisit extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { generalDetails, error, index } = this.props;
  return(
    <div className="bulk-operation" >
        <Link className="add-attachment">+ Add Attachments
          <input type="file" onChange={(e) => this.props.GenUrlFn(e.target.files, 'shop_visit',index)}  onClick={(e)=> {e.target.value = null}} accept=".xls,.xlsx,application/pdf"/>
        </Link>
    </div>
    );
  }
}
