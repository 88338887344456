import React from 'react';
import { Row, Col } from 'reactstrap';
import { utilizationFormat, displayDateFormat } from '../../../../constants';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
const EventUtilsInfo = (props) => {
  const { halfLifeItm } = props;
  return(
    <div className="util-outer-wrap">

    <h4 className="util-heading">Utilization { window.safari ? halfLifeItm.as_of_date ? `as of ${moment(halfLifeItm.as_of_date, 'YYYY-MM-DD').format(displayDateFormat)}` :'' :halfLifeItm.as_of_date ? ' as of ' + moment(halfLifeItm.as_of_date).format(displayDateFormat):'' }</h4>
      <Row className="utilization-block">
        <Col md="3" className="adj-inner-block">
          <h6>TSN</h6>
         {halfLifeItm.tsn ? <NumericLabel params={utilizationFormat}>{halfLifeItm.tsn}</NumericLabel>  : '--'}
        </Col>
        <Col md="3" className="adj-inner-block">
          <h6>CSN</h6>
          <span>{halfLifeItm.csn ? Math.round(halfLifeItm.csn) : '--'}</span>
        </Col>
        <Col md="3" className="adj-inner-block margin-bottom-0">
          <h6>Last { halfLifeItm.type == 'airframe'? 'Event ': 'Shop Visit ' } TSN</h6>
          <span>{halfLifeItm.last_event_tsn ? halfLifeItm.last_event_tsn : '--'}</span>
        </Col>
        <Col md="3" className="adj-inner-block margin-bottom-0">
          <h6>Last { halfLifeItm.type == 'airframe'? 'Event ': 'Shop Visit ' } CSN</h6>
          <span>{halfLifeItm.last_event_csn ? halfLifeItm.last_event_csn : '--'}</span>
        </Col>
        <Col md="3" className="adj-inner-block">
          <h6>Avg. Monthly Hours</h6>
          {halfLifeItm.average_monthly_hours ?
           <NumericLabel params={utilizationFormat}>{halfLifeItm.average_monthly_hours}</NumericLabel>: '--'
           }
        </Col>
        <Col md="3" className="adj-inner-block">
          <h6>Avg. Monthly Cycles</h6>
          <span>{halfLifeItm.average_monthly_cycles ? halfLifeItm.average_monthly_cycles : '--'}</span>
        </Col>
        <Col md="3" className="adj-inner-block margin-bottom-0">
          <h6>Time Remaining</h6>
          <span>{halfLifeItm.time_remaining ? halfLifeItm.time_remaining : '--'}</span>
        </Col>
        <Col md="3" className="adj-inner-block margin-bottom-0">
          <h6>Cycles Remaining</h6>
          <span>{halfLifeItm.cycles_remaining ? halfLifeItm.cycles_remaining : '--'}</span>
        </Col>

      </Row>
      </div>
  )
}
export default EventUtilsInfo;
