import * as actions from '../actions';
export default function( state = {
  workingGroupList: [],
  teamView: {
    flag: false,
    index: 0,
    data: {}
  },

  workingGroupCrud: {
    type: '',
    data: {},
    flag: false
  },
  workingGroupMemCrud: {
    type: '',
    data: {},
    flag: false
  },

  workingGrpLoader: true
}, action ){
  switch (action.type) {
    case actions.WORKING_GROUP_LIST:
      return {
        ...state,
        workingGroupList: action.payload
      };
    case actions.WORKING_GROUP_VIEW:
      return {
        ...state,
        teamView: {
          ...state.teamView,
          flag: true,
          index: action.index,
          data: action.payload
        }
      }

    case actions.WORKING_GROUP_ADD_SUCCESS:
      return {
        ...state,
        workingGroupList: [ action.payload, ...state.workingGroupList ]
      }
    case actions.WORKING_GROUP_EDIT_SUCCESS:
      return {
        ...state,
        workingGroupList: state.workingGroupList.map((workingGroup) => workingGroup.id == action.payload.id ? { ...workingGroup, ...action.payload } : workingGroup),
        teamView: {
          ...state.teamView,
          flag: false,
          data: {}
        }
      }
    case actions.WORKING_GROUP_MEMBER_ADD:
      return {
        ...state,
        workingGroupList: state.workingGroupList.map((workingGroup, index) => workingGroup.id == action.payload.working_group_id ? { ...workingGroup, working_group_member: [action.payload, ...workingGroup.working_group_member] } : workingGroup ),
      }
    case actions.WORKING_GROUP_MEMBER_EDIT:
      return {
        ...state,
        workingGroupList: state.workingGroupList.map(workingGroup => workingGroup.id == action.payload.working_group_id ? {...workingGroup, working_group_member: workingGroup.working_group_member.map(tx => tx.id == action.payload.id ? action.payload: tx)} : workingGroup),
      }
    case actions.CLOSE_TEAM_SIDE_BAR:
      return {
        ...state,
        teamView: {
          ...state.teamView,
          flag: false,
          index: '',
          data: {}
        }
      }
  
    case actions.WORKING_GRP_LOADER:
      return {
        ...state,
        workingGrpLoader: action.payload
      }
    case actions.WORKING_GRP_CRUD:
      return {
        ...state,
        workingGroupCrud: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        [action.actionType]: {
          ...state[action.actionType],
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.WORKING_GRP_MEM_CRUD:
      return {
        ...state,
        workingGroupMemCrud: action.payload
      }
    
    default:
      return state;
  }
}
