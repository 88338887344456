import React, { Component } from 'react';
import { getFileSize, localTimeFn } from '../../../../utils';
import { Link } from 'react-router';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
class FolderInfo extends Component{
  constructor(props){
    super(props);
  }

  render(){
    const { style, previewInfo, recordDetail } = this.props;
    return(
      <div className="folder-info" style={style}>
        <span className="close-folder-info" onClick={this.props.toggleFolderInfo}>X</span>
        <div className="folder-details">
          <img src={imgStoragePath+"folder_icn3.png"} width="30"/>
          <span className="folder-title"><h4>{previewInfo.name}</h4></span>
          <div className="folder-info-inner">
            <table>
              {
                Object.keys(recordDetail).length ?
                <tbody>
                  <tr>
                    <td>Created At:</td>
                    <td>{localTimeFn(recordDetail.created_at)} </td>
                  </tr>
                  <tr>
                    <td>Created By:</td>
                  <td>{recordDetail.created_by && recordDetail.created_by != null ? `${recordDetail.created_by}` : '--'} </td>
                  </tr>
                  <tr>
                    <td>Modified At:</td>
                    <td> {localTimeFn(recordDetail.modified_at)}</td>
                  </tr>
                  <tr>
                    <td>Modified By:</td>
                  <td>{recordDetail.modified_by && recordDetail.modified_by != null ? `${recordDetail.modified_by}` : '--'} </td>
                  </tr>
                  <tr>
                    <td>Size:</td>
                    <td>{getFileSize(recordDetail.size)}</td>
                  </tr>
                  <tr>
                    <td>Contains:</td>
                    <td>{ previewInfo.file_count  ? previewInfo.file_count : 0 } Files</td>
                  </tr>
                </tbody>
                :
                <tbody>
                  <tr>
                    <td colSpan="2" style={{textAlign: 'center'}}>Loading...</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
          {/* <button type="button" className="add-new">Share</button> */}
        </div>
      </div>
    )
  }
}

export default FolderInfo;
