import React, { Component } from 'react';
import { Link } from 'react-router';
import {  Row, Col } from 'reactstrap';
import 'react-select/dist/react-select.css';
import Select from 'react-select';
import EngineDropDown from '../containers/EngineDropDown';
export default class EngineInfCard extends Component{
  constructor(props){
    super(props);
  }
  createEngine = (engines) => {
    let list = [];
    for (let j = 1; j <= engines; j++) {
      list.push(<li style={{paddingRight: '15px'}} className="list-inline-item" key={j}><label style={{cursor: 'pointer'}}><input checked={this.props.engineInfo.noOfEngine == j ? true:false} style={{cursor: 'pointer'}} onChange={() => this.noOfEngine(j)} name="enigne_no" type="radio"/><span style={{paddingLeft: '5px'}}>{j}</span></label></li>);
    }
    return list
  }
  noOfEngine = (number) => {
    this.props.noOfEngineUpdate(number, 'noOfEngine');
  }
  render() {
    const  {engineInfo, engineTypeList, regionsList, engineUtilRatio, regionCondition, errors,aircraftEngineTypeList, metricsInfo} = this.props;
    var regionCountries = []
    if(regionsList.filter(region => region.id === engineInfo.regionOfOperation).length){
      regionsList.filter(region => region.id === engineInfo.regionOfOperation)[0].country.map(contry => {
        regionCountries.push({id: contry, label: contry})
      })
    }
    return(
      <div className="info-card">
        <div className="engine-info-card">
          <h6>Enter Engine Information</h6>
          <Row className="outer-wrap">
            <Col md="3" className="inner-wrap">
              <label>Engine Type <sup className="reqStar"> * </sup></label>
              <EngineDropDown
                selectedValue= {engineInfo.engineType}
                values={metricsInfo.indexOf('airframe') != -1 ? aircraftEngineTypeList :engineTypeList}
                placeholder = "Select Engine Type"
                type="engineType"
                labelKey = "name"
                valueKey = "id"
                disabled = {false}
                regionId={engineInfo.regionOfOperation}
                updateErrorCode={this.props.updateErrorCode}
                error={errors['engineType']}
              />
            <h6 className="error-msg">{errors['engineType']}</h6>
            </Col>
            {
              engineInfo.noOfEngines > 0 && ((metricsInfo.indexOf('airframe') >= 0) || (metricsInfo.indexOf('lg') >= 0) || (metricsInfo.indexOf('apu') >= 0) )  ?
              <Col md="3" className="inner-wrap">
                <label>No. of Engines</label>
                <ul className="list-inline engine-no-block">
                  { this.createEngine(engineInfo.noOfEngines)}
               </ul>
             </Col>:null
            }

           <Col md="3" className="inner-wrap adjust-link-block">
             <label>Region of operation <sup className="reqStar"> * </sup></label>
             <EngineDropDown
               selectedValue= {engineInfo.regionOfOperation}
               values={regionsList}
               type="regionOfOperation"
               labelKey = "name"
               placeholder = "Select Region"
               valueKey = "id"
               disabled = { engineInfo.engineType ? false:true}
               error={errors['regionOfOperation']}
               updateErrorCode={this.props.updateErrorCode}
             />
           <h6 className="error-msg">{errors['regionOfOperation']}</h6>
           { regionCondition && Object.keys(regionCondition).length ?
             <Link className="adjust-link" onClick = {() => this.props.toggleRegion(true)}>Adjust Region Variables</Link>:null
           }
           { engineInfo.variable_condition_id.length  ? <span className="custom-region-sel" onClick={() => this.props.resetCustomRegion(engineInfo.regionOfOperation,engineInfo.engineType)}>Custom Region Variable Applied <span>x</span></span>:null }
          </Col>
          {
            regionsList.filter(region => region.id === engineInfo.regionOfOperation).length && regionsList.filter(region => region.id === engineInfo.regionOfOperation)[0].country.length ?
            <Col md="3" className="inner-wrap adjust-link-block">
              <label>Country Of Operation</label>
              <EngineDropDown
                selectedValue= {engineInfo.region_country}
                values={regionCountries.length ? regionCountries : []}
                type="region_country"
                valueKey = "id"
                labelKey = "label"
                placeholder = "Select Country"
                disabled = { engineInfo.engineType ? false:true}
                error={errors['region_country']}
                updateErrorCode={this.props.updateErrorCode}
              />
           </Col>
            :null
          }
          <Col md="3" className="inner-wrap">
              <label>Utilization Ratio <sup className="reqStar"> * </sup></label>
              <EngineDropDown
                selectedValue= {engineInfo.utlizationRatio}
                values={engineUtilRatio}
                type="utlizationRatio"
                placeholder = "Select Utilization Ratio"
                labelKey = "label"
                valueKey = "value"
                disabled = {false}
                error={errors['utlizationRatio']}
                updateErrorCode={this.props.updateErrorCode}
               />
             <h6 className="error-msg">{errors['utlizationRatio']}</h6>
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}
