import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class DescriptionComponent extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="description-section">
              <Container>
                <Row className="description-row">
                  <Col lg="12">
                    <p className="text-center">{ this.props.description }</p>
                  </Col>
                </Row>
              </Container>
            </div>
        )
    }
}
