import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import List from '../components/List';
import BulkOperation from '../components/BulkOperation';
import AddEditProject from '../components/AddEditProject';
import { EditFormBar,DeleteModal, UserInfoModal } from '../../Elements'
import { TableUIComp, ExportFiles, MSNSwitcherHeader, ListLoader, LicenceModal, BuyLicenseModal } from '../../../../shared';
import { sLesseeListAc, sGlobalConstantAc } from '../../../../shared/actionCreators';
import {createProject} from '../../Forms/AddFormObjects';
import HelpTextInspectionType from  '../components/HelpTextInspectionType';
import { getUsageDetailAc, projectViewCrudAc, fetchProjectListAc, addEditProjectAc, fetchProjectUserAc,closeProjectAc, technicalInspectionTypeAc, getGeneralInstructionAc,exportcontactListExportAc,fetchProjectFormAc, getUserDetailAc } from '../actionCreators';
import { TOGGLE_PROJECT_SIDEBAR, CLOSE_USER_MODAL } from '../actions';
import { BUY_LICENCE } from '../../../../shared/actions';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import {imgStoragePath} from '../../../../constants';

class ProjectLists extends Component{
  constructor(props){
    super(props);
    this.state = {
       activeTab:'all',
       workOrderInfoFlag: false,
    }
  }

  componentWillReceiveProps (nextProps){
    let location = browserHistory.getCurrentLocation();
    if(location.query.project_status && this.state.activeTab != location.query.project_status){
      this.componentDidMount()
    }
  }

  licenceModal = () =>{
    this.props.buyLicence({
      flag: true,
      action: '/licenses/inspections',
      content: 'License not available for creating more Inspections. Please buy new license to continue.'
    })
  }

  componentDidMount(){

    if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.is_saas){
			this.props.getUsageDetail()
		}
    let location = browserHistory.getCurrentLocation();
    if(location.query != null && location.query != undefined){
      if(location.query.project_status!=null&&location.query.project_status != undefined){
      this.setState({
        activeTab: location.query.project_status,
      })
      if(location.query.project_status == 'all'){
        delete location.query ['project_status'];
      }
    }else{
      this.setState({
        activeTab: 'all',
      })
    }
      this.props.fetchProjectListing(location.query,false);
    }else {
      this.props.fetchProjectListing({},true);
    }
    this.props.fetchLesseeList();
    this.props.fetchProjectEngineer({role: 'engineer'}, 'projectEngineer');
    this.props.fetchProjectLead({role: 'lead'}, 'projectLead');
    this.props.fetchTechnicalInspection('inspectionType');
    this.props.fetchProjectDepartment({constant_types:['project_departments','project_name']});
    this.props.fetchGenInstruction();
    this.props.fetchForms();
    trackActivity('Page Visited',{
      application_module: 'Inspection',
      page_title: 'Project Listing'
    })
  }


  toggleWorkorderInfo=()=> {
    this.setState(prevState => ({
      ...prevState,
      workOrderInfoFlag: !prevState.workOrderInfoFlag
    }))
  }

  rowActivity = (type, item) => {
    if(type == 'edit'){
      this.props.toggleEditAddSide({type: type, flag: true, data: item }, item.slug )
    }
  }

  sortTable = (sortKey) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
        query: {
          ...location.query,
          sort: sortKey,
          page:1,
          per_page: this.props.pagination.per_page,
          sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }

    browserHistory.push(location);
    this.props.fetchProjectListing(location.query,false);
	}

	projectListingType = (type) => {
    this.setState({
      activeTab:type
    })
    if(type == 'all'){
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
          query: {
            ...location.query,
            project_status:type
            }
        }
      browserHistory.push(location);
      delete location.query ['project_status'];
      this.props.fetchProjectListing(location.query,false);
    }else{
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
          query: {
            ...location.query,
            project_status:type
            }
        }
      browserHistory.push(location);
      this.props.fetchProjectListing(location.query,false);
    }
  }
  changeFilterFn=()=>{
    let location = browserHistory.getCurrentLocation();
    this.props.fetchProjectListing(location.query,false);
  }

  changePerPage=(count) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'per_page': count,
        'page': 1
      }
    }
    browserHistory.push(location);
    this.props.fetchProjectListing(location.query,false);
  }
  changePagination = (type, currentPage) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'page': currentPage
      }
    }
    browserHistory.push(location);
    this.props.fetchProjectListing(location.query,false);
  }

  contactListExport=(fileType)=>{
    let location = browserHistory.getCurrentLocation();
    this.props.contactListExport(fileType,location.query);
  }
  render(){
    const menuList = {
      'Project Name': {
        'inputType': 'text',
        'keyParam': 'name',
        'placeholder': 'Search by Project Name',
        'label': 'Project Name'
      },
      'Project Number': {
        'inputType': 'text',
        'keyParam': 'project_number',
        'placeholder': 'Search by Project Number',
        'label': 'Project Number'
      },
      'Serial Number': {
        'inputType': 'text',
        'keyParam': 'msn',
        'placeholder': 'Search by Asset serial number',
        'label': 'Serial Number'
      },
      'Project Lead / Project Engineer': {
        'inputType': 'text',
        'keyParam': 'username',
        'placeholder': 'Search by Project Lead / Project Engineer',
        'label': 'Project Lead / Project Engineer'
      },
      'Location': {
        'inputType': 'text',
        'keyParam': 'location',
        'placeholder': 'Search',
        'label': 'Location'
      },
      'Forms Type': {
        'inputType': 'dropdown',
        'keyParam': 'forms',
        'placeholder': 'Search by Forms',
        'label': 'Forms Type',
        'options':this.props.formList,
        'labelKey':'name',
        'valueKey':'id',
        'multi': false
      },
      'Lessee': {
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'placeholder': 'Search by Lessee',
        'label': 'Lessee',
        'options':this.props.sLesseeList,
        'labelKey':'name',
        'valueKey':'id',
        'multi': false

      }
    }
    const { inspUsage, projectList, pagination, projectCrud, projectLoader, sLesseeList, genInstruction, projectExportLoader,techConstants,projectCounts, formList } = this.props;
    const {activeTab}= this.state;

    return(
      <div className="technical-inspection-cn project-listing">
        <div className="title-block">
            <h1>Projects</h1>
            <p>Offers a ‘LIST’ view of all Technical and Asset Management (TAM) projects created in SPARTA</p>
            <div className="download-links" style={{position:"relative",top:"-11px"}}>
             <span style={{float:'right',marginTop:'20px'}}>
             <a href="https://itunes.apple.com/us/app/sparta-acumen-aviation/id1270942972?mt=8" target="_blank" style={{display:'inline',padding:'0px',border:'none'}} onClick={()=>trackActivity('Item Clicked', { page_title: 'Project Listing', application_module: 'Inspection',  item_type:'Inspection App (iOS))'})}>
            <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
            <a href="https://play.google.com/store/apps/details?id=acumenaviation.sparta.app"  target="_blank" style={{display:'inline',padding:'0px',border:'none'}} onClick={()=>trackActivity('Item Clicked', { page_title: 'Project Listing', application_module: 'Inspection',  item_type:'Inspection App (Android)'})}>
            <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a>
            </span>
            <p style={{fontSize:'13px',fontWeight:'300',float:"right", position:"relative", left:"208px"}}>Access Sparta Technical App on</p>
            </div>
        </div>
        <div className="technical-projects">
          <div className="projects-tab">
            <ul className="list-inline ui-tabs">
              <li className="list-inline-item ">
                <a className={activeTab=='all'?"active":''} onClick = {() => this.projectListingType('all')}>All ({projectCounts.all})</a>
              </li>
              <li className="list-inline-item">
                <a className={activeTab=='upcoming'?"active":''} onClick = {() => this.projectListingType('upcoming')}>Up Coming ({projectCounts.upcoming})</a>
              </li>
              <li className="list-inline-item">
                <a className={activeTab=='ongoing'?"active":''} onClick = {() => this.projectListingType('ongoing')}>On Going ({projectCounts.ongoing})</a>
              </li>
              <li className="list-inline-item">
                <a className={activeTab=='submitted'?"active":''} onClick = {() => this.projectListingType('submitted')}>Submitted ({projectCounts.submitted})</a>
              </li>
              <li className="list-inline-item">
                <a className={activeTab=='overdue'?"active":''} onClick = {() => this.projectListingType('overdue')}>Overdue ({projectCounts.overdue})</a>
              </li>
              <li className="list-inline-item">
                <a className={activeTab=='completed'?"active":''} onClick = {() => this.projectListingType('completed')}>Completed ({projectCounts.completed})</a>
              </li>
              <li className="list-inline-item">
                <a className={activeTab=='archived'?"active":''} onClick = {() => this.projectListingType('archived')}>Archived({projectCounts.archived})</a>
              </li>
            </ul>
          </div>
          { projectExportLoader || projectLoader ? <ListLoader drawer="primary-nav" />:null}
          <TableUIComp
            theads={[{label:'Project', sortKey: 'project'}, {label:'Assets', sortKey: ''}, {label:'Location', sortKey: ''},{label:'Lessee', sortKey: 'lessee'}, {label:'Start Date', sortKey: 'start_date'},{label:'Expected End Date', sortKey:'end_date'},{label:'Lead By', sortKey:''}, {label:'Status', sortKey: ''}, {label:'', sortKey: ''}]}
            bulkOperation={false}
            content={projectList.length? projectList.map((item, index) => item.status.id != 8 ? <List getUserDetailFn = {this.props.getUserDetailFn}  key={index}  data={item} rowActivity={(type) => this.rowActivity(type, item)} selectItem={(event) => this.props.selectItem(event.target.checked, item.id)} /> : null) : null}
            tableBulkOp = {<BulkOperation projectLoader={projectLoader} licenceModal={this.licenceModal} buyLicence={getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.is_saas == false ? false : inspUsage.available == inspUsage.used} addText= "Create Project" toggleSidebar= {() => this.props.toggleEditAddSide({type: 'add', flag: true, data: createProject})} bulkDelete = {() => this.toggleDelModal('bulk')} contactListExport={(fileType) => this.contactListExport(fileType)} cancelBulkOp={() => this.props.selectAllItem(false)} />}
            pagination = {pagination}
            filter={{menuList: menuList, appliedFilter: {}, displayFilter: sLesseeList.length && formList.length ? true : false, projectInfo:true}}
            selectAllItem={(e) => this.props.selectAllItem(e.target.checked, 'all')}
            sortTable={this.sortTable}
            changePerPage ={this.changePerPage}
            changePagination = {this.changePagination}
            closeFilter = {this.closeFilter}
            searchQuery = {browserHistory.getCurrentLocation().query}
            changeFilterFn={this.changeFilterFn}
            recordsFound = { projectLoader ? false : projectList.length ? false:true}
            queryParamsEnabled={true}
            tableHeight = {window.innerHeight - 285}
          />
        </div>

        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {projectCrud.type == 'add' ? 'Create Project' : 'Edit Project'} </h3></div>}
          toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
          isOpen={projectCrud.flag}
          style={projectCrud.flag ? {width: '900px'} : {width: '0px'}}
          >
          {projectCrud.flag ?
            <AddEditProject
              project={projectCrud}
              toggleEditAddSide={this.props.toggleEditAddSide}
              toggleEditSideBar={this.props.toggleEditAddSide}
              projectLoader={projectLoader}
              addEditProject= {this.props.addEditProject}
              sLesseeList = {sLesseeList}
              workOrderInfo = {this.toggleWorkorderInfo}
              projectEngineer= {this.props.projectEngineer}
              projectLead = {this.props.projectLead}
              projectDepartment = {techConstants.filter(item => item.type == 'project_departments')}
              projectNames = {techConstants.filter(item => item.type == 'project_name')}
              inspectionType = {this.props.inspectionType}
              closeProject = {this.props.closeProject}
              genInstruction = {genInstruction}
              />
            :null
          }
        </EditFormBar>

        <UserInfoModal
          userDetail = {this.props.userDetail}
          closeUserDetailModal = {this.props.closeUserDetailModal}
        />

        <DeleteModal isOpen={this.state.workOrderInfoFlag}
         toggle={() => this.toggleWorkorderInfo()}
         title={'Nomenclature'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <HelpTextInspectionType/>
        </DeleteModal>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="3"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  projectList: state.PorjectsReducer.projectList,
  pagination: state.PorjectsReducer.pagination,
  projectCrud: state.PorjectsReducer.projectCrud,
  projectLoader: state.PorjectsReducer.projectLoader,
  sLesseeList: state.sharedReducers.sLesseeList,
  techConstants:state.sharedReducers.techConstants,
  buyLicenceModal:state.sharedReducers.buyLicenceModal,
  projectEngineer: state.PorjectsReducer.projectEngineer,
  projectLead: state.PorjectsReducer.projectLead,
  inspectionType: state.PorjectsReducer.inspectionType,
  genInstruction: state.PorjectsReducer.genInstruction,
  projectExportLoader:state.PorjectsReducer.projectExportLoader,
  projectCounts:state.PorjectsReducer.projectCounts,
  formList:state.PorjectsReducer.formList,
  userDetail: state.PorjectsReducer.userDetail,
  inspUsage: state.PorjectsReducer.inspUsage,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    toggleEditAddSide: (data, slug) => dispatch(projectViewCrudAc(ownProps, data, slug)),
    fetchProjectListing: (queryParam={},initial) => dispatch(fetchProjectListAc(ownProps,queryParam,initial)),
    addEditProject: (data, type) => dispatch(addEditProjectAc(ownProps.param, data, type)),
    fetchLesseeList: () => dispatch(sLesseeListAc()),
    fetchProjectEngineer: (queryParam, type) => dispatch(fetchProjectUserAc(queryParam, type)),
    closeProject:(projectSlug)=>dispatch(closeProjectAc(projectSlug)),
    contactListExport: (type,queryParam={}) => dispatch(exportcontactListExportAc(type,queryParam)),
    fetchProjectLead: (queryParam, type) => dispatch(fetchProjectUserAc(queryParam, type)),
    fetchProjectDepartment: (type) => dispatch(sGlobalConstantAc(type)),
    fetchTechnicalInspection: (type) => dispatch(technicalInspectionTypeAc(type)),
    fetchGenInstruction: () => dispatch(getGeneralInstructionAc()),
    fetchForms: () => dispatch(fetchProjectFormAc({both:true})),
    getUserDetailFn : (userSlug, flag) => dispatch(getUserDetailAc(userSlug, flag)),
    closeUserDetailModal: (data, flag) => dispatch({
      type: CLOSE_USER_MODAL,
      payload: {data: data, flag: flag}
    }),
    getUsageDetail: () => dispatch(getUsageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectLists);
