import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import { browserHistory } from 'react-router';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { removeEmptyKey } from '../../../../utils';
import { LOG_FILTER_UPDATE, LOGS_LOADER } from '../actions';
import { logsListingAc } from '../actionCreators';
class LogFilterField extends Component {
    constructor(props){
        super(props);
        this.state={
          fieldValue: '',
          startDate: moment(),
          endDate: moment().add(5, 'day'),
          fieldFocus : false,
          log_id: ''
        }
    }

    handleFocus = () => {
      this.setState({
        fieldFocus: true
      })

    }

    handleBlur = () => {
      this.setState({
        fieldFocus: false
      })
    }

    componentDidMount(){
      this.updateInitalFilter(this.props);
    }
    componentWillReceiveProps(nextProps){
      this.updateInitalFilter(nextProps);
    }
    updateInitalFilter = (data) => {
      if(data.fieldObj.keyParam == 'date'){
        this.setState({
          startDate: (data.filterData.startDate ? data.filterData.startDate : moment().subtract(7, 'day')) ,
          endDate: (data.filterData.endDate ? data.filterData.endDate : moment())
        })
      }else{
        const value = (data.filterData[data.fieldObj.keyParam] ? data.filterData[data.fieldObj.keyParam].split(','): '');
        this.setState({
          fieldValue: value
        })
      }
    }
    lastModifiedDate = (date, type) => {
      this.setState(prevState => ({
        ...prevState,
        [type]: date
      }))
    }

    logDropDownFilterChange = (selectdVal) => {
      let array = [];
      selectdVal.map(value => {
        array.push(value[this.props.fieldObj.valueKey]);
      });
      this.setState({
        fieldValue: array
      })
    }

    changeFilterVal = (logId) => {
      this.setState({
        log_id: logId
      })
    }

     updateLogFilter = (e) => {
        e.preventDefault();
        let queryFilter = Object.assign({}, this.props.logFilter);   
        if(queryFilter.startDate &&  queryFilter.hasOwnProperty('startDate') && queryFilter.startDate != '' && queryFilter.startDate != null ){
          queryFilter = {
            ...queryFilter,
            startDate: moment(queryFilter.startDate).format('YYYY-MM-DD')
          }
        }
        if(queryFilter.endDate &&  queryFilter.hasOwnProperty('endDate')  && queryFilter.endDate != '' && queryFilter.endDate != null){
          queryFilter = {
            ...queryFilter,
            endDate: moment(queryFilter.endDate).format('YYYY-MM-DD')
          }
        }
        if(this.props.fieldObj.keyParam == 'date'){
          queryFilter = {
            ...queryFilter,
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
          };
          this.props.updateLogFilter({startDate:this.state.startDate,endDate: this.state.endDate}, this.props.fieldObj.keyParam);
        }else if(this.props.fieldObj.keyParam == 'log_id'){
          queryFilter = {
            ...queryFilter,
            [this.props.fieldObj.keyParam]: this.state.log_id
          };
          this.props.updateLogFilter(this.state.log_id, this.props.fieldObj.keyParam);
        }else{
          queryFilter = {
            ...queryFilter,
            [this.props.fieldObj.keyParam]: (this.props.fieldObj.inputType == 'text' ? this.state.fieldValue : this.state.fieldValue.join(','))
          };
          this.props.updateLogFilter(this.state.fieldValue.join(','), this.props.fieldObj.keyParam);
        }
        const filterParams = removeEmptyKey(queryFilter);
        this.props.setLoader();
        this.props.fetchLogLists(filterParams, false);
        this.props.updateSlectedItem('');
        const location = Object.assign({}, browserHistory.getCurrentLocation());
        // location.query= filterParams ;
        // browserHistory.push(location);
      }
    render(){
        const {fieldObj} = this.props;
        if(fieldObj.inputType == 'dropdown'){
            return(
              <form onSubmit= {this.updateLogFilter}>
                <div className="match-body">
                  <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'} }>Matches</label>
                  <Select
                      name="form-field-name"
                      value={this.state.fieldValue}
                      onChange={this.logDropDownFilterChange}
                      options={fieldObj.options}
                      placeholder = {fieldObj.placeholder}
                      matchProp = "any"
                      labelKey = {fieldObj.labelKey}
                      valueKey	= {fieldObj.valueKey}
                      multi = {true}
                      onFocus={() => this.handleFocus()}
                      onBlur={() => this.handleBlur()}
                  />
                </div>
                <div className="match-footer">
                  <input type="submit" className="primary-btn" value="Apply" />
                </div>
              </form>
            )
          }

          if(fieldObj.inputType == 'date'){
            return(
              <form onSubmit= {this.updateLogFilter}>
                <div className="match-body">
                  <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'}} >Matches</label>
                  <DatePicker
                    dateFormat = 'YYYY-MM-DD'
                    selected={moment(this.state.startDate)}
                    selectsStart
                    startDate={moment(this.state.startDate)}
                    endDate={moment(this.state.endDate)}
                    onChange={(e) =>this.lastModifiedDate(moment(e).format('YYYY-MM-DD'), 'startDate')}
                    onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                  />

                  <DatePicker
                    dateFormat = 'YYYY-MM-DD'
                    selected={moment(this.state.endDate)}
                    selectsEnd
                    startDate={moment(this.state.startDate)}
                    endDate={moment(this.state.endDate)}
                    onChange={(e) => this.lastModifiedDate(moment(e).format('YYYY-MM-DD'), 'endDate')}
                    onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                 />
                </div>
                <div className="match-footer">
                  <input className="primary-btn" type="submit" value="Apply" />
                </div>
              </form>
            )
          }

          if( fieldObj.inputType == 'text' ){
            return(
              <form onSubmit= {this.updateLogFilter}>
                <div className="match-body">
                  <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'} }>Matches</label>
                  <input type="text" value={this.state.log_id}
                  onChange={(e) => this.changeFilterVal(e.target.value)}
                  onFocus={() => this.handleFocus()}
                  onBlur={() => this.handleBlur()}
                  />
                </div>
                <div className="match-footer">
                  <input className="primary-btn" type="submit" value="Apply"
                        onFocus={() => this.handleFocus()}
                        onBlur={() => this.handleBlur()}
                  />
                </div>
              </form>
            )
          }

    }
}

const mapStateToProps = state => ({
    logFilter: state.MRFinanaceRptReducer.logFilter
  })

  const mapDispatchToProps = dispatch => {
    return{
      setLoader: () => dispatch({
        type: LOGS_LOADER,
        payload: true
      }),
      updateLogFilter: (value, type) => dispatch({
        type: LOG_FILTER_UPDATE,
        payload: {value, type}
      }),
      fetchLogLists: (filterParams, allTypeFlag) => dispatch(logsListingAc(filterParams, allTypeFlag))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(LogFilterField);
