import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DbBackCom, TechnicalLocations, AssetWidget, LastAssetInfoCard } from '../components';
import { FieldCol, FieldGroup, FieldLabel, FieldSelect} from '../../contracts/Elements';
import { imgStoragePath,projectStatus }  from '../../../constants';
import { getLocalStorageInfo }  from '../../../utils';
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { trackActivity } from '../../../utils/mixpanel';
import { technicalProjectsWidgetAc, technicalAircraftAc,technicalLocationsAc,technicalDbFindingsAc,technicalProjectLocationtAc ,technicalInspectionTypeAc, technicalLastAssetsAc} from '../actionCreators';
class InspectionFleetDb extends Component {
  constructor(props){
    super(props);
    this.state = {
      formQuery:{},
      locQuery:{},
      height: 440
    };
  }
  componentDidMount(){
    this.props.technicalProjectsWidget();
    this.props.technicalLocation();
    this.props.technicalAircraft();
    this.props.technicalInspectionType();
    this.props.technicalInspectionFindings({});
    this.props.technicalProjectLocation({});
    this.props.technicalLastAssets();
    trackActivity('Page Visited',{
      application_module: 'Dashboards',
      asset: 'All',
      page_title: 'Technical Dashboard'
    })
  }
  updateForm=(type,value)=>{
    let data = Object.assign({}, this.state.formQuery);
    let newData={
      ...data,
      [type]:value
    }
    if(type=='location'){
    if(value=='All'||value==''){
      delete newData['location'];
    }
   }
   if(type=='aircraft_type'){
    if(value==''){
      delete newData['aircraft_type'];
    }
   }
    this.setState({
      formQuery:newData
    });

    this.props.technicalInspectionFindings(newData);
  }
  updateLocForm=(type,value)=>{
    let data = Object.assign({}, this.state.locQuery);
    let newData={
     ...data,
     [type]:value
    }
    this.setState({
      locQuery:newData
    });
    this.props.technicalProjectLocation(newData);
  }
  getStyleClass=(id)=>{
    switch(id){
      case 1:
      return 'atrweb flex-center model';
      case 23:
      return 'heliweb flex-center model';
      case 4:
      return 'narro-with-winglet flex-center model';
      case 5:
      return 'narro-without-winglet flex-center model';
      case 2:
      return 'q400 flex-center model';
      case 10:
      return 'three-engine flex-center model';
      case 8:
      return 'wide-four-engine-with-winglet flex-center model';
      case 9:
      return 'wide-four-engine-without-winglet flex-center model';
      case 6:
      return 'wide-two-engine-with-winglet text-center model';
      case 7:
      return 'wide-two-engine-without-winglet flex-center model';
      case 11:
      return 'crj flex-center model';
      case 12:
      return 'crj flex-center model';
    }
  }
  render(){
    const{ loactionsList, airCraftTypeList, technicalProjectWidget, dbAssetLoader,inspectionData,technicalLocationData,technicalInspectionData, technicalProjectAssets } = this.props;
    const projectWidget = [
      { type: 'all', url : '/technical/projects?project_status=all', title: 'TOTAL PROJECTS', classes: 'widget-cards  marginRight  flex-centered total-count' },
      { type: 'active', url :'/technical/projects?project_status=ongoing', title: 'ACTIVE PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered on-lease' },
      { type: 'upcoming', url: '/technical/projects?project_status=upcoming', title: 'UPCOMING PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered off-lease' },
      { type: 'overdue', url: '/technical/projects?project_status=overdue', title: 'OVERDUE PROJECTS', classes: 'widget-cards marginRight marginLeft flex-centered on-ground' },
      { type: 'completed', url :'/technical/projects?project_status=completed', title: 'COMPLETED PROJECTS', classes: 'widget-cards marginLeft flex-centered written-off' }
    ];
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: technicalProjectAssets.length <= 2 ? technicalProjectAssets.length : 3 ,
      slidesToScroll: 1,
      variableWidth: true,
      adaptiveHeight: true,
    };
    return(
      <div className="fleet-technical-db">
        <DbBackCom pageTitle="Technical Dashboard" />
        <Row className="assets-row" style={{marginLeft: '5px', marginRight: '6px'}}>
          { projectWidget.map((project, index) =>
            <AssetWidget
              className={project.classes}
              statusGrpTitle={project.title}
              value={technicalProjectWidget[project.type]}
              url={project.url}
              dbAssetLoader={dbAssetLoader}
            />
          )}
        </Row>
        <Row className="technical-manpower-info">
          {
            getLocalStorageInfo().user.permission.technical && !getLocalStorageInfo().user.permission.technical.is_saas && getLocalStorageInfo().user.type.value == 1 ?
            <Col md="6">
              <Row>
                {[{title: 'Total MANPOWER', value: technicalProjectWidget.total_manpower}, {title: 'Engaged MANPOWER', value: technicalProjectWidget.engaged_manpower}].map((stats, index) =>
                  <Col md="6">
                    <div className="manpower-widget flex-centered" style={{cursor: "alias !important"}}>
                      <p>
                        <h5>{stats.value}</h5>
                        <h6>{stats.title}</h6>
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            :null
          }
          <Col md="6">
            <table>
              <tr>
                <td>
                  <h5>{technicalProjectWidget.total_assets}</h5>
                  <h6>Total Assets</h6>
                </td>
                <td>
                  <img src={imgStoragePath + "aircraft_circle.png"} alt=" " />
                </td>
                <td>
                  <h5>{technicalProjectWidget.active_assets}</h5>
                  <h6>Active Assets</h6>
                </td>
              </tr>
            </table>
          </Col>
        </Row>
        <Row style={{marginBottom:'20px'}}>
          <Col md="6">
          <div className="projects-findings">
          <Row style={{padding:'4px 5px 1px 0'}}>
          <Col md="8">
           <h4>Global Trends On Inspection Findings</h4>
           </Col>
           <FieldCol md="4">
                  <FieldGroup className="select-contract-block">
                    <FieldSelect
                      placeholder='Select Aircraft'
                      keyParam="aircraft_type"
                      value={this.state.formQuery.aircraft_type ?this.state.formQuery.aircraft_type: inspectionData && inspectionData.aircraft_type ? inspectionData.aircraft_type : ''}
                      type={'edit'}
                      styleName={''}
                      options={airCraftTypeList.aircraftTypes}
                      updateField={this.updateForm}
                      labelKey="name"
                      valueKey="id"
                    />
                  </FieldGroup>
                </FieldCol>
              </Row>
              <div className="model-container findings-map">
                <div className="finding-third-wrapper">
                  <div className="indicator-block">
                    <ul className="list-unstyled">
                      {['front', 'mid', 'aft', 'engines', 'wings'].map((label, index) =>
                        <li key={index} style={{textTransform: 'capitalize'}}><span className={label}></span> {label}</li>
                      )}
                  </ul>
                </div>
              </div>
              <div className="search-wrapper">
                <FieldCol md="4" style={{padding:'0px'}}>
                  <FieldGroup className="select-contract-block">
                    <FieldSelect
                      placeholder='All Locations'
                      keyParam="location"
                      value={this.state.formQuery.location?this.state.formQuery.location:''}
                      type={'edit'}
                      styleName={''}
                      options={loactionsList}
                      updateField={this.updateForm}
                      labelKey="label"
                      valueKey="value"
                    />
                  </FieldGroup>
                </FieldCol>
              </div>
             { inspectionData  && inspectionData.inspection && inspectionData.aircraft_type ?
               <Col md={{ offset: 2,size: 8 }} className="finding-details finding-map-second-wrapper">
                <div className="model-inner">
                  <div className={this.getStyleClass(inspectionData.aircraft_type.aircraft_model_id)}>
                    { inspectionData.inspection.fwd_area_sections!=null?
                      <span className="front">{ Math.round(inspectionData.inspection.fwd_area_sections)}%</span>:null
                    }
                    { inspectionData.inspection.mid_area_sections!=null?
                      <span className="mid">{Math.round(inspectionData.inspection.mid_area_sections)}%</span>:null
                    }
                    { inspectionData.inspection.aft_area_sections!=null?
                      <span className="aft">{Math.round(inspectionData.inspection.aft_area_sections)}%</span>:null
                    }
                    { inspectionData.inspection.engine_lh1_sections!=null?
                      <span className="engine1 engine">{Math.round(inspectionData.inspection.engine_lh1_sections)}%</span>:null
                    }
                    { inspectionData.inspection.engine_rh1_sections!=null?
                      <span className="engine2 engine">{Math.round(inspectionData.inspection.engine_rh1_sections)}%</span>:null
                    }
                    { inspectionData.inspection.engine_lh2_sections!=null?
                      <span className="engine3 engine">{Math.round(inspectionData.inspection.engine_lh2_sections)}%</span>:null
                    }
                    { inspectionData.inspection.engine_rh2_sections!=null?
                      <span className="engine4 engine">{Math.round(inspectionData.inspection.engine_rh2_sections)}%</span>:null
                    }
                    { inspectionData.inspection.wing_lh_sections!=null?
                      <span className="wings1 wings">{Math.round(inspectionData.inspection.wing_lh_sections)}%</span>:null
                    }
                    { inspectionData.inspection.wing_rh_sections!=null?
                      <span className="wings2 wings">{Math.round(inspectionData.inspection.wing_rh_sections)}%</span>:null
                    }
                    {
                      inspectionData.aircraft_type && inspectionData.aircraft_type.image ?
                        <img src={inspectionData.aircraft_type.image} id="aircraft_bg" onLoad={(evt) => this.setState({
                            height: document.querySelector('.projects-findings') ? document.querySelector('.projects-findings').offsetHeight - 40 : 440
                          })}/>
                      :null
                    }
                  </div>
                </div>
                </Col>:null
              }
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="projects-location">
              <Row style={{padding:'4px 5px 1px 0'}}>
                <Col md="5">
                  <h4>Location of Projects</h4>
                </Col>
                { technicalInspectionData?
                  <FieldCol md="4">
                    <FieldGroup className="select-contract-block">
                      <FieldSelect
                        placeholder='Inspection Type'
                        keyParam="inspection_type"
                        value={this.state.locQuery.inspection_type ?this.state.locQuery.inspection_type:''}
                        type={'edit'}
                        styleName={''}
                        options={technicalInspectionData}
                        updateField={this.updateLocForm}
                        labelKey="name"
                        valueKey="id"
                      />
                    </FieldGroup>
                  </FieldCol>:null
                }
                <FieldCol md="3">
                  <FieldGroup className="select-contract-block">
                    <FieldSelect
                      placeholder='Project'
                      keyParam="project_status"
                      value={this.state.locQuery.project_status ?this.state.locQuery.project_status:''}
                      type={'edit'}
                      styleName={''}
                      options={projectStatus}
                      updateField={this.updateLocForm}
                      labelKey="label"
                      valueKey="value"
                    />
                  </FieldGroup>
                </FieldCol>
              </Row>
              <Row className="assetLocation">
                <Col md="12">
                  {
                    inspectionData.aircraft_type && inspectionData.aircraft_type.image ?
                      <TechnicalLocations
                      markerList={technicalLocationData}
                      height={this.state.height}/>
                    :null
                  }
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        { technicalProjectAssets.length ?
          <Row>
            <Col md="12">
              <div className="technical-last-assets">
                <p className="expiry-title">LAST {technicalProjectAssets.length} ASSETS</p>
                <Slider {...settings} className="airframe-cards flex-not-centered">
                  { technicalProjectAssets.map((workOrder, index) =>
                    <LastAssetInfoCard key={index} workOrder={workOrder} />
                  )}
                </Slider>
              </div>
            </Col>
          </Row>:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  airCraftTypeList: state.DashboardReducer.airCraftTypeList,
  loactionsList: state.DashboardReducer.loactionsList,
  inspectionData: state.DashboardReducer.inspectionData,
  technicalProjectWidget: state.DashboardReducer.technicalProjectWidget,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
  technicalLocationData: state.DashboardReducer.technicalProjectLocation,
  technicalInspectionData:state.DashboardReducer.technicalInspectionData,
  technicalProjectAssets: state.DashboardReducer.technicalProjectAssets
});
const mapDispatchToProps  = (dispatch, ownProps) => {
  return {
    technicalProjectsWidget: () => dispatch(technicalProjectsWidgetAc()),
    technicalLocation: () => dispatch(technicalLocationsAc()),
    technicalInspectionType: () => dispatch(technicalInspectionTypeAc()),
    technicalProjectLocation:(query)=>dispatch(technicalProjectLocationtAc(ownProps.params,query)),
    technicalAircraft: () => dispatch(technicalAircraftAc()),
    technicalInspectionFindings:(query)=> dispatch(technicalDbFindingsAc(ownProps.params,query)),
    technicalLastAssets: () => dispatch(technicalLastAssetsAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(InspectionFleetDb,['console','dashboard','R']));
