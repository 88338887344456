import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DropDownField } from '../../../Elements';

export default class ItemCards extends Component {
  constructor(props) {
    super(props);
  }


  render() {
  const { } = this.props;
  return(
    <div>
       <div className="folder-card">
              <div className="folder-info vl">
                <h4>{this.props.value}</h4>
                <p>{this.props.label}</p>
              </div>
        </div>
    </div>
    );
  }
}
