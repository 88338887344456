import * as actions from '../actions';
export default function(state = {
  mrRatesList: [],
  mrRatesView: {
    flag: false,
    index: 0,
    data: {}
  },
  mrRateInvoiceInfo: {
    flag: false,
    data: {}
  },
  mrRateCrud: {
    type: '',
    data: {},
    flag: false
  },
  mrInflationCrud: {
    type: '',
    data: {},
    flag: false
  },
  inflationList:[],
  annualRatioList:[],
  mrRevisionList:[],
  revisionFlag:false,
  reserveAccountList: [],
  reserveRateLoader: true,
  
}, action){
  switch (action.type) {
    case actions.CONTR_RESERVE_RATE_LIST:
      return {
        ...state,
        mrRatesList: action.payload
      }
    case actions.CONTR_RESERVE_RATE_ADD:
      return {
        ...state,
        mrRatesList: [ action.payload, ...state.mrRatesList]
      }
    case actions.MR_REVISION_HISTORY:
      return {
        ...state,
        mrRevisionList: action.payload,
        revisionFlag:true
      }

    case actions.TOGGLE_REVISION_HISTORY:
      return {
        ...state,
        revisionFlag: action.payload
      }
      
    case actions.MUICP_INFLATION_RATE_LIST:
    return {
      ...state,
      inflationList:action.payload
    }
    case actions.ANNUAL_RATIO_LIST:
    return {
      ...state,
      annualRatioList:action.payload
    }
    case actions.CONTR_RESERVE_RATE_EDIT:
      let list = state.mrRatesList;
      return {
        ...state,
        mrRatesView: {
          flag: false,
          data: {}
        },
        mrRatesList: list.map(data => data.id == action.payload.id ? action.payload : data)
      }
    case actions.CONTR_RESERVE_RATE_VIEW:
      return {
        ...state,
        mrRatesView: {
          ...state.mrRatesView,
          flag: true,
          index: action.payload.index,
          data: action.payload.res
        }
      }
    case actions.RESERVE_ACCOUNTS_TYPE:
      return {
        ...state,
        reserveAccountList: action.payload
      }
    case actions.CONTR_RESERVE_RATE_INVOICE: {
      return {
        ...state,
        mrRateInvoiceInfo: {
          ...state.mrRateInvoiceInfo,
          data: action.payload.res,
          flag: action.payload.flag
        }
      }
    }
    case actions.INFLATION_RATE_CRUD: {
      return {
        ...state,
        mrInflationCrud: action.payload
      }
    }
    
    case actions.CLOSE_RESERVE_RATE_SIDE_BAR_INVOICE:
      return {
        ...state,
        mrRateInvoiceInfo: {
          ...state.mrRateInvoiceInfo,
          flag: action.payload
        }
      }
    case actions.CLOSE_RESERVE_RATE_SIDE_BAR:
      return {
        ...state,
        [action.viewType]: action.payload,
      }
    case actions.RESERVE_RATE_LOADER:
      return {
        ...state,
        reserveRateLoader: action.payload
      }
    case actions.MR_RATE_CRUD:
      return {
        ...state,
        mrRateCrud: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        mrRateCrud: {
          ...state.mrRateCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }

    case actions.INFLATION_RATE_UPDATE:
    return {
      ...state,
      mrInflationCrud:{
        ...state.mrInflationCrud,
        data:{
          ...state.mrInflationCrud.data,
          [action.payload.key]:action.payload.value
        }
      }
    }
    case actions.MUICP_INFLATION_UPDATE:
    return {
      ...state,
      inflationList : state.inflationList.map(list => list.id == action.payload.id ? action.payload : list)
      
    }
    case actions.MUICP_INFLATION_ADD:
    return {
      ...state,
      inflationList: [ action.payload, ...state.inflationList]
    }
    case actions.TOGGLE_VIEW_EDIT_INFLATION:
    return {
      ...state,
      mrInflationCrud: {
        ...state.mrInflationCrud,
        type: (action.payload == 'view' ? 'edit': 'view')
      }
    }

    default:
      return state;
  }
}
