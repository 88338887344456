import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkValidDate } from '../../../../formValidator';
export default class AddEditSublease extends Component {
  constructor(props){
    super(props);
    this.state = {
      subLease: {},
      error: {}
    }
  }
  componentDidMount(){
    this.setState({
      subLease: this.props.subLeaseObj
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      subLease: {
        ...prevState.subLease,
        [type]: (value == 'Invalid date' ? null:value)
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }

  addEditSubLease = (e) => {
    e.preventDefault();
    const subLease = this.state.subLease;
    const validateNewInput = {
      sublease_lessee: checkString({value: subLease.sublease_lessee, required: true, minLength: '', maxLength: '', message: 'Please Enter The SubLease Name'}),
      start_date: checkString({value: subLease.start_date, required: false, minLength: '', maxLength: '', message: 'Please Enter The Valid Start Date'}),
      end_date: checkString({value: subLease.end_date, required: false, minLength: '', maxLength: '', message: 'Please Enter The Valid End Date'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(subLease.end_date && subLease.start_date){
        if(moment(subLease.end_date).diff(moment(subLease.start_date), 'days', true) <= 0){
          this.setState({
            error: {
              start_date: 'Start Date cannot be greater than End Date'
            }
          });
          return;
        }
      }else{
        if(subLease.end_date){
          if(!subLease.start_date){
            this.setState({
              error: {
                start_date: 'Start Date cannot be Blank'
              }
            });
            return;
          }
        }
      }
      this.props.addEditSubLease(this.state.subLease, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }

  render() {
    const { subLease, error } = this.state;
    const { type } = this.props;
    return (
      <form onSubmit={(e) => this.addEditSubLease(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Name
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={subLease.sublease_lessee}
                  type={type}
                  keyParam="sublease_lessee"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.sublease_lessee}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Details </FieldLabel>
                <FieldTextarea
                  value={subLease.details}
                  type={type}
                  keyParam="details"
                  updateField={this.updateForm}
                />

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Start Date </FieldLabel>
                <FieldDate
                  value={subLease.start_date}
                  type={type}
                  keyParam="start_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.start_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">End Date </FieldLabel>
                <FieldDate
                  value={subLease.end_date}
                  type={type}
                  keyParam="end_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.end_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks </FieldLabel>
                <FieldTextarea
                  value={subLease.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          this.props.type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
    )
  }
}
