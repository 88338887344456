import React from 'react';
import { withRouter } from 'react-router';
import { imgStoragePath } from '../../constants';
const NoAccessApps = (props) => {
  return (
    <div className="">
      <img style={{maxWidth: '100%'}} src={imgStoragePath + (props.params.type == 'mr-calculator' ? 'mr_overlay.png':'fleet_no_access.png')} alt="" />
    </div>
  )
}
export default withRouter(NoAccessApps);
