import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
export default class CashFlowListInfo extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="asset-listing-wrap utilization-history" style={{marginLeft:'0'}}>
        <div className="asset-list-filter-sort">
          <Row className="filter-block">
            <Col md="2" xs="2"><h6>Month</h6></Col>
            <Col md="2" xs="2"><h6>Name</h6></Col>
            <Col md="2" xs="2"><h6>Credit</h6></Col>
            <Col md="2" xs="2"><h6>Debit</h6></Col>
            <Col md="2" xs="2"><h6>Balance</h6></Col>
            <Col md="2" xs="2"><h6>Transaction Detail</h6></Col>
          </Row>
        </div>
        {this.props.children}
      </div>
    )
  }
}
