
export const CONTRACT_MSN = 'CONTRACT_MSN';
export const FLEET_SUMM_WIDGET = 'FLEET_SUMM_WIDGET';
export const AIRCRAFT_DIS_WIDGET = 'AIRCRAFT_DIS_WIDGET';
export const APPS_MOD_WIDGET = 'APPS_MOD_WIDGET';
export const ASSETS_INFO_WIDGET = 'ASSETS_INFO_WIDGET';
export const OPR_RATING_WIDGET = 'OPR_RATING_WIDGET';
export const CONTRACT_CARD_WIDGET = 'CONTRACT_CARD_WIDGET';
export const CASHFLOW_SUMM_WIDGET = 'CASHFLOW_SUMM_WIDGET';
export const CASHFLOW_PAYMENT_WIDGET = 'CASHFLOW_PAYMENT_WIDGET';
export const INVOICE_SUMM_WIDGET = 'INVOICE_SUMM_WIDGET';
export const CONTRACT_EXPIRY_WIDGET = 'CONTRACT_EXPIRY_WIDGET';
export const UTIL_SUMMARY_WIDGET = 'UTIL_SUMMARY_WIDGET';
export const UTIL_TREND_WIDGET = 'UTIL_TREND_WIDGET';
export const CLAIM_TRACKER_WIDGET = 'CLAIM_TRACKER_WIDGET';
export const MAJOR_ASSEMBLY_WIDGET = 'MAJOR_ASSEMBLY_WIDGET';
export const ACTION_DOCK_WIDGET = 'ACTION_DOCK_WIDGET';
export const SHARED_CONTENT_WIDGET = 'SHARED_CONTENT_WIDGET';
export const LAST_ACTIVITY_WIDGET = 'LAST_ACTIVITY_WIDGET';
export const MSN_ACTIVITY_WIDGET = 'MSN_ACTIVITY_WIDGET';
export const FLEET_ACTIVITY_LIST = 'FLEET_ACTIVITY_LIST';
export const ACTION_ACTIVITY_LIST = 'ACTION_ACTIVITY_LIST';
export const UPDATE_DB_LOADER = 'UPDATE_DB_LOADER';
export const MSN_UPDATE_FILTER = 'MSN_UPDATE_FILTER';
export const TECHNICAL_LOCATION='TECHNICAL_LOCATION';
export const TECHNICAL_AIRCRAFT='TECHNICAL_AIRCRAFT';
export const TECHNICAL_INSPECTION_FINDINGS='TECHNICAL_INSPECTION_FINDINGS';
export const TECHNICAL_PROJECT_WIDGET = 'TECHNICAL_PROJECT_WIDGET';
export const TECHNICAL_PROJECT_LOCATION='TECHNICAL_PROJECT_LOCATION';
export const TECHNICAL_INSPECTION_TYPE='TECHNICAL_INSPECTION_TYPE';
export const TECHNICAL_PROJECT_ASSETS='TECHNICAL_PROJECT_ASSETS';
export const EXPORT_DASH_LOADER='EXPORT_DASH_LOADER';
export const FILE_COUNT_LOADER='FILE_COUNT_LOADER';
export const UTIL_SUM_LOADER='UTIL_SUM_LOADER';
