import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, Button , ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { DeleteModal } from  '../../../technicalInspection/Elements';
import { CtrTableHd, ContractSideBar, ShortHead, ContractBarLoader, EditSidebar } from '../../Elements';
import { ListLoader } from '../../../../shared';
import { invoiceViewAc, InvoiceAssetViewAc, changeInvoiceStatusAc, detailInvExportAc, getShareInvoiceInfoAc, updateShareInvFnAc } from '../actionCreators';
import { INVOICE_SHARE } from '../actions';
import InvFields from '../components/InvFields';
import ChangeInvoiceStatus from '../components/ChangeInvoiceStatus';
import ShareInvoice from '../components/ShareInvoice';
import { invoiceStatusList, displayDateFormat, invoiceDescList, imgStoragePath, assetsType } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo, showCurrencyFormat } from '../../../../utils';
import InvoiceInfo from '../components/InvoiceInfo'
import LessorInfo from '../components/LessorInfo'
import LesseeInfo from '../components/LesseeInfo'
import BankingInfo from '../components/BankingInfo'
import MaintenanceInvoiceItem from '../components/MaintenanceInvoiceItem'
import RentalInvoiceItem from '../components/RentalInvoiceItem'
import CreditDebitNote from '../components/CreditDebitNote'
import PenaltyInvoiceItem from '../components/PenaltyInvoiceItem'
import SecurityInvoiceItem from '../components/SecurityInvoiceItem'
import InsuranceInvoiceItem from '../components/InsuranceInvoiceItem'
import MRAdhocInvoiceItem from '../components/MRAdhocInvoiceItem'
import RentalAdhocInvoiceItem from '../components/RentalAdhocInvoiceItem'
class ViewInvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delModal:false,
      dropdownOpen: false,
      data:{}
    };
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    this.props.fetchAssetInfo();
    this.props.fetchInvoiceView();
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }


  toggleDelModal(data) {
    this.setState({
      delModal: !this.state.delModal,
      data:data,
    });
  }



  toggleExport=()=> {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/invoice/';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
    }
  }
  updateInvStatus = (data) => {
    this.toggle();
    if(data.status==3||data.status==4){
      this.toggleDelModal(data);
    }else{
      this.props.updateInvStatus(data, 'view');
    }
  }

  getHours = (units, unit_type, invoice_item_type) =>{
    // let unit = units
    // if(invoice_item_type.toLowerCase() != 'apu' && unit_type.toLowerCase().includes('flight hour') && units.toString().indexOf('.') != -1 && parseInt(units.toString().substr(units.toString().indexOf('.') + 1)) > 0){
    //   if((parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60) < 10){
    //     if(units.toString().substr(units.toString().indexOf('.'), units.toString().length).length > 1){
    //       unit = parseFloat(units.toString().substr(0,units.toString().indexOf('.') + 1)) + parseFloat(Math.round(parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60) / 100)
    //     }else{
    //       unit = parseFloat(units.toString().substr(0,units.toString().indexOf('.') + 1)) + parseFloat(Math.round(parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60))
    //     }
    //   }else{
    //     unit =units.toString().substr(0,units.toString().indexOf('.') + 1) + Math.round(parseFloat(units.toString().substr(units.toString().indexOf('.'), units.toString().length)) * 60)
    //   }
    // }
    return units.toString()
  }

  render() {
    const { invoiceInfo, ctrShortInfo, params, invoiceLoader, shareInvoiceCrud,invoiceExportLoader } = this.props;
    const startDate=ctrShortInfo && ctrShortInfo.contract  ? ctrShortInfo.contract.contractual_start_date  : null
    const endDate=ctrShortInfo && ctrShortInfo.contract ?ctrShortInfo.contract.contractual_end_date : null
    const total =  invoiceInfo && invoiceInfo.invoice_items ?  (invoiceInfo.invoice_items.reduce( (total, next) => total + next.billable_amount, 0 ) + invoiceInfo.invoice_items.reduce( (total, next) => total + next.tax_amount, 0 )) : 0
    const partialPaidAmount= invoiceInfo && invoiceInfo.partial_paid_amount ? invoiceInfo.partial_paid_amount : 0
    const diff = total - partialPaidAmount
    return(
      <div className="mr-rate-wrapper  mr-rate-spacing" >
        { Object.keys(ctrShortInfo).length ?
          <ShortHead contrAssetBasicInfo={ctrShortInfo} changeMsn={this.changeMsn} headingText="Contracts"/>: <ContractBarLoader />
        }
        {
          invoiceInfo && Object.keys(invoiceInfo).length ?
            <ContractSideBar application={`Invoice View - ${invoiceInfo.invoice_number}`} type="shorter" params={this.props.params} />
          :null
        }
        {invoiceLoader ? <ListLoader /> :null}
        {invoiceExportLoader? <ListLoader /> :null}
        { Object.keys(invoiceInfo).length ?
          <div className="add-edit-invoice-form view-block" >
            <h5 className="para invoice-heading">
              <Link className="back-link" to={'/' +this.props.params.type + '/' + this.props.params.aircraft_slug + '/contract/invoice/'}>
                <img width="15" src={imgStoragePath+"back_arw.png"} alt="img"/>
                <div className="inv-back-info">
                  <span>{invoiceDescList[invoiceInfo.invoice_type] + ' Invoice'}</span><br/>
                  <h6>Invoice {'#' + invoiceInfo.invoice_number}</h6>
                </div>
              </Link>
              <div className="download-block" style={{ paddingRight: '15px',float: 'right',marginTop:'-5px'}}>
                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleExport}>
                                <DropdownToggle caret size="sm">
                                   <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/> Export </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem  onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number,'pdf')}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                                    <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number,'xls')} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                    <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number,'docx')} ><img src={imgStoragePath+"docs_icon.png"} alt="img"/>Doc</DropdownItem>
                                    <DropdownItem onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number,'csv')} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>CSV</DropdownItem>
                                </DropdownMenu>
                     </ButtonDropdown>
                    </div>

              <span onClick={() => this.props.exportInvoice(ctrShortInfo, invoiceInfo.invoice_number,'pdf')} style={{paddingLeft: '10px', cursor: 'pointer', fontSize:'14px', float: 'right',display:'none'}}><img width="18" src={imgStoragePath+"export_icon.png"} alt="export" /> Export</span>
              { (invoiceInfo.status == '1' || invoiceInfo.status == '2')?
              <span onClick={this.props.getShareInvoiceInfo} style={{color:'#2f8cff', cursor: 'pointer', fontSize:'14px', float: 'right',borderRight:'1px solid #2f8cff',padding:'0 10px', borderLeft:'1px solid #2f8cff'}}><img style={{width:"12px",paddingBottom:'3px'}} src={imgStoragePath+"icon_send_email.png"} alt="export" /> Send Email</span>:null
              }
              { (invoiceInfo.status == '1' || invoiceInfo.status == '2') && (getLocalStorageInfo().user.permission['contracts']['invoices'].indexOf('U') != -1) ?
                <Link to={'/' +this.props.params.type+'/'+this.props.params.aircraft_slug+'/contract/'+ this.props.params.contract_slug +'/invoice/edit/'+ this.props.params.id} className="edit-icon edit-icon-bar"><img  width="16" src={imgStoragePath+"icon_editblock.png"} alt="img"/> Edit Invoice</Link>:null
              }
            </h5>
            <form onSubmit={(e) => this.createInvoice(e)} style={{padding:'1px'}}>
              <InvoiceInfo
                type={'view'}
                invoiceInfo={invoiceInfo} toggleInvoiceState={() => this.toggle()}
                invoiceStatusList={invoiceStatusList}
                startDate={startDate}
                endDate={endDate}
              />
              <Row className="margin-0 company-form-block">
                <LessorInfo
                  invoiceInfo={invoiceInfo}
                  type="view"
                />
                <LesseeInfo
                  invoiceInfo={invoiceInfo}
                  type="view"
                />

              </Row>
              {invoiceInfo.invoice_type == 2 && invoiceInfo.rental_description && invoiceInfo.rental_description !='' ?
              <Row className="margin-0 company-form-block">
                <Col md="12" className="padding-right-0">
                  <Row className="inner-wrap"
                    style={{background: '#fafafa', padding: '0 15px', marginRight: '15px'}}
                    >
                    <InvFields label = "Description" colSize = "12" padding = 'padding-left-0' value={invoiceInfo.rental_description ? invoiceInfo.rental_description:'--'}  />
                   </Row>
                </Col>
              </Row>:null
              }
              <div className="charge-description-block " >
              {invoiceInfo.invoice_type === 9 ?
                <div>
                 <div className="table-block">
                 <table className="contr-table invoice-table-style">
                   <CtrTableHd theads={['Item','Description', 'Unit Description', 'No. Of Units', invoiceInfo.rental_type === 1 ? 'Fixed Rate ('+invoiceInfo.currency+')' : 'Per Unit Rate ('+invoiceInfo.currency+')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total ('+invoiceInfo.currency+')']} />
                   <tbody>
                     {invoiceInfo.invoice_items.map((invoice_item, index) => (
                       invoiceInfo.parent_invoice_type == 1 ?
                         <MRAdhocInvoiceItem
                           key={index}
                           type="view"
                           invoiceInfo={invoiceInfo}
                           invoice_item={invoice_item}
                           value={invoice_item}
                           index={index}
                           validationArray={this.state.validationArray}
                           updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                         />
                       :<RentalAdhocInvoiceItem
                         key={index}
                         type="view"
                         invoiceInfo={invoiceInfo}
                         value={invoice_item}
                         invoice_item={invoice_item}
                         index={index}
                         validationArray={this.state.validationArray}
                         updateInvoiceItemField={(value, type, index, name, unit_type) => this.props.updateInvoiceItemField(value, type, index, name, unit_type)}
                       />
                     ))}
                   </tbody>
                 </table>
                 </div>
                 <div className="sub-total-block">
                   <h5 className="para-ui">Billable Amount:
                     <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                       {
                         invoiceInfo.invoice_items.reduce( (total, next) => total + parseFloat(next.billable_amount), 0 )
                       }
                     </NumericLabel>
                    </h5>
                    {
                      invoiceInfo.status == '5' ?
                      <h5 className="para-ui">Paid Amount :
                        <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                           {invoiceInfo.partial_paid_amount}
                        </NumericLabel>
                      </h5> : null
                    }
                   <h5 className="para-ui">Tax:
                     <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                       {
                         invoiceInfo.invoice_items.reduce( (total, next) => total + next.tax_amount, 0 )
                       }
                     </NumericLabel>
                   </h5>
                   <h4 className="para">Total:
                     <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                          { invoiceInfo.status == "5" ? diff : total}
                       </NumericLabel>
                   </h4>

                 </div>


                 </div>
               : (invoiceInfo.invoice_type == 1 || invoiceInfo.invoice_type ==2) ?
              <div>
                {invoiceInfo.invoice_type ==2 ? <h5 className="para currency-break">{invoiceInfo.currency} Rental Receivable</h5>:null}
                <div className="table-block">
                  <table className="contr-table invoice-table-style">
                    <CtrTableHd theads={invoiceInfo.interval === 5 ? ['Item','Description', 'Unit Description', 'No. Of Days', 'Per Unit Rate ('+invoiceInfo.currency+')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total ('+invoiceInfo.currency+')'] : ['Item','Description', 'Unit Description', 'No. Of Units', 'Per Unit Rate ('+invoiceInfo.currency+')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total ('+invoiceInfo.currency+')']} />
                    <tbody>
                      {invoiceInfo.invoice_items.map((value, index) => (
                        invoiceInfo.invoice_type === 1 ?
                        <MaintenanceInvoiceItem
                          key={index}
                          type="view"
                          value={value}
                          invoiceInfo={invoiceInfo}
                        />
                      :<RentalInvoiceItem
                          key={index}
                          type="view"
                          invoice_item={value}
                          invoiceInfo={invoiceInfo}
                          validationArray={[]}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="sub-total-block">
                  <h5 className="para-ui">Billable Amount :
                    <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                      { invoiceInfo.invoice_items.reduce( (total, next) =>
                        total + next.billable_amount, 0
                      )}
                    </NumericLabel>
                  </h5>
                  {
                    invoiceInfo.status == '5' ?
                    <h5 className="para-ui">Paid Amount :
                      <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                         {invoiceInfo.partial_paid_amount}
                      </NumericLabel>
                    </h5> : null
                  }
                  <h5 className="para-ui">Tax :
                    <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                      { invoiceInfo.invoice_items.reduce((total, next) =>
                        total + next.tax_amount, 0
                      )}
                    </NumericLabel>
                  </h5>
                  <h4 className="para">Total:
                    <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                       {invoiceInfo.sub_total !== undefined && invoiceInfo.sub_total !== null && invoiceInfo.sub_total !== '' ? invoiceInfo.sub_total :invoiceInfo.status == "5" ? diff : total }
                    </NumericLabel>
                  </h4>
                </div>
                </div>
                :(invoiceInfo.invoice_type == 4 || invoiceInfo.invoice_type ==5) ?
                <div className="table-block">
                <table className="contr-table invoice-table-style">
                <CtrTableHd theads={['Description','Amount' , 'Tax Rate (%)', 'Tax Amount', 'Total (USD)']} />
                  <tbody>
                    <CreditDebitNote
                      type="view"
                      invoice_item={invoiceInfo.invoice_items[0]}
                      invoiceInfo={invoiceInfo}
                    />
                  </tbody>
                </table>
              </div>:
              invoiceInfo.invoice_type === 6 ?
              <div className="table-block">
                <table className="contr-table invoice-table-style">
                  <CtrTableHd theads={['Item','Security Against','Security Deposit Type', ' Security Deposit Amount', 'Sub Total ('+invoiceInfo.currency+')']} />
                  <tbody>
                      <SecurityInvoiceItem
                        type="view"
                        invoice_item={invoiceInfo.invoice_items[0]}
                        invoiceInfo={invoiceInfo}
                      />
                  </tbody>
                </table>
              </div>:
              invoiceInfo.invoice_type === 7 ?
              <div className="table-block">
                <table className="contr-table invoice-table-style">
                  <CtrTableHd theads={['Item','Description','Insurance Type','Insurance Amount', 'Sub Total ('+invoiceInfo.currency+')']} />
                  <tbody>
                      <InsuranceInvoiceItem
                        type="view"
                        invoice_item={invoiceInfo.invoice_items[0]}
                        invoiceInfo={invoiceInfo}
                      />
                  </tbody>
                </table>
              </div>:  invoiceInfo.invoice_type === 8 ?
                <div className="table-block">
                  <table className="contr-table invoice-table-style">
                    <CtrTableHd theads={['Item','Description','Insurance Type','Insurance Amount', 'Sub Total ('+invoiceInfo.currency+')']} />
                    <tbody>
                        <InsuranceInvoiceItem
                          type="view"
                          invoice_item={invoiceInfo.invoice_items[0]}
                          invoiceInfo={invoiceInfo}
                        />
                    </tbody>
                  </table>
                </div>:
              <div className="table-block">
                <table className="contr-table invoice-table-style">
                  <CtrTableHd theads={['Over Due Amount','Description','Due Date', 'Payment Date', 'No Of Days Over Due' , '6 Months USD LIBOR  '+ (invoiceInfo.libor_date ? '('+moment(invoiceInfo.libor_date ).format(displayDateFormat)+')' :''), 'Additional Rate', 'Total Penal Interest Rate', 'Interest On Over Due Amount']} />
                  <tbody>
                      <PenaltyInvoiceItem
                        type="view"
                        invoiceInfo={invoiceInfo}
                      />
                  </tbody>
                </table>
              </div>
              }
              </div>
              <h5 className="para invoice-heading">Bank Detail {invoiceInfo.invoice_type==2 ? 'For '+invoiceInfo.currency+ ' Rental Receivable':''}</h5>
              <BankingInfo
                type="view"
                invoiceInfo={invoiceInfo}
              />

              {invoiceInfo.invoice_type == 2 && invoiceInfo.children ?

              <div>
                { invoiceInfo.children.length > 0?
                  invoiceInfo.children.map((children, index) =>
                  <div>
                   <div className="charge-description-block ">
                    <div className="table-block">
                    <h5 className="para currency-break">{children.currency} Rental Receivable</h5>
                      <table className="contr-table invoice-table-style">
                        <CtrTableHd theads={['Item','Description', 'Unit Description', 'No. Of Units' , children.rental_type === 1 ? 'Fixed Rate ('+children.currency+')' : 'Per Unit Rate ('+children.currency+')', 'Tax Rate (%)', 'Tax Amount', 'Sub Total ('+children.currency+')']} />
                        <tbody>
                          {children.invoice_items.map((value, index) => (
                            <RentalInvoiceItem
                                key={index}
                                type="view"
                                invoice_item={value}
                                invoiceInfo={invoiceInfo}
                                validationArray={[]}
                              />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="sub-total-block">
                      <h5 className="para-ui">Billable Amount :
                        <NumericLabel params={showCurrencyFormat(children.currency)}>
                          { children.invoice_items.reduce( (total, next) =>
                            total + next.billable_amount, 0
                          )}
                        </NumericLabel>
                      </h5>
                      {
                        invoiceInfo.status == '5' ?
                        <h5 className="para-ui">Paid Amount :
                          <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                             {invoiceInfo.partial_paid_amount}
                          </NumericLabel>
                        </h5> : null
                      }
                      <h5 className="para-ui">Tax :
                        <NumericLabel params={showCurrencyFormat(children.currency)}>
                          { children.invoice_items.reduce((total, next) =>
                            total + next.tax_amount, 0
                          )}
                        </NumericLabel>
                      </h5>
                      <h4 className="para">Total:
                        <NumericLabel params={showCurrencyFormat(children.currency)}>
                          {children.invoice_items ? children.invoice_items.reduce( (total, next) =>
                            total + next.sub_total, 0
                          ) : invoiceInfo.sub_total !== undefined && invoiceInfo.sub_total !== null && invoiceInfo.sub_total !== '' ? invoiceInfo.sub_total : 0}
                        </NumericLabel>
                      </h4>
                    </div>
                    </div>



                  <h5 className="para invoice-heading">Bank Detail {invoiceInfo.invoice_type==2 ? 'For '+children.currency+ ' Rental Receivable':''}</h5>
                    <BankingInfo
                      type="view"
                      invoiceInfo={children}
                    />
                  </div>
                  ):null
                }
                </div>:null
              }
            </form>
            <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={"Change Status"} className="modal-tform-delete-pictures modal-dialog-centered">
          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to mark this invoice as {this.state.data&&this.state.data.status==3?"'paid' ?":"'cancelled' ?"}</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal('');this.props.updateInvStatus(this.state.data, 'view');}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
           </DeleteModal>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="invoice-status-modal">
              <ModalHeader toggle={this.toggle}>Change Invoice Status</ModalHeader>
              <ModalBody>
                {
                  this.state.modal ?
                  <ChangeInvoiceStatus
                    status={invoiceInfo.status}
                    updateInvStatus={this.updateInvStatus}
                    invoiceType={invoiceInfo.invoice_type}
                    invoiceStartDate={startDate}
                    invoiceEndDate={endDate}
                    totalAmount={total}
                  />:null
                }
              </ModalBody>
            </Modal>
          </div>: <ListLoader />
        }
        <EditSidebar
          title="Share Invoice"
          editSideBar={shareInvoiceCrud.flag}
          toggleEditSideBar={() => this.props.closeShareSidebar({data: {}, flag: false})}
        >
        { shareInvoiceCrud.flag ?
          <ShareInvoice
            shareInv={shareInvoiceCrud.data}
            shareInvoiceFn={this.props.updateShareInvFn}
            toggleEditSideBar={() => this.props.closeShareSidebar({data: {}, flag: false})}
            invoiceLoader={invoiceLoader}
           />:null
        }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  invoiceInfo : state.InvoiceReducer.invoiceView,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  invoiceLoader: state.InvoiceReducer.invoiceLoader,
  shareInvoiceCrud: state.InvoiceReducer.shareInvoiceCrud,
  invoiceExportLoader:state.InvoiceReducer.invoiceExportLoader
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(InvoiceAssetViewAc(ownProps.params)),
    fetchInvoiceView : () => dispatch(invoiceViewAc(ownProps.params)),
    updateInvStatus: (data, type) => dispatch(changeInvoiceStatusAc(ownProps.params, data, ownProps.params.contract_slug, ownProps.params.id, type)),
    exportInvoice:(ctrShortInfo, invoiceNumber, fileType) => dispatch(detailInvExportAc(ownProps.params ,ctrShortInfo, invoiceNumber, fileType)),
    getShareInvoiceInfo: () => dispatch(getShareInvoiceInfoAc(ownProps.params)),
    updateShareInvFn: (data) => dispatch(updateShareInvFnAc(ownProps.params,data)),
    closeShareSidebar: (data) => dispatch({
      type: INVOICE_SHARE,
      payload: data
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ViewInvoiceForm, ['contracts','invoices','R']));
