import React from 'react';
import {Row, Col } from 'reactstrap';
class StatusRadio extends React.Component {
  constructor(props){
    super(props);
  }
  
  
  createStatus = (flag) => {
   
    this.props.updateForm({type: 'engine_status', value:flag, sectionType: 'EngineCurrentDetails', index:this.props.index})
  }

  render(){ 
    return(
      <div>
        <div className="form-group-edit" style={{ marginTop: '-35px'}} >
          <label className="label" style={{ marginBottom: '10px'}}>{this.props.children}</label>
          { 
           <div>
            <Row>
            <Col md="4">
            {
              <label className="radio-block" style={{marginBottom: '0px'}} >
                <input type="radio" name="status-type" checked={this.props.value===1} onChange={() => this.createStatus(1)} />
                On Wing
              </label>
            }
            </Col>
            <Col md="4" >
              {
                <label className="radio-block" style={{marginBottom: '0px'}} >
                  <input type="radio" name="status-type" checked={this.props.value===2} onChange={() => this.createStatus(2)} />
                  In Shop
                </label> 
              }
            </Col>
            <Col md="4" >
              {
                <label className="radio-block" style={{marginBottom: '0px'}} >
                  <input type="radio" name="status-type" checked={this.props.value===3} onChange={() => this.createStatus(3)} />
                  In Storage
                </label> 
              }
            </Col>
            </Row>
          </div>
          }
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default StatusRadio;
