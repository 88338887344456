import React from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, prettyNumberParamsL } from '../../../../constants';
const AfClaimPayout = (props) => {
  return(
    <Row>

      <Col md="4" className="label-card">
        <h6 className="label-title">Payout Amount</h6>
        <span className="label-value">
          <NumericLabel params={prettyNumberParamsL}>
            {props.claim.amount_paid}
          </NumericLabel>
        </span>
      </Col>
      <Col md="4"className="label-card">
        <h6 className="label-title">Fund Balance</h6>
        <span className="label-value">
          <NumericLabel params={prettyNumberParamsL}>
            {props.claim.fund_balance}
          </NumericLabel>
        </span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Payout Status</h6>
        <span className="label-value">{props.claim.payout_status.label}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Transaction Number</h6>
        <span className="label-value">{props.claim.reference_number}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Transaction Details</h6>
        <span className="label-value">{props.claim.transaction_description}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Remarks</h6>
        <span className="label-value">{props.claim.remarks}</span>
      </Col>
    </Row>
  )
}
export default AfClaimPayout;
