import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col, Input, Button, Modal, ModalHeader, ModalBody, Dropdown, DropdownItem, DropdownMenu, ButtonDropdown, DropdownToggle } from 'reactstrap';
import { loadAssetViewAc} from '../../Dashboard/actionCreators';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { ImageSlider } from '../../../technicalInspection/Elements';
import { RecordsSideBar } from '../../Elements';
import MiniPackList from '../components/MiniPackList';
import SortableFilesContainer from '../components/SortableFilesContainer';
import { shareRecordsAc } from '../../DataRoom/actionCreators'
import {getMiniPackListAc, removeFileAc,removeMiniPackFileAc, downLoadResourcesTempAC, sortMiniPackListAc} from '../actionCreators';
import '../../assets/styles/records_a.scss';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import Pagination from "react-js-pagination";
import { RESOURCE_LOADER } from '../actions'
import moment from 'moment'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { SHARE_KEY } from '../../DataRoom/actions'
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { imgStoragePath, assetsType, displayDateTimeFormatShort } from '../../../../constants';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../../globalServices';
import { arrayMove } from 'react-sortable-hoc';
class MiniKit extends Component{
  constructor(props) {
    super(props);
    this.state ={
      pageNumber: 1,
      delModalFlag: false,
      delCoverPhotoFlag: false,
      delModalAllFlag: false,
      recordAction: 'Remove',
      previewCard:{},
      currentFolder: {},
      dropdownOpen: false,
      selectedIds : [],
      minipackImage: '',
      minipackImageId: '',
      minipackImageFile: '',
      imageModal: false,
      ModalFlag: false,
      renameCard: {},
      changeShareDuration: false,
      shareDuration: 24,
      shareUrl: '',
      shareDurationError: '',
      isChecked: false
    };

    this.removeRecord = this.removeRecord.bind(this);
  }

  copyFileUrl = () => {
    let file_url = document.querySelector("#file_url");
    file_url.select();
    document.execCommand("copy");
    this.setState({
      shareUrl: true
    });
  }

  changeShareStatus(evt){
    this.setState({
      isChecked: !this.state.isChecked,
      shareDuration: 24,
      shareUrl: false,
      customDuration: false,
      shareDurationError: false,
    });
    this.props.shareRecords(this.state.renameCard,this.state.isChecked, this.state.shareDuration);
  }

  shareRecord = (item) => {
    this.setState(prevState => ({
      ...prevState,
      ModalFlag: true,
      renameCard: item,
      shareUrl: false,
      isChecked: item.share_enabled,
      folderUUid:item.uuid,
      shareValidity: item.valid_till,
      shareDuration: item.share_duration ? ![24,72,168,240].includes(item.share_duration) && item.share_duration % 24 == 0 ? item.share_duration / 24 : item.share_duration : 24,
      shareType: item.share_duration % 24 == 0 ? 'days' : 'hours',
      customDuration: ![24,72,168,240].includes(item.share_duration),
      shareDurationError: false
    }));
    if(item.share_enabled != null && item.share_enabled != undefined && item.share_enabled == true){
      this.setState({
        changeShareDuration: true
      })
      this.props.getShareKey(item.share_key, item.valid_till);
    }else {
      this.setState({
        changeShareDuration: false
      })
      //this.props.shareRecords(item,false, 24);
    }
  }

  setShareDuration = (type,duration,flag) =>{
    this.setState({
      shareDuration: duration,
      shareType: type,
      customDuration: flag,
      isChecked: true,
      shareDurationError: false
    });
    var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
    if(flag != true){
      this.props.shareRecords(this.state.renameCard,false,shareDuration)
    }
  }
  setCustomDuration = (type,duration,flag) =>{
    if(duration != null && duration != undefined && duration != '' && duration != 0){
      this.setState({
        shareDuration: duration,
        shareType: type,
        customDuration: flag,
        isChecked: true,
      });
      var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
      this.props.shareRecords(this.state.renameCard,false,shareDuration)
    }else{
      this.setState({
        shareDurationError: true
      })
    }
  }

  removeRecord(item, action){
    this.setState({
      delModalFlag: !this.state.delModalFlag,
      previewCard: item,
      recordAction: action,
    })
  }

  removeRecordAll = () =>{
    this.setState({
      delModalAllFlag: !this.state.delModalAllFlag,
    })
  }

  sortFiles = (args, pictures) => {
    if(args[0].oldIndex != args[0].newIndex){
      let sortedPictures = [];
      pictures = arrayMove(pictures, args[0].oldIndex, args[0].newIndex);
      pictures.map(picture => sortedPictures.push(picture.id))
      this.props.sortFiles(this.state.currentFolder.uuid, sortedPictures);
    }
  }

  getMiniPackImage = () => {
    let params = this.props.params
    this.setState({
      minipackImage: ''
    })
    this.props.toggleLoader(true)
    globalGetService(`/records/${params.type}/${params.aircraft_slug}/folder/${this.state.currentFolder.uuid}/minipack-cover-photo/`)
    .then(response => {
      this.props.toggleLoader(false)
      if(response.data.statusCode === 200){
        this.setState({
          minipackImage: response.data.data.cover_photo,
          minipackImageId: response.data.data.id,
        })
      }
    })
  }

  saveMiniPackImage = () => {
    let params = this.props.params
    let formData = new FormData()
    formData.append('cover_photo', this.state.minipackImageFile)
    this.props.toggleLoader(true)
    globalPostService(`/records/${params.type}/${params.aircraft_slug}/folder/${this.state.currentFolder.uuid}/minipack-cover-photo/`, formData)
    .then(response => {
      this.props.toggleLoader(false)
      if(response.data.statusCode === 200){
        this.setState({
          minipackImage: response.data.data.cover_photo,
          minipackImageId: response.data.data.id,
        })
      }
    })
  }

  updateMiniPackImage = () => {
    let params = this.props.params
    let formData = new FormData()
    formData.append('cover_photo', this.state.minipackImageFile)
    this.props.toggleLoader(true)
    globalPutService(`/records/${params.type}/${params.aircraft_slug}/folder/${this.state.currentFolder.uuid}/minipack-cover-photo/${this.state.minipackImageId}/`, formData)
    .then(response => {
      this.props.toggleLoader(false)
      if(response.data.statusCode === 200){
        this.setState({
          minipackImage: response.data.data.cover_photo,
          minipackImageId: response.data.data.id,
        })
      }
    })
  }

  deleteMiniPackImage = () => {
    let params = this.props.params
    let formData = new FormData()
    formData.append('cover_photo', this.state.minipackImageFile)
    this.props.toggleLoader(true)
    globalDeleteService(`/records/${params.type}/${params.aircraft_slug}/folder/${this.state.currentFolder.uuid}/minipack-cover-photo/${this.state.minipackImageId}/`, formData)
    .then(response => {
      this.props.toggleLoader(false)
      if(response.data.statusCode === 200){
        this.setState({
          minipackImage: '',
          minipackImageId: '',
          delCoverPhotoFlag: false
        })
      }
    })
  }

  getMinipackFolderFiles = (item) =>{
    this.props.getMiniPackList('files',item.uuid);
    this.setState({currentFolder: item}, () => this.getMiniPackImage());
    let url = `/${this.props.params.type}/${this.props.params.aircraft_slug}/records/resources/mini-pack/?type=minipack&uuid=${item.uuid}&title=${item.name}&redirect_uuid=${item.redirect_uuid}`
    browserHistory.push(url);
  }

  componentWillReceiveProps(nextProps){
    let location = browserHistory.getCurrentLocation()
    if(this.props.location.search !== nextProps.location.search){
      if(location.search.includes('uuid')){
        this.props.getMiniPackList('files', location.query.uuid);
        this.setState({
          currentFolder:{
            uuid: location.query.uuid,
            name: location.query.title,
            redirect_uuid: location.query.redirect_uuid,
          }
        }, () => this.getMiniPackImage())
      }else{
        this.props.getMiniPackList();
      }
    }
  }

  componentDidMount(){
    this.props.fetchAssetInfo();
    let location = browserHistory.getCurrentLocation()
    if(location.search.includes('uuid')){
      this.props.getMiniPackList('files', location.query.uuid);
      this.setState({
        currentFolder:{
          uuid: location.query.uuid,
          name: location.query.title,
          redirect_uuid: location.query.redirect_uuid,
        }
      }, () => this.getMiniPackImage())
    }else{
      this.props.getMiniPackList();
    }
    this.props.fetchTechConstant({constant_types: ['file_extension']})
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/resources/';
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  makeRecycleList(id){
     this.props.restoreAllItem(this.state.currentFolder.uuid,[id], true);
  }
  removeAll(){
    let fileIds = []
    this.state.selectedIds.map(item => {
      fileIds = fileIds.length ? fileIds + ',' + item.toString() : item.toString()
    })
    this.props.restoreAllItem(this.state.currentFolder.uuid, fileIds);
    this.setState({
      selectedIds: []
    })
 }
  render(){
    const { ctrShortInfo, dataKitList, techConstants, resourceLoader, pagination, recycleList} = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    const { isChecked } = this.state
    return(
     <div className="records-list-wrap" style={{marginTop: '94px', padding: '0 15px'}}>
       <RecordsSideBar />
       { ctrShortInfo && Object.keys(ctrShortInfo).length ?
         <ShortHead
           headingText="Records"
           changeMsn={this.changeMsn}
           contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
       }
       <Row>
         <Col md="8" className="invoice-heading">
          <h1>
             <Link className="back-link-single" onClick={() => {browserHistory.goBack();this.setState({currentFolder: {}})}}>
                {
                  <img width="15" src={imgStoragePath+"back_arw.png"} alt="img"/>
                }
                <span style={{color: '#000', marginLeft:'5px'}}> {this.props.params.id == 1 ? "Marketing Kit" : this.props.params.id == 2 ? "Engineering Kit":"Major Assembly Minipack"}</span>
              </Link>
              {
                this.state.currentFolder && Object.keys(this.state.currentFolder).length ?
                  <span>&nbsp;/&nbsp;{this.state.currentFolder.name}</span>
                :null
              }
              {
                this.state.currentFolder && Object.keys(this.state.currentFolder).length ?
                  <Link className="back-link-single" to={'/' +this.props.params.type + '/' + this.props.params.aircraft_slug + '/records/data-room/technical/'+ this.state.currentFolder.redirect_uuid + '?type=minipack'}>
                    <span style={{marginLeft:'15px',fontSize:'14px'}}>+ Add</span>
                  </Link>
                :null
              }
          </h1>
         </Col>
         {
           this.state.currentFolder && Object.keys(this.state.currentFolder).length ?
            <Col md={12}>
                <span className="warning-info-text">
                  Note : File order can be changed in minipack by drag and drop.
                </span>
            </Col>
           :null
         }
         {
           // dataKitList.data && dataKitList.data.type !== 'files' && dataKitList.data.length && Object.keys(this.state.currentFolder).length > 0 ?
           // <ButtonDropdown style={{marginTop: '-20px', outline: 'none'}} isOpen={this.state.dropdownOpen} toggle={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
           //     <DropdownToggle caret size="sm" style={{background: 'transparent', border: 'none', color: '#3587f1'}}>
           //        <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export Minipack </span>
           //     </DropdownToggle>
           //     <DropdownMenu>
           //         <DropdownItem style={{fontSize: '12px', color: '#3587f1'}} onClick={() => this.props.getMiniPackList('files', this.state.currentFolder.uuid, {download: 'pdf', merged_download: true})}><img width="12" style={{marginRight: '5px'}} src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
           //         <DropdownItem style={{fontSize: '12px', color: '#3587f1'}} onClick={() => this.props.getMiniPackList('files', this.state.currentFolder.uuid, {download: 'pdf'})} ><img style={{marginRight: '5px'}} width="12" src={imgStoragePath+"zip.png"} alt="img"/>Zip</DropdownItem>
           //     </DropdownMenu>
           // </ButtonDropdown>
           // :null
         }
         {
           // (dataKitList.data && dataKitList.data.list && dataKitList.data.list.length > 0 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ) ?
           //   <Col md="2"  style={{maxWidth:'113px'}} className="status-block text-right bucket-action">
           //     <span style={{color: '#007bff'}} onClick={() => this.props.downloadresources(this.props.params.id == 1 ?'marketing_kit':this.props.params.id == 2?'engineering_kit':'engine_apu_minipack',ctrShortInfo.records.technical_records.uuid,'zip',(ctrShortInfo.asset_type==2?'ESN'+ctrShortInfo.esn:'MSN '+ctrShortInfo.msn)+(this.props.params.id == 1 ?'_marketing_kit':this.props.params.id == 2 ?'_engineering_kit':'_engine_apu_minipack'))} >Download All</span>
           //   </Col>:null
         }
       </Row>
       {

           <Row style={{marginBottom: '20px'}}>
             <Col md={12} style={{textAlign: 'right'}}>
               {
                 (this.state.selectedIds.length && dataKitList.data && dataKitList.data.type !== 'files' && dataKitList.data && dataKitList.data.length > 0 && Object.keys(this.state.currentFolder).length && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ) ?
                    <span style={{color: '#FE5D5E', marginRight: '10px', cursor: 'pointer'}} onClick={() => this.removeRecordAll()} >Remove File(s)</span>
                 :null
               }
               {
                 dataKitList.data && dataKitList.data.type !== 'files' && dataKitList.data && dataKitList.data.length > 0 && Object.keys(this.state.currentFolder).length && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPD') != -1 ?
                  <button style={{marginRight: '20px'}} className="primary-btn" onClick={() => this.props.getMiniPackList('files', this.state.currentFolder.uuid, {download: 'pdf'})}>Download</button>
                 :null
               }
               {
                 dataKitList.data && dataKitList.data.type !== 'files' && dataKitList.data && dataKitList.data.length > 0 && Object.keys(this.state.currentFolder).length && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPC') != -1 && [242].includes(getLocalStorageInfo().defaultLessor.id) ?
                  <button className="primary-btn" onClick={() => this.props.getMiniPackList('files', this.state.currentFolder.uuid, {download: 'pdf', merged_download: true})}>Generate Minipack</button>
                 :null
               }
             </Col>
           </Row>
       }
       <div className="asset-list-filter-sort">
         <Row className="filter-block">
           <Col md="8">
             <h6>
             Name</h6>
           </Col>
           <Col md="2">
             <h6>Size</h6>
           </Col>
           {
             Object.keys(this.state.currentFolder).length === 0 ?
             <Col md="1">
               <h6>No. Of Files</h6>
             </Col>
             :null
           }
           <Col md="1">

           </Col>
         </Row>
       </div>
       <Modal isOpen={this.state.delModalFlag} centered={true} toggle={this.removeRecord} className={this.props.className}>
         <ModalHeader toggle={this.removeRecord}>{this.state.recordAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to {this.state.recordAction} {this.state.previewCard.name}?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                <button className="btn btn-danger" onClick={() => {this.makeRecycleList(this.state.previewCard.uuid);this.removeRecord(this.state.previewCard, this.state.recordAction)}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.removeRecord(this.state.previewCard.uuid, this.state.recordAction)}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
       <Modal isOpen={this.state.delModalAllFlag} centered={true} toggle={this.removeRecordAll} className={this.props.className}>
         <ModalHeader toggle={this.removeRecordAll}>{this.state.recordAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to remove?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                <button className="btn btn-danger" onClick={() => {this.removeAll();this.removeRecordAll()}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.removeRecordAll()}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
       <Modal isOpen={this.state.delCoverPhotoFlag} centered={true} toggle={() => this.setState({delCoverPhotoFlag: false})} className={this.props.className}>
         <ModalHeader toggle={this.removeRecordAll}>Delete Cover Photo</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to delete cover photo?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                <button className="btn btn-danger" onClick={() => this.deleteMiniPackImage()}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.setState({delCoverPhotoFlag: false})}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>

       <div className="list-wrapper" style={{marginTop: '0px'}} id="dataBucket">
         {
           resourceLoader ?
           <ListLoader/> :
           (dataKitList.data && dataKitList.type && dataKitList.type === 'files' && dataKitList.data && dataKitList.data.length > 0 && !resourceLoader)?
           <SortableFilesContainer
             recycleBin = {false}
             dataKit = {true}
             removeRecord={(item, type) => this.removeRecord(item, type)}
             restoreRecord={(item, type) => this.deleteRecord(item, type)}
             addToRecycle={(item) => this.setState({selectedIds: this.state.selectedIds.length ? this.state.selectedIds.indexOf(item.id) !== -1 ? this.state.selectedIds.filter(id => id !== item.id) : [...this.state.selectedIds, item.id] : [item.id]})}
             recycleList={this.state.selectedIds}
             fileIcons={fileIcons}
             resourceLoader={resourceLoader}
             list={dataKitList.data}
             onSortEnd={(...args) => this.sortFiles(args, dataKitList.data)}
           />
             :(dataKitList.data && dataKitList.data.length > 0 && !resourceLoader)?
               dataKitList.data.map((item, index) =>
                 <MiniPackList
                   key={item.uuid}
                   shareRecord={() => this.shareRecord(item)}
                   index={index}
                   recycleBin = {false}
                   dataKit = {true}
                   listItem={item}
                   removeRecord={() => this.removeRecord(item, 'Remove')}
                   restoreRecord={() => this.deleteRecord(item, 'Restore')}
                   addToRecycle={() => this.props.addToRecycle(item.uuid,item.type)}
                   recycleList={recycleList}
                   fileIcons={fileIcons}
                   getFilesList={() => this.getMinipackFolderFiles(item)}
                 />
               ):<NoRecordFound/>
         }
         {
           !resourceLoader ?
           <Row>
           {
             Object.keys(this.state.currentFolder).length ?
              <Col className="coverPhotoContainer" md={4} style={{marginBottom: '10px', marginTop: '30px'}}>
                <h6>Cover Photo</h6>
                {
                  this.state.minipackImage && this.state.minipackImage != '' ?
                    <img src={this.state.minipackImage} style={{width: '160px', marginTop: '20px', display: 'block', marginBottom: '10px'}}/>
                  :null
                }
                {
                  this.state.minipackImage === '' || this.state.minipackImage === null || this.state.minipackImage === undefined ?
                  <button className="primary-btn" style={{cursor: 'pointer'}}>
                    Upload
                    <input accept="image/*" style={{opacity: '0', position: 'absolute', left: '15px', width: '140px'}} type="file" onChange={(evt) => this.setState({minipackImageFile: evt.target.files[0]}, () => this.saveMiniPackImage())}/>
                  </button>
                  :null
                }
                {
                  this.state.minipackImage && this.state.minipackImage != '' ?
                  <div className="coverPhotoActions">
                    <ul>
                      {
                        this.state.minipackImage && this.state.minipackImage != '' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 ?
                        <li>
                          Change
                          <input accept="image/*" style={{opacity: '0', position: 'absolute', left: '15px', width: '140px', cursor: 'pointer'}} type="file" onChange={(evt) => this.setState({minipackImageFile: evt.target.files[0]}, () => this.updateMiniPackImage())}/>
                        </li>
                        :null
                      }
                      {
                        this.state.minipackImage && this.state.minipackImage != '' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 ?
                        <li onClick={() => this.setState({delCoverPhotoFlag: true})}>
                          Remove
                        </li>
                        :null
                      }
                      {
                        this.state.minipackImage && this.state.minipackImage != '' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
                        <li onClick={() => this.setState({imageModal: true})}>
                          View
                        </li>
                        :null
                      }
                    </ul>
                  </div>:null
                }
              </Col>
             :null
           }
           </Row>
           :null
         }
         {
           (pagination && Object.keys(pagination).length &&dataKitList.data && dataKitList.data.length && dataKitList.data.list.length > 0) ?
             <Pagination
              activePage={pagination.current_page ? parseInt(pagination.current_page):1}
              itemsCountPerPage={20}
              totalItemsCount={pagination.total}
              pageRangeDisplayed={2}
              onChange={this.handlePageChange}
            />
           :null
         }

       </div>
       <Modal isOpen={this.state.ModalFlag} size="lg" toggle={() => this.setState({ModalFlag: false})} className="share-record-modal">
         <ModalHeader toggle={() => this.setState({ModalFlag: false})}>
           Share <strong>"{this.state.renameCard.name}"</strong>
         </ModalHeader>
         <ModalBody>
           <Row>
             <Col md="12">
               <input onChange={(evt) => this.changeShareStatus(evt) } ref="switch" checked={ isChecked } type="checkbox" />
               <span className="share-link-checkbox">Share Link</span>
             </Col>
           </Row>
           { isChecked == true ?
             <div>
               <Row>
                 <Col md="12">
                   <span>
                     Anyone with the url below will be able to view and download shared records.
                     Updating the sharing duration will generate a new URL below.
                   </span>
                 </Col>
               </Row>
               <Row>
                 <Col md="12">
                   <Input id="file_url" readOnly type="text" disabled={ isChecked == true ? false : true} className="share-text" value={ isChecked == true ? this.props.shareKey ? window.location.protocol+'//'+window.location.hostname+'/records/view-shared/' + this.props.shareKey +'/':'':''} placeholder="https://"/>
                   <span className="share-button" onClick={() => this.copyFileUrl()}>Copy Link</span>
                 </Col>
               </Row>
               <Row>
                 <Col md="12">
                   {resourceLoader}
                   <h2> {this.props.shareValidity != null ? 'Share Link Validity' : null}   {this.props.shareValidity != null ? <span>(Valid Till: {this.props.shareValidity != null && this.props.shareValidity != undefined && this.props.shareValidity != '' ? moment.utc(this.props.shareValidity).format(displayDateTimeFormatShort) + ' UTC' : '--'})</span> : null} {this.state.changeShareDuration ? <Link onClick={() => this.setState({changeShareDuration: false, shareUrl: false})} className="change-share-duration">Update Share Duration</Link> : null}</h2>
                   {
                     this.state.changeShareDuration == false ?
                     <ul className="list-inline">
                       <li className={this.state.shareDuration == 24 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                         <span onClick={() => this.setShareDuration("hour",24, false)}><input type="radio" checked={this.state.shareDuration == 24 ? 'checked' : ''} name="share_duration" /> 1 Day</span>
                       </li>
                       <li className={this.state.shareDuration == 72 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                         <span onClick={() => this.setShareDuration("hour",72, false)}><input type="radio" checked={this.state.shareDuration == 72 ? 'checked' : ''} name="share_duration" /> 3 Days</span>
                       </li>
                       <li className={this.state.shareDuration == 168 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                         <span><input type="radio" onClick={() => this.setShareDuration("hour",168, false)} checked={this.state.shareDuration == 168 ? 'checked' : ''} name="share_duration"/> 7 Days</span>
                       </li>
                       <li className={this.state.shareDuration == 240 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"} >
                         <span onClick={() => this.setShareDuration("hour",240, false)}><input type="radio" checked={this.state.shareDuration == 240 ? 'checked' : ''} name="share_duration" /> 10 Days</span>
                       </li>
                       <li className={this.state.customDuration ? "list-inline-item active" : "list-inline-item"}>
                         <span onClick={() => this.setCustomDuration("hour",6, true)}><input type="radio" checked={this.state.customDuration ? 'checked' : ''} name="share_duration"/> Custom</span>
                         <input type="number" min='1' step='1' value={this.state.customDuration ? this.state.shareDuration : 6} onChange={(evt) => this.setShareDuration(this.state.shareType, evt.target.value, true)} onBlur={(evt) => this.setCustomDuration(this.state.shareType, evt.target.value, true)}/>
                         <select onChange={(evt) => this.setCustomDuration(evt.target.value, this.state.customDuration ? this.state.shareDuration : 6, true)}>
                           <option value="hour">Hours</option>
                           <option value="days">Days</option>
                         </select>
                       </li>
                     </ul>
                     :null
                   }
                 </Col>
               </Row>
               <Row>
                 <Col md="12">
                   {this.state.shareUrl ?
                     <span className="copy-link-success">Link Copied Successfully</span>:null
                     }
                   </Col>
                 </Row>
                 <Row>
                   <Col md="12">
                     {this.state.shareDurationError  ?
                       <span style={{marginLeft: '175px'}} className="copy-link-success">Custom share duration can not be 0 or empty.</span>:null
                     }
                   </Col>
                 </Row>
               </div>
               :null
             }
             <Row>
               <Col md="12">
                 { isChecked == false?
                   <span style={{marginLeft: '200px'}} className="copy-link-success">Sharing link is disabled for this record</span>:null
                 }
               </Col>
             </Row>
           </ModalBody>
         </Modal>
       <Modal isOpen={this.state.imageModal} toggle={() => this.setState({imageModal: false})} className="modal-tform-pictures">
         <ModalHeader toggle={() => this.setState({imageModal: false})}></ModalHeader>
         <ModalBody>
           <ImageSlider imageSlider={{images: [{original: this.state.minipackImage, thumbnail: this.state.minipackImage}]}} />
         </ModalBody>
       </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  dataKitList: state.ResourcesReducer.dataKitList,
  resourceLoader: state.ResourcesReducer.resourceLoader,
  pagination: state.ResourcesReducer.pagination,
  recycleList: state.RecycleBinReducer.recycleList,
  techConstants: state.sharedReducers.techConstants,
  shareKey: state.DataRoomReducer.shareKey,
  shareValidity: state.DataRoomReducer.shareValidity,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Mini Kit')),
    getMiniPackList: (type, uuid, download) => dispatch(getMiniPackListAc(ownProps.params, type, uuid, download)),
    sortFiles: ( uuid, sortedFiles) => dispatch(sortMiniPackListAc(ownProps.params, uuid, sortedFiles)),
    restoreRecycleItem: (uuid,id) => dispatch(removeFileAc(ownProps.params,uuid,id)),
    restoreAllItem: (uuid, fileIds, oldFile) => dispatch(removeMiniPackFileAc(ownProps.params,uuid, fileIds, oldFile)),
    downloadresources: (template, uuid, type, name) => dispatch (downLoadResourcesTempAC(ownProps.params, template, uuid, type, name)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    shareRecords: (data, status, hours) => dispatch(shareRecordsAc(ownProps.params,data,status,hours, true)),
    toggleLoader: (flag) => dispatch({
      type: RESOURCE_LOADER,
      payload: flag
    }),
    getShareKey: (shareKey, shareValidity) => dispatch({
      type: SHARE_KEY,
      payload: {shareKey,shareValidity},
    }),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(MiniKit,['records','resources','R'])))
