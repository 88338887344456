import React , { Component } from 'react';
import {Row, Col} from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import HDLabelCard from './HDLabelCard';
import { getLocalStorageInfo } from '../../../../utils';
export default class HorizontalInfoCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { leaseInfo } = this.props;
    return(
      <div className="horizontal-card card-block">
        <h5 className="para"> Lease
        {getLocalStorageInfo().user.permission['contracts']['headlines'].indexOf('U') != -1 ?
          <img onClick = {this.props.toggleEditSideBar} src={imgStoragePath+"icon_editblock.png"} alt="img" className="blue-img" width="16"/>:
          null
        }
        </h5>
        <Row>
          <HDLabelCard col="4" label="Lease Type" value={leaseInfo.lease_type &&  leaseInfo.lease_type.label ?  leaseInfo.lease_type.label : '--'} />
          <HDLabelCard col="4" label="Lease Type ( Accounting )" value={leaseInfo.lease_type_accounting &&  leaseInfo.lease_type_accounting.label ? leaseInfo.lease_type_accounting.label : '--'} />
          <HDLabelCard col="4" label="Contract Number" value={leaseInfo.contract_number ? leaseInfo.contract_number : '--'} />
          <HDLabelCard col="12"label="Contractual Definition of Lease Period" value={leaseInfo.contractual_definition_of_lease_period ? leaseInfo.contractual_definition_of_lease_period : '--'} />
          <HDLabelCard col="4" label="Contractual Start Date" value={leaseInfo.contractual_start_date ? moment(leaseInfo.contractual_start_date).format(displayDateFormat) : '--'} />
          <HDLabelCard col="4" label="Contractual End Date" value={leaseInfo.contractual_end_date ? moment(leaseInfo.contractual_end_date).format(displayDateFormat) : '--'} />
          <HDLabelCard col="4" label="Lease Term" value={leaseInfo.lease_term ? leaseInfo.lease_term + ' Months': '--'} />
          <HDLabelCard col="4" label="Conditions Precedent" value={leaseInfo.conditions_precedence ? leaseInfo.conditions_precedence : '--'} />
          <HDLabelCard col="4" label="Alternate Dispute Redressal" value={leaseInfo.alternate_dispute_redressal ? leaseInfo.alternate_dispute_redressal : '--'} />
        </Row>
      </div>
    )
  }
}
