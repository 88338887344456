import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import moment from 'moment';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from  'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import Pagination from "react-js-pagination";
import { ContractHeadertext, ShortHead, ContractSideBar, WidgetCard, WidgetTitle, ContractBarLoader } from '../../Elements';
import { displayDateFormat, imgStoragePath, assetsType } from '../../../../constants';
import { removeEmptyKey, showCurrencyFormat } from '../../../../utils';
import FilterBar from '../../../../shared/FilterBar';
import { ListLoader, NoRecordFound  } from '../../../../shared';
import CashFlowListInfo from '../components/CashFlowListInfo';
import CashFlowList from '../components/CashFlowList';
import { loadCashFlowAssetViewAc, cashFlowListAc, exportContractCashflowAc } from '../actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import '../../assets/styles/CashFlow.scss';
class ContractCashFlow extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      appliedFilter: {},
      currentPage: 1,
      dropdownOpen: false
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  componentDidMount(){
    this.props.fetchAssetInfo();
  }
  toggleFilterList = () => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: !prevState.toggleFilter
    }))
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/cash-flow';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.componentDidMount();
    }
  }
  selectFilter = (value) => {
    this.setState(prevState => ({
      ...prevState,
      selectedFilterTitle: value,
      toggleFilter: false
    }))
  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.cashFowList(this.props.ctrShortInfo.contract.slug , getQueryStr);
    this.setState({
      appliedFilter: filterParams,
      page:1
    });
  }
  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'transaction_reference_id':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Reserve Account: ', value: args[1].name,
              filterData: args[1].id
            }
          }
        }
        break;
      case 'transaction_type':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Transaction Type: ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'currency':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Currency: ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'date':
        let date = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
        filterParams = {
          ...filterParams,
          [args[0]]: {
            name:'Date',
            value: date,
            startDate: args[1] ? args[1]:'',
            endDate: args[2]?args[2]:''
          }
        }
      default:
       break;
    }
    this.setState({
      appliedFilter: filterParams,
      page: 1
    });
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.cashFowList(this.props.ctrShortInfo.contract.slug , getQueryStr);
  }

  updateAssetQueryUrl = (queryFilter) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    location.query= queryFilter;
    browserHistory.push(location);
  }

  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if(key == 'date'){
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      }else{
        queryFilter[key] = params[key].filterData
      }
    });
    this.updateAssetQueryUrl(queryFilter);
    return queryFilter;
  }
  handlePageChange = (page) => {
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter));
    getQueryStr['page'] = page;
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    this.props.cashFowList(this.props.ctrShortInfo.contract.slug , getQueryStr);
  }
  render() {
    const { invoiceList, cashFlowList, ctrShortInfo, cashflowWidget, cashflowLoader, mrRatesList } = this.props;
    const filterMenu = {
      'Currency Type': {
        'inputType': 'dropdown',
        'keyParam': 'currency',
        'placeholder': 'Select Currency Type',
        'options': [{label: 'USD', value: 'USD'}, {label: 'EURO', value: 'EUR'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      },
      'Reserve Account Type': {
        'inputType': 'dropdown',
        'keyParam': 'transaction_reference_id',
        'placeholder': 'Select Reserve Account Type',
        'options': mrRatesList,
        'labelKey': 'name',
        'valueKey': 'id',
      },
      'Transaction Type':{
        'inputType': 'dropdown',
        'keyParam': 'transaction_type',
        'placeholder': 'Select Transaction Type',
        'options': [{label: 'Credit Note Transaction', value: '5'}, {label: 'Debit Note Transaction', value: '4'},{label: 'MR Transaction', value: '1'},{label: 'Penalty Transaction', value: '3'} ,{label: 'Rental Transaction', value: '2'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
    };
    return (
      <div className="contract-container">
       <div className="mr-rate-wrapper" style={{marginLeft: '60px'}}>
        { Object.keys(ctrShortInfo).length ?
          <ShortHead contrAssetBasicInfo={ctrShortInfo} changeMsn={this.changeMsn} headingText="Contracts" />: <ContractBarLoader />
        }
        <ContractSideBar application="Cashflow" type="shorter" params= {this.props.params} />
        {cashflowLoader ? <ListLoader />:null}
        <div className="cash-flow-tab">
          <Row className="invoice-cards flex-not-centered">
            <WidgetCard md="3" className="padding-right-0 padding-left-xs-0 " >
              <WidgetTitle className="para">Total Maintenance Reserve Fund</WidgetTitle>
              <div>
                <p>
                  {
                    cashflowWidget.total_balance ?
                    <NumericLabel params={showCurrencyFormat()}>
                      {cashflowWidget.total_balance - cashflowWidget.rental}
                    </NumericLabel>:'$0.00'
                  }

                  <span style={{color:'#ffffff !important',opacity:'0.5'}} className="as-of-date">
                  As of {
                    cashflowWidget.mr_detail_as_of_date ?
                    moment(cashflowWidget.mr_detail_as_of_date).format(displayDateFormat) : 'date --'
                  }</span>
                </p>
              </div>
            </WidgetCard>
            <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">

              <WidgetTitle className="para">Total Monthly Rental Fund</WidgetTitle>
              <div>
                <p>
                { cashflowWidget.rental_new ?
                  cashflowWidget.rental_new.length?
                  cashflowWidget.rental_new.map((rental, index) =>
                  <div>
                  <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                  </div>
                  ): '$0.00': '$0.00'
                }
                  <span style={{color:'#ffffff !important',opacity:'0.5'}} className="as-of-date">
                    As of {
                      cashflowWidget.rental_detail_as_of_date ?
                      moment(cashflowWidget.rental_detail_as_of_date).format(displayDateFormat) : 'date --'
                    }
                  </span>
                </p>
              </div>
            </WidgetCard>

          </Row>
        </div>

        {
          ctrShortInfo.asset_type === 1 || ctrShortInfo.asset_type ===2 ?
          <h5 className="uti-heading main-reserve-fund-status" >Maintenance Reserves fund status</h5>
          :null
        }
        {
          ctrShortInfo.asset_type === 1 || ctrShortInfo.asset_type ===2 ?
          <div className="cash-flow-tab">
            <Row className="invoice-cards flex-not-centered">
              {
                cashflowWidget.engines ?
                Object.keys(cashflowWidget.engines).map((engine,index) =>
                  <WidgetCard key={index} md="2" className="padding-right-0 padding-left-xs-0">
                    <WidgetTitle className="para">{cashflowWidget.engines[engine].name}</WidgetTitle>
                    <div>
                      <h6>PRSV</h6>
                      <p>
                        {
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.engines[engine].prsv}
                          </NumericLabel>
                        }
                      </p>
                    </div>
                    <div>
                      <h6>LLP Replacement</h6>
                      <p>
                        {
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.engines[engine].llp}
                          </NumericLabel>
                        }
                      </p>
                    </div>
                    {cashflowWidget.engines[engine].cold_section?
                    <div>
                      <h6>Cold Section</h6>
                      <p>
                        {
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.engines[engine].cold_section}
                          </NumericLabel>
                        }
                      </p>
                    </div>:null
                    }
                  </WidgetCard>
                ):null
              }

              {
                cashflowWidget.airframe_events && Object.keys(cashflowWidget.airframe_events).length ?
              <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para">Maintenance Events</WidgetTitle>
                  {Object.keys(cashflowWidget.airframe_events).map((program, index) =>
                  <div key={index}>
                    <h6>{program}</h6>
                    <p>
                      <NumericLabel params={showCurrencyFormat()}>
                        {cashflowWidget.airframe_events[program]}
                      </NumericLabel>
                    </p>
                  </div>
                  )}
              </WidgetCard>:null
            }
              {
                ctrShortInfo.asset_type == 1 ?
                <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                  <WidgetTitle className="para">APU</WidgetTitle>
                  <div>
                    <p>
                      {
                        cashflowWidget.apu ?
                        <NumericLabel params={showCurrencyFormat()}>
                          {cashflowWidget.apu}
                        </NumericLabel>:'$0.00'
                      }
                    </p>
                  </div>
                </WidgetCard>
                :null
              }
              {
                ctrShortInfo.asset_type == 1 ?
                <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                  <WidgetTitle className="para">Landing Gears</WidgetTitle>
                  <div>
                    <p>
                      {
                        cashflowWidget.lg ?
                        <NumericLabel params={showCurrencyFormat()}>
                          {cashflowWidget.lg}
                        </NumericLabel>:'$0.00'
                      }
                    </p>
                  </div>
                </WidgetCard>
                :null
              }
            </Row>
          </div>
          :null
        }
        <div className="cash-flow-wrapper">
          <div  className="download-block" style={{ textAlign: 'right', marginBottom: '15px'}}>
            {
              cashFlowList.list && cashFlowList.list.length ?
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret size="sm">
                   <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/> Export </span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem  onClick={() => this.props.exportCashFlow(ctrShortInfo, 'pdf', this.state.appliedFilter)}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                    <DropdownItem onClick={() => this.props.exportCashFlow(ctrShortInfo, 'xls', this.state.appliedFilter)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                    <DropdownItem onClick={() => this.props.exportCashFlow(ctrShortInfo, 'docx', this.state.appliedFilter)} ><img src={imgStoragePath+"docs_icon.png"} alt="img"/>Doc</DropdownItem>
                    <DropdownItem onClick={() => this.props.exportCashFlow(ctrShortInfo, 'csv', this.state.appliedFilter)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>CSV</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              :null
            }
          </div>
          <div className="mobile-asset-list">
            <div className="asset-list-filter-sort" >
                <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn}/>
            </div>
            <CashFlowListInfo>
                { cashFlowList.list.map((cashflow, index) =>
                  <CashFlowList key={index} index={index} cashflow={cashflow} cashflows={cashFlowList.list} contract_slug={this.props.ctrShortInfo.contract.slug} /> )
                }
              </CashFlowListInfo>
          </div>

          {
            !cashflowLoader && !cashFlowList.list.length ? <NoRecordFound style={{margin:'0px'}}description="Get Started by generating Maintenance & Rental invoices" />:null
          }
          {
            cashFlowList.list.length ?
            <div style={{padding: '0 0 40px 0'}}>
               <Pagination
                activePage={this.state.page}
                itemsCountPerPage={20}
                totalItemsCount={cashFlowList.pagination.total ? cashFlowList.pagination.total:0}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>:null
          }

        </div>
      </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  cashFlowList: state.ContrCashFlowReducer.cashFlowList,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  cashflowWidget: state.ContrCashFlowReducer.cashflowWidget,
  cashflowLoader: state.ContrCashFlowReducer.cashflowLoader,
  mrRatesList: state.ContrMRRateReducer.mrRatesList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadCashFlowAssetViewAc(ownProps.params)),
    cashFowList: (contract_slug, filter) => dispatch(cashFlowListAc(contract_slug, filter)),
    exportCashFlow: (contract, fileType, filter) => dispatch(exportContractCashflowAc(contract, fileType, filter))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractCashFlow, ['contracts','cash_flow','R'])));
