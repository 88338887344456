import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { getFileSize, localTimeFn, getLocalStorageInfo } from '../../../utils';
import { NoRecordFound  } from '../../../shared';
import { imgStoragePath, assetsType, recordCategories } from '../../../constants';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import Select from 'react-select';
import { getMsnSwitcherAc } from '../../../shared/actionCreators';
import ActivityComponent from '../../records/Dashboard/components/ActivityComponent';
import { DbBackCom, AssetWidget, ActionDockActivity } from '../components';
import {AssetPopDb} from '../components'
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { trackActivity } from '../../../utils/mixpanel';
import { sharedContentWidgetAc, actionDockCardWidgetAc, fetchActivitiesListAc, assetsInfoWidgetAc, fetchActionDockActivitiesListAc } from '../actionCreators';
class RecordsFleetDb extends Component {
  constructor(props){
    super(props);
    this.state ={
      activitySlug: 0,
    }
  }
  componentDidMount(){
    if(this.props.msnSwitcher.length <= 0){
			this.props.getMsnSwitcher();
		}
    this.props.fetchSharedContnet();
    this.props.fetchFilesStats();
    this.props.fetchActivitiesList(0);
    this.props.fetchActionDockActivitiesList();
    this.props.fetchAssetInfo({application: 6});
    trackActivity('Page Visited',{
      application_module: 'Dashboards',
      asset: 'All',
      page_title: 'Records Dashboard'
    })
  }
  filterActivityData = (evt) =>{
    this.setState({
      activitySlug: evt? evt.slug:''
    })
    evt? this.props.fetchActivitiesList(evt.id,evt.asset_type):this.props.fetchActivitiesList(0)
  }
  activityRedirect = (activity) => {
    if([3, 6, 7, 10, 11, 14, 15, 16, 17].includes(activity.activity_type.id)){
      if(activity.parent_folder.uuid){
          browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/data-room/'+ recordCategories[activity.parent_folder.category] + '/' +activity.parent_folder.uuid);
      }else if(activity.folder.uuid){
          browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/data-room/'+ recordCategories[activity.parent_folder.category] + '/' +activity.folder.uuid);
      }else{

      }
    }else if([1,2,4,5].includes(activity.activity_type.id)){
      let actionParams =  {
        1: 'quetype=1&status=status',
        2: 'quetype=1&status=status',
        4: 'quetype=4&status=status',
        5: 'quetype=1&status=status'
      };
      browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/action-dock/?'+actionParams[activity.activity_type.id]);
    }else if([8].includes(activity.activity_type.id)){
      browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/recycle-bin/');
    }
    else{
      browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/report/?report='+activity.asset.uuid);
    }
  }
  render(){
    const { sharedContentWidget, actionDockWidget, fleetActivityList, actionActivityList, params, dbAssetLoader, assetsInfoWidget,fileCountLoader} = this.props;
    let statusOnLease = assetsInfoWidget.filter(asset => asset.status == 1);
    let statusOffLease = assetsInfoWidget.filter(asset => asset.status == 2);
    let statusOnGround= assetsInfoWidget.filter(asset => asset.status == 3);
    let statusWrittenOFF = assetsInfoWidget.filter(asset => asset.status == 4);
    let assetWidgetCard
    if(getLocalStorageInfo().defaultLessor.id === 348){
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'OPERATIONAL',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'AOG', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
      ]
    }else{
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'ON LEASE',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'OFF LEASE', ownedUrl: '/assets-listing?status=2&ownership_type=1',managedUrl: '/assets-listing?status=2&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 2).length, url: '/assets-listing?status=2', classes: 'widget-cards marginRight marginLeft flex-centered off-lease'},
        { title: 'ON GROUND', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
        { title: 'WRITTEN OFF / SOLD', ownedUrl: '/assets-listing?status=4&ownership_type=1',managedUrl: '/assets-listing?status=4&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing?status=4', classes: 'widget-cards marginLeft flex-centered written-off'}
      ]
    }
    var showSplitTabs = false
    assetWidgetCard.map(item => {
      showSplitTabs = (item.managed > 0) ? true : showSplitTabs
      return item
    })
    return(
      <div className="fleet-records-db">
        <Row className="assets-row " style={{marginBottom: '10px'}}>
          { assetWidgetCard.map((asset, index) =>
            <AssetWidget
              className={asset.classes}
              statusGrpTitle={asset.title}
              value={asset.value}
              url={asset.url}
              managedUrl={asset.managedUrl}
              dbAssetLoader={dbAssetLoader}
              dbAssetLoader={dbAssetLoader}
              key={index}
              index={index}
              owned={asset.owned}
              managed={asset.managed}
              showManaged={showSplitTabs}
            />
          )}
        </Row>
        <DbBackCom pageTitle="Records Dashboard" />
        <Row>
        {fileCountLoader?
          <div style={{paddingLeft: '45%',zIndex:'10',marginTop:'-10px'}} className="data-room-expansion-loader-db"><img src={imgStoragePath + "spinner.gif"}/></div>:null
         }
          <Col md="12">
            <div className="file-status-card-size">
              <Row>
                <Col md="2">
                  <div className="fleet-size-details vhr">
                    <p className="title">No. Of Files</p>
                    <h4 className="count-stats">{actionDockWidget.totalFiles ? actionDockWidget.totalFiles : '--'}</h4>
                  </div>
                </Col>
                <Col md="2">
                  <div className="fleet-size-details">
                    <p className="title">Total Size</p>
                    <h4 className="count-stats">{actionDockWidget.totalSize ? getFileSize(actionDockWidget.totalSize): '--'}</h4>
                  </div>
                </Col>
                <Col md="8">
                  <div className="fleet-dash-progress" >
                    <ul>
                      <li>
                        <div className="progress-card">
                          <p className="title">In Queue</p>
                          <h4 className="count-stats">{actionDockWidget.inQueue ? actionDockWidget.inQueue : '--'}</h4>
                        </div>
                      </li>
                      <li>
                        <div className="progress-card">
                          <p className="title">OCR Completed</p>
                          <h4 className="count-stats">{actionDockWidget.ocrCompleted ? actionDockWidget.ocrCompleted :'--'} / <span>{parseInt(actionDockWidget.ocrCompleted ? actionDockWidget.ocrCompleted:0)+parseInt(actionDockWidget.ocrInQueue ? actionDockWidget.ocrInQueue:0)}</span>
                          </h4>
                        </div>
                      </li>
                      <li>
                        <div className="progress-card">
                          <p className="title">OCR Rejected</p>
                          <h4 className="count-stats">{actionDockWidget.ocrRejected ? actionDockWidget.ocrRejected :'--'}
                          </h4>
                        </div>
                      </li>
                      <li>
                        <div className="progress-card">
                          <p className="title">To be Renamed</p>
                          <h4 className="count-stats">{actionDockWidget.rename ? actionDockWidget.rename : '--'}</h4>
                        </div>
                      </li>
                      <li>
                        <div className="progress-card">
                          <p className="title">Published</p>
                          <h4 className="count-stats">{actionDockWidget.published ? actionDockWidget.published : '--'} / <span>{parseInt(actionDockWidget.published ? actionDockWidget.published:0)+parseInt(actionDockWidget.inQueue ? actionDockWidget.inQueue:0)}</span>
                          </h4>
                        </div>
                      </li>
                      <li>
                        <div className="progress-card">
                          <p className="title">Rejected</p>
                          <h4 className="count-stats">{actionDockWidget.rejected ? actionDockWidget.rejected : '--'}</h4>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="flex-not-centered">
        <Col md="8">
          <div>
            <div className="table-responsive record-activity-table flex-not-centered" style={{ minHeight:'400px', maxHeight: '300px', marginBottom: '20px'}}>
              <table className="table-sticky">
                <thead>
                  <tr>
                    <th className="activity-heading" style={{ position : "sticky", top : '0' }}>MSN / ESN</th>
                    <th style={{ position : "sticky", top : '0' }}>In Queue</th>
                    <th style={{textAlign: 'left', position : "sticky", top : '0'}}>To Be Renamed</th>
                    <th style={{ position : "sticky", top : '0' }}>Last Modified By</th>
                    <th style={{textAlign: 'right', position : "sticky", top : '0'}}>Last Modified At</th>
                  </tr>
                </thead>
                {actionActivityList && actionActivityList.data && actionActivityList.data.length ?
                  <tbody>
                    { actionActivityList.data.map((lastActivity, index) =>
                      <ActionDockActivity assetsType={assetsType} key={index} lastActivity={lastActivity}/>
                    )}
                  </tbody>
                  :null
                }
              </table>
              { actionActivityList && actionActivityList.data ?
                !actionActivityList.data.length ?
                  <NoRecordFound/>:null
                  :<p className="flex-centered">Loading...</p>
              }
            </div>
          </div>
        </Col>
          <Col md="4" className="flex-not-centered" style={{ minHeight:'100px', maxHeight: '400px'}}>
            <Row className="flex-not-centered" style={{width:'103%'}}>
              <Col md="4" className="flex-not-centered file-status-card">
                <div  style={{width:'100%',paddingTop:'20px'}}  >
                  <p className="title">Total No. Of Files Shared</p>
                  <h4 className="count-stats">{sharedContentWidget.totalShared?sharedContentWidget.totalShared:'--'}/<span>{sharedContentWidget.totalFiles ? sharedContentWidget.totalFiles:'--'}</span></h4>
                </div>
              </Col>
              <Col md="8" className="flex-not-centered shared-content-cn">
                {sharedContentWidget && sharedContentWidget.list ?
                  <table className="table-sticky">
                    <thead>
                      <tr>
                        <th>ASSET</th>
                        <th></th>
                        <th>Sharing Enabled</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sharedContentWidget.list.length >0 ?
                        sharedContentWidget.list.map((sharedContent, index) =>
                        <tr>
                          <td>
                            {
                              sharedContent.asset_type==1?
                              'MSN '+ sharedContent.msn:
                              sharedContent.asset_type==2?
                              'ESN '+ sharedContent.esn :
                              sharedContent.asset_type==3?
                              'APU '+ sharedContent.esn :
                              sharedContent.asset_type==4?
                              'LG '+ sharedContent.esn :
                              sharedContent.asset_type==5?
                              'PROPELLER '+ sharedContent.esn :''
                            }

                          </td>
                          <td>
                            {sharedContent.expire_details && sharedContent.expire_details.length?
                          <AssetPopDb
                              placement= 'down'
                              tagetId={'msn'+sharedContent.slug}
                              fileNames={sharedContent.expire_details}
                            />:null}
                          </td>
                          <td style={{paddingTop:'8px'}} className="file-count" onClick = {() => { window.open('/records/view-shared/'+sharedContent.folder_uuid + '?type=msn', '_blank')}}>
                            {sharedContent.shared_count} Files
                            <Link><img src={imgStoragePath+"rightArrow.png"} alt="nxt"/></Link>
                          </td>
                        </tr>
                        ):null
                      }
                    </tbody>
                  </table>:<p className="flex-centered">Loading...</p>
                }
                { sharedContentWidget && sharedContentWidget.list && !sharedContentWidget.list.length ? <NoRecordFound/>:null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="flex-not-centered">
        <Col md="12">
          <Row style={{marginLeft: '0px', marginRight: '0px',marginTop: '0px', height: '50px'}}>
            <Col md="9" className="activity-detail-header" style={{borderRight: 'none'}}>
              <h6 className="prpl-text">Activity Log</h6>
            </Col>
            <Col md="3" className="activity-detail-header" style={{borderLeft: 'none'}}>
              <div className="select-contract-block flex-centered" style={{float: 'right', height: '30px'}}>
                <Select
                  name="form-field-name"
                  placeholder="Select Asset"
                  value={this.state.activitySlug}
                  options = {this.props.msnSwitcher}
                  valueKey="slug"
                  labelKey="unique_name"
                  matchProp="any"
                  style={{width: '200px', height: '30px'}}
                  onChange = {(evt) => this.filterActivityData(evt)}
                />
              </div>
            </Col>
          </Row>
          <div>
            <div className="table-responsive record-activity-table flex-not-centered" style={{ minHeight:'350px', maxHeight: '350px', borderRadius: '0px 0px 3px 3px', borderTop: 'none'}}>
              <table className="table-sticky">
                { fleetActivityList && fleetActivityList.data && fleetActivityList.data.length ?
                  <tbody>
                    { fleetActivityList.data.map((lastActivity, index) =>
                      <tr onClick={() => getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('R') != -1 ? this.activityRedirect(lastActivity) : null}>
                        <ActivityComponent
                          name={lastActivity.modified_by.name}
                          fileName={lastActivity.new_name}
                          type={lastActivity.folder ? 'folder':'file'}
                          count={lastActivity.count}
                          id={lastActivity.activity_type.id}
                          userId={lastActivity.modified_by.id}
                          fromFolder={lastActivity.from_folder}
                          toFolder={lastActivity.to_folder}
                          oldName={lastActivity.old_name}
                        />
                        <td style={{textAlign: 'center'}}><img className="profile-pic"  src={lastActivity.modified_by.profile_pic ? lastActivity.modified_by.profile_pic : imgStoragePath+"default_lessor.png" } alt="img"/></td>
                        <td style={{textAlign: 'left'}}>
                          {
                            lastActivity.asset.asset_type == 1 ? 'MSN' : lastActivity.asset.asset_type == 2 ? 'ESN' : lastActivity.asset.asset_type == 3 ? 'APU' : lastActivity.asset.asset_type == 4 ? 'LG' : lastActivity.asset.asset_type == 5 ? 'PROPELLER' : ''
                          }&nbsp;
                          { lastActivity.asset.msn ? lastActivity.asset.msn : lastActivity.asset.esn ? lastActivity.asset.esn : lastActivity.asset.serial_number }
                        </td>
                        <td style={{textAlign: 'right'}}>{moment( localTimeFn(lastActivity.activity_time)).fromNow() }</td>
                      </tr>
                    )}
                  </tbody>:null
                }
              </table>
              { fleetActivityList && fleetActivityList.data ? !fleetActivityList.data.length ?
                <NoRecordFound/>:null:<p className="flex-centered">Loading...</p>
              }
            </div>
          </div>
        </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  assetsInfoWidget: state.DashboardReducer.assetsInfoWidget,
  sharedContentWidget: state.DashboardReducer.sharedContentWidget,
  actionDockWidget: state.DashboardReducer.actionDockWidget,
  fleetActivityList: state.DashboardReducer.fleetActivityList,
  actionActivityList: state.DashboardReducer.actionActivityList,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
  msnSwitcher: state.sharedReducers.msnSwitcher,
  fileCountLoader:state.DashboardReducer.fileCountLoader,
})
const mapDispatchToProps  = (dispatch, ownProps) => {
  return {
    fetchAssetInfo :(queryParam) => dispatch(assetsInfoWidgetAc(queryParam)),
    fetchSharedContnet: () => dispatch(sharedContentWidgetAc()),
    fetchFilesStats: () => dispatch(actionDockCardWidgetAc()),
    fetchActivitiesList: (aircraftId,type) => dispatch(fetchActivitiesListAc(aircraftId,type)),
    fetchActionDockActivitiesList: () => dispatch(fetchActionDockActivitiesListAc()),
    getMsnSwitcher: () => dispatch(getMsnSwitcherAc()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(RecordsFleetDb,['console','dashboard','R']));
