import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath, displayDateFormat } from '../../../../constants';

export default class AssetPopover extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {
		this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  toggleDelModal = ( ) => {
    this.setState(prevState =>({
      ...prevState,
      delModal: !prevState.delModal,
      modalTitle: 'Are you sure to delete?',
    }));
  }

  render() {
    const {  tagetId, placement, assets } = this.props;
    return (
        <span>
       { assets.length > 0 ?
       <span>
        <img width="12" className="img_eye" id={tagetId} onClick={() => this.toggle()} src={ imgStoragePath +'inv_info_icon.png'} alt="img" style={{ display: 'inline-block', marginLeft: '10px',cursor:'pointer'}}/>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
							<div className="project-tracker-popover">
              { assets.map((assetData,index) =>
							<div className="msn-outer-wrapper">
								<Row className="flex-not-centered engineer-block">
									<Col xs="6" className="padding-left-0">
										<div className="msn-title-wrapper flex-not-centered">
												<div className="first-wrapper">
												  <img src={imgStoragePath + 'aircraft_circle.png'} alt="img"/>
												</div>
												<div className="second-wrapper">
													<span>
													{ assetData.asset_type == 1 ?  assetData.asset.aircraft_type : assetData.asset_type == 2 ?  assetData.asset.engine_type : assetData.asset_type == 3 ? assetData.asset.apu_type : assetData.asset_type === 4 ? assetData.asset.lg_position : assetData.asset.propeller_position }
													</span>
													<p>
														<Link
														// to = {`/technical/project/view/${this.props.projectSlug}`}
														target="_blank">{assetData.asset_type == 1 ? 'MSN'+ assetData.asset.msn : assetData.asset_type == 2 ? 'ESN'+ assetData.asset.esn : `${assetData.asset_type === 3 ? 'APU' : assetData.asset_type === 4 ? 'LG' : 'PROPELLER'} ${assetData.asset.serial_number}`}</Link>
													</p>
												</div>
										</div>
									</Col>
									<Col xs="6" className="	">
										<span className={`status-indicator status${ assetData.status &&assetData.status.id ? assetData.status.id : null}`}></span>
									 	<span className="status-title">{assetData.status && assetData.status.label ? assetData.status.label : null}</span>
									</Col>
									<Col xs="6">
										<div className="start-date-wrapper">
												<span>Start Date</span>
												<p>
												{ assetData.start_date ? moment(assetData.start_date).format(displayDateFormat) : '--'}

												</p>
										</div>
									</Col>
									<Col xs="6">
										<div className="end-date-wrapper">
												<span>End Date</span>
												<p>
												{assetData.end_date ? moment(assetData.end_date).format(displayDateFormat) : '--'}
												</p>
										</div>
									</Col>
									<Col xs="12"  className="project-engineer-block" style={{padding: '15px 0 0px'}}>
									 {
										 assetData.project_engineers && assetData.project_engineers.length ?
											 assetData.project_engineers.map((engineer, index) =>
											 <div className="msn-title-wrapper flex-centered" >
											 {
												 engineer.profile_pic ?
												 <img src={engineer.profile_pic} style={{ borderRadius: '50%', height: '20px', width: '20px'}}/>
												:
											  	<img src={imgStoragePath+"user.png"} style={{ borderRadius: '50%', height: '20px', width: '20px'}}/>
											 }
													<div className="enginner-block" onClick = {() => {this.props.getUserDetailFn(engineer.slug , true); this.toggle()}}>
														<h6>Project Engineer</h6>
														<p>
															<a href="javascript:void(0)" target="_blank" data-id="qVKbX" className="user-block">
															{engineer.name  ? engineer.name : null}
															</a>
														</p>
														<p className="lead-by" style={{ fontSize: '10px'}} > { engineer.designation ? engineer.designation : null}</p>
													</div>
											 </div>
											 )
										 : null
									 }
								</Col>
							</Row>
							</div>
							)}
							</div>
            </PopoverBody>
        </Popover>
				</span>

        :  null }




     </span>

    );
  }
}
