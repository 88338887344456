

import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';


export default class HelpTextInspectionType extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }

render() {
// const { } = this.props;
  return(
    <div className="helptext">
    <p> <b><font size="4">Mid-Term Inspection</font></b><br/><i><font size="2">(also called Annual Inspection, Mid-lease inspection and other allied terms which include the same scope)</font></i><br/><br/>
Onsite technical support for aircraft mid-term inspections during the Term in accordance with the Inspection Program. Also perform a records review, which shall be carried out remotely should such records be available online in advance of the inspection; otherwise, an on-site records inspection shall be accomplished. When possible, the storage location of the physical records shall be visited.
<br/>
<br/>
<b><font size="4">Transition</font></b><br/><i><font size="2">(also called Redelivery,. Return & Delivery and other allied terms which include the same scope)</font></i><br/><br/>
Onsite technical support for transition management and lease return services for client, subject to geographical concentration considerations. Onsite team will be responsible to coordinate, manage and execute the redelivery of the aircraft to ensure a smooth and efficient transition.
<br/>
<br/>
<b><font size="4">Pre-Purchase Inspection</font></b><br/><i><font size="2">(also called Pre-Buy inspection, Due Diligence Inspection and other allied terms which include the same scope)</font></i><br/><br/>
On-site records and aircraft inspections for second-hand aircraft which are in the process of being acquired by client (buyer).
<br/>
<br/>
<b><font size="4">Sales Assistance</font></b><br/><i><font size="2">(also called Disposal Inspection, Due Diligence Assistance and other allied terms which includes the same scope)</font></i><br/><br/>
Onsite technical support during sales / disposal inspections, providing escort and due diligence support as may be required on behalf of the client (seller).
<br/>
<br/>
<b><font size="4">C/D/HMV Check Monitoring</font></b><br/><i><font size="2">(also called Airframe Maintenance Check monitoring or Oversight projects and other allied terms which includes the same scope)</font></i><br/><br/>
Onsite technical support for supervising aircraft inspections during major airframe structural events in accordance with the then-current maintenance program as applicable. Additional review of the check work scope as per the Lease Agreement criteria pertaining to a Maintenance Reserve Reimbursement claim may also be accomplished during such projects (at request from clients). Inspection will include a physical inspection of the asset and routine reporting on the check progress to the clients.
<br/>
<br/>
<b><font size="4">Records Review & Scanning Project</font></b><br/><i><font size="2">(also called Records Scanning projects, Full Records Inventory and Scanning and other allied terms which include the same scope)</font></i><br/><br/>
Onsite review of records, scanning and digitization of the hard copy records, structured indexing and uploading of the data into online data room platform of client’s choice (if included in the GTA).
<br/>
<br/>
<b><font size="4">General Surveillance</font></b><br/><i><font size="2">(also called Surveillance, Credit Risk Surveillance, Potential Repossession Watch and other allied terms which include the same scope)</font></i><br/><br/>
Onsite representation on behalf of clients to collect information, report major issues, maintain a watch over airline functioning, monitoring the titled major assemblies and reporting information to clients for risk mitigation measures to be implemented in due course.
<br/>
<br/>
<b><font size="4">Pre-Re-Delivery Inspection</font></b><br/><i><font size="2">(also called Pre-transition or Pre-Return inspections and other allied terms which include the same scope)</font></i><br/><br/>
Onsite technical support for inspecting the asset and its maintenance records, archives and preparation of the lessee for an upcoming scheduled end of lease project. The scope shall include the review of the lease return conditions and reporting of the observed condition of the asset to the client as a measure of compliance of the lease return conditions.
<br/>
<br/>
<b><font size="4">Airline Technical Review</font></b><br/><i><font size="2">(also called Lessee Due Diligence Inspection and other allied terms which include the same scope)</font></i><br/><br/>
Onsite visit to a lessee’s facility that the client wishes to place assets on lease with. Intent of inspection is to review the lessee’s processes and procedures and report to client on the technical experience, expertise, operational and commercial parameters for internal decision making exercise.
<br/>
<br/>
<b><font size="4">Valuation/Appraisals</font></b><br/>Provision of expert opinion by certified appraisers on asset value based on review of the aircraft technical records and physical condition (depending on the scope of the appraisal).
<br/>
<br/>

<b><font size="4">Engine shop visit management</font></b><br/>Workscope review and recommendation
Table top inspection and reporting<br/>
Engine repair progress monitoring and approval of parts replacement<br/>
Engine shop visit documents and invoice review<br/>
MR reimbursement recommendation<br/>
Insurance survey and claim recommendation
<br/>
<br/>

<b><font size="4">Engine BSI review and recommendation</font></b><br/>Review of BSI report and recommendation<br/>
Witness video BSI and reporting<br/>
<br/>

<b><font size="4">Engine document review</font></b><br/>Identifying the discrepancies/gap<br/>
Coordinate with various agencies for corrective actions<br/>
collation of documents and submit/upload the documents<br/>
<br/>

<b><font size="4">Engine redelivery management</font></b><br/>Engine documents review<br/>
MPA and BSI witness and recommendation<br/>
Engine build standard evaluation<br/>
Engine change monitoring<br/>
Engine inventory check<br/>
Overview of QEC swap<br/>
<br/>

<b><font size="4">Engine BSI witness and reporting</font></b><br/>
The borescope inspection is witnessed along with the certified inspector and ensure the finding<br />noted during borescope inspection are recorded in the BSI report and latest AMM is referred.<br/>
<br/>

<b><font size="4">Engine / LLP gap report</font></b><br/>
Supplied engine / LLP documents are reviewed for completeness against the checklist and<br />findings noted is raised as gap through report and follow up for closing all the gaps.<br/>
<br/>

<b><font size="4">Coordinate with various agencies for corrective actions</font></b><br/>
As title stated, will be contacting the concern engine repair shop / engine operator for<br />correction of the documents and certification of the same.<br/>
<br/>

<b><font size="4">Collation of documents and submit/upload</font></b><br/>
Randomly collected documents in several instances are organised and shared with concern for<br />ready reference and easy read through for further transactions.<br/>
<br/>

<b><font size="4">Engine build standard evaluation</font></b><br/>
Engine documents are reviewed considering the engine redelivery condition agreed between parties and<br />suggest the built life and approximately estimated life on-wing.<br/>
<br/>

<b><font size="4">Engine change monitoring</font></b><br/>
Be present during the engine removal/Installation to ensure all standard practices are followed, AMM<br />procedures are strictly adhered.<br/>
<br/>

<b><font size="4">Engine inventory check</font></b><br/>
Comparison of the components inventory during delivery and component inventory currently installed,<br />make note of the components changed, avail relevant documents supporting the serviceability and<br />installation.<br/>
<br/>

<b><font size="4">Engine QEC swap overview</font></b><br/>
Be present during the QEC change and make sure ensure all standard practices are followed and engine<br />documents are updated with Off, On components PN and SN.<br/>
<br/>

<b><font size="4">Engine advisory</font></b><br/>
With any specific question from client, the scenario is analysed, evaluated and suggested with the<br />answers.<br/>
<br/>

<b><font size="4">Engine specialist deputation</font></b><br/>
Upon request from client powerplant staff is positioned on-site and assigned role is performed during the<br />period.<br/>
<br/>

<b><font size="4">Engine / MRO selection</font></b><br/>
Based on the requirement received from client / operator, the compatible engine for their operation<br />and/or engine repair shop for maintenance is recommended.<br/>
<br/>

<b><font size="4">Maintenance Status</font></b><br/>
High-level records collection and review designed to provide the lessor with a  snapshot of the status and configuration of an aircraft during lease without physically inspecting the aircraft.<br />Records are requested to a minimum prescribed checklist consisting largely of mandatory statuses and statements.<br />From them a maintenance status report is written to highlight the current configuration of the aircraft, location of titled major assemblies and last done/next due status of heavy checks and major assembly shop visits.<br />The maintenance status can be completed remotely through remote provision of records or while onsite during inspection activity on other aircraft.<br/>
<br/>

Note: Any of the above services are available as stand alone service</p><br/>
    </div>
    );
  }
}
