import * as actions from '../actions';
import { globalGetService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
export const getFinanceDetailsAc = (params) => {
  return(dispatch) => {
    dispatch({
      type: actions.FINANCE_LOADER,
      payload: true
    });
    globalGetService(`contracts/${params.contract_slug}/financial-details/`)
    .then(response => {
      dispatch({
        type: actions.FINANCE_LOADER,
        payload: false
      });
       if(response.data.statusCode ==  200){
        dispatch({
          type:actions.FINANCE_DETAILS,
          payload:response.data.data
        });
       }
    })
  }
}

export const editFinanceAc = (params, data, type, assetViewId) => {
  return(dispatch) => {
    dispatch({
      type: actions.FINANCE_LOADER,
      payload: true
    });
    globalPutService(`contracts/${params.contract_slug}/financial-details/${data.id}/`, data)
    .then((response)=> {
      dispatch({
        type: actions.FINANCE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.FINANCE_DETAILS,
          payload:response.data.data
        });
        dispatch({
          type: actions.TOGGLE_VIEW_EDIT,
          payload: {data: {}, flag:false, otherFlag: false}
        });
        toastFlashMessage(response.data.message, "success")
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        trackActivity('Item Edited', {
          page_title: `Financing Details`,
          application_module: `Contracts`,
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
          contract_slug: params.contract_slug,
          item_type: type,
          item_id: data.id
        })
      }
    })
  }
}
