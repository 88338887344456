import React from 'react'
import { Row, Col } from 'reactstrap'
import NumericLabel from 'react-pretty-numbers';

const InvoiceDetailItem = (props) =>{
  return(
    <div className="asset-list" style={{ cursor: 'default' }}>
        <Row>
            <Col md="2" >
                <h6>
                { props.item.plan.name }
                </h6>
            </Col>
            <Col md="3" >
                <h6>
                { props.item.description ? props.item.description : '-'}
                </h6>
            </Col>
            <Col  md="2">
                <h6>
                    { props.item.plan.details.interval_type.label}
                </h6>
            </Col>
            <Col  md="1" style={{padding: '0px'}}>
                <h6>
                    $ <NumericLabel>{ props.item.amount }</NumericLabel>
                </h6>
            </Col>
            <Col  md="2">
                <h6>{ props.item.quantity != -1 ? props.item.quantity : 'Unlimited ' } { props.item.plan.units.label }</h6>
            </Col>
            <Col  md="2">
                <h6>$ <NumericLabel>{ props.item.sub_total}</NumericLabel></h6>
            </Col>
        </Row>
    </div>
  )
}

export default InvoiceDetailItem
