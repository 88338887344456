import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { imgStoragePath } from '../../constants';


export default class CautionReadMore extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    const { contentflag } = this.props;
    return(
      <div className="ai-assistant-instruction">
          <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
             <p>Please read the instruction below for optimum results from the Artificial Intelligence Program</p>
             <ul className="list-unstyled">
                 <li>
                    <img className="caution-img" src={imgStoragePath+"ai_pdf.png"} alt="img"/>
                    <h2>Only Readable PDF formats are supported</h2>
                    <p>No other format like Word, Excel, Open Document type etc.. are<br/> supported</p>
                 </li>
                 <li>
                    <img className="caution-img" src={imgStoragePath+"ai_dpi.png"} alt="img"/>
                    <h2>Minimum 300 DPI Resolution</h2>
                    <p>If the PDF document is scanned, it should be equal to or greater than 300DPI in resolution</p>
                 </li>
                 <li>
                    <img className="caution-img" src={imgStoragePath+"ai_source.png"} alt="img" style={{ left: '5px' }}/>
                    <h2>Source of Scanned PDF should be an Original</h2>
                    <p>In-case of scanned PDF/document, it should be from the original<br/> PDF document</p>
                 </li>
                 {contentflag?
                 <li>
                    <img className="caution-img" src={imgStoragePath+"ai_table.png"} alt="img" style={{ left: '5px' }}/>
                    <h2>Sample AD & OEM Status Report for Reference</h2>
                    <p>Make sure table structure in the AD or OEM Status Report is as per the reference provided below</p>
                    <Link to="https://aims-sfo.sfo2.cdn.digitaloceanspaces.com/others/static/samples/ADStatusFormats.pdf" style={{ marginRight: '10px' }} target="_blank">&darr; Download Sample</Link>
                    {/* <Link to="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/samples/OEM_AD_STATUS_FORMAT.xlsx" target="_blank">&darr; Download OEM Sample</Link> */}
                 </li>:null}
             </ul>
          </div>
      </div>
    )
  }
}
