
import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldInput } from '../../../contracts/Elements';
export default class EditEngineLLP extends Component {
  constructor(props){
    super(props);
    this.state = {
      engineLLP: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.llpInfoObj));
    this.setState({
      engineLLP:data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      engineLLP: {
        ...prevState.engineLLP,
        [type]: value
      }
    }))
  }
  addEditEngineLLP = (e) => {
    e.preventDefault();
    this.props.addEditEngineLLP(this.props.asset_slug, this.props.engineId, this.state.engineLLP, this.props.type, 'engineList');
  }

  render() {
    const { engineLLP } = this.state;
    const { type, engineId } = this.props;
    if(!Object.keys(engineLLP).length){
      return null
    }
    return (
      <form onSubmit={(e) => this.addEditEngineLLP(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
          <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Module</FieldLabel>
                <FieldInput
                  value={engineLLP.module}
                  type="view"
                  keyParam="module"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={engineLLP.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Description</FieldLabel>
                <FieldTextarea
                  value={engineLLP.description}
                  type="view"
                  keyParam="description"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Serial Number</FieldLabel>
                <FieldInput
                  value={engineLLP.serial_number}
                  type={type}
                  keyParam="serial_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">TSN</FieldLabel>
                <FieldInput
                  value={engineLLP.tsn}
                  type={type}
                  keyParam="tsn"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">CSN</FieldLabel>
                <FieldInput
                  value={engineLLP.csn}
                  type={type}
                  keyParam="csn"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Cycles Max Limit</FieldLabel>
                <FieldInput
                  value={engineLLP.cycles_max_limit}
                  type={type}
                  keyParam="cycles_max_limit"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Cycles Remaining</FieldLabel>
                <FieldInput
                  value={engineLLP.cycles_remaining}
                  type={type}
                  keyParam="cycles_remaining"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
    )
  }
}
