export const INVOICE_LIST = 'INVOICE_LIST'
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_EDIT_SUCCESS = 'INVOICE_EDIT_SUCCESS';
export const INVOICE_VIEW_SUCCESS = 'INVOICE_VIEW_SUCCESS';
export const INVOICE_FILTER_UPDATE = 'INVOICE_FILTER_UPDATE';
export const INVOICE_SORT_UPDATE = 'INVOICE_SORT_UPDATE';
export const INVOICE_LOADER = 'INVOICE_LOADER';
export const INVOICE_ASSET_INFO = 'INVOICE_ASSET_INFO';
export const UPDATE_INVOICE_ITEM_FIELD = 'UPDATE_INVOICE_ITEM_FIELD';
export const UPDATE_INSURANCE_INVOICE_ITEM_FIELD = 'UPDATE_INSURANCE_INVOICE_ITEM_FIELD';
export const UPDATE_INVOICE_FIELD = 'UPDATE_INVOICE_FIELD';
export const ADD_INVOICE_ITEM_FIELD = 'ADD_INVOICE_ITEM_FIELD';
export const GEN_INVOICE = 'GEN_INVOICE';
export const INVOICE_WIDGET = 'INVOICE_WIDGET';
export const INV_STR_LOADER = 'INV_STR_LOADER';
export const UPDATE_PENALETY_INVOICE_FIELD = 'UPDATE_PENALETY_INVOICE_FIELD';
export const UPDATE_LIST_STATUS = 'UPDATE_LIST_STATUS';
export const INVOICE_SHARE = 'INVOICE_SHARE';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const UPDATE_CHILDREN_ITEM_FIELD='UPDATE_CHILDREN_ITEM_FIELD';
export const INVOICE_EXPORT_LOADER='INVOICE_EXPORT_LOADER';
