import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../../globalServices';
import { toastFlashMessage, removeEmptyKey, downloadFileType } from '../../../../utils';
import { browserHistory } from 'react-router';
import { trackActivity } from '../../../../utils/mixpanel'
import { createProject } from '../../Forms/AddFormObjects';

export const triggerPush = (data) => {
  switch (data.status){

  case 1:
   return browserHistory.push('/technical/program-planner/create/program');
  case 2:
   return browserHistory.push('/technical/program-planner/assets');
  case 3:
   return  browserHistory.push('/technical/program-planner/program/'+data.program);
  case 4:
   return  browserHistory.push('/technical/program-planner/program/'+data.program+'/details');
  }

}

export const triggerLoader=(flag)=>{
 return (dispatch) => {
  dispatch({
    type: actions.COMMON_PLANNER_LOADER,
    payload: flag
  });
  }
}


export const projectViewCrudAc = (params,data, slug) => {
  return (dispatch) => {
    dispatch({
      type:  actions.TOGGLE_PROJECT_SIDEBAR,
      payload: createProject
    });
    if(data.type == 'add'){
      dispatch(getGeneralInstructionAc())
      dispatch({
        type: actions.TOGGLE_PROJECT_SIDEBAR,
        payload: data
      })
    }
  }
}


export const addEditProjectAc = (param, data, ids) => {
  let project=data;
  project = {
    ...project,
    assets:ids
  }
  return(dispatch) => {
     dispatch(triggerLoader(true));
      globalPostService('technical/program/'+param.programId+'/create-project/', project)
      .then(response => {
       dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          browserHistory.push('/technical/program-planner/program/'+param.programId+'/tracker');
        }
      })

  }
}


export const fetchProgramStatusAc = (props) => {
  return (dispatch) => {
   // dispatch(triggerLoader(true));
   dispatch({
    type: actions.STATUS_PLANNER_LOADER,
    payload: true
   });
    globalGetService('technical/program/status/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PROGRAM_PLANNER_STATUS,
          payload: response.data.data
        });
        dispatch({
          type: actions.STATUS_PLANNER_LOADER,
          payload: false
         });
        if(browserHistory.getCurrentLocation().pathname=='/technical/program-planner'||browserHistory.getCurrentLocation().pathname=='/technical/program-planner/create/program'){
         // dispatch(triggerPush(response.data.data));
          switch (response.data.data.status){
            case 1:
             return browserHistory.push('/technical/program-planner/create/program');
            case 2:
             return browserHistory.push('/technical/program-planner/assets');
            case 3:
             return  browserHistory.push('/technical/program-planner/program/'+response.data.data.program);
            case 4:
             return  browserHistory.push('/technical/program-planner/program/'+response.data.data.program+'/details');
            }
        }
      }
     // dispatch(triggerLoader(false));
    });
  }
}

export const fetchSchedulerAssetsAc = (props,queryParams) => {
  return (dispatch) => {

    dispatch(triggerLoader(true));
    globalGetService('technical/program/'+props.programId+'/scheduler-assets/', queryParams)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PROGRAM_SCHEDULER_LIST,
          payload: response.data.data
        });
        trackActivity('Page Visited', {
          application_module: 'Program Planner',
          page_title: `Program Details`
        })
      }

    });
  }
}



export const fetchFIlterListAc = (queryParams) => {
  return (dispatch) => {

    dispatch(triggerLoader(true));
    globalGetService('technical/program/assets/filter-list/', {type:queryParams})
    .then(response => {
      if(response.data.statusCode == 200){
        if(queryParams=='lessee'){
          dispatch({
            type: actions.LESSE_LIST,
            payload: response.data.data
          });
        }else if(queryParams=='aircraft_type'){
          dispatch({
            type: actions.AIRCARAFT_LIST,
            payload: response.data.data
          });
        }else if(queryParams=='engine_type'){
          dispatch({
            type: actions.ENGINE_LIST,
            payload: response.data.data
          });
        }

      }
      dispatch(triggerLoader(false));
    });
  }
}


export const fetchProgramTrackerAc = (props,queryparams) => {
  return (dispatch) => {
    dispatch({
      type: actions.STATUS_PLANNER_LOADER,
      payload: true
     });
    globalGetService('/technical/program/tracker/',queryparams)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PROGRAM_TRACKER_LIST,
          payload: response.data.data
        });
        trackActivity('Page Visited', {
          application_module: 'Program Planner',
          page_title: `Program Tracker`
        })
      }
      dispatch({
        type: actions.STATUS_PLANNER_LOADER,
        payload: false
       });
    });
  }
}






export const fetchConfirmAssetsAc = (props,queryParams) => {

  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/program/'+props.programId+'/confirm-assets-list/', queryParams)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PROGRAM_PLANNER_CONFIRM_ASSETS,
          payload: response.data.data
        });
      }

    });
  }
}


export const fetchAssetsAc = (props,queryParams) => {

  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/program-planner/assets/', queryParams)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
            type: actions.PROGRAM_PLANNER_ASSETS,
            payload: response.data.data
        });
      }

    });
  }
}



export const assetExportAc = (props,flag,query) => {
  let url='';
  let name='';
  if(flag=='Assets'){
    url='technical/program-planner/assets/';
    name='assets';
  }else if(flag=='confirm'){
    url='technical/program/'+props.programId+'/confirm-assets-list/';
    name='confirm_assets_list';
  }else{
    url='technical/program/'+props.programId+'/scheduler-assets/';
    name='scheduler_assets';
  }

  return (dispatch) => {
    dispatch(triggerLoader(true));

    globalExportService(url, query)
    .then(response => {
      dispatch(triggerLoader(false));
      downloadFileType(response, name+'.', 'xls')
    })
  }
}



export const trackerExportAc = (name,query) => {

  return (dispatch) => {
    dispatch(triggerLoader(true));

    globalExportService('technical/program/tracker/', query)
    .then(response => {
      dispatch(triggerLoader(false));
      downloadFileType(response, name+'.', 'xls')
    })
  }
}

export const technicalInspectionTypeAc = (type) => {
  return (dispatch) => {
      globalGetService('technical/inspection-types/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.INSPECTION_TYPE,
            payload: response.data.data
          });
        }
      })
  }
}



export const updateAssetValueAc = (key,value,id,flag) => {
  return (dispatch) => {
      globalPostService('technical/program/assets/'+id+'/', {[key]:value})
      .then(response => {
        if(response.data.statusCode == 200){
          if(flag=='confirm'){
            dispatch({
              type: actions.UPDATE_CONFIRM_LIST,
              payload: response.data.data
            });
          }else if(flag=='assets'){
            dispatch({
              type: actions.UPDATE_ASSETS_LIST,
              payload: response.data.data
            });
          }else{
            dispatch({
              type: actions.UPDATE_SCHEDULER_LIST,
              payload: response.data.data
            });
          }

        }
      })
  }
}

export const updateCommentAc = (assetID,value,flag) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
      globalPostService('technical/program/assets/'+assetID+'/comment/', {data:value})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          if(flag=='confirm'){
            dispatch({
              type: actions.UPDATE_CONFIRM_COMMENTS,
              payload: {id:assetID,data:response.data.data}
            });
          }else if(flag=='assets'){
            dispatch({
              type: actions.UPDATE_ASSETS_COMMENTS,
              payload: {id:assetID,data:response.data.data}
            });
          }else if(flag=='tracker'){
            dispatch({
              type: actions.UPDATE_TRACKER_COMMENTS,
              payload: {id:assetID,data:response.data.data}
            });
          }else{
            dispatch({
              type: actions.UPDATE_SCHEDULER_COMMENTS,
              payload: {id:assetID,data:response.data.data}
            });
          }

        }
      })
  }
}




export const createProgramAc = (props) => {
  return (dispatch) => {
   // dispatch(triggerLoader(true));
    globalPostService('technical/program/create/', {})
    .then(response => {
      if(response.data.statusCode == 200){
          dispatch(fetchProgramStatusAc());
      }
     //
    });
  }
}


export const addAssetProgramAc = (props,id,ids) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalPostService('technical/program/'+id+'/add-asset/', {asset_ids:ids})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        browserHistory.push('/technical/program-planner/program/'+id);
      }
     //
    });
  }
}

export const fetchProjectUserAc = (queryParams, type) => {
  return (dispatch) => {
   // dispatch(triggerLoader(true));
      globalGetService('technical/users/', queryParams)
      .then(response => {
       // dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.PROJECT_MEMBERS,
            payload: {type: type, data:response.data.data}
          });
        }
      })
  }
}

export const getGeneralInstructionAc = () => {
  return (dispatch) => {
    globalGetService('/technical/project-initiate/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.GET_GEN_INFO,
          payload: response.data.data
        });
      }
    })
}
}



export const addAssetConfirmProgramAc = (props,ids) => {
  return (dispatch) => {
  dispatch(triggerLoader(true));
    globalPostService('technical/program/'+props.programId+'/add-asset-program/', {asset_ids:ids})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CLEAR_SELECTED_IDS,
          payload: {}
        });
        browserHistory.push('/technical/program-planner/program/'+props.programId+'/details');
      }
     //
    });
  }
}


export const deleteCommentAc = (assetId,id,flag) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalDeleteService('technical/program/assets/'+assetId+'/delete-comment/'+id, {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        if(flag=='confirm'){
          dispatch({
            type: actions.UPDATE_CONFIRM_COMMENTS,
            payload: {id:assetId,data:response.data.data}
          });
        }else if(flag=='assets'){
          dispatch({
            type: actions.UPDATE_ASSETS_COMMENTS,
            payload: {id:assetId,data:response.data.data}
          });
        }else if(flag=='tracker'){
          dispatch({
            type: actions.UPDATE_TRACKER_COMMENTS,
            payload: {id:assetId,data:response.data.data}
          });
        }else{
          dispatch({
            type: actions.UPDATE_SCHEDULER_COMMENTS,
            payload: {id:assetId,data:response.data.data}
          });
        }
      }
     //
    });
  }
}
export const deleteTrackerCommentAc = (assetId,id,flag) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalPostService('technical/program/assets/'+assetId+'/delete-comment/'+id, {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        if(flag=='tracker'){
          dispatch({
            type: actions.UPDATE_TRACKER_COMMENTS,
            payload: {id:assetId,data:response.data.data}
          });
        }
      }
     //
    });
  }
}


export const deleteAssetAc = (params,id,flag) => {
  let data = {};
  if(params.programId!=null){
    data = {
      ...data,
      program_slug:params.programId
    }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/program/assets/'+id+'/delete-asset/', data)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        if(flag=='confirm'){
          dispatch({
            type: actions.DELETE_CONFIRM_COMMENTS,
            payload:id
          });
        }else if(flag=='assets'){
          dispatch({
            type: actions.DELETE_ASSETS_COMMENTS,
            payload: id
          });
          dispatch(fetchSchedulerAssetsAc(params, {}))
        }
      }
     //
    });
  }
}



export const reviewConfimAssetAc = (props) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalPostService('technical/program/'+props.programId+'/confirm-asset/', {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        browserHistory.push('/technical/program-planner/program/'+props.programId+'/details');
      }
     //
    });
  }
}
