import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { FieldGroup, FieldTextarea, EditSidebar, ShortHead, FormModeType, ContractBarLoader } from '../../../contracts/Elements';
import classnames from 'classnames';
import { AssemblySideBar } from '../../Elements';
import AfViewWrapper from '../../AirframeAssembly/components/AfViewWrapper';
import AfViewCard from '../../AirframeAssembly/components/AfViewCard';
import AfClaimInfo from '../../AirframeAssembly/components/AfClaimInfo';
import AfClaimPayout from '../../AirframeAssembly/components/AfClaimPayout';
import AddEditShopVisit from '../../AirframeAssembly/components/AddEditShopVisit';
import AddEditClaims from '../../AirframeAssembly/components/AddEditClaims';
import AddEditPayout from '../../AirframeAssembly/components/AddEditPayout';
import EngineWorkScope from '../components/EngineWorkScope'
import { engineShopVisitCrudAc, addEditEngineShopVisitAc, engineSVStatusAc, engineClaimsListAc, engineClaimInfoCrudAc, addEditEngineClaimAc, engineClaimStatusAc, engineLLPListAc, engineLLPInfoAc, engineAddLLPAc, engineLlpCrudFnAc, editLLPAc, delLLpAc } from '../actionCreators';
import { TOGGLE_VIEW_EDIT, ENGINE_SHOP_VISIT_CRUD, ENGINE_CLAIM_CRUD, ENGINE_PAYOUT_CRUD, ADD_WORKSCOPE_ITEM, DEL_WORKSCOPE_ITEM, CLOSE_ENGINE_LLP_SIDEBAR ,UPDATE_WORK_SCOPE,ADD_WORKSCOPE_MODULE, ADD_WORKSCOPE_SUBMODULE, TOGGLE_VIEW_EDIT_ENGINE} from '../actions';
import { eventTypeAc, assetInfoAc } from '../../AirframeAssembly/actionCreators';
import LLPList from '../components/LLPList';
import EngineLLPAdd from '../components/EngineLLPAdd';
import EditEngineAsy from '../components/EditEngineAsy';
import EngineHeader from '../components/EngineHeader';
import { ListLoader, NoRecordFound } from '../../../../shared';
import '../../assets/styles/maintenance.scss';
import { checkString } from '../../../../formValidator';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
class EngineView extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      activeTab: '1',
      modal: false,
      shopVisitReason: '',
      claimModal:false,
      claim:'',
      error: {}
    };

  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleClaim = (claim_id) => {
    if(claim_id != undefined){
      this.setState({
        claimModal: !this.state.claimModal,
        claim:claim_id
      });
    }
    else{
      this.setState({
        claimModal: !this.state.claimModal,
        claim:''
      });
    }
  }

  rejectClaim = (e) => {
    e.preventDefault();
    const validateNewInput = {
      shopVisitReason: checkString({value: this.state.shopVisitReason, required: true, minLength: '', maxLength: '', message: 'Please Enter Reason To Reject'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.changeClaimStatus(this.state.claim,{status:4, reason_for_rejection: this.state.shopVisitReason});
      this.toggleClaim();
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  updateSvReson = (e) => {
    let value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      shopVisitReason: value,
      error: {}
    }));
  }
  rejectSv = (e) => {
    e.preventDefault();
    const validateNewInput = {
      shopVisitReason: checkString({value: this.state.shopVisitReason, required: true, minLength: '', maxLength: '', message: 'Please Enter Reason To Reject'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.changeSVStatus({status: 4, reason_for_rejection: this.state.shopVisitReason});
      this.toggleModal();
      // this.toggle();
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  componentDidMount(){
    this.props.eventDetails();
    this.props.eventType();
    this.props.fetchClaims();
    this.props.fetchAssetInfo();
    this.props.fetchLLPList();
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/engines';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

updateWorkScopesToShop=()=>{
  let data = JSON.parse(JSON.stringify(this.props.engineShopVisit.data));
  data = { ...data, workscope_modules:this.props.workScopes};
  this.props.addEditShopVisit(data, 'edit');
}

  toggle = () => {
    this.props.fetchModule(this.props.engineShopVisit.data.asset_id, !this.props.engineLLPinfo.flag);
  }


  render(){
    const { error } = this.state;
    const { workScopes, engineShopVisit, engineClaims, eventTypes, engineClaimInfo, enginePayoutInfo, assetInfoObj, engineLLPList, engineLoader, engineLLPinfo, engineLLpCrud } = this.props;
    return(
      <div style={{marginLeft: '215px', marginTop: '94px'}}>
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} changeMsn={this.changeMsn} headingText="Maintenance" />:<ContractBarLoader />
        }
        {engineLoader ? <ListLoader />:null}
        <AssemblySideBar params={this.props.params}/>
          <div className="mr-rate-inner-wrapper">
          <div className="assembly-view-layout" style={{padding: '0 15px'}}>
            <Nav tabs>
              <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggleTab('1'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Shop Visit Tab' })}}
                  >
                    Shop Visit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggleTab('2'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'LLP Tab' })}}
                >
                  LLP
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggleTab('3'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Workscope Tab' })}}
                  >
                  Workscope
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {
                  Object.keys(engineShopVisit.data).length ?
                  <div>
                    <AfViewWrapper shopVisit={engineShopVisit.data} backLink={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/maintenance/engines'}>
                      <AfViewCard loader={engineLoader} changeSVStatus={this.props.changeSVStatus} changeSVStatus={this.props.changeSVStatus} editAssembly={this.props.editAssembly} shopVisit={engineShopVisit.data}  toggle={this.toggleModal}/>
                    </AfViewWrapper>
                    {
                      engineShopVisit.data.status.value == 3 ?
                      <div className="add-claims-section">
                        <div className="add-claim-cta">
                          {
                            getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                            <h4>MR Claim</h4>
                            :null
                          }
                            {
                              getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                              <button onClick={() => this.props.claimInfoCrud('add', {})} className="btn-primary add-new">+ Add Claim</button>
                              :null
                            }
                        </div>
                        {
                          engineClaims.map((claim,index) =>
                          <div key={index} className="card-body">
                            { (claim.status.value == 1 ||  claim.status.value ==2) && getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                              <span onClick={() => this.props.toggleClaimEditBar({type: 'edit', data: claim, flag: true})} className="edit-icon"><img src={imgStoragePath+"icon_editblock.png"} alt="icon" /></span>:null
                            }
                            {
                              getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                              <div className="shop-visit-status">
                                <ul className="list-inline">
                                  {
                                    claim.status.value == 1 ?
                                    <li className="list-inline-item">
                                      <span>Claim is Submitted </span>
                                      <button disabled={engineLoader} onClick={() => {this.props.changeClaimStatus(claim.id, {status: 2}); ; trackActivity('Item Status Changed', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Move to Review' })}} className="btn-primary add-new">{ engineLoader ? 'Processing...': 'Move to Review'}</button>
                                    </li>:null
                                  }
                                  {
                                    claim.status.value == 2 ?
                                    <li className="list-inline-item">
                                      <span>Claim is Submitted </span>
                                      <button disabled={engineLoader} onClick={() => {this.props.changeClaimStatus(claim.id, {status: 3}); ; trackActivity('Item Status Changed', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Approve' })}} className="btn-primary add-new">{engineLoader ? 'Processing...':'Approve'}</button>
                                      <button className="btn-primary add-new" onClick={() => {this.toggleClaim(claim.id); ; trackActivity('Item Status Changed', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Reject' })}}>Reject</button>
                                    </li>:null
                                  }
                                </ul>
                              </div>
                              :null
                            }
                            <div>
                              <AfClaimInfo claim={claim} />
                            </div>
                            {
                              claim.status.value == 3 && getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                              <div className="claim-payout-detail">
                                {
                                  claim.payout_status.value == 1 ?
                                  <span className="edit-icon" onClick={() => this.props.togglePayoutEditBar({type: 'edit', data: claim, flag: true})}><img src={imgStoragePath+"icon_editblock.png"} alt="icon" /></span>:null
                                }
                                <h4>Claim Payout Details</h4>
                                  {
                                    claim.payout_status.value == 1 ?
                                    <div className="shop-visit-status">
                                      <ul className="list-inline">
                                        <li>
                                          <span>Claim Amount Verification is Pending</span>
                                          <button
                                            disabled={!(claim.amount_paid > 0 && claim.amount_paid <= claim.claim_amount && claim.amount_paid != '')}
                                            className="btn-primary add-new"
                                            onClick={() => {this.props.changeClaimStatus(claim.id, {payout_status: 2}); trackActivity('Item Clicked', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance',  item_type:'Verify Amount', item_id:claim.id })}}>
                                            { engineLoader ? 'Processing...':'Verify Amount'}
                                          </button>
                                        </li>
                                      </ul>
                                    </div>:null
                                  }
                                  {
                                    claim.payout_status.value == 2 ?
                                    <div className="shop-visit-status">
                                      <ul className="list-inline">
                                        <li>
                                          <span>Claim Payout is Pending</span>
                                          <button
                                            disabled={!(claim.amount_paid > 0 && claim.amount_paid <= claim.claim_amount && claim.amount_paid != '')}
                                            className="btn-primary add-new"
                                            onClick={() => {this.props.changeClaimStatus(claim.id, {payout_status: 3}); trackActivity('Item Clicked', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Mark as Paid', item_id:claim.id })}}>
                                            { engineLoader ? 'Processing...' :'Mark as Paid'}
                                          </button>
                                        </li>
                                      </ul>
                                    </div>:null
                                  }
                                <AfClaimPayout loader={engineLoader} claim={claim} />
                              </div>:null
                            }
                          </div>
                        )}

                      </div>:null
                    }
                  </div>:null
                }
              </TabPane>
              <TabPane tabId="2">
              <div className="llp-table">
                {
                  getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.engines && (getLocalStorageInfo().user.permission.maintenance.engines.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                  <div className="add-item">
                    <Link onClick={this.toggle} className="primary-btn ">+ Add LLP</Link>
                  </div>
                  :null
                }
                <div className="overflow-block">
                  <div className="table-outer-wrap asset-list-filter-sort">
                  <EngineHeader/>
                    {
                     engineLLPList.map((llpList, index) =>
                        <LLPList delLLp={(id)=>this.props.delLLp(id, assetInfoObj)} key={index} llpList={llpList} viewLLPEngine = {() => this.props.engineLlpCrudFn(llpList.id, 'edit')}/>
                      )
                    }
                    {!engineLoader && !engineLLPList.length ? <NoRecordFound description=""/>:null}
                  </div>
                </div>
              </div>

               <EditSidebar
                title={ 'Edit LLP ' }
                editSideBar={engineLLpCrud.flag}
                toggleEditSideBar={() => this.props.closeEditViewSideBar('engineLLpCrud', {flag: false,index: 0,data: {},type : ''})}
               >
                {
                  Object.keys(engineLLpCrud.data).length && engineLLpCrud.flag ?
                  <div>
                    <EditEngineAsy
                      type={engineLLpCrud.type}
                      engineLLpCrudObj = {engineLLpCrud.data}
                      toggleEditSideBar={() => this.props.closeEditViewSideBar('engineLLpCrud', {flag: false,index: 0,data: {},type : ''})}
                      editLLP={(data, type)=>this.props.editLLP(data, type, assetInfoObj)}
                    />
                  </div>:null
                }
          </EditSidebar>
              <Modal isOpen={engineLLPinfo.flag} className="llp-brekup-modal" toggle={this.toggle} >
                  <ModalHeader toggle={this.toggle}>LLP List</ModalHeader>
                  <ModalBody className="engine-llp">
                    <EngineLLPAdd updateForm = {this.props.updateForm} engineAddLLP={(data)=>this.props.engineAddLLP(data, assetInfoObj)} engineLLPinfo={engineLLPinfo.data} />
                  </ModalBody>
              </Modal>

              </TabPane>
              <TabPane tabId="3">
                <Row className="workscope-button">
                  {
                    getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.engines && (getLocalStorageInfo().user.permission.maintenance.engines.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                    <Col md="12">
                      <button type="button" className="btn btn-primary btn-sm float-left add-new" onClick={() => {this.props.addWorkScopeModule(); trackActivity('Item Added', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Workscope Major Module' })}}>+ Add Major Module</button>
                      <button type="button" className="btn btn-primary btn-sm float-right add-new" onClick={() => {this.updateWorkScopesToShop(); trackActivity('Item Saved', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Workscope' })}}>Save Changes</button>
                    </Col>
                    :null
                  }
                </Row>
                <div className="asset-list-filter-sort">
                  <Row className="workscope-row filter-block workscope-table-heading">
                    <Col md="2" className="flex-centered border-right">
                      <h6>
                        <span>Major Module</span>
                      </h6>
                    </Col>
                    <Col md="10" className="flex-centered padding-0">
                      <Row className="workscope-sub-row margin-0">
                        <Col md="3" className="submodule-col flex-centered border-right">
                          <h6>
                            <span>Sub Module</span>
                          </h6>
                        </Col>
                        <Col md="9" className="flex-centered padding-right-0">
                          <Row className="workscope-sub-row flex-not-centered margin-0">
                            <Col md="2" className="item-col flex-centered border-right">
                              <h6>
                                <span>Module No</span>
                              </h6>
                            </Col>
                            <Col md="5" className="item-col flex-centered border-right">
                              <h6>
                                <span>Module Description</span>
                              </h6>
                            </Col>
                            <Col md="4" className="item-col workscope-level border-right">
                              <h6>
                                <span>Workscope Level</span>
                              </h6>
                              <Row>
                                <Col md="6" className="item-col border-right">
                                  <h6>
                                    <span>Initial</span>
                                  </h6>
                                </Col>
                                <Col md="6" className="item-col ">
                                  <h6>
                                    <span>Final</span>
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="1" className="item-col">

                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <EngineWorkScope 
                  workScopes={workScopes} 
                  addWorkscopeSubModule={this.props.addWorkscopeSubModule} 
                  addWorkScopeItem={this.props.addWorkScopeItem} 
                  updateWorkScopes={this.props.updateWorkScopes} 
                  delWorkScopeItem={this.props.delWorkScopeItem} 
                />
              </TabPane>
            </TabContent>
          </div>
          </div>
          <EditSidebar
            title={
              engineShopVisit.type != '' ?
              (engineShopVisit.type == 'add') ? 'Add Shop Visit for Engine':
              (engineShopVisit.type == 'edit') ? 'Edit Shop Visit for Engine': 'View Shop Visit for Engine' : ''
              }
            editSideBar={engineShopVisit.flag && engineShopVisit.type == 'edit'}
            toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: engineShopVisit.data, flag: false})}
            >
            {
              Object.keys(engineShopVisit.data).length && engineShopVisit.flag && engineShopVisit.type == 'edit' ?
              <div>
                <AddEditShopVisit
                  type={engineShopVisit.type}
                  shopVisitObj={engineShopVisit.data}
                  eventTypes={eventTypes}
                  addEditShopVisit={(data, type)=>this.props.addEditShopVisit(data, type, assetInfoObj)}
                  assemblyType="engine"
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: engineShopVisit.data, flag: false})}
                />
              </div>:null
            }
          </EditSidebar>
          <EditSidebar
            title={
              engineClaimInfo.type != '' ?
                (engineClaimInfo.type == 'add') ? 'Add Claim':
                (engineClaimInfo.type == 'edit') ? 'Edit Claim': 'View Claim' : ''
              }
              toggleEditSideBar={() => this.props.toggleClaimEditBar({type: '', data: {}, flag: false})}
              editSideBar={engineClaimInfo.flag}
            >
            {
              Object.keys(engineClaimInfo.data).length && engineClaimInfo.flag ?
              <div>
                <AddEditClaims
                  type={engineClaimInfo.type}
                  claimObj={engineClaimInfo.data}
                  addEditClaims={(data, type)=>this.props.addEditClaims(data, type, assetInfoObj)}
                  assemblyType="engine"
                  toggleEditSideBar={() => this.props.toggleClaimEditBar({type: '', data: {}, flag: false})}
                  assetInfoObj={assetInfoObj}
                />
              </div>:null
            }
          </EditSidebar>
          <EditSidebar
            title="Edit Payout Detail"
            toggleEditSideBar={() => this.props.togglePayoutEditBar({type: '', data: {}, flag: false})}
            editSideBar={enginePayoutInfo.flag}
            >
            {
              Object.keys(enginePayoutInfo.data).length && enginePayoutInfo.flag && enginePayoutInfo.type == 'edit' ?
              <AddEditPayout
                type="edit"
                payoutObj={enginePayoutInfo.data}
                addEditClaims={(data, type)=>this.props.addEditClaims(data, type, assetInfoObj)}
                assemblyType="engine"
                toggleEditSideBar={() => this.props.togglePayoutEditBar({type: '', data: {}, flag: false})}
               />:null
            }
          </EditSidebar>
          <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="sv-reject-modal">
            <ModalHeader toggle={this.toggleModal}>Reason For Rejection</ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => this.rejectSv(e)}>
                <FieldGroup className="form-group">
                  <textarea onChange={this.updateSvReson}></textarea>
                  <h6 className="error-msg">{error.shopVisitReason}</h6>
                </FieldGroup>
                <input type="submit" className="btn-primary add-new" value="Save" />
              </form>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.claimModal} toggle={this.toggleClaim} className="sv-reject-modal">
            <ModalHeader toggle={this.toggleClaim}>Reason For Rejection</ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => this.rejectClaim(e)}>
                <FieldGroup className="form-group">
                  <textarea onChange={this.updateSvReson}></textarea>
                  <h6 className="error-msg">{error.shopVisitReason}</h6>
                </FieldGroup>
                <input type="submit" className="btn-primary add-new" value="Save" />
              </form>
            </ModalBody>
          </Modal>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  engineShopVisit: state.EngineAssemblyReducer.engineShopVisit,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  sShopVisitStatus: state.sharedReducers.sShopVisitStatus,
  engineClaims: state.EngineAssemblyReducer.engineClaims,
  engineClaimInfo: state.EngineAssemblyReducer.engineClaimInfo,
  enginePayoutInfo: state.EngineAssemblyReducer.enginePayoutInfo,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  engineLLPList: state.EngineAssemblyReducer.engineLLPList,
  engineLoader: state.EngineAssemblyReducer.engineLoader,
  engineLLPinfo : state.EngineAssemblyReducer.engineLLPinfo,
  workScopes : state.EngineAssemblyReducer.workScopes,
  engineLLpCrud: state.EngineAssemblyReducer.engineLLpCrud,

});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    eventDetails: () => dispatch(engineShopVisitCrudAc(ownProps.params, 'view')),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'engine')),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params)),
    fetchClaims: () => dispatch(engineClaimsListAc(ownProps.params, {shop_visit_id: ownProps.params.id})),
    changeSVStatus: (data) => dispatch(engineSVStatusAc(ownProps.params, data)),
    addEditShopVisit: (data, type, assetInfoObj) => dispatch(addEditEngineShopVisitAc(ownProps.params, data, type, assetInfoObj)),
    claimInfoCrud: (type,id) => dispatch(engineClaimInfoCrudAc(ownProps.params,type,id)),
    addEditClaims: (data, type, assetInfoObj) => dispatch(addEditEngineClaimAc(ownProps.params, data, type, assetInfoObj)),
    changeClaimStatus: (id, data) => dispatch(engineClaimStatusAc(ownProps.params, id, data)),
    fetchLLPList : () =>  dispatch(engineLLPListAc(ownProps.params)),
    fetchModule: (engineId, flag) => dispatch(engineLLPInfoAc(ownProps.params, engineId, flag)),
    engineLlpCrudFn: (llpId, type) => dispatch(engineLlpCrudFnAc(ownProps.params, llpId, type)),
    editLLP : (llpData, type, assetInfoObj) => dispatch(editLLPAc(ownProps.params, llpData, type, assetInfoObj)),
    engineAddLLP: (data, assetInfoObj) => dispatch(engineAddLLPAc(ownProps.params, data, assetInfoObj)),
    delLLp: (llpId) => dispatch(delLLpAc(ownProps.params, llpId)),
    addWorkScopeItem: (index, childIndex) => dispatch({
      type: ADD_WORKSCOPE_ITEM,
      payload:{index, childIndex}
    }),
    addWorkScopeModule: () => dispatch({
      type: ADD_WORKSCOPE_MODULE,
      payload:{}
    }),
    addWorkscopeSubModule: (index) => dispatch({
      type: ADD_WORKSCOPE_SUBMODULE,
      payload: {index}
    }),
    updateWorkScopes:(type,key,value,index,childIndex,itemIndex) => dispatch({
      type: UPDATE_WORK_SCOPE,
      payload: {type,key,value,index,childIndex,itemIndex}
    }),
    delWorkScopeItem: (index, childIndex,itemIndex) => dispatch({
      type: DEL_WORKSCOPE_ITEM,
      payload:{index, childIndex,itemIndex}
    }),
    editAssembly: () => dispatch({
      type: TOGGLE_VIEW_EDIT_ENGINE,
      payload: ''
    }),
    toggleEditBar: (data) => dispatch({
      type: ENGINE_SHOP_VISIT_CRUD,
      payload: data
    }),
    toggleClaimEditBar: (data) => dispatch({
      type: ENGINE_CLAIM_CRUD,
      payload: data
    }),
    togglePayoutEditBar: (data) => dispatch({
      type: ENGINE_PAYOUT_CRUD,
      payload: data
    }),
    changeViewMode: (type, actionType) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      actionType: actionType,
      payload: type
    }),
    closeEditViewSideBar: (type, data) => dispatch({
      type: CLOSE_ENGINE_LLP_SIDEBAR,
      viewType: type,
      payload: data
    }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(EngineView, ['maintenance', 'engines', 'R']));
