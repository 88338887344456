import React, {Component} from 'react';
import { connect } from 'react-redux';
import { globalPutService } from '../../../globalServices';
import { imgStoragePath } from '../../../constants';
import { Link, withRouter, browserHistory } from 'react-router';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';
import { getCookie, getLocalStorageInfo } from '../../../utils'
const pageTitleUrl = {
  'Technical Dashboard': '/fleet/technical/dashboard',
  'Contracts Dashboard': '/fleet/contracts/dashboard',
  'Maintenance Dashboard': '/fleet/maintenance/dashboard',
  'Records Dashboard': '/fleet/records/dashboard'
}
class  DbBackCom extends Component {
  constructor(props){
    super(props);
    this.state = {
      flag: true,
      loader: false
    }
  }
  componentDidMount(){
    if(this.props.type == 'shorter'){
      this.props.fetchAssetView(this.props.params.aircraft_slug);
    }
    this.props.fixedPositionFn('closeMaintenance',this.props.closeMaintenance);
  }
  setDefaultUrl = (data) => {
    this.setState({loader: true});
    globalPutService('console/user/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        let userInfo = getLocalStorageInfo();
        userInfo = {
          ...userInfo,
          user: {
            ...userInfo.user,
            default_dashboard_url: data.default_dashboard_url
          }
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        this.setState(prevState => ({
          ...prevState,
          flag: !prevState.flag,
          loader: false
        }))
      }
    })
  }

  render() {
    const { pageTitle, closeMaintenance, notificationOpen, setDefaultUrl } = this.props
    console.log('window.history.go', window.history)
    return (
      <div className="db-back-arrow" style={ getCookie() || !closeMaintenance ? {} : getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.on_trail ? {} : notificationOpen ? { top: '75px'} : {top: '40px'} }>
        <Link>
        <img
          onClick={() => window.history.go(-1)}
          src={imgStoragePath+"back_arw.png"}
          style={{cursor: 'pointer', marginLeft: '10px'}}
          alt="back" width="16"/>
        </Link>
        <span>{pageTitle}</span>
        {
          <span
            style={{padding: '2px 8px', borderRadius: '3px', marginLeft: '10px', border: '1px solid #2F8BFF', color:'#2F8BFF', cursor: 'pointer'}}
            onClick={() => this.setDefaultUrl({default_dashboard_url: pageTitleUrl[pageTitle], accept_old_dashboard: null})}>
              { this.state.loader ? 'Processing...':'Set as default Home Page' }
          </span>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  closeMaintenance: state.sharedReducers.closeMaintenance,
  notificationOpen: state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = dispatch => {
  return{
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DbBackCom))
