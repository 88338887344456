import React from 'react';
import { Row, Col } from 'reactstrap';
const SecurityTxHd = (props) => {
  return(
    <Row>
      {props.theads.map((label,index) =>
        <Col key={index} md="3" xs="3">
          <h6 className="caption-ui">{label}</h6>
        </Col>
      )}
    </Row>
  )
}

export default SecurityTxHd;
