import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput,FieldSelect, FieldDate, FieldTextarea } from '../../../contracts/Elements';
import {  checkValidDate, checkNumber, checkString } from '../../../../formValidator';
import moment from 'moment';

export default class AddEditClaims extends Component{
  constructor(props){
    super(props);
    this.state = {
      afClaim: {},
      error: {}
    }
  }
  componentDidMount() {
    let data = Object.assign({}, this.props.claimObj);
    this.setState(prevState => ({
      ...prevState,
      afClaim: data
    }));
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      afClaim: {
        ...prevState.afClaim,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditClaims = (e) => {
    e.preventDefault();
    const validateNewInput = {
      submission_date: checkValidDate({value: this.state.afClaim.submission_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Submission Date'}),
      claim_amount: checkNumber({value: this.state.afClaim.claim_amount ? parseFloat((this.state.afClaim.claim_amount.toString()).trim()) : '', required: true, minLength: '', maxLength: '', message: 'Please Enter Claim Amount'}),
      shop_visit_type: checkString({value: (this.props.assemblyType == 'engine' ? this.state.afClaim.shop_visit_type:''), required: (this.props.assemblyType == 'engine' ? true: false), minLength: '', maxLength: '', message: 'Please Select Type'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.toggleEditSideBar();
      this.props.addEditClaims(this.state.afClaim, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { type, assemblyType, loader,assetInfoObj } = this.props;
    const { afClaim, error } = this.state;
    return(
      <form onSubmit={(e) => this.addEditClaims(e)}>
       <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
         <Row>
           {
             assemblyType == 'engine' ?
             <FieldCol md="12">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Type
                   { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                 </FieldLabel>
                 <FieldSelect
                   placeholder='Select Type'
                   keyParam="shop_visit_type"
                   value={afClaim.shop_visit_type}
                   type={type}
                   options={[{label: 'PRSV', shop_visit_type: 'prsv'}, {label: 'LLP', shop_visit_type:'llp'}]}
                   updateField={this.updateForm}
                   labelKey= 'label'
                   valueKey='shop_visit_type'
                 />
               <h6 className="error-msg">{error.shop_visit_type}</h6>
               </FieldGroup>
             </FieldCol>:null
           }
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                 Submission Date
                 { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldDate
                 value={afClaim.submission_date}
                 type={type}
                 keyParam="submission_date"
                 maxDate = {moment(assetInfoObj.contract.contractual_end_date)}
                 updateField={this.updateForm}
                 minDate = {moment(assetInfoObj.contract.contractual_start_date)}

               />
             <h6 className="error-msg">{error.submission_date}</h6>
             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                Claim Amount
                { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
               <FieldInput
                 value={afClaim.claim_amount}
                 type={type}
                 keyParam="claim_amount"
                 updateField={this.updateForm}
               />
              <h6 className="error-msg">{error.claim_amount}</h6>

             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Claim Details</FieldLabel>
               <FieldTextarea
                 value={afClaim.description}
                 type={type}
                 keyParam="description"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Remarks</FieldLabel>
               <FieldTextarea
                 value={afClaim.remarks}
                 type={type}
                 keyParam="remarks"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>
         </Row>
        </div>
       { type != 'view' ?
         <div className="submit-block">
            <input type="submit" className="primary-btn" disabled={loader} value={loader ? 'Processing...': 'Save Changes'}/>
            <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
         </div>:null
       }
     </form>
    )
  }
}
