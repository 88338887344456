import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class BreakUpLLPMRCostModal extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.props.breakUpLLPMRCostFn('','')
  }
  render() {
    const { breakUpLLPMRCost } = this.props;
    return (
      <div className="">
        <Modal isOpen={breakUpLLPMRCost.modalStatus} toggle={this.toggle} className="llp-brekup-modal">
           <div className="modal-header">
             <h5>
               Breakup of LLP MR Costs
             </h5>
            {/* <button type="button" className="close" onClick={this.toggle}><span aria-hidden="true">×</span></button> */}
          </div>
          <ModalBody style={{maxHeight: '440px'}}>
            <table>
              <thead>
                <tr>
                  <th className="caption-ui">LLP Type</th>
                  <th className="caption-ui  text-right">Cost</th>
                </tr>
              </thead>
              <tbody>
                { breakUpLLPMRCost.list.map((llpCost, index) => {
                  return (
                    <tr key={index}>
                      <td className="caption-ui">{llpCost.part_name}</td>
                      <td className="caption-ui text-right">$ {llpCost.MR}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <p>Total LLP MR</p>
            <span>$
              {
                breakUpLLPMRCost.list.reduce( (total, next) => total + next.MR, 0 )
              }
            </span>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
