import React from 'react';
class IsFittedInputT004 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value=='true'? true:false
    })
  }
  componentWillReceiveProps(nextProps){

    if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value=='true'? true:false
      })
    }
  }
  updateForm = (value) => {
    this.setState({
      value: value,
     // error: ''
    });
    this.props.updateForm(value? 'true':'false');


  }

  render(){
    const { type, isDisabled } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
      <div>
        <div className="form-group-edit">
          <label className="label" style={{ marginBottom: '10px'}}>{this.props.children}</label>
          { type == 'view' ? <h6>{this.props.value ? this.props.value:'--'}</h6> :
           <div>
           <label style={{  marginRight: '10px'}}>
              <input
                type="radio"
                placeholder= {this.props.placeholder}
                value={this.state.value}
                onChange={(evt) => this.updateForm(true)}
                onBlur={this.onBlur}
                checked={this.state.value}
                disabled = {isDisabled}
                name= "fitted-status"
              /> Yes
            </label>
            <label>
                <input
                  type="radio"
                  placeholder= {this.props.placeholder}
                  value={this.state.value}
                  onChange={(evt) => this.updateForm(false)}
                  onBlur={this.onBlur}
                  checked={!this.state.value}
                  disabled = {isDisabled}
                  name= "fitted-status"

                /> No
              </label>
          </div>
          }
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default IsFittedInputT004;
