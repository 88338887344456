import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, UncontrolledTooltip  } from 'reactstrap';
import { imgStoragePath } from '../../../../constants'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea,  FieldInput, FieldSelect } from '../../../contracts/Elements';
import { checkString, checkIntNumber, checkNumber, checkFloat } from '../../../../formValidator';
import AddEditMjrAssyfields from './AddEditMjrAssyfields';
import OffWingServiceable from './OffWingServiceable';
import InShopUnServiceable from './InShopUnServiceable';
import InShopServiceble from './InShopServiceble';
import YetToBeSentFields from './YetToBeSentFields';
import OffWingUnserviceble from './OffWingUnserviceble';
import { getLocalStorageInfo } from '../../../../utils';

export default class AddEditPropeller extends Component {
  constructor(props){
    super(props);
    this.state = {
      propeller: {
        serial_number: '',
        model: '',
        manufacturer: '',
        part_number: 0,
        is_fitted: 0,
        is_titled: 0,
        installed_position: 1,
      },
      error:{}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.propellerCrud));
    if(this.props.type == 'edit'){
      delete data['status'];
      delete data['off_wing_status'];
      data = {
        ...data,
        aircraft: this.props.propellerCrud.aircraft.id,
        aircraft_name : this.props.propellerCrud.aircraft.msn,
        status:  this.props.propellerCrud.status && this.props.propellerCrud.status.value ? this.props.propellerCrud.status.value : null,
        installed_position:  this.props.propellerCrud.installed_position.value,
        state: this.props.propellerCrud.state && this.props.propellerCrud.state.value ? this.props.propellerCrud.state.value : null,
        off_wing_status:  this.props.propellerCrud.off_wing_status &&  this.props.propellerCrud.off_wing_status.value ? this.props.propellerCrud.off_wing_status.value : '',
        on_wing_lessor : (this.props.propellerCrud && this.props.propellerCrud.on_wing_lessor) ? this.props.propellerCrud.on_wing_lessor : (this.props.overview.lessor && this.props.overview.lessor.name ) ? this.props.overview.lessor.name : null,
        on_wing_msn : (this.props.propellerCrud && this.props.propellerCrud.on_wing_msn) ? this.props.propellerCrud.on_wing_msn : this.props.overview.msn ,
        owner_id:this.props.propellerCrud.owner && this.props.propellerCrud.id ? this.props.propellerCrud.owner.id: getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : "",
        owner_level: this.props.propellerCrud.owner && this.props.propellerCrud.owner.lessor_level? this.props.propellerCrud.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
      }
    }else{
      data = {
        ...data,
        on_wing_lessor : (this.props.overview.lessor && this.props.overview.lessor.name ) ? this.props.overview.lessor.name : null,
        on_wing_msn :  this.props.overview.msn ,
      }
    }
    this.setState({
      propeller:data
    });
    if(data.on_wing_status == true){
      this.setState({
        on_wing_status: true
      })
    }
    if(data.off_wing_status){
      this.setState({
        off_wing_status: true
      })
    }
  }
  updateForm = (type, value) => {
    this.props.updateForm({
      type: this.props.type,
      data: {
      ...this.props.propellerCrud,
      [type]: value
    }, flag: true}, 'propellerCrud')
    this.setState({
      propeller: {
        ...this.state.propeller,
        [type]: value
      },
      error:{
        ...this.state.error,
        [type]:''
      }
    })
    if( type == 'on_wing_status' && !value) {
      this.setState(prevState => ({
        ...prevState,
        propeller: {
          ...prevState.propeller,
          on_wing_msn: '',
          on_wing_lessor: '',
          off_wing_detail: {}
        }
      }))
    }else if(type == 'on_wing_status' && value) {
      this.setState(prevState => ({
        ...prevState,
        propeller: {
          ...prevState.propeller,
          off_wing_detail: {},
          on_wing_msn: this.state.propeller.on_wing_msn,
          on_wing_lessor: this.state.propeller.on_wing_lessor,

        },
        off_wing_status : false

      }))
    }else if(type == 'off_wing_status'){
      this.setState(prevState => ({
        ...prevState,
        propeller: {
          ...prevState.propeller,
          off_wing_detail: {}
        },
        off_wing_status : false
      }))
    }
    if(value){
      this.setState({
        [type]: true
      })
    }else {
      this.setState({
        [type]: false
      })

    }
  }

  updateDetailData = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      propeller: {
        ...prevState.propeller,
        off_wing_detail: {
          ...prevState.propeller.off_wing_detail,
            [type]: value
          }
      }
    }));
  }


  addEditAvionic = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.propeller);
    data = {
      ...data,
      aircraft: this.props.asset_id
    }
    const validateNewInput = {
      serial_number: checkString({value: data.serial_number, required: true, minLength: '', maxLength: '', message: 'Please enter serial number'}),
      part_number: checkString({value: data.part_number, minLength: '', maxLength: '', message: 'Please enter valid Part Number'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      delete data.created_by
      delete data.updated_by
      setTimeout(() => this.props.addEditAvionic(data, this.props.type, 'propellerList','propellerCrud'),500)
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    var { propeller, error } = this.state;
    const { type, techConstants, showAdvanced, standAlone, ownerList } = this.props;
    if(!Object.keys(propeller).length){
      return <h4 className="text-center">Loading ...</h4>
    }
    if(type == 'add'){
      propeller = this.props.propellerCrud
    }

    return (
      <form onSubmit={(e) => this.addEditAvionic(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Serial Number {standAlone ? 'Stand Alone' : 'No Stand Alone'}
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={propeller.serial_number}
                  type={type}
                  keyParam="serial_number"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.serial_number}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Part Number
                </FieldLabel>
                <FieldInput
                  value={propeller.part_number ? propeller.part_number.toString() : ''}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.part_number}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Model</FieldLabel>
                <FieldInput
                  value={propeller.model}
                  type={type}
                  keyParam="model"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Manufacturer</FieldLabel>
                <FieldInput
                  value={propeller.manufacturer}
                  type={type}
                  keyParam="manufacturer"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
              <label className="label">
                {showAdvanced ?
                  <input
                  disabled={type === 'view'}
                  type="checkbox"
                  checked={this.state.propeller.is_titled}
                  onClick={(e) => {this.updateForm('is_titled', true);setTimeout(() => this.updateForm('is_fitted', false), 300)}}
                />
                  :<input
                  disabled={type === 'view'}
                  type="checkbox"
                  checked={this.state.propeller.is_titled}
                  onChange={(e) => this.updateForm('is_titled', e.target.checked)}
                />
              }
                <span>Is Propeller titled for this MSN?</span>
              </label>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <label className="label">
                  {showAdvanced ?
                    <input
                    disabled={type === 'view'}
                    type="checkbox"
                    checked={this.state.propeller.is_fitted}
                    onClick={(e) => {this.updateForm('is_fitted',true);setTimeout(() => this.updateForm('is_titled', false), 300)}}
                  />
                    :<input
                    disabled={type === 'view'}
                    type="checkbox"
                    checked={this.state.propeller.is_fitted}
                    onChange={(e) => this.updateForm('is_fitted', e.target.checked)}
                  />
                }
                  <span>Is Propeller fitted for this MSN?</span>
                </label>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Installed Position</FieldLabel>
                <FieldSelect
                  placeholder='Select position'
                  keyParam="installed_position"
                  value={type == 'view' ? propeller.installed_position.value ? propeller.installed_position.value : 1 : this.state.propeller.installed_position != null && this.state.propeller.installed_position != undefined ? this.state.propeller.installed_position : 1}
                  type={type}
                  options={techConstants.filter(item => item.type=="engine_position")}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={propeller.installed_position.label ? propeller.installed_position.label : 'LH'}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
          <div className="tech-spec-off-wing-block">
            <Row>
              <Col md="12">
                <div className="form-group"  style={{margin: "20px 0px"}}>
                  <label className="label">
                    {showAdvanced && propeller.is_fitted ?
                      <input
                      type="checkbox"
                      checked={propeller.on_wing_status}
                      />
                      :<input
                      type="checkbox"
                      checked={propeller.on_wing_status}
                      onChange={(e) => this.updateForm('on_wing_status', e.target.checked)}
                      />
                    }
                    <span>
                      Is Propeller On MSN ?
                      <i class="fa fa-info-circle" aria-hidden="true" id="Propeller-field3" style={{paddingLeft: '4px'}}></i>
                      <UncontrolledTooltip placement="right" target="Propeller-field3">
                        Tick if the Titled major assembly is installed on  aircraft
                      </UncontrolledTooltip>
                    </span>
                  </label>
                </div>
              </Col>
              <FieldCol md="12" style={ ! this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Off MSN Status</FieldLabel>
                  <FieldSelect
                    placeholder='Select Off MSN Status'
                    keyParam="off_wing_status"
                    value={propeller.off_wing_status}
                    type={type}
                    options={techConstants.filter(item => item.type=="off_wing_status")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                    on_wing_status = {this.state.on_wing_status}
                  />
                </FieldGroup>
              </FieldCol>
            </Row>

              {  propeller.off_wing_status && this.state.off_wing_status   ?
                        <AddEditMjrAssyfields updateDetailData={this.updateDetailData} mjrAssy = {propeller.off_wing_detail} type={type}/>
                 : null
              }
              {
                  propeller.off_wing_status == 1 ?
                 <YetToBeSentFields assemblyName="Propeller"  updateDetailData={this.updateDetailData} yetToBeSend = {propeller.off_wing_detail} type={type}/> : null
              }
              {
                 propeller.off_wing_status == 2 ?
                 <InShopServiceble assemblyName="Propeller"  techConstants={techConstants} updateDetailData={this.updateDetailData} inShopserv = {propeller.off_wing_detail} type={type}/> : null
              }
              {
                 propeller.off_wing_status == 3 ?
                <InShopUnServiceable assemblyName="Propeller" techConstants={techConstants} techConstants={techConstants}  updateDetailData={this.updateDetailData} inShopUnserv = {propeller.off_wing_detail} type={type}/> : null
              }
              {
                 propeller.off_wing_status == 4 ?
                <OffWingServiceable assemblyName="Propeller" techConstants={techConstants}  updateDetailData={this.updateDetailData} offWingServ = {propeller.off_wing_detail} type={type}/> : null
              }

              {
                propeller.off_wing_status == 5 ?
                <OffWingUnserviceble assemblyName="Propeller" techConstants={techConstants} updateDetailData={this.updateDetailData} offWingUnServ = {propeller.off_wing_detail} type={type}/> : null
              }

              <Row>
                <FieldCol md="6" style={ this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      On MSN
                    </FieldLabel>
                    <FieldInput
                      value={propeller.on_wing_msn}
                      type={type}
                      keyParam="on_wing_msn"
                      updateField={this.updateForm}
                      disable={showAdvanced && propeller.is_fitted}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="6" style={ this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    On MSN Propeller Owner
                    </FieldLabel>
                    <FieldInput
                      value={propeller.on_wing_lessor }
                      type={type}
                      keyParam="on_wing_lessor"
                      updateField={this.updateForm}
                      disable={showAdvanced  && propeller.is_fitted}
                    />
                  </FieldGroup>
                </FieldCol>
            </Row>
            </div>
        </div>
        { this.props.type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
