import React from 'react';
const ReportList = (props) =>{
  return(
    <tr>
      {
        props.items.map((item, index) =>
          <td key={index}>
            { (item.length > 0 && item.includes('https')) ? <a href={item} target='_blank'>Enabled</a> : item}
          </td>
        )
      }
    </tr>
  )
}

export default ReportList;
