import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
export default class FilterList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Row {...this.props}>
        {this.props.children}
      </Row>
    )
  }
}
