import * as actions from '../actions';
export default function( state = {
  returnCondList: [],
  returnCondCrud: {
    flag: false,
    type: '',
    data: {}
  },
  returnCondLoader: true
}, action){
  switch (action.type) {
    case actions.RETURN_COND_LIST:
      return {
        ...state,
        returnCondList: action.payload
      }
    case actions.RETURN_COND_ADD_SUCCESS:
      return {
        ...state,
        returnCondList: [action.payload, ...state.returnCondList]
      }
    case actions.RETURN_COND_EDIT_SUCCESS:
      return {
        ...state,
        returnCondList: state.returnCondList.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.RETURN_COND_CRUD:
      return {
        ...state,
        returnCondCrud:action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        returnCondCrud: {
          ...state.returnCondCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.RETURN_COND_LOADER:
      return {
        ...state,
        returnCondLoader: action.payload
      }
    default:
      return state;
  }
}
