import { ASSET_DIR_TREE } from '../../DataRoom/actions';
import { globalGetService, globalDownloadService, globalDownloadGetService,globalStaticExportService, globalStaticPostService,globalStaticDownloadService, globalStaticGetService } from '../../../../globalServices/';
import * as actions from '../actions';
import { downloadFileType,toastFlashMessage} from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const dirTreeAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: ASSET_DIR_TREE,
      payload: {}
    });
    dispatch({
      type: actions.FILTER_LOADER,
      payload: true
    });
    globalGetService('records/'+params.type+'/'+ params.aircraft_slug+'/data-room/'+params.id+'/', {for_filter: true})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: ASSET_DIR_TREE,
          payload: response.data.data
        });
      }
      dispatch({
        type: actions.FILTER_LOADER,
        payload: false
      });
    });
  }
}
export const getRecordsReportsAc = (params, uuid) => {
  return (dispatch) => {
    dispatch({
      type: actions.REPORT_LOADER,
      payload: true
    });
    dispatch({
      type: actions.REPORTS_INFO,
      payload: {}
    })
    globalStaticPostService('records/'+params.type+'/'+params.aircraft_slug+'/data-room-report/?share_link=true&json=true',{uuid: uuid})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.REPORTS_INFO,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.REPORT_LOADER,
        payload: false
      });
    })
  }
}
export const getRecordsActivityReportsAc = (params, page, filter, download, ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.REPORT_LOADER,
      payload: true
    });
    let queryParams = {};
    if(download == false ){
      dispatch({
        type: actions.REPORTS_INFO,
        payload: {}
      })
      queryParams = {page: page, share: filter};
      globalGetService('records/'+params.type+'/'+params.aircraft_slug+'/activities/',queryParams)
      .then(response => {
        if(response.data.statusCode == 200){
            dispatch({
              type: actions.REPORTS_INFO,
              payload: response.data.data
            })
        }
        dispatch({
          type: actions.REPORT_LOADER,
          payload: false
        });
      })
    }else{
      let exportName
      switch (parseInt(ctrShortInfo.asset_type)) {
        case 1:
          exportName = `MSN_${ctrShortInfo.msn}_Records_Activity_Report`
          break;
        case 2:
          exportName = `ESN_${ctrShortInfo.esn}_Records_Activity_Report`
          break;
        case 3:
          exportName = `APU_${ctrShortInfo.serial_number}_Records_Activity_Report`
          break;
        case 4:
          exportName = `LG_${ctrShortInfo.serial_number}_Records_Activity_Report`
          break;
        case 5:
          exportName = `PROPELLER_${ctrShortInfo.serial_number}_Records_Activity_Report`
          break;
        default:

      }
      queryParams = {page: page, share: filter, download: 'xls'};
      globalDownloadGetService('records/'+params.type+'/'+params.aircraft_slug+'/activities/',queryParams)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', ['fan', 'hpc', 'hpt', 'lpt'].includes(params.type) ? `ESN_${ctrShortInfo.esn}_Records_Activity_Report.xls` : exportName + '.xls');
        document.body.appendChild(link);
        link.click();
        let assetInfo = ctrShortInfo;
        let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        trackActivity('Report Exported',{
          page_title: 'Reports',
          application_module: 'Records',
          asset: asset_serial_number,
          file_name: 'Activity Report',
          file_type: 'XLS',
        })
        dispatch({
          type: actions.REPORT_LOADER,
          payload: false
        });
      })
    }
  }
}
export const getDeletedReportsAc = (uuid, pageNumber, download, msn,type, ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.REPORT_LOADER,
      payload: true
    });
    if(download != null && download != undefined){
      globalStaticExportService('records/' + uuid + '/deleted-files/',{download: download})
      .then(response => {
        downloadFileType(response, ['fan', 'hpc', 'hpt', 'lpt'].includes(type) ?  msn + ' Deleted Files.xls' : type+msn + ' Deleted Files.', 'xls')
        let assetInfo = ctrShortInfo;
        let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        trackActivity('Report Exported',{
          page_title: 'Reports',
          application_module: 'Records',
          asset: asset_serial_number,
          file_name: 'Delete Report',
          file_type: 'XLS',
        })
       /* if(response.status==200 && (response.headers['content-type'] == 'application/ms-excel' || response.headers['content-type'] == 'application/vnd.ms-excel')){

          const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', type+msn + ' Deleted Files.xls');
          document.body.appendChild(link);
          link.click();
        }else{
          toastFlashMessage("Can not download file", "error");
        }*/
        dispatch({
          type: actions.REPORT_LOADER,
          payload: false
        });
      })
    }else{
      dispatch({
        type: actions.REPORTS_INFO,
        payload: {}
      })
      globalStaticGetService('records/' + uuid + '/deleted-files/',{page: pageNumber})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.REPORTS_INFO,
            payload: response.data.data
          })
        }
        dispatch({
          type: actions.REPORT_LOADER,
          payload: false
        });
      })
    }
  }
}
export const downloadRecordsReportsAc = (params, uuid, msn,type, ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.REPORT_LOADER,
      payload: true
    });
    globalStaticDownloadService('records/'+params.type+'/'+params.aircraft_slug+'/data-room-report/?share_link=true&download=true',{uuid: uuid})
    .then(response => {
      if(response.headers['content-type'] != 'application/ms-excel' && response.headers['content-type'] != 'application/ms-excel'){
        toastFlashMessage("Can not download file", "error");
      }
      else{
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', ['fan', 'hpc', 'hpt', 'lpt'].includes(params.type) ? type + ' Inventory Report.xls' : type+msn + ' Inventory Report.xls');
        document.body.appendChild(link);
        link.click();
        // let assetInfo = ctrShortInfo;
        // let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        // trackActivity('Report Exported',{
        //   page_title: 'Reports',
        //   application_module: 'Records',
        //   asset: asset_serial_number,
        //   file_name: 'Activity Report',
        //   file_type: 'XLS',
        // })
        toastFlashMessage("File has been Exported Successfully", "success");
      }
      dispatch({
        type: actions.REPORT_LOADER,
        payload: false
      });
    })
  }
}
