import React, { Component } from 'react';
import {  TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import IssueList from './IssueList';
import { NoRecordFound  } from '../../../../../shared';
import {imgStoragePath} from "../../../../../constants";
export default class ReviewIssueDrawer extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: '1',
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }


  render() {
    const {issueList} = this.props;
    return (
      <div>
      <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
      <div className="edit-view-tech-spec profile-tab">
              <Nav tabs>
                  {['All', 'Unresolved', 'Resolved'].map((label, index) =>
                      <NavItem>
                          <NavLink
                              className={classnames({ active: this.state.activeTab == (index+1).toString() })}
                              onClick={() => { this.toggle((index+1).toString()); }}
                          >
                              {label}
                          </NavLink>
                      </NavItem>
                  )}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="reviewer-issue-drawer">
                    <div className="issues-listing">
                      <ul className="list-unstyled">
                        {issueList.map((item, index) =>
                            <IssueList
                                key={index}
                                index={index}
                                issue={item}
                                issueDetail={() => this.props.toggleDetailBar(item)}
                                changeStatus={this.props.changeStatus}
                                deleteStatus={this.props.deleteStatus}
                                editStatus={this.props.editStatus}
                            />
                        )}
                      </ul>
                      {issueList.length? null:<NoRecordFound/>}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                <div className="reviewer-issue-drawer">
                    <div className="issues-listing">
                      <ul className="list-unstyled">
                        { issueList.filter(issue => issue.status == 0).map((item, index) =>
                            <IssueList
                                key={index}
                                index={index}
                                issue={item}
                                issueDetail={() => this.props.toggleDetailBar(item)}
                                changeStatus={() => this.props.changeStatus}
                                deleteStatus={this.props.deleteStatus}
                                editStatus={this.props.editStatus}
                            />
                        )}
                      </ul>
                      {issueList.filter(issue => issue.status == 0).length? null:<NoRecordFound/>}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                <div className="reviewer-issue-drawer">
                    <div className="issues-listing">
                      <ul className="list-unstyled resolved-list">
                        {issueList.filter(issue => issue.status == 1).map((item, index) =>
                            <IssueList
                                key={index}
                                index={index}
                                issue={item}
                                issueDetail={() => this.props.toggleDetailBar(item)}
                            />
                        )}
                      </ul>
                      {issueList.filter(issue => issue.status == 1).length? null:<NoRecordFound/>}
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
     </div>
    </div>
    )
  }
}
