import React, { Component } from 'react';
import { withRouter } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import GrpAmountField from '../containers/GrpAmountField';
import { displayDateFormat, prettyNumberParamsL, imgStoragePath } from '../../../../constants';
class EngineGraphTable extends Component {
  constructor(props){
    super(props);
  }
  eventSameValue = (value,interval) => {
    return(
      <h6>
        <span>{value}</span>
        {
          (interval!='' && interval!=undefined && interval != null)?
          <span className="interval-unit">
            &nbsp;{"/"+interval}
          </span>:null
        }
      </h6>
    )
  }
  render() {
    const { shopVisit, newshopVisit, label, simulationType, metrixType, programType, urlLoad } = this.props;
    return(
      <tr>
        <td className="left-blk">
          <span className="workscope-type" style={{paddingLeft: '5px', marginBottom: '3px', display: 'inline-block', textDecoration: 'underline'}}>{shopVisit[Object.keys(shopVisit)[0]].workscopes}</span>
          <div>
            <img className="shop-visit-icon" width="27" src={imgStoragePath+"shop_visit_icn.png"} alt="img" />
            <ul className="list-unstyled">
              { Object.keys(shopVisit).map((sv,index) => {
                return(
                  <li key={index}>
                    {shopVisit[sv].name}
                  </li>
                )
              })}
            </ul>
          </div>
        </td>

        <td>
          <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
          <div>
            <ul className="list-unstyled">
              { Object.keys(shopVisit).map((sv,index) => {
                return(
                  <li key={index}>
                    {
                      simulationType == 2 && newshopVisit[sv] ?
                      (newshopVisit[sv].eventDate != shopVisit[sv].eventDate) ?
                      <div className="override-value">
                        <h6 className="changed-value">
                          <span>
                            {moment(newshopVisit[sv].eventDate).format(displayDateFormat)}
                          </span>
                        </h6>
                        <p className="old-value">
                          <span>
                            {moment(shopVisit[sv].eventDate).format(displayDateFormat)}
                          </span>
                        </p>
                      </div>:this.eventSameValue(moment(shopVisit[sv].eventDate).format(displayDateFormat))
                      :
                      this.eventSameValue(moment(shopVisit[sv].eventDate).format(displayDateFormat))
                    }
                  </li>
                )
              })}
            </ul>
          </div>
        </td>
        <td>
          <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
          <div>
            <ul className="list-unstyled">
              { Object.keys(shopVisit).map((sv,index) => {
                return(
                  <li key={index}>
                    {
                      simulationType == 2 && newshopVisit[sv] ?
                      (newshopVisit[sv].mr != shopVisit[sv].mr) ?
                      <div className="override-value">
                        <h6 className="changed-value"><NumericLabel params={prettyNumberParamsL}>{newshopVisit[sv].mr}</NumericLabel> <span className="interval-unit">/{shopVisit[sv].mr_interval}</span> </h6>
                        <p className="old-value"><NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].mr}</NumericLabel> <span className="interval-unit">/{shopVisit[sv].mr_interval}</span></p>
                      </div>:
                        this.eventSameValue(<NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].mr}</NumericLabel>,shopVisit[sv].mr_interval)
                      :
                      this.eventSameValue(<NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].mr}</NumericLabel>,shopVisit[sv].mr_interval)
                    }
                  </li>
                )
              })}
            </ul>
          </div>
        </td>
        <td>
          <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
          <div>
            <ul className="list-unstyled">
              { Object.keys(shopVisit).map((sv,index) => {
                return(
                  <li key={index}>
                    {
                      this.props.location.pathname.includes('contract/mr-accrual') ?
                      <div>
                        {simulationType == 2 && newshopVisit[sv] ?
                          (newshopVisit[sv].eventCost != shopVisit[sv].eventCost) ?
                            <div className="override-value">
                              <h6 className="changed-value">
                                <NumericLabel params={prettyNumberParamsL}>{newshopVisit[sv].eventCost}</NumericLabel>
                              </h6>
                              <p className="old-value">
                                <NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].eventCost}</NumericLabel>
                              </p>
                            </div>
                            :
                            this.eventSameValue(<NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].eventCost}</NumericLabel>)
                            :
                            this.eventSameValue(<NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].eventCost}</NumericLabel>)
                          }
                      </div>:
                      <GrpAmountField
                        metrixType={metrixType}
                        keyParam="eventCost"
                        eventIndex={label}
                        shopVisit={sv}
                        programType={programType}
                        newvalue={simulationType == 2 ? newshopVisit[sv].eventCost:'' }
                        value={shopVisit[sv].eventCost}
                      />
                    }

                  </li>
                )
              })}
            </ul>
          </div>
        </td>
        <td>
          <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
          <div>
            <ul className="list-unstyled">
              { Object.keys(shopVisit).map((sv,index) => {
                return(
                  <li key={index}>
                    {
                      simulationType == 2 && newshopVisit[sv] ?
                      (newshopVisit[sv].fundCollected != shopVisit[sv].fundCollected) ?
                      <div className="override-value">
                        <h6 className="changed-value">
                          <NumericLabel params={prettyNumberParamsL}>{newshopVisit[sv].fundCollected}</NumericLabel>
                        </h6>
                        <p className="old-value">
                          <NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].fundCollected}</NumericLabel>
                        </p>
                      </div>:
                      this.eventSameValue(<NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].fundCollected}</NumericLabel>)
                      :
                      this.eventSameValue(<NumericLabel params={prettyNumberParamsL}>{shopVisit[sv].fundCollected}</NumericLabel>)
                    }
                  </li>
                )
              })}
            </ul>
          </div>
        </td>
        <td>
          <span className="workscope-type" style={{paddingLeft: '10px'}}></span>
          <div>
            <ul className="list-unstyled">
              { Object.keys(shopVisit).map((sv,index) => {
                return(
                  <li key={index}>
                    {
                      simulationType == 2 &&  newshopVisit[sv] ?
                      ((newshopVisit[sv].fundCollected - newshopVisit[sv].eventCost) != (shopVisit[sv].fundCollected - shopVisit[sv].eventCost)) ?
                      <div className="override-value">
                        <h6 className="changed-value">
                          <NumericLabel params={prettyNumberParamsL}>
                            {newshopVisit[sv].fundCollected - newshopVisit[sv].eventCost}
                          </NumericLabel>
                          <img className="profile-loss-icon" src={ imgStoragePath + ((newshopVisit[sv].fundCollected - newshopVisit[sv].eventCost) >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
                          {newshopVisit[sv].carry_forward_status ?
                            <img className="carried-over-fund" src={imgStoragePath+"Carryover.png"} alt="" />:null
                          }
                        </h6>
                        <p className="old-value">
                          <NumericLabel params={prettyNumberParamsL}>
                            {shopVisit[sv].fundCollected - shopVisit[sv].eventCost}
                          </NumericLabel>
                          <img className="profile-loss-icon" src={ imgStoragePath + ((shopVisit[sv].fundCollected - shopVisit[sv].eventCost) >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
                          {
                            shopVisit[sv].carry_forward_status ?
                            <img className="carried-over-fund" src={imgStoragePath+"Carryover.png"} alt="" />:null
                          }

                        </p>
                      </div>
                      :
                      <h6>
                        <NumericLabel params={prettyNumberParamsL}>
                          {shopVisit[sv].fundCollected - shopVisit[sv].eventCost}
                        </NumericLabel>
                        <img className="profile-loss-icon" src={ imgStoragePath + ((shopVisit[sv].fundCollected - shopVisit[sv].eventCost) >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
                        {
                          shopVisit[sv].carry_forward_status ?
                          <img className="carried-over-fund" src={imgStoragePath+"Carryover.png"} alt="" />:null
                        }

                      </h6>
                      :
                      <h6>
                        <NumericLabel params={prettyNumberParamsL}>
                          {shopVisit[sv].fundCollected - shopVisit[sv].eventCost}
                        </NumericLabel>
                        <img className="profile-loss-icon" src={ imgStoragePath + ((shopVisit[sv].fundCollected - shopVisit[sv].eventCost) >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
                        {
                          shopVisit[sv].carry_forward_status ?
                          <img className="carried-over-fund" src={imgStoragePath+"Carryover.png"} alt="" />:null
                        }
                      </h6>
                    }
                  </li>
                )
              })}
            </ul>
          </div>
        </td>
      </tr>
    )
  }
}

export default withRouter(EngineGraphTable);
