import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';

export default class LastShopVisit extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { shopVisit, type, error, svErr } = this.props;
  return(
      <div style={{ position:'relative'}}>
      {
        type == 'edit' ?
        <img width="12"
          onClick = {this.props.editViewBlk}
        className="edit-img-blk" src={imgStoragePath+"view_icon_blue.png"}
        /> :
        <img width="12"
          onClick = {this.props.editViewBlk}
        className="edit-img-blk" src={imgStoragePath+"icon_editblock.png"}
        />
      }
      <Row>
				<Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Shop Visit Number{ type == 'edit' ? <sup className="reqStar"> * </sup> : null}</label>,
              { type: type,
               value: shopVisit.shop_visit_number,
               error:svErr.shop_visit_number,
               updateForm: (value) => this.props.updateForm({ type: 'shop_visit_number', value: value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Date of Removal</label>,
              { type: type,
              value: shopVisit.date_of_removal,
              error:svErr.date_of_removal,
              updateForm: (value) => this.props.updateForm({ type:'date_of_removal', value:value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Reason of Removal </label>,
              { type: type,
              value: shopVisit.reason_for_removal,
              error:'',
               updateForm: (value) => this.props.updateForm({ type: 'reason_for_removal', value: value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Shop Facility</label>,
              { type: type,
              value: shopVisit.shop_facility,
              error: '',
              updateForm: (value) => this.props.updateForm({ type:'shop_facility', value:value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>


         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSN</label>,
              { type: type,
               value: shopVisit.tsn,
               error:svErr.tsn,
               updateForm: (value) => this.props.updateForm({ type: 'tsn', value: value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSN</label>,
              { type: type,
              value: shopVisit.csn,
              error:svErr.csn,
              updateForm: (value) => this.props.updateForm({ type:'csn', value:value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSLSV</label>,
              { type: type,
              value: shopVisit.tslsv,
              error:svErr.tslsv,
               updateForm: (value) => this.props.updateForm({ type: 'tslsv', value: value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSLSV</label>,
              { type: type,
              value: shopVisit.cslsv,
              error:svErr.cslsv,
              updateForm: (value) => this.props.updateForm({ type:'cslsv', value:value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSR</label>,
              { type: type,
              value: shopVisit.tsr,
              error:svErr.tsr,
              updateForm: (value) => this.props.updateForm({ type:'tsr', value:value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSR</label>,
              { type: type,
              value: shopVisit.csr,
              error:svErr.csr,
               updateForm: (value) => this.props.updateForm({ type: 'csr', value: value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSO</label>,
              { type: type,
              value: shopVisit.tso,
              error:svErr.tso,
              updateForm: (value) => this.props.updateForm({ type:'tso', value:value, sectionType: 'shop_visit'})
              }
            )
          }
         </Col>

         <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSO </label>,
                    { type: type,
                      value: shopVisit.cso,
                      error:svErr.cso,
                      updateForm: (value) =>  this.props.updateForm({ type:'cso', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TSHSI </label>,
                    { type: type,
                      value: shopVisit.tshi,
                      error:svErr.tshi,
                      updateForm: (value) =>  this.props.updateForm({ type:'tshi', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">CSHSI </label>,
                    { type: type,
                      value: shopVisit.cshi,
                      error:svErr.cshi,
                      updateForm: (value) =>  this.props.updateForm({ type:'cshi', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Engine Induction Date </label>,
                    { type: type,
                      value:shopVisit.induction_date,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'induction_date', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Engine Workscope </label>,
                    { type: type,
                      value:shopVisit.final_workscope,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'final_workscope', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Engine Build Target </label>,
                    { type: type,
                      value:shopVisit.build_target,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'build_target', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Test Run EGT Margin </label>,
                    { type: type,
                      value:shopVisit.test_run_egt_margin,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'test_run_egt_margin', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

							<Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">LLP Status </label>,
                    { type: type,
                      value:shopVisit.llp_status,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'llp_status', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">ARC Date ( EASA/FAA) </label>,
                    { type: type,
                      value:shopVisit.arc_date,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'arc_date', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">TAT </label>,
                    { type: type,
                      value:shopVisit.total_air_temperature,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'total_air_temperature', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>

              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Date of Reinstallation </label>,
                    { type: type,
                      value:shopVisit.date_of_reinstallation,
                      error: '',
                      updateForm: (value) =>  this.props.updateForm({ type:'date_of_reinstallation', value:value, sectionType: 'shop_visit'})
                    }
                  )
                }
              </Col>
          </Row>
      </div>
    );
  }
}
