import React, {Component} from 'react';
import { Row, Col} from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import FilterDropdown from './FilterDropdown';
import AssetFilterField from '../containers/AssetFilterField';
import AssetAppliedFilter from '../containers/AssetAppliedFilter';
import { creditClassification, imgStoragePath } from '../../constants';
import { getLocalStorageInfo } from '../../utils';
import { Link } from 'react-router';
export default class AssetsFilter extends Component{
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: true,
      dropDownOpen : false,
      filterSelected : false,
      selectedFilterTitle: ''
    };

    this.displayFilter = {
      'display':'block'
    };
    this.hideFilter = {
      'display':'none'
    };
    this.displaySelectedBlock = {
      'display':'block'
    };
    this.hideSlelectedBlock = {
      'display':'none'
    };
    this.changeFilter = this.changeFilter.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.updateSlectedItem = this.updateSlectedItem.bind(this);
  }
  handleDownClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }else{
    }

  }
  componentWillMount() {
    document.addEventListener('mousedown', this.handleDownClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDownClick, false);
  }

  changeFilter(selectionOption, type, valueKey) {
    this.props.updateFilter(selectionOption? selectionOption[valueKey]: '', type);
  }

  toggleFilter(){
    this.setState(prevState => ({
      ...prevState,
      dropDownOpen : !prevState.dropDownOpen
    }));
  }
  updateSlectedItem(value){
    if(value){
      this.setState(prevState => ({
        ...prevState,
        selectedFilterTitle: value,
        filterSelected : !prevState.filterSelected,
        dropDownOpen : !prevState.dropDownOpen
      }));
    }else{
      this.setState(prevState => ({
        ...prevState,
        filterSelected : !prevState.filterSelected
      }));
    }
  }
  onChange = date => this.setState({ date })
  render(){
    const { clients, operators, engineTypes, aircraftTypes, filterData, lessorList, moduleTypes } = this.props;
    
    var filterMenu = {
      'Application' : {
        'inputType': 'dropdown',
        'keyParam': 'application',
        'placeholder': 'Select Application',
        'labelKey': 'name',
        'valueKey': 'id',
        'options':  [{id: 3, name: 'Contracts'}, {id: 6, name: 'Records'}, {id: 2, name: 'Maintenance'}]
      },
      'Lessee': {
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'listType': 'lessee',
        'placeholder': 'Select Lessee',
        'labelKey': 'name',
        'valueKey': 'slug',
        'options': clients
      },
      'Lessor': {
        'inputType': 'text',
        'keyParam': 'lessor_name'
      },
      'Ownership': {
        'inputType': 'dropdown',
        'keyParam': 'ownership_type',
        'placeholder': 'Select Ownership Type',
        'labelKey': 'label',
        'valueKey': 'value',
        'options': [{label: 'Owned', value: '1'}, {label: 'Managed', value: '2'}]
      },
      'Portfolio': {
        'inputType': 'text',
        'keyParam': 'portfolio'
      },
      'Registration Number': {
        'inputType': 'text',
        'keyParam': 'registration'
      },
      'Serial Number': {
        'inputType': 'text',
        'keyParam': 'serial_number'
      },
      'Status': {
        'inputType': 'dropdown',
        'keyParam': 'status',
        'placeholder': 'Select Status',
        'labelKey': 'label',
        'valueKey': 'value',
        'options': getLocalStorageInfo().defaultLessor.id === 348 ? [{label: 'Operational', value: '1'}, {label: 'AOG', value: '3'}] : [{label: 'On Lease', value: '1'}, {label: 'Off Lease', value: '2'}, {label: 'On Ground', value: '3'}, {label: 'Written Off / Sold', value: '4'}]
      },
      'Owner': {
        'inputType': 'text',
        'keyParam': 'owner'
      },
    };
    if(this.props.activeTab == 'all' || this.props.activeTab == '2'){
      filterMenu = {
        'Engine Type': {
          'inputType': 'dropdown',
          'keyParam': 'engine_type',
          'placeholder': 'Select Engine Type',
          'options': engineTypes,
          'labelKey': 'name',
          'valueKey': 'id',
        },
        ...filterMenu
      }
    }
    if(this.props.activeTab == 'all' || this.props.activeTab == '1'){
      filterMenu = {
        'Aircraft Type': {
          'inputType': 'dropdown',
          'keyParam': 'aircraft_type',
          'placeholder': 'Select Aircraft Type',
          'options': aircraftTypes,
          'labelKey': 'name',
          'valueKey': 'id',
        },
        ...filterMenu
      }
    }
    return(
      <div className="asset-list-filter-sort">
        <Row className="add-filter-type">
          <Col md="12">
            <div className="add-filter">
              <p onClick = { this.toggleFilter} style={{fontSize:"12px", padding: "4px 17px", border:"1px dashed"}}>
                <span>
                  { this.state.dropDownOpen ? <img className="close-block" src={imgStoragePath+"black_close.png"} alt="close"/> : <img className="plus-icon" src={imgStoragePath+"black_plus.png"} alt="img" width="10"/> }
                  {this.state.dropDownOpen ?  ' Close Filter': ' Add a Filter'}
                </span>
              </p>
              {filterData && Object.keys(filterData).length ? <AssetAppliedFilter />:null}
              <ul ref={node => this.node = node} className="list-unstyled filter-list" style={this.state.dropDownOpen ? this.displayFilter : this.hideFilter}>
                {filterMenu && Object.keys(filterMenu).sort().map((menu, index) =>
                  <li onClick={() => {this.updateSlectedItem(menu)}} key={index} className="para-ui th-highlight">{menu}</li>
                )}
              </ul>
              <div className="match-block" style={this.state.filterSelected ? this.displaySelectedBlock : this.hideSlelectedBlock}>
                <div className="match-header">
                  <h4>
                    {this.state.selectedFilterTitle}
                    <span onClick={() => {this.updateSlectedItem('')}}><img width="13" src={imgStoragePath+"white_close.png"}  alt="img"/></span>
                  </h4>
                </div>
                {this.state.selectedFilterTitle?<AssetFilterField updateSlectedItem={this.updateSlectedItem} filterData={filterData} fieldObj={filterMenu[this.state.selectedFilterTitle]} />:null}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="filter-block">
          <Col xs="2" md="2" >
            <h6>
              <span onClick={() => this.props.updateAssetSort('asset_name')}>
                Asset
                <sup className="asc-sort" style={(filterData.sort == 'asset_name' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'asset_name' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="1" md="1">
            <h6>
              <span onClick={() => this.props.updateAssetSort('lessor_name')}>
                Lessor
                <sup className="asc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="1" md="1">
            <h6>
              <span onClick={() => this.props.updateAssetSort('owner')}>
                Owner
                <sup className="asc-sort" style={(filterData.sort == 'owner' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'owner' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="1" md="1">
            <h6>
              <span onClick={() => this.props.updateAssetSort('lessee')}>
                Lessee
                <sup className="asc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="1" md="2">
            <h6>
              <span onClick={() => this.props.updateAssetSort('registration')}>
                Registration No.
                <sup className="asc-sort" style={(filterData.sort == 'registration' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'registration' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col>
          {/* <Col xs="1" md="1">
            <h6>
              <span style={{marginLeft:'-2px'}} onClick={() => this.props.updateAssetSort('country')}>
                Country
                <sup className="asc-sort" style={(filterData.sort == 'country' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'country' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col> */}
          <Col xs="2" md="2">
            <h6>
              <span onClick={() => this.props.updateAssetSort('records_size')}>
                Total Records Size
                <sup className="asc-sort" style={(filterData.sort == 'records_size' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'records_size' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="1" md="1">
            <h6>
              <span onClick={() => this.props.updateAssetSort('status')}>
                Status
                <sup className="asc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="2" md="2"></Col>
        </Row>
      </div>
    );
  }
}
