import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { RecordsSideBar } from '../../Elements';
import BinList from '../components';
import {actionRecycleBinAc, getRecycleBinAc } from '../actionCreators';
import { RECYCLE_ALL, ADD_RECYCLE_LIST } from '../actions';
import '../../assets/styles/records_a.scss';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import Pagination from "react-js-pagination";
import { getLocalStorageInfo } from '../../../../utils';
import { assetsType, imgStoragePath } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';

class RecycleBin extends Component{
  constructor(props) {
    super(props);
    this.state ={
      recordAction: 'delete',
      delModalFlag: false,
      previewCard:{},
      pageNumber: 1,
      binAction: 'Delete',
      delBinFlag: false,
      resBinFlag: false,
      bulkDeleteObj: {},
      bulkDeleteArray: []
    };
    this.deleteRecord = this.deleteRecord.bind(this);
    this.binAction = this.binAction.bind(this);
  }
  binAction(actionType){
    if(actionType == "1"){
      this.setState({
        binAction: 'Delete',
        delBinFlag: !this.state.delBinFlag,
      });
    }else if(actionType == "2") {
      this.setState({
        binAction: 'Restore',
        resBinFlag: !this.state.resBinFlag,
      });
    }
  }
  handlePageChange = (pageNumber) =>{
    this.setState({
      pageNumber: pageNumber
    });
    this.props.getRecycleBin(pageNumber);
  }
  deleteRecord(item, action){
    this.setState({
      delModalFlag: !this.state.delModalFlag,
      previewCard: item,
      recordAction: action,
    })
  }

  makeRecycleList(uuid,actionType,type){

    let newListAdded = [];
    let obj = {};
    obj['uuid'] = uuid;
    obj['type'] = type;

     newListAdded.push(obj);
     this.props.restoreRecycleItem(actionType,newListAdded);

  }



  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.getRecycleBin(1);
    this.props.fetchTechConstant({constant_types: ['file_extension']})
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/recycle-bin/';
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }

  render(){
    const { ctrShortInfo, techConstants, binList, recycleList, binPagination, recycleBinLoader, notificationOpen } = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    return(
     <div className="records-list-wrap record-recycle-bin" style={{marginTop: '94px', padding: '0 15px', marginLeft: '270px'}}>
       <RecordsSideBar />
       { Object.keys(ctrShortInfo).length ?
         <ShortHead
           headingText="Records"
           changeMsn={this.changeMsn}
           subHeaderTopMargin={notificationOpen == true ? 74 : 40}
           contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
       }
       <Row>
         {
           (binList && binList.length > 0 && this.props.recycleList.length > 0) ?
             <Col md="12" className="status-block text-right bucket-action">
               {getLocalStorageInfo().user.permission['records']['recycle_bin']&&
                 getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('RES') != -1 ?
                 <span style={{color: '#007bff'}} onClick={() => this.binAction("2")}>Restore</span>
                 :null
               }
               {getLocalStorageInfo().user.permission['records']['recycle_bin']&&
                 getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('REJ') != -1 ?
                 <span style={{color: '#ff5e5e'}} onClick={() => this.binAction("1")}>Delete({recycleList.length})</span>
                 :null
               }
             </Col>:null
         }
       </Row>
       <div class="recycle-listing-wrapper">
       <div className="asset-list-filter-sort">
         <Row className="filter-block">
           <Col md="6">
             <h6>
               { getLocalStorageInfo().user.permission['records']['recycle_bin']&&
                 getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('REJ') != -1 && getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('RES') != -1 ?
                 <Input type="checkbox" checked={(recycleList.length == binList.length && recycleList.length > 0) ? 'checked' : ''} onClick={() => this.props.recycleAll()}/>
                 :null
               }
             Name</h6>
           </Col>
           <Col md="1">
             <h6>Size</h6>
           </Col>
           <Col md="2">
             <h6>Deleted By</h6>
           </Col>
           <Col md="2">
             <h6>Deleted At</h6>
           </Col>
           <Col md="1"></Col>
         </Row>
       </div>
       <div className="list-wrapper" style={{margin: '0 !important'}} id="dataBucket">
         {
           recycleBinLoader ?
           <ListLoader/> :
           (binList && binList.length > 0 && !recycleBinLoader)?
             binList.map((item, index) =>
               <BinList
                 key={item.uuid}
                 recycleBin= {true}
                 dataKit={false}
                 index={index}
                 listItem={item}
                 deleteRecord={() => this.deleteRecord(item, 'Delete')}
                 restoreRecord={() => this.deleteRecord(item, 'Restore')}
                 addToRecycle={() => this.props.addToRecycle(item.uuid,item.type)}
                 recycleList={recycleList}
                 fileIcons={fileIcons}
               />
             ):<NoRecordFound/>
         }

       </div>
       </div>
       {
           (Object.keys(binPagination).length && binList.length > 0) ?
             <Pagination
              activePage={binPagination.current_page ? parseInt(binPagination.current_page):1}
              itemsCountPerPage={20}
              totalItemsCount={binPagination.total}
              pageRangeDisplayed={2}
              onChange={this.handlePageChange}
            />
           :null
         }
       <Modal isOpen={this.state.delModalFlag} centered={true} toggle={this.deleteRecord} className={this.props.className}>
         <ModalHeader toggle={this.deleteRecord}>{this.state.recordAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to {this.state.recordAction} {this.state.previewCard.name}?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                 {
                   this.state.recordAction == "Delete" ?
                     <button className="btn btn-danger" onClick={() => {this.makeRecycleList(this.state.previewCard.uuid, "1",this.state.previewCard.type);this.deleteRecord(this.state.previewCard, this.state.recordAction)}}>YES</button>
                   :<button className="btn btn-danger" onClick={() => {this.makeRecycleList(this.state.previewCard.uuid, "2",this.state.previewCard.type);this.deleteRecord(this.state.previewCard, this.state.recordAction)}}>YES</button>
                 }
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.deleteRecord(this.state.previewCard.uuid, this.state.recordAction)}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
       <Modal isOpen={this.state.delBinFlag} centered={true} toggle={()=>this.binAction("1")} className={this.props.className}>
         <ModalHeader toggle={this.delBinFlag}>{this.state.binAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to {this.state.binAction} selected items?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.deleteRecycleBin(this.props.recycleList);this.binAction("1")}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.binAction("1")}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
       <Modal isOpen={this.state.resBinFlag} centered={true} toggle={()=>this.binAction("2")} className={this.props.className}>
         <ModalHeader toggle={this.resBinFlag}>{this.state.binAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to {this.state.binAction} selected items?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.restoreRecycleBin(this.props.recycleList);this.binAction("2")}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.binAction("2")}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  binList: state.RecycleBinReducer.binList,
  recycleList: state.RecycleBinReducer.recycleList,
  recycleBinLoader: state.RecycleBinReducer.recycleBinLoader,
  binPagination: state.RecycleBinReducer.binPagination,
  techConstants: state.sharedReducers.techConstants,
  notificationOpen:state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Recycle Bin')),
    recycleAll: () => dispatch({
      type: RECYCLE_ALL,
      payload: ''
    }),
    addToRecycle: (uuid,type) => dispatch({
      type: ADD_RECYCLE_LIST,
      payload: {'uuid':uuid,'type':type}
    }),
    getRecycleBin: (pageNumber) => dispatch(getRecycleBinAc(ownProps.params, pageNumber)),
    restoreRecycleItem: (type, newListAdded) => dispatch(actionRecycleBinAc(ownProps.params, type, newListAdded)),
    restoreRecycleBin: (recycleList) => dispatch(actionRecycleBinAc(ownProps.params,"2" ,recycleList)),
    deleteRecycleBin: (recycleList) => dispatch(actionRecycleBinAc(ownProps.params, "1" ,recycleList)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(RecycleBin,['records','recycle_bin','R'])))
