
export const COMPONENT_INSPECT_BAR = 'COMPONENT_INSPECT_BAR';
export const GET_BOROSCOPE_INSP_DTLS = 'GET_BOROSCOPE_INSP_DTLS';
export const UPDATE_FORM = 'UPDATE_FORM';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const ADD_DYNAMIC_COMPONENT  = 'ADD_DYNAMIC_COMPONENT';
export const BOROSCOPE_DETLS_LOADER = 'BOROSCOPE_DETLS_LOADER';
export const EDIT_INSPECTION_IMAGE = 'EDIT_INSPECTION_IMAGE';
export const DELETE_BORSCP_IMAGE = 'DELETE_BORSCP_IMAGE';
export const SORT_PICTURES = 'SORT_PICTURES';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const CLEAR_ERR_MESSGAE = 'CLEAR_ERR_MESSGAE';
