import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';

export default class ListingHeading extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Row className="logHeading contract-heading">
        <Col md="4" xs="4">
          <h6 className="caption-ui" >
            Lease Type
          </h6>
        </Col>
        <Col md="2" xs="2">
          <h6 className="caption-ui " >
            Lessee
          </h6>
        </Col>
        <Col md="4" xs="4">
          <h6 className="caption-ui " >
            Lease Duration
          </h6>
        </Col>
        <Col md="2" xs="2">
          <h6 className="caption-ui " >
            Status
          </h6>
        </Col>
      </Row>
     )
    }
  }
