import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { ContentDrop, ImageCard, SortableImageCard, ImageListRow } from'../../../Elements';
import { SortableContainer } from 'react-sortable-hoc';


 class InspectionPictures extends Component {
  constructor(props) {
		super(props);
		this.state = {
			dragOver: false
		}
	}

	hideOverlay = () => {

	}

	onDragOver = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
		  dragOver:true,
		});
	  }

	  onFocusOut = () =>{
		this.setState({
		  dragOver: false,
		})
	  }

	  onDrop = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
		this.setState({
		  dragOver: false,
		})
	  }


  render() {
    const { classCustom } = this.props;
    const orderFindings = this.props.disAssemblyCrud.data.finding_pictures.sort(function(a,b){return a.order-b.order});
  return(
    <div className={'inspection-pictures '+this.props.classCustom} onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
				<Row>
            <Col md="12" className="image-row">
              <ul className="list-inline image-list-block flex-not-centered"
              >
                {
                 this.props.disAssemblyCrud.data && this.props.disAssemblyCrud.data.finding_pictures && this.props.disAssemblyCrud.data.finding_pictures.length ?
                 orderFindings.map((item, index) =>
                 <SortableImageCard
                  key={index}
				          index = {index}
                  picture={item}
                  type={this.props.disAssemblyCrud.type}
                  editSideBar={true}
                  toggleDelModal={() => this.props.toggleDelModal('Are you sure to delete?', item.id, index)}
                  editImage={() => this.props.editImageFn({flag: true, data: item, index: index})}
                  openSlider = {() => this.props.openSlider(this.props.disAssemblyCrud.data.finding_pictures, index)}
                  //// changePicture= {(files) => this.uploadLopaImage(files)}
                  options = { this.props.disAssemblyCrud.type != 'view'?[ 'E', 'R', 'D', 'V']:['D', 'V']}
                  updateForm = {(description, value) => this.props.updateForm({type: 'description', value:value, sectionType: 'findingPicture' , index: index})}
                  // hideOverLay = {this.hideOverlay}
                  classCustom = {this.props.classCustom}
								/>
                ) : null }
                {this.props.disAssemblyCrud.type != 'view'?
                  <li className="list-inline-item dropable-zone"
                  onDrop={(evt) => { evt.preventDefault(); evt.stopPropagation(); this.props.uploadDissAssembFiles(evt.dataTransfer.files, 'dissasembly')}}
                    style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)', verticalAlign: 'top'} : { background: 'transparent', verticalAlign: 'top'}} >
                    <ContentDrop
                    mutlipleEnabled={true} acceptExtensions={'image/*'}
                    mutliple={true}
                    filesUpload={(e) => this.props.uploadDissAssembFiles(e.target.files, 'dissasembly')}
                    />
                    <span className="img-info-msg">
                      Recommended Picture dimension 400 X 400 Pixels
                    </span>
                  </li> :null
                }
              </ul>
            </Col>
          </Row>
    </div>
	)}
}

export default SortableContainer(InspectionPictures);
