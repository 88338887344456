import React, { Component } from 'react';
import { Col } from 'reactstrap';
export default class InvFields extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { colSize, label, value, padding } = this.props;
    return (
      <Col md={colSize} className={padding? padding : ''} >
        <div className="form-group">
          <label className="label">{label}  </label>
          <h6 className="para-ui">{value}</h6>
        </div>
      </Col>
    )
  }
}
