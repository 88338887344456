import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../../../../constants';
import {ToolTipHover} from '../../../../../shared';
import {getLocalStorageInfo} from '../../../../../utils';
import { FieldCol, FieldGroup, FieldSelect } from '../../../../contracts/Elements';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { getTableSectionsApi, getUserListApi } from '../apiServices';

let fullscreenElement
document.onfullscreenchange = ( event ) => {
  fullscreenElement = document.fullscreenElement;
};
class TableHeaderDropdown extends Component {
  constructor(props){
    super(props);
    this.state = {
      // fullscreenElement: false,
    }
    this.getTableSectionsApi = getTableSectionsApi.bind(this);
  }
  // openFullscreenMode = () => {
  //   if (document.querySelector('.zoom-content').requestFullscreen) {
  //     document.querySelector('.zoom-content').requestFullscreen();
  //   } else if (document.querySelector('.zoom-content').msRequestFullscreen) {
  //     document.querySelector('.zoom-content').msRequestFullscreen();
  //   } else if (document.querySelector('.zoom-content').mozRequestFullScreen) {
  //     document.querySelector('.zoom-content').mozRequestFullScreen();
  //   } else if (document.querySelector('.zoom-content').webkitRequestFullscreen) {
  //     document.querySelector('.zoom-content').webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  //   }
  //   this.setState({fullscreenElement: true})
  // }
  // closeFullscreenMode = () => {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //   } else if (document.webkitExitFullscreen) {
  //     document.webkitExitFullscreen();
  //   } else if (document.msExitFullscreen) {
  //     document.msExitFullscreen();
  //   }
  //   this.setState({fullscreenElement: false})
  // }
  render(){
    const { openTechForm, dropdownMenuList, onSectionChange, value, hideAddButton, changeFilter, filterOptions, operatorFlag, formInfo, userList } = this.props;
    const options = userList.map(item => ({
      "value" : item.id,
      "label" : item.name
    }))
    let { fullscreenElement } = this.state;
    return(
        <div className="table-header">
          <Row className="flex-centered">
            {/* <Col md="10">
              <h4>{dropdownMenuList.find(item => item.id == value).name}</h4>
            </Col> */}
            <FieldCol md="7">
              <FieldGroup className="form-group" style={{display: "inline-block"}}>
                <Select
                  name="form-field-name"
                  value={value}
                  options={dropdownMenuList.map(item => {return {name:item.total_item ? `${item.name} (${item.total_item})` : item.name, id:item.id}})}
                  labelKey ="name"
                  valueKey ="id"
                  onChange={(section) => onSectionChange(section)}
                />
              </FieldGroup>
            {
              value !== 0 && formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !formInfo.current_form.status.label.toLowerCase().includes('completed') && formInfo.current_form.user_security_group !== 'operator' ?
               <button style={this.props.fullScreen ? {display: 'none'} :{ marginLeft: '20px', color: '#2F8CFF', fontSize: '14px', fontWeight: '700', borderBottom: '1px solid #2F8CFF', position: 'relative', top: '-20px', background: 'none' }} onClick={openTechForm}>+ Add New Item</button>:null
            }
              <ul className="list-inline delete-prs">
                {
                  formInfo && formInfo.project && formInfo.project.leads && formInfo.project.leads.length > 0 && formInfo.project.leads.filter(user => user.name.trim().toLowerCase() === getLocalStorageInfo().user.name.toLowerCase()).length > 0 ?
                  <li className="list-inline-item">
                    <span onClick={() => this.props.emptyPrs()}><img alt="Delete Icon"  className="delete-icon" src={imgStoragePath + 'delete_red.png'} />Entire PRS</span>
                  </li>
                  :null
                }
                {
                  this.props.gap_report_ids.length && value !== 0 ?
                  <li className="list-inline-item">
                    <span onClick={() => this.props.deleteGapIds()}><img style={{ width: '15px', cursor: 'pointer', marginRight: '5px' }} alt="Delete Icon"  className="delete-icon" src={imgStoragePath + 'delete_red.png'} /> Selected Rows({this.props.gap_report_ids.length})</span>
                  </li>
                  :null
                }
                {
                  this.props.gap_report_ids.length && value !== 0 ?
                  <li className="list-inline-item" style={{cursor:'pointer'}}>
                    <span onClick={() => this.props.moveGapIds()}>Move Items ({this.props.gap_report_ids.length})</span>
                  </li>
                  :null
                }
                {
                  this.props.gap_report_ids.length && value !== 0 ?
                  <li className="list-inline-item" style={{cursor:'pointer'}} >
                    <span onClick={() => this.props.assignGapIds()}> Select Assignee</span>
                  </li>
                  :null
                }
                {
                  // this.props.gapList && value !== 0 ?
                  // <li className="list-inline-item">
                  //   <span onClick={() => this.props.emptySection()}>Empty Section</span>
                  // </li>
                  // :null
                }
              </ul>
            </FieldCol>
            <Col md="5">
              <div className="full-screen-block">
                {
                  // fullscreenElement === false ?
                  //   <img onClick={() => this.openFullscreenMode()} src={imgStoragePath + 'maximize.svg'} style={{ width: '26px', marginRight: '8px', cursor: 'pointer' }} alt="Zoom Icon" />
                  // :null
                }
                  {
                    // fullscreenElement === true ?
                    //   <button className="primary-btn" onClick={() => this.closeFullscreenMode()}>Exit Full Screen</button>
                    // :null
                  }
                  {
                    dropdownMenuList.filter(item => parseInt(item.id) === parseInt(value)).length ?
                    <ul className="list-inline flex-centered">
                      {
                        value !== 0 && dropdownMenuList.filter(item => parseInt(item.id) === parseInt(value))[0].total_item > 0 ?
                          <li className="list-inline-item">Filter By:</li>
                        :null
                      }
                      {
                        value !== 0 && dropdownMenuList.filter(item => parseInt(item.id) === parseInt(value))[0].total_item > 0 ?
                        <li className="list-inline-item">
                          <Select
                            placeholder="Priority"
                            name="form-field-name"
                            value={filterOptions.priority ? filterOptions.priority:''}
                            options={[{label:'None',value:3},{label:'P1',value:1},{label:'P2',value:2}]}
                            onChange={(item) => changeFilter(item ? item.value:'', 'priority')}

                            />
                        </li>
                        :null
                      }
                      {
                        value !== 0 && dropdownMenuList.filter(item => parseInt(item.id) === parseInt(value))[0].total_item > 0 ?
                        <li className="list-inline-item disposition">
                          <Select
                            placeholder="Disposition"
                            name="form-field-name"
                            value={filterOptions.disposition ? filterOptions.disposition:''}
                            options={[{label:'Open',value:'0'},{label:'Closed',value:'1'}, {label:'Reopen',value:'2'}]}
                            onChange={(item) => changeFilter(item ? item.value:'' , 'disposition')}
                            />
                        </li>
                        :null
                      }
                      {
                        value !== 0 && dropdownMenuList.filter(item => parseInt(item.id) === parseInt(value))[0].total_item > 0 ?
                        <li className="list-inline-item disposition" style={{marginLeft:'8px'}}>
                          <Select
                            isClearable={false}
                            placeholder={'Assignee'}
                            value={filterOptions.assignee}
                            options = {options}
                            onChange={(item) => changeFilter(item ? item.value : '' , 'assignee')}
                            />
                        </li>
                        :null
                      }
                      {
                        this.props.fullScreen == true ?
                        <li className="list-inline-item" style={{borderLeft:'2px solid #2f8cff', marginLeft: '8px', padding: '7px', width: '30px'}}>
                          <img id="fullscreen-exit" className="table-zoom" onClick={() => this.props.toggleFullScreen()} src={this.props.fullScreen == false ? null : imgStoragePath + 'zoom-in.svg'} style={{ width: '20px', cursor: 'pointer' }} />
                          <ToolTipHover placement="bottom" tagetId={"fullscreen-exit"}>
                            Exit Full Screen
                          </ToolTipHover>
                        </li>:null
                      }
                    </ul>
                    :null
                  }
              </div>
            </Col>
          </Row>
        </div>
    )
  }
}
export default TableHeaderDropdown;
