import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils';
export const getRecycleBinAc = (params, pageNumber) =>{
  return(dispatch) => {
    dispatch({
      type: actions.RECYCLE_BIN_LOADER,
      payload: true
    });
    globalGetService('records/'+params.type+'/'+params.aircraft_slug+'/recycle-bin/list/', { page: pageNumber})
    .then(response => {
      dispatch({
        type: actions.RECYCLE_BIN_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.GET_RECYCLE_BIN,
          payload: response.data.data,
          pagination: response.data.pagination
        })
      }
    })
  }
}

export const actionRecycleBinAc = (params,actionType, recycleList) => {
  let binObject = {
    action: actionType,
    items:recycleList,
  }

  return(dispatch) => {
    dispatch({
      type: actions.RECYCLE_BIN_LOADER,
      payload: true
    });
    globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/recycle-bin/action/', binObject)
    .then( response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.RECYCLE_BIN_ACTION,
          payload: binObject.items,
        });
      }
      dispatch({
        type: actions.RECYCLE_BIN_LOADER,
        payload: false
      });
    })
  }
}
