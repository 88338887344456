import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import {  prettyNumberParamsL, displayDateFormat } from '../../../../constants';
import moment from 'moment';

export default class MjrAssShopVisitDetail extends Component{
  constructor(props){
    super(props);
  }

  render(){
    const { shopvisit } = this.props;
    return(
      <a className="asset-list" >
        <Row className="flex-centered">
            <Col md="2">
              <h6><b>{shopvisit.name} </b></h6>
              <h6> {shopvisit.shop_visit_date ? moment(shopvisit.shop_visit_date).format(displayDateFormat) : '--'}</h6>
          </Col>  
          <Col md="2">
             <NumericLabel params={prettyNumberParamsL}>{shopvisit.cost}</NumericLabel>
          </Col>         
          <Col md="2">
              <h6>
              {shopvisit.shop_visit_facility ? shopvisit.shop_visit_facility: '--'}
              </h6>
              <h6>
              {shopvisit.facility_location ? shopvisit.facility_location: '--'}
              </h6>
          </Col>
          <Col md="2">
             <h6>{shopvisit.tsn ? shopvisit.tsn: '--'}</h6>
          </Col>
          <Col md="2">
             <h6>{shopvisit.csn ? shopvisit.csn: '--'}</h6>
          </Col>
          <Col md="2">
              <h6>{shopvisit.status && shopvisit.status.label ? shopvisit.status.label : '--'}</h6>
          </Col>
        </Row>
      </a>
    )
  }
}