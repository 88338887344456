import React, { Component } from 'react';
import { NoRecordFound  } from '../../../../shared';
export default class ReturnVsDeliveryPop extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { returnCondCompare } = this.props;
    return(
      <div>
        <div className="return-cond-modal-body">
          <table className="outer-table" width="100%">
            <thead>
              <tr>
                <th width="16%">Component</th>
                <th width="42%">Return Conditions</th>
                <th width="42%">Delivery Conditions</th>
              </tr>
            </thead>
            <tbody>
              {
                returnCondCompare.returnConds.map((returnsCond,index) =>
                <tr key={index}>
                  <td className="condition-component-name">{returnsCond.name}</td>
                  <td>
                    {
                      returnsCond.return_condition.map((return_condition,index) =>
                      <div className="conditions-list" key={index}>
                        <ul>
                          <li>
                            <span>Terms</span>
                            <p>{return_condition.terms}</p>
                          </li>
                          <li>
                            <span>Exceptions Done</span>
                            <p>{return_condition.exceptions_done}</p>
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                  <td>
                      {
                        returnsCond.delivery_condition.map((delivery_condition,index) =>
                        <div className="conditions-list">
                          <ul>
                            <li>
                              <span>Terms:</span>
                              <p>{delivery_condition.terms}</p>
                            </li>
                            <li>
                              <span>Exceptions Done:</span>
                              <p>{delivery_condition.exceptions_done}</p>
                            </li>
                            <li>
                              <span>Actual</span>
                              <p>{delivery_condition.actual}</p>
                            </li>
                          </ul>
                        </div>
                      )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {
            returnCondCompare.flag && !returnCondCompare.returnConds.length ?
            <NoRecordFound description="Get Started by adding Return & Delivery conditions under contract" />:null
          }
        </div>
      </div>
    )
  }
}
