import * as actions from '../actions';

export default function (state = {
  rentalInfo: {},
  leaseRentalLoader: false,
  path:'',
  leaseRentalCrud: {
    flag: false,
    type: '',
    data: {}
  },
  sheetInfo:{},
  modalFlag: false,
  rentalInvoiceFlag: false,
  sheetLoader:false,
  mrRevisionList:[],
  revisionFlag:false,
}, action) {
  switch (action.type) {
    case actions.RENTAL_INFO:
    return{
      ...state,
      rentalInfo : action.payload
    }

    case actions.RENTAL_REVISION_HISTORY:
      return {
        ...state,
        mrRevisionList: action.payload,
        revisionFlag:true
      }

    case actions.TOGGLE_REVISION_HISTORY:
      return {
        ...state,
        revisionFlag: action.payload
      }
      
    case actions.RENTAL_VIEW_DETIAL:
      return {
        ...state,
        rentalView: {
          ...state.rentalView,
          flag: true,
          index: action.payload.index,
          data: action.payload.res
        }
      }
    case actions.RENTAL_INVOICE_VIEW:
      return {
        ...state,
        rentalInvoiceView: {
          ...state.rentalInvoiceView,
          flad: false,
          data: action.payload
        }
      }

    case actions.LEASE_RENTAL_SHEET_SUCCESS:
       return{
        ...state,
        sheetInfo:action.payload
       }
  case actions.LEASE_RENTAL_PATH:
       return{
        ...state,
        path:action.payload
       }
       
    case actions.LEASE_RENTAL_ADD_SUCCESS:
    return {
      ...state,
      rentalInfo : {
        ...state.rentalInfo,
        rental_payment_schedule : [ action.payload, ...state.rentalInfo.rental_payment_schedule]
      }
    }
    case actions.EDIT_RENTAL_SUCCESS:
      return {
        ...state,
        rentalInfo : {
          ...state.rentalInfo,
          rental_payment_schedule: state.rentalInfo.rental_payment_schedule.map(list => list.id == action.payload.id ? action.payload : list)
        }
      }
    case actions.RENTAL_INVOICE_EDIT:
      return {
        ...state,
        rentalInfo : {
          ...state.rentalInfo,
          contract_rental_details : action.payload
        }
      }
  
    case actions.TOGGLE_RENTAL_INVOICE_SIDE_BAR:
      return {
        ...state,
        rentalInvoiceFlag: action.payload
      }
    case actions.LEASE_RENTAL_LOADER:
      return {
        ...state,
        leaseRentalLoader: action.payload
      }
    case actions.LEASE_RENTAL_CRUD:
      return{
        ...state,
        leaseRentalCrud: action.payload,
        sheetInfo:(action.payload.type=='add' ? {} : state.sheetInfo),
        path:(action.payload.type=='add' ? '' : state.path)
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        leaseRentalCrud: {
          ...state.leaseRentalCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
      case actions.DELETE_LEASE_RENTAL:
        return {
          ...state,
          rentalInfo : {
            ...state.rentalInfo,
            rental_payment_schedule: state.rentalInfo.rental_payment_schedule.filter(list => [action.payload].indexOf(list.id) == -1),
          },
          
        }
      case actions.MODAL_TOGGLE:
        return{
          ...state,
          modalFlag: action.payload,
        }
      case actions.SHEET_LOADER:
        return{
          ...state,
          sheetLoader: action.payload,
        }
    default:
    return state
  }
}
