import moment from 'moment';
const numberRegx = /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/;
const intRegx = /^[-+]?[1-9]\d*$/;
let engineData;
export const validMRFinanceGenGraph = data => {
  return validMRFinanceGenGraphData(data);
}
function validMRFinanceGenGraphData(data){
  let apu = {}, landingGear = {}, airframe = {};
  let errorObj = {};
  if(data.airframe){
    Object.keys(data.airframe).map(label => {
      airframe[label] = airframeValidatorError(airframeValidator(data.airframe[label]));
    });
    errorObj['airframe'] = airframe;
  }
  if(data.apu){
    apu = apuAndLgValidatorError(apuAndLgValidator(data.apu));
    errorObj['apu'] = apu;
  }
  if(data.landingGear){
    landingGear = apuAndLgValidatorError(apuAndLgValidator(data.landingGear));
    errorObj['landingGear'] = landingGear;
  }
  return errorObj;
}
function airframeValidator(data) {
  return {
    currentFundValue: data.currentFundValue == '' || numberRegx.test(data.currentFundValue),
    fundDate: data.fundDate == null || data.fundDate == '' || moment(data.fundDate).isValid(),
    lastShopvisitDate: data.lastShopvisitDate == null || data.lastShopvisitDate == '' || moment(data.lastShopvisitDate).isValid(),
    nextShopvisitDate: moment(data.nextShopvisitDate).isValid(),
    actualMR: data.actualMR == '' || numberRegx.test(data.actualMR),
    escalation: data.escalation == '' ||  numberRegx.test(data.escalation),
    cost_escalation: data.cost_escalation == '' ||  numberRegx.test(data.cost_escalation)
  }
}
function apuAndLgValidator(data) {
  const utilizationType =  data.utilizationType ? data.utilizationType:'tsn';
  return {
    currentFundValue: data.currentFundValue == '' || numberRegx.test(data.currentFundValue),
    fundDate: data.fundDate == null || data.fundDate == '' ||  data.fundDate == 'Invalid date' || moment(data.fundDate).isValid(),
    escalation: data.escalation == '' || numberRegx.test(data.escalation),
    cost_escalation: data.cost_escalation == '' || numberRegx.test(data.cost_escalation),
    actualMR: data.escalation == '' || numberRegx.test(data.actualMR),
    dateOfLastOverhual: utilizationType != 'dateOfLastOverhual' || moment(data.dateOfLastOverhual).isValid(),
    tsn: (numberRegx.test(data.tsn)),
    csn: data.csn == 0 || intRegx.test(data.csn),
    tsn_at_lsv: utilizationType != 'dateOfLastOverhual' || (numberRegx.test(data.tsn_at_lsv) && data.tsn_at_lsv > 0),
    csn_at_lsv: utilizationType != 'dateOfLastOverhual' || (numberRegx.test(data.csn_at_lsv) && data.csn_at_lsv > 0 && intRegx.test(data.csn_at_lsv) )
  }
}
function apuAndLgValidatorError(data){
  return {
    currentFundValue: data.currentFundValue ? '' : 'Enter Valid Fund',
    fundDate: data.fundDate ? '' : 'Select Valid Fund Date',
    escalation: data.escalation ? '' : 'Enter Valid  MR Rate Escalation',
    cost_escalation: data.cost_escalation ? '' : 'Enter Valid Shop Visit Cost Escalation',
    actualMR: data.actualMR ? '' : 'Enter Valid Actual MR',
    dateOfLastOverhual: data.dateOfLastOverhual ? '' : '',
    tsn: data.tsn ? '' : 'Enter Valid TSN',
    csn:  data.csn  ? '' : 'Enter Valid CSN',
    tsn_at_lsv: data.tsn_at_lsv ? '' : 'Enter Valid TSLSV',
    csn_at_lsv: data.csn_at_lsv ? '' : 'Enter Valid CSLSV',
  }
}
function airframeValidatorError(data) {
  return {
    currentFundValue: data.currentFundValue ? '' : 'Enter Valid Fund',
    fundDate: data.fundDate ? '' : 'Select Valid Fund Date',
    lastShopvisitDate: data.lastShopvisitDate ? '' : 'Select Valid LSV Date',
    nextShopvisitDate: data.nextShopvisitDate ? '' : 'Select Valid NSV Date',
    actualMR: data.actualMR ? '' : 'Enter Valid Actual MR',
    escalation: data.escalation ? '' : 'Enter Valid MR Rate Escalation',
    cost_escalation: data.cost_escalation ? '' : 'Enter Valid Event Cost Escalation'
  }
}
// Particular Engine Field Validation
export const engineValidator = (data) => {
  engineData = {};
  engineData = data;
  return engineValidatorError(engineValidatorData(data))
}
function engineValidatorError(data){

  return {
    fund: data.fund ? '' : 'Enter Valid Fund',
    fund_llp: data.fund_llp ? '' : 'Enter Valid Fund Value For LLP',
    fund_date: data.fund_date ? '':'Select Valid Fund Date',
    tsn: data.tsn ? '': numberRegx.test(engineData.tsn) ?  (!engineData.timeAndCycleStatus && data.tsn == 0) ? 'TSN or CSN Entered appears incorrect':'TSN or CSN Entered appears incorrect' :'Enter Valid TSN',
    csn: data.csn ? '':(data.csn == '0' || intRegx.test(data.csn)) ? (!engineData.timeAndCycleStatus && engineData.csn == 0) ? 'TSN or CSN Entered appears incorrect' : 'TSN or CSN Entered appears incorrect':'Enter Valid CSN',
    tsn_at_lsv: data.tsn_at_lsv ? '':'TSN at Last Shop Visit should be greater than 0 & less than TSN',
    csn_at_lsv: data.csn_at_lsv ? '':'CSN at Last Shop Visit should be greater than 0 & less than CSN',
    lsv_date: data.lsv_date ? '':'Select Valid LSV Date',
    engine_escalation: data.engine_escalation ? '':'Enter Valid Engine Escalation',
    prsv_cost_escalation: data.prsv_cost_escalation ? '':'Enter Valid PRSV Cost Escalation',
    hour_util: data.hour_util ? data.hour_util_check ? '':'Maximum Monthly Utilization in Hour should be below 720':'Enter Valid Assumed Monthly Utilization in Hour',
    actual_prsv_mr: data.actual_prsv_mr ? '':'Enter Valid Actual PRSV MR Rate',
    actual_llp_mr: data.actual_llp_mr ? '':'Enter Valid Actual LLP MR Rate',
    llp_escalation: data.llp_escalation ? '':'Enter Valid LLP Escalation',
    llp_cost_escalation: data.llp_cost_escalation ? '':'Enter Valid LLP Cost Escalation'
  }
}
function engineValidatorData(data){
  return {
    fund_date: data.fund_date == '' || data.fund_date == 'Invalid date' ||  moment(data.fund_date).isValid(),
    fund:  data.fund == '' || numberRegx.test(data.fund),
    fund_llp: data.fund_llp == '' || numberRegx.test(data.fund_llp),
    engine_escalation: data.engine_escalation == '' || numberRegx.test(data.engine_escalation),
    prsv_cost_escalation: data.prsv_cost_escalation == '' || numberRegx.test(data.prsv_cost_escalation),
    llp_escalation: data.llp_escalation == '' || numberRegx.test(data.llp_escalation),
    llp_cost_escalation: data.llp_cost_escalation == '' || numberRegx.test(data.llp_cost_escalation),
    hour_util: numberRegx.test(data.hour_util) && data.hour_util > 0 ,
    hour_util_check: numberRegx.test(data.hour_util) && data.hour_util > 0 && data.hour_util < 720,
    actual_prsv_mr: numberRegx.test(data.actual_prsv_mr) && data.actual_prsv_mr >= 0,
    actual_llp_mr: numberRegx.test(data.actual_llp_mr) && data.actual_llp_mr >= 0,
    tsn: numberRegx.test(data.tsn) && checkTsnCsn(data.tsn, data.csn) && (data.timeAndCycleStatus ? data.tsn > 0 ? true: false :true ),
    csn: checkTsnCsn(data.tsn, data.csn) && (data.csn == '0' || intRegx.test(data.csn)) && (data.timeAndCycleStatus ? data.csn > 0 ? true: false :true ),
    tsn_at_lsv: !data.timeAndCycleStatus || (numberRegx.test(data.tsn_at_lsv) && (data.tsn_at_lsv > 0 && parseInt(data.tsn_at_lsv) <= parseInt(data.tsn))),
    csn_at_lsv: !data.timeAndCycleStatus || intRegx.test(data.csn_at_lsv) && ((data.csn_at_lsv > 0) && (parseFloat(data.csn_at_lsv) <= parseFloat(data.csn))),
    lsv_date: data.fundDate == null || data.lsv_date == '' || data.lsv_date == 'Invalid date' ||  moment(data.lsv_date).isValid(),
  }
}
function checkTsnCsn(tsn, csn){
  if((tsn == '0' && csn == '0')){return true}
  else if(tsn == '0' && csn != '0'){return false}
  else if(tsn != '0' && csn == '0'){return false}
  else{return true}
}
