import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { TextInputField, GetInputField } from'../../../Elements';

import moment from 'moment';

export default class EditIssues extends Component {
  constructor(props){
    super(props);
    this.state = {
      contact:{},
      error: {},
      comment:'',
    }
  }
  updateComments = (event) => {
    this.setState({
        comment:event.target.value,
    }); 
  }
  componentDidMount(){
    this.setState({
      comment:this.props.issueData.description,
  });
  this.nameInput.focus();
  }

  addEditContact = (e) => {
    e.preventDefault();
    this.props.updateComments(this.state.comment);
  }
  render(){
    const { comments } = this.props;
    return(
      <div>
        <form onSubmit={(e) => this.addEditContact(e)}>
          <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
            <div style={{position: 'absolute',bottom:'65px',left:'0px',width:'100%',backgroundColor:'#ffffff'}}>
            <div style={{margin:'5px'}}>
                <input 
                   ref={(input) => { this.nameInput = input; }}
                   type = "text" 
                   placeholder="Edit issue"
                   value = {this.state.comment}
                   onChange = {this.updateComments}
                 />
                 </div>
             </div>
          </div>

            <div className="edit-sidebar-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="submit"
                  type="submit"
                  className="btn btn-primary">{'Update'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
                </li>
               </ul>
            </div>

        </form>
      </div>
    )
  }
}
