import * as actions from '../actions';

export default function(state={
  ctrShortInfo: {},
  inQueList:{},
  actionDockStatus: ['', 'Uploaded', 'OCR', 'Published', 'Rejected', 'Issue', 'Rename', 'Deleted', 'Forwarded'],
  dockLoader: false,
  publishList:[],
  publishables:[],
  pagination:{},
}, action){
  switch (action.type) {
    case actions.RECORDS_CTR_SHORT_INFO:
      return {
        ...state,
        ctrShortInfo: action.payload
      }
    case actions.INQUE_LIST:
      let publishables = [];
      action.payload.list.map(item => publishables.push(item.uuid));
      return {
        ...state,
        inQueList: action.payload,
        publishables
      }
    case actions.UPDATE_DOCK_STATUS:
      return {
        ...state,
        inQueList: {...state.inQueList, list:state.inQueList.list.filter(item => item.uuid != action.payload.objects[0])},
      }
      case actions.DOCK_LOADER:
        return {
          ...state,
          dockLoader: action.payload
        }
      case actions.PUBLISH_ALL_FILES:
        return {
          ...state,
          inQueList: [],
          publishList: [],
          publishables: [],
          inQueList: [],
        }
      case actions.ADD_TO_PUBLISH:
        let publishList = Object.assign([],state.publishList);
        publishList.indexOf(action.payload) != -1 ? publishList.splice(publishList.indexOf(action.payload),1) : publishList.push(action.payload);
        return {
          ...state,
          publishList
        }
      case actions.ADD_TO_PUBLISH_ALL:
        let publishFileList = [];
        if(action.payload == true){
            publishFileList = state.publishables;
        }
        return {
          ...state,
          publishList: publishFileList
        }
      case actions.PUBLISH_FILES:
        publishList = Object.assign([],state.publishList);
        publishables = Object.assign([],state.publishables);
        let inQueList = Object.assign([],state.inQueList);
        inQueList.list.map((item, index) =>{
          if(item.uuid == publishList[0]){
            inQueList.list.splice(index, 1);
            publishList.splice(0, 1);
            publishables.splice(0, 1);
          }
        });
        return{
          ...state,
          publishList:[],
          inQueList,
          publishables
        }
      case actions.RENAME_RECORD:
        return{
          ...state,
          inQueList: {...state.inQueList, list: state.inQueList.list.filter(item => item.uuid != action.payload.uuid )}
        }
      case actions.PAGINATION:
        return{
          ...state,
          pagination: action.payload
        }
      case actions.CHANGE_OCR_PRIORITY:
        return{
          ...state,
          inQueList: {...state.inQueList, list:state.inQueList.list.map(item => item.uuid == action.payload.files_uuid[0] ? {...item, ocr_priority : action.payload.priority }: item)}
        }
      case actions.RESTORE_REJECTED_RECORD:

        return {
          ...state,
          inQueList: {...state.inQueList, list: state.inQueList.list.filter(item => item.uuid != action.payload[0]['uuid'])}
        }
    default:
      return state

  }
}
