import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumericLabel from 'react-pretty-numbers';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UPDATE_AIRFRAME_MRDETAIL } from '../actions';
import { fieldDateFormat, backendDateFormat, imgStoragePath, floatMRDecRegx } from '../../../../constants';
class AirframeMRRow extends Component{
  constructor(props){
    super(props);
    this.state = {
      fieldFocus : false,
    }
  }
  handleFocus = (flag, item, keyParam) => {
    this.setState({
      fieldFocus: true
    });
    if(flag){

      this.props.updateErrorCode('airframe', item, keyParam);
    }

  }

  updateAirframeMRDetail = (value, item, keyParam, type) => {
    if(floatMRDecRegx.test(value)){
      this.props.updateAirframeMRDetail(value, item, keyParam, type)
    }
  }

  handleBlur = () => {
    this.setState({
      fieldFocus: false
    })
  }
  plotAirframeCol = () => {
    const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true}
    const editTable = this.props.airframeRow.editTable;
    const prefix = this.props.airframeRow.prefix;
    const airframeObj = this.props.reviewMRReport.airframe;
    const errors = this.props.errors;
    return Object.keys(airframeObj).map((item,index) => {
      return (
        <td className={ (errors['airframe'] && errors['airframe'][item]) ? (errors['airframe'][item][this.props.airframeRow.keyParam] != '' ? 'error-cls para-ui': 'para-ui'):'para-ui'} key={index}>
          <span className="field-container">
            {this.props.airframeRow.inputType != 'date' && editTable && prefix ? <span className="prefix">$</span>: null}
            {
              this.props.airframeRow.inputType == 'date' ?
              <DatePicker
                className={ editTable? 'edit-field': ''}
                dateFormat={fieldDateFormat}
                placeholder = "Select Date"
                selected={airframeObj[item][this.props.airframeRow.keyParam] && moment(airframeObj[item][this.props.airframeRow.keyParam]).isValid() ? moment(airframeObj[item][this.props.airframeRow.keyParam]): null}
                onChange={(e) => this.props.updateAirframeMRDetail(moment(e).format(backendDateFormat), item, this.props.airframeRow.keyParam, 'date')}
                onFocus={() => this.handleFocus((errors['airframe'] && errors['airframe'][item]) ? true:false,item, this.props.airframeRow.keyParam)}
                onBlur={() => this.handleBlur(item, this.props.airframeRow.keyParam)}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
              />
              :
              editTable ?
              <input className={editTable? 'edit-field': ''} type="text" disabled = {this.props.airframeRow.editTable ? false:true}
                value={airframeObj[item][this.props.airframeRow.keyParam]}
                onChange = {(e) => this.updateAirframeMRDetail(e.target.value, item, this.props.airframeRow.keyParam, 'text')}
                onFocus={() => this.handleFocus((errors['airframe'] && errors['airframe'][item]) ? true:false,item, this.props.airframeRow.keyParam)}
                onBlur={() => this.handleBlur()}
              />: <NumericLabel params={prettyNumberParams}>{airframeObj[item][this.props.airframeRow.keyParam]}</NumericLabel>

            }
            {this.props.airframeRow.inputType != 'date' && editTable && !prefix ? <span className="suffix">%</span>: null}
            { editTable ? <img src={imgStoragePath+"edit-field.png"} alt="icon" /> : null }
          </span>
        </td>
      )
    })
  }
  render(){
    return(
      <tr>
        <td className="caption-ui">{this.props.airframeRow.label}</td>
        {this.plotAirframeCol()}
      </tr>
    )
  }
}
const mapStateToProps = state => ({
  reviewMRReport: state.MRFinanaceRptReducer.reviewMRReport
});


const mapDispatchToProps = dispatch => {
  return {
    updateAirframeMRDetail : (value, outerKey, innerKey, inputType) => dispatch({
      type: UPDATE_AIRFRAME_MRDETAIL,
      payload : {value, outerKey, innerKey, inputType}
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AirframeMRRow);
