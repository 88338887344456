import React, { Component } from 'react';
import { Link } from 'react-router';
import {  ContentDrop } from '../../../Elements';
import {  ReviewCollapse } from '../../../Elements';
export default class ReviewEditor extends Component {
  constructor(props){
		super(props);
	}
	render(){
		const { inspectionSummary } = this.props;
		return(
			<div>
				<div className="sidebar-form review-editor"  style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
          { inspectionSummary.flag ?
              inspectionSummary.data.map((review, index) =>
  				      <ReviewCollapse
                  updateReviewCollapse={this.props.updateReviewCollapse}
                  type="inspectionSummary"
                  removeReviewCollapse={() => this.props.removeReviewCollapse('inspectionSummary', index)}
                  toggleReviewCollapse = {() => this.props.toggleReviewCollapse('inspectionSummary', index)}
                  review={review}
                  key={index}
                />
			        )
						:null
					}
        </div>
        <div className="edit-sidebar-footer">
          <ul className="list-inline">
            <li className="list-inline-item">
              <input className="btn btn-primary" type="button" value="Save Changes" />
            </li>
            <li className="list-inline-item">
              <Link onClick={() => this.props.addReviewCollapse('inspectionSummary', {index:inspectionSummary.data.length + 1,title: '', flag: false, content: ''})}> Add New Section</Link>
            </li>
            <li className="list-inline-item">
              <Link onClick={() => this.props.changeRecordReviews('inspectionSummary', {data: inspectionSummary.data, flag: false})}> Cancel</Link>
            </li>
          </ul>
        </div>
      </div>
		)
	}
}
