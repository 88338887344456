import React, {Component} from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

class WhatsNew extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const feature = this.props.feature
    return(
      <div className="timeline-container">
      <li class="timeline-item">
      <div className="timeline-circle"></div>
      <p className="timeline-time" style={{"font-size":"10px"}}>{ feature.release_date ?   moment(feature.release_date).format('MMM DD, YYYY') : '-'}</p>
      <div className="content">
      <h2 className="timeline-title" style={{"font-size":"16px"}}>{feature.title}</h2>
      <div dangerouslySetInnerHTML={{__html: feature.description?feature.description:''}} ></div>
      </div>
      </li>
      </div>
    )
  }
}
export default withRouter(WhatsNew);
