import React from 'react';
import EventCardInfo from './EventCardInfo';
import moment from 'moment';
import { displayDateFormat } from '../../../constants';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsDB } from '../../../constants';
const EventCard = (props) => {
  return(
    <div className="">
      <div className="card-date-info">
        <ul className="list-unstyled">
          <EventCardInfo title={"Last " + props.type + " Date"} value={ props.event.lastEventDate ? moment(props.event.lastEventDate).format(displayDateFormat): '--' } />
          <EventCardInfo title={"Next " + props.type + " Date"} value={ props.event.eventDate ? moment(props.event.eventDate).format(displayDateFormat): '--' } />
        </ul>
      </div>
      <div className="card-other-info">
        <ul className="list-unstyled">
          <EventCardInfo title={"Fund Balance as of " + props.type + " Date"} value={props.event.fundCollected ? <NumericLabel params={prettyNumberParamsDB}>{props.event.fundCollected}</NumericLabel>:'--'} />
          <EventCardInfo title={props.type + " Cost Estimate"} value={props.event.eventCost ? <NumericLabel params={prettyNumberParamsDB}>{props.event.eventCost}</NumericLabel>:'--'}/>
          <EventCardInfo title="Estimated Remaining Balance" value={props.event.surPlus ? <NumericLabel params={prettyNumberParamsDB}>{props.event.surPlus}</NumericLabel>:'--'} />
        </ul>
      </div>
    </div>
  )
}
export default EventCard;
