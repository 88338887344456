import React from 'react';
import { Link } from 'react-router';
import { getLocalStorageInfo } from '../../utils';

const AssetList = (props) =>{
  return(
    <li>
       <div >
         {getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user &&
             getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 ?
        <input checked={props.deleteObject.indexOf(props.asset.id) != -1 ? 'checked' : ''} type="checkbox" onClick={() => props.addUserAsset(props.asset)}/>
        :null
         }
        <span style={{width:'200px',marginLeft:'10px',display:'inline-block'}}>{props.asset.asset_name}</span>

         {/* <i className="fa fa-trash" style={{padding:'10px'}}onClick={() => props.deleleLessor('Delete Lessor ' + props.lessor.name, 'lessor', props.lessor.id)}></i>*/}
         {getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user &&
             getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 ?
        <Link className="delete-link" onClick={() => props.deleleAsset('Delete Asset - "' + props.asset.asset_name +'"', 'asset', props.asset.id)}>Delete</Link>:null
         }
      </div>
    </li>
  )
}
export default AssetList;
