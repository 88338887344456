import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements'

export default class PassengerSeating extends Component {
 constructor(props){
  super(props);
 }
 render() {
   const { paxSeat, index, asset_slug } = this.props;
   const pasObj = [
    {value: paxSeat.seat_type && paxSeat.seat_type.label ? paxSeat.seat_type.label : '--'},
    {value: paxSeat.no_of_seats ? paxSeat.no_of_seats : '--'},
    {value: paxSeat.model ? paxSeat.model : '--'},
    {value: paxSeat.part_number ? paxSeat.part_number : '--'},
    {value: paxSeat.convertable_or_fixed && paxSeat.convertable_or_fixed.label? paxSeat.convertable_or_fixed.label : '--'},
    {value: paxSeat.manufacturer ? paxSeat.manufacturer : '--'} 
  ]
  return (
      <tr onClick={() => this.props.viewPaxSeat(asset_slug, paxSeat.id, index, 'paxSeatView')}>
         {pasObj.map((paxSeating,index) =>
           <ContrListCol key={index} colObj={paxSeating}   />
        )}
      </tr>
    )
  }
}
