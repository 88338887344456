import * as actions from '../actions';
import { globalGetService } from '../../../globalServices'
import { trackActivity } from '../../../utils/mixpanel'
export const getFilterOptionsAc = (obj) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.GLOBAL_SEARCH_LOADER,
      payload: true
    })
    globalGetService('/get_filter_details/', obj)
    .then(response => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
      if(response.data.statusCode === 200){
        dispatch({
          type: actions.ASSET_FILTERS,
          payload: response.data.data
        })
      }
    })
    .catch(error => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
    })
  }
}

export const getSearchResultAc = (params, query, application, level, pageNumber, filters, noFilter) => {
  return(dispatch) => {
    let data = {};
    if(params.aircraft_slug != undefined && params.aircraft_slug != null && params.aircraft_slug != '' && level && level.toLowerCase() != 'fleet'){
      data = {q: query, page: pageNumber, application: application != undefined ? application && application.toLowerCase() : '', asset_type:params.type, level: params.aircraft_slug, ...filters}
    }else {
      data = {q: query, page: pageNumber, application: '', asset_type: '', level: 'fleet', ...filters}
    }
    dispatch({
      type: actions.GLOBAL_SEARCH_RESULT,
      payload: {
        data:{
          "results": [],
          "pagination": {
              "total": 0,
              "current_page": "0",
              "number_of_pages": 0
          }
        }
      }
    })
    dispatch({
      type: actions.GLOBAL_SEARCH_LOADER,
      payload: true
    })
    dispatch({
      type: actions.PREVIEW_RECORD,
      payload: {}
    })
    globalGetService('search/',data)
    .then((response) => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.GLOBAL_SEARCH_RESULT,
            payload: response.data
          })
          trackActivity('Global Search', {
            query: query,
            source: level,
            application: application ? application : 'None',
            result_count: response.data.data.pagination.total
          })
          if(noFilter){
          }else{
            dispatch(getFilterOptionsAc(data))
          }
        }
    })
    .catch(error => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
    })
  }
}
