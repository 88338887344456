import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkNumber, checkValidDate } from '../../../../formValidator';
import moment from 'moment'
import { float2DecRegx } from '../../../../constants'
export default class AddCashTx extends Component{
  constructor(props){
    super(props);
    this.state = {
      cashTx: {},
      error: {},
      existingAmount: 0
    }
  }
  componentDidMount(){
    if(this.props.type !== 'add'){
      this.setState({
        cashTx: this.props.cashTxObj,
        existingAmount: this.props.cashTxObj.amount
      });
    }else {
      this.setState({
        cashTx: this.props.cashTxObj
      });
    }
  }
  updateForm = (type, value) => {
    if(type === 'amount'){
      if(float2DecRegx.test(value)){
        this.setState(prevState => ({
          ...prevState,
          cashTx: {
            ...prevState.cashTx,
            [type]: (value == 'Invalid date' ? null:value)
          },
          error: {
            ...prevState.error,
            [type]:''
          }
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        cashTx: {
          ...prevState.cashTx,
          [type]: (value == 'Invalid date' ? null:value)
        },
        error: {
          ...prevState.error,
          [type]:''
        }
      }))
    }
  }
  addEditCashTx = (e) => {
    e.preventDefault();
    let data = this.state.cashTx;
    if(this.props.type == 'add'){
      data = {
        ...data,
        security_deposit_id: this.props.securityDepositId
      }
    }
    var validateNewInput = {
      reference_number: checkString({value: data.reference_number, required: true, minLength: '', maxLength: '', message: 'Please Enter The Reference Number'}),
      transaction_date: checkValidDate({value: data.transaction_date, required: true, minLength: '', maxLength: '', message: 'Please Enter The Valid Transaction Date'}),
    };
    if(this.props.type === 'add'){
      if(data.amount > data.remaining_amount){
        validateNewInput = {
          ...validateNewInput,
          amount: "Please enter transaction amount less than equal to " + data.remaining_amount,
        }
      }else{
        validateNewInput = {
          ...validateNewInput,
          amount: checkNumber({value: data.amount, required: true, minLength: '', maxLength: '', message: 'Please Enter The Valid Amount'}),
        }
      }
    }else {
      if(data.amount > (data.remaining_amount + this.state.existingAmount)){
        validateNewInput = {
          ...validateNewInput,
          amount: "Please enter transaction amount less than equal to " + (data.remaining_amount + this.state.existingAmount),
        }
      }else{
        validateNewInput = {
          ...validateNewInput,
          amount: checkNumber({value: data.amount, required: true, minLength: '', maxLength: '', message: 'Please Enter The Valid Amount'}),
        }
      }
    }

    if(data.retention_period_end_date && data.retention_period_end_date.trim() != '' && parseInt(moment(data.retention_period_end_date).diff(moment(data.retention_period_start_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        retention_period_end_date: 'Validity start date can not be after Validity end date'
      }
    }

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.addEditCashTx(data, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { type, assetViewId } = this.props;
    const { cashTx, error } = this.state;
    return(
      <form onSubmit={(e) => this.addEditCashTx(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Reference Number
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={cashTx.reference_number}
                  type={type}
                  keyParam="reference_number"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.reference_number}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Transaction Date
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldDate
                  value={cashTx.transaction_date}
                  type={type}
                  keyParam="transaction_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.transaction_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Amount ( {cashTx.currency ? cashTx.currency: 'USD' })
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={cashTx.amount}
                  type={type}
                  keyParam="amount"
                  updateField={this.updateForm}
                  currency={true}
                  currencyType={cashTx.currency}
                />
                <h6 className="error-msg">{error.amount}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Validity Period Start Date
                </FieldLabel>
                <FieldDate
                  value={cashTx.retention_period_start_date}
                  type={type}
                  maxDate={moment(assetViewId.contract.contractual_end_date)}
                  minDate={moment(assetViewId.contract.contractual_start_date)}
                  keyParam="retention_period_start_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.retention_period_end_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Validity Period End Date </FieldLabel>
                <FieldDate
                  value={cashTx.retention_period_end_date}
                  type={type}
                  maxDate={moment(assetViewId.contract.contractual_end_date)}
                  minDate={cashTx.retention_period_start_date ? moment(cashTx.retention_period_start_date) : moment(assetViewId.contract.contractual_start_date)}
                  keyParam="retention_period_end_date"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view'?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
