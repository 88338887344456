import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import { getLocalStorageInfo } from '../../../utils'
const AssetWidget = (props) => {
  if(getLocalStorageInfo().defaultLessor.lessor_type == 1 && props.showManaged){
    return(
      <Col className="col1" style={{marginLeft: '0px', marginRight: '0px',paddingLeft: '0px', paddingRight: props.index != 0 ? '10px' : '0px'}}>
        <Row className="assets-row " style={{padding: '0px'}}>
          <Col sm="12" md="12" className={props.className} style={{cursor: 'pointer', marginBottom: '10px'}} onClick={() => props.url ? browserHistory.push(props.url) : null}>
                <Link className="text-center">
                  {
                    props.dbAssetLoader ? <h6 style={{color: '#ffffff'}}>Loading...</h6> :<h6>{props.statusGrpTitle} {props.value != 0 ? `- ${props.value}` : ``}</h6>
                  }
      					</Link>
    			</Col>
          <Col md="12" style={props.index == 0 ? {paddingLeft: '0px', paddingRight: '0px'} : {paddingLeft: '0px', paddingRight: '0px',marginLeft: '10px'}}>
            <div className={props.className + " left-column"} onClick={() => props.ownedUrl ? browserHistory.push(props.ownedUrl) : null}>
                  <Link className="text-center">
                    {!props.dbAssetLoader ? <h5>{props.owned}</h5> : null}
                    {
                      props.dbAssetLoader ? <span style={{color: '#ffffff', textAlign: 'center', display: 'block', width: '100%', paddingTop: '5px'}}>Loading...</span> :<h6>OWNED</h6>
                    }
        					</Link>
      			</div>
            <div className={props.className + " right-column"} onClick={() => props.managedUrl ? browserHistory.push(props.managedUrl) : null}>
                  <Link className="text-center">
                    {
                      !props.dbAssetLoader ? <h5>{props.managed}</h5> : null
                    }
                    {
                      props.dbAssetLoader ? <span style={{color: '#ffffff', textAlign: 'center', display: 'block', width: '100%', paddingTop: '5px'}}>Loading...</span> :<h6>MANAGED</h6>
                    }
        					</Link>
      			</div>
          </Col>
        </Row>
      </Col>

    )
  }else {
    return(
      <Col style={{marginLeft: '0px', marginRight: '0px',paddingLeft: '0px', paddingRight: props.index != 0 ? '10px' : '0px'}}>
        <Row className="assets-row " style={{padding: '0px'}}>
          <Col md="12" className={props.className} style={{cursor: 'pointer', height: '100px'}} onClick={() => props.url ? browserHistory.push(props.url) : null}>
                <Link className="text-center">
                  {!props.dbAssetLoader ? <h5 style={{fontSize: '24px'}}>{props.value ? props.value : 0}</h5> : null}
                  {
                    props.dbAssetLoader ? <h6 style={{color: '#ffffff'}}>Loading...</h6> :<h6 style={{fontSize: '14px'}}>{props.statusGrpTitle}</h6>
                  }
      					</Link>
    			</Col>
        </Row>
      </Col>

    )
  }
}
export default AssetWidget;
