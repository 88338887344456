import * as actions from '../actions';

export default function( state = {
  addSideBar:false,
  powerPlantInvDet: {},
  powerPlantLoader:false,

}, action ){

  switch (action.type) {
    case actions.ADD_ITEM_BAR:
      return {
        ...state,
        addSideBar:!state.addSideBar
      }

    case actions.GET_POWERPLANT_INV:
      return {
        ...state,
        powerPlantInvDet: action.payload
      } 

    case actions.POWERPLANT_DETLS_LOADER:
      return {
        ...state,
        powerPlantLoader: action.payload
      }
      
    case actions.ADD_DYNAMIC_ACCESSORIES:
      return{
        ...state,
        powerPlantInvDet: {
          ...state.powerPlantInvDet,
          accessories_list : [...state.powerPlantInvDet.accessories_list,action.payload]
        }
      }

    case actions.DELETE_POWER_COMPONENT:

    if(action.payload.sectionType == 'accessories_list'){
      return{
        ...state,
        powerPlantInvDet: {
          ...state.powerPlantInvDet,
          accessories_list : [...state.powerPlantInvDet.accessories_list].filter((component, compoIndex) =>  compoIndex != action.payload.index)  
      }
    }
    }
    if(action.payload.sectionType == 'additional_data'){
      return{
        ...state,
        powerPlantInvDet: {
          ...state.powerPlantInvDet,
          additional_data : [...state.powerPlantInvDet.additional_data].filter((component, compoIndex) =>  compoIndex != action.payload.index)  
      }
    }
  }
    if(action.payload.sectionType == 'missing_hardware'){
      return{
        ...state,
        powerPlantInvDet: {
          ...state.powerPlantInvDet,
          missing_hardware : [...state.powerPlantInvDet.missing_hardware].filter((component, compoIndex) =>  compoIndex != action.payload.index)  
      }
    }
    }

    case actions.ADD_DYNAMIC_ADDITIONAL:
      return{
        ...state,
        powerPlantInvDet: {
          ...state.powerPlantInvDet,
          additional_data : [...state.powerPlantInvDet.additional_data,action.payload]
        }
      }

    case actions.ADD_DYNAMIC_HARDWARE:
      return{
        ...state,
        powerPlantInvDet: {
          ...state.powerPlantInvDet,
          missing_hardware : [...state.powerPlantInvDet.missing_hardware,action.payload]
        }
      }
    case actions.UPDATE_TE002_FORM: 

      if(action.payload.sectionType == 'genDetails'){
          return{
          ...state,
          powerPlantInvDet: {
            ...state.powerPlantInvDet,
            general_details: {
              ...state.powerPlantInvDet.general_details,
              [action.payload.type]: action.payload.value
            }
          }
        }
      }
      if(action.payload.sectionType == 'accessories_list'){
        return{
          ...state,
          powerPlantInvDet: {
            ...state.powerPlantInvDet,
            accessories_list : [...state.powerPlantInvDet.accessories_list].map((component, compoIndex) =>  compoIndex == action.payload.index ?  {
            ...component,
            [action.payload.type]: action.payload.value
           }: component ) 
          }
        }  
      }    
      if(action.payload.sectionType == 'additional_data'){
        return{
          ...state,
          powerPlantInvDet: {
            ...state.powerPlantInvDet,
            additional_data : [...state.powerPlantInvDet.additional_data].map((component, compoIndex) =>  compoIndex == action.payload.index ?  {
            ...component,
            [action.payload.type]: action.payload.value
           }: component ) 
          }
        }  
      }    
      if(action.payload.sectionType == 'missing_hardware'){
        return{
          ...state,
          powerPlantInvDet: {
            ...state.powerPlantInvDet,
            missing_hardware : [...state.powerPlantInvDet.missing_hardware].map((component, compoIndex) =>  compoIndex == action.payload.index ?  {
            ...component,
            [action.payload.type]: action.payload.value
           }: component ) 
          }
        }  
      }    
  
      

    default:
      return state;

  }
}
