import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import OwnerClient from './OwnerClient';
import StatusRadio from './StatusRadio';
import { TextInputField,DateInputField, GetInputField } from '../../Elements';

export default class MRInvoiceDetails  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = () => {

  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row  style={{margin: '0px 15px'}}>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">QME</label>,
            { type: 'edit',
              value: content.qme,
              error: '',
              options: [{label:'Yes',value:'1'},{label:'No',value:'0'}],
              labelKey: 'label',
              valueKey: 'value',
              updateForm: (value) => this.props.updateForm({type: 'qme', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
        <Col sm="3"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">MR</label>,
            { type: 'edit',
              value: content.mr,
              error: '',
              options: [{label:'Yes',value:'1'},{label:'No',value:'0'}],
              labelKey: 'label',
              valueKey: 'value',
              updateForm: (value) => this.props.updateForm({type: 'mr', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
        <Col sm="3"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">LC</label>,
            { type: 'edit',
              value: content.lease_contract,
              error: '',
              options: [{label:'Yes',value:'1'},{label:'No',value:'0'}],
              labelKey: 'label',
              valueKey: 'value',
              updateForm: (value) => this.props.updateForm({type: 'lease_contract', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
        <Col sm="3"  style={{marginBottom: '20px'}}>
           {
         GetInputField(
            'dropdown',
            <label className="for-label">EOL</label>,
            { type: 'edit',
              value:  content.end_of_lease,
              error: '',
              options: [{label:'Yes',value:'1'},{label:'No',value:'0'}],
              labelKey: 'label',
              valueKey: 'value',
              updateForm: (value) => this.props.updateForm({type: 'end_of_lease', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
            }
         )
         }
        </Col>
        
         <Col sm="3"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'string',
              <label className="for-label">Hours/Cycles Ratio</label>,
              { type: 'edit',
              value: content.ratio,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'ratio', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index}) }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">MR Available For EPR</label>,
              { type: 'edit',
              value: content.mr_available_for_erp,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'mr_available_for_erp', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})  }
            )
          }
         </Col>

         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">MR Available For LLP</label>,
              { type: 'edit',
              value: content.mr_available_for_llp,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'mr_available_for_llp', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})  }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">As On Date</label>,
              { type: 'edit',
              value: content.as_on_date,
              error: error.as_on_date,
              updateForm: (value) => this.props.updateForm({type: 'as_on_date', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})  }
            )
          }
         </Col>
      </Row>
    </div>
    );
  }
}
