import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/securityDeposit.scss';
import { ListLoader, NoRecordFound } from '../../../../shared'
import { Header, ContractHeadertext, EditSidebar, ContractSideBar, FormModeType } from '../../Elements';
import SecurityDepositWrapper from '../components/SecurityDepositWrapper';
import AddSecurityDeposit from '../components/AddSecurityDeposit';
import SecurityTxHd from '../components/SecurityTxHd';
import CashTxRow from '../components/CashTxRow';
import AddCashTx from '../components/AddCashTx';
import NonCashTxRow from '../components/NonCashTxRow';
import AddNonCashTx from '../components/AddNonCashTx';
import { sExportContractsAc, sGlobalConstantAc, sAuditLogAc }  from '../../../../shared/actionCreators';
import { securityDpListAc, securityDepositCrudFnAc, addEditSecurityDpAc,addEditCashTxAc, securitydepositeMlAc } from '../actionCreators';
import { SECURITY_DEPOSIT_CRUD, TOGGLE_VIEW_EDIT } from '../actions';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { withRouter, browserHistory, Link } from 'react-router';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { imgStoragePath, assetsType } from '../../../../constants'
 class SecurityDeposit extends Component {
  constructor(props){
    super(props);
    this.state = {
      securityDepositeId: '',
      remaining_amount: 0
    }
  }
  componentDidMount(){
    this.props.fetchSecurityDepositeList();
    this.props.fetchTechConstant({constant_types:['security_deposit_type']});
    this.props.fetchsecuritydepositeMlAc();
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/security-deposit';
      browserHistory.push(url);
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }
  }

  updateSDId = (securityDepositeId, type, remaining_amount) => {
    this.setState(prevState => ({
      ...prevState,
      securityDepositeId: securityDepositeId,
      remaining_amount: remaining_amount,
    }));
    this.props.securityDepositCrudFn('add', {remaining_amount: remaining_amount}, type);
  }

  render() {
    const { securityDepositList, securityDepositCrud, cashTxCrud, nonCashTxCrud, securityDepositLoader, techConstants, auditLogView, securityDepositMLData, assetViewId } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Contracts" params={this.props.params} pageTitle="Security Deposit" changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application={`Security Deposit`} type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <ContractHeadertext
              title="Security Deposit"
              desc="Section holds all Security Deposits made under the Contract"
              addNew ={getLocalStorageInfo().user.permission['contracts']['security_deposit'].indexOf('C') != -1 ? '+ Add Security Deposit': '' }
              toggleAddEvent = {() => this.props.securityDepositCrudFn('add', {}, 'securityDepositCrud')}
              toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctsecuritydeposits', 0, null, 'add', this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Security Deposit', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Security Deposit'})}}
              addAudit= "Revision History"

              // addAudit= {auditLogView ?  "Revision History" : null}
          />
            <div className="list-container">
              {  securityDepositLoader ? <ListLoader />:null }
              { securityDepositList ? securityDepositList.map((securityDepDetail, index) =>
                <SecurityDepositWrapper key={index} securityDepDetail={securityDepDetail} viewDeposit={() => this.props.securityDepositCrudFn('view', securityDepDetail.id, 'securityDepositCrud' )}>
                  <div className="security-element">
                    {
                      securityDepDetail.security_deposit_type && (securityDepDetail.security_deposit_type.value == 2 || securityDepDetail.security_deposit_type.value == 1) ?
                      <div className="transaction-wrapper">
                        <h6 className="transaction-heading">Cash Transactions
                          { getLocalStorageInfo().user.permission['contracts']['security_deposit'].indexOf('C') != -1 && securityDepDetail.remaining_amount > 0 ?
                              <img onClick={() => this.updateSDId(securityDepDetail.id, 'cashTxCrud', securityDepDetail.remaining_amount)} className="add-trasaction" src={imgStoragePath+"plus_icon.png"} width="18" alt="img"/>:null
                          }
                        </h6>
                        <div className="mobile-asset-list">
                        <SecurityTxHd key={index} theads={['Reference Number', 'Transaction Date', 'Validity Period', 'Amount (USD)']} />
                        { Object.keys(securityDepDetail.security_deposit_transactions).length ? securityDepDetail.security_deposit_transactions.map((cashFlow, childIndex) =>
                          <CashTxRow key={childIndex} cashFlow={cashFlow} viewCashTransaction={() => this.props.securityDepositCrudFn('view', {...cashFlow, remaining_amount: securityDepDetail.remaining_amount}, 'cashTxCrud')} />
                          ) : null
                        }
                      </div>

                        { !securityDepDetail.security_deposit_transactions.filter(tx => tx.type_of_transaction == 1).length ?
                          <p style={{padding: '40px 0', textAlign: 'center', fontSize: '14px'}}>No Cash Transactions Added Yet!</p>:null
                        }
                      </div>
                      :null
                    }
                    {
                      securityDepDetail.security_deposit_type && (securityDepDetail.security_deposit_type.value == 3 || securityDepDetail.security_deposit_type.value == 1) ?
                      <div className="transaction-wrapper">
                        <h6 className="transaction-heading">Non-Cash Transactions
                          {getLocalStorageInfo().user.permission['contracts']['security_deposit'].indexOf('C') != -1 && securityDepDetail.remaining_amount > 0 ?
                            <img onClick={() => this.updateSDId(securityDepDetail.id, 'nonCashTxCrud', securityDepDetail.remaining_amount)} className="add-trasaction" src={imgStoragePath+"plus_icon.png"} width="18" alt="img"/>:null
                          }
                        </h6>
                        <div className="mobile-asset-list">
                          <SecurityTxHd theads={['Financial Instrument Type', 'Validity Period', 'Amount (USD)', 'Purpose']} />
                          {securityDepDetail.security_deposit_transactions ? securityDepDetail.security_deposit_transactions.map((nonCashFlow, childIndex) =>
                            <NonCashTxRow key={childIndex} nonCashFlow={nonCashFlow} viewNonCashTransaction={() => this.props.securityDepositCrudFn('view',{...nonCashFlow, remaining_amount: securityDepDetail.remaining_amount}, 'nonCashTxCrud')} />
                            ) : null
                          }
                         </div>

                          {!securityDepDetail.security_deposit_transactions.filter(tx => tx.type_of_transaction == 2).length?
                            <p style={{padding: '40px 0', textAlign: 'center', fontSize: '14px'}}>No Non-Cash Transactions Added Yet!</p>:null
                          }

                      </div>
                      :null
                    }
                  </div>
                </SecurityDepositWrapper>
                ) : null
              }
              {!securityDepositLoader && !securityDepositList.length ?
               <NoRecordFound description="Get Started by clicking '+ Add Security Deposit' button above" />:null
              }
            </div>
            <EditSidebar width="560px" right="-560px"
              title={
                securityDepositCrud.type != '' ?
                (securityDepositCrud.type == 'add') ? 'Add Security Deposit':
                (securityDepositCrud.type == 'edit') ? 'Edit Security Deposit': 'View Security Deposit' : ''
                }
              editSideBar={securityDepositCrud.flag}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'securityDepositCrud')}
              >
              {
                Object.keys(securityDepositCrud.data).length && securityDepositCrud.flag ?
                <div>
                  <FormModeType
                    auditLog={true}
                    auditLogMode= {()=> {this.props.toggleAuditlogFn('contracts', 'ctsecuritydeposits', 1, securityDepositCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Security Deposit', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Security Deposit', item_id:securityDepositCrud.data.id})}}
                    type={securityDepositCrud.type}
                    changeViewMode={()=>this.props.changeViewMode(securityDepositCrud.type, 'securityDepositCrud')}
                    editAble = {getLocalStorageInfo().user.permission['contracts']['security_deposit'].indexOf('U') != -1 && securityDepositCrud.data.is_editable ? true:false}
                  />
                  <AddSecurityDeposit
                   securityDepositMLData = {securityDepositMLData}
                    type={securityDepositCrud.type}
                    techConstants={techConstants}
                    securityDepositObj={securityDepositCrud.data}
                    addEditSecurityDeposit={(data,type)=>this.props.addEditSecurityDeposit(data, type, 'Security Deposit', assetViewId)}
                    toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'securityDepositCrud')}
                  />
                </div>:null
              }
            </EditSidebar>
            <EditSidebar width="560px" right="-560px"
              title={
                cashTxCrud.type != '' ?
                (cashTxCrud.type == 'add') ? 'Add Cash Transaction':
                (cashTxCrud.type == 'edit') ? 'Edit Cash Transaction': 'View Cash Transaction' : ''
                }
              editSideBar={cashTxCrud.flag}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'cashTxCrud')}
              >
              {
                Object.keys(cashTxCrud.data).length && cashTxCrud.flag ?
                <div>
                  <FormModeType
                    auditLog={true}
                    auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctsecuritydeposittransactions', 1, cashTxCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Security Deposit', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Cash Traction', item_id:cashTxCrud.data.id})}}
                    type={cashTxCrud.type}
                    changeViewMode={()=>this.props.changeViewMode(cashTxCrud.type, 'cashTxCrud')}
                    editAble = {getLocalStorageInfo().user.permission['contracts']['security_deposit'].indexOf('U') != -1 && cashTxCrud.data.is_editable ? true:false}
                  />

                  <AddCashTx
                    type={cashTxCrud.type}
                    assetViewId={assetViewId}
                    securityDepositId={this.state.securityDepositeId}
                    cashTxObj={cashTxCrud.data}
                    addEditCashTx={(data,type)=>this.props.addEditCashTx(data, type, 'Cash Transaction', assetViewId)}
                    toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'cashTxCrud')}
                  />
                </div>:null
              }
            </EditSidebar>
            <EditSidebar width="560px" right="-560px"
              title={
                nonCashTxCrud.type != '' ?
                (nonCashTxCrud.type == 'add') ? 'Add Non Cash Transaction':
                (nonCashTxCrud.type == 'edit') ? 'Edit Non Cash Transaction': 'View Non Cash Transaction' : ''
                }
              editSideBar={nonCashTxCrud.flag}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'nonCashTxCrud')}
              >
              {
                Object.keys(nonCashTxCrud.data).length && nonCashTxCrud.flag ?
                <div>
                  <FormModeType
                    auditLog={true}
                    auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctsecuritydeposittransactions', 1, nonCashTxCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Security Deposit', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Non Cash Transaction', item_id:nonCashTxCrud.data.id})}}
                    type={nonCashTxCrud.type}
                    changeViewMode={()=>this.props.changeViewMode(nonCashTxCrud.type, 'nonCashTxCrud')}
                    editAble = {getLocalStorageInfo().user.permission['contracts']['security_deposit'].indexOf('U') != -1 && nonCashTxCrud.data.is_editable ? true:false}
                  />
                  <AddNonCashTx
                    type={nonCashTxCrud.type}
                    assetViewId={assetViewId}
                    securityDepositId={this.state.securityDepositeId}
                    nonCashTxObj={nonCashTxCrud.data}
                    addEditCashTx={(data,type)=>this.props.addEditCashTx(data, type, 'Non Cash Transaction', assetViewId)}
                    toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'nonCashTxCrud')}
                  />
                </div>:null
              }
            </EditSidebar>
            <EditSidebar width="560px" right="-560px"
              title="Revision History"
              editSideBar={auditLogView.flag}
              toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
              >
              {Object.keys(auditLogView.data).length && auditLogView.flag ?
                <div>
                  <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
                    {auditLogView.type == "add" ?
                      auditLogView.data.map((item, index) =>
                      <AddDeleteAudit auditLogView={item} key={index}/>
                    ) :
                      auditLogView.data.map((item, index) =>
                      <UpdateAuditLog auditLogView={item} key={index}/>
                    )
                  }
                </div>
              </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>

        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  securityDepositList : state.SecurityDepositsReducer.securityDepositList,
  securityDepositCrud: state.SecurityDepositsReducer.securityDepositCrud,
  cashTxCrud: state.SecurityDepositsReducer.cashTxCrud,
  nonCashTxCrud: state.SecurityDepositsReducer.nonCashTxCrud,
  securityDepositLoader: state.SecurityDepositsReducer.securityDepositLoader,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  securityDepositMLData: state.SecurityDepositsReducer.securityDepositMLData,
  assetViewId: state.userAssetReducer.assetViewId
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchSecurityDepositeList : () =>  dispatch(securityDpListAc(ownProps.params)),
    addEditSecurityDeposit: (data, type, module, assetViewId) => dispatch(addEditSecurityDpAc(ownProps.params, data, type, module, assetViewId)),
    addEditCashTx: (data, type, module, assetViewId) => dispatch(addEditCashTxAc(ownProps.params, data, type, module, assetViewId)),
    securityDepositCrudFn: (type, id, actionType) => dispatch(securityDepositCrudFnAc(ownProps.params, type, id, actionType)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    fetchsecuritydepositeMlAc : () => dispatch(securitydepositeMlAc(ownProps.params)),
    toggleEditBar: (data, actionType) => dispatch({
      type: SECURITY_DEPOSIT_CRUD,
      actionType: actionType,
      payload: data
    }),
    changeViewMode: (type, actionType) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      actionType: actionType,
      payload: type
    }),
    exportContract : (basicInfo) => dispatch(sExportContractsAc(basicInfo)),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    toggleAuditlogFn: (application, content_type, action, objectId, type, paramType, paramKey) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramType, paramKey)),
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(SecurityDeposit, ['contracts', 'security_deposit' ,'R'])));
