import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, browserHistory } from 'react-router';
import * as actions from '../actions';
import { Row, Col, Modal, ModalHeader, ModalBody, Input, Button } from 'reactstrap';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { DataList, FileInfo } from '../components';
import { ShortHead } from '../../../contracts/Elements';
import { RecordsSideBar, FileNavigation } from '../../Elements';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import Switch from '../../../../shared/Switch';
import { assetsType } from '../../../../constants';
import '../../assets/styles/records_a.scss';

class FileList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ModalFlag: false,
      isChecked: false,
      recordPreview: false
    }
    this.shareRecord = this.shareRecord.bind(this);
    this.previewRecord = this.previewRecord.bind(this);
    this.toggleFolderInfo = this.toggleFolderInfo.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  shareRecord(){
    this.setState({
      ModalFlag: true
    });
  }
  toggleFolderInfo(){
    this.setState({
      recordPreview: false
    });
  }

  previewRecord(){
    this.setState({
      recordPreview: !this.state.recordPreview
    });
  }

  toggle(){
    this.setState(prevState => ({
      ...prevState,
      ModalFlag: !prevState.ModalFlag
    }));
  }
  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.fetchTechConstant({constant_types:['lease_type_accounting','lease_type','insurance_type','lease_option_type','interval','escalation_interval','mr_rate_range_condition','party_role','security_deposit_type']});
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dataRoom/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }

  changeShareStatus(evt){
    this.setState({
      isChecked: !this.state.isChecked
    });
  }

  render(){
    const { ctrShortInfo } = this.props;

    return(
      <div>
        { Object.keys(ctrShortInfo).length ?
          <ShortHead headingText="Dashboard" changeMsn={this.changeMsn} contrAssetBasicInfo={ctrShortInfo} />:null
        }
        <FileNavigation />
        {
          Object.keys(ctrShortInfo).length ?
            <RecordsSideBar ctrShortInfo={ctrShortInfo}/>:null
        }
        <div className="records-list-wrap">
          <div className="list-wrapper " style={ this.state.recordPreview ? {marginRight:'550px'}:{marginRight:'0px'}}>
            <DataList menuId="menu1" type="file" shareRecord={this.shareRecord} previewRecord={this.previewRecord} />
            <DataList menuId="menu2" type="file" shareRecord={this.shareRecord} previewRecord={this.previewRecord} />
            <DataList menuId="menu3" type="file" shareRecord={this.shareRecord} previewRecord={this.previewRecord} />
          </div>
        </div>
          <Modal isOpen={this.state.ModalFlag} size="lg" toggle={this.toggle} className="share-record-modal">
            <ModalHeader toggle={this.toggle}>
              Share "Test File"
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12" className="share-heading">
                  Sharing { this.state.isChecked == true ? `Enabled` : `Disabled`} <Switch isChecked={this.state.isChecked} onChange={(evt) => {this.changeShareStatus(evt)}}/>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Input type="text" disabled={ this.state.isChecked == true ? true : false} className="share-text" placeholder="https://"/>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  { this.state.isChecked == true ? <Button type="button" className="add-new share-button" color="primary">Copy Link</Button>:null}
                </Col>
              </Row>
            </ModalBody>
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileList))
