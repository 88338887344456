import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { checkString } from '../../../../formValidator';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class AddEditLavatory extends Component{
  constructor(props){
    super(props);
    this.state = {
      lavatory: {},
      error:{}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.lavatoryObj));
    if(this.props.type == 'edit' || this.props.type== 'view'){
      data = {
        ...data,
        aircraft: this.props.lavatoryObj.id,
        location : this.props.lavatoryObj.location.value,
        location_name: this.props.lavatoryObj.location.label
      }
    }
    this.setState({
      lavatory:data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      lavatory: {
        ...prevState.lavatory,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }
  addEditLavatory = (e) => {
    e.preventDefault();
    const validateNewInput = {
      name: checkString({value: this.state.lavatory.name, required: true, minLength: '', maxLength: '', message: 'Please Enter Name'}),
      location: checkString({value: this.state.lavatory.location, required: true, minLength: '', maxLength: '', message: 'Please Select Location'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditTechSummary(this.state.lavatory, this.props.type, 'lavatoryList', 'lavatoryCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { lavatory, error } = this.state;
    const { type, techConstants } = this.props;
    if(!Object.keys(lavatory).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditLavatory(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Name
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={lavatory.name}
                  type={type}
                  keyParam="name"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.name}</h6>
              </FieldGroup>

            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={lavatory.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Serial Number</FieldLabel>
                <FieldInput
                  value={lavatory.serial_number}
                  type={type}
                  keyParam="serial_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Location
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Location'
                  keyParam="location"
                  value={lavatory.location}
                  type={type}
                  options={techConstants.filter(item => item.type=="interior_location")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {lavatory.location_name}
                />
              <h6 className="error-msg">{error.location}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Manufacturer</FieldLabel>
                <FieldInput
                  value={lavatory.manufacturer}
                  type={type}
                  keyParam="manufacturer"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={lavatory.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { type !== 'view' ?
           <div className="submit-block">
           <input type="submit" className="primary-btn" value="Save Changes"/>
           <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
         </div> : null
        }
      </form>
    )
  }
}
