import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addAfAssembly } from '../../mntAddObject'
import { browserHistory } from 'react-router'
export const apuUpcomingEventsAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.APU_LOADER,
      payload: true
    });
    globalGetService('maintenance/asset-type/apu/asset/'+params.aircraft_slug+'/upcoming-events/', {})
    .then(response => {
      dispatch({
        type: actions.APU_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_EVENT_LIST,
          payload: response.data.data.upcoming_events
        });
      }
    });
  }
}
export const apuEventHistoryAc = (params, queryParam={}) => {
  return (dispatch) => {
    globalGetService('maintenance/asset-type/apu/asset/'+params.aircraft_slug+'/shopvisit/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_EVENT_HISTORY,
          payload: response.data.data
        });
      }
    });
  }
}

export const addEditApuShopVisitAc = (params, data, type, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.APU_LOADER,
      payload: true
    });
    if(type == 'add'){
      globalPostService('maintenance/asset-type/apu/asset/'+params.aircraft_slug+'/shopvisit/',data)
      .then(response => {
        dispatch({
          type: actions.APU_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APU_SHOP_VIST_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.APU_SHOP_VISIT_CRUD,
            payload: {type: '', data: {} , flag: false }
          });
          browserHistory.push(`/aircraft/${params.aircraft_slug}/maintenance/apu/view/${response.data.data.id}`)
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Major Assemblies APU`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Event`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('maintenance/asset-type/apu/asset/'+params.aircraft_slug+'/shopvisit/' + data.id + '/',data)
      .then(response => {
        dispatch({
          type: actions.APU_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APU_SHOP_VIST_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.APU_SHOP_VISIT_CRUD,
            payload: {type: '', data: response.data.data , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Major Assemblies APU`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Event`,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const apuShopVisitCrudAc = (params, type, id) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.APU_SHOP_VISIT_CRUD,
        payload: {type: type, data: addAfAssembly , flag: true }
      });
    }else{
      dispatch({
        type: actions.APU_LOADER,
        payload: true
      });
      globalGetService('maintenance/asset-type/apu/asset/'+params.aircraft_slug+'/shopvisit/' + params.id + '/',{})
      .then(response => {
        dispatch({
          type: actions.APU_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APU_SHOP_VISIT_CRUD,
            payload:{type: '', data:response.data.data , flag: false }
          });
        }
      })
    }
  }
}

export const apuSVStatusAc = (params, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.APU_LOADER,
      payload: true
    });
    globalPostService('maintenance/shopvisit/'+params.id+'/update-status/', data)
    .then(response => {
      dispatch({
        type: actions.APU_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_SHOP_VISIT_CRUD,
          payload: {type: '', data: response.data.data , flag: false }
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}

export const apuClaimsListAc = (params, queryParam={}) => {
  return (dispatch) => {
    globalGetService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_CLAIM_LIST,
          payload: response.data.data
        });
      }
    })
  }
}


export const addEditApuClaimAc = (params, data, type, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.APU_LOADER,
      payload: true
    });
    if(type == 'add'){
      let claim = data;
      claim = {
        ...data,
        shop_visit_id: params.id
      }
      globalPostService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/',claim)
      .then(response => {
        dispatch({
          type: actions.APU_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APU_CLAIM_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.APU_CLAIM_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Major Assemblies APU`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Claim`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/' + data.id + '/', data)
      .then(response => {
        dispatch({
          type: actions.APU_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APU_CLAIM_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.APU_CLAIM_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          dispatch({
            type: actions.APU_PAYOUT_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Major Assemblies APU`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Claim`,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const apuClaimInfoCrudAc = (params, type, id) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.APU_CLAIM_CRUD,
        payload: {type: type, data:{name: '', age:null} , flag: true }
      });
    }else{
      dispatch({
        type: actions.APU_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/induction-condition-items/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.APU_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APU_CLAIM_CRUD,
            payload: {type: type, data:response.data.data , flag: true }
          });
        }
      })
    }
  }
}

export const apuClaimStatusAc = (params, id, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.APU_LOADER,
      payload: true
    });
    globalPostService('maintenance/claim/'+id+'/update-status/', data)
    .then(response => {
      dispatch({
        type: actions.APU_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.APU_CLAIM_EDIT_SUCCESS,
          payload: response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
