import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { displayDateFormat, fieldDateFormat, backendDateFormat } from '../../../constants';
export default class FieldDateIndex extends Component{
  constructor(props){
    super(props);
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){
    const { type, value, keyParam, maxDate, minDate, index, style, popperPlacement } = this.props;
    if(type != 'view'){
      return(
        <DatePicker
          dateFormat = {fieldDateFormat}
          selected={ value &&  moment(value).isValid() ? moment(value): null }
          onChange={(e) => this.props.updateField(keyParam, moment(e).format(backendDateFormat),index)}
          showYearDropdown
          showMonthDropdown
          popperPlacement={popperPlacement ? popperPlacement : ''}
          showPopperArrow={true}
          useShortMonthInDropdown
          onFocus={() => this.handleFocus()}
          onBlur={() => this.handleBlur()}
          maxDate = {maxDate}
          minDate = {minDate}
          style={style}
        />
      )
    }else{
      return(

        <h6>{value ? moment(value).format(displayDateFormat):'--'}</h6>
      )
    }

  }
}
