import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, prettyNumberParamsL } from '../../../../constants';
export default class Obligation extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { item } = this.props;
    return (
      <tr onClick={this.props.viewObligation}>
        <td >
          <span>{item.obligation_type ? item.obligation_type.label : '--'}</span>
        </td>
        <td >
          <span>{item.details ? item.details : '--'}</span>
        </td>
        <td >
          <span>{item.value ? <NumericLabel params={prettyNumberParamsL}>{item.value}</NumericLabel> : '--'}</span>
        </td>
        <td >
          <span>
            {item.status ? item.status.label : '--'}
            { item.status && item.status.value == '0' && (getLocalStorageInfo().user.id == item.to_be_approved_by.id) ?
             <span style={{padingLeft: '4px'}} onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.changeStatus()}}>
              <img width="16" className="editIcon" id="obligation-status" src={imgStoragePath+"icon_editblock.png"} alt="img"/>
              <UncontrolledTooltip placement="right" target="obligation-status">
                Change Obligation Status
              </UncontrolledTooltip>
             </span>:null}
          </span>
        </td>
        <td >
          <span>{item.created_by ? item.created_by.name : '--'}</span>
        </td>
        <td >
          <span>{item.to_be_approved_by ? item.to_be_approved_by.name : '--'}</span>
        </td>
      </tr>
    )
  }
}
