import React, {Component} from 'react';
import { connect } from 'react-redux';
import {UncontrolledTooltip} from 'reactstrap';
import { Link, withRouter } from 'react-router';
import ContractSideLink from './ContractSideLink';
import { assetViewIdAc } from '../../../User/actionCreators';
import { getLocalStorageInfo, getCookie } from '../../../utils';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';
import {ToolTipHover} from '../../../shared'
import { imgStoragePath } from '../../../constants';
class ContractSideBar extends Component{
  constructor(props){
    super(props);
    this.state = {
      contMblSidebar: false
    }
  }
  componentDidMount(){
    if(this.props.type == 'shorter'){
      this.props.fetchAssetView(this.props.params.aircraft_slug);
    }
    this.props.fixedPositionFn('closeMaintenance',this.props.closeMaintenance);

  }

  ContractSideBarToggle = () => {
    this.setState(prevState => ({
      ...prevState,
      contMblSidebar: !this.state.contMblSidebar
    }));
  }

  closeMblSidebar = () => {
    this.setState(prevState => ({
      ...prevState,
      contMblSidebar: !this.state.contMblSidebar
    }));
  }

  render(){
    const { basicInfo, assetViewId, params } = this.props;
    const pathname = this.props.location.pathname;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    if(this.props.type == "shorter"){
      return (
        <div className="">
          <div className="mobile-bread-crumb hidden-lg">
            <Link className="bread-crumb" id="ham" onClick={() => this.ContractSideBarToggle()}>
              <span></span>
              <span className="cont-breadcrumb-bar"></span>
              <span></span>
            </Link>
          </div>
          <div style={this.props.style} className= {this.state.contMblSidebar ? "mr-calc-sidebar contract-side-bar sidebar-active " : "mr-calc-sidebar contract-side-bar" }>
            <div className="hidden-lg">
              <Link className="mbl-cross" onClick = {this.closeMblSidebar}>
               <img src={imgStoragePath+"cross_w_icn.png"} alt="img"/>
              </Link>
            </div>
            {
            Object.keys(assetViewId).length && Object.keys(assetViewId.contract).length ?
            <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0'  }}>
              {
                getLocalStorageInfo().user.user_role != 'lessee' ?
                <ContractSideLink to={"/" +params.type+ "/" +params.aircraft_slug+ "/contract/dashboard/"} title="Dashboard" />:null
              }
              <span id="UtilizationToolTip" style={{width:"100%", height:"100%"}} aria-hidden="true">
              <ContractSideLink classProp = { pathname.includes('contract/utilization') ? 'active' : '' } to={"/" +params.type+ "/" +params.aircraft_slug+ "/contract/utilization"} title="Utilization" />
              <ToolTipHover placement="top" tagetId="UtilizationToolTip"  >
              Update and monitor asset utilization trend
              </ToolTipHover>
              </span>



              {
                getLocalStorageInfo().user.user_role != 'lessee' ?
                <ContractSideLink  classProp = { (pathname.includes('/invoice/view/') || pathname.includes('/invoice/edit/'))? 'active' : '' } to={"/" +params.type+ "/" +params.aircraft_slug+ "/contract/invoice/"} title="Invoices" />:null
              }
              {
                getLocalStorageInfo().user.user_role != 'lessee' ?
                <span id="cashFlowTooltip">
                <ContractSideLink to={"/" +params.type+ "/" +params.aircraft_slug+ "/contract/cash-flow"} title="Cash Flow" />
                <UncontrolledTooltip  placement="bottom" target="cashFlowTooltip">
                Track lease related cash inflows and outflows
                </UncontrolledTooltip>
                </span>
                :null
              }
              {
                getLocalStorageInfo().user.user_role != 'lessee' && ['engine', 'aircraft'].includes(params.type) ?
                <span id="mrAccrualsToolTip">
                <ContractSideLink to={"/" +params.type+ "/" +params.aircraft_slug+ "/contract/mr-accrual"} title="MR Accruals" />
                <UncontrolledTooltip placement="bottom" target="mrAccrualsToolTip">
                Monitor reserves accruals or simulate maintenance event scenarios
                </UncontrolledTooltip>

                </span>:null
              }
              {
                getLocalStorageInfo().user.user_role != 'lessee' && ['engine', 'aircraft'].includes(params.type) ?
                <ContractSideLink to={"/" +params.type+ "/" +params.aircraft_slug+ "/contract/half-life-maintenance-adjustments"} title="Half Life Maintenance Adjustments" />:null
              }
              {
                getLocalStorageInfo().user.user_role != 'lessee' ?
                <ContractSideLink to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} title="Contracts History" />:null
              }

            </ul>:<ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0'  }}><ContractSideLink to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} title="Contracts History" /></ul>
          }
           </div>
        </div>

      )
    }else{
      return (
        <div style={this.props.style}>
         <div className="mobile-bread-crumb hidden-lg ">
            <Link className="bread-crumb" id="ham" onClick={() => this.ContractSideBarToggle()}>
              <span></span>
              <span className="cont-breadcrumb-bar"></span>
              <span></span>
            </Link>
          </div>
          <div style={this.props.style} className= {this.state.contMblSidebar ? "mr-calc-sidebar contract-side-bar sidebar-active " : "mr-calc-sidebar contract-side-bar" }>
            <div className="hidden-lg">
              <Link className="mbl-cross" onClick = {this.closeMblSidebar}>
                 <img src={imgStoragePath+"cross_w_icn.png"} alt="img"/>
              </Link>
            </div>
            <div className="mr-calc-heading">
                <Link className="ct-back-icon"  to={"/" + params.type + "/" + params.aircraft_slug + "/contracts"} onClick={this.closeMblSidebar}>
                  <img className="back-icon" src={imgStoragePath+"contract-back.png"} alt="img"/>
                </Link>

            </div>
              <div>
                <h5>Key Information</h5>
                <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0'  }}>
                  <ContractSideLink  to={"/"+params.type+"/"+params.aircraft_slug + "/contract/list/"+params.contract_slug} title="Headlines" />
                  {
                   params.type == 'aircraft' && getLocalStorageInfo().user.permission.maintenance && !getLocalStorageInfo().user.permission.maintenance.is_saas && getLocalStorageInfo().user.permission.contracts && !getLocalStorageInfo().user.permission.contracts.is_saas ?
                      <ContractSideLink classProp = { pathname.includes('extract-file/'+this.props.dataId) ? 'active' : '' } to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/extract-file-list"} title="Automated Content Extractor " />
                    :null
                  }
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/working-group"} title="Working Group" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/parties"} title="Parties" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/sub-lease"} title="Subleases" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/security-deposit"} title="Security Deposit" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/insurance"} title="Insurance" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/cape-town-convention"} title="Cape Town Convention" />

                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/lease-option"} title="EOL Options" />
                  <ContractSideLink  to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/obligations"} title="Obligations" />
                </ul>
              </div>

              <div>
                <h5>Transition</h5>
                <ul className="list-unstyled">
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/induction-condition"} title="Induction Conditions" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/delivery-condition"} title="Delivery Conditions" />
                  <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/return-condition"} title="Return Conditions" />
                </ul>
              </div>

              <div>
              <h5>Finance</h5>
              <ul className="list-unstyled">
                <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/mr-rate"} title="MR Rates" />
                <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/lease-rental-condition"} title="Lease Rental Rates" />
                <ContractSideLink to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/qualified-maintenance"} title="Qualified Maintenance Events" />
                <ContractSideLink  to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/finance-detail"} title="Financing Details" />
              </ul>
            </div>
            </div>
         </div>

      )
    }
  }
}
const mapStateToProps = state => ({
  basicInfo: state.ContractListReducer.basicMetaInfo,
  assetViewId: state.userAssetReducer.assetViewId,
  closeMaintenance: state.sharedReducers.closeMaintenance,

});
const mapDispatchToProps =  (dispatch, ownProps ) => {
  return{
    fetchAssetView: (id, application) => dispatch(assetViewIdAc(id, ownProps.params)),
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractSideBar))
