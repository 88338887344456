import React, { Component } from 'react';
import { Row, Col, Collapse, CardBody, Card } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { showCurrencyFormat } from '../../../../utils';
export default class SecurityDepositWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { collapse: true };
  }
  render() {
    const { securityDepDetail } = this.props;
    return (
      <div className="collpase-wrapper security-deposit-wrapper">
          <div className="para" onClick = {this.props.viewDeposit}>
            <Row className="security-heading">
              <Col md="3">
                <p className="para security-type">Type of Security Deposit</p>
                <span>{ securityDepDetail.security_deposit_type && securityDepDetail.security_deposit_type.label ? securityDepDetail.security_deposit_type.label : '--' }</span>
              </Col>
              <Col md="2">
                <p>
                  <NumericLabel params={showCurrencyFormat(securityDepDetail.currency)}>
                   {
                     securityDepDetail.security_deposit_transactions.reduce((total, next) => total + next.amount, 0 )
                   }
                  </NumericLabel>
                  <br/><span style={{padding: '0 2px'}}>/</span>
                 {securityDepDetail.amount ?
                  <NumericLabel params={showCurrencyFormat(securityDepDetail.currency)}>{securityDepDetail.amount}</NumericLabel>
                  : '--' }
                </p>
              </Col>
              <Col md="3">
                <h6 className="caption-ui">
                   Security Against
                </h6>
                <p className="para-ui" style={{padding: '0 10px 0 0'}}>
                { securityDepDetail.security_against ? securityDepDetail.security_against : '--' }
                </p>
              </Col>
              <Col md="2">
                <h6 className="caption-ui">
                   Security Deposit Refundable?
                </h6>
                <p className="para-ui">
                { securityDepDetail.is_refundable ? 'Yes' : 'No' }
                </p>
              </Col>
              <Col md="2">
              <h6 className="caption-ui">
                  Interest Refundable?
                </h6>
                <p className="para-ui">
                { securityDepDetail.is_interest_refundable ? 'Yes' : 'No'}
                </p>
              </Col>
            </Row>
          </div>
          <Collapse isOpen={this.state.collapse}>
            <Card>
              <CardBody>
                  {this.props.children}
              </CardBody>
            </Card>
          </Collapse>
      </div>
    )
  }
}
