import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { TextInputField, GetInputField } from'../../Elements';
import { checkString, checkEmail } from '../../../../formValidator'
import { displayDateTimeFormat,imgStoragePath } from '../../../../constants';
import moment from 'moment';

export default class CommentsEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      contact:{},
      error: {},
      comment:'',
    }
  }
  updateComments = (value) => {
   this.setState({
       comment:value,
   });
    
  }
  
  addEditContact = (e) => {
    e.preventDefault();
    this.props.updateComments(this.state.comment);
    this.setState({
        comment:'',
    });
  }
  render(){
    const {comments } = this.props;
    return(
      <div>
        <form onSubmit={(e) => this.addEditContact(e)}>
          <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
          {comments && comments.length ? comments.map((data,index) => 
           <Row style={{padding:'5px',borderBottom:'1px solid #eee'}}>
              <Col md="2">
              <img className="profile-pic" src={data.user&&data.user.profile_pic?data.user.profile_pic:imgStoragePath+"user.png"} />
              </Col>
              <Col md="8">
                      <div >
                        <h4 style={{fontSize:'16px'}}>{data.user&&data.user.name?data.user.name:'--'}{data.user&&data.user.designation?' - '+data.user.designation:''}</h4>
                        <p style={{fontSize:'14px'}}>{data.comment?data.comment:'--'}</p>
                        <p style={{fontSize:'12px'}}>{data.updated_at?moment(data.updated_at).format(displayDateTimeFormat):'--'}</p>
                      </div>
              </Col>
              <Col md="2">
              <img onClick={()=>this.props.toggleDelModal(data.id)} src={imgStoragePath + "delete_red.png"} style={{width:'15px',marginBottom:'10px'}} />
              </Col>
           </Row>):null
             }

            <div style={{position: 'absolute',bottom:'65px',left:'0px',width:'100%',backgroundColor:'#ffffff'}}> 
            <div style={{margin:'5px'}}>
            {
                  GetInputField(
                    'textarea',
                    null,
                    { type: 'edit',
                      value: this.state.comment,
                      placeholder:'Post Comments',
                      updateForm: (value) => this.updateComments(value)
                    }
                  )
                }
                 </div>
             </div>
          </div>
          
            <div className="edit-sidebar-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="submit" 
                  type="submit" 
                  className="btn btn-primary">{'Post'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
                </li>
               </ul>
            </div>
          
        </form>
      </div>
    )
  }
}
