
import * as actions from '../actions';
const crudObj = {
  type:'',
  data:{},
  flag: false
};
export default function( state = {
  clients: [],
  operators: [],
  aircraftTypes: [],
  engineTypes: [],
  overviewTechInfo: {},
  engineList: [],
  engineDetails: {},
  apuList: [],
  assetFilter: {},
  avionicList: [],
  seatingList: [],
  equipmentList: [],
  maintenanceProgGrp: [],
  lavatoryList: [],
  lopaHistoryList:[],
  paxSeatList: [],
  galleyList: [],
  techSmryView: {
    flag: false,
    index: 0,
    data: {},
    listType : '',
    type:''
  },
  techSummaryLoader: true,
  lgList: [],
  existEsn: {},
  paginationInfo: {},
  techSpecsLoader: false,
  crewSeatCrud:crudObj,
  lavatoryCrud:crudObj,
  passengerSeatCrud:crudObj,
  equipmentCrud:crudObj,
  lopacrud:crudObj,
  galleyCrud:crudObj,
  avionicCrud:crudObj,
  propellerCrud:crudObj,
  llpCrud:{...crudObj,engineId:''},
  engineCrud:{...crudObj,index:0},
  apuCrud:{...crudObj,index:0},
  lgCrud:{...crudObj,index:0},
  llpToggleState: false,
  propellerList: [],
  assetTransferModal: false,
  techSpecApuLoader: false,
  assetTransferUsage: {
    available: 0,
    used: 0
  },
  apuDetail: {},
  remarksList: [],
  revisionHistoryList:[],
  remarksCrud: {
    flag: false,
    type: 'add',
    data:{
      remarks: ''
    }
  }
}, action){
  switch (action.type) {
    case actions.FORM_SUBMIT_LOADER:
      return {
        ...state,
        loginLoader: action.payload
      }
    case actions.REMARKS_LIST:
      return {
        ...state,
        remarksList: action.payload
      }
    case actions.REMARKS_CRUD:
      return {
        ...state,
        remarksCrud: action.payload
      }
    case actions.APU_DETAIL:
      return {
        ...state,
        apuDetail: action.payload,
        assetId:action.payload.id
      }
    case actions.ASSET_TRANSFER_MODAL:
      return {
        ...state,
        assetTransferModal: action.payload
      }
    case actions.ASSET_TRANSFER_USAGE:
      return {
        ...state,
        assetTransferUsage: action.payload
      }
    case actions.PROPELLERS_LIST:
      return {
        ...state,
        propellerList: action.payload
      }
    case actions.OVERVIEW_TECH_INFO:
      return {
        ...state,
        overviewTechInfo: action.payload,
        assetID:action.payload.id
      }
    case actions.TECH_SMRY_LIST:
      return {
        ...state,
        [action.payload.type] : action.payload.res
      }
    case actions.TECH_SMRY_ADD_SUCCESS:
      let listData = state[action.payload.type];
      return {
        ...state,
        [action.payload.viewType]: {
          flag: false,
          data: {},
          listType: ''
        },
        [action.payload.type] : [action.payload.res, ...listData]
      }
    case actions.CLOSE_AVIONIC_SIDE_BAR:
      return {
        ...state,
        [action.viewType]: action.payload,
      }
    case actions.LLP_VIEW_INFO:
      return {
        ...state,
        llpCrud: {
          ...state.llpCrud,
          type:action.payload.type,
          flag: action.payload.flag,
          data: action.payload.res,
          engineId: action.payload.engineId
        },
      }
    case actions.LLP_EDIT_SUCCESS:
      return {
        ...state,
        llpCrud: {
          flag: false,
          data: {}
        },
        // engineList: state.engineList.map(engine => engine.id != action.payload.engine.id ? engine : { ...engine, llp: engine.llp.map(llp => llp.id == action.payload.id ? action.payload:llp)}),
         engineList: state.engineList.map(engine => engine.id != action.payload.engineId ? engine : { ...engine, llp: action.payload.data }),

      }
    case actions.AIRCRAFT_MAIN_PROG:
      return {
        ...state,
        maintenanceProgGrp: action.payload
      }
    case actions.TECHSPEC_LOADER:
      return {
        ...state,
        techSpecsLoader: action.payload
      }
    case actions.TECH_SMRY_CRUD:
      return {
        ...state,
        [action.actionType]:action.payload
      }
    case actions.TOGGLE_TECH_SMRY_VIEW:
      return {
        ...state,
        [action.actionType]:{
          ...state[action.actionType],
          type:(action.payload.type == 'view' ? 'edit' : 'view')
        }
      }
    case actions.TECH_SMRY_EDIT_SUCCESS:
      let techSmryList = state[action.payload.type];
      return {
        ...state,
        [action.payload.viewType]: {
          flag: false,
          data: {},
          listType: ''
        },
        [action.payload.type]: techSmryList.map(item => item.id == action.payload.res.id ? action.payload.res : item)
      }
      case actions.LLP_UPDATE_FIELD_TOGGLE:
        return {
          ...state,
          llpToggleState: action.payload
        }
      case actions.UPDATE_IMPORT_LLP:
        if(action.assetType == 'engine'){
          return {
            ...state,
            engineDetails: {...state.engineDetails, [action.llpType]: action.payload}
          }
        }else{
          return {
            ...state,
            engineList: state.engineList.map(engine => engine.slug == action.engineSlug ? {...engine, [action.llpType]: action.payload} : engine)
          }
        }
      case actions.UPDATE_ENGINE_DETAILS:
        return {
          ...state,
          engineDetails: action.payload,
          engineCrud:  {
            flag: false,
            data: {},
            listType: ''
          },
        }
      case actions.IMPORT_APU_LLP:
        if(action.assetType == 'apu'){
          return {
            ...state,
            apuDetail:{...state.apuDetail, llps:action.payload}
          }
        }else {
          return {
            ...state,
            apuList:state.apuList.map(apu => apu.slug == action.apuSlug ? {...apu,llps:action.payload} : apu)
          }
        }

      case actions.IMPORT_LG_LLP:
        if(action.assetType == 'lg'){
            return {
              ...state,
              apuDetail:{...state.apuDetail, llps:action.payload}
            }
          }else{
            return {
              ...state,
              apuList:state.lgList.map(lg => lg.slug == action.lgSlug ? {...lg, llps:action.payload}: lg)
            }
          }


      case actions.IMPORT_PROPELLER_LLP:
        if(action.assetType == 'propeller'){
            return {
              ...state,
              apuDetail:{...state.apuDetail, llps:action.payload}
            }
          }else {
            return {
              ...state,
              propellerList:state.propellerList.map(propeller => propeller.slug == action.propellerSlug ? {...propeller, llps:action.payload} : propeller)
            }
          }
      case actions.TECHSPEC_APU_LOADER:
        return {
          ...state,
          techSpecApuLoader:action.payload
        }
      case actions.REVISION_HISTORY_TECHSPEC:
        return {
          ...state,
          revisionHistoryList:action.payload
        }
    default:
      return state;
  }
}
