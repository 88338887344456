import React, {Component} from 'react';
import { Link, withRouter, browserHistory } from 'react-router';
import {Row, Col, Badge, UncontrolledTooltip} from 'reactstrap';
import '../assets/newsfeed.scss';
import { globalGetService } from '../../../globalServices'
import moment from 'moment';
class BlogShow extends Component {
    constructor (props) {
        super(props)
        this.state = {
          newsFeedDetail: {}
        }
    }
    componentDidMount () {
      globalGetService(`console/news-feed/${this.props.params.slug}/`)
           .then((response)=> {
             if(response.data.statusCode == 200){
               this.setState({
                 newsFeedDetail: response.data.data
               })
             }
           })
    }
    BlogShowPrev = () => {
        browserHistory.push(`/newsfeed/${this.state.newsFeedDetail.previous_news.slug}`)
        window.location.reload(false)
    }
    BlogShowNext = () => {
        browserHistory.push(`/newsfeed/${this.state.newsFeedDetail.next_news.slug}`)
        window.location.reload(false)
    }
    handleTag = (e, tag) => {
      browserHistory.push(`/newsfeed?tag=${tag.id}`)
    }

    render() {
       const {  newsFeedDetail  } = this.state
        return (
            <div>
                <section className="banner" style={{color:"#fff"}}>
                <div class="breadcrumb-section clients-breadscrumb" style={{position:"relative", top: "90px",left: "179px",fontSize: "13px", width:"1000px"}}  >
						 	<div class="breadcrumb-row">
								<ul class="list-inline">
									<li className="list-inline-item">
                                     <Link to="/newsfeed" style={{color: "#fff"}} >News Feed</Link>
									</li>
									<li className="list-inline-item">
									 <span class="divider"> / </span>
									</li >
									<li className="list-inline-item" style={{width:"800px"}}>
                                    <span class="current-page-title">{newsFeedDetail.title? newsFeedDetail.title.length>100?

                                     <span  href="#" id="displayTitle" style={{cursor:"pointer"}} >{newsFeedDetail.title.slice(0,100)+"..."}
                                     <UncontrolledTooltip placement="top" target="displayTitle">
                                       {newsFeedDetail.title}
                                     </UncontrolledTooltip>
                                     </span>
                                    :newsFeedDetail.title :""}</span>
								 </li>
							 </ul>
							</div>
						</div>
                </section>
                <div className="report-export-lists" style={{marginLeft:"70px", paddingTop:"30px"}}>
                <div>
                <img src={newsFeedDetail.cover_image} alt="image" className="thumbnail"/>
                <Row style={{marginTop: "227px"}}>

                <Col md={{ size: 9, offset: 1 }} style={{marginLeft:"165px"}}>
                    <span style={{fontSize:"14px"}} >{newsFeedDetail && newsFeedDetail.category ? newsFeedDetail.category.name:""}, {moment(newsFeedDetail.published_date).format('MMM DD, YYYY') }</span>
                    <ul className="list list-inline">
                        {newsFeedDetail && newsFeedDetail.tag ?  newsFeedDetail.tag.map((tag, index)=> {
                            return <li className="list-inline-item" key={index} style={{paddingRight:"7px"}} ><Badge style={{padding:"6px", cursor:"pointer"}} onClick={(e)=>this.handleTag(e,tag)}>{tag.name}</Badge></li>
                        }): null
                    }
                    </ul>
                    <div className="blog-content" dangerouslySetInnerHTML={{__html: newsFeedDetail.content?newsFeedDetail.content:''}} >
                    </div>
                    </Col>

                </Row>
                </div>
                </div>
                <Row style={{marginLeft:"100px"}}>
                <Col md={{ size: 9, offset: 1 }} style={{marginBottom:"50px"}}>
                 {newsFeedDetail && newsFeedDetail.previous_news ?<Link onClick={this.BlogShowPrev}style={{color:"#007bff", position:"relative", top:"-2px"}} ><img src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/back_icon.png" style={{height:"16px"}} alt="back"/>{newsFeedDetail.previous_news.title ? newsFeedDetail.previous_news.title.length >60 ?newsFeedDetail.previous_news.title.slice(0,60)+"...":newsFeedDetail.previous_news.title:''}</Link>:""}
                {newsFeedDetail && newsFeedDetail.next_news  ? <Link onClick={this.BlogShowNext} style={{float:"right", color:"#007bff"}}>{newsFeedDetail.next_news.title ? newsFeedDetail.next_news.title.length > 60?newsFeedDetail.next_news.title.slice(0,60)+'...':newsFeedDetail.next_news.title:''}<img src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/back_icon.png" style={{transform: "rotate(180deg)", height:"16px"}} alt="back"/> </Link>:"" }

                </Col>
                </Row>

            </div>
        )
    }
}


  export default withRouter(BlogShow);
