
import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { checkString, checkIntNumber } from '../../../../formValidator';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class AddEditPassengerSeat extends Component{
  constructor(props){
    super(props);
    this.state = {
      passengerSeat: {},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.passengerSeatObj));
    if(this.props.type == 'edit' || this.props.type == 'view'){
      data = {
        ...data,
        aircraft: this.props.passengerSeatObj.aircraft.id,
        convertable_or_fixed: this.props.passengerSeatObj.convertable_or_fixed && this.props.passengerSeatObj.convertable_or_fixed.value ? this.props.passengerSeatObj.convertable_or_fixed.value : '',
        convertable_or_fixed_name: this.props.passengerSeatObj.convertable_or_fixed && this.props.passengerSeatObj.convertable_or_fixed.label ? this.props.passengerSeatObj.convertable_or_fixed.label : '',
        seat_type: this.props.passengerSeatObj.seat_type.value,
        seat_type_name: this.props.passengerSeatObj.seat_type.label
      }
    }
    this.setState({
      passengerSeat:data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      passengerSeat: {
        ...prevState.passengerSeat,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }
  addEditPassengerSeat = (e) => {
    e.preventDefault();
    const validateNewInput = {
      seat_type: checkString({value: this.state.passengerSeat.seat_type, required: true, minLength: '', maxLength: '', message: 'Please select Seat type'}),
      no_of_seats: checkIntNumber({value: this.state.passengerSeat.no_of_seats, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid No. of Seats'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditTechSummary(this.state.passengerSeat, this.props.type, 'paxSeatList', 'passengerSeatCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }
  render(){
    const { passengerSeat, error } = this.state;
    const { type, techConstants } = this.props;
    if(!Object.keys(passengerSeat).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditPassengerSeat(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Type
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Seat Type'
                  keyParam="seat_type"
                  value={passengerSeat.seat_type}
                  type={type}
                  options={techConstants.filter(item => item.type=="pax_seat_type")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name={passengerSeat.seat_type_name}
                />
              <h6 className="error-msg">{error.seat_type}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  No. of Seats
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={passengerSeat.no_of_seats}
                  type={type}
                  keyParam="no_of_seats"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.no_of_seats}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Model</FieldLabel>
                <FieldInput
                  value={passengerSeat.model}
                  type={type}
                  keyParam="model"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={passengerSeat.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Convertable Or Fixed</FieldLabel>
                <FieldSelect
                  placeholder='Select Convertable Or Fixed'
                  keyParam="convertable_or_fixed"
                  value={passengerSeat.convertable_or_fixed}
                  type={type}
                  options={techConstants.filter(item => item.type=="pax_seating_convertable_or_fixed")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {passengerSeat.convertable_or_fixed_name}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Manufacturer</FieldLabel>
                <FieldInput
                  value={passengerSeat.manufacturer}
                  type={type}
                  keyParam="manufacturer"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={passengerSeat.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
