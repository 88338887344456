import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Plans } from '../../phase2/licenses/components';
import { plansListAc, addLicenceToCartAc, selectedPlansListAc } from '../../phase2/licenses/actionCreators';
import { imgStoragePath } from '../../constants';
class BuyLicenseModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            buyLicenseModal : false
        };
    }

    componentDidMount(){
        this.props.fetchPlans(this.props.planId);
        this.props.fetchSelectedPlans(this.props.planId);
    }

    render(){
        const { planDetail, selectedPlansDetail } = this.props;
        return(
            <div>
                <Modal id="buyLicenseModal" isOpen={this.props.isOpen} centered={true} toggle={this.props.toggle} size="lg" style={{ minWidth: '500px' }}>
                    <img className="close-btn" src={imgStoragePath+'black_close.png'}  onClick={() => this.props.toggle()} />
                    <ModalBody>
                        <Plans
                            planDetail={planDetail}
                            addLicenceToCart={this.props.addLicenceToCart}
                            modal={true}
                            selectedPlans={selectedPlansDetail.hasOwnProperty('license') && selectedPlansDetail.license.hasOwnProperty('selected_plans') ? selectedPlansDetail.license.selected_plans : []}
                        />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    planDetail: state.LicenseReducer.plansList,
    selectedPlansDetail : state.LicenseReducer.selectedPlansList,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        fetchPlans : (licenseId) => dispatch(plansListAc(licenseId)),
        fetchSelectedPlans : (licenseId) => dispatch(selectedPlansListAc(licenseId)),
        addLicenceToCart: (licenceId, quantity) => dispatch(addLicenceToCartAc(licenceId, quantity))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BuyLicenseModal);
