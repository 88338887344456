import React from 'react';
import { imgStoragePath } from '../../../constants';
const EditFormBar = (props) => {
  return(
    <div className="application-edit-side-bar">
      <div className="edit-bar-content-blk technical-edit-sidebar" style={props.style}>
        <div className="top-content" id="sidebar-header">
          {props.titleContent}
          {props.path?
          <p>{props.path}</p>:null
          }
          <img className="close-sidebar" src={imgStoragePath+"black_close.png"} alt="img" width="12" onClick={props.toggleEditSideBar}/>
          {props.formType}
        </div>
        <div className="body-content">
          {props.children}
        </div>
      </div>
      { props.isOpen ?
        <div className="overlays"></div>:null
      }
    </div>
  )
}
export default EditFormBar;
