import moment from 'moment';
export const validGenFinanceRpt = (data) => {
  return validGenFinanceRptError(validGenFinanceRptData(data))
}
const numberRegx = /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/;
const intRegx = /^[-+]?[1-9]\d*$/;
const regex = /^[0-9]*$/;
function validGenFinanceRptData(data){
  return {
    aircraftType: (checkMetricsInfo(data.metricsInfo) || data.airframeAndGen.aircraftType != ''),
    dateOfManufacture: (checkMetricsInfo(data.metricsInfo) || moment(data.airframeAndGen.dateOfManufacture).isValid()),
    maintenanceProgram: ( !(data.metricsInfo.indexOf('airframe') != -1) || data.airframeAndGen.maintenanceProgram != ''),
    tsn: (checkMetricsInfo(data.metricsInfo) || numberRegx.test(data.airframeAndGen.tsn)),
    csn: (checkMetricsInfo(data.metricsInfo) || (data.airframeAndGen.csn !='' && regex.test(data.airframeAndGen.csn)) || data.airframeAndGen.csn===0),
    utlizationRatioAir: (checkMetricsInfo(data.metricsInfo) ||  parseFloat(data.airframeAndGen.hourlyUtlization/data.airframeAndGen.cycleUtlization) > 0.5 ),
    hourlyUtlization: (checkMetricsInfo(data.metricsInfo) || numberRegx.test(data.airframeAndGen.hourlyUtlization)),
    cycleUtlization: (checkMetricsInfo(data.metricsInfo) || numberRegx.test(data.airframeAndGen.cycleUtlization)),
    engineType: (!(data.metricsInfo.indexOf('engine') != -1) || data.engine.engineType != ''),
    noOfEngine: (!(data.metricsInfo.indexOf('engine') != -1) || data.engine.noOfEngine != ''),
    regionOfOperation: (!(data.metricsInfo.indexOf('engine') != -1) || data.engine.regionOfOperation != ''),
  }
}

function validGenFinanceRptError(val){
  return {
    aircraftType: val.aircraftType ? '': 'Aircraft Type is Required',
    dateOfManufacture: val.dateOfManufacture ? '': 'Date of Manufacture is Required' ,
    maintenanceProgram: val.maintenanceProgram ? '': 'Maintenance Program is Required',
    tsn: val.tsn ? '': 'TSN should be valid',
    csn: val.csn  ? '': 'CSN should be valid',
    utlizationRatioAir: val.utlizationRatioAir ? '': 'Ratio should be greater than 0.5',
    hourlyUtlization: val.hourlyUtlization ? '': 'Please Enter Valid Field',
    cycleUtlization: val.cycleUtlization ? '': 'Please Enter Valid Field',
    engineType: val.engineType ? '': 'Please Select the Engine Type',
    noOfEngine: val.noOfEngine ? '': 'Please Enter Valid Field',
    regionOfOperation: val.regionOfOperation ? '': 'Please Enter Valid Field',
  }
}
function checkMetricsInfo(metricsInfo){

  return !(metricsInfo.indexOf('airframe') != -1 || metricsInfo.indexOf('lg') != -1 || metricsInfo.indexOf('apu') != -1);
}
