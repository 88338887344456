import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col} from 'reactstrap';
import 'react-contexify/dist/ReactContexify.min.css';
import { getFileSize } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
class BucketList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { type, menuId, item, folderDirTree } = this.props;
    return(
      <div className="record-list" style={{marginLeft: '15px', marginRight: '15px'}}>
            <Row>
              <Col md="9">
                {
                  item.type == "file" ? <img src={this.props.fileIcons[item.extension]} width="14" /> : <img src={this.props.fileIcons[item.extension]} width="14" />
                }
                <span id={"info_" + menuId}>{item.name}</span>
                {item.share_enabled ? <span className="shared">Shared</span>:null}

              </Col>
              <Col md="2">
                <span>{item.size}</span>
              </Col>
              <Col md="1">
                <span onClick={this.props.deleteRecord}><i style={{color: 'rgb(255, 94, 94)'}} className="fa fa-trash"></i></span>
              </Col>
            </Row>
      </div>
    )
  }
}
export default withRouter(BucketList);
