import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { GEN_MR_REPORT } from '../actions';
class SelectUtilType extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return(
      <Row className="lg-entry-block">
        <Col md="12">
          <ul className="list-inline lg-radio-block flex-centered">
            <li className="list-inline-item">
              <label>
                <input type="checkbox" name={this.props.name}
                  checked={this.props.utilizationType == 'dateOfLastOverhual' ? true: false}
                  onChange={(e) => this.props.updateReviewMR(e.target.checked ? 'dateOfLastOverhual':'tsn', 'utilizationType', this.props.type)}
                />
              <span>Add Last Qualified Shop Visit Detail</span>
              </label>
            </li>
          </ul>
        </Col>
      </Row>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateReviewMR: (value, keyParam, type) => dispatch({
      type: GEN_MR_REPORT,
      payload: {value, keyParam, type}
    })
  }
}

export default connect(null, mapDispatchToProps)(SelectUtilType);
