import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { loadAssetViewAc, fetchDbStatsAc, fetchDbActivityAc, fetchLastActivityAc, exportActivityReportAc } from '../../Dashboard/actionCreators';
import { RecordsSideBar } from '../../Elements';
import { withRouter, browserHistory } from 'react-router';
import { mediaBaseUrl, assetsType, imgStoragePath  } from '../../../../constants';
import '../../assets/styles/records_d.scss';
import { ListLoader, NoRecordFound  } from '../../../../shared';
import { getFileSize, getLocalStorageInfo,localTimeFn  } from '../../../../utils';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import moment from 'moment';
import { trackActivity } from '../../../../utils/mixpanel';
import ActivityComponent from '../components/ActivityComponent';
class RecordDB extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false
    }
  }
  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.fetchLastActivity();
    this.props.fetchDbStats(this.props.params);
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dashboard/';
    }else{
      url = '/asset-view/'+this.props.params.type+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  activityRedirect = (activity) => {
    if([30,31,32,33,34].includes(activity.activity_type.id)){
      return
    }
    const category = {
      1: 'technical',
      2: 'discrepancies',
      3: 'contracts',
      4: 'inspections'
    }
    if([3, 6, 7, 10, 11, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26].includes(activity.activity_type.id)){
      if(activity.parent_folder.category == 0){
        browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/dataRoom/');
      }else if(activity.parent_folder.uuid){
          browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/data-room/' + category[activity.parent_folder.category] + '/'+activity.parent_folder.uuid);
      }else if(activity.folder.uuid){
          browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/data-room/' + category[activity.parent_folder.category] + '/'+activity.folder.uuid);
      }
    }else if([1,2,4,5].includes(activity.activity_type.id)){
      let actionParams =  {
        1: 'quetype=1&status=status',
        2: 'quetype=1&status=status',
        4: 'quetype=4&status=status',
        5: 'quetype=1&status=status'
      };
      browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/action-dock/?'+actionParams[activity.activity_type.id]);
    }else if([8].includes(activity.activity_type.id)){
      browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/recycle-bin/');
    }
    else{
      browserHistory.push('/'+assetsType[activity.asset.asset_type]+'/'+activity.asset.slug+'/records/report/?report='+activity.asset.uuid);
    }
  }
  render(){
    const { ctrShortInfo, recordsDbStats, recordsDbActivity,fileStatusLoader, dashboardLoader,lastActivityData, notificationOpen } = this.props;
    const userInfo = getLocalStorageInfo();
    let totalFiles = null;
    if(Object.keys(recordsDbStats).length){
      totalFiles = parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0)
      +parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.in_queue_count:0)
      +parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0)
      +parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0)
      +parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.published_count:0);
    }
    return(
      <div className="records-db-container">
        <RecordsSideBar />
        { Object.keys(ctrShortInfo).length ?
          <ShortHead
            headingText="Records"
            changeMsn={this.changeMsn}
            subHeaderTopMargin={notificationOpen == true ? 74 : 40}
            contrAssetBasicInfo={ctrShortInfo} /
          >:
          <ContractBarLoader />
        }
        { recordsDbStats && !dashboardLoader ?
          <Row>
            <Col className="dataroom-dashboard" md="9">
              <div className="file-status-card">
                {fileStatusLoader?
                <div style={{paddingLeft: '45%',zIndex:'10'}} className="data-room-expansion-loader-db"><img src={imgStoragePath + "spinner.gif"}/></div>:null
                }
                <Row>
                  <Col md="3">
                    <h3>File Status</h3>
                      <div>
                        <p className="title">No. Of Files</p>
                      <h4 className="count-stats">{ recordsDbStats.records && recordsDbStats.records.total_files ? recordsDbStats.records.total_files : '--'}</h4>
                      </div>
                      <div>
                        <p className="title">Total Size</p>
                      <h4 className="count-stats">{ recordsDbStats.records && recordsDbStats.records.total_size ? getFileSize(recordsDbStats.records.total_size) : '--'}</h4>
                      </div>
                  </Col>
                  <Col md="9">
                    <h3>Documents Status</h3>
                    <Row>
                      <Col md="4" className="progress-card">
                        <p className="title">In Queue</p>
                      <h4 className="count-stats">{recordsDbStats.files_status ? recordsDbStats.files_status.in_queue_count :'--'} /<span>{totalFiles - (parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0)  + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0) + parseInt(recordsDbStats.files_status && recordsDbStats.files_status.ocr_rejected_count ? recordsDbStats.files_status.ocr_rejected_count : 0) )}</span></h4>
                        <div className="progressbar">
                        <span style={{width: parseInt(totalFiles - parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0)-parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0)-parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.published_count:0))==0 ?
                        0+'%':parseFloat(parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.in_queue_count:0)/
                        parseInt(totalFiles - parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0)-parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0)-parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.published_count:0))
                        *100)+'%'}}></span>
                        </div>
                      </Col>
                      <Col md="4" className="progress-card">
                        <p className="title">OCR Completed</p>
                      <h4 className="count-stats">{recordsDbStats.files_status ? recordsDbStats.files_status.ocr_completed_count:'--'} / <span>{totalFiles - (parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0) )}</span></h4>
                        <div className="progressbar">
                        <span style={{width: parseInt(totalFiles - parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0)-parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0))==0 ?
                        0+'%':parseFloat(parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_completed_count:0)/
                        parseInt(totalFiles - (parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0) ))
                        *100)+'%'}}></span>
                        </div>
                      </Col>
                      <Col md="4" className="progress-card">
                        <p className="title">OCR Rejected</p>
                      <h4 className="count-stats">{recordsDbStats.files_status && recordsDbStats.files_status.ocr_rejected_count ? recordsDbStats.files_status.ocr_rejected_count:0}</h4>
                        <div className="progressbar" style={{display: 'none'}}>
                        <span style={{width: parseInt(totalFiles - parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0)-parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0))==0 ?
                        0+'%':parseFloat(parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_completed_count:0)/
                        parseInt(totalFiles - (parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0) ))
                        *100)+'%'}}></span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4" className="progress-card">
                        <p className="title">Published</p>
                        <h4 className="count-stats">{recordsDbStats.files_status ? recordsDbStats.files_status.published_count:'--'} / <span>{totalFiles - (parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:0) + parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.ocr_rejected_count:0) + parseInt(recordsDbStats.files_status && recordsDbStats.files_status.ocr_rejected_count ? recordsDbStats.files_status.ocr_rejected_count : 0) )}</span></h4>
                        <div className="progressbar">
                        <span style={{width: parseInt(totalFiles - parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0))==0 ?
                        0+'%':parseFloat(parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.published_count:0)/
                        parseInt(totalFiles - parseInt(recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:0))
                        *100)+'%'}}></span>
                        </div>
                      </Col>
                      <Col md="4" className="progress-card">
                        <p className="title">To be Renamed</p>
                        <h4 className="count-stats">{recordsDbStats.files_status ? recordsDbStats.files_status.rename_count:'--'} </h4>
                      </Col>
                      <Col md="4" className="progress-card">
                        <p className="title">Rejected</p>
                        <h4 className="count-stats">{recordsDbStats.files_status ? recordsDbStats.files_status.rejected_count:'--'} </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="gap-summery" style={{display:'none'}} >
                <h3>Gap Summary</h3>
                <Row>
                  <Col>
                    <p className="title">Total No. Of Items</p>
                    <h4 className="count-stats">5</h4>
                  </Col>
                  <Col>
                    <p className="title">Total No. Of Closed Items</p>
                    <h4 className="count-stats">0</h4>
                  </Col>
                  <Col>
                    <p className="title">Total No. Of Unresolved Items</p>
                    <h4 className="count-stats">0</h4>
                  </Col>
                </Row>
              </div>
              <div className="record-activity-table" style={{display:'none'}} >
                <table>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Description</th>
                      <th>Remarks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Engine Log Book</td>
                      <td>Engine Log Book is not available</td>
                      <td>
                        <span>open</span>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Engine Log Book</td>
                      <td>Engine Log Book is not available</td>
                      <td>
                        <span>open</span>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Engine Log Book</td>
                      <td>Engine Log Book is not available</td>
                      <td>
                        <span>open</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{position: 'relative', minHeight: '200px'}}>
                {lastActivityData && lastActivityData.data && lastActivityData.data.list && lastActivityData.data.list.length && !dashboardLoader ?
                <div className="record-activity-table" >
                  <table>
                    <thead>
                      <tr>
                        <th>Last Activity</th>
                        <th> </th>
                        <th> </th>
                        <th style={{textAlign: 'right'}}>
                        Activity Report
                        {
                          getLocalStorageInfo().user.permission['records']['report'].indexOf('EXP') != -1 ?
                          <li className="list-inline-item download-block">
                              <ButtonDropdown style={{fontSize: '13px'}} isOpen={this.state.dropdownOpen} toggle={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
                                  <DropdownToggle caret size="sm">
                                     <img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export
                                  </DropdownToggle>
                                  <DropdownMenu>
                                      <DropdownItem onClick={()=> this.props.exportActivityReport(ctrShortInfo)}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                  </DropdownMenu>
                              </ButtonDropdown>
                          </li>
                          :null
                        }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    { lastActivityData && lastActivityData.data && lastActivityData.data.list ?
                      lastActivityData.data.list.map((lastActivity, index) =>
                      <tr key={index} onClick={() => this.activityRedirect(lastActivity)}>
                        <ActivityComponent
                        name={lastActivity.modified_by.name}
                        fileName={lastActivity.new_name}
                        type={lastActivity.folder ? 'folder':'file'}
                        count={lastActivity.count}
                        id={lastActivity.activity_type.id}
                        userId={lastActivity.modified_by.id}
                        fromFolder={lastActivity.from_folder}
                        toFolder={lastActivity.to_folder}
                        oldName={lastActivity.old_name}
                        />
                      <td style={{textAlign: 'right'}}><img className="profile-pic"  src={lastActivity.modified_by.profile_pic != null ? `${lastActivity.modified_by.profile_pic}` : `${imgStoragePath}user.png`} alt="img"/></td>
                      <td style={{textAlign: 'left'}}>{lastActivity.modified_by.name}</td>
                      <td style={{textAlign: 'right'}}>{moment(localTimeFn(lastActivity.activity_time)).fromNow()}</td>
                      </tr>):null
                    }
                    </tbody>
                  </table>
                </div>: lastActivityData.data && lastActivityData.data.length == 0 && !dashboardLoader ? <NoRecordFound/>:<div style={{textAlign: 'center', position:'absolute', left: '50%', top: '40%', transform: 'translate(-40%, -50%)', zIndex: 10}} >Loading...</div>
                }
              </div>
            </Col>
            <Col md="3" style={{paddingLeft:'0px'}}>
             {
              (getLocalStorageInfo().defaultLessor.id == '83') ?
              <div className="record-assign-users" style={{display:'none'}}>
                <h3>Assigned Users</h3>
                <ul>
                  <li>
                    <img className="profile-pic" src={mediaBaseUrl+'/media/users/vishrutha-dhruva.jpg'} />
                    <div className="user-detail">
                      <h5>Vishrutha Dhruva</h5>
                      <a>vish.dhruva@acumenaviation.in</a>
                    </div>
                  </li>
                </ul>
              </div>:null
             }
            </Col>
          </Row>
          :<ListLoader/>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  recordsDbStats: state.RecordDBReducer.recordsDbStats,
  lastActivityData: state.RecordDBReducer.lastActivityData,
  recordsDbActivity: state.RecordDBReducer.recordsDbActivity,
  dashboardLoader: state.RecordDBReducer.dashboardLoader,
  fileStatusLoader: state.RecordDBReducer.fileStatusLoader,
  notificationOpen:state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Dashboard')),
    fetchDbStats: () => dispatch(fetchDbStatsAc(ownProps.params)),
    fetchDbActivity: () => dispatch(fetchDbActivityAc(ownProps.params)),
    fetchLastActivity: () => dispatch(fetchLastActivityAc(ownProps.params)),
    exportActivityReport: (ctrShortInfo) => dispatch(exportActivityReportAc(ownProps.params, ctrShortInfo))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(RecordDB,['records','dashboard','R'])))
