import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
export default class ImageSlider extends Component{
  constructor(props){
    super(props);
    this.state = {}
  }

  onImageLoad = () =>{
    var obj = document.querySelector('.image-gallery-image > img')
    obj.setAttribute('style','height: ' + obj.naturalHeight < 599 ? obj.naturalHeight : 599 +'px;' + 'width: ' + obj.naturalWidth < 599 ? obj.naturalWidth : 599 + 'px;')
  }

  render(){
    const { imageSlider } = this.props;
    return(
      <ImageGallery
        showPlayButton ={false}
        items={imageSlider.images}
        startIndex={imageSlider.startIndex}
        onSlide={this.props.getCurrentIndex}
        useBrowserFullscreen={true}
        onImageLoad={(evt) => this.onImageLoad(evt)}
        onSlide={() => this.onImageLoad()}
      />
    )
  }
}
