import * as actions from '../actions'
export default function(state = {
  notificationLoader: false,
  notificationList:{
    notifications: [],
    pagination: {}
  }
}, action){
  switch (action.type) {
    case actions.NOTIFICATION_LOADER:
        return{
          ...state,
          notificationLoader: action.payload
        }
    case actions.NOTIFICATION_LIST:
        return{
          ...state,
          notificationList: action.payload
        }
    default:
      return state
  }
}
