import * as actions from '../actions';
const formReviewType = {
  t001: 't001ReviewList',
  t002: 't002ReviewList',
  t003: 't003ReviewList',
  t004: 't004ReviewList',
  t005: 't005ReviewList',
  t006: 't006ReviewList',
  t007: 't007ReviewList',
  t009: 't009ReviewList'
};
export default function(state={
  t001ReviewList: [],
  t002ReviewList: [],
  t003ReviewList: [],
  t004ReviewList: [],
  t006ReviewList: {},
  t005ReviewList: [],
  t007ReviewList: {},
  t009ReviewList: [],
  reviewFeedbacksList: [],
  issueDetailInfo: {},
  toggleComments:false,
  toggleEditIssues:false,
  toggleIssues:false,
  commentsLoader:false,
  notifyFlag:false,
  toggleDetail:false,
  selectedText: '',
  markedIssue:{
    flag: false,
    data:{
      qoute: '',
      description: '',
      object_id: '',
      issue_type: 3,
      form_id: 4
    }
  }
  }, action ){

  switch (action.type) {
    case actions.FORMS_REVIEW_MODEL_DATA:
      return {
        ...state,
        [formReviewType[action.formType]]: action.payload
      }
    case actions.ISSUES_CRUD:
      return{
        ...state,
        markedIssue: action.payload
      }
    case actions.SELECT_REVIEW_TEXT:
      return {
        ...state,
        t004ReviewList:{
          ...state.t004ReviewList, list: {
            ...state.t004ReviewList.list, inspection_qa:
              state.t004ReviewList.list.inspection_qa.map((item, index) =>
              index == action.payload.index ? {...item, prefill: item.prefill.replace(action.payload.selectedText, `<span class="selected-text-highlight">${action.payload.selectedText}</span>`)} : item
            )
          }
        },
        selectedText: action.payload.selectedText
      }
    case actions.EMPTY_SELECT_REVIEW_TEXT:
      return {
        ...state,
        t004ReviewList:{
          ...state.t004ReviewList, list: {
            ...state.t004ReviewList.list, inspection_qa:
              state.t004ReviewList.list.inspection_qa.map((item, index) =>
              {
                let modItem =  {...item, prefill: item.prefill.replace('</span>','')}
                modItem =  {...modItem, prefill: modItem.prefill.replace('<span class="selected-text-highlight">','')}
                return modItem
              }
            )
          }
        },
        selectedText: ''
      }
    case actions.FORMS_REVIEW_FEEDBACKS:
      return {
        ...state,
        reviewFeedbacksList:action.payload
      }
    case actions.NOTIFY_CLIENT:
      return {
        ...state,
        notifyFlag:action.payload
      }

    case actions.UPDATE_FORMS_REVIEW_FEEDBACKS:
      return {
        ...state,
        reviewFeedbacksList:state.reviewFeedbacksList.map((data)=> data.location_slug==action.payload.location_slug?action.payload:data)
      }

      case actions.FORMS_REVIEW_FEEDBACKS_DELETE:
      return {
        ...state,
        reviewFeedbacksList:state.reviewFeedbacksList.filter((data)=> data.location_slug!=action.payload)
      }

    case actions.REVIEW_UPDATE_COMMENT:
      return {
        ...state,
        issueDetailInfo: {
          ...state.issueDetailInfo,
          issue_comments: [...state.issueDetailInfo.issue_comments.map((data)=>data.id==action.payload.id?action.payload:data)]
        }
      }
    case actions.FORMS_REVIEW_FEEDBACKS_DETAIL:
      return {
        ...state,
        issueDetailInfo: action.payload
      }
    case actions.ADD_ISSUE_COMMENTS:
      return {
        ...state,
        issueDetailInfo: {
          ...state.issueDetailInfo,
          issue_comments: [...state.issueDetailInfo.issue_comments, action.payload]
        }
      }
      case actions.REVIEW_ADD_COMMENT:
      return{
        ...state,
        issueDetailInfo: {
          ...state.issueDetailInfo,
          issue_comments: [...state.issueDetailInfo.issue_comments, action.payload]
        }
      }
      case actions.TOOGLE_ADD_ISSUES:
      return{
        ...state,
        toggleComments: !state.toggleComments,
        toggleIssues:false,
        toggleDetail:false,
        toggleEditIssues:false,
      }
      case actions.TOOGLE_EDIT_ISSUES:
      return{
        ...state,
        toggleEditIssues: !state.toggleEditIssues,
        toggleIssues:false,
        toggleComments:false,
        toggleDetail:false,
      }

      case actions.UPDATE_EDIT_ISSUES:
      return{
        ...state,
        toggleEditIssues:false,
        toggleIssues:true,
        toggleComments:false,
        toggleDetail:false,
      }

      case actions.COMMENTS_LOADER:
      return{
        ...state,
        commentsLoader: action.payload,
      }
      case actions.TOGGLE_DETAILS:
      return{
        ...state,
        toggleDetail: !state.toggleDetail,
        toggleIssues:false,
        toggleComments:false,
        toggleEditIssues:false

      }
      case actions.TOOGLE_ISSUES_LIST:
      return{
        ...state,
        toggleIssues: !state.toggleIssues,
        toggleComments:false,
        toggleDetail:false,
        toggleEditIssues:false
      }
      case actions.ADD_ISSUES_REVIEW:
      return{
        ...state,
        reviewFeedbacksList: [...state.reviewFeedbacksList, action.payload]
      }
    default:
      return state;
  }
}
