import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, withRouter, browserHistory } from 'react-router';
import { imgStoragePath } from '../../../constants';
import { getCookie, getLocalStorageInfo } from '../../../utils';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';

class FileNavigation extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount() {
    let cookieVal = getCookie();
    let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
    let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0 ;
    let contractHeaderHeight = document.body.contains(document.getElementById('contract-header')) ? document.getElementById('contract-header').clientHeight : 0;
    if(cookieVal){
			this.props.fixedPositionFn('closeMaintenance',false);
      this.props.fixedPositionFn('maintenanceBarHeight',0);
      this.props.fixedPositionFn('subHeaderTop', headerHeight);
      this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);

    }else{
			this.props.fixedPositionFn('closeMaintenance',true);
      this.props.fixedPositionFn('maintenanceBarHeight',maintenanceHeight);
      this.props.fixedPositionFn('subHeaderTop',maintenanceHeight + headerHeight);
      this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);

    }
  }
  render(){
    const { navigation, dataBucket, maintenanceBarHeight, subHeaderTop, recordNavTop, navigationTree, params } = this.props;
      return(
        <div className="file-navigation" style={this.props.style}>
          {
            navigation && navigation.length > 0 ?
            <Row>
              <Col md="9">
                <div className='navigation-scroll'>
                  <div className='navigation-item' style={this.props.params.application == 'technical' ? {position: 'relative', top: '13px', left: '8px', fontSize: '14px', color: '#2f8cff', cursor:'pointer'} : {display : 'none'}} onClick={() => this.props.getNavigationTree()}>
                    <span>( <img src={imgStoragePath+'nav_tree_icon.png'} style={{width: '18px', position: 'relative'}}/> {navigationTree ? 'Close': 'Browse'} )</span>
                  </div>
                  {
                    navigation && navigation.length ?
                    navigation.map( (item, index) => index == navigation.length-1 ?
                    <div key={item.uuid} className='navigation-item active'>
                        <span className="file-path">{index == 0 ? '.. /' :`${item.name}`}</span>
                    </div>:
                    <div key={item.uuid} className='navigation-item'>
                      {
                        index != 0 ?
                        <Link onClick={() => this.props.changeDirectory(item.uuid)}>
                           <span className="file-path">{item.name} /</span>
                        </Link>:
                        <Link onClick={() => this.props.navigateToRoot ? this.props.navigateToRoot(item.uuid) : browserHistory.push(`/${params.type}/${params.aircraft_slug}/records/dataRoom/`)}>
                           <span className="file-path">.. /</span>
                        </Link>
                      }
                      </div>):<div className='navigation-item'><span className="file-path">... /</span>
                    </div>
                  }
                </div>
              </Col>
              <Col md="3" style={{textAlign: 'right'}}>
                {
                   getLocalStorageInfo().user.permission['records']['data_room'].indexOf('C') != -1 && this.props.canCreateFolder ?
                  <Link onClick={this.props.createFolder}><span className="bucket-link"><img src={imgStoragePath+"folder_add.png"} style={{width: '20px', marginTop: '-4px'}}/> Create Folder</span></Link>
                  :null
                }
                {
                  dataBucket && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
                    <Link className="bucket-link" to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/bucket/`}><span className='bucket-count'>{dataBucket && dataBucket.length ? dataBucket.length : 0}</span><img src={imgStoragePath+"bucket.png"} /> File Bucket</Link>
                  :null
                }
              </Col>
            </Row>
            :<Row>
              <Col md="12" style={{textAlign: 'center'}}>Loading...</Col>
            </Row>
          }
        </div>
      )
  }
}

const mapStateToProps = state => ({

	closeMaintenance: state.sharedReducers.closeMaintenance,
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
  subHeaderTop: state.sharedReducers.subHeaderTop,
  recordNavTop: state.sharedReducers.recordNavTop
});
const mapDispatchToProps = (dispatch, ownProps ) => {
	return {

		fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
	}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileNavigation));
