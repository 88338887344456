import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal, ModalBody,Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Popover, PopoverBody } from 'reactstrap';
import classnames from 'classnames';
import { imgStoragePath } from '../../../../constants';
import { EditSidebar } from '../../../contracts/Elements'
import EditDataPointInfo from '../../../contracts/DataExtract/components/EditDataPointInfo';
import { AD_FILES_MODAL, SELECT_DATAPOINT_LIST, SELECT_ALL_DATAPOINT_LIST, TOGGLE_DATA_EXT_BAR } from '../actions';
import { TableUIComp, ListLoader, SecondarySideBar, SecondaryHeader, NoRecordFound } from '../../../../shared';
import { getTechSpecsDataPointsAc, dataPointToggleStatusAc, editDataPointAc, submitDataPointAc } from '../actionCreators';
import { localTimeFn } from '../../../../utils';
import ExtractPointData from '../components/ExtractPointData'
class TechSpecsLogView extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: '1',
      operatorFilter: {},
      oemFilter: {},
      file: [],
      easaFilter: {},
    };
    this.toggle = this.toggle.bind(this);

  }
  componentDidMount(){
    this.props.getTechSpecsDataPoints()
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  confirmModal = (file) => {
    let fileInfo = [];
    file.map(f => {
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        fileInfo.push({file: f, fileType: 'oem_uri' })
      }else{
        fileInfo.push({ file: f, error: true });

      }
    })
    this.setState(prevState => ({
      ...prevState,
      file: fileInfo,
    }));
    this.props.toggleViewModalFn(!this.props.modalStatus);
  }

  confirmClickModal = (file) => {
    if(!file.length){
      return false;
    }
    let fileInfo = [];
    for(var i=0; i<file.length; i++) {
      var f = file[i];
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        fileInfo.push({file: f, fileType: 'oem_uri'})
      }else{
        fileInfo.push({ file: f, error: 1 });
      }
    }

    this.setState(prevState => ({
      ...prevState,
      file: fileInfo
    }));
    this.props.toggleViewModalFn(!this.props.modalStatus);
  }


  // Table Filter and pagination Function
  changeFilterFn = (filter) => {
    if(this.state.activeTab == '1'){
      filter = {
        ...filter,
        type: 'operator_status'
      }
      this.setState({
        operatorFilter: filter
      });
    }else if (this.state.activeTab == '2') {
      filter = {
        ...filter,
        type: 'easa_faa_status'
      }
      this.setState({
        easaFilter: filter
      });
    }else{
      filter = {
        ...filter,
        type: 'oem_status'
      }
      this.setState({
        oemFilter: filter
      });
    }
    this.props.adDataPointsList(filter, 'later');
  }
  changePerPage=(count) => {
    let filter = {};
    if(this.state.activeTab == '1'){
      filter = this.state.operatorFilter;
      this.setState(prevState => ({
        ...prevState,
        operatorFilter: {
          ...prevState.operatorFilter,
          per_page: count
        }
      }))
    }else if (this.state.activeTab == '2') {
      filter = this.state.easaFilter;
      this.setState(prevState => ({
        ...prevState,
        easaFilter: {
          ...prevState.easaFilter,
          per_page: count
        }
      }))
    }else{
      filter = this.state.oemFilter;
      this.setState(prevState => ({
        ...prevState,
        oemFilter: {
          ...prevState.oemFilter,
          per_page: count
        }
      }))
    }
    filter = {
      ...filter,
      per_page: count,
      type: this.state.activeTab == '1' ? 'operator_status' : this.state.activeTab == '2' ? 'easa_faa_status': 'oem_status'
    }
    this.props.adDataPointsList(filter,'later');
  }
  sortTable = (sortKey) => {
    let filter = {};
    if(this.state.activeTab == '1'){
      filter = this.state.operatorFilter;
      this.setState(prevState => ({
        ...prevState,
        operatorFilter: {
          ...prevState.operatorFilter,
          sort: sortKey,
          page:1,
          per_page:10,
          sort_by: prevState.operatorFilter.sort && prevState.operatorFilter.sort ==  sortKey ? prevState.operatorFilter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }))
    }else if (this.state.activeTab == '2') {
      filter = this.state.easaFilter;
      this.setState(prevState => ({
        ...prevState,
        easaFilter: {
          ...prevState.easaFilter,
          sort: sortKey,
          page:1,
          per_page:10,
          sort_by:prevState.easaFilter.sort && prevState.easaFilter.sort ==  sortKey ? prevState.easaFilter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }))
    }else{
      filter = this.state.oemFilter;
      this.setState(prevState => ({
        ...prevState,
        oemFilter: {
          ...prevState.oemFilter,
          sort: sortKey,
          page:1,
          per_page:10,
          sort_by: prevState.oemFilter.sort && prevState.oemFilter.sort ==  sortKey ? prevState.oemFilter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }))
    }

    filter = {
      ...filter,
      sort: sortKey,
      page:1,
      per_page:10,
      sort_by: filter.sort && filter.sort ==  sortKey ? filter['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc',
      type: this.state.activeTab == 1 ? 'operator_status' : this.state.activeTab == 2 ? 'easa_faa_status' : 'oem_status'
    }

    this.props.adDataPointsList(filter,'later');
  }

  deleteFn = (index) => {
    var file = this.state.file.filter(( obj, key ) => {
      return key !== index;
    });

    this.setState(prevState => ({
      ...prevState,
      file: file,
    }));

    if(this.state.file.length <= 1){
      this.props.toggleViewModalFn(!this.props.modalStatus);
    }
  }

  changePagination = (type, currentPage) => {
    let filter = {};
    if(this.state.activeTab == '1'){
      filter = this.state.operatorFilter;
    }else if (this.state.activeTab == '2') {
      filter = this.state.easaFilter;
    }else{
      filter = this.state.oemFilter;
    }
    filter = {
      ...filter,
      page: currentPage,
      type: this.state.activeTab == '1' ? 'operator_status' : this.state.activeTab == '2' ? 'easa_faa_status': 'oem_status'
    }
    this.props.adDataPointsList(filter, 'later');
  }
  uploadMLFile = (params, file) => {
    let submitFileinfo =  file.map(file => file.fileType == '' ? { ...file, error : 2 } : file)
    this.setState(prevState => ({
      ...prevState,
      file: submitFileinfo,
    }))
    if(!submitFileinfo.filter(obj => obj.error && obj.error == 2 ).length){
      if(file.length > 1 && !(file[0].fileType != '' && file[file.length - 1].fileType != '') && file[0].fileType == file[file.length - 1].fileType){
        this.props.uploadMLFile(params, file);
      }else{
        this.props.uploadMLFile(params, file);
      }
    }
  }

  render(){
    const { file, modal } = this.state;
    const { adMLLoader, techSpecsPoints, dataIds, dataPointCrud } = this.props;
    return(
      <div className="">
        <SecondaryHeader technical={true} assetInfo={techSpecsPoints.asset_details ? {aircraft_type: techSpecsPoints.asset_details.model, name: 'MSN '+techSpecsPoints.asset_details.msn } :{}} />
        <div className="sparta-secondary-sidbar">
          <ul className="list-unstyled">
            <li>
              <Link className={this.props.location.pathname.includes('techspecs-logs') || this.props.location.pathname.includes('techspecs-logs') ? 'active': ''} to={'/'+this.props.params.type +'/'+this.props.params.aircraft_slug+'/techspecs-logs'}>Technical Specs Logs</Link>
            </li>
          </ul>
        </div>
        {adMLLoader ? <ListLoader />:null}
        {
          !adMLLoader && !Object.keys(techSpecsPoints).filter(item => item != 'asset_details').length ?
            <NoRecordFound style={{marginTop: '100px', marginLeft: '280px'}}/>
          :null
        }
        <div className="" style={{margin: '54px -15px 0 195px',  padding: '30px'}}>
          <div className="lg-view-block" style={{margin: '0px 8px 15px 224px'}}>
            <div className="approve-data-point flex-center" style= {{ top: '90px', marginLeft: '26px', zIndex: '98'}}>
              <Row className="back-lg-block" style={{padding: '4px 0'}}>
                <Col md="4" className="flex-centered">
                  <Link to={"/"+this.props.params.type + "/" + this.props.params.aircraft_slug + "/techspecs-logs/"}  className="back-nav"><img src={imgStoragePath + "back_arrow.png"} alt="img"/></Link>
                    {
                    techSpecsPoints.pending_items &&  techSpecsPoints.pending_items.length ?
                      <h5 className="select-all-block"><label><input type="checkbox" checked={(dataIds.length ? dataIds.length == techSpecsPoints.pending_items.length ? true : false : false)} onChange={() => this.props.selectAllFn()}/> Select All Datapoints</label>
                        <br />
                        {
                          dataIds.length ?
                          <span style={{ marginLeft: '17px'}} className="count">{dataIds.length} Data Point(s) Selected</span>
                        : null }
                      </h5>
                      : null
                    }
                </Col>
                <Col md="8" className="right-blk ">
                  <ul className="list-inine flex-centered">
                    { dataIds.length ?
                      <li className="list-inline-item">
                        <Link onClick={() => this.props.dataPointToggleFn('3', dataIds)} className="ignore-log caption-ui"> Ignore</Link>
                      </li>:null
                    }
                    { dataIds.length ?
                      <li className="list-inline-item">
                        <Link onClick={() => this.props.dataPointToggleFn('2', dataIds)} className="reject-log caption-ui">  Reject</Link>
                      </li>:null
                    }
                    { dataIds.length ?
                      <li className="list-inline-item">
                        <Link onClick={() => this.props.dataPointToggleFn('1', dataIds)} className="caption-ui approve-btn">  Approve</Link>
                      </li>:null
                    }
                    <li className="list-inline-item">
                      <a className="downlaod caption-ui" href={techSpecsPoints.file_name}  target="blank"><img width="15" src={imgStoragePath+"pdf.png"} alt="img"/>Download File</a>
                    </li>
                    <li className="list-inline-item">
                        <button className="primary-btn" disabled={!techSpecsPoints.approved_data_points} onClick={() => this.props.submitDataPointFn(this.props.params)}>Submit</button>
                        {
                          techSpecsPoints.approved_data_points > 0 ?
                          techSpecsPoints.file_type == 1 ?
                          <span className="count">{techSpecsPoints.approved_data_points + ' Data Point(s) are cleared for Submission'}</span>:
                          <span className="count">{techSpecsPoints.approved_data_points + ' Data Point(s) are cleared for Submission'}</span>
                          :null
                        }
                      </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
          <div className="lg-view-block" style={{margin: '50px 8px 15px 0px'}}>
          { techSpecsPoints.response_text.aircraft ?
            <div className="data-log-view-block">
                <div>
                  <h5 className="para">Aircraft </h5>
                {
                  techSpecsPoints.response_text.aircraft.map(item =>
                    <ExtractPointData
                      selectDataPoint={() => this.props.selectDataPoint(item.id)}
                      dataIds={dataIds}
                      dataPoint={item}
                      changeStatus={this.props.dataPointToggleFn}
                      fileType = {techSpecsPoints.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )
                }
                </div>

            </div>:null
          }
          { techSpecsPoints.response_text.airframe ?
            <div className="data-log-view-block">
                <div>
                  <h5 className="para">Airframe </h5>
                {
                  techSpecsPoints.response_text.airframe.map(item =>
                    <ExtractPointData
                      selectDataPoint={() => this.props.selectDataPoint(item.id)}
                      dataIds={dataIds}
                      dataPoint={item}
                      changeStatus={this.props.dataPointToggleFn}
                      fileType = {techSpecsPoints.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )
                }
                </div>

            </div>:null
          }
          { techSpecsPoints.response_text.aircraft_maintenance_event ?
            Object.keys(techSpecsPoints.response_text.aircraft_maintenance_event).map((event, eventIndex) => <div className="data-log-view-block">
                <div>
                  <h5 className="para">Maintenace Event #{eventIndex + 1} </h5>
                {
                  techSpecsPoints.response_text.aircraft_maintenance_event[event].map(item =>
                    <ExtractPointData
                      selectDataPoint={() => this.props.selectDataPoint(item.id)}
                      dataIds={dataIds}
                      dataPoint={item}
                      changeStatus={this.props.dataPointToggleFn}
                      fileType = {techSpecsPoints.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )
                }
                </div>

            </div>):null
          }
          { techSpecsPoints.response_text.apu ?
            <div className="data-log-view-block">
                <div>
                  <h5 className="para">APU </h5>
                {
                  techSpecsPoints.response_text.apu.map(item =>
                    <ExtractPointData
                      selectDataPoint={() => this.props.selectDataPoint(item.id)}
                      dataIds={dataIds}
                      dataPoint={item}
                      fileType = {techSpecsPoints.file_type}
                      changeStatus={this.props.dataPointToggleFn}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )
                }
                </div>

            </div>:null
          }
          { techSpecsPoints.response_text.avionics && Object.keys(techSpecsPoints.response_text.avionics).length ?
            Object.keys(techSpecsPoints.response_text.avionics).map((avionic, index) =>
            <div className="data-log-view-block">
                <div>
                  <h5 className="para">Avionics No. {index+1}</h5>
                {
                  techSpecsPoints.response_text.avionics[avionic].map(item =>
                    <ExtractPointData
                      selectDataPoint={() => this.props.selectDataPoint(item.id)}
                      dataIds={dataIds}
                      dataPoint={item}
                      changeStatus={this.props.dataPointToggleFn}
                      fileType = {techSpecsPoints.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )
                }
                </div>

            </div>):null
          }
          { techSpecsPoints.response_text.engine ?
            Object.keys(techSpecsPoints.response_text.engine).map((key, index) =>
              Object.keys(techSpecsPoints.response_text.engine[key]).map((innerKey, innerIndex) =>
              techSpecsPoints.response_text.engine[key][innerKey].length ?
              <div className="data-log-view-block">
                  <div>
                    <h5 style={{textTransform: 'capitalize'}} className="para">Engine #{innerIndex + 1}</h5>
                  {
                    techSpecsPoints.response_text.engine[key][innerKey].map((item, itemIndex) =>
                    <ExtractPointData
                      selectDataPoint={() => this.props.selectDataPoint(item.id)}
                      dataIds={dataIds}
                      dataPoint={item}
                      changeStatus={this.props.dataPointToggleFn}
                      fileType = {techSpecsPoints.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                    )
                  }
                  </div>
              </div>
              :null
              )
            ):null
          }
          { techSpecsPoints.response_text.engine ?
            Object.keys(techSpecsPoints.response_text.engine).map((key, index) =>
              Object.keys(techSpecsPoints.response_text.engine[key]).map((innerKey, innerIndex) =>
              techSpecsPoints.response_text.engine[key][innerKey].length ?
                  techSpecsPoints.response_text.engine[key][innerKey].map((item, itemIndex) =>
                  item.llp_list ?
                    Object.keys(item.llp_list).map((llp, llpIndex) =>
                    <div className="data-log-view-block">
                      <div>
                        <h5 style={{textTransform: 'capitalize'}} className="para">Engine {innerKey} LLP {llp}</h5>
                      {
                        item.llp_list[llp].map(innerLlp =>
                          <ExtractPointData
                            selectDataPoint={() => this.props.selectDataPoint(innerLlp.id)}
                            dataIds={dataIds}
                            dataPoint={innerLlp}
                            changeStatus={this.props.dataPointToggleFn}
                            fileType = {techSpecsPoints.file_type}
                            toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:innerLlp, flag: true})}
                          />
                        )
                      }
                      </div>
                    </div>
                    )
                  :null
                )
              :null
              )
            ):null
          }
          { techSpecsPoints.response_text.propeller && techSpecsPoints.response_text.propeller.propeller_list ?
            Object.keys(techSpecsPoints.response_text.propeller.propeller_list).map((key, index) =>
            <div className="data-log-view-block">
              <div>
                <h5 style={{textTransform: 'capitalize'}} className="para">Propeller {key}</h5>
              {
                techSpecsPoints.response_text.propeller.propeller_list[key].map(innerLlp =>
                  <ExtractPointData
                    selectDataPoint={() => this.props.selectDataPoint(innerLlp.id)}
                    dataIds={dataIds}
                    dataPoint={innerLlp}
                    changeStatus={this.props.dataPointToggleFn}
                    fileType = {techSpecsPoints.file_type}
                    toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:innerLlp, flag: true})}
                  />
                )
              }
              </div>
            </div>
            ):null
          }
          { techSpecsPoints.response_text.landing_gear ?
            Object.keys(techSpecsPoints.response_text.landing_gear).map((key, index) =>

              <div className="data-log-view-block">
                  <div>
                    <h5 style={{textTransform: 'capitalize'}} className="para">LG {key.replace(/_/g,' ')}</h5>
                  {
                    techSpecsPoints.response_text.landing_gear[key].map(item =>
                      <ExtractPointData
                        selectDataPoint={() => this.props.selectDataPoint(item.id)}
                        fileType = {techSpecsPoints.file_type}
                        dataIds={dataIds}
                        dataPoint={item}
                        changeStatus={this.props.dataPointToggleFn}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )
                  }
                  </div>
              </div>
            ):null
          }
          { techSpecsPoints.response_text.interior ?
           <h3 className="reserve-acnt">Interiors</h3>
           : null
         }
          { techSpecsPoints.response_text.interior ?
            Object.keys(techSpecsPoints.response_text.interior).map((key, index) =>
            !['galley','lavatory'].includes(key) ?
              <div className="data-log-view-block">
                  <div>
                    <h5 style={{textTransform: 'capitalize'}} className="para">{key.replace(/_/g,' ')}</h5>
                  {
                    techSpecsPoints.response_text.interior[key].map(item =>
                      <ExtractPointData
                        selectDataPoint={() => this.props.selectDataPoint(item.id)}
                        fileType = {techSpecsPoints.file_type}
                        dataIds={dataIds}
                        dataPoint={item}
                        changeStatus={this.props.dataPointToggleFn}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )
                  }
                  </div>
              </div>
              :null
            ):null
          }
          { techSpecsPoints.response_text.interior ?
            Object.keys(techSpecsPoints.response_text.interior).map((key, index) =>
              ['galley','lavatory'].includes(key) ?
              Object.keys(techSpecsPoints.response_text.interior[key]).map((innerKey, innerIndex) =>
                Object.keys(techSpecsPoints.response_text.interior[key][innerKey]).length ?
                  Object.keys(techSpecsPoints.response_text.interior[key][innerKey]).map((innerItem, itemIndex) =>

                        <div className="data-log-view-block">
                            <div>
                              <h5 style={{textTransform: 'capitalize'}} className="para">{innerKey} {key.replace(/_/g,' ')} #{itemIndex + 1}</h5>
                            {
                              techSpecsPoints.response_text.interior[key][innerKey][innerItem].map(item =>
                                <ExtractPointData
                                  selectDataPoint={() => this.props.selectDataPoint(item.id)}
                                  fileType = {techSpecsPoints.file_type}
                                  dataIds={dataIds}
                                  dataPoint={item}
                                  changeStatus={this.props.dataPointToggleFn}
                                  toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                                />
                              )
                            }
                            </div>
                        </div>
                    )
                :null
                )
              :null
            ):null
          }
        </div>
        </div>
        <EditSidebar
          title="Edit Data Point"
          editSideBar={dataPointCrud.flag}
          toggleEditSideBar={() => this.props.dataPointSideBar({flag: false, data:{}, type: 'edit'})}
          >
          { dataPointCrud.flag ?
            <EditDataPointInfo
              editDataPoint={(data) => this.props.editDataPoint(this.props.params.id, data)}
              dataPoint={dataPointCrud.data}
              toggleEditSideBar={() => this.props.dataPointSideBar({flag: false, data:{}, type: 'edit'})}
            />:null
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  adMLLoader: state.TechPointsReducer.adMLLoader,
  techSpecsPoints: state.TechPointsReducer.techSpecsPoints,
  dataIds: state.TechPointsReducer.dataIds,
  dataPointCrud: state.TechPointsReducer.dataPointCrud,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTechSpecsDataPoints: () => dispatch(getTechSpecsDataPointsAc(ownProps.params)),
    submitDataPointFn: () => dispatch(submitDataPointAc(ownProps.params)),
    dataPointToggleFn: (type, ids) => dispatch(dataPointToggleStatusAc(ownProps.params, type, ids)),
    editDataPoint: (id,data) => dispatch(editDataPointAc(ownProps.params, id, data)),
    selectDataPoint: (id) => dispatch({
      type: SELECT_DATAPOINT_LIST,
      payload: id
    }),
    selectAllFn: () => dispatch({
      type: SELECT_ALL_DATAPOINT_LIST,
      payload: ''
    }),
    dataPointSideBar: (data) => dispatch({
      type: TOGGLE_DATA_EXT_BAR,
      payload: data
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TechSpecsLogView);
