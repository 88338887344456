import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
export default class InShopUnservList extends Component{
  constructor(props){
    super(props)
  }
 render(){
  const { dataList, techConstants } = this.props;
  let data = dataList.off_wing_detail;
  return(
    <Row>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Expected Release Date of the Engine" text={data.expected_release_date_of_the_engine ? moment(data.expected_release_date_of_the_engine).format(displayDateFormat):'--'}  />
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Induction Date Of Engine"  text={data.induction_date_of_engine ? moment(data.induction_date_of_engine).format(displayDateFormat):'--'} />
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Work Scope Status"   text={data.work_scope_status && techConstants.length ? techConstants.find(item => item.type === 'workscope_status' && item.value == data.work_scope_status).label : '--'}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Facility Name"   text={data.shop_visit_name ?  data.shop_visit_name :'--'}/>
      <GenTabField grid="3" classText = "horizontal-inner-block " label="Facility Location"   text={data.shop_visit_location ?  data.shop_visit_location :'--'}/>
    </Row>
  )
  }
}
