import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath, displayDateFormat } from '../../../../constants';

export default class EnggCorAvg extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {    
		this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  

  render() {
    const { projectDetails,tagetId,placement } = this.props;
    return (
        <span>
       { projectDetails.engineers.length > 0 ?
       <span>
        <img width="12" className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath +'inv_info_icon.png'} alt="img" style={{ display: 'inline-block', marginLeft: '5px'}}/>
        <Popover  placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody style={{width:'100%'}}>
							<div>
              <div>
          <table>
          <thead>
            <tr>
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}} >Project engineers</th>
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}} >General</th>
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}}>Physical Inspection</th>
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}}>Maintenance</th>       
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}} >Technical Records</th>       
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}} >Operation Of Aircraft</th>       
                <th className="sticky-background" style={{width:'100px', overflow:'auto'}}>GeoPolitical</th>     
                
            </tr>
          </thead>
  	          <tbody>
  	              <tr >
                      <td> -- </td>
                      <td> -- </td>
                      <td> -- </td>
                      <td> -- </td>
                      <td> -- </td>
                      <td> -- </td>
                      <td> -- </td>
                      
  	              </tr>
  	          </tbody>
            </table>
          </div>
							</div>
            </PopoverBody>
        </Popover>
				</span> 
				
        :  null }



   
     </span>

    );
  }
}
