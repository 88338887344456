import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Row } from 'reactstrap';
import {InfoIcon} from '../../../../shared'
import {UncontrolledTooltip} from 'reactstrap'
import { invoiceStatusDrpdwn, fieldDateFormat, backendDateFormat } from '../../../../constants';
import { FieldInput, FieldLabel, FieldCol, FieldGroup, FieldDate } from '../../Elements';
import { checkValidDate, checkNumber } from '../../../../formValidator';
const numberWithDot =  /^[0-9]*(\.\d{0,2})?$/
export default class ChangeInvoiceStatus extends Component{
  constructor(props){
    super(props);
    this.state={
      status: '',
      paid_date: moment().format(backendDateFormat),
      error:{},
      partial_paid_amount:'',
      partial_paid_date:''
    }
  }
  dropDownChange = (statusOption) => {
    this.setState(prevState => ({
      ...prevState,
      status: statusOption?statusOption.value:'',
      error: {
        ...prevState.error,
        status: ''
      }
    }));
  }
  updateField = (e) => {
    this.setState(prevState => ({
      ...prevState,
      paid_date: e,
      error: {
        ...prevState.error,
        paid_date: ''
      }
    }));
  }
  updateForm = (key, value) => {
   if(key == 'partial_paid_amount'){
     if(numberWithDot.test(value)){
       this.setState(prevState => ({
         ...prevState,
         partial_paid_amount:value
       }))
     }
   }else {
     this.setState(prevState => ({
       ...prevState,
       [key]:value
     }))
   }

 }
  updateInvStatus = () => {
    if(this.state.status){
      if(this.state.status == 3){
        if(this.state.paid_date != 'Invalid date' && this.state.paid_date != '' && this.state.paid_date != null && this.state.paid_date != undefined && moment(this.state.paid_date).isValid()){
          this.props.updateInvStatus({status: this.state.status, paid_date: this.state.paid_date});
        }else{
          this.setState(prevState => ({
            ...prevState,
            error: {
              ...prevState.error,
              paid_date: 'Please enter valid Invoice Payment Date'
            }
          }))
        }
      }
      if(this.state.status == 5){
        let validateNewInput = {
          partial_paid_date:checkValidDate({value:this.state.partial_paid_date, required:true, minLength:'', maxLength:'', message:'Please enter Partial Payment Date'}),
          partial_paid_amount:checkNumber({value: this.state.partial_paid_amount, required: true, minLength:'', maxLength:'', message:'Please enter Partial Payment Amount'})
        }
        if(this.state.partial_paid_amount > this.props.totalAmount ){
          validateNewInput = {
            ...validateNewInput,
            partial_paid_amount:`Please enter Partial Payment Amount less that Total Amount`
          }
        }
        if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] == ""})){
          this.props.updateInvStatus({status:this.state.status, partial_paid_date:moment(this.state.partial_paid_date).format(backendDateFormat),partial_paid_amount:this.state.partial_paid_amount })
        }else {
          this.setState({
            error: validateNewInput
          })
        }
      }else{
        this.props.updateInvStatus({status: this.state.status});
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          status: 'Please Select the Status First'
        }
      }))
    }
  }
  render(){
    const { status, invoiceType, invoiceStartDate, invoiceEndDate, totalAmount } = this.props;
    const { partial_paid_date, partial_paid_amount} = this.state
    let statusList = [];
    if(parseInt(status) == 1 ){
      if([1,2,9].includes(parseInt(invoiceType))){
        statusList = invoiceStatusDrpdwn.filter(status => status.value != 1 );
      }else {
        statusList = invoiceStatusDrpdwn.filter(status => status.value != 1 && status.value != 5 );
      }

    }
    if(parseInt(status) == 2){
      if([1,2,9].includes(parseInt(invoiceType))){
        statusList = invoiceStatusDrpdwn.filter(status => status.value != 1 && status.value != 2  );
      }else {
        statusList = invoiceStatusDrpdwn.filter(status => status.value != 1 && status.value != 2 && status.value != 5);
      }
    }
    if( parseInt(status) == 5) {
      statusList = invoiceStatusDrpdwn.filter(status =>  status.value == '3')
    }
    return(
      <div classNamne="" style={this.state.status === 3 ? {height: '300px'} :{}} >
        <h5>Update the invoice status as applicable
        <InfoIcon id="updateInvoiceStatus" />
        <UncontrolledTooltip placement="right" target="updateInvoiceStatus">
        Status of the invoice to be updated to reflect on invoice summary.
          </UncontrolledTooltip>
        </h5>
        <Select
          name="form-field-name"
          placeholder='Select Status'
          className="custom-select-block"
          name="form-field-name"
          labelKey= 'label'
          valueKey='value'
          value={this.state.status}
          onChange={this.dropDownChange}
          options={statusList}

        />
        <h6 style={{position: 'relative', top: '-20px'}} className="error-msg">{this.state.error.status}</h6>
        {
          this.state.status == 3 ?
          <div >
            <label className="label">Invoice Payment Date</label>
            <DatePicker
              dateFormat = {fieldDateFormat}
              selected={ this.state.paid_date &&  moment(this.state.paid_date).isValid() ? moment(this.state.paid_date): null }
              maxDate={moment()}
              onChange={(e) => this.updateField(moment(e).format(backendDateFormat))}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              popperPlacement={'right'}
            />
          <h6 style={{position: 'relative', top: '-20px'}} className="error-msg">{this.state.error.paid_date}</h6>
          </div>
          :null
        }
        {
            this.state.status == 5 ?
            <Row>
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Partial Payment Date</FieldLabel>
                  <FieldDate
                    value={partial_paid_date && moment(partial_paid_date).isValid() ? moment(partial_paid_date) : null}
                    type='edit'
                    keyParam="partial_paid_date"
                    updateField={this.updateForm}
                    minDate={moment(invoiceStartDate)}
                    maxDate={moment(invoiceEndDate)}

                  />
                <h6 style={{position: 'relative', top: '-20px'}} className="error-msg">{this.state.error.partial_paid_date}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Partial Payment Amount</FieldLabel>
                    <FieldInput
                      value={partial_paid_amount}
                      keyParam="partial_paid_amount"
                      updateField={ this.updateForm}
                    />
                  <h6  className="error-msg">{this.state.error.partial_paid_amount}</h6>
                </FieldGroup>
              </FieldCol>
          </Row> : null
          }
        <button className="btn btn-primary" onClick={this.updateInvStatus} style={{position:'relative', top:'110px'}} >Proceed</button>
      </div>
    )
  }
}
