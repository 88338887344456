import React from 'react';
import {Row, Col} from 'reactstrap';
const HDLabelCard = (props) => {
  return(
    <Col md={props.col} className="horizontal-inner-block">
     <h6>{props.label}</h6>
     <span>{props.value}</span>
    </Col>
  )
}

export default HDLabelCard;
