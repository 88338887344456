import React from 'react';
import { ExportFiles } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';
const BulkOperation = (props) => {
  return(
    <div className={"add-bulk-operations " + (props.selectedIds.length ? 'selected-operations':'')}>
      <ul className="list-inline flex-centered">
        {
          props.selectedIds.length ?
          <li className="list-inline-item"><img onClick={props.cancelBulkOp} src={imgStoragePath + "blue_cross_icon.png"} style={{width: '15px', marginRight: '5px'}} /> {props.selectedIds.length} items selected</li>:null
        }

       {/* <li className="list-inline-item operation-cta">
          <ExportFiles exportFile={(fileType) => props.contactListExport(fileType)} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'}, {img:'excel_icon.png', label: 'Excel', type:'xls'}]} />
      </li>*/}
        {
          props.selectedIds.length ?
          <li className="list-inline-item operation-cta" onClick={props.bulkDelete}> <img src={imgStoragePath + 'blue_delete_icon.png'} style={{width: '13px', marginRight: '5px'}} />Delete</li>:null
        }

      </ul>
      <button onClick={() => props.toggleSidebar()} className="aims-primary-btn">{props.addText ? props.addText : 'Pass Add Text'}</button>
    </div>
  )
}
export default BulkOperation;
