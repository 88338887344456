import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
require('highcharts/modules/exporting')(Highcharts);
export default class LOWGraph extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { title, type, color, lineColor, plots, events, yType, legend, xLabel, yLabel, toolTipText, symbol, newplots } = this.props;
    let data = [];
    let newData = [];
    Object.keys(plots).map( year => {
      let d = [];
      d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
      d.push(plots[year]);
      data.push(d);
    });
    if(Object.keys(newplots).length){
      Object.keys(newplots).map( year => {
        let d = [];
        d.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
        d.push(newplots[year]);
        newData.push(d);
      });
    }
    const options = {
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: xLabel
        }
      },
      yAxis:{
        title: {
          text: yLabel
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        },
        gridLineDashStyle: 'longdash'
      },
      tooltip: {
        formatter: function () {
            return 'Date <b>' + moment(this.x).format('MMM YYYY') +
                '<br/></b>Hours <b>' + this.y + '</b>';
        }
      },
      credits: {
        enabled: false
      },
      series:  [
        {
          showInLegend: true,
          name: 'Calculated Hours',
          data: data,
          color: color,
          lineColor: lineColor,
          marker: {
            fillColor: lineColor
          }
        },{
          showInLegend: newData.length ? true:false,
          name: 'Simulated Hours',
          data: newData,
          color: '#BAB9D3',
          lineColor: '#150F78',
          marker: {
            fillColor: '#150F78'
          }
        }
      ],
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      }
    };
    return(
      <div className="finance-graph-blk">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    )
  }
}
