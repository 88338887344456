import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {  GetInputField, DeleteModal } from '../../../Elements';
import TextareaField from '../../../Elements/TextareaField';
import { NoRecordFound  } from '../../../../../shared';
import { FindingList } from '../../T004/components';
export default class EditPictorialFinding extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      auditsInfo: [],
      activeTab: Object.keys(this.props.auditsInfo).length ? Object.keys(this.props.auditsInfo)[0] : 0,
      delModal: false,
      modalTitle:'',
      findingId: '',
      activeCabinTab: 'main'
    };
  }
  componentDidMount(){
    this.setState({
      auditsInfo: this.props.auditsInfo
    })

    let upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169]
    let upperCabinTab = false
    let initLower = []
    let initMain = []
    this.props.auditsInfo.map((item, index) => {
      if(upGalleys.indexOf(item.id) != -1){
        initLower.push({...item, index: index})
      }else{
        initMain.push({...item, index: index})
      }
    })
    this.props.auditsInfo.map(item => {
      upperCabinTab = upGalleys.indexOf(item.id) != -1 ? true : upperCabinTab
      return item
    })

    if(upperCabinTab){
      this.setState({ activeTab: initMain[0].index})
    }
  }
  toggleDelModal = (title='', item = []) => {
    this.setState({
      delModal: !this.state.delModal,
      modalTitle: title,
      findingId: item.id
    });
  }
  componentWillReceiveProps(nextProps){
    this.setState(prevState => ({
      ...prevState,
      auditsInfo: nextProps.auditsInfo
    }))
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  updateForm = (type, value, id) => {
    if(id){
      this.setState(prevState => ({
        ...prevState,
          auditsInfo: {
            ...prevState.auditsInfo,
            answers:   [...prevState.auditsInfo.answers].map(data => data['key_param'] == type ? {
              ...data,
              value: value
            } : data)
          }
        }));
    }else{
      this.setState(prevState => ({
        ...prevState,
          auditsInfo: {
            ...prevState.auditsInfo,
            answers:   [...prevState.auditsInfo.answers, {key_param: type, value: value }]
          }
        }));
    }
  }


  render(){
    const { physicalInspection, formInfo } = this.props;
    const { auditsInfo } = this.state;
    let upGalleys = [140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169]
    let upperCabinTab = false
    let initLower = []
    let initMain = []
    if(auditsInfo.length){
      auditsInfo.map((item, index) => {
        if(upGalleys.indexOf(item.id) != -1){
          initLower.push({...item, index: index})
        }else{
          initMain.push({...item, index: index})
        }
      })
      auditsInfo.map(item => {
        upperCabinTab = upGalleys.indexOf(item.id) != -1 ? true : upperCabinTab
        return item
      })
    }
    if(!(Object.keys(auditsInfo).length )){ return null }
    return(
      <form className="physical-inspection-form technical-form">
        <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px', paddingBottom: '100px', }}>
          {
            Object.keys(auditsInfo).length <= 1 &&  auditsInfo[0] && auditsInfo[0].findings ?
            <div>
              { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <p className="add-finding-link" >
                  <Link onClick={() => this.props.findingCrudFn('add', '', auditsInfo[0].id)}>+ Add Finding</Link>
                </p>:null
              }
              <table className="finding-tables">
                <thead>
                  <tr>
                    { ['SL No.' ,'Type of Damage', 'Description',"Classification","Category",'Repair type','Action'].map((item, index) => <th key={index}>{item}</th>)}
                  </tr>
                </thead>
                <tbody>
                  { auditsInfo[0].findings.map((item, index) =>
                    <FindingList
                      formInfo={formInfo}
                      index = {index}
                      findingCrudFn={() => this.props.findingCrudFn('edit', item, auditsInfo[0].id)}
                      item={item}
                      toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                    />
                  )}
                </tbody>
              </table>
              { !(Object.keys(auditsInfo).length && auditsInfo[0].findings && auditsInfo[0].findings.length) ?
                <NoRecordFound style={{ 'margin': 0, borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                : null
              }
            </div> :
            upperCabinTab ?
            <div style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
              <Nav tabs className="commons-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeCabinTab === 'main' })}
                    onClick={() => {
                      this.setState({
                        activeCabinTab: 'main',
                        activeTab: initMain[0].index,
                      })
                    }}
                  >
                    Main Deck
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeCabinTab === 'upper' })}
                    onClick={() => {
                      this.setState({
                        activeCabinTab: 'upper',
                        activeTab: initLower[0].index,
                      })
                    }}
                  >
                    Upper Deck
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeCabinTab}>
                  <TabPane tabId="main">
                    <Nav tabs className="commons-tabs">
                      { auditsInfo.map((key, index) =>
                        upGalleys.indexOf(key.id) == -1 ?
                        <NavItem>
                          <NavLink
                             className={classnames({ active: this.state.activeTab === index })}
                             onClick={() => { this.toggle(index); }}
                           >
                            {key.section_name?key.section_name:key.name}
                          </NavLink>
                        </NavItem>:null
                      )}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className={this.state.activeTab}>
                     { auditsInfo.map((key, index) =>
                       upGalleys.indexOf(key.id) == -1 ?
                        <TabPane tabId={index} key={index}>
                          {key.index}
                          <div>
                            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                              <p className="add-finding-link">
                                <Link onClick={() => this.props.findingCrudFn('add','', key.id )}>+ Add Finding</Link>
                              </p>:null
                            }
                            <table className="finding-tables">
                              <thead>
                                <tr>
                                  { ['SL No.','Type of Damage', 'Details',''].map((item, index) => <th key={index}>{item}</th>)}
                                </tr>
                              </thead>
                              <tbody>
                                { key.findings.map((item, index) =>
                                  <FindingList
                                    formInfo={formInfo}
                                    index = {index}
                                    findingCrudFn={() => this.props.findingCrudFn('edit', item, key.id)}
                                    item={item}
                                    toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                                  />
                                )}
                              </tbody>
                            </table>
                            { !(auditsInfo.length && key.findings && key.findings.length) ?
                              <NoRecordFound style={{ 'margin': 0, borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                              : null
                            }
                          </div>
                        </TabPane>:null
                      )
                    }
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="upper">
                    <Nav tabs className="commons-tabs">
                      { auditsInfo.map((key, index) =>
                        upGalleys.indexOf(key.id) != -1 ?
                        <NavItem>
                          <NavLink
                             className={classnames({ active: this.state.activeTab === index })}
                             onClick={() => { this.toggle(index); }}
                           >
                            {key.section_name?key.section_name:key.name}
                          </NavLink>
                        </NavItem>:null
                      )}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className={this.state.activeTab}>
                     { auditsInfo.map((key, index) =>
                       upGalleys.indexOf(key.id) != -1 ?
                        <TabPane tabId={index} key={index}>
                          <div>
                            { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                              <p className="add-finding-link">
                                <Link onClick={() => this.props.findingCrudFn('add','', key.id )}>+ Add Finding</Link>
                              </p>:null
                            }
                            <table className="finding-tables">
                              <thead>
                                <tr>
                                  { ['SL No.','Type of Damage', 'Details',''].map((item, index) => <th key={index}>{item}</th>)}
                                </tr>
                              </thead>
                              <tbody>
                                { key.findings.map((item, index) =>
                                  <FindingList
                                    formInfo={formInfo}
                                    index = {index}
                                    findingCrudFn={() => this.props.findingCrudFn('edit', item, key.id)}
                                    item={item}
                                    toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                                  />
                                )}
                              </tbody>
                            </table>
                            { !(auditsInfo.length && key.findings && key.findings.length) ?
                              <NoRecordFound style={{ 'margin': 0, borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                              : null
                            }
                          </div>
                        </TabPane>:null
                      )
                    }
                    </TabContent>
                  </TabPane>
              </TabContent>
          </div>:
          <div style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
            <Nav tabs className="commons-tabs">
              { Object.keys(auditsInfo).map(key =>
                <NavItem>
                  <NavLink
                     className={classnames({ active: this.state.activeTab === key })}
                     onClick={() => { this.toggle(key); }}
                   >
                    {auditsInfo[key]['section_name']?auditsInfo[key]['section_name']:auditsInfo[key]['name']}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={this.state.activeTab} className={this.state.activeTab}>
             { Object.keys(auditsInfo).map((key) =>
                <TabPane tabId={key}  className={key}>
                  <div>
                    { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                      <p className="add-finding-link">
                        <Link onClick={() => this.props.findingCrudFn('add','', auditsInfo[key]['id'] )}>+ Add Finding</Link>
                      </p>:null
                    }
                    <table className="finding-tables">
                      <thead>
                        <tr>
                          { ['SL No.','Type of Damage', 'Details',''].map((item, index) => <th key={index}>{item}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        { auditsInfo[key].findings.map((item, index) =>
                          <FindingList
                            formInfo={formInfo}
                            index = {index}
                            findingCrudFn={() => this.props.findingCrudFn('edit', item, auditsInfo[key].id)}
                            item={item}
                            toggleDelModal={() => this.toggleDelModal('Are you sure to delete?' , item)}
                          />
                        )}
                      </tbody>
                    </table>
                    { !(Object.keys(auditsInfo).length && auditsInfo[key].findings && auditsInfo[key].findings.length) ?
                      <NoRecordFound style={{ 'margin': 0, borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd'}}/>
                      : null
                    }
                  </div>
                </TabPane>
              )
            }
          </TabContent>
        </div>
        }
      </div>
      <DeleteModal isOpen={this.state.delModal}
        toggle={() => this.toggleDelModal()}
        title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal();this.props.deleteFindingFn(this.state.findingId)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
      </form>
    )
  }
}
