import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath,displayDateFormat } from '../../../constants';


export default class AssetPopDb extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {
		this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }


  render() {
    const {  tagetId, placement, fileNames } = this.props;
    return (
        <span>
       <span>
        <img width="12" className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath +'inv_info_icon.png'} alt="img" style={{ display: 'inline-block', marginRight: '10px',width:'15px'}}/>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
					<div className="project-tracker-popover">
                    <table className="table-sticky">
                    <thead>
                      <tr>
                        <th style={{paddingRight:'20px'}}>No of Files</th>
                        <th>Exp Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fileNames && fileNames.length > 0 ?
                        fileNames.map((sharedContent, index) =>
                        <tr>
                          <td>
                           {sharedContent[1]}
                          </td>
                          <td className="file-count" >
                            {moment(sharedContent[0]).format(displayDateFormat)}
                          </td>
                        </tr>
                        ):null
                      }
                    </tbody>
                  </table>
						</div>
            </PopoverBody>
        </Popover>
				</span>

     </span>

    );
  }
}
