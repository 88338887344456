
import React, { Fragment } from 'react';
import {  Row, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
export default function HlaRpt({exportObj, onChangeExportFeature, onChangeExtension, onChangeDate}){
  return(
    <Fragment>
      <div className="export-items">
        <Row className="flex-centered">
          <Col cs="5">
            <div className="">
              <label className="flex-centered export-label">
                <input type="checkbox"
                  onChange={(e) => onChangeExportFeature(e.target.checked, 'hla')}
                  checked={exportObj.contracts && exportObj.contracts.hla && exportObj.contracts.hla.export ? true:false}
                />
                <span>HLA Summary</span>
              </label>
            </div>
          </Col>
          <Col cs="4">
            <div className="export-avial">
              <h6>Export Format</h6>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <label>
                    <input
                      name="hla"
                      checked={(exportObj.contracts && exportObj.contracts.hla && exportObj.contracts.hla.extension == 'pdf')}
                      onChange={() => onChangeExtension('pdf', 'hla')}
                      type="radio"
                    />
                    <span>PDF</span>
                  </label>
                </li>
                <li className="list-inline-item">
                  <label>
                    <input
                      name="hla"
                      checked={(exportObj.contracts && exportObj.contracts.hla && exportObj.contracts.hla.extension == 'xls')}
                      onChange={() => onChangeExtension('xls', 'hla')}
                      type="radio"
                    />
                    <span>XL</span>
                  </label>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="filter-avail">
          <ul className="list-inline">
            <li className="list-inline-item">
              <label>As of Date</label>
              <DatePicker
                dateFormat = {fieldDateFormat}
                selected={moment(exportObj.contracts.hla.filter.projection_date)}
                onChange={(e) => onChangeDate(moment(e).format(backendDateFormat), 'projection_date', 'hla')}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
              />
            </li>
            <li className="list-inline-item"></li>
            <li className="list-inline-item"></li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}
