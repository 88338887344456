import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getGlobalCookie, getLocalStorageInfo, setGlobalCookie, authorizeAutoLogin, checkEnvironment } from './'

window.addEventListener('visibilitychange', checkLogin)

function checkLogin () {
    if(document.visibilityState || document.hasFocus()) {
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      let location = browserHistory.getCurrentLocation().pathname
      let lessorAccess = ''
      let redirectURI = browserHistory.getCurrentLocation().pathname
      if(redirectURI && redirectURI != '' && redirectURI !== '/login' && redirectURI !== '/select-lessor' && redirectURI !== '/signup'){
        setGlobalCookie('redirectURI', browserHistory.getCurrentLocation().pathname, 3)
      }
      if(getGlobalCookie('lessorAccess')){
        lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
      }
      if(!lessorAccess){
        localStorage.clear();
        sessionStorage.removeItem('initial_pop');
        if(!location.includes('login') && !location.includes('signup') && !location.includes('password/create') && !location.includes('create-successfully') && !location.includes('reset-successfully') && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('user/activate') && !location.includes('otp-verification') && !location.includes('forgot')  && !location.includes('view-shared')){
           browserHistory.push('/login');
           window.location.reload()
         }
      }else{
        if(checkEnvironment() === lessorAccess.environment){
          if(lessorAccess.id){
            if(!getLocalStorageInfo()){
               authorizeAutoLogin(lessorAccess)
             }else if(!location.includes('select-lessor') && !location.includes('otp')){
               if(lessorAccess.id !== userDetail.defaultLessor.id){
                 authorizeAutoLogin(lessorAccess)
               }
             }
          }else {
            if((userDetail && userDetail.defaultLessor == undefined) || (!userDetail && lessorAccess)){
              browserHistory.push( "/select-lessor" );
            }
          }
        }
      }
    }
}


function LoggedUserRed(ComposedComponent, extraInfo) {
 class LoginAccessCrtl extends Component {
   constructor(props) {
     super(props);
   }
   componentDidMount(){
     checkLogin()
   }
   render() {
     if(!localStorage.getItem('userInfo')) {
       return(<ComposedComponent {...this.props}/>)
     }else {
       return null
     }
   }
 }

  return connect(null, null)(LoginAccessCrtl);
}
export default LoggedUserRed;
