
import * as actions from '../actions';
import { globalGetService, globalFileUploadPutService, globalPostService, globalDeleteService, globalFileUploadService, globalPutService } from '../../../../../globalServices/';
import { toastFlashMessage, dataURItoBlob } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { TOGGLE_T004_T007 } from '../../T004/actions';
import { trackActivity } from '../../../../../utils/mixpanel';
const auditFindingInfo = {
  images: [],
  title: '',
  finding_parameters: '',
  finding_type: '',
  arm_srm_reference: '',
  damage_dimension: '',
  damage_limit: '',
  dd_wo_reference: '',
  remarks: '',
  corrective_actions:'',
  classifications:'',
  category:'',
  repair_type:'',
  intervals:''

}

export const fetchFindingSectionsAc = (props) => {
    return (dispatch) => {
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-sections/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FINDING_LIST,
            payload: response.data.data
          })
        }
        dispatch(triggerLoader(false));
      })
    }
  }

export const auditSectionInfoAc = (props, data) => {
    return (dispatch) => {
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+data.id+'/inspection-findings/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AUDITS_SECTIONS_INFO,
            payload:{flag: true, title: data.name, sectionId: data.id, type: 'edit', data: response.data.data}
          })
        }
        dispatch(triggerLoader(false));
      })
    }
  }
export const auditFindingDetailAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.AUDITS_FINDING_INFO,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+data.id+'/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AUDITS_FINDING_INFO,
            payload: {type: 'edit', flag: true, data: response.data.data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
      })
    }
  }
}
export const findingAddEditAc = (props, data, type, fileArr, sectionId) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    var formData = new FormData();
    for (let i = 0; i < fileArr.length; i++) {
      let file = fileArr[i];
      if (!file.type.match('image.*')) {
        toastFlashMessage( file.name + ' is not Valid Format', 'error');
         continue;
       }
     formData.append('files[]', file, file.name);
    }
    formData.append('arm_srm_reference', data.arm_srm_reference);
    formData.append('damage_dimension', data.damage_dimension);
    formData.append('damage_limit', data.damage_limit);
    formData.append('dd_wo_reference', data.dd_wo_reference);
    formData.append('finding_type', data.finding_type);
    formData.append('remarks', data.remarks);
    formData.append('corrective_actions', data.corrective_actions);
    formData.append('classifications',data.classifications);
    formData.append('category', data.category);
    formData.append('repair_type',data.repair_type);
    formData.append('intervals', data.intervals);

    var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        }
    }

    for (var value of formData.values()) {

    }
    if(type=='add'){
      globalPostService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/', formData, config)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ADD_FINDINGS_T007,
            payload: {sectionId:  sectionId , data: response.data.data}
          });
          toastFlashMessage(response.data.message, 'success');
          dispatch(fetchFindingSectionsAc(props));
          trackActivity('Finding Added', {
            page_title: `T007`,
            application_module: 'Inspection',
            item_type:'Pictorial Findings',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            finding_type:data.finding_type,
            aircraft_section: data.aircraft_section,
            item_id: data.id
          })
        }
        dispatch(triggerLoader(false));
        dispatch({type: TOGGLE_T004_T007, payload:{}});
      })
    }else{
      globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+data.id+'/', formData, config)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.UPDATE_FINDINGS,
            payload: {sectionId:  sectionId , data: response.data.data}
          });
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Finding Edited', {
            page_title: `T007`,
            application_module: 'Inspection',
            item_type:'Pictorial Findings',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            finding_type:data.finding_type,
            aircraft_section: data.aircraft_section,
            item_id: data.id
          })
        }
        dispatch(triggerLoader(false));
        dispatch({type: TOGGLE_T004_T007, payload:{}});
      })
    }
    dispatch(getFormDetailsAc(props));
  }
}


  export const editFindingImageSaveAc = (props, data, type, fileArr, sectionId) => {
    return (dispatch) => {
      if(type == 'add'){
        dispatch({
          type: 'UPDATE_IMAGE_IN_EDIT',
          payload: data
        });
      }else{
        dispatch(triggerLoader(true));
        var formData = new FormData();
        for (let i = 0; i < fileArr.length; i++) {
          let file = fileArr[i];
          if (!file.type.match('image.*')) {
            toastFlashMessage( file.name + ' is not Valid Format', 'error');
             continue;
           }
         formData.append('files[]', file, file.name);
        }
        var config = {
          onUploadProgress: function(progressEvent) {
              var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            }
        }
        globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+data.id+'/', formData, config)
        .then(response => {
          dispatch(triggerLoader(false));
          if(response.data.statusCode == 200){
            dispatch({
              type: 'UPDATE_IMAGE_IN_EDIT',
              payload: response.data.data.images
            });
            toastFlashMessage(response.data.message, 'success');

          }
          dispatch(usageDetailAc())
          dispatch(triggerLoader(false));
          dispatch(getFormDetailsAc(props));
        })
      }

    }
  }

  export const deleteImageAc = (props, picId, picIndex, type) => {
    return (dispatch) => {
      if(type == 'add'){
        dispatch({
          type: actions.DELETE_ADD_IMAGE,
          payload: {picId: picId, picIndex: picIndex}
        })
      }else{
        dispatch(triggerLoader(true));
        globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-picture/'+picId+'/', {})
        .then(response => {
          dispatch(triggerLoader(false));
          if(response.data.statusCode == 200){
            dispatch({
              type: actions.DELETE_IMAGE,
              payload: picId
            })
            toastFlashMessage(response.data.message, 'success');
          }
          dispatch(triggerLoader(false));
          dispatch(getFormDetailsAc(props));
        })
      }

    }
  }


  export const deleteFindingAc = (props, findingId, sectionId) => {
    return (dispatch) => {
      dispatch(triggerLoader(true));
      globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+findingId+'/', {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.DELETE_FINDING,
            payload: { findingId: findingId, sectionId: sectionId, finding_count: response.data.data.finding_count}
          })
          toastFlashMessage(response.data.message, 'success');
          dispatch(fetchFindingSectionsAc(props));
          trackActivity('Finding Deleted', {
            page_title: `T007`,
            application_module: 'Inspection',
            item_type:'Pictorial Findings',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            item_id: findingId
          })
        }
        dispatch(triggerLoader(false));
        dispatch(getFormDetailsAc(props));
      })
    }
  }


  export const editImageAc = (props, picId) => {
    return (dispatch) => {
      dispatch(triggerLoader(true));
      globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-picture/'+picId+'/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.EDIT_IMAGE,
            payload: ''
          })
          toastFlashMessage(response.data.message, 'success');
        }
        dispatch(triggerLoader(false));
        dispatch(getFormDetailsAc(props));
      })
    }
  }

  export const changePictureAc = (props,picId, fileArr, type ) => {
    if(type == 'add'){
      return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ADD_IMAGE,
        payload: fileArr
      })
    }
    }else{
      var formData = new FormData();
      if (fileArr[0].type.match('image.*')) {
        formData.append('file', fileArr[0], fileArr[0].name);
       }else{
         return false
       }

      if(!formData.get('file')){
        return false;
       }
       var config = {
        onUploadProgress: function(progressEvent) {
            var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            // dispatch({
            //   type: actions.PROGRESS_PERCENTAGE,
            //   payload: percentage
            // });
          }
      }
      return (dispatch) => {
        dispatch(triggerLoader(true));
        globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-picture/'+picId+'/', formData, config)
        .then(response => {
          if(response.data.statusCode == 200){
            dispatch({
              type: actions.CHANGE_IMAGE,
              payload: response.data.data
            })
            toastFlashMessage(response.data.message, 'success');

          }
          dispatch(triggerLoader(false));
          dispatch(getFormDetailsAc(props));
        })
      }
    }

  }

  export const triggerLoader = (flag) => {
    return (dispatch) => {
      dispatch({
        type: actions.FINDING_LOADER,
        payload: flag
      })
    }
  }

export const editFindingImageAc = (props, data) =>{
  return (dispatch) =>{
    dispatch(triggerLoader(true));
    let fileInfo = Object.assign({}, data);
    globalGetService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/get-pictures-url/`, {pic_id: data.imageId})
    .then(response => {
      if(response.data.statusCode == 200){
        fileInfo = {
          ...fileInfo,
          imageUrl: response.data.data
        }
        dispatch({
          type: actions.EDIT_FINDING,
          payload: {flag: true, data:fileInfo}
        })
      }
      dispatch(triggerLoader(false));
    })
  }
}

  export const editFindingSaveAc = (params, image, imageId) =>{
    let formData = new FormData();
    return(dispatch) => {
      dispatch(triggerLoader(true));
      dataURItoBlob(image).then(imageAnnotate => {
        formData.append('file', imageAnnotate);
        globalPutService(`technical/workorder/${params.workOrderSlug}/inspection-finding-picture/${imageId}/`,formData)
        .then((response) => {
          if(response.data.statusCode == 200){
            dispatch(
              {
                type: actions.EDIT_FINDING,
                payload: {title: '', flag: false, imageUrl: ''}
              }
            );
            dispatch(
              {
                type: actions.AUDITS_FINDING_INFO,
                payload: {title: '', flag: false, imageUrl: ''}
              }
            );
            toastFlashMessage(response.data.message, 'success');

          }
          dispatch(triggerLoader(false));
        })
      })
    }
  }
