import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { Row, Col } from 'reactstrap';
import { LoginSideBar, LoginForm } from '../components';
import { getLocalStorageInfo } from '../../utils';
import { authorizationAc } from '../actionCreators';
import { FORM_SUBMIT_LOADER } from '../actions';

class SelectLessor extends Component{
  constructor(props){
    super(props);
    this.state = {
      lessor: {},
      error: ''
    }
  }
  changeLessor = (option) => {
    this.setState({
      lessor: option ? option:'',
      error: ''
    });
  }
  updateLessor = (e) => {
    e.preventDefault();
    if(Object.keys(this.state.lessor).length){
      this.props.formSubmitLoader(true);
      this.props.updateAuthToken(this.state.lessor);
    }else{
      this.setState({
        error: 'Please Select Lessor'
      });
    }

  }
  render(){
    const userInfo = getLocalStorageInfo();
    return(
      <Row className="flex-not-centered login-wrap">
        <LoginSideBar />
        <Col md="6" className="flex-centered rh-block">
          <div className="form-block">
            <div className="login-heading-block">
              <h2 className="wc-login-block">You are signed in to SPARTA!</h2>
              <h6 className="continue-subheading">
                Please choose an account to proceed
              </h6>
            </div>
            <form>
              <div className="form-group">
                <label>Select Lessor Account</label>
                <Select
                  name="form-field-name"
                  value={this.state.lessor.id ? this.state.lessor.id:''}
                  onChange={this.changeLessor}
                  options={userInfo.lessor_list}
                  placeholder = '--'
                  className="custom-select-block"
                  labelKey = "name"
                  valueKey = "id"
                />
              <h6 className="error-msg">{this.state.error}</h6>
              </div>
              <div className="input-group">
                <button onClick={this.updateLessor} disabled={this.props.loginLoader}>
                {this.props.loginLoader?'Processing...': 'Continue' }
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    )
  }
}
const mapStateToProps = state => ({
  loginLoader: state.userAssetReducer.loginLoader
});
const mapDispatchToProps = dispatch => {
  return {
    updateAuthToken:(lessor, pageType) => dispatch(authorizationAc(lessor, pageType)),
    formSubmitLoader: (flag) => dispatch({
      type:FORM_SUBMIT_LOADER,
      payload: flag
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectLessor);
