import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements' 

export default class AvionicsTab extends Component {
	constructor(props) {
		super(props)
	}
	render() {
		const { avionicList, index, asset_slug } = this.props;
		const avionicViewObj = [
			{value: avionicList.ata_code ? avionicList.ata_code : '--'},
			{value: avionicList.item ? avionicList.item : '--'},
			{value: avionicList.item_description ? avionicList.item_description : '--'},
			{value: avionicList.manufacturer ? avionicList.manufacturer : '--'},
			{value: avionicList.model ? avionicList.model : '--'},
			{value: avionicList.part_number ? avionicList.part_number : '--'},
			{value: avionicList.quantity ? avionicList.quantity : '--'},
	];
		return (
			<tr onClick={() => this.props.viewAvionic(asset_slug ,avionicList.id, index, 'viewAvionic')}>
				{avionicViewObj.map((avionic,index) => <ContrListCol key={index} colObj={avionic}  />)}
			</tr>
		)
	}
}