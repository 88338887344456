import * as actions from '../actions';
const crud = {
  flag: false,
  data: {},
  type: ''
}
export default function( state = {
  shopVisitLoader:false,
  shopVisitCrud: crud,
  shopVisitDetails: {},
  genErr: {},
  shopVisitErr: {},
  detailErr: {}
}, action ){
  switch (action.type) {
    case actions.ADD_EDIT_SHOPVISIT:
      return{
        ...state,
        shopVisitCrud: action.payload
      }
   case actions.GET_SHOPVISIT_DETAILS:
     return {
       ...state,
       shopVisitDetails: action.payload
     } 
   case actions.UPDATE_GENERAL_DETLS_FORM:
    return {
      ...state,
      shopVisitDetails: {
        ...state.shopVisitDetails,
        details : {
          ...state.shopVisitDetails.details,
          [action.payload.type] : action.payload.value
        }
      },
      detailErr : {
        [action.payload.type]: ''
      }
    } 
   case actions.UPLOAD_FILE:
    return {
    ...state,
    shopVisitCrud: {
      ...state.shopVisitCrud,
      data : {
        ...state.shopVisitCrud.data,
        attachments: [ ...state.shopVisitCrud.data.attachments, action.payload].filter(data => data !== undefined)
      }
    }

  } 
 case actions.UPDATE_SHOPVISIT_FORM:
 return {
  ...state,
  shopVisitCrud: {
    ...state.shopVisitCrud,
    data : {
      ...state.shopVisitCrud.data,
      [action.payload.key] : action.payload.value
    }
  },
  shopVisitErr : {
    ...state.shopVisitErr,
    [action.payload.key]: ''
  }
 } 
 case actions.UPDATE_DESCRIPTION: 
 return {
  ...state,
  shopVisitCrud: {
    ...state.shopVisitCrud,
    data : {
      ...state.shopVisitCrud.data,
      attachments:  [...state.shopVisitCrud.data.attachments].map((attachmentData, dataIndex) => dataIndex ==  action.payload.index ? {
        ...attachmentData,
        [action.payload.key] : action.payload.value
      }: attachmentData)
    }
  }
 }
 case actions.ADD_SHOPVISIT: 
   return {
   ...state, 
   shopVisitDetails : {
    ...state.shopVisitDetails,
    shop_visits: [...state.shopVisitDetails.shop_visits, action.payload],
   },
   shopVisitCrud: crud
 }
 case actions.UPDATE_SHOPVISIT:
  return {
    ...state,
    shopVisitDetails: {
      ...state.shopVisitDetails,
      shop_visits : [...state.shopVisitDetails.shop_visits].map(shopVisit => shopVisit.id == action.payload.id ?  action.payload : shopVisit )
    },
    shopVisitCrud: crud
  }
case actions.DELETE_SHOPVISIT:
  return {
    ...state,
    shopVisitDetails: {
      ...state.shopVisitDetails,
      shop_visits : [...state.shopVisitDetails.shop_visits].filter(shopVisit => shopVisit.id != action.payload)
    }
  }
 case actions.DELETE_SHOPVISIT_ATTACHMENT:
  return {
    ...state,
    shopVisitCrud: {
      ...state.shopVisitCrud,
      data : {
        ...state.shopVisitCrud.data,
        attachments:  [...state.shopVisitCrud.data.attachments].filter((attachmentData, dataIndex) => dataIndex !=  action.payload)
      }
    }
  }  
 case actions.UPDATE_SHOP_VISIT_ERR: 
  return {
    ...state,
    shopVisitErr :  action.payload
  }
 case actions.GENERAL_ERR_UPDATE: 
  return {
    ...state,
    detailErr: action.payload
  } 
 case actions.SHOP_VISIT_LOADER:
    return{
      ...state,
      shopVisitLoader: action.payload
    }
 default:
      return state;
  }
}
