import * as actions from '../actions';
export default function( state = {
  conventionData:[],
  conventionLoader: true,
  conventionEditFlag: false

}, action){
  switch (action.type) {
    case actions.CONVENTION_LIST:
      return {
        ...state,
        conventionData: action.payload
      }
      case actions.CONVENTION_LOADER:
      return {
        ...state,
        conventionLoader: action.payload
      }
      case actions.CONVENTION_EDIT:
      
      return {
        ...state,
        conventionEditFlag: action.payload
      }

      default:
      return state;
  }
}
