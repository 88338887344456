import * as actions from '../actions';

export default function (state = {
  bathcesList: [],
  organisedList:{
    pagination:{},
    list:{}
  },
  unorganisedList:{
    pagination:{},
    files:{}
  },
  batchLoader: false,
  folderDirTree: {},
  batchDetail: {}
}, action){
  switch (action.type) {
    case actions.BATCHES_LIST:
      return{
        ...state,
        bathcesList: action.payload
      }
    case actions.BATCH_DIRECTORY:
      return{
        ...state,
        folderDirTree: action.payload
      }
    case actions.BATCH_DETAIL:
      return{
        ...state,
        batchDetail: action.payload
      }
    case actions.UNORGANISSED_BATCHES_LIST:
      return{
        ...state,
        unorganisedList: action.payload
      }
    case actions.ORGANISSED_BATCHES_LIST:
      return{
        ...state,
        organisedList: action.payload
      }
    case actions.BATCH_LOADER:
    return{
      ...state,
      batchLoader: action.payload
    }
    default:
    return state
  }
}
