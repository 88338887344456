import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import 'react-select/dist/react-select.css';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea,  FieldInput, FieldSelect } from '../../contracts/Elements';
import { checkString, checkNumber } from '../../../formValidator';

export default class AddEditPortfolio extends Component {
  constructor(props){
    super(props);
    this.state = {
      portfolio: {},
      error:{}
    }
  }
  componentDidMount(){
    this.setState({
      portfolio: this.props.portfolioObj
    });
    if(this.props.type != "add"){
      this.setState({
        portfolio:{
          name: this.props.portfolioObj.name,
          portfolio_type: this.props.portfolioObj.portfolio_type.value,
          status: this.props.portfolioObj.status.value,
          description: this.props.portfolioObj.description,
          credit_rating: this.props.portfolioObj.credit_rating,
          portfolio_owner: this.props.portfolioObj.portfolio_owner,
          slug: this.props.portfolioObj.slug
        }
      })
    }
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      portfolio: {
        ...prevState.portfolio,
        [type]: value
      },
      error:{
        ...prevState.error,
        [type]:''
      }
    }))
  }


  addEditPortfolio = (e) => {
    e.preventDefault();
    let validateNewInput = {};
      validateNewInput = {
        name: checkString({value: this.state.portfolio.name, required: true, minLength: '', maxLength: '', message: 'Please enter Portfolio name'}),
        status: checkNumber({value: this.state.portfolio.status, required: true, minLength: '', maxLength: '', message: 'Please select portfolio status'}),
      };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar()
      }
      this.props.addEditPortfolio(this.state.portfolio,this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { portfolio, error } = this.state;
    const { type, portfolio_type, portfolio_status } = this.props;
    if(!Object.keys(portfolio).length){
      return <h4 className="text-center">Loading ...</h4>
    }
    return (
      <form onSubmit={(e) => this.addEditPortfolio(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Name
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={portfolio.name}
                  type={type}
                  keyParam="name"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.name}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Description
                </FieldLabel>
                <FieldTextarea
                  value={portfolio.description}
                  type={type}
                  keyParam="description"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Portfolio Owner
                </FieldLabel>
                <FieldInput
                  value={portfolio.portfolio_owner}
                  type={type}
                  keyParam="portfolio_owner"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Portfolio Status
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                    name="form-field-name"
                    keyParam="status"
                    value={portfolio.status}
                    options={portfolio_status}
                    placeholder = "select portfolio status"
                    matchProp = "any"
                    multi = {false}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                />
                <h6 className="error-msg">{error.status}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Portfolio Type
                </FieldLabel>
                <FieldSelect
                    name="form-field-name"
                    value={portfolio.portfolio_type}
                    options={portfolio_type}
                    placeholder = "select portfolio type"
                    matchProp = "any"
                    multi = {false}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                    keyParam="portfolio_type"
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Credit Rating
                </FieldLabel>
                <FieldInput
                  value={portfolio.credit_rating}
                  type={type}
                  keyParam="credit_rating"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { this.props.type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
