export const TYPE_LESSOR_UPDATE  = 'TYPE_LESSOR_UPDATE';
export const LESSOR_FIELD_UPDATE = 'LESSOR_FIELD_UPDATE';
export const LESSOR_PROCESSING='LESSOR_PROCESSING';
export const LESSOR_LOADER='LESSOR_LOADER';
export const UPDATE_LESSOR_DATA='UPDATE_LESSOR_DATA';
export const OWNERS_LIST='OWNERS_LIST';
export const BANKING_DETAILS_LIST='BANKING_DETAILS_LIST';
export const BANKING_DROPDOWN_LIST='BANKING_DROPDOWN_LIST';
export const OWNER_DROPDOWN_LIST='OWNER_DROPDOWN_LIST';
export const ADD_OWNER='ADD_OWNER';
export const EDIT_OWNER='EDIT_OWNER';
export const ADD_BANKING_DETAILS='ADD_BANKING_DETAILS';
export const EDIT_BANKING_DETAILS='EDIT_BANKING_DETAILS';
export const DELETE_OWNER='DELETE_OWNER';
export const BANK_DROPDOWN = 'BANK_DROPDOWN'
export const OWNER_DROPDOWN = 'OWNER_DROPDOWN'
