import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router';
import {Row, Col } from 'reactstrap';
import { connect } from 'react-redux'
import DatePicker from "react-datepicker";
import {TableUIComp, ListLoader, NoRecordFound} from '../../../../shared';
import {imgStoragePath, fieldDateFormat, backendDateFormat, emailRegx} from '../../../../constants';
import { EditFormBar } from '../../../technicalInspection/Elements';
import { removeEmptyKey } from '../../../../utils';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import {ActivityList} from '../components';
import {activityLogHd} from '../index';
import { getActivityLogsApi, exportActivityLogApi, getUsersListApi, getActivityTypeListApi } from '../apiServices';
import Select from 'react-select';
import { RecordsSideBar } from '../../Elements';
import InfiniteScroll from 'react-infinite-scroll-component';
class Activity extends Component {
  constructor(props){
    super(props);
    this.state = {
      activityList:{
        list:[],
        pagination:{}
      },
      filter:{
        user_id:null,
        email_id:null,
        activity_type:null,
        start_date: null,
        end_date: null
      },
      appliedFilter:{
        user_id:null,
        email_id:null,
        activity_type:null,
        start_date: null,
        end_date: null
      },
      openFilterSidebar:false,
      pageLoader:false,
      users: [],
      activity:[]
    }
    this.getActivityLogsApi = getActivityLogsApi.bind(this);
    this.exportActivityLogApi = exportActivityLogApi.bind(this);
    this.getUsersListApi = getUsersListApi.bind(this);
    this.getActivityTypeListApi = getActivityTypeListApi.bind(this);
  }
  componentDidMount(){
    this.getActivityLogsApi(this.props);
    this.props.fetchAssetInfo();
    this.getUsersListApi();
    this.getActivityTypeListApi();
  }
  moveNextPage = () =>{
    const {filter}=this.state;
    this.getActivityLogsApi(this.props, removeEmptyKey({page: parseInt(this.state.activityList.pagination.current_page) + 1, ...filter}));
  }

  onFieldChange=(value, key)=>{
    this.setState(prevState =>({
      ...prevState,
      filter:{
        ...prevState.filter,
        [key]:(value == 'Invalid date' ? null:value)
      },
    }))
  }
  applyFilter=()=>{
    this.setState({activityList:{
      list:[],
      pagination:{}
    }}, () => {
      var filter=this.state.filter;
      delete filter.page
      this.getActivityLogsApi(this.props, removeEmptyKey(filter));
    })
  }

  clearFilter = (key) => {
    this.setState({activityList:{
      list:[],
      pagination:{}
    }}, () => this.setState({filter:{...this.state.filter, [key]: null}}, () => this.getActivityLogsApi(this.props, removeEmptyKey(this.state.filter))))
  }

  render() {
      const {activityList, pageLoader, users, filter, openFilterSidebar, appliedFilter, activity} = this.state;
      const { ctrShortInfo } = this.props;
    return(
      <div>
        <RecordsSideBar />
          { Object.keys(ctrShortInfo).length ?
            <ShortHead
              headingText="Records"
              changeMsn={this.changeMsn}
              subHeaderTopMargin={40}
              contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
          }
          <section className="activity-log-section">
            { pageLoader ? <ListLoader />:null}
            <div className="activity-heading">
                <Row className="flex-centered">
                    <Col md="8">
                      <h4>Activity Log</h4>
                      <ul className="list-inline add-filter">
                        {
                          appliedFilter.start_date && appliedFilter.start_date !== '' ?
                            <li className="list-inline-item applied-filter">
                                Start Date: {moment(appliedFilter.start_date).format('DD-MMM-yyyy')}
                              <span onClick={() => this.clearFilter('start_date')}>x</span>
                            </li>
                          :null
                        }
                        {
                          appliedFilter.end_date && appliedFilter.end_date !== '' ?
                            <li className="list-inline-item applied-filter">
                                End Date: {moment(appliedFilter.end_date).format('DD-MMM-yyyy')}
                              <span onClick={() => this.clearFilter('end_date')}>x</span>
                            </li>
                          :null
                        }
                        {
                          appliedFilter.email_id && appliedFilter.email_id !== '' ?
                            <li className="list-inline-item applied-filter">
                                Email: {appliedFilter.email_id}
                              <span onClick={() => this.clearFilter('email_id')}>x</span>
                            </li>
                          :null
                        }
                        {
                          appliedFilter.user_id !== undefined && appliedFilter.user_id !== null && appliedFilter.user_id !== '' ?
                            <li className="list-inline-item applied-filter">
                                User: {users.filter(user => user.id === appliedFilter.user_id).length ? users.filter(user => user.id === appliedFilter.user_id)[0].name : ""}
                              <span onClick={() => this.clearFilter('user_id')}>x</span>
                            </li>
                          :null
                        }
                        {
                          appliedFilter.activity_type && appliedFilter.activity_type !== '' ?
                            <li className="list-inline-item applied-filter">
                                Activity: {activity.filter(act => act.value === appliedFilter.activity_type).length ? activity.filter(act => act.value === appliedFilter.activity_type)[0].label : ""}
                              <span onClick={() => this.clearFilter('activity_type')}>x</span>
                            </li>
                          :null
                        }
                      </ul>
                    </Col>
                    <Col md="4">
                      {
                        !pageLoader ?
                          <ul className="list-inline">
                            <li className="list-inline-item download-block">
                              <span className="filter-activity" onClick={() => this.setState({openFilterSidebar: true, filter: appliedFilter})}>
                                <img style={{marginRight: '4px', verticalAlign: 'baseline'}} width="10" src={imgStoragePath+"black_plus.png"} alt="Filter" />Add Filter
                              </span>
                            </li>
                            {
                              activityList && activityList.list.length ?
                              <li className="list-inline-item download-block">
                                <span onClick={() => this.exportActivityLogApi(this.props, removeEmptyKey({download:'xls', ...filter}))}>
                                  <img style={{marginRight: '4px'}} width="15" src={imgStoragePath+"export_icon.png"} alt="export" />Export Activity Log
                                </span>
                              </li>
                              :null
                            }
                          </ul>
                        :null
                      }
                    </Col>
                </Row>
              </div>
              <InfiniteScroll
                dataLength={activityList.list.length}
                next={() => this.moveNextPage()}
                hasMore={activityList.list.length < activityList.pagination.total}
                className="scrollbar-hidden"
                height={window.innerHeight - 240}
                endMessage={
                  activityList.pagination.current_page > 1 ?
                  <p style={{textAlign:'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                  :null
                }
              >
                <TableUIComp
                  theads={activityLogHd}
                  content={activityList.list.map((item, index) =>
                  <ActivityList
                      item={item}
                      index={index}
                      recordsFound = {activityList.list.length ? false : true}
                  />
                  )}
                  recordsFound = { pageLoader ? false : activityList.list.length ? false:true }
              />
              </InfiniteScroll>
              <EditFormBar
                titleContent={<h4 style={{fontSize: '18px'}}>Filter By</h4>}
                toggleEditSideBar={() => this.setState({ openFilterSidebar: false, filter: appliedFilter })}
                isOpen={openFilterSidebar}
                style={openFilterSidebar ? { width: '400px' } : { width: '0px' }}
              >
                <div className="sidebar-form">
                  <ul className="list-unstyled">
                        <li className="filter-list">
                          Start Date:
                          <DatePicker
                            dateFormat = {fieldDateFormat}
                            placeholderText="Start Date"
                            showYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            maxDate={moment()}
                            selected={appliedFilter.start_date ? moment(appliedFilter.start_date) : filter.start_date ? moment(filter.start_date): null}
                            onChange={(e) => this.onFieldChange(moment(e).format(backendDateFormat), 'start_date')}
                          />
                        </li>
                        <li className="filter-list">
                          End Date:
                          <DatePicker
                            dateFormat = {fieldDateFormat}
                            placeholderText="End Date"
                            showYearDropdown
                            showMonthDropdown
                            minDate={filter.start_date ? moment(filter.start_date): null}
                            useShortMonthInDropdown
                            selected={filter.end_date ? moment(filter.end_date): null}
                            onChange={(e) => this.onFieldChange(moment(e).format(backendDateFormat), 'end_date')}
                          />
                        </li>
                        <li className="filter-list">
                          <p>Activity:</p>
                          <Select
                            placeholder="Select Activity"
                            name="form-field-name"
                            value={appliedFilter.activity_type ? appliedFilter.activity_type :filter.activity_type}
                            options={activity}
                            valueKey="value"
                            labelKey="label"
                            onChange={(item) => this.onFieldChange(item ? item.value : null , 'activity_type')}
                          />
                        </li>
                        <li className="filter-list">
                          <p>User:</p>
                          <Select
                            placeholder="Select User"
                            name="form-field-name"
                            value={appliedFilter.activityType ? appliedFilter.user_id :filter.user_id}
                            options={users}
                            valueKey="id"
                            labelKey="name"
                            onChange={(item) => this.onFieldChange(item ? item.id : null , 'user_id')}
                          />
                        </li>
                        <li className="filter-list" style={{textAlign: 'center', marginBottom: 'auto'}}>
                          OR
                        </li>
                        <li className="filter-list">
                          <p>Email:</p>
                          <textarea
                            Placeholder="Please Type Email"
                            style={{ resize:'inherit', height:'30px'}}
                            rows="2"
                            value={appliedFilter.email_id ? appliedFilter.email_id : filter.email_id }
                            onChange={(e) => this.onFieldChange(e.target.value, 'email_id')}
                          />
                        <h6 className="error-msg">{filter.email_id && filter.email_id.length > 0 && !emailRegx.test(filter.email_id) ? 'Please enter proper email' : ''}</h6>
                        </li>
                    </ul>
                    <div className="sidebar-footer">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <input disabled={filter.email_id && filter.email_id.length > 0 && !emailRegx.test(filter.email_id)} type="submit" className="primary-btn" value="Apply Filter" onClick={this.applyFilter}/>
                        </li>
                      </ul>
                    </div>
                </div>
              </EditFormBar>
          </section>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return{
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params)),
  }
}

const mapStateToProps = (state) => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));
