import React, { Component, Fragment } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router';
import moment from 'moment';
import { displayDateFormat } from '../../../constants';
import { imgStoragePath } from '../../../constants';
export default class MntCollapse extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  render(){
    const { assetInfo } = this.props;
    return(
      <div className="assembly-collapser">
        <h4>
          <Link to={this.props.url}>
          {(() => {
            if(assetInfo.asset_type === 1) {
              return (
                `MSN ${assetInfo.msn}`
              )
            }else if (assetInfo.asset_type === 2) {
              return (
                `ESN ${assetInfo.esn}`
              )
            }else if (assetInfo.asset_type === 3) {
              return(
                `Serial No. ${assetInfo.serial_number}`
              )
            }else if (assetInfo.asset_type === '4') {
              return(
                `Serial No. ${assetInfo.serial_number}`
              )
            }else {
              return (
                <div>catch all</div>
              )
            }
          })()}

          </Link>
          {this.props.name ? <span> ({this.props.name})</span>:null}
          { !this.state.collapse ?
            <p>Next {this.props.type} Date: {moment(this.props.date).format(displayDateFormat)}</p>:null
          }
          { moment() > moment(this.props.date)?
            <p className="error-msg" style={{fontSize: '12px'}}>Shop visit is overdue! Please update historical shop visit data under Maintenance of the asset.</p>:null
          }
          {
            this.props.exceeding_contract?
            <p style={{color: '#DFD700', marginTop: '6px'}}>Upcoming shop visit exceeds active contract end date</p>:null
          }
          <img onClick={this.toggle} className="collapse-arw-img" style={ !this.state.collapse ? {transform: 'rotate(180deg)'}:{transform: 'rotate(0deg)'}} src={imgStoragePath + "collapse_arw.png"} alt="icon" />
        </h4>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
              {this.props.children}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    )
  }
}
