import * as actions from '../actions';
const numberRegx = /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/;
export default function( state = {
  mrAccuralAsset: {},
  mrAccuralListInfo : [],
  cashflowMrAccrual : [],
  mrAccrulsProjection: {
    data: {}
  },
  simulatedData: {},
  simulationType: 0,
  mrAccuralLoader: false,
  cashflowMonthlyBreakup : [],
  monthlyModal:false,
  lifeOnWings: {}
}, action){
  switch (action.type) {
    case actions.MR_ACCURAL_ASSET_INFO:
      return {
        ...state,
        mrAccuralAsset: action.payload
      }
    case actions.MR_ACCURAL_LIST_INFO:
      return {
        ...state,
        mrAccuralListInfo: action.payload
      }
    case actions.MR_ACCRUAL_PROJECTION:
      return {
        ...state,
        mrAccrulsProjection: {
          ...state.mrAccrulsProjection,
          selectedAirframeEvent:action.payload.airframe ? Object.keys(action.payload.airframe)[0]:'',
          selectedEngineNo: action.payload.engines ? Object.keys(action.payload.engines.engine_data)[0]:'',
          selectedLg: action.payload.landing_gears ? Object.keys(action.payload.landing_gears)[0]:'',
          data: action.payload
        }
      }
    case actions.ACCRUAL_PROGRAM_UPDATE:
      return {
        ...state,
        mrAccrulsProjection: {
          ...state.mrAccrulsProjection,
          [action.payload.programType]: action.payload.label
        }
      }
    case actions.MR_ACCURAL_LOADER:
      return {
        ...state,
        mrAccuralLoader: action.payload
      }
    case actions.MR_ACCRUAL_CASH_FLOW:
      return {
        ...state,
        cashflowMrAccrual: action.payload
      }

    case actions.CASH_FLOW_MONLTY_BREAKUP_LIST:
      return {
        ...state,
        cashflowMonthlyBreakup: action.payload,
      }
    case actions.MONTHLY_TOGGLE_DATA:
      return {
        ...state,
        monthlyModal: action.payload
      }
    default:
      return state;
  }
}
