import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router';
import { Row, Col } from 'reactstrap'
import { getLocalStorageInfo } from '../../../../utils'
import InvFields from './InvFields'
class BankingInfo extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { type, invoiceInfo, error } = this.props
    const numericWithHyphen = /^[\d\-]+$/
    if(type === 'view'){
      return(
        <div className="bank-detail-block ">
          <Row>
            <InvFields label="Name" colSize="4" value={invoiceInfo.bank_name?invoiceInfo.bank_name:'--'}  />
            <InvFields label="Account Name" colSize="4" value={invoiceInfo.bank_account_name?invoiceInfo.bank_account_name:'--'}  />
            <InvFields label="Account Number" colSize="4" value={invoiceInfo.bank_account_number?invoiceInfo.bank_account_number:'--'}  />
            <InvFields label={getLocalStorageInfo().defaultLessor.id==14?"ABA Code":"IBAN Code"} colSize="4" value={invoiceInfo.bank_iban?invoiceInfo.bank_iban:'--'}  />
            <InvFields label="SWIFT Code" colSize="4" value={invoiceInfo.bank_swift_code?invoiceInfo.bank_swift_code:'--'}  />
            <InvFields label="SORT Code" colSize="4" value={invoiceInfo.bank_sort_code?invoiceInfo.bank_sort_code:'--'}  />
            {[14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
                <Fragment>
                  <InvFields label="VAT number" colSize = "4"  value={invoiceInfo.vat_number? invoiceInfo.vat_number:'--'}  />
                  <InvFields label="Hub account" colSize = "4"  value={invoiceInfo.hub_number? invoiceInfo.hub_number:'--'}  />
                  <InvFields label="Customer Reference Number" colSize ="4"  value={ invoiceInfo.customer_reference_number ? invoiceInfo.customer_reference_number  :'--'}  />
                </Fragment>
                :null
              }
            <InvFields label="Footer Note" colSize="8" value={invoiceInfo.footer_note?invoiceInfo.footer_note:'--'}  />
          </Row>
        </div>
      )
    }else{
      return(
        <div className="bank-detail-block">
          <Row>
            <Col md="4">
              <div className="form-group">
                <label className="label">Name</label>
                <input type="text" value={invoiceInfo.bank_name}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'bank_name')}

                />
                <h6 className="error-msg">{error.bank_name}</h6>
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="label">Account Name</label>
                <input type="text" value={invoiceInfo.bank_account_name}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'bank_account_name')}
                />
                 <h6 className="error-msg">{error.bank_account_name}</h6>
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="label">Account Number</label>
                <input type="text" value={invoiceInfo.bank_account_number}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'bank_account_number')}
                />
                 <h6 className="error-msg">{error.bank_account_number}</h6>
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="label">{getLocalStorageInfo().defaultLessor.id==14?"ABA Code":"IBAN Code"}</label>
                <input type="text" value={invoiceInfo.bank_iban}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'bank_iban')}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="label">SWIFT Code</label>
                <input type="text" value={invoiceInfo.bank_swift_code}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'bank_swift_code')}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="label">SORT Code</label>
                <input
                  type="text"
                  value={invoiceInfo.bank_sort_code}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'bank_sort_code')}
                  maxLength="20"
                />
              </div>
            </Col>
            {[14,242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <Fragment>
                <Col md="4">
                  <div className="form-group">
                    <label className="label">Hub Account</label>
                    <input
                      type="text"
                      value={invoiceInfo.hub_number}
                      onChange={(e) =>  this.props.updateInvoiceField(e.target.value, 'hub_number') }
                      maxLength="20"
                    />
                  </div>
                </Col><Col md="4">
                  <div className="form-group">
                    <label className="label">VAT Number</label>
                    <input type="text" value={invoiceInfo.vat_number}
                      onChange={(e) => this.props.updateInvoiceField(e.target.value, 'vat_number')}
                    />
                  </div>
                </Col><Col md="4">
                  <div className="form-group">
                    <label className="label">Customer Reference Number</label>
                    <input type="text" value={invoiceInfo.customer_reference_number}
                      onChange={(e) => this.props.updateInvoiceField(e.target.value, 'customer_reference_number')}
                    />
                  </div>
                </Col>

              </Fragment> : null

            }
            <Col md="8">
              <div className="form-group">
                <label className="label">Footer Note</label>
                <input type="text" value={invoiceInfo.footer_note}
                  onChange={(e) => this.props.updateInvoiceField(e.target.value, 'footer_note')}
                />
              </div>
            </Col>
          </Row>
        </div>
      )
    }
  }
}
export default withRouter(BankingInfo);
