import React from 'react';
import { Col } from 'reactstrap';
const FieldCol = (props) => {
  return(
    <Col {...props}>
      {props.children}
    </Col>
  )
}
export default FieldCol;
