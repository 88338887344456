import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { claimStatusDrpdwn, float2DecRegx } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils'
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
import moment from 'moment'
export default class AddEditClaim extends Component {
  constructor(props){
    super(props);
    this.state = {
      claim: {},
      remaining_amount: 0,
      error: {},
      apporveStatus: 'view',
      policy_start_date: '',
      policy_end_date: '',
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.claimObj));
    data = {
      ...data,
      status: data.status && data.status.value ? data.status.value : '',
      status_name: data.status && data.status.label ? data.status.label : ''
    }
    this.setState({
      claim: data,
      apporveStatus: data.status.value === 3 || data.status.value === 4 ? 'view' : 'edit',
      remaining_amount: data.amount
    });
  }
  updateForm = (type, value) => {
    if(type === 'insurance'){
      let insurance = this.props.insurance.filter(item => item.id === value)
      this.setState(prevState => ({
        ...prevState,
        claim: {
          ...prevState.claim,
          insurer: insurance[0] ? insurance[0].insurer : '',
          insuree: insurance[0] ? insurance[0].insuree : '',
          policy_start_date: insurance[0] ? insurance[0].policy_start_date : '',
          policy_end_date: insurance[0] ? insurance[0].policy_end_date : '',
        },
        remaining_amount: insurance[0] ? insurance[0].remaining_amount : 0
      }))
    }
    if(type === 'amount'){
      if(float2DecRegx.test(value)){
        this.setState(prevState => ({
          ...prevState,
          claim: {
            ...prevState.claim,
            [type]: (value == 'Invalid date' ? null:value)
          },
          error: {
            ...prevState.error,
            [type]:''
          }
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        claim: {
          ...prevState.claim,
          [type]: (value == 'Invalid date' ? null:value)
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    }
  }
  addEditClaim = (e) => {
    e.preventDefault();
    const claim = this.state.claim;
    var validateNewInput = {
      insurance: checkString({value: claim.insurance, required: true, minLength: '', maxLength: '', message: 'Please select Insurance Number'}),
      insurer: checkString({value: claim.insurer, required: true, minLength: '', maxLength: '', message: 'Please Enter The Insurer'}),
      claim_summary: checkString({value: claim.claim_summary, required: true, minLength: '', maxLength: '', message: 'Please enter Claim Details'}),
      claim_number: checkString({value: claim.claim_number, required: true, minLength: '', maxLength: '', message: 'Please enter Claim Number'}),
      amount: checkNumber({value: claim.amount, required: true, minLength: '', maxLength: '', message: 'Please enter Claim Amount'}),
    };
    if(this.props.type !== 'view'){
      validateNewInput = {
        ...validateNewInput,
        approver_id: checkString({value: claim.approver_id, required: true, minLength: '', maxLength: '', message: 'Please select Approver'}),
      }
    }
    if(this.props.type === 'view' && claim.approver_id === getLocalStorageInfo().user.id){
      validateNewInput = {
        ...validateNewInput,
        status: checkString({value: claim.status, required: true, minLength: '', maxLength: '', message: 'Please select Claim Status'}),
      }
    }
    if(claim.status === 4){
      validateNewInput = {
        ...validateNewInput,
        rejection_reason: checkString({value: claim.rejection_reason, required: true, minLength: '', maxLength: '', message: 'Please enter reason for rejection'}),
      }
    }
    if(claim.amount > this.state.remaining_amount){
      validateNewInput = {
        ...validateNewInput,
        amount: 'Please enter the claim amount less than or equal to ' + this.state.remaining_amount
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditClaim(this.state.claim, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  render() {
    const { type, techConstants } = this.props;
    const { claim, error, apporveStatus } = this.state;
    var approvers = []
    this.props.approvers.map(user => {
      approvers.push({id: user.id, name: user.first_name + ' ' + user.last_name})
    })
    return (
      <form onSubmit={(e) => this.addEditClaim(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Insurance Number
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Insurance'
                  keyParam="insurance"
                  value={claim.insurance}
                  type={type}
                  options={this.props.insurance}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                  name={claim.insurance_number}
                />
              <h6 className="error-msg">{error.insurance}</h6>
              </FieldGroup>
            </FieldCol>
            {

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Claim Number
                    {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldInput
                    value={claim.claim_number}
                    type={type}
                    keyParam="claim_number"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.claim_number}</h6>
                </FieldGroup>
              </FieldCol>

            }
            {
              claim.insurance !== '' && claim.insurance !== null && claim.insurance !== undefined ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Insurer
                    {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldInput
                    value={claim.insurer}
                    type={'view'}
                    keyParam="insurer"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.insurer}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }
            {
              claim.insurance !== '' && claim.insurance !== null && claim.insurance !== undefined?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Insuree
                    {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldInput
                    value={claim.insuree}
                    type={'view'}
                    keyParam="insurer"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.insurer}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Approver Name
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Approver'
                  keyParam="approver_id"
                  value={claim.approver_id}
                  type={type}
                  options={approvers}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                  name = {claim.approver ? claim.approver.name : ''}
                />
              <h6 className="error-msg">{error.approver_id}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Claim Details
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldTextarea
                  value={claim.claim_summary}
                  type={type}
                  keyParam="claim_summary"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.claim_summary}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Date of Closure</FieldLabel>
                <FieldDate
                  value={claim.date}
                  minDate={moment(this.state.policy_start_date)}
                  maxDate={moment(this.state.policy_end_date)}
                  type={type}
                  keyParam="date"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            {
              claim.insurance !== '' && claim.insurance !== null && claim.insurance !== undefined ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Amount ({claim.currency ? claim.currency: 'USD'})
                    {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldInput
                    value={claim.amount}
                    type={type}
                    keyParam="amount"
                    updateField={this.updateForm}
                    currency = {true}
                    currencyType={claim.currency}
                  />
                <h6 className="error-msg">{error.amount}</h6>
                </FieldGroup>

              </FieldCol>
              :null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Primary Contact </FieldLabel>
                <FieldInput
                  value={claim.primary_contact}
                  type={type}
                  keyParam="primary_contact"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={claim.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            {
              this.props.type === 'view' && claim.approver_id === getLocalStorageInfo().user.id ?
               apporveStatus === 'edit' ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Claim Status
                    {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Status'
                    keyParam="status"
                    value={claim.status}
                    type={apporveStatus}
                    options={[{value: 3, label: 'Approved'}, {value: 4, label: 'Rejected'}]}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                    name = {claim.status_name}
                  />
                <h6 className="error-msg">{error.status}</h6>
                </FieldGroup>
             </FieldCol>
              :<FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Claim Status

                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Status'
                    keyParam="status"
                    value={claim.status}
                    type={'view'}
                    options={[{value: 3, label: 'Approved'}, {value: 4, label: 'Rejected'}]}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                    name = {claim.status_name}
                  />
                <h6 className="error-msg">{error.status}</h6>
                </FieldGroup>
             </FieldCol>:<FieldCol md="6">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Claim Status

                 </FieldLabel>
                 <FieldSelect
                   placeholder='Select Status'
                   keyParam="status"
                   value={claim.status}
                   type={'view'}
                   options={[{value: 3, label: 'Approved'}, {value: 4, label: 'Rejected'}]}
                   updateField={this.updateForm}
                   labelKey= 'label'
                   valueKey='value'
                   name = {claim.status_name}
                 />
               <h6 className="error-msg">{error.status}</h6>
               </FieldGroup>
            </FieldCol>
            }
            {
              claim.status === 4  && claim.approver_id === getLocalStorageInfo().user.id ?
               apporveStatus === 'edit' ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Reason for Rejection<sup className="reqStar"> * </sup></FieldLabel>
                  <FieldTextarea
                    value={claim.rejection_reason}
                    type={apporveStatus}
                    keyParam="rejection_reason"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.rejection_reason}</h6>
                </FieldGroup>
              </FieldCol>
              :<FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Reason for Rejection</FieldLabel>
                  <FieldTextarea
                    value={claim.rejection_reason}
                    type={'view'}
                    keyParam="rejection_reason"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.rejection_reason}</h6>
                </FieldGroup>
              </FieldCol>:<FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Reason for Rejection</FieldLabel>
                  <FieldTextarea
                    value={claim.rejection_reason}
                    type={'view'}
                    keyParam="rejection_reason"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.rejection_reason}</h6>
                </FieldGroup>
              </FieldCol>
            }
          </Row>
        </div>

        {
          type != 'view' || (this.props.type === 'view' && claim.approver_id === getLocalStorageInfo().user.id) ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
   )
 }
}
