import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
export default class PaymentFailed extends Component{
    constructor(props) {
        super(props);
        this.state = {
            paymentFailed : false
        };
    }
    render(){
        return(
            <Modal id="paymentFailed" isOpen={this.props.isOpen} toggle={() => this.props.toggle()} size="sm">
                <ModalBody className="text-center">
                   <img src={ imgStoragePath+'phase-2/failed.svg' } />
                   <h4>Payment Failed!</h4>
                   <p>We could not process your payment</p>
                   <p>
                      <a href="javascript:void(0)" onClick={() => this.props.toggle()} className="cancel">OK</a>
                   </p>
                </ModalBody>
            </Modal>
        )
    }
}
