import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addInductionCondition } from '../../contractAddObject';
export const inductionListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.INDUCTION_COND_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/induction-condition-items/', {})
    .then(response => {
      dispatch({
        type: actions.INDUCTION_COND_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INDUCTION_COND_LIST,
          payload: response.data.data
        });
      }
    })
  }
}
export const inductionConditionCrudAc = (params, type, id) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.INDUCTION_COND_CRUD,
        payload: {type: type, data:addInductionCondition, flag:true }
      });
    }else{
      dispatch({
        type: actions.INDUCTION_COND_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/induction-condition-items/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.INDUCTION_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.INDUCTION_COND_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}
export const contractCondnAc = (params) => {
  return (dispatch) => {
    globalGetService('contracts/'+params.contract_slug+'/contract-asset-conditions-list/',{})
    .then(response => {
      dispatch({
        type: actions.CONTRACT_CONDN_LIST,
        payload: response.data.data
      })
    })
  }
}
export const addEditInductionCondAc = (params, data,type, assetViewId) => {
  return (dispatch) => {
    dispatch({
      type: actions.INDUCTION_COND_LOADER,
      payload: true
    });
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/induction-condition-items/',data)
      .then(response => {
        dispatch({
          type: actions.INDUCTION_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.INDUCTION_COND_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.INDUCTION_COND_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Induction Condition`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Induction Condition`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/induction-condition-items/' + data.id + '/', data)
      .then(response => {
        dispatch({
          type: actions.INDUCTION_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.INDUCTION_COND_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.INDUCTION_COND_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Induction Condition`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Induction Condition`,
            item_id: data.id
          })
        }
      })
    }
  }
}
