import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router';
import * as actions from '../actions';
import { getFleetUsageAc, fetchPortfolioListAC, delPortfolioAc, portFolioCrudAc, addEditPortfolioAc, getPortfolioConstants, downLoadPortfoliosAc } from '../actionCreators';
import AddEditPortfolio from '../components/AddEditPortfolio';
import PortfolioItem from '../components/PortfolioItem';
import FieldHeading  from '../components/FieldHeading';
import { NoRecordFound  } from '../../../shared';
import { trackActivity } from '../../../utils/mixpanel'
import { EditSidebar} from '../../contracts/Elements'
import '../assets/styles/fleet.scss';
import '../../../User/assets/styles/assetListing.scss';
import { ListLoader, LicenceModal } from '../../../shared/';
import { imgStoragePath } from '../../../constants';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import { BUY_LICENCE } from '../../../shared/actions'
import { getLocalStorageInfo } from '../../../utils'

class FleetAnalysis extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  portFolioCrud = (type, object) => {
    if(getLocalStorageInfo().user.permission.fleet_cash_flow && getLocalStorageInfo().user.permission.fleet_cash_flow.is_saas){

      if(this.props.fleetViewUsage.available > this.props.fleetViewUsage.used){
       this.props.portFolioCrud(type,object)
      }else{
        if(this.props.fleetViewUsage.available==-1){
          this.props.portFolioCrud(type,object)
        }else{
          this.props.buyLicence({
            flag: true,
            action: '/licenses/fleet-cashflow',
            content: 'License for creating portfolio(s) is expired. Please upgrade your license to add more portfolio(s).'
          })
          return;
        }
      }
    }else{
      this.props.portFolioCrud(type,object)
    }
  }

  componentDidMount(){
    this.props.fetchPortfolios();
    this.props.getPortfolioConstants('portfolio_type');
    this.props.getPortfolioConstants('portfolio_status');
    trackActivity('Page Visited', {
      application_module: 'Fleet Cashflow',
      page_title: `Portfolios`
    })
    if(getLocalStorageInfo().user.permission.fleet_cash_flow && getLocalStorageInfo().user.permission.fleet_cash_flow.is_saas){
			this.props.getFleetUsage();
		}
  }
  render(){
    const { portfolioList, portfolioCrud, portfolio_type, portfolio_status, listLoader } = this.props;
    return(
      <div className="asset-listing-wrap fleet-list" style={{ 'paddingTop': '15px'}}>
        <h1>Portfolios</h1>
        <p className="block-subs">Allows users to create portfolios for securitization purposes using the data available for assets in the fleet</p>
        <Row>
          <Col md="12">
            <ul className="list-inline tab-left">
            </ul>
            <hr/>
            <ul className="list-inline tab-right flex-centered">
              <li className="list-inline-item download-block">
                  <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                      <DropdownToggle caret size="sm">
                         <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export list </span>
                      </DropdownToggle>
                      <DropdownMenu>
                          <DropdownItem  onClick={() => this.props.downLoadPortfolios('pdf')}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                          <DropdownItem onClick={() => this.props.downLoadPortfolios('xls')} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                      </DropdownMenu>
                  </ButtonDropdown>
              </li>
              <li className="list-inline-item">
                <Button color="primary" size="sm" className="add-new float-right" onClick={() => this.portFolioCrud('add',{})}>Create a Portfolio</Button>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="fleet-list-wrapper-blk">
        <div className="asset-list-filter-sort">
          <Row className="filter-block">
            <FieldHeading label = "Portfolio Name" colSize = "2"/>
            <FieldHeading label = "No Of Assets" colSize = "1"/>
            <FieldHeading label = "Status" colSize = "1"/>
            <FieldHeading label = "Portfolio Owner" colSize = "2"/>
            <FieldHeading label = "Credit Rating" colSize = "1"/>
            <FieldHeading label = "Cashflow" colSize = "3"/>
            <FieldHeading label = "Last Modified By" colSize = "2"/>
          </Row>
        </div>
          { listLoader ?
              portfolioList.length > 0 ?
                portfolioList.map((portfolio,index) => <PortfolioItem key={index} portFolioCrud={this.props.portFolioCrud} index={index} portfolio={portfolio} delPortfolio={this.props.delPortfolio}/>)
              :<NoRecordFound description="Get Started by clicking 'Create a Portfolio' button above" />
            :<ListLoader drawer="primary-nav" />
          }
          </div>
        <EditSidebar
				title={
					portfolioCrud.type != '' ?
					(portfolioCrud.type == 'add') ? 'Add Portfolio':
					(portfolioCrud.type == 'edit') ? 'Edit Portfolio': '' : ''
					}
				editSideBar={portfolioCrud.flag}
				toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}>
					<div>
						{Object.keys(portfolioCrud.data).length && portfolioCrud.flag ?
							<AddEditPortfolio
								addEditPortfolio={this.props.addEditPortfolio}
								type={portfolioCrud.type}
								portfolioObj={portfolioCrud.data}
                portfolio_type={portfolio_type}
                portfolio_status={portfolio_status}
								toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}/>
								:null}
					</div>
				</EditSidebar>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="5"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  portfolioList: state.FleetAnalysisReducer.portfolioList,
  portfolioCrud: state.FleetAnalysisReducer.portfolioCrud,
  portfolio_status: state.FleetAnalysisReducer.portfolio_status,
  portfolio_type: state.FleetAnalysisReducer.portfolio_type,
  portfolio_type: state.FleetAnalysisReducer.portfolio_type,
  listLoader: state.FleetAnalysisReducer.listLoader,
  fleetViewUsage: state.FleetAnalysisReducer.fleetViewUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
});

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    fetchPortfolios: () => dispatch(fetchPortfolioListAC()),
    addEditPortfolio : (data, operation) => dispatch(addEditPortfolioAc(ownProps.params , data, operation)),
    toggleEditBar: (data) => dispatch({
      type:actions.PORTFOLIO_CRUD,
      payload:data
    }),
    downLoadPortfolios: (fileType) => dispatch(downLoadPortfoliosAc(fileType)),
    delPortfolio: (portfolioId) => dispatch(delPortfolioAc(portfolioId)),
    portFolioCrud: (type, data) => dispatch(portFolioCrudAc(type, data)),
    getPortfolioConstants: (data) => dispatch(getPortfolioConstants(data)),
    getFleetUsage: () => dispatch(getFleetUsageAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }

}
export default connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(FleetAnalysis, ['fleet_cash_flow','dashboard','R']));
