import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
export default class InShopServList extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { dataList, techConstants } = this.props;
    let data = dataList.off_wing_detail;
    return(
      <Row>
        <GenTabField grid="3" classText = "horizontal-inner-block " label=" Release Date of the Engine" text={data.release_date_of_the_engine ? moment(data.release_date_of_the_engine).format(displayDateFormat):'--'}  />
        <GenTabField grid="3" classText = "horizontal-inner-block " label="SV Report Provided"   text={data.shop_visit_report_status == 1 ? 'YES':'No'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Type of Shop Visit"   text={data.type_of_shop_visit && techConstants.length ? techConstants.find(item => item.type ==='shop_visit_type' && item.value == data.type_of_shop_visit).label :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Expected Date of Shipment Back to Lessee"   text={data.expected_date_of_shipment_back_to_lessee ? moment(data.expected_date_of_shipment_back_to_lessee).format(displayDateFormat):'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Facility Name"   text={data.shop_visit_name ?  data.shop_visit_name :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Facility Location"   text={data.shop_visit_location ?  data.shop_visit_location :'--'}/>
      </Row>
    )
  }
}
