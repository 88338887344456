import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { getFileSize } from '../../../utils'
    const StorageList = (props) => {
        return(
            <tr onClick={() => browserHistory.push(`/asset-view/${props.asset.asset_type == 1 ? 'aircraft' : 'engine' }/${props.asset.asset_slug}`)}>
                <td>
                    <div className="asset-title">
                        <img src={imgStoragePath+(props.asset.asset_type == 1 ? "icon_Aircraft.png" : (props.asset.asset_type == 2) ? "icon_engine.png" : props.asset.asset_type == 3 ? "icon_apu.svg" : props.asset.asset_type == 4 ? 'icon_lg.svg' : 'icon_propeller.svg')} />
                        <span>{props.asset.asset_type_name}</span>
                      <p>{props.asset.asset_type == 1 ? `MSN ${props.asset.asset}` : `ESN ${props.asset.asset}`}</p>
                    </div>
                </td>
                <td>
                    {getFileSize(props.asset.storage)}
                </td>
                <td>
                    {getFileSize(props.asset.inspection_storage)}
                </td>
                <td>
                    {getFileSize(0)}
                </td>
            </tr>
        )
    }
export default StorageList
