import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import { ToolTipHover }  from '../../../shared';
import { imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
const AppModuleWidget = (props) => {
  return(
    <Col md="3" className={props.classParam}>
      <div className="dashboard-shadow-block">
        <div className={props.inactive ? "submodule-component " : "submodule-component inactive"  } >
          <div style={props.inactive ? {cursor: 'pointer', position: 'relative'}: {}} className="img-block flex-centered" onClick={() =>  props.inactive ? browserHistory.push(props.url) : null}>
            <div>
              <img src={props.imgsrc} alt="img"/>
			        <h5>{props.title}</h5>
	          </div>
            { props.inactive && getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id !== 348 ?
              <Link
                style={{position: 'absolute', color: '#fff', fontSize: '12px', right: '4px', bottom: '4px'}}
                to={props.url}>
                  Go to {props.title} Dashboard
              </Link>:null
            }
          </div>
          <div>
            { props.dbAppModLoader ? <div className="text-block" style={{position: 'relative'}}><span style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</span></div>:
              props.inactive ?
              <div className="text-block">
                <ul className="list-unstyled">
                  <li>
                    <h5 className="para-ui">
                      {props.assetParamOneLabel}
  		              </h5>
  									<p className="para">
  	                   {props.assetParamOne}
                    </p>
                  </li>
  								<li>
                    <h5 className="para-ui">
                      {props.assetParamTowLabel}
                    </h5>
                    <p className="para">
                      {props.assetParamTwo}
                    </p>
                  </li>
  		          </ul>
  							{	props.assetsCount > 0 ?
                  <Link to={props.url} className="flex-centered"><b>{props.assetsCount}</b> Assets under {props.title} Module <img src={imgStoragePath+"rightArrow.png"} alt="img"/></Link>
  				        : null
                }
  						</div>:
              <div className="inactive-module flex-centered">
  			        <h5>Please contact support@sparta.aero to avail <br/> this service</h5>
  						</div>
            }
          </div>
			 </div>
		 </div>
   </Col>
  )
}

export default AppModuleWidget;
