import React, { Component } from 'react';
import { Link } from 'react-router';
import { ToolTipHover } from '../../../../shared';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../../assets/styles/records_v.scss';
import { displayDateTimeFormat, displayDateFormat } from '../../../../constants';
import moment from 'moment';
import { getFileSize, getAccessToken, getLocalStorageInfo } from '../../../../utils';
import { SortableElement } from 'react-sortable-hoc';
import { imgStoragePath } from '../../../../constants';
const MiniPackFileList = SortableElement((props) =>
{
    const { listItem, index } = props;
    return(
      <div className="asset-list" style={{width: '100%'}}>
        <Row className="flex-centered">
        {

          <Col md={props.recycleBin ? "6" : "8"}>
              <div className="flex-centered msn-block record-preview">
              <div>
                  <input style={{ marginRight: '10px'}} onClick={props.addToRecycle} checked={props.recycleList.filter(item => item == listItem.id).length ? true : false } type="checkbox"/>
                </div>
                <div><img src={listItem.file.type !== "folder" ? props.fileIcons[listItem.file.extension] : imgStoragePath+"folder_icn3.png"} alt="img" width="20"/></div>
              <Link to={(listItem.file.type == "folder" && listItem.file.extension == 'pdf' && props.recycleBin) ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${listItem.file.path}`: (listItem.file.extension == 'csv' || listItem.file.extension == 'xls' || listItem.file.extension == 'xlsx') && props.recycleBin ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${listItem.file.path}` : ''} target="_blank"><span style={{fontSize:'14px', position: 'relative', top: '0px', right: '0px'}}>{listItem.file.name}</span></Link>
              </div>
              <h6 className="para-ui" style={{fontSize: '12px'}}>{listItem.file.location}</h6>
          </Col>
        }
          <Col md="1">

            <h6 className="para-ui">
              {
                listItem.file.size != 0 && listItem.file.size != null && listItem.file.size != undefined?
                `${getFileSize(listItem.file.size)}`:'--'
              }
            </h6>
         </Col>
         {
           props.recycleBin ?
            <Col md="2">
             <h6 className="para-ui">{listItem.file.modified_by && listItem.file.modified_by != null && listItem.file.modified_by != undefined ? listItem.file.modified_by.name : '--'}</h6>
            </Col>
            :null
         }
         {
           props.recycleBin ?
              <Col md="2">
                <h6 className="para-ui" >{moment(listItem.file.modified).format(displayDateFormat)}</h6>
            </Col>:null
          }
          <Col md="2">
          </Col>
          <Col md="1" className="text-right status-block bucket-action">
             <span className="glyphicon glyphicon-move" style={{fontSize: '25px'}}>
               &#10021;
            </span>
          </Col>
        </Row>
      </div>
    )
}
)

export default MiniPackFileList
