import axiosInstance from '../../../../utils/httpInterceptors';
import { getMRUsageAc } from '../actionCreators'
export const clientListApi = () => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: 'console/lessors/',
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const aircraftTypeApi = (queryParams={}) => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'console/aircraft-types/',
        params:queryParams
      })
      .then(response => {
        const aircraftType = response.data.data.aircraftTypes;
        resolve(aircraftType);
      });
    }
  )
}
export const engineTypeApi = (queryParams={}) => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'console/engine-types/',
        params: queryParams
      })
      .then(response => {
        const aircraftType = response.data.data.engineTypes;
        resolve(aircraftType);
      });
    }
  )
}
export const aircraftEngineTypeApi = (id) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'GET',
        url: 'console/engine-types/',
        params: {
          'aircraft_type': id,
          'mr-calculator': 'mr-calculator'
        }
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}
export const regionOfOpApi = () => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'GET',
        url: 'console/regions/'
      })
      .then(response => {
        const regionList = response.data.data.region_list;
        resolve(regionList);
      })
    }
  )
}
export const regionInfoApi = (region_id, engine_type) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'GET',
        url: 'console/regions/',
        params: {region_id, engine_type}
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const aircraftMaintenanceApi = (id) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'GET',
        url: 'console/aircraft-type/' + id + '/events/'
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const mrCalculationApi = (data) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/initiate/',
        data: data
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const fetchMrLogApi = (id) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/log/' + id + '/',
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const engineWorkScopeCalApi = (data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/engine-usage/',
        data: data
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const selectLLPToReplaceApi = (shop_visit_no, log_id, engine_no) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/workscope/llps/',
        params: {shop_visit_no, log_id, engine_no}
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const replacedTheLLPSelectedApi = (obj) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/workscope/llps-update/',
        data: obj
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const customCycleRemainApi = (engineTypeId) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/engine-type/' + engineTypeId + '/llps/'
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const breakUpEngineCostApi = (data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: 'maintenance/edit_modules/',
        data: data
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const changeWorkScopeApi = (data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/workscope/modules/',
        data: data
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const carryOverNSVApi = (data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/workscope/fund-carry-forward/',
        data: data
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const logsListingApi = (params) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/logs/list/',
        params:params
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const deleteLogApi = (id) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'DELETE',
        url: 'mr-calculator/delete-log/',
        data:{log_id: id}
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}
export const genMRFinanceGraphApi = (data) => {
  return new Promise(
    function(resolve, reject){
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/aircraft-usage/',
        data: data
      })
      .then(response => {
        resolve(response);
      })
    }
  )
}

export const downloadLogApi = (fileType) => {
  return new Promise(
    function(resolve, reject) {
      axiosInstance({
        method: 'GET',
        url: '/mr-calculator/export-log-details/',
        responseType: 'blob',
        params: {
          download: fileType
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'log_list.'+fileType);
        document.body.appendChild(link);
        link.click();
      })
    }
  )
}
