import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getLocalStorageInfo, getDataUri, dataURItoBlob } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import moment from 'moment';

const userInfo = getLocalStorageInfo();
const shopVisit = {
	arc_date: null,
	build_target: "",
	cshi: null,
	cslsv: null,
	csn: null,
	cso:null,
	csr: null,
	date_of_reinstallation: null,
	date_of_removal: null,
	final_workscope: "",
	induction_date: null,
	llp_status: "",
	reason_for_removal: "",
	shop_facility: "",
	shop_visit_number: "",
	test_run_egt_margin: "",
	total_air_temperature: "",
	tshi: null,
	tslsv: null,
	tsn: null,
	tso: null,
	tsr: null,
	attachments: [],
	added_from: 1
}


export const fetchshopVisitDetailsAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, {})
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_SHOPVISIT_DETAILS,
					payload: response.data.data
				})
			}
		})		
	}
}

export const toggleAddEditShopvisitAc = (props, data) => {
	return (dispatch) => {
		if(data.type == 'add'){
			dispatch({
				type: actions.ADD_EDIT_SHOPVISIT,
				payload: {data : shopVisit, flag: data.flag, type: data.type}
			})
		}else{
				dispatch(triggerLoader(true));
				globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${data.data.id}/`, {})
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						dispatch({
							type: actions.ADD_EDIT_SHOPVISIT,
							payload: {data: response.data.data, flag: data.flag, type: data.type}
						})
					}
				})		
		}
	

		}
	}

	export const UpdateShopVisitDetailsAc = (props, data) => {
		return (dispatch) => {
			dispatch(triggerLoader(true));
			globalPostService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit-details/`, data)
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						dispatch({
							type: actions.GET_SHOPVISIT_DETAILS,
							payload: response.data.data
						})
						toastFlashMessage(response.data.message, 'success');
						trackActivity('Shop Visit Details Saved', {
							page_title: `TE004`,
							application_module: 'Inspection',
							workorder_slug: props.params.workOrderSlug,
							form_slug: props.params.formSlug,
						  })
					}
				})
		}
	}


	export const GenUrlAc = (props, files) => {
		return (dispatch) => {
			dispatch(triggerLoader(true));
			var formData = new FormData();

			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!(file.type.match('application/pdf.*')||file.type.match('application/vnd.ms-excel.*')||file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*'))) {
					toastFlashMessage( file.name + ' is not Valid Format', 'error');
					 continue;
				 }
			 formData.append('file', file, file.name);
			}
			formData.append('shop_visit', true);
			var config = {
				onUploadProgress: function(progressEvent) {
				 var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
						// dispatch({
						//   type: actions.PROGRESS_PERCENTAGE,
						//   payload: percentage
						// });
					}
			}
			globalFileUploadService('/technical/engine-workorder/'+props.params.workOrderSlug+'/file-upload/', formData, config)
			.then(response => {
				dispatch(triggerLoader(false));
				if(response.data.statusCode == 200){
					dispatch({
						type:actions.UPLOAD_FILE,
						payload: {attachment : response.data.url, name: response.data.name, created_at: moment(), user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic  }}
					})
				}
			})
		}
	}


	export const updateShopVisitAc = (props, dataObj) => {
		return(dispatch) => {
			dispatch(triggerLoader(true));
			if(dataObj.type=='add'){
				globalPostService('/technical/engine-workorder/'+props.params.workOrderSlug+'/shop-visit/', dataObj.data)
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						toastFlashMessage(response.data.message, 'success');
						trackActivity('Shop Visit Added', {
							page_title: `TE004`,
							application_module: 'Inspection',
							item_type:'Built Standard Evaluation',
							workorder_slug: props.params.workOrderSlug,
							form_slug: props.params.formSlug,
							engine_workorder:dataObj.engine_workorder,
							item_id: dataObj.id
						  })
						dispatch({
							type: actions.ADD_SHOPVISIT,
							payload: response.data.data
						});
					}
				})
			}else{
				globalPutService(`/technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${dataObj.data.id}/`, dataObj.data)
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						dispatch({
							type: actions.UPDATE_SHOPVISIT,
							payload: response.data.data
						});
					toastFlashMessage(response.data.message, 'success');
					trackActivity('Shop Visit Edited', {
						page_title: `TE004`,
						application_module: 'Inspection',
						item_type:'Built Standard Evaluation',
						workorder_slug: props.params.workOrderSlug,
						form_slug: props.params.formSlug,
						engine_workorder:dataObj.engine_workorder,
						item_id: dataObj.id
					  })
					}
				})
			}
		}
	}

	export const deleteShopVisitAc = (props,id, type, index) => {		
		return(dispatch) => {
			if(type == 'shopVisit'){
				dispatch(triggerLoader(true));
				globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/shop-visit/${id}/delete/`,{})
				.then(response => {
					dispatch(triggerLoader(false));
					if(response.data.statusCode == 200){
						dispatch({
							type: actions.DELETE_SHOPVISIT,
							payload: id
						});
						 toastFlashMessage(response.data.message, 'success');
						 trackActivity('Shop Visit Edited', {
							page_title: `TE004`,
							application_module: 'Inspection',
							item_type:'Built Standard Evaluation',
							workorder_slug: props.params.workOrderSlug,
							form_slug: props.params.formSlug,
							item_id: id
						  })
					}
				})
			}else{
				if(!id){
					dispatch({
						type: actions.DELETE_SHOPVISIT_ATTACHMENT,
						payload: index
					});
				}else{
					dispatch(triggerLoader(true));
					globalDeleteService(`technical/shop-visit-attachment/${id}/delete/`,{})
					.then(response => {
						dispatch(triggerLoader(false));
						if(response.data.statusCode == 200){
							dispatch({
								type: actions.DELETE_SHOPVISIT_ATTACHMENT,
								payload: index
							});

						}
					})
				}
				toastFlashMessage('Shop visit attachment deleted successfully', 'success');

			}
	
		}
	}


	export const triggerLoader = (flag) => {
		return (dispatch) => {
			dispatch({
				type: actions.SHOP_VISIT_LOADER,
				payload: flag
			});
		}
	}
 

