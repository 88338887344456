import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import GrpAmountField from '../containers/GrpAmountField';
import GrpEscalationField from '../containers/GrpEscalationField';
import { imgStoragePath } from '../../../../constants';
export default class GraphTableWrapper extends Component{
  constructor(props){
    super(props);
    this.state={
      collapse: false
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  render(){
    const { newprogramInfo, programInfo, metrixType, programType, eventIndex, simulationType } = this.props;
    if(programInfo){
      return(
        <div className="grp-table-wrapper">
          <div className="grp-table-wrapper-collapse" onClick={this.toggle}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <img className="marker-icn" src={imgStoragePath + (metrixType == 'airframe' ? 'airframe_marker.png' : metrixType =='apu' ? 'apu_marker.png':'lg_marker.png')} alt="icon" />
              </li>
              <li className="list-inline-item" style={{width: '115px'}}>
                <span className="program-name">{ programInfo.name }</span>
              </li>
              <li className="list-inline-item" style={{width: '160px'}}>
                <GrpAmountField
                  metrixType={metrixType}
                  programType={programType}
                  eventIndex={eventIndex}
                  newvalue={ simulationType == 2 ? newprogramInfo.mr:'' }
                  value={programInfo.mr}
                  keyParam="mr"
                  intervalUnit={programInfo.mr_interval}/>
              </li>
              <li className="list-inline-item" style={{width: '145px'}}>
                <GrpEscalationField metrixType={metrixType} programType={programType} eventIndex={eventIndex} newvalue={ simulationType == 2 ? newprogramInfo.escalation_percentage:'' } value={programInfo.escalation_percentage} keyParam="escalation_percentage" />
              </li>
              <li className="list-inline-item" style={{width: '180px',textAlign: "left"}}>
                <GrpEscalationField metrixType={metrixType} programType={programType} eventIndex={eventIndex} newvalue={ simulationType == 2 ? newprogramInfo.cost_escalation:'' } value={programInfo.cost_escalation} keyParam="cost_escalation" />
              </li>
            </ul>
            <span className="grp-arrow">
              <img style={this.state.collapse ? {transform: 'rotate(0deg)'}: {transform: 'rotate(180deg)'}} src={imgStoragePath+"collapse_arw.png"} alt="icon" />
            </span>

          </div>
          <Collapse isOpen={this.state.collapse}>
            <Card>
              <CardBody>
                {this.props.children}
              </CardBody>
            </Card>
          </Collapse>
        </div>
      )
    }else{
      return null
    }

  }
}
