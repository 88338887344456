import React, { Component } from 'react';
import moment from 'moment';
import PopoverLeadInfo from '../Projects/components/PopoverLeadInfo';
import { ToolTipHover } from '../../../shared';
import { imgStoragePath } from '../../../constants';
export default class  SecondaryTechHeaderRH extends Component {
  render(){
    return(
      <ul className="list-inline flex-centered">
        <li className="list-inline-item">
          <h6>Project Lead</h6>
          { this.props.projectInfo && this.props.projectInfo.leads && this.props.projectInfo.leads.length ?
            <div className="lead-block">
              { this.props.projectInfo.leads.length > 1 ?
                <div className="flex-centered">
                  { this.props.projectInfo.leads.map((lead, index) =>
                    index <= 1 ?
                    <span>
                      <img  src={lead.profile_pic ? lead.profile_pic : imgStoragePath+'user.png'} alt="" id={'img-'+index+'-'} />
                      <ToolTipHover placement="top" tagetId={'img-'+index+'-'}>
                        {lead.name}
                      </ToolTipHover>
                    </span>:null
                  )}
                  { this.props.projectInfo.leads.filter(leads => leads).length > 2 ?
                    <PopoverLeadInfo
                      placement = 'top'
                      tagetId={'Popover-img-'+'header'}
                      leadInfo = { this.props.projectInfo.leads}
                    />
                    : null
                  }
                </div>:
                this.props.projectInfo.leads.length  == 1 ?
                <div>
                  { this.props.projectInfo.leads.map((data, index) =>
                    <span id="user-info-name" key={index} className="flex-centered">
                      <div>
                        <img style={{width: '20px', height: '20px', borderRadius: '50%'}} src={data.profile_pic ? data.profile_pic : imgStoragePath+'user.png' } alt="logo" id={'img-'+index} />
                      </div>
                      { data.name ?
                        <span style={{marginLeft: '8px',fontSize:'10px'}}> {data.name.length > 14 ? data.name.substr(0,11)+ ' ...' : data.name } </span>
                        :null
                      }
                      { data.name.length > 14 ?
                        <ToolTipHover placement="top" tagetId='user-info-name'>
                          {data.name}
                        </ToolTipHover>:null
                      }
                    </span>
                  )}
                </div>: null
              }
            </div>:null
          }
        </li>
        <li className="list-inline-item lesse-block">
          {/* <ToolTipHover className="test" placement="top" tagetId="lessee-tip">
            {this.props.projectInfo ? this.props.projectInfo.lessee.name:null}
          </ToolTipHover> */}
          <h6>Lessee</h6>
          <img id="lessee-tip" className="lessee-img" src={ this.props.projectInfo && this.props.projectInfo.lessee.logo != undefined ? `${this.props.projectInfo.lessee.logo}` : imgStoragePath+'flight_common_icon.png'} alt="img"/>
          
        </li>
        <li className="list-inline-item">
          <h6>Project Start Date</h6>
          <p>{this.props.projectInfo && this.props.projectInfo.start_date != undefined && this.props.projectInfo.start_date != null ? moment(this.props.projectInfo.start_date).format('MMM DD, YYYY') : '--'}</p>
        </li>
        <li className="list-inline-item">
          <h6>Project End Date</h6>
          <p> { this.props.projectInfo && this.props.projectInfo.expected_end_date != undefined && this.props.projectInfo.expected_end_date != null ? moment(this.props.projectInfo.expected_end_date).format('MMM DD, YYYY') : '--'}</p>
        </li>
      </ul>
    )
  }
}
