import * as actions from '../actions';
import { toastFlashMessage } from '../../../utils/';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { globalGetService, globalPostService, globalPutService, globalDeleteService, globalExportService} from '../../../globalServices';
import { downloadFileType } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel'
let filterKeyName = {
  cumulative_cashflow: 'Cumulative_Cash_Flow_Summary_of_Assets',
  cash_inflow: 'Lessor_Revenues_By_Aircraft',
  cash_outflow: 'Lessor_Expense_By_Aircraft',
  halflife_main: 'Value_Adjustment_From_Half-Life',
  eol_compensation:'EOL_Compensation',

}
export const fetchPortfolioListAC = (filters = {}) => {
  return (dispatch) => {
    dispatch({
      type:actions.FLEET_LIST_LOADER,
      payload: false
    });
    globalGetService('/console/portfolios/', filters)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.PORTFOLIO_LIST,
          payload:response.data.data
        });
        dispatch({
          type:actions.FLEET_LIST_LOADER,
          payload: true
        });
      }
    })
  }
}

export const portFolioCrudAc = (type,data) =>{
  return(dispatch) => {
    if(type=='add'){
      dispatch({
        type:actions.ADD_PORTFOLIO,
        payload:data
      });
    }
    else {
      dispatch({
        type:actions.PORTFOLIO_CRUD,
        payload:data
      })
    }
  }
}

export const addEditPortfolioAc = (params,data, operation) => {
  return(dispatch) => {
    data.assets=[];
    data.inflow=0;
    data.outflow=0;
    data.lastModified={};
    if(operation=='add'){
      globalPostService('/console/portfolios/',data)
      .then( response => {
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.ADD_PORTFOLIO_LIST,
            payload:response.data.data
          });
          dispatch({
            type:actions.PORTFOLIO_CRUD,
            payload:{type: '', data: {}, flag: false}
          });
        }
        dispatch(getFleetUsageAc())
        toastFlashMessage(response.data.message, 'success');
      });
    }else {
      globalPutService('/console/portfolios/'+data.slug+'/',data)
      .then( response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.EDIT_PORTFOLIO_LIST,
            payload: response.data.data
          });
          dispatch({
            type:actions.PORTFOLIO_CRUD,
            payload:{type: '', data: {}, flag: false}
          });
        }
        toastFlashMessage(response.data.message, 'success');
      });
    }
  }
}
export const addAssetsToListAc = (params,data) =>{
    return (dispatch) =>{
      globalPostService('/console/portfolio/'+params.slug+'/assets/',data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.ADD_PORTFOLIO_ASSET,
            payload:false
          });
          toastFlashMessage(response.data.message, 'success');
          browserHistory.push( "/fleet-cashflow/portfolio/" +params.slug );
        }

      });
    }
}

export const deleteAssetsFromListAc = (params,data) =>{
    return (dispatch) =>{
      let ids = [];
      data.map(item => {
        ids.push(item.asset_id);
      })
      globalPostService('/console/portfolio/'+params.slug+'/asset/delete/',{"ids":ids})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.DEL_PORTFOLIO_ASSET,
            payload:ids,
            id:params.id
          });
        }
        toastFlashMessage(response.data.message, 'success');
      });
    }
}

 export const getPortfolioConstants = (data) => {
   return (dispatch) => {
     globalGetService('console/get-constants/?constant_type='+data, { })
     .then(response => {
       if(response.data.statusCode == 200){
         dispatch({
           type: actions.GET_PORTFOLIO_CONSTANTS,
           payload: response.data.data,
           constantType: data
         })
       }
     })
   }
 }
 export const getPortfolioAssetsAc = (params) => {
   return (dispatch) => {
     dispatch({
       type:actions.FLEET_LIST_LOADER,
       payload: true
     });
     globalGetService('/console/portfolios/'+params.slug+'/', { })
     .then(response => {
       if(response.data.statusCode == 200){
         dispatch({
           type: actions.ASSETS_LIST,
           payload: response.data.data
         });
         trackActivity('Page Visited', {
           application_module: 'Fleet Cashflow',
           page_title: `Portfolio View ${response.data.data.name}`
         })
         dispatch({
           type:actions.FLEET_LIST_LOADER,
           payload: false
         });
         console.log('response', response.data.data.name);
       }
     })
   }
 }

 export const getAssetsListAc = (filterParams,params) => {
  filterParams= {
    ...filterParams,
    portfolio_slug:params.slug
  }
   return (dispatch) => {
     dispatch({
       type:actions.FLEET_LIST_LOADER,
       payload: true
     });
     globalGetService('/console/non-portfolio/assets/', filterParams)
     .then(response => {
       if(response.data.statusCode == 200){
         dispatch({
           type: actions.GET_ASSETS_LIST,
           payload: response.data.data
         });
         dispatch(getPortfolioAssetsAc(params))
       }
     })
   }
 }

export const delPortfolioAc = (portfolioId) => {
  return (dispatch) =>{
    globalDeleteService('/console/portfolios/'+portfolioId+'/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.DEL_PORTFOLIO_LIST,
          payload:portfolioId
        });
      }
    });
  }
}

export const fleetCashflowFilterAc = (params, type, value) => {
  let filterParam = '';
  return (dispatch) => {
    if( type != 'submit'){
      dispatch({
        type: actions.FLEET_UPDATE_FILTER,
        payload: {type: type, value: value}
      })
      filterParam = {
        [type]: value
      }
    }else{
      filterParam = value;
    }

     if(type == 'filter_type' || type == 'submit'){
        dispatch(fleetCashflowSimulationAc(params, filterParam));
     }
  }

}

export const fleetCashflowViewFilterAc = (params, type, value) =>{
  let filterParam = '';
  return (dispatch) => {
    if( type != 'submit'){
      dispatch({
        type: actions.FLEET_VIEW_UPDATE_FILTER,
        payload: {type: type, value: value}
      })
      filterParam = {
        [type]: value
      }
    }else{
      filterParam = value;
    }

     if(type == 'filter_type' || type == 'submit'){
        dispatch(fleetCashflowViewAc(params, filterParam));

     }
  }
}

export const fleetCashflowViewAc = (params, queryParams={}) => {
  return(dispatch) => {
    dispatch({
      type:actions.PORTFOLIO_LIST_LOADER,
      payload: true
    });
    globalGetService('contracts/asset/'+params.assetType+'/'+params.assetSlug+'/get-asset-cashflow-projection/', queryParams)
    .then(response => {
      dispatch({
        type:actions.PORTFOLIO_LIST_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        let data = response.data.data;
        if(queryParams.filter_type == 'eol_compensation'){
          data = {
            ...data,
            cashflowArr: data.major_assemblies.length ? generateEolMnthLIst(data.major_assemblies):[]
          }
        }else{
          data = {
            ...data,
            cashflowArr: data.major_assemblies.length ? generateMonth(moment(data.endDate).add(1, 'M').diff(moment(data.startDate), 'months', true), data.startDate):[]
          }
        }

        dispatch({
          type:actions.FLEET_CASHFLOW_VIEW,
          payload:data,
          filterType: queryParams
        });
      }
    })
  }
}

export const fleetCashflowSimulationAc = (params, queryParams={}) => {
  return (dispatch) => {
    dispatch({
      type:actions.PORTFOLIO_LIST_LOADER,
      payload: true
    });
    globalGetService('/contracts/portfolio/'+params.slug+'/get-portfolio-cashflow-projection/', queryParams)
    .then(response => {
      dispatch({
        type:actions.PORTFOLIO_LIST_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        let data = response.data.data;
        if(queryParams.filter_type == 'eol_compensation'){
          data = {
            ...data,
            cashflowArr: data.cashflow_data.length ? generateEolMnthLIst(data.cashflow_data):[]
          }
        }else{
          data = {
            ...data,
            cashflowArr: data.cashflow_data.length ? generateMonth( moment(data.endDate).add(1, 'M').diff(moment(data.startDate), 'months', true), data.startDate):[]
          }
        }

        dispatch({
          type:actions.FLEET_CASHFLOW_SIMULATION,
          payload:data
        });
      }
    })
  }
}


function generateMonth(count, date){
    let monthArry = [];
    for(let i = 0; i < count; i++){
      monthArry.push(moment(date).add(i, 'M').format('YYYY-MM')+'-01');
    }
    return monthArry;
}


function generateEolMnthLIst(data){
  let monthArry = [];
  data.map((item, index ) => {
    if(item.cashflow.length){
      monthArry.push( moment(item.cashflow[0].date).format('YYYY-MM')+'-01')
    }
  })
  return monthArry.filter( onlyUnique ).sort();
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


export const downLoadPortfoliosAc = (fileType) => {
  return (dispatch) => {
    dispatch({
      type:actions.FLEET_LIST_LOADER,
      payload: false
    });
    globalExportService('console/portfolios/',{download: fileType})
    .then(response => {
      dispatch({
        type:actions.FLEET_LIST_LOADER,
        payload: true
      });
      downloadFileType(response, 'Portfolios.', fileType)
    });
  }
}
export const exportMsnFleetCashFlowAc = (asset, filterType, startDate, endDate, fileType) => {
  let assetType = "";
  return (dispatch) => {
    dispatch({
      type:actions.PORTFOLIO_LIST_LOADER,
      payload: true
    });
    let queryParams = {}
    if(startDate == null || startDate == 'Invalid date' || endDate == null || endDate == 'Invalid date'){
      queryParams = {
        download: fileType,
        filter_type: filterType
      }
    }else{
      queryParams = {
        download: fileType,
        filter_type: filterType,
        startDate,
        endDate
      }
    }
    globalExportService('contracts/asset/'+asset.asset_type+'/'+asset.slug+'/get-asset-cashflow-projection/',queryParams)
    .then(response => {
      dispatch({
        type:actions.PORTFOLIO_LIST_LOADER,
        payload: false
      });
      if(asset.asset_type == 1){
        assetType = "MSN";
      }
      else{
        assetType = "ESN";
      }
      downloadFileType(response, assetType+'_'+filterKeyName[filterType]+'.', fileType)
    });
  }
}
export const exportCashFlowAc = (portfolio, filterType, startDate, endDate, fileType) => {
  return (dispatch) => {
    dispatch({
      type:actions.PORTFOLIO_LIST_LOADER,
      payload: true
    });


    let queryParams = {}
    if(startDate == null || startDate == 'Invalid date' || endDate == null || endDate == 'Invalid date'){
      queryParams = {
        download: fileType,
        filter_type: filterType
      }
    }else{
      queryParams = {
        download: fileType,
        filter_type: filterType,
        startDate,
        endDate
      }
    }
    globalExportService('contracts/portfolio/'+portfolio.slug+'/get-portfolio-cashflow-projection/',queryParams)
    .then(response => {
      dispatch({
        type:actions.PORTFOLIO_LIST_LOADER,
        payload: false
      });
      downloadFileType(response, filterKeyName[filterType]+'.', fileType)
    });
  }
}

export const getFleetUsageAc = () =>{
  return(dispatch) =>{
    globalGetService('/console/license/fleet-cashflow/usage/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FLEET_VIEW_USAGE,
          payload: response.data.data
        })
      }
    })
  }
}
