import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { getLocalStorageInfo } from '../../../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput } from '../../Elements';
import { displayDateFormat, backendDateFormat, days,securityGroups, imgStoragePath } from '../../../../constants';
export default class MRInvoiceConf extends Component {
  constructor(props){
    super(props);
    this.state = {
      mrRateInvoiceInfo: {},
      adminFlag:false,
      contracterFlag:false,
      requireBanking:true,
    }
  }

  componentDidMount(){
    let data = this.props.mrRateInvoiceInfo
    // data = {
    //   ...data,
    //   mr_bank_account_name: this.props.mrRateInvoiceInfo.bank_account_name !== undefined && this.props.mrRateInvoiceInfo.bank_account_name !== null && this.props.mrRateInvoiceInfo.bank_account_name !== '' ? this.props.mrRateInvoiceInfo.bank_account_name : getLocalStorageInfo().defaultLessor.bank_account_name,
    //   mr_bank_code: this.props.mrRateInvoiceInfo.bank_code !== undefined && this.props.mrRateInvoiceInfo.bank_code !== null && this.props.mrRateInvoiceInfo.bank_code !== '' ? this.props.mrRateInvoiceInfo.bank_code : getLocalStorageInfo().defaultLessor.bank_code,
    //   mr_bank_iban: this.props.mrRateInvoiceInfo.bank_iban !== undefined && this.props.mrRateInvoiceInfo.bank_iban !== null && this.props.mrRateInvoiceInfo.bank_iban !== '' ? this.props.mrRateInvoiceInfo.bank_iban : getLocalStorageInfo().defaultLessor.bank_iban,
    //   mr_bank_name: this.props.mrRateInvoiceInfo.bank_name !== undefined && this.props.mrRateInvoiceInfo.bank_name !== null && this.props.mrRateInvoiceInfo.bank_name !== '' ? this.props.mrRateInvoiceInfo.bank_name : getLocalStorageInfo().defaultLessor.bank_name,
    //   mr_bank_note: this.props.mrRateInvoiceInfo.bank_note !== undefined && this.props.mrRateInvoiceInfo.bank_note !== null && this.props.mrRateInvoiceInfo.bank_note !== '' ? this.props.mrRateInvoiceInfo.bank_note : getLocalStorageInfo().defaultLessor.bank_note,
    //   mr_bank_reference: this.props.mrRateInvoiceInfo.bank_reference !== undefined && this.props.mrRateInvoiceInfo.bank_reference !== null && this.props.mrRateInvoiceInfo.bank_reference !== '' ? this.props.mrRateInvoiceInfo.bank_reference : getLocalStorageInfo().defaultLessor.bank_reference,
    //   mr_bank_sort_code: this.props.mrRateInvoiceInfo.bank_sort_code !== undefined && this.props.mrRateInvoiceInfo.bank_sort_code !== null && this.props.mrRateInvoiceInfo.bank_sort_code !== '' ? this.props.mrRateInvoiceInfo.bank_sort_code : getLocalStorageInfo().defaultLessor.bank_sort_code,
    //   mr_bank_account_number: this.props.mrRateInvoiceInfo.bank_account_number !== undefined && this.props.mrRateInvoiceInfo.bank_account_number !== null && this.props.mrRateInvoiceInfo.bank_account_number !== '' ? this.props.mrRateInvoiceInfo.bank_account_number : getLocalStorageInfo().defaultLessor.bank_account_number,
    //   mr_bank_swift_code: this.props.mrRateInvoiceInfo.bank_swift_code !== undefined && this.props.mrRateInvoiceInfo.bank_swift_code !== null && this.props.mrRateInvoiceInfo.bank_swift_code !== '' ? this.props.mrRateInvoiceInfo.bank_swift_code : getLocalStorageInfo().defaultLessor.bank_swift_code,
    //   mr_footer_note: this.props.mrRateInvoiceInfo.mr_footer_note !== undefined && this.props.mrRateInvoiceInfo.mr_footer_note !== null && this.props.mrRateInvoiceInfo.mr_footer_note !== '' ? this.props.mrRateInvoiceInfo.mr_footer_note : getLocalStorageInfo().defaultLessor.footer_note,
    //   mr_hub_number: this.props.mrRateInvoiceInfo.mr_hub_number !== undefined && this.props.mrRateInvoiceInfo.mr_hub_number !== null && this.props.mrRateInvoiceInfo.mr_hub_number !== '' ? this.props.mrRateInvoiceInfo.mr_hub_number : getLocalStorageInfo().defaultLessor.hub_number,
    //   mr_vat_number: this.props.mrRateInvoiceInfo.mr_vat_number !== undefined && this.props.mrRateInvoiceInfo.mr_vat_number !== null && this.props.mrRateInvoiceInfo.mr_vat_number !== '' ? this.props.mrRateInvoiceInfo.mr_footer_note : getLocalStorageInfo().defaultLessor.vat_number,
    // }
    this.setState({
      mrRateInvoiceInfo: data
    });
    this.props.mrRateInvoiceInfo.requested_data && Object.keys(this.props.mrRateInvoiceInfo.requested_data).length ?
      getLocalStorageInfo().user.is_root_user?
      this.setState({
        adminFlag: true
      }):
      getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
      this.setState({
        adminFlag: true
      }):
      this.setState({
        contracterFlag: true
      }):
      this.setState({
        contracterFlag: false,
        adminFlag:false
      })

  }

  capitalizeFirstLetter=(str)=> {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  updateForm = (type, value) => {
    if(['mr_bank_code','mr_bank_iban', 'mr_bank_name', 'mr_bank_note', 'mr_bank_reference', 'mr_bank_sort_code', 'mr_bank_account_name', 'mr_bank_account_number', 'mr_bank_swift_code'].includes(type)){
      if(value && value !== ''){
        this.setState({
          requireBanking: true
        })
      }else{
        this.setState({
          requireBanking: false
        })
      }
    }
    this.setState(prevState => ({
      ...prevState,
      mrRateInvoiceInfo: {
        ...prevState.mrRateInvoiceInfo,
        [type]: value
      }
    }))
  }

  updateFormEdit = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      mrRateInvoiceInfo: {
        ...prevState.mrRateInvoiceInfo,
        requested_data:{
          ...prevState.mrRateInvoiceInfo.requested_data,
          [type]: value
        }
      }
    }))
  }
  contrInvcInfoAccept = (e,flag) => {

    e.preventDefault();
    let data = Object.assign({}, this.state.mrRateInvoiceInfo);
    data = {
      ...data,
      change_request:flag?1:2,
      is_mr:true,
      asset_id: this.state.mrRateInvoiceInfo.asset.id,
      lessor_id: this.state.mrRateInvoiceInfo.lessor.id,
      lessee_id: this.state.mrRateInvoiceInfo.lessee.id,
      operator_id: this.state.mrRateInvoiceInfo.operator.id,
      rental_type : this.state.mrRateInvoiceInfo.rental_type && this.state.mrRateInvoiceInfo.rental_type.value ? this.state.mrRateInvoiceInfo.rental_type.value : null,
      rental_interval : this.state.mrRateInvoiceInfo.rental_interval && this.state.mrRateInvoiceInfo.rental_interval.value ? this.state.mrRateInvoiceInfo.rental_interval.value : null,
      rental_escalation_interval : this.state.mrRateInvoiceInfo.rental_escalation_interval && this.state.mrRateInvoiceInfo.rental_escalation_interval.value ? this.state.mrRateInvoiceInfo.rental_escalation_interval.value : null,
      lessor_portfolio : this.state.mrRateInvoiceInfo.lessor_portfolio && this.state.mrRateInvoiceInfo.lessor_portfolio.value ? this.state.mrRateInvoiceInfo.lessor_portfolio.value : null,
      lease_status : this.state.mrRateInvoiceInfo.lease_status && this.state.mrRateInvoiceInfo.lease_status.value ? this.state.mrRateInvoiceInfo.lease_status.value : null
    }

    delete data['lease_type'];
    delete data['lease_type_accounting'];
    delete data['operator'];
    delete data['lessor'];
    delete data['lessor'];
    delete data['lessee'];
    delete data['asset'];
    if(flag==1){
      this.props.contrInvcInfoEdit(data);
    }else{
      this.props.toggleReason(data);
    }

  }


  contrInvcInfoEdit = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.mrRateInvoiceInfo);
    data = {
      ...data,
      is_mr:true,
      asset_id: this.state.mrRateInvoiceInfo.asset.id,
      lessor_id: this.state.mrRateInvoiceInfo.lessor.id,
      lessee_id: this.state.mrRateInvoiceInfo.lessee.id,
      operator_id: this.state.mrRateInvoiceInfo.operator.id,
      rental_type : this.state.mrRateInvoiceInfo.rental_type && this.state.mrRateInvoiceInfo.rental_type.value ? this.state.mrRateInvoiceInfo.rental_type.value : null,
      rental_interval : this.state.mrRateInvoiceInfo.rental_interval && this.state.mrRateInvoiceInfo.rental_interval.value ? this.state.mrRateInvoiceInfo.rental_interval.value : null,
      rental_escalation_interval : this.state.mrRateInvoiceInfo.rental_escalation_interval && this.state.mrRateInvoiceInfo.rental_escalation_interval.value ? this.state.mrRateInvoiceInfo.rental_escalation_interval.value : null,
      lessor_portfolio : this.state.mrRateInvoiceInfo.lessor_portfolio && this.state.mrRateInvoiceInfo.lessor_portfolio.value ? this.state.mrRateInvoiceInfo.lessor_portfolio.value : null,
      lease_status : this.state.mrRateInvoiceInfo.lease_status && this.state.mrRateInvoiceInfo.lease_status.value ? this.state.mrRateInvoiceInfo.lease_status.value : null
    }


    delete data['lease_type'];
    delete data['lease_type_accounting'];
    delete data['operator'];
    delete data['lessor'];
    delete data['lessor'];
    delete data['lessee'];
    delete data['asset'];
    this.props.contrInvcInfoEdit(data);
  }

  render(){
    const { mrRateInvoiceInfo,adminFlag ,contracterFlag, requireBanking } = this.state;
    const { type } = this.props;

    return(
      <form onSubmit={(e) => this.contrInvcInfoEdit(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_invoice_day_of_month ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Invoice Generation Day ( For Every Month )</FieldLabel>
                <FieldSelect
                  placeholder='Select Day'
                  keyParam="mr_invoice_day_of_month"
                  value={mrRateInvoiceInfo.mr_invoice_day_of_month}
                  type={type}
                  options={days}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                />
              </FieldGroup>
              {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_invoice_day_of_month ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Invoice Generation Day ( For Every Month ) (Edited) <span className="error-msg">*</span></FieldLabel>
                <FieldSelect
                  placeholder='Select Day'
                  keyParam="mr_invoice_day_of_month"
                  value={mrRateInvoiceInfo.requested_data.mr_invoice_day_of_month}
                  type={type}
                  options={days}
                  updateField={this.updateFormEdit}
                  labelKey= 'label'
                  valueKey='value'
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_invoice_due_day_of_month ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Invoice Due Day ( For Every Month )</FieldLabel>
                  <FieldSelect
                    placeholder='Select Day'
                    keyParam="mr_invoice_due_day_of_month"
                    value={mrRateInvoiceInfo.mr_invoice_due_day_of_month}
                    type={type}
                    options={days}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
              </FieldGroup>
              { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_invoice_due_day_of_month ?
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Invoice Due Day ( For Every Month ) (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldSelect
                    placeholder='Select Day'
                    keyParam="mr_invoice_due_day_of_month"
                    value={mrRateInvoiceInfo.requested_data.mr_invoice_due_day_of_month}
                    type={type}
                    options={days}
                    updateField={this.updateFormEdit}
                    labelKey= 'label'
                    valueKey='value'
                  />
              </FieldGroup>:null}
            </FieldCol>
            <Col md="12">
              <div className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_invoice_is_due_day_next_month ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <label className="label">
                  <input type="checkbox" name="mr-invoice" checked={mrRateInvoiceInfo.mr_invoice_is_due_day_next_month} onChange={(e) => this.updateForm('mr_invoice_is_due_day_next_month', e.target.checked)} />
                  <span> Invoice Due Day falls in next Month</span>
                </label>
              </div>
              { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_invoice_is_due_day_next_month ?
              <div className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <label className="label">
                  <input type="checkbox" name="mr-invoice" checked={mrRateInvoiceInfo.requested_data.mr_invoice_is_due_day_next_month} onChange={(e) => this.updateFormEdit('mr_invoice_is_due_day_next_month', e.target.checked)} />
                  <span>  Invoice Due Day falls in next Month (Edited) <span className="error-msg">*</span></span>
                </label>
              </div>:null}
            </Col>
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <h5 className="para-ui escaltion-seperator">Invoice Sender </h5>
              </FieldCol>
              :null
            }
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_from_company_name ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label">Invoice From</FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.mr_from_company_name}
                    type={type}
                    keyParam="mr_from_company_name"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
                { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_from_company_name ?
                <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label"> Invoice From (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.requested_data.mr_from_company_name}
                    type={type}
                    keyParam="mr_from_company_name"
                    updateField={this.updateFormEdit}
                  />
                </FieldGroup>:null}
              </FieldCol>
              :null
            }
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_from_company_address ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label">Company Address</FieldLabel>
                  <FieldTextarea
                    value={mrRateInvoiceInfo.mr_from_company_address}
                    type={type}
                    keyParam="mr_from_company_address"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
                { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_from_company_address ?
                <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label"> Company Address (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldTextarea
                    value={mrRateInvoiceInfo.requested_data.mr_from_company_address}
                    type={type}
                    keyParam="mr_from_company_address"
                    updateField={this.updateFormEdit}
                  />
                </FieldGroup>:null}
              </FieldCol>
              :null
            }
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?

              <FieldCol md="12">
                <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_from_company_contact ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label">Contact Details</FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.mr_from_company_contact}
                    type={type}
                    keyParam="mr_from_company_contact"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
                { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_from_company_contact ?
                <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label"> Contact Details (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.requested_data.mr_from_company_contact}
                    type={type}
                    keyParam="mr_from_company_contact"
                    updateField={this.updateFormEdit}
                  />
                </FieldGroup>:null}
              </FieldCol>
              :null
            }

            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <h5 className="para-ui escaltion-seperator">Invoice Receiver</h5>
              </FieldCol>
              :null
            }
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_to_company_name ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label">Invoice To</FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.mr_to_company_name}
                    type={type}
                    keyParam="mr_to_company_name"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
                { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_to_company_name ?
                <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label"> Invoice To (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.requested_data.mr_to_company_name}
                    type={type}
                    keyParam="mr_to_company_name"
                    updateField={this.updateFormEdit}
                  />
                </FieldGroup>:null}
              </FieldCol>
              :null
            }
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_to_company_address ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label">Company Address</FieldLabel>
                  <FieldTextarea
                    value={mrRateInvoiceInfo.mr_to_company_address}
                    type={type}
                    keyParam="mr_to_company_address"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
                { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_to_company_address ?
                <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label"> Company Address (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldTextarea
                    value={mrRateInvoiceInfo.requested_data.mr_to_company_address}
                    type={type}
                    keyParam="mr_to_company_address"
                    updateField={this.updateFormEdit}
                  />
                </FieldGroup>:null}
              </FieldCol>
              :null
            }
            {
              ![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <FieldCol md="12">
                <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_to_company_contact ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label">Contact Details</FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.mr_to_company_contact}
                    type={type}
                    keyParam="mr_to_company_contact"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
                {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_to_company_contact ?
                <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                  <FieldLabel className="label"> Contact Details (Edited) <span className="error-msg">*</span></FieldLabel>
                  <FieldInput
                    value={mrRateInvoiceInfo.requested_data.mr_to_company_contact}
                    type={type}
                    keyParam="mr_to_company_contact"
                    updateField={this.updateFormEdit}
                  />
                </FieldGroup>:null}
              </FieldCol>
              :null
            }
            <FieldCol md="12" style={{display: 'none'}}>
              <h5 className="para-ui escaltion-seperator"> Bank Details</h5>
            </FieldCol>
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_name ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Bank Name {requireBanking ? <span className="error-msg">*</span> : null}</FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.mr_bank_name}
                  type={type}
                  keyParam="mr_bank_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_name ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Bank Name (Edited) <span className="error-msg">*</span></FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.requested_data.mr_bank_name}
                  type={type}
                  keyParam="mr_bank_name"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_account_name ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Bank Account Name{requireBanking ? <span className="error-msg">*</span> : null}</FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.mr_bank_account_name}
                  type={type}
                  keyParam="mr_bank_account_name"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_account_name ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Bank Account Name (Edited) <span className="error-msg">*</span></FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.requested_data.mr_bank_account_name}
                  type={type}
                  keyParam="mr_bank_account_name"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}

            </FieldCol>
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_account_number ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Bank Account Number{requireBanking ? <span className="error-msg">*</span> : null}</FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.mr_bank_account_number}
                  type={type}
                  keyParam="mr_bank_account_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_account_number ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Bank Account Number (Edited)<span className="error-msg">*</span></FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.requested_data.mr_bank_account_number}
                  type={type}
                  keyParam="mr_bank_account_number"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_iban ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> {getLocalStorageInfo().defaultLessor.id==14?"Bank ABA":"Bank IBAN"}</FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.mr_bank_iban}
                  type={type}
                  keyParam="mr_bank_iban"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_iban ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">{ getLocalStorageInfo().defaultLessor.id==14 ? "Bank ABA (Edited)":"Bank IBAN (Edited)"} <span className="error-msg">*</span></FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.requested_data.mr_bank_iban}
                  type={type}
                  keyParam="mr_bank_iban"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_swift_code ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Bank Swift Code</FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.mr_bank_swift_code}
                  type={type}
                  keyParam="mr_bank_swift_code"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_swift_code ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Bank Swift Code (Edited) </FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.requested_data.mr_bank_swift_code}
                  type={type}
                  keyParam="mr_bank_swift_code"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_sort_code ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Bank SORT Code</FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.mr_bank_sort_code}
                  type={type}
                  keyParam="mr_bank_sort_code"
                  updateField={this.updateForm}
                  maxLength={20}
                />
              </FieldGroup>
            {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_bank_sort_code ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Bank SORT Code (Edited) </FieldLabel>
                <FieldInput
                  value={mrRateInvoiceInfo.requested_data.mr_bank_sort_code}
                  type={type}
                  keyParam="mr_bank_sort_code"
                  updateField={this.updateFormEdit}
                  maxLength={20}
                />
              </FieldGroup>:null}
            </FieldCol>
            { [14,242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <Fragment style={{display: 'none'}}>
                <FieldCol md="12">
                  <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_hub_number ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                    <FieldLabel className="label">Hub Account</FieldLabel>
                    <FieldInput
                      value={mrRateInvoiceInfo.mr_hub_number}
                      type={type}
                      keyParam="mr_hub_number"
                      updateField={this.updateForm}
                      maxLength={20}
                    />
                  </FieldGroup>
                {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_hub_number ?
                  <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                    <FieldLabel className="label"> Hub Account (Edited) </FieldLabel>
                    <FieldInput
                      value={mrRateInvoiceInfo.requested_data.mr_hub_number}
                      type={type}
                      keyParam="mr_hub_number"
                      updateField={this.updateFormEdit}
                      maxLength={20}
                    />
                  </FieldGroup>:null}
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.vat_number ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                    <FieldLabel className="label">VAT Number</FieldLabel>
                    <FieldInput
                      value={mrRateInvoiceInfo.mr_vat_number}
                      type={type}
                      keyParam="mr_vat_number"
                      updateField={this.updateForm}
                    />
                  </FieldGroup>
                {mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.vat_number ?
                  <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                    <FieldLabel className="label"> VAT Number (Edited) </FieldLabel>
                    <FieldInput
                      value={mrRateInvoiceInfo.requested_data.mr_vat_number}
                      type={type}
                      keyParam="mr_vat_number"
                      updateField={this.updateFormEdit}
                    />
                  </FieldGroup>:null}
                </FieldCol>
              </Fragment> : null
            }
            <FieldCol md="12" style={{display: 'none'}}>
              <FieldGroup className="form-group" style={adminFlag && mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_footer_note ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Footer Note</FieldLabel>
                <FieldTextarea
                  value={mrRateInvoiceInfo.mr_footer_note}
                  type={type}
                  keyParam="mr_footer_note"
                  updateField={this.updateForm}
                />
              </FieldGroup>
              { mrRateInvoiceInfo.requested_data && mrRateInvoiceInfo.requested_data.mr_footer_note ?
              <FieldGroup className="form-group"style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label"> Footer Note (Edited) <span className="error-msg">*</span></FieldLabel>
                <FieldTextarea
                  value={mrRateInvoiceInfo.requested_data.mr_footer_note}
                  type={type}
                  keyParam="mr_footer_note"
                  updateField={this.updateFormEdit}
                />
              </FieldGroup>:null}
            </FieldCol>
          </Row>
       </div>
      {mrRateInvoiceInfo.requested_data && Object.keys(mrRateInvoiceInfo.requested_data).length ?
      getLocalStorageInfo().user.is_root_user?

      <div className="submit-block">
      <Link style={{marginRight:'10px'}} onClick ={(e)=>this.contrInvcInfoAccept(e,true)} className="primary-btn" >Accept</Link>
      <Link style={{marginRight:'10px',background:'#ff5e5e',border: '1px solid #ff5e5e'}} onClick ={(e)=>this.contrInvcInfoAccept(e,false)} className="primary-btn" >Reject</Link>
      <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
      <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}} onClick={()=>this.props.toggleRevisionHistory()} ><img src={imgStoragePath+'audit_icon.png'} className="audit-img" alt="img" style={{ position: 'relative', bottom: '1px' }} />Revision History</Link>
      {mrRateInvoiceInfo.requested_data&&mrRateInvoiceInfo.requested_data.created_by&&mrRateInvoiceInfo.requested_data.created_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',
       marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {mrRateInvoiceInfo.requested_data&&mrRateInvoiceInfo.requested_data.created_by?this.capitalizeFirstLetter(mrRateInvoiceInfo.requested_data.created_by.first_name+' '+mrRateInvoiceInfo.requested_data.created_by.last_name)+' at '+ moment(mrRateInvoiceInfo.requested_data.created_at).format(displayDateFormat):'--'}
      </div>
      }
      </div>:
      getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
      <div className="submit-block">
      <Link style={{marginRight:'10px'}} onClick ={(e)=>this.contrInvcInfoAccept(e,true)} className="primary-btn" >Accept</Link>
      <Link style={{marginRight:'10px',background:'#ff5e5e',border: '1px solid #ff5e5e'}} onClick ={(e)=>this.contrInvcInfoAccept(e,false)} className="primary-btn" >Reject</Link>
      <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
      <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={()=>this.props.toggleRevisionHistory()} ><img src={imgStoragePath+'audit_icon.png'} alt="img" className="audit-img" style={{ position: 'relative', bottom: '1px' }} /> Revision History</Link>
      {mrRateInvoiceInfo.requested_data&&mrRateInvoiceInfo.requested_data.created_by&&mrRateInvoiceInfo.requested_data.created_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',
       marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {mrRateInvoiceInfo.requested_data&&mrRateInvoiceInfo.requested_data.created_by?this.capitalizeFirstLetter(mrRateInvoiceInfo.requested_data.created_by.first_name+' '+mrRateInvoiceInfo.requested_data.created_by.last_name)+' at '+ moment(mrRateInvoiceInfo.requested_data.created_at).format(displayDateFormat):'--'}
      </div>
      }
      </div>:
      <div className="submit-block">
      <Link  style={{marginRight:'10px',opacity:'0.5'}}  className="primary-btn" >In Review</Link>
      <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
      <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={()=>this.props.toggleRevisionHistory()} > <img src={imgStoragePath+'audit_icon.png'} alt="img" className="audit-img" style={{ position: 'relative', bottom: '1px' }} /> Revision History</Link>
      {mrRateInvoiceInfo.requested_data&&mrRateInvoiceInfo.requested_data.created_by&&mrRateInvoiceInfo.requested_data.created_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {mrRateInvoiceInfo.requested_data&&mrRateInvoiceInfo.requested_data.created_by?this.capitalizeFirstLetter(mrRateInvoiceInfo.requested_data.created_by.first_name+' '+mrRateInvoiceInfo.requested_data.created_by.last_name)+' at '+ moment(mrRateInvoiceInfo.requested_data.created_at).format(displayDateFormat):'--'}
      </div>
      }
      </div>:
       getLocalStorageInfo().user.is_root_user?
       <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={()=>this.props.toggleRevisionHistory()} ><img src={imgStoragePath+'audit_icon.png'} alt="img" className="audit-img" style={{ position: 'relative', bottom: '1px' }} />Review Revision History</Link>
      {mrRateInvoiceInfo.mr_last_updated_by?mrRateInvoiceInfo.mr_last_updated_by&&mrRateInvoiceInfo.mr_last_updated_by.id&&mrRateInvoiceInfo.mr_last_updated_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {mrRateInvoiceInfo.mr_last_updated_by&&mrRateInvoiceInfo.mr_last_updated_by.first_name?this.capitalizeFirstLetter(mrRateInvoiceInfo.mr_last_updated_by.first_name+' '+mrRateInvoiceInfo.mr_last_updated_by.last_name)+' at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat):'--'}
      </div>:null
      }
       </div>:
        getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={()=>this.props.toggleRevisionHistory()} ><img src={imgStoragePath+'audit_icon.png'} alt="img" className="audit-img" style={{ position: 'relative', bottom: '1px' }} /> Revision History</Link>
      {mrRateInvoiceInfo.mr_last_updated_by?mrRateInvoiceInfo.mr_last_updated_by&&mrRateInvoiceInfo.mr_last_updated_by.id&&mrRateInvoiceInfo.mr_last_updated_by.id==getLocalStorageInfo().user.id?
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {'You at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat)}
      </div>:
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {mrRateInvoiceInfo.mr_last_updated_by&&mrRateInvoiceInfo.mr_last_updated_by.first_name?this.capitalizeFirstLetter(mrRateInvoiceInfo.mr_last_updated_by.first_name+' '+mrRateInvoiceInfo.mr_last_updated_by.last_name)+' at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat):'--'}
      </div>:null
      }
       </div>:
       <div className="submit-block">
       <input type="submit" className="primary-btn" value="Submit"/>
       <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
       <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={()=>this.props.toggleRevisionHistory()} ><img src={imgStoragePath+'audit_icon.png'} alt="img" className="audit-img" style={{ position: 'relative', bottom: '1px' }} /> Revision History</Link>
       {mrRateInvoiceInfo.mr_last_updated_by?mrRateInvoiceInfo.mr_last_updated_by&&mrRateInvoiceInfo.mr_last_updated_by.id&&mrRateInvoiceInfo.mr_last_updated_by.id==getLocalStorageInfo().user.id?
       <div style={{fontSize: '12px',
       marginTop: '5px',color:'#877a7a'}}>
       Last Edited by: {'You at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat)}
       </div>:
      <div style={{fontSize: '12px',
      marginTop: '5px',color:'#877a7a'}}>
      Last Edited by: {mrRateInvoiceInfo.mr_last_updated_by&&mrRateInvoiceInfo.mr_last_updated_by.first_name?this.capitalizeFirstLetter(mrRateInvoiceInfo.mr_last_updated_by.first_name+' '+mrRateInvoiceInfo.mr_last_updated_by.last_name)+' at '+ moment(mrRateInvoiceInfo.mr_last_updated_at).format(displayDateFormat):'--'}
      </div>:null
      }
      </div>
      }
     </form>
    )
  }
}
