import React from 'react';
import {Badge} from 'reactstrap'
import {imgStoragePath} from '../../../constants'
export default function SelectedTag({handleSelectedTag, tag}) {
  return (
    <li  className="list-inline-item" >
    <Badge color="light" onClick={()=>handleSelectedTag()} >
    <img width="9" src={imgStoragePath+"black_close.png"} alt="img" style={{marginRight:"3px", cursor:"pointer"}}  />
    {tag.name}
    </Badge>
    </li>
  )
}
