import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { displayDateFormat, backendDateFormat, fieldDateFormat, float2DecRegx } from '../../../../constants'
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class InsuranceInvoiceItem extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { type, invoiceInfo, invoice_item, index, multiCurrency } = this.props
    if(type === 'view'){
      return(
        <tr>
          <td>
            <h6 className="para-ui">
              {invoice_item && invoice_item.invoice_item_type_id && invoice_item.invoice_item_type_id.name ? invoice_item.invoice_item_type_id.name : invoice_item && invoice_item.is_additional_rate ? 'Additional Rent' : 'Basic Rent'}
              {invoice_item && invoice_item.is_additional_rate ?
              <span>
                <p style={{fontSize:'12px',marginTop:'10px'}}>Libor(%)</p>
                <p  style={{marginTop:'5px'}}>{invoice_item.libor_value}</p>
                <p style={{fontSize:'12px',marginTop:'10px'}}>Libor Rate as of date</p>
                <p style={{marginTop:'5px'}}>{invoice_item.libor_date? moment(invoice_item.libor_date).format(displayDateFormat) : '--' }</p>
              </span>:null}
            </h6>
          </td>
          <td className="invoice-description-block">
            <h6 className="para-ui"> {invoice_item.is_additional_rate ? invoice_item.payment_schedule ?
            <p>Dollar margin applied: {invoice_item.payment_schedule.margin}%, Rental Period: {invoice_item.payment_schedule.period}, Dollar Reference Value:
            <NumericLabel params={showCurrencyFormat('USD')}>
            {invoice_item.payment_schedule.reference_value}
            </NumericLabel></p>
          :'':invoice_item.description}</h6>
          </td>
          <td>
            <h6 className="para-ui">
              {invoiceInfo.insurance_type && invoiceInfo.insurance_type.label ? invoiceInfo.insurance_type.label : '--'}
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.billable_amount}
              </NumericLabel>
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.sub_total }
              </NumericLabel>
            </h6>
          </td>
        </tr>
      )
    }else{
      return(
        <tr>
          <td>
            <h6 className="para-ui">
              {invoice_item && invoice_item.invoice_item_type_id && invoice_item.invoice_item_type_id.name ? invoice_item.invoice_item_type_id.name : invoice_item && invoice_item.is_additional_rate ? 'Additional Rent' : 'Basic Rent'}
              {invoice_item && invoice_item.is_additional_rate ?
              <span>
                <p style={{fontSize:'12px',marginTop:'10px'}}>Libor(%)</p>
                <p  style={{marginTop:'5px'}}>{invoice_item.libor_value}</p>
                <p style={{fontSize:'12px',marginTop:'10px'}}>Libor Rate as of date</p>
                <p style={{marginTop:'5px'}}>{invoice_item.libor_date? moment(invoice_item.libor_date).format(displayDateFormat) : '--' }</p>
              </span>:null}
            </h6>
          </td>
          <td className="invoice-description-block">
            <h6 className="para-ui"> {invoice_item.is_additional_rate ? invoice_item.payment_schedule ?
            <p>Dollar margin applied: {invoice_item.payment_schedule.margin}%, Rental Period: {invoice_item.payment_schedule.period}, Dollar Reference Value:
            <NumericLabel params={showCurrencyFormat('USD')}>
            {invoice_item.payment_schedule.reference_value}
            </NumericLabel></p>
          :'':invoice_item.description}</h6>
          </td>
          <td>
            <h6 className="para-ui">
              {invoiceInfo.insurance_type && invoiceInfo.insurance_type.label ? invoiceInfo.insurance_type.label : '--'}
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.billable_amount}
              </NumericLabel>
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.sub_total }
              </NumericLabel>
            </h6>
          </td>
        </tr>
      )
    }
  }
}
