import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, prettyNumberParamsL } from '../../../../constants';
const AfClaimInfo = (props) => {
  return(
    <Row>
      <Col md="4" className="label-card">
        <h6 className="label-title">Submission Date</h6>
        <span className="label-value">{props.claim.submission_date ? moment(props.claim.submission_date).format(displayDateFormat): '--'}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Claim Amount</h6>
        <span className="label-value">
          <NumericLabel params={prettyNumberParamsL}>
            {props.claim.claim_amount}
          </NumericLabel>
        </span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Claim Status</h6>
        <span className="label-value">{props.claim.status.label}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Reserve Account</h6>
        <span className="label-value">{props.claim.name}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Claim Details</h6>
        <span className="label-value">{props.claim.description}</span>
      </Col>
      <Col md="4" className="label-card">
        <h6 className="label-title">Rejection Reason</h6>
      <span className="label-value">{props.claim.reason_for_rejection}</span>
      </Col>
    </Row>
  )
}

export default AfClaimInfo;
