import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldDate, FieldTextarea } from '../../../contracts/Elements';
import { checkString, checkNumber } from '../../../../formValidator';

export default class AddEditPayout extends Component{
  constructor(props){
    super(props);
    this.state = {
      afClaim: {},
      error: {}
    }
  }
  componentDidMount() {
    let data = Object.assign({}, this.props.payoutObj);
    this.setState(prevState => ({
      ...prevState,
      afClaim: data
    }));
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      afClaim: {
        ...prevState.afClaim,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditClaims = (e) => {
    const { afClaim } = this.state
    e.preventDefault();
    let validateNewInput = {
      reference_number: checkString({value: this.state.afClaim.reference_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Reference Number'}),
      amount_paid: checkNumber({value: this.state.afClaim.amount_paid, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Payout Amount'})
    };
    if(afClaim.amount_paid > afClaim.claim_amount){
      validateNewInput = {
        ...validateNewInput,
        amount_paid:"Please enter Payout amount less than Claim amount"
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.toggleEditSideBar();
      this.props.addEditClaims(this.state.afClaim, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { type, loader } = this.props;
    const { afClaim, error } = this.state;
    return(
      <form onSubmit={(e) => this.addEditClaims(e)}>
       <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
         <Row>

           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                 Payout Amount
                 { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldInput
                 value={afClaim.amount_paid}
                 type={type}
                 keyParam="amount_paid"
                 updateField={this.updateForm}
               />
              <h6 className="error-msg">{error.amount_paid}</h6>

             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Reference Number
               { type != 'view' ? <sup className="reqStar"> * </sup>:null}

               </FieldLabel>
               <FieldInput
                 value={afClaim.reference_number}
                 type={type}
                 keyParam="reference_number"
                 updateField={this.updateForm}
               />
              <h6 className="error-msg">{error.reference_number}</h6>

             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Transaction Description</FieldLabel>
               <FieldTextarea
                 value={afClaim.transaction_description}
                 type={type}
                 keyParam="transaction_description"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Remarks</FieldLabel>
               <FieldTextarea
                 value={afClaim.remarks}
                 type={type}
                 keyParam="remarks"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>
         </Row>
        </div>
       { type != 'view' ?
         <div className="submit-block">
            <input type="submit" className="primary-btn" disabled={loader} value={loader ? 'Processing...': 'Save Changes'}/>
            <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
         </div>:null
       }
     </form>
    )
  }
}
