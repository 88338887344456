import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import AddEditRobbery from '../components/AddEditRobbery';
import FormModeType from '../components/FormModeType'
import { robberyListAc, robberyViewCrudAc, robberyAddEditAc,robberyDeleteAc, robberyListExportAc } from '../actionCreators'
import { DeleteModal, EditFormBar, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, DownloadedLogs } from '../../../Elements';
import { MSNSwitcherHeader, TableUIComp, ListLoader,ExportFiles } from '../../../../../shared'
import BulkOperation from '../components/BulkOperation';
import List from '../components/List';
import { imgStoragePath } from '../../../../../constants';
import { UPDATE_SELECTED_IDS } from '../actions';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';

class InspectionFormT009 extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalTitle: '',
      modal: false,
      delModal: false,
      deleteType: '',
      deleteId: [],
      downloadFormLogs: false,
      filterParams: {}
    }
  }
  componentDidMount(){
    let location = browserHistory.getCurrentLocation();
    this.props.fetchtRobberyList(location.query);
    this.props.getFormDetails(1, 'T009');
  }

  toggleDelModal = (deleteType = null) => {
    this.setState(prevState =>({
      ...prevState,
      delModal: !prevState.delModal,
      modalTitle: 'Are you sure to delete?',
      deleteType: deleteType

    }));
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  sortTable = (sortKey) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
        query: {
          ...location.query,
          sort: sortKey,
          page:1,
          per_page: this.props.pagination.per_page,
          sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }
    browserHistory.push(location);
    this.props.fetchtRobberyList(location.query);
  }

  rowActivity = (type, item) => {
    if(type == 'view' || type == 'edit'){
        this.props.toggleEditAddSide({type: type, flag: true, data: item})
    }else if(type == 'delete'){
      this.setState(prevState => ({
        ...prevState,
        deleteId: [item.id]
      }));
      this.toggleDelModal('single');

    }
  }
  deleteRoberyItem = () => {
    let deleteArr = [];
    if(this.state.deleteType == 'single'){
      deleteArr = this.state.deleteId
    }else{
      deleteArr = this.props.selectedIds

    }
    this.props.deleteRoberyItem(deleteArr, this.state.deleteType);
    this.toggleDelModal();
    setTimeout(() => {
      window.location.reload(false)
    }, 2000);
  }
  changePerPage=(count) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'per_page': count,
          'page': 1
      }
    }
    browserHistory.push(location);
    this.props.fetchtRobberyList(location.query);

  }
  changePagination = (type, currentPage) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'page': currentPage
      }
    }
    browserHistory.push(location);
    this.props.fetchtRobberyList(location.query);

  }
  changeFilterFn = (filterParams) => {
    this.setState({filterParams})
    this.props.fetchtRobberyList(filterParams);
  }


  render(){
    const { robberyList , robberyCrud, selectedIds, pagination, robberyLoader, formInfo, formStatusLoader }= this.props;
    const menuList = {
      'Part Number': {
        'inputType': 'text',
        'keyParam': 'part_number',
        'placeholder': 'Search by part number',
        'label': 'Part Number'
      },
      'Part Description': {
        'inputType': 'text',
        'keyParam': 'part_description',
        'placeholder': 'Search by part description',
        'label': 'Part Description'
      },
      'Part Location': {
        'inputType': 'text',
        'keyParam': 'part_location',
        'placeholder': 'Search by part location',
        'label': 'Part Location'
      }
    }
    return(
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.robberyListExport('MSN_'+formInfo.asset.msn+'_'+formInfo.current_form.name,fileType, this.state.filterParams)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'},{img:'excel_icon.png', label: 'Excel', type:'xls'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />
        { robberyLoader || formStatusLoader ? <ListLoader drawer="primary-nav" />:null }
        <div className="form-t009-cn">
          <TableUIComp
              theads={[{label:'Part Number', sortKey: 'part_number'},{label:'Part Description', sortKey: ''}, {label:'Location', sortKey: 'email'}, {label:'Removal Date', sortKey: ''},{label:'Reason For Removal ', sortKey: ''}, {label:'Date Verified Removed from Aircraft', sortKey: ''}, {label: 'Date Verified in Storehouse', sortKey: ''}, {label: 'Date Verified installed on Aircraft', sortKey: ''},  {label: 'Price', sortKey: ''}, {label: '', sortKey: ''}]}
              bulkOperation={ formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? true:false}
              content={robberyList.map((item, index) => <List formInfo={formInfo} key={index} selectedIds={selectedIds} data={item} rowActivity={(type) => this.rowActivity(type, item)} selectItem={(event) => this.props.selectItem(event.target.checked, item.id)} />)}
              tableBulkOp = { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? <BulkOperation addText= "+ Add Robbery Item" pagination = {pagination} selectedIds={selectedIds} toggleSidebar= {() => this.props.toggleEditAddSide({type: 'add', flag: true, data: {}})} bulkDelete = {() => this.toggleDelModal('bulk')}  cancelBulkOp={() => this.props.selectAllItem(false)} />:null}
              pagination = {pagination}
              filter={{menuList: menuList, appliedFilter: {}, displayFilter:true}}
              selectAllItem={(e) => this.props.selectAllItem(e.target.checked, 'all')}
              sortTable={this.sortTable}
              changePerPage ={this.changePerPage}
              changePagination = {this.changePagination}
              closeFilter = {this.closeFilter}
              checkedAll={ robberyList.length > 0 ? selectedIds.length == robberyList.length ? true:false : false}
              searchQuery = {browserHistory.getCurrentLocation().query}
              changeFilterFn={this.changeFilterFn}
              recordsFound = { robberyLoader ? false : robberyList.length ? false:true}

           />
            <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="button" className="btn btn-danger" onClick={() => this.deleteRoberyItem('')}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
                </li>
              </ul>
            </DeleteModal>
            <EditFormBar
               titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {robberyCrud.type +' Robbery'} </h3></div>}
               toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
               isOpen={robberyCrud.flag}
               style={robberyCrud.flag ? {width: '800px'} : {width: '0px'}}
               >
               {robberyCrud.flag ?
                 <AddEditRobbery
                   robbery={robberyCrud }
                   toggleEditAddSide={this.props.toggleEditAddSide}
                   updateSideBar= {this.props.updateSideBar}
                   toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
                   robberyLoader={robberyLoader}
                   />
                 :   null
              }
            </EditFormBar>
          </div>
          <EditFormBar
            titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
            toggleEditSideBar={this.toggleDownloadedLogs}
            isOpen={this.state.downloadFormLogs}
            style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
            >
            {this.state.downloadFormLogs ?
              <DownloadedLogs logs={formInfo.current_form.download_report} />
              :null
            }
          </EditFormBar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  robberyList:state.FormT009Reducer.robberyList,
  robberyCrud:state.FormT009Reducer.robberyCrud,
  selectedIds: state.FormT009Reducer.selectedIds,
  pagination: state.FormT009Reducer.pagination,
  robberyLoader: state.FormT009Reducer.robberyLoader,
  formInfo: state.FormT001Reducer.formInfo,
  formStatusLoader: state.FormT001Reducer.formStatusLoader
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchtRobberyList:(queryParam = {}) => dispatch(robberyListAc(ownProps, queryParam)),
    toggleEditAddSide:(robberyCrud) => dispatch(robberyViewCrudAc(ownProps, robberyCrud)),
    updateSideBar: (data, type) => dispatch(robberyAddEditAc(ownProps, data, type)),
    deleteRoberyItem: (data, type) => dispatch(robberyDeleteAc(ownProps, data, type)),
    robberyListExport: (name,fileType, query) => dispatch(robberyListExportAc(ownProps,fileType,name, query)),
    selectItem: (flag, id) => dispatch({
      type: UPDATE_SELECTED_IDS,
      payload: {flag, id, type: 'single'}
    }),
    selectAllItem: (flag) => dispatch({
      type: UPDATE_SELECTED_IDS,
      payload: {flag, type: 'all'}
    }),
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT009);
