export const RECORDS_CTR_SHORT_INFO = 'RECORDS_CTR_SHORT_INFO';
export const INQUE_LIST = 'INQUE_LIST';
export const UPDATE_DOCK_STATUS = 'UPDATE_DOCK_STATUS';
export const DOCK_LOADER = 'DOCK_LOADER';
export const PUBLISH_ALL_FILES = 'PUBLISH_ALL_FILES';
export const PUBLISH_FILES = 'PUBLISH_FILES';
export const ADD_TO_PUBLISH_ALL = 'ADD_TO_PUBLISH_ALL';
export const ADD_TO_PUBLISH = 'ADD_TO_PUBLISH';
export const RENAME_RECORD = 'RENAME_RECORD';
export const CHANGE_OCR_PRIORITY = 'CHANGE_OCR_PRIORITY';
export const PAGINATION = 'PAGINATION';
export const RESTORE_REJECTED_RECORD = 'RESTORE_REJECTED_RECORD';
