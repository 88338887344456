import { numberRegx, emailRegx } from '../constants';
import moment from 'moment';
const regex = /^[0-9]*$/;
//const decimalRegex= /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
const decimalRegex= /^[0-9\.\/]+$/;
const mobileNumberRegex=/(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
const phoneNumber = /(^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$)/
const numberWithDot =  /^[0-9]*(\.\d{0,2})?$/


export const checkNumber = (fieldInfo) => {
  return (checkEmpty(fieldInfo) && checkPattern(fieldInfo) && checkMinLength(fieldInfo) && checkMaxLength(fieldInfo)) ? '':fieldInfo.message;
}
export const checkString = (fieldInfo) => {
  return (checkEmpty(fieldInfo) && checkMinLength(fieldInfo) && checkMaxLength(fieldInfo)) ? '':fieldInfo.message;
}
export const checkIntNumber = (fieldInfo) => {
  return (checkEmpty(fieldInfo) && checkIntType(fieldInfo) && checkMinLength(fieldInfo) && checkMaxLength(fieldInfo)) ? '':fieldInfo.message;
}
export const checkMobileNumber = (fieldInfo) => {
  return checkMobilePattern(fieldInfo) ? '':fieldInfo.message;
}
export const checkValidDate = (fieldInfo) => {
  return (checkDateType(fieldInfo)) ? '':fieldInfo.message;
}
export const checkEmail = (fieldInfo) => {
  return (checkEmpty(fieldInfo) && checkEmailPattern(fieldInfo) ) ? '':fieldInfo.message;
}
export const checkFloat = (fieldInfo) => {
  return (checkEmpty(fieldInfo) && checkFloatPattern(fieldInfo) ) ? '':fieldInfo.message;
}


export const checkDateDiff = (startDate,endDate) => {

  if(!checkEmpty(startDate)){
    return "Please enter Start Date";
  }

  if(!checkEmpty(endDate)){
    return "Please enter End Date";
  }

  if(checkEmpty(endDate) && (startDate.value == "" || startDate.value == undefined || startDate.value == null)) {

    if(endDate.value == "" || endDate.value == undefined || endDate.value == null||!endDate.required){
      return "";
    }
    return "Start date is required";
  }
  if(checkEmpty(endDate) && checkEmpty(startDate)){
    if(!checkDateType(startDate)){
      return "Start date is not valid";
    }
    if(!checkDateType(endDate)){
      return "End date is not valid";
    }
    if(moment(endDate.value).diff(moment(startDate.value),'days')<0){
      return "End Date should be greater than Start Date";
    }
  }
  return "";
}


export const checkDateDiffText = (startDate,endDate,startText,endText) => {

  if(!checkEmpty(endDate)){
    return "Please Enter "+endText;
  }
  if(!checkEmpty(startDate)){
    return "Please Enter "+startText;
  }

  if(checkEmpty(endDate) && (startDate.value == "" || startDate.value == undefined || startDate.value == null)) {
    return startText+" is required";
  }
  if(checkEmpty(endDate) && checkEmpty(startDate)){
    if(!checkDateType(startDate)){
      return startText+" is not valid";
    }
    if(!checkDateType(endDate)){
      return endText+" is not valid";
    }
    if(moment(endDate.value).diff(moment(startDate.value),'days')<0){
      return startText+" can't be post "+endText;
    }
  }
  return "";
}





function checkEmpty(fieldInfo){
  if(fieldInfo.required){
    return (String(fieldInfo.value).trim() != '' && fieldInfo.value != null && fieldInfo.value != undefined) ? true:false
  }else{
    return true
  }
}

function checkFloatPattern(fieldInfo){
  if(!fieldInfo.required && (fieldInfo.value == '' || fieldInfo.value == undefined || fieldInfo.value == null) ){
    return true
  }else{
    return decimalRegex.test(fieldInfo.value);
  }
}

function checkMobilePattern(fieldInfo){
  if(!fieldInfo.required && (fieldInfo.value == '' || fieldInfo.value == undefined || fieldInfo.value == null) ){
    return true
  }else if(fieldInfo.value == '' && fieldInfo.value == undefined && fieldInfo.value == null){
    return phoneNumber.test(fieldInfo.value);
  }
}

function checkPattern(fieldInfo){
  if(!fieldInfo.required && (fieldInfo.value == '' || fieldInfo.value == undefined || fieldInfo.value == null) ){
    return true
  }else{
    return numberWithDot.test(fieldInfo.value);
  }
}
function checkEmailPattern(fieldInfo){
  if(!fieldInfo.required && (fieldInfo.value == '' || fieldInfo.value == undefined || fieldInfo.value == null)){
    return true
  }else{
    return emailRegx.test(fieldInfo.value);
  }
}
function checkMinLength(fieldInfo){
  if(fieldInfo.minLength){
    return parseInt(fieldInfo.value.length) >= parseInt(fieldInfo.minLength) ? true:false
  }else{
    return true
  }
}
function checkMaxLength(fieldInfo){
  if(fieldInfo.maxLength){
      return parseInt(fieldInfo.value.length) <= parseInt(fieldInfo.maxLength) ? true:false
  }else{
    return true
  }
}
function checkDateType(fieldInfo){
  if(!fieldInfo.required && (fieldInfo.value == '' || fieldInfo.value == undefined || fieldInfo.value == null)){
    return true
  }else{
    return fieldInfo.value == undefined || fieldInfo.value == null ? false : moment(fieldInfo.value).isValid();
  }
}
function checkIntType(fieldInfo){
  if(!fieldInfo.required && (fieldInfo.value == '' || fieldInfo.value == undefined || fieldInfo.value == null)){
    return true
  }else{
    return regex.test(fieldInfo.value);
  }
}
