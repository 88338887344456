import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, prettyNumberParamsL } from '../../../constants';
const EventItemRow = ({item, previewDetail}) => {
  return(
    <tr onClick={previewDetail}>
      <td>{item.name? item.name:'--'}</td>
      <td>
        { item.shop_visit_date ? moment(item.shop_visit_date).format(displayDateFormat):'--'}
      </td>
      <td>{item.tsn ? item.tsn:'--'}</td>
      <td>{item.csn ? item.csn:'--'}</td>
      <td>{ item.interval ? item.interval + ' ' + item.interval_type :'--'}</td>
      <td>{item.removal_reason ? item.removal_reason:'--'}</td>
      <td>
        {item.workscope_details ? item.workscope_details:''}
        <br/><span style={{padding: '2px 4px', border: '1px solid #615f5f', borderRadius: '3px', color:'#615f5f', fontSize:'11px', display:'inline-block', textAlign:'center', minWidth:'120px'}}>{item.status.label}</span>
      </td>
      <td>{item.shop_visit_facility ? item.shop_visit_facility:'--'}</td>
      <td>
        {item.cost ?
          <NumericLabel params={prettyNumberParamsL}>
            {item.cost}
          </NumericLabel>
          :'--'
        }
      </td>
    </tr>
  )
}
export default EventItemRow;
