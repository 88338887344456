export const ASSET_DIR_TREE = 'ASSET_DIR_TREE';
export const ASSET_DIR_TREE_EXPAND = 'ASSET_DIR_TREE_EXPAND';
export const SHARE_DATA = 'SHARE_DATA';
export const SHARE_DATA_USER = 'SHARE_DATA_USER';
export const DELETE_RECORD = 'DELETE_RECORD';
export const DIRECTORY_LOADER = 'DIRECTORY_LOADER';
export const DIRECTORY_EXPANSION_LOADER = 'DIRECTORY_EXPANSION_LOADER';
export const DOWNLOAD_LOADER='DOWNLOAD_LOADER';
export const FOLDER_TO_MOVE = 'FOLDER_TO_MOVE';
export const MOVE_RECORD = 'MOVE_RECORD';
export const PROGRESS_PERCENTAGE = 'PROGRESS_PERCENTAGE';
export const POPOVER_LOADER = 'POPOVER_LOADER';
export const UPLOAD_FILE_DATA_ROOM = 'UPLOAD_FILE_DATA_ROOM';
export const FILES_UPLOAD = 'FILES_UPLOAD';
export const GET_VALIDATED_FILES = 'GET_VALIDATED_FILES';
export const SHARE_KEY = 'SHARE_KEY';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const RECORD_DETAIL = 'RECORD_DETAIL';
export const AI_SUPPORTED_FOLDERS = 'AI_SUPPORTED_FOLDERS';
export const AI_SUPPORTED_FOLDERS_FLAG = 'AI_SUPPORTED_FOLDERS_FLAG';
export const FILE_FOLDER_CHECK='FILE_FOLDER_CHECK';
export const CANCEL_FILE_FOLDER_CHECK='CANCEL_FILE_FOLDER_CHECK';
export const STORAGE_USAGE='STORAGE_USAGE';
export const  USER_SHARE_EMAIL_LIST='USER_SHARE_EMAIL_LIST';
export const USER_SELECTED_EMAIL_LIST='USER_SELECTED_EMAIL_LIST';
export const UPDATE_SELECTED_EMAILS='UPDATE_SELECTED_EMAILS';
export const USER_SELECTED_EMAIL_OBJECT='USER_SELECTED_EMAIL_OBJECT';
export const UPDATE_SELECTED_PERMISSION='UPDATE_SELECTED_PERMISSION';
export const BULK_DOWNLOAD_POPUP = 'BULK_DOWNLOAD_POPUP';
export const MINPACK_FOLDERS = 'MINPACK_FOLDERS';
