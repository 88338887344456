import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { Map, Marker,InfoWindow, GoogleApiWrapper } from "google-maps-react";
import { imgStoragePath } from '../../../constants';
class TechnicalLocations extends Component {
  constructor(props){
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      aircraftType: '',
      assetSlug: '',
      lesseeLogo: ''
    }
  }


  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      assetSlug: props.assetSlug,
      aircraftType: props.aircraftType,
      lesseeLogo: props.lesseeLogo,
      assets: props.assets,
    });
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  render() {
    const { title, markerList } = this.props;
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    return (
      <div className="map-wrapper-block"
        style={{
          position: 'relative',
          height: this.props.height + 'px',
          width: '100%'
        }}
      >
        <Map style={{}}  google={this.props.google} zoom={2.1} initialCenter={{ lat: 20.0529906, lng: 64.4385803}}>
          { markerList.map((marker, index) => {
            return(
            <Marker key={index}
                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)}}
                onClick={this.onMarkerClick}
                name = {marker.name}
                aircraftType= {marker.lead_by && marker.lead_by.length? marker.lead_by[0].user.name:'--'}
                assetSlug = {marker.slug}
                lesseeLogo = {marker.lessee_logo}
                assets={marker.assets}
                icon={{
                  url: imgStoragePath + "mapPointer.png",
                }}
              />
            )
          })}
          <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}>
                <div className="map-tooltip-style flex-centered">
                  <div className="inner-first-block">
                    <h1 style={{fontSize:'14px',color:'#696969'}}>{this.state.selectedPlace.name}</h1>
                    <h5 style={{fontSize:'10px',color:'#9999A1',marginTop:'1px'}}>{this.state.aircraftType ?  this.state.aircraftType.length>15? 'Lead By ' +this.state.aircraftType.substring(0,13)+'...' : 'Lead By: ' +this.state.aircraftType : null}</h5>
                    <h5 style={{fontSize:'10px',color:'#696969',marginTop:'3px'}}>{this.state.assets && this.state.assets.msn_count?  this.state.assets.msn_count+' MSN':  this.state.assets && this.state.assets.msn?'MSN '+this.state.assets.msn:null}</h5>
                    <h5 style={{fontSize:'10px',color:'#696969',marginTop:'1px'}}>{this.state.assets && this.state.assets.esn_count?  this.state.assets.esn_count+' ESN' :  this.state.assets && this.state.assets.esn?'ESN '+this.state.assets.esn:null}</h5>
                    <a  style={{fontSize:'10px', marginTop:'5px',borderBottom:'1px solid #2789ff'}} href={"/technical/project/view/"+ this.state.assetSlug}>View</a>
                  </div>
                  { this.state.lesseeLogo ?
                    <div className="inner-second-block">
                      <img width='30px'  style={{ marginTop:'-20px',marginLeft:'10px'}} src={this.state.lesseeLogo} alt="logo"/>
                    </div>
                   : null
                  }
                </div>
            </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBqQiBoZlzISDH0BsrpykXoKmRV7rjemPw"
})(TechnicalLocations);
