import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col, Popover, PopoverHeader, PopoverBody, Button, Modal, ModalBody, ModalHeader, Input} from 'reactstrap';
import { RecordsContextMenu } from '../../Elements';
import { ContextMenuProvider, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { getFileSize } from '../../../../utils';
import { imgStoragePath, imageExtensions } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import ToolTipHover from '../../../../shared/components/ToolTipHover';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class DataList extends Component{
  constructor(props){
    super(props);
    this.state ={
      popoverOpen: false,
      destination: '',
      renameModal: false,
      renameRecord: {},
      imagePreview: false
    }
    this.shareRecord = this.shareRecord.bind(this);
    this.moveRecord = this.moveRecord.bind(this);
    this.toggle = this.toggle.bind(this);
    this.moveDest = this.moveDest.bind(this);
    this.activeRecord = this.activeRecord.bind(this);
  }

  activeRecord(evt){

    // var target = document.querySelector('.record-list .row');
    // target.classList.remove('active');
    // evt.target.parentElement.className = "active row";
  }

  updateRecord = (value) =>{
    this.setState(prevState => ({
      ...prevState,
      renameRecord: {...prevState.renameRecord, name: value}
    }))
  }

  renameRecord = (item) =>{
    this.setState(prevState =>({
      ...prevState,
      renameModal: !prevState.renameModal,
      renameRecord: item
    }))
  }

  shareRecord(event){
    this.props.shareRecord(this.props.item);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  moveDest(destination){
    this.setState({destination});
  }

  previewRecord = () =>{
    let url = ''
    if(this.props.item.type == "file"){
      if(this.props.item.extension == 'pdf'){
        url = `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${this.props.item.path}`;
      }else if(this.props.item.extension == 'csv' || this.props.item.extension == 'xls' || this.props.item.extension == 'xlsx'){
        url = `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${this.props.item.path}`
      }else if(imageExtensions.indexOf(this.props.item.extension) != -1){
        this.setState({
          imagePreview: true
        })
      }else {
        url = decodeURIComponent(`${this.props.item.path}`);
      }
    }else {
      this.props.previewRecord(this.props.item);
    }
  }

  moveRecord(){
    this.setState({
      popoverOpen: true,
      destination: '',
    });
    this.props.getFoldersToMove(this.props.item,{source: this.props.item.uuid});
  }

  render(){
    const { type, fileIcons, menuId, item, folderDirTree, index, parentFolder, createFolder, previewMode } = this.props;
    return(
      <div className="record-list" style={this.props.style}>
        <ContextMenuProvider id={menuId}>
          <Link onContextMenu={(evt) => this.activeRecord(evt)}  onDoubleClick={() => this.previewRecord()}>
            <Row>
              <Col md={previewMode ? "6" : "7"}>
                {this.props.checkBox && getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1||
                getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1 ||
                getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1||
                getLocalStorageInfo().user.permission['records']['data_room'].indexOf('D') != -1)?
                <input style={{marginRight:'10px'}} type="checkbox" checked = {this.props.checkedIds.filter(check => check.id === item.id).length != 1 ? false : true } onChange={(e) => this.props.updateCheckedIds(item, e)}/>:null}
                {
                  item.type == "file" ? <img src={fileIcons[item.extension]} width="14" /> : <img src={imgStoragePath+"folder_icn3.png"} width="14" />
                }
                <span  onClick={item.remarks != 'stand_alone_folder' ? item.type == "file" ? this.props.previewRecord : this.props.updateDirTree : this.props.infoModal} id={"info_" + menuId}>{item.name} {((!item.stand_alone_is_fitted && !item.stand_alone_is_titled) || (item.stand_alone_is_fitted && item.stand_alone_is_titled)) ? '' : item.stand_alone_is_fitted ? '( Fitted )' : item.stand_alone_is_titled ? '( Titled )' : ''  }</span>
              </Col>
              <Col md="3" className="text-right" onClick={item.remarks != 'stand_alone_folder' ? item.type == "file" ? this.props.previewRecord : this.props.updateDirTree : this.props.infoModal}>
                {
                  ((item.share_enabled || item.user_shared_folder) && item.type == "folder") ?
                  <span>
                  <img id={"ShareLinkEnabled-"+index} src={imgStoragePath+"share_icon.png"} style={{height: '15px'}}/>
                    <ToolTipHover placement="top" tagetId={"ShareLinkEnabled-"+index}>Share Link Enabled</ToolTipHover>
                  </span>:((item.share_enabled || item.user_shared_folder) && item.type == "file" && item.status == "Published") ?
                  <span >
                  <img id={"ShareLinkEnabled-"+index} src={imgStoragePath+"share_icon.png"} style={{height: '15px'}}/>
                    <ToolTipHover placement="top" tagetId={"ShareLinkEnabled-"+index}>Share Link Enabled</ToolTipHover>
                  </span>: null
                }
                { getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  || getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                  (item.type !="file"  && item.file_count!=0 && item.file_count != null) ?  <span style={{ width: '90px', display: 'inline-block'}}>{item.file_count} Files</span>: null
                  :
                  (item.type !="file"  && item.published_file_count!=0 && item.published_file_count != null)?  <span style={{ width: '90px', display: 'inline-block'}}>{item.published_file_count} Files</span>: null
                }
                {
                  (item.status == "Published" && item.type == 'file') ?
                  <span>
                  <img id={"Published-"+index} src={imgStoragePath+"published.png"} height="22"/>
                  <ToolTipHover placement="top" tagetId={"Published-"+index}>Published</ToolTipHover>
                  </span>:null
                }
                {
                  (item.status != "Published"  && item.type == 'file') ?
                  <span>
                  <img id={"Unpublished-"+index} src={imgStoragePath+"unpublished.png"} width="22" />
                  <ToolTipHover placement="top" tagetId={"Unpublished-"+index}>Unpublished</ToolTipHover>
                  </span>:null
                }
                {
                  (parseInt(item.ocr_status) == 2  && item.type == 'file' && item.extension == 'pdf') ?
                  <span>
                  <img id={"OCRIsPending-"+index} src={imgStoragePath+"rolling.gif"} width="22"/>
                  <ToolTipHover placement="top" tagetId={"OCRIsPending-"+index}>OCR is Processing</ToolTipHover>
                  </span>:null
                }
                {
                  (parseInt(item.ocr_status) == 3  && item.type == 'file' && item.extension == 'pdf') ?
                  <span>
                  <img id={"OCRIsCompleted-"+index} src={imgStoragePath+"ocr_done_new.png"} width="22"/>
                  <ToolTipHover placement="top" tagetId={"OCRIsCompleted-"+index}>OCR is Completed</ToolTipHover>
                  </span>:null
                }
                {
                  (parseInt(item.ocr_status) == 4  && item.type == 'file' && item.extension == 'pdf') ?
                  <span>
                  <img id={"OCRIsRejected-"+index} src={imgStoragePath+"ocr_rejected.png"} width="22"/>
                  <ToolTipHover placement="top" tagetId={"OCRIsRejected-"+index}>OCR is Rejected</ToolTipHover>
                  </span>:null
                }
                {
                  (parseInt(item.ocr_status) == 1 && item.type == 'file' && item.extension == 'pdf') ?
                  <span>
                  <img id={"OCRisInqueue-"+index} src={imgStoragePath+"ocr.png"} width="22"/>
                  <ToolTipHover placement="top" tagetId={"OCRisInqueue-"+index}>OCR is Inqueue</ToolTipHover>
                  </span>:null
                }
              </Col>
              <Col md={previewMode ? "3" : "2"} className="text-right" style={{paddingLeft : '0px'}} onClick={item.remarks != 'stand_alone_folder' ? item.type == "file" ? this.props.previewRecord : this.props.updateDirTree : this.props.infoModal}>
              {
                getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  || getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                (item.size !=0 && item.size != null) ? <span>{getFileSize(item.size)}</span>:null
                :
                (item.type !='file')?
                <span>{(item.published_size !=0 && item.published_size !=null) ? getFileSize(item.published_size):''}</span>
                :
                <span>{(item.size !=0 && item.size != null) ? getFileSize(item.size):''}</span>
              }

              </Col>
            </Row>
            <Viewer
              visible={this.state.imagePreview}
              onClose={() => { this.setState({ imagePreview: false }); } }
              images={[{src: `${decodeURIComponent(item.path)}`, alt: ''}]}
              />
          </Link>
        </ContextMenuProvider>
        <RecordsContextMenu assetType={this.props.assetType} addFileToMiniPackBulk={(uuid, itemUuid) => this.props.addFileToMiniPackBulk(uuid, itemUuid)} miniPackFolders={this.props.miniPackFolders} createFolder={createFolder} parentFolder={parentFolder} renameRecord={() => this.renameRecord(item)} changeActionDockStatus={this.props.changeActionDockStatus} addToCategory={this.props.addToCategory} addToBucket={this.props.addToBucket} downloadFiles={this.props.downloadFiles} deleteRecord={this.props.deleteRecord} menuId={menuId} item={item} type={type} moveRecord={this.moveRecord} shareRecord={this.shareRecord} previewRecord={this.props.previewRecord}/>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={"info_" + menuId} toggle={this.toggle}>
          <PopoverHeader className="popover-header">
            Move To
            <span className="close-popover" onClick={() =>this.toggle()}>X</span>
          </PopoverHeader>
          <PopoverBody>
            <ul className="list-inline">
              {
                this.props.popoverLoader ?
                <img src={imgStoragePath + "wait.gif"} style={{width:'40px', position: 'relative', left: '45%'}}/>:
                folderDirTree ?
                folderDirTree.length > 0 ?
                <li>
                  <img src={imgStoragePath+"popover_back.png"} onClick={() => folderDirTree[0].level? this.props.getFoldersToMove(folderDirTree[0],{level: folderDirTree[0].level > 0 ? folderDirTree[0].level - 1 : 0, source: this.props.item.uuid}):null}/> Parent Folder
                </li>
                :<li>
                  <img src={imgStoragePath+"popover_back.png"} onClick={() => this.props.getFoldersToMove(this.props.item,{level: this.props.level > 0 ? this.props.level : 0 , source: this.props.item.uuid})}/> Parent Folder
                </li>:null
              }
              {
                folderDirTree ?
                folderDirTree.length > 0 ?
                folderDirTree.map((folderItem, index) => <li className={this.state.destination == folderItem.uuid ? 'active' : ''} key={folderItem.uuid}>
                  <img src={imgStoragePath+"folder_icn3.png"}/><span onClick={() => this.moveDest(folderItem.uuid)}>{folderItem.name}</span>{folderItem.leaf == false ? <img src={imgStoragePath+"move_icon.png"} onClick={() => this.props.getFoldersToMove(folderItem,{select: folderItem.level, source: this.props.item.uuid})} className='sub-folder'/>:null}
                </li>) :<li>--</li>:null
              }
            </ul>
          </PopoverBody>
          <Separator />
            <Button type="button" disabled={ this.state.destination != '' ? false : true} onClick={() => {this.props.moveRecordToDest(this.props.item,this.state.destination);this.toggle();}} color="primary" className="add-new">Move Here</Button>
        </Popover>
        <Modal isOpen={this.state.renameModal} centered={true} toggle={this.renameRecord} className={this.props.className}>
          <ModalHeader toggle={this.renameRecord}>Rename {this.state.renameRecord.type}</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <Input type="text" ref="file_name" defaultValue={this.state.renameRecord.name} onChange={(evt) => this.updateRecord(evt.target.value) } />
            {
              item.type == 'file' ?
              <p style={{fontSize:"10px",textAlign:"left",marginLeft:"5px",marginTop:"3px"}}>{parentFolder && parentFolder.file_naming_conventions && parentFolder.file_naming_conventions != null ? "Format:": null}</p>
              :null
            }
          {
            item.type == 'file' ?
            <ul className="list-unstyled">
            {parentFolder && parentFolder.file_naming_conventions_array && parentFolder.file_naming_conventions_array != null  ? parentFolder.file_naming_conventions_array.map(format => <li style={{fontSize:"10px",textAlign:"left",marginLeft:"5px",marginTop:"3px"}}>{format}</li> ) : null}
            </ul>
            :null
          }
                <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-success" onClick={() => {this.props.renameRecord(this.state.renameRecord); this.renameRecord(this.state.renameRecord);}}>Save</button>
                  <h6 className="error-msg"></h6>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
export default withRouter(DataList);
