import React, { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import { UtilsHourCycls, UtilsBlock, DbGraphCard } from './';
import classnames from 'classnames';
export default class EngineAndLgUtil extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
      activeLabel: ''
    };
  }
  toggle(tab, label) {
    this.setState({
      activeTab: tab,
      activeLabel: label
    });
  }
  render(){
    const { data, label, contractMsn, lessorDetail, utilSumLoader } = this.props;
    let activeLabel = ''
    if(Object.keys(data).length){
      Object.keys(data).map((label, index) => {
        if(index == this.state.activeTab){
          activeLabel = data && data[label] ? data[label].name : ''
        }
        return label
      })
    }
    return (
      <div className="engine-lg-utils">
        <Nav tabs>
          {
            Object.keys(data).map((label,index) =>
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: this.state.activeTab == index })}
                onClick={() => { this.toggle(index, (data && data[label] ? data[label].name : '')); }}
              >
              <input type="radio"  checked={(this.state.activeTab === index ? 'checked' : false)} name={label }/> {data && data[label] ? data[label].name : ''}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {
            Object.keys(data).map((label,index) =>
              <TabPane key={index} tabId={index}>

                <UtilsHourCycls>
                  {
                    data[label].lastMonthHours ?
                    <UtilsBlock label="FH"  dateOfManu = {data[label].utilizationDate } value={data[label].lastMonthHours + ' HRS'} />
                    : null
                  }
                  {
                    data[label].utilizationDate  ?
                    <UtilsBlock label="FC"   dateOfManu = {data[label].utilizationDate } value={data[label].lastMonthCycles + ' Cycles'} />
                    : null
                  }
                  <UtilsBlock label="Average FH" value={data[label].avgFlightHours + ' HRS'} />
                  <UtilsBlock label="Average FC" value={data[label].avgFlightCycles + ' Cycles'} />
                </UtilsHourCycls>
                <div className="utiles-graph">
                  {
                    !utilSumLoader ?
                    <DbGraphCard
                      graphType='area'
                      graphTitle=''
                      graphxLabel=''
                      graphyLabel=''
                      utilSum = {true}
                      lessorDetail={!utilSumLoader && lessorDetail}
                      label={this.props.label}
                      contractMsn = {!utilSumLoader && contractMsn}
                      activeLabel = {activeLabel}
                      headingText={ activeLabel + ' ' + 'Utilization Summary'}
                      series = {
                        [{
                            showInLegend: true,
                            name: 'Hours',
                            color: '#378FE0',
                            lineColor: '#4F61C5',
                            data: Object.keys(data[label].hours).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: data[label].hours[year]} })
                          },
                          {
                              showInLegend: true,
                              name: 'Cycles',
                              color: '#67AEFF',
                              lineColor: '#1E7DB5',
                              data: Object.keys(data[label].cycles).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: data[label].cycles[year]} })
                            }
                        ]
                      }
                    />
                    :null
                  }
                </div>
              </TabPane>
          )}
        </TabContent>
      </div>
    )
  }
}
