import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { localTimeFn } from '../../../utils';
import { imgStoragePath } from '../../../constants';
// onClick={() => props.activityRedirect(lastActivity)}
const ActionDockActivity = (props) =>{
  if(props.lastActivity.inQueue != 0 || props.lastActivity.rename != 0){
    return(
      <tr className="action-dock-activity">
        <td style={{textAlign: 'left'}}><Link to={`/${props.assetsType[props.lastActivity.asset_type]}/${props.lastActivity.slug}/records/dashboard/`}>{props.lastActivity.asset_type == 1 ? 'MSN' : props.lastActivity.asset_type == 2 ? 'ESN' : props.lastActivity.asset_type == 3 ? 'APU' : props.lastActivity.asset_type == 4 ? 'LG' : props.lastActivity.asset_type == 5 ? 'PROPELLER' : ''} { props.lastActivity.msn ? props.lastActivity.msn : props.lastActivity.esn ? props.lastActivity.esn : props.lastActivity.serial_number }</Link></td>
        <td style={{textAlign: 'left'}}><Link to={`/${props.assetsType[props.lastActivity.asset_type]}/${props.lastActivity.slug}/records/action-dock/?quetype=1&status=status`}>{ props.lastActivity.inQueue ? props.lastActivity.inQueue : 0 }</Link></td>
        <td style={{textAlign: 'left'}}><Link to={`/${props.assetsType[props.lastActivity.asset_type]}/${props.lastActivity.slug}/records/action-dock/?quetype=2&status=status`}>{ props.lastActivity.rename ? props.lastActivity.rename : 0 }</Link></td>
        <td style={{textAlign: 'left'}}> <img className="profile-pic"  src={props.lastActivity.last_modified_by != null && props.lastActivity.last_modified_by.profile_pic ? props.lastActivity.last_modified_by.profile_pic : imgStoragePath+"default_lessor.png"} alt="img" style={{marginRight: '5px'}}/>{ props.lastActivity.last_modified_by != null && props.lastActivity.last_modified_by.name ? props.lastActivity.last_modified_by.name : ''}</td>
        <td style={{textAlign: 'right'}}><Link to={`/${props.assetsType[props.lastActivity.asset_type]}/${props.lastActivity.slug}/records/report/?report=activity`}>{props.lastActivity.last_modified_at != null && props.lastActivity.last_modified_at != undefined && props.lastActivity.last_modified_at != '' ? moment( localTimeFn(props.lastActivity.last_modified_at)).fromNow() : '' }</Link></td>
      </tr>
    )
  }else {
    return(null)
  }
}

export default ActionDockActivity;
