import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, Tooltip } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
export default class Prices extends Component{
    constructor(props){
        super(props);
        this.state = {
            lessorFeatures: true,
            tooltipOpen: false
        }
      }
      toggle = () => {
        this.setState(prevState => ({
          ...prevState,
          tooltipOpen: !prevState.tooltipOpen
        }))
      }
      render(){
        const{ signupType } = this.props;
        const { tooltipOpen } = this.state;
        return(
            <div className="features-wrapper pricing-blk-wrapper" style={signupType == '1' ? {paddingTop: '200px'} : {paddingTop: '70px'}}>
                <div className="container">
                    <div className="heading-block" style={{marginBottom:'20px'}}>
                        <h2>Choose a plan that's right for you</h2>
                    </div>
                    <div className="feature-content-block">
                        <ul className="list-inline hidden" style={{ display: 'none'}}>
                            <li><Link onClick={() => this.setState({lessorFeatures :true})} className={this.state.lessorFeatures ? "tab-active" : "" }>Lessors/Asset Managers</Link></li>
                            <li><Link onClick={() => this.setState({lessorFeatures :false})} className={!this.state.lessorFeatures ? "tab-active" : "" }>Inspectors/Servicers</Link></li>
                        </ul>
                        <div className="tab-content lessor-features text-center" style={{marginLeft:'70px'}}>
                            <Row className="flex-not-centered">
                              <Col md="4" className="card-block">
                                    <h4 style={{color:'#ffffff',fontSize:'20px'}}>Free</h4>
                                    <ul className="list-unstyled">
                                        <li>Data Storage - Upto 5GB</li>
                                        <li>No. of users - One</li>
                                        <li>All Core features</li>
                                        <li>Technical support - In app chat</li>
                                    </ul>
                                    <div className="wrapper">
                                      <p style={{padding:'20px 0px 15px 0px'}}>
                                      <Link to="/signup" className="sign-button">Sign Up</Link></p>
                                      <p className="card">No credit card required</p>
                                      <span style={{fontSize: '12px', display: 'none'}}>Request Extra Access
                                        <i id="module-free-access" style={{marginLeft: '4px', display: 'inline-block'}} class="fa fa-info-circle" aria-hidden="true"></i>
                                        <Tooltip placement="right" isOpen={tooltipOpen} target="module-free-access" toggle={this.toggle}>
                                          Activate extra licences for free
                                        </Tooltip>
                                      </span>
                                    </div>
                                </Col>
                                <Col md="4" className="card-block">
                                    <h4 style={{color:'#ffffff',fontSize:'20px'}}>Pay-As-You-Go<p style={{color:'#ffffff',fontSize:'10px'}}>Monthly and yearly plans available</p></h4>
                                    <ul className="list-unstyled">
                                        <li>Data Storage - Unlimited</li>
                                        <li>No. of users - Unlimited</li>
                                        <li>All Core features</li>
                                        <li>Technical support - 24/7 support</li>
                                    </ul>
                                    <div className="wrapper signup">
                                      <p style={{padding:'20px 0px 15px 0px'}}>
                                      <Link to="/signup" className="sign-button">Sign Up</Link></p>
                                      <p className="hidden"></p>
                                    </div>
                                </Col>
                                <Col md="4" className="card-block">
                                    <h4 style={{color:'#ffffff',fontSize:'20px'}}>Enterprise <p style={{color:'#ffffff',fontSize:'10px'}}>Custom Pricing</p></h4>
                                    <ul className="list-unstyled">
                                      <li>Data Storage - Unlimited</li>
                                      <li>No. of users - Unlimited</li>
                                      <li>All Core features, Advanced tools and Customised features</li>
                                      <li>Dedicated Technical Support</li>
                                    </ul>
                                    <div className="wrapper contact">
                                      <p style={{padding:'20px 0px 15px 0px'}}>
                                      <a href="mailto:sales@acumenaviation.ie" target="_blank"  className="sign-button">Contact Sales</a></p>
                                      <p className="hidden"></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
