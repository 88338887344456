import React,{ Component } from 'react';
import { Collapse, CardBody, Card, Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import MRReviewFields from '../containers/MRReviewFields';
import SelectUtilType from '../containers/SelectUtilType';
import { imgStoragePath, intervalUnitLinking } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
export default class LandingGearMRDetail extends Component{
    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { collapse: true };
    }
    toggle() {
      this.setState({ collapse: !this.state.collapse });
    }
    render(){
      const { lgAndApu, title, type, errors } = this.props;
      const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true}

      return(
        <div className="common-mr-card">
          <h6 className="para"  onClick={this.toggle}>
            {title}
            <img style={this.state.collapse ? {transform: 'rotate(0deg)'}: {transform: 'rotate(180deg)'}} src={imgStoragePath + "collapse_arw.png"} alt="img"/>
          </h6>
          <Collapse className="lg-mr-detail" isOpen={this.state.collapse}>
            <Card>
              <CardBody>
                <Row>
                  <Col md="8">
                    <Row className="lg-entry-block">
                      <MRReviewFields
                        col="6"
                        label="Fund Date"
                        keyParam="fundDate"
                        type={type}
                        value={lgAndApu.fundDate}
                        inputType="date"
                        addSymbol = {false}
                        errorMsg = { errors[type] && errors[type].fundDate ? errors[type].fundDate : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                      <MRReviewFields
                        col="6"
                        label="Fund Status as of Date"
                        keyParam="currentFundValue"
                        type={type}
                        value={lgAndApu.currentFundValue}
                        inputType="text"
                        prefix = {true}
                        addSymbol = {true}
                        errorMsg = { errors[type] && errors[type].currentFundValue ? errors[type].currentFundValue : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                    </Row>
                    <Row className="lg-entry-block">
                      <MRReviewFields
                        col="6"
                        label="Time Since New (TSN)"
                        keyParam="tsn"
                        type={type}
                        value={lgAndApu.tsn}
                        inputType="text"
                        addSymbol = {false}
                        errorMsg = { errors[type] && errors[type].tsn ? errors[type].tsn : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                      <MRReviewFields
                        col="6"
                        label="Cycles Since New (CSN)"
                        keyParam="csn"
                        type={type}
                        value={lgAndApu.csn}
                        inputType="text"
                        addSymbol = {false}
                        errorMsg = { errors[type] && errors[type].csn ? errors[type].csn : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                    </Row>
                    <SelectUtilType utilizationType={lgAndApu.utilizationType ? lgAndApu.utilizationType: 'tsn'} type={type} name={type == 'landingGear' ? 'lg-value' : 'apu-value'}/>
                    { lgAndApu.utilizationType == 'dateOfLastOverhual' ?
                      <Row className="lg-entry-block">
                        <MRReviewFields
                          col="4"
                          label="Date of Last Qualified Shop Visit"
                          keyParam="dateOfLastOverhual"
                          type={type}
                          value={lgAndApu.dateOfLastOverhual}
                          inputType="date"
                          addSymbol = {false}
                          errorMsg = { errors[type] && errors[type].dateOfLastOverhual ? errors[type].dateOfLastOverhual : '' }
                          updateErrorCode ={ this.props.updateErrorCode }
                        />
                        <MRReviewFields
                          col="4"
                          label="TSN at Last Qualified Shop Visit"
                          keyParam="tsn_at_lsv"
                          type={type}
                          value={lgAndApu.tsn_at_lsv}
                          inputType="text"
                          addSymbol = {false}
                          errorMsg = { errors[type] && errors[type].tsn_at_lsv ? errors[type].tsn_at_lsv : '' }
                          updateErrorCode ={ this.props.updateErrorCode }
                        />
                        <MRReviewFields
                          col="4"
                          label="CSN at Last Qualified Shop Visit"
                          keyParam="csn_at_lsv"
                          type={type}
                          value={lgAndApu.csn_at_lsv}
                          inputType="text"
                          addSymbol = {false}
                          errorMsg = { errors[type] && errors[type].csn_at_lsv ? errors[type].csn_at_lsv : '' }
                          updateErrorCode ={ this.props.updateErrorCode }
                        />
                      </Row>:null
                    }


                    { lgAndApu.utilizationType == 'tslsv' ?
                      <Row className="lg-entry-block">
                        <MRReviewFields
                          col="6"
                          label="Time Since Last Shop Visit (TSLSV)"
                          keyParam="tslsv"
                          type={type}
                          value={lgAndApu.tslsv}
                          inputType="text"
                          addSymbol = {false}
                          errorMsg = { errors[type] && errors[type].tslsv ? errors[type].tslsv : '' }
                          updateErrorCode ={ this.props.updateErrorCode }
                        />
                        <MRReviewFields
                          col="6"
                          label="Cycles Since Last Shop Visit (CSLSV)"
                          keyParam="cslsv"
                          type={type}
                          value={lgAndApu.cslsv}
                          inputType="text"
                          addSymbol = {false}
                          errorMsg = { errors[type] && errors[type].cslsv ? errors[type].cslsv : '' }
                          updateErrorCode ={ this.props.updateErrorCode }
                        />
                      </Row>:null
                    }
                    <Row className="lg-entry-block">
                      <MRReviewFields
                        col="4"
                        label="Actual MR Rate"
                        keyParam="actualMR"
                        type={type}
                        value={lgAndApu.actualMR}
                        inputType="text"
                        prefix = {true}
                        addSymbol = {true}
                        errorMsg = { errors[type] && errors[type].actualMR ? errors[type].actualMR : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                      <MRReviewFields
                        col="4"
                        label="MR Rate Escalation"
                        keyParam="escalation"
                        type={type}
                        value={lgAndApu.escalation}
                        inputType="text"
                        prefix = {false}
                        addSymbol = {true}
                        errorMsg = { errors[type] && errors[type].escalation ? errors[type].escalation : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                      <MRReviewFields
                        col="4"
                        label="Shop Visit Cost Escalation"
                        keyParam="cost_escalation"
                        type={type}
                        value={lgAndApu.cost_escalation}
                        inputType="text"
                        prefix = {false}
                        addSymbol = {true}
                        errorMsg = { errors[type] && errors[type].cost_escalation ? errors[type].cost_escalation : '' }
                        updateErrorCode ={ this.props.updateErrorCode }
                      />
                    </Row>
                  </Col>
                  <Col md="4">
                    <ul className="list-unstyled lg-side-block">
                      <li>
                        Recommended MR Rates range
                      </li>
                      <li>
                        <h6 className="caption-ui">Rates as per</h6>
                        <p className="para-ui">
                        <span className="pretty-number-override-css ">
                          {lgAndApu.year_of_cost ? lgAndApu.year_of_cost  : '2018' }
                        </span>
                        </p>
                      </li>
                      { getLocalStorageInfo().defaultLessor.id != 24 ?
                        <li>
                          <h6 className="caption-ui">Upper Rate</h6>
                          <p className="para-ui">
                            <NumericLabel params={prettyNumberParams}>
                              {lgAndApu.maxMR}
                            </NumericLabel> /{ lgAndApu.interval_type ? intervalUnitLinking[lgAndApu.interval_type]: type == 'apu' ? 'FH':'Month' }
                          </p>
                        </li>:null
                      }

                      <li>
                        <h6 className="caption-ui">Target Rate</h6>
                        <p className="para-ui">
                        <NumericLabel params={prettyNumberParams}>
                          {lgAndApu.targetMR}
                        </NumericLabel> /{ lgAndApu.interval_type ? intervalUnitLinking[lgAndApu.interval_type]: type == 'apu' ? 'FH':'Month' }
                        </p>
                      </li>
                      { getLocalStorageInfo().defaultLessor.id != 24 ?
                        <li>
                          <h6 className="caption-ui">Lower Rate</h6>
                          <p className="para-ui">
                            <NumericLabel params={prettyNumberParams}>
                               {lgAndApu.minMR}
                            </NumericLabel> /{ lgAndApu.interval_type ? intervalUnitLinking[lgAndApu.interval_type]: type == 'apu' ? 'FH':'Month' }
                          </p>
                        </li>:null
                      }
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Collapse>
        </div>
      )
  }
}
