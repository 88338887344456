import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
class BillingSidebar extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    render(){
        const{ notifications, notificationOpen } = this.props;
        const location = browserHistory.getCurrentLocation()
        const userDetail = JSON.parse(localStorage.getItem('userInfo'));
        return(
            <div className="billing-sidebar" style={(notifications && notifications.overdue && notifications.overdue > 1 && notificationOpen) ? {paddingTop: '30px'} :  null }>
                 <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0'  }}>
                    <li>
                        <Link to="/billing/invoices" className={location.pathname.includes("billing/invoice") ? "active" : ""}>History</Link>
                    </li>
                    <li>
                        <Link to="/billing/payment-methods" className={location.pathname.includes("billing/payment-methods") ? "active" : ""}>Settings</Link>
                    </li>
                 </ul>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    notificationOpen:state.sharedReducers.notificationOpen,
    notifications: state.sharedReducers.notifications,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingSidebar);
