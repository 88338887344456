import React, { Component } from 'react';
import { imgStoragePath } from '../../../../constants'
import { getLocalStorageInfo  } from '../../../../utils'

export default class PropellersList extends Component {
	constructor(props) {
		super(props)
	}
	render() {
		const { propeller, index, asset_slug } = this.props;
		return (
			<tr>
				<td>{this.props.propeller.serial_number.trim() != '' ? this.props.propeller.serial_number : '--'}</td>
				<td>{this.props.propeller.part_number}</td>
				<td>{this.props.propeller.model && this.props.propeller.model.trim() != '' ? this.props.propeller.model : '--'}</td>
				<td>{this.props.propeller.manufacturer && this.props.propeller.manufacturer.trim() != '' ? this.props.propeller.manufacturer : '--'}</td>
				<td>{this.props.propeller.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}</td>
				<td>{this.props.propeller.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}</td>
				<td>{this.props.propeller.installed_position.label}</td>
				<td>
				{
					getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('U') != -1 ?
					<img onClick={() => this.props.viewAvionic(asset_slug ,propeller.id, index, 'viewAvionic')} style={{color: 'red', marginRight: '10px', cursor: 'pointer'}} src={imgStoragePath+"icon_editblock.png"} alt="img" className="blue-img" width="16" />
					:null
				}
				{getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('D') != -1) && propeller.is_fitted && this.props.showTabs ?
					<img onClick={(evt) => this.props.deleteMsnEngine()} style={{color: 'red', marginRight: '10px', cursor: 'pointer'}} src={imgStoragePath+"delete_red.png"} alt="img" className="blue-img" width="16" />
					:null
				}
				</td>
			</tr>
		)
	}
}
