import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import '../assets/styles/fleet.scss';
import { withRouter } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import FleetSim from '../components/FleetSim';
import FleetSimHeading from '../components/FleetSimHeading';
import SimMonthTotal from '../components/SimMonthTotal';
import MonthSidebar from '../components/MonthSidebar';
import FleetScrollHeader from '../components/FleetScrollHeader';
import { fleetCashflowSimulationAc, fleetCashflowFilterAc, exportCashFlowAc } from '../actionCreators';
import { ListLoader, NoRecordFound } from '../../../shared/';
import { getCookie } from '../../../utils';
import {  prettyNumberParamsL } from '../../../constants';
import ShortHead from '../elements/ShortHead';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
class FleetSimulation  extends Component {
  constructor(props){
    super(props);
    this.state = {
        width: 0,
        height: 0,

    }
  }
  componentDidMount(){
    this.props.fleetCashflowSimulation();
    this.setState({width: window.innerWidth + 'px'});
    document.querySelector('body').style.overflow = 'hidden';
    this.setState({
       height : window.innerHeight - (41 + 78 + 112 + 80)
    });



  }

  componentWillUnmount(){
    document.querySelector('body').style.overflow = 'auto';
  }
  render() {
    const { cashSimulation, cashSimulationFilter, portListLoader, fleetContainerInfo } = this.props;
    if(!portListLoader){
      if(Object.keys(cashSimulation).length){
        return(
          <div style={{ padding: '9px 5px 0 55px' }}>
            {portListLoader ? <ListLoader drawer="primary-nav" />  : null}

            <ShortHead style={{marginTop: "-5px", marginLeft: '85px'}} headingText="" backUrl={`/fleet-cashflow/portfolio/${cashSimulation.portfolio.slug}`} contrAssetBasicInfo={cashSimulation}  portfolio={cashSimulation.portfolio} totalValue={cashSimulation.total_value} assetIncluded={cashSimulation.no_of_assets}/>


            {cashSimulation.cashflow_data.length ?
              <FleetSimHeading updateField={this.props.fleetCashflowFilter} cashSimulationFilter={cashSimulationFilter} cashSimulation={cashSimulation} exportCashFlow={this.props.exportCashFlow}/>:null
            }


            {cashSimulation.cashflow_data.length ?
              <div>
                <div className="flex-not-centered simulation-block header-sep-block" >
                  <div className="inner-width-block">
                    <div className="row-header flex-not-centered">
                      <div className="flex-centered spacing-cls month-blk text-right" >
                        <p className="para-ui ">Month</p>
                      </div>
                      <div style={{ width: (( (window.innerWidth - (300 + 175)) >= (cashSimulation.cashflow_data.length * 220) ? cashSimulation.cashflow_data.length * 220 : window.innerWidth - (300 + 175) + 'px')) , overflow: 'hidden' }}>
                        <FleetScrollHeader fleetViewWidth={fleetContainerInfo} cashSimulation={cashSimulation}/>
                      </div>
                      <div className="flex-centered spacing-cls total-block text-right" >
                          <p style={{width:"100%"}} className="para-ui ">Total</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ height: this.state.height + 'px', overflow:'auto',marginBottom:'60px' }}>
                  <div className="flex-not-centered simulation-block">
                    <MonthSidebar customClass="flex-centered total-block list" monthList={cashSimulation.cashflowArr} />
                  <div style={{ width: (( (window.innerWidth - (300 + 175)) >= (cashSimulation.cashflow_data.length * 220) ? cashSimulation.cashflow_data.length * 220 : window.innerWidth - (300 + 175) + 'px')) , overflow: 'auto' }}>
                      <div className="inner-width-block" style={{ width: this.props.fleetContainerInfo }}>
                        <div className="row-header flex-not-centered"  style={{ width: this.props.fleetContainerInfo }} >
                          {cashSimulation.cashflow_data && cashSimulation.cashflow_data.length > 0 ?
                              cashSimulation.cashflow_data.map((data, index) =>
                              <FleetSim key={index} cashSimulation={data}  monthList={cashSimulation.cashflowArr}/>
                            ) : <NoRecordFound/>
                          }
                        </div>
                      </div>
                    </div>
                    <SimMonthTotal customClass="flex-centered total-block  list" monthList={cashSimulation.cashflowArr} cashSimulation={cashSimulation} heading="Total "/>
                  </div>
                </div>
              </div>
              :<NoRecordFound/>
            }
          </div>
        )
      }else{
       return <div style={{paddingTop: '150px'}}><NoRecordFound/></div>
      }
    }else{
      return <ListLoader drawer="primary-nav" /> ;
    }
  }
}

const mapStateToProps = state => ({
  cashSimulation: state.FleetAnalysisReducer.cashSimulation,
  fleetContainerInfo: state.FleetAnalysisReducer.fleetContainerInfo,
  cashSimulationFilter: state.FleetAnalysisReducer.cashSimulationFilter,
  portListLoader: state.FleetAnalysisReducer.portListLoader,

});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportCashFlow: (portfolio, filterType, startDate, endDate, fileType) => dispatch(exportCashFlowAc( portfolio, filterType, startDate, endDate, fileType)),
    fleetCashflowSimulation: (filterParam = {}) => dispatch(fleetCashflowSimulationAc(ownProps.params, filterParam)),
    fleetCashflowFilter: (type, value) => dispatch(fleetCashflowFilterAc(ownProps.params, type, value)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(FleetSimulation, ['fleet_cash_flow','dashboard','R'])));
