import React from 'react';
import { Col } from 'reactstrap';
import DbPieChart from './DbPieChart';
import {  showCurrencyFormat } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsDB } from '../../../constants';
const CashflowPieChart = (props) => {
  let series = [];
  let graphData = [];
  if(props.cashflowPayment.totalMrFund > 0){
    graphData = [...graphData, {
      name:'MR',
      color: '#f0e25b',
      y:  props.cashflowPayment.totalMrFund
    }];
  }
  props.cashflowPayment.totalRentalFund_new.map(rental => {
    if(rental.total_amount > 0){
      graphData = [...graphData,
        {
          name: 'Rental in ' + rental.currency,
          color: rental.currency == 'USD' ? '#17B9FF': '#8bbc21',
          y: rental.total_amount
        }
      ]
    }
  });
  series = [
    {
      name:'Funds',
      colorByPoint: true,
      data: graphData
    }
  ];


  return(

      <div className="pie-cn">
        <h6>Total MR Funds as of Date</h6>
        <p>
          <span style={{display: 'inline-block', width: '8px', height: '8px', position: 'relative', top: '-2px', background: '#f0e25b'}}></span> <NumericLabel params={prettyNumberParamsDB}>{props.cashflowPayment.totalMrFund ? props.cashflowPayment.totalMrFund:0}</NumericLabel>
        </p>
        <div className="pie-chart-blk">
          <DbPieChart
            title=''
            subTitle=''
            series={series}
           />
        </div>
        <h6>Total Rental Funds as of Date</h6>
        <p>
          { props.cashflowPayment.totalRentalFund_new ?
            props.cashflowPayment.totalRentalFund_new.length?
              props.cashflowPayment.totalRentalFund_new.map((rental, index) =>
              <div>
                <span style={{display: 'inline-block', width: '8px', height: '8px', position: 'relative', top: '-2px', background: rental.currency == 'USD' ? '#17B9FF': '#8bbc21'}}></span> <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
              </div>
              ): '$0.00': '$0.00'
            }
        </p>
      </div>
  )
}
export default CashflowPieChart;
