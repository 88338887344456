import React, { Component } from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import { checkIntNumber } from '../../../formValidator';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fieldDateFormat, backendDateFormat } from '../../../constants';
export default class FilterField extends Component{
  constructor(props){
    super(props);
    this.state = {
      fieldValue: '',
      startDate: null,
      endDate: null,
      minValue: '',
      maxValue: '',
      error: {},
    };
  }
  lastModifiedDate = (value, type) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: (value == 'Invalid date' ? null:value)
    }))
  }
  changeFilterVal = (value) => {
    this.setState(prevState => ({
        ...prevState,
        fieldValue: value
      }));
  }
  changeMinVal = (value) => {
    this.setState(prevState => ({
        ...prevState,
        minValue: value
      }));
  }
  changeMaxVal = (value) => {
    this.setState(prevState => ({
        ...prevState,
        maxValue: value
      }));
  }
  updateFilter = (e) => {
    e.preventDefault();
    if(this.props.applyFilterType.inputType == 'range'){
      const validateNewInput = {
        minValue: checkIntNumber({value: this.state.minValue, required: false, minLength: '', maxLength: '', message: 'Please enter number only'}),
        maxValue: checkIntNumber({value: this.state.maxValue, required: false, minLength: '', maxLength: '', message: 'Please enter number only'}),
      };
      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        if(this.props.applyFilterType.inputType == 'date'){
          this.props.changeFilterFn(this.props.applyFilterType.keyParam, this.state.startDate, this.state.endDate);
        }else if(this.props.applyFilterType.inputType == 'range'){
          this.props.changeFilterFn(this.props.applyFilterType.keyParam, this.state.minValue, this.state.maxValue);
        }else{
          this.props.changeFilterFn(this.props.applyFilterType.keyParam, this.state.fieldValue);
        }
      }else {
        this.setState({
          error: validateNewInput
        });
      }
    }else {
      if(this.props.applyFilterType.inputType == 'date'){
        this.props.changeFilterFn(this.props.applyFilterType.keyParam, this.state.startDate, this.state.endDate);
      }else if(this.props.applyFilterType.inputType == 'range'){
        this.props.changeFilterFn(this.props.applyFilterType.keyParam, this.state.minValue, this.state.maxValue);
      }else{
        this.props.changeFilterFn(this.props.applyFilterType.keyParam, this.state.fieldValue);
      }
    }
  }
  render(){
    const { applyFilterType } = this.props;
    const { error } = this.state;
    if(applyFilterType.inputType == 'dropdown'){
      return (
        <form onSubmit= {this.updateFilter}>
          <div className="match-body">
            <label>Matches</label>
            <Select
                name="form-field-name"
                value={this.state.fieldValue}
                onChange={this.changeFilterVal}
                options={applyFilterType.options}
                placeholder = {applyFilterType.placeholder}
                matchProp = "any"
                labelKey = {applyFilterType.labelKey}
                valueKey	= {applyFilterType.valueKey}
                multi={applyFilterType.multi}
            />
          </div>
          <div className="match-footer">
            <input className="primary-btn"  type="submit" value="Apply" />
          </div>
        </form>
      )
    }
    if(applyFilterType.inputType == 'text'){
      return(
        <form onSubmit= {this.updateFilter}>
          <div className="match-body">
            <label>Matches</label>
            <input type="text" value={this.state.fieldValue}
              onChange={(e) => this.changeFilterVal(e.target.value)}
            />
          </div>
          <div className="match-footer">
            <input className="primary-btn" type="submit" value="Apply"/>
          </div>
        </form>
      )
    }
    if(applyFilterType.inputType == 'number'){
      return(
        <form onSubmit= {this.updateFilter}>
          <div className="match-body">
            <label>Matches</label>
            <input type="number" value={this.state.fieldValue}
              onChange={(e) => this.changeFilterVal(e.target.value)}
            />
          </div>
          <div className="match-footer">
            <input className="primary-btn" type="submit" value="Apply"/>
          </div>
        </form>
      )
    }
    if(applyFilterType.inputType == 'range'){
      return (
        <form onSubmit= {this.updateFilter}>
          <div className="match-body">
            <label>Matches</label>
            <input style={{width: "200px"}} type="text" defaultValue={this.state.minValue} placeholder={applyFilterType.placeholderMin}
              onChange={(e) => this.changeMinVal(e.target.value)}
            />
            <h6 className="error-msg">{error.minValue}</h6>
            <input style={{width: "200px"}} type="text" defaultValue={this.state.maxValue} placeholder={applyFilterType.placeholderMax}
              onChange={(e) => this.changeMaxVal(e.target.value)}
            />
            <h6 className="error-msg">{error.maxValue}</h6>
          </div>
          <div className="match-footer">
            <input className="primary-btn" type="submit" value="Apply" />
          </div>
        </form>
      )
    }
    if(applyFilterType.inputType == 'date'){
      return (
        <form onSubmit= {this.updateFilter}>
          <div className="match-body">
            <label>Matches</label>
            <DatePicker
              selected={ this.state.startDate ? moment(this.state.startDate):null}
              placeholderText="Start Date"
              dateFormat={fieldDateFormat}
              onChange={(e) =>this.lastModifiedDate(moment(e).format(backendDateFormat), 'startDate')}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
            />

            <DatePicker
              selected={this.state.endDate ? moment(this.state.endDate):null}
              placeholderText="End Date"
              dateFormat={fieldDateFormat}
              onChange={(e) => this.lastModifiedDate(moment(e).format(backendDateFormat), 'endDate')}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
           />
          </div>
          <div className="match-footer">
            <input className="primary-btn" type="submit" value="Apply" />
          </div>
        </form>
      )
    }
  }
}
