import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { getFileSize } from '../../../../utils'
import { imgStoragePath } from '../../../../constants';
export default class FilesUploaded extends Component{
  constructor(props){
    super(props);
    this.state = {
      pdf_type:1
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){
    const { fileDetail, index } = this.props;
    return(
      <Row className="upload-row margin-bottom-10">
        <Col md="4">
          { !fileDetail.error == 1 ?
            <img width="15" src={imgStoragePath+"pdf.png"} alt="img"/> : null }
            <span className="caption-ui">{fileDetail.file.name.replace(/^.*[\\\/]/, '')}</span>
        </Col>
        <Col md="3">
          <span className="caption-ui">{( getFileSize(fileDetail.file.size))} </span>
        </Col>
        { fileDetail.error == 1 ?
          <Col md="3" className="">
            <p style= {{ color: '#ff0000', fontSize: '10px', position: 'relative', top: '4px' }}> {fileDetail.error ? 'File Format Not Supported' : null} </p>
          </Col>:
          <Col md="4" className="">
            <Select
               disabled = {this.props.oemUpload? true : false}
               name="form-field-name"
               placeholder="Select Type"
               className="custom-select-block"
               value={fileDetail.fileType}
               options={[{label:'OEM AD status report', value:'oem_uri'}, {label:'Opeator AD status report', value:'ad_uri'}]}
               labelKey="label"
               valueKey="value"
               onChange = {(target) => this.props.updateField('fileType',target?target['value']:'', index)}
               onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
            />
            { fileDetail.error == 2 ?
              <p style= {{ color: '#ff0000', fontSize: '12px' }}> {fileDetail.error ? 'Please Select File Type' : null} </p> : null
            }
          </Col>
        }
        <Col md="1" className="text-right">
          { fileDetail.error == 1 ?
            <span className="change-file" >
              <img src={imgStoragePath+"file_replace.png"} alt="img"/>
              <input type="file" onChange={(e) => this.props.replaceFile(index, e.target.files[0])} />
            </span> : null
          }
          <img src={imgStoragePath+"icon_trash.png"} alt="delete" className="delete-icon" onClick={() => this.props.deleteFn(index)}/>
        </Col>
      </Row>
    )
  }
}
