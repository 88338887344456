import * as actions from '../actions';
import { globalGetService, globalPostService, globalFileUploadService, globalDeleteService,globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils';
import { browserHistory } from 'react-router';


export const uploadMLFileAc = (params, files) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('contract_slug', params.contract_slug);
    files.map((file, index) => {
      formData.append(file.fileType, file.file);
    });

  var config = {
    onUploadProgress: function(progressEvent) {
        var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        dispatch({
          type: actions.PROGRESS_PERCENTAGE,
          payload: percentage
        });
      }
  };
  globalFileUploadService('/ml/upload-ml-files/',  formData, config)
  .then(response => {
      if(response.data.statusCode == 200){
        dispatch(extractFileListAc(params));
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: false
        });
      }
    })

  }
}

export const extractFileListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.ML_LIST_LOADER,
      payload: true
    })
    globalGetService('/ml/contract/'+params.contract_slug+'/files/', {})
    .then(response => {
      dispatch({
        type: actions.ML_LIST_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.EXTRACT_FILE_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const extractFileInfoAc = (params) => {

  return (dispatch) => {
    dispatch({
      type: actions.ML_LIST_LOADER,
      payload: true
    })
    globalGetService('/ml/file/'+params.id+'/data-points/', {})
    .then(response => {
      dispatch({
        type: actions.ML_LIST_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200 ){
        dispatch({
          type: actions.EXTRACT_FILE_INFO,
          payload: response.data.data
        });

      }
    })
  }
}
export const dataPointToggleStatusAc = (id, type, ids) => {
  return (dispatch) => {
    dispatch({
      type: actions.ML_LIST_LOADER,
      payload: true
    });
    globalPostService('ml/file/' + id + '/data-point/status/' , {data_point_id: ids, status: type})
    .then(response => {

      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.CHANGE_DATA_POINT_STATUS,
          payload:{status: type, ids: ids }
        });
        dispatch({
          type: actions.ML_LIST_LOADER,
          payload: false
        });
      }
    })
  }
}
export const submitDataPointAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.ML_LIST_LOADER,
      payload: true
    });
    globalGetService('ml/file/'+params.id+'/data-points/apply/',{})
    .then(response => {
      dispatch({
        type: actions.ML_LIST_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        browserHistory.push('/'+params.type+'/'+params.aircraft_slug+'/contract/'+params.contract_slug+'/extract-file-list')
      }
    })
  }
}
export const editDataPointAc = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.ML_LIST_LOADER,
      payload: true
    });
    globalPostService('/ml/file/'+id+'/data-point/update/', {data_point_id:data.id, value: (data.options ? data.option_value : data.value) })
    .then(response => {
      dispatch({
        type: actions.ML_LIST_LOADER,
        payload: false
      });
      dispatch({
        type: actions.TOGGLE_DATA_EXT_BAR,
        payload: {flag: false, data: {}, type: 'edit'}
      });
      toastFlashMessage(response.data.message, 'success');
      if(response.data.statusCode == 200){
        dispatch(extractFileInfoAc({id: id}))
      }
    })
  }
}

export const deleteFileAc = (params, Fileid) => {
  return (dispatch) => {
    dispatch({
      type: actions.ML_LIST_LOADER,
      payload: true
    });
    globalDeleteService('ml/contract/'+params.contract_slug+'/files/'+Fileid+'/' , {})
    .then(response => {
      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.DELETE_ML_FILE,
          payload:Fileid
        });
        dispatch({
          type: actions.ML_LIST_LOADER,
          payload: false
        });
      }
    })
  }
}
