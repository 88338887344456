import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getFileSize, localTimeFn, getAccessToken } from '../../../../utils';
import { imgStoragePath, imageExtensions } from '../../../../constants';
import { Link } from 'react-router';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class FileInfo extends Component{
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      width: 530,
      height: 450,
      imagePreview: false
    };
    this.onDocumentComplete = this.onDocumentComplete.bind(this);
  }
  onDocumentComplete = (pages) => {
    this.setState({ page: 1, pages });
  }


  render(){
    const { style, previewInfo, recordDetail } = this.props;
    const fileTypes=['png','jpg','jpeg','tiff','svg','png'];
    return(
      <div className="file-info" style={style}>
        <div className="file-info-header">
          <span className="close-file-info" onClick={this.props.toggleFolderInfo}>X</span>
          {
            imageExtensions.indexOf(previewInfo.extension) != -1 ?
              <Link onClick={() => this.setState({imagePreview: true})}><img src={imgStoragePath+"maximize.png"} width="12px"/></Link>
            :previewInfo.extension == 'pdf' ? <Link to={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${previewInfo.path}#search=${this.props.searchKey}`} target='_blank'><img src={imgStoragePath+"maximize.png"} width="12px"/></Link>
              :<Link to={`https://view.officeapps.live.com/op/embed.aspx?src=${previewInfo.path}`} target='_blank'><img src={imgStoragePath+"maximize.png"} width="12px"/></Link>
          }
        </div>
        <div className="file-preview" style= {imageExtensions.indexOf(previewInfo.extension) != -1 ? {background: '#4a4a4a'} : {}}>
          {
            previewInfo.extension == 'pdf' ?
            <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${previewInfo.path}#search=${this.props.searchKey}`} height="450" width="530">
              <param name="type" value="text/html" />
              <param name="width" value="330" />
              <param name="width" value="250" />
            </object>:null
          }
          {
            ['xls','docx','doc','xlsx','csv'].includes(previewInfo.extension) ?
            <object data={`https://view.officeapps.live.com/op/embed.aspx?src=${previewInfo.path}`} height="450" width="530">
              <param name="src" value="PATH TO YOUR PDF HERE.pdf?#zoom=75" />
              <param name="type" value="application/pdf" />
              <param name="width" value="330" />
              <param name="width" value="250" />
            </object>:null
          }
          {
            imageExtensions.indexOf(previewInfo.extension) != -1 ?
            <img style={{objectFit: 'contain', maxHeight: '450px', maxWidth: '530px'}} src={`${decodeURIComponent(previewInfo.path)}`}/>
            :null
          }
          <Viewer
            visible={this.state.imagePreview}
            onClose={() => { this.setState({ imagePreview: false }); } }
            images={[{src: `${decodeURIComponent(previewInfo.path)}`, alt: ''}]}
            />
        </div>
        <div className="file-details">
          <div className="file-info-inner">
            <table>
              {
                Object.keys(recordDetail).length ?
                <tbody>
                  <tr>
                    <td>Created At:</td>
                  <td>{localTimeFn(recordDetail.created_at)}</td>
                  </tr>
                  <tr>
                    <td>Created By:</td>
                  <td>{recordDetail.created_by && recordDetail.created_by != null ? `${recordDetail.created_by}` : '--'} </td>
                  </tr>
                  <tr>
                    <td>Modified At:</td>
                  <td>{localTimeFn(recordDetail.modified_at)}</td>
                  </tr>
                  <tr>
                    <td>Modified By:</td>
                  <td>{recordDetail.modified_by && recordDetail.modified_by != null ? `${recordDetail.modified_by}` : '--'} </td>
                  </tr>
                  <tr>
                    <td>Size:</td>
                    <td>{getFileSize(recordDetail.size)}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{
                      recordDetail.type =="file" ? previewInfo.status ? `${previewInfo.status}` : '--' : null
                    }</td>
                  </tr>
                </tbody>
                :
                <tbody>
                  <tr>
                    <td colSpan="2" style={{textAlign: 'center'}}>Loading...</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(FileInfo);
