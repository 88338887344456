import React from 'react'
import moment from 'moment'
import { displayDateTimeFormatShort, imgStoragePath } from '../../../../constants'
import { getLocalStorageInfo } from '../../../../utils'
export default function({remark, index, editRemark, deleteRemark}){
  return(
    <tr>
      <td>{index}</td>
      <td style={{width: '60%'}}>{remark.remarks}</td>
      <td>{remark.created_by.name}</td>
      <td>{moment(remark.created_at).format(displayDateTimeFormatShort)}</td>
      <td>
      {
        getLocalStorageInfo().user.permission['technical_specs'] ?
        <img onClick={() => editRemark()} style={{width: '15px'}} src={imgStoragePath + 'icon_editblock.png'}/>
        :null
      }
      {
        getLocalStorageInfo().user.permission['technical_specs'] ?
        <img onClick={() => deleteRemark()} style={{width: '15px', marginLeft: '10px'}} src={imgStoragePath + 'delete_red.png'}/>
        :null
      }
      </td>
    </tr>
  )
}
