import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../constants';
import { MRCalculator } from './index';
import PlanList from './PlanList'
import { ListLoader } from '../../../shared'
import { INCREMENT, DECREMENT, INTERVAL_TOGGLE } from '../actions';
class Plans extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    getTagText=(planName)=>{
        
        switch(planName){
            case 'User':
            return "Monthly/Yearly Subscription plans per user";
            case 'Storage':
            return "Monthly/Yearly Subscription plans per storage unit";
            case 'Inspections':
            return "One-time license purchase per inspection plan";
            case 'MR calculator':
            return "One-time license purchase per query / unlimited plan";
            case 'Fleet cashflow':
            return "Yearly unlimited Subscription plans";
            case 'Contracts':
            return "Yearly Subscription plans per asset";
            case 'Maintenance':
            return "Yearly Subscription plans per asset";
            case 'Asset data transfer':
            return "One-time license purchase plan per asset";
        }
    }

    render(){
        const{ planDetail, extra_free_plan_approved } = this.props;
        let intialQuantity = null;
        let planIds = [3,6,7];
        return(
            <div>
                {
                    planDetail.id == 4 ?
                    <MRCalculator
                        props={this.props}
                        increment={this.props.increment}
                        decrement={this.props.decrement}
                        selectedPlans={this.props.selectedPlans}
                        addLicenceToCart={this.props.addLicenceToCart}
                        showCartButton={planDetail.is_show != undefined && planDetail.is_show != null ? planDetail.is_show : true}
                    />:
                    <div className="users-license mr-calculator">
                        <h2 className="block-title">{planDetail.name ? planDetail.name + ' License' : null }</h2>
                        <p className="block-title-tag">{this.getTagText(planDetail.name)}</p>
                        <Row>
                            {
                                planDetail.plans ?
                                planDetail.plans.map((plan, key) =>
                                plan.plan_type.value != 1 ?
                                <PlanList
                                 addLicenceToCart={this.props.addLicenceToCart}
                                 index={key}
                                 key={key}
                                 showCartButton={planDetail.is_show != undefined && planDetail.is_show != null ? planDetail.is_show : true}
                                 selectedFlag={this.props.selectedPlans? this.props.selectedPlans.filter(item => item.plan.plan_type.value==2).length>0? true :false:false}
                                 selectedPlans={this.props.selectedPlans}
                                 modal={this.props.modal}
                                 planIds={planIds}
                                 intialQuantity={intialQuantity}
                                 planDetail={planDetail}
                                 plan={plan}
                                 increment={this.props.increment}
                                 decrement={this.props.decrement}
                                 extra_free_plan_approved={extra_free_plan_approved}
                                 intervalToggle={this.props.intervalToggle}/>:null
                                )
                                :null
                            }
                            {planDetail.plans && planDetail.plans.length && !this.props.modal ?
                            <Col md="5" style={this.props.modal ? {display: 'none'} : {}} className="mr-pricing-grid contact-sales-grid">
                                <Row className="contact-sales">
                                    <Col md="12">
                                        <img src={imgStoragePath+'phase-2/email.png'} />
                                        <div className="sales-content">
                                            <p>
                                                Looking for a custom pricing or
                                                an enterprise plan?
                                                Please contact our
                                                sales team.
                                            </p>
                                            <a href="mailto:sales@acumenaviation.ie" target="_blank">Contact Sales</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            :null}
                        </Row>
                    </div>
                }
                {
                  this.props.licenseLoader ?
                    <ListLoader/>
                  :null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
  licenseLoader: state.LicenseReducer.licenseLoader,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        increment : (key, quantity) =>  dispatch({
            type: INCREMENT,
            payload: {key, quantity}
        }),
        decrement : (key, quantity) =>  dispatch({
            type: DECREMENT,
            payload: {key, quantity}
        }),
        intervalToggle : (key) => dispatch({
            type: INTERVAL_TOGGLE,
            payload: {key}
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
