import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel'
export const contrListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_LIST_LOADER,
      payload: true
    });
    globalGetService(params.type+'/'+params.aircraft_slug+'/contracts/',{})
    .then(response => {
      dispatch({
        type: actions.CONTRACT_LIST_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.CONTRACT_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const contrAddAc = (params, data) => {
  return (dispatch) => {
    globalPostService(params.type+'/'+params.aircraft_slug+'/contracts/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ADD_CONTR,
          payload: response.data.data
        });
        dispatch(contractUsageAc(params))
        trackActivity('Item Added', {
          page_title: `Contract`,
          application_module: `Contracts`,
          contract_slug: response.data.data.contract_slug,
          item_id: response.data.data.id,
          item_type: `Contract`,
        })
        toastFlashMessage(response.data.message, 'success');
      }
    });
  }
}
export const basicInfoAc = (params) => {
  return (dispatch) => {
    globalGetService(params.type + '/' +params.aircraft_slug + '/contracts/' + params.contract_slug  + '/',{})
    .then(response =>{
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BASIC_INFO,
          payload: response.data.data
        });
      }
    })
  }
}
export const publishContractAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_LIST_LOADER,
      payload: true
    });
    globalGetService('contracts/' + params.contract_slug  + '/publish/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BASIC_INFO,
          payload: response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
          setTimeout(function(){
            window.location.reload()
          }, 200);
          dispatch({
            type: actions.CONTRACT_LIST_LOADER,
            payload: false
          });
      }
      dispatch({
        type: actions.CONTRACT_LIST_LOADER,
        payload: false
      });
      trackActivity('Contract Published',{
        contract_slug: params.contract_slug
      })
    })
  }
}


export const contractUsageAc = (params) =>{
  return(dispatch) =>{
    globalGetService(`/console/${params.type}/${params.aircraft_slug}/license/contract/usage/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTRACT_USAGE_INFO,
          payload: response.data.data
        })
      }
    })
  }
}
