import * as actions from '../actions';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { addPortfolio } from '../createPortfolio';
import moment from 'moment';
let width = 0;
export default function(state={
  portfolioCrud:{
    flag:false,
    data:{},
    type:''
  },
  portfolioList:{},
  listLoader: false,
  portListLoader: false,
  assetLists: {
    asset:{},
    pagination:{}
  },
  compareList:[],
  addAssetList:[],
  portfolio_type: [],
  portfolio_status: [],
  portfolio:addPortfolio,
  addToCompareAll:false,
  checkedList: [],
  cashSimulation: {},
  cashFleetView: {},
  fleetContainerInfo: 0,
  cashSimulationFilter : {
    startDate : moment(),
    endDate : moment(),
    filter_type: 'cumulative_cashflow'
  },
  cashSimulationViewFilter : {
    startDate : moment(),
    endDate : moment(),
    filter_type: 'cumulative_cashflow'
  },
  fleetViewWidth: 0,
  fleetViewUsage:{}
},action){
  switch (action.type) {
    case actions.FLEET_VIEW_USAGE:
      return {
        ...state,
        fleetViewUsage:action.payload
      }
    case actions.PORTFOLIO_LIST:
      return {
        ...state,
        portfolioList:action.payload
      }
    case actions.PORTFOLIO_CRUD:
      return {
        ...state,
        portfolioCrud:action.payload
      }
    case actions.ADD_PORTFOLIO:
      return{
        ...state,
        portfolioCrud:{type:'add',flag:true,data:addPortfolio}
      }
    case actions.ADD_PORTFOLIO_LIST:
      return {
        ...state,
        portfolioList:[action.payload,...state.portfolioList]
      }
    case actions.DEL_PORTFOLIO_LIST:
      let portfolioList = Object.assign([], state.portfolioList);
      portfolioList.map((portfolio,index) => portfolio.id == action.payload ? portfolioList.splice(index,1) : portfolio);
      return {
        ...state,
        portfolioList
      }


      case actions.ADD_TO_COMPARE:
        if(action.operationType == 'existing'){
          if(!state.checkedList.filter(item => item.asset_id == action.payload.data.asset_id).length){
              action.payload.data.flag = true;
                return {
                  ...state,
                  compareList:[action.payload.data,...state.compareList],
                  checkedList:[action.payload.data,...state.checkedList],
                  addToCompareAll: (state.checkedList.length + 1) == state.portfolio.assets.length ? true :false
                }
          }else {
            return {
              ...state,
              compareList: state.compareList.filter(item => item.asset_id != action.payload.data.asset_id),
              addToCompareAll:false,
              checkedList: state.checkedList.filter(item => item.asset_id != action.payload.data.asset_id)
            }
          }
        }else {
          if(!state.checkedList.filter(item => item.asset_id == action.payload.data.asset_id).length){
                action.payload.data.flag = true;
                let arr = Object.assign([], state.checkedList);
                let flag = (arr.length + 1) == state.assetLists.asset.length ? true :false;
                return {
                  ...state,
                  addAssetList:[action.payload.data,...state.addAssetList],
                  checkedList:[action.payload.data,...state.checkedList],
                  addToCompareAll: flag
                }
          }
          else {
            return {
              ...state,
              addAssetList: state.addAssetList.filter(item => item.asset_id != action.payload.data.asset_id),
              addToCompareAll:false,
              checkedList: state.checkedList.filter(item => item.asset_id != action.payload.data.asset_id)
            }
          }
        }
      case actions.ADD_TO_COMPARE_ALL:
      let compareList = Object.assign([],state.compareList);
      let addAssetList = Object.assign([],state.addAssetList);
      let checkedList = Object.assign([],state.checkedList);
      let assetLists = Object.assign([],state.assetLists);
      let portfolio = Object.assign([],state.portfolio);
          checkedList = [];
          compareList = [];
          addAssetList = [];
          if(action.operation == 'existing'){
            if(action.payload==true){
              portfolio.assets.map((asset,index) => checkedList = [{asset_id:asset.id},...checkedList]);
              portfolio.assets.map((asset,index) => compareList = [{asset_id:asset.id},...compareList]);
                return {
                  ...state,
                  compareList,
                  checkedList,
                  addToCompareAll: action.payload
                }
            }
            else {
              return {
                ...state,
                compareList:[],
                checkedList:[],
                addToCompareAll: action.payload
              }
            }
          }else {
            assetLists.asset.map((asset,index) => addAssetList = [{asset_id:asset.id,asset_type:asset.asset_type,status:asset.status},...addAssetList]);
            assetLists.asset.map((asset,index) => checkedList = [{asset_id:asset.id},...checkedList]);
            if(action.payload==true){
                return {
                  ...state,
                  addAssetList,
                  checkedList,
                  addToCompareAll:true
                }
            }
            else {
              return {
                ...state,
                addAssetList:[],
                checkedList:[],
                addToCompareAll: false
              }
            }
          }
      case actions.DEL_PORTFOLIO_ASSET:
        let portfolioObj = Object.assign([],state.portfolio);
        let portfolioCompareList = Object.assign([],state.compareList);
        portfolioCompareList.map((item,index) => {
          portfolioObj.assets.map((asset,assetIndex) => {
            if(asset.id){
              item.asset_id =  portfolioObj.assets.splice(assetIndex,1);
            }else{
              item.asset_id =  asset
            }
            if( portfolioObj.no_of_asset > 0){
                portfolioObj.no_of_asset = portfolioObj.no_of_asset - 1;
            }
            return asset;
          });
        });
        return {
          ...state,
          compareList:[],
          portfolio: portfolioObj,
          checkedList:[],
          addToCompareAll:false
        }
      case actions.ADD_PORTFOLIO_ASSET:
          let portfolioAssetLists = Object.assign([],state.assetLists);
          let portfolioAddAssetList=Object.assign([],state.addAssetList);
          portfolioAddAssetList.map((asset,index) => portfolioAssetLists.asset.map((item,itemIndex) => asset.asset_id == item.id ? portfolioAssetLists.asset.splice(itemIndex,1) : item));
          return {
            ...state,
            assetLists : portfolioAssetLists,
            addAssetList: [],
            checkedList : [],
            addToCompareAll: false
          }
        case actions.EMPTY_COMPARE_LIST:
          return {
            ...state,
            compareList:[],
            checkedList:[],
            addAssetList:[],
            addToCompareAll:false
          }
        case actions.GET_PORTFOLIO_CONSTANTS:
          return {
            ...state,
            [action.constantType]:action.payload
          }
        case actions.ASSETS_LIST:
          return {
            ...state,
            portfolio:action.payload
          }
        case actions.GET_ASSETS_LIST:
          return {
            ...state,
            assetLists:action.payload
          }
        case actions.EDIT_PORTFOLIO_LIST:
          return {
            ...state,
            portfolioList: state.portfolioList.map(portfolio => portfolio.id == action.payload.id ? action.payload : portfolio)
          }
        case actions.FLEET_LIST_LOADER:
          return {
            ...state,
            listLoader:action.payload
          }
        case actions.PORTFOLIO_LIST_LOADER:
          return {
            ...state,
            portListLoader:action.payload
          }
       case actions.FLEET_CASHFLOW_SIMULATION:
          if(Object.keys(action.payload).length && action.payload.cashflow_data){
            width= 220 * (action.payload.cashflow_data.length);
            return {
              ...state,
              cashSimulation: action.payload,
              fleetContainerInfo: width,
              cashSimulationFilter: {
                ...state.cashSimulationFilter,
                startDate: moment(action.payload.startDate).format(backendDateFormat),
                endDate: moment(action.payload.endDate).format(backendDateFormat)
              }
            }
          }else{
            return state;
          }
       case actions.FLEET_UPDATE_FILTER:
          return {
            ...state,
            cashSimulationFilter: {
              ...state.cashSimulationFilter,
              [action.payload.type] : action.payload.value
            }

          }
      case actions.FLEET_CASHFLOW_VIEW:
          if(Object.keys(action.payload).length && action.payload.major_assemblies){
            width= (220 * (action.payload.major_assemblies.length));
            return {
              ...state,
              cashFleetView: action.payload,
              fleetViewWidth: width,
              cashSimulationViewFilter: {
                ...state.cashSimulationViewFilter,
                startDate: moment(action.payload.startDate).format(backendDateFormat),
                endDate: moment(action.payload.endDate).format(backendDateFormat),
                filter_type: (Object.keys(action.filterType).length && action.filterType && action.filterType != '') ? action.filterType.filter_type: 'cumulative_cashflow'
              }
            }
          }else{
            return state;
          }
        case actions.FLEET_VIEW_UPDATE_FILTER:
          return {
            ...state,
            cashSimulationViewFilter: {
              ...state.cashSimulationViewFilter,
              [action.payload.type] : action.payload.value
            }

          }

    default:
      return state
  }
}
