export const UPDATE_T011_CARD_POS = 'UPDATE_T011_CARD_POS';
export const INITIAL_SCROLL = 'INITIAL_SCROLL';
export const TABLE_CONTENT_DATA = 'TABLE_CONTENT_DATA';
export const UPDATE_GAP_LOADER='UPDATE_GAP_LOADER';
export const GAP_REPORT_DATA='GAP_REPORT_DATA';
export const TOGGLE_SIDE_BAR='TOGGLE_SIDE_BAR';
export const TOGGLE_IMPORT_SIDE_BAR = 'TOGGLE_IMPORT_SIDE_BAR';
export const CREATE_SECTION_DATA='CREATE_SECTION_DATA';
export const UPDATE_SECTION_LOADER='UPDATE_SECTION_LOADER';
export const DELETE_SECTION_DATA='DELETE_SECTION_DATA';
export const UPDATE_POS='UPDATE_POS';
export const UPDATE_TABLE_CONTENT='UPDATE_TABLE_CONTENT';
export const UPDATE_SECTION_CREATE='UPDATE_SECTION_CREATE';
export const CLEAR_DATA='CLEAR_DATA';
