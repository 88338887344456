import React, { Component } from 'react';
import moment from 'moment';
import { ContrListCol } from '../../Elements';
import { displayDateFormat } from '../../../../constants';
export default class QfdMntEventRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { qfdMntEvent } = this.props;
    const qfdMntEventobj = [
      { value: qfdMntEvent.reserve_account && qfdMntEvent.reserve_account.name ? qfdMntEvent.reserve_account.name : '--' },
      { value: qfdMntEvent.event ? qfdMntEvent.event : '--' },
      { value: qfdMntEvent.qualified_items ? qfdMntEvent.qualified_items : '--' },
      { value: qfdMntEvent.start_date ? moment(qfdMntEvent.start_date).format(displayDateFormat) + ' to ' + (qfdMntEvent.end_date ? moment(qfdMntEvent.end_date).format(displayDateFormat): '--'): '--' }
    ]
    return(
      <tr onClick={this.props.viewQfdMntEvent}>
        {qfdMntEventobj.map((qfdMntEvent,index) => <ContrListCol colObj={qfdMntEvent}  key={index} />)}
      </tr>
    )
  }
}
