import React, { Component } from 'react';
export default class MRRateHeading extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <thead>
        <tr>
          <th>Reserve Account</th>
          <th>Interval</th>
          <th>Rate</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Escalation</th>
          <th>Escalation Interval</th>
        </tr>
      </thead>
    )
  }
}
