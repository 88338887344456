import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class TableContentRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <tr>
        <td>
          1
        </td>
        <td>
          Test
        </td>
        <td>
          0
        </td>
        <td>
          0
        </td>
        <td>
          0
        </td>
      </tr>
    )
  }
}

export default TableContentRow;
