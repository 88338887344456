import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { getFileSize } from '../../../../utils'
import { imgStoragePath } from '../../../../constants';
export default class FilesUploaded extends Component{
  constructor(props){
    super(props);
    this.state = {
      pdf_type:1
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){
    const { fileDetail, index } = this.props;
    return(
      <Row className="upload-row margin-bottom-10">
        <Col md="8">
          { !fileDetail.error == 1 ?
            <img width="15" src={imgStoragePath+"pdf.png"} alt="img"/> : null }
            <span className="caption-ui">{fileDetail.file.name.replace(/^.*[\\\/]/, '')}</span>
        </Col>
        <Col md="3">
          <span className="caption-ui">{( getFileSize(fileDetail.file.size))} </span>
        </Col>
        <Col md="1" className="text-right">
          { fileDetail.error == 1 ?
            <span className="change-file" >
              <img src={imgStoragePath+"file_replace.png"} alt="img"/>
              <input type="file" onChange={(e) => this.props.replaceFile(index, e.target.files[0])} />
            </span> : null
          }
          <img src={imgStoragePath+"icon_trash.png"} alt="delete" className="delete-icon" onClick={() => this.props.deleteFn(index)}/>
        </Col>
      </Row>
    )
  }
}
