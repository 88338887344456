export const shopVisitObj={
added_from: 0,
arc_date: null,
attachments: [],
build_target: null,
cshi: null,
cslsv: null,
csn: null,
cso: null,
csr: null,
date_of_reinstallation: null,
date_of_removal: null,
engine_work_order: 0,
final_workscope: null,
induction_date: null,
llp_status:null,
reason_for_removal: null,
shop_facility: null,
shop_visit_number: null,
status: 0,
test_run_egt_margin: null,
total_air_temperature: null,
tshi: null,
tslsv: null,
tsn: null,
tso: null,
tsr: null,
}
export const createEngine={
    ad_status: null,
    aircraft_msn: null,
    aircraft_registration: null,
    aircraft_type_id: null,
    as_on_date: null,
    attachments: [],
    build_standard: null,
    country:null,
    constructed_model: null,
    cshi: null,
    cslv: null,
    csn: null,
    cso: null,
    csr: null,
    date_of_inspection: null,
    date_of_installation: null,
    ectm_detail: null,
    end_of_lease: null,
    engine_id: null,
    engine_manufacturing_date:null,
    engine_status: 1,
    engine_type_id: null,
    esn: null,
    forms: [12, 13, 14, 15, 16, 17],
    inspection_cshi: null,
    inspection_cslv: null,
    inspection_csn: null,
    inspection_cso: null,
    inspection_csr: null,
    inspection_details: null,
    inspection_performed_by: null,
    inspection_tshi: null,
    inspection_tslv: null,
    inspection_tsn: null,
    inspection_tso: null,
    inspection_tsr: null,
    install_position: 1,
    lang: null,
    lat: null,
    lease_contract: null,
    lease_return_standard: null,
    llp_status_sheet: null,
    location: null,
    mpa_test_margin: null,
    mr: null,
    mr_available_for_erp: null,
    mr_available_for_llp: null,
    operation: null,
    owned_by: null,
    owned_by_type: 1,
    progress: 0,
    project: null,
    qme: null,
    ratio: null,
    reference_links: [],
    remarks: null,
    sb_status: null,
    shop_visits: [],
    status: {id: 1, label: "Initiated"},
    tshi: null,
    tslv: null,
    tsn: null,
    tso: null,
    tsr: null,
}

export const  quesObj={
    "data": {
        "sections": [
            {
                "id": 1,
                "name": "General",
                "questions": [
                    {
                        "id": 1,
                        "question": "Approach and general cooperation from the Operator",
                        "answers": [
                            {
                                "id": 1,
                                "user_id": 1,
                                "comments": "Nice",
                                "rating": 1
                            },
                            {
                                "id": 3,
                                "user_id": 2,
                                "comments": "Nice",
                                "rating": 5
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "question": "Ease of access to Operator's facility",
                        "answers": [
                            {
                                "id": 2,
                                "user_id": 1,
                                "comments": "Excellent",
                                "rating": 5
                            },
                            {
                                "id": 4,
                                "user_id": 2,
                                "comments": "Nice",
                                "rating": 1
                            }
                        ]
                    },
                    {
                        "id": 3,
                        "question": "Signs of Operator financial distress (If applicable and easy to source)",
                        "answers": []
                    },
                    {
                        "id": 4,
                        "question": "Total number of Aircraft (<= 10 , <= 50 , >= 100)",
                        "answers": []
                    },
                    {
                        "id": 5,
                        "question": "Types of Aircraft in the fleet (Helicopters, Turbo Props/Jets, Narrow and/or Wide body)",
                        "answers": []
                    },
                    {
                        "id": 6,
                        "question": "Operator’s fleet configuration (Single Aircraft type fleet, Mixed Aircraft type Fleet)",
                        "answers": []
                    },
                    {
                        "id": 7,
                        "question": "Number of Aircraft AOG (If applicable  and easy to source) (>= 25 %, <= 5 %, <= 15 %)",
                        "answers": []
                    },
                    {
                        "id": 8,
                        "question": "Number of Engines in Shop (If easy to source) (>= 25 %, <= 5 %, <= 15 %)",
                        "answers": []
                    },
                    {
                        "id": 9,
                        "question": "Number of Aircraft in Major checks (If easy to source) (>= 10 %, <= 5 %, <= 1 %)",
                        "answers": []
                    },
                    {
                        "id": 10,
                        "question": "Approved regions for flying (Only Domestic, Restricted Overwater Flying, International)",
                        "answers": []
                    },
                    {
                        "id": 11,
                        "question": "Operating Environment",
                        "answers": []
                    },
                    {
                        "id": 12,
                        "question": "Overall quality of workmanship",
                        "answers": []
                    },
                    {
                        "id": 13,
                        "question": "Maintenance Training / Certification of the maintenance staff\t",
                        "answers": []
                    }
                ]
            },
            {
                "id": 2,
                "name": "Physical Inspection",
                "questions": [
                    {
                        "id": 66,
                        "question": "Airframe",
                        "answers": []
                    },
                    {
                        "id": 67,
                        "question": "Engines Inspection, (Overall condition)",
                        "answers": []
                    },
                    {
                        "id": 68,
                        "question": "APU",
                        "answers": []
                    },
                    {
                        "id": 69,
                        "question": "Cabin / Flight Deck",
                        "answers": []
                    },
                    {
                        "id": 70,
                        "question": "Interior",
                        "answers": []
                    },
                    {
                        "id": 71,
                        "question": "Paint",
                        "answers": []
                    },
                    {
                        "id": 72,
                        "question": "Owner's Placards",
                        "answers": []
                    },
                    {
                        "id": 73,
                        "question": "Aircraft Exterior Inspection (overall condition)",
                        "answers": []
                    },
                    {
                        "id": 74,
                        "question": "Aircraft Interior Inspection (overall condition)",
                        "answers": []
                    }
                ]
            },
            {
                "id": 3,
                "name": "Maintenance",
                "questions": [
                    {
                        "id": 14,
                        "question": "General maintenance capability/  facilities/ engineering ",
                        "answers": []
                    },
                    {
                        "id": 15,
                        "question": "Maintenance arrangements - base maintenance ",
                        "answers": []
                    },
                    {
                        "id": 16,
                        "question": "Maintenance (SV) arrangements for engines, APU and Landing Gears",
                        "answers": []
                    },
                    {
                        "id": 17,
                        "question": "Engine pooling arrangements (if\tapplicable)",
                        "answers": []
                    },
                    {
                        "id": 18,
                        "question": "Maintenance arrangements –\tComponents",
                        "answers": []
                    },
                    {
                        "id": 19,
                        "question": "C Check Intervals (MSG II, MSG III, Optimized Maintenance Program)",
                        "answers": []
                    },
                    {
                        "id": 20,
                        "question": "ERP system in place (No, Manual and ERP, Yes)",
                        "answers": []
                    },
                    {
                        "id": 21,
                        "question": "ERP System’s reliability (Poor, Average, Good)",
                        "answers": []
                    },
                    {
                        "id": 22,
                        "question": "Airframe and Engines reliability\t/ condition monitoring Arrangements (Poor, Average, Good)",
                        "answers": []
                    },
                    {
                        "id": 23,
                        "question": "Any tracking system in place for aircraft movements (No, Manual and ERP, ERP)",
                        "answers": []
                    },
                    {
                        "id": 34,
                        "question": "Airframe",
                        "answers": []
                    },
                    {
                        "id": 35,
                        "question": "Engines\tInspection, (Overall\tcondition)",
                        "answers": []
                    },
                    {
                        "id": 36,
                        "question": "APU",
                        "answers": []
                    },
                    {
                        "id": 37,
                        "question": "Cabin / Flight Deck",
                        "answers": []
                    },
                    {
                        "id": 38,
                        "question": "Interior",
                        "answers": []
                    },
                    {
                        "id": 39,
                        "question": "Paint",
                        "answers": []
                    },
                    {
                        "id": 40,
                        "question": "Owner's Placards",
                        "answers": []
                    },
                    {
                        "id": 41,
                        "question": "Aircraft Exterior Inspection (overall condition)",
                        "answers": []
                    },
                    {
                        "id": 42,
                        "question": "Aircraft Interior Inspection (overall condition)",
                        "answers": []
                    },
                    {
                        "id": 52,
                        "question": "Average Annual Utilization for A/C Type",
                        "answers": []
                    },
                    {
                        "id": 53,
                        "question": "Average Annual Utilization for A/C Type",
                        "answers": []
                    },
                    {
                        "id": 54,
                        "question": "Engine De-rate Policy (%age de- rate applied)",
                        "answers": []
                    },
                    {
                        "id": 55,
                        "question": "Maintenance Approvals",
                        "answers": []
                    },
                    {
                        "id": 56,
                        "question": "Maintenance Program specifications (more or less restrictive that MPD)",
                        "answers": []
                    },
                    {
                        "id": 57,
                        "question": "Heavy Maintenance Visit Contracts (FAA / EASA Approved MROs)",
                        "answers": []
                    },
                    {
                        "id": 58,
                        "question": "Powerplant Management Systems",
                        "answers": []
                    },
                    {
                        "id": 59,
                        "question": "Engine De-rate Policy (%age de- rate applied)",
                        "answers": []
                    },
                    {
                        "id": 60,
                        "question": "Maintenance Approvals",
                        "answers": []
                    },
                    {
                        "id": 61,
                        "question": "Maintenance Program specifications (more or less restrictive that MPD)",
                        "answers": []
                    },
                    {
                        "id": 62,
                        "question": "Heavy Maintenance Visit Contracts (FAA / EASA Approved MROs)",
                        "answers": []
                    },
                    {
                        "id": 63,
                        "question": "Powerplant Management Systems",
                        "answers": []
                    },
                    {
                        "id": 64,
                        "question": "Operator's maintenance contract policy for Engine Shop Visits (ESV)",
                        "answers": []
                    },
                    {
                        "id": 65,
                        "question": "Operator's maintenance contract policy for Engine Shop Visits (ESV)",
                        "answers": []
                    }
                ]
            },
            {
                "id": 4,
                "name": "Technical records",
                "questions": [
                    {
                        "id": 24,
                        "question": "Language of records – ENGLISH / Other (No English, Local Language and English, English)",
                        "answers": []
                    },
                    {
                        "id": 25,
                        "question": "Hard copy storage / scanning / archive (Do operate\thave in house or external arrangements for records dizitisation)",
                        "answers": []
                    },
                    {
                        "id": 26,
                        "question": "Completeness of records",
                        "answers": []
                    },
                    {
                        "id": 27,
                        "question": "AD / SB / Modifications / STC records sampling",
                        "answers": []
                    },
                    {
                        "id": 28,
                        "question": "Dent an buckle / repair records sampling",
                        "answers": []
                    },
                    {
                        "id": 29,
                        "question": "Engine LLP records sampling",
                        "answers": []
                    },
                    {
                        "id": 30,
                        "question": "Landing Gear records sampling",
                        "answers": []
                    },
                    {
                        "id": 31,
                        "question": "Accident / incident history",
                        "answers": []
                    },
                    {
                        "id": 32,
                        "question": "Operator Maintenance Program meets / exceeds OEM MPD (Internal Program, Internal and MPD, Same as MPD)",
                        "answers": []
                    },
                    {
                        "id": 33,
                        "question": "General\tCondition of Record Room",
                        "answers": []
                    },
                    {
                        "id": 43,
                        "question": "Records Digitization Measures (implemented / not implemented)",
                        "answers": []
                    },
                    {
                        "id": 44,
                        "question": "Technical Records Archival and\r\n Storage",
                        "answers": []
                    }
                ]
            },
            {
                "id": 5,
                "name": "Operation of aircraft",
                "questions": [
                    {
                        "id": 45,
                        "question": "Accreditations from IATA Operational Safety Audit (IOSA), Safety Management System (SMS), IATA Safety Audit for Ground Operations (ISAGO),etc",
                        "answers": []
                    },
                    {
                        "id": 46,
                        "question": "Transparency in reporting adverse events (incidents / accidents / Engine removals etc)",
                        "answers": []
                    },
                    {
                        "id": 47,
                        "question": "Accurate and On Time reporting of utilization",
                        "answers": []
                    },
                    {
                        "id": 48,
                        "question": "Operator cooperation towards audit program",
                        "answers": []
                    },
                    {
                        "id": 49,
                        "question": "Compliance with lessor requirements and engagement towards auditor's observations\r\n / findings",
                        "answers": []
                    },
                    {
                        "id": 50,
                        "question": "Overall impression of operator's responsibilities towards lease agreement clauses",
                        "answers": []
                    }
                ]
            },
            {
                "id": 6,
                "name": "GeoPolitical",
                "questions": [
                    {
                        "id": 51,
                        "question": "Participation of country in Cape\r\n Town Treaty",
                        "answers": []
                    }
                ]
            }
        ],
        "users": [
            {
                "id": 2,
                "name": "Vijay Kumar",
                "profile_pic": "https://ams3.digitaloceanspaces.com/sparta-aims/media-dev/users/vijay1_1024-284x300.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20190102%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20190102T130615Z&X-Amz-Expires=30000&X-Amz-SignedHeaders=host&X-Amz-Signature=30663651ad08ff2fdec14ba92266b283a09abac213448a7dc2d835b419084157"
            },
            {
                "id": 3,
                "name": "Murugan",
                "profile_pic": "https://ams3.digitaloceanspaces.com/sparta-aims/media-dev/users/vijay1_1024-284x300.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20190102%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20190102T130615Z&X-Amz-Expires=30000&X-Amz-SignedHeaders=host&X-Amz-Signature=30663651ad08ff2fdec14ba92266b283a09abac213448a7dc2d835b419084157"
            },
            {
                "id": 4,
                "name": "Nakul",
                "profile_pic": "https://ams3.digitaloceanspaces.com/sparta-aims/media-dev/users/vijay1_1024-284x300.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=4MFZU7TBCN3FWTR7ZZLJ%2F20190102%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20190102T130615Z&X-Amz-Expires=30000&X-Amz-SignedHeaders=host&X-Amz-Signature=30663651ad08ff2fdec14ba92266b283a09abac213448a7dc2d835b419084157"
            }
        ]
    },
    "status": "SUCCESS",
    "statusCode": 200
}