import * as actions from '../actions';
import {sectionObj} from '../../AddFormObjects'
export default function( state = {
  t011InfoSettings: {
    totalWidth: window.innerWidth,
    leftCount: 0,
    rightCount: 0,
    pageCount: 0,
    leftPos: 0,
    avg:0,
    actualLeftCount:0
  },
  contentOfTable:{
    list:[]
  },
  gapReportData:{},
  contentLoader:false,
  sectionLoader:false,
  editSideBar:false,
  updateComponent:false,
  editSideBarTitle:'',
  lengthOfsection:0,
  sectionObj:sectionObj,
  importReport: false

}, action ){
  switch (action.type) {
    case actions.TOGGLE_IMPORT_SIDE_BAR:
    return{
      ...state,
      importReport: action.payload
    }
    case actions.TOGGLE_SIDE_BAR:
    return{
      ...state,
      editSideBar:!state.editSideBar,
      editSideBarTitle:action.payload
    }
    case actions.TABLE_CONTENT_DATA:
    return{
    ...state,
    contentOfTable: action.payload
    }
    case actions.UPDATE_GAP_LOADER:
    return{
      ...state,
      contentLoader: action.payload
    }
    case actions.UPDATE_TABLE_CONTENT:
    return{
      ...state,
      updateComponent: action.payload
    }
    case actions.GAP_REPORT_DATA:
    return{
    ...state,
    gapReportData: action.payload,
    updateComponent:true

    }
    case actions.CLEAR_DATA:
    return{
    ...state,
    gapReportData: action.payload,
    }
    case actions.UPDATE_SECTION_LOADER:
    return{
    ...state,
    sectionLoader: action.payload
    }

    case actions.DELETE_SECTION_DATA:
    return{
      ...state,
    gapReportData:{
      ...state.gapReportData,
      list:state.gapReportData.list.filter(data => data.id!=action.payload)}
    }

    case actions.CREATE_SECTION_DATA:
    return{
    ...state,
    gapReportData:{
      ...state.gapReportData,
      list:[...state.gapReportData.list,action.payload]}
    }

    case actions.UPDATE_SECTION_CREATE:
    return{
      ...state,
      sectionObj: sectionObj
      }

    case actions.INITIAL_SCROLL:
    let winowWidth = window.innerWidth-180;
    let totalWidth = 0;
    let avg = 165;
    if(state.contentOfTable.list.length>0){
      state.contentOfTable.list.map(content => {
        let charValue = 7.3 * content.name.length ;
        totalWidth=totalWidth+30+charValue;
      })
      totalWidth=totalWidth+30+183;
      avg=totalWidth/state.contentOfTable.list.length;
    }else{
      totalWidth = 165*17;
      avg=165
    }
     let leftCount = Math.round((totalWidth - winowWidth)/avg);
     leftCount= leftCount-1;
    return {
      ...state,
      t011InfoSettings: {
        ...state.t011InfoSettings,
        totalWidth: totalWidth,
        avg:avg,
        leftCount: (leftCount > 0) ? leftCount : 0,
        actualLeftCount:(leftCount > 0) ? leftCount : 0,
      }
    }

    case actions.UPDATE_T011_CARD_POS:
      let newLeftCount = 0, newRightCount = 0, newLeftPos = 0;
      if(action.payload == 'next'){
        newLeftCount = state.t011InfoSettings.leftCount - 1;
        newRightCount = state.t011InfoSettings.rightCount + 1;
        newLeftPos = state.t011InfoSettings.leftPos - state.t011InfoSettings.avg;
      }else{
        newLeftCount = state.t011InfoSettings.leftCount + 1;
        newRightCount = state.t011InfoSettings.rightCount - 1;
        newLeftPos = state.t011InfoSettings.leftPos + state.t011InfoSettings.avg;
      }
      return {
        ...state,
        t011InfoSettings: {
          ...state.t011InfoSettings,
          leftPos:newLeftPos,
          leftCount: newLeftCount,
          rightCount: newRightCount
        }
      }

    case actions.UPDATE_POS:
      let newLeft = 0, newRight = 0, newPos = 0;
      let totalNewWidth = 0;
      let newAvg = 0;
      let newwinowWidth = window.innerWidth-385;
         state.contentOfTable.list.map((content,index) => {
           if(action.payload>=index){
          let charValue = 7.3 * content.name.length ;
          totalNewWidth=totalNewWidth+30+charValue;
           }
        })
        totalNewWidth=totalNewWidth+30+183;
        newAvg=totalNewWidth/action.payload+1;
        let newleftCount = Math.round((totalNewWidth - newwinowWidth)/newAvg);
        newleftCount= newleftCount-1;
        newleftCount = (newleftCount > 0) ? newleftCount : 0;
        if(newleftCount==state.t011InfoSettings.leftPos){
          return state
        }
          newLeft = state.t011InfoSettings.actualLeftCount - newleftCount;
          newRight = newleftCount;
          newPos = -(state.t011InfoSettings.avg * newleftCount);
          return {
            ...state,
            t011InfoSettings: {
              ...state.t011InfoSettings,
              leftPos:newPos,
              leftCount: newLeft,
              rightCount: newRight
            }
          }

      default:
      return state;
  }
}
