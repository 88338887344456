import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../assets/styles/records_d.scss';
import { downloadBaseUrl, imgStoragePath } from '../../../../constants';
export default class ResourceFolder extends Component{
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }
  render (){
    const { name, size, hide} = this.props;
    return (
      hide? null:
      <Col onClick={this.props.downloadFn} md="4" style={{ cursor: 'default'}}>
        <div className="folder-card">
          <img  src={imgStoragePath+"folder_icn2.png"} alt="" />
          <div className="folder-info">
            { size == '0 bytes'? <h4 style={{paddingTop:'5px'}}>{name}</h4>:<h4>{name}</h4> }
            <p>{size == '0 bytes'? '':size}</p>
          </div>
          { this.props.permission == "DN" && this.props.donwloadEnabled ?
            <img className="rec-download-icon" src={imgStoragePath+"downloadresources.png"}  alt="" />
            :null
          }
          { name == 'Drive Tool' ?
            <a className="downloadapp" download={size == "Windows OS 32 bit" ? "SPARTA_Drive_32.msi" : size == "Windows OS 64 bit" ? "SPARTA_Drive.msi" : "SPARTA_Drive.dmg"} href={downloadBaseUrl+ (size =='Windows OS 32 bit' ? '/tools/drive/SPARTA_Drive_32.msi' : size == 'Windows OS 64 bit' ? '/tools/drive/SPARTA_Drive.msi':'/tools/drive/SPARTA_Drive.dmg' ) } />:null
          }
          {
            this.props.content ?
            <div className="notice-info" style={{marginTop: '10px', marginBottom: '0px', marginLeft: '0px', textAlign: 'center'}}>
              <p>{this.props.content}</p>
            </div>
            :null
          }
        </div>
      </Col>
    )
   }
}
