import React, { Fragment } from 'react';
export default function AssetCard({item}) {
   return(
     <Fragment>
       {(() => {
         if(item.asset_type === 1) {
           return (
             <div className="asset-card">
               <img width="32" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_Aircraft.png" alt="" />
               <div className="">
                 <span>{item.aircraft_type.name}</span>
                 <h4>{`MSN ${item.msn}`}</h4>
               </div>
             </div>
           )
         }else if (item.asset_type === 2) {
           return (
             <div className="asset-card">
               <img width="32" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_engine.png" alt="" />
               <div className="">
                 <span>{item.engine_type.name}</span>
                 <h4>{`ESN ${item.esn}`}</h4>
               </div>
             </div>
           )
         }else if (item.asset_type === 3) {
           return(
             <div className="asset-card">
               <img width="32" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_lg.svg" alt="" />
               <div className="">
                 <h4>
                   {`LG ${item.serial_number}`}
                 </h4>
               </div>
             </div>
           )
         }else if (item.asset_type === 4) {
           return(
             <div className="asset-card">
               <img width="32" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_apu.svg" alt="" />
               <div className="">
                 <h4>
                   {`APU ${item.serial_number}`}
                 </h4>
               </div>
             </div>
           )
         }else if (item.asset_type === 5) {
           return(
             <div className="asset-card">
               <img width="32" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_propeller.svg" alt="" />
               <div className="">
                 <h4>
                   {`PROPELLER ${item.serial_number}`}
                 </h4>
               </div>
             </div>
           )
         }else if ([6,7,8,9].includes(item.asset_type)) {
           return(
             <div className="asset-card">
               <img width="32" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_engine.png" alt="" />
               <div className="">
                 <span>{item.engine_type.name}</span>
                 <h4>{item.esn}</h4>
               </div>
             </div>
           )
         }
         else {
           return (
             <div>catch all</div>
           )
         }
       })()}
     </Fragment>
   )
 }
