import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  LopaTab,
  TableHeadingTechSummary,
  RemarksList,
  AddEditRemarks
} from '../components';
import { getRemarksListAc, saveRemarksAc, deleteRemarkAc } from '../actionCreators';
import { REMARKS_CRUD } from '../actions';
import { EditSidebar, CtrTableHd, FormModeType } from '../../../contracts/Elements';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { NoRecordFound  } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils';
class RemarksCont extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      deleteRemark: {flag: false, remark: {}}
    }
  }
  componentDidMount() {
    this.props.getRemarksList()
  }

saveRemarks = (evt) =>{
  evt.preventDefault()
  evt.stopPropagation()
  if(this.props.remarksCrud.data.remarks.trim() == ''){
    this.setState({error: 'Please enter Remark'})
  }else{
    this.props.saveRemarks(this.props.remarksCrud)
  }
}

  render(){
    const {
      asset_slug,
      techSpecsLoader,
      remarksCrud,
      remarksList
    } = this.props;
    return(
      <div>
        <div className="contr-table-blk">
          <TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud({flag: true, type: 'add', data: {remarks:''}})} title='Additional Remarks' newFeature={this.props.params.type != 'aircraft' ? true : false} addButton={getLocalStorageInfo().user.permission['technical_specs'] ? true: false} />
          <div className="table-container">
            <table className="common-table">
              <CtrTableHd theads={['Sr No.','Remarks','Created By', 'Created At', '']}/>
              <tbody>
                {
                  remarksList.length ? remarksList.map((remark, index) => <RemarksList deleteRemark={() => this.setState({deleteRemark: {flag: true, remark: remark}})} editRemark={() => this.props.techSummaryCrud({flag: true, type: 'edit', data: remark})} key={"remark_" + index} index={index + 1} remark={remark}/>) : null
                }
              </tbody>
            </table>
          </div>
          {!techSpecsLoader && !remarksList.length ?
						<NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
		    	}
        </div>
        <EditSidebar
          title={
            remarksCrud.type != '' ?
            (remarksCrud.type == 'add') ? 'Add Remarks':
            (remarksCrud.type == 'edit') ? 'Edit Remarks': 'View Crew Seat' : ''
            }
          editSideBar={remarksCrud.flag}
          toggleEditSideBar={() =>this.props.techSummaryCrud({flag: false, type: 'add', data: {remarks:''}})}
          >
          {
            Object.keys(remarksCrud.data).length && remarksCrud.flag ?
            <div>
              <AddEditRemarks
                remark={remarksCrud.data}
                type={remarksCrud.type}
                setRemark={(value) => {this.props.techSummaryCrud({flag: true, type: this.props.remarksCrud.type, data: {...this.props.remarksCrud.data, remarks:value}});this.setState({error: ''})}}
                error={this.state.error} saveRemarks={(evt) => this.saveRemarks(evt)}
                toggleEditSideBar={() =>this.props.techSummaryCrud({flag: false, type: 'add', data: {remarks:''}})}
              />
            </div>:null
          }
        </EditSidebar>
        <DeleteModal isOpen={this.state.deleteRemark.flag} toggle={() => this.setState({deleteRemark: {flag: false, remark: {}}})} title="Delete Remark" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => {this.props.deleteRemark(this.state.deleteRemark.remark);this.setState({deleteRemark: {flag: false, remark: {}}});}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.setState({deleteRemark: {flag: false, remark: {}}})}>No</button>
              </li>
          </ul>
          </DeleteModal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
    remarksCrud: state.TechSummaryReducer.remarksCrud,
    remarksList: state.TechSummaryReducer.remarksList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRemarksList: () => dispatch(getRemarksListAc(ownProps.params)),
    saveRemarks: (data) => dispatch(saveRemarksAc(data, ownProps.params)),
    techSummaryCrud: (crudObj) => dispatch({
      type: REMARKS_CRUD,
      payload: crudObj
    }),
    deleteRemark: (remark) => dispatch(deleteRemarkAc(ownProps.params, remark))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemarksCont));
