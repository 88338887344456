import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { displayDateFormat, imgStoragePath, fileIcons } from '../../../constants'
import { getLocalStorageInfo } from '../../../utils'
import Highlighter from "react-highlight-words";
export default class RecordsSearchCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { item } = this.props;
    // const itemRegEx = new RegExp(this.props.searchTerm, "gi");
    const assetInfo = {
      engine: 'ESN',
      aircraft: 'MSN',
      apu: 'APU',
      lg: 'LG',
      propeller: 'PROPELLER'
    }
    const category = {
      1: 'technical',
      2: 'discrepancies',
      3: 'contracts',
      4: 'inspections'
    }
    return(
      <div className="record-search-list">
        <img src={item.type == 'file' ? this.props.fileIcons[item.extension] : imgStoragePath+"folder_icn2.png"} alt="icon" />
        <div className="records-info">
          {this.props.level == 'Fleet' ? <span className="msn-tag">{assetInfo[item.asset_type]} {item.msn}</span> : null}
          <h3>
            {
              getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.data_room && getLocalStorageInfo().user.permission.records.data_room.indexOf('R') != -1 ?
              <Link to={item.type == 'folder' ? "/"+item.asset_type+"/"+item.asset_slug+"/records/data-room/" + category[item.category] + '/' +item.uuid : ''} onClick={this.props.previewFile} >
              <Highlighter
                  highlightClassName="SearchHighlightClass"
                  searchWords={[this.props.searchTerm]}
                  autoEscape={true}
                  textToHighlight={item.name}
                />
              </Link>
              :<Link >
              <Highlighter
                  highlightClassName="SearchHighlightClass"
                  searchWords={[this.props.searchTerm]}
                  autoEscape={true}
                  textToHighlight={item.name}
                />
              </Link>
            }
            {
              getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.data_room && getLocalStorageInfo().user.permission.records.data_room.indexOf('DN') != -1 ?
                <a href={item.path} target="_blank" download={item.name}><img src={imgStoragePath + 'downloadresources.png'} style={{marginRight: '10px', width: '15px'}}/></a>
              :null
            }
          </h3>
          <p>{item.location}</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <span>Author:</span> {item.created_by && item.created_by.first_name ? item.created_by.first_name : '--' }
            </li>
            <li className="list-inline-item">
              <span>Created Date:</span> {moment(item.created).format(displayDateFormat)}
            </li>
            <li className="list-inline-item">
              <span>Occurence Count:&nbsp;</span>
              {
                (item.occurrence_count!=0 && item.occurrence_count!=null)?
                `${item.occurrence_count}`: 0
              }
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
