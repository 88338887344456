export const GEN_MR_REPORT = 'GEN_MR_REPORT';
export const REVIEW_MR_DATA = 'REVIEW_MR_DATA';
export const REGION_LIST = 'REGION_LIST';
export const REGION_CONDITION = 'REGION_CONDITION';
export const UDPATE_CUSTOM_REGION = 'UDPATE_CUSTOM_REGION';
export const UPDATE_VAR_CONS_IDS = 'UPDATE_VAR_CONS_IDS';
export const AIRCRAFT_TYPE_LIST = 'AIRCRAFT_TYPE_LIST';
export const UPDATE_MR_CLIENTS = 'UPDATE_MR_CLIENTS';
export const AIRCRAFT_MAIN_PROG = 'AIRCRAFT_MAIN_PROG';
export const ENGINE_TYPE_LIST = 'ENGINE_TYPE_LIST';
export const AIRCRAFT_ENGINE_LIST = 'AIRCRAFT_ENGINE_LIST';
export const ENGINE_RATIO_LIST = 'ENGINE_RATIO_LIST';
export const UPDATE_FINANCE_METRIX = 'UPDATE_FINANCE_METRIX';
export const UPDATE_LESSOR_ID = 'UPDATE_LESSOR_ID';
export const UPDATE_AIRFRAME_FIELD = 'UPDATE_AIRFRAME_FIELD';
export const UPDATE_ENGINE_FIELD = 'UPDATE_ENGINE_FIELD';
export const UPDATE_AIRFRAME_MRDETAIL = 'UPDATE_AIRFRAME_MRDETAIL';
export const UPDATE_ENGINE_MRDETAIL = 'UPDATE_ENGINE_MRDETAIL';
export const UPDATE_ENGINE_WS = 'UPDATE_ENGINE_WS';
export const LLP_PART_LIST = 'LLP_PART_LIST';
export const BREAKUP_LLP_MR_COST = 'BREAKUP_LLP_MR_COST';
export const BREAKUP_ENGINE_MR_COST = 'BREAKUP_ENGINE_MR_COST';
export const BREAKUP_ENGINE_MR_COST_SUCCESS = 'BREAKUP_ENGINE_MR_COST_SUCCESS';
export const UPDATE_BREAKUP_ENGINE_MR_COST = 'UPDATE_BREAKUP_ENGINE_MR_COST';
export const LOGS_LOADER = 'LOGS_LOADER'
export const LOGS_LIST = 'LOGS_LIST';
export const LOG_DEL_SUCCESS = 'LOG_DEL_SUCCESS';
export const LOG_FILTER_UPDATE = 'LOG_FILTER_UPDATE';
export const LOG_SORT_UPDATE = 'LOG_SORT_UPDATE';
export const LLP_TO_BE_REPLACED =  'LLP_TO_BE_REPLACED';
export const SELECT_LLP_TO_REPLACE = 'SELECT_LLP_TO_REPLACE';
export const SELECT_LLP_TO_REPLACE_LOADER = 'SELECT_LLP_TO_REPLACE_LOADER';
export const LLP_REPLACED_SUCCESS = 'LLP_REPLACED_SUCCESS';
export const UPDATE_SELECT_LLP_TO_REPLACE = 'UPDATE_SELECT_LLP_TO_REPLACE';
export const CUSTOM_THE_LLP_CYCLE = 'CUSTOM_THE_LLP_CYCLE'
export const UPDATE_CUSTOM_THE_LLP_CYCLE = 'UPDATE_CUSTOM_THE_LLP_CYCLE'
export const COMPARE_CUSTOM_CR = 'COMPARE_CUSTOM_CR';
export const CARRY_OVER_NSV = 'CARRY_OVER_NSV';
export const UPDATE_ENGINE_REVIEW_REPORT_RATIO = 'UPDATE_ENGINE_REVIEW_REPORT_RATIO';
// MR Calculator Loader
export const GEN_FINANCE_LOADER = 'GEN_FINANCE_LOADER';
export const REVIEW_MR_LOADER = 'REVIEW_MR_LOADER';
export const CLEAR_MR_REVIEW_RPT = 'CLEAR_MR_REVIEW_RPT';
export const CLEAR_LOG_FILTER = 'CLEAR_LOG_FILTER';
export const CLEAR_FINANACE_DATA = 'CLEAR_FINANACE_DATA';
export const USE_CDB_ALGO = 'USE_CDB_ALGO';
export const CUSTOM_REGION_CONDITION = 'CUSTOM_REGION_CONDITION';
export const LLP_EXP_LOADER='LLP_EXP_LOADER';

export const MR_USAGE = 'MR_USAGE'
