import React, { Component } from 'react';
import moment from 'moment';
export default class MonthSidebar  extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { monthList, customClass } = this.props;
      return(
        <div className="simulation-month-sidebar item-block">
          <div className="row-header">
            {/* <div className={customClass}>
              <p className="para-ui ">
              Month
              </p>
            </div> */}
            {
            monthList.map((date, index) =>
            <div key={index} className="item-detail-block">
              <p className="para-ui month-detail">
                <span className="date-block">{ date ?   moment(date).format('MMM YYYY') : '-'}</span>
              </p>
            </div>
            )}
          </div>
        </div>
      )
  }
}
