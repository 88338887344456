import React from 'react';
const GrpEventTopHd = () => {
  return(
    <ul className="list-inline grp-event-hd">
      <li className="list-inline-item" style={{width: '140px'}}>Maintenance Events</li>
      <li className="list-inline-item" style={{width: '140px'}}>MR Rate</li>
      <li className="list-inline-item">MR Rate Escalation</li>
      <li className="list-inline-item">Event Cost Escalation</li>
    </ul>
  )
}
export default GrpEventTopHd;
