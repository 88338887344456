import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements'
export default class EquipmentTab extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { equipment, index, asset_slug } = this.props;
    const equipmentObj = [
      {value: equipment.equipment},
      {value: equipment.part_number},
      {value: equipment.serial_number},
      {value: equipment.manufacturer},
      {value: equipment.equipment_type},
    ]
    return (
      <tr onClick={() => this.props.viewEquipment(asset_slug, equipment.id, index, 'equipmentView')}>
         {equipmentObj.map((equipment,index) =>
           <ContrListCol key={index} colObj={equipment}   />
        )}
      </tr>
    )
  }
}
