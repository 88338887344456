import * as actions from '../actions';
export default function( state = {
  componentInspectSideBar:false,
  boroscopeInspDet: {},
  boroscopeLoader: false,
  editBoroscpPicCrud: {
    flag:false,
    data: {
      borescope_details: {},
      components_inspected: []
    }
  },
  error: []
}, action ){
  switch (action.type) {

    case actions.COMPONENT_INSPECT_BAR:
      return {
        ...state,
        componentInspectSideBar:!state.componentInspectSideBar
      }
    case actions.GET_BOROSCOPE_INSP_DTLS:
    return {
      ...state,
      boroscopeInspDet: action.payload
    }
    case actions.UPDATE_FORM:
    if(action.payload.sectionType == 'genDetails'){
        return{
        ...state,
        boroscopeInspDet: {
          ...state.boroscopeInspDet,
          borescope_details: {
            ...state.boroscopeInspDet.borescope_details,
            [action.payload.type]: action.payload.value
          }
        }
      }
    }
    if(action.payload.sectionType == 'componentInspected'){
      return{
        ...state,
        boroscopeInspDet: {
          ...state.boroscopeInspDet,
         components_inspected : [...state.boroscopeInspDet.components_inspected].map((component, compoIndex) =>  compoIndex == action.payload.index ?  {
          ...component,
          [action.payload.type]: action.payload.value
         }: component )
        }
      }
    }

    if(action.payload.sectionType == 'inspectionPicture'){
      return{
        ...state,
        boroscopeInspDet: {
          ...state.boroscopeInspDet,
          inspection_pictures : [...state.boroscopeInspDet.inspection_pictures].map((inspDetail, compoIndex) =>  compoIndex == action.payload.index ?  {
          ...inspDetail,
          [action.payload.type]: action.payload.value,
          // order: action.payload.index + 1
        }: inspDetail )
        }
      }
    }
    case actions.UPLOAD_IMAGE:
     // let order = [...state.boroscopeInspDet.inspection_pictures] && [...state.boroscopeInspDet.inspection_pictures].length ? [...state.boroscopeInspDet.inspection_pictures].length + 1: 1 ;
     return{
        ...state,
        boroscopeInspDet: {
          ...state.boroscopeInspDet,
          inspection_pictures : [ ...state.boroscopeInspDet.inspection_pictures ,...action.payload]
        }
      }
    case actions.DELETE_COMPONENT:
      return{
        ...state,
        boroscopeInspDet: {
          ...state.boroscopeInspDet,
        components_inspected : [...state.boroscopeInspDet.components_inspected].filter((component, compoIndex) =>  compoIndex != action.payload)
      }
    }
   case actions.ADD_DYNAMIC_COMPONENT:
   return{
    ...state,
    boroscopeInspDet: {
      ...state.boroscopeInspDet,
    components_inspected : [...state.boroscopeInspDet.components_inspected,action.payload]
   }
  }
  case actions.BOROSCOPE_DETLS_LOADER:
    return {
      ...state,
      boroscopeLoader: action.payload
    }
  case actions.EDIT_INSPECTION_IMAGE:

    return {
      ...state,
      editBoroscpPicCrud: action.payload
    };
   case actions.DELETE_BORSCP_IMAGE:
    return{
      ...state,
      boroscopeInspDet: {
        ...state.boroscopeInspDet,
        inspection_pictures : [...state.boroscopeInspDet.inspection_pictures].filter((inspDetail, picIndex) =>  picIndex != action.payload )
      }
    }
   case actions.SORT_PICTURES:
    return{
      ...state,
      boroscopeInspDet: {
        ...state.boroscopeInspDet,
        inspection_pictures : action.payload.map((inspDetail, index)  =>   index >= 0 ? {
          ...inspDetail,
          order : index+1
        } : inspDetail )
      }
    }
   case actions.ERROR_MESSAGE:
    return{
      ...state,
      error: action.payload
    }
    case actions.CLEAR_ERR_MESSGAE:
    if(action.payload.sectionType == 'genDetails'){
      return{
        ...state,
        error: state.error.map((dataGenDet, index) => index == 0 ?
          [...dataGenDet].map((data, index) => data && Object.keys(data).includes(action.payload.type) ? {
            ...data,
            [action.payload.type] : ''
          } : data
        )
        : dataGenDet )
      }
    }

    if(action.payload.sectionType == 'componentInspected'){
      return{
        ...state,
        error: [...state.error].map((compoDet, compIndex) => compIndex == 1 ?
          [...compoDet].map((data, index) => index == action.payload.index  ? {
            ...data,
            [action.payload.type] : ''
          } : data
        )
        : compoDet )
      }
    }

    default:
      return state;

  }
}
