import React, { Component } from 'react';
import { Link } from 'react-router';
import { Collapse } from 'reactstrap';
import CKeditor from "../../../../../shared/CKEditor";
import { trackActivity } from '../../../../../utils/mixpanel';
class ReviewCollapseMr extends Component{
  constructor(props){
    super(props);
    this.state={
        reviewflag: false,
        
      }
  }

  toggleReviewCollapse = () => {
    this.setState({
        reviewflag: !this.state.reviewflag,
    });
  }

  updateCkEditer = (evt) =>{
    var newContent = evt.editor.getData();
    this.props.updateReviewCollapse({type: 'content', value:newContent, sectionType: 'section', index:this.props.index});
  }
   

  render(){
    const { review, index, formInfo,reviewIndex } = this.props;
    return(
      <div className="collapse-editor" style={{marginBottom:'20px'}} >
      <div>
        <div className="collapse-header">
        {reviewIndex? null:
          <input type="text" onChange={(evt) => this.props.updateReviewCollapse(this.props.type, 'index', evt.target.value, index)} className="review-index" value={review.index}/>
        }
          <input type="text" onChange={(evt) => this.props.updateReviewCollapse({type: 'title', value:evt.target.value, sectionType: 'section', index:this.props.index})} className="review-title" value={review.title}/>
          <Link onClick={this.toggleReviewCollapse}><span className="fa fa-angle-down"></span></Link>
        </div>
        {this.props.error && this.props.error.title ? <h6 className="error-msg" >{this.props.error.title}</h6> : null}
        </div>
        <Collapse isOpen={this.state.reviewflag}>
          <CKeditor
            events={{"change": this.updateCkEditer}}
            content={review && review.content ? review.content : ''}
          />
          { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
            <Link className="remove-review-section" onClick={()=>{this.props.removeReviewCollapse(); trackActivity('Section Removed', { page_title: 'TE006', application_module: 'Inspection',  item_type:'MR Claim & Invoice Review'})}}><i className="fa fa-trash"></i> Remove this section</Link>:null
          }

        </Collapse>
      </div>
    )
  }
}

export default ReviewCollapseMr
