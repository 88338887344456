import React from 'react';
import moment from 'moment'
import { browserHistory, Link } from 'react-router'
import { displayDateTimeFormatShort, notificationTypeUrls } from '../../../constants'

const NotificationList = (props) => {
  const notiTypes = [4,11,13,14,16,17,18, 19, 20, 21]
  const modalNotiTypes = [11,13,14,16,17,18, 19, 20, 21]
  const triggerModal = () => {
    if(props.notification.notification_type && (props.notification.notification_type.value == 4 || props.notification.notification_type.value == 10)){
      browserHistory.push(`${notificationTypeUrls[props.notification.notification_type && props.notification.notification_type.value ? props.notification.notification_type.value : '']}${props.notification.action_id}`)
    }else{
      if(props.notification.notification_type && props.notification.notification_type.value == 9 && props.notification.request_access && props.notification.request_access.verification_status.value == 0){
        props.getRequestModal()
      }else{
        if(props.notification.notification_type && modalNotiTypes.includes(props.notification.notification_type.value)){
          props.getWelcomeNote()
        }
      }
    }
    if(props.notification.status != 1){
      props.markNotificationRead()
    }
  }
    return(
        <tr>
            <td onClick={() => triggerModal()} className={props.notification.status == 1 ?'read' : ''}>
                {props.notification.notification_type ? props.notification.notification_type.label : '--'}
            </td>
            <td onClick={() => triggerModal()} className={props.notification.status == 1 ?'read' : ''}>

                  <span>{props.notification.subject ? props.notification.subject : props.notificationTypes ? props.notificationTypes.label : ''}
                    {
                      props.notification.notification_type && (notiTypes.includes(props.notification.notification_type.value)) ?
                        <Link className="aims-primary-link"> Read more</Link>
                      :null
                    }
                  </span>
            </td>
            <td onClick={() => triggerModal()} className={props.notification.status == 1 ?'read' : ''}>
                {moment(props.notification.created_at).format('MMM DD, YYYY hh:mm A')}
            </td>
            <td>
              {
                props.notification.status == 1 ?
                <span className="read">Read</span>
                :<span className="mark-read" onClick={() => props.markNotificationRead()}>Mark as Read</span>
              }
            </td>
        </tr>
    )
}
export default NotificationList
