import React, { Component } from 'react';
import { Link } from 'react-router';
import PopoverItem  from './PopoverItem';
import AssetPopover  from './AssetPopover';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { imgStoragePath } from '../../../../../constants';
import { trackActivity } from '../../../../../utils/mixpanel';
export default class AuditCheckList extends Component {
  constructor(props){
    super(props);
    this.state={
      popoverOpen: false,
      value: 0,
      remarks: '',
      crudType: 'add',
      remarkErr: '',
      error: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    const data = Object.assign({}, this.props.list)
    this.setState({
      remarks: data.answer && data.answer.remarks != '' ? data.answer.remarks:'',
      value: data.answer && data.answer.value ? data.answer.value : '',
      crudType: data.answer && Object.keys(data.answer).length ? 'edit' : 'add'
    });
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      crudType: nextProps.list.answer && Object.keys(nextProps.list.answer).length ? 'edit' : 'add'
    });
  }
  handleChange(value) {
    this.setState(prevState => ({
      ...prevState,
      remarks: value
    }));
    if(value){
      this.setState(prevState => ({
        ...prevState,
        remarkErr: ''
      }));
    }
  }
  changeStatus = (value,listindex,id) => {
    this.setState(prevState => ({
      ...prevState,
      value: value,
      remarkErr: '',
      error: false
    }))
    if((value == 2 || value == 3) && !(this.state.remarks && this.state.remarks.length)) {
      this.setState(prevState => ({
        ...prevState,
        value: value,
        remarkErr: 'Please enter remarks',
        error: false
      }))
    }
    this.props.changeStatus(value);

    setTimeout(() => this.state.remarkErr==''?this.props.saveData(this.state.crudType, listindex, id):null, 300);
  }

  changeRemark = (value,checkValue,listindex,id) =>{
    if(value==='' && checkValue != 1){
      this.setState(prevState =>({
        remarkErr: 'Please enter remarks',
      }))
      return
    }
    this.props.changeRemark(value);

    if(checkValue==undefined){
      this.setState(prevState =>({
        error: true
      }))
    }else{
      setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300);
      this.setState(prevState =>({
        error: false
      }))
    }
  }

  fileSelectFn = (files) => {
    if(!files.length){
      return false;
    }
    this.props.saveData(this.state.crudType, this.props.listindex, this.props.list.id, files, [], [] )
  }

  deleteFiles = (id, fileArr) => {
    const existFileArr= fileArr.filter(data => data.id != id);
    this.props.saveData('delete', this.props.listindex, this.props.list.id, [], existFileArr)
  }
  render(){
    const{ listindex , list, id, auditLoader, formInfo, section } = this.props;
    return(
      <tr style={list.appearance ? {} :{background: 'rgb(249 249 249)'}}>
        <td style={{width: '28%'}}>
          <h4>{list.index_number ? list.index_number + ' ' : ''}{list.name != '' ? list.name:'--'}</h4>
          <div>
            {list.appearance && section.name.toLowerCase() != 'photos' && section.name.toLowerCase() != 'contacts' ? <ul className="list-inline attach-files">
                <li className="list-inline-item" style={{marginTop: '10px', width: '100%'}}>
                  <label style={{fontSize: '12px', color: '#8c9ba5'}}>Folder/File Path</label>
                </li>
            </ul>:null}
          </div>
          { list.notes!=null && list.notes!='' ?
            <p style={{fontSize:'11px',fontStyle:'italic',color:'#717171'}}>Note: {list.notes}</p>:null
          }
        </td>
        <td style={{width: '12%'}}>
          {list.appearance && <div>
            <Select
              className="custom-select-block"
              name="form-field-name"
              value={list.answer.received_status}
              options={[{label: 'Yes', value: 1}, {label: 'No', value: 3}, {label: 'NA', value: 2}]}
              onChange={(target) => {this.props.changeValues('received_status',target?target.value:'');setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300)}}
            />
          </div>}
        </td>
        <td style={{width: '12%'}}>
          {list.appearance && <div>
            <Select
              className="custom-select-block"
              name="form-field-name"
              value={list.answer.document_status}
              options={[{label: 'Open', value: 1}, {label: 'Close', value: 0}]}
              onChange={(target) => {this.props.changeValues('document_status',target?target.value:'');setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300)}}
            />
          </div>}
        </td>
        <td style={{width: '12%'}}>

            {list.appearance && <div>
                <textarea value={this.state.remarks}
                className={ this.state.remarkErr.length ? 'mandatory' : 'no mandatory '+this.state.value }
                rows="6"
                style={{borderBottom: '1px solid #828282', display: 'block', width: '100%'}}
                onChange={(e) => this.handleChange(e.target.value)}
                onBlur={(evt) =>this.changeRemark(evt.target.value,list.answer.value,listindex,list.id)}
               >{list.answer.remarks}</textarea>
             {
               this.state.error ?
                <h6 className="error-msg">Please update status before entering remark</h6>
               :null
             }
           </div>}


            <h6 className="error-msg">{this.state.remarkErr}</h6>
         </td>
         <td style={{width: '12%'}}>
           {list.appearance && <div>
             <Select
               className="custom-select-block"
               name="form-field-name"
               value={list.answer.acumen_status}
               options={[{label: 'Yes', value: 1}, {label: 'No', value: 3}, {label: 'NA', value: 2}]}
               onChange={(target) => {this.props.changeValues('acumen_status',target?target.value:'');setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300)}}
             />
           </div>}
         </td>
         <td style={{width: '12%'}}>
           <textarea value={list.answer.folder_path} onChange={(event) => {this.props.changeValues('folder_path',event.target.value)}} placeholder="File Path" style={{borderBottom: '1px solid #828282', display: 'block', width: '100%'}} type="text"
             onBlur={() => setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300)}
             placeholder="E1/New Folder/Test.pdf"
             >{list.answer.folder_path}</textarea>
         </td>
        <td style={{width: '12%'}}>
                {list.appearance && <textarea
                className={ this.state.remarkErr.length ? 'mandatory' : 'no mandatory '+this.state.value }
                rows="6"
                style={{borderBottom: '1px solid #828282', display: 'block', width: '100%'}}
                onChange={(evt) => this.props.changeValues('acumen_response', evt.target.value)}
                onBlur={(evt) =>{setTimeout(() => this.props.saveData(this.state.crudType, listindex, id), 300)}}
               >{list.answer.acumen_response}</textarea>}
         </td>
      </tr>
    )
  }
}
