import React, { Component } from 'react';

class ContentRow extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <tr onClick={this.props.onClick}>
        <td>
          1
        </td>
        <td>
          Noise Certificate
        </td>
        <td>
          Open
        </td>
        <td>
          14 Nov 2018
        </td>
        <td>
          14 Nov 2018
        </td>
        {
          this.props.type == "engine" ?
          <td>
            14 Nov 2018
          </td>
          :null
        }
        {
          this.props.type == "engine" ?
          <td>
            14 Nov 2018
          </td>
          :null
        }
        {
          this.props.type == "engine" ?
          <td>
            14 Nov 2018
          </td>
          :null
        }
        {
          this.props.type == "engine" ?
          <td>
            14 Nov 2018
          </td>
          :null
        }
        <td>
          <table>
            <tbody>
              <tr>
                <td>
                  Test
                </td>
                <td>
                  Test
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          Testing
        </td>
        <td>
          Image
        </td>
        <td>
          Priority
        </td>
      </tr>
    )
  }
}

export default ContentRow;
