import * as actions from '../actions';
import { robberyObj }from '../../AddFormObjects'
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../../../globalServices/';
import { toastFlashMessage, removeEmptyKey, downloadFileType } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { browserHistory } from 'react-router';
import { trackActivity } from '../../../../../utils/mixpanel';
export const robberyListAc = (props, queryParam={}) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/robbery-list/',queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ROBBERY_LIST,
          payload: response.data.data
        });
      }
      dispatch(triggerLoader(false));
    })
  }
}

export const robberyViewCrudAc = (props, robberyCrud, id=null) => {
  return (dispatch) => {
    if(robberyCrud.type == 'add'){
      dispatch({
        type: actions.TOGGLE_SIDE_BAR_T009,
        payload: {type: 'add', data: robberyObj, flag: robberyCrud.flag}
      })
    }else{
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/robbery-list/'+robberyCrud.data.id + '/', {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          trackActivity('Robbery Viewed', {
            page_title: `T009`,
            application_module: 'Inspection',
            item_type:'Robbery List',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            item_id: robberyCrud.data.id
          })
          dispatch({
            type: actions.TOGGLE_SIDE_BAR_T009,
            payload:{flag: true, data: response.data.data, type: robberyCrud.type}
          })
        }
      })
    }
  }
}

export const robberyAddEditAc = (props, data, type) => {
  delete data['work_order'];
  data = {
      ...data,
      work_order: props.params.workOrderSlug
    }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if(type == 'add'){
      globalPostService('technical/workorder/'+props.params.workOrderSlug+'/robbery-list/', data)
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Robbery Added', {
            page_title: `T009`,
            application_module: 'Inspection',
            item_type:'Robbery List',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            item_id: data.id
          })
          dispatch({
            type: actions.ADD_ROBBERY,
            payload: response.data.data
          })
        }
      })
    }else{
      globalPutService('technical/workorder/'+props.params.workOrderSlug+'/robbery-list/' + data.id + '/', data)
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Robbery Edited', {
            page_title: `T009`,
            application_module: 'Inspection',
            item_type:'Robbery List',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            item_id: data.id
          })
          dispatch({
            type: actions.UPDATE_ROBBERY,
            payload: response.data.data
          })
        }
      })
    }
    dispatch(getFormDetailsAc(props));
  }
}

export const robberyDeleteAc= (props, ids, type) => {
  let location = browserHistory.getCurrentLocation();
  location = {
    ...location,
    query: {
      page:1
    }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/robbery-list/', {ids: ids})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        trackActivity('Robbery Deleted', {
          page_title: `T009`,
          application_module: 'Inspection',
          item_type:'Robbery List',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          item_id: ids
        })
        dispatch({
          type: actions.DELETE_ROBBERY,
          payload: ids
        });
        if(type == 'bulk'){
          browserHistory.push(location);
          dispatch(robberyListAc(props));
        }
      }
      dispatch(getFormDetailsAc(props));
    })
  }
}
export const robberyListExportAc = (props, fileType=null,name, query={}) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalExportService('technical/workorder/'+props.params.workOrderSlug+'/robbery-list/', {...query, download: fileType})
    .then(response => {
      dispatch(triggerLoader(false));
      downloadFileType(response, name+'.', fileType)
      trackActivity('Rerort Exported', {
        page_title: `T009`,
        application_module: 'Inspection',
        item_type:'Robbery List',
        workorder_slug: props.params.workOrderSlug,
        form_slug: props.params.formSlug,
        file_type: fileType
      })
    })
  }
}

export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.ROBBERY_LOADER,
      payload: flag
    });
  }
}
