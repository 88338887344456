import React, { Component } from 'react';
import { Row, Col, Progress,Collapse,  Card, CardBody } from 'reactstrap';
import { Link } from 'react-router';
import moment from 'moment';
import { ToolTipHover } from '../../shared';
import {  CollapseWrapper  } from '../../applications/technicalInspection/Elements';
import { displayDateFormat,displayDateTimeFormat, imgStoragePath,displayDateFormatCard,displayDateFormatCardYear,displayDateFormatCardYearN } from '../../constants';
import { getAccessToken, getLocalStorageInfo, getFileSize } from '../../utils';
export default class OtherPlatform extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
        this.state = {
             collapse: false,
             };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
   }
  checkdates=(date1,date2)=>{
    if(date2!='' && date2!=null){
     if( moment(date1).format('YYYY') == moment(date2).format('YYYY')){
       return moment(date1).format(displayDateFormatCard)+' - '+moment(date2).format(displayDateFormatCard)+' '+moment(date1).format(displayDateFormatCardYear)
     }else{
      return moment(date1).format(displayDateTimeFormat)+' - '+moment(date2).format(displayDateTimeFormat)
     }
    }else if(date1!=''){
      return moment(date1).format(displayDateTimeFormat)
    }else{
      return '--'
    }

  }
  render() {
    const { assetView, params } = this.props;
    let contractUrl = '';
    let contactAIUrl = '';
    if(assetView.is_contract_enabled){
      contractUrl = Object.keys(assetView.contract).length ? '/' + params.type + '/'+params.aircraft_slug+'/contract/dashboard': '/'+params.type+'/'+ params.aircraft_slug +'/contracts';
      contactAIUrl=Object.keys(assetView.contract).length ? '/' + params.type + '/'+params.aircraft_slug+'/contract/' + assetView.contract.slug +'/extract-file-list' : '/'+params.type+'/'+ params.aircraft_slug +'/contracts';
    }else{
      contractUrl = '/asset-view/' + params.type + '/' + params.aircraft_slug;
      contactAIUrl=contractUrl;
    }
    return(
      <div className="aims-different-product-list">
        <Row>
          <Col md="6">
            <Link to={getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && ![6,7,8,9].includes(assetView.asset_type) ? assetView.technical.project && assetView.technical.form_slug ? '/'+params.type+'/'+params.aircraft_slug+'/technical/'+ assetView.technical.work_order_slug +'/forms/'+ assetView.technical.form_slug+'/'+ assetView.technical.form: '/technical/projects?project_status=all' :''}>
              <div className="platform-card" style={ getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && ![6,7,8,9].includes(assetView.asset_type) ? {opacity: 1} : {opacity: 0.6}}>

                <div className="progress-meter">
                  <h4><img src={imgStoragePath+"technical_icn.png"} alt="icon" /> Technical</h4>
                  <p className="platform-progress">
                    <span style={{width: '0%'}}></span>
                  </p>
                </div>
                <div className="ptl-info">
                  <Row>
                    <Col xs="6">
                      <span>Project Name</span>
                      <h6 className="para-ui" id={'asset-project-name'}>
                        { getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && assetView.technical.project ? assetView.technical.project.name.length>18?assetView.technical.project.name.substring(0,15)+'...':assetView.technical.project.name :'--'
                        }
                      </h6>
                      { getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && assetView.technical.project && assetView.technical.project.name ?
                      <ToolTipHover placement="top" tagetId={'asset-project-name'}>{assetView.technical.project.name}</ToolTipHover>:null}
                    </Col>
                    <Col xs="6">
                      <span>Project Lead</span>
                      <h6 className="para-ui" >
                        { getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical  && assetView.technical.project && assetView.technical.project.lead != '' ? assetView.technical.project.lead:'--'
                        }
                      </h6>
                    </Col>

                  </Row>
                </div>
                <div className="ptl-info">
                  <Row>
                    <Col xs="6">
                      <span>Location</span>
                      <h6 className="para-ui" id={'asset-tech-location'}>
                        {
                          getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && assetView.technical.project && assetView.technical.project.location != '' ? assetView.technical.project.location.length>23?assetView.technical.project.location.substring(0,20)+'...':assetView.technical.project.location:'--'
                        }
                      </h6>
                      {getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical  && assetView.technical.project && assetView.technical.project.location && assetView.technical.project.location != '' ?
                      <ToolTipHover placement="top" tagetId={'asset-tech-location'}>{ assetView.technical.project.location}</ToolTipHover>:null}
                    </Col>
                    <Col xs="6">
                      <span>Insp. Scheduled Date</span>
                      <h6 className="para-ui">

                       { this.checkdates(getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && assetView.technical.project && assetView.technical.project.schedule_start_date != '' ? assetView.technical.project.schedule_start_date:'',
                       getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled  && assetView.technical.project && assetView.technical.project.schedule_end_date != '' ?assetView.technical.project.schedule_end_date :'')}

                      </h6>
                    </Col>
                  </Row>
                </div>
                <div className="ptl-info" style={{display:'none'}}>
                  <Row>
                    <Col xs="6">
                      <span>Insp. Scheduled Start Date</span>
                      <h6 className="para-ui">
                        { getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical  && assetView.technical.project && assetView.technical.project.schedule_start_date != '' ?
                          moment(assetView.technical.project.schedule_start_date).format(displayDateFormat):'--'
                        }
                      </h6>
                    </Col>
                    <Col xs="6">
                      <span>Insp. Scheduled End Date</span>
                      <h6 className="para-ui">
                        { getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled && assetView.technical && assetView.technical.project && assetView.technical.project.schedule_end_date != '' ?
                          moment(assetView.technical.project.schedule_end_date).format(displayDateFormat):'--'
                        }
                      </h6>
                    </Col>
                  </Row>
                </div>
                {
                  !(getLocalStorageInfo().user.permission.technical && assetView.is_technical_enabled) ?
                  <div className="plt-last-activity">
                    <ul className="list-inline">
                      <li>
                        <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                        Please contact support@sparta.aero to avail this service
                      </li>
                    </ul>
                  </div>:null
                }
              </div>
            </Link>
          </Col>
          <Col md="6">

            <Link to={(getLocalStorageInfo().user.permission.records && (assetView.is_records_enabled &&  assetView.records.template_sheet)) ? '/'+params.type+'/'+params.aircraft_slug+'/records/' +(getLocalStorageInfo().defaultLessor.id !== 348 && getLocalStorageInfo().user.permission.records.dashboard ? 'dashboard': 'dataRoom'):''}>
              <div className="platform-card" style={ (getLocalStorageInfo().user.permission.records && (assetView.is_records_enabled &&  assetView.records.template_sheet)) ? {opacity: 1} : {opacity: 0.6}}>
                <div className="progress-meter">
                  <h4><img src={imgStoragePath+"records_icn.png"} alt="icon" /> Records</h4>
                  <p className="platform-progress">
                    <span style={{width: '0%'}}></span>
                  </p>
                </div>
                <div className="ptl-info">
                  <Row>
                    <Col xs="6">
                      <span>No. of Files</span>
                      <h6 className="para-ui">
                        { assetView.records.total_files ? assetView.records.total_files :'--'}
                      </h6>
                    </Col>
                    <Col xs="6">
                      <span>Attention Reqd.</span>
                      <h6 className="para-ui">
                        { (getLocalStorageInfo().user.permission.records && assetView.is_records_enabled) ?
                          assetView.records.technical_records && assetView.records.technical_records.issue_count ? assetView.records.technical_records.issue_count + ' Issues':0:'--'
                        }
                      </h6>
                    </Col>
                  </Row>
                </div>
                <div className="ptl-info">
                  <Row>
                    <Col xs="6">
                      <span>Total Records Size</span>
                      <h6 className="para-ui">
                        { assetView.records.total_files ? getFileSize(assetView.records.total_size) :'--'}
                      </h6>
                    </Col>
                  </Row>
                </div>
                { !(getLocalStorageInfo().user.permission.records && assetView.is_records_enabled) ?
                  <div className="plt-last-activity">
                    <ul className="list-inline">
                       <li>
                         <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                          Please contact support@sparta.aero to avail this service
                       </li>
                    </ul>
                  </div> :
                  !assetView.records.template_sheet ?
                  <div className="plt-last-activity">
                    <ul className="list-inline">
                       <li>
                         <img width="15" src={imgStoragePath+"info_icon.png"} alt="img"/>
                         Please Upload Template
                       </li>
                     </ul>
                   </div>:null
                }
              </div>
            </Link>
          </Col>
          <Col md="6">
            <Link to={(getLocalStorageInfo().user.permission.maintenance && assetView.is_maintenance_enabled && ![6, 7, 8, 9].includes(assetView.asset_type)) ? '/' +params.type+ '/' +params.aircraft_slug+ '/maintenance/claims' : ''}>
              <div className="platform-card" style={getLocalStorageInfo().user.permission.maintenance && assetView.is_maintenance_enabled && ![6, 7, 8, 9].includes(assetView.asset_type) ? {opacity: 1}: {opacity: '0.6'}}>
                <div className="progress-meter">
                  <h4><img src={imgStoragePath+"maintanenace_icn.png"} alt="icon" /> Maintenance</h4>
                  <p className="platform-progress">
                    <span style={{width: '0%'}}></span>
                  </p>
                </div>

                <div className="ptl-info">
                  <Row>
                    <Col xs="12">
                      <span>No of Events Approaching in next 6 months</span>
                      <h6 className="para-ui">{assetView.maintenance && assetView.maintenance.upcoming_events_count != null && assetView.maintenance.upcoming_events_count != undefined?assetView.maintenance.upcoming_events_count:'--' }</h6>
                    </Col>
                  </Row>
                </div>
                <div className="ptl-info">
                  <Row>
                    <Col xs="12">
                      <span>Open Maintenance claims</span>
                      <h6 className="para-ui">{assetView.maintenance && assetView.maintenance.open_claims_count!= null && assetView.maintenance.open_claims_count!= undefined ?assetView.maintenance.open_claims_count:'--' }</h6>
                    </Col>
                  </Row>
                </div>
                {
                  !(getLocalStorageInfo().user.permission.maintenance && assetView.is_maintenance_enabled) ?
                  <div className="plt-last-activity">
                    <ul className="list-inline">
                      <li>
                        <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                        {assetView.maintenance_msg?assetView.maintenance_msg:'Please contact support@sparta.aero to avail this service'}
                      </li>
                    </ul>
                  </div>:null
                }
              </div>
            </Link>
          </Col>
          <Col md="6">
            <Link to={ getLocalStorageInfo().user.permission.contracts ? contractUrl : ''}>
            <div className="platform-card" style={getLocalStorageInfo().user.permission.contracts && assetView.is_contract_enabled ? {opacity: 1}: {opacity: '0.6'}}>
              <div className="progress-meter">
                <h4><img src={imgStoragePath+"contracts_icn.png"} alt="icon" /> Contracts</h4>
                <div className="platform-progress">
                  {getLocalStorageInfo().user.permission.contracts && assetView.is_contract_enabled ?
                    <Progress
                      max="100"
                      value={100-(moment(assetView.contract.contractual_end_date).diff(moment(),'months')/moment(assetView.contract.contractual_end_date).diff(moment(assetView.contract.contractual_start_date),'months')) * 100}
                      color="#ffffff"
                    />:null
                  }
                </div>
              </div>
              <div className="ptl-info">
                <Row>
                  <Col xs="6">
                    <span>Start Date</span>
                    <h6 className="para-ui">
                      { getLocalStorageInfo().user.permission.contracts && Object.keys(assetView.contract).length ?
                        moment(assetView.contract.contractual_start_date).format(displayDateFormat) : '--'
                      }
                    </h6>
                  </Col>
                  <Col xs="6">
                    <span>End Date</span>
                    <h6 className="para-ui">
                      {getLocalStorageInfo().user.permission.contracts && Object.keys(assetView.contract).length ?
                        moment(assetView.contract.contractual_end_date).format(displayDateFormat) : '--'
                      }
                    </h6>
                  </Col>
                </Row>
              </div>
              <div className="ptl-info">
                <Row>
                  <Col xs="12">
                    <span>Lessee</span>
                    <h6 className="para-ui">
                      { getLocalStorageInfo().user.permission.contracts && Object.keys(assetView.contract).length && assetView.contract.lessee ?
                        assetView.contract.lessee.name : '--'
                      }
                    </h6>
                  </Col>
                </Row>
              </div>
              {
                !(assetView.is_contract_enabled && getLocalStorageInfo().user.permission.contracts) ?
                <div className="plt-last-activity">
                  <ul className="list-inline">
                    <li>
                      <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                      Please contact support@sparta.aero to avail this service
                    </li>
                  </ul>
                </div>:null
              }
            </div>
            </Link>
          </Col>
        </Row>

        <div className="colapsable-wrapper" style={ assetView.asset_type === 1 && getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id !== 348 ? {marginTop: '20px'} : {display: 'none'}}>
          <h6 className="form-collapse-title" style={{color:'#fff',fontSize:'16px',marginBottom:'0px',fontWeight:'bolder',height:'50px'}}  onClick={this.toggle}>
                    AI Assistant
                    <img  style={{width:'20px',float:'left',marginRight:'5px'}} src={imgStoragePath + 'ai_icon.png'} alt="img"/>
                   <img  style={this.state.collapse ? { transform: 'rotate(0deg)',width:'16px',float:'right',marginTop:'5px'} : { transform: 'rotate(180deg)',width:'16px',float:'right',marginTop:'5px' }} src={imgStoragePath + 'arrow-up.png'} alt="img"/>
                </h6>
          <Collapse isOpen={this.state.collapse}>
          <Card>
           <CardBody>
              <Row>
                {
                  <Col md="6">
                      <Link to={getLocalStorageInfo().user.permission.contracts && !getLocalStorageInfo().user.permission.contracts.is_saas && assetView.is_contract_enabled ? contactAIUrl : null}>
                        <div className="platform-card" style={getLocalStorageInfo().user.permission.contracts && !getLocalStorageInfo().user.permission.contracts.is_saas && assetView.is_contract_enabled ? {opacity: 1, height: '90px'}: {opacity: '0.6', height: '110px'}}>
                          <div className="progress-meter">
                            <h4 style={{lineHeight:'19px'}} ><img style={{marginBottom: '30px', marginRight: '8px', marginTop: '4px', float: 'left'}} src={imgStoragePath+"contracts_icn.png"} alt="icon" /> Lease Agreement Auto Filler</h4>
                            <p style={{fontSize: '10px',color: '#d7d7d7',marginTop:'-8px'}}>
                              Upload lease agreement to automatically extract and update the system with contractual data
                            </p>
                          </div>
                          {
                            !(getLocalStorageInfo().user.permission.contracts && !getLocalStorageInfo().user.permission.contracts.is_saas ) ?
                            <div className="plt-last-activity">
                              <ul className="list-inline">
                                <li>
                                  <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                                  Please contact support@sparta.aero to avail this service
                                </li>
                              </ul>
                            </div>:null
                          }
                        </div>
                      </Link>
                    </Col>

                }
                {
                  <Col md="6">
                    <Link to={assetView.is_ad_enabled && getLocalStorageInfo().user.permission.ad_validation && !getLocalStorageInfo().user.permission.ad_validation.is_saas &&
                      getLocalStorageInfo().user.permission.ad_validation.ad_validator &&  getLocalStorageInfo().user.permission.ad_validation.ad_validator.indexOf('R') != -1 ? '/' + params.type + '/' +params.aircraft_slug + '/airworthiness-list':''}>
                      <div className="platform-card" style={assetView.is_ad_enabled && getLocalStorageInfo().user.permission.ad_validation && !getLocalStorageInfo().user.permission.ad_validation.is_saas &&  getLocalStorageInfo().user.permission.ad_validation.ad_validator &&  getLocalStorageInfo().user.permission.ad_validation.ad_validator.indexOf('R') != -1 ? {opacity: 1, height: '90px'}: {opacity: '0.6', height: '110px'}}>
                        <div className="progress-meter">
                          <h4 style={{lineHeight:'19px'}} ><img style={{marginBottom: '30px', marginRight: '8px', marginTop: '4px', float: 'left'}} src={imgStoragePath+"aircraft_model_icon.png"} alt="icon" /> Airworthiness Directive Validation</h4>
                          <p style={{fontSize: '10px',color: '#d7d7d7',marginTop:'-8px'}}>Review Operator AD status report against FAA/EASA &amp; OEM AD Status Report</p>
                        </div>
                        {
                          !(getLocalStorageInfo().user.permission.ad_validation && !getLocalStorageInfo().user.permission.ad_validation.is_saas &&
                            getLocalStorageInfo().user.permission.ad_validation.ad_validator &&  getLocalStorageInfo().user.permission.ad_validation.ad_validator.indexOf('R') != -1 ) ?
                          <div className="plt-last-activity">
                            <ul className="list-inline">
                              <li>
                                <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                                Please contact support@sparta.aero to avail this service
                              </li>
                            </ul>
                          </div>:null
                        }
                      </div>
                    </Link>
                  </Col>

                }
                {
                  assetView.asset_type === 1 ?
                  <Col md="6">
                    <Link to={getLocalStorageInfo().user.permission.records && !getLocalStorageInfo().user.permission.records.is_saas && getLocalStorageInfo().user.permission.auto_bulk_organizer && assetView.is_records_enabled  ? '/' + params.type + '/' +params.aircraft_slug + '/records/auto-organiser-and-renaming':''}>
                      <div className="platform-card" style={getLocalStorageInfo().user.permission.records && !getLocalStorageInfo().user.permission.records.is_saas && getLocalStorageInfo().user.permission.auto_bulk_organizer && assetView.is_records_enabled ? {opacity: 1, height: '90px'}: {opacity: '0.6', height: '110px'}}>
                        <div className="progress-meter">
                          <h4 style={{lineHeight:'19px'}} ><img style={{marginBottom: '30px', marginRight: '8px', marginTop: '4px', float: 'left'}} src={imgStoragePath+"records_icn.png"} alt="icon" /> Auto Bulk Organizer &amp; Renamer</h4>
                          <p style={{fontSize: '10px',color: '#d7d7d7',marginTop:'-8px'}}>Upload unorganized bulk records to automatically organize and rename as per data room template</p>
                        </div>
                        {
                          !(getLocalStorageInfo().user.permission.records && !getLocalStorageInfo().user.permission.records.is_saas && getLocalStorageInfo().user.permission.auto_bulk_organizer ) ?
                          <div className="plt-last-activity">
                            <ul className="list-inline">
                              <li>
                                <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                                Please contact support@sparta.aero to avail this service
                              </li>
                            </ul>
                          </div>:null
                        }
                      </div>
                    </Link>
                  </Col>
                  :null
                }
                {
                  assetView.asset_type === 1 ?
                  <Col md="6">
                    <Link to={'/' + params.type + '/' +params.aircraft_slug + '/techspecs-logs'}>
                      <div className="platform-card" style={{opacity: 1, height: '90px'}}>
                        <div className="progress-meter">
                          <h4 style={{lineHeight:'19px'}} ><img style={{marginBottom: '30px', marginRight: '8px', marginTop: '4px', float: 'left'}} src={imgStoragePath+"contracts_icn.png"} alt="icon" /> Technical Specs Auto Filler</h4>
                        <p style={{fontSize: '10px',color: '#d7d7d7',marginTop:'-8px'}}>Upload technical agreement to automatically extract and update the system with contractual data.</p>
                        </div>
                        {
                          !(getLocalStorageInfo().user.permission.records && !getLocalStorageInfo().user.permission.records.is_saas && getLocalStorageInfo().user.permission.auto_bulk_organizer ) ?
                          <div className="plt-last-activity" style={{display: 'none'}}>
                            <ul className="list-inline">
                              <li>
                                <img width="18px" src={imgStoragePath+"info_icon.png"} alt="img"/>
                                Please contact support@sparta.aero to avail this service
                              </li>
                            </ul>
                          </div>:null
                        }
                      </div>
                    </Link>
                  </Col>
                  :null
                }
              </Row>
           </CardBody>
          </Card>
          </Collapse>
        </div>
      </div>
    )
  }
}
