import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {  GetInputField } from '../../Elements';
import BulkOperationReference from "./BulkOperationReference";
import ReferenceLinkList from "./ReferenceLinkList";
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp,NoRecordFound } from '../../../../shared';


export default class ReferenceLinks  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = () => {

  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row style={{margin: '0px'}}>
          <Col>
          <div className='tableInspec'>
            <TableUIComp
                noStickyHeader={true}
                hover={true}
                tableBulkOp = {<BulkOperationReference addText= "Create" addReferenceLink= {this.props.addReferenceLink}/>}
                filter={false}
                tableHeight= "auto"
                theads={[{label:'Link', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'Uploaded on', sortKey: '' },{label:'Uploaded by', sortKey: ''}, {label: 'Action', sortKey: ''}]}
                bulkOperation={false}
                pagination={false}
                content={content.reference_links && content.reference_links.length ? content.reference_links.map((refrenceDetails, index) => <ReferenceLinkList index={index} shopVisit = {refrenceDetails} key={index} index={index} updateForm = {this.props.updateForm} toggleDelModal={this.toggleDelAttachmentModal}   toggleDelModal={this.props.toggleDelModal} />)  : null} 
                recordsFound = {content.reference_links && content.reference_links.length ?false:true}
              />
          </div>
          </Col>
         </Row> 
   
    </div>
    );
  }
}
