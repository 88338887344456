import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {BannerComponent, DescriptionComponent, InvestmentComponent, FooterComponent, PlansComponent, FeatureComponent, ManageComponent}  from '../components';
import '../assets/management.scss';

export default class InvestmentManagement extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const manageData = [
            'Accurately manages all lease touchpoints',
            'Provides a fully audible records trail',
            'Offers fleet cashflow forecasting',
            'Enables the fast mobilisation of multiple assets for transition i.e Asset Backed Securities(ABS)transactions',
            'Thereby enabling banks to capitalise on market trends and optimise asset value',
            'Monitors operators(airlines) globally highlighting possible risk',
            'Mitigates risk through the accurate and AI enhanced management of assets across the asset investment lifecycle'
        ];

        const features = [
            {
                title : 'Contract Management',
                img : 'landing/contact_management1.png',
                description : [
                    'Complete fleet management; aircraft, engines, APUs and landing gears',
                    'End-to-end lease and contract management for all assets',
                    'Management of lease terms and conditions including delivery and redelivery requirements',
                    'Unified view of the assets'
                ]
            },
            {
                title : 'Maintenance, Cash Flow & MR',
                img : 'landing/maintenance_cash_flow_mr.png',
                description : [
                    'Maintenance related cash flow analysis',
                    'Capability to undertake fleet wide cash flow analysis for different scenarios including Asset Backed Securitization (ABS)',
                    'Maintenance event tracking, status of findings, workscope approvals, Life Limited Parts (LLP) status, Airworthiness Directives (AD) and Service Bulletins (SB) status'
                ]
            },
            {
                title: 'Analytics & Valuation',
                img : 'landing/group_3.png',
                description : [
                    'Complete fleet management; aircraft, engines, APUs and landing gears ',
                    'End-to-end lease and contract management for all assets',
                    'Management of lease terms and conditions including delivery and redelivery requirements',
                    'Unified view of the assets'
                ]
            }
        ]


        return(
            <div className="management-container sparta-staticpage-container">
                <BannerComponent
                    title="Asset management, supported by digital technologies."
                    subtitle="Why limit your full potential?"
                    link="/signup"
                />
                <DescriptionComponent
                    description="A digital asset manager, an extension of the human workplace.  Coupling Acumen's depth of knowledge in the aviation asset management sector with and innate inquisitive and problem-solving approach, it has successfully developed a universal and integrated asset management platform.  This platform uses embedded machine learning (ML) and artificial intelligence (AI) technologies and utilises full form robotic process automation (RPA)"
                />
                <InvestmentComponent />
                <ManageComponent
                    title="How does SPARTA help banks manage their investments?"
                    data={manageData}
                />
                <FeatureComponent features={features} />
                <PlansComponent />
                <FooterComponent />
            </div>
        )
    }
}
