export const SECURITY_DEPOSIT_LIST = 'SECURITY_DEPOSIT_LIST';
export const SECURITY_DEPOSIT_ADD = 'SECURITY_DEPOSIT_ADD';
export const SECURITY_DEPOSIT_EDIT = 'SECURITY_DEPOSIT_EDIT';
export const CASH_TRANSACTIONS_ADD = 'CASH_TRANSACTIONS_ADD';
export const CASH_TRANSACTIONS_EDIT = 'CASH_TRANSACTIONS_EDIT';
export const CASH_NON_TRANSACTIONS_ADD = 'CASH_NON_TRANSACTIONS_ADD';
export const CASH_NON_TRANSACTIONS_EDIT = 'CASH_NON_TRANSACTIONS_EDIT';
export const SECURITY_DEPOSIT_LOADER = 'SECURITY_DEPOSIT_LOADER';
export const SECURITY_DEPOSIT_CRUD = 'SECURITY_DEPOSIT_CRUD';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const ML_SECURITY_DEPOSIT_DATA = 'ML_SECURITY_DEPOSIT_DATA';
