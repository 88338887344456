import React, {Component} from 'react';
import { Row, Col} from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { Link } from 'react-router';
import InvoiceDetailItem from './InvoiceDetailItem'
import NumericLabel from 'react-pretty-numbers';
export default class InvoiceDetailList extends Component{
    constructor(props) {
        super(props);
    }
    render(){
        const{ items } = this.props;
        return(
            <div className="invoice-lists">
                <Row className="header-row">
                    <Col md="2" >
                        <h6>
                            License
                        </h6>
                    </Col>
                    <Col md="3">
                      <h6>
                        Description
                      </h6>
                    </Col>
                    <Col md="2">
                        <h6>
                            Plan
                        </h6>
                    </Col>
                    <Col md="1" style={{padding: '0px'}}>
                        <h6>
                        Item Cost
                        </h6>
                    </Col>
                    <Col  md="2">
                        <h6>
                        Quantity
                        </h6>
                    </Col>
                    <Col  md="2">
                        <h6>
                        Cost
                        </h6>
                    </Col>
                </Row>
                {
                    items.map((item, key) =>
                    <InvoiceDetailItem item={item} key={key} />
                    )
                }
                <p className="total-amount">Total <span>$ <NumericLabel>{this.props.totalCost}</NumericLabel></span></p>
            </div>
        )
    }
}
