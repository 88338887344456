import React, { Component } from 'react';
import { Row, Col, Collapse, CardBody, Card, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import 'react-rangeslider/lib/index.css'
import Slider from 'react-rangeslider';
import GraphCard from './GraphCard';
import LOWGraph from './LOWGraph';
import { imgStoragePath } from '../../../../constants';
export default class LifeOnWings extends Component {
  constructor(props){
    super(props);
    this.state = {
      collapse: true,
      volume: 0,
      activeTab: 1
    };
    this.toggle = this.toggle.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  handleOnChange = (value) => {
    this.setState({
      volume: value
    })
  }
  render() {
    let { volume } = this.state;
    const { lifeOnWings, simulationType, simulatedData } = this.props;
    return(
      <div className="wing-analysis-block" style={{ padding : '0', marginBottom: '60px' }}>
        <h5 className="para-ui" onClick={this.toggle}>
             Life on Wing Analysis
             <img style={this.state.collapse ? {transform: 'rotate(0deg)'}: {transform: 'rotate(180deg)'}} src={imgStoragePath + "collapse_arw.png"} alt="icon" />
         </h5>
         <Collapse className="detail-block" isOpen={this.state.collapse}>
           <Card>
             <CardBody>
               <Nav tabs>
                 { Object.keys(lifeOnWings).map((label, index) => {
                   return(
                     <NavItem key={index}>
                       <NavLink
                         className={classnames({ active: this.state.activeTab === index+1 })}
                         onClick={() => { this.toggleTab(index+1); }}
                       >
                       {lifeOnWings[label].name ? lifeOnWings[label].name: 'Engine' + (index + 1) }
                       </NavLink>
                     </NavItem>
                   )
                 } )}
               </Nav>
               <TabContent activeTab={this.state.activeTab}>
                 {Object.keys(lifeOnWings).map((label,index) => {
                   return(
                     <TabPane key={index} tabId={index+1}>
                       <div>
                       <LOWGraph
                       symbol="Hours Remaining :"
                       yType="eventDate"
                       toolTipText="Year"
                       xLabel="Engine Life ( Year )"
                       yLabel="Life of Engine on Wing ( Hours )"
                       legend="Hours"
                       type="low"
                       title="Life On Wings Analysis"
                       color='#FEF7D1'
                       lineColor="#F8D41C"
                       events={lifeOnWings[label].shop_visits}
                       plots={lifeOnWings[label].plots}
                       newplots={simulationType == 2 ? simulatedData.engines.engine_data[label].life_on_wing_plots :{}}
                       />
                       <Row className="wing-param-block" style={{paddingTop:'10px', paddingBottom:'10px'}}>
                         <Col md="6" className="margin-bottom-15-xs" style={{opacity: 0}}>
                           <h6>Utilisation hours <span className="pull-right">111</span></h6>
                             <Slider
                               value={volume}
                               orientation="horizontal"
                               onChange={this.handleOnChange}
                               />
                         </Col>
                         {Object.keys(lifeOnWings[label].shop_visits).map((sv,index) => {
                           return(
                             <Col md="2" className="margin-bottom-15-xs" key={index}>
                               <h6>Life On Wing: {lifeOnWings[label].shop_visits[sv].name}</h6>
                               { simulationType == 2 && (simulatedData.engines.engine_data[label].life_on_wing_shop_visits[sv].life_on_wings != lifeOnWings[label].shop_visits[sv].life_on_wings) ?
                                 <p style={{color:'#2a9ef5'}}>{simulatedData.engines.engine_data[label].life_on_wing_shop_visits[sv].life_on_wings} Hours</p>:null
                               }
                               <span style={simulationType == 2 && (simulatedData.engines.engine_data[label].life_on_wing_shop_visits[sv].life_on_wings != lifeOnWings[label].shop_visits[sv].life_on_wings) ?{textDecoration:'line-through'}:{}}>{lifeOnWings[label].shop_visits[sv].life_on_wings} Hours</span>
                             </Col>
                           )
                         })}
                       </Row>
                     </div>
                    </TabPane>
                   )
                 })}
               </TabContent>
             </CardBody>
           </Card>
         </Collapse>
      </div>
    )
  }
}
