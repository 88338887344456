import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AssetViewInfo, OtherPlatform } from '../components';
import { ListLoader, LicenceModal } from '../../shared';
import { Link } from 'react-router'
import '../assets/styles/assetView.scss';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { assetViewAc, recordInitializeAc, transferAssetAc } from '../actionCreators';
import { loadAssetViewAc } from '../../applications/records/Dashboard/actionCreators';
import { ASSET_TRANSFER_MODAL } from '../../applications/console/TechSummary/actions'
import { getAssetTranferUsageAc } from '../../applications/console/TechSummary/actionCreators'
import { ASSET_DETAIL } from '../actions';
import { imgStoragePath } from '../../constants'
import { getLocalStorageInfo } from '../../utils'
import { trackActivity } from '../../utils/mixpanel'
import { BUY_LICENCE } from '../../shared/actions'
class AssetView extends Component {
  constructor(props){
    super(props);
    this.state = {
      transferAssetObj: {
				is_contract_and_maintenance: false,
				is_records: false,
				is_technical: false,
				is_all: false
			}
    }
  }
  componentDidMount() {
    this.props.fetchAssetView(this.props.params);
    if(getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.is_saas){
      this.props.getAssetTranferUsage();
    }
    // trackActivity('Page Visited', {
    //   application_module: 'Console',
    //   asset: 'All',
    //   page_title: 'Assets View'
    // })
   // this.props.loadAssetView();
  }
  componentWillUnmount(){
    this.props.clearViewData();
  }

  toggleTransfer = (flag) =>{
		if(getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 1){
      if(this.props.assetTransferUsage.available == this.props.assetTransferUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/asset-data-transfer',
          content: "You don't have license to transfer asset. Please buy new license to transfer asset."
        })
        return;
      }
    }else if( getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type == 1 && getLocalStorageInfo().user.permission.console.is_saas){
      if(this.props.assetTransferUsage.available == this.props.assetTransferUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/asset-data-transfer',
          content: "You don't have license to transfer asset. Please buy new license to transfer asset."
        })
        return;
      }
    }
		this.props.toggleTransfer(flag)
	}

  updateTransferObj = (key, flag) =>{
		this.setState((prevState) => ({
			...prevState,
			transferAssetObj: {...prevState.transferAssetObj, [key]: flag}
		}))
		if(this.state.transferAssetObj.is_contract_and_maintenance == true && this.state.transferAssetObj.is_records == true && this.state.transferAssetObj.is_technical == true){
			this.setState((prevState) => ({
				...prevState,
				transferAssetObj: {...prevState.transferAssetObj, is_all: true}
			}))
		}else{
			this.setState((prevState) => ({
				...prevState,
				transferAssetObj: {...prevState.transferAssetObj, is_all: false}
			}))
		}
	}

  render(){
    const { assetView, assetTransferModal } = this.props;
    return(
      Object.keys(assetView).length ?
      <div className="assets-detail-section flex-not-centered">
        <AssetViewInfo assetView={assetView} params={this.props.params} toggleTransfer={this.toggleTransfer}/>
        { Object.keys(assetView).length ? <OtherPlatform params={this.props.params} assetView={assetView}  id={assetView.id} /> : null }
        {
          assetView.data_transfer && Object.keys(assetView.data_transfer).length && assetView.data_transfer.value != 1 ?
            <p class="contract-draft-mode">
              {
                assetView.data_transfer.value == 0 ?
                  `Transfer has been initiated for this asset.`
                :null
              }
              {
                assetView.data_transfer.value == 1 ?
                  ``
                :null
              }
              {
                assetView.data_transfer.value == 2 ?
                  `Data has been transferred for this asset.`
                :null
              }
            </p>
          :null
        }
        <Modal isOpen={assetTransferModal} toggle={() => this.props.toggleTransfer(false)} className="transfer-modal">
					<ModalBody>
						<Row className="transfer-modal-body" style={{marginBottom: '15px'}}>
							<Col md="12">
								<ul className="list-inline">
									<li className="list-inline-item"><img src={`${imgStoragePath}warning.png`}/></li>
									<li className="list-inline-item"><h2>Transfer {assetView.msn ? `MSN ${assetView.msn}` : `ESN ${assetView.esn}`}</h2></li>
								</ul>
							</Col>
							<Row className="apps-container">
								<Col md="12">
									<h4>Select Applications To Transfer</h4>
								</Col>
								{
									assetView.is_contract_enabled || assetView.is_maintenance_enabled ?
									<Col md="5" className="apps-name" onClick={() => this.updateTransferObj('is_contract_and_maintenance', !this.state.transferAssetObj.is_contract_and_maintenance)}>
										<input type="checkbox" checked={this.state.transferAssetObj.is_contract_and_maintenance}/> <span>Contracts & Maintenance</span>
									</Col>
									:null
								}
								{
									assetView.is_records_enabled ?
									<Col md="2" className="apps-name" onClick={() => this.updateTransferObj('is_records', !this.state.transferAssetObj.is_records)}>
										<input type="checkbox" checked={this.state.transferAssetObj.is_records}/> <span>Records</span>
									</Col>
									:null
								}
								{
									assetView.is_technical_enabled ?
									<Col md="3" className="apps-name" onClick={() => this.updateTransferObj('is_technical', !this.state.transferAssetObj.is_technical)}>
										<input type="checkbox" checked={this.state.transferAssetObj.is_technical}/> <span>Technical</span>
									</Col>
									:null
								}
								<Col md="12">
									<p>
										Download transfer key and transfer data to another lessor instance. Kindly confirm asset to be transferred is not existing. Please contact <a href="mailto:support@sparta.aero">support@sparta.aero</a> in case of any clarifications.
									</p>
								</Col>
                {
                  !(assetView.data_transfer == undefined || assetView.data_transfer == null || Object.keys(assetView.data_transfer).length < 1) ?
                  <Col md="12">
                    <span style={{width: '100%', marginTop: '10px'}} className="warning-info-text">Please note on re-generating transfer key all previous transfer keys will be invalidated</span>
                  </Col>
                  :null
                }
							</Row>
						</Row>
						<Row className="transfer-modal-footer">
							<Col md="12">
								<ul className="list-inline">
									<li className="list-inline-item">
										<button type="button" disabled={this.state.transferAssetObj.is_contract_and_maintenance == false && this.state.transferAssetObj.is_records == false && this.state.transferAssetObj.is_technical == false ? true : false} onClick={() => this.props.transferAsset(this.state.transferAssetObj, assetView)} className="btn-secondary">{(assetView.data_transfer == undefined || assetView.data_transfer == null || Object.keys(assetView.data_transfer).length < 1) ? 'Download' : 'Re-Download'} Transfer Key</button>
									</li>
									<li className="list-inline-item">
										<Link className="aims-primary-link" onClick={() => this.props.toggleTransfer(false)}>Cancel</Link>
									</li>
								</ul>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="8"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>:
      <ListLoader />
    )
  }
}

const mapStateToProps = state => ({
  assetView: state.userAssetReducer.assetView,
  recordInit: state.userAssetReducer.recordInit,
  assetTransferModal: state.TechSummaryReducer.assetTransferModal,
  assetTransferUsage: state.TechSummaryReducer.assetTransferUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetView: (params) => dispatch(assetViewAc(params)),
    clearViewData:() => dispatch({
      type: ASSET_DETAIL,
      payload: {}
    }),
    toggleTransfer: (flag) => dispatch({
			type: ASSET_TRANSFER_MODAL,
			payload: flag
		}),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getAssetTranferUsage: () => dispatch(getAssetTranferUsageAc(ownProps.params)),
    loadAssetView: () => dispatch(loadAssetViewAc(ownProps.params)),
    transferAsset: (applications, asset) => dispatch(transferAssetAc(ownProps.params, applications, asset))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetView);
