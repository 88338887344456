
import * as actions from '../actions';
export default function( state = {
  adMLLoader: false,
  dataListLoader: false,
  linkLoader:false,
  adModalStatus: false,
  adProgressPercentage: 0,
  adLogsListing: [],
  assetDetail: {},
  dataIds: [],
  operatorDataPoints: {
    list: [],
    pagination:{}
  },
  oemDataPoints: {
    list: [],
    pagination: {}
  },
  easaAndFaaDataPoints: {
    list: [],
    pagination: {}
  },
  adReportDetail: {},
  easaDataListInfo: {
    list: [],
    pagination: {}
  },
  techSpecsPoints: {
    response_text:{}
  },
  dataPointCrud: {
    flag: false,
    data: {},
    type: 'edit'
  }
}, action){
  switch (action.type) {
    case actions.TOGGLE_DATA_EXT_BAR:
      return{
        ...state,
        dataPointCrud: action.payload
      }
    case actions.SELECT_DATAPOINT_LIST:
    return{
      ...state,
      dataIds: state.dataIds.indexOf(action.payload) == -1 ? [...state.dataIds, action.payload] : state.dataIds.filter(item => item != action.payload)
    }
    case actions.SELECT_ALL_DATAPOINT_LIST:
    return{
      ...state,
      dataIds: state.techSpecsPoints.pending_items.length == state.dataIds.length ? [] : state.techSpecsPoints.pending_items
    }
    case actions.AD_FILES_MODAL:
      return {
        ...state,
        adModalStatus: !state.adModalStatus
      }
    case actions.TECHSPECS_DATA_POINTS_PLOT:
      return {
        ...state,
        techSpecsPoints: action.payload
      }
    case actions.AD_LOGS_LIST:
      return {
        ...state,
        adLogsListing: action.payload.file_logs,
        assetDetail: action.payload.asset_detail
      }
    case actions.AD_POINTS_INFO:
      return {
        ...state,
        adReportDetail: action.payload
      }
    case actions.AD_DATA_POINTS:
      if(action.filter.type == 'oem_status'){
        return {
          ...state,
          oemDataPoints: {
            ...state.oemDataPoints,
            list: action.payload.ad_data,
            pagination: action.payload.pagination
          }
        }
      }else if (action.filter.type == 'easa_faa_status') {
        return {
          ...state,
          easaAndFaaDataPoints: {
            ...state.easaAndFaaDataPoints,
            list: action.payload.ad_data,
            pagination: action.payload.pagination
          }
        }
      }else{
        return {
          ...state,
          operatorDataPoints: {
            ...state.operatorDataPoints,
            list: action.payload.ad_data,
            pagination: action.payload.pagination
          }
        }
      }
    case actions.AD_PROGRESS_PERCENTAGE:
      return {
        ...state,
        adProgressPercentage: action.payload
      }
    case actions.UPDATE_AD_LOADER:
      return {
        ...state,
        adMLLoader: action.payload
      }
    case actions.UPDATE_AD_LIST_LOADER:
      return {
        ...state,
        dataListLoader: action.payload
      }
    case actions.AD_LINK_LOADER:
      return {
        ...state,
        linkLoader: action.payload
      }
    case actions.UPDATE_AD_LINK:
      return {
        ...state,
        adReportDetail: {
          ...state.adReportDetail,
          is_gap_report:true,
          asset_type:action.payload.asset_type,
          form_slug:action.payload.form_slug,
          work_order_slug:action.payload.work_order_slug,
          asset_slug:action.payload.asset_slug,

        }
      }

    case actions.EASA_DATA_LIST:
      return {
        ...state,
        easaDataListInfo: {
          ...state.easaDataListInfo,
          list: action.payload.ad_data,
          pagination: action.payload.pagination
        },
        assetDetail: action.payload.asset_detail
      }
    default:
      return state;
  }
}
