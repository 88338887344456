import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addInsurance, addClaim } from '../../contractAddObject';
export const insuranceListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.INSURANCE_CLAIM_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/insurances/', {})
    .then(response => {
      dispatch({
        type: actions.INSURANCE_CLAIM_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INSURANCE_LIST,
          payload: response.data.data
        });
      }
    })
  }
}

export const insuranceCrudFnAc = (params, type, id, actionType) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.INSURANCE_CRUD,
        actionType: actionType,
        payload: {type: type, data:(actionType == 'insuranceCrud' ? addInsurance : addClaim ) , flag: true}
      });
    }else{
      dispatch({
        type: actions.INSURANCE_CLAIM_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug +(actionType == 'insuranceCrud' ? '/insurances/':'/insurance-claims/') + id+ '/',{})
      .then(response => {
        dispatch({
          type: actions.INSURANCE_CLAIM_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.INSURANCE_CRUD,
            actionType: actionType,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}

export const addEditInsuranceAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/insurances/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(insuranceListAc(params))
          dispatch({
            type: actions.INSURANCE_CRUD,
            actionType: 'insuranceCrud',
            payload: {type: '', data:{} , flag: false}
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Insurance`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Insurance`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/insurances/' + data.id + '/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(insuranceListAc(params))
          dispatch({
            type: actions.INSURANCE_CRUD,
            actionType: 'insuranceCrud',
            payload: {type: '', data:{} , flag: false}
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Insurance`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Insurance`,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const claimListAc = (params) => {
  return (dispatch) => {
    globalGetService('contracts/'+params.contract_slug+'/insurance-claims/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CLAIM_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const addEditClaimAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/insurance-claims/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(claimListAc(params))
          dispatch(insuranceListAc(params))
          dispatch({
            type: actions.INSURANCE_CRUD,
            actionType:'claimCrud',
            payload: {type: '', data: {}, flag: false}
          })
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Claims`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Insurance Claims`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/insurance-claims/' + data.id + '/', data)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch(claimListAc(params))
          dispatch(insuranceListAc(params))
          dispatch({
            type: actions.INSURANCE_CRUD,
            actionType:'claimCrud',
            payload: {type: '', data: {}, flag: false}
          })
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Claims`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Insurance Claims`,
            item_id: data.id
          })
        }
      })
    }
  }
}
