import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../constants';
export default class LoginSideBar extends Component {
  constructor(props){
    super(props);


  }
  render() {
    return(
      <Col md="6" className=" logo-block" >
        <div className="acumen-logo">
          <img src={imgStoragePath+"sparta_logo.png"} alt="logo" width="50"/>
        </div>
        <div className=" aims-logo flex-centered">
          <div>
              <h1 className="apha">
                 Integrated Aircraft <br/> Management System
              </h1>
          </div>
        </div>
        <div className="contribute-block">
         <img src={imgStoragePath+"acumen_logo.png"} alt="logo"/>
          <p>
          &copy; 2019 Acumen Aviation Europe Limited. All rights reserved. <Link to="https://www.acumen.aero" target="_blank"> www.acumen.aero </Link>
          </p>
        </div>
      </Col>
    )
  }
}
