import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loadMrAccuralAc, mrAccuralexportAc } from '../actionCreators';
import { CashFlowList } from '../../../maintenance/MRFinanceGraph/components'
import { ShortHead, ContractSideBar, ContractBarLoader } from '../../Elements';
import { Link } from 'react-router';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { imgStoragePath, assetsType } from '../../../../constants';
import{RESET_SIMULATION} from '../../../maintenance/MRFinanceGraph/actions';
class MrAccrualCashFlow extends Component{
    constructor(props) {
        super(props);
        this.state={
          filter: null
        }
    }
    componentDidMount(){
        this.props.listCashFlow('mr-accrual-cash');
    }
    updateFilter = (value, type) => {
      this.setState(prevState => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          [type]: value
        }
      }))
    }
    changeMsn =(evt) =>{
      if(evt.contracts == false){
        let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
        browserHistory.push(url)
      }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
        let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
        browserHistory.push(url);
      }else{
        let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/mr-accrual';
        browserHistory.push(url);
        this.props.params.type = assetsType[evt.asset_type];
        this.props.params.aircraft_slug = evt.slug;
        this.props.resetSimlute(0);
        this.props.listCashFlow('mr-accrual-cash');
      }
    }
    render(){
        const  { cashflowMrAccrual, ctrShortInfo,params, mrAccuralLoader } = this.props;

        return(
            <div className="contract-container">
             {mrAccuralLoader?<ListLoader />:null}
                <div >
                    { Object.keys(ctrShortInfo).length ?
                        <ShortHead contrAssetBasicInfo={ctrShortInfo} changeMsn={this.changeMsn} headingText="Contracts" /> : <ContractBarLoader />
                    }
                </div>
                <ContractSideBar application={`MR Accrual Cash Flow`} type="shorter" params= {this.props.params} />

                <div className="cash-flow-wrapper">
                <h5 className="para invoice-heading" style={{position:'relative',marginBottom:'20px',marginLeft:'60px'}}>
                <Link className="back-link flex-centered" to={'/' +this.props.params.type + '/' + this.props.params.aircraft_slug + '/contract/mr-accrual'}>
                   <img width="15" src={imgStoragePath+"back_arw.png"} alt="img" style={{ margin:'0px 10px 0 0'}}/>

                    <h6>MR ACCRUAL CASH FLOW</h6>

                  </Link>
                  <span onClick={() => this.props.exportMrAccrualflow(ctrShortInfo.contract.slug, this.state.filter)}style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px', position: 'absolute', right:'0px', top:'0px'}}><img width="18" src={imgStoragePath+"export_icon.png"} alt="export" /> Export</span>
                  </h5>
                    { cashflowMrAccrual.length ?
                      <CashFlowList
                        cashFlow={cashflowMrAccrual}
                        paddingSpace = {60}
                        updateFilter={this.updateFilter}
                      />:null
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ctrShortInfo: state.ContractListReducer.ctrShortInfo,
    cashflowMrAccrual: state.MRAccuralReducer.cashflowMrAccrual,
    mrAccuralLoader: state.MRAccuralReducer.mrAccuralLoader,
  });

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        listCashFlow: (type) => dispatch(loadMrAccuralAc(ownProps.params,type)),
        exportMrAccrualflow:(contract_slug, filter)=>dispatch(mrAccuralexportAc(contract_slug, filter)),
        resetSimlute: (type) => dispatch({
          type: RESET_SIMULATION,
          payload: type
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MrAccrualCashFlow)
