import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { Link } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
export default class CartList extends Component{
    constructor(props) {
        super(props);
    }
    render(){
        const{cart} = this.props;
        return(
                    <div className="asset-list" style={{ cursor : "default" }}>
                      <Row className="flex-centered">
                          <Col md="3" >
                              <span className="label">License</span>
                          </Col>
                          <Col  md="2">
                              <span className="label">Plan</span>

                          </Col>
                          <Col  md="2">
                              <span className="label">Cost</span>

                          </Col>
                          <Col  md="2">
                              <span className="label">
                              Quantity
                              </span>
                          </Col>
                          <Col  md="2">
                              <span className="label">Sub-total</span>
                          </Col>
                          <Col  md="1" className="text-center">

                          </Col>
                      </Row>
                        <Row className="flex-centered">
                            <Col md="3" >

                                <h6>
                                    {cart.license ?  cart.license : null}
                                </h6>
                            </Col>
                            <Col  md="2">

                                <h6>
                                    {cart.quantity == -1 ? 'Unlimited' : cart.plan.is_subscription ? cart.plan.details.interval_type.label+', Recurring' :'One Time'}
                                </h6>
                            </Col>
                            <Col  md="2">

                                <h6>
                                    $ <NumericLabel >{cart.amount ? cart.amount :null }</NumericLabel >
                                </h6>
                            </Col>
                            <Col  md="2">

                                <h6 className="transaction">
                                    {cart.quantity == -1 ? 'Unlimited' : cart.quantity ? cart.quantity : null } {cart.plan.units.label ? cart.plan.units.label : null }
                                    {
                                        cart.quantity != -1 ?
                                           <span className="caret-list">
                                                <i className="fa fa-caret-up" onClick={ (e) => { e.preventDefault(); this.props.updateCart(cart.plan.details.id, cart.quantity+1)} } ></i>
                                              <i className="fa fa-caret-down" onClick={ cart.quantity > 1 && cart.quantity > cart.previous_quantity  ? (e) => { e.preventDefault(); this.props.updateCart(cart.plan.details.id, cart.quantity-1)} : (e) => { e.preventDefault()} } style={ (cart.quantity == 1 || (cart.quantity == cart.previous_quantity )) ? { color: "#dddddd", cursor: 'not-allowed' } : {} }></i>
                                           </span>
                                           : null
                                    }
                                </h6>
                            </Col>
                            <Col  md="2">

                                <h6>$ <NumericLabel >{cart.sub_total ? cart.sub_total: 0 }</NumericLabel></h6>
                            </Col>
                            <Col  md="1" className="text-center">
                                <img style={{ position: 'relative', top: '-8px', cursor: 'pointer' }} src={imgStoragePath+'phase-2/cancel.png'} onClick={() => {this.props.removeCartItem(cart.plan.details.id)}}  />
                            </Col>
                        </Row>
                    </div>
        )
    }
}
