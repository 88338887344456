import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { GetInputField } from'../../../Elements';
import NumericLabel from 'react-pretty-numbers';
import { showCurrencyFormat } from '../../../../../utils';
import moment from 'moment'
import { checkNumber, checkString, checkValidDate } from '../../../../../formValidator'
export default class AddEditRobbery extends Component {
  constructor(props){
    super(props);
    this.state = {
      robbery:{},
      error: {}
    }
  }
  componentDidMount(){
    this.setState({
      robbery:this.props.robbery.data
    });
  }
  updateForm = (key,value) => {
    this.setState(prevState =>({
        ...prevState,
        robbery:{
          ...prevState.robbery,
          [key]:value
        }
    }));
  }
  addEditRobbery = (e) => {
    e.preventDefault();
    const validateNewInput = {
      part_number: checkString({value: this.state.robbery.part_number, required: true, minLength: '', maxLength: '', message: 'Please enter Part Number'}),
      price: checkNumber({value: this.state.robbery.price, required: false, minLength: '', maxLength: '', message: 'Please enter valid Price'})
    };
    let roberyObj = Object.assign({}, this.state.robbery);
    delete roberyObj['work_order'];
    roberyObj = {
      ...roberyObj,
      work_order: 'abcd'
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateSideBar(roberyObj, this.props.robbery.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { error,robbery } = this.state;
    const { type } = this.props.robbery;
    if(!Object.keys(robbery).length) {
      return null;
    }
    return(
      <div>
        <form onSubmit={this.addEditRobbery}>
          <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
            <Row>
              <Col md="6" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Part Number {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                    { type: type,
                      value: robbery.part_number,
                      error: this.state.error.part_number,
                      updateForm: (value) => this.updateForm('part_number', value)
                    }
                  )
                }
              </Col>
              <Col md="6" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Removal Date</label>,
                    { type: type,
                      value: robbery.removal_date,
                      updateForm: (value) => this.updateForm('removal_date', value)
                    }
                )}
              </Col>
              <Col md="12" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Part Description</label>,
                    { type: type,
                      value: robbery.part_description,
                      updateForm: (value) => this.updateForm('part_description', value)
                    }
                  )
                }
              </Col>
              <Col md="12" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Part Location</label>,
                    { type: type,
                      value: robbery.part_location,
                      updateForm: (value) => this.updateForm('part_location', value)
                    }
                  )
                }
              </Col>

              <Col md="12" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Reason for Removal</label>,
                    { type: type,
                      value: robbery.reason_for_removal,
                      updateForm: (value) => this.updateForm('reason_for_removal', value)
                    }
                  )
                }
              </Col>
              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label">Date Verified Removed From Aircraft</label>,
                    { type: type,
                      value: robbery.date_verified_removed_from_aircraft,
                      maxDate: moment(),
                      updateForm: (value) => this.updateForm('date_verified_removed_from_aircraft', value)
                    }
                  )
                }
              </Col>
              <Col md="4" style={{marginBottom: '20px'}}>
                {
                   GetInputField(
                     'date',
                     <label className="for-label">Date Verified Installed On Aircraft</label>,
                     { type: type,
                       value: robbery.date_verified_installed_on_aircraft,
                       maxDate: moment(),
                       updateForm: (value) => this.updateForm('date_verified_installed_on_aircraft', value)
                     }
                   )
                 }
              </Col>
              <Col md="4" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'date',
                    <label className="for-label" style={{position:"relative", top:"-18px"}}>Date Verified in Store House</label>,
                    { type: type,
                      value: robbery.date_verified_in_store_house,
                      maxDate: moment(),
                      updateForm: (value) => this.updateForm('date_verified_in_store_house', value)
                    }
                  )
                }
              </Col>
              <Col md="6" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Price (USD)</label>,
                    { type: type,
                      value: type == 'view' ? <NumericLabel params={showCurrencyFormat(robbery.currency)}>{robbery.price}</NumericLabel> : robbery.price,
                      error: this.state.error.price,
                      updateForm: (value) => this.updateForm('price', value)
                    }
                  )
                }
              </Col>
              <Col md="6" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Work Card No</label>,
                    { type: type,
                      value: robbery.work_card_no,
                      updateForm: (value) => this.updateForm('work_card_no', value)
                    }
                  )
                }
              </Col>
              <Col md="12" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Comments</label>,
                    { type: type,
                      value: robbery.comments,
                      updateForm: (value) => this.updateForm('comments', value)
                    }
                  )
                }
              </Col>
            </Row>
          </div>
          { type != 'view' ?
            <div className="edit-sidebar-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button disabled={this.props.robberyLoader} type="submit" className="btn btn-primary">{this.props.robberyLoader ? 'Processing...':'Save Changes'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
                </li>
              </ul>
            </div>:null
          }
        </form>
      </div>
    )
  }
}
