export const AUDITS_SECTIONS = 'AUDITS_SECTIONS';
export const AUDITS_SECTIONS_INFO = 'AUDITS_SECTIONS_INFO';
export const AUDITS_FINDING_INFO = 'AUDITS_FINDING_INFO';
export const COVER_IMAGE = 'COVER_IMAGE';
export const FORM_INTRODUCTION = 'FORM_INTRODUCTION';
export const RECORD_REVIEW = 'RECORD_REVIEW';
export const RECORD_REVIEW_TOGGLE = 'RECORD_REVIEW_TOGGLE';
export const ADD_REVIEW_TOGGLE = 'ADD_REVIEW_TOGGLE';
export const REMOVE_REVIEW_TOGGLE = 'REMOVE_REVIEW_TOGGLE';
export const UPDATE_REVIEW_TOGGLE = 'UPDATE_REVIEW_TOGGLE';
export const UPDATE_FINDINGS_T004 = 'UPDATE_FINDINGS_T004';
export const ADD_FINDINGS_T004 = 'ADD_FINDINGS_T004';
export const DELETE_FINDING_T004 = 'DELETE_FINDING_T004';
export const UPDATE_IMAGE_IN_EDIT = 'UPDATE_IMAGE_IN_EDIT';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const EDIT_IMAGE = 'EDIT_IMAGE';
export const UPDATE_ADD_IMAGE = 'UPDATE_ADD_IMAGE';
export const CHANGE_IMAGE = 'CHANGE_IMAGE';
export const EDIT_FINDING = 'EDIT_FINDING';
export const TOOGLE_AUDIT_TAB = 'TOOGLE_AUDIT_TAB';
export const DELETE_ADD_IMAGE = 'DELETE_ADD_IMAGE';
export const FINDING_LOADER = 'FINDING_LOADER';
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const QUESTION_UPDATE = 'QUESTION_UPDATE';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const REMOVE_COMPONENT_t004 = 'REMOVE_COMPONENT_t004';
export const LOPA_IMAGE='LOPA_IMAGE';
export const TOGGLE_T004_T007 = 'TOGGLE_T004_T007';