import React, { Component } from 'react';
import moment from 'moment';
import { claimStatusLst, displayDateFormat }  from '../../../../constants'
import { showCurrencyFormat }  from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import { ContrListCol } from '../../Elements';
export default class ClaimRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { claim } = this.props;
    const claimObj = [
      { value: claim.insurance_number ? claim.insurance_number : '--' },
      { value: claim.claim_number ? claim.claim_number : '--' },
      { value: claim.insurer ? claim.insurer : '--' },
      { value: claim.claim_summary ? claim.claim_summary.length > 160 ? claim.claim_summary.substring(0,157) + ' ...': claim.claim_summary : '--' },
      { value: claim.date ? moment(claim.date).format(displayDateFormat) : '--' },
      { value: claim.status && claim.status.label ? claim.status.label : '--' },
      { value: claim.amount ? <NumericLabel params={showCurrencyFormat(claim.currency)}>{claim.amount}</NumericLabel> : '--', style: {width: '150px', wordBreak: 'break-all'} }
    ]
    return(
      <tr onClick={this.props.claimView}>
        {claimObj.map((claim, index) => <ContrListCol colObj={claim} key={index}/>)}
      </tr>
    )
  }
}
