import React from 'react';
import { Row } from 'reactstrap'
const UtilsHourCycls = (props) => {
  return (
    <Row className="utils-row">
      {props.children}
    </Row>
  )
}
export default UtilsHourCycls;
