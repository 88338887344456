export const FORM_GENPICTURES = 'FORM_GENPICTURES';
export const IMPORT_GEN_PIC = 'IMPORT_GEN_PIC';
export const UPDATE_IMPORT_GEN_PIC = 'UPDATE_IMPORT_GEN_PIC';
export const EDIT_GEN_IMAGE = 'EDIT_GEN_IMAGE';
export const DELETE_GENPICTURE = 'DELETE_GENPICTURE';
export const REMOVE_GEN_PIC = 'REMOVE_GEN_PIC';
export const ADD_IMPORT_GEN_PIC = 'ADD_IMPORT_GEN_PIC';
export const UPDATE_FORM_GENPICTURES = 'UPDATE_FORM_GENPICTURES';
export const EDIT_GEN_PIC = 'EDIT_GEN_PIC';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const GEN_PIC_LOADER = 'GEN_PIC_LOADER';
export const GEN_SORT_LOADER = 'GEN_SORT_LOADER';
export const GET_FORM_INFO = 'GET_FORM_INFO';
export const SORT_GEN_IMAGES = 'SORT_GEN_IMAGES';
export const TOGGLE_FORM_STATUS_LOADER = 'TOGGLE_FORM_STATUS_LOADER';
export const FORM_PICTURE_SEGREGATION = "FORM_PICTURE_SEGREGATION";
export const IMPORT_PICTURE_SEGREGATION = "IMPORT_PICTURE_SEGREGATION";
export const SELECT_SEG_IMG = "SELECT_SEG_IMG";
export const SELECT_MOVE_SECTION = "SELECT_MOVE_SECTION";
export const TOGGLE_MOVE_SECTION = "TOGGLE_MOVE_SECTION";
export const AI_WAIT_TIMER = 'AI_WAIT_TIMER';
