import {
  regionOfOpApi,
  regionInfoApi,
  aircraftMaintenanceApi,
  mrCalculationApi,
  aircraftTypeApi,
  engineTypeApi,
  aircraftEngineTypeApi,
  engineWorkScopeCalApi,
  logsListingApi,
  deleteLogApi,
  fetchMrLogApi,
  customCycleRemainApi,
  selectLLPToReplaceApi,
  carryOverNSVApi,
  clientListApi,
  replacedTheLLPSelectedApi,
  breakUpEngineCostApi,
  genMRFinanceGraphApi,
  changeWorkScopeApi,
  downloadLogApi,
} from '../services';
import { globalGetService, globalExportService} from '../../../../globalServices';
import * as actions from '../actions';
import { browserHistory } from 'react-router';
import { toastTimeOut } from '../../../../constants';
import { toastFlashMessage,downloadFileType } from '../../../../utils';

export const clientListAc = () => {
  return (dispatch) => {
    clientListApi()
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UPDATE_MR_CLIENTS,
          payload: response.data.data.lessors
        });
      }
    })
  }
}
export const aircraftTypeAc = () => {
  return (dispatch) => {
    aircraftTypeApi({'mr-calculator': 'mr-calculator'})
    .then(response => {
      // if(response.data.statusCode == 200){
        dispatch({
          type: actions.AIRCRAFT_TYPE_LIST,
          payload: response
        });
      // }
    })
  }
}
export const aircraftEngineTypeAc = (id) => {
  return (dispatch) => {
    if(id){
      aircraftEngineTypeApi(id)
      .then(response => {
        dispatch({
          type: actions.AIRCRAFT_ENGINE_LIST,
          payload: response.data.data.engineTypes
        });
      })
    }else{
      dispatch({
        type: actions.AIRCRAFT_ENGINE_LIST,
        payload: []
      });
    }
  }
}
export const engineTypeAc = () => {
  return (dispatch) => {
    engineTypeApi({'mr-calculator': 'mr-calculator'})
    .then(response => {
      // if(response.data.statusCode == 200){
        dispatch({
          type: actions.ENGINE_TYPE_LIST,
          payload: response
        })
      // }
    })
  }
}
export const regionOfOpAc = () => {
  return (dispatch) => {
    regionOfOpApi()
    .then(response => {
      // if(response.data.statusCode == 200){
        dispatch({
          type: actions.REGION_LIST,
          payload: response
        })
      // }
    })
  }
}
export const regionInfoAc = (region_id, engine_type) => {
  return (dispatch) => {
    if(region_id){
      regionInfoApi(region_id, engine_type)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.REGION_CONDITION,
            payload: (Object.keys(response.data.data).length) ? response.data.data.variable_condition: {}
          })
        }
      })
    }else{
      dispatch({
        type: actions.REGION_CONDITION,
        payload: {}
      })
    }

  }
}
export const aircraftMaintenanceAc = (id) => {
  return (dispatch) => {
    if(id){
      aircraftMaintenanceApi(id)
      .then(response => {
        if(response.data.statusCode == 200){
          let maintanenceList = [];
          const data = response.data.data;
          Object.keys(data).map((item,index) => {
            maintanenceList.push({label: data[item].replace(/,/g, '/'), value: item})
          });
          dispatch({
            type: actions.AIRCRAFT_MAIN_PROG,
            payload: maintanenceList
          });
        }
      })
    }else{
      dispatch({
        type: actions.AIRCRAFT_MAIN_PROG,
        payload: []
      });
    }
  }
}
export const mrCalculationAc = (data) => {
  return (dispatch) => {
    mrCalculationApi(data)
    .then(response => {
      dispatch({
        type: actions.GEN_FINANCE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch(getMRUsageAc())
        browserHistory.push('/review-finance/'+response.data.data.log_id);
      }
    })
  }
}
export const fetchMrLogAc = (id) => {
  return (dispatch) => {
    fetchMrLogApi(id)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.REVIEW_MR_DATA,
          payload: response.data.data
        });
        if(response.data.data.engines){
          dispatch(getCustomeVariableAc(id))
        }
      }
    })
  }
}
export const engineWorkScopeCalAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.REVIEW_MR_LOADER,
      payload: true,
    });
    engineWorkScopeCalApi(data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UPDATE_ENGINE_WS,
          payload: {res: response.data.data, request: data }
        })
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch({
        type: actions.REVIEW_MR_LOADER,
        payload: false,
      });
    })
  }
}

// Review MR Modal API
export const changeWorkScopeAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.REVIEW_MR_LOADER,
      payload: true,
    });
    changeWorkScopeApi(data)
    .then(response => {
      dispatch({
        type: actions.REVIEW_MR_LOADER,
        payload: false,
      });
      if(response.data.statusCode == 200){
      dispatch({
        type: actions.CARRY_OVER_NSV,
        payload: {res: response.data.data, req: data}
      });
      toastFlashMessage(response.data.message, 'success');
      }
    });
  }
}
export const carryOverNSVAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.REVIEW_MR_LOADER,
      payload: true,
    });
    carryOverNSVApi(data)
    .then(response => {
      dispatch({
        type: actions.REVIEW_MR_LOADER,
        payload: false,
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CARRY_OVER_NSV,
          payload: {res: response.data.data, req: data}
        });

        toastFlashMessage(response.data.message, 'success');

      }
    })
  }
}
export const selectLLPToReplaceAc = (modalStatus, shop_visit_no, log_id, engine_no, name) => {
  return (dispatch) => {
    if(modalStatus == 'open'){
      dispatch({
        type: actions.SELECT_LLP_TO_REPLACE_LOADER,
        payload: {loader: true, shop_visit_no}
      });
      selectLLPToReplaceApi(shop_visit_no, log_id, engine_no)
      .then(response => {
        dispatch({
          type: actions.SELECT_LLP_TO_REPLACE_LOADER,
          payload: {loader: false, shop_visit_no}
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.SELECT_LLP_TO_REPLACE,
            payload: {list: response.data.data, modalStatus, shop_visit_no, log_id, engine_no, name, cost_of_years: response.data.cost_of_years}
          })
        }
      });
    }else{
      dispatch({
        type: actions.SELECT_LLP_TO_REPLACE,
        payload: {modalStatus: 'close'}
      })
    }
  }
}
export const replacedTheLLPSelectedAc = (obj) => {
  return (dispatch) => {
    replacedTheLLPSelectedApi(obj)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LLP_REPLACED_SUCCESS,
          payload:{res: response.data.data, req: obj}
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
export const customCycleRemainAc = (modalStatus, engineTypeId, engineNo) => {
  return (dispatch) => {
    if(modalStatus == 'open'){
      customCycleRemainApi(engineTypeId)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.CUSTOM_THE_LLP_CYCLE,
            payload: {
              modalStatus: modalStatus,
              engineNo: engineNo,
              list: response.data.data
            }
          })
        }
      })
    }else{
      dispatch({
        type: actions.CUSTOM_THE_LLP_CYCLE,
        payload: {modalStatus}
      })
    }

  }
}
export const breakUpEngineCostAc = (data) => {
  return (dispatch) => {
    breakUpEngineCostApi(data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.BREAKUP_ENGINE_MR_COST_SUCCESS,
          payload: {res: response.data.data, req: data}
        })
      }
    })
  }
}
export const logsListingAc = (params) => {
  return (dispatch) => {
    logsListingApi(params)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LOGS_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const deleteLogAc = (id) => {
  return (dispatch) => {
    deleteLogApi(id)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LOG_DEL_SUCCESS,
          payload:id
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
export const genMRFinanceGraphAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.REVIEW_MR_LOADER,
      payload:true
    });
    genMRFinanceGraphApi(data)
    .then(response => {
      dispatch({
        type: actions.REVIEW_MR_LOADER,
        payload:false
      });
      if(response.data.statusCode == 200){
        browserHistory.push('/financial-plots/'+response.data.data.log_id);
      }
    });
  }
}


export const downLoadLLPAc = (fileName,data) => {

	return (dispatch) => {
    dispatch({
      type: actions.LLP_EXP_LOADER,
      payload:true
    });

	  globalExportService('mr-calculator/workscope/llps/', data)
	  .then(response => {
      dispatch({
        type: actions.LLP_EXP_LOADER,
        payload:false
      });

			downloadFileType(response, fileName+'.', 'xls')
	  })
	}
  }


export const downloadLogAc = (fileType) => {
  return (dispatch) => {
    downloadLogApi(fileType)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage('File downloaded successfully', 'success');
      }
    })
  }
}

export const getCustomeVariableAc = (logId) => {
  return(dispatch) => {
    globalGetService('/mr-calculator/log/' + logId+ '/variable-conditions/', {})
    .then((response) => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CUSTOM_REGION_CONDITION,
          payload: response.data.data.variable_condition
        });
      }
    })
  }
}


export const getMRUsageAc = () =>{
  return(dispatch) =>{
    globalGetService('/console/license/mr/usage/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MR_USAGE,
          payload: response.data.data
        })
      }
    })
  }
}
