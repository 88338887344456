import * as actions from '../actions';
export default function( state = {
    ordersList : {
      orders:[],
      pagination: {total: 0, number_of_pages: 1, per_page: 10, page: 1}
    },
    orderDetail: {},
    orderLoader: false,
    dueAmount: 0
}, action){
  switch (action.type) {
    case actions.ORDERS_LOADER:
    return {
      ...state,
      orderLoader: action.payload
    }
    case actions.ORDER_DUE_AMOUNT:
    return {
      ...state,
      dueAmount: action.payload
    }
    case actions.ORDERS_LIST:
    return {
      ...state,
      ordersList: action.payload
    }
    case actions.ORDERS_DETAIL:
    return {
        ...state,
        orderDetail: action.payload
    }
    default:
      return state;
  }
}
