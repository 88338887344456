import React from 'react'
import { imgStoragePath } from '../../../constants'
import { Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

class PaymentCardList extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      dropdownOpen: false
    }
  }

  toggleDropdown = () =>{
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  render(){
    return(
      <div className={this.props.selected == this.props.card.id ? "card-list-item payment-list-item-row row active" : "card-list-item payment-list-item-row row"}>
        <div className="col-md-2">
          <img src={`${imgStoragePath}phase-2/cards/${this.props.card.brand.toLowerCase().replace(' ','')}.png`} />
        </div>
        <div className="card-number col-md-10">
            <span>
              XXXX XXXX XXXX {this.props.card.last4}
              <span className="edit-menu">
                <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggleDropdown()}>
                  <DropdownToggle>
                    <img className="edit-icon" src={imgStoragePath+'phase-2/license_edit.png'} onClick={() => this.toggleDropdown()}/>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.props.getCardDetail()}><h4>Edit</h4></DropdownItem>
                    {
                      this.props.selected != this.props.card.id ?
                        <DropdownItem divider/>
                      :null
                    }
                    {
                      this.props.selected != this.props.card.id ?
                        <DropdownItem onClick={() => this.props.selectCard()}><h4>Set as Default</h4></DropdownItem>
                      :null
                    }
                    {
                      this.props.selected != this.props.card.id ?
                        <DropdownItem divider/>
                      :null
                    }
                    {
                      this.props.selected != this.props.card.id ?
                        <DropdownItem onClick={() => this.props.deleteCard()}><h4>Remove</h4></DropdownItem>
                      :this.props.selected  == this.props.card.id && !this.props.activeSubscription ?
                        <DropdownItem onClick={() => this.props.deleteCard()}><h4>Remove</h4></DropdownItem>
                      :null
                    }
                  </DropdownMenu>
                </Dropdown>
              </span>
              {
                this.props.selected == this.props.card.id ?
                  <span className={this.props.selected == this.props.card.id ? 'default-card' : 'non-default-card'}>Default</span>
                :null
              }
            </span>
            <span className="expiry-date">Expires On: {this.props.card.exp_month > 9 ? this.props.card.exp_month : '0' + this.props.card.exp_month}/{this.props.card.exp_year}</span>
        </div>

      </div>
    )
  }
}

export default PaymentCardList
