import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import moment from 'moment'
import { float2DecRegx, onlyNumeric } from '../../../../constants'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
import { checkString,  checkValidDate, checkIntNumber, checkNumber } from '../../../../formValidator';

export default class AddEditShopVisit extends Component{
  constructor(props){
    super(props);
    this.state = {
      afAssembly: {},
      error: {}
    }
  }
  componentDidMount() {
    let data = Object.assign({}, this.props.shopVisitObj);
    this.setState(prevState => ({
      ...prevState,
      afAssembly: data
    }));
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      afAssembly: {
        ...prevState.afAssembly,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditShopVisit = (e) => {
    e.preventDefault();
    const  { cost, tsn, csn, event_id, shop_visit_date } = this.state.afAssembly;
    const validateNewInput = {
      event_id: checkString({value: event_id, required: true, minLength: '', maxLength: '', message: 'Please Select The Event'}),
      shop_visit_date: checkValidDate({value: shop_visit_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Shop Visit Date'}),
      cost: checkNumber({value: cost ? (cost.toString()).replace(/ /g,'') : '', required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Cost'}),
      tsn: checkNumber({value:  tsn ? (tsn.toString()).replace(/ /g,'') : '', required: true, minLength: '', maxLength: '', message: 'Please Enter Valid TSN'}),
      csn: checkNumber({value: csn ? (csn.toString()).replace(/ /g,'') : '' , required: true, minLength: '', maxLength: '', message: 'Please Enter Valid CSN'}),

    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.toggleEditSideBar();
      this.props.addEditShopVisit(this.state.afAssembly, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  render(){
    const { type, eventTypes, assemblyType, loader } = this.props;
    const { afAssembly, error } = this.state;
    if(!Object.keys(afAssembly).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditShopVisit(e)}>
       <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
         <Row>
           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                 {
                    assemblyType=='airframe'?
                      'Select Maintenance Event' :
                    assemblyType == 'apu'?
                      'Select APU' :
                    assemblyType == 'lg'?
                      'Select Landing Gear' :
                    assemblyType == 'engine'?
                      'Select Engine' :
                    'Select Major Assembly'
                  }
                 { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldSelect
                 placeholder=''
                 keyParam="event_id"
                 value={afAssembly.event_id}
                 type={type}
                 options={eventTypes && eventTypes.length ? eventTypes : [] }
                 updateField={this.updateForm}
                 labelKey= 'event_name'
                 valueKey='event_id'
               />
               <h6 className="error-msg">{error.event_id}</h6>
             </FieldGroup>
           </FieldCol>

           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
               {assemblyType=='airframe'? 'Event' : 'Shop Visit'} Date
               { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldDate
                 value={afAssembly.shop_visit_date}
                 type={type}
                 keyParam="shop_visit_date"
                 updateField={this.updateForm}
                 maxDate= {moment()}
                 readOnly={true}
               />
             <h6 className="error-msg">{error.shop_visit_date}</h6>
             </FieldGroup>
           </FieldCol>
           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
               {assemblyType=='airframe'? 'Event' : 'Shop Visit'} Cost (USD)
               { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldInput
                 value={afAssembly.cost}
                 type={type}
                 keyParam="cost"
                 updateField={(key, value) => float2DecRegx.test(value) ? this.updateForm(key, value) : null}
               />
             <h6 className="error-msg">{error.cost}</h6>
             </FieldGroup>
           </FieldCol>
           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">{assemblyType=='airframe'? 'Event' : 'Shop Visit'} Reason</FieldLabel>
               <FieldTextarea
                 value={afAssembly.removal_reason}
                 type={type}
                 keyParam="removal_reason"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>
           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">
                 <input type="checkbox" onChange={(evt) => this.updateForm('prsv', evt.target.checked ? 1 : 0)} checked={afAssembly.prsv === 1}/>
                 &nbsp;&nbsp;PRSV Event
               </FieldLabel>
             </FieldGroup>
           </FieldCol>

           <FieldCol md="12">
             <FieldGroup className="form-group">
               <FieldLabel className="label">{assemblyType=='airframe'? 'Event' : 'Shop Visit'} Workscope / Details</FieldLabel>
               <FieldTextarea
                 value={afAssembly.workscope_details}
                 type={type}
                 keyParam="workscope_details"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>

           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">{assemblyType=='airframe'? 'Event' : 'Shop Visit'} Facility</FieldLabel>
               <FieldInput
                 value={afAssembly.shop_visit_facility}
                 type={type}
                 keyParam="shop_visit_facility"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>

           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">Facility Location</FieldLabel>
               <FieldInput
                 value={afAssembly.facility_location}
                 type={type}
                 keyParam="facility_location"
                 updateField={this.updateForm}
               />
             </FieldGroup>
           </FieldCol>

           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">TSN at {assemblyType=='airframe'? 'Event' : 'Shop Visit'}
               { type != 'view' ? <sup className="reqStar"> * </sup>:null}

               </FieldLabel>
               <FieldInput
                 value={afAssembly.tsn}
                 type={type}
                 keyParam="tsn"
                 updateField={(key,value) => float2DecRegx.test(value) ? this.updateForm(key,value) : null}
               />
               <h6 className="error-msg">{error.tsn}</h6>

             </FieldGroup>
           </FieldCol>
           <FieldCol md="6">
             <FieldGroup className="form-group">
               <FieldLabel className="label">CSN at {assemblyType=='airframe'? 'Event' : 'Shop Visit'}
               { type != 'view' ? <sup className="reqStar"> * </sup>:null}
               </FieldLabel>
               <FieldInput
                 value={afAssembly.csn}
                 type={type}
                 keyParam="csn"
                 updateField={(key,value) => onlyNumeric.test(value) ? this.updateForm(key,value) : null}
               />
              <h6 className="error-msg">{error.csn}</h6>

             </FieldGroup>
           </FieldCol>
         </Row>
        </div>
       { type != 'view' ?
         <div className="submit-block">
            <input type="submit" className="primary-btn" disabled={loader} value={loader ? 'Processing...':'Save Changes'}/>
            <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
         </div>:null
       }
     </form>
    )
  }
}
