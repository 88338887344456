import React, { Component } from 'react';
import { Link } from 'react-router';
import DropToUpload from 'react-drop-to-upload';
import { imgStoragePath } from '../../../../constants';
export default class FileDrop extends Component {
  constructor(props) {
    super(props);
    this.handleDrop = this.handleDrop.bind(this);
    this.state = {
      handleDrag: false
    }
  }
  handleDrop(files) {
    this.props.confirmModal(files);
    this.setState({
      handleDrag: false
    })
  }
  onDragOver = () => {
    this.setState({
      handleDrag: true

    })
  }
  onDragLeave = () => {
    this.setState({
      handleDrag: false

    })
  }
  render() {
    const { type } = this.props;
    return (
      <DropToUpload
        onDrop={ this.handleDrop }
        onOver= { this.onDragOver}
        onLeave = {this.onDragLeave}
      >
        { type == "newUpload" ?
           <div className="ml-modal-upload"
            style={ this.state.handleDrag ?  { background : 'rgba(0,0,0,0.3)', transition: 'all 0.4s ease', opacity: '0.2'} : {background: 'transparent', opacity: '1'}}
           >
            <h3 className="gama">Save time and autofill data from documents</h3>
            <p className="para-ui">
              Our Data Extractor will help you extract data from your Lease Agreements and <br/> Technical Acceptance documents and autofill your data points
            </p>
            <img src={imgStoragePath+"ml_modal.png"} className="ml-upload-img" alt="img"/>
            <h5>Drag and drop your files here <br/> or</h5>
            <Link className="upload-file primary-btn">Upload Files
              <input type="file" onChange={(e) => this.props.confirmClickModal(e.target.files)} multiple/>
            </Link>
          </div> :
          <div className="drag-drop-block" style={ this.state.handleDrag ?  { background : 'rgba(0,0,0,0.3)', transition: 'all 0.4s ease'} : {background: 'transparent'}}>
            <div>
              <p className="caption-ui">Drag and drop your files here  or
                <Link className="primary-btn">Upload Files
                  <input type="file" onChange={(e) => this.props.confirmClickModal(e.target.files)} multiple/>
                </Link>
              </p>
            </div>
          </div>
        }
      </DropToUpload>
    );
  }
}
