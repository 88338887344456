import React, { Component } from 'react';
import { Row, Col,ModalHeader, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import GenTabField from './GenTabField';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, utilizationFormat, imgStoragePath } from '../../../../constants';
import { utilizationImportApi, updateUtilizationApi, exportUtilzationApi } from '../apiServices';
import { ListLoader } from '../../../../shared'
import WhiteImport from '../../../../assets/img/NewIcon.png'
import moment from 'moment';
export default class OverViewUtilization extends Component{
  constructor(props){
    super(props)
    this.state = {
      importedData:[],
      postBody:{},
      utilizationsModal:false,
      pageLoader:false,
      responseMessage:''
    }
    this.utilizationImportApi = utilizationImportApi.bind(this)
    this.updateUtilizationApi = updateUtilizationApi.bind(this)
    this.exportUtilzationApi = exportUtilzationApi.bind(this)
  }
 render(){
  const { utilization, timeUnit, importAllowed, fileKey, assembly, slug, refresh  } = this.props;
  const { utilizationsModal, importedData, pageLoader, postBody, responseMessage} = this.state
  let errorsArr = []
  const imporDataError=importedData.length ? importedData.map(item => {
    return errorsArr.push(item.errors)
  }) : null
  let errorsFlagArr= errorsArr.map(item =>  Object.keys(item).length ? true : false)
  console.log('month', moment(`${postBody.year}-${postBody.month}`).format('MMMM , YYYY'));

  if(utilization){
    return(
      <div className="data-invoice-block margin-bottom-10">
        {pageLoader ? <ListLoader /> :null}
        <Row>
          <Col md={6}>
            <h5 className="para blue-title">Utilization {utilization.last_utilization_updated ? '( As of Date : '+ moment(utilization.last_utilization_updated).format(displayDateFormat) +' )': null }
              {importAllowed ?
                <span style={{width:'40px', position:'relative', cursor:'pointer', marginLeft:'10px',}}>
                  <a style={{color:'white', cursor:'pointer'}}>
                  Import <img width='20' style={{width: "16px",marginLeft: "3px",marginBottom: "5px" , cursor:'pointer'}} src={WhiteImport}/>
                  <span><input id={fileKey} type="file" accept='.xls, .xlsx, .csv' onChange={(e)=> this.utilizationImportApi(this.props, assembly, slug, e.target.files)} className="custom-file-input" style={{cursor:"pointer", position:'absolute',top:'-10px' , right:'0px'}} /></span>
                  </a>
                </span> : null
              }
              {
                importAllowed ?
                <span style={{width:'40px', position:'relative', cursor:'pointer', marginLeft:'10px',}}>
                  <a style={{color:'white', cursor:'pointer'}} onClick={() => this.exportUtilzationApi(this.props, assembly, slug, utilization)}>
                  Export <img width='20' style={{width: "16px",marginLeft: "3px",marginBottom: "5px" , cursor:'pointer'}} src={imgStoragePath+"export_icon.png"}/>
                  </a>
                </span> : null
              }
            </h5>
        </Col>
        </Row>
        <Row>
        <GenTabField grid="2" classText = "horizontal-inner-block margin-bottom-10 " label="Utilization Unit Type" text={ utilization.time_unit && timeUnit && timeUnit.length ? timeUnit.filter(unit => unit.value == utilization.time_unit)[0].label : 'Decimal'}/>
          <GenTabField grid="2" classText = "horizontal-inner-block margin-bottom-10 " label="TSN" text={ utilization.tsn }/>
          <GenTabField grid="2" classText = "horizontal-inner-block margin-bottom-10 " label="CSN" text={ utilization.csn }/>
          <GenTabField grid="2" classText = "horizontal-inner-block margin-bottom-10 " label="Avg. Monthly Hours" text={<NumericLabel params={utilizationFormat}>{utilization.average_monthly_hours ? utilization.average_monthly_hours : 0}</NumericLabel>}/>
          <GenTabField grid="2" classText = "horizontal-inner-block margin-bottom-10 " label="Avg. Monthly Cycles" text={ utilization.average_monthly_cycles}/>
        </Row>
        {
          utilizationsModal ?
          <Modal isOpen={utilizationsModal} toggle={() => this.setState({utilizationsModal: false})} >
            <ModalHeader toggle={() =>  this.setState({utilizationsModal:false})}>Import Utilization {postBody.year && postBody.month ? `(As of : ${moment(`${postBody.year}-${postBody.month}`).format('MMMM , YYYY')})` : null }
            </ModalHeader>
            <ModalBody>
              <div className="asset-listing-wrap utilization-history" style={{marginLeft:'0px'}} >
                <div className="asset-list-filter-sort">
                  <Row className="filter-block">
                    <Col md={6}><h6>Major Assembly</h6></Col>
                    <Col md={3}><h6>TSN ( Hrs )</h6></Col>
                    <Col md={3}><h6>CSN ( Cycles )</h6></Col>
                  </Row>
                </div>
                <div className="asset-list-util" style={{margin:'0px',padding:'0px'}}>
                  {importedData && importedData.length ? importedData.map((util, index) =>
                    <Row className="flex-centered cashflow-row-util" >
                      <Col className="cash-info para-ui border-col" md={6}>
                        {util.name} <br />
                        {util.errors && util.errors.name ?
                            <span style={{fontSize: '10px', color:'#ff0000', clear:'both', display:'block',}}>{util.errors.name }</span>
                          :null
                        }
                      </Col>
                      <Col className="cash-info para-ui border-col" md={3}>
                        {util.tsn}
                        {util.errors && util.errors.tsn ?
                          <span style={{fontSize: '10px', color:'#ff0000', clear:'both', display:'block',}}>{util.errors.tsn }</span>
                          :null
                        }
                      </Col>
                      <Col className="cash-info para-ui border-col" md={3}>
                          {util.csn}
                        {util.errors && util.errors.csn ?
                          <span style={{fontSize: '10px', color:'#ff0000', clear:'both', display:'block',}}>{util.errors.csn }</span>
                          :null
                        }
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {
                errorsFlagArr.includes(true) ?
                <span style={{float:'right', fontSize:'12px'}}>{responseMessage}</span>
                : null
              }
              {errorsFlagArr.includes(true) ? null :
                <Button color="primary"  onClick={() =>  this.updateUtilizationApi(this.props, assembly,slug, postBody )}>Import</Button>
              }
              <Button color="secondary" onClick={() => {this.setState({utilizationsModal:false})} }>Cancel</Button>
            </ModalFooter>
          </Modal> : null
        }
       </div>
      )
    }else{
        return <h5 className="text-center">Loading...</h5>
      }
   }
}
