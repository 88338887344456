import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString,checkDateDiff } from '../../../../formValidator';
export default class AddEditQfdEvent extends Component {
  constructor(props){
    super(props);
    this.state = {
      qfdMntEvent: {},
      error: {}
    }
  }
  componentDidMount(){
    let data  = Object.assign({}, this.props.qfdMntEventObj);
    if(this.props.type != 'add'){
      data = {
        ...data,
        reserve_account: this.props.qfdMntEventObj.reserve_account.id,
      }
    }
    this.setState({
      qfdMntEvent: data
    })
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      qfdMntEvent: {
        ...prevState.qfdMntEvent,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }

  addEditQfdMntEvent = (e) => {
    e.preventDefault();
    const validateNewInput = {
      reserve_account: checkString({value: this.state.qfdMntEvent.reserve_account, required: true, minLength: '', maxLength: '', message: 'Please Select Maintenance Reserve Account'}),
      qualified_items: checkString({value: this.state.qfdMntEvent.qualified_items, required: true, minLength: '', maxLength: '', message: 'Please Enter The Qualified items'}),
      end_date:checkDateDiff({value:this.state.qfdMntEvent.start_date,required:false},{value:this.state.qfdMntEvent.end_date,required:false}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditQfdMntEvent(this.state.qfdMntEvent , this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { reserveAcntList, type, qfdMntEventLoader } = this.props;
    const { qfdMntEvent, error } = this.state;
    let reserveList = [];
    if(Object.keys(reserveAcntList).length){
      reserveAcntList.map((item, index) => {
        reserveList.push({id: item['id'], name: item['name'] })
      });
    }
    return (
      <form onSubmit={(e) => this.addEditQfdMntEvent(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Maintenance Reserve Account
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Reserve Account'
                  keyParam="reserve_account"
                  value={ qfdMntEvent.reserve_account }
                  type={type}
                  options={reserveList}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                  name ={type != 'add' ? this.props.qfdMntEventObj.reserve_account.name: ''}
                />
              <h6 className="error-msg">{error.reserve_account}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Event</FieldLabel>
                <FieldTextarea
                  value={qfdMntEvent.event}
                  type={type}
                  keyParam="event"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Qualified Items
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldTextarea
                  value={qfdMntEvent.qualified_items}
                  type={type}
                  keyParam="qualified_items"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.qualified_items}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Validity	Start Date</FieldLabel>
                <FieldDate
                  value={qfdMntEvent.start_date}
                  type={type}
                  keyParam="start_date"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Validity	End Date</FieldLabel>
                <FieldDate
                  value={qfdMntEvent.end_date}
                  type={type}
                  keyParam="end_date"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.end_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={qfdMntEvent.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input disabled={qfdMntEventLoader} type="submit" className="primary-btn" value={qfdMntEventLoader ? 'processing...': 'Save Changes'}/>
            <Link onClick={this.props.toggleEditSideBar} >Cancel</Link>
          </div>:null
        }
    </form>
    )
  }
}
