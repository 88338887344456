import { globalGetService } from '../../../globalServices'
import * as actions from '../actions'

export const getStorageUsageListAc = (filters) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.STORAGE_USAGE_LOADER,
      payload: true
    })
    globalGetService('/console/license/storage/usage/list/', filters)
    .then(response => {
      if(response.data.statusCode ==200){
        dispatch({
          type: actions.STORAGE_USAGE_LIST,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.STORAGE_USAGE_LOADER,
        payload: false
      })
    })
  }
}
