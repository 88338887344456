import * as actions from '../actions';
import { globalGetService, globalPostService, globalExportService } from '../../../../globalServices/';
import { downloadFileType, toastFlashMessage } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
import { CTR_SHORT_INFO } from '../../Listing/actions';
import { MR_ACCRUALS_GRAPH, UPDATE_SIML_RESULT } from '../../../maintenance/MRFinanceGraph/actions';
import {monthlyAccuralExportPostApi} from '../../../maintenance/MRFinanceGraph/services'
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const loadMrAccuralAc = (params,type) => {
  return (dispatch) => {
    dispatch({
      type: actions.MR_ACCURAL_LOADER,
      payload: true
    });
    globalGetService('console/contract/'+params.type+'/'+params.aircraft_slug + '/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        });
        let assetViewId = response.data.data
        trackActivity('Page Visited',{
          page_title: 'MR Accruals',
          application_module: 'Contracts',
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
        })
        if(type=='mr-projection'){
          dispatch(accrualsProjectionAc(response.data.data.contract.slug));
        }else{
          dispatch(accrualsCashFowAC(response.data.data.contract.slug));
        }
      }
    })
  }
}




export const accrualsProjectionAc = (contract_slug) => {
  return (dispatch) => {
    globalGetService('contracts/' +contract_slug+ '/mr-projection/', {})
    .then(response => {
      dispatch({
        type: actions.MR_ACCURAL_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        if(Object.keys(response.data.data.error).length){
          toastFlashMessage( response.data.data.error[Object.keys(response.data.data.error)[0]], 'error');
        }
        dispatch({
          type: MR_ACCRUALS_GRAPH,
          payload: response.data.data
        })
      }
    })
  }
}

export const accrualsCashFowAC = (contract_slug) => {
  return (dispatch) => {
    globalGetService('contracts/' +contract_slug+ '/mr-projection/monthly-cash-flow/', {})
    .then(response => {
      dispatch({
        type: actions.MR_ACCURAL_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MR_ACCRUAL_CASH_FLOW,
          payload: response.data.data
        })
      }
    })
  }
}


export const mrAccuralexportAc = (contract_slug, filter=null) => {
  return (dispatch) => {
    dispatch({
      type: actions.MR_ACCURAL_LOADER,
      payload: true
    });
    let params = {};
    params ={
      ...params,
      download: 'xls'
    }
    if(filter != null) {
      if(filter.month && filter.month != ''){
        params = {
          ...params,
          year: filter.month
        }
      }
      if(filter.name && filter.name != ''){
        let str = filter.name.split(/[# ]+/).join('');
        params = {
          ...params,
          reserve_account: str
        }
      }
    }
    globalExportService('contracts/'+contract_slug+'/mr-projection/monthly-cash-flow/',params)
    .then(response => {
      dispatch({
        type: actions.MR_ACCURAL_LOADER,
        payload: false
      });
      downloadFileType(response, 'MR_Accrual_Monthly_Cashflow.', 'xls')
    });
  }
}

export const cashFlowListPostAc = (id,data) => {
  data={
    ...data,
    monthly_cash_flow:true
  }
  return (dispatch) => {
    dispatch({
      type: actions.MR_ACCURAL_LOADER,
      payload: true
    });
    const newdata = JSON.parse(JSON.stringify(data));
    if(newdata.apu){
      delete newdata['apu']['plots'];
    }
    if(newdata.life_on_wings){
      delete newdata['life_on_wings'];
    }
    if(newdata.landing_gears){
      Object.keys(newdata.landing_gears).map(label => {
        delete newdata['landing_gears'][label]['plots'];
      });
    }
    if(newdata.airframe){
      Object.keys(newdata.airframe).map(label => {
        delete newdata.airframe[label].plots;
      })
    }
    if(newdata.engines){
      Object.keys(newdata.engines.engine_data).map(label => {
        delete newdata.engines.engine_data[label].plots;
      })
    }
    globalPostService('contracts/' +id+ '/simulation/', newdata)
    .then(response => {
      dispatch({
        type: actions.MR_ACCURAL_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASH_FLOW_MONLTY_BREAKUP_LIST,
          payload: response.data.data
        })
        dispatch({
          type: actions.MONTHLY_TOGGLE_DATA,
          payload: true
        })
      }
    })
  }



}


export const monthlyBreakDownExportPostAc = (id,data, filter=null) => {
  data={
    ...data,
    monthly_cash_flow:true,
    download:true
  }
  if(filter != null) {
    if(filter.month && filter.month != ''){
      data = {
        ...data,
        year: filter.month
      }
    }
    if(filter.name && filter.name != ''){
      let str = filter.name.split(/[# ]+/).join('');
      data = {
        ...data,
        reserve_account: str
      }
    }
  }
  return (dispatch) => {
    dispatch({
      type: actions.MR_ACCURAL_LOADER,
      payload: true
    });
    monthlyAccuralExportPostApi(id,data)
    .then(response => {
      dispatch({
        type: actions.MR_ACCURAL_LOADER,
        payload: false
      });
    })
  }
}



export const accrualsSimulationAc = (contract_slug, data, ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.MR_ACCURAL_LOADER,
      payload: true
    });
    const newdata = JSON.parse(JSON.stringify(data));
    if(newdata.apu){
      delete newdata['apu']['plots'];
    }
    if(newdata.life_on_wings){
      delete newdata['life_on_wings'];
    }
    if(newdata.landing_gears){
      Object.keys(newdata.landing_gears).map(label => {
        delete newdata['landing_gears'][label]['plots'];
      });
    }
    if(newdata.airframe){
      Object.keys(newdata.airframe).map(label => {
        delete newdata.airframe[label].plots;
      })
    }
    if(newdata.engines){
      Object.keys(newdata.engines.engine_data).map(label => {
        delete newdata.engines.engine_data[label].plots;
      })
    }
    globalPostService('contracts/' +contract_slug+ '/simulation/', newdata)
    .then(response => {
      dispatch({
        type: actions.MR_ACCURAL_LOADER,
        payload: false
      });
      let assetViewId = ctrShortInfo
      trackActivity('Simulation',{
        page_title: 'MR Accruals',
        application_module: 'Contracts',
        asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: UPDATE_SIML_RESULT,
          payload: response.data.data
        })
      }
    })
  }
}
