import React, { Component } from 'react';
import { Collapse,  Card, CardBody} from 'reactstrap';
import { imgStoragePath } from '../../../constants';
export default class CollapseTeWrapper extends Component {
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }
    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
    render(){
        const{ title }= this.props;
        return(
            <div className="form-collapse-wrapper">
                <h6 className="form-collapse-title"  onClick={this.toggle}>
                    {title}
                   <img  src={imgStoragePath + (this.state.collapse ? "up_arrow.png":"down_arrow.png")} alt="img"/>
                </h6>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>
                            {this.props.children}
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        )
    }
}