import * as actions from '../actions';
import { globalGetService, globalFileUploadPutService, globalPostService, globalDeleteService, globalFileUploadService, globalPutService } from '../../../../../globalServices/';
import { toastFlashMessage, getDataUri, dataURItoBlob } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { TOGGLE_T007_T004 } from '../../T007/actions';
import { trackActivity } from '../../../../../utils/mixpanel';
const auditFindingInfo = {
  pictures: [],
  title: '',
  finding_parameters: '',
  finding_type: '',
  arm_srm_reference: '',
  dd_wo_reference: '',
  remarks: '',
  corrective_actions:'',
  classifications:'',
  category:'',
  repair_type:'',
  intervals:''
}
export const fetchAuditsSectionsAc = (params) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/workorder/'+params.workOrderSlug+'/audit-report-sections/', {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AUDITS_SECTIONS,
          payload: response.data.data
        })
      }
    })
  }
}

export const auditSectionInfoAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService(`technical/workorder/${props.params.workOrderSlug}/section/${data.id}/questions/`, {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AUDITS_SECTIONS_INFO,
          payload:{flag: true, title: response.data.data.length > 1 ? data.name:response.data.data[0].name, sectionId: data.id , type: 'edit', data: response.data.data}
        })
      }
    })
  }
}

export const auditSectionInfoSaveAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalPostService(`technical/workorder/${props.params.workOrderSlug}/section/${data[0].id}/questions/`, {data : data})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TOOGLE_AUDIT_TAB,
          payload:{}
        })
        toastFlashMessage(response.data.message, 'success');
        dispatch(fetchAuditsSectionsAc(props.params))
        dispatch(getFormDetailsAc(props));
        trackActivity('Observation Submitted', {
          page_title: `T004`,
          application_module: 'Inspection',
          item_type:'Inspection Report',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          item_name: data[0].name,
          item_id: data[0].id
        })
      }
    })
  }
}

export const editFindingImageSaveAc = (props, data, type, fileArr, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: 'UPDATE_IMAGE_IN_EDIT',
        payload: data
      });
    }else{
      dispatch(triggerLoader(true));
      var formData = new FormData();
      for (let i = 0; i < fileArr.length; i++) {
        let file = fileArr[i];
        if (!file.type.match('image.*')) {
          toastFlashMessage( file.name + ' is not Valid Format', 'error');
           continue;
         }
       formData.append('files[]', file, file.name);
      }
      var config = {
        onUploadProgress: function(progressEvent) {
            var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          }
      }
      globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+data.id+'/', formData, config)
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: 'UPDATE_IMAGE_IN_EDIT',
            payload: response.data.data.images
          });
          dispatch(usageDetailAc())
          toastFlashMessage(response.data.message, 'success');

        }
        dispatch(triggerLoader(false));
      })
    }

  }
}


export const deleteImageAc = (props, picId, picIndex, type) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.DELETE_ADD_IMAGE,
        payload: {picId: picId, picIndex: picIndex}
      })
    }else{
      dispatch(triggerLoader(true));
      globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-picture/'+picId+'/', {})
      .then(response => {
        dispatch(triggerLoader(false));
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.DELETE_IMAGE,
            payload: picId
          })
          toastFlashMessage(response.data.message, 'success');

        }
        dispatch(triggerLoader(false));
      })
    }

  }
}

export const editImageAc = (props, picId) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-picture/'+picId+'/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.EDIT_IMAGE,
          payload: ''
        })
        toastFlashMessage(response.data.message, 'success');

      }
      dispatch(triggerLoader(false));
    })
  }
}

export const changePictureAc = (props,picId, fileArr, type ) => {
  if(type == 'add'){
    return (dispatch) => {
    dispatch({
      type: actions.UPDATE_ADD_IMAGE,
      payload: fileArr
    })
  }
  }else{
    var formData = new FormData();
    if (fileArr[0].type.match('image.*')) {
      formData.append('file', fileArr[0], fileArr[0].name);
     }else{
       return false
     }

    if(!formData.get('file')){
      return false;
     }
     var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          // dispatch({
          //   type: actions.PROGRESS_PERCENTAGE,
          //   payload: percentage
          // });
        }
    }
    return (dispatch) => {
      dispatch(triggerLoader(true));
      globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/inspection-finding-picture/'+picId+'/', formData, config)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.CHANGE_IMAGE,
            payload: response.data.data
          })
          toastFlashMessage(response.data.message, 'success');

        }
        dispatch(triggerLoader(false));
      })
    }
  }

}

export const deleteFindingAc = (props, findingId, sectionId) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalDeleteService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+findingId+'/', {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.DELETE_FINDING_T004,
          payload: { findingId: findingId, sectionId: sectionId, finding_count: response.data.data.finding_count}
        })
        toastFlashMessage(response.data.message, 'success');
        trackActivity('Finding Deleted', {
          page_title: `T004`,
          application_module: 'Inspection',
          item_type:'Inspection Report',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          item_id: findingId
        })
      }
      dispatch(triggerLoader(false));
    })
  }
}



export const auditFindingDetailAc = (props, type, data = null, sectionId) => {
   return (dispatch) => {
     if(type == 'add'){
       dispatch({
         type: actions.AUDITS_FINDING_INFO,
         payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
       })
     }else{
       dispatch(triggerLoader(true));
       globalGetService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+data.id+'/', {})
       .then(response => {
         if(response.data.statusCode == 200){
           dispatch({
             type: actions.AUDITS_FINDING_INFO,
             payload: {type: 'edit', flag: true, data: response.data.data, sectionId:  sectionId }
           })
         }
         dispatch(triggerLoader(false));
       })
     }
   }
 }



export const editFindingSaveAc = (params, image, imageId) =>{
  let formData = new FormData();
  return(dispatch) => {
    dispatch(triggerLoader(true));
    dataURItoBlob(image).then(imageAnnotate => {
      formData.append('file', imageAnnotate);
      globalPutService(`technical/workorder/${params.workOrderSlug}/inspection-finding-picture/${imageId}/`,formData)
      .then((response) => {
        if(response.data.statusCode == 200){
          dispatch(
            {
              type: actions.EDIT_FINDING,
              payload: {title: '', flag: false, imageUrl: ''}
            }
          );
          dispatch(
            {
              type: actions.AUDITS_FINDING_INFO,
              payload: {title: '', flag: false, imageUrl: ''}
            }
          );
          toastFlashMessage(response.data.message, 'success');

        }
        dispatch(triggerLoader(false));
      })
    })
  }
}

export const findingAddEditT004Ac = (props, data, type, fileArr, sectionId) => {
  console.log('data', data);
  return (dispatch) => {
    dispatch(triggerLoader(true));
    var formData = new FormData();
    for (let i = 0; i < fileArr.length; i++) {
      let file = fileArr[i];
      if (!file.type.match('image.*')) {
        toastFlashMessage( file.name + ' is not Valid Format', 'error');
         continue;
       }
     formData.append('files[]', file, file.name);
    }
    formData.append('arm_srm_reference', data.arm_srm_reference);
    formData.append('damage_dimension', data.damage_dimension);
    formData.append('damage_limit', data.damage_limit);
    formData.append('dd_wo_reference', data.dd_wo_reference);
    formData.append('finding_type', data.finding_type);
    formData.append('remarks', data.remarks);
    formData.append('corrective_actions', data.corrective_actions);
    formData.append('classifications',data.classifications);
    formData.append('category', data.category);
    formData.append('repair_type',data.repair_type);
    formData.append('intervals', data.intervals);


    var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        }
    }

    if(type=='add'){
      globalPostService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/', formData, config)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ADD_FINDINGS_T004,
            payload: {sectionId:  sectionId , data: response.data.data}
          });

          toastFlashMessage(response.data.message, 'success');
          trackActivity('Finding Added', {
            page_title: `T004`,
            application_module: 'Inspection',
            item_type:'Inspection Report',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            finding_type:data.finding_type,
            aircraft_section: data.aircraft_section,
            item_id: data.id
          })
        }
        dispatch(triggerLoader(false));
        dispatch({type:TOGGLE_T007_T004, payload:{}})
      })
    }else{
      globalFileUploadPutService('technical/workorder/'+props.params.workOrderSlug+'/aircraft-section/'+sectionId+'/inspection-findings/'+data.id+'/', formData, config)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.UPDATE_FINDINGS_T004,
            payload: {sectionId:  sectionId , data: response.data.data}
          });
          toastFlashMessage(response.data.message, 'success');
          trackActivity('Finding Edited', {
            page_title: `T004`,
            application_module: 'Inspection',
            item_type:'Inspection Report',
            workorder_slug: props.params.workOrderSlug,
            form_slug: props.params.formSlug,
            finding_type:data.finding_type,
            aircraft_section: data.aircraft_section,
            item_id: data.id
          })
        }
        dispatch(fetchAuditsSectionsAc(props.params))
        dispatch(triggerLoader(false));
        dispatch({type:TOGGLE_T007_T004, payload:{}})
      })
    }
    dispatch(getFormDetailsAc(props));
  }
}

export const uploadCoverImgAc = (props, file) => {
  if (!(file[0]['type']).match('image.*')) {
    toastFlashMessage( file.name + ' is not Valid Format', 'error');
   }
  var formData = new FormData();
  formData.append('file',file[0], file[0]['name']);
  var config = {
    onUploadProgress: function(progressEvent) {
        var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalFileUploadService('technical/workorder/'+props.params.workOrderSlug+'/inspection-cover-image/', formData, config)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.COVER_IMAGE,
          payload: {data: response.data.data, flag: false}
        });
        dispatch(usageDetailAc())
        dispatch(getFormDetailsAc(props));
        trackActivity('Cover Photo Uploaded', {
          page_title: `T004`,
          application_module: 'Inspection',
          item_type:'Inspection Report',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          file_type: file[0].file_type,
          file_name: file[0].file_name
        })
      }
    })
  }
}

export const uploadLopaImgAc = (props, file) => {

  if (!(file[0]['type']).match('image.*')) {
    toastFlashMessage( file.name + ' is not Valid Format', 'error');
   }
  var formData = new FormData();
  formData.append('file',file[0], file[0]['name']);
  formData.append('shop_visit', true);
  var config = {
    onUploadProgress: function(progressEvent) {
        var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      }
  }
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalFileUploadService('/technical/engine-workorder/file-upload/', formData, config)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LOPA_IMAGE,
          payload:response.data.url
        });
        dispatch(getFormDetailsAc(props));
      }
    })
  }
}



export const getCoverImgAc = (props) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/inspection-cover-image/', {})
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.COVER_IMAGE,
          payload: {data: response.data.data.lopa_image_url, flag: true}
        })
      }
    })
  }
}


export const UpdateIntroductionQaAc = (props, data, type) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalPutService('technical/workorder/'+props.params.workOrderSlug+'/inspection-qa/'+data.id+'/', data)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.QUESTION_UPDATE,
          payload: {flag: false, data: response.data.data }
        })
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch(AddEditIntroductionQaAc(props))
      dispatch(triggerLoader(false));
      dispatch(getFormDetailsAc(props));
    })
  }
}


export const AddEditIntroductionQaAc = (props, dataId) => {
  
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/inspection-qa/', {})
    .then(response => {
      if(response.data.statusCode == 200){

        dispatch({
          type: actions.FETCH_QUESTIONS,
          payload: {data: response.data.data}
        })
      }
      dispatch(triggerLoader(false));
    })
  }
}




export const UpdateDocsReviewQaAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.AUDITS_FINDING_INFO,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalPutService('workorder/'+props.params.workOrderSlug+'/inspection-qa/'+sectionId, {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AUDITS_FINDING_INFO,
            payload: {type: 'edit', flag: true, data: data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
        dispatch(getFormDetailsAc(props));
      })
    }
  }
}


export const AddEditDocsReviewQaAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.FORM_INTRODUCTION,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/audit-report-sections/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FORM_INTRODUCTION,
            payload: {type: 'edit', flag: true, data: data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
      })
    }
  }
}



export const UpdateReviewEditorQaAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.AUDITS_FINDING_INFO,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalPutService('workorder/'+props.params.workOrderSlug+'/inspection-qa/'+sectionId, {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AUDITS_FINDING_INFO,
            payload: {type: 'edit', flag: true, data: data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
        dispatch(getFormDetailsAc(props));
      })
    }
  }
}


export const AddEditReviewEditorQaAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.FORM_INTRODUCTION,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/audit-report-sections/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FORM_INTRODUCTION,
            payload: {type: 'edit', flag: true, data: data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
      })
    }
  }
}



export const UpdateConclusionsQaAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.AUDITS_FINDING_INFO,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalPutService('workorder/'+props.params.workOrderSlug+'/inspection-qa/'+sectionId, {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AUDITS_FINDING_INFO,
            payload: {type: 'edit', flag: true, data: data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
        dispatch(getFormDetailsAc(props));
      })
    }
  }
}


export const AddEditConclusionsQaAc = (props, type, data = null, sectionId) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.FORM_INTRODUCTION,
        payload: {type: 'add', flag: true, data: auditFindingInfo, sectionId:  sectionId }
      })
    }else{
      dispatch(triggerLoader(true));
      globalGetService('technical/workorder/'+props.params.workOrderSlug+'/audit-report-sections/', {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.FORM_INTRODUCTION,
            payload: {type: 'edit', flag: true, data: data, sectionId:  sectionId }
          })
        }
        dispatch(triggerLoader(false));
      })
    }
  }
}


export const removeComponentAc = (props, sectionId, id) =>{

  return (dispatch) =>{
    let data={custom_component_ids:[id]};
    dispatch(triggerLoader(true));
    globalPostService('/technical/workorder/'+props.params.workOrderSlug+'/section/'+sectionId+'/custom-components/delete/',data)
    .then(response =>{
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.REMOVE_COMPONENT_t004,
          payload: {sectionId: sectionId, id: id}
        })
      }
      dispatch(getFormDetailsAc(props));
      dispatch(triggerLoader(false));
    });

  }
}


export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.FINDING_LOADER,
      payload: flag
    })
  }
}
