import React, { Component } from 'react';

export default class FilterCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div {...this.props}>
        {this.props.children}
      </div>
    )
  }
}
