import * as actions from '../actions';
const crudObj = {
  type: '',
  flag: false,
  data: {}
};
export default function( state = {
  robberyList: [],
  pagination: {},
  selectedIds: [],
  robberyCrud: crudObj,
  robberyLoader: false
}, action ){
  switch (action.type) {
    case actions.ROBBERY_LIST:
      return {
        ...state,
        robberyList: action.payload.list,
        pagination: action.payload.pagination,
        selectedIds:[]
      }
    case actions.TOGGLE_SIDE_BAR_T009:
      return {
        ...state,
        robberyCrud: action.payload
      }
    case actions.ADD_ROBBERY:
      return {
        ...state,
        robberyList: [action.payload, ...state.robberyList],
        pagination: {
          ...state.pagination,
          page: state.pagination.page ? state.pagination.page: 1,
          total: 1 + (state.pagination.total ? state.pagination.total:0),
          per_page: state.pagination.page ? state.pagination.page:10
        },
        robberyCrud: crudObj
      }
    case actions.UPDATE_ROBBERY:
      return {
        ...state,
        robberyList: state.robberyList.map(item => item.id == action.payload.id ? action.payload: item),
        robberyCrud: crudObj
      }
    case actions.DELETE_ROBBERY:
      return {
        ...state,
        robberyList: state.robberyList.filter(item => !action.payload.includes(item.id))
      }
    case actions.UPDATE_ROBBERY_SELECTED_IDS:
      if(action.payload.type == 'all'){
        if(action.payload.flag){
          return {
            ...state,
            selectedIds: state.robberyList.map(robbery => { return robbery.id})
          }
        }else{
          return {
            ...state,
            selectedIds: []
          }
        }
      }else{
        if(state.selectedIds.includes(action.payload.id)){
          return {
            ...state,
            selectedIds: state.selectedIds.filter(id => id != action.payload.id)
          }
        }else{
          return {
            ...state,
            selectedIds: [...state.selectedIds, action.payload.id]
          }
        }
      }
    case actions.ROBBERY_LOADER:
      return {
        ...state,
        robberyLoader: action.payload
      }
    default:
      return state;

  }
}
