import React, { Fragment } from 'react';
import {  Row, Col } from 'reactstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
export default function InvoiceRpt({exportObj, onChangeExportFeature, onChangeExtension, onChangeDate, onChangeDropdown}){
  return(
    <Fragment>
      <div className="export-items">
        <Row className="flex-centered">
          <Col cs="5">
            <div className="">
              <label className="flex-centered export-label">
                <input type="checkbox"
                  onChange={(e) => onChangeExportFeature(e.target.checked, 'invoice')}
                  checked={exportObj.contracts && exportObj.contracts.invoice && exportObj.contracts.invoice.export ? true:false}
                />
                <span>Invoice Detail</span>
              </label>
            </div>
          </Col>
          <Col cs="4">
            <div className="export-avial">
              <h6>Export Format</h6>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <label>
                    <input
                      name="invoice"
                      checked={(exportObj.contracts && exportObj.contracts.invoice && exportObj.contracts.invoice.extension == 'pdf')}
                      onChange={() => onChangeExtension('pdf', 'invoice')}
                      type="radio"
                    />
                    <span>PDF</span>
                  </label>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="filter-avail">
          <ul className="list-inline flex-centered">

            <li className="list-inline-item">
              <label>Invoice Start Date</label>
              <DatePicker
                selected={exportObj.contracts.invoice.filter.startDate}
                dateFormat = {fieldDateFormat}
                maxDate={moment()}
                selected={exportObj.contracts.invoice.filter.startDate ? moment(exportObj.contracts.invoice.filter.startDate):null}
                onChange={(e) => onChangeDate(moment(e).format(backendDateFormat), 'startDate', 'invoice')}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
             />
            </li>
            <li className="list-inline-item">
              <label>Invoice End Date</label>
              <DatePicker
                dateFormat = {fieldDateFormat}
                maxDate={moment()}
                selected={exportObj.contracts.invoice.filter.endDate ? moment(exportObj.contracts.invoice.filter.endDate):null}
                onChange={(e) => onChangeDate(moment(e).format(backendDateFormat), 'endDate', 'invoice')}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                readOnly
              />
            </li>
            <li className="list-inline-item">
              <label>Invoice Type</label>
              <Select
                  name="form-field-name"
                  value={exportObj.contracts.invoice.filter.invoice_type}
                  options={[{label: 'MR Invoice', value: '1'}, {label: 'Rental Invoice', value: '2'}, {label: 'Penalty Invoice', value: '3'},{label: 'Debit Note Invoice', value: '4'},{label: 'Credit Note Invoice', value: '5'}]}
                  placeholder = 'Select Invoice Type'
                  onChange={(data) => onChangeDropdown(data, 'invoice_type', 'invoice')}
              />
            </li>
            <li className="list-inline-item">
              <label>Invoice Status</label>
              <Select
                  name="form-field-name"
                  value={exportObj.contracts.invoice.filter.status}
                  options={[{label: 'Draft', value: '1'}, {label: 'Sent', value: '2' }, {label: 'Paid', value: '3'}, {label: 'Cancelled', value: '4'}]}
                  placeholder = 'Select Invoice Status'
                  onChange={(data) => onChangeDropdown(data, 'status', 'invoice')}
              />
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}