export const LICENSE_LIST = 'LICENSE_LIST';
export const PLANS_LIST = 'PLANS_LIST';
export const SELECTED_PLANS_LIST = 'SELECTED_PLANS_LIST';
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const INTERVAL_TOGGLE = 'INTERVAL_TOGGLE';
export const CART_LIST = 'CART_LIST';
export const LICENSE_LOADER = 'LICENSE_LOADER';
export const CARDS_LOADER = 'CARDS_LOADER';
export const CARDS_LIST = 'CARDS_LIST';
export const UPDATE_CART = 'UPDATE_CART';
export const INOVICE_PREVIEW = 'INOVICE_PREVIEW';
export const REFERRAL_HISTORY = 'REFERRAL_HISTORY';
export const CARD_DETAIL = 'CARD_DETAIL'
export const UPDATE_CARD_DETAIL = 'UPDATE_CARD_DETAIL'
