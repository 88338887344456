export const APU_EVENT_LIST = 'APU_EVENT_LIST';
export const APU_EVENT_HISTORY = 'APU_EVENT_HISTORY';
export const APU_SHOP_VISIT_CRUD = 'APU_SHOP_VISIT_CRUD';
export const APU_SHOP_VIST_ADD_SUCCESS = 'APU_SHOP_VIST_ADD_SUCCESS';
export const APU_SHOP_VIST_EDIT_SUCCESS = 'APU_SHOP_VIST_EDIT_SUCCESS';
export const APU_CLAIM_ADD_SUCCESS = 'APU_CLAIM_ADD_SUCCESS';
export const APU_CLAIM_EDIT_SUCCESS = 'APU_CLAIM_EDIT_SUCCESS';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const APU_CLAIM_CRUD = 'APU_CLAIM_CRUD';
export const APU_PAYOUT_CRUD = 'APU_PAYOUT_CRUD';
export const APU_CLAIM_LIST = 'APU_CLAIM_LIST';
export const APU_LOADER = 'APU_LOADER';
