import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router'
import { Link } from 'react-router';
import { imgStoragePath, fieldDateFormat, backendDateFormat } from '../../../../../constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import { ListLoader} from '../../../../../shared';
import { toastFlashMessage, getLocalStorageInfo } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel'
import { FieldCol, FieldGroup, FieldLabel } from '../../../../contracts/Elements';
import { updateTechnicalFormDataApi, getTableSectionsApi } from '../apiServices';
import { ContentDrop } from '../../../Elements';
import UploadExcel from './UploadExcel';
import { T011SectionCrud } from '..';
import closeIcon from '../../../../../User/assets/img/black_close.png'

class TechnicalInspectionFormV1 extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      error:{},
      dragOver:false,
      item:T011SectionCrud
    }
    this.updateTechnicalFormDataApi = updateTechnicalFormDataApi.bind(this);
    this.getTableSectionsApi = getTableSectionsApi.bind(this);
  }
  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,
      item:{
        ...prevState.item,
      }
    }))
  }
  addTechnicalInspectionForm = (update) => {
    const {item} = this.state
    const {sectionId} = this.props
    const validateNewInput = {
      description: !item.description ? "Please enter Description":''
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.setState({ pageLoader: true });
      this.updateTechnicalFormDataApi(this.props, item, sectionId, update)
    }else{
      this.setState({
        error: validateNewInput
      })
      let formInfo = this.props.formInfo
      let assetTypeNames = {
        3: 'APU',
        4: 'LG',
        5: 'PROPELLER'
      }
      trackActivity('Page Visited',{
        application_module: 'Inspection',
        asset: formInfo.asset.asset_type === 1 ? `MSN ${formInfo.asset.msn}` : [3,4,5].includes(formInfo.asset.asset_type) ? `${assetTypeNames[formInfo.asset.asset_type]} ${formInfo.asset.serial_number}` : `ESN ${formInfo.asset.esn}`,
        page_title: formInfo.current_form.name.toUpperCase(),
        page_activity: `New row added failed with validations in section ${this.props.dropdownMenuList.filter(section => section.id === sectionId)[0].name}`
      })
    }
  }
  onChangeField =(keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      item:{
        ...prevState.item,
        [keyParam]:value,
      }
    }))
  }
  resetErrorKey =(key) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: ''
      }
    }))
  }
  onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }
  onDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragOver: false,
    })
  }
  updateForm=(key,value)=>{

    if(key == 'files'){
      if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
        let filesSize = 0
        for(let file of value){
          filesSize += file.size
        }
        if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
          this.props.buyLicence({
            flag: true,
            action: '/licenses/storage',
            content: 'The uploaded file(s) size is not available in storage.'
          })
          return;
        }
      }
      if(value.length > 10){
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            [key]: 'Maximum 10 files allowed to upload.'
          }
        }));
        return
      }else{
        let files = ''
        Array.from(value).map(file => {
          if(['png', 'jpg', 'jpeg', 'pdf', 'xls', 'xlsx', 'doc', 'docx'].indexOf(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()) == -1){
            files = files.length ? files + ', ' + file.name : file.name
          }
        })
        if(files != ''){
          this.setState(prevState => ({
            ...prevState,
            error: {
              ...prevState.error,
              [key]: files + ' are not allowed to upload.',
            },
            item: {
              ...prevState.item,
              files: [],
            },
          }));
          return
        }
      }
    }
    this.setState(prevState => ({
      ...prevState,
      item: {
        ...prevState.item,
        [key]: value,
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));
  }
  removeAttachFiles = (name) => {
    let files = this.state.item.files;
    files = Array.from(files).filter( function(file){
        return file.name != name
    });
    this.setState(prevState => ({
      ...prevState,
      item: {
        ...prevState.item,
        files: files
      }
    }))
  }
  render(){
    const { error, item, pageLoader } = this.state;
    const { closeTechForm, sectionId, sectionIds, updateField, updateTitleContent, tabIndex, operatorFlag, formInfo, userList, userListoptions } = this.props;
    const options = userList.map(item => ({
      "value" : item.id,
      "label" : item.name
    }))
    return(
      <Fragment>
      {pageLoader ? <ListLoader />:null}
        <Row>
          <Col md="12">
            <div style={{position: 'relative'}}>
              <ul className="list-inline tab-left">
                <li className={`list-inline-item ${tabIndex == 0 ? 'active':''}`} onClick={() =>  updateTitleContent(true, 0)}>Single Entry</li>
                <li className={`list-inline-item ${tabIndex == 1 ? 'active':''}`} onClick={() => updateTitleContent(false, 1)}>Import Via Excel</li>
              </ul>
            </div>
          </Col>
        </Row>
        {tabIndex == 0 ?
          <Fragment>
            <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="6">
              <FieldGroup className="form-group enable-select-clear">
                <FieldLabel className="label" required={false}>Priority</FieldLabel>
                  <Select
                    isClearable={false}
                    name="form-field-name"
                    placeholder={'Select Priority'}
                    className="custom-select-block"
                    value={item.priority ? item.priority : ''}
                    options = {[{label:'P1',value:1},{label:'P2',value:2}]}
                    labelKey={'label'}
                    valueKey={'value'}
                    onChange={(priority) => this.onChangeField('priority', priority ? priority.value:'')}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6">
              <FieldGroup className="form-group enable-select-clear">
                <FieldLabel className="label" required={false}>Assignee</FieldLabel>
                  <Select
                    isClearable={false}
                    name="form-field-name"
                    placeholder={'Select Assignee'}
                    className="custom-select-block"
                    value={item.assignee && item.assignee.id ? item.assignee.id : item.assignee ? item.assignee : 0}
                    options = {options}
                    labelKey={'label'}
                    valueKey={'value'}
                    onChange={(assignee) => this.onChangeField('assignee', assignee ? assignee.value:'')}
                  />
                </FieldGroup>
              </FieldCol>
            </Row>
            <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}>Description</FieldLabel>
                  <textarea
                    style={{ resize:'inherit'}}
                    rows="4"
                    value={item.description ? item.description:''}
                    onChange={(e) =>  this.onChangeField('description', e.target.value)}
                    onFocus={(e) => this.resetErrorKey('description')}
                    ></textarea>
                  <h6 className="error-msg">{error.description ? error.description :''}</h6>
                </FieldGroup>
              </FieldCol>
              {
                [2,5,6,7,8,9,10,11,21,22,14,15,16,17,23,24,25,26,27,28,29,30,31,32,33,34,35].includes(sectionId) ?
                <FieldCol md="6">
                 <FieldGroup className="form-group">
                   <FieldLabel className="label" required={false}>ATA</FieldLabel>
                     <input
                       type="text"
                       value={item.ata}
                       onChange={(e) =>  this.onChangeField('ata', e.target.value)}
                     />
                  </FieldGroup>
                 </FieldCol>
                :null
              }
              {
                sectionId === 26 ?
                <FieldCol md="6">
                 <FieldGroup className="form-group">
                   <FieldLabel className="label" required={false}>Cycles Remaining</FieldLabel>
                     <input
                       type="text"
                       value={item.cycles_remaining}
                       onChange={(e) =>  this.onChangeField('cycles_remaining', e.target.value)}
                     />
                  </FieldGroup>
                 </FieldCol>
                :null
              }
              { sectionIds.includes(sectionId) ?
              <Fragment>
              <FieldCol md="6">
               <FieldGroup className="form-group">
                 <FieldLabel className="label" required={false}>Part Number</FieldLabel>
                   <input
                     type="text"
                     value={item.part_number}
                     onChange={(e) =>  this.onChangeField('part_number', e.target.value)}
                   />
                </FieldGroup>
               </FieldCol>
                <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label" required={false}>Serial Number</FieldLabel>
                    <input
                      type="text"
                      value={item.serial_number}
                      onChange={(e) => this.onChangeField('serial_number', e.target.value)}
                    />
               </FieldGroup>
                </FieldCol>
              </Fragment>:null
            }
            <FieldCol md="6">
             <FieldGroup className="form-group">
              <FieldLabel className="label" required={false}>Start Date</FieldLabel>
                <img src={item.started_date?closeIcon:null} onClick={()=> this.onChangeField('started_date', null)} style={{position:'relative', top:'38px', zIndex: '99', width: '8px', float: 'right', cursor:'pointer'}}/>
                <DatePicker
                  dateFormat = {fieldDateFormat}
                  value={item.started_date !== undefined && item.started_date !== null && item.started_date !== "" ? item.started_date: null }
                  onChange={(e) => this.onChangeField('started_date', e.format(backendDateFormat))}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  style={{ overflow:'hidden' }}
                />
             </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
             <FieldGroup className="form-group">
              <FieldLabel className="label" required={false}>Completed Date</FieldLabel>
              <img src={item.estimated_completion_date?closeIcon:null} onClick={()=> this.onChangeField('estimated_completion_date', null)} style={{position:'relative', top:'38px', zIndex: '99', width: '8px', float: 'right', cursor:'pointer'}}/>
                <DatePicker
                  dateFormat = {fieldDateFormat}
                  value={item.estimated_completion_date !== undefined && item.estimated_completion_date !== null && item.estimated_completion_date !== "" ? item.estimated_completion_date: null }
                  onChange={(e) => this.onChangeField('estimated_completion_date', e.format(backendDateFormat))}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  style={{ overflow:'hidden' }}
                  isClearable
                />
             </FieldGroup>
            </FieldCol>
            { sectionIds.includes(sectionId) ?
            <Fragment>
              <FieldCol md="6">
               <FieldGroup className="form-group">
                <FieldLabel className="label" required={false}>Install Date</FieldLabel>
                <img src={item.install_date?closeIcon:null} onClick={()=> this.onChangeField('install_date', null)} style={{position:'relative', top:'38px', zIndex: '99', width: '8px', float: 'right', cursor:'pointer'}}/>
                  <DatePicker
                    dateFormat = {fieldDateFormat}
                    value={item.install_date !== undefined && item.install_date !== null && item.install_date !== "" ? item.install_date: null }
                    onChange={(e) => this.onChangeField('install_date', e.format(backendDateFormat))}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    style={{ overflow:'hidden' }}
                    isClearable
                  />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
               <FieldGroup className="form-group">
                <FieldLabel className="label" required={false}>Position</FieldLabel>
                  <input
                    type="text"
                    value={item.position ? item.position : ''}
                    onChange={(e) => this.onChangeField('position', e.target.value)}
                  />
             </FieldGroup>
            </FieldCol>
          </Fragment>: null
        }
        <FieldCol md="6">
           <FieldGroup className="form-group">
            <FieldLabel className="label" required={false}>Requirement Discrepancy</FieldLabel>
              <textarea
                style={{ resize:'inherit'}}
                rows="4"
                value={item.requirement_remarks ? item.requirement_remarks : ''}
                onChange={(e) => this.onChangeField('requirement_remarks', e.target.value)}
              />
           </FieldGroup>
          </FieldCol>
          <FieldCol md="6">
           <FieldGroup className="form-group">
            <FieldLabel className="label" required={false}>Background Discrepancy</FieldLabel>
              <textarea
                style={{ resize:'inherit'}}
                rows="4"
                value={item.background_remarks ? item.background_remarks : ''}
                onChange={(e) => this.onChangeField('background_remarks', e.target.value)}
              />
           </FieldGroup>
          </FieldCol>
          <FieldCol md="6">
             <FieldGroup className="form-group">
              <FieldLabel className="label" required={false}>Project Lead/Engineer Comment</FieldLabel>
                <textarea
                  style={{ resize:'inherit'}}
                  rows="4"
                  value={item.acumen_comments}
                  disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag ? false : true}
                  onChange={(e) => this.onChangeField('acumen_comments', e.target.value)}
                />
             </FieldGroup>
          </FieldCol>
            </Row>
            <Row>
            <div style={{marginLeft:'15px'}}>
            <ul className="list-inline flex-centered">
              {
              !item.files || item.files.length <10 ?
            <li className="list-inline-item dropable-zone"
              onDrop={(evt) => { evt.preventDefault(); evt.stopPropagation(); this.updateForm('files',evt.dataTransfer.files)}}
              onDragOver={(evt) => this.onDragOver(evt)}
              onDragLeave={() => this.onFocusOut()}
              style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)', width:'200px',height:'200px'} : { background: 'transparent', width:'200px',height:'200px'}} >
             <ContentDrop
               mutlipleEnabled={true}
               acceptExtensions={'.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx'}
               filesUpload={(evt) => this.updateForm('files',evt.target.files)}
               content="Drag & Drop Or Click here to choose Document(s)"
             />
           </li>:null
          }
          </ul>
          <h6 className="error-msg">{this.state.error.files}</h6>
        </div>
        <div  style={{marginLeft:'25px'}}>
        { item.files && item.files.length ?
          Array.from(item.files).map(file =>
            <div style={{width: '100%'}}>
              <p style={{padding:'3px',fontSize: '12px', borderBottom: '1px solid #a0a0a0'}}>{file.name}<span onClick={()=>this.removeAttachFiles(file.name)}
                style={{cursor:'pointer', color:'#ff0000', paddingLeft: '15px'}}>
                <i className="fa fa-trash"></i></span>
              </p>
            </div>
          )
         :null
       }
        </div>
      </Row>
      </div>
      <div className="submit-block">
        <input type="submit" className="primary-btn" value="Add" onClick={() => this.addTechnicalInspectionForm(true)} />
        <Link className="primary-btn" onClick={()=> this.addTechnicalInspectionForm(false)}>Save &amp; Add Another</Link>
        <Link style={{marginLeft:'20px'}}  onClick={closeTechForm} >Cancel</Link>
      </div>
      </Fragment> :
        <UploadExcel formInfo={this.props.formInfo} sectionId={sectionId} getTableContentsApi={() => this.props.getTableContentsApi()} exportReport={() => this.props.exportReport()} closeTechForm={closeTechForm}/>
      }
    </Fragment>
    )
  }
}

export default withRouter(TechnicalInspectionFormV1);
