import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UPDATE_AIRFRAME_FIELD } from '../actions';
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
class AirframeFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      fieldFocus : false,
      error: '',
      errorflag: false
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
    if(this.props.error){
      this.props.updateErrorCode(this.props.type);
    }
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  render(){
    const { label, value, type, updategenFinanceInfo, inputType, error } = this.props;
    if(inputType == 'text'){
      return(
        <Col md="4"  className="inner-wrap">
            <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'}  } >{label}<sup className="reqStar"> * </sup></label>
            <input onChange={(e) => this.props.updategenFinanceInfo(e.target.value, type) } onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}  type="text" name="" value={value}/>
            <h6 className="error-msg">{error}</h6>
        </Col>
      )
    }else{
      return(
        <Col md="4"  className="inner-wrap">
          <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'}  }>{label}<sup className="reqStar"> * </sup></label>
          <DatePicker
            dateFormat={fieldDateFormat}
            maxDate={moment().subtract(1, 'day')}
            selected={ value && moment(value).isValid() ? moment(value):''}
            onChange= {(e) => this.props.updategenFinanceInfo(moment(e).format(backendDateFormat), type)}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
           />
          <h6 className="error-msg">{error}</h6>
       </Col>
      )
    }

  }
}
const mapDispatchToProps = dispatch => {
  return {
    updategenFinanceInfo: (value, type) => dispatch({
      type: UPDATE_AIRFRAME_FIELD,
      payload: {value, type}
    })
  }
}
export default connect(null, mapDispatchToProps)(AirframeFields)
