import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { loadAssetViewAc} from '../../Dashboard/actionCreators';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { RecordsSideBar } from '../../Elements';
import BinList from '../../RecycleBin/components';
import {getDataKitListAc, removeFileAc,removeAllFileAc, downLoadResourcesTempAC} from '../actionCreators';
import '../../assets/styles/records_a.scss';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import Pagination from "react-js-pagination";
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { imgStoragePath, assetsType } from '../../../../constants';
class DataKit extends Component{
  constructor(props) {
    super(props);
    this.state ={
      pageNumber: 1,
      delModalFlag: false,
      delModalAllFlag: false,
      recordAction: 'Remove',
      previewCard:{},
    };
    this.removeRecord = this.removeRecord.bind(this);
  }

  handlePageChange = (pageNumber) =>{
    this.setState({
      pageNumber: pageNumber
    });
    this.props.getDataKitList(pageNumber);

  }

  removeRecord(item, action){
    this.setState({
      delModalFlag: !this.state.delModalFlag,
      previewCard: item,
      recordAction: action,
    })
  }

  removeRecordAll(){
    this.setState({
      delModalAllFlag: !this.state.delModalAllFlag,
    })
  }

  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.getDataKitList(1);
    this.props.fetchTechConstant({constant_types: ['file_extension']})
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/resources/';
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  makeRecycleList(uuid){
     this.props.restoreRecycleItem(uuid,this.props.params.id);
  }
  removeAll(){
    this.props.restoreAllItem(this.props.params.id);
 }
  render(){
    const { ctrShortInfo, dataKitList, techConstants, resourceLoader, pagination, recycleList} = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    return(
     <div className="records-list-wrap" style={{marginTop: '94px', padding: '0 15px'}}>
       <RecordsSideBar />
       { ctrShortInfo && Object.keys(ctrShortInfo).length ?
         <ShortHead
           headingText="Records"
           changeMsn={this.changeMsn}
           contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
       }
       <Row>
         <Col md="8" className="invoice-heading">
          <h1>
             <Link className="back-link-single" to={'/' +this.props.params.type + '/' + this.props.params.aircraft_slug + '/records/resources/'}>
                <img width="15" src={imgStoragePath+"back_arw.png"} alt="img"/>
                <span style={{color: '#000', marginLeft:'5px'}}> {this.props.params.id == 1 ? "Marketing Kit" : this.props.params.id == 2 ? "Engineering Kit":"Engine And APU Minipack"}</span>
              </Link>
              <Link className="back-link-single" to={'/' +this.props.params.type + '/' + this.props.params.aircraft_slug + '/records/data-room/technical/'+(ctrShortInfo && ctrShortInfo.records? ctrShortInfo.records.technical_records.uuid:'') + '?type=' + this.props.location.query.type}>
                <span style={{marginLeft:'15px',fontSize:'12px'}}>+ Add Files</span>
              </Link>
          </h1>
         </Col>
         {
           (dataKitList.data && dataKitList.data.list && dataKitList.data.list.length > 0 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ) ?
             <Col md="2" className="status-block text-right bucket-action">
               <span style={{color: '#FE5D5E'}} onClick={() => this.removeRecordAll()} >Remove All</span>
             </Col>:null
         }
         {
           (dataKitList.data && dataKitList.data.list && dataKitList.data.list.length > 0 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ) ?
             <Col md="2"  style={{maxWidth:'113px'}} className="status-block text-right bucket-action">
               <span style={{color: '#007bff'}} onClick={() => this.props.downloadresources(this.props.params.id == 1 ?'marketing_kit':this.props.params.id == 2?'engineering_kit':'engine_apu_minipack',ctrShortInfo.records.technical_records.uuid,'zip',(ctrShortInfo.asset_type==2?'ESN'+ctrShortInfo.esn:'MSN '+ctrShortInfo.msn)+(this.props.params.id == 1 ?'_marketing_kit':this.props.params.id == 2 ?'_engineering_kit':'_engine_apu_minipack'))} >Download All</span>
             </Col>:null
         }
       </Row>
       <div className="asset-list-filter-sort">
         <Row className="filter-block">
           <Col md="8">
             <h6>
             Name</h6>
           </Col>
           <Col md="2">
             <h6>Size</h6>
           </Col>
         </Row>
       </div>
       <Modal isOpen={this.state.delModalFlag} centered={true} toggle={this.removeRecord} className={this.props.className}>
         <ModalHeader toggle={this.removeRecord}>{this.state.recordAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to {this.state.recordAction} {this.state.previewCard.name}?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                <button className="btn btn-danger" onClick={() => {this.makeRecycleList(this.state.previewCard.uuid);this.removeRecord(this.state.previewCard, this.state.recordAction)}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.removeRecord(this.state.previewCard.uuid, this.state.recordAction)}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
       <Modal isOpen={this.state.delModalAllFlag} centered={true} toggle={this.removeRecordAll} className={this.props.className}>
         <ModalHeader toggle={this.removeRecordAll}>{this.state.recordAction}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to remove all?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                <button className="btn btn-danger" onClick={() => {this.removeAll();this.removeRecordAll()}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.removeRecordAll()}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>

       <div className="list-wrapper" style={{marginTop: '0px'}} id="dataBucket">
         {
           resourceLoader ?
           <ListLoader/> :
           (dataKitList.data && dataKitList.data.list && dataKitList.data.list.length > 0 && !resourceLoader)?
             dataKitList.data.list.map((item, index) =>
               <BinList
                 key={item.uuid}
                 index={index}
                 recycleBin = {false}
                 dataKit = {true}
                 listItem={item}
                 removeRecord={() => this.removeRecord(item, 'Remove')}
                 restoreRecord={() => this.deleteRecord(item, 'Restore')}
                 addToRecycle={() => this.props.addToRecycle(item.uuid,item.type)}
                 recycleList={recycleList}
                 fileIcons={fileIcons}
               />
             ):<NoRecordFound/>
         }
         {
           (Object.keys(pagination).length &&dataKitList.data && dataKitList.data.list && dataKitList.data.list.length > 0) ?
             <Pagination
              activePage={pagination.current_page ? parseInt(pagination.current_page):1}
              itemsCountPerPage={20}
              totalItemsCount={pagination.total}
              pageRangeDisplayed={2}
              onChange={this.handlePageChange}
            />
           :null
         }
       </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  dataKitList: state.ResourcesReducer.dataKitList,
  resourceLoader: state.ResourcesReducer.resourceLoader,
  pagination: state.ResourcesReducer.pagination,
  recycleList: state.RecycleBinReducer.recycleList,
  techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Data Kit')),
    getDataKitList: (pageNumber) => dispatch(getDataKitListAc(ownProps.params, pageNumber)),
    restoreRecycleItem: (uuid,id) => dispatch(removeFileAc(ownProps.params,uuid,id)),
    restoreAllItem: (id) => dispatch(removeAllFileAc(ownProps.params,id)),
    downloadresources: (template, uuid, type, name) => dispatch (downLoadResourcesTempAC(ownProps.params, template, uuid, type, name)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(DataKit,['records','resources','R'])))
