import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import List from '../components/List';
import ConfirmList from '../components/ConfirmList'
import { Row, Col } from 'reactstrap';
import BulkOperation from '../components/BulkOperation';
import BulkOptSchedule from '../components/BulkOptSchedule';
import BulkOptConfirm from '../components/BulkOptConfirm'
import LaunchProject from '../components/LaunchProject'
import CommentsEdit from '../components/CommentsEdit'
import ScheduleList from '../components/ScheduleList'
import HelpTextInspectionType from  '../../Projects/components/HelpTextInspectionType';
import { sLesseeListAc, getMsnSwitcherAc, sManufacturerAc, sGlobalConstantAc,sEngineTypeAc,sArctTypesAc } from '../../../../shared/actionCreators';
import { DeleteModal, EditFormBar,GetInputField } from '../../Elements';
import { imgStoragePath } from '../../../../constants'
import { browserHistory } from 'react-router';
import { UPDATE_SELECTED_ASSETS } from '../actions';
import { fetchProgramStatusAc,
    createProgramAc,
    fetchFIlterListAc,
    fetchSchedulerAssetsAc,
    fetchAssetsAc,
    fetchConfirmAssetsAc,
    technicalInspectionTypeAc,
    updateAssetValueAc,
    reviewConfimAssetAc,
    addAssetConfirmProgramAc,
    updateCommentAc,
    projectViewCrudAc,
    addEditProjectAc,
    fetchProjectUserAc,
    deleteCommentAc,
    deleteAssetAc,
    assetExportAc,
    addAssetProgramAc} from '../actionCreators'
import { TableUIComp, ExportFiles, MSNSwitcherHeader, ListLoader, NoRecordFound, InfoIcon } from '../../../../shared';
import {UncontrolledTooltip} from 'reactstrap';


class ProgramScheduler extends Component {
  constructor(props){
    super(props);
    this.state = {
      months: [],
      lhWidth: 0,
      height: 0,
      windows:false,
      delModal:false,
      delModalAsset:false,
      toggleComments:false,
      typeFlag:'',
      assetId:'',
      colWidth : 80,
      addMsn:false,
      workOrderInfoFlag: false,
      commentID:'',
      queryParam:{},
      userDetail: {
        flag: false,
        data: {}
      }
    }
  }



  componentDidMount(){
    this.props.fetchProgramStatus();
    this.props.fetchTechConstant({constant_types:['project_departments','project_name','risk_level']});
    this.props.fetchTechnicalInspection();
    this.props.fetchEngines();
    this.props.fetchArchType();
    this.props.selectAllItem(false)
    this.props.fetchFilterList('lessee');
    this.props.fetchFilterList('aircraft_type');
    this.props.fetchFilterList('engine_type');


    if(browserHistory.getCurrentLocation().pathname.indexOf("/details")!=-1){
    this.props.fetchSchedulerAssets();
    this.props.fetchLesseeList();
    this.props.fetchManufactureList();
    this.props.getMsnSwitcher();
    this.props.fetchProjectLead({role: 'lead'}, 'projectLead');
    this.props.fetchProjectEngineer({role: 'engineer'}, 'projectEngineer');

    }
    if(browserHistory.getCurrentLocation().pathname.indexOf("/technical/program-planner/assets")!=-1){
        this.props.fetchAssets();
    }
    if(browserHistory.getCurrentLocation().pathname.indexOf("/technical/program-planner/program/")!=-1 && browserHistory.getCurrentLocation().pathname.indexOf("/details")==-1){
        this.props.fetchConfirmAssets();
    }
  }

  componentWillUnmount(){
    this.props.selectAllItem(false)
  }

  toggleAddMsn = () => {
    browserHistory.push('/technical/program-planner/assets/program/'+this.props.plannerStatus.program)
  }

  toggleDelModal=(id)=>{
    this.setState({
        delModal: !this.state.delModal,
        commentID: id
      });
  }

  toggleDelAssetModal=(id,flag)=>{
    this.setState({
        delModalAsset: !this.state.delModalAsset,
        assetId: id,
        typeFlag:flag
      });
  }



  toggleWorkorderInfo=()=> {
    this.setState(prevState => ({
      ...prevState,
      workOrderInfoFlag: !prevState.workOrderInfoFlag
    }))
  }

  toggleComments=(id,flag)=>{
    this.setState({
        toggleComments: !this.state.toggleComments,
        assetId:id,
        typeFlag:flag,
      });
  }

  deletePlanner=()=>{
      this.props.deleteComment(this.state.assetId,this.state.commentID,this.state.typeFlag);
      this.toggleDelModal('')
  }
  deleteAsset=()=>{
    this.props.deleteAsset(this.state.assetId,this.state.typeFlag);
    this.toggleDelAssetModal('')
  }

  updateComments=(value)=>{
     this.props.updateComment(this.state.assetId,value,this.state.typeFlag);
  }

  addEditProject=(data,type)=>{
    this.props.addEditProject(data,this.props.selectedIds);
  }


  changeFilterFn=(filter)=>{
    let filterObj = Object.assign({}, filter);
    if(filterObj.lease){
      let issueDate = filter.lease.split(',');
      delete filterObj['lease'];
      issueDate.map((lease, index) => {
        if(index == 0 && lease != null && lease != 'null'){
          filterObj = {
            ...filterObj,
            lease_exp_start_date:lease
          }
        }
        if(index == 1 && lease != null && lease != 'null'){
          filterObj = {
            ...filterObj,
            lease_exp_end_date:lease
          }
        }
      })
    }else if(filterObj.inspection){
      let inspectionDate = filter.inspection.split(',');
      delete filterObj['Technical Inspection'];
      inspectionDate.map((inspection, index) => {
        if(index == 0 && inspection != null && inspection != 'null'){
          filterObj = {
            ...filterObj,
            inspection_due_start_date:inspection
          }
        }
        if(index == 1 && inspection != null && inspection != 'null'){
          filterObj = {
            ...filterObj,
            inspection_due_end_date:inspection
          }
        }
      })
    }


    this.setState({
      queryParam: filterObj
    });
    if(browserHistory.getCurrentLocation().pathname.indexOf("/details")!=-1){
      this.props.fetchSchedulerAssets(filterObj);
      }
      if(browserHistory.getCurrentLocation().pathname.indexOf("/technical/program-planner/assets")!=-1){
          this.props.fetchAssets(filterObj);
      }
      if(browserHistory.getCurrentLocation().pathname.indexOf("/technical/program-planner/program/")!=-1 && browserHistory.getCurrentLocation().pathname.indexOf("/details")==-1){
          this.props.fetchConfirmAssets(filterObj);
      }
  }


  assetExport=(flag,fileType)=>{
    let filterObj = Object.assign({}, this.state.queryParam);
    filterObj = {
      ...filterObj,
      download:fileType
    }
   this.props.assetExportFn(flag,filterObj)
  }


  render(){
  const { programSchedulerList,programAssetsList ,programConfirmAssetsList,selectedIds,projectCrud,statusLoader,commonLoader} = this.props;
  const location = Object.assign({}, browserHistory.getCurrentLocation());
  let menuList = {
    'Quarter': {
      'inputType': 'dropdown',
      'keyParam': 'quarter',
      'placeholder': 'Select Quarter',
      'options': [{label:'Q1',value:'Q1'},{label:'Q2',value:'Q2'},{label:'Q3',value:'Q3'},{label:'Q4',value:'Q4'}],
      'labelKey': 'label',
      'valueKey': 'value',
      'label': 'Qaurter'
    },
    'Aircraft Type': {
      'inputType': 'dropdown',
      'keyParam': 'aircraft_type',
      'placeholder': 'Select Aircraft Type',
      'options':  this.props.filterAirCraftList?this.props.filterAirCraftList:[],
      'labelKey': 'label',
      'valueKey': 'label',
      'label': 'Aircraft Type'
    },
    'Engine Type': {
      'inputType': 'dropdown',
      'keyParam': 'engine_type',
      'placeholder': 'Select Engine Type',
      'options': this.props.filterEngineList?this.props.filterEngineList:[],
      'labelKey': 'label',
      'valueKey': 'label',
      'label': 'Engine Type'
    },
    'Inspection Type': {
      'inputType': 'dropdown',
      'keyParam': 'inspection_type',
      'placeholder': 'Select Inspection Type',
      'options': this.props.inspectionType?this.props.inspectionType:[],
      'labelKey': 'name',
      'valueKey': 'id',
      'label': 'Inspection Type'
    },
    'Risk Category': {
      'inputType': 'dropdown',
      'keyParam': 'risk_category',
      'placeholder': 'Select Category',
      'options': this.props.techConstants? this.props.techConstants.filter(item => item.type == 'risk_level'):[],
      'labelKey': 'label',
      'valueKey': 'value',
      'label': 'Category'
    },
    'Lessee Name': {
      'inputType': 'dropdown',
      'keyParam': 'lessee',
      'placeholder': 'Select Lessee Name',
      'options': this.props.filterLesseList?this.props.filterLesseList:[],
      'labelKey': 'label',
      'valueKey': 'label',
      'label': 'Lessee Name'
    },
    'MSN/ESN':{
      'inputType': 'text',
      'keyParam': 'search',
      'label': 'Search MSN/ESN'
    },
    'Lease Date Range': {
      'inputType': 'date',
      'keyParam': 'lease',
      'label': 'Lease Date'
    },
    'Inspection Date Range': {
      'inputType': 'date',
      'keyParam': 'Technical Inspection',
      'label': 'Inspection Date'
    }
  };
  let newmenuList = {
    'Aircraft Type': {
      'inputType': 'dropdown',
      'keyParam': 'aircraft_type',
      'placeholder': 'Select Aircraft Type',
      'options':  this.props.filterAirCraftList?this.props.filterAirCraftList:[],
      'labelKey': 'label',
      'valueKey': 'label',
      'label': 'Aircraft Type'
    },
    'Engine Type': {
      'inputType': 'dropdown',
      'keyParam': 'engine_type',
      'placeholder': 'Select Engine Type',
      'options': this.props.filterEngineList?this.props.filterEngineList:[],
      'labelKey': 'label',
      'valueKey': 'label',
      'label': 'Engine Type'
    },
    'Inspection Type': {
      'inputType': 'dropdown',
      'keyParam': 'inspection_type',
      'placeholder': 'Select Inspection Type',
      'options': this.props.inspectionType?this.props.inspectionType:[],
      'labelKey': 'name',
      'valueKey': 'id',
      'label': 'Inspection Type'
    },
    'Risk Category': {
      'inputType': 'dropdown',
      'keyParam': 'risk_category',
      'placeholder': 'Select Category',
      'options': this.props.techConstants? this.props.techConstants.filter(item => item.type == 'risk_level'):[],
      'labelKey': 'label',
      'valueKey': 'value',
      'label': 'Category'
    },
    'Lessee Name': {
      'inputType': 'dropdown',
      'keyParam': 'lessee',
      'placeholder': 'Select Lessee Name',
      'options': this.props.filterLesseList?this.props.filterLesseList:[],
      'labelKey': 'label',
      'valueKey': 'label',
      'label': 'Lessee Name'
    },
    'MSN/ESN':{
      'inputType': 'text',
      'keyParam': 'search',
      'label': 'Search MSN/ESN'
    },
    'Lease Date Range': {
      'inputType': 'date',
      'keyParam': 'lease',
      'label': 'Lease Date'
    },
    'Inspection Date Range': {
      'inputType': 'date',
      'keyParam': 'Technical Inspection',
      'label': 'Inspection Date'
    }
  };

    return(
      <div className="program-planner-block">
      <div style={{margin:'20px',padding:'10px',background:'#fff',display:'none'}}>
      <Row>
        <Col md="3">
                {
                  GetInputField(
                    'dropdown',
                    null,
                    { type: 'edit',
                    value: '2019',
                    options: [{label:'2019'},{label:'2018'}],
                    labelKey: 'label',
                    valueKey: 'label',
                    updateForm: (value) => this.updateForm('name', value)
                    }
                  )
                }
              </Col>
        </Row>
    </div>
    {commonLoader ? <ListLoader/>:null}
    {statusLoader ? <ListLoader/>:
      <div>
      {location.pathname=='/technical/program-planner' ?
      <div>
          <NoRecordFound description="No records of Assets. Please contact Administrator to upload Assets."/>
      </div> : null}
      {location.pathname=='/technical/program-planner/create/program' ?
     <div style={{margin:'20px',padding:'100px',background:'#fff',textAlign:'center'}}>
         <p>Interested in planning your fleet wide asset inspections? Click "Create Program" and follow the steps</p>
         <button style={{marginTop:'20px'}} onClick={()=>this.props.createProgram()} className="btn btn-primary">Create Program</button>
      </div>:null}
      {(location.pathname=='/technical/program-planner/assets'|| location.pathname.indexOf("/technical/program-planner/program/")!=-1) && location.pathname.indexOf("/details")==-1 ?
       <ul className="list-inline project-heading">
          <li  onClick={() => browserHistory.push('/technical/program-planner/assets')} className="list-inline-item">
          {location.pathname.indexOf("/technical/program-planner/program/")!=-1 ?
          <Link>
          Shortlist Assets
          <span  id="shortListAssetsToolTip">
          <InfoIcon id="total-invoice-info" />
          <UncontrolledTooltip placement="bottom" target="shortListAssetsToolTip">
          Select assets and click "Add to Program" to proceed further
          </UncontrolledTooltip>
          </span>
          </Link>
           :
           <Link className="active">
           Shortlist Assets
           <span  id="shortListAssetsToolTip">
           <InfoIcon id="total-invoice-info" />
           <UncontrolledTooltip placement="top" target="shortListAssetsToolTip">
           Select assets and click "Add to Program" to proceed further
           </UncontrolledTooltip>
           </span>
           </Link>
           }
          </li>
          <li onClick={() => browserHistory.push('/technical/program-planner/program/'+this.props.plannerStatus.program)} className="list-inline-item">
          {location.pathname.indexOf("/technical/program-planner/program/")!=-1?
          <Link className="active">
            Review Program Dates
            <span  id="ReviewProgramDateToolTip">
            <InfoIcon id="total-invoice-info" />
            <UncontrolledTooltip placement="top" target="ReviewProgramDateToolTip">
          Review and update inspection dates after assets have been added to program
          </UncontrolledTooltip>
          </span>
            </Link>

            :
            <Link>
            Review Program Dates
            <span  id="ReviewProgramDateToolTip">
            <InfoIcon id="total-invoice-info" />
            <UncontrolledTooltip placement="top" target="ReviewProgramDateToolTip">
            Review and update inspection dates after assets have been added to program
            </UncontrolledTooltip>
            </span>
            </Link>

            }
          </li>
          <li className="list-inline-item">
          <Link >
          Schedule &amp; Track
          <span  id="scheduleTrackToolTip">
          <InfoIcon id="total-invoice-info" />
          <UncontrolledTooltip placement="top" target="scheduleTrackToolTip">
            Schedule, Launch and Monitor inspections added in program
            </UncontrolledTooltip>
            </span>
          </Link>
          </li>
        </ul>:null}
        {location.pathname.indexOf("/details")!=-1 ?
        <div>
        <ul className="list-inline project-heading">
          <li className="list-inline-item">
            <Link className="active">SCHEDULER</Link>
          </li>
          <li className="list-inline-item" onClick={() => browserHistory.push('/technical/program-planner/program/'+this.props.plannerStatus.program+'/tracker')} >
            <Link>TRACKER</Link>
          </li>
        </ul>

        <div style={{marginLeft:'20px'}}>
          <TableUIComp
            theads={[{label:'MSN/ESN', sortKey: ''}, {label:'Inspection Due Date', sortKey: ''}, {label:'Quarter', sortKey: ''},{label:'Risk Level', sortKey: ''}, {label:'Cur. Lessee', sortKey: ''}, {label:'Inspection Type', sortKey: ''},{label:'Last Inspection', sortKey: ''},{label:'Date of MFR.', sortKey: ''},{label:'Lease Expiry', sortKey: ''},{label:'', sortKey: ''}]}
            bulkOperation={true}
            content={programSchedulerList.map((item, index) => <ScheduleList toggleDelModal={this.toggleDelAssetModal} key={index} selectedIds={selectedIds} toggleComments={this.toggleComments} data={item} techConstants={this.props.techConstants} inspectionType={this.props.inspectionType} updateForm={this.props.updateForm} selectItem={(event) => this.props.selectItem(event.target.checked, item.id)} />)}
            tableBulkOp = { <BulkOptSchedule addText= "+ Add MSN" assetExport={this.assetExport}  programSchedulerList={programSchedulerList} selectedIds={selectedIds} toggleSidebar= {() => this.toggleAddMsn()} toggleEditAddSide={this.props.toggleEditAddSide} cancelBulkOp={() => this.props.selectAllItem(false)}  />}
            pagination = {[]}
            filter={{menuList: menuList, appliedFilter: {}, displayFilter:true}}
            selectAllItem={(e) => this.props.selectAllItem(e.target.checked, 'all','scheduler')}
            sortTable={this.sortTable}
            changePerPage ={this.changePerPage}
            changePagination = {this.changePagination}
            closeFilter = {this.closeFilter}
            checkedAll={ programSchedulerList.length > 0 ? selectedIds.length == programSchedulerList.length ? true:false : false}
            changeFilterFn={this.changeFilterFn}
            recordsFound =  {programSchedulerList.length?false:true}
            queryParamsEnabled={false}
            tableHeight = {window.innerHeight-220+'px'}
          />
        </div>
        </div>:null}
        {location.pathname=='/technical/program-planner/assets'|| location.pathname.indexOf("/assets/program/")!=-1 ?
        <div  style={{marginLeft:'20px'}}>
        {location.pathname=='/technical/program-planner/assets' ?null:
          <div className="go-back-scheduler"><Link onClick={() => browserHistory.goBack()}><img src={imgStoragePath + 'back_arrow.png'} to={`/technical/program-planner/program/${this.props.params.programId}/details`}/> <span>Scheduler</span></Link></div>}
          <TableUIComp
            theads={[{label:'MSN/ESN', sortKey: ''}, {label:'Aircraft Type', sortKey: ''}, {label:'Current Lessee', sortKey: ''},{label:'Risk Category', sortKey: ''}, {label:'Inspection Type', sortKey: ''}, {label:'Last Inspection date', sortKey: ''},{label:'Inspection Due Date', sortKey: ''},{label:'Date of MFR.', sortKey: ''},{label:'Lease Expiry Date', sortKey: ''},{label:'', sortKey: ''},{label:'', sortKey: ''}]}
            bulkOperation={true}
            content={programAssetsList.map((item, index) => <List key={index} toggleDelModal={this.toggleDelAssetModal} toggleComments={this.toggleComments}  selectedIds={selectedIds} typeFlag={'assets'} data={item}  techConstants={this.props.techConstants} updateForm={this.props.updateForm} inspectionType={this.props.inspectionType} selectItem={(event) => this.props.selectItem(event.target.checked, item.id)} />)}
            tableBulkOp = { <BulkOperation addText= "+ Add to Program" assetExport={this.assetExport} selectedIds={selectedIds} toggleSidebar= {() => location.pathname.indexOf("/assets/program/")!=-1 ?this.props.addAssetsConfirm(selectedIds):this.props.addAssets(this.props.plannerStatus.program,selectedIds)} cancelBulkOp={() => this.props.selectAllItem(false)} />}
            pagination = {[]}
            filter={{menuList: newmenuList, appliedFilter: {}, displayFilter:true}}
            selectAllItem={(e) => this.props.selectAllItem(e.target.checked, 'all','assets')}
            sortTable={this.sortTable}
            changePerPage ={this.changePerPage}
            changePagination = {this.changePagination}
            closeFilter = {this.closeFilter}
            checkedAll={ programAssetsList.length > 0 ? selectedIds.length == programAssetsList.length ? true:false : false}
            changeFilterFn={this.changeFilterFn}
            recordsFound = {programAssetsList.length?false:true}
            queryParamsEnabled={false}
            tableHeight = {window.innerHeight-220+'px'}
          />
        </div>:null
        }
        {location.pathname.indexOf("/technical/program-planner/program/")!=-1 && location.pathname.indexOf("/details")==-1 ?
        <div  style={{marginLeft:'20px'}}>
          <TableUIComp
            theads={[{label:'MSN/ESN', sortKey: ''}, {label:'Inspection Due Date', sortKey: ''}, {label:'Quarter', sortKey: ''},{label:'Risk Level', sortKey: ''}, {label:'Cur. Lessee', sortKey: ''}, {label:'Inspection Type', sortKey: ''},{label:'Last Insp.', sortKey: ''},{label:'Date of MFR.', sortKey: ''},{label:'Lease Expiry', sortKey: ''},{label:'', sortKey: ''}]}
            content={programConfirmAssetsList.map((item, index) => <ConfirmList key={index} toggleDelModal={this.toggleDelAssetModal} toggleComments={this.toggleComments} data={item}  updateForm={this.props.updateForm} techConstants={this.props.techConstants} inspectionType={this.props.inspectionType} />)}
            tableBulkOp = { <BulkOptConfirm addText= "CONFIRM"  assetExport={this.assetExport} toggleSidebar= {() => this.props.reviewConfirm()}  />}
            pagination = {[]}
            filter={{menuList: menuList, appliedFilter: {}, displayFilter:true}}
            sortTable={this.sortTable}
            changePerPage ={this.changePerPage}
            changePagination = {this.changePagination}
            closeFilter = {this.closeFilter}
            changeFilterFn={this.changeFilterFn}
            recordsFound = {programConfirmAssetsList.length?false:true}
            queryParamsEnabled={false}
            tableHeight = {window.innerHeight-220+'px'}
          />
        </div>:null
        }
        </div>}
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={'Are you sure you want to delete ?'} className="modal-tform-delete-pictures modal-dialog-centered">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deletePlanner('')}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
              </li>
            </ul>
        </DeleteModal>
        <DeleteModal isOpen={this.state.delModalAsset} toggle={() => this.toggleDelAssetModal('')} title={browserHistory.getCurrentLocation().pathname.indexOf("/details")!=-1 ? 'Are you sure you want to remove ?' : 'Are you sure you want to delete ?'} className="modal-tform-delete-pictures modal-dialog-centered">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deleteAsset('')}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelAssetModal('')}>No</button>
              </li>
            </ul>
        </DeleteModal>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Comments'} </h3></div>}
          toggleEditSideBar={() => this.toggleComments()}
          isOpen={this.state.toggleComments}
          style={this.state.toggleComments ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.toggleComments?
          <CommentsEdit
           comments={this.state.typeFlag=='confirm'?programConfirmAssetsList.filter(item=>item.id==this.state.assetId)[0].comments:this.state.typeFlag=='scheduler'?programSchedulerList.filter(item=>item.id==this.state.assetId)[0].comments:programAssetsList.filter(item=>item.id==this.state.assetId)[0].comments}
           toggleDelModal={this.toggleDelModal}
           updateComments={this.updateComments}
           toggleEditSideBar={() => this.toggleComments()}
          />:null}

        </EditFormBar>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {'Details'} </h3></div>}
          toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
          isOpen={projectCrud.flag}
          style={projectCrud.flag ? {width: '600px'} : {width: '0px'}}
          >
          {projectCrud.flag ?
            <LaunchProject
              project={projectCrud}
              toggleEditAddSide={this.props.toggleEditAddSide}
              updateSideBar= {this.props.updateSideBar}
              toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
              projectLoader={this.props.projectLoader}
              inspectionType = {this.props.inspectionType}
              projectEngineer= {this.props.projectEngineer}
              projectLead = {this.props.projectLead}
              workOrderInfo = {this.toggleWorkorderInfo}
              sLesseeList = {this.props.sLesseeList}
              addEditProject= {this.addEditProject}
              projectDepartment = {this.props.techConstants.filter(item => item.type == 'project_departments')}
              projectNames = {this.props.techConstants.filter(item => item.type == 'project_name')}
              />
            :null
          }
        </EditFormBar>
        <DeleteModal isOpen={this.state.workOrderInfoFlag}
         toggle={() => this.toggleWorkorderInfo()}
         title={'Nomenclature'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <HelpTextInspectionType/>
        </DeleteModal>

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    plannerStatus: state.ProgramPlannerReducer.plannerStatus,
    programSchedulerList: state.ProgramPlannerReducer.programSchedulerList,
    programConfirmAssetsList: state.ProgramPlannerReducer.programConfirmAssetsList,
    programAssetsList: state.ProgramPlannerReducer.programAssetsList,
    selectedIds:state.ProgramPlannerReducer.selectedIds,
    techConstants: state.sharedReducers.techConstants,
    inspectionType: state.ProgramPlannerReducer.inspectionType,
    projectCrud: state.ProgramPlannerReducer.projectCrud,
    projectLoader: state.ProgramPlannerReducer.projectLoader,
    projectEngineer: state.ProgramPlannerReducer.projectEngineer,
    projectLead: state.PorjectsReducer.projectLead,
    sLesseeList: state.sharedReducers.sLesseeList,
    sEngineTypes: state.sharedReducers.sEngineTypes,
    aircraftTypes: state.sharedReducers.aircraftTypes,
    statusLoader: state.ProgramPlannerReducer.statusLoader,
    commonLoader:state.ProgramPlannerReducer.commonLoader,
    filterLesseList: state.ProgramPlannerReducer.filterLesseList,
    filterAirCraftList: state.ProgramPlannerReducer.filterAirCraftList,
    filterEngineList: state.ProgramPlannerReducer.filterEngineList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    fetchProgramStatus: () => dispatch(fetchProgramStatusAc(ownProps.params)),
    fetchSchedulerAssets: (queryParams={}) => dispatch(fetchSchedulerAssetsAc(ownProps.params,queryParams)),
    fetchConfirmAssets: (queryParams={}) => dispatch(fetchConfirmAssetsAc(ownProps.params,queryParams)),
    fetchAssets: (queryParams={}) => dispatch(fetchAssetsAc(ownProps.params,queryParams)),
    createProgram: () => dispatch(createProgramAc(ownProps.params)),
    fetchEngines: () => dispatch(sEngineTypeAc()),
    fetchArchType: () => dispatch(sArctTypesAc()),
    fetchFilterList: (query) => dispatch(fetchFIlterListAc(query)),
    assetExportFn: (flag,query) => dispatch(assetExportAc(ownProps.params,flag,query)),
    fetchTechConstant: (type) => dispatch(sGlobalConstantAc(type)),
    fetchTechnicalInspection: (type) => dispatch(technicalInspectionTypeAc(type)),
    addAssets: (slug,ids) => dispatch(addAssetProgramAc(ownProps.params,slug,ids)),
    addAssetsConfirm: (ids) => dispatch(addAssetConfirmProgramAc(ownProps.params,ids)),
    reviewConfirm: () => dispatch(reviewConfimAssetAc(ownProps.params)),
    updateForm:(key,value,id,flag)=>dispatch(updateAssetValueAc(key,value,id,flag)),
    updateComment:(id,comment,typeFlag)=>dispatch(updateCommentAc(id,comment,typeFlag)),
    toggleEditAddSide: (data, slug) => dispatch(projectViewCrudAc(ownProps, data, slug)),
    addEditProject:(data, ids) => dispatch(addEditProjectAc(ownProps.params, data, ids)),
    fetchLesseeList:(id) => dispatch(sLesseeListAc()),
    getMsnSwitcher:() => dispatch(getMsnSwitcherAc()),
    fetchManufactureList:() => dispatch(sManufacturerAc()),
    deleteComment:(slug,id,flag) => dispatch(deleteCommentAc(slug,id,flag)),
    deleteAsset:(id,flag) => dispatch(deleteAssetAc(ownProps.params,id,flag)),
    fetchProjectEngineer: (queryParam, type) => dispatch(fetchProjectUserAc(queryParam, type)),
    fetchProjectLead: (queryParam, type) => dispatch(fetchProjectUserAc(queryParam, type)),
    selectItem: (flag, id) => dispatch({
        type: UPDATE_SELECTED_ASSETS,
        payload: {flag, id, type: 'single'}
      }),
    selectAllItem: (flag, all, type) => dispatch({
        type: UPDATE_SELECTED_ASSETS,
        payload: {flag, type:'all',typeList:type}
      }),
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramScheduler);
