export const INSURANCE_LIST = 'INSURANCE_LIST';
export const INSURANCE_ADD_SUCCESS = 'INSURANCE_ADD_SUCCESS';
export const INSURANCE_EDIT_SUCCESS = 'INSURANCE_EDIT_SUCCESS';
export const CLAIM_LIST = 'CLAIM_LIST';
export const CLAIM_VIEW = 'CLAIM_VIEW';
export const CLAIM_ADD_SUCCESS = 'CLAIM_ADD_SUCCESS';
export const CLAIM_EDIT_SUCCESS = 'CLAIM_EDIT_SUCCESS';
export const INSURANCE_CRUD = 'INSURANCE_CRUD';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const INSURANCE_CLAIM_LOADER = 'INSURANCE_CLAIM_LOADER';
