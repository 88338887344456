import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import '../assets/styles/EditSidebar.scss';
import 'react-select/dist/react-select.css';
import { imgStoragePath } from '../../../constants';
export default class EditSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
    }
  }
  render() {
    const { editSideBar, title, width, right } = this.props;
    const rightPos = editSideBar ? '0px': right ? right: '-670px';
    return (
      <div>
        <div className="edit-header-side-bar" style={{ width: width ? width: '670px', right: rightPos }}>
          <h3>
            {title ? title : 'Edit Lessor Details'}
            <img width="12" src={imgStoragePath+"black_close.png"} alt="img" onClick={() => this.props.toggleEditSideBar()} />
          </h3>
          <div>
            {this.props.children}
          </div>
        </div>
        {
          editSideBar ?
            <div className="overlays" style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: '999', background: 'rgba(0,0,0,0.4)' }}></div>
            : null
        }
      </div>
    )
  }
}
