import React, { Component } from 'react';
import { Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath } from '../../constants';
import { ToolTipHover } from '../../shared';
export default class PopoverNote extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false

    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }



  render() {
    const {  tagetId } = this.props;
    return (
		<span>
         <img className="img_eye" width="12" style={{ marginLeft: '5px',marginBottom:'2px'}} id={tagetId} onClick={this.toggle} src={ imgStoragePath + (this.state.popoverOpen ? 'inv_info_icon.png' : 'inv_info_icon.png') } alt="img"/>
            <ToolTipHover placement="right" tagetId={tagetId}>
                        <div className="popover-div">
                                <span style={{marginBottom:'5px'}} >
                                <span>Note: if exact DOM is not available please update approximate DOM </span>
                                </span>
                        </div>
            </ToolTipHover>
	    </span>
    );
  }
}
