export const TE005 = 'TE005';
export const TABLE_INSPECTION_DETAILS = 'TABLE_INSPECTION_DETAILS';
export const UPDATE_GEN_IP_FIELDS = 'UPDATE_GEN_IP_FIELDS';
export const UPDATE_SV_IP_FIELDS = 'UPDATE_SV_IP_FIELDS';
export const UPDATE_SV_DETAIL_UPDATE = 'UPDATE_SV_DETAIL_UPDATE';
export const LLP_IP_UPDATE = 'DISASSEMBLY_IP_UPDATE';
export const SV_UPLOAD_FILE = 'SV_UPLOAD_FILE';
export const UPDATE_SV_DESC = 'UPDATE_SV_DESC';
export const ADD_LLP_DETAILS = 'ADD_LLP_DETAILS';
export const DELETE_SV_ATTACHMENT = 'DELETE_SV_ATTACHMENT';
export const TOGGLE_SV = 'TOGGLE_SV';
export const LLP_DELETE = 'LLP_DELETE';
export const DISS_ASSEM_SIDEBAR = 'DISS_ASSEM_SIDEBAR';
export const UPDATE_DISS_ASSEM_FIELD = 'UPDATE_DISS_ASSEM_FIELD';
export const UPDATE_DISS_ASSEM_DATA = 'UPDATE_DISS_ASSEM_DATA';
export const ADD_DISS_ASSEM_DATA = 'ADD_DISS_ASSEM_DATA';
export const UPDATE_SV_WORK_SCOPE = 'UPDATE_SV_WORK_SCOPE';
export const DEL_SV_WORKSCOPE_ITEM = 'DEL_SV_WORKSCOPE_ITEM';
export const ADD_SV_WORKSCOPE_MODULE = 'ADD_SV_WORKSCOPE_MODULE';
export const ADD_SV_WORKSCOPE_SUBMODULE = 'ADD_SV_WORKSCOPE_SUBMODULE';
export const ADD_SV_WORKSCOPE_ITEM = 'ADD_SV_WORKSCOPE_ITEM';
export const VIEW_DISS_ASSEM_DATA = 'VIEW_DISS_ASSEM_DATA';
export const DIS_ASSEM_DELETE = 'DIS_ASSEM_DELETE';
export const TI_LOADER = 'TI_LOADER';
export const EDIT_DA_IMAGE = 'EDIT_DA_IMAGE';
export const SAVE_EDITED_DA_IMG = 'SAVE_EDITED_DA_IMG';
export const DELETE_DA_IMAGE = 'DELETE_DA_IMAGE';
export const UPDATE_SV_ERR = 'UPDATE_SV_ERR';
export const SORT_PICTURES_TE005='SORT_PICTURES_TE005';
