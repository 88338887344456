import React from 'react'
const FilterTypeDropdown = (props) => {
  if(props.isFilter){
    return(
      <ul {...props}>
        {props.children}
      </ul>
    )
  }else{
    return null
  }
}
export default FilterTypeDropdown;
