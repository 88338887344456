import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService,globalStaticDownloadGetService, globalDownloadGetService } from '../../../../globalServices/';
import { downloadFileType, toastFlashMessage } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { browserHistory } from 'react-router';
export const loadAssetViewAc = (params) => {
  return (dispatch) => {
    globalGetService('console/'+params.type+'/' + params.aircraft_slug + '/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.RECORDS_CTR_SHORT_INFO,
          payload: response.data.data
        });
      }
    })
  }
}
export const getActionDockListAc = (params, fileStatus, pageNumber, statusType, filter, searchText) => {
  return (dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    var actionObject = {};
    if(searchText != undefined && searchText != null && searchText != ''){
      actionObject = { [statusType]: fileStatus, page: pageNumber, sort: filter.sort, sort_by: filter.sort_by, search: searchText};
    }else {
      actionObject = { [statusType]: fileStatus, page: pageNumber, sort: filter.sort, sort_by: filter.sort_by};
    }
    globalGetService('records/' +params.type+'/'+ params.aircraft_slug + '/action-dock/get-files/',actionObject)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INQUE_LIST,
          payload: response.data.data
        })
        dispatch({
          type: actions.PAGINATION,
          payload: response.data.pagination
        });
      }
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });
    })

  }
}




export const changeActionDockStatusAc = (params, fileObject) => {
  let location = browserHistory.getCurrentLocation();
  return (dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/change-files-status/', fileObject)
    .then(response => {
      if(response.data.statusCode == 200){
        // dispatch({
        //   type: actions.UPDATE_DOCK_STATUS,
        //   payload: fileObject
        // })
        trackActivity('Item Status Changed',{
          item_type: 'File',
          page_title: 'Action Dock',
          application_module: 'Records',
          item_id: fileObject.objects[0],
          status:{
            1: 'OCR In Queue',
            2: 'Processing',
            3: 'Completed',
            4: 'Issue',
            5: 'OCR Not Applicable'
          }[fileObject.new_status]
        })
        if(location.query.status != null && location.query.status != undefined && location.query.status == 'ocr_status'){
          dispatch(getActionDockListAc(params, location.query.quetype, 1, 'ocr_status', {sort: '', sort_by: 'asc'}));
        }else {
          dispatch(getActionDockListAc(params, location.query.quetype, 1, 'status', {sort: '', sort_by: 'asc'}));
        }
          toastFlashMessage(response.data.message, 'success');
      }

    })
    setTimeout(function(){
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });
    }, 4000)
  }
}

export const publishAllFilesAc = (params, files) => {
  let location = browserHistory.getCurrentLocation();
  let publishing = {
    file: true,
    new_status: 3,
    objects: ["all"],
    current_status: location.query.quetype
  };
  //files.map(item => item.status != "Rejected" ? publishing.objects.push(item.uuid) : item);
  return (dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/change-files-status/', publishing)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PUBLISH_ALL_FILES,
          payload: {}
        })
        trackActivity('Item Status Changed',{
          item_type: 'File',
          page_title: 'Action Dock',
          application_module: 'Records',
          file_id: 'multiple',
          status: {
            1: 'OCR In Queue',
            2: 'Processing',
            3: 'Completed',
            4: 'Issue',
            5: 'OCR Not Applicable'
          }[publishing.new_status]
        })
        if(location.query.status != null && location.query.status != undefined && location.query.status == 'ocr_status'){
          dispatch(getActionDockListAc(params, location.query.quetype, 1, 'ocr_status', {sort: '', sort_by: 'asc'}));
        }else {
          dispatch(getActionDockListAc(params, location.query.quetype, 1, 'status', {sort: '', sort_by: 'asc'}));
        }
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });

    })

  }
}
export const publishFilesAc = (params, files, status) => {
  let location = browserHistory.getCurrentLocation();
  let publishing = {
    file: true,
    new_status: status,
    objects: files
  };
  return (dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/change-files-status/', publishing)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PUBLISH_FILES,
          payload: publishing.objects
        })
        trackActivity('Item Status Changed',{
          item_type: 'File',
          page_title: 'Action Dock',
          file_id: 'multiple',
          application_module: 'Records',
          status: {
            1: 'OCR In Queue',
            2: 'Processing',
            3: 'Completed',
            4: 'Issue',
            5: 'OCR Not Applicable'
          }[publishing.new_status]
        })
        if(location.query.status != null && location.query.status != undefined && location.query.status == 'ocr_status'){
          dispatch(getActionDockListAc(params, location.query.quetype, 1, 'ocr_status', {sort: '', sort_by: 'asc'}));
        }else {
          dispatch(getActionDockListAc(params, location.query.quetype, 1, 'status', {sort: '', sort_by: 'asc'}));
        }
        toastFlashMessage(response.data.message, 'success');
      }
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });
    })
  }
}
export const changeOCRPriorityAc = (params, uuid, status) => {
  let priority = {
    files_uuid:[uuid],
    priority: "1"
  };
  if (status == "1") {
    priority = {
      files_uuid:[uuid],
      priority: "2"
    }
  }
  return (dispatch) => {

    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/change-ocr-priority/', priority)
    .then(response => {

      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CHANGE_OCR_PRIORITY,
          payload: priority
        })
        toastFlashMessage(response.data.message, 'success');
      }

    })

  }
}
export const renameRecordAc = (params, item, pageNumber, publish) => {
  let fileObject = {
    new_name: item.name,
    object_uuid: item.uuid,
    type: item.type
  }
  if(publish){
    fileObject = {...fileObject,...publish}
  }
  let location = browserHistory.getCurrentLocation();
  return (dispatch) => {
    globalPostService(`records/file-name-checker/${item.folder.uuid}/`,{files: [item.name]})
    .then(response => {
      if(response.data.statusCode === 200){
        if(response.data.data.overwrite_files.length === 0){
          globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/rename/', fileObject)
          .then(response => {
            dispatch({
              type: actions.DOCK_LOADER,
              payload: true
            });
            if(response.data.statusCode == 200){
              dispatch({
                type: actions.RENAME_RECORD,
                payload: response.data.data
              })
              if(location.query.status != null && location.query.status != undefined && location.query.status == 'ocr_status'){
                dispatch(getActionDockListAc(params, location.query.quetype, pageNumber, 'ocr_status', {sort: '', sort_by: 'asc'}));
              }else {
                dispatch(getActionDockListAc(params, location.query.quetype, pageNumber, 'status', {sort: '', sort_by: 'asc'}));
              }
                toastFlashMessage(response.data.message, 'success');
            }

          })
          setTimeout(function(){
            dispatch({
              type: actions.DOCK_LOADER,
              payload: false
            });
          }, 4000)
        }else{
          toastFlashMessage('File already exist with same name in ' + item.folder.location, 'error');
        }
      }
    })
  }
}


export const restoreRejectedRecordAc = (params,actionType, fileInfo) => {
  let fileObject = {
    action: actionType,
    items:[fileInfo],
  }
  return(dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/rejected-bin/action/', fileObject)
    .then( response => {
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.RESTORE_REJECTED_RECORD,
          payload: fileObject.items,
        });
      }
    })
  }
}

export const exportActionDockAc = (params, fileStatus, pageNumber, statusType, filter, msn, type,name) =>{

  return(dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    globalStaticDownloadGetService('records/'+params.type+'/'+params.aircraft_slug+'/action-dock/get-files/',{ [statusType]: fileStatus, sort: filter.sort, search: filter.search , sort_by: filter.sort_by, download: type})
    .then(response => {
      if(response.data.statusCode){
        toastFlashMessage("Can not download file", "error");
      }else {
        downloadFileType(response.data, ['fan', 'hpc', 'hpt', 'lpt'].includes(params.type) ? name+ ' Action Dock.' : name+msn + ' Action Dock.', type)
      }
      trackActivity('Report Exported',{
        file_name: 'Action Dock',
        page_title: 'Action Dock',
        application_module: 'Records',
        file_type: type.toUpperCase()
      })
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });
    });
  }
}

export const deleteInqueueAc = (params, uuid, fileStatus, pageNumber, statusType, filter, searchText) =>{
  return(dispatch) => {
    dispatch({
      type: actions.DOCK_LOADER,
      payload: true
    });
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/delete/',{type: 'file', new_status: 7, object_uuid: [uuid]})
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        trackActivity('File Deleted', {
        item_id: uuid,
        application_module: 'Records',
        page_title: 'Action Dock'
        })
      }
      dispatch({
        type: actions.DOCK_LOADER,
        payload: false
      });
      dispatch(getActionDockListAc(params, fileStatus, pageNumber, statusType, filter, searchText))
    });
  }
}
