import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { prettyNumberParamsR, utilizationFormat, displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import { Link } from 'react-router';
import { NoRecordFound  } from '../../../../shared';
import moment from 'moment';
export default class LifeAdjustInfo extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      modal: false
    };
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  render(){
  const { halfLifeItm } = this.props;

  return(
      <div className="">
        <div className="adj-inner-block">
          <h6>Full Life Remaining</h6>
          {halfLifeItm.full_life_remaining_percentage ?
            <NumericLabel params={utilizationFormat}>
              {halfLifeItm.full_life_remaining_percentage}
            </NumericLabel>: '0.00'
          }%
        </div>
        <div className="adj-inner-block">
          <h6>Half Life Remaining</h6>
          {halfLifeItm.half_life_remaining_percentage ?
            <NumericLabel params={utilizationFormat}>
              {halfLifeItm.half_life_remaining_percentage}
            </NumericLabel>: '0.00'
          }%
        </div>
        {
          halfLifeItm.type == 'engine' ?
          <div>
          <div className="adj-inner-block">
            <h6>PRSV Half Life Adjustment</h6>
            {halfLifeItm.prsv_half_life_adjustment ?
                  <NumericLabel params={showCurrencyFormat()}>
                    {halfLifeItm.prsv_half_life_adjustment}
                  </NumericLabel> : '--'
                }

         </div>
          <div className="adj-inner-block">
            <h6>LLP Half Life Adjustment <Link onClick={this.toggle} className="llp-breakdown-modal">View Cost</Link></h6>
            {halfLifeItm.llp_half_life_adjustment ?
                  <NumericLabel params={showCurrencyFormat()}>
                    {halfLifeItm.llp_half_life_adjustment}
                  </NumericLabel> : '--'
                }
          </div>
          <div className="adj-inner-block">
            <h6>Total Half Life Adjustments</h6>
            <NumericLabel params={showCurrencyFormat()}>{halfLifeItm.llp_half_life_adjustment && halfLifeItm.prsv_half_life_adjustment? halfLifeItm.llp_half_life_adjustment + halfLifeItm.prsv_half_life_adjustment : 0}</NumericLabel>
          </div>
        </div> :
           <div className="adj-inner-block">
          <h6>Half Life Adjustments</h6>
          <NumericLabel params={showCurrencyFormat()}>{halfLifeItm.half_life_adjustment ? halfLifeItm.half_life_adjustment : 0}</NumericLabel>
          </div>

        }
        {  halfLifeItm.llps ?
          <Modal   size="lg"  isOpen={this.state.modal} toggle={this.toggle} className="llp-brekup-modal half-life-modal" >
            <ModalHeader toggle={this.toggle}>
              Life Limited  Parts<br/>
              <span className="engine-name">{halfLifeItm.name}</span>
              <span className="as-of-date">As of {moment(halfLifeItm.as_of_date).format(displayDateFormat)}</span>
            </ModalHeader>
              <ModalBody className="engine-llp">
                  <form onSubmit={(e) => this.engineAddLLP(e)}>
                    <table>
                      <thead>
                        <tr>
                          <th className="caption-ui">LLP Type</th>
                          <th className="caption-ui">LLP Description</th>
                          <th className="caption-ui">LLP Part Number</th>
                          <th className="caption-ui">TSN</th>
                          <th className="caption-ui">CSN</th>
                          <th className="caption-ui">Cycle Limit</th>
                          <th className="caption-ui">Cost</th>
                          <th className="caption-ui">Cycle Remaining</th>
                          <th className="caption-ui">Half Life Remaining<br/> Percentage (%)</th>
                          <th className="caption-ui">Full Life Remaining<br/> Percentage (%)</th>
                          <th className="caption-ui">Half Life Adjustment</th>
                        </tr>
                      </thead>
                      <tbody>
                           {
                             halfLifeItm.llps.map((llpInfo, index) => {
                            return (
                              <tr key={index}>
                                <td className="caption-ui">{llpInfo.llp_type}</td>
                                <td className="caption-ui">{llpInfo.description}</td>
                                <td className="caption-ui">{llpInfo.part_number}</td>
                                <td className="caption-ui">
                                {llpInfo.tsn ?
                                    <NumericLabel params={utilizationFormat}>
                                      {llpInfo.tsn}
                                    </NumericLabel> : '--'
                                  }
                                </td>
                                <td className="caption-ui">{ Math.round(llpInfo.csn)}</td>
                                <td className="caption-ui">{llpInfo.cycles_max_limit}</td>
                                  <td className="caption-ui">
                                  {llpInfo.cost ?
                                      <NumericLabel params={showCurrencyFormat()}>
                                        {llpInfo.cost}
                                      </NumericLabel> : '--'
                                    }
                                  </td>
                                <td className="caption-ui">{llpInfo.cycles_remaining}</td>
                                <td className="caption-ui">
                                  {
                                    llpInfo.half_life_remaining_percentage ?
                                    <NumericLabel params={utilizationFormat}>{llpInfo.half_life_remaining_percentage}</NumericLabel>:'0.00'
                                  }
                                </td>
                                <td className="caption-ui">
                                  { llpInfo.full_life_remaining_percentage ?
                                    <NumericLabel params={utilizationFormat}>{llpInfo.full_life_remaining_percentage}</NumericLabel>:'0.00'
                                  }
                                </td>
                                <td className="caption-ui">
                                {llpInfo.half_life_adjustment ?
                                    <NumericLabel params={showCurrencyFormat()}>
                                      {llpInfo.half_life_adjustment}
                                    </NumericLabel> : '--'
                                  }
                                </td>
                              </tr>
                            )
                           }
                        )}
                      </tbody>
                    </table>
                    {!halfLifeItm.llps.length ? <NoRecordFound description=""/>:null}
              </form>
        </ModalBody>
        <ModalFooter>
          <div className="select-llp-replace-total">
            <p>Total Half Life Adjustment Cost</p>
              <NumericLabel params={prettyNumberParamsR}>
                {
                  halfLifeItm.llps.reduce((total, llp) => total + llp.half_life_adjustment, 0 )
                }
            </NumericLabel>
          </div>
        </ModalFooter>
      </Modal>: null }
      </div>
     )
  }
}
