import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UPDATE_ENGINE_MRDETAIL } from '../actions';
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
class EngineField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldFocus : false
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true
    });
    this.props.updateErrorCode(this.props.keyParam);
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false
    })
  }
  render() {
    const { col, label, outerKey, keyParam, engines, inputType, value, errorMsg, prefix, addSymbol } = this.props;
    if (outerKey) {
      if (inputType == 'text') {
        return (
          <Col sm={col}>
            <label style={ this.state.fieldFocus ? {color:'#2f8cff'} : {color:'#828282'} }>{label}</label>
            <span className="field-container">
              { addSymbol && prefix ? <span>$</span>:null}
              <input type="text"
                value={engines[outerKey][keyParam]}
                onChange={(e) => this.props.updateEngineField(e.target.value, outerKey, keyParam)}
                onFocus={() => this.handleFocus()}
                onBlur={() => this.handleBlur()}
              />
            { addSymbol && !prefix ? <span>%</span>:null}
            </span>
            <h6 className="error-msg">{errorMsg}</h6>
          </Col>
        )
      } else if(inputType == 'checkbox') {
        return (
          <label className="match-llp-block">
            <input type="checkbox"
              checked={engines[outerKey][keyParam]}
              onChange={(e) => this.props.updateEngineField((e.target.checked ? true : false), outerKey, keyParam)}
              onFocus={() => this.handleFocus()}
              onBlur={() => this.handleBlur()}
          />
            {label}
          </label>
        )
      } else if(inputType == 'date') {
        return (
          <Col sm={col}>
            <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'}}>{label}</label>
            <DatePicker
              selected={engines[outerKey][keyParam] && moment(engines[outerKey][keyParam]).isValid() ? moment(engines[outerKey][keyParam]) : null}
              dateFormat = {fieldDateFormat}
              onChange={(e) => this.props.updateEngineField(moment(e).format(backendDateFormat)=="Invalid date"? null : moment(e).format(backendDateFormat), outerKey, keyParam)}
              onFocus={() => this.handleFocus()}
              onBlur={() => this.handleBlur()}
              maxDate = {this.props.minDate}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
            />
          <h6 className="error-msg">{errorMsg}</h6>
          </Col>
        )
      } else if(inputType == 'radio'){
        return (
          <ul className="list-inline lg-radio-block flex-centered">
            <li className="list-inline-item">
              <label>
                <input type="radio" name="lsv-status" checked={ value ? false:true } onChange={(e) => this.props.updateEngineField(false, outerKey, 'lsvDateStatus')} />
                <span>TSLSV & CSLSV</span>
              </label>
            </li>
            <li className="list-inline-item">
              <label>
                <input type="radio" name="lsv-status" checked={value ? true:false } onChange={(e) => this.props.updateEngineField(true, outerKey, 'lsvDateStatus')} />
                <span>LSV</span>
              </label>
            </li>
          </ul>
        )
      }
    } else{
      return null
    }

  }
}
const mapStateToProps = state => ({
  engines: state.MRFinanaceRptReducer.reviewMRReport.engines,

});
const mapDispatchToProps = dispatch => {
  return {
    updateEngineField: (value, outerKey, innerkey) => dispatch({
      type: UPDATE_ENGINE_MRDETAIL,
      payload: { value , outerKey, innerkey }
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EngineField);
