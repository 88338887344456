import React, {Component} from 'react';
import { Row, Col} from 'reactstrap';
export default class LogListHeading extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { filterData } = this.props;
    return(
      <div>
        <Row className="logHeading">
          <Col md="1">
            <h6 className="caption-ui" onClick={() => this.props.updateLogSort('log_id')}>
              Log ID
              <sup className="asc-sort" style={(filterData.sort == 'log_id' && filterData.sort_by == 'asc') ? { color: '#000'} : {color: '#dfdfdf'}}> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'log_id' && filterData.sort_by == 'desc') ? { color: '#000'} : {color: '#dfdfdf'}}>&#9660;</sub>
              </h6>
            </Col>
            <Col md="2">
              <h6 className="caption-ui "  onClick={() => this.props.updateLogSort('updated_at')}>
                Generated / Updated On
                <sup className="asc-sort" style={(filterData.sort == 'updated_at' && filterData.sort_by == 'asc') ? { color: '#000'} : {color: '#dfdfdf'}}> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'updated_at' && filterData.sort_by == 'desc') ? { color: '#000'} : {color: '#dfdfdf'}}>&#9660;</sub>
              </h6>
            </Col>
            <Col md="1">
              <h6 className="caption-ui">
                Aircraft
              </h6>
            </Col>
            <Col md="2">
              <h6 className="caption-ui">
                Engine
              </h6>
            </Col>
            <Col md="2">
              <h6 className="caption-ui">
                Region of Operation
              </h6>
            </Col>
            <Col md="2">
              <h6 className="caption-ui">
                Engine Utilization Ratio
              </h6>
            </Col>
            <Col md="2">
              <h6 className="caption-ui ">
                Major Assemblies selected
              </h6>
            </Col>
          </Row>
        </div>
      )
    }
  }
