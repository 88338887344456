import React, { Component } from 'react';
import {ContrListCol} from '../../../contracts/Elements'
export default class LopaTab extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { lopa, index, asset_slug } = this.props;
    const equipmentObj = [
      {value: lopa.file_name},
      {value: lopa.remarks},
      {value: <a href={lopa.image} onClick={'noPropagation'} target="_blank">View LOPA</a>}
    ]
    return (
      <tr onClick={() => this.props.viewLopa(asset_slug, lopa.id, index, 'lopaView')}>
         {equipmentObj.map((lopa,index) =>
           <ContrListCol key={index} colObj={lopa}   />
        )}
      </tr>
    )
  }
}
