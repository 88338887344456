import * as actions from '../actions';
import { getLocalStorageInfo} from '../../../utils'

export default function( state = {
   type:'view',
   processing:false,
   lessorLoader:false,
   lessorObj:{
    logo:getLocalStorageInfo()? getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.logo:'':'',
    name: getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.name:'':'',
    contact_address: getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.contact_address:'':'',
    country:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.country:'':'',
    bank_name:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_name:'':'',
    bank_account_name:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_account_name:'':'',
    bank_account_number:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_account_number:'':'',
    bank_code:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_code:'':'',
    bank_iban:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_iban:'':'',
    bank_sort_code:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_sort_code:'':'',
    bank_swift_code:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_swift_code:'':'',
    bank_reference:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_reference:'':'',
    bank_note:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.bank_note:'':'',
    footer_note:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.footer_note:'':'',
    distribution_emails:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.distribution_emails:'':'',
    spv:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.spv:'':'',
    jurisdiction:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.jurisdiction:'':'',
    introduction:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.introduction:'':'',
    disclaimer:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.disclaimer:'':'',
    confidentiality:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.confidentiality:'':'',
    email:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.email:'':'',
    phone_number:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.phone_number:'':'',
    summary_tnc:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.summary_tnc:'':'',
    hub_number:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.hub_number:'':'',
    vat_number:getLocalStorageInfo()?getLocalStorageInfo().defaultLessor?getLocalStorageInfo().defaultLessor.vat_number:'':'',
   },
   updateImageFlag:false,
   imageFile:'',
   ownerList:[],
   bankingDetailList:[],
   bankingDropdownList:[],
   ownerDropdownList:[],
   bankingDropdownList: [],
   ownerDropdownList: []
}, action){
  switch (action.type) {
    case actions.OWNER_DROPDOWN:
      return {
        ...state,
        ownerDropdownList: action.payload
      }
    case actions.BANK_DROPDOWN:
      return {
        ...state,
        bankingDropdownList: action.payload
      }
    case actions.TYPE_LESSOR_UPDATE:
      return {
        ...state,
        type: action.payload
      }
    case actions.UPDATE_LESSOR_DATA:
      return {
        ...state,
        lessorObj: action.payload
      }
    case actions.LESSOR_PROCESSING:
      return {
        ...state,
        processing: action.payload
      }
    case actions.LESSOR_LOADER:
      return {
        ...state,
        lessorLoader: action.payload
      }
    case actions.LESSOR_FIELD_UPDATE:
      return {
        ...state,
        lessorObj: {
        ...state.lessorObj,
        [action.payload.type]:action.payload.value
        }
      }
    case actions.OWNERS_LIST:
      return {
        ...state,
        ownerList:action.payload
      }
    case actions.ADD_OWNER:
      return {
        ...state,
        ownerList:[...state.ownerList, action.payload]
      }
    case actions.EDIT_OWNER:
      return {
        ...state,
        ownerList:state.ownerList.map(owner => owner.id == action.ownerID ? action.payload:owner)
      }
    case actions.DELETE_OWNER:
      return {
        ...state,
        ownerList:state.ownerList.map(owner => owner.id != action.ownerId)
      }
    case actions.BANKING_DETAILS_LIST:
      return {
        ...state,
        bankingDetailList:action.payload
      }
    case actions.ADD_BANKING_DETAILS:
      return {
        ...state,
        bankingDetailList:[...state.bankingDetailList, action.payload]
      }
    case actions.EDIT_BANKING_DETAILS:
      return {
        ...state,
        bankingDetailList:state.bankingDetailList.map(bank => bank.id == action.bankID ? action.payload : bank)
      }
    case actions.BANKING_DROPDOWN_LIST:
        return {
          ...state,
          bankingDropdownList:action.payload
        }
    case actions.OWNER_DROPDOWN_LIST:
        return {
          ...state,
          ownerDropdownList:action.payload
        }
    default:
      return state;
  }
}
