import React , { Component } from 'react'

export default class TrialExpired extends Component{
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className="trial-expired-con">

      </div>
    )
  }
}
