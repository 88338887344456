import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import  NoRecordFound  from './NoRecordFound';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem,UncontrolledTooltip } from 'reactstrap';
import { globalGetService} from '../../globalServices/index'
import '../assets/styles/Switcher.scss'
import {toastFlashMessage} from '../../utils'

class Switcher extends Component{
  constructor(props){
    super(props);
    this.state = {
        dropdownOpen: false,
        tooltipOpen:false


    }
  }

  toggle = () =>{ this.setState({
    dropdownOpen: !this.state.dropdownOpen,
    tooltipOpen:!this.state.tooltipOpen

   }); }

  applicationSwitcher = (application)=>{
    globalGetService(`console/app-switcher/${this.props.params.type}/${this.props.params.aircraft_slug}/`, {'module': application})
      .then((response)=>{
        switch (application){
          case 'Records':
            if(response.data.statusCode == 204){
              browserHistory.push(`/${response.data.data.asset_type.label}/${response.data.data.asset_slug}/records/dataRoom/`)
              toastFlashMessage(response.data.message, 'success');
            }
            if(response.data.statusCode == 200){
              browserHistory.push(`/${response.data.data.asset_type.label}/${response.data.data.asset_slug}/records/data-room/technical/${response.data.data.uuid}`)
              toastFlashMessage(response.data.message, 'success');

            }
             break;
          case 'Technical':
            if(response.data.statusCode == 200){
              if(response.data.data.multi_project){
                browserHistory.push(`/technical/projects?msn=${response.data.data.asset_info.serial_number}&page=1&project_status=all`)
                toastFlashMessage(response.data.message, 'success');
              }
              else{
                 if(response.data.data.form_info){
                    browserHistory.push(`/${response.data.data.asset_info.asset_type.label}/${response.data.data.asset_info.asset_slug}/technical/${response.data.data.form_info.work_order_slug}/forms/${response.data.data.form_info.slug}/${response.data.data.form_info.name}`)
                    toastFlashMessage(response.data.message, 'success');


                }
                else{
                    browserHistory.push(`/technical/project/view/${response.data.data.project_slug}`)
                    toastFlashMessage(response.data.message, 'success');
                }
              }
            }else{
              browserHistory.push('/technical/projects?project_status=all') 
              toastFlashMessage(response.data.message,"success");  
             }
            break;
          case 'Contracts':
            if(response.data.statusCode == 200) {
              browserHistory.push(`/${response.data.data.asset_type.label}/${response.data.data.asset_slug}/contract/dashboard`)
            }

            break;
          case 'Maintenance':
            if(response.data.statusCode == 200) {
              browserHistory.push(`/${response.data.data.asset_type.label}/${response.data.data.asset_slug}/maintenance/claims`)
            }

            break;
          default:

        }

      })
  }


  render(){
    return(
      <Dropdown className="application-sw-dropdown" isOpen={this.state.dropdownOpen} toggle={() => this.toggle()}>
        <DropdownToggle caret
          tag="span"
          data-toggle="dropdown"
          aria-expanded={this.statedropdownOpen}
          style={{color:"#BD10E0"}}
        >
          <span id="TooltipExample" > {this.props.app}</span>

          <UncontrolledTooltip placement="right" isOpen={this.state.tooltipOpen} target="TooltipExample" onClick={()=>this.toggle()} style={{background:"#212529"}}>
             Application switcher
          </UncontrolledTooltip>

        </DropdownToggle>
        <DropdownMenu>
          {/* <ul className="list-unstyled">
            {this.props.list.map((item,index) =>

              <li onClick={() => this.applicationSwitcher(this.props.app == "Records"?"Technical":"Records")} key={index}>{item}</li>
            )}

            
          </ul> */}
          {this.props.app == "Records" && (
          <ul className="list-unstyled">
              <li onClick={() => this.applicationSwitcher('Technical')} >Technical</li>
              <li onClick={() => this.applicationSwitcher('Contracts')} >Contracts</li>
              <li onClick={() => this.applicationSwitcher('Maintenance')} >Maintenance</li>
  
         </ul>)}
         {this.props.app == "Technical" && (
          <ul className="list-unstyled">
              <li onClick={() => this.applicationSwitcher('Records')} >Records</li>
              <li onClick={() => this.applicationSwitcher('Contracts')} >Contracts</li>
              <li onClick={() => this.applicationSwitcher('Maintenance')} >Maintenance</li>
  
         </ul>)}
         {this.props.app == "Contracts" && (
          <ul className="list-unstyled">
              <li onClick={() => this.applicationSwitcher('Records')} >Records</li>
              <li onClick={() => this.applicationSwitcher('Technical')} >Technical</li>
              <li onClick={() => this.applicationSwitcher('Maintenance')} >Maintenance</li>
  
         </ul>)}
         {this.props.app == "Maintenance" && (
          <ul className="list-unstyled">
              <li onClick={() => this.applicationSwitcher('Records')} >Records</li>
              <li onClick={() => this.applicationSwitcher('Technical')} >Technical</li>
              <li onClick={() => this.applicationSwitcher('Contracts')} >Contracts</li>
  
         </ul>)}
          
          
          
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default withRouter(Switcher);
