
import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import {  prettyNumberParamsL } from '../../../constants';

export default class SimMonthTotal extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false,
    }
  }


  totalAmount = (date) => {
    let sum = 0 ;
    if(this.props.cashSimulation.cashflow_data){
      this.props.cashSimulation.cashflow_data.map((data, index) => {
        let arr = data.cashflow.filter(cashflow =>  cashflow.date == date);
        if(arr.length){
          sum += arr[0].amount;
        }
      })
    }
    return sum;
  }

  render(){
    const { customClass, heading, cashSimulation, monthList } = this.props;
    if(!Object.keys(cashSimulation).length){
      return false;
    }
    return(
      <div className="item-block simulation-total-block">

       <div className="row-header">
          {
            monthList.map((date, index) => {
              let sum = this.totalAmount(date);
              return (
                <div key={index} className={ sum  < 0 ? 'neg-value item-detail-block': 'item-detail-block'}>
                  <p className="para-ui text-right">
                    <NumericLabel params={prettyNumberParamsL}>{sum}</NumericLabel>
                  </p>
               </div>
              )
            }

            )
          }
      </div>
    </div>

    )
  }
}
