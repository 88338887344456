import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { getAccessToken } from '../../../utils';
import { Link } from 'react-router';
import { displayDateFormat } from '../../../constants';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL, imgStoragePath } from '../../../constants';
export default class PortfolioItem extends Component {
  constructor(props) {
    super(props);
  }

  toggle(e,portfolio){
    e.preventDefault();
    e.stopPropagation();
    this.props.portFolioCrud('edit',{type: 'edit',flag: true, data: portfolio});
  }
  render() {
    const { portfolio,index,delPortfolio } = this.props;
    let url = '/fleet-cashflow/portfolio/' + portfolio.slug;
    return (
      <div className="asset-list">
        <Link to={url}>
          <Row className="flex-centered">
              <Col md="2">
                  {
                    (portfolio.portfolio_type.label == '' || portfolio.portfolio_type.label == undefined || portfolio.portfolio_type.label == null) ?
                      null
                      : <span className="portfolio-type">{portfolio.portfolio_type.label}</span>
                  }
                <h6>
                  {portfolio.name}
                </h6>

                  {
                    (portfolio.description == '' || portfolio.description == undefined || portfolio.description == null) ?
                        <p className="portfolio-description"> -- </p>
                      : <p className="portfolio-description">{
                        portfolio.description.length > 40 ?
                        portfolio.description.substr(0,37) + "...":
                        portfolio.description
                      }</p>
                  }
                <p className="portfolio-description">
                  {moment(portfolio.created_at).format(displayDateFormat)}
                </p>
              </Col>
            <Col md="1"> <h6 className="theta">{portfolio.no_of_asset}</h6></Col>
            <Col md="1">
              { (portfolio.status.label != '' && portfolio.status.label != null && portfolio.status.label != undefined)?
                <h6 className="theta">
                  {portfolio.status.label}
                </h6>
                :<h6 className="theta">
                  --
                </h6>
              }
            </Col>
            <Col md="2">
              { (portfolio.portfolio_owner != '' && portfolio.portfolio_owner != null && portfolio.portfolio_owner != undefined)?
              <h6 className="theta">
                {portfolio.portfolio_owner}
              </h6> :<h6 className="theta">
                --
              </h6>}
            </Col>
            <Col md="1">
              { (portfolio.credit_rating != '' && portfolio.credit_rating != null && portfolio.credit_rating != undefined)?
              <h6 className="theta">
                {portfolio.credit_rating}
              </h6> :<h6 className="theta">
                --
              </h6>}
            </Col>
            <Col md="3" className="cashflow">
              <h6 className="theta">
                <span>Total Inflow:</span><NumericLabel params={prettyNumberParamsL}>{portfolio.total_inflow}</NumericLabel>
              </h6>
              <h6 className="theta">
                <span>Total Outflow:</span><NumericLabel params={prettyNumberParamsL}>{portfolio.total_outflow}</NumericLabel>
              </h6>
              <h6 className="theta">
                <span>Net Result:</span><NumericLabel params={prettyNumberParamsL}>{portfolio.net_result}</NumericLabel>
              </h6>
            </Col>
            <Col md="2"> <h6 className="theta">
              {moment(portfolio.updated_at).format(displayDateFormat)}
              <br/>
              <img src={portfolio.modified_by.profile_pic} />{portfolio.modified_by.name}
              <img onClick={ (e) => this.toggle(e,portfolio)} className="action-icon" width="20" src={imgStoragePath+"edit-field.png"} alt="" /></h6>
            </Col>
          </Row>
        </Link>
      </div>
    )
  }
}
