import React from 'react';
import {Row, Col } from 'reactstrap';
class OwnerClient extends React.Component {
  constructor(props){
    super(props);

  }


  createOwner = (flag) => {

    this.props.updateForm({type: 'owned_by_type', value:flag, sectionType: 'EngineCurrentDetails', index:this.props.index})
  }

  render(){

    

    return(
      <div>
        <div className="form-group-edit" style={{ marginTop: '-35px'}} >
          <label className="label" style={{ marginBottom: '10px'}}>{this.props.children}</label>
          {
           <div>
            <Row>
            <Col md="4">
            {
              <label className="radio-block" style={{marginBottom: '0px'}} >
                <input type="radio" name="owner-type" checked={this.props.value === 1} onChange={() => this.createOwner(1)} />
                Owner
              </label>
            }
            </Col>
            <Col md="4" >
              {
                <label className="radio-block" style={{marginBottom: '0px'}} >
                  <input type="radio" name="owner-type" checked={this.props.value === 2} onChange={() => this.createOwner(2)} />
                  Client
                </label>
              }
            </Col>
            </Row>
          </div>
          }
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default OwnerClient;
