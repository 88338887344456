import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../../assets/styles/records_v.scss';
import { mediaBaseUrl } from '../../../../constants';
import { getFileSize, getAccessToken } from '../../../../utils';
import ToolTipHover from '../../../../shared/components/ToolTipHover';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, displayDateTimeFormat } from '../../../../constants';
import moment from 'moment';
export default class InqueueLst extends Component{
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      restoreFlag: false,
      delFlag: false
    };
  }
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  restoreRejectedFn = () => {
    this.setState(prevState => ({
      ...prevState,
      restoreFlag: !this.state.restoreFlag
    }));
  }
  deleteFile = () => {
    this.setState(prevState => ({
      ...prevState,
      delFlag: !this.state.delFlag
    }));
  }
  render(){
    const { listItem, index, queType, typeFlag } = this.props;
    return(
      <div className="asset-list" style={this.props.style}>
        <Row className="flex-centered">
          <Col md="5">
            <div className="flex-centered msn-block record-preview">
              <div>
                {(this.props.queType == 1 || this.props.queType == 2) && typeFlag == 'status' && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJ') != -1 || getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ) ?
                  <input style={{ marginRight: '10px'}} onClick={this.props.addToPublish} checked = {this.props.publishList.indexOf(listItem.uuid) != -1 ? 'checked' : ''} type="checkbox"/>
                  :null
                }
              </div>
              <div>
                <img src={this.props.fileIcons[listItem.extension]} alt="img" width="15"/>
              </div>
              <Link to={listItem.extension == 'pdf' ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${listItem.path}` : (listItem.extension == 'csv' || listItem.extension == 'xls' || listItem.extension == 'xlsx') ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${listItem.path}` : decodeURIComponent(`${listItem.path}`)} target="_blank"><span>{listItem.name}</span></Link>
            </div>
            <h6 className="thet">{listItem.location}</h6>
          </Col>
          <Col md="1">
            <h6 className="theta">{getFileSize(listItem.size)}</h6>
          </Col>
          <Col md="2">
            <div className="flex-centered msn-block">
              <div>
                <img className="user-logo" src={ listItem.modified_by != null && listItem.modified_by != undefined && listItem.modified_by != '' ? listItem.modified_by.profile_pic != null ? `${listItem.modified_by.profile_pic}` : `${imgStoragePath}user.png` : listItem.created_by.profile_pic != null ? `${listItem.created_by.profile_pic}` : `${imgStoragePath}user.png`} alt="img" />
              </div>
              { listItem.modified_by != null && listItem.modified_by != undefined && listItem.modified_by != ''?
                <div>
                  <h4 className="para-ui">{listItem.modified_by.first_name} {listItem.modified_by.last_name}</h4>
                </div>
                :<div>
                  <h4 className="para-ui">{listItem.created_by.first_name} {listItem.created_by.last_name}</h4>
                </div>
              }
            </div>
          </Col>
          <Col md="1">
            <h6 className="theta">
              { listItem.modified != null && listItem.modified != undefined && listItem.modified != '' ?
                `${moment(listItem.modified).format(displayDateTimeFormat)}`
                :`${moment(listItem.created).format(displayDateTimeFormat)}`
              }
            </h6>
          </Col>
          <Col md={ queType == 1 ? "2": "3" }>
            { queType == 2 ?
              <ul className="list-inline" style={{display: 'inline-block', marginRight: '5px'}}>
                {getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REN') != -1 ?
                <li className="list-inline-item">
                  <span className='rename-action' onClick={this.props.renameFile}>
                    <img src={imgStoragePath+"warning.png"}/>Rename
                  </span>
                </li>:null}
              </ul>:null
            }
            { ((listItem.status == 'Uploaded' || listItem.status == 'OCR' || listItem.status == 'Rename') && ((queType == 1 || queType == 2) && typeFlag=='status' ) && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 || getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJ') != -1 )) ?
              <Dropdown  isOpen={this.state.dropdownOpen} toggle={this.toggle} >
                <DropdownToggle caret>
                <img className="warning-img" src={imgStoragePath+"warning.png"} alt="img" width="15"/> Pending
                </DropdownToggle>

                <DropdownMenu>
                  {getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ?
                     <DropdownItem className="publish" onClick={() => this.props.changeActionDockStatus({file: true, new_status: 3, objects:[listItem.uuid]})}>Publish <img src={imgStoragePath+"green_flag.png"} alt="img" width="20"/></DropdownItem>
                     :null}
                  {getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJ') != -1 ?
                    <DropdownItem className="reject" onClick={() => this.props.changeActionDockStatus({file: true, new_status: 4, objects:[listItem.uuid]})}>Reject <img src={imgStoragePath+"cross_red.png"} alt="img"  width="20"/></DropdownItem>
                  :null}
              </DropdownMenu>
            </Dropdown>:null
            }
            { typeFlag =='ocr_status' && parseInt(queType) !== 4 && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('OCR') != -1 ?
              listItem.ocr_priority == 1 ?
              <img onClick={ listItem.ocr_status== 1 ? this.props.changeOCRPriority : null } src={imgStoragePath+"ocr_complete.png"} width="20"/>:
              <img onClick={listItem.ocr_status==1 ? this.props.changeOCRPriority : null} src={imgStoragePath+"ocr_start.png"} width="20"/> : null
            }
            { parseInt(queType) == 4 ?
            <div className="restoreRejected">
               <span className='rejected-action'>{typeFlag =='ocr_status' ? "OCR " : ""}Rejected </span>
             {getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('RES') != -1 ?
              <Link style={typeFlag !== 'ocr_status' ? {right: '40px'} : {display: 'none'}} onClick={() => this.restoreRejectedFn()}><img src={imgStoragePath+"refresh_blue.png"} alt="img"/></Link>:null}
              {getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('D') != -1 ?
               <Link style={{color: 'rgb(255, 94, 94)', fontSize: '14px', marginTop: '4px'}} onClick={() => this.deleteFile()}><i id="trash-0" class="fa fa-trash"></i></Link>:null}
            </div>
              :null
            }
         </Col>
         {
          queType == 1 ?
              <Col md="1">
               {
                  ( listItem.ocr_status == 2  && listItem.type == 'file') ?
                  <span>
                  <img id={"OCRIsPending-"+index} src={imgStoragePath+"rolling.gif"} width="22"/>
                  <ToolTipHover placement="top" tagetId={"OCRIsPending-"+index}>OCR is Processing</ToolTipHover>
                  </span>:null
                }
                {
                  (listItem.ocr_status == 3  && listItem.type == 'file') ?
                  <span>
                   <img id={"OCRComplete-"+index} src={imgStoragePath+"ocr_done_new.png"} width="22"/>
                  { <ToolTipHover placement="top" tagetId={"OCRComplete-"+index}>OCR is Completed</ToolTipHover> }
                  </span>:null
                }
                {
                  (parseInt(listItem.ocr_status) == 4  && listItem.type == 'file') ?
                  <span>
                  <img id={"OCRIsRejected-"+index} src={imgStoragePath+"ocr_rejected.png"} width="22"/>
                  { <ToolTipHover placement="top" tagetId={"OCRIsRejected-"+index}>OCR is Rejected</ToolTipHover> }
                  </span>:null
                }
                {
                  (listItem.ocr_status == 1 && listItem.type == 'file') ?
                  <span>
                    <img id={"OCRIsPending-"+index} src={imgStoragePath+"ocr.png"} width="22"/>
                  { <ToolTipHover placement="top" tagetId={"OCRIsPending-"+index}>OCR In Queue</ToolTipHover> }
                  </span>:null
                }
              </Col>:null
           }
        </Row>


        <Modal isOpen={this.state.restoreFlag} centered={true}  className={this.props.className}>
         {/* <ModalHeader toggle={this.resBinFlag}>{this.state.restoreFlag}</ModalHeader> */}
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to Restore file?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.restoreRejectedRecord("1",listItem); this.restoreRejectedFn()}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.restoreRejectedFn()}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
        <Modal isOpen={this.state.delFlag} centered={true}  className={this.props.className}>
         <ModalHeader toggle={this.deleteFile}>Delete File</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, you want to delete file?</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.deleteInqueue(); this.deleteFile()}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.deleteFile()}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>
      </div>
    )
  }
}
