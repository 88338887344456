import React, { Component } from 'react';
export default class FilterCardTitle extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="match-header">
        {this.props.children}
      </div>
    )
  }
}
