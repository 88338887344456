import React from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, prettyNumberParamsL } from '../../../../constants';
const ClaimList = (props) => {
  return(
    <Link className="asset-list" to={'/' + props.params.type + '/' + props.params.aircraft_slug + '/maintenance/' + props.claim.asset_type + '/view/' + props.claim.shop_visit}>
      <Row>
        <Col md="3">
          <h6 className="theta">
          {props.claim.name}
          </h6>
        </Col>
        <Col md="3">
          <h6 className="theta">
          {moment(props.claim.submission_date).format(displayDateFormat)}
          </h6>
        </Col>
        <Col md="3">
          <h6 className="theta">
            <NumericLabel params={prettyNumberParamsL}>
              {props.claim.claim_amount}
            </NumericLabel>
          </h6>
        </Col>
        <Col md="3">
        <h6 className="theta">
          {props.claim.status.label}
          </h6>
        </Col>
      </Row>
    </Link>
  )
}
export default withRouter(ClaimList);
