import React from 'react';
import { Row, Col } from 'reactstrap';
import { ExportFiles } from '../../../../../shared';
const BulkOperation = (props) => {
  return(
    <div className="add-bulk-operations">
      {<ul className="list-inline flex-centered">
        <li className="list-inline-item operation-cta">
        {props.export ?
          <ExportFiles exportFile={(fileType) => props.contactListExport(fileType)} files={[{img:'excel_icon.png', label: 'Excel', type:'xls'}]} />:null}
        </li>
       </ul>}
      <button onClick={() => props.addComponent()} className="aims-primary-btn">{props.addText ? props.addText : ' Add '}</button>
    </div>
  )
}
export default BulkOperation;
