import React, { Component } from 'react';
import { connect} from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { UPDATE_ENGINE_FIELD, ENGINE_RATIO_LIST } from '../actions';
import { regionInfoAc } from '../actionCreators';
class EngineDropDown extends Component {
  constructor(props){
    super(props)
  }
  updateEngine = (selectedOption) => {
    this.props.updateEngine(selectedOption ? selectedOption[this.props.valueKey] : '', this.props.type);
    if(this.props.type == 'engineType'){
      let utilsRatio = [];
      if(selectedOption){
        for(let ratio = selectedOption.utilization_min_ratio; ratio <= selectedOption.utilization_max_ratio; ratio = ratio + 0.1){
          utilsRatio.push({value: ratio.toFixed(1), label: ratio.toFixed(1) + ':1' });
        }
        if(selectedOption.aircraft_type.length){
          this.props.updateEngine(selectedOption.aircraft_type[0].no_of_engines, 'noOfEngines');
        }
      }else{
        this.props.updateEngine(1, 'noOfEngines');
      }
      if(this.props.regionId){
        this.props.fetchRegionStats(this.props.regionId, this.props.engineType);
      }
      this.props.updateEngineUtils(utilsRatio);
      this.props.updateEngine( selectedOption ? selectedOption.utilization_default_ratio.toFixed(1) : '', 'utlizationRatio');
    }else if(this.props.type == 'regionOfOperation'){
      this.props.updateEngine('', 'region_country');
      this.props.fetchRegionStats(selectedOption?selectedOption[this.props.valueKey]:'', this.props.engineType);
    }else if(this.props.type == 'region_country'){
      this.props.updateEngine(selectedOption?selectedOption[this.props.valueKey]:'', 'region_country');
    }
    if(this.props.error){
      this.props.updateErrorCode(this.props.type);
    }
  }
  render(){
    return(
      <Select
        name="form-field-name"
        value={this.props.selectedValue}
        onChange={this.updateEngine}
        options={this.props.values}
        placeholder = {this.props.placeholder}
        className="custom-select-block"
        labelKey = {this.props.labelKey}
        valueKey = {this.props.valueKey}
        matchProp = "any"
        disabled = {this.props.disabled}
      />
    )
  }
}
const mapStateToProps = state => ({
  engineType: state.MRFinanaceRptReducer.genFinanceInfo.engine.engineType
});
const mapDispatchToProps = dispatch => {
  return {
    updateEngine: (value, type) => dispatch({
      type: UPDATE_ENGINE_FIELD,
      payload: {value, type},
    }),
    updateEngineUtils: (utilsRatio) => dispatch({
      type: ENGINE_RATIO_LIST,
      payload: utilsRatio
    }),
    fetchRegionStats: (region_id, engine_type) => dispatch(regionInfoAc(region_id, engine_type))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EngineDropDown);
