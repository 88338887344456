import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { imgStoragePath } from '../../../constants';
class DashboardGoogleMapCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      aircraftType: '',
      assetSlug: '',
      lesseeLogo: ''
    }
  }
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      assetSlug: props.assetSlug,
      aircraftType: props.aircraftType,
      lesseeLogo: props.lesseeLogo,
      asset_type: props.asset_type,
    });
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  render() {
    const { title, markerList } = this.props;
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    return (
      <div className="map-wrapper-block"
        style={{
          position: "relative",
          height: "485px",
          width: "100%"
        }}
      >
        <Map style={{}} google={this.props.google} zoom={2.1} initialCenter={{ lat: 20.0529906, lng: 64.4385803}}>
          { markerList.map((marker, index) => {
            return(
            <Marker key={index}
                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)}}
                onClick={this.onMarkerClick}
                name = {marker.msn ? marker.msn : marker.esn ? marker.esn : marker.serial_number}
                aircraftType= {marker.aircraft_type ? marker.aircraft_type : ''}
                assetSlug = {marker.slug}
                lesseeLogo = {marker.logo}
                asset_type={marker.asset_type == 1 ? 'aircraft' : marker.asset_type == 2 ? 'engine' : marker.asset_type == 3 ? 'apu' : marker.asset_type == 4 ? 'lg' : 'propeller'}
                icon={{
                  url: marker.msn ?  imgStoragePath +"mapPointer.png": imgStoragePath +"mapPointerESN.png",
                }}
              />
            )
          })}
           <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}>
                <div className="map-tooltip-style flex-centered">
                  <div className="inner-first-block">
                    <h1>{this.state.asset_type == 'aircraft' ? 'MSN' : this.state.asset_type == 'engine' ? 'ESN' : this.state.asset_type == 'apu' ? 'APU' : this.state.asset_type == 'lg' ? 'LG' : 'PROPELLER'} {this.state.selectedPlace.name}</h1>
                    <h5>{this.state.aircraftType ? this.state.aircraftType :  null}</h5>
                    <a href={"/asset-view/" + this.state.asset_type + "/" + this.state.assetSlug}>View</a>
                  </div>
                  { this.state.lesseeLogo ?
                    <div className="inner-second-block">
                      <img src={this.state.lesseeLogo} alt="logo"/>
                    </div>
                   : null
                  }
                </div>
            </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBqQiBoZlzISDH0BsrpykXoKmRV7rjemPw"
})(DashboardGoogleMapCard);
