import React, { Component } from 'react';
import { FieldSelect, FieldInput, FieldDate } from '../../../../contracts/Elements';
import { imgStoragePath } from '../../../../../constants';
import {  TextInputField } from '../../../Elements';
 class AccessoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      error:{},
      
    }
  }
 
  render (){
    const {error} = this.state;
    const {content} = this.props;
    return(
    <tr>
      <td>
      <div >
        <TextInputField
              value={content.ata}
              type={'edit'}
              keyParam="ata"
              error={error.ata}
              updateForm={(value)=>this.props.updateForm({type: 'ata', value:value, sectionType: this.props.section, index:this.props.index})}
        />
        </div>
      </td>
      <td>
        <div >
        <TextInputField
              value={content.description}
              type={'edit'}
              keyParam="description"
              error={error.description}
              updateForm={(value)=>this.props.updateForm({type: 'description', value:value, sectionType: this.props.section, index:this.props.index})}
        />
        </div>
      </td>
      <td>
          <table>
            <tbody>
              <tr className="no-border">
                <td>
                <div >
                  <TextInputField
                      value={content.delivery_part_number}
                      type={'edit'}
                      keyParam="delivery_part_number"
                      updateForm={(value)=>this.props.updateForm({type: 'delivery_part_number', value:value, sectionType: this.props.section, index:this.props.index})}
                />
                </div>
                </td>
                <td>
                <div >
               <TextInputField
                      value={content.delivery_serial_number}
                      type={'edit'}
                      keyParam="delivery_serial_number"
                      updateForm={(value)=>this.props.updateForm({type: 'delivery_serial_number', value:value, sectionType: this.props.section, index:this.props.index})}
                />
                </div>
                </td>
              </tr>
            </tbody>
          </table>
      </td>
      <td>
          <table>
            <tbody>
              <tr className="no-border">
                <td>
                <div >
                  <TextInputField
                      value={content.installed_part_number}
                      type={'edit'}
                      keyParam="installed_part_number"
                      updateForm={(value)=>this.props.updateForm({type: 'installed_part_number', value:value, sectionType: this.props.section, index:this.props.index})}
                />
                </div>
                </td>
                <td>
                <div >
               <TextInputField
                      value={content.installed_serial_number}
                      type={'edit'}
                      keyParam="installed_serial_number"
                      updateForm={(value)=>this.props.updateForm({type: 'installed_serial_number', value:value, sectionType: this.props.section, index:this.props.index})}
                />
                </div>
                </td>
              </tr>
            </tbody>
          </table>
      </td>
      <td>
      <div >
       <TextInputField
          value={content.tsn}
          type={'edit'}
          keyParam="tsn"
          updateForm={(value)=>this.props.updateForm({type: 'tsn', value:value==''?null:value, sectionType: this.props.section, index:this.props.index})}
       />   
       </div>                   
      </td>
      <td>
      <div >
       <TextInputField
          value={content.csn}
          type={'edit'}
          keyParam="csn"
          updateForm={(value)=>this.props.updateForm({type: 'csn', value:value==''?null:value, sectionType: this.props.section, index:this.props.index})}
       />  
        </div>
      </td>
      <td>
      <div >
       <TextInputField
          value={content.remarks}
          type={'edit'}
          keyParam="remarks"
          updateForm={(value)=>this.props.updateForm({type: 'remarks', value:value, sectionType: this.props.section, index:this.props.index})}
       />   
        </div>      
      </td>
      <td>
      <div style={{textAlign:'center',cursor:'pointer'}}>
      <img width="15" onClick={()=>''} src={imgStoragePath+"delete_red.png"} onClick={this.props.toggleComDelModal} />
      </div>
      </td>
    </tr>
    )
}
}
export default AccessoriesList;
