import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat } from '../../../constants';
import { dateTransform } from '../../../utils';
const ContractExpiryCard = (props) => {

  return (
    <div className="expiry-card">
      <div className="asset-info">
        <h4>MSN {props.contract.asset.msn}</h4>
        <h5>{dateTransform(moment(),props.contract.contractual_end_date)}  remaining</h5>
      </div>
      <div className="contract-info">
        <div className="lessee-info">
          <h6>Lessee</h6>
          <p>{props.contract && props.contract.lessee && props.contract.lessee.name ? props.contract.lessee.name : ''}</p>
        </div>
        <Row>
          <Col md="6">
            <h6>Start Date</h6>
            <p>{moment(props.contract.contractual_start_date).format(displayDateFormat)}</p>
          </Col>
          <Col md="6">
            <h6>End Date</h6>
            <p>{moment(props.contract.contractual_end_date).format(displayDateFormat)}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default ContractExpiryCard;
