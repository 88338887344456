import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { TableUIComp, ListLoader, SecondarySideBar, SecondaryHeader } from '../../../../shared';
import { EasaList, BulkOperation } from '../components';
import { fetchEasaListAc, easaFaaExportAc } from '../actionCreators';
class EasaDatabaseList extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.props.fetchEasaList()
  }
  changeFilterFn=()=>{
    let location = browserHistory.getCurrentLocation();
    this.props.fetchEasaList(location.query);
  }
  changePerPage=(count) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'per_page': count,
        'page': 1
      }
    }
    browserHistory.push(location);

    this.props.fetchEasaList(location.query);
  }
  changePagination = (type, currentPage) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'page': currentPage
      }
    }
    browserHistory.push(location);
    this.props.fetchEasaList(location.query);
  }
  sortTable = (sortKey) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
        query: {
          ...location.query,
          sort: sortKey,
          page:1,
          sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }
    browserHistory.push(location);
    this.props.fetchEasaList(location.query);
  }

  render(){
    const menuList = {
      'AD Number': {
        'inputType': 'text',
        'keyParam': 'ad_name',
        'placeholder': 'Search by Ad Number',
        'label': 'Ad Number'
      },
      'EASA/FAA': {
        'inputType': 'dropdown',
        'keyParam': 'easa_faa',
        'placeholder': 'Search by EASA/FAA',
        'label': 'EASA/FAA',
        'options':[{label: 'EASA', value: 'EASA'}, {label: 'FAA', value:'FAA'}],
        'labelKey':'label',
        'valueKey':'value',
        'multi': false
      },
      'Select Assembly': {
        'inputType': 'dropdown',
        'keyParam': 'assembly',
        'placeholder': 'Search by Assembly',
        'label': 'Assembly',
        'options':[{label: 'Airframe', value: 'airframe'}, {label: 'Engine', value:'engine'}, {label: 'Appliance', value: 'appliance' }],
        'labelKey':'label',
        'valueKey':'value',
        'multi': false
      },
      'Publish Date' : {
        'inputType': 'date',
        'keyParam': 'publish_date',
        'placeholder': 'Search by Publish Date',
        'label': 'Publish Date'
      },
      'Updated Date' : {
        'inputType': 'date',
        'keyParam': 'issue_date',
        'placeholder': 'Search by Updated Date',
        'label': 'Updated Date'
      }
    }

    const { easaDataListInfo, adMLLoader, assetDetail } = this.props;
    return(
      <div className="">
        <SecondaryHeader assetInfo={assetDetail} />
        <SecondarySideBar />
        <div className="" style={{margin: '54px -15px 0 246px',  padding: '30px'}}>
          { adMLLoader ? <ListLoader />:null }
          <TableUIComp
            theads={[{label:'AD Number', sortKey: 'document_number'}, {label:'Assembly', sortKey: ''}, {label:'Published Date', sortKey: 'publish_date'},{label:'Updated Date', sortKey: 'issue_date'}, {label:'EASA/FAA', sortKey: ''}]}
            bulkOperation={false}
            tableBulkOp = {<BulkOperation easaFaaExport={(fileType) => this.props.easaFaaExport({...this.props.location.query,download:fileType}, 'easa_faa_list')} />}
            filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
            content={easaDataListInfo.list.map((item, index) => <EasaList item={item} key={index} />)}
            pagination = {easaDataListInfo.pagination}
            changePerPage ={this.changePerPage}
            changePagination = {this.changePagination}
            changeFilterFn={this.changeFilterFn}
            sortTable={this.sortTable}
            searchQuery =  {browserHistory.getCurrentLocation().query}
            recordsFound = { adMLLoader ? false : easaDataListInfo.list.length ? false:true }
            queryParamsEnabled={true}
          />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  easaDataListInfo: state.ADReducer.easaDataListInfo,
  adMLLoader: state.ADReducer.adMLLoader,
  assetDetail: state.ADReducer.assetDetail
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchEasaList: (filter={}) => dispatch(fetchEasaListAc(ownProps, filter)),
    easaFaaExport: (query={}, fileName) => dispatch(easaFaaExportAc(ownProps, query, fileName))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EasaDatabaseList);
