import React, { useState } from 'react'
import { Row, Col, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router'
import moment from 'moment';
import { FieldCol, FieldGroup, FieldLabel, FieldSelect, FieldTextarea, FieldDate } from '../../../contracts/Elements';
const AddEditSTCMod = ({stcModCrud, error, onFieldChange, updateErrorField, toggleEditSideBar, addEditSTCModFn}) => {
  return(
    <form onSubmit={(e) => {e.preventDefault(); addEditSTCModFn()}}>
      <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
        <Row>
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Description<span className="reqStar" style={{color: 'red'}}>*</span></FieldLabel>
              <textarea
                value={stcModCrud.description ? stcModCrud.description:''}
                onChange={(e) => onFieldChange('description', e.target.value)}
                maxLength={255}
                onFocus={() => updateErrorField('description', '')}
              />
              { error.description ?
                <h6 className="error-msg">{error.description}</h6>:null
              }
            </FieldGroup>
          </FieldCol>
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">STC Number</FieldLabel>
              <input
                type="text"
                value={stcModCrud.stc_number ? stcModCrud.stc_number:''}
                onChange={(e) => onFieldChange('stc_number', e.target.value)}
                maxLength={20}
                onFocus={() => updateErrorField('stc_number', '')}
              />
              { error.stc_number ?
                <h6 className="error-msg">{error.stc_number}</h6>:null
              }
            </FieldGroup>
          </FieldCol>
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Regulatory Approval
                <i class="fa fa-info-circle" aria-hidden="true" id="regulatory_approval" style={{paddingLeft:'4px'}}></i>
                <UncontrolledTooltip placement="right" target="regulatory_approval">
                  EASA, FAA, Local
                </UncontrolledTooltip>
              </FieldLabel>
              <input
                type="text"
                value={stcModCrud.regulatory_approval ? stcModCrud.regulatory_approval:''}
                onChange={(e) => onFieldChange('regulatory_approval', e.target.value)}
                maxLength={20}
                onFocus={() => updateErrorField('stc_number', '')}
              />
            </FieldGroup>
          </FieldCol>
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Compliance Status</FieldLabel>
              <FieldSelect
                placeholder='Select Compliance Status'
                keyParam="stc_compliance_status"
                value={ stcModCrud.stc_compliance_status ? stcModCrud.stc_compliance_status: ''}
                type={'add'}
                options={[{value:1,label: 'Complied'}, {value:2,label: 'Not Complied'}, {value:3,label: 'Not Applicable'}]}
                updateField={onFieldChange}
                labelKey= 'label'
                valueKey='value'
              />
            </FieldGroup>
          </FieldCol>
          <FieldCol md="12" style={{marginBottom:"40px"}} >
            <FieldGroup className="form-group">
              <FieldLabel className="label">Compliance Date </FieldLabel>
                <FieldDate
                    value={stcModCrud.compliance_date ? moment(stcModCrud.compliance_date).format('MMM DD, YYYY') : null }
                    type=''
                    keyParam="compliance_date"
                    updateField={onFieldChange}
                 />
            </FieldGroup>
          </FieldCol>
        </Row>
      </div>
      <div className="submit-block">
        <input type="submit" className="primary-btn" value="Save Changes"/>
        <Link onClick={() => toggleEditSideBar()} >Cancel</Link>
      </div>
    </form>
  )
}
export default AddEditSTCMod;
