import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Highcharts from 'highcharts'
import Select from 'react-select';
import HighchartsReact from 'highcharts-react-official';
import { getLocalStorageInfo } from '../../../utils';
require('highcharts/modules/exporting')(Highcharts);
const AircraftDistribution = (props) => {
	// var pieColors = (function () {
  //   var colors = [],
  //       base = '#003271',
  //       i;
	//
  //   for (i = 0; i < 10; i += 1) {
  //       // Start out with a darkened base color (negative brighten), and end
  //       // up with a much brighter color
  //       colors.push(Highcharts.Color(base).brighten((i - 3) / 7).get());
  //   }
  //   return colors;
	// }());

	const assetTypes = {'aircraft_types': 'Aircraft','engine_types': 'Engine','apu_types': 'APU','propeller_types': 'Propeller'}
	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			height: 300,
		},
		title: {
			text: ''
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.number_of_aircrafts}{point.number_of_engines}{point.number_of_apus}{point.number_of_propellers}</b>'
		},
		legend: {
			itemMarginBottom: 8,
		},
		exporting: { enabled: false },
		credits: { enabled: false },
		plotOptions: {
			series: {
				animation: false,
			  },
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false
				},
				showInLegend: true
			}
		},
		series: [{
			name: assetTypes[props.asset_type],
			colorByPoint: true,
			data: props.aircraftDisWidget[props.asset_type]
		}]
	};
  return(
		<Col lg="12" xl={getLocalStorageInfo().defaultLessor.id === 348 ? "6" : "4"} className="paddingLeft paddingRight flex-not-centered">
			<div className="dashboard-shadow-block">
				<h3 className="gama dashboard_heading">
					<div style={{width: '60%', display: 'inline-block', verticalAlign: 'super'}}>Asset Distribution by Type</div>
					<div style={{width: '40%', display: 'inline-block'}}>
					<Select
						name="form-field-name"
						placeholder="Select Asset Type"
						value={props.asset_type}
						labelKey='label'
						valueKey='value'
						options = {[{value: 'aircraft_types', label: 'Aircraft'}, {value: 'engine_types', label: 'Engine'}, {value: 'apu_types', label: 'APU'}]}
						onChange = {(value) => props.changeAssetType(value.value)}
						/>
					</div>
				</h3>
				<div className="hightchart-block">
					{
						props.dbAircraftDisLoader ? <span style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</span>:
						<HighchartsReact
							highcharts={Highcharts}
							options={options}
						/>
					}
				</div>
			</div>
		</Col>
  )
}
export default AircraftDistribution;
