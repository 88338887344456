import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import moment from 'moment'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { fieldDateFormat, backendDateFormat, float2DecRegx } from '../../../../constants'
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
export default class AddEditInsurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insurance: {},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.insuranceObj));
    if(this.props.type != 'add'){
      data = {
        ...data,
        insurance_type: this.props.insuranceObj && this.props.insuranceObj.insurance_type.value ? this.props.insuranceObj.insurance_type.value : '',
        insurance_type_name: this.props.insuranceObj && this.props.insuranceObj.insurance_type.label ? this.props.insuranceObj.insurance_type.label : ''
      }
    }
    this.setState({
      insurance: data
    });
  }
  updateForm = (type, value) => {
    if(type === 'insurance_amount'){
      if(float2DecRegx.test(value) && (parseFloat(value) <= 1000000000000000000 || value === '')){
        this.setState(prevState => ({
          ...prevState,
          insurance: {
            ...prevState.insurance,
            [type]: (value == 'Invalid date' ? null:value)
          },
          error: {
            ...prevState.error,
            [type]:''
          }
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        insurance: {
          ...prevState.insurance,
          [type]: (value == 'Invalid date' ? null:value)
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    }
  }
  addEditInsurance = (e) => {
    e.preventDefault();
    const insurance = this.state.insurance;
    var validateNewInput = {
      insurance_type: checkString({value: insurance.insurance_type, required: true, minLength: '', maxLength: '', message: 'Please select Insurance Type.'}),
      insurance_number: checkString({value: insurance.insurance_number, required: true, minLength: '', maxLength: '', message: 'Please enter Insurance Number'}),
      insurer: checkString({value: insurance.insurer, required: true, minLength: '', maxLength: '', message: 'Please enter Insurer'}),
      insuree: checkString({value: insurance.insuree, required: true, minLength: '', maxLength: '', message: 'Please enter Insuree'}),
      policy_start_date: checkValidDate({value: insurance.policy_start_date, required: true, minLength: '', maxLength: '', message: 'Please select Policy Start Date'}),
      policy_end_date: checkValidDate({value: insurance.policy_end_date, required: true, minLength: '', maxLength: '', message: 'Please select Policy End Date'}),
      insurance_amount: checkNumber({value: insurance.insurance_amount, required: true, minLength: '', maxLength: '', message: 'Please enter Insurance Amount'}),
    };

    if(insurance.policy_end_date && insurance.policy_end_date.trim() != '' && parseInt(moment(insurance.policy_end_date).diff(moment(insurance.policy_start_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        policy_end_date: 'Policy end date can not be before Policy start date'
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditInsurance(this.state.insurance, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  render() {
    const { type, techConstants } = this.props;
    const { insurance, error } = this.state;
    return (
      <form onSubmit={(e) => this.addEditInsurance(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Insurance Type
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                <FieldSelect
                  placeholder='Select Insurance Type'
                  keyParam="insurance_type"
                  value={insurance.insurance_type}
                  type={type}
                  options={techConstants.filter(item => item.type == 'insurance_type')}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name= {insurance.insurance_type_name}
                />
              <h6 className="error-msg">{error.insurance_type}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Insurance Number
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={insurance.insurance_number}
                  type={type}
                  keyParam="insurance_number"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.insurance_number}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Insurer
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={insurance.insurer}
                  type={type}
                  keyParam="insurer"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.insurer}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Insuree
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={insurance.insuree}
                  type={type}
                  keyParam="insuree"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.insuree}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Policy Start Date
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldDate
                  value={insurance.policy_start_date}
                  type={type}
                  keyParam="policy_start_date"
                  minDate={[2,6,7,8,9].includes(this.props.contract.asset_type) ?moment(this.props.contract.manufacturing_date) :moment(this.props.contract.date_of_manufacture)}
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.policy_start_date}</h6>
              </FieldGroup>
            </FieldCol>
            {
              insurance.policy_start_date != '' && insurance.policy_start_date != null && insurance.policy_start_date != undefined ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Policy End Date
                    {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldDate
                    value={insurance.policy_end_date}
                    type={type}
                    keyParam="policy_end_date"
                    updateField={this.updateForm}
                    minDate={insurance.policy_start_date != '' && insurance.policy_start_date != null && insurance.policy_start_date != undefined ? moment(insurance.policy_start_date) : moment()}
                  />
                <h6 className="error-msg">{error.policy_end_date}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Geo Limit</FieldLabel>
                <FieldInput
                  value={insurance.geo_limit}
                  type={type}
                  keyParam="geo_limit"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Insurance Amount ({insurance.currency ? insurance.currency: 'USD'})
                  {type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={insurance.insurance_amount}
                  type={type}
                  keyParam="insurance_amount"
                  updateField={this.updateForm}
                  currency = {true}
                  currencyType={insurance.currency}
                />
              <h6 className="error-msg">{error.insurance_amount}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Effective Date </FieldLabel>
                <FieldDate
                  value={insurance.effective_date}
                  type={type}
                  minDate={moment(this.props.contract.contract.contractual_start_date)}
                  maxDate={moment(insurance.policy_end_date)}
                  keyParam="effective_date"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Primary Contact</FieldLabel>
                <FieldInput
                  value={insurance.primary_contact}
                  type={type}
                  keyParam="primary_contact"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={insurance.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes" />
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
