import * as actions from '../actions';
export default function( state={
  engineEventList: [],
  engineEventHistory: [],
  engineClaims: [],
  engineLLPList: [],
  engineShopVisit: {
    type: '',
    data: {},
    flag: false
  },
  engineClaimInfo: {
    type: '',
    data: {},
    flag: false
  },
  enginePayoutInfo: {
    type: '',
    data: {},
    flag: false
  },
  engineLLPinfo: {
    data: {},
    flag: false,
    id: []
  },
  engineLLpCrud: {
    flag: false,
    index: 0,
    data: {},
    type: ''
  },
  workScopes: [],
  engineLoader: false
},action){
  switch (action.type) {
    case actions.ENGINE_EVENT_LIST:
      return {
        ...state,
        engineEventList: action.payload
      }
    case actions.ENGINE_EVENT_HISTORY:
      return {
        ...state,
        engineEventHistory: action.payload
      }
    case actions.ENGINE_SHOP_VIST_ADD_SUCCESS:
      return {
        ...state,
        engineEventHistory:[action.payload, ...state.engineEventHistory]
      }
    case actions.ENGINE_SHOP_VISIT_CRUD:
      return {
        ...state,
        engineShopVisit: action.payload
      }
    case actions.GET_ENGINE_WORKSCOPES:
        return {
          ...state,
          workScopes: action.payload
        }
    case actions.ENGINE_CLAIM_LIST:
      return {
        ...state,
        engineClaims: action.payload
      }
    case actions.ENGINE_CLAIM_ADD_SUCCESS:
      return {
        ...state,
        engineClaims: [action.payload, ...state.engineClaims]
      }
    case actions.ENGINE_CLAIM_EDIT_SUCCESS:
      return {
        ...state,
        engineClaims: state.engineClaims.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.ENGINE_CLAIM_CRUD:
      return {
        ...state,
        engineClaimInfo: action.payload
      }
    case actions.ENGINE_PAYOUT_CRUD:
      return {
        ...state,
        enginePayoutInfo: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT_ENGINE:
      return {
        ...state,
        engineShopVisit:{
          ...state.engineShopVisit,
          type: 'edit',
          flag: true
        }
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        [action.actionType]:{
          ...state[action.actionType],
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.ENGINE_LOADER:
      return {
        ...state,
        engineLoader: action.payload
      }
    case actions.ENGINE_LLP_LIST:
      return {
        ...state,
        engineLLPList: action.payload
      }
    case actions.LLP_INFO:
      return {
        ...state,
        engineLLPinfo: action.payload
      }
    case actions.ADD_WORKSCOPE_ITEM:
      const item = {
        moduleNo: '',
        moduleDescription: '',
        initialLevel: '',
        finalLevel:''
      }
      return {
        ...state,
        workScopes: state.workScopes.map((majorMod,index) =>
        index == action.payload.index ?
        { ...majorMod, subModule: majorMod.subModule.map((sb, childIndex) =>
          childIndex == action.payload.childIndex ? {...sb, items: [...sb.items, item]} :sb
        )}:
        majorMod
      )}
      case actions.ADD_WORKSCOPE_SUBMODULE:
      const subModule = {
            name:'',
            items:[
              {
                moduleNo: '',
                moduleDescription: '',
                initialLevel: '',
                finalLevel:''
              }
            ]
          }
      return {
        ...state,
        workScopes: state.workScopes.map((majorMod,index) =>
        index == action.payload.index ?
        { ...majorMod, subModule: [...majorMod.subModule, subModule] } :
        majorMod
      )}
        return state
      case actions.ADD_WORKSCOPE_MODULE:
        const majorModule = {
          name: '',
          subModule: [
            {
              name:'',
              items:[
                {
                  moduleNo: '',
                  moduleDescription: '',
                  initialLevel: '',
                  finalLevel:''
                }
              ]
            }
          ]
        }
        return {
          ...state,
          workScopes: [ ...state.workScopes, majorModule ]
        }

      case actions.DEL_WORKSCOPE_ITEM:
        const workScopes = Object.assign([], state.workScopes);
        if(workScopes[action.payload.index].subModule.length==1 && workScopes[action.payload.index].subModule[action.payload.childIndex].items.length==1){
          workScopes.splice(action.payload.index,1);
          return {
            ...state,
            workScopes
          }
        }
        if(workScopes[action.payload.index].subModule[action.payload.childIndex].items.length==1){
          workScopes[action.payload.index].subModule.splice(action.payload.childIndex,1);
          return {
            ...state,
            workScopes
          }
        }
        return {
          ...state,
          workScopes: state.workScopes.map((majorMod,index) =>
          index == action.payload.index ?
          { ...majorMod, subModule: majorMod.subModule.map((sb, childIndex) => {
            return childIndex == action.payload.childIndex ? {...sb, items: sb.items.filter((item, index) => index != action.payload.itemIndex )} :sb;
          })}:
          majorMod
        )}
    case actions.ENGINE_LLP_ADD_SUCCESS:
    return {
      ...state,
      engineLLPList: [...action.payload,  ...state.engineLLPList],
    }
    case actions.ENGINE_LLP_ASSY_CRUD:
      return {
        ...state,
        engineLLpCrud: action.payload
      }
    case actions.ENGINE_LLP_EDIT_SUCCESS:
      return {
        ...state,
        engineLLPList: state.engineLLPList.map(list => list.id == action.payload.id ? action.payload:list)
      }
      case actions.CLOSE_ENGINE_LLP_SIDEBAR:

      return {
        ...state,
        [action.viewType]: action.payload,
      }
  case actions.DELETE_ENGINE_LLP:
      return {
        ...state,
        engineLLPList: state.engineLLPList.filter(list => action.payload.indexOf(list.id) == -1),
      }
  case actions.UPDATE_WORK_SCOPE:
    switch (action.payload.type) {
      case "majorModule":
          return {
            ...state,
            workScopes:state.workScopes.map((majorMod,index) => index == action.payload.index ? {...majorMod,[action.payload.key]:action.payload.value}:majorMod)
          }
      case "childModule":
        return {
          ...state,
          workScopes:state.workScopes.map((majorMod,index) => index == action.payload.index ?
          {...majorMod,subModule:majorMod.subModule.map((childModule,childIndex) => childIndex==action.payload.childIndex?{...childModule,[action.payload.key]:action.payload.value}:childModule)}:majorMod)
        }
      case "itemModule":
        return {
          ...state,
          workScopes: state.workScopes.map((majorMod,index) => index==action.payload.index?{...majorMod,subModule:majorMod.subModule.map((childModule,childIndex) => childIndex==action.payload.childIndex?
            {...childModule,items:childModule.items.map((itemData,itemIndex) => itemIndex==action.payload.itemIndex?
              {...itemData,[action.payload.key]:action.payload.value}:itemData)}:childModule)}:majorMod)
        }
      default:
        return state
    }
  default:
      return state;
  }
}
