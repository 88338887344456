import React, { Fragment } from 'react';
import {  Row, Col } from 'reactstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
export default function UtilizationRpt({exportObj, onChangeExportFeature, onChangeExtension, onChangeDate}){
  return(
    <Fragment>
      <div className="export-items">
        <Row className="flex-centered">
          <Col cs="5">
            <div className="">
              <label className="flex-centered export-label">
                <input type="checkbox"
                  onChange={(e) => onChangeExportFeature(e.target.checked, 'utilization_trends')}
                  checked={exportObj.contracts && exportObj.contracts.utilization_trends && exportObj.contracts.utilization_trends.export ? true:false}
                />
                <span>Utilization Trends</span>
              </label>
            </div>
          </Col>
          <Col cs="4">
            <div className="export-avial">
              <h6>Export Format</h6>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <label>
                    <input
                      name="utilization_trends"
                      checked={(exportObj.contracts && exportObj.contracts.utilization_trends && exportObj.contracts.utilization_trends.extension == 'pdf')}
                      onChange={() => onChangeExtension('pdf', 'utilization_trends')}
                      type="radio"
                    />
                    <span>PDF</span>
                  </label>
                </li>
                <li className="list-inline-item">
                  <label>
                    <input
                      name="utilization_trends"
                      type="radio"
                      checked={(exportObj.contracts && exportObj.contracts.utilization_trends && exportObj.contracts.utilization_trends.extension == 'xls')}
                      onChange={() => onChangeExtension('xls', 'utilization_trends')}
                    />
                    <span>XL</span>
                  </label>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      <div className="export-items">
        <Row className="flex-centered">
          <Col cs="5">
            <div className="">
              <label className="flex-centered export-label">
                <input type="checkbox"
                  onChange={(e) => onChangeExportFeature(e.target.checked, 'utilization_history')}
                  checked={exportObj.contracts && exportObj.contracts.utilization_history && exportObj.contracts.utilization_history.export ? true:false}
                />
                <span>Utilization History</span>
              </label>
            </div>
          </Col>
          <Col cs="4">
            <div className="export-avial">
              <h6>Export Format</h6>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <label>
                    <input
                      name="utilization_history"
                      checked={(exportObj.contracts && exportObj.contracts.utilization_history && exportObj.contracts.utilization_history.extension == 'pdf')}
                      onChange={() => onChangeExtension('pdf', 'utilization_history')}
                      type="radio"
                    />
                    <span>PDF</span>
                  </label>
                </li>
                <li className="list-inline-item">
                  <label>
                    <input
                      name="utilization_history"
                      type="radio"
                      checked={(exportObj.contracts && exportObj.contracts.utilization_history && exportObj.contracts.utilization_history.extension == 'xls')}
                      onChange={() => onChangeExtension('xls', 'utilization_history')}
                    />
                    <span>XL</span>
                  </label>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="filter-avail">
          <ul className="list-inline flex-centered">
            <li className="list-inline-item">
              <label>Start Date</label>
              <DatePicker
                dateFormat = {fieldDateFormat}
                maxDate={moment()}
                selected={exportObj.contracts.utilization_history.filter.startDate ? moment(exportObj.contracts.utilization_history.filter.startDate):null}
                onChange={(e) => onChangeDate(moment(e).format(backendDateFormat), 'startDate', 'utilization_history')}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
             />
            </li>
            <li className="list-inline-item">
              <label>End Date</label>
              <DatePicker
                dateFormat = {fieldDateFormat}
                maxDate={moment()}
                selected={exportObj.contracts.utilization_history.filter.endDate ? moment(exportObj.contracts.utilization_history.filter.endDate):null}
                onChange={(e) => onChangeDate(moment(e).format(backendDateFormat), 'endDate', 'utilization_history')}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
              />
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}
