import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkFloat } from '../../../../formValidator';
export default class EditOthers extends Component {
  constructor(props){
    super(props);
    this.state = {
      finance:{},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.financeObj));
    this.setState({
      finance: data,
    });
  }
  updateForm = (type, value) => {
    if (isNaN(value) || parseFloat(value) * 100 % 1 > 0) {
      return false;
    }
    this.setState(prevState => ({
      ...prevState,
      finance: {
        ...prevState.finance,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditFinance = (e) => {
    e.preventDefault();
    const { finance } = this.state;
    let lease_service_fee = checkFloat({value: finance.lease_service_fee, required: true, minLength: '', maxLength: '', message: 'Please enter Lease Servicer Fee p.a'});
    let administrator_fee= checkFloat({value: finance.administrator_fee, required: true, minLength: '', maxLength: '', message: 'Please enter Admininstrator Fee p.a'});
    let re_marketing_cost= checkFloat({value:finance.re_marketing_cost,required:true, message: 'Please enter Remarketing Cost'});
    let asset_management_cost = checkFloat({value:finance.asset_management_cost, required:true, message: 'Please enter Asset Management Cost p.a'});
    var validateNewInput = {
      lease_service_fee: lease_service_fee != '' ? lease_service_fee: parseFloat(finance.lease_service_fee) <= 1000000000000 ? '':'Should be less or equal to 1 trillion',
      administrator_fee: administrator_fee != '' ? administrator_fee: parseFloat(finance.administrator_fee) <= 1000000000000 ? '':'Should be less or equal to 1 trillion',
      re_marketing_cost: re_marketing_cost != '' ? re_marketing_cost : parseFloat(finance.re_marketing_cost) <= 1000000000000 ? '':'Should be less or equal to 1 trillion',
      asset_management_cost: asset_management_cost != '' ? asset_management_cost: parseFloat(finance.asset_management_cost) <= 1000000000000 ? '':'Should be less or equal to 1 trillion',
    };
    if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.editFinanceFn(this.state.finance);
    }else{
      this.setState({
        error: validateNewInput
      })
    }
  }
  render(){
    const { finance, error } = this.state;
    const { type } = this.props;
    return(
      <form onSubmit={(e) => this.addEditFinance(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Lease Servicer Fee p.a
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.lease_service_fee}
                  type={type}
                  keyParam="lease_service_fee"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.lease_service_fee}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Administrator Fee p.a
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.administrator_fee}
                  type={type}
                  keyParam="administrator_fee"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.administrator_fee}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Remarketing Cost
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.re_marketing_cost}
                  type={type}
                  keyParam="re_marketing_cost"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.re_marketing_cost}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Asset Management Cost p.a
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.asset_management_cost}
                  type={type}
                  keyParam="asset_management_cost"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.asset_management_cost}</h6>
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { type != 'view' ?
          <div className="submit-block">
             <input type="submit" className="primary-btn" value="Save Changes"/>
             <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
    )
  }
}
