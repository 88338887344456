import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldSelect, FieldTextarea, FieldDate} from '../../Elements';
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
import { Link } from 'react-router';
import Select , { Creatable } from 'react-select';
import { imgStoragePath } from '../../../../constants';
import { uploadLesseeLogoAc } from '../../../../shared/actionCreators';
import { toastFlashMessage } from '../../../../utils';
export default class EditLesseeType extends Component {
  constructor(props){
    super(props);
    this.state = {
      contract: {},
      error:{},
      newLessee:false,

    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }
  componentDidMount(){
    this.updateHdState(this.props.contract);
  }
  componentWillReceiveProps(nextProps){
    this.updateHdState(nextProps.contract)
  }
  updateHdState = (contract) => {
    let data = Object.assign({}, contract);
    data = {
      ...data,
      lessor_id: data.lessor.id,
      asset_id: data.asset.id,
      lessee_id: data.lessee.id,
      operator_id: data.operator.id,
      lessor_portfolio: data.lessor_portfolio && data.lessor_portfolio.id ? data.lessor_portfolio.id: null,
      lease_type : data.lease_type && data.lease_type.value ? data.lease_type.value: null,
      lease_type_accounting : data.lease_type_accounting && data.lease_type_accounting.value ? data.lease_type_accounting.value: null,
      rental_escalation_interval: data.rental_escalation_interval && data.rental_escalation_interval.value?data.rental_escalation_interval.value:null,
      rental_interval: data.rental_interval && data.rental_interval.value?data.rental_interval.value:null,
      rental_type: data.rental_type && data.rental_type.value?data.rental_type.value:null,
      lease_status: data.lease_status && data.lease_status.value?data.lease_status.value:null
    }
    this.setState({
      contract: data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      contract: {
        ...prevState.contract,
        [type]: value
      },
      error:{
        ...prevState.error,
        [type]:''
      }
    }));
  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          contract:{
            ...prevState.contract,
            lessee_id:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          contract:{
            ...prevState.contract,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        contract:{
          ...prevState.contract,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      contract:{
        ...prevState.contract,
        lessee_id:{
          ...prevState.contract.lessee_id,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          contract:{
            ...prevState.contract,
            lessee_id:{
              ...prevState.contract.lessee_id,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }
  updateHdlineInfo =  (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.contract);
    delete data['operator'];
    delete data['lessor'];
    delete data['asset'];
    delete data['lessee'];
    let validateNewInput = {
      operator_id: checkNumber({value: data.operator_id, required: !data['same_operator_lessee'], message: 'Please select Operator'}),
      lessee_id: checkString({value: data.lessee_id, required: true, minLength: '', maxLength: '', message: 'Please select Lessee'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.toggleEditSideBar();
      this.props.updateHdlineInfo(data);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  render() {
    const { lessees, operators, type } = this.props;
    const { contract, error, newLessee } = this.state;
    if(Object.keys(contract).length){
      return(
        <form onSubmit={(e) => this.updateHdlineInfo(e)}>
          <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
            <Row>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lessee<span className="reqStar">*</span></FieldLabel>
                    <Creatable
                      value={contract.lessee_id}
                      options={lessees}
                      isClearable={true}
                      onChange={this.updateLesseeForm}
                      className="custom-select-block"
                      placeholder='Add or Select Lessee'
                      valueKey="id"
                      labelKey="name"
                      type={type}
                    />
                 <h6 className="error-msg">{error.lessee_id}</h6>
                </FieldGroup>
              </FieldCol>
              {
                newLessee ?
                <Fragment>
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        Contact Address
                      </FieldLabel>
                      <FieldInput
                        value={contract.lessee_id ? contract.lessee_id.contact_address:'' }
                        type={type}
                        keyParam="contact_address"
                        updateField={this.updateNewLessee}
                      />
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        Country
                      </FieldLabel>
                      <FieldInput
                        value={contract.lessee_id ? contract.lessee_id.country:'' }
                        type={type}
                        keyParam="country"
                        updateField={this.updateNewLessee}
                      />
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                        Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                        <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                      </FieldLabel>
                    </FieldGroup>
                  </FieldCol>
                </Fragment> : null
              }
              <Col md="12">
                <div className="form-group">
                  <label className="label">
                    <input
                      type="checkbox"
                      checked={contract.same_operator_lessee}
                      onChange={(e) => this.updateForm('same_operator_lessee', e.target.checked)}
                      />
                    <span> Operator is same as Lessee</span>
                  </label>
                </div>
              </Col>
              { !contract.same_operator_lessee ?
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Operator <sup className="reqStar"> * </sup></FieldLabel>
                    <FieldSelect
                      placeholder='Select Operator'
                      keyParam="operator_id"
                      value={contract.operator_id}
                      type={type}
                      options={operators}
                      updateField={this.updateForm}
                      labelKey="name"
                      valueKey="id"
                    />
                    <h6 className="error-msg">{error.operator_id}</h6>
                  </FieldGroup>
                </FieldCol>:null
              }
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Jurisdiction</FieldLabel>
                  <FieldInput
                    value={contract.lessee_jurisdiction}
                    type={type}
                    keyParam="lessee_jurisdiction"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Habitual Base</FieldLabel>
                  <FieldInput
                    value={contract.habitual_base}
                    type={type}
                    keyParam="habitual_base"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contact Address</FieldLabel>
                  <FieldTextarea
                    value={contract.lessee_contact_address}
                    type={type}
                    keyParam="lessee_contact_address"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contact (Email and Phone)</FieldLabel>
                  <FieldInput
                    value={contract.lessee_contact_details}
                    type={type}
                    keyParam="lessee_contact_details"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contractual Notice Address</FieldLabel>
                  <FieldTextarea
                    value={contract.lessee_contractual_notice_address}
                    type={type}
                    keyParam="lessee_contractual_notice_address"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
            </Row>
          </div>
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>
        </form>
      )
    }else{
      return <h6>Loading...</h6>
    }

 }
}
