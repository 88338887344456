import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldTextarea, FieldDate } from '../../Elements';
import { checkString, checkNumber } from '../../../../formValidator';
import moment from 'moment'
import { float2DecRegx } from '../../../../constants'
export default class AddNonCashTx extends Component{
  constructor(props){
    super(props);
    this.state = {
      nonCashTx: {},
      error:{},
      existingAmount: 0
    }
  }
  componentDidMount(){
    if(this.props.type !== 'add'){
      this.setState({
        nonCashTx: this.props.nonCashTxObj,
        existingAmount: this.props.nonCashTxObj.amount
      });
    }else {
      this.setState({
        nonCashTx: this.props.nonCashTxObj
      });
    }
  }
  updateForm = (type, value) => {
    if(type === 'amount'){
      if(float2DecRegx.test(value)){
        this.setState(prevState => ({
          ...prevState,
          nonCashTx: {
            ...prevState.nonCashTx,
            [type]: value
          },
          error: {
            ...prevState.error,
            [type]:''
          }
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        nonCashTx: {
          ...prevState.nonCashTx,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]:''
        }
      }))
    }
  }
  addEditCashTx = (e) => {
    e.preventDefault();
    let data = this.state.nonCashTx;
    if(this.props.type == 'add'){
      data = {
        ...data,
        security_deposit_id: this.props.securityDepositId
      }
    }
    var validateNewInput = {};
    if(this.props.type === 'add'){
      if(data.amount > data.remaining_amount){
        validateNewInput = {
          ...validateNewInput,
          amount: "Please enter transaction amount less than equal to " + data.remaining_amount,
        }
      }else{
        validateNewInput = {
          ...validateNewInput,
          amount: checkNumber({value: data.amount, required: true, minLength: '', maxLength: '', message: 'Please Enter The Valid Amount'}),
        }
      }
    }else{
      if(data.amount > (data.remaining_amount + this.state.existingAmount)){
        validateNewInput = {
          ...validateNewInput,
          amount: "Please enter transaction amount less than equal to " + (data.remaining_amount + this.state.existingAmount),
        }
      }else{
        validateNewInput = {
          ...validateNewInput,
          amount: checkNumber({value: data.amount, required: true, minLength: '', maxLength: '', message: 'Please Enter The Valid Amount'}),
        }
      }
    }
    if(data.retention_period_end_date && data.retention_period_end_date.trim() != '' && parseInt(moment(data.retention_period_end_date).diff(moment(data.retention_period_start_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        retention_period_end_date: 'Validity start date can not be after Validity end date'
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditCashTx(data, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }
  render(){
    const { type, assetViewId } = this.props;
    const { nonCashTx, error } = this.state;
    return(
      <form onSubmit={(e) => this.addEditCashTx(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Type of Financial Instrument</FieldLabel>
                <FieldInput
                  value={nonCashTx.sub_type_of_transaction}
                  type={type}
                  keyParam="sub_type_of_transaction"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">On Behalf of</FieldLabel>
                <FieldInput
                  value={nonCashTx.on_behalf_of}
                  type={type}
                  keyParam="on_behalf_of"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Issuing Party</FieldLabel>
                <FieldInput
                  value={nonCashTx.issuing_party}
                  type={type}
                  keyParam="issuing_party"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Confirming Party</FieldLabel>
                <FieldInput
                  value={nonCashTx.confirming_party}
                  type={type}
                  keyParam="confirming_party"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Amount ({nonCashTx.currency ? nonCashTx.currency: 'USD'}) {type!='view'?<sup className="reqStar"> * </sup>:null}</FieldLabel>
                <FieldInput
                  value={nonCashTx.amount}
                  type={type}
                  keyParam="amount"
                  updateField={this.updateForm}
                  currency = {true}
                  currencyType={nonCashTx.currency}
                />
              <h6 className="error-msg">{error.amount}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Validity Start Date
                </FieldLabel>
                <FieldDate
                  value={nonCashTx.retention_period_start_date}
                  minDate={moment(assetViewId.contract.contractual_start_date)}
                  maxDate={moment(assetViewId.contract.contractual_end_date)}
                  type={type}
                  keyParam="retention_period_start_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.retention_period_end_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Validity End Date </FieldLabel>
                <FieldDate
                  value={nonCashTx.retention_period_end_date}
                  maxDate={moment(assetViewId.contract.contractual_end_date)}
                  type={type}
                  keyParam="retention_period_end_date"
                  updateField={this.updateForm}
                  minDate={nonCashTx.retention_period_start_date ? moment(nonCashTx.retention_period_start_date) : moment(assetViewId.contract.contractual_start_date)}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Purpose of Instrument</FieldLabel>
                <FieldTextarea
                  value={nonCashTx.purpose}
                  type={type}
                  keyParam="purpose"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
