import React, {Component} from 'react';
import { connect } from 'react-redux';
import { CashFlowWrapper, CashFlowList }  from '../components';
import { SidebarMntcCalc, ListLoader } from '../../../../shared/';
import { cashFlowListAc, monthlyBreakDownExportAc } from '../actionCreators';
import '../assets/styles/CashFlow.scss';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { EMPTY_CASH_FLOW_MONLTY_BREAKUP_LIST } from '../actions';
class CashFlow extends Component{
  constructor(props){
    super(props);
    this.state={
      filter: null
    }
  }

  componentWillMount(){
    window.location.assign(`https://qa.mr-calculator.sparta.aero/mr-calculator/financial-plots/${this.props.params.id}`)
  }

  componentDidMount(){
    this.props.fetchCashFlow(this.props.params.id);
  }
  componentWillUnmount(){
    this.props.clearCashFlow();
  }
  updateFilter = (value, type) => {
    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [type]: value
      }
    }))
  }
  render(){
    const  { cashflowMonthlyBreakup } = this.props;
    return(
      <div className="cash-flow-wrapper" style={{margin:'0 0 0 255px'}}>
        <SidebarMntcCalc path={this.props.route.path} />
        {
          cashflowMonthlyBreakup.length ?
          <CashFlowWrapper id={this.props.params.id} downloadGraphMonthly={() => this.props.downloadGraphMonthly(this.props.params.id, this.state.filter)}>
            <CashFlowList
              cashFlow={cashflowMonthlyBreakup}
              paddingSpace = {22}
              updateFilter={this.updateFilter}
            />
          </CashFlowWrapper>: <ListLoader />
        }
      </div>

    )
  }
}
const mapStateToProps = state => ({
  cashflowMonthlyBreakup: state.MRFinanceGraphReducer.cashflowMonthlyBreakup
});
const mapDispatchToProps = dispatch => {
  return {
    fetchCashFlow: (id) => dispatch(cashFlowListAc(id)),
    downloadGraphMonthly: (id, filter) => dispatch(monthlyBreakDownExportAc(id, filter)),
    clearCashFlow: () => dispatch({
      type: EMPTY_CASH_FLOW_MONLTY_BREAKUP_LIST,
      payload: ''
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(CashFlow, ['mr_calculator', 'analyze_mr_cash_flow','R']));
