import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { utilizationFormat, imgStoragePath } from '../../../../constants';
export default class MajorAssemblyCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { majorAssembly } = this.props;
    return(
      <div className="airframe-single-card">
        <div>
          <h5>{majorAssembly.name} <br/>{majorAssembly.name.trim().includes('Ai') ? '' :`(Titled)`}</h5>
          {  majorAssembly.name.trim().includes('Ai') ?
            <img src={imgStoragePath + "airframe.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().includes('Engine') ?
            <img src={imgStoragePath + "engine.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().includes('APU')  ?
            <img src={imgStoragePath + "power.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().includes('LG') ?
            <img src={imgStoragePath + "landingGear.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().toLowerCase().includes('propeller') ?
            <img src={imgStoragePath + "icon_propeller_white.svg"} width="50" alt="img" style={{color: '#fff'}}/>:null
          }
          <Row>
            <Col md="6" className="airframe-left-bar">
              <h6 className="airframe-text">Avg. Monthly FH</h6>
              <p className="in-pink">
                <NumericLabel params={utilizationFormat}>{majorAssembly.hours}</NumericLabel>
              </p>
            </Col>
            <Col md="6" className="airframe-right-bar">
              <h6 className="airframe-text">Avg. Monthly FC</h6>
              <p className="in-pink">
                {majorAssembly.cycles}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
