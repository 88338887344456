import React, { Component } from 'react';
import { Link } from 'react-router';

export default class BulkOperationReference extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {
  
  }
  render() {
  const { generalDetails, error, detailErr } = this.props;
  return(
    <div className="bulk-operation" >
        <Link className="add-attachment" onClick={()=>this.props.addReferenceLink()} >+ Add Reference Link
        </Link>
      
    </div>
    );
  }
}
