import React, { Component } from 'react';
import moment from 'moment';
import { ContrListCol } from '../../Elements';
import { leaseTypeLst, displayDateFormat } from '../../../../constants';
export default class LeaseOptionRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { leaseOption } = this.props;
    return(
      <tr onClick={this.props.viewLeaseOption}>
        <td width="140">
          <span>{leaseOption.lease_option && leaseOption.lease_option.label ? leaseOption.lease_option.label : '--'}</span>
        </td>
        <td>
          <span>{leaseOption.details ? leaseOption.details.length > 120 ? leaseOption.details.substring(0, 117) + ' ...': leaseOption.details : '--' }</span>
        </td>
        <td>
          <span>
          {
            (leaseOption.validity_start_date ? moment(leaseOption.validity_start_date).format(displayDateFormat):'--' ) + ' to ' + (leaseOption.validity_end_date ? moment(leaseOption.validity_end_date).format(displayDateFormat):'--')
          }
          </span>
        </td>
        <td>
          <span>
            {
              (leaseOption.notice_period_start_date ? moment(leaseOption.notice_period_start_date).format(displayDateFormat):'--') + ' to ' + (leaseOption.notice_period_end_date ? moment(leaseOption.notice_period_end_date).format(displayDateFormat):'--')
            }
          </span>
        </td>

      </tr>
    )
  }
}
