import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { utilizationFormat, imgStoragePath } from '../../../../constants';
export default class FittedAssembly extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { majorAssembly } = this.props;
    return(
      <div className="airframe-single-card" style={{width: '24%', marginRight: '1%'}}>
        <div>
          <h5>{majorAssembly.name}</h5>
          {  majorAssembly.name.trim().includes('Ai') ?
            <img src={imgStoragePath + "airframe.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().includes('Engine') ?
            <img src={imgStoragePath + "engine.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().includes('APU')  ?
            <img src={imgStoragePath + "power.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().includes('LG') ?
            <img src={imgStoragePath + "landingGear.png"} width="50" alt="img" />:null
          }
          { majorAssembly.name.trim().toLowerCase().includes('propeller') ?
            <img src={imgStoragePath + "icon_propeller_white.svg"} width="50" alt="img" />:null
          }
        </div>
      </div>
    )
  }
}
