import * as actions from '../actions';
export default function( state = {
  leaseOptionList: [],
  leaseOptionCrud: {
    flag: false,
    index: 0,
    data: {}
  },
  leaseOptionLoader: true
}, action){
  switch (action.type) {
    case actions.LEASE_OPTION_LIST:
      return {
        ...state,
        leaseOptionList: action.payload
      }
    case actions.LEASE_OPTION_ADD_SUCCESS:
      return {
        ...state,
        leaseOptionList: [action.payload, ...state.leaseOptionList]
      }
    case actions.LEASE_OPTION_EDIT_SUCCESS:
      return {
        ...state,
        leaseOptionList: state.leaseOptionList.map(list => list.id == action.payload.id ? action.payload:list)
      }
      case actions.LEASE_OPTION_CRUD:
        return {
          ...state,
          leaseOptionCrud: action.payload
        }
      case actions.TOGGLE_VIEW_EDIT:
        return {
          ...state,
          leaseOptionCrud: {
            ...state.leaseOptionCrud,
            type: (action.payload == 'view' ? 'edit': 'view')
          }
        }
    case actions.LEASE_OPTION_LOADER:
      return {
        ...state,
        leaseOptionLoader: action.payload
      }
    default:
      return state;
  }
}
