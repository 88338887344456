import React, {Component} from 'react';
import { Link } from 'react-router';
import '../../../../shared/assets/styles/CommonHeaderBlock.scss';
import { imgStoragePath } from '../../../../constants';
export default class CashFlowWrapper extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { id } = this.props;
    return(
      <div className="reviewFinanceRpt-blk">
        <div className="generate-finance-heading" id="maintainence-gen-blk">
          <h2 className="beta gengraph-text">
            <Link to={"/financial-plots/"+id}><img src={imgStoragePath+"back_arw.png"} alt="" width="16"/>Monthly Cash Flow Report</Link>
            <span onClick={this.props.downloadGraphMonthly}><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>Export</span>

          </h2>
        </div>
        {this.props.children}
      </div>
    )
  }
}
