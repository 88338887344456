import React, {Component, Fragment} from 'react';
import{imgStoragePath} from '../../../../constants';
import {FieldDate  } from '../../../contracts/Elements';
import moment from 'moment'
const onlyNumberic = /^(\s*|\d+)$/;
const numberWithDot = /^[0-9]*(\.\d{0,2})?$/;
class LLPsList extends Component{
  constructor(props){
    super(props)
  }
  render() {
    const {llp, type, apu , addEditTechSummaryAc, llpCrud, heading, error, index} = this.props
    return (
      <tr>
        <td style={{width:'100px'}}>
          { !type ? llp.module ? llp.module: '--'
            :
            <input
              type="text"
              maxLength={50}
              value={ llp.module ? llp.module : '' }
              onChange={(e) => this.props.updateForm('module', e.target.value, llp.id)}
              onFocus={(e) => this.props.updateErrorList('module', '', index)}
            />
          }
          <h6 className="error-msg">{error.module}</h6>
        </td>
        <td style={{width:'180px'}}>
            { !type ? llp.description ? llp.description : '--'
              :
              <input
                type="text"
                maxLength={50}
                value={ llp.description ? llp.description : '' }
                onChange={(e) => this.props.updateForm('description', e.target.value, llp.id)}
                onFocus={(e) => this.props.updateErrorList('description', '', index)}
              />
            }
            <h6 className="error-msg">{error.description}</h6>
        </td>
        <td>
          { !type ? llp.part_number ? llp.part_number : '--'
           :
           <input
             type="text"
             maxLength={50}
             value={ llp.part_number ? llp.part_number : '' }
             onChange={(e) => this.props.updateForm('part_number', e.target.value, llp.id)}
             onFocus={(e) => this.props.updateErrorList('part_number', '', index)}
           />
          }
          <h6 className="error-msg">{error.part_number}</h6>
        </td>
          <td>
           { !type ? llp.serial_number ? llp.serial_number : '--'
             :
             <input
               type="text"
               maxLength={50}
               value={ llp.serial_number ? llp.serial_number : '' }
               onChange={(e) => this.props.updateForm('serial_number', e.target.value, llp.id)}
               onFocus={(e) => this.props.updateErrorList('serial_number', '', index)}
             />
           }
           <h6 className="error-msg">{error.serial_number}</h6>
          </td>
          <td>
            { !type ? llp.tsn ? llp.tsn : '--'
              :
              <input
                type="text"
                maxLength={10}
                value={ llp.tsn ? llp.tsn : '' }
                onChange={(e) => {numberWithDot.test(e.target.value) ? this.props.updateForm('tsn', e.target.value, llp.id):e.preventDefault()} }
                onFocus={(e) => this.props.updateErrorList('tsn', '', index)}
              />
            }
            <h6 className="error-msg">{error.tsn}</h6>
          </td>
          <td>
            { !type ? llp.tso ? llp.tso : '--'
              :
              <input
                type="text"
                maxLength={10}
                value={ llp.tso ? llp.tso : '' }
                onChange={(e) => { numberWithDot.test(e.target.value) ? this.props.updateForm('tso', e.target.value, llp.id):e.preventDefault()}}
                onFocus={(e) => this.props.updateErrorList('tso', '', index)}
              />
            }
            <h6 className="error-msg">{error.tso}</h6>
          </td>
          <td>
            { !type ? llp.csn  ? llp.csn : '--'
              :
              <input
                type="text"
                maxLength={10}
                value={ llp.csn ? llp.csn : '' }
                onChange={(e) => { onlyNumberic.test(e.target.value) ? this.props.updateForm('csn', e.target.value, llp.id):e.preventDefault()} }
                onFocus={(e) => this.props.updateErrorList('csn', '', index)}
              />
            }
            <h6 className="error-msg">{error.csn}</h6>
          </td>
          <td>
            { !type ?  llp.cso ? llp.cso : '--'
              :
              <input
                type="text"
                maxLength={10}
                value={ llp.cso ? llp.cso : '' }
                onChange={(e) => { onlyNumberic.test(e.target.value) ? this.props.updateForm('cso', e.target.value, llp.id):e.preventDefault()} }
                onFocus={(e) => this.props.updateErrorList('cso', '', index)}
              />
            }
            <h6 className="error-msg">{error.cso}</h6>
          </td>
          <td>
           { !type ? llp.cycles_max_limit ? llp.cycles_max_limit : '--'
             :
             <input
               type="text"
               maxLength={10}
               value={ llp.cycles_max_limit ? llp.cycles_max_limit : '' }
               onChange={(e) => { onlyNumberic.test(e.target.value) ? this.props.updateForm('cycles_max_limit', e.target.value, llp.id):e.preventDefault()} }
               onFocus={(e) => this.props.updateErrorList('cycles_max_limit', '', index)}
             />

           }
           <h6 className="error-msg">{error.cycles_max_limit}</h6>
          </td>
          <td>
             { !type ? llp.cycles_remaining ? llp.cycles_remaining : '--'
               :
               <input
                 type="text"
                 maxLength={10}
                 value={ llp.cycles_remaining ? llp.cycles_remaining : '' }
                 onChange={(e) => { onlyNumberic.test(e.target.value) ? this.props.updateForm('cycles_remaining', e.target.value, llp.id):e.preventDefault()} }
                 onFocus={(e) => this.props.updateErrorList('cycles_remaining', '', index)}
               />
             }
             <h6 className="error-msg">{error.cycles_remaining}</h6>
          </td>
          <td>
             { !type ?  llp.as_of_date ? moment(llp.as_of_date).format('MMM DD, YYYY'): "--"
               :  <FieldDate
                     value={llp.as_of_date ? llp.as_of_date : null }
                     type=''
                     keyParam="as_of_date"
                     updateField={this.props.updateForm}
                  />
             }
          </td>
          <td style={{background:"none"}}>
           <img src={imgStoragePath + 'delete_red.png'} alt="delete" width="15px" onClick={() => this.props.deleteModal(llp.id)} />
         </td>
      </tr>
      )
    }
  }
export default LLPsList;
