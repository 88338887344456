import * as actions from '../actions';
const numberRegx = /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/;
export default function( state = {
  cashFlowList: {
    list: [],
    pagination: {},
    totalAmount: 0
  },
  cashflowWidget: {},
  cashflowAssetBasicInfo: {},
  cashflowLoader: false,
}, action){
  switch (action.type) {
    case actions.CASH_FLOW_ASSET_BASIC_INFO:
      return {
        ...state,
        cashflowAssetBasicInfo: action.payload
      }
    case actions.CASH_FLOW_LIST:
      return {
        ...state,
        cashFlowList: {
          ...state.cashFlowList,
          list: action.payload.cashflow_list,
          pagination: action.payload.pagination,
          totalAmount: action.payload.total_amount
        }
      }
    case actions.CASHFLOW_WIDGET:
      return {
        ...state,
        cashflowWidget: action.payload
      }
    case actions.CASHFLOW_LOADER:
      return {
        ...state,
        cashflowLoader: action.payload
      }
    default:
      return state;
  }
}
