import React from 'react';
import { imgStoragePath } from '../../constants';
const ListLoader = (props) => {
  if(props.drawer!="primary-nav"){
  return(
    <div style={{position: 'fixed', width: '100%', height: '100%', zIndex: '9999'}} onClick={() => null}>
        <div className="list-loader" style={{position: 'fixed', left: '60%', top: '60%', transform: 'translate(-60%, -60%)', zIndex:10000, background: 'rgba(255,255,255,0.8)', border:'1px solid #d7d7d7',borderRadius:'4px', padding: '15px' }} ><img style={{width: '100px'}} src={imgStoragePath+"loader_v2.gif"} alt="icn" /><p style={{marginTop:"10px", fontWeight:'700',fontSize:'14px'}} className="text-center"></p></div>
    </div>
  )
}else{
  return(
    <div style={{position: 'fixed', width: '100%', height: '100%', zIndex: '9999'}} onClick={() => null}>
        <div className="list-loader" style={{position: 'fixed', left: '53%', top: '60%', transform: 'translate(-60%, -53%)', zIndex:10000, background: 'rgba(255,255,255,0.9)', border:'1px solid #d7d7d7',borderRadius:'4px', padding: '15px' }} ><img style={{width: '100px'}} src={imgStoragePath+"loader_v2.gif"} alt="icn" /><p style={{marginTop:"10px", fontWeight:'700',fontSize:'14px'}} className="text-center"></p></div>
    </div>
  )
}
}
export default ListLoader;
