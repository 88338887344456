import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getLocalStorageInfo, getAccessToken } from './';
import { ContractSideBar } from '../applications/contracts/Elements';
import { AssemblySideBar } from '../applications/maintenance/Elements';
import { RecordsSideBar } from '../applications/records/Elements';
import {lockImgPath, imgStoragePath} from '../constants';
import SidebarMntcCalc from '../shared/components/SidebarMntcCalc';
function RoleBasedAccess(ComposedComponent, extraInfo) {
  class authorizedUser extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount(){
      // let permissionInfo = getLocalStorageInfo().user.permission;
      // if(!(permissionInfo[extraInfo[0]] && permissionInfo[extraInfo[0]][extraInfo[1]] && permissionInfo[extraInfo[0]][extraInfo[1]].indexOf(extraInfo[2]) != -1)){
      //   browserHistory.push('/');
      // }
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      if(userDetail && userDetail.defaultLessor == undefined){
        browserHistory.push( "/select-lessor" );
      }
    }
    render() {
      let permissionInfo1 = getLocalStorageInfo() && getLocalStorageInfo().user ? getLocalStorageInfo().user.permission : {};
      if(permissionInfo1 && permissionInfo1[extraInfo[0]] && permissionInfo1[extraInfo[0]][extraInfo[1]] && permissionInfo1[extraInfo[0]][extraInfo[1]].indexOf(extraInfo[2]) != -1) {
        return(<ComposedComponent {...this.props}/>)
      }else {
        let sideBar;
        let style = {};
        if(extraInfo[0] == 'contracts'){
          style = {marginLeft: '200px', background: '#ffffff', marginRight: '-8px'};
          sideBar = <ContractSideBar style={{top: '45px'}} type={['dashboard', 'utilization', 'invoices', 'cash_flow', 'mr_accruals', 'contract_history', 'half_life_adjustment'].indexOf(extraInfo[1]) != -1 ? 'shorter': 'brief' } params={this.props.params} />
        }else if(extraInfo[0] == 'maintenance'){
          style = {marginLeft: '215px', background: '#ffffff', marginRight: '-8px'};
          sideBar = <AssemblySideBar style={{top: '45px'}} params={this.props.params} />
        }else if(extraInfo[0] == 'mr_calculator'){
          style = {marginLeft: '200px', background: '#ffffff', marginRight: '-8px'};
          sideBar = <SidebarMntcCalc style={{top: '45px'}} path={this.props.route.path} />
        }else if(extraInfo[0] == 'technical_specs' || extraInfo[0] == 'fleet_cash_flow'){
          style = {marginLeft: '0px', background: '#ffffff', marginRight: '-8px'}
          sideBar = null
        }else if(extraInfo[0] == 'records'){
          style = {marginLeft: '0px', background: '#ffffff', marginRight: '-8px'}
          sideBar = <RecordsSideBar style={{top: '45px'}}/>
        }
        let baseUrl = lockImgPath +extraInfo[0]+ '_' +extraInfo[1]+'.jpg';
        return (
          <div style={style}>
            <div className="not-access">
              {sideBar}
            </div>
            <div style={{position: 'fixed', top: '50%', left: '58%', transform: 'translate(-50%, -58%)', color: '#000000', textAlign: 'center', display: 'none'}}>
              <img src={imgStoragePath+"lock_access.png"} style={{}} alt="No Access" style={{width: '40px'}} />
              <br/><br/>
              Sorry, You do not have access to the Parties.<br/>
              Please contact to Acumen for access
            </div>
            <img style={extraInfo[0] === 'analytics' ? {maxWidth: '100%', marginLeft: '60px'} : {maxWidth: '100%'}} src={baseUrl} alt="Not Access" />
          </div>
        )
      }
    }
  }
  return connect(null, null)(authorizedUser);
}
export default RoleBasedAccess;
