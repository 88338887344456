import React, {Component} from 'react';
import { Link } from 'react-router';
import {  Row, Col } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AirframeFields from '../containers/AirframeFields';
export default class AirframeInfCard extends Component{
  constructor(props){
    super(props);
    this.state = {
      fieldFocus : false,
    }
  }
  aircraftTypeChange = (selectedOption) => {
    this.props.changeAircraftType(selectedOption ? selectedOption.id : '', 'aircraftType');
    if(this.props.errors['aircraftType']){
      this.props.updateErrorCode('aircraftType');
    }
  }

  mainPrgTypeChange = (selectedOption) => {
    this.props.changeAircraftType(selectedOption ? selectedOption.value : '', 'maintenanceProgram');
    if(this.props.errors['maintenanceProgram']){
      this.props.updateErrorCode('maintenanceProgram');
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true
    })
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false
    })
  }
  render(){
    const { title, type, airframeAndGen, aircraftTypeList, aircraftMainPrg, errors } = this.props;
    return(
      <div className="info-card">
        <div className="engine-info-card">
          <h6>{title}</h6>
          <Row className="aircraft-outer-wrap">
            <Col md="4" className="inner-wrap">
              <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'}  }>Aircraft Type<sup className="reqStar"> * </sup></label>
              <Select
                name="form-field-name"
                value={airframeAndGen.aircraftType}
                onChange={this.aircraftTypeChange}
                options={aircraftTypeList}
                placeholder = 'Select Aircraft type'
                className="custom-select-block"
                labelKey = "name"
                valueKey = "id"
                onFocus = {() => this.handleFocus() }
                onBlur={() => this.handleBlur()}
                />
              <h6 className="error-msg">{errors['aircraftType']}</h6>
            </Col>
            <AirframeFields
              label="Date of Manufacture"
              value={airframeAndGen.dateOfManufacture}
              error={Object.keys(errors).length ? errors['dateOfManufacture']:''}
              type="dateOfManufacture"
              inputType="date"
              updateErrorCode={this.props.updateErrorCode}
            />
            { type == 'airframe' ?
              <Col md="4" className="inner-wrap">
                <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'}  }>Maintenance Program<sup className="reqStar"> * </sup></label>
                <Select
                  name="form-field-name"
                  value={airframeAndGen.maintenanceProgram}
                  onChange={this.mainPrgTypeChange}
                  options={aircraftMainPrg}
                  placeholder = 'Select Maintenance Program'
                  className="custom-select-block"
                  labelKey = "label"
                  valueKey = "value"
                  onFocus = {() => this.handleFocus() }
                  onBlur={() => this.handleBlur()}
                  />
                <h6 className="error-msg">{errors['maintenanceProgram']}</h6>
              </Col>
                :null
            }
          </Row>
          <Row className="aircraft-outer-wrap">
            <AirframeFields
              label="Time Since New (TSN)"
              value={airframeAndGen.tsn}
              error={Object.keys(errors).length?errors['tsn']:''}
              type="tsn"
              inputType="text"
              updateErrorCode={this.props.updateErrorCode}
            />
            <AirframeFields
              label="Cycles Since New (CSN)"
              value={airframeAndGen.csn}
              error={Object.keys(errors).length?errors['csn']:''}
              type="csn"
              inputType="text"
              updateErrorCode={this.props.updateErrorCode}
            />
            <Col md="4"  className="inner-wrap">
              <label style={ {color:'#828282'}  } >Current Utilization Ratio</label>
              {airframeAndGen.utlizationRatio ? airframeAndGen.utlizationRatio + ':1':'--'}
            </Col>
            <AirframeFields
              label="Assumed Monthly Utilization in Hour"
              value={airframeAndGen.hourlyUtlization}
              error={Object.keys(errors).length?errors['hourlyUtlization']:''}
              type="hourlyUtlization"
              inputType="text"
              updateErrorCode={this.props.updateErrorCode}
            />
            <AirframeFields
              label="Assumed Monthly Utilization in Cycle"
              value={airframeAndGen.cycleUtlization}
              error={Object.keys(errors).length ? errors['cycleUtlization']:''}
              type="cycleUtlization"
              inputType="text"
              updateErrorCode={this.props.updateErrorCode}
            />
            <Col md="4"  className="inner-wrap">
              <label style={ {color:'#828282'}  } >Assumed Utilization Ratio</label>
              {
                airframeAndGen.hourlyUtlization && (airframeAndGen.cycleUtlization && airframeAndGen.cycleUtlization > 0) ? (airframeAndGen.hourlyUtlization/airframeAndGen.cycleUtlization).toFixed(1) + ':1':'--'
              }
              <h6 className="error-msg">{ Object.keys(errors).length ? errors['utlizationRatioAir']:''}</h6>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
      }
