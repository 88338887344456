import React, { Component } from 'react';
import { Link } from 'react-router';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';

export default class BulkOperation extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { generalDetails, error, detailErr } = this.props;
  return(
    <div className="bulk-operation" >
        <Link className="add-attachment">+ ADD ATTACHMENTS
          <input type="file" onChange={(e) => this.props.GenUrlFn(e.target.files, 'attachment')} onClick={(e)=> {e.target.value = null}} accept="application/pdf"/>
        </Link>
    </div>
    );
  }
}
