import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';
import { imgStoragePath, displayDateFormat } from '../../../../../constants';

export default class LLPData extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { llpDataList, detailErr, index } = this.props;
  let type = 'edit';
  return(
    <tr>
			<td>{index+1}</td>
      <td>
      {
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: llpDataList.description,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'description', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
      </td>
      <td>
      {
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: llpDataList.part_number,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'part_number', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
      </td>
      <td>
        {
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: llpDataList.serial_number,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'serial_number', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
      </td>
      <td>
      {
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: llpDataList.csn,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'csn', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
      </td>
       <td>
       {
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: llpDataList.cycles_remaining,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'cycles_remaining', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
       </td>
      <td>
        {
        GetInputField(
          'dropdown',
          null,
          { type: 'edit',
            value: llpDataList.status,
            error:'',
            labelKey:'label',
						valueKey: 'key',
            options: [{label: 'Scrap', key:1}, {label: 'Reuse', key:2}],
            updateForm: (value) => this.props.updateForm({ type: 'status', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
        </td>
      <td>
      {
        GetInputField(
          'string',
          null,
          { type: 'edit',
            value: llpDataList.remarks,
            error:'',
            updateForm: (value) => this.props.updateForm({ type: 'remarks', value: value, sectionType: 'llp_details', index: index})
          }
        )
      }
     </td>
     <td>
       <img width="12" onClick = {() => this.props.toggleDelModal(llpDataList.id, 'llp_details', index)} className="" src={imgStoragePath+"delete_red.png"}/>

     </td>
      </tr>
    );
  }
}
