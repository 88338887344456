import React, { Component } from 'react';
import { FieldSelect, FieldInput, FieldDate } from '../../../../contracts/Elements';
import { imgStoragePath } from '../../../../../constants';
import { toastFlashMessage } from '../../../../../utils';
import {  TextInputField, TextareaField } from '../../../Elements';
import { Link } from 'react-router';
import moment from 'moment';
class FinalSummariesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      editable: null,
      error:{},
      imageFile:''
    }
  }
  componentDidMount() {
    let data = Object.assign({}, this.props.content);
    this.setState({
      content: data,
      editable: this.props.editable
    });
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.updateComponent){
    let data = Object.assign({}, nextProps.content);
    console.log("WILL RECEIVE",nextProps.content);
    this.setState(prevState => ({
      ...prevState,
      content: data,
      error:{}
    }));
    this.props.toggleUpdate();
  }
  }
  updateForm=(key,value)=>{
    if(value==null || value== undefined){
      value='';
    }
    if(key=='files'){
      if((value.length + this.state.content.files.length) >10 ){
        this.setState({
          error: {
            ...this.state.erros,
            files: 'Maximum 10 files allowed'
          }
        })
        return
      }
    }
    if(key=='description'){
      let objError = {
        description: value.trim() == '' ? 'Please enter description':'',
      }
      if (Object.keys(objError).every((k) => { return objError[k] === '' })){
        this.setState(prevState => ({
          ...prevState,
          error: objError,
        }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          error: {...prevState.error,...objError},
        }));
        return;
      }
    }
    if (Object.keys(this.state.error).every((k) => { return this.state.error[k] === '' })) {
      this.setState(prevState => ({
        ...prevState,
        content: {
          ...prevState.content,
          [key]: value,
        },
        error: {
          ...prevState.error,
          [key]: ''
        }
      }));
      var formData = new FormData();
      if(this.state.content.description != ''){
        if(key != 'description'){
          formData.append("description", this.state.content.description);
        }else {
          formData.append([key],value);
        }
      }
      if(key == 'files'){
        console.log('files value', value)
        for(let f of value){
          formData.append('files', f)
        }
      }else {
        formData.append([key],value);
      }
      setTimeout(() => {
        formData.append("gap_report_section_id",this.props.sectionId);
        this.props.updateField(formData,this.state.content.id,key=='files'?'update':null);
      }, 500)
    }
  }
  updateImage=(image)=>{
    if(image.type=='image/jpeg'||image.type=='image/png'){
      this.setState(prevState => ({
        ...prevState,
        imageFile:image
      }));
    }else{
      toastFlashMessage('Please add proper image', 'error');
    }
  }
  render (){
    const { content, error, editable} = this.state;
    const  { formInfo,operatorFlag } = this.props;
    return(
      <tr>
        <td>{this.props.index+1}</td>
        <td>
          <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
            <TextareaField
              value={content.description}
              type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase())? 'edit': 'view'}
              keyParam="description"
              error={error.description}
              updateForm={(value)=>this.updateForm('description',value)}
            />
          </div>
        </td>
        <td>
          <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
            <FieldSelect
              placeholder='Select Disposition'
              keyParam="disposition"
              value={content.disposition}
              type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
              options={[{label:'open',value:0},{label:'close',value:1}]}
              labelKey='label'
              valueKey='value'
              updateField={this.updateForm}
              name = {content.disposition == 0 ? "Open" : "Close"}
            />
          </div>
        </td>
        <td>
          <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
            <FieldDate
              value={content.started_date}
              type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save&& operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
              keyParam="started_date"
              updateField={this.updateForm}
            />
            <h6 className="error-msg">{''}</h6>
          </div>
        </td>
        <td>
          <div className= {content.estimated_completion_date?moment(content.estimated_completion_date).diff(moment()) > 0 ?"":"time-check":""} style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
            <FieldDate
              value={content.estimated_completion_date}
              type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save&& operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
              keyParam="estimated_completion_date"
              updateField={this.updateForm}
            />
            <h6 className="error-msg">{''}</h6>
          </div>
        </td>
        <td>
          <table>
            <tbody>
              <tr className="no-border">
                <td>
                  <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
                    <TextareaField
                      value={content.requirement_remarks}
                      type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save&& operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
                      keyParam="requirement_remarks"
                      updateForm={(value)=>this.updateForm('requirement_remarks',value)}
                    />
                  </div>
                </td>
                <td>
                  <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
                    <TextareaField
                      value={content.background_remarks}
                      type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save&& operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
                      keyParam="background_remarks"
                      updateForm={(value)=>this.updateForm('background_remarks',value)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
        <table>
          <tbody>
            <tr className="no-border">
              <td style={{width: '33.33%'}}>
                <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
                  <TextareaField
                    value={content.acumen_comments}
                    isDisabled={editable !== 0}
                    type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
                    keyParam="acumen_comments"
                    updateForm={(value)=>this.updateForm('acumen_comments',value)}
                  />
                </div>
              </td>
              <td style={{width: '33.33%'}}>
                <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
                  <TextareaField
                    value={content.operator_comments}
                    isDisabled={editable !== 1}
                    type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
                    keyParam="operator_comments"
                    updateForm={(value)=>this.updateForm('operator_comments',value)}
                  />
                </div>
              </td>
              <td style={{width: '33.33%'}}>
                <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
                  <TextareaField
                    value={content.operator_2_comments}
                    isDisabled={editable !== 2}
                    type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
                    keyParam="operator_2_comments"
                    updateForm={(value)=>this.updateForm('operator_2_comments',value)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </td>
        <td style={{width: '100px'}}>
        {
          content.files && content.files.length?
          <Link onClick={this.props.openFilesList} style={{fontSize:'14px', color: '#2f8cff', marginBottom: '15px', display: 'block'}}>{content.files.length} Files attached</Link>:null
        }
          {
            ![1,2].includes(editable) && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
            <div className="attach-image">
              {content.files  && content.files.length <10?<h4 className="btn btn-primary" style={{textAlign: 'left', color: '#fff', fontSize: '12px', padding: '5px 10px'}}>Attach File(s) {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && content.files  && content.files.length <10 ?<input type="file" multiple={true} accept={".png, .jpg, .jpeg, .pdf, .xls, .xlsx"} style={{marginTop:'-10px',height:'30px'}} onChange={(e)=>this.updateForm('files',e.target.files)}/>:null}</h4>:null}
            </div>
            :null
          }
          <h6 className="error-msg">{this.state.error.files}</h6>
        </td>
        <td>
          <div style={{borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?'1px solid #717171':'none'}}>
            <FieldSelect
              placeholder='Select Priority'
              keyParam="priority"
              value={content.priority}
              type={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? 'edit': 'view'}
              options={[{label:'P1',value:1},{label:'P2',value:2}]}
              labelKey='label'
              valueKey='value'
              updateField={this.updateForm}
              name={content.priority?'P'+content.priority:'--'}
            />
          </div>
        </td>
        <td>
          { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && ![1,2].includes(editable)?
            <div style={{textAlign:'center'}}>
              <img width="15" onClick={()=>this.props.deleteSectionData(content.id,content.gap_report_section)} src={imgStoragePath+"delete_red.png"} />
            </div>:null
          }

        </td>
      </tr>
    )
  }
}
export default FinalSummariesList;
