import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
import { localTimeFn, getFileSize } from '../../../../utils';
class List extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal: false
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle(e=null) {
    if(e){
      e.preventDefault();
    }
    this.setState({
      modal: !this.state.modal
    });
  }
  deleteFile = (params, fileId) => {
    this.toggle();
    this.props.deleteFile(params, fileId);
  }
  render(){
    const { item, params } = this.props;
    return(
      <Link className="list-row" to={item.status == '1' ? '/'+params.type+'/'+params.aircraft_slug +'/techspecs-logs/' + item.id :''}>
        <Row className="upload-row flex-centered">
          <Col md="3">
            <span className="caption-ui wrapword">
              <ul className="list-unstyled">
                { item.files.map((file) =>
                  <li style={{marginBottom:'5px'}}><img src={imgStoragePath+'pdf.png'} alt="" width="15" />  {file.name}</li>
                )}
              </ul>
            </span>
          </Col>
          <Col md="2" style={{paddingRight:'0'}}>
              <span className="file-type-status">
                <ul>
                  { item.files.map((file) =>
                    <li>-{file.file_type.label}</li>
                  )}
                </ul>
            </span>
          </Col>
          <Col md="1">
            <span className="caption-ui">{getFileSize(item.size)}</span>
          </Col>
          <Col md="2">
            <span className="caption-ui">{localTimeFn(item.created_at)}</span>
          </Col>
          <Col md="2">
            <div className="flex-centered upload-by">
              <img src={item.uploaded_by.profile_pic && item.uploaded_by.profile_pic != '' ? item.uploaded_by.profile_pic : imgStoragePath+'user.png' } />
              <span className="caption-ui">{item.uploaded_by.name}</span>
            </div>
          </Col>
          <Col md="2" className="text-right">
            {
              item.status == '0' ?
              <span className="caption-ui">
                <img  width="20" src={imgStoragePath+"rolling.gif"} alt="img" style={{marginRight: '8px'}}/>
                  Processing
              </span>:
              item.status == '2' ?
              <span className="caption-ui">
                <img  width="15" src={imgStoragePath+"warning.png"} alt="img"/>
                  Failed To Process
              </span>
              :<span className="caption-ui">
                <img  width="15" src={imgStoragePath+"file_completed.png"} alt="img"/>
                  Processed
              </span>
            }
            <Link to="" style={{marginLeft: '10px', display: 'inline-block'}} onClick={(e) => this.toggle(e)}>
              <img src={imgStoragePath+'icon_trash.png'} alt="" width="15" />
            </Link>
          </Col>
        </Row>
        { item.status == '1' && item.ad_points > 0 ?
          <Row className="margin-bottom-10 pending-status-data">
            <Col md="12">
              <p className="caption-ui">
                { item.files && item.files.count == 1 ? <span><b>{item.ad_points}</b> ADs found in Operator AD status report <Link>View</Link></span>:
                <span><b>{item.ad_points}</b> ADs found in OEM AD status report are not in Operator AD status report <Link>View</Link></span>  }
              </p>
            </Col>
          </Row>:null
        }
        { item.status == '0' ?
          <Row className="margin-bottom-10 awiting-for-approval">
          <Col md="12">
            <p>
                Document is under processing. You can proceed with other actions on system till then.
            </p>
          </Col>
        </Row>:null
        }
        { item.status == '2' ?
          <Row className="margin-bottom-10 awiting-for-approval">
          <Col md="12">
            <p>
                Please contact support for more details.
            </p>
          </Col>
        </Row>:null
        }
        <Modal isOpen={this.state.modal} centered={true} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Delete File</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to delete ?</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick = {(e) => this.deleteFile(item.id)}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={this.toggle}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </Link>
    )
  }
}
export default withRouter(List)
