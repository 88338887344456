import * as actions from '../actions';
export default function(state = {
  contractList: [],
  basicMetaInfo: {},
  ctrShortInfo: {},
  contractListLoader: false,
  contractUsage:{}
}, action){
  switch (action.type) {
    case actions.CONTRACT_USAGE_INFO:
      return {
        ...state,
        contractUsage : action.payload
      }
    case actions.CONTRACT_LIST:
      return {
        ...state,
        contractList : action.payload
      }
    case actions.ADD_CONTR:
      return {
        ...state,
        contractList: [action.payload, ...state.contractList]
      }
    case actions.BASIC_INFO:
      return {
        ...state,
        basicMetaInfo: action.payload
      }
    case actions.CONTRACT_LIST_LOADER:
      return {
        ...state,
        contractListLoader: action.payload
      }
    case actions.CTR_SHORT_INFO:
      return {
        ...state,
        ctrShortInfo: action.payload
      }
    default:
      return state;
  }
}
