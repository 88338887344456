import React, { Component } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TableFilter from '../TableFilter';
import { imgStoragePath } from '../../constants';
import { NoRecordFound } from '../../shared';
import { StatusDetails } from '../../applications/technicalInspection/Tracker/components'

export default class TableUIComp extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  render(){
    const {
      theads,
      checkedAll,
      content,
      bulkOperation,
      pagination,
      filter,
      tableBulkOp,
      sortTable,
      selectAllItem,
      changePerPage,
      changePagination,
      searchQuery,
      tableHeight,
      recordsFound,
      tableScroll,
    } = this.props;
    let pageCount = pagination ? Math.ceil(pagination.total/pagination.per_page) : 0;

    let customClass = '';
    if(this.props.noStickyHeader){
      if(this.props.hover){
        customClass = "table-sticky hover-table";
      }else{
        customClass = "";
      }
    }else{
      if(this.props.hover){
        customClass = " hover-table";
      }else{
        customClass = "table-sticky ";
      }
    }
    return(
      <div className="table-ui-component">
        <div className="bulk-ops-cn ">{tableBulkOp}</div>
        { filter && filter.displayFilter ? filter.projectInfo ?
        <div className="table-list-filter row" style={{margin:'0px'}}>
         <div className="col-md-10" style={{marginLeft:'-15px'}}>
           <TableFilter
            clearFilterValue={this.clearFilterValue}
            filter={filter}
            changeFilterFn={this.props.changeFilterFn}
            queryParamsEnabled={this.props.queryParamsEnabled}
          />
          </div>
          <div className="col-md-2" style={{marginLeft:'10px'}}>
          <StatusDetails/>
          </div>
          </div>:
          <div className="table-list-filter">
              <TableFilter
                clearFilterValue={this.clearFilterValue}
                filter={filter}
                changeFilterFn={this.props.changeFilterFn}
                queryParamsEnabled={this.props.queryParamsEnabled}
                tableSpecialOp={this.props.tableSpecialOp}
             />
          </div>
          :null
        }
        <div className= {tableScroll=='none'?"":"table-height"} style={{ height: tableHeight ? tableHeight:'400px'} }>
          <table className= {customClass}>
           <thead>
              <tr>
                { bulkOperation ?
                  <th className="check-opt">
                    <input checked={checkedAll} onChange={selectAllItem} type="checkbox" />
                  </th>:null
                }
                {theads.map((thead, index) =>
                  <th key={index} style={thead.style} colSpan={thead.colSpan} className={(thead.label == 'Risk Level') ? "risk-level": ''}>
                    {!thead.child?
                    <span onClick={(e) => { thead.sortKey ? sortTable(thead.sortKey) : e.preventDefault()}}>
                      {thead.label}
                        { thead.sortKey ?
                         <b>
                          <sup className={window.safari ? "asc-sort-safari":"asc-sort" } style={searchQuery.sort == thead.sortKey && searchQuery.sort_by == 'asc' ? {color: 'rgb(0, 0, 0)'}:{color: 'rgb(223, 223, 223)'}}> ▲ </sup>
                          <sub className="desc-sort" style={searchQuery.sort == thead.sortKey && searchQuery.sort_by == 'desc' ? {color: 'rgb(0, 0, 0)'}:{color: 'rgb(223, 223, 223)'}}>▼</sub>
                          </b> : null
                         }
                    </span>:
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2" style={{textAlign:'center'}} className="table-head">{thead.label}</th>
                           </tr>
                            <tr>
                            { thead.child.map((child, index) =>
                              <th className="sub-head">{child.label}</th>
                            )}
                            </tr>
                          </thead>
                      </table>
                    }
                  </th>
                )}
              </tr>
           </thead>
           <tbody>
            {content}
            {recordsFound ?
              <tr className="no-rcrd-found-tbody">
                 <td colSpan={theads.length + 1}>
                    <NoRecordFound style={this.props.noRecordStyle}/>
                 </td>
               </tr>:null
            }
           </tbody>
        </table>
        </div>

        {
          pagination !== undefined && pagination !== null && Object.keys(pagination).length ?
          <div className="table-footer">
            <Row>
              <Col md="6">
                <Dropdown isOpen={this.state.dropdownOpen}  toggle={this.toggle}>
                  <DropdownToggle
                    tag="span"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                  >
                  <span className="per-page">Show</span> {pagination.per_page} <span style={{color: 'rgb(47, 140, 255)', cursor:'pointer'}}>&#9662;</span>
                  </DropdownToggle>
                  <DropdownMenu className="per-page-menu" right={true}>
                    {[10,20,30,40,50].map(perPage =>
                      <div className="per-page-option" key={perPage} onClick={() => {this.toggle();changePerPage(perPage)}}>{perPage}</div>
                    )}
                  </DropdownMenu>
                  <span className="per-page"> entries</span>
                </Dropdown>

              </Col>
              <Col md="6">
                {
                  pageCount && pageCount > 1 ?
                  <ul className="list-inline page-info">
                    <li className="list-inline-item">
                      <a onClick={pagination.page == 1 ? null : () => changePagination('first',  1 )}>
                        <img style={pagination.page == 1 ? {width: '13px', opacity: 0.5}:{width: '13px'}} src={imgStoragePath+"table_first_arw.png"} alt="icn" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a onClick={pagination.page == 1 ? null : () => changePagination('prev', (parseInt(pagination.page) - 1))}>
                        <img  style={pagination.page == 1 ? {width: '8px', opacity: 0.5}:{width: '8px'}} src={imgStoragePath+"table_prev_arw.png"} alt="icn" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <span>Page {pagination.page} of {pageCount} </span>
                    </li>
                    <li className="list-inline-item">
                      <a onClick={ pagination.page == pageCount ? null : () => changePagination('next', (parseInt(pagination.page) + 1)) }>
                        <img style={pagination.page == pageCount ? {width: '8px', opacity: 0.5}:{width: '8px'}} src={imgStoragePath+"table_next_arw.png"} alt="icn" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a onClick={pagination.page == pageCount ? null : () => changePagination('last', pageCount)}>
                        <img style={pagination.page == pageCount ? {width: '13px', opacity: 0.5}:{width: '13px'}} src={imgStoragePath+"table_last_arw.png"} alt="icn" />
                      </a>
                    </li>
                  </ul>: null
                }

              </Col>
            </Row>
           </div> : null
        }

      </div>
    )
  }
}
