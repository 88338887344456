import * as actions from '../actions';
export default function(state={
resourcesRecords:{},
resourceLoader: false,
dataDownloadFlag: false,
dataKitList: {
  title: '',
  data: [],
},
pagination: {},
}, action){
  switch (action.type) {

    case actions.LOAD_RES_RECORDS:
    return {
      ...state,
      resourcesRecords: action.payload
    }
    case actions.DATAKIT_LIST:
    return {
      ...state,
      dataKitList: action.payload
    }
    case actions.RESOURCE_LOADER:
    return {
      ...state,
      resourceLoader: action.payload
    }
    case actions.PAGINATION:
    return {
      ...state,
      pagination: action.payload
    }
    case actions.REMOVE_LIST:
      return {
        ...state,
        dataKitList: {
          ...state.dataKitList,
          data: {...state.dataKitList.data ,list: state.dataKitList.data.list.filter(item => item.uuid != action.payload.uuid)}
        },
      }
    case actions.BULK_DATA_DWN_FLAG:
      return {
        ...state,
        dataDownloadFlag: action.payload
      }
    default:
      return state

  }
}
