import React, { Component } from 'react';
import {
  GraphTableWrapper,
  GrpTableHd,
  GrpTableRow
} from './';
export default class GrpALGAPUWrapper extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { programInfo, metrixType, programType, newprogramInfo, simulationType } = this.props;
    return(
      <GraphTableWrapper simulationType={simulationType } metrixType={metrixType} programType={programType} eventIndex="" newprogramInfo={ newprogramInfo } programInfo={programInfo}>
        <table>
          <GrpTableHd metrixType={metrixType} />
          <tbody>
            {
              Object.keys(programInfo.events).map((eventIndex, childIndex) =>
                <GrpTableRow simulationType={simulationType} metrixType={metrixType} programType={programType} eventIndex={eventIndex} childIndex={childIndex} key={eventIndex} neweventInfo={simulationType == 2 ? newprogramInfo.events[eventIndex]:{}} eventInfo={programInfo.events[eventIndex]} />
              )
            }
          </tbody>
        </table>
      </GraphTableWrapper>
    )
  }
}
