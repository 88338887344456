import * as actions from '../actions';
export default function(state={
  recordsDbStats: {},
  lastActivityData: {},
  recordsDbActivity: [],
  dashboardLoader: false,
  fileStatusLoader:false,
}, action){
  switch (action.type) {
    case actions.RECORDS_DB_STATS:
      return {
        ...state,
        recordsDbStats: action.payload
      }
    case actions.RECORDS_DB_ACTIVITY:
      return {
        ...state,
        recordsDbActivity: action.payload
      }
    case actions.RECORDS_DASHBOARD_LOADER:
      return{
        ...state,
        dashboardLoader: action.payload
      }
    case actions.FILE_DASHBOARD_LOADER:
      return{
        ...state,
        fileStatusLoader: action.payload
      }
    case actions.LAST_ACTIVITY_DATA:
      return{
        ...state,
        lastActivityData: action.payload
      }

    default:
      return state;
  }
}
