

  export const robberyObj={
      partNumber: '',
      partDescription: '',
      locationOfPart: '',
      removalDate: null,
      reasonOfRemoval:'',
      dateVerifiedRemovedFromAircraft:null,
      dateVerifiedInStorehouse:null,
      dateVerifiedInstallOnAircraft:null,
      price:'',
      workCardNo: '',
      comments:''
  }

  export const contactObj = {
    full_name: '',
    email: '',
    phone_number: '',
    fax: '',
    title: ''
  }

  export const sectionObj={
			description: '',
			part_number: '',
			serial_number: '',
			disposition: 0,
			started_date: null,
			estimated_completion_date: null,
			requirement_remarks: null,
			background_remarks: null,
			image_url: null,
			operator_comments: null,
			priority: null,
			position: null,
			install_date: null,
			gap_report_section_id: 0

  }

  export const accessoriesData={
			ata: null,
			description: null,
			delivery_part_number: null,
			delivery_serial_number: null,
			installed_part_number: null,
			installed_serial_number: null,
			tsn: null,
			csn: null,
			remarks: null
  }
  export const mrSectionData={
    content: '',
    title: ''
}
  export const recordsData={
				description: null,
				part_number: null,
				serial_number: null,
				cycles_remaining: null,
				acumen_remarks: null,
				status: 1,
				comments:null,
				ref_document: null,
				operator_comments: null,
				engine_gap_report_section: null,
				engine_work_order: null
 }

  export const additionalData={
    description: null,
    item: null,
    part_number: null,
    remarks: null,
    serial_number: null
  }

  export const createProject = {
    name: '',
    start_date: null,
    expected_end_date: null,
    leads:[],
    status: {
      id: 0
    },
    engineers:[],
    operator_id:'',
    lessee_id:'',
    assets: [],
    leads: [],
    location:'',
    third_party: '',
    cor_required: true,
    project_form: 0,
    client_contacts:[
      {name: '', email:'',designation:'', phone_number: ''}
    ],
    operator_contacts:[
      {name: '', email:'',designation:'', phone_number: ''}
    ],
    third_party_contacts: [
      {name: '', email:'',designation:'', phone_number: ''}
    ],
    instructions: '',
    miscellaneous_comments:'',
    equipment_requirements: ''
  }

  export const forms =  [{
    "id": 1,
    "name": "T001"
    }, {
    "id": 2,
    "name": "T002",

  }, {
    "id": 3,
    "name": "T003",

  }, {
    "id": 4,
    "name": "T004",

  }, {
    "id": 6,
    "name": "T006",

  }, {
    "id": 7,
    "name": "T007",

  }, {
    "id": 11,
    "name": "T011",

  }, {
    "id": 5,
    "name": "T005",

  }];


  export const inspection_types = [
    {
      "id": 1,
			"name": "Airline Technical Review",
    },
    {
      "id": 2,
			"name": "Annual Audit",
    },
    {
      "id": 3,
			"name": "C/D/HMV Checks",
    },
    {
      "id": 4,
			"name": "Delivery",
    },
    {
      "id": 5,
			"name": "General Surveillance",
    },
    {
      "id": 6,
			"name": "Mid-Term Audit",
    },
    {
      "id": 7,
			"name": "Pre-Purchase Inspection",
    },
    {
      "id": 8,
      "name": "Pre-Re-Delivery Audit"
  }]

  export const aircraft =  {
		"id": '',
		"project_id": '',
		"start_date": null,
		"expected_end_date": null,
		"end_date": null,
		"status": '',
		"msn": '',
		"operator": null,
		"location": "",
		"lat": "",
		"lang": "",
		"aircraft_model_id": "",
		"registration": "",
		"aircraft_type_id": '',
		"tsn": null,
		"csn": null,
		"date_of_manufacture": null,
		"contact_number": null,
		"contact_email": null,
		"contact_name": null,
		"aircraft_series": "",
		"progress":"",
		"serial_number": null,
		"line_number": null,
		"status_as_of": null,
		"manufacturer": null,
		"operator_storage_facility": null,
		"aoc_regulation": null,
		"no_of_galleys": {"fwd":'' ,"mid":'', "aft":'' },
		"no_of_lavatories":  {"fwd":'',"mid":'', "aft":''},
		"no_of_gears": '',
		"records_inspection_on_start_date": "",
		"physical_inspection_on_start_date": "",
		"records_inspection_on_end_date": "",
		"physical_inspection_on_end_date": "",
		"lease_start_date": "",
		"lease_end_date": null,
		"original_engine_information": null,
		"lopa_photo": null,
		"lopa_remark": null,
		"status_changed_at": "",
		"rejected_aircraft_section_id": null,
		"operator_status": 0,
		"client_status": 0,
		"country": "",
		"created_at": "",
		"updated_at": "",
		"slug": "",
		"forms": [],
		"inspection_types": []
	}

  export const te001=[
   "To ensure the BSI report is legible and complete",
   "To check for the availability of Video / Image for all BSI inspected engine hardware",
   "To check for the quality of Video / Image received is in good condition to interpret the findings (if any)",
   "To use the latest revision AMM reference to review the BSI report",
   "To check if all the findings reported in the BSI report have substantiating Video / Image (with measurement if any)",
   "To review any additional documents sent with the BSI report to substantiate the serviceability of any findings beyond AMM limits",
   "Prepare the form with all essential engine data and borescope finding details",
   "Review the form for the correctness of the engine data and borescope finding details",
   "Insert the borescope pictures at its respective locations",
   "Update the recommendation based on the BSI report and pictorial evidences",
  ]

  export const te002=[
    "To avail the engine accessories delivery part number and serial number details from the operator / lessor",
    "To check for the latest available engine accessories part number and serial number details from the operator / lessor",
    "Physically check for the accessories part number and serial number installed on the engine",
    "Prepare the accessory list with the delivery part number, serial number and installed part number, serial number",
    "Make sure to note the TSN and CSN of the installed accessories and provide remarks (if any) for the missing accessories",
    "Make sure to update the additional data and missing hardware details",
    "Ensure the information provided is accurate"
   ]

   export const te003=[
    "To check for the documents received against the list of engine documents",
    "Sort out the engine documents received / not received and update the client accordingly",
    "Review each document throughly for the technical details available and/or missing, incorrect data",
    "Review each document to trace the operation of the engine from new and the supporting documents from the operator / owner / shop",
    "Check for all supplied documents provided for the correctness of data",
    "Cross check the LLP BTB provided is in line with the latest available LLP sheet",
    "Review each installed LLP BTB documents for the trace the operation from new and the supporting documents from the operator / owner / shop",
    "Consolidate all the missing data and prepare the gap report accordingly",
    "Finalize the gap report with all the essential data filled",
    "If any supplement document supplied for closing the gaps, repeat the process check list (wherever applicable) from point number 1"
   ]

   export const te004=[
    "To check for the documents received against the list of engine documents pertaining to the scope of review",
    "To update the objective of the review report",
    "Review and extract the engine origin details",
    "Review and extract the engine operation and the shop visit details through out the engine operation",
    "Analyse the extracted data to understand the engine operational and shop visit history",
    "Review and analyse the latest ECTM, BSI report, LLP status, any soft time limited engine hardware and / or any additional information",
    "Update all extracted details in the engine data review master sheet till the latest available date",
    "Update the extracted details in the engine data review master sheet",
    "Select the required check box pertaining to the scope of review for generating report"
   ]

   export const te005=[
    "To ensure the initial workscope is available for the engine shop visit",
    "To ensure the latest LLP status of the engine is available prior to shop visit",
    "To ensure the engine accessories workscope is available",
    "To check if the previous shop visit details are available (if any)",
    "To ensure the Lease Agreement is available to check for the engine build life / lease return condition",
    "Coordinate with the Client and MRO for the table inspection date and availability of engine hardware in dirty condition",
    "Perform the table inspection of the available hardware and capture pictures",
    "Relate the engine hardware condition to the initial workscope for assessment",
    "To conduct discussion with the MRO and the Client if the proposed workscope do not meet the hardware condition / engine build life",
    "Prepare the report with observations on the engine hardware and summary on the engine workscope and build life."
   ]

   export const te006=[
    "To ensure that Lease Agreement with the Reimbursement Clauses are available",
    "To check for the availability of approved workscope",
    "To check for the receipt of preliminary invoices",
    "Review the preliminary invoices for labor, material, in house repair, outside vendor repair, sub contractor charges, etc., in relation to the approved workscope",
    "Review the minipack to relate the work carried out is as per the approved workscope",
    "To confirm the initial payment through Proof of Payment",
    "Recommend the Preliminary Claim amount",
    "Review the final invoices for labor, material, in house repair, outside vendor repair, sub contractor charges, etc., in relation to the approved workscope",
    "Review the detailed shop visit report to relate the work carried out is as per the approved workscope and charged accordingly",
    "To confirm the full payment through Proof of Payment",
    "Review the exclusions from the final invoices based on the Lease Agreement Clauses",
    "Check for any industry support, warranty adjustment, changes required in the final invoice value",
    "Recommend the Final Claim amount"
   ]
