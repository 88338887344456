import React, {Component}  from 'react';
import { connect } from 'react-redux';
import {  withRouter, browserHistory } from 'react-router';
import { Row, Col  } from 'reactstrap';
import { assetViewIdAc } from '../../User/actionCreators';
import { getMsnSwitcherAc } from '../actionCreators';
import { imgStoragePath } from '../../constants';
import { ToolTipHover } from '../../shared';
class MSNSwitcherHeader extends Component{
  constructor(props){
  	super(props)
  }
	componentDidMount(){
		this.props.getMsnSwitcher();
	}
  render(){
	   const { assetViewId, SecondaryHeaderMid, SecodaryHeaderRH, projectSlug, activeAsset } = this.props;
     let assetTypeFlag = activeAsset && Object.keys(activeAsset).length
     return(
       <div className="techMsn-switcher" id="techMsn-switcher">
         <Row className="flex-not-centered">
           <Col md="6" className="nav-block flex-not-centered">
             <div className="msn-switcher flex-centered">
     					<img className="back-nav-img" src={imgStoragePath+"back_arrow.png"} onClick={() => browserHistory.goBack()} alt="img"/>
     					<span>
                {
                  this.props.activeAsset ?
                    assetTypeFlag && this.props.activeAsset.asset_type == 1 ? 'MSN ' : this.props.activeAsset.asset_type == 2 ? 'ESN ' : this.props.activeAsset.asset_type == 3 ? 'APU ' : this.props.activeAsset.asset_type == 4 ? 'LG ' : 'PROPELLER '
                  :''
                }
                { assetTypeFlag ?
                  (this.props.activeAsset.asset_type != 1 || this.props.activeAsset.asset_type != 2) && this.props.activeAsset.serial_number ? this.props.activeAsset.serial_number.length > 4 ? '...'+ this.props.activeAsset.serial_number.substr(this.props.activeAsset.serial_number.length - 4, this.props.activeAsset.serial_number.length) :this.props.activeAsset.serial_number :
                  assetTypeFlag && this.props.activeAsset.asset_type == 1 ?
                    this.props.activeAsset.msn.length > 4 ?
                      '...'+ this.props.activeAsset.msn.substr(this.props.activeAsset.msn.length - 4, this.props.activeAsset.msn.length) :
                      this.props.activeAsset.msn
                    :
                  this.props.activeAsset.esn && this.props.activeAsset.esn.length > 4 ? '...'+ this.props.activeAsset.esn.substr(this.props.activeAsset.esn.length - 4, this.props.activeAsset.esn.length) :this.props.activeAsset.esn
                  :''
                }
              </span>
     					<span id="technical-asset-model">
                { assetTypeFlag ?
                  this.props.activeAsset.asset_type == 1 ?
                  this.props.activeAsset.aircraft_type ? this.props.activeAsset.aircraft_type.length > 11 ? this.props.activeAsset.aircraft_type.substr(0,8)+'...' : this.props.activeAsset.aircraft_type : '':
                  this.props.activeAsset.engine_type ? this.props.activeAsset.engine_type.length > 11 ? this.props.activeAsset.engine_type.substr(0,8)+'...' : this.props.activeAsset.engine_type :''
                  :''
                }
              </span>
              { assetTypeFlag ?
                this.props.activeAsset.asset_type == 1 ?
                this.props.activeAsset.aircraft_type ? this.props.activeAsset.aircraft_type.length > 11 ? <ToolTipHover placement="top" tagetId="technical-asset-model">{this.props.activeAsset.aircraft_type}</ToolTipHover> : null : null:
                this.props.activeAsset.engine_type ? this.props.activeAsset.engine_type.length > 11 ? <ToolTipHover placement="top" tagetId="technical-asset-model">{this.props.activeAsset.engine_type}</ToolTipHover> : null :null
                :null
              }
             </div>
             { SecondaryHeaderMid }
          </Col>
					<Col md="6" className="detail-block flex-not-centered">
					  { SecodaryHeaderRH }
          </Col>
			  </Row>
      </div>
        )
    }
}

const mapStateToProps = state => ({
	assetViewId: state.userAssetReducer.assetViewId,
	msnSwitcher: state.sharedReducers.msnSwitcher
});
const mapDispatchToProps = (dispatch, ownProps ) => {
	return {
		getMsnSwitcher: () => dispatch(getMsnSwitcherAc()),
		fetchAssetView: (id) => dispatch(assetViewIdAc(id, ownProps.params)),

	}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MSNSwitcherHeader));
