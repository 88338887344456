import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { checkString } from '../../../../formValidator';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class AddEditCrewSeat extends Component{
  constructor(props){
    super(props);
    this.state = {
      crewSeat: {},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.crewSeatObj));
    if(this.props.type == 'edit' || this.props.type== 'view'){
      data = {
        ...data,
        aircraft: this.props.crewSeatObj.aircraft.id,
        type_of_seat: this.props.crewSeatObj.type_of_seat.value,
        type_of_seat_name: this.props.crewSeatObj.type_of_seat.label
      }
    }
    this.setState({
      crewSeat:data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      crewSeat: {
        ...prevState.crewSeat,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }
  addEditCrewSeat = (e) => {
    e.preventDefault();

    const validateNewInput = {
      type_of_seat: checkString({value: this.state.crewSeat.type_of_seat, required: true, minLength: '', maxLength: '', message: 'Please select Seat type'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditTechSummary(this.state.crewSeat, this.props.type, 'seatingList', 'crewSeatCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { crewSeat, error } = this.state;
    const { type, techConstants } = this.props;
    if(!Object.keys(crewSeat).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditCrewSeat(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Type Of Seat
                { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Type Of Seat'
                  keyParam="type_of_seat"
                  value={crewSeat.type_of_seat}
                  type={type}
                  options={techConstants.filter(item => item.type=="crew_seat_type")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {crewSeat.type_of_seat_name}
                />
                <h6 className="error-msg">{error.type_of_seat}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={crewSeat.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Serial Number</FieldLabel>
                <FieldInput
                  value={crewSeat.serial_number}
                  type={type}
                  keyParam="serial_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Location</FieldLabel>
                  <FieldSelect
                    placeholder='Select Location'
                    keyParam="location"
                    value={crewSeat.location}
                    type={type}
                    options={techConstants.filter(item => item.type=="interior_location")}
                    updateField={(key, value) => this.updateForm(key, value)}
                    labelKey= 'label'
                    valueKey='label'
                    name = {crewSeat.location_name}
                  />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Manufacturer</FieldLabel>
                <FieldInput
                  value={crewSeat.manufacturer}
                  type={type}
                  keyParam="manufacturer"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={crewSeat.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
