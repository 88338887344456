import React, { Component } from 'react';
import { imgStoragePath } from '../../../../constants';
import { toastFlashMessage} from '../../../../utils';
import {createProject} from '../../Forms/AddFormObjects';
import {  ExportFiles } from '../../../../shared';
export default class BulkOptSchedule extends Component {
  constructor(props){
    super(props);
    this.state = {
     
    }
  }



  launchProject=()=>{
    if( this.props.selectedIds.length>0){
    let lesseNames=[];
    this.props.selectedIds.map((id,index) =>{
      lesseNames.push( this.props.programSchedulerList.filter(item => item.id==this.props.selectedIds[index])[0].lessee?this.props.programSchedulerList.filter(item => item.id==this.props.selectedIds[index])[0].lessee:'')
    })
    if(lesseNames.filter(names => names==lesseNames[0]).length==this.props.selectedIds.length){
      return <button onClick={() => this.props.toggleEditAddSide({type: 'add', flag: true, data: createProject})}  style={{marginRight:'10px'}} className="aims-primary-btn">Launch Project</button>
    }else{
      toastFlashMessage('To Launch Project, Please choose same Lessee', 'error');
      return  <button onClick={() => this.props.toggleSidebar()} className="aims-primary-btn">{this.props.addText ? this.props.addText : 'Pass Add Text'}</button>
    } 
    }else{

      return  <button onClick={() => this.props.toggleSidebar()} className="aims-primary-btn">{this.props.addText ? this.props.addText : 'Pass Add Text'}</button>
    }
  }
  
  render(){
    
  return(
    <div className={"add-bulk-operations " + (this.props.selectedIds.length ? 'selected-operations':'')}>
      <ul className="list-inline flex-centered">
        {
          this.props.selectedIds.length ?
          <li className="list-inline-item"><img onClick={this.props.cancelBulkOp} src={imgStoragePath + "blue_cross_icon.png"} style={{width: '15px', marginRight: '5px'}} /> {this.props.selectedIds.length} items selected</li>:null
        }

       { <li className="list-inline-item operation-cta">
         <ExportFiles exportFile={(fileType) => this.props.assetExport('scheduler',fileType)} files={[{img:'xls_icon.png', label: 'XLS', type:'xls'}]} />
      </li>}
        {/*
          this.props.selectedIds.length ?
          <li className="list-inline-item operation-cta" onClick={this.props.bulkDelete}> <img src={imgStoragePath + 'blue_delete_icon.png'} style={{width: '13px', marginRight: '5px'}} />Delete</li>:null
        */}

      </ul>
      {
        this.launchProject()
      }
    </div>
  )
}

}

