import React from 'react';
const ContractHdEl = (props) => {
  return(
    <li className="list-inline-item">
      <h6 className="caption-ui">{props.title}</h6>
      {props.children}
    </li>
  )
}
export default ContractHdEl;
