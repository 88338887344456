import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
export default class BankingDetail extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { bank, editBankingMode, deleteBankingMode, viewBankingMode } = this.props
        return (
            <Col md="3" style={{ cursor: 'default' }}>
                <div className="folder-card owner-card">
                    <div className="folder-info owner-info">
                        <span style={{ float: 'right' }}>
                            <img src={imgStoragePath + 'blue_eye_icon.png'} onClick={() => viewBankingMode(bank)} style={{ width: '18px', marginRight: '5px', cursor:'pointer' }} />
                            {
                              bank.status !== 0 ?
                                (getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['banking_details'].indexOf('U') != -1) || (bank.status === 2 && getLocalStorageInfo().user.id === bank.created_by) || (getLocalStorageInfo().user.is_root_user || getLocalStorageInfo().user.is_super_admin) ?
                                    <img src={imgStoragePath + 'icon_editblock.png'} onClick={() => editBankingMode(bank)} style={{ width: '14px', marginRight: '5px', cursor: 'pointer' }} /> : null:null
                            }
                            {
                              bank.status !== 0 ?
                                (getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['banking_details'].indexOf('D') != -1) || (bank.status === 2 && getLocalStorageInfo().user.id === bank.created_by) || (getLocalStorageInfo().user.is_root_user || getLocalStorageInfo().user.is_super_admin) ?
                                    <img color="danger" src={imgStoragePath + 'cross_red.png'} onClick={() => deleteBankingMode(bank)} style={{ width: '15px', cursor: 'pointer' }} /> : null:null
                            }
                        </span>
                        <div className="folder-info owner-info">
                            <h4 style={{display:'none'}}>Lessor</h4>
                            <p style={{ color: '#9439e4', fontSize:'15px', fontWeight: '600' }}>{bank.lessor.name ? bank.lessor.name : '--'}</p>
                            <h4 >Bank Name</h4>
                            <p>{bank.name ? bank.name : '--'}</p>
                            <h4 >Account Holder Name</h4>
                            <p>{bank.account_name ? bank.account_name : '--'}</p>
                            <h4 >Account Number</h4>
                            <p>{bank.account_number ? bank.account_number : '--'}</p>
                            <h4 >SWIFT Code</h4>
                            <p>{bank.swift_code ? bank.swift_code : '--'}</p>
                            <h4 >Status</h4>
                            <p>{bank.status == 0 ? 'Pending': bank.status == 1 ? 'Approved' : bank.status == 2 ? "Rejected" :''}</p>
                        </div>
                        {
                          (getLocalStorageInfo().user.is_super_admin || getLocalStorageInfo().user.is_root_user) && bank.status === 0 ?
                          <div>
                            <button className="primary-btn" onClick={() => this.props.approveBanking()}>Approve</button>
                            <button className="primary-btn" onClick={() => this.props.rejectBanking()} style={{background:'#ff5e5e',border: '1px solid #ff5e5e',marginLeft: '10px'}}>Reject</button>
                          </div>
                          :null
                        }
                    </div>
                </div>
            </Col>
        )
    }
}
