import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteModal, EditFormBar, SecondaryTechHeaderRH, SecondaryTechHeaderMid, FormHeader, DownloadedLogs } from '../../../Elements';
import AddEditContact from '../components/AddEditContact';
import { browserHistory } from 'react-router';
import { contactListAc, contactAddEditAc, contactViewCrudAc, deleteContactAc, contactListExportAc } from '../actionCreators';
import { imgStoragePath } from '../../../../../constants';
import { TableUIComp, ExportFiles, MSNSwitcherHeader, ListLoader } from '../../../../../shared';
import List from '../components/List';
import { trackActivity } from '../../../../../utils/mixpanel';
import BulkOperation from '../components/BulkOperation';
import { UPDATE_SELECTED_IDS, PAGINATION_INFO } from '../actions';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';

let param = {}
class InspectionFormT006 extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalTitle: '',
      modal: false,
      delModal: false,
      queryParams: {},
      deleteType: '',
      deleteId: [],
      downloadFormLogs: false
    }
  }
  componentDidMount(){
    let location = browserHistory.getCurrentLocation();
     this.props.fetchContactList(location.query);
     this.props.getFormDetails(1, 'T006');
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggleDelModal = (deleteType = null) => {
    this.setState(prevState =>({
      ...prevState,
      delModal: !prevState.delModal,
      modalTitle: 'Are you sure to delete?',
      deleteType: deleteType
    }));
  }
  sortTable = (sortKey) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
        query: {
          ...location.query,
          sort: sortKey,
          page:1,
          per_page: this.props.pagination.per_page,
          sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
        }
      }

    browserHistory.push(location);
    this.props.fetchContactList(location.query);
  }
  rowActivity = (type, item) => {
    if(type == 'view' || type == 'edit'){
        this.props.toggleEditAddSide({type: type, flag: true, data: item})
    }else if(type == 'delete'){
      this.setState(prevState => ({
        ...prevState,
        deleteId: [item.id]
      }));
      this.toggleDelModal('single');
    }
  }
  deleteContact = () => {
    let deleteArr = [];
    if(this.state.deleteType == 'single'){
      deleteArr = this.state.deleteId
    }else{
      deleteArr = this.props.selectedIds

    }
    this.props.deleteContact(deleteArr, this.state.deleteType);
    this.toggleDelModal();
  }
  changePerPage=(count) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'per_page': count,
          'page': 1
      }
    }
    browserHistory.push(location);
    this.props.fetchContactList(location.query);

  }
  changePagination = (type, currentPage) => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      query: {
        ...location.query,
        'page': currentPage
      }
    }
    browserHistory.push(location);
    this.props.fetchContactList(location.query);
  }
  changeFilterFn = (filterParams) => {
    this.props.fetchContactList(filterParams);
  }

  render(){
    const { contactList, contactCrud, selectedIds, pagination, contactLoader, formInfo, formStatusLoader } = this.props;
    const menuList = {
      'Name': {
        'inputType': 'text',
        'keyParam': 'name',
        'placeholder': 'Search by name',
        'label': 'Name'
      },
      'Email': {
        'inputType': 'text',
        'keyParam': 'email',
        'placeholder': 'Search by email',
        'label': 'Email'
      }
    }
    return(
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.contactListExport('MSN_'+formInfo.asset.msn+'_'+formInfo.current_form.name,fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />
        { contactLoader || formStatusLoader ? <ListLoader drawer="primary-nav" />:null }
        <div className="form-t006-cn">
          <TableUIComp
            theads={[{label:'Name', sortKey: 'name'}, {label:'Email', sortKey: 'email'}, {label:'Designation', sortKey: ''},{label:'Phone Number', sortKey: ''}, {label:'Fax', sortKey: ''}, {label:'', sortKey: ''}]}
            bulkOperation={ formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? true:false}
            content={contactList.map((item, index) => <List formInfo={formInfo} key={index} selectedIds={selectedIds} data={item} rowActivity={(type) => this.rowActivity(type, item)} selectItem={(event) => this.props.selectItem(event.target.checked, item.id)} />)}
            tableBulkOp = { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? <BulkOperation addText= "+ Add Contact" selectedIds={selectedIds} toggleSidebar= {() => this.props.toggleEditAddSide({type: 'add', flag: true, data: {}})} bulkDelete = {() => this.toggleDelModal('bulk')}  cancelBulkOp={() => this.props.selectAllItem(false)} />:null}
            pagination = {pagination}
            filter={{menuList: menuList, appliedFilter: {}, displayFilter:true}}
            selectAllItem={(e) => this.props.selectAllItem(e.target.checked, 'all')}
            sortTable={this.sortTable}
            changePerPage ={this.changePerPage}
            changePagination = {this.changePagination}
            closeFilter = {this.closeFilter}
            checkedAll={ contactList.length > 0 ? selectedIds.length == contactList.length ? true:false : false}
            searchQuery = {browserHistory.getCurrentLocation().query}
            changeFilterFn={this.changeFilterFn}
            recordsFound = { contactLoader ? false : contactList.length ? false:true}
            queryParamsEnabled={true} />
        </div>
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} className="modal-tform-delete-pictures modal-dialog-centered">
          <h5 style={{textAlign:"center"}}>{this.state.modalTitle} </h5>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deleteContact('')}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
              </li>
            </ul>
        </DeleteModal>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {contactCrud.type +' Contact'} </h3></div>}
          toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
          isOpen={contactCrud.flag}
          style={contactCrud.flag ? {width: '400px'} : {width: '0px'}}
          >
          {contactCrud.flag ?
            <AddEditContact
              contact={contactCrud}
              toggleEditAddSide={this.props.toggleEditAddSide}
              updateSideBar= {this.props.updateSideBar}
              toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
              contactLoader={contactLoader}
              />
            :null
          }
        </EditFormBar>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  contactList:state.FormT006Reducer.contactList,
  contactCrud:state.FormT006Reducer.contactCrud,
  selectedIds:state.FormT006Reducer.selectedIds,
  pagination: state.FormT006Reducer.pagination,
  contactLoader: state.FormT006Reducer.contactLoader,
  formInfo: state.FormT001Reducer.formInfo,
  formStatusLoader: state.FormT001Reducer.formStatusLoader
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchContactList: (qeryParam = {}) => dispatch(contactListAc(ownProps, qeryParam)),
    toggleEditAddSide:(contactCrud) => dispatch(contactViewCrudAc(ownProps, contactCrud)),
    updateSideBar: (data, type) => dispatch(contactAddEditAc(ownProps, data, type)),
    deleteContact: (data, type) => dispatch(deleteContactAc(ownProps, data, type)),
    contactListExport: (name,fileType) => dispatch(contactListExportAc(ownProps, fileType,name)),
    selectItem: (flag, id) => dispatch({
      type: UPDATE_SELECTED_IDS,
      payload: {flag, id, type: 'single'}
    }),
    selectAllItem: (flag) => dispatch({
      type: UPDATE_SELECTED_IDS,
      payload: {flag, type: 'all'}
    }),
    changePageInfo: (obj) => ({
      type: PAGINATION_INFO,
      payload: obj
    }),
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT006);
