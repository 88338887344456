
import { globalGetService, globalPostService, globalPutService, globalExportService, globalDownloadService, globalFileUploadService, globalDeleteService } from '../../../globalServices';
import {toastFlashMessage, downloadFileType, getLocalStorageInfo } from '../../../utils';
import { checkString } from '../../../formValidator';
const generateLLPUrl = (props={}, assemblyType='', assemblySlug='') => {
  if(props.params.type === 'aircraft'){
    return `console/aircraft/${props.params.aircraft_slug}/${assemblyType}/${assemblySlug}/llp/`
  }else{
    return `console/${assemblyType}/${assemblySlug}/llp/`
  }
}
export function deleteGenericLLPApi(props={}, assemblyType='', assemblySlug='',llpId=''){
  globalDeleteService(`${generateLLPUrl(props, assemblyType, assemblySlug)}${llpId}/`)
  .then(response => {

    if(response.data.statusCode === 200){
      this.setState(prevState => ({
        ...prevState,
        llp: prevState.llp.filter((item, index) => item.id != llpId),
        llpToggleDelete:false,
        deleteLLPId:''
      }))
      toastFlashMessage(response.data.message, 'success')
    }else{
      toastFlashMessage(response.data.message, 'error')
    }
  })
}
export function getGenericLLPApi(props, assemblyType, assemblySlug){
  globalGetService(generateLLPUrl(props, assemblyType, assemblySlug))
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState(prevState => ({
        ...prevState,
        llp: response.data.data
      }))
    }
    }
  )
}
export function addGenericLLPApi(props, assemblyType, assemblySlug, data){
  globalPostService(generateLLPUrl(props, assemblyType, assemblySlug), data)
  .then(response => {
    if(response.data.statusCode === 200){
      this.setState(prevState => ({
        ...prevState,
        llp: [response.data.data, ...prevState.llp],
        toggleLLP:false,
        llpCrud:{}
      }))
      toastFlashMessage(response.data.message, 'success')
    }else{
      toastFlashMessage(response.data.message, 'error')
    }
  })
}
export function editGenericLLPsApi(props={}, assemblyType='', assemblySlug='', data={}){
  globalPutService(generateLLPUrl(props, assemblyType, assemblySlug), data)
  .then(response => {
    if(response.data.statusCode === 200){
      this.setState(prevState => ({
        ...prevState,
        llp:response.data.data,
        llpState: !prevState.llpState,
        prevLLPs:[]
      }))
      toastFlashMessage(response.data.message, 'success');
    }else{
      toastFlashMessage(response.data.message, 'error');
    }
  })
}
export function exportGenericLLPsApi(props={}, assemblyType='', assemblySlug='', assembly){
  globalExportService(`console/${assemblyType}/${assemblySlug}/llp/export/`, {download:'xls'})
  .then(response => {
    downloadFileType(response, `${assemblyType.toUpperCase()}_${assembly.serial_number}_${getLocalStorageInfo().defaultLessor.name}_LLP.`, 'xlsx')
  })
}
export function importGenericLLPsApi(props={}, assemblyType='', assemblySlug='', file){
  if(['xls', 'xlsx'].includes(file[0].name.split('.').reverse()[0].toLowerCase())){
    let formData = new FormData()
    formData.append('file', file[0]);
    this.setState({pageLoader:true});
    globalFileUploadService(`console/${assemblyType}/${assemblySlug}/llp/import/`, formData)
    .then(response => {
      this.setState({pageLoader:false});
      if(response.data.statusCode === 200){
        toastFlashMessage(response.data.message, 'success');
        this.setState(prevState => ({
          ...prevState,
          llp:response.data.data
        }))
      }
    })
  }else {
    toastFlashMessage('Please use file generated by clicking on Export, i.e xls or xlsx.', 'error')
  }
}
export function getSTCModsListApi(props={}){
  this.setState({pageLoader:true});
  globalGetService(`console/aircraft/${props.params.aircraft_slug}/stc-mod/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(response.data.statusCode == 200){
      this.setState({
        stcModsList:response.data.data
      })
    }
  })
}
export function getSTCModApi(props={}, id){
  this.setState({pageLoader:true});
  globalGetService(`console/aircraft/${props.params.aircraft_slug}/stc-mod/${id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(response.data.statusCode == 200){
      let resData = Object.assign({}, response.data.data);
      resData = {
        ...resData,
        stc_compliance_status: resData.stc_compliance_status && resData.stc_compliance_status.value ? resData.stc_compliance_status.value:null
      }
      this.setState({
        stcModCrud:resData,
        error:{},
        modal:true,
        mode:'Edit'
      });
    }
  })
}
export function addEditSTCModApi(props={}, data){
  let validateNewInput = {
    description:checkString({value: data.description, required:true, minLength:"", maxLength:"", message:"Please enter Description"}),
  };
  if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
    this.setState({pageLoader:true});
    if(data.id){
      globalPutService(`console/aircraft/${props.params.aircraft_slug}/stc-mod/${data.id}/`, data)
      .then(response => {
        this.setState({pageLoader:false});
        if(response.data.statusCode == 200){
          this.setState(prevState => ({
            ...prevState,
            stcModsList: prevState.stcModsList.map(item => item.id == data.id ? response.data.data:item),
            modal:false,
            mode:'',
            stcModCrud:{},
            error:{}
          }));
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }else{
      globalPostService(`console/aircraft/${props.params.aircraft_slug}/stc-mod/`, data)
      .then(response => {
        this.setState({pageLoader:false});
        if(response.data.statusCode == 200){
          this.setState(prevState => ({
            ...prevState,
            stcModsList:[response.data.data, ...prevState.stcModsList],
            modal:false,
            mode:'',
            stcModCrud:{},
            error:{}
          }));
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }
  }else{
    this.setState({error:validateNewInput})
  }
}
export function deleteSTCModApi(props={}, data){
  this.setState({pageLoader:true});
  globalDeleteService(`console/aircraft/${props.params.aircraft_slug}/stc-mod/${data.id}/`)
  .then(response => {
    this.setState({pageLoader:false});
    if(response.data.statusCode == 200){
      this.setState(prevState => ({
        ...prevState,
        stcModsList: prevState.stcModsList.filter(item => item.id !== data.id),
        deleteStcMod: {flag: false, stcMod: {}}
      }));
      toastFlashMessage(response.data.message, 'success');
    }
  })
}
export function utilizationImportApi(props={}, assembly, slug, file){
  if(file[0]){
    let formData = new FormData()
    formData.append('file', file[0]);
    this.setState({pageLoader:true})
    globalPostService(`console/${assembly}/${slug}/utilizations/import/`, formData)
    .then(response => {
      this.setState({pageLoader:false})
      if(response.data.statusCode == 200){
        this.setState({
          utilizationsModal:true,
          importedData: response.data.data.list,
          postBody:response.data.data,
          responseMessage: response.data.message
        })

      }else {
        toastFlashMessage(response.data.message, 'error');
      }
    })
  }

}
export function updateUtilizationApi(props = {}, assembly, slug, data){
  this.setState({pageLoader:true})
  globalPostService(`console/${assembly}/${slug}/utilizations/update/`, data)
  .then(response => {
    this.setState({pageLoader:false})
    if(response.data.statusCode == 200){
      toastFlashMessage(response.data.message, 'success')
      this.setState({utilizationsModal:false})
      setTimeout(() => {
        window.location.reload()
      }, 1000)

    }else {
      toastFlashMessage(response.data.message, 'error')
    }
  })
}
export function exportUtilzationApi(props = {}, assembly, slug, utilization) {
  this.setState({pageLoader:true})
  globalExportService(`console/${assembly}/${slug}/utilizations/sample-download/`)
  .then(response => {
    this.setState({pageLoader:false})
    if(assembly.includes('aircraft')){
      downloadFileType(response, `MSN-${utilization.msn}-Utilization-Import`, '.xls')
    }
    if(assembly.includes('engine')){
      downloadFileType(response, `ESN-${utilization.esn}-Utilization-Import`, '.xls')
    }
    if(assembly.includes('apu')){
      downloadFileType(response, `APU-${utilization.serial_number}-Utilization-Import`, '.xls')
    }
    if(assembly.includes('lg')){
      downloadFileType(response, `LG-${utilization.serial_number}-Utilization-Import`, '.xls')
    }
  })
}
