
import React, { Component } from 'react';
import { Col } from 'reactstrap';
export default class ContrTextareaField extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { md, label, keyParam, value, error, type } = this.props;
    return(
      <Col md={md ? md:'12'}>
        <div className="form-group">
          <label className="label">{this.props.label}</label>
          {
            type != 'view' ?
            <textarea value={value}
              onChange={(e) => this.props.updateField(keyParam, e.target.value)}
              >
            </textarea>
            :
            <h6>{value ? value : '--'}</h6>
          }
          {this.props.error ? <h6 className="error-msg">{error}</h6>:null}
        </div>
      </Col>
    )
  }
}
