import React, {Component} from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter, Link } from 'react-router';
import { APUtab, AddEditAPU, OverViewUtilization,  MjrAssShopVisitHeader, MjrAssShopVisitDetail, GenericAssemblyLLPs, AddLLP } from '../components';
import { fetchTechSummaryListAc, addEditTechSummaryAc,techSummaryCrudAc, deleteMsnAssetAc, editApuAc, exportApuAc, importApuLLPsAc, deleteApuLLPsAc } from '../actionCreators';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { EditSidebar, FieldSelect, FieldCol, FieldGroup, FieldLabel } from '../../../contracts/Elements';
import { getApuTypesAc } from '../../../../User/actionCreators'
import { TECH_SMRY_CRUD } from '../actions';
import { sManufacturerAc } from '../../../../shared/actionCreators';
import { NoRecordFound  } from '../../../../shared';
import { addApu } from '../createTechSummary';
import { Row, UncontrolledTooltip, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { getLocalStorageInfo } from '../../../../utils';
import { globalGetService, globalPostService } from '../../../../globalServices';
import { DeleteModal, EditFormBar } from '../../../technicalInspection/Elements';
import { CtrTableHd } from '../../../contracts/Elements/';
import {  imgStoragePath } from '../../../../constants';
class APUContent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      activeTab: 1,
			engines: [],
      toggleLLP: false,
			linkEnigne: {
				flag: false,
				engine: {
					stand_alone_link: true,
					stand_alone_asset_id: null,
				}
			},
      deleteEngine:{
				flag: false,
				engine: {}
			}
    }
  }

  toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

  getEnignesList = () =>{
    globalGetService(`console/stand-alone-asset/`, {asset_type: 'apu',aircraft_slug: this.props.asset_slug})
    .then(response => {
      if(response.data.statusCode === 200){
        this.setState({
          engines: response.data.data.apu
        })
      }
    })
  }

  linkEnigne = () =>{
    if(this.state.linkEnigne.engine.stand_alone_asset_id === null || this.state.linkEnigne.engine.stand_alone_asset_id === ""){
      this.setState({error: 'Please select APU'})
    }
    setTimeout(() => {
			if(this.state.error != '' || this.state.positionError != ''){
				return
			}else{
        setTimeout(() => {
          globalPostService(`console/aircraft/${this.props.asset_slug}/apu/`, this.state.linkEnigne.engine)
          .then(response => {
            if(response.data.statusCode === 200){
              this.setState({linkEnigne: {flag: false, engine: {
                stand_alone_link: true,
                stand_alone_asset_id: null,
              }}})
              this.getEnignesList()
              this.props.fetchTechSummaryList(this.props.asset_slug, 'apuList');
            }
          })
        }, 500)
			}
		}, 300)
  }

  updateEngineField = (key, value) => {
    this.setState({
      error: '',
      positionError: ''
    })
    this.setState({
      linkEnigne:{
        ...this.state.linkEnigne,
        engine: {...this.state.linkEnigne.engine, [key]: value}
      }
    })
  }

  deleteMsnEngine = () =>{
		this.props.deleteMsnEngine(this.state.deleteEngine.engine.id)
		this.setState({
			deleteEngine: {flag: false, engine:{}}
		})
	}
  updateForm=()=>{

  }

  componentDidMount() {
    if(!this.props.apucraftManufacturerList.length){
			this.props.fetchManufacturer('apu','apucraftManufacturerList');
		}
    this.props.getApuTypes()
    this.getEnignesList()
		this.props.fetchTechSummaryList(this.props.asset_slug, 'apuList');
    this.props.getOwnerList()
  }
  render(){
    const { apuTypesList, apuList, apuCrud, apucraftManufacturerList, overviewTechInfo, techSpecsLoader, techConstants, editApu, ownerList } = this.props;

    var showTabs = false
		let lessorIds = []
		 if(window.location.href.includes('qa')){
			 lessorIds = [241, 86]
		 }else if(window.location.href.includes('dev')){
			 lessorIds = [289,89]
		 }else{
			 lessorIds = [86]
		 }
		if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
			showTabs = true
		}
    return(
      <div>
        {
          getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['apu'].indexOf('C') != -1 ?
          <p style={{overflow: 'hidden'}}>
            <span style={{float: 'right'}}>
            {
 					 showTabs ?
            <button style={{marginRight: '20px'}} className="btn btn-primary add-new" onClick={() => this.setState({linkEnigne: {flag: true, engine: {stand_alone_link: true,
            stand_alone_asset_id: null,
            is_fitted: true,
            is_titled: false,
            position: 1}}, error: '', positionError: ''})}> + Link APU
            </button>:null
 				    }
          {!showTabs && <button style={{float: 'right'}} className="btn btn-primary add-new" onClick={() => this.props.techSummaryCrud('add', {}, 'apuCrud',addApu)}> + Add APU</button>}
          </span>
          </p>: null
        }
          {apuList.length ?
            <Nav tabs>
              {apuList.map((apu, index) =>
                <NavItem key={index}>
                  <NavLink
                  className={classnames({ active: this.state.activeTab === (parseInt(index)+1) })}
                  onClick={() => { this.toggle((parseInt(index)+1)); }}
                  > APU {apu.position ? '#' + apu.position.value : '' } ( {(apu.serial_number ? apu.serial_number : null )} )
                  <p>
                    {apu.is_titled ?
                      <label className="titled-fitted">Titled</label>:
                        apu.is_fitted ?
                      <label className="titled-fitted">Fitted</label>:null
                    }
                  </p>
                  </NavLink>
              </NavItem>
              )}
            </Nav>:null
          }
        <TabContent activeTab={this.state.activeTab}>
          {apuList.map((apu, index) =>{
            return(
              <TabPane key={parseInt(index)} tabId = {parseInt(index)+ 1}>
                <div>
                  <APUtab techConstants={techConstants} deleteMsnEngine={() => this.setState({deleteEngine: {flag: true, engine: apu}})} apu={apu} showTabs={showTabs} apuTypesList={apuTypesList} index={index} asset_slug={this.props.asset_slug}  viewApuSummery={this.props.techSummaryCrud} />
                  <OverViewUtilization index={index} utilization={apu} />
                  <GenericAssemblyLLPs
                    llp={apu.llps}
                    heading='apu'
                    assemblyType='apu'
                    assemblySlug={apu.slug}
                    assembly={apu}
                  />
                  <div className="contr-table-blk">
                    <h5 style={{ borderBottom: '0'}}>Shop Visits History</h5>
                    <div className="mobile-table-format">
                      <MjrAssShopVisitHeader theads = {[{ label: 'For Shop Visit / Shop Visit Date', size: 2 }, { label: 'Shop Visit Cost', size: 2}, { label: 'Shop Visit Facility/ Location', size: 2}, { label: 'TSN at Shop Visit', size: 2}, { label: 'CSN at Shop Visit', size: 2},  {label: 'Status', size: 2}]}/>
                        { Object.keys(apu.shopvisit).length ? apu.shopvisit.map((item,index) => <MjrAssShopVisitDetail shopvisit = {item} key={index}/> ) : null }
                   </div>
                      {!techSpecsLoader && !Object.keys(apu.shopvisit).length ?
                        <NoRecordFound description="" /> :  null
                      }
                  </div>
                </div>
              </TabPane>
            )
          }

          )}
        </TabContent>
        {!techSpecsLoader && !apuList.length ?
          <NoRecordFound description="Get Started by clicking '+ Add APU' button above" /> :  null
				}
        <EditSidebar title={apuCrud.type == 'add' ? 'Add APU' : apuCrud.type == 'edit' ? 'Edit APU' : 'View APU'}  width="800px" right= "-800px" editSideBar={apuCrud.flag} toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'apuCrud')}>
					{Object.keys(apuCrud.data).length && apuCrud.flag ?
            <AddEditAPU
              asset_slug={this.props.asset_slug}
              type={apuCrud.type}
              addEditTechSummary={this.props.addEditTechSummary}
              apuSummeryObj={apuCrud.data}
              manufacturerList={apucraftManufacturerList}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'apuCrud')}
              techConstants={techConstants}
              overview={overviewTechInfo}
              apuTypesList={apuTypesList}
              showAdvanced={showTabs}
              ownerList={ownerList}
              />:null
          }
				</EditSidebar>
        <DeleteModal isOpen={this.state.linkEnigne.flag} toggle={() => this.setState({linkEnigne: {flag: false, engine: {stand_alone_link: true,
        stand_alone_asset_id: null,
        position: 1}}})} title="Link APU" className="modal-tform-delete-pictures">
          <div>
          <Row>
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">APU</FieldLabel>
                <FieldSelect
                  placeholder='Select APU'
                  keyParam="stand_alone_asset_id"
                  value={this.state.linkEnigne.engine.stand_alone_asset_id}
                  type='edit'
                  options={this.state.engines}
                  updateField={this.updateEngineField}
                  labelKey= 'serial_number'
                  valueKey='id'
                />
                <h6 className="error-msg">{this.state.error}</h6>
              </FieldGroup>
             </FieldCol>
             <FieldCol md="6">
             <div className="form-group" style={{display: "block",margin: "25px 0px"}}>
               <label className="label">
                 <input
                   type="checkbox"
                   checked={this.state.linkEnigne.engine.is_fitted}
                   onChange={(e) =>{ this.updateEngineField('is_fitted', e.target.checked); setTimeout(() => this.updateEngineField('is_titled', false), 300)}}
                 />
                 <span>
                   Is APU fitted on this MSN ?
                   <i class="fa fa-info-circle" aria-hidden="true" id="engine-field2" style={{paddingLeft: '4px'}}></i>
                   <UncontrolledTooltip placement="right" target="engine-field2">
                     Tick if the major assembly is temporarily installed
                   </UncontrolledTooltip>
                 </span>
               </label>

             </div>
                </FieldCol>
             <FieldCol md="6">
             <div className="form-group" style={{display: "block",margin: "25px 0px"}}>
               <label className="label">
                 <input
                   type="checkbox"
                   checked={this.state.linkEnigne.engine.is_titled}
                   onChange={(e) =>{ this.updateEngineField('is_titled', e.target.checked);  setTimeout(() => this.updateEngineField('is_fitted', false), 300)}}
                 />
                 <span>
                   Is APU titled on this MSN ?
                   <i class="fa fa-info-circle" aria-hidden="true" id="engine-field3" style={{paddingLeft: '4px'}}></i>
                   <UncontrolledTooltip placement="right" target="engine-field3">
                       Tick if the major assembly is Titled
                   </UncontrolledTooltip>
                 </span>
               </label>

             </div>
                </FieldCol>
          </Row>
          </div>
          <ul className="list-inline" style={{textAlign: 'right'}}>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.linkEnigne()}>Add</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-secondary" onClick={() => this.setState({linkEnigne: {flag: false, engine: {
                  stand_alone_link: true,
                  stand_alone_asset_id: null,
                  position: 1
                }}})}>Cancel</button>
              </li>
          </ul>
          </DeleteModal>
          <DeleteModal isOpen={this.state.deleteEngine.flag} toggle={() => this.setState({deleteEngine: {flag: false, engine: {}}})} title={`Remove APU ${this.state.deleteEngine.engine.serial_number}`} className="modal-tform-delete-pictures">
					<ul className="list-inline" style={{textAlign: 'right'}}>
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.deleteMsnEngine()}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-secondary" onClick={() => this.setState({deleteEngine: {flag: false, engine: {}}})}>Cancel</button>
              </li>
          </ul>
					</DeleteModal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  apucraftManufacturerList: state.sharedReducers.apucraftManufacturerList,
  apuList: state.TechSummaryReducer.apuList,
  apuCrud: state.TechSummaryReducer.apuCrud,
  techConstants: state.sharedReducers.techConstants,
  apuTypesList: state.userAssetReducer.apuTypesList,
  llpToggleState: state.TechSummaryReducer.llpToggleState,
  ownerList:state.confReducer.ownerDropdownList
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchManufacturer: (group_type, reducerType) => dispatch(sManufacturerAc(group_type, reducerType)),
    getOwnerList:() => dispatch(getOwnerDropdownListAc()),
    deleteMsnEngine: (engineId) => dispatch(deleteMsnAssetAc(ownProps.params,engineId, 'apu')),
    fetchTechSummaryList: (asset_slug, type) => dispatch(fetchTechSummaryListAc(asset_slug, type)),
    addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
    techSummaryCrud: (type, id, actionType,dataObject,index) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject,index)),
    editApu: () => dispatch(editApuAc(ownProps.params, )),
    exportLLPs:(apu_slug) => dispatch(exportApuAc(ownProps.params, apu_slug)),
    importLLPs:(file, apu_slug)=> dispatch(importApuLLPsAc(ownProps.params, file, apu_slug)),
    toggleEditBar: (data, actionType) => dispatch({
      type:TECH_SMRY_CRUD,
      payload:data,
      actionType
    }),
    getApuTypes: () => dispatch(getApuTypesAc()),
    deleteLLP:(apu_slug,id)=> dispatch(deleteApuLLPsAc(ownProps.params,apu_slug,id))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(APUContent));
