import React, { Component } from 'react';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
import { ContrListCol } from '../../Elements';

export default class PartiesHeading extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { party } = this.props;
    const partyViewObj = [
      { value: party.name ? party.name : '--' },
      { value: party.role && party.role.label ? party.role.label : '--' },
      { value: party.contact_address ? party.contact_address : '--' },
      { value: party.concerned_person_name ? party.concerned_person_name : '--' },
      { value: party.start_date ? moment(party.start_date).format(displayDateFormat) : '--' },
      { value: party.end_date ? moment(party.end_date).format(displayDateFormat) : '--' }
    ];
    return (
      <tr onClick={this.props.viewParty}>
        {partyViewObj.map((party,index) => <ContrListCol key={index} colObj={party}  />)}
      </tr>
    )
  }
}
