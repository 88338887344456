import * as actions from '../actions';
const crud = {
  type: '',
  flag: false,
  data: {}
}
export default function( state = {
  aircraftConfig : crud,
  lopaConfig: crud,
  techSpecConfig: crud,
  detailConfig: crud,
  seatConfig: {},
  seatQuestions: {},
  seatingLoader: false,
  technicalData: [],
  seatarr: [],
  windowArr: [],
  findingArr : [],
  existingFinding: {},
  paxSeatingLoader: false,
  seatStatus: 1
}, action ){
  switch (action.type) {
    case actions.PAX_SEATING_LOADER:
    return {
      ...state,
      paxSeatingLoader: action.payload
    }
    case actions.ADD_CONFIG:
     return {
       ...state,
       aircraftConfig: action.payload,
      //  seatConfig: action.payload.data
     } 
     case actions.UPDATE_CONFIG:
     return {
       ...state,
       aircraftConfig: action.payload,
       seatConfig: action.payload.data
     } 
    case actions.GET_CONFIG:{
      return{
        ...state,
        seatConfig: action.payload
      }
    } 
    case actions.SEATING_LOADER:
    return {
      ...state,
      seatingLoader: action.payload
    }
    case actions.TOGGLE_LOPA_SIDEBAR:
    return {
      ...state,
      lopaConfig: action.payload
    } 
    case actions.TOGGLE_TECH_SIDEBAR:
    return {
      ...state,
      technicalData : action.payload
    }
    case actions.TOGGLE_DETAIL_SIDEBAR:
    return{
      ...state,
      seatQuestions: action.payload,
      // seatArr: [],
      // findingArr: []
    } 
    case actions.UPDATE_TECH_SIDEBAR: 
    return {
      ...state,
      technicalData : action.payload
    }
    case actions.SEATS_ARR:
    let checkExisting = state.seatarr.filter(seatData => seatData.seat_number == action.payload.seat_number);    
    return {
      ...state,
      seatarr : checkExisting.length  ? 
        [...state.seatarr].filter(seats => seats.seat_number != action.payload.seat_number)
      : [ action.payload, ...state.seatarr]
    }
    case actions.FINDING_ARR:
    let checkExistingFinding = state.findingArr.filter(findingData => findingData.id == action.payload.id) ;    
  
    return {
      ...state,
      findingArr : checkExistingFinding.length  ? 
        [...state.findingArr].filter(finding => finding.id != action.payload.id)
      : [ action.payload, ...state.findingArr]
    }
    case actions.UPDATE_WINDOW_FINDING: 
  
    let checkExistingWindow = state.windowArr.filter(seatData => seatData.window_number == action.payload.window_number);    
    return {
      ...state,
      windowArr : checkExistingWindow.length  ? 
        [...state.windowArr].filter(seats => seats.window_number != action.payload.window_number)
      : [ action.payload, ...state.windowArr]
    }
    case actions.ALL_EXISTING_FINDINGS:
      return {
        ...state,
        existingFinding: action.payload
      }
    case actions.FINDING_ARR_UPDATE_AC:
      return  {
        ...state,
        findingArr:  action.payload.findings ,
        seatStatus:  action.payload.status
      }
   case actions.SEAT_ARR_UPDATE_AC:
      return  {
        ...state,
        seatarr: action.payload
    }
   case actions.CLEAR_ARRAYS: 
      return {
        ...state,
        seatarr: [],
        windowArr: [],
        findingArr: []
      }
    case actions.WINDOW_ARR_UPDATE_AC:
      return {
        ...state,
        windowArr: action.payload
      }
    default:
      return state;
  }
}
