import React from 'react';
import { Link } from 'react-router';
import { Container, Row, Col} from 'reactstrap';
import { imgStoragePath } from '../../constants';

const ManageInvestment = (props) => {
    return(
        <div className="manage-investment-block ">
            <Container>
               <div className="manage-card">
                    <Row>
                        <Col lg="6">
                            <img src={imgStoragePath+'landing/desk-top.png'} />
                        </Col>
                        <Col lg="6">
                            <h2>{props.title}</h2>
                            <ul>
                                {
                                    props.data.map((val, key) => {
                                        return(
                                            <li key={key}>{val}</li>
                                        )
                                    })
                                }
                                 
                                {/* <li>Provides a fully audible records trail</li> 
                                <li>Offers fleet cashflow forecasting</li> 
                                <li>Enables the fast mobilisation of multiple assets for transition i.e Asset Backed Securities(ABS)transactions</li> 
                                <li>Thereby enabling banks to capitalise on market trends and optimise asset value</li> 
                                <li>Monitors operators(airlines) globally highlighting possible risk</li>
                                <li>Mitigates risk through the accurate and AI enhanced management of assets across the asset investment lifecycle</li> */}
                            </ul>
                        </Col>
                    </Row>
               </div>
            </Container>
        </div>
    )
}

export default ManageInvestment;