import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { ContrInputField, ContrTextareaField } from '../../Elements';
import NumericLabel from 'react-pretty-numbers';
import { utilizationFormat } from '../../../../constants';
import { toastFlashMessage } from '../../../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea,  FieldInput } from '../../Elements';
import { checkNumber } from '../../../../formValidator';
import EditUitlField from './EditUitlField';
export default class EditUtils extends Component{
  constructor(props){
    super(props);
    this.state = {
      utilsEdit: {},
      error:{}
    }
  }
  componentDidMount(){
    this.setState({
      utilsEdit:this.props.utilsObj
    })
  }
  roundBy60 = (val1, val2, type, name) => {
    let number;
    let floatNumber;
    if(type == 'add'){
      // if(name.includes('APU') || name.includes('apu')){
      //   number = (parseFloat(val1) + parseFloat(val2)).toFixed(2)
      // }else{
      //   number = Math.floor(val1) + Math.floor(val2) + parseFloat(parseFloat(((parseFloat(val1 % Math.floor(val1)) + parseFloat(val2 % Math.floor(val2))).toFixed(2)%0.60).toFixed(2)).toFixed(2)) + parseInt(parseFloat(((parseFloat(val1 % Math.floor(val1)) + parseFloat(val2 % Math.floor(val2))).toFixed(2)/0.60).toFixed(2)).toFixed(2));
      // }
      number = (parseFloat(val1) + parseFloat(val2)).toFixed(2)
    }else{
      // if(name.includes('APU') || name.includes('apu')){
      //   number = (parseFloat(val1) - parseFloat(val2)).toFixed(2)
      // }else{
      //   let diff = parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) - parseFloat(val2 % Math.floor(val2))).toFixed(2));
      //   let diffVale = Math.floor(val1) - Math.floor(val2);
      //   number = (diff > 0 ? (diffVale+diff):((diffVale - 1) + (.60 + diff)));
      // }
      number = (parseFloat(val1) - parseFloat(val2)).toFixed(2)
    }
    return number
  }
  updateForm = (type, value) => {
    if(this.state.utilsEdit.tsn_as_of_last_month > 0){
      if(value != ''){
        let assetObj = Object.assign({}, this.state.utilsEdit);
        switch (type) {
          case 'hours':
            assetObj = {
              ...assetObj,
              hours: value,
              cycles: this.state.utilsEdit.cycles,
              tsn: parseFloat(this.roundBy60(this.state.utilsEdit.tsn_as_of_last_month, parseFloat(value), 'add', this.state.utilsEdit.name)),
              csn: this.state.utilsEdit.csn
            }

            break;
          case 'cycles':
            assetObj = {
              ...assetObj,
              hours: this.state.utilsEdit.hours,
              cycles: value,
              tsn: this.state.utilsEdit.tsn,
              csn: parseFloat(this.state.utilsEdit.csn_as_of_last_month) + parseFloat(value)
            }
            break;
          case 'tsn':
            assetObj = {
              ...assetObj,
              hours:this.roundBy60(value, this.state.utilsEdit.tsn_as_of_last_month, 'sub', this.state.utilsEdit.name),
              cycles: this.state.utilsEdit.cycles,
              tsn: value,
              csn: this.state.utilsEdit.csn
            }
            break;
          case 'csn':
            assetObj = {
              ...assetObj,
              hours: this.state.utilsEdit.hours,
              cycles:parseFloat(value) - parseFloat(this.state.utilsEdit.csn_as_of_last_month),
              tsn: this.state.utilsEdit.tsn,
              csn: value
            }
          break;
          case 'remarks':
            assetObj = {
              ...assetObj,
              remarks:this.state.utilsEdit.remarks
            }
          case 'asset_location':
            assetObj = {
              ...assetObj,
              asset_location:this.state.utilsEdit.asset_location
            }
          break;
          default:
        }

        this.setState(prevState => ({
          ...prevState,
          utilsEdit: assetObj,
          error: {
            ...prevState.error,
            [type]: ''
          }
        }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          utilsEdit: {
            ...prevState.utilsEdit,
            [type]: value
          },
          error: {
            ...prevState.error,
            [type]: ''
          }
        }));
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        utilsEdit: {
          ...prevState.utilsEdit,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }
  }
  editUtilsInfo = (e) => {
    e.preventDefault();
      const validateNewInput = {
        hours: checkNumber({value: this.state.utilsEdit.hours, required: true, minLength: '', maxLength: '', message: 'Please Enter Hours'}),
        cycles: checkNumber({value: this.state.utilsEdit.cycles, required: true, minLength: '', maxLength: '', message: 'Please Enter Cycles'}),
        tsn: checkNumber({value: this.state.utilsEdit.tsn && this.state.utilsEdit.tsn != '' ? this.state.utilsEdit.tsn : 0, required: true, minLength: '', maxLength: '', message: 'Please Enter TSN'}),
        csn: checkNumber({value: this.state.utilsEdit.csn && this.state.utilsEdit.csn != '' ? this.state.utilsEdit.csn : 0, required: true, minLength: '', maxLength: '', message: 'Please Enter CSN'}),
      };
      if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        if(this.props.type == 'add'){
          this.props.toggleEditSideBar();
        }
        let data = {
          ...this.state.utilsEdit,
          csn: this.state.utilsEdit.csn && this.state.utilsEdit.csn != '' ? this.state.utilsEdit.csn : 0,
          tsn: this.state.utilsEdit.tsn && this.state.utilsEdit.tsn != '' ? this.state.utilsEdit.tsn : 0,
        }
        this.props.editUtilsInfo(this.props.contract_slug, data);
      }else{
        this.setState({
          error: validateNewInput
        });
      }

  }
  render(){
    const { utilsEdit, error } = this.state;
    const { type } = this.props;
    if(!Object.keys(utilsEdit).length){
      return null
    }
    return(
      <form onSubmit={this.editUtilsInfo} className="sidebar-form">
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row >
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Name
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.name}
                  keyParam="name"
                  type="view"
                  updateUtilsFields={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  TSN of Last Month
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.tsn_as_of_last_month > 0 ? <NumericLabel params={utilizationFormat}>{utilsEdit.tsn_as_of_last_month}</NumericLabel>:'0'}
                  keyParam="tsn_as_of_last_month"
                  type="view"
                  updateUtilsFields={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  CSN of Last Month
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.csn_as_of_last_month > 0 ? utilsEdit.csn_as_of_last_month :'0'}
                  keyParam="csn_as_of_last_month"
                  type="view"
                  updateUtilsFields={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Hours {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.hours}
                  keyParam="hours"
                  type={type}
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.hours}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Cycles {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <EditUitlField
                  value= {utilsEdit.cycles}
                  keyParam="cycles"
                  type={type}
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.cycles}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">TSN</FieldLabel>
                <EditUitlField
                  value={utilsEdit.tsn}
                  keyParam="tsn"
                  type={type}
                  updateUtilsFields={this.updateForm}
                />
                <h6 className="error-msg">{error.tsn}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  CSN
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.csn}
                  keyParam="csn"
                  type={type}
                  updateUtilsFields={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Present Location of Original Assembly
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.asset_location}
                  type={type}
                  keyParam="asset_location"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.csn}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Remark
                </FieldLabel>
                <EditUitlField
                  value={utilsEdit.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.csn}</h6>
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { type != 'view' ?
          <div className="submit-block">
             <input type="submit" className="primary-btn" value="Save Changes"/>
             <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
