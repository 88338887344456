import * as actions from '../actions';
export default function( state = {
  platePictures: [],
  platePicEditCrud: {
    flag: false,
    data: {}
  },
  dataPlateLoader: false
}, action ){
switch (action.type) {
  case actions.DATA_PLATE_LIST:
    return{
      ...state,
      platePictures:action.payload
    }
  case actions.PLATE_EDIT_PIC:
    return {
      ...state,
      platePicEditCrud: action.payload
    }
  case actions.ADD_REPLACE_DATE_PLATE:
    return {
      ...state,
      platePictures: state.platePictures.map(plate => plate.id == action.payload.id ? {...plate, pictures:action.payload.pictures} :plate)
    }
  case actions.DELETE_PLATE_PICTURES:
    return{
      ...state,
      platePictures: state.platePictures.map(plate => plate.id == action.payload.id ? {...plate, pictures:[]} :plate)
    }
  case actions.DATA_PLATE_LOADER:
    return {
      ...state,
      dataPlateLoader: action.payload
    }
  default:
    return state;
  }
}
