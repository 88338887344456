import React from 'react'
import moment from 'moment'
import { imgStoragePath } from '../../../../constants'
import { ToolTipHover } from '../../../../shared'

export default class ProjectList extends React.Component{
  constructor(){
    super()
  }

  getDates = (startDate, endDate) => {
		let dates=[], currentDate = moment(startDate);
		while(moment(endDate) >= moment(currentDate)){
			dates.push(moment(currentDate).format('YYYY-MM-DD'));
			currentDate = moment(currentDate).add('days', 1).format('YYYY-MM-DD');
		}
		return dates
	}

  getProjectSlots = (csd, ced, psd, ped) => {
		/*
		PSD => Proejct Start Date
		PED => Proejct End Date
		CSD => Calendar Start Date
		CED => Calendar End Date
		*/
		let initialSpace= 0;
		let finalSpace= 0;
		let duration = 0;
		let dataObj = {}
		if (( (moment(psd) == moment(csd)) && (moment(psd) == moment(ced)) )) {
			duration = moment.duration(moment(psd).diff(moment(ped)))
			initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
			dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
		 return dataObj;
	 }else if((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))){
			 duration = moment.duration(moment(ped).diff(moment(psd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ced).diff(moment(ped))) ;
			 dataObj = { duration: (duration.asDays() + 1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}else if ((moment(psd) <= moment(csd)) && (moment(ped) <= moment(ced) )) {

			duration = moment.duration(moment(ped).diff(moment(csd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}else if ((moment(psd) <= moment(csd)) && (moment(ped) >= moment(ced) )) {
			duration = moment.duration(moment(ced).diff(moment(csd)))
			 initialSpace = moment.duration(moment(csd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ced).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}else if (( (moment(psd) >= moment(csd)) && (moment(ped) >= moment(ced)) )) {

			duration = moment.duration(moment(ced).diff(moment(psd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ced).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}
		else if ((moment(psd) >= moment(csd)) && (moment(ped) <= moment(ced))) {
			duration = moment.duration(moment(ped).diff(moment(csd)))
			 initialSpace = moment.duration(moment(psd).diff(moment(csd))) ;
			 finalSpace = moment.duration(moment(ped).diff(moment(ced))) ;
			 dataObj = { duration: (duration.asDays()+1), initialSpace: initialSpace.asDays(), finalSpace: finalSpace.asDays()}
			return dataObj;
		}
		else{
			return null
		}
	}

  render(){
    const { project, projectTrackerDtls, index, colWidth } = this.props
    let projectDays = this.getProjectSlots(projectTrackerDtls.start_date, projectTrackerDtls.end_date, project.start_date, project.end_date)
      return(
        <div key={index} className="rh-project-block" style={{ width: this.getDates(projectTrackerDtls.start_date, projectTrackerDtls.end_date).length * colWidth + 'px' }}>
          {
            this.getDates(projectTrackerDtls.start_date, projectTrackerDtls.end_date).map((date, index) =>
            <div className="inner-row-block ">
              <ul className="list-inline">
                <li style={{width: colWidth+'px', margin: 0, textAlign: 'center',height:'100px'}}  className="list-inline-item inner-square-block"></li>
              </ul>
            </div>
          )
        }
           <span id ={`project-status-${project.id}`} className= {`project-dates status${project.status.id}`}
            style={{
               display: 'inline-block',
               width: projectDays && projectDays.duration > 0 ? projectDays.duration * colWidth : 0,
               left: projectDays && projectDays.initialSpace > 0 ? projectDays.initialSpace * colWidth : 0,
               right: projectDays && projectDays.finalSpace > 0 ? (projectDays.finalSpace * colWidth) : 0
               }}
              >
            { projectDays && projectDays.duration >  3 ?
              <b className={projectDays.duration}>{ (project.start_date > projectTrackerDtls.start_date  || project.start_date < projectTrackerDtls.end_date) ? moment(project.start_date).format('DD MMM, YYYY') : '--'}</b>
              : null
            }
            <img  src={imgStoragePath + 'flight_white.png'} alt="img"/>
          </span>
          <ToolTipHover placement="top" tagetId={`project-status-${project.id}`}>
            {moment(project.start_date).format('DD MMM, YYYY')} to {moment(project.end_date).format('DD MMM, YYYY')}
          </ToolTipHover>
        </div>
    )
  }
}
