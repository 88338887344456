
/*
simulationType = 0 No Editable Before Simulation
simulationType = 1 Editable On Simulation
simulationType = 2 No Editable(strike) After Simulation
*/
import * as actions from '../actions';
import moment from 'moment';
const regex = /^[0-9]*$/;
function graphWidgetSum(component, dateType){
  let leangingGearSum = 0;
  let apuSum = 0;
  let totalSum = 0;
  let airframeSumArray =[];
  let enginesSumArray = [];
  let checkFlag=false;
  let monthlyModal=false;
  let graphSumObj = {
    leangingGearSum: 0,
    apuSum: 0,
    totalSum: 0,
    airframeSumArray: [],
    enginesSumArray: []
  };
  if(component.apu){
    Object.keys(component.apu.plots).map(label => {
      if(label == dateType){
        apuSum += component.apu.plots[label];
        totalSum += component.apu.plots[label];
      }
    });
    graphSumObj['apuSum'] = apuSum;
  }
  if(component.landing_gear){
    Object.keys(component.landing_gear.plots).map(label => {
      if(label == dateType){
        leangingGearSum += component.landing_gear.plots[label];
        totalSum += component.landing_gear.plots[label];
      }
    });
    graphSumObj['leangingGearSum'] = leangingGearSum;
  }
  if(component.airframe){
    Object.keys(component.airframe).map(air => {
      let airframeSum = {};
      let sum = 0;
      Object.keys(component.airframe[air].plots).map(label => {
        if(label == dateType){
          sum += component.airframe[air].plots[label];
          totalSum += component.airframe[air].plots[label];
        }
      });
      airframeSum['name'] = air;
      airframeSum['amount'] = sum;
      airframeSumArray.push(airframeSum);
    });
    graphSumObj['airframeSumArray'] = airframeSumArray;
  }
  if(component.engines){
    Object.keys(component.engines.engine_data).map(engine => {
      let engineSum = 0;
      Object.keys(component.engines.engine_data[engine].plots).map(label => {
        if(label == dateType){
          engineSum += component.engines.engine_data[engine].plots[label];
          totalSum += component.engines.engine_data[engine].plots[label];
        }
      });
      enginesSumArray.push(engineSum);

    });
    graphSumObj['enginesSumArray'] = enginesSumArray;
  }
  graphSumObj['totalSum'] = totalSum;
  return graphSumObj;
}
export default function( state = {
  mrFinanceGraph: {
    totalSum:0,
    currentDate: moment().format('YYYY-MM-DD'),
    data: {}
  },
  mrReviewCopy: {},
  lifeOnWings: {},
  simulationType: 0,
  simulatedData: {},
  cashflowMonthlyBreakup : [],
  simulateLoader:false,
  mrCustomRegion: {},
  regionFlag: false,
  deRateList: [],
  deRateMatrix: {
    engineNo: '',
    flag: false,
    data: []
  }
}, action){
  switch (action.type) {
    case actions.MR_ACCRUALS_GRAPH:
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          selectedAirframeEvent:action.payload.airframe ? Object.keys(action.payload.airframe)[0]:'',
          selectedEngineNo: action.payload.engines && action.payload.engines.engine_data ? Object.keys(action.payload.engines.engine_data)[0]:'',
          selectedLg: action.payload.landing_gears ? Object.keys(action.payload.landing_gears)[0]:'',
          data: action.payload
        },
        mrReviewCopy: action.payload,
        lifeOnWings: action.payload.life_on_wings
      }
    case actions.ACCRUAL_PROGRAM_UPDATE:
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          [action.payload.programType]: action.payload.label
        }
      }
    case actions.MR_FINANCE_GRAPH:
      let sumObj = graphWidgetSum(action.payload, moment().format('YYYYMM'));
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          selectedAirframeEvent:action.payload.airframe ? Object.keys(action.payload.airframe)[0]:'',
          selectedEngineNo: action.payload.engines ? Object.keys(action.payload.engines.engine_data)[0]:'',
          leangingGearSum: sumObj['leangingGearSum'],
          apuSum: sumObj['apuSum'],
          enginesSumArray: sumObj['enginesSumArray'],
          airframeSumArray: sumObj['airframeSumArray'],
          totalSum: sumObj['totalSum'],
          data: action.payload
        },
        mrReviewCopy: action.payload
      }
    case actions.GRAPH_WIDGET_SUM:
      let reCheckSum = graphWidgetSum(state.mrFinanceGraph.data, moment(action.payload).format('YYYYMM'));
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          leangingGearSum: reCheckSum['leangingGearSum'],
          apuSum: reCheckSum['apuSum'],
          enginesSumArray: reCheckSum['enginesSumArray'],
          airframeSumArray: reCheckSum['airframeSumArray'],
          totalSum: reCheckSum['totalSum'],
          currentDate: action.payload
        }
      }
    case actions.MR_FINANCE_LOW:
      return {
        ...state,
        lifeOnWings: action.payload
      }
    case actions.UPDATE_ENGINE_NUMBER:
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          selectedEngineNo: action.payload.engineNumber
        }
      }
    case actions.UPDATE_AIRFRAME_PROGRAM:
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          selectedAirframeEvent: action.payload.program
        }
      }
    case actions.AIRFRAME_EVENT_CHANGE:
      let currentEventType = parseInt(action.payload.eventType);
      if(action.payload.dir == 'next'){
        currentEventType += 1;
      }else{
        currentEventType -= 1;
      }
      if(action.payload.type == 'airframe'){
        return {
          ...state,
          mrFinanceGraph: {
            ...state.mrFinanceGraph,
            data: {
              ...state.mrFinanceGraph.data,
              airframe: {
                ...state.mrFinanceGraph.data.airframe,
                [action.payload.program]:{
                  ...state.mrFinanceGraph.data.airframe[action.payload.program],
                  currentEventType:currentEventType.toString()
                }
              }
            }
          }
        }
      }else{
        return {
          ...state,
          mrFinanceGraph: {
            ...state.mrFinanceGraph,
            data: {
              ...state.mrFinanceGraph.data,
              [action.payload.type]: {
                ...state.mrFinanceGraph.data[action.payload.type],
                currentEventType: currentEventType.toString()
              }
            }
          }
        }
      }
    case actions.UPDATE_SIMULATION_FIELDS:

      if(action.payload.metrixType == 'apu' || action.payload.metrixType == 'landing_gear'){
        if(action.payload.eventIndex){
          return {
            ...state,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                [action.payload.metrixType]: {
                  ...state.mrFinanceGraph.data[action.payload.metrixType],
                  events: {
                    ...state.mrFinanceGraph.data[action.payload.metrixType].events,
                    [action.payload.eventIndex]: {
                      ...state.mrFinanceGraph.data[action.payload.metrixType].events[action.payload.eventIndex],
                      [action.payload.keyParam]: action.payload.value
                    }
                  }
                }
              }
            }
          }
        }else{
          return {
            ...state,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                [action.payload.metrixType]: {
                  ...state.mrFinanceGraph.data[action.payload.metrixType],
                  [action.payload.keyParam]: action.payload.value
                }
              }
            }
          }
        }
      }

      if(action.payload.metrixType == 'landing_gears'){
        if(action.payload.eventIndex){
          return {
            ...state,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                landing_gears: {
                  ...state.mrFinanceGraph.data.landing_gears,
                  [action.payload.programType]: {
                    ...state.mrFinanceGraph.data.landing_gears[action.payload.programType],
                    events: {
                      ...state.mrFinanceGraph.data.landing_gears[action.payload.programType].events,
                      [action.payload.eventIndex]: {
                        ...state.mrFinanceGraph.data.landing_gears[action.payload.programType].events[action.payload.eventIndex],
                        [action.payload.keyParam]: action.payload.value
                      }
                    }
                  }
                }
              }
            }
          }
        }else{
          return {
            ...state,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                landing_gears: {
                  ...state.mrFinanceGraph.data.landing_gears,
                  [action.payload.programType]: {
                    ...state.mrFinanceGraph.data.landing_gears[action.payload.programType],
                    [action.payload.keyParam]: action.payload.value
                  }
                }
              }
            }
          }
        }
      }

      if(action.payload.metrixType == 'airframe'){
        if(action.payload.eventIndex){
          return {
            ...state,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                airframe: {
                  ...state.mrFinanceGraph.data.airframe,
                  [action.payload.programType]: {
                    ...state.mrFinanceGraph.data.airframe[action.payload.programType],
                    events: {
                      ...state.mrFinanceGraph.data.airframe[action.payload.programType].events,
                      [action.payload.eventIndex]: {
                        ...state.mrFinanceGraph.data.airframe[action.payload.programType].events[action.payload.eventIndex],
                        [action.payload.keyParam]: action.payload.value
                      }
                    }
                  }
                }
              }
            }
          }
        }else{
          return {
            ...state,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                airframe: {
                  ...state.mrFinanceGraph.data.airframe,
                  [action.payload.programType]: {
                    ...state.mrFinanceGraph.data.airframe[action.payload.programType],
                    [action.payload.keyParam]: action.payload.value
                  }
                }
              }
            }
          }
        }
      }
      if(action.payload.metrixType == 'engines'){
        if(action.payload.programType == 'general_info'){
          let utilization_ratio = state.mrFinanceGraph.data.engines.general_info.utilization_ratio;
          if(action.payload.keyParam == 'hourly_utilization'){
            utilization_ratio = (action.payload.value/state.mrFinanceGraph.data.engines.general_info.cycle_utilization).toFixed(1);
          }else if(action.payload.keyParam == 'cycle_utilization'){
            utilization_ratio = (state.mrFinanceGraph.data.engines.general_info.hourly_utilization/action.payload.value).toFixed(1);
          }
          return {
            ...state,
            regionFlag: false,
            mrFinanceGraph: {
              ...state.mrFinanceGraph,
              data: {
                ...state.mrFinanceGraph.data,
                engines: {
                  ...state.mrFinanceGraph.data.engines,
                  general_info: {
                    ...state.mrFinanceGraph.data.engines.general_info,
                    utilization_ratio: utilization_ratio,
                    [action.payload.keyParam]: action.payload.value,
                  }
                }
              }
            }
          }
        }else{

          if(action.payload.keyParam == 'eventCost'){
            let shopVisit = state['mrFinanceGraph']['data']['engines']['engine_data'][action.payload.programType].shop_visits[action.payload.eventIndex][action.payload.shopVisit];
            shopVisit = {
              ...shopVisit,
              [action.payload.keyParam]: action.payload.value
            };
            return {
              ...state,
              mrFinanceGraph:{
                ...state.mrFinanceGraph,
                data:{
                  ...state.mrFinanceGraph.data,
                  engines: {
                    ...state.mrFinanceGraph.data.engines,
                    engine_data:{
                      ...state.mrFinanceGraph.data.engines.engine_data,
                      [action.payload.programType]:{
                        ...state.mrFinanceGraph.data.engines.engine_data[action.payload.programType],
                        shop_visits:{
                          ...state.mrFinanceGraph.data.engines.engine_data[action.payload.programType].shop_visits,
                          [action.payload.eventIndex]:{
                            ...state.mrFinanceGraph.data.engines.engine_data[action.payload.programType].shop_visits[action.payload.eventIndex],
                            [action.payload.shopVisit]:shopVisit
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }else{
            return {
              ...state,
              mrFinanceGraph: {
                ...state.mrFinanceGraph,
                data: {
                  ...state.mrFinanceGraph.data,
                  engines: {
                    ...state.mrFinanceGraph.data.engines,
                    engine_data:{
                      ...state.mrFinanceGraph.data.engines.engine_data,
                      [action.payload.programType]:{
                        ...state.mrFinanceGraph.data.engines.engine_data[action.payload.programType],
                        [action.payload.keyParam]: action.payload.value
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    case actions.START_GRAPH_SIMULATION:
      return {
        ...state,
        simulationType: action.payload,
        simulateLoader: false,
      }
    case actions.CASH_FLOW_MONLTY_BREAKUP_LIST:
      return {
        ...state,
        cashflowMonthlyBreakup: action.payload,
        simulateLoader: false,
      }
    case actions.EMPTY_CASH_FLOW_MONLTY_BREAKUP_LIST:
      return {
        ...state,
        cashflowMonthlyBreakup: [],
        simulateLoader: false,
      }
    case actions.UPDATE_SIML_RESULT:
      return {
        ...state,
        simulatedData: action.payload,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          data: state.mrReviewCopy
        },
        simulationType: 2,
        simulateLoader: false,
        checkFlag:true
      }
    case actions.SIMULATE_LOADER:
      return {
        ...state,
        simulateLoader: true
      }
    case actions.SIMULATE_LOADER_STOP:
      return {
        ...state,
        simulateLoader: false
      }

    case actions.MONTHLY_TOGGLE_DATA:
      return {
        ...state,
        monthlyModal: action.payload
      }
    case actions.RESET_SIMULATION:
      return {
        ...state,
        simulatedData: {},
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          data: state.mrReviewCopy
        },
        simulationType: action.payload == 'reset' ? 0:1,
        simulateLoader: false,
        checkFlag:false
      }
    case actions.MR_CUSTOM_REGION:
      return {
        ...state,
        mrCustomRegion: action.payload,
        regionFlag: true
      }
    case actions.MR_CUSTOM_REGION_CHA:
      return {
        ...state,
        mrCustomRegion: {
          ...state.mrCustomRegion,
          variable_condition: {
            ...state.mrCustomRegion.variable_condition,
            [action.payload.index]: {
              ...state.mrCustomRegion.variable_condition[action.payload.index],
              default: action.payload.value
            }
          }
        }
      }
    case actions.CANCEL_REGION_BAR:
      return {
        ...state,
        mrCustomRegion: {},
        regionFlag: false
      }
    case actions.DE_RATE_VAR:
      return {
        ...state,
        deRateList: action.payload
      }
    case actions.UPDATE_DE_RATE_OP:
      return {
        ...state,
        deRateMatrix: action.payload
      }
    case actions.CLEAR_SIMULATION_DATA:
      return {
        ...state,
        mrFinanceGraph: {
          ...state.mrFinanceGraph,
          totalSum:0,
          currentDate: moment().format('YYYY-MM-DD'),
          data: {}
        },
        mrReviewCopy: {},
        simulationType: 0,
        simulatedData: {},
      }
    default:
      return state;
  }
}
