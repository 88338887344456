import React, { Component } from 'react';
import { Col } from 'reactstrap';
export default class FieldHeading extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { colSize, label, padding } = this.props;
    return (
      <Col md={colSize} className={padding? padding : ''} >
        <h6>{label}</h6>
      </Col>
    )
  }
}
