import React, { Component, Fragment } from 'react'
import { Row, Col, Tooltip } from 'reactstrap'
import { Link, withRouter, browserHistory } from 'react-router'
import NumericLabel from 'react-pretty-numbers';
import { toastFlashMessage } from '../../../utils'
import { globalPostService } from '../../../globalServices';
class PlanList extends Component{
  constructor(props){
    super(props)
    this.state = {
      tooltipOpen:false,
      loader: false
    }
  }
  toggle = () => {
    this.setState(prevState => ({
      ...prevState,
      tooltipOpen: !prevState.tooltipOpen
    }))
  }
  requestModAccess = (module) => {
    this.setState({loader: true});
    globalPostService('api/saas_request/', {moduleType: module})
    .then(response => {
      this.setState({loader: false});
      if(response.data.statusCode == 200){
        toastFlashMessage('Your request has been accepted, will activate and notify you soon', 'success');
      }
    });
  }
  render(){
    
    const { tooltipOpen, loader } = this.state;
    const location = browserHistory.getCurrentLocation()
    var minQuantity = 1
    var toggleEnabled = true
    var defaultQuantityFlag = true
    if(this.props.selectedPlans && this.props.selectedPlans.length){
      this.props.selectedPlans.map(plan => {
        if(plan.plan.plan_type.value != 1 && plan.status.value == 1){
          minQuantity = plan.quantity + 1
          if((plan.plan.details.interval_type && plan.plan.details.interval_type.value == 2) || (this.props.plan.details && this.props.plan.details.filter(detail => detail.interval_type.value == 2 && detail.is_default == true).length) && toggleEnabled){
            minQuantity = plan.quantity
          }
        }
        return plan
      })
    }
    if((location.pathname.includes('user') || location.pathname.includes('storage')) && this.props.selectedPlans.length){
      if(this.props.selectedPlans.filter(item => item.status && item.status.value == 1 && item.plan && item.plan.details && item.plan.details.interval_type && item.plan.details.interval_type.label.toLowerCase() == 'yearly' ).length){
        toggleEnabled = false
      }
    }
    return(
      <Col md={ this.props.modal ? "12" : "7" } className="mr-pricing-grid">
        <Row className="pricing-outer">
          <Col md = "5" className="text-center pricing-grid flex-centered" style={{borderRight:'1px solid #ddd'}}>
            <div>
              {!(this.props.planIds.indexOf(this.props.planDetail.id) > -1) && this.props.plan.quantity && this.props.plan.quantity != -1 && this.props.plan.is_subscription?
                <ul className="list-inline switcher">
                  <li className="list-inline-item">Monthly</li>
                  <li className="list-inline-item">
                    <label className="switch">
                      <input type="checkbox" checked={this.props.plan.details && this.props.plan.details.length > 1 && this.props.plan.details[1].is_default} />
                      <div className="slider round" onClick={() => toggleEnabled ? this.props.intervalToggle(this.props.index) : null}></div>
                    </label>
                  </li>
                  <li className="list-inline-item">Yearly</li>
                </ul>:null
              }
              { this.props.plan ?
                this.props.plan.details ?
                this.props.plan.details.map((detail, key2) =>
                  <div style={{ paddingLeft: '10px' }} key={key2}>
                    { this.props.params.id && this.props.params.id.includes('fleet') ?
                      <p className="title">Unlimited</p> :null
                    }
                    <p className="price" key={key2} style={{ display: detail.is_default ? 'block' : 'none', marginTop: this.props.planIds.indexOf(this.props.planDetail.id) > -1 ? '0' : this.props.params.id && this.props.params.id.includes('fleet') ? '0px' : '15px'}}>
                      $<NumericLabel className="price-label">{detail.amount}</NumericLabel>
                      <span>{detail.interval_type.value == 1 ? "Per Month" : detail.interval_type.value == 2 ? "Per Year" : detail.interval_type.label.toLowerCase() == 'one time' && this.props.params.id && this.props.params.id == 'asset-data-transfer' ? 'Per Transfer' : detail.interval_type.label}{this.props.plan.units.label ? ', Per ' + this.props.plan.units.label : ''}{this.props.params.id && this.props.params.id == 'fleet-cashflow' ? `( ${this.props.plan.plan_type.label} )` : null}</span>
                    </p>
                  </div>
                ):
                this.props.plan.details.map((detail, key2) =>
                <div style={{ paddingLeft: '10px' }} key={key2}>
                  <p className="title text-left">{this.props.plan.name}</p>
                  <p className="price text-left">
                    $<NumericLabel className="price-label">{detail.amount}</NumericLabel>
                    <span>Billed {detail.interval_type.label.toLowerCase() == 'one time' && this.props.params.id && this.props.params.id == 'asset-data-transfer' ? 'Per Transfer' : detail.interval_type.label} ( {this.props.plan.plan_type.label} )</span>
                  </p>
                </div>
                )
                :null
              }
            </div>
          </Col>
          <Col md="7" className="users-grid flex-centered" style={{paddingLeft: '15px'}}>
            { this.props.plan.details && this.props.plan.is_subscription ?
              this.props.plan.details.map((detail, key2) =>
              <div key={key2} style={ detail.is_default ? {display:'block', width: '100%'} : {display: 'none'}}>
                <ul className="list-inline pricing-calculations">
                  { this.props.plan.quantity != -1 ?
                    <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount}</NumericLabel></li>
                    :null
                  }
                  { this.props.plan.quantity != -1 ?
                    <li className="list-inline-item" >X</li>
                    :null
                  }
                  { this.props.plan.quantity != -1 ?
                    <li className="list-inline-item" ><span>{minQuantity > this.props.plan.quantity ? minQuantity : this.props.plan.quantity}</span> {this.props.plan.units.label}</li>
                    :null
                  }
                  { this.props.plan.quantity != -1 ?
                    (defaultQuantityFlag) ?
                    <li className="list-inline-item caret-list">
                      <i className="fa fa-caret-up" onClick={()=> this.props.increment(this.props.index, minQuantity > this.props.plan.quantity ? minQuantity + 1 : ++this.props.plan.quantity)}></i>
                      <i className="fa fa-caret-down" onClick={ this.props.plan.quantity > minQuantity ? ()=> this.props.decrement(this.props.index, --this.props.plan.quantity) : null } style={ this.props.plan.quantity == 1 || this.props.plan.quantity == minQuantity ? {color: "#dddddd", cursor:"not-allowed"}:{}}></i>
                    </li>
                    :
                    <li className="list-inline-item caret-list">
                      <i className="fa fa-caret-up" onClick={()=> this.props.increment(this.props.index, this.props.plan && this.props.plan.quantity == 1 ? this.props.plan && this.props.selectedPlans && this.props.selectedPlans.length ? this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type.label.toLowerCase() == 'yearly' ? this.props.selectedPlans.length > 1 ? this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity + 2 : this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity + 1 : this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity + 1 : ++this.props.plan.quantity : ++this.props.plan.quantity)}></i>
                      <i className="fa fa-caret-down" onClick={ this.props.plan && this.props.selectedPlans && this.props.selectedPlans.length && this.props.plan.quantity > (this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type.label.toLowerCase() == 'yearly' ? this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity + 1 : this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity) && this.props.plan.quantity > 1 ? ()=> this.props.decrement(this.props.index, --this.props.plan.quantity) : null } style={((this.props.plan && this.props.selectedPlans && this.props.selectedPlans.length && this.props.plan.quantity == (this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type.label.toLowerCase() == 'yearly' ? this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity + 1 : this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity)) || this.props.plan.quantity == 1) ? {color: "#dddddd", cursor:"not-allowed"}:{}}></i>
                    </li>
                    :null
                  }
                  { this.props.plan.quantity != -1 ?
                    <li className="list-inline-item">=</li>
                    :null
                  }
                </ul>
                { (defaultQuantityFlag && this.props.plan.quantity != -1) ?
                  <ul className="list-inline total-price">
                    <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount * (minQuantity > this.props.plan.quantity ? minQuantity : this.props.plan.quantity)}</NumericLabel></li>
                    <li className="list-inline-item">Billed {detail.interval_type.label.toLowerCase() == 'one time' && this.props.params.id && this.props.params.id == 'asset-data-transfer' ? 'Per Transfer' : detail.interval_type.label} <br/>( {this.props.plan.plan_type.label} )</li>
                  </ul>
                  :this.props.plan.quantity != -1 ? <ul className="list-inline total-price">
                  <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount * (this.props.plan && this.props.plan.quantity == 1 ? this.props.plan && this.props.selectedPlans && this.props.selectedPlans.length && this.props.selectedPlans.length > 1 ? this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.details.interval_type.label.toLowerCase() == 'yearly' ?   this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity + 1 : this.props.selectedPlans[this.props.selectedPlans.length - 1].quantity : this.props.plan.quantity : this.props.plan.quantity != -1 ? this.props.plan.quantity : 1)}</NumericLabel></li>
                  <li className="list-inline-item">Billed {detail.interval_type.label.toLowerCase() == 'one time' && this.props.params.id && this.props.params.id == 'asset-data-transfer' ? 'Per Transfer' : detail.interval_type.label} <br/>( {this.props.plan.plan_type.label} )</li>
                </ul> : null
              }
              { this.props.plan.is_subscription ?
                this.props.plan.quantity == -1 && this.props.selectedPlans.length >= 2 && this.props.selectedPlans[this.props.selectedPlans.length - 1].plan.plan_type.value  != 1 && this.props.selectedPlans[this.props.selectedPlans.length - 1].status.value == 1 ?
                <Link disabled={true} onClick={() => null} style={{background: 'rgb(210, 207, 207)', cursor: 'not-allowed', border: '1px solid #a0a0a0'}} className="buy-license">Buy Now</Link>
                :
                <Link disabled={!this.props.showCartButton} onClick={() => this.props.addLicenceToCart(detail.id, minQuantity > this.props.plan.quantity ? minQuantity : this.props.plan.quantity, minQuantity )} className="buy-license">{this.props.selectedPlans && this.props.selectedPlans.length ? `Buy Now` : `Buy Now`}</Link>
                :
                <Link disabled={!this.props.showCartButton} onClick={() => this.props.addLicenceToCart(detail.id, this.props.plan.quantity, 0)} className="buy-license">Buy Now</Link>
              }
              {
                // this.props.params.id != 'fleet-cashflow' ?
                // <Fragment style={{display: 'none'}}>
                //   <span onClick={() => {this.props.extra_free_plan_approved ? null:this.requestModAccess(this.props.params.id)}} style={{background: this.props.extra_free_plan_approved ? '#C0C0C0' : '#28a745', border: '1px solid #28a745', borderRadius: '4px', padding: '8px', color: '#fff', marginLeft: '10px', fontSize: '14px', cursor: this.props.extra_free_plan_approved ? 'not-allowed':'pointer'}}>{ loader ? 'Processing...':'Free Access'}</span>
                //   <i id="module-free-access" style={{marginLeft: '4px', display: 'inline-block'}} class="fa fa-info-circle" aria-hidden="true"></i>
                //   <Tooltip placement="right" isOpen={tooltipOpen} target="module-free-access" toggle={this.toggle}>
                //     Activate extra licences for free
                //   </Tooltip>
                // </Fragment>:null
              }
            </div>
          ): this.props.plan.details.map((detail, key2) =>
          <div key={key2} style={ detail.is_default ? {display:'block', width: '100%'} : {display: 'none'}}>
            {this.props.plan.quantity != -1 ?
              <ul className="list-inline pricing-calculations">
                <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount}</NumericLabel></li>
                <li className="list-inline-item">X</li>
                <li className="list-inline-item"><span>{this.props.plan.quantity}</span> {this.props.plan.units.label}</li>
                <li className="list-inline-item caret-list">
                    <i className="fa fa-caret-up" onClick={()=> this.props.increment(this.props.index, ++this.props.plan.quantity)}></i>
                    <i className="fa fa-caret-down" onClick={ this.props.plan.quantity > 1  ? ()=> this.props.decrement(this.props.index, --this.props.plan.quantity) : null } style={this.props.plan.quantity == 1 ? {color: "#dddddd", cursor:"not-allowed"}:{}}></i>
                </li>
                <li className="list-inline-item">=</li>
              </ul>:null
            }
            {this.props.plan.quantity != -1 ?
              <ul className="list-inline total-price">
                <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount * this.props.plan.quantity}</NumericLabel></li>
                <li className="list-inline-item">Billed {detail.interval_type.label.toLowerCase() == 'one time' && this.props.params.id && this.props.params.id == 'asset-data-transfer' ? 'Per Transfer' : detail.interval_type.label} <br/>( {this.props.plan.plan_type.label} )</li>
              </ul>:null
            }
            {
              this.props.plan.details && this.props.planDetail.id == 2 && this.props.plan.is_subscription && this.props.selectedFlag ?
              <Link disabled={!this.props.showCartButton} onClick={() => this.props.addLicenceToCart(detail.id, this.props.plan.quantity, 0)} className="buy-license">Buy Now</Link>
              :
              <Link disabled={!this.props.showCartButton} onClick={() => this.props.addLicenceToCart(detail.id, this.props.plan.quantity, 0)} className="buy-license">Buy Now</Link>
            }
            {
              // this.props.params.id != 'fleet-cashflow' ?
              // <Fragment style={{display: 'none'}}>
              //   <span onClick={() => { this.props.extra_free_plan_approved ? null:this.requestModAccess(this.props.params.id)} } style={{background: this.props.extra_free_plan_approved ? '#C0C0C0':'#28a745', border: '1px solid #28a745', borderRadius: '4px', padding: '8px', color: '#fff', marginLeft: '10px', fontSize: '14px', cursor: this.props.extra_free_plan_approved ? 'not-allowed': 'pointer'}}>{ loader?'Processing...':'Free Access'}</span>
              //   <i id="module-free-access" style={{marginLeft: '4px', display: 'inline-block'}} class="fa fa-info-circle" aria-hidden="true"></i>
              //   <Tooltip placement="right" isOpen={tooltipOpen} target="module-free-access" toggle={this.toggle}>
              //     Activate extra licences for free
              //   </Tooltip>
              // </Fragment>:null
            }
          </div>
        )
      }
    </Col>
  </Row>
</Col>
)
}
}
export default withRouter(PlanList)
