import React, {Component} from 'react';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils'
import {  ListLoader } from '../../../../shared';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import NumericLabel from 'react-pretty-numbers';
export default class SelectLLPToReplaceModal extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.togglExport = this.togglExport.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }
  toggle() {
    this.props.selectLLPToReplaceFn('close', '', '', '' , '');
  }

  togglExport() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  render() {
    const { selectLLPToReplace,llpExpLoader } = this.props;
    const userInfo = getLocalStorageInfo().user
    const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'R', currency: true, precision: 2, commafy: true}
    return(
      <div>
        <Modal isOpen={selectLLPToReplace.modalStatus} toggle={this.toggle} className="llp-brekup-modal">
          <div className="modal-header">
            <h5>
              Update Life Limited Parts <br/>( LLPs ) to be replaced in  {selectLLPToReplace.name} Run
              <span className="add-enquiry-block download-block" style={{float:'right'}}>
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.togglExport}>
                    <DropdownToggle caret size="sm">
                     <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export </span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => this.props.downLoadLLPFn('llp'+selectLLPToReplace.name+'Run',{download:'xls',shop_visit_no:selectLLPToReplace.shop_visit_no,log_id:selectLLPToReplace.log_id,engine_no:selectLLPToReplace.engine_no})} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </span>
            </h5>
          </div>

          <ModalBody style={{maxHeight: '440px'}}>
            <table>
              <thead>
                <tr>
                  <th className="caption-ui">LLP Type</th>
                  <th className="caption-ui">Cycle Remaining</th>
                  {
                    userInfo.permission && userInfo.permission.mr_calculator && !userInfo.permission.mr_calculator.is_saas ?
                    <th className="caption-ui  text-right">
                      Cost
                      { this.props.costOfYears && this.props.costOfYears.length == 1 ?
                        <span style={{ fontSize: '10px', color: '#585454', fontFamily: 'Conv_IBMPlexSans-Regular'}}> (as per  {this.props.costOfYears[0]} )</span>
                        : null }
                      </th>
                    :null
                  }
                </tr>
              </thead>
              <tbody>
                {selectLLPToReplace.list.map((llp, index) => {
                  return(
                    <tr key={index}>
                      <td className="caption-ui">
                        <label className="flex-centered">
                          <input type="checkbox" name="" checked={llp.replaced == 1 ? true:false} onChange={(e) => this.props.updateLLPToReplaceFn(e.target.checked ? 1:0, index)} />
                         <span> {llp.part_name} </span>
                        </label>
                       </td>
                      <td className="caption-ui"> {llp.cycles_remaining}/{llp.max_limit} </td>
                        {
                          userInfo.permission && userInfo.permission.mr_calculator && !userInfo.permission.mr_calculator.is_saas ?
                          <td  style={{ position:'relative'}} className="caption-ui text-right">
                            <NumericLabel params={prettyNumberParams}>{llp.price}</NumericLabel>
                            { llp.year_of_cost && this.props.costOfYears.length > 1 ?
                              <span style={{ position: 'absolute', bottom:'-1px', right:'6px', fontSize: '10px', color: '#585454', fontFamily: 'Conv_IBMPlexSans-Regular'}}> ( as per { llp.year_of_cost ?  llp.year_of_cost : '2018' } )</span>
                              : null }
                            </td>
                          :null
                        }
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <div>
                  <button onClick={() => this.props.replaceLLPListFn()} className="select-llp-replace-btn primary-btn">Save Changes</button>
                  <span onClick={this.toggle} style={{padding: '0 5px'}}>Cancel</span>
                </div>
              </Col>
              { userInfo.permission && userInfo.permission.mr_calculator && !userInfo.permission.mr_calculator.is_saas ?
              <Col>
                <div className="select-llp-replace-total">
                  <p>Total Replacement Cost</p>
                    <NumericLabel params={prettyNumberParams}>
                      {
                        selectLLPToReplace.list.reduce( (total, next) => total + (next.replaced == 1 ? next.price:0), 0 )
                      }
                  </NumericLabel>
                </div>
              </Col>:null}
            </Row>
          </ModalFooter>
        </Modal>
        {llpExpLoader?<ListLoader/>:null}
      </div>
    )
  }
}
