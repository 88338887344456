import * as actions from '../actions';

export default function( state = {
  itemSideBar:false,
  gepReportLoader:false,
  gapReportInvDet: {},
  error: []

}, action ){

  switch (action.type) {

  case actions.ITEM_SIDE_BAR:
      return {
        ...state,
        itemSideBar:!state.itemSideBar

      }
  case actions.GET_GAPREPORT_INV:
      return {
        ...state,
        gapReportInvDet: action.payload
      }

  case actions.ADD_DYNAMIC_TE003_DATA:
      return{
        ...state,
        gapReportInvDet: {
          ...state.gapReportInvDet,
          list :  [...state.gapReportInvDet.list].map((gapReportDetails, index)=> gapReportDetails.id == action.payload.id ? {
           ...gapReportDetails,
           gap_reports:[...gapReportDetails.gap_reports,action.payload.data]
          }:gapReportDetails
          )
        }
      }

  case actions.GAPREPORT_DETLS_LOADER:
      return {
        ...state,
        gepReportLoader: action.payload
      }

  case actions.DELETE_GAP_COMPONENT:
    return{
      ...state,
      gapReportInvDet: {
      ...state.gapReportInvDet,
      list :  [...state.gapReportInvDet.list].map((gapReportDetails, index)=> gapReportDetails.id == action.payload.sectionType ? {
      ...gapReportDetails,
      gap_reports:[...gapReportDetails.gap_reports].filter((component, compoIndex) =>  compoIndex != action.payload.index)
      }:gapReportDetails
      )
    }
  }
  case actions.UPDATE_TE003_FORM:
      if(action.payload.sectionType == 'genDetails'){
          return{
          ...state,
          gapReportInvDet: {
            ...state.gapReportInvDet,
            general_details: {
              ...state.gapReportInvDet.general_details,
              [action.payload.type]: action.payload.value
            }
          }
        }
      }else{
        return{
          ...state,
          gapReportInvDet: {
            ...state.gapReportInvDet,
            list :  [...state.gapReportInvDet.list].map((gapReportDetails, index)=> gapReportDetails.id == action.payload.sectionType ? {
             ...gapReportDetails,
             gap_reports:[...gapReportDetails.gap_reports].map((gapReports, index)=>index==action.payload.index ?{
              ...gapReports,
              [action.payload.type]: action.payload.value
             }:gapReports
             )
            }:gapReportDetails
            )
          }
        }
      }

      case actions.ERROR_MESSAGE_03:
      return{
        ...state,
        error: action.payload
      }

      case actions.CLEAR_ERR_MESSGAE_03:
      if(action.payload.sectionType == 'genDetails'){
        return{
          ...state,
          error: [...state.error].map((dataGenDet, index) => index == 0 ?
            [...dataGenDet].map((data, index) => Object.keys(data).includes(action.payload.type) ? {
              ...data,
              [action.payload.type] : ''
            } : data
          )
          : dataGenDet )
        }
      }
      else{

        return{
          ...state,
          error: [...state.error].map((compoDet, compIndex) => compIndex == 2 ?
            [...compoDet].map((data, index) => index == 1  ?
              [...data].map((subData,subIndex)=> subIndex==action.payload.index ?{
                ...subData,
                [action.payload.type]:''
              }:subData
             ) : data
          )
          : compoDet )
        }
      }




      default:
      return state;

  }
}
