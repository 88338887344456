import React, { Component } from 'react';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
import { ContrListCol } from '../../Elements';
export default class SubLeaseRow extends Component {
 constructor(props) {
  super(props);
 }
 render() {
  const { subLease} = this.props;
  const subLeaseObj = [
    { value: subLease.sublease_lessee ? subLease.sublease_lessee : '--'},
    { value: subLease.details ? subLease.details.length > 120 ? subLease.details.substring(0, 117) + ' ...' : subLease.details :'' }, 
    { value: subLease.start_date ? moment(subLease.start_date).format(displayDateFormat) : '--' },
    { value: subLease.end_date ? moment(subLease.end_date).format(displayDateFormat) : '--'}
  ];
  return (
   <tr onClick = {this.props.viewSublease}>
     {subLeaseObj.map((subLease, index) => <ContrListCol colObj={subLease} key={index} />)}
   </tr>
  )
 }
}
