import * as actions from '../actions';
import { contrResvRateListAc } from '../../MRRates/actionCreators';
import { downloadFileType } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
import { CTR_SHORT_INFO } from '../../Listing/actions';
import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../../../globalServices/';
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const loadCashFlowAssetViewAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.CASHFLOW_LOADER,
      payload: true
    });
    globalGetService('console/contract/'+params.type+'/'+params.aircraft_slug + '/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        });
        let params = {}
        let assetViewId = response.data.data
        trackActivity('Page Visited',{
          page_title: 'Cash Flow',
          application_module: 'Contracts',
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
        })
        if(response.data && response.data.data && response.data.data.contract.slug){
          params['contract_slug'] = response.data.data.contract.slug;
          dispatch(cashFlowListAc(response.data.data.contract.slug, {}));
          dispatch(cashFlowWidgetAc(response.data.data.contract.slug));
          dispatch(contrResvRateListAc(params));
        }

      }
    })
  }
}

export const cashFlowWidgetAc = (contract_slug) => {
  return (dispatch) => {
    globalGetService('contracts/' +contract_slug+ '/cashflow/widget/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASHFLOW_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const cashFlowListAc = (contract_slug, queryParams) => {
  return (dispatch) => {
    if(Object.keys(queryParams).length){
      dispatch({
        type: actions.CASHFLOW_LOADER,
        payload: true
      });
    }
    globalGetService('contracts/' +contract_slug+ '/cashflow-ledger-list/', queryParams)
    .then(response => {
      dispatch({
        type: actions.CASHFLOW_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200 ){
        dispatch({
          type: actions.CASH_FLOW_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const exportContractCashflowAc = (contract, fileType, filter) => {
  let queryParam = {'download': fileType};
  Object.keys(filter).map(key => {
    if(key == 'date'){
      if(filter[key].startDate != ''){
        queryParam = {
          ...queryParam,
          startDate: filter[key].startDate
        }
      }
      if(filter[key].endDate != ''){
        queryParam = {
          ...queryParam,
          endDate: filter[key].endDate
        }
      }
    }else{
      queryParam = {
        ...queryParam,
        [key]: filter[key].filterData
      }
    }
  });
  return (dispatch) => {
    dispatch({
      type: actions.CASHFLOW_LOADER,
      payload: true
    });
    globalExportService('/contracts/'+contract.contract.slug+'/cashflow-ledger-list/',queryParam)
    .then(response => {
      dispatch({
        type: actions.CASHFLOW_LOADER,
        payload: false
      });
      if (contract.asset_type === 2){
        downloadFileType(response, 'ESN'+contract.esn+'_'+contract.contract.lessee.name+'_cash_flow.', fileType)
      }else if(contract.asset_type === 3){
        downloadFileType(response, 'APU'+contract.serial_number+'_'+contract.contract.lessee.name+'_cash_flow.', fileType)
      }else if(contract.asset_type === 4){
        downloadFileType(response, 'LG'+contract.serial_number+'_'+contract.contract.lessee.name+'_cash_flow.', fileType)
      }else if(contract.asset_type === 5){
        downloadFileType(response, 'Propeller'+contract.serial_number+'_'+contract.contract.lessee.name+'_cash_flow.', fileType)
      }else if(contract.asset_type === 1){
        downloadFileType(response, 'MSN'+contract.msn+'_'+contract.contract.lessee.name+'_cash_flow.', fileType)
      }else if (contract.asset_type === 6) {
        downloadFileType(response, 'FAN' + contract.esn + '_' + contract.contract.lessee.name + '_cash_flow.', fileType)
      }else if (contract.asset_type === 7) {
        downloadFileType(response, 'HPC' + contract.esn + '_' + contract.contract.lessee.name + '_cash_flow.', fileType)
      }else if (contract.asset_type === 8) {
        downloadFileType(response, 'HPT' + contract.esn + '_' + contract.contract.lessee.name + '_cash_flow.', fileType)
      }else if (contract.asset_type === 9) {
        downloadFileType(response, 'LPT' + contract.esn + '_' + contract.contract.lessee.name + '_cash_flow.', fileType)
      }
      let assetInfo = contract;
      let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
      trackActivity('Report Exported',{
        page_title: 'Cash Flow',
        application_module: 'Contracts',
        asset: asset_serial_number,
        file_name: 'Cash Flow',
        file_type: fileType.toUpperCase(),
      })
    });
  }
}
