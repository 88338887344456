import React, { Component } from 'react';
import { Link } from 'react-router';
import { UserManageSideBar, UserList, AddUser } from '../components';
import { Row, Col } from 'reactstrap';
import { globalPostService } from '../../globalServices';
import { browserHistory } from 'react-router';
import { toastFlashMessage, getLocalStorageInfo } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
import { NoRecordFound, ListLoader, LicenceModal  } from '../../shared';
import { connect } from 'react-redux';
import Select from 'react-select';
import Pagination from "react-js-pagination";
import { BUY_LICENCE } from '../../shared/actions'
import { DeleteModal } from '../../applications/technicalInspection/Elements';
import { getInvitesListAc, acceptInviteRequestAc, rejectInviteRequestAc, getUsersUsageAc } from '../actionCreators';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';

class UserInviteList extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeUser: '',
      page: 1,
      deleteModal: false,
      deleteTitle: '',
      slug: '',
      type: ''
    }
  }

  deleteModal = (title, slug, type) => {
    if(getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 1){
      if(this.props.usersUsage.available == this.props.usersUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/user',
          content: 'License for adding new users is expired. Please upgrade your license to add more users.'
        })
        return;
      }
    }else if(getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type == 1 && getLocalStorageInfo().user.permission.console.is_saas){
      if(this.props.usersUsage.available == this.props.usersUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/user',
          content: 'License for adding new users is expired. Please upgrade your license to add more users.'
        })
        return;
      }
    }
    this.setState({
      deleteModal: !this.state.deleteModal,
      deleteTitle: title,
      slug: slug,
      type: type
    })
  }

  handlePageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    this.props.getInvitesList(page);
  }

  componentDidMount(){
    this.props.getInvitesList(1);
    if(getLocalStorageInfo().user.permission.console.is_saas){
      this.props.getUsersUsage();
    }
  }


  render(){
    const { inviteList, downloadLoader } = this.props;
    const { passInfo, error  } = this.state
    return(
      <div className="contract-container user-profile-blk">
        <UserManageSideBar/>

      <h4 className="gama">
        <div className="title-block">
            <h1>User Invites</h1>
            <p className="block-subs">Contains pending requests from users</p>
        </div>
      </h4>
      <div className="users-list-wrapper">
      <table className="users-list">
          <thead>
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>Contact</td>
              <td>Designation</td>
              <td>Description</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {
              inviteList && inviteList.list && inviteList.list.length && !downloadLoader ?
              inviteList.list.map((user, index) => <UserList type="invite" aproveRequest={this.deleteModal} rejectRequest={this.deleteModal} user={user} key={index}/>):null
            }
          </tbody>
      </table>
      {
        !downloadLoader ? inviteList && inviteList.list && inviteList.list.length ? null : <NoRecordFound/>: null
      }
       </div>
      {
        inviteList && inviteList.pagination && inviteList.list.length ?
        <Pagination
         activePage={this.state.page}
         itemsCountPerPage={20}
         totalItemsCount={inviteList.pagination.total ? inviteList.pagination.total:0}
         pageRangeDisplayed={5}
         onChange={this.handlePageChange}
       />:null
      }

      {
        downloadLoader? <ListLoader/>:null
      }
      <DeleteModal title={this.state.deleteTitle} isOpen={this.state.deleteModal} className="modal-dialog-centered" toggle={() => this.deleteModal('','')}>
      <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to {
              this.state.type == 'approve' ? 'approve ?' :'reject ?'} </p>
        <ul className="list-inline" style={{textAlign: 'center'}}>
          <li className="list-inline-item">
            {
              this.state.type == 'approve' ?
              <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.acceptInviteRequest(this.state.slug); this.deleteModal('', '');}}>Yes</button>
              :<button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.rejectInviteRequest(this.state.slug); this.deleteModal('','');}} >Yes</button>
            }
          </li>
          <li className="list-inline-item">
            <button className="btn btn-danger btn-sm add-new" onClick={() => this.deleteModal('','')}>No</button>
          </li>
        </ul>
      </DeleteModal>
      {
        this.props.buyLicenceModal.flag ?
        <LicenceModal
          planId="1"
          toggleLicence={() => this.props.buyLicence({
            flag: false,
            action: '',
            content: ''
          })}
          licenceModal={this.props.buyLicenceModal}
          />
        :null
      }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  inviteList:state.userAssetReducer.inviteList,
  downloadLoader:state.userAssetReducer.downloadLoader,
  usersUsage:state.userAssetReducer.usersUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
})
const mapDispatchToProps = dispatch => {
  return {
    getInvitesList: (page) => dispatch(getInvitesListAc(page)),
    acceptInviteRequest: (slug) => dispatch(acceptInviteRequestAc(slug)),
    rejectInviteRequest: (slug) => dispatch(rejectInviteRequestAc(slug)),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getUsersUsage: () => dispatch(getUsersUsageAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserInviteList)
