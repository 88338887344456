import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
export default class WorkingGrpToolTip extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  render(){
    return(
      <span>
        <img src={this.props.imgPath} alt="icon" id={this.props.tooltipId} />
        <Tooltip placement="left" isOpen={this.state.tooltipOpen} target={this.props.tooltipId} toggle={this.toggle}>
          {this.props.toolTipText}
        </Tooltip>
      </span>
    )
  }
}
