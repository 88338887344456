import React, { Component } from 'react';
import { Link } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col , Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { UserSideBar, ContractList,GroupList } from '../components';
import {toastFlashMessage, getLocalStorageInfo} from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate, EditSidebar } from '../../applications/contracts/Elements';
import classnames from 'classnames';
import { NoRecordFound } from '../../shared';
import { globalGetService } from '../../globalServices';
import { DeleteModal,GetInputField } from '../../applications/technicalInspection/Elements';
import { userProfileAc, getGroupsSuperListAc, editProfileAc,deleteSuperGroupAc, exportPermisionAc, addEditUserContractAc, updateUserProjectsAc, addEditExperienceAc, exportCVAc ,deleteExpAc, userStatusUpdateAc, deleteUserStatusAc } from '../actionCreators';
import { sGlobalConstantAc } from '../../shared/actionCreators';
import { ListLoader } from '../../shared';
import PlacesAutocomplete from 'react-places-autocomplete';
import { TYPE_UPDATE, USER_AVAILABILITY_CRUD, USER_CONTRACT_CRUD,GROUP_LOADER_UPDATE,FILTER_SECURITY_GRP_LIST, ADD_UPDATE_EXPEREIENCE, UPDATE_USER_FIELD} from '../actions';
import { checkMobileNumber, checkString } from '../../formValidator';
import { imgStoragePath } from '../../constants';
import { checkFloat } from '../../formValidator';
import countryList from 'react-select-country-list'
import Select from 'react-select'
import moment from 'moment'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const regex = /^[0-9]*$/;
class UserProfile extends Component {
  constructor(props){
    super(props);
    this.options = countryList().getData()
    this.state = {
      updateImageFlag:false,
      profile_pic:'',
      userName: '',
      contact_no: '',
      designation:'',
      alternative_contact_no:'',
      activeTab: '1',
      groupModal: false,
      base_location:'',
      city: '',
      department:'',
      deleteObject: [],
      availibility_error: '',
      addGroupToUser: [],
      deleteBulkModal: false,
      deleteTitle: '',
      deleteType: '',
      userGroups: [],
      departmentName:'',
      error:{},
      imageFile:null,
      userSkills: [],
      contract_error: {},
      showAditionalInfo: false,
      project_types: [],
      experience_error:{},
      expModal: false,
      expId:'',
      expData:{
        type: 'add',
        flag: false,
        data:{
          aircraft_type: '',
          year_of_experience: '',
          remarks: ''
        }
      },
      deleteStatus:{
        flag: false,
        status: {}
      }
    }
  }

  updateContractImage=(image)=>{
      this.setState(prevState => ({
        ...prevState,
        contract_doc: URL.createObjectURL(image),
        contractImageFlag:true,
        contractFile:image
      }));
  }

  showAdditonalFields = () =>{
    this.setState(prevState => ({
      ...prevState,
      showAditionalInfo : !this.state.showAditionalInfo
    }));
  }

  addEditAvailabilityFn = () =>{
    let data = this.props.userAvailability.data
    this.setState((prevState) => ({
      ...prevState,
      availibility_error: {...prevState.availibility_error,
        from_date: data.from_date.trim() == '' ? 'Please select from date' : '',
        to_date: data.to_date.trim() == '' ? 'Please select to date' : '',
        remarks: data.remarks.trim() == '' ? 'Please enter remarks' : '',
      }
    }), () => {
      if(!Object.keys(this.state.availibility_error).filter(key => this.state.availibility_error[key] != '').length){
        this.props.userStatusUpdate(this.props.userProfileInfo, this.props.userAvailability)
      }
    })
  }

  addEditContractFn = () =>{
    var formData = new FormData()
    var validateNewInput = {}
    validateNewInput = {
      ...validateNewInput,
      contract_file: !this.state.contractImageFlag && (this.state.contractFile == undefined || this.state.contractFile == null) ? 'Please add document' : '',
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.state.contractImageFlag){
        formData.append("contract_file", this.state.contractFile);
      }
      if(this.props.userContract.data.start_date != null && this.props.userContract.data.start_date.trim() != ''){
        formData.append("start_date", this.props.userContract.data.start_date);
      }
      if(this.props.userContract.data.end_date != null && this.props.userContract.data.end_date.trim() != ''){
        formData.append("end_date",this.props.userContract.data.end_date);
      }
      if(this.props.userContract.data.name != null && this.props.userContract.data.name.trim() != ''){
        formData.append("name", this.props.userContract.data.name);
      }
      this.props.addEditUserContract({id: this.props.userProfileInfo.slug}, {...this.props.userContract, data: formData});
      this.setState({
        contract_error: {}
      });
    }else{
      this.setState({
        contract_error: validateNewInput
      });
    }
  }

  addEditExperienceFn = () =>{
    var formData = new FormData()
    var validateNewInput = {}
    validateNewInput = {
      ...validateNewInput,
      aircraft_type: this.props.experienceData.data.aircraft_type ? '':'Please enter Aircraft Type',
      year_of_experience:checkFloat({value: this.props.experienceData.data.year_of_experience, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Data'}),
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {

      if(this.props.experienceData.data.aircraft_type != null && this.props.experienceData.data.aircraft_type.trim() != ''){
        formData.append("aircraft_type", this.props.experienceData.data.aircraft_type);
      }
      if(this.props.experienceData.data.year_of_experience != null && this.props.experienceData.data.year_of_experience!= ''){
        formData.append("year_of_experience", new Number(parseFloat(this.props.experienceData.data.year_of_experience)).toFixed(1));
      }
      if(this.props.experienceData.data.remarks != null){
        formData.append("remarks",this.props.experienceData.data.remarks);
      }
      this.props.addEditExperience({...this.props.experienceData, data: formData}, this.props.experienceData.data.id);
      this.setState({
        experience_error: {}
      });
    }else{
      this.setState({
        experience_error: validateNewInput
      });
    }
  }

  addEditContractFlag = (flag) =>{
    this.props.addEditContract({...this.props.userContract, flag: flag})
    this.setState(prevState => ({
      ...prevState,
      contract_error: {...prevState.contract_error,
        fee_rate: null,
        start_date: null,
        end_date: null,
        contract_file: null,
        addEditContract: flag
      }
    }))
  }

  getUserSkillsList = (value, callBack) =>{
    var sendData = function(error, data){
      callBack(error, data)
    }
      globalGetService(`console/user-skills/`,{filter: value})
      .then(response => {
        if(response.data.statusCode == 200){
          this.setState({userSkills: response.data.data})
        }
        sendData(null, {
          options: response.data.data,
        })
      })
  }

  componentDidMount(){
    this.props.fetchUserProfile();
    this.props.fetchProjectDepartment({constant_types:['project_departments','user_category','project_name']});
    trackActivity('Page Visited', {
      application_module:'User Management',
      asset:'None',
      page_title:'User Profile'
    })
  }

  toggle(tab) {
    if(tab == 5){
      this.setState({
        project_types: this.props.userProfileInfo.project_types
      })
    }
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  updateForm = (type, value) => {
    let data = this.props.userProfileInfo
    this.props.updateUserField({...data, [type]: value})
    if(value==null && value.length == 0){
      value=''
    }
    else if(type == 'language_proficiency' && value.length > 0){
      this.setState(prevState => ({
        ...prevState,
        [type]: value.charAt(0).toUpperCase() + value.slice(1),
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }
    else if(type == 'original_resume'){
      this.setState({
        originalResume: value.name
      })
    }
    else if(type == "skill_sets" ) {
      this.setState(prevState => ({
        ...prevState,
        [type]:value,
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }else {
      this.setState(prevState => ({
        ...prevState,
          [type]: value,

        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }

  }

  editUserAction = (userProfileInfo) => {
    this.setState(prevState => ({
      ...prevState,
      originalResume: '',
      userName:userProfileInfo.first_name+' '+userProfileInfo.last_name,
      profile_pic:userProfileInfo.profile_pic,
      original_resume: userProfileInfo.original_resume,
      contact_no:userProfileInfo.contact_no?userProfileInfo.contact_no:'',
      designation:userProfileInfo.designation,
      alternative_contact_no:userProfileInfo.alternative_contact_no?userProfileInfo.alternative_contact_no:'',
      base_location:userProfileInfo.base_location,
      department:userProfileInfo.department.value?userProfileInfo.department.value:'',
      departmentName:userProfileInfo.department.value?userProfileInfo.department.label:'',
      experience:userProfileInfo.experience?userProfileInfo.experience:'',
      skill_sets:userProfileInfo.skill_sets?userProfileInfo.skill_sets:'',
      licenses:userProfileInfo.licenses?userProfileInfo.licenses:'',
      passport_issued_by:userProfileInfo.passport_issued_by?userProfileInfo.passport_issued_by:'',
      license_endorsements:userProfileInfo.license_endorsements?userProfileInfo.license_endorsements:'',
      license_issued_by:userProfileInfo.license_issued_by?userProfileInfo.license_issued_by:'',
      special_trainings:userProfileInfo.special_trainings?userProfileInfo.special_trainings:'',
      fee_rate:userProfileInfo.fee_rate?userProfileInfo.fee_rate:'',
      special_approvals:userProfileInfo.special_approvals?userProfileInfo.special_approvals:'',
      category:userProfileInfo.category.value?userProfileInfo.category.value:'',
      additional_remarks:userProfileInfo.additional_remarks?userProfileInfo.additional_remarks:'',
      language_proficiency:userProfileInfo.language_proficiency?userProfileInfo.language_proficiency:'',
      city: userProfileInfo.city ? userProfileInfo.city :"",
      updateImageFlag:false,
      error: { }
    }));
  }

  updateImage=(image)=>{
    if(image.type=='image/jpeg'||image.type=='image/png'){
      this.setState(prevState => ({
        ...prevState,
        profile_pic: URL.createObjectURL(image),
        updateImageFlag:true,
        imageFile:image
      }));
   }else{
    toastFlashMessage('Please add proper image', 'error');
   }
  }

  addGroupsToUserFn = (type, group={}) => {
    if(type == 'single'){
      if(this.state.addGroupToUser.includes(group.id)){
        this.setState({
          addGroupToUser: this.state.addGroupToUser.filter(id => id != group.id)
        })
      }else{
        this.setState({
          addGroupToUser: [...this.state.addGroupToUser, group.id]
        })
      }
    }else if (type == 'all') {
      let groupIds = this.props.groupList.map(group => { return group.id});
      this.setState({
        addGroupToUser:this.state.addGroupToUser.length == groupIds.length ? []:groupIds,
      })
    }else{
      this.setState({
        groupModal: false
      })
    }
  }

  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
        base_location: address,

      error: {
        ...prevState.error,
        base_location: ''
      }
    }));
  };

  handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          base_location: address
      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );
          this.setState(prevState => ({
            ...prevState,
                country: place.length ? place[0]['long_name'] : address
            }))
           })
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );
          this.setState(prevState => ({
            ...prevState,
                country: place.length ? place[0]['long_name'] : address
            }))
           })
      .catch(error => console.error('Error', error));
  };

  selectAllGroups = () => {
    this.setState({
      deleteObject: this.state.deleteObject.length == this.props.userProfileInfo.sparta_group.length ? [] : this.props.userProfileInfo.sparta_group.map(item => item.id)
    })
  }
  toggleDeleteModal = (title, deleteType, deleteObject) =>{
    this.setState({
      deleteModal: !this.state.deleteModal,
      deleteTitle: title,
      deleteType: deleteType,
      deleteObjectId: [deleteObject],
      deleteObject: []
    })
  }


  addUserGroup = (group) =>{
    if(this.state.userGroups.filter(item => group.id == item.id).length){
      this.setState({
        userGroups: this.state.userGroups.filter((item, index) => item.id != group.id),
        deleteObject: this.state.deleteObject.filter((item, index) => item != group.id),
      })
    }else{
      this.setState(prevState => ({
        ...prevState,
        userGroups: [ ...prevState.userGroups, group],
        deleteObject: [ ...prevState.deleteObject, group.id]
      }))
    }
  }

  toggleGroupModal = (value) =>{
    this.setState({
      groupModal: value,
      addGroupToUser: [],
      deleteObjectId:[],
      deleteObject:[],
    })
    if(value){
      this.props.getGroupsList(getLocalStorageInfo().user.slug);
    }else{
      this.props.updateGroupLoader();
    }
  }

  toggleExpModal = () =>{
    this.setState({
      expModal: !this.state.expModal,
    })
  }

  toggleDeleteBulkModal = (title, deleteType, deleteObj) =>{
    this.setState({
      deleteBulkModal: !this.state.deleteBulkModal,
      deleteTitle: title,
      deleteType: deleteType,
      deleteObject: deleteObj
    })
  }

  updateProjectTypes = (project) =>{
    this.setState({
      project_types: this.state.project_types.filter(item => item.project_type_value == project.project_type_value).length ? this.state.project_types.map(item => item.project_type_value == project.project_type_value ? {...item, no_of_projects: project.no_of_projects} : item) : [...this.state.project_types, project]
    })
  }

  updateProjects = (id) =>{
    var project_list=[];
    project_list = this.state.project_types.filter(item => (item.project_type_value == id && item.no_of_projects=='')).length ? this.state.project_types.map(item => (item.project_type_value == id && item.no_of_projects=='')? {...item, no_of_projects: 0} : item) : this.state.project_types
    this.setState({
      project_types:project_list,
    })
    var formData = new FormData()
    formData.append("project_types", JSON.stringify(project_list));
    this.props.updateUserProjects(formData,)
  }

  updateProjectCounts=(id,value)=>{
    if(isNaN(value) || value < 0 || !regex.test(value) ){
      this.updateProjectTypes({project_type_value: id, no_of_projects:''})
    }else{
      this.updateProjectTypes({project_type_value: id, no_of_projects:value})
    }
  }

  setExpId=(id)=>{
    this.setState({
      expId: id,
    })
  }


  addEditExperienceFlag = (flag) =>{
    this.props.toggleExpereience(this.state.expData)
    this.setState(prevState => ({
      ...prevState,
      experience_error: {...prevState.experience_error,
        aircraft_type: null,
        year_of_experience: null
      }
    }))
  }

  editUserInfo = (e) => {
    e.preventDefault();
    let objError = {
      userName: checkString({value:this.state.userName, required:true, minLength:'', maxLength:'', message:'Please enter Name '}),
    }
    if (Object.keys(objError).every((k) => { return objError[k] === '' })) {
    var formData = new FormData();
    if(this.state.updateImageFlag){
      formData.append("profile_pic", this.state.imageFile);
    }
    if(this.state.userName!= ''){
      formData.append("first_name", this.state.userName.substring(0,this.state.userName.indexOf(' ')));
      formData.append("last_name", this.state.userName.substring(this.state.userName.indexOf(' ')));
    }
      formData.append("original_resume", this.state.original_resume== null ? '':this.state.original_resume);
      formData.append("contact_no", this.state.contact_no);
      formData.append("alternative_contact_no", this.state.alternative_contact_no);
      formData.append("designation", this.state.designation);
      formData.append("department", this.state.department);
      formData.append("base_location", this.state.base_location);
      formData.append("city",this.state.city == null ? '':this.state.city);
      formData.append("category", this.state.category==null?'':this.state.category);
      if(this.props.userProfileInfo.type.value == 3 ||this.props.userProfileInfo.type.value == 1){
      formData.append("fee_rate", this.state.fee_rate== null ? '':this.state.fee_rate);
      formData.append("experience", this.state.experience== null ? '':this.state.experience);
      formData.append("skill_sets", this.state.skill_sets== null ? '' :this.state.skill_sets.map(skill => skill.id));
      formData.append("licenses", this.state.licenses== null ? '':this.state.licenses);
      formData.append("passport_issued_by", this.state.passport_issued_by== null ? '':this.state.passport_issued_by);
      formData.append("license_endorsements", this.state.license_endorsements== null ? '':this.state.license_endorsements);
      formData.append("license_issued_by", this.state.license_issued_by== null ? '':this.state.license_issued_by);
      formData.append("special_trainings", this.state.special_trainings==null?'':this.state.special_trainings);
      formData.append("special_approvals", this.state.special_approvals==null?'':this.state.special_approvals);
      formData.append("additional_remarks", this.state.additional_remarks==null?'':this.state.additional_remarks);
      formData.append("language_proficiency",this.state.language_proficiency);
      }

    this.props.userInfoUpdate(formData);

  }else{
    this.setState({
      error: objError,
    });
   }
  }
  render(){
    var countries = this.options.map(item => {return {...item, value: item.label}})
    const { error, userSkills, contract_error, experience_error, availibility_error } = this.state;
    const { userAvailabilityList, userAvailability, type,userProfileInfo, userContract, userContracts, userProfileLoader, processing, downloadLoader,techConstants,groupList,groupLoader,deleteExpLoader} = this.props;
    var showContractor ;
    showContractor = true;
    showContractor = getLocalStorageInfo().defaultLessor.lessor_type == 1 ? true : false;
    return(
      <div className="contract-container user-profile-blk">

        <UserSideBar />
        {deleteExpLoader? <ListLoader/>:null}
        { userProfileLoader ? <ListLoader/>:
       <div>
        { <h4 className="gama"> Profile
        {
          <span onClick={() => this.props.exportCV(userProfileInfo.slug, userProfileInfo.first_name ) } style={!showContractor ? {display: 'none'} : {paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '12px', float: 'right', fontFamily : 'Conv_IBMPlexSans-Regular',marginRight:'10px'}}><img width="15" src={imgStoragePath+"export_icon.png"} alt="export" style={{ marginRight: '5px' }} />Export CV</span>
        }
        </h4> }

        <div className="user-profile">
          <form onSubmit={(e) => this.editUserInfo(e)}>
            {
              type == 'edit' ?
              <Link onClick={() => {this.editUserAction(userProfileInfo);this.props.typeUpdate('view')}}>
                 <img className="edit-profile-icon" src={imgStoragePath+"toast_cross.png"} alt="img"/>
              </Link>
              :<Link onClick={() => {this.editUserAction(userProfileInfo);this.props.typeUpdate('edit')}}> <img className="edit-profile-icon" src={imgStoragePath+"icon_editblock.png"} alt="img"/> </Link>
            }
            <Row className="user-detail">
                <FieldCol md="2">
                  <div className="user-profile-pic">
                    <div className="update-img-block">
                      {this.state.profile_pic==null || this.state.profile_pic == '' ?
                        userProfileInfo.profile_pic ?
                        <img className="profile-pic"  src={userProfileInfo.profile_pic} alt="img"/>
                        :
                        <img  src={imgStoragePath+"default_lessor.png"} alt="img"/>:<img className="profile-pic"  src={this.state.profile_pic} alt="img"/>
                      }
                      { type == 'edit' ?
                       <h4>Edit<input type="file" accept="image/*" onChange={(e)=>this.updateImage(e.target.files[0])}/></h4> : null }

                    </div>
                  </div>
                </FieldCol>
                <Col md="5">
                <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Name
                     {
                       type == 'edit' ?
                       <sup className="reqStar"> * </sup>
                      : null
                     }
                    </FieldLabel>
                     <FieldInput
                      value={this.state.userName =='' ? userProfileInfo.first_name+' '+userProfileInfo.last_name :this.state.userName}
                      type={type}
                      keyParam="userName"
                      updateField={this.updateForm}
                    />
                   <h6 className="error-msg">{error.userName}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Email
                    </FieldLabel>
                    <h6>{userProfileInfo.email != null ? userProfileInfo.email : '--'}</h6>
                  {/* <h6 className="error-msg">{error.lease_type}</h6> */}
                  </FieldGroup>
                </FieldCol>
                {userProfileInfo.is_superuser ?
                <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      User Role
                    </FieldLabel>
                    <h6>Root User</h6>
                  {/* <h6 className="error-msg">{error.lease_type}</h6> */}
                  </FieldGroup>
                </FieldCol>
                : userProfileInfo.user_role !=null ?
                userProfileInfo.user_role=='4' ?
                <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      User Role
                    </FieldLabel>
                    <h6>Lessee</h6>
                  {/* <h6 className="error-msg">{error.lease_type}</h6> */}
                  </FieldGroup>
                </FieldCol>:null:null
                }
                <FieldCol md="12" style={{width: '200px'}} className="update-img-block">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      <span>Resume {type=='edit' ? <h4 style={{display: 'inline'}}>{userProfileInfo.original_resume ? "Change" : "Upload"}</h4> : ''}</span><br/> { type == 'edit' ?
                       <h4 style={{textAlign: 'left'}}><span>{this.state.originalResume}</span><input accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" onChange={(e)=>this.updateForm('original_resume',e.target.files[0])}/></h4> : userProfileInfo.original_resume != null  && typeof userProfileInfo.original_resume != 'object' ? <a target="_blank" href={userProfileInfo.original_resume} style={!showContractor ? {display: 'none'} : { color: '#2f8cff', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', right: '0', bottom: '0' }}>Download</a> : 'Not Available' }
                      </FieldLabel>
                     <h6 className="error-msg">{error.userName}</h6>
                   </FieldGroup>
                </FieldCol>
                {getLocalStorageInfo().defaultLessor.lessor_type==1?
                  <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Category
                     </FieldLabel>
                     {type!='view'?
                     <FieldSelect
                      placeholder='Category'
                      keyParam="category"
                      value={this.state.category ==''? userProfileInfo.category && userProfileInfo.category.value ? userProfileInfo.category.value :'':this.state.category}
                      type={type}
                      options={techConstants?techConstants.filter(item => item.type == 'user_category'):[]}
                      updateField={this.updateForm}
                      labelKey='label'
                      valueKey='value'
                      name=""
                    />:
                    <h6>{userProfileInfo.category&&userProfileInfo.category.label? userProfileInfo.category.label : '--'}</h6>
                  }
                   <h6 className="error-msg">{error.category}</h6>
                  </FieldGroup>
                </FieldCol>:null}
                <FieldCol md="8" >
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Profile Type</FieldLabel>
                    <FieldInput
                      value={userProfileInfo.type && userProfileInfo.type.label ? userProfileInfo.type.label :'--'}
                      type='view'
                      keyParam="contact_no"
                      updateField={this.updateForm}
                    />
                  </FieldGroup>
                </FieldCol>
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Overall Work Experience</FieldLabel>
                    <FieldInput
                      value={userProfileInfo.experience ? userProfileInfo.experience  ? userProfileInfo.experience :'--':this.state.experience}
                      type={type}
                      keyParam="experience"
                      updateField={(keyParam, value) => this.updateForm('experience', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">License Issued By</FieldLabel>
                    <FieldTextarea
                      value={userProfileInfo.license_issued_by ? userProfileInfo.license_issued_by  ? userProfileInfo.license_issued_by :'--':this.state.license_issued_by}
                      type={type}
                      keyParam="license_issued_by"
                      updateField={(keyParam, value) => this.updateForm('license_issued_by', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">License Type Endorsements</FieldLabel>
                    <FieldTextarea
                      value={userProfileInfo.license_endorsements ? userProfileInfo.license_endorsements  ? userProfileInfo.license_endorsements :'--':this.state.license_endorsements}
                      type={type}
                      keyParam="license_endorsements"
                      updateField={(key, value) => this.updateForm('license_endorsements', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}

                 {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Skill Sets</FieldLabel>
                    {
                      type == 'edit' ?
                      <Select.AsyncCreatable
                        multi={true}
                        options={userSkills && userSkills.length ? userSkills : []}
                        onChange={(value) => this.updateForm('skill_sets', value)}
                        value={this.state.skill_sets ? this.state.skill_sets : userProfileInfo.skill_sets ? userProfileInfo.skill_sets : []}
                        disabled={type != 'edit'}
                        valueKey="id"
                        labelKey="name"
                        autoload={true}
                        loadOptions={this.getUserSkillsList}
                      />
                      :userProfileInfo.skill_sets.length ?
                      <p style={{ display: 'block', overflow: 'hidden' }}>
                       {userProfileInfo.skill_sets.map((skill, index) => <span key={index} className="user-skill-list-item">{skill.name}</span>)}
                      </p>
                      :'--'
                    }
                  </FieldGroup>
                </FieldCol> : null}
                {userProfileInfo.rating ?
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Rating</FieldLabel>
                      <h6>{parseFloat(userProfileInfo.rating.toFixed(2))} <i className="fa fa-star" style={{ fontSize: '10px', color: '#cccccc', position: 'relative', bottom: '3px' }}></i></h6>
                    </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Language Proficiency</FieldLabel>
                      <FieldTextarea
                        value={type == 'edit' ? this.state.language_proficiency : userProfileInfo.language_proficiency}
                        type={type}
                        keyParam="language_proficiency"
                        updateField={(key, value) => this.updateForm(key, value.length > 0 ? `${value.charAt(0).toUpperCase()}${value.slice(1)}`:value)}
                      />
                    </FieldGroup>
                  </FieldCol> : null}
              </Col>
              <Col md="5">
              <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Designation
                    </FieldLabel>
                    <FieldInput
                      value={this.state.designation ==''? userProfileInfo.designation != null ? userProfileInfo.designation :'--':this.state.designation}
                      type={type}
                      keyParam="designation"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.designation}</h6>
                  </FieldGroup>
                </FieldCol>
              <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Phone Number
                    </FieldLabel>
                    <PhoneInput
                      defaultCountry={'in'}
                      value={this.state.contact_no ==''? userProfileInfo.contact_no != null ? userProfileInfo.contact_no :'--':this.state.contact_no}
                      type={type}
                      disabled={type=='edit'?false:true}
                      onBlur={(e) => this.updateForm('contact_no', e.target.value)}
                      />
                    <h6 className="error-msg">{error.contact_no}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Alternate Phone Number
                    </FieldLabel>
                    <PhoneInput
                      defaultCountry={'in'}
                      value={this.state.alternative_contact_no == '' ? userProfileInfo.alternative_contact_no != null ? userProfileInfo.alternative_contact_no : '--' : this.state.alternative_contact_no}
                      type={type}
                      disabled={type=='edit'?false:true}
                      onBlur={(e) => this.updateForm('alternative_contact_no', e.target.value)}
                      />
                   <h6 className="error-msg">{error.alternative_contact_no}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Department
                     </FieldLabel>
                     <FieldSelect
                      placeholder='Department'
                      keyParam="department"
                      value={this.state.department ==''? userProfileInfo.department && userProfileInfo.department.label ? userProfileInfo.department.label :'':(type=='edit'?this.state.department:this.state.departmentName)}
                      type={type}
                      options={techConstants?techConstants.filter(item => item.type == 'project_departments'):[]}
                      updateField={this.updateForm}
                      labelKey='label'
                      valueKey='value'
                      name=""
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="6">
                 <FieldGroup className="form-group">
                   <FieldLabel className="label">
                   Country
                    </FieldLabel>
                  {type!='view'?
                  <FieldSelect
                   placeholder='Country'
                   keyParam="base_location"
                   value={this.state.base_location ? userProfileInfo.base_location :''}
                   type={type}
                   options={countries}
                   updateField={(key, value) => this.updateForm(key, value)}
                   labelKey='label'
                   valueKey='value'
                   name=""
                 />:
               <h6>{userProfileInfo.base_location? userProfileInfo.base_location : '--'}</h6>
                   }
                  <h6 className="error-msg">{error.base_location}</h6>
                 </FieldGroup>
               </FieldCol>
               <FieldCol md="6">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                 City
                  </FieldLabel>
                {type!='view'?
                <FieldInput
                  value={userProfileInfo.city  ? userProfileInfo.city :''}
                  type={type}
                  keyParam="city"
                  updateField={(key, value)=> this.updateForm(key, value)}
                />
            :
             <h6>{userProfileInfo.city? userProfileInfo.city : '--'}</h6>
                 }
                <h6 className="error-msg">{error.base_location}</h6>
               </FieldGroup>
             </FieldCol>
               {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && type == 'edit' && showContractor) ? <FieldCol md="8">
                 <FieldGroup className="form-group">
                   <FieldLabel className="label">Daily Fee Rate</FieldLabel>
                   <h6>{userProfileInfo.fee_rate != null ? userProfileInfo.fee_rate : '--'}</h6>
                 </FieldGroup>
               </FieldCol> : null}
               {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && type == 'edit' && showContractor) ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Special Approvals</FieldLabel>
                    <FieldTextarea
                      value={ type == 'edit' ? this.state.special_approvals :  userProfileInfo.special_approvals }
                      type={type}
                      keyParam="special_approvals"
                      updateField={(keyParam, value) => this.updateForm('special_approvals', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">License</FieldLabel>
                    <FieldTextarea
                      value={type == 'edit' ? this.state.licenses : userProfileInfo.licenses}
                      type={type}
                      keyParam="licenses"
                      updateField={(keyParam, value) => this.updateForm('licenses', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Passport Issued By</FieldLabel>
                    <FieldInput
                      value={userProfileInfo.passport_issued_by ? userProfileInfo.passport_issued_by  ? userProfileInfo.passport_issued_by :'--':this.state.passport_issued_by}
                      type={type}
                      keyParam="passport_issued_by"
                      updateField={(keyParam, value) => this.updateForm('passport_issued_by', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && type == 'edit' && showContractor) ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Special Trainings</FieldLabel>
                    <FieldTextarea
                      value={ type == 'edit' ?  this.state.special_trainings : userProfileInfo.special_trainings }
                      type={type}
                      keyParam="special_trainings"
                      updateField={(keyParam, value) => this.updateForm('special_trainings', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Additional Remarks</FieldLabel>
                    <FieldTextarea
                      value={type == 'edit' ? this.state.additional_remarks : userProfileInfo.additional_remarks}
                      type={type}
                      keyParam="additional_remarks"
                      updateField={(keyParam, value) => this.updateForm('additional_remarks', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {type =='view' && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor ?
                  !this.state.showAditionalInfo ?
                    <Link style={!showContractor ? {display: 'none'} : { color: '#2f8cff', float: 'right', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', position: 'absolute', right: '0', bottom: '0' }} onClick={() => {this.showAdditonalFields()}}><i className="fa fa-plus-circle"></i>&nbsp;Show Additional Info</Link>
                  :<Link style={!showContractor ? {display: 'none'} : { color: '#2f8cff', float: 'right', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', position: 'absolute', right: '0', bottom: '0' }} onClick={() => {this.showAdditonalFields()}}><i className="fa fa-minus-circle"></i>&nbsp;Hide Additional Info</Link>
                :null}
              </Col>
            </Row>
            {
              type == 'edit' ?
              <div className="submit-block">
                <input type="submit" className="primary-btn" disabled={processing ? 'disabled' : ''}  value={processing ? 'Processing...' :"Save Changes"}/>
                <Link onClick={() => { this.editUserAction(userProfileInfo);this.props.typeUpdate('view')}}>Cancel</Link>
              </div>:null
            }
          </form>
          <div style={{position:'relative'}} >
            {this.state.activeTab === '2' ?
            <span onClick={() => this.props.exportPermision()} style={{position:'absolute',right:'10px',top:'5px',color:'#2f8cff',fontSize:'15px',cursor:'pointer', display:'none'}}><img width="15" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export </span>:null
            }
            <div className="edit-view-tech-spec profile-tab">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                    >
                    Lessors
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                    >
                    Security Groups
                  </NavLink>
                </NavItem>
                { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 ) && !getLocalStorageInfo().user.is_root_user ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggle('4'); }}
                      >
                      Documents
                    </NavLink>
                  </NavItem>:null
                }
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && !getLocalStorageInfo().user.is_root_user ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => { this.toggle('5'); }}
                      >
                      Previous Inspections
                    </NavLink>
                  </NavItem>:null
                }
                 { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && !getLocalStorageInfo().user.is_root_user ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => { this.toggle('6'); }}
                      >
                      Experience on Aircraft Type
                    </NavLink>
                  </NavItem>:null
                }
                { userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '7' })}
                      onClick={() => { this.toggle('7'); }}
                      >
                      Availability Tracker
                    </NavLink>
                  </NavItem>:null
                }
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                  <Row>
                    <Col sm="6">
                    { this.state.activeTab=='2' && userProfileInfo && userProfileInfo.sparta_group && userProfileInfo.sparta_group.length ?
                          <span className="select-all" onClick={() => this.selectAllGroups()}>
                            <input type="checkbox" checked={ !this.state.groupModal && userProfileInfo.sparta_group && userProfileInfo.sparta_group.length >0 && this.state.deleteObject.length == userProfileInfo.sparta_group.length}/> Select all security groups
                          </span>:null }
                    </Col>
                    <Col sm="6" className="text-right">
                    <span className="nav-item" style={{ float: 'right'}}>
                          { this.state.activeTab == '2' ?
                            <Link style={{fontSize: '12px',color:'#dc3545', marginRight: '5px',textAlign:'center'}} onClick={() => this.toggleDeleteBulkModal('Delete selected groups', 'group', this.state.deleteObject)}>
                              { this.state.deleteObject.length  ? `Delete Security Groups (${this.state.deleteObject.length})` : null}
                            </Link>:null}
                    { this.state.activeTab == '2' ?
                      <button className="btn btn-primary btn-sm add-new" onClick={() => this.toggleGroupModal(true)}>Add Security Groups</button>:
                      this.state.activeTab == '4' ?
                      <button className="btn btn-primary btn-sm add-new" style={{marginBottom: '20px'}} onClick={() => this.props.addEditContract({...this.props.userContract, flag: true, type: 'add'})}>Add Document</button>:null}
                      </span>
                    </Col>
                  </Row>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <ul className="list-unstyled lessor-list">
                        {
                          userProfileInfo && userProfileInfo.lessor_list ?
                          userProfileInfo.lessor_list.map((lessor, index) =>
                          <li>
                            <span>
                              <img src={lessor.logo ? lessor.logo : imgStoragePath+"default_lessor.png"} style={{width:'200px',marginRight:'35px'}} alt={lessor.name} />
                              {lessor.name}
                            </span>
                          </li>):null
                        }
                      </ul>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <ul className="list-unstyled lessor-list">
                  {
                    userProfileInfo && userProfileInfo.sparta_group &&  userProfileInfo.sparta_group.length ?
                    userProfileInfo.sparta_group.map((userGroup, index) =>
                    <GroupList
                    key={index}
                    deleteObject={this.state.deleteObject}
                    addUserGroup={this.addUserGroup}
                    userGroup={userGroup}
                    deleleGroup={this.toggleDeleteModal}
                  />
                ):<NoRecordFound/>
                }
                  </ul>
                </TabPane>
                <TabPane tabId="4">
                  <div className="users-contract-list-con">
                      <Row className="users-contract-list-con-header">
                        <Col md="1">#</Col>
                        <Col md="3">Ttile</Col>
                        <Col md="3">Start Date</Col>
                        <Col md="3">End Date</Col>
                        <Col md="2"></Col>
                      </Row>
                        { userContracts && userContracts.length ?
                          userContracts.map((contract, index) => <ContractList contract={contract} index={index}/>):<NoRecordFound/>
                        }
                    </div>
                </TabPane>
                <TabPane tabId="5">
                      <div className="users-contract-list-con">
                          <Row className="users-contract-list-con-header">
                            <Col md="4">Inspection Type</Col>
                            <Col md="3">No. Of Projects</Col>
                            <Col md="3"></Col>
                            <Col md="2"></Col>
                          </Row>
                            { techConstants && techConstants.length ?
                              techConstants.map((project, index) =>
                              project.type == 'project_name' ?
                                <Row className="users-contract-list">
                                  <Col md="4">{project.label}</Col>
                                  <Col md="3">
                                    {
                                      this.state.project_types.filter(item => project.value == item.project_type_value).length ? this.state.project_types.map(item => project.value == item.project_type_value ?
                                      <input value={item.no_of_projects} style={{borderBottom: '1px solid #8e8e8e'}} onBlur={() => this.updateProjects(project.value)} onChange={(evt) => this.updateProjectCounts(project.value,evt.target.value) } type="text"/>
                                        : null):
                                        <input value="0" style={{borderBottom: '1px solid #8e8e8e'}} onBlur={() => this.updateProjects(project.value)} onChange={(evt) => this.updateProjectCounts(project.value,evt.target.value) } type="text"/>
                                      }
                                  </Col>

                                </Row>
                              :null
                            ):<NoRecordFound/>
                            }
                        </div>
                    </TabPane>
                <TabPane tabId="6">
                  <div className="users-contract-list-con">
                  <div style={{textAlign:'right',marginBottom:'10px'}} >
                  <button className="btn btn-primary btn-sm add-new" onClick={() => this.props.toggleExpereience({...this.props.experienceData, flag: true, type: 'add'})}>Add Aircraft Type Experience</button>
                  </div>
                      <Row className="users-contract-list-con-header">
                        <Col md="4">Aircraft Type</Col>
                        <Col md="3">No. of Years</Col>
                        <Col md="3">Notes</Col>
                        <Col md="2"></Col>
                      </Row>
                      { userProfileInfo && userProfileInfo.experience_of_aircraft_type && userProfileInfo.experience_of_aircraft_type.length ?
                          userProfileInfo.experience_of_aircraft_type.map((experience, index) =>
                            <Row className="users-contract-list">
                              <Col md="4">{experience.aircraft_type}</Col>
                              <Col md="3">{new Number(parseFloat(experience.year_of_experience)).toFixed(1)}</Col>
                              <Col md="3">{experience.remarks}</Col>
                              <Col md="2" style={{textAlign: 'right'}}>
                                  <a onClick={() => this.props.toggleExpereience({type:'update',flag:true,data:experience})}><i style={{color: '#4382f9'}} className="fa fa-edit"></i></a>
                                  <a onClick={() => {this.toggleExpModal();this.setExpId(experience.id)}}><i style={{color: 'red'}} className="fa fa-trash"></i></a>
                              </Col>
                            </Row>
                        ):<NoRecordFound/>
                        }
                    </div>
                </TabPane>
                {
                  userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                  <TabPane tabId="7">
                    <div className="users-contract-list-con">
                    <div style={{textAlign:'right',marginBottom:'10px'}} >
                    <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.addEditAvailability({flag: true, data: {from_date: moment().format('YYYY-MM-DD'),to_date: '',remarks: '',status: 1}, type: 'add'});this.setState({availibility_error: {}})}}>Add Availability Status</button>
                    </div>
                        <Row className="users-contract-list-con-header">
                          <Col md="4">Availability Type</Col>
                          <Col md="2">From</Col>
                          <Col md="2">To</Col>
                          <Col md="3">Remarks</Col>
                          <Col md="1"></Col>
                        </Row>
                        { userAvailabilityList.list.length ?
                            userAvailabilityList.list.map((list, index) =>
                              <Row className="users-contract-list">
                                <Col md="4">{list.status.label ? list.status.label : '--'}</Col>
                                <Col md="2">{list.from_date}</Col>
                                <Col md="2">{list.to_date}</Col>
                                <Col md="3">{list.remarks}</Col>
                                <Col md="1" style={{textAlign: 'right'}}>
                                    <a onClick={() => {this.props.addEditAvailability({type:'update',flag:true,data:{...list, status: list.status.value}});this.setState({availibility_error: {}})}}><i style={{color: '#4382f9'}} className="fa fa-edit"></i></a>
                                    <a onClick={() => this.setState({deleteStatus: {flag: true, status: list}})}><i style={{color: 'red'}} className="fa fa-trash"></i></a>
                                </Col>
                              </Row>
                          ):<NoRecordFound/>
                          }
                      </div>
                  </TabPane>
                  :null
                }
              </TabContent>
              <DeleteModal title="Delete Status" isOpen={this.state.deleteStatus.flag} className="modal-dialog-centered" toggle={() => this.setState({deleteStatus: {flag: false, status: {}}})}>
                <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                <ul className="list-inline" style={{textAlign: 'center'}}>
                  <li className="list-inline-item">
                      <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserStatus(this.props.userProfileInfo, this.state.deleteStatus.status.id);this.setState({deleteStatus: {flag: false, status: {}}});}}>Yes</button>
                  </li>
                  <li className="list-inline-item">
                    <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({deleteStatus: {flag: false, status: {}}})}>No</button>
                  </li>
                </ul>
              </DeleteModal>
              <Modal isOpen={this.state.groupModal} toggle={() => this.addGroupsToUserFn('clear')} className="modal-md profile-lessor-list modal-dialog-centered">
                  <ModalHeader toggle={() => this.addGroupsToUserFn('clear')}>
                    Security Groups <br/>
                    { groupList.length ?
                      <label className="for-label" onClick={() => this.addGroupsToUserFn('all')}>
                        <input type="checkbox" checked={ this.state.addGroupToUser.length == groupList.length ? 'checked':'' } /> Select All( {groupList.length} )
                      </label>:null
                    }
                    <input type="text" placeholder="Search for Security Groups" style={{borderBottom: '1px solid #d7d7d7', marginBottom: '5px', width: '100%'}} onChange={(e) => this.props.updateSecurityGroupsSearch(e.target.value)} />
                  </ModalHeader>
                  <ModalBody style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto', position: 'relative',paddingTop:'0px' }}>
                  { groupLoader ?
                    <Row>
                      { groupList.length ?
                        groupList.map((group, index) =>
                          <Col md="12" className="modal-list-user" key={index} onClick={() => this.addGroupsToUserFn('single',group)}>
                            <span >
                              <input checked={this.state.addGroupToUser.includes(group.id)} style={{marginRight:'20px'}} type="checkbox"/>
                              {group.name}
                            </span>
                          </Col>
                        ): <Col md="12"><NoRecordFound/></Col>
                      }
                    </Row>:
                    <span style={{position:'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</span>
                  }
                </ModalBody>
                <ModalFooter>
                  { groupList.length ? this.state.addGroupToUser.length ?
                    <button className="btn btn-primary btn-sm add-new"  onClick= {() =>  {this.props.addUserGroup(this.state.addGroupToUser); this.addGroupsToUserFn('clear');}}>Add</button>:
                    <button className="btn btn-primary btn-sm add-new" style={{opacity:'0.3',cursor:'disabled'}} >Add</button>:null
                  }
                  <button className="btn btn-danger btn-sm add-new" onClick={() => this.addGroupsToUserFn('clear')}>Cancel</button>
                </ModalFooter>
                </Modal>
                <DeleteModal title={this.state.deleteTitle} isOpen={this.state.deleteModal} className="modal-dialog-centered" toggle={() => this.toggleDeleteModal('', '')}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">

                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserGroup(this.state.deleteObjectId); this.toggleDeleteModal('', '');}}>Yes</button>

                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleDeleteModal('', '')}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title={this.state.deleteTitle} isOpen={this.state.deleteBulkModal} className="modal-dialog-centered" toggle={() => this.toggleDeleteBulkModal('','', [])}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">

                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserGroup(this.state.deleteObject); this.toggleDeleteBulkModal('', '', []);}}>Yes</button>

                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleDeleteBulkModal('', '', [])}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title={"Delete Experience"} isOpen={this.state.expModal} className="modal-dialog-centered" toggle={() => this.toggleExpModal()}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete the experience ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                      <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.removeExp(this.state.expId);this.toggleExpModal()}}>Yes</button>
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleExpModal()}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                {this.props.experienceData.flag ?
                  <EditSidebar title={this.props.experienceData.type == 'add' ? 'Add Aircraft Type Experience' : 'Edit Aircraft Type Experience'}  width="400px" right= "-400px" editSideBar={this.props.experienceData.flag} toggleEditSideBar={() => this.addEditExperienceFlag(false)}>
                    <Row className="add-edit-user-contract text-edit-area">
                      <Col md="12">
                          <FieldGroup className="form-group">
                            <FieldLabel className="label" style={{display: 'block'}}>Aircraft Type<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={this.props.experienceData.data.aircraft_type != '' ? this.props.experienceData.data.aircraft_type : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.toggleExpereience({...this.props.experienceData, data: {...this.props.experienceData.data, aircraft_type: value}})}
                            />
                          <h6 className="error-msg">{experience_error.aircraft_type}</h6>
                          </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label" style={{display: 'block'}} >
                            No. Of Years<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={this.props.experienceData.data.year_of_experience != '' ? this.props.experienceData.data.year_of_experience : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.toggleExpereience({...this.props.experienceData, data: {...this.props.experienceData.data, year_of_experience: value}})}
                            />
                          <h6 className="error-msg">{experience_error.year_of_experience}</h6>
                         </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label" style={{display: 'block'}}>
                            Notes
                            </FieldLabel>
                            <FieldTextarea
                              value={this.props.experienceData.data.remarks != '' ? this.props.experienceData.data.remarks : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.toggleExpereience({...this.props.experienceData, data: {...this.props.experienceData.data, remarks: value}})}
                            />
                         </FieldGroup>
                      </Col>
                    </Row>
                    <div className="edit-sidebar-footer" style={this.props.experienceData.flag ? {width: '400px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 400) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <button disabled={this.props.processing} onClick={(e) => this.addEditExperienceFn()} type="button" className="btn btn-primary">{this.props.processing ? 'Processing...':'Save Changes'}</button>
                          </li>
                          <li className="list-inline-item">
                            <Link onClick={() => this.addEditExperienceFlag(false)}> Cancel</Link>
                          </li>
                        </ul>
                      </div>
                  </EditSidebar>
                  :null
                }
            </div>
          </div>
        </div>
      </div>
      }
      {
        userContract.flag ?
        <EditSidebar title={userContract.type == 'add' ? 'Add Document' : 'Edit Document'}  width="400px" right= "-400px" editSideBar={userContract.flag} toggleEditSideBar={() => this.addEditContractFlag(false)}>
          <Row className="add-edit-user-contract">
            <Col md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Title</FieldLabel>
                  <FieldInput
                    value={this.props.userContract.data.name != '' ? this.props.userContract.data.name : ''}
                    type="edit"
                    keyParam="name"
                    updateField={(keyParam, value) => this.props.addEditContract({...this.props.userContract, data: {...this.props.userContract.data, name: value}})}
                  />
                </FieldGroup>
            </Col>
            <Col md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Start Date
                  </FieldLabel>
                   <FieldDate
                    value={this.props.userContract.data.start_date != '' ? this.props.userContract.data.start_date : '--'}
                    type='edit'
                    keyParam="start_date"
                    updateField={(keyParam, value) => this.props.addEditContract({...this.props.userContract, data: {...this.props.userContract.data, start_date: value}})}
                  />
                <h6 className="error-msg">{contract_error.start_date}</h6>
               </FieldGroup>
            </Col>
            <Col md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  End Date
                  </FieldLabel>
                   <FieldDate
                     value={this.props.userContract.data.end_date != '' ? this.props.userContract.data.end_date : '--'}
                    type='edit'
                    keyParam="end_date"
                    updateField={(keyParam, value) => this.props.addEditContract({...this.props.userContract, data: {...this.props.userContract.data, end_date: value}})}
                  />
                <h6 className="error-msg">{contract_error.end_date}</h6>
               </FieldGroup>
            </Col>
            <Col md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Attachment<sup className="reqStar"> * </sup>
                  </FieldLabel>
                   <input type="file" onChange={(e)=>this.updateContractImage(e.target.files[0])}/>
                  <h6 className="error-msg">{contract_error.contract_file}</h6>
               </FieldGroup>
            </Col>
          </Row>
          <div className="edit-sidebar-footer" style={userContract.flag ? {width: '400px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 400) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button disabled={this.props.processing} onClick={(e) => this.addEditContractFn()} type="button" className="btn btn-primary">{this.props.processing ? 'Processing...':'Save Changes'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={() => this.addEditContractFlag(false)}> Cancel</Link>
                </li>
              </ul>
            </div>
        </EditSidebar>
        :null
      }
      {this.props.userAvailability.flag ?
        <EditSidebar title={this.props.userAvailability.type == 'add' ? 'Add Availability Status' : 'Edit Availability Status'}  width="400px" right= "-400px" editSideBar={this.props.userAvailability.flag} toggleEditSideBar={() => {this.props.addEditAvailability({flag: false, data: {from_date: moment().format('YYYY-MM-DD'),to_date: '',remarks: '',status: 1}, type: 'add'});this.setState({availibility_error: {}})}}>
          <Row className="add-edit-user-contract text-edit-area">
            <Col md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Status
                  </FieldLabel>
                  <FieldSelect
                   placeholder='Status'
                   keyParam="status"
                   value={this.props.userAvailability.data.status}
                   type={this.props.userAvailability.type}
                   options={[{label: 'Not Available', value: 2}, {label: 'On Leave', value: 1}]}
                   updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, status: value}});this.setState({availibility_error: {...availibility_error, status: ''}})}}
                   labelKey='label'
                   valueKey='value'
                   name=""
                 />
                <h6 className="error-msg">{availibility_error.from_date}</h6>
               </FieldGroup>
            </Col>
            <Col md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Start Date <sup className="reqStar"> * </sup>
                  </FieldLabel>
                   <FieldDate
                    value={this.props.userAvailability.data.from_date != '' ? this.props.userAvailability.data.from_date : '--'}
                    type='edit'
                    minDate={moment()}
                    keyParam="from_date"
                    updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, from_date: moment(value).format('YYYY-MM-DD')}});this.setState({availibility_error: {...availibility_error, from_date: ''}})}}
                    readOnly={true}
                  />
                <h6 className="error-msg">{availibility_error.from_date}</h6>
               </FieldGroup>
            </Col>
            <Col md="12">
              <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    End Date <sup className="reqStar"> * </sup>
                    </FieldLabel>
                     <FieldDate
                      value={this.props.userAvailability.data.to_date != '' ? this.props.userAvailability.data.to_date : '--'}
                      type='edit'
                      minDate={moment(this.props.userAvailability.data.from_date)}
                      keyParam="to_date"
                      updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, to_date: moment(value).format('YYYY-MM-DD')}});this.setState({availibility_error: {...availibility_error, to_date: ''}})}}
                    />
                  <h6 className="error-msg">{availibility_error.to_date}</h6>
                 </FieldGroup>
            </Col>
            <Col md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label" style={{display: 'block'}}>
                  Remarks <sup className="reqStar"> * </sup>
                  </FieldLabel>
                  <FieldTextarea
                    value={this.props.userAvailability.data.remarks != '' ? this.props.userAvailability.data.remarks : ''}
                    type="edit"
                    keyParam="name"
                    updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, remarks: value}});this.setState({availibility_error: {...availibility_error, remarks: ''}})}}
                  />
                <h6 className="error-msg">{availibility_error.remarks}</h6>
               </FieldGroup>
            </Col>
          </Row>
          <div className="edit-sidebar-footer" style={this.props.userAvailability.flag ? {width: '400px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 400) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button disabled={this.props.processing} onClick={(e) => this.addEditAvailabilityFn()} type="button" className="btn btn-primary">{this.props.processing ? 'Processing...':'Save Changes'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={() => this.props.addEditAvailability({flag: false, type: 'add', data: {from_date: moment().format('YYYY-MM-DD'),to_date: '',remarks: '',status: 1}})}> Cancel</Link>
                </li>
              </ul>
            </div>
        </EditSidebar>
        :null
      }
      {
        downloadLoader?
        <ListLoader/>:
        null
      }
    </div>
    )
  }
}
const mapStateToProps = state => ({
  userProfileInfo: state.userAssetReducer.userProfileInfo,
  userProfileLoader:state.userAssetReducer.userProfileLoader,
  downloadLoader:state.userAssetReducer.downloadLoader,
  processing:state.userAssetReducer.processing,
  userContracts:state.userAssetReducer.userContracts,
  userContract:state.userAssetReducer.userContract,
  type:state.userAssetReducer.type,
  groupList:state.userAssetReducer.groupList,
  groupLoader:state.userAssetReducer.groupLoader,
  techConstants:state.sharedReducers.techConstants,
  deleteExpLoader:state.userAssetReducer.deleteExpLoader,
  experienceData:state.userAssetReducer.experienceData,
  userAvailability: state.userAssetReducer.userAvailability,
  userAvailabilityList: state.userAssetReducer.userAvailabilityList,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchUserProfile: () => dispatch(userProfileAc()),
    updateUserProjects: (formdata) => dispatch(updateUserProjectsAc(formdata, getLocalStorageInfo().user.slug)),
    fetchProjectDepartment: (type) => dispatch(sGlobalConstantAc(type)),
    userInfoUpdate: (formdata) => dispatch(editProfileAc(formdata)),
    getGroupsList: (slug) => dispatch(getGroupsSuperListAc(slug)),
    addUserGroup:(userGroups) => dispatch(deleteSuperGroupAc({add_sparta_group_list:userGroups})),
    deleteUserGroup: (userGroups) => dispatch(deleteSuperGroupAc({delete_sparta_group_list:userGroups})),
    removeExp:(expId)=>dispatch(deleteExpAc(getLocalStorageInfo().user.slug,expId)),
    exportPermision: () => dispatch(exportPermisionAc()),
    typeUpdate: (value) => dispatch({
       type:TYPE_UPDATE,
       payload:value
    }),
    updateGroupLoader:() => dispatch({
      type:GROUP_LOADER_UPDATE,
    }),
    updateSecurityGroupsSearch: (value) => dispatch({
      type: FILTER_SECURITY_GRP_LIST,
      payload: value
    }),
    addEditContract: (data) => dispatch({
      type: USER_CONTRACT_CRUD,
      payload: data
    }),
    exportCV: (user_slug, user_name) => dispatch(exportCVAc(user_slug, user_name)),
    addEditUserContract: (user, contractCrud) => dispatch(addEditUserContractAc(user, contractCrud)),
    toggleExpereience:(data) => dispatch({
      type: ADD_UPDATE_EXPEREIENCE,
      payload: data
    }),
    addEditAvailability: (data) => dispatch({
      type: USER_AVAILABILITY_CRUD,
      payload: data
    }),
    addEditExperience: (data, expId) => dispatch(addEditExperienceAc({id:getLocalStorageInfo().user.slug}, data, expId)),
    userStatusUpdate:(userInfo, data) => dispatch(userStatusUpdateAc({id: userInfo.slug}, data)),
    deleteUserStatus:(userInfo,id) => dispatch(deleteUserStatusAc({id: userInfo.slug}, id)),
    updateUserField: (object) => dispatch({
      type: UPDATE_USER_FIELD,
      payload: object
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
