import React, { Component } from 'react';
import { Button, Popover, PopoverBody} from 'reactstrap';
import { DeleteModal } from '../../../Elements'
import { imgStoragePath } from '../../../../../constants';
export default class PopoverItem extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  toggleDelModal = ( ) => {
    this.setState(prevState =>({
      ...prevState,
      delModal: !prevState.delModal,
      modalTitle: 'Are you sure to delete?',
    }));
  }

  render() {
    const {  tagetId, placement, files } = this.props;
    return (
        <span>
       { files.length>0 ?
       <span>
        <img className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath + (this.state.popoverOpen ? 'eye_color.png' : 'eye_gray.png') } alt="img"/>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
              { files.map((file,index) =>
                <div className="popover-div">
                   <span style={{marginBottom:'5px'}} >
                   <img className="pdf-icon" src={imgStoragePath+"pdf.png"}  alt="icon" />
                   <span className="popover-link" >{file}</span>
                   <img className="delete-icon" onClick={() => {this.toggle();this.toggleDelModal('')}} src={imgStoragePath+"delete.png"} alt="icon" />
                   </span>
                </div>
              )}
            </PopoverBody>
        </Popover>
        </span>:null
       }
       <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => this.toggleDelModal('')}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
              </li>
          </ul>
       </DeleteModal>
        </span>

    );
  }
}
