import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal, ModalHeader, ModalBody,Col,TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Table, UncontrolledTooltip } from 'reactstrap';
import '../../../assets/styles/technical_inspections.scss';
import { EditFormBar, ImageListRow, SortableImageCard, DropFiles, ImageSlider, DeleteModal, ContentDrop, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, ImageAnnotator, DownloadedLogs } from '../../../Elements';
import { fetchGenPicturesAc, addGenPicturesAc, importGeneralPicAc, editGenPictureAc, deleteGenPicAc, importGenInReprtAc, removeGenPicAc, saveEditedImageAc, sortPicturesAc, getFormDetailsAc, updateFormStatusAc,addPictureSegregationAc,fetchPictureSegregationAc, moveSegregationImagesAc, publishSegregationImagesAc, deletePictureSegregationAc } from '../actionCreators';
import { imgStoragePath,noteFormsText } from '../../../../../constants';
import { MSNSwitcherHeader, ListLoader,ExportFiles, TableUIComp,LicenceModal } from '../../../../../shared';
import { downLoadTechFormAc } from '../../../../../shared/actionCreators'
import { BUY_LICENCE } from '../../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../../utils'
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { DELETE_GENPICTURE, UPDATE_IMPORT_GEN_PIC, IMPORT_GEN_PIC, EDIT_GEN_IMAGE, SELECT_SEG_IMG, TOGGLE_MOVE_SECTION, SELECT_MOVE_SECTION, AI_WAIT_TIMER } from '../actions';
import { arrayMove } from 'react-sortable-hoc';
import classnames from 'classnames';
class InspectionFormT001 extends Component {
  constructor(props){
    super(props);
    this.state = {
      dragOver: false,
      modalTitle: '',
      modal: false,
      delModal: false,
      delAIModal: false,
      publishModal: false,
      nonSupportFileModel: false,
      nonSupportFiles: [],
      picId: null,
      sectionId: null,
      imageSlider: {
        images: [],
        startIndex: 0
      },
      imagePath: '',
      angle: 45,
      image : '',
      filesToUpload: [],
      editedImage: '',
      downloadFormLogs: false,
      activeTab: '1',
      moveSections:[]
    }
  }
  componentDidMount(){
    this.props.fetchGenPictures();
    this.props.fetchPictureSegregation()
    this.props.getFormDetails(1, 'T001');
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      this.props.usageDetail();
    }
  }
  toggle = () => {
    this.setState({
    modal: !this.state.modal,
      modalTitle: ''
    });
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }));
  }
  toggleAIDelModal = () => {
    this.setState(prevState => ({
      ...prevState,
      delAIModal: !prevState.delAIModal
    }));
    if(this.state.delAIModal){
      this.props.toggleMoveSections({flag: false});
    }
  }
  toggleDelModal = (title=null, id=null, sectionId=null) => {
    this.setState({
      delModal: !this.state.delModal,
      modalTitle: title,
      picId: id,
      sectionId: sectionId
    });
  }
  sortFiles = (args, listId, pictures) => {
    if(args[0].oldIndex != args[0].newIndex){
      let sortedPictures = [];
      pictures = arrayMove(pictures, args[0].oldIndex, args[0].newIndex);
      pictures.map(picture => sortedPictures.push(picture.id))
      this.props.sortPictures(sortedPictures, listId, pictures);
    }
  }
  openSlider = (pictures, startIndex,title) => {
    let items = [];
    pictures.map(picture => {
      items.push({original: picture.image, thumbnail: picture.image})
    });
    this.setState(prevState => ({
      ...prevState,
      modal: !prevState.modal,
      modalTitle:title,
      imageSlider: {
        ...prevState.imageSlider,
        images: items,
        startIndex: startIndex
      }
    }));
  }

  filesUpload = (status, id, files) => {
    this.setState({
      dragOver: false,
      filesToUpload: files
    });
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.addGenPictures(status, id, files);
  }

  filesUploadPictureSegregation = (files) => {
    this.setState({
      dragOver: false,
    });
    let filesExtension = [];
    let properExtension = [];
    for(let file of files){
      if(!['jpg','jpeg', 'png'].includes(file.name.split('.').reverse()[0].toLowerCase())){
        filesExtension = [...filesExtension, file];
      }
    }
    this.props.toggleWaitTimer({flag: true});
    if(filesExtension.length){
      this.setState(prevState => ({
        ...prevState,
        nonSupportFileModel: !prevState.nonSupportFileModel,
        nonSupportFiles: filesExtension
      }))
    }else{
      if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
        let filesSize = 0
        for(let file of files){
          filesSize += file.size
        }
        if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
          this.props.buyLicence({
            flag: true,
            action: '/licenses/storage',
            content: 'The uploaded file(s) size is not available in storage.'
          })
          return;
        }
      }
      this.props.addPictureSegregation(files);
    }
  }
  moveTheImages = ()  => {
    const { moveImages } = this.props;
    if(moveImages.sectionId){
      this.props.moveTheImagesFn(moveImages);
    }
  }
  saveImage = () =>{
    document.querySelector("#annotator").contentWindow.document.querySelector('#btn-download').click();
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        editedImage: document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src')
      }))
      this.props.saveEditedImage(this.props.editGenPicCrud.data.id, document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src'))
    }, 1000)
  }

  onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  onDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver: false,
    })
  }

  tabToggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  searchAircraftSection = (name) => {
    const { generalPictures } = this.props;
    this.setState(prevState => ({
      ...prevState,
      moveSections: generalPictures.filter(section => section.name.toLowerCase().includes(name.toLowerCase()))
    }));
  }


  render(){
    const { genSortLoader, aiWaitTimer, generalPictures, importGnPicCrud, editGenPicCrud, genPicLoader, imageUploading, techExportLoader, formStatusLoader, formInfo,pictureSegregation, moveImages } = this.props;
    const assetTypes = {
      1: 'MSN',
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return(
      <div className="technical-inspection-cn" onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project} />}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('general-pictures',assetTypes[formInfo.asset.asset_type]+'_'+(formInfo.asset.msn ? formInfo.asset.msn : formInfo.asset.serial_number)+'_'+formInfo.current_form.name,fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'}, {img:'docx_exp_icon.png', label: 'Document', type:'docx'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />
        <div className="profile-tab" style={{marginTop:"20px"}}>
          { techExportLoader || genPicLoader || formStatusLoader ? <ListLoader drawer="primary-nav" />:null }
          <Nav tabs className="commons-tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.tabToggle('1'); }}
                style={{fontSize:"unset"}}
              >
                General Pictures
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.tabToggle('2'); }}
                style={{fontSize:"unset"}}
              >
                { aiWaitTimer ? <img src={imgStoragePath+'rolling.gif'} width="22" />:null}
                Image Segregation using AI
                <sup style={{color: '#ff0000', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-Bold', padding: '0 2px'}}>BETA</sup>
                <i style={{fontSize: '12px', paddingLeft: '2px'}} class="fa fa-info-circle" aria-hidden="true" id="Image-Segregation"></i>
                <UncontrolledTooltip placement="right" target="Image-Segregation">
                  Auto-segregate and populate inspection images using Artificial Intelligence.
                </UncontrolledTooltip>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">

              { generalPictures.length?
                <div className="form-t001-cn">
                  <Col sm="12">
                    <p className="notice-info" style={{margin:'10px -15px'}}>{noteFormsText}</p>
                  </Col>
                  { generalPictures.map((list, index) =>
                    <ImageListRow
                      genSortLoader={genSortLoader}
                      distance="50"
                      key={index}
                      items={list.pictures}
                      toggleDelModal={this.toggleDelModal}
                      openSlider={this.openSlider}
                      editGenPicture={this.props.editGenPicture}
                      collection={list.id}
                      onSortEnd={(...args) => this.sortFiles(args, list.id, list.pictures)}
                      axis="xy"
                      toggleEditSideBar={() => this.props.importGeneralPic(list.pictures.length, {status: 0, aircraft_section_id: list.id})}
                      list={list}
                      activityOp = {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? ['E', 'R', 'V']: ['V']}
                    >
                      { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && list.pictures.length < (list.id == 19 ? 10:5) ?
                        <li className="list-inline-item dropable-zone"  onDrop={(evt) => this.filesUpload(1, list.id, evt.dataTransfer.files)} style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}} >
                          <ContentDrop mutlipleEnabled={true} acceptExtensions={'image/*'} filesUpload={(evt) => this.filesUpload(1, list.id, evt.target.files)} />
                          <span className="img-info-msg">
                            Recommended Picture dimension 400 X 400 Pixels
                          </span>
                        </li>
                        :null
                      }
                    </ImageListRow>
                  )}
                </div>:null
              }
            </TabPane>
            <TabPane tabId="2">
              { aiWaitTimer ?
                <p style={{textAlign: 'center', margin: '15px 0'}}>
                  <span style={{background: '#d1ecf1', color: '#0c5460', border: '1px solid #bee5eb', padding: '4px', fontSize: '12px', borderRadius: '3px'}}>
                    Uploaded images are being processed. Do not close or refresh the window. You may continue to work on other functions in SPARTA
                  </span>
                </p>
                :null
              }
              <Row>
                <Col md='5'>
                  <h4 style={{fontSize: '14px'}}>
                    NOTE: Images uploaded in bulk will be processed and auto-populated in applicable sections. An email will be sent once the process is complete.
                  </h4>
                  <div
                    className="dropable-zone"
                    onDrop={(evt) => this.filesUploadPictureSegregation(evt.dataTransfer.files)}
                    style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)', width: '100%', height: '300px'} : { background: 'transparent', width: '100%', height: '300px', marginTop:"20px"}}>
                    <ContentDrop
                      acceptExtensions={'image/*'}
                      mutlipleEnabled={true}
                      filesUpload={(evt) => this.filesUploadPictureSegregation(evt.target.files)}
                    />
                    <span className="img-info-msg">
                      Recommended Picture dimension 400 X 400 Pixels, and format .png, .jpg, .jpeg
                    </span>
                  </div>
                </Col>
                { pictureSegregation.batches && pictureSegregation.batches.length ?
                  <Col md="7">
                    <Table bordered>
                      <thead className="users-list">
                        <tr>
                          <th>Batch</th>
                          <th>Uploaded by</th>
                          <th>Status</th>
                          <th>No. of Images</th>
                        </tr>
                      </thead>
                      <tbody>
                        { pictureSegregation.batches.map((batch, index) =>
                          <tr>
                            <td>{ batch.name }</td>
                            <td>{ batch.created_by.first_name }</td>
                            <td>{ batch.status ? <img src={imgStoragePath+'select_user_checked.png'} width="22" />: <img src={imgStoragePath+'rolling.gif'} width="22" /> }</td>
                            <td>{ batch.file_count }</td>
                          </tr>

                        )}
                        <tr>
                          <td colSpan="3">Total Images</td>
                          <td>{pictureSegregation.batches.reduce((total, next) => total + next.file_count,0)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  :null
                }
              </Row>
              { pictureSegregation.predicted_sections && pictureSegregation.predicted_sections.length ?
                <div style={{borderTop: '1px solid #d7d7d7', marginTop: '30px', paddingTop: '30px'}}>
                  <div style={{clear: 'both'}}>
                    <ul className="list-inline" style={{float: 'right'}}>
                      { moveImages.imageIds.length ?
                        <Fragment>
                          <li className="list-inline-item">
                            <span style={{color: '#dc3545'}} onClick={() => this.toggleAIDelModal()}>Delete</span>
                          </li>
                          <li className="list-inline-item" style={{padding: '0 15px'}}>
                            <span style={{color: '#2f8cff'}} onClick={() => {this.props.toggleMoveSections({flag: true}); this.setState({moveSections: generalPictures})}}>Move</span>
                          </li>
                        </Fragment>:null
                      }
                      <li className="list-inline-item">
                        <button className="primary-btn" onClick={() => { this.setState(prevState => ({...prevState, publishModal: true}))}}>Publish</button>
                      </li>
                    </ul>
                  </div>
                  <div style={{clear: 'both'}}>
                    { pictureSegregation.predicted_sections ?
                      pictureSegregation.predicted_sections.map((section, index) =>
                      section.pictures.length ?
                      <div className="image-row">
                        <div style={{marginBottom: '20px'}}>
                          <h4 style={{marginBottom:'6px'}}>
                            {section.name.charAt(0).toUpperCase() + section.name.slice(1)}
                          </h4>
                          { !section.id ?
                            <span style={{background: '#fff3cd', border: '1px solid #ffeeba', color: '#856404', borderRadius: '43px', fontSize: '12px', padding: '5px 10px'}}>
                              AI Bot unsure of image(s) segregation. Review and move image(s) to appropriate section of aircraft or delete as necessary
                            </span>:null}
                        </div>
                        <ul className="list-inline" style={{minHeight: '200px'}}>
                          { section.pictures.map((picture, picIndex) =>
                            <li className="list-inline-item form-img-card">
                              <input
                                type="checkbox"
                                onChange={(e) => this.props.selectMLImage({checked: e.target.checked, id: picture.id})}
                                checked={moveImages.imageIds.includes(picture.id)}
                                style={{position: 'absolute', top: '5px', left: '5px', zIndex: 1, cursor: 'pointer'}}
                              />
                              <div className="">
                                <div style={{backgroundImage: "url(" + picture.image +")", width: '200px', height: '200px', backgroundRepeat:'no-repeat', backgroundPosition: '50% center', backgroundSize: 'cover'}}>

                                </div>
                                <div className="storage-action-block">
                                  <span className="image-card-action">
                                    <span onClick={() => { this.props.toggleMoveSections({flag: true, picId: [picture.id]}); this.setState({moveSections: generalPictures})} }>Move</span>
                                    <span onClick={() => {this.toggleAIDelModal(); this.props.selectMLImage({checked: true, id: picture.id})}}>Delete</span>
                                    <span onClick={()=>this.openSlider(section.pictures, picIndex, section.name )}>View</span>
                                  </span>
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>:null
                      ):null
                    }
                  </div>
                </div>:null
              }
            </TabPane>
          </TabContent>
        </div>
        <EditFormBar
          titleContent={ <div><h3>{importGnPicCrud.flag ? importGnPicCrud.data.name:''} Picture Gallery</h3><p>Store all the {importGnPicCrud.flag ? importGnPicCrud.data.name:''} pictures other than to be added in the report</p></div>}
          toggleEditSideBar={() => this.props.closeImageSideBar(IMPORT_GEN_PIC, {flag: false, data: {}, selected: []})}
          isOpen={importGnPicCrud.flag}
          style={importGnPicCrud.flag ? {width: '670px'} : {width: '0px'}}
        >
          { importGnPicCrud.flag ?
            <div style={{position: 'relative'}}>
              <div className="sb-body" style={{ height: (window.innerHeight - 90) + 'px', overflow:'auto'}}>
                <div className="technical-form-images">
                  <ul className="list-inline flex-centered">
                    { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                      <li className="list-inline-item dropable-zone" onDrop={(evt) => this.filesUpload(0, importGnPicCrud.data.id, evt.dataTransfer.files)} style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}} >
                        <ContentDrop mutlipleEnabled={true} acceptExtensions={'image/*'} filesUpload={(evt) => this.filesUpload(0, importGnPicCrud.data.id, evt.target.files)} />
                        <span className="img-info-msg">
                          Recommended Picture dimension 400 X 400 Pixels
                        </span>
                      </li>:null
                    }
                    { importGnPicCrud.data.pictures.map((item, index) =>
                      <li
                        key={index}
                        onClick={() =>  formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? this.props.toggleImages(item):null}
                        className={importGnPicCrud.selected.includes(item.id) ? 'list-inline-item selected': 'list-inline-item'}
                        style={{  backgroundImage: "url(" + item.image + ")"}}>
                        <div style={{ backgroundImage: "url(" + imgStoragePath + "lazy_load.png)"}}></div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="edit-sidebar-footer">
                <ul className="list-inline flex-centered">
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                    <li className="list-inline-item">
                      <button disabled={!((importGnPicCrud.inReportCount < (importGnPicCrud.data.id == 19 ? 10:5)) && importGnPicCrud.selected.length) || (this.state.filesToUpload.length > (importGnPicCrud.data.id == 19 ? 10:5)) || (importGnPicCrud.inReportCount + (importGnPicCrud.selected.length) > (importGnPicCrud.data.id == 19 ? 10:5))} onClick={() => this.props.importGenInReprt(importGnPicCrud)} className="import-picture">
                        <img src={imgStoragePath+"import_images_icons.png"} /> Import Selected
                      </button>
                    </li>:null
                  }
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                    <li className="list-inline-item">
                      <button disabled={!(importGnPicCrud.data.pictures.length && importGnPicCrud.selected.length)} onClick={() => this.props.deleteGenPic(importGnPicCrud)} className="delete-picture">
                        <img src={imgStoragePath + "delete_red.png"} /> Delete Selected
                      </button>
                    </li>:null
                  }
                  <li className="list-inline-item">
                    <Link onClick={() => this.props.closeImageSideBar(IMPORT_GEN_PIC, {flag: false, data: {}, selected: []})}>Cancel</Link>
                  </li>
                </ul>
              </div>
            </div>
            :null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={ <div><h3>Edit {editGenPicCrud.data.name} Picture</h3></div>}
          toggleEditSideBar={() => this.props.closeImageSideBar(EDIT_GEN_IMAGE, {flag: false, data: {}})}
          isOpen={editGenPicCrud.flag}
          style={editGenPicCrud.flag ? {width: '840px'} : {width: '0px'}}
        >
          { editGenPicCrud.flag ?
            <div className="sidebar-form">
              <ImageAnnotator style={{marginLeft: '20px'}} markup={false} image={editGenPicCrud.data.image} width="800" height="500" color="ff0000" fontSize="12"/>
            </div>:null
          }
          <div className="edit-sidebar-footer" style={editGenPicCrud.flag ? {width: '840px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 840) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.props.genPicLoader} onClick={this.saveImage} type="submit" className="btn btn-primary">{this.props.genPicLoader ? 'Processing...':'Save Changes'}</button>
              </li>
              <li className="list-inline-item">
                <Link onClick={() => this.props.closeImageSideBar(EDIT_GEN_IMAGE, {flag: false, data: {}})}> Cancel</Link>
              </li>
            </ul>
          </div>
        </EditFormBar>
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={this.state.modalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal('');this.props.removeGenPic(this.state.picId, this.state.sectionId)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <DeleteModal isOpen={this.state.delAIModal} toggle={() => this.toggleAIDelModal()} title='Delete Image' className="modal-tform-delete-pictures modal-dialog-centered">
          <h4 className="text-center" style={{marginBottom: '30px'}}>Are you sure you <br/>want to delete Image's</h4>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleAIDelModal(); this.props.deleteAIImages(moveImages)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleAIDelModal()}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-tform-pictures">
          <ModalHeader toggle={this.toggle}>{this.state.modalTitle}</ModalHeader>
          <ModalBody>
            <ImageSlider imageSlider={this.state.imageSlider} />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.nonSupportFileModel} toggle={() => { this.setState(prevState => ({...prevState, nonSupportFileModel: !prevState.nonSupportFileModel}))}} className="modal-tform-pictures">
          <ModalHeader toggle={() => { this.setState(prevState => ({...prevState, nonSupportFileModel: !prevState.nonSupportFileModel}))}}>Non supported Files format</ModalHeader>
          <ModalBody style={{textAlign: 'center', padding: '60px 0'}}>
            <h4 style={{fontSize: '18px'}}>We found {this.state.nonSupportFiles.length} file's not in supported format</h4>
            <span style={{fontSize: '12px'}}>supporting format: jpg, jpeg, png</span>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.publishModal} toggle={() => { this.setState(prevState => ({...prevState, publishModal: !prevState.publishModal}))}} className="modal-tform-pictures" size="xs">
          <ModalHeader toggle={() => { this.setState(prevState => ({...prevState, publishModal: !prevState.publishModal}))}}>Publish Image's</ModalHeader>
          <ModalBody style={{textAlign: 'center', padding: '60px 0'}}>
            <h4 style={{fontSize: '18px', marginBottom: '20px'}}>
              Publishing the images will remove the remaining<br/> images and batches info from the form.
            </h4>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button className="primary-btn" onClick={() => { this.setState(prevState => ({...prevState, publishModal: false}));this.props.publishAIImages(pictureSegregation.batches ? pictureSegregation.batches:[])}}>Proceed</button>
              </li>
              <li className="list-inline-item" style={{cursor: 'pointer', color: '#2f8cff', fontSize:'12px'}} onClick={() => { this.setState(prevState => ({...prevState, publishModal: false}))}}>Cancel</li>
            </ul>
          </ModalBody>
        </Modal>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Move to Sections</h3></div>}
          toggleEditSideBar={() => this.props.toggleMoveSections({flag: false})}
          isOpen={moveImages.flag}
          style={moveImages.flag ? {width: '400px'} : {width: '0px'}}
        >
          { moveImages.flag ?
            <div style={{padding: '0 20px 90px 20px', height: window.innerHeight - 80, overflow: 'auto'}}>
              <input type="text" placeholder="Search..." onChange={(e) => this.searchAircraftSection(e.target.value)} />
              <ul className="list-unstyled">
                { this.state.moveSections.map((section, index) =>
                  <li style={{padding: '8px 0', borderBottom: '1px solid #d7d7d7'}}>
                    <label>
                      <input
                        onChange={() => this.props.selectTheMoveSec({sectionId:section.id})}
                        name="general-pic"
                        type="radio"
                        style={{marginRight: '10px'}}
                        checked={section.id == moveImages.sectionId ? true:false}
                      />
                        {section.name}
                    </label>
                  </li>
                )}
              </ul>
            </div>:null
          }

          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button className="primary-btn" onClick={() => this.moveTheImages()}>MOVE</button>
              </li>
              <li className="list-inline-item" style={{color: '#2f8cff', fontSize: '14px', cursor:'pointer'}} onClick={() => this.props.toggleMoveSections({flag: false})}>Cancel</li>
            </ul>
          </div>
        </EditFormBar>
        { this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="2"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
          />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  generalPictures: state.FormT001Reducer.generalPictures,
  importGnPicCrud: state.FormT001Reducer.importGnPicCrud,
  editGenPicCrud: state.FormT001Reducer.editGenPicCrud,
  genPicLoader: state.FormT001Reducer.genPicLoader,
  genSortLoader: state.FormT001Reducer.genSortLoader,
  formInfo: state.FormT001Reducer.formInfo,
  techExportLoader:state.sharedReducers.techExportLoader,
  formStatusLoader:state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.DataRoomReducer.storageUsage,
  pictureSegregation:state.FormT001Reducer.pictureSegregation,
  moveImages:state.FormT001Reducer.moveImages,
  aiWaitTimer:state.FormT001Reducer.aiWaitTimer
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return {
    fetchGenPictures: () => dispatch(fetchGenPicturesAc(ownProps)),
    fetchPictureSegregation:() => dispatch(fetchPictureSegregationAc(ownProps)),
    toggleWaitTimer: (data) => dispatch({type: AI_WAIT_TIMER, payload: data}),
    addGenPictures: (status, id, files) => dispatch(addGenPicturesAc(ownProps,status, id,files)),
    addPictureSegregation:(files) =>dispatch(addPictureSegregationAc(ownProps, files)),
    moveTheImagesFn: (data) => dispatch(moveSegregationImagesAc(ownProps, data)),
    deleteAIImages: (data) => dispatch(deletePictureSegregationAc(ownProps, data)),
    publishAIImages: (data) => dispatch(publishSegregationImagesAc(ownProps, data)),
    selectMLImage: (data) => dispatch({type: SELECT_SEG_IMG, payload: data}),
    selectTheMoveSec: (data) => dispatch({type: SELECT_MOVE_SECTION, payload: data}),
    toggleMoveSections: (data) => dispatch({type: TOGGLE_MOVE_SECTION, payload: data}),
    importGeneralPic: (inReportCount, queryParam) => dispatch(importGeneralPicAc(ownProps, inReportCount, queryParam)),
    importGenInReprt: (importGnPicCrud) => dispatch(importGenInReprtAc(ownProps, importGnPicCrud)),
    editGenPicture: (data) => dispatch(editGenPictureAc(ownProps, data)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    removeGenPic: (id, sectionId) => dispatch(removeGenPicAc(ownProps, id, sectionId)),
    deleteGenPic: (importGnPicCrud) => dispatch(deleteGenPicAc(ownProps, importGnPicCrud)),
    toggleImages: (data) => dispatch({type: UPDATE_IMPORT_GEN_PIC,payload: data}),
    closeImageSideBar: (type, data) => dispatch({type: type,payload: data}),
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    saveEditedImage:(imageId, image) => dispatch(saveEditedImageAc(ownProps, imageId, image)),
    sortPictures: (sortedPictures, listId, pictures) => dispatch(sortPicturesAc(ownProps, sortedPictures, listId, pictures)),
    usageDetail:() => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({type: BUY_LICENCE,payload: modalObj})
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT001);
