import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

export default class EngineHeader extends Component{
  constructor(props){
    super(props);
    }

    render(){
      const enigneHeaderObj = [
        {label: 'Module', size: 1, align: 'text-left' },
        {label: 'Description', size: 3, align: 'text-left' },
        {label: 'Part Number', size: 2, align: 'text-left' },
        {label: 'Cycles Max Limit', size: 2, align: 'text-left' },
        {label: 'Cycles Remaining', size: 2, align: 'text-left' },
        {label: 'Price', size: 1, align: 'text-left' },
        {label: 'Action', size: 1, align: 'text-right' },
    ];
      return(

        <Row className="filter-block flex-not-centered" >
        	{
            enigneHeaderObj.map((engine,index) => 
              <Col md={engine.size} key={index} className={engine.align}>
               <span> {engine.label} </span>
              </Col>
          
          )}
      </Row>         
      )
    }
  }

