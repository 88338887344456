import * as actions from '../actions';
import moment from 'moment';
import produce from 'immer';
import { getLocalStorageInfo } from '../../../../utils';
const genFinanceInfoData = {
  metricsInfo: [],
  use_acumen_algorithm: (getLocalStorageInfo() != '' && getLocalStorageInfo() != null && getLocalStorageInfo() != undefined) ? getLocalStorageInfo().defaultLessor? getLocalStorageInfo().defaultLessor.id == 24 ? false : true :true:true,
  airframeAndGen: {
    aircraftType: '',
    dateOfManufacture: '',
    maintenanceProgram: '',
    tsn: '',
    csn: '',
    utlizationRatio: '',
    hourlyUtlization: '',
    cycleUtlization: ''
  },
  engine: {
    engineType: '',
    noOfEngine: 1,
    regionOfOperation: '',
    utlizationRatio: '',
    variable_condition_id: []
  }
};
const regex = /^[0-9]*$/;
const numberRegx = /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/;
const logFilterData = {
  aircraft_type: '',
  engine_type:'',
  startDate : '',
  endDate: '',
  sort: '',
  sort_by: '',
  log_id: '',
  page: '1'
};
export default function( state = {
  logListLoader: true,
  genFinanceLoader: false,
  reviewMRLoader: false,
  llpExpLoader: false,
  reviewMRReport: {},
  genFinanceInfo: genFinanceInfoData,
  regionsList: [],
  regionCondition: {},
  customizeRegionType: {},
  clients: [],
  aircraftTypeList: [],
  engineTypeList: [],
  aircraftEngineTypeList: [],
  engineUtilRatio:[],
  aircraftMainPrg: [],
  logsInfo: {
    pagination: {},
    list: []
  },
  logFilter: logFilterData,
  selectLLPToReplace: {
    modalStatus: false,
    loader: false,
    list: []
  },
  costOfYears: [],
  breakUpLLPMRCost: {
    modalStatus: false,
    list: []
  },
  llpToBeReplaced: {
    modalStatus: false,
    list: []
  },
  breakUpEngineMRCost: {
    modalStatus: false,
    module_percentages: {
      Core: {},
      LPT: {},
      Fan: {}
    }
  },
  customCycleRemain: {
    modalStatus: false,
    wrongEntry: '',
    list: {},
    originalList: {}
  },
  regionInfo: {},
  mrUsage: {}
}, action){
  switch (action.type) {
    case actions.GEN_FINANCE_LOADER:
      return {
        ...state,
        genFinanceLoader: action.payload
      }
    case actions.MR_USAGE:
      return {
        ...state,
        mrUsage: action.payload
      }
    case actions.REVIEW_MR_LOADER:
      return {
        ...state,
        reviewMRLoader: action.payload
      }
    case actions.LOGS_LOADER:
      return {
        ...state,
        logListLoader: action.payload
      }
    case actions.UPDATE_MR_CLIENTS:
      return {
        ...state,
        clients: action.payload
      }
    case actions.UPDATE_FINANCE_METRIX:

      if (state.genFinanceInfo.metricsInfo.indexOf(action.payload) > -1) {
        return {
          ...state,
          genFinanceInfo: {
            ...state.genFinanceInfo,
            metricsInfo: produce(state.genFinanceInfo.metricsInfo, draft => {
                draft.splice(draft.indexOf(action.payload),1)
              }),
          }
        }
      }else{
        return {
          ...state,
          genFinanceInfo: {
            ...state.genFinanceInfo,
            metricsInfo: produce(state.genFinanceInfo.metricsInfo, draft => {
                draft.push(action.payload);
              }),
          }
        }
      }
    case actions.UPDATE_LESSOR_ID:
      return {
        ...state,
        genFinanceInfo: {
          ...state.genFinanceInfo,
          lessorId: action.payload,
        }
      }
    case actions.USE_CDB_ALGO:
      return {
        ...state,
        genFinanceInfo: {
          ...state.genFinanceInfo,
          use_acumen_algorithm: action.payload
        }
      }
    case actions.UPDATE_AIRFRAME_FIELD:
      if(action.payload.type == 'tsn' || action.payload.type == 'csn'){
        let utilRatio = '';
        if(action.payload.type == 'tsn'){
          utilRatio = action.payload.value/state.genFinanceInfo.airframeAndGen.csn;
          if(numberRegx.test(utilRatio)){
            return {
              ...state,
              genFinanceInfo: {
                ...state.genFinanceInfo,
                airframeAndGen: {
                  ...state.genFinanceInfo.airframeAndGen,
                  utlizationRatio: utilRatio.toFixed(1),
                  tsn: action.payload.value
                }
              }
            }
          }else{
            return {
              ...state,
              genFinanceInfo: {
                ...state.genFinanceInfo,
                airframeAndGen: produce(state.genFinanceInfo.airframeAndGen, draft => {
                  draft[action.payload.type]= action.payload.value
                })
              }
            }
          }
        }
        if(action.payload.type == 'csn'){
          utilRatio = state.genFinanceInfo.airframeAndGen.tsn / action.payload.value;
          if(numberRegx.test(utilRatio)){
            return {
              ...state,
              genFinanceInfo: {
                ...state.genFinanceInfo,
                airframeAndGen: {
                  ...state.genFinanceInfo.airframeAndGen,
                  utlizationRatio: utilRatio.toFixed(1),
                  csn: action.payload.value
                }
              }
            }
          }else{
            return {
              ...state,
              genFinanceInfo: {
                ...state.genFinanceInfo,
                airframeAndGen: produce(state.genFinanceInfo.airframeAndGen, draft => {
                  draft[action.payload.type]= action.payload.value
                })
              }
            }
          }
        }
      }else{
        return {
          ...state,
          genFinanceInfo: {
            ...state.genFinanceInfo,
            airframeAndGen: produce(state.genFinanceInfo.airframeAndGen, draft => {
              draft[action.payload.type] = action.payload.value
            })
          }
        }
      }
    case actions.UPDATE_VAR_CONS_IDS: {
      let variable_condition_id = [];
      Object.keys(state.regionCondition).map((label) => {
        if(state.regionCondition[label].default){
          variable_condition_id.push(state.regionCondition[label].default);
        }
      });
      return {
        ...state,
        genFinanceInfo: {
          ...state.genFinanceInfo,
          engine: {
            ...state.genFinanceInfo.engine,
            variable_condition_id: variable_condition_id
          }
        }
      }
    }
    case actions.UPDATE_ENGINE_FIELD:
      if(action.payload.type == 'regionOfOperation'){
        return {
          ...state,
          genFinanceInfo: {
            ...state.genFinanceInfo,
            engine: {
              ...state.genFinanceInfo.engine,
              [action.payload.type]: action.payload.value,
              variable_condition_id: []
            }
          }
        }
      }else if(action.payload.type == 'engineType'){
        return {
          ...state,
          genFinanceInfo: {
            ...state.genFinanceInfo,
            engine: {
              ...state.genFinanceInfo.engine,
              [action.payload.type]: action.payload.value,
              variable_condition_id: []
            }
          },
        }
      }else{
        return {
          ...state,
          genFinanceInfo: {
            ...state.genFinanceInfo,
            engine: {
              ...state.genFinanceInfo.engine,
              [action.payload.type]: action.payload.value
            }
          }
        }
      }
    case actions.REVIEW_MR_DATA:
      let data = Object.assign({}, action.payload);
      if(data.apu){
        if(!data.apu.utilizationType){
          data = {
            ...data,
            apu:{
              ...data.apu,
              utilizationType: 'tsn'
            }
          }
        }
      }
      if(data.landingGear){
        if(!data.landingGear.utilizationType){
          data = {
            ...data,
            landingGear:{
              ...data.landingGear,
              utilizationType: 'tsn'
            }
          }
        }
      }
      return {
        ...state,
        reviewMRReport: data
      }
    case actions.GEN_MR_REPORT:
      return {
        ...state,
        reviewMRReport: {
          ...state.reviewMRReport,
          [action.payload.type]: {
              ...state.reviewMRReport[action.payload.type],
              [action.payload.keyParam]: action.payload.value
          }
        }
      }
    case actions.UPDATE_AIRFRAME_MRDETAIL:
      if(action.payload.inputType == 'date'){
        return {
          ...state,
          reviewMRReport: {
            ...state.reviewMRReport,
              airframe : {
                ...state.reviewMRReport.airframe,
                [action.payload.outerKey]: {
                  ...state.reviewMRReport.airframe[action.payload.outerKey],
                  [action.payload.innerKey] : (moment(action.payload.value).isValid() ? action.payload.value:null)
                }
              }
          }
        }
      }else{
        return {
          ...state,
          reviewMRReport: {
            ...state.reviewMRReport,
              airframe : {
                ...state.reviewMRReport.airframe,
                [action.payload.outerKey]: {
                  ...state.reviewMRReport.airframe[action.payload.outerKey],
                  [action.payload.innerKey] : action.payload.value
                }
              }
          }
        }
      }
    case actions.UPDATE_ENGINE_MRDETAIL:
      if(action.payload.innerkey == 'hour_util'){
        let cycle_utils = state.reviewMRReport.engines[action.payload.outerKey].cycle_util ? state.reviewMRReport.engines[action.payload.outerKey].cycle_util: '0'
        if(regex.test(action.payload.value)){
          cycle_utils = Math.round(action.payload.value/state.reviewMRReport.generalInfo.ratio);
        }
        return {
          ...state,
          reviewMRReport: {
            ...state.reviewMRReport,
            engines: {
              ...state.reviewMRReport.engines,
              [action.payload.outerKey]: {
                ...state.reviewMRReport.engines[action.payload.outerKey],
                [action.payload.innerkey]: action.payload.value,
                cycle_util: cycle_utils
              }
            }
          }
        }
      }else if((action.payload.innerkey == 'lsv_date' || action.payload.innerkey == 'tsn' || action.payload.innerkey == 'csn') && state.reviewMRReport.engines[action.payload.outerKey].timeAndCycleStatus){
        let cycle_utils = state.reviewMRReport.engines[action.payload.outerKey].cycle_util ? state.reviewMRReport.engines[action.payload.outerKey].cycle_util: '0';
        let tsn = action.payload.innerkey == 'tsn' ? action.payload.value : state.reviewMRReport.engines[action.payload.outerKey].tsn ? state.reviewMRReport.engines[action.payload.outerKey].tsn: '0';
        let csn = action.payload.innerkey == 'csn' ? action.payload.value : state.reviewMRReport.engines[action.payload.outerKey].csn ? state.reviewMRReport.engines[action.payload.outerKey].csn: '0';
        let hours = state.reviewMRReport.engines[action.payload.outerKey].hour_util ? state.reviewMRReport.engines[action.payload.outerKey].hour_util: '0';
        let cycle_util = state.reviewMRReport.engines[action.payload.outerKey].cycle_util ? state.reviewMRReport.engines[action.payload.outerKey].cycle_util: '0';
        let lsv_date = (action.payload.innerkey == 'tsn' || action.payload.innerkey == 'csn') ? state.reviewMRReport.engines[action.payload.outerKey].lsv_date : action.payload.value;
        let months = moment().diff(moment(lsv_date), 'months');
        let tsn_at_lsv = tsn - (months * hours);
        let csn_at_lsv = csn - (months * cycle_util);
        return {
          ...state,
          reviewMRReport: {
            ...state.reviewMRReport,
            engines: {
              ...state.reviewMRReport.engines,
              [action.payload.outerKey]: {
                ...state.reviewMRReport.engines[action.payload.outerKey],
                csn_at_lsv: csn_at_lsv > 0 ? csn_at_lsv : 0,
                tsn_at_lsv: tsn_at_lsv > 0 ? tsn_at_lsv : 0,
                cycle_util: cycle_utils,
                lsv_date: lsv_date,
                tsn: tsn,
                csn: csn,
              }
            }
          }
        }
      } else{
        return {
          ...state,
          reviewMRReport: {
            ...state.reviewMRReport,
            engines: {
              ...state.reviewMRReport.engines,
              [action.payload.outerKey]: {
                ...state.reviewMRReport.engines[action.payload.outerKey],
                [action.payload.innerkey]: (action.payload.value == 'Invalid date' ? null:action.payload.value)
              }
            }
          }
        }
      }

    case actions.UPDATE_ENGINE_WS:
      const engineNo = action.payload.request['engine_no'];
      return {
        ...state,
        reviewMRReport: {
          ...state.reviewMRReport,
          engines: {
            ...state.reviewMRReport.engines,
            [engineNo]: {
              ...state.reviewMRReport.engines[engineNo],
              workscopes: action.payload.res.workscope,
              blended_mr: action.payload.res.blended_mr
            }
          }
        }
      }
    case actions.BREAKUP_LLP_MR_COST:
      if(state.breakUpLLPMRCost.modalStatus){
        return {
          ...state,
          breakUpLLPMRCost: {
            ...state.breakUpLLPMRCost,
            engineNo:action.payload.engineNo,
            runType:action.payload.runType,
            modalStatus: false,
            list: []
          }
        }
      }else{
        const breakUpLLPMRCost = state.reviewMRReport.engines[action.payload.engineNo]['workscopes'][action.payload.runType]['LLP'];
        return {
          ...state,
          breakUpLLPMRCost: {
            ...state.breakUpLLPMRCost,
            engineNo:action.payload.engineNo,
            runType:action.payload.runType,
            modalStatus: true,
            list: breakUpLLPMRCost
          }
        }
      }
    case actions.LLP_TO_BE_REPLACED:
      if(state.llpToBeReplaced.modalStatus){
        return {
          ...state,
          llpToBeReplaced: {
            ...state.llpToBeReplaced,
            engineNo:action.payload.engineNo,
            runType:action.payload.runType,
            svd: action.payload.svd,
            runId: action.payload.runId,
            modalStatus: false,
            list: []
          }
        }
      }else{
        const llpToBeReplaced = state.reviewMRReport.engines[action.payload.engineNo]['workscopes'][action.payload.runType]['LLP'];
        return {
          ...state,
          llpToBeReplaced: {
            ...state.llpToBeReplaced,
            engineNo:action.payload.engineNo,
            runType:action.payload.runType,
            svd: action.payload.svd,
            runId: action.payload.runId,
            modalStatus: true,
            list: llpToBeReplaced
          }
        }
      }
    case actions.SELECT_LLP_TO_REPLACE:
      if(action.payload.modalStatus == 'open'){
        return {
          ...state,
          selectLLPToReplace:{
            ...state.selectLLPToReplace,
            modalStatus: true,
            shop_visit_no: action.payload.shop_visit_no,
            log_id: action.payload.log_id,
            engine_no: action.payload.engine_no,
            name: action.payload.name,
            list: action.payload.list,
            loader: false
          },
          costOfYears: action.payload.cost_of_years
        }
      }else{
        return {
          ...state,
          selectLLPToReplace:{
            ...state.selectLLPToReplace,
            modalStatus: false,
            loader: false,
            list:[],
            costOfYears: []
          }
        }
      }
    case actions.LLP_EXP_LOADER:
     return{
      ...state,
      llpExpLoader:action.payload
     }

    case actions.SELECT_LLP_TO_REPLACE_LOADER:
      return {
        ...state,
        selectLLPToReplace: {
          ...state.selectLLPToReplace,
          loader: action.payload.loader,
          shop_visit_no: action.payload.shop_visit_no
        }
      }
    case actions.UPDATE_SELECT_LLP_TO_REPLACE:
      return {
        ...state,
        selectLLPToReplace: {
          ...state.selectLLPToReplace,
          list: produce(state.selectLLPToReplace.list, draft => {
            draft[action.payload.index].replaced=action.payload.value
          })
        }
      }
    case actions.LLP_REPLACED_SUCCESS:
      let engine_number = action.payload.req.engine_no.toString();
      return {
        ...state,
        selectLLPToReplace: {
          ...state.selectLLPToReplace,
          modalStatus: false,
          list: []
        },
        reviewMRReport: {
          ...state.reviewMRReport,
          engines: {
            ...state.reviewMRReport.engines,
            [engine_number]: {
              ...state.reviewMRReport.engines[engine_number],
              blended_mr: action.payload.res.blended_mr,
              workscopes: action.payload.res.workscope
            }
          }
        }
      }
    case actions.CARRY_OVER_NSV:
      return {
        ...state,
        reviewMRReport: {
          ...state.reviewMRReport,
          engines: {
            ...state.reviewMRReport.engines,
            [action.payload.req.engine_no.toString()]: {
              ...state.reviewMRReport.engines[action.payload.req.engine_no.toString()],
              blended_mr: action.payload.res.blended_mr,
              workscopes: action.payload.res.workscope
            }
          }
        }
      }
    case actions.CUSTOM_THE_LLP_CYCLE:
      if(action.payload.modalStatus == 'open'){
        let engineCSN = parseInt(state.reviewMRReport.engines[action.payload.engineNo].csn);
        let ids = (state.reviewMRReport.engines[action.payload.engineNo].llpLimits ? state.reviewMRReport.engines[action.payload.engineNo].llpLimits.map(llpLimit => llpLimit.id):[]);
        let resObj = Object.assign({}, action.payload.list);
        let llpCustom = Object.keys(resObj).map(key => {
            if(ids.indexOf(parseInt(key)) != -1){
              let llpEdit =  state.reviewMRReport.engines[action.payload.engineNo].llpLimits.filter(llpLimit => llpLimit.id == key)[0];
              if(llpEdit.editCycles){
                return llpEdit = { ...llpEdit, max_limit: parseInt(llpEdit.max_limit), remaining: parseInt(llpEdit.remaining)}
              }else{
                llpEdit = {
                  ...llpEdit,
                  remaining: llpEdit.remaining - (regex.test(engineCSN) ? engineCSN:0),
                  max_limit: parseInt(llpEdit.max_limit)
                }
                return llpEdit;
              }
            }else{
              let customCsn  = Object.assign({}, resObj[key]);
              customCsn = {
                ...customCsn,
                remaining: customCsn['remaining'] - (regex.test(engineCSN) ? engineCSN : 0)
              }
              return customCsn
            }
          });
        llpCustom = llpCustom.reduce((obj, key) => {
          obj[key.id] = key;
          return obj;
        }, {});
        return {
          ...state,
          customCycleRemain: {
            ...state.customCycleRemain,
            modalStatus: true,
            wrongEntry: '',
            engineNo: action.payload.engineNo,
            list: llpCustom,
            originalList: action.payload.list
          }
        }
      }else{
        return {
          ...state,
          customCycleRemain: {
            ...state.customCycleRemain,
            modalStatus: false,
            wrongEntry: '',
            engineNo: '',
            list: [],
            originalList: []
          }
        }
      }
    case actions.UPDATE_CUSTOM_THE_LLP_CYCLE:
      let editCycles;
      if(state.customCycleRemain.list[action.payload.label].editCycles){
        editCycles = true;
      }else{
        if(action.payload.keyParam == 'remaining'){
          editCycles = true;
        }else{
          editCycles = false;
        }
      }
      if(regex.test(action.payload.value)){
          return {
            ...state,
            customCycleRemain: {
              ...state.customCycleRemain,
              list: {
                ...state.customCycleRemain.list,
                [action.payload.label]: {
                  ...state.customCycleRemain.list[action.payload.label],
                  [action.payload.keyParam]: action.payload.value,
                  editCycles:editCycles
                }
              }
            }
          }
        }else{
        return state
      }
    case actions.COMPARE_CUSTOM_CR:
      const updateLLP = state.customCycleRemain.list;
      const originalLLP = state.customCycleRemain.originalList;
      let customizeLLP = [];
      let customizeWrong = false;
      Object.keys(updateLLP).map((label, index) => {
        if(updateLLP[label].remaining <= updateLLP[label].max_limit){
          if(state.reviewMRReport.engines[state.customCycleRemain.engineNo].llpLimits.filter(item => item.id == label).length){
            customizeLLP.push(updateLLP[label]);
          }else{
            if( (updateLLP[label].remaining != (originalLLP[label].remaining) - state.reviewMRReport.engines[state.customCycleRemain.engineNo].csn) || updateLLP[label].max_limit != originalLLP[label].max_limit ){
              customizeLLP.push(updateLLP[label]);
            }
          }
        }else{
          customizeWrong = true;
        }
      });
      if(customizeWrong){
        return {
          ...state,
          customCycleRemain: {
            ...state.customCycleRemain,
            wrongEntry: 'Cycle Remaining Cannot be Greater the Max Limit',
          }
        }
      }else{
        const engineNo = state.customCycleRemain.engineNo
        return {
          ...state,
          customCycleRemain: {
            ...state.customCycleRemain,
            modalStatus: false
          },
          reviewMRReport: {
            ...state.reviewMRReport,
            engines: {
              ...state.reviewMRReport.engines,
              [engineNo]: {
                ...state.reviewMRReport.engines[engineNo],
                llpLimits: customizeLLP,
                engineLLPStatus: customizeLLP.filter(item => item.editCycles).length ? false: true
              }
            }
          }
        }
      }
    case actions.BREAKUP_ENGINE_MR_COST:
      if(state.breakUpEngineMRCost.modalStatus){
        return {
          ...state,
          breakUpEngineMRCost: {
            ...state.breakUpEngineMRCost,
            modalStatus: false,
            wrongEntry: ''
          }
        };
      }else{
        const module = state.reviewMRReport.engines[action.payload.engine_no]['workscopes'][action.payload.runType]['module']
        return {
          ...state,
          breakUpEngineMRCost: {
            ...state.breakUpEngineMRCost,
            wrongEntry: '',
            modalStatus: true,
            shop_visit_no: action.payload.shop_visit_no,
            log_id: action.payload.log_id,
            engine_no: action.payload.engine_no,
            workscope: action.payload.workscope,
            module_percentages: module
          }
        };
      }
    case actions.UPDATE_BREAKUP_ENGINE_MR_COST:
      if(action.payload.outerKey == ''){
        return {
          ...state,
          breakUpEngineMRCost: {
            ...state.breakUpEngineMRCost,
            wrongEntry: 'Total Percentage Should be 100'
          }
        }
      }else{
        return {
          ...state,
          breakUpEngineMRCost: {
            ...state.breakUpEngineMRCost,
            wrongEntry: '',
            module_percentages: {
              ...state.breakUpEngineMRCost.module_percentages,
              [action.payload.outerKey]: {
                ...state.breakUpEngineMRCost.module_percentages[action.payload.outerKey],
                [action.payload.innerKey]: {
                  ...state.breakUpEngineMRCost.module_percentages[action.payload.outerKey][action.payload.innerKey],
                  percentage: action.payload.value
                }
              }
            }
          }
        }
      }
    case actions.BREAKUP_ENGINE_MR_COST_SUCCESS:
      return {
        ...state,
        breakUpEngineMRCost: {
          ...state.breakUpEngineMRCost,
          modalStatus: false
        },
        reviewMRReport: {
          ...state.reviewMRReport,
          engines: {
            [action.payload.req.engine_no.toString()]: {
              ...state.reviewMRReport.engines[action.payload.req.engine_no.toString()],
              workscopes: action.payload.res
            }
          }
        }
      }
    case actions.REGION_LIST:
      return {
        ...state,
        regionsList: action.payload
      }
    case actions.REGION_CONDITION:
      return {
        ...state,
        regionCondition: action.payload,
        genFinanceInfo: {
          ...state.genFinanceInfo,
          engine: {
            ...state.genFinanceInfo.engine,
            variable_condition_id: []
          }
        }
      }
    case actions.CUSTOM_REGION_CONDITION:
      return {
        ...state,
        regionCondition: action.payload,
      }
    case actions.UDPATE_CUSTOM_REGION:
      return {
        ...state,
        regionCondition: {
          ...state.regionCondition,
          [action.payload.index]: {
            ...state.regionCondition[action.payload.index],
            default: action.payload.value
          }
        }
      }
    case actions.AIRCRAFT_TYPE_LIST:
      return {
        ...state,
        aircraftTypeList: action.payload
      }
    case actions.ENGINE_TYPE_LIST:
      return {
        ...state,
        engineTypeList: action.payload
      }
    case actions.AIRCRAFT_ENGINE_LIST:
      return {
        ...state,
        aircraftEngineTypeList: action.payload
      }
    case actions.ENGINE_RATIO_LIST:
      return {
        ...state,
        engineUtilRatio: action.payload
      }
    case actions.AIRCRAFT_MAIN_PROG:
      return {
        ...state,
        aircraftMainPrg: action.payload
      }
    case actions.LOGS_LIST:
      return {
        ...state,
        logsInfo: {
          ...state.logsInfo,
          list: action.payload.logs,
          pagination: action.payload.pagination,
        },
        logFilter: {
          ...state.logFilter,
          page: action.payload.pagination.current_page
        },
        logListLoader: false
      }
    case actions.LOG_DEL_SUCCESS:
      return {
        ...state,
        logsInfo: {
          ...state.logsInfo,
          list: state.logsInfo.list.filter(list => action.payload.indexOf(list.id) == -1),
          pagination: {
            ...state.logsInfo.pagination,
            total: state.logsInfo.pagination.total - 1
          }
        }

      }
    case actions.LOG_FILTER_UPDATE :
      if(action.payload.type == 'date'){
        return {
          ...state,
          logFilter: {
            ...state.logFilter,
            startDate:  moment(action.payload.value.startDate).format('YYYY-MM-DD'),
            endDate: moment(action.payload.value.endDate).format('YYYY-MM-DD')
          }
        }
      }else{
        return {
          ...state,
          logFilter: {
            ...state.logFilter,
            [action.payload.type]: action.payload.value
          }
        }
      }
    case actions.LOG_SORT_UPDATE:
      let sortBy = 'asc';
      if(action.payload == state.logFilter.sort){
        sortBy = (state.logFilter.sort_by == 'asc') ? 'desc': 'asc';
      }
      return {
        ...state,
        logFilter: produce(state.logFilter, draft => {
          draft.sort = action.payload
          draft.sort_by = sortBy
        })
      }
    case actions.UPDATE_ENGINE_REVIEW_REPORT_RATIO:
      return {
        ...state,
        reviewMRReport: {
          ...state.reviewMRReport,
          generalInfo: {
            ...state.reviewMRReport.generalInfo,
            ratio: action.payload
          }
        }
      }
    case actions.CLEAR_MR_REVIEW_RPT:
      return {
        ...state,
        reviewMRReport: {}
      }
      case actions.CLEAR_LOG_FILTER:
      return {
        ...state,
        logFilter: logFilterData

      }
      case actions.CLEAR_FINANACE_DATA:
      return {
        ...state,
        genFinanceInfo: genFinanceInfoData

      }
    default:
      return state;
  }
}
