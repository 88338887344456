import React, { Component } from 'react';
import { withRouter } from 'react-router';
import  NoRecordFound  from './NoRecordFound';
// import FaqAccordion from './FaqAccordion';
import { AccordionWithHeader, AccordionNode, AccordionHeader, AccordionPanel } from 'react-accordion-with-header';
import ListLoader from './ListLoader';
import {connect} from 'react-redux';
import { globalGetService } from '../../globalServices'

 class Faqs extends Component{
  constructor(props){
    super(props);
    this.state = {
      activeFaq: [],
      faqsList: [],
      loader: false,
      search:''
    }
  }

  componentDidMount(){
    this.getAllFaqs()
  }


  getAllFaqs = (query) => {
    this.setState({
      loader: true
    })
    globalGetService('/console/faq/', query)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState({
          faqsList: response.data.data
        })
      }
      this.setState({
        loader: false
      })
    })
  }

  searchFaqs = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    this.getAllFaqs({search: this.state.search})
  }

  // actionCallback = (panels, state) => {
  //   this.setState({
  //     activeFaq: panels.active
  //   });
  // }

  render(){
    let customHeight=document.body.contains(document.getElementById('sidebar-header')) ?document.getElementById('sidebar-header').clientHeight : 0;
    const faqsList = this.state.faqsList
    return(
      <form onSubmit={(e) => this.searchFaqs(e)}>
        <div className="application-edit-side-bar">
          <div className="edit-bar-content-blk technical-edit-sidebar" style={{width: 'auto', position:'unset', marginLeft:'55px'}}>
            <div className="top-content">
              <div md="12">
                  <h3 style={{textTransform: 'capitalize'}}> FAQs </h3>
                  <input type="text" id="userSearch" onChange={(evt) => evt.target.value.trim() === '' ? this.getAllFaqs(null) : this.setState({search: evt.target.value})} placeholder="Search..." style={{borderBottom:"1px solid #22345d", width:"100%"}} />
              </div>
            </div>
            <div className="body-content">
              <div className="sidebar-form"  style={{ height: (window.innerHeight - customHeight-40) + 'px',paddingLeft:'20px',paddingRight:'20px'}}onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
                <div className="faq-accordian">
                  {faqsList && faqsList.length ?
                  <AccordionWithHeader >
                  { faqsList.map((faqData,index)=>
                  <AccordionNode style={faqData.section?{borderBottom:'none',pointerEvents:'none'}:{}} key={index}>
                    <AccordionHeader>
                      <h2 style={faqData.section?{color:'#6c757d',fontSize:'12px'}:{}}>
                        {faqData.question?faqData.question:''}
                        {faqData.section?null:<i className="fa fa-chevron-down"></i>}
                        {faqData.section?null:<i className="fa fa-chevron-up"></i>}
                      </h2>
                    </AccordionHeader>
                    <AccordionPanel>
                      <div dangerouslySetInnerHTML={{__html: faqData.answer?faqData.answer:''}} style = {{maxHeight:'100%'}} >
                      </div>
                    </AccordionPanel>
                  </AccordionNode>
                  )}
                </AccordionWithHeader>: <NoRecordFound /> }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default withRouter(Faqs);
