
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import { Link } from 'react-router'
class UserInfoModal extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  checkSecurityGroup(){
    let obj = null;
    obj = getLocalStorageInfo().user.security_groups.find( g => g.id == 24);
    if(obj){
      return true;
    }else{
      return false;
    }
  }

  render() {
      const { userDetail } = this.props;
    return (
      <div className="">
        <Modal isOpen={userDetail.flag} toggle={this.toggle} className={this.props.className}>
          <img width="12" className="um-close-icon" onClick={() => this.props.closeUserDetailModal({}, false)} src={imgStoragePath + 'black_close.png'} alt="logo"/>
          <ModalBody className="user-detail-modal">
             <div className="user-profile-pic">
               {
                  userDetail.data.profile_pic ?
                  <img src={userDetail.data.profile_pic} alt="img"/>
                  :
                  <img src={imgStoragePath + 'user.png'} alt="logo"/>
                }
                <p>
                  <Link to={`/users/manage/active/${userDetail.data.slug}`} target="_blank">
                    {userDetail.data && userDetail.data.name ? userDetail.data.name : null}
                    <i class="fas fa-external-link-alt" style={{marginLeft: '5px', fontSize: '12px'}}></i>
                  </Link>
                </p>
                <p style={{ color: '#000', fontSize: '12px', paddingTop: 0}}>{userDetail.data && userDetail.data.designation ? userDetail.data.designation : null}</p>
                <p style={{padding: '0px', marginTop: '-5px'}}>
                  { userDetail.data.rating ?<span style={{ fontSize: '10px', color: '#cccccc', position: 'relative', bottom: '1px' }}>{parseFloat(userDetail.data.rating.toFixed(2))} <i class="fa fa-star"></i> </span> : ''}
                </p>
             </div>
                <ul className="list-unstyled">
                  <li className="flex-centered">
                    <div className="first-block">
                        <label>
                         Email
                        </label>
                    </div>
                   <div className="second-block">
                    <p>
                      {userDetail.data && userDetail.data.email ? userDetail.data.email : '--'}
                    </p>
                  </div>
                  </li>
                  <li className="flex-centered">
                   <div className="first-block">
                      <label>
                        Phone Number
                      </label>
                    </div>
                    <div className="second-block">
                      <p>
                      {userDetail.data && userDetail.data.contact_no ? userDetail.data.contact_no : '--'}
                      </p>
                    </div>
                  </li>
                  <li className="flex-centered">
                  <div className="first-block" style={{display:'none'}}>
                      <label>
                        Role
                      </label>
                    </div>
                    <div className="second-block" style={{display:'none'}}>
                      <p>
                      {userDetail.data && userDetail.data.project_role ? userDetail.data.project_role : '--'}
                      </p>
                    </div>
                  </li>
                  <li className="flex-centered">
                  <div className="first-block" >
                      <label>
                        Department
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.department && userDetail.data.department.label? userDetail.data.department.label : '--'}
                      </p>
                    </div>
                  </li>
                  <li className="flex-centered">
                  <div className="first-block" >
                      <label>
                        City
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.city ? userDetail.data.city : '--'}
                      </p>
                    </div>
                  </li>
                  <li className="flex-centered">
                  <div className="first-block" >
                      <label>
                        Country
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.base_location ? userDetail.data.base_location : '--'}
                      </p>
                    </div>
                  </li>
                  { ((getLocalStorageInfo().user.is_root_user || this.checkSecurityGroup()) && (userDetail.data.type ? userDetail.data.type.value == 3 : null)) ?
                  <li className="flex-centered">
                    <div className="first-block" >
                      <label>
                        Experience
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.experience ? userDetail.data.experience : '--'}
                      </p>
                    </div>
                  </li>
                  : null }
                  { ((getLocalStorageInfo().user.is_root_user || this.checkSecurityGroup()) && (userDetail.data.type ? userDetail.data.type.value == 3 : null)) ?
                  <li className="flex-centered">
                    <div className="first-block" >
                      <label>
                        Skill Sets
                      </label>
                    </div>
                    <div className="second-block" >
                      {userDetail.data.skill_sets ?
                      <p>
                        {
                        userDetail.data.skill_sets.map((skill, index) => {
                          return (
                            <span key={index} style={{ display: 'inline-block', background: '#e6e9f2', padding: '2px 16px', marginRight : '5px', marginBottom: '4px', backgroundColor: '#e6e9f2', border: '1px solid #c4cde3', borderRadius: '16px', color: '#000000', letterSpacing: '.22px' }}>{skill.name}</span>
                          )
                        })
                        }
                     </p>
                      :null}
                    </div>
                  </li>
                  : null }
                  { ((getLocalStorageInfo().user.is_root_user || this.checkSecurityGroup()) && (userDetail.data.type ? userDetail.data.type.value == 3 : null)) ?
                  <li className="flex-centered">

                    <div className="first-block" >
                      <label>
                        Special Approvals
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.special_approvals ? userDetail.data.special_approvals : '--'}
                      </p>
                    </div>
                  </li>
                  :null}
                  { ((getLocalStorageInfo().user.is_root_user || this.checkSecurityGroup()) && (userDetail.data.type ? userDetail.data.type.value == 3 : null)) ?
                  <li className="flex-centered">
                    <div className="first-block" >
                      <label>
                        License
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.licenses ? userDetail.data.licenses : '--'}
                      </p>
                    </div>
                  </li>
                  :null}
                  { ((getLocalStorageInfo().user.is_root_user || this.checkSecurityGroup()) && (userDetail.data.type ? userDetail.data.type.value == 3 : null)) ?
                  <li className="flex-centered">
                    <div className="first-block" >
                      <label>
                        Special Trainings
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.special_trainings ? userDetail.data.special_trainings : '--'}
                      </p>
                    </div>
                  </li>
                  :null}
                  <li className="flex-centered">
                    <div className="first-block" >
                      <label>
                        Category
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.category && userDetail.data.category.label? userDetail.data.category.label : '--'}
                      </p>
                    </div>
                  </li>
                  { userDetail.data.type && userDetail.data.type.value == 1 && userDetail.data.is_acumen_project_lead  ?
                  <li className="flex-centered">
                    <div className="first-block" >
                      <label>
                        Daily Fee
                      </label>
                    </div>
                    <div className="second-block" >
                      <p>
                      {userDetail.data && userDetail.data.fee_rate ? userDetail.data.fee_rate : '--'}
                      </p>
                    </div>
                  </li>
                  :null}
                </ul>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default UserInfoModal;
