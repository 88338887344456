import React from 'react';
import { Link } from 'react-router';
const ContractSideLink = (props) => {
  return(
    <li>
      <Link className={props.classProp ? props.classProp : null} activeClassName="active" to={props.to}>
        {props.title}
        {
          props.newFeature && <sup style={{fontWeight: 'bold'}}> NEW</sup>
        }
      </Link>
    </li>
  )
}
export default ContractSideLink;
