import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import Pagination from "react-js-pagination";
import { globalGetService, globalPostService, globalExportService } from '../../../globalServices';
import { TableUIComp } from '../../../shared';
import { sEngineTypeAc, sArctTypesAc, sLesseeListAc } from '../../../shared/actionCreators';
import { TechSpecsRpt, CashflowRpt, HlaRpt, InvoiceRpt, MaintenanceRpt, RecordRpt, UtilizationRpt, AssetCard, ValuationPortfolio, AircraftReport, PortfolioReport, EngineReport, CustAcctStatement, UtilizationInvRpt, AssetLogReport, CustomerAccCashflow  } from '../components';
import { EditSidebar } from '../../contracts/Elements';
import { getOwnerDropdownListAc } from '../../../phase2/ConfigureSettings/actionCreators';
import { getLocalStorageInfo, removeEmptyKey, toastFlashMessage, downloadFileType } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { ListLoader, NoRecordFound } from '../../../shared';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import moment from 'moment';
const bulkExportObj = {
  asset_info: {},
  tech_specs: {
    overview: {
      consolidate: false,
      extension: "pdf",
      export: false
    },
    marketing: {
      consolidate: false,
      extension: "pdf",
      export: false
    }
  },
  contracts: {
    utilization_trends: {
      consolidate: true,
      extension: "pdf",
      export: false
    },
    utilization_history: {
      consolidate: false,
      extension: "pdf",
      filter: {
        startDate: moment().subtract(30, 'd').format(backendDateFormat),
        endDate: moment().format(backendDateFormat)
      },
      export: false
    },
    invoice: {
      consolidate: false,
      extension: "pdf",
      filter: {
        invoice_type: '',
        status: '',
        startDate: moment().subtract(30, 'd').format(backendDateFormat),
        endDate: moment().format(backendDateFormat)
      },
      export: false
    },
    cashflow: {
      consolidate: false,
      extension: "pdf",
      filter: {
        transaction_type: '',
        startDate: moment().subtract(30, 'd').format(backendDateFormat),
        endDate: moment().format(backendDateFormat)
      },
      export: false
    },
    hla: {
      consolidate: false,
      extension: "pdf",
      filter: {
        projection_date: moment().format(backendDateFormat)
      },
      export: false
    }
  },
  maintenance: {
    upcoming_events: {
      consolidate: false,
      extension: 'pdf',
      filter: {},
      export: false
    }
  },
  records: {
    inventory_report: {
      consolidate: false,
      extension: "xls",
      export: false
    },
    action_dock: {
      consolidate: false,
      extension: "pdf",
      filter: {},
      export: false
    },
    lessor_template_sheet: {
      consolidate: false,
      extension: "xls",
      export: false
    }
  }
}
class ReportExport extends Component {
  constructor(props){
    super(props);
    this.state = {
      sideBar: false,
      customReportSidebar:false,
      filter: {},
      selectedAsset: [],
      pageLoader: false,
      reportError: '',
      assetInfo: {
        asset: [],
        pagination: {},
        asset_count: {}
      },
      exportObj: bulkExportObj,
      error: {}
    }
  }
  componentDidMount(){
    this.props.sEngineType();
    this.props.sArctTypes();
    this.props.sLesseeListfn();
    this.fetchAssets();
    this.props.getOwnerList()
    trackActivity('Page Visited', {
      application_module: 'Reports',
      page_title: 'Report List'
    })
  }
  selectAsset = (e,asset) => {
    if(e.target.checked){
      if(this.state.selectedAsset.length < 10){
        this.setState(prevState => ({
          ...prevState,
          selectedAsset: [...prevState.selectedAsset, {asset_type: asset.asset_type, id: asset.id, slug: asset.slug}]
        }));
      }else{
        // toast the message
      }
    }else{

      this.setState(prevState => ({
        ...prevState,
        selectedAsset: prevState.selectedAsset.filter(item => (item.asset_type != asset.asset_type || asset.id != item.id))
      }));
    }
  }
  handlePageChange = (page) => {
    this.fetchAssets({page: page, ...this.state.filter});
  }
  changeFilterFn=(query)=>{
    this.setState({
      filter: query
    });
    this.fetchAssets(query);
  }
  onChangeExportFeature = (value, featureType, application) => {
    this.setState(prevState => ({
      ...prevState,
      exportObj: {
        ...prevState.exportObj,
        [application]: {
          ...prevState.exportObj[application],
          [featureType]: {
            ...prevState.exportObj[application][featureType],
            export: value
          }
        }
      }
    }))
  }
  onChangeExtension = (value, featureType, application) => {
    this.setState(prevState => ({
      ...prevState,
      exportObj: {
        ...prevState.exportObj,
        [application]: {
          ...prevState.exportObj[application],
          [featureType]: {
            ...prevState.exportObj[application][featureType],
            extension: value
          }
        }
      }
    }))
  }
  onChangeDropdown = (data, keyParam ,featureType, application) => {
    this.setState(prevState => ({
      ...prevState,
      exportObj: {
        ...prevState.exportObj,
        [application]: {
          ...prevState.exportObj[application],
          [featureType]: {
            ...prevState.exportObj[application][featureType],
            filter: {
              ...prevState.exportObj[application][featureType].filter,
              [keyParam]: data ? data.value:''
            }
          }
        }
      }
    }))
  }
  onChangeDate = (value, keyParam ,featureType, application) => {
    this.setState(prevState => ({
      ...prevState,
      exportObj: {
        ...prevState.exportObj,
        [application]: {
          ...prevState.exportObj[application],
          [featureType]: {
            ...prevState.exportObj[application][featureType],
            filter: {
              ...prevState.exportObj[application][featureType].filter,
              [keyParam]: value == 'Invalid date' ? '':value
            }
          }
        }
      }
    }))
  }
  fetchAssets = (query={}) => {
    this.setState({pageLoader: true});
    globalGetService('console/list/', query)
    .then(response => {
      this.setState({pageLoader: false});
      if(response.data.statusCode == 200){
        this.setState({
          assetInfo: response.data.data
        });
      }
    })
  }
  generateBulkExport = () => {
    let assetSlugInfo = {
      aircraft_slug: this.state.selectedAsset.filter(asset => asset.asset_type == 1).map(item => item.slug),
      engine_slug: this.state.selectedAsset.filter(asset => asset.asset_type == 2).map(item => item.slug),
      apu_slug: this.state.selectedAsset.filter(asset => asset.asset_type == 3).map(item => item.slug),
      lg_slug: this.state.selectedAsset.filter(asset => asset.asset_type == 4).map(item => item.slug),
      propeller_slug: this.state.selectedAsset.filter(asset => asset.asset_type == 5).map(item => item.slug)
    }
    let payload = JSON.parse(JSON.stringify(this.state.exportObj));
    if(!payload.tech_specs.overview.export){
      delete payload['tech_specs']['overview'];
    }
    if(!payload.tech_specs.marketing.export){
      delete payload['tech_specs']['marketing'];
    }

    if(!payload.contracts.utilization_trends.export){
      delete payload['contracts']['utilization_trends'];
    }
    if(!payload.contracts.utilization_history.export){
      delete payload['contracts']['utilization_history'];
    }else{
      payload = {
        ...payload,
        contracts: {
          ...payload.contracts,
          cashflow: {
            ...payload.contracts.utilization_history,
            filter: removeEmptyKey(payload.contracts.utilization_history.filter)
          }
        }
      }
    }
    if(!payload.contracts.invoice.export){
      delete payload['contracts']['invoice'];
    }else{
      payload = {
        ...payload,
        contracts: {
          ...payload.contracts,
          invoice: {
            ...payload.contracts.invoice,
            filter: removeEmptyKey(payload.contracts.invoice.filter)
          }
        }
      }
    }
    if(!payload.contracts.cashflow.export){
      delete payload['contracts']['cashflow'];
    }else{
      payload = {
        ...payload,
        contracts: {
          ...payload.contracts,
          cashflow: {
            ...payload.contracts.cashflow,
            filter: removeEmptyKey(payload.contracts.cashflow.filter)
          }
        }
      }
    }
    if(!payload.contracts.hla.export){
      delete payload['contracts']['hla'];
    }
    if(!payload.maintenance.upcoming_events.export){
      delete payload['maintenance']['upcoming_events'];
    }
    if(!payload.records.inventory_report.export){
      delete payload['records']['inventory_report'];
    }
    if(!payload.records.lessor_template_sheet.export){
      delete payload['records']['lessor_template_sheet'];
    }
    if(!payload.records.action_dock.export){
      delete payload['records']['action_dock'];
    }
    if(!Object.keys(payload.tech_specs).length){
      delete payload['tech_specs'];
    }

    if(!Object.keys(payload.contracts).length){
      delete payload['contracts'];
    }
    if(!Object.keys(payload.records).length){
      delete payload['records'];
    }
    payload = {
      ...payload,
      asset_info: assetSlugInfo
    }
    if(Object.keys(payload).length > 1){
      this.setState({
        pageLoader: true
      })
      globalPostService('reports/bulk-download-background/', payload)
      .then(response => {
        this.setState({
          pageLoader: false
        });
        if(response.data.statusCode == 200){
          //
          toastFlashMessage('Thanks for downloading report, soon we will send you download link', 'success', 10000);
        }
        trackActivity('Report Exported', {
          application_module: 'Reports',
          page_title: 'Report',
          file_name: 'Custom Report'
        })
        this.setState({
          sideBar: false,
          exportObj: bulkExportObj,
          selectedAsset: []
        });
      })
    }else{
      this.setState({
        reportError: 'Please select atleast 1 export feature to continue'
      });
      setTimeout(() => {
        this.setState({
          reportError: ''
        });
      },3000);
    }
  }

  exportAnnualReport = () =>{
    this.setState({
      pageLoader: true
    })
    globalExportService(`/console/export-annual-report/`)
    .then(response => {
      this.setState({
        pageLoader: false
      })
      downloadFileType(response, 'Portfolio Report.' ,'xlsx')
    })
  }

  render(){
    const { sideBar, assetInfo, selectedAsset, exportObj, pageLoader, reportError, customReportSidebar } = this.state;
    const { aircraftTypes, sEngineTypes, sLesseeList, ownerList } = this.props;
    const menuList = {
      'Aircraft Type': {
        'inputType': 'dropdown',
        'keyParam': 'aircraft_type',
        'placeholder': 'Search by Aircraft Type',
        'label': 'Aircraft Type',
        'labelKey': 'name',
        'valueKey': 'id',
        'options':aircraftTypes,
        'multi': true
      },
      'Application': {
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'placeholder': 'Search by Application',
        'label': 'Application',
        'labelKey': 'name',
        'valueKey': 'id',
        'options':[{id: 3, name: 'Contracts'}, {id: 6, name: 'Records'}, {id: 2, name: 'Maintenance'}],
        'multi': true
      },
      'Asset Type': {
        'inputType': 'dropdown',
        'keyParam': 'asset_type',
        'placeholder': 'Search by Asset Type',
        'label': 'Asset Type',
        'labelKey': 'label',
        'valueKey': 'value',
        'options':[{label: 'Aircraft', value: '1'}, {label: 'Engine', value: '2'}, {label: 'APU', value: '4'}, {label: 'Landing Gears', value: '3'}, {label: 'Propeller', value: '5'}],
        'multi': true
      },
      'Engine Type': {
        'inputType': 'dropdown',
        'keyParam': 'engine_type',
        'placeholder': 'Search by Engine Type',
        'label': 'Engine Type',
        'labelKey': 'name',
        'valueKey': 'id',
        'options':sEngineTypes,
        'multi': true
      },
      'Lessee': {
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'placeholder': 'Search by Lessee',
        'label': 'Lessee',
        'labelKey': 'name',
        'valueKey': 'slug',
        'options':sLesseeList,
      },
      'Registration Number': {
        'inputType': 'text',
        'keyParam': 'registration',
        'placeholder': 'Search',
        'label': 'Registration Number'
      },
      'Serial Number': {
        'inputType': 'text',
        'keyParam': 'serial_number',
        'placeholder': 'Search',
        'label': 'Serial Number'
      },
      'Status': {
        'inputType': 'dropdown',
        'keyParam': 'status',
        'placeholder': 'Search by status',
        'label': 'Status',
        'labelKey': 'label',
        'valueKey': 'value',
        'options':[{label: 'On Lease', value: '1'}, {label: 'Off Lease', value: '2'}, {label: 'On Ground', value: '3'}, {label: 'Written Off / Sold', value: '4'}],
        'multi': true
      },
    }
    return(
      <div className="report-export-lists" style={{marginLeft: '70px', paddingTop: '30px'}}>
        { pageLoader ? <ListLoader />:null}
        <Row className="report-export-title">
          <Col md="6">
            <h4>Export Reports</h4>
            <p>
              Enables user to select multiple assets (maximum 10) for downloading SPARTA reports, by a system sent link to the registered email ID
            </p>
          </Col>
          {

            getLocalStorageInfo().user.permission &&  getLocalStorageInfo().user.permission['reports'] && getLocalStorageInfo().user.permission['reports']['custom_reports'].indexOf('DN') != -1  &&
            [14, 242, 271, 13].includes(getLocalStorageInfo().defaultLessor.id) && !selectedAsset.length  ?
            <Col md={6}>
              <div className="bulk-rpt-cta">
                <button className="btn-primary" onClick={() =>  this.setState({customReportSidebar : true})}>Custom Reports</button>
              </div>
            </Col>
            :null
          }
          { [14, 242, 271].includes(getLocalStorageInfo().defaultLessor.id) && selectedAsset.length ?
            <Col md="6">
              <div className="bulk-rpt-cta">
                <button className="btn-primary" onClick={() => {this.setState({sideBar: true})}}>Export Report</button>
                <p>Selected Asset {selectedAsset.length}/10 </p>
              </div>
            </Col>
            :selectedAsset.length ?
            <Col md="6">
              <div className="bulk-rpt-cta">
                <button className="btn-primary" onClick={() => {this.setState({sideBar: true})}}>Export Report</button>
                <p>Selected Asset {selectedAsset.length}/10 </p>
              </div>
            </Col>:null
          }
        </Row>
        <TableUIComp
          theads={[{label:'Assets (Select to Export)', sortKey: '',colSpan: '2'}, {label:'Lessee', sortKey: ''},{label:'Registration No', sortKey: ''}]}
          bulkOperation={false}
          content={assetInfo.asset.map((item, index) =>
            <tr>
              <td width={20}>
                <input
                  checked={selectedAsset.find(asset => asset.asset_type == item.asset_type && asset.id == item.id) ? true:false}
                  onChange={(event) => this.selectAsset(event,item)}
                  type="checkbox"
                />
              </td>
              <td>
                <AssetCard item={item} />
              </td>
              <td>
                { item.lessee && item.lessee.logo ?
                  <img src={item.lessee.logo} alt={item.lessee.name} style={{maxWidth: '75px'}} />:'--'
                }
              </td>
              <td>{item.country ? item.country:'--'}</td>
            </tr>
          )}
          changePerPage ={this.changePerPage}
          changePagination = {this.changePagination}
          filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
          changeFilterFn={this.changeFilterFn}
          pagination = {{}}
          recordsFound = { pageLoader ? false : assetInfo.asset.length ? false:true}
          tableHeight = {window.innerHeight - 220}
        />
        { Object.keys(assetInfo.pagination).length ?
          <div>
             <Pagination
              activePage={assetInfo.pagination.current_page ? parseInt(assetInfo.pagination.current_page):1}
              itemsCountPerPage={10}
              totalItemsCount={assetInfo.pagination.total}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange}
            />
          </div>:null
        }
        <EditSidebar
          title={
            <div>
              <Link onClick={() => {this.setState({sideBar: false})}}>&#8592; <span>Back to Asset Selection</span></Link>
              <h5>Export Module Reports</h5>
            </div>
          }
          editSideBar={sideBar}
          toggleEditSideBar = {() => { this.setState({sideBar: false, selectedAsset: [], exportObj: bulkExportObj})}}
        >
          <div className="">
            <div className="report-export-section" style={{ height: (window.innerHeight - 180) + 'px', overflow:'auto'}}>
              <TechSpecsRpt
                exportObj={exportObj}
                onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'tech_specs')}
              />
              { getLocalStorageInfo().user.permission.maintenance ?
                <MaintenanceRpt
                  exportObj={exportObj}
                  onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'maintenance')}
                />:null
              }
              { getLocalStorageInfo().user.permission.records ?
                <RecordRpt
                  exportObj={exportObj}
                  onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'records')}
                  onChangeExtension={(e, featureType) => this.onChangeExtension(e, featureType, 'records')}
                />:null
              }

              { getLocalStorageInfo().user.permission.contracts ?
                <div className="export-module-card">
                  <div className="rpt-card-header">
                    <h2>Contracts</h2>
                  </div>
                  <div className="rpt-card-body">
                    { getLocalStorageInfo().user.permission.contracts.utilization ?
                      <div className="">
                        <h4 className="sub-mod-title">Utilization</h4>
                        <div className="sub-mod-card">
                          <UtilizationRpt
                            exportObj={exportObj}
                            onChangeDate={(value, keyParam, featureType) => this.onChangeDate(value, keyParam, featureType, 'contracts')}
                            onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'contracts')}
                            onChangeExtension={(e, featureType) => this.onChangeExtension(e, featureType, 'contracts')}
                          />
                        </div>
                      </div>:null
                    }
                    { getLocalStorageInfo().user.permission.contracts.invoices ?
                      <div className="">
                        <h4 className="sub-mod-title">Invoice</h4>
                        <div className="sub-mod-card">
                          <InvoiceRpt
                            exportObj={exportObj}
                            onChangeDropdown={(value, keyParam, featureType) => this.onChangeDropdown(value, keyParam, featureType, 'contracts')}
                            onChangeDate={(value, keyParam, featureType) => this.onChangeDate(value, keyParam, featureType, 'contracts')}
                            onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'contracts')}
                            onChangeExtension={(e, featureType) => this.onChangeExtension(e, featureType, 'contracts')}
                          />
                        </div>
                      </div>:null
                    }
                    { getLocalStorageInfo().user.permission.contracts.cash_flow ?
                      <div className="">
                        <h4 className="sub-mod-title">Cashflow</h4>
                        <div className="sub-mod-card">
                          <CashflowRpt
                            exportObj={exportObj}
                            onChangeDropdown={(value, keyParam, featureType) => this.onChangeDropdown(value, keyParam, featureType, 'contracts')}
                            onChangeDate={(value, keyParam, featureType) => this.onChangeDate(value, keyParam, featureType, 'contracts')}
                            onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'contracts')}
                            onChangeExtension={(e, featureType) => this.onChangeExtension(e, featureType, 'contracts')}
                          />
                        </div>
                      </div>:null
                    }
                    {getLocalStorageInfo().user.permission.contracts.half_life_adjustment ?
                      <div className="">
                        <h4 className="sub-mod-title">Half Life Adjustment</h4>
                        <div className="sub-mod-card">
                          <HlaRpt
                            exportObj={exportObj}
                            onChangeDate={(value, keyParam, featureType) => this.onChangeDate(value, keyParam, featureType, 'contracts')}
                            onChangeExportFeature={(e, featureType) => this.onChangeExportFeature(e, featureType, 'contracts')}
                            onChangeExtension={(e, featureType) => this.onChangeExtension(e, featureType, 'contracts')}
                          />
                        </div>
                      </div>:null
                    }
                  </div>
                </div>:null
              }
            </div>
            <div style={{padding: '15px 20px', position: 'relative', overflow: 'hidden'}}>
              { reportError ?
                <h6 className="error-msg" style={{position: 'absolute', right: '15px', bottom: '-5px', zIndex: 99}}>{reportError}</h6>:null
              }
              <ul className="list-inline" style={{float: 'right'}}>
                <li className="list-inline-item" onClick={() => { this.setState({sideBar: false, selectedAsset:[], exportObj: bulkExportObj})}}>
                  Cancel
                </li>
                <li className="list-inline-item">
                  <button style={{padding: '6px 10px', borderRadius: '4px'}} onClick={this.generateBulkExport} className="btn-primary">Generate Report</button>
                </li>
              </ul>

            </div>
          </div>

        </EditSidebar>
        <EditSidebar
          title={
            <div>
              <h5>Custom Reports</h5>
            </div>
          }
          editSideBar={customReportSidebar}
          toggleEditSideBar = {() =>  this.setState({ customReportSidebar: false})}
        >
        <div className="" >
          <div className="report-export-section" style={{ height: (window.innerHeight - 180) + 'px', overflow:'auto'}}>
            <AircraftReport ownerList={ownerList} />
            <AssetLogReport ownerList={ownerList} />
            <CustAcctStatement ownerList={ownerList} />
            <CustomerAccCashflow ownerList={ownerList} />
            <EngineReport ownerList={ownerList} />
            <PortfolioReport ownerList={ownerList} />
            {[14, 242, 271, 13].includes(getLocalStorageInfo().defaultLessor.id) ? <UtilizationInvRpt ownerList={ownerList} /> : null}
            <ValuationPortfolio ownerList={ownerList} />
          </div>
        </div>

        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
    aircraftTypes: state.sharedReducers.aircraftTypes,
    sEngineTypes: state.sharedReducers.sEngineTypes,
    sLesseeList: state.sharedReducers.sLesseeList,
    ownerList:state.confReducer.ownerDropdownList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sEngineType: () => dispatch(sEngineTypeAc()),
    sArctTypes: () => dispatch(sArctTypesAc()),
    sLesseeListfn: () => dispatch(sLesseeListAc()),
    getOwnerList:() => dispatch(getOwnerDropdownListAc()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExport);
