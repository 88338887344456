import * as actions from '../actions';
export default function(state={
  binList:[],
  recycleBinLoader: false,
  recycleList:[],
  binPagination:{},
}, action){
  switch (action.type) {
    case actions.GET_RECYCLE_BIN:
      return{
        ...state,
        binList: action.payload,
        binPagination: action.pagination
      }
    case actions.ADD_RECYCLE_LIST:
      let recycleList = Object.assign([], state.recycleList);
      if(recycleList.filter(item => item.uuid == action.payload.uuid).length){
        return {
          ...state,
          recycleList: recycleList.filter(item => item.uuid != action.payload.uuid)
        }
      }else{
        return {
          ...state,
          recycleList: [ ...state.recycleList, action.payload]
        }
      }
    case actions.RECYCLE_ALL:
       return {
         ...state,
         recycleList: (state.binList.length == state.recycleList.length) ? [] : state.binList.map(item => {
           let obj = {};
           obj['uuid'] = item.uuid;
           obj['type'] = item.type;
           return obj
         })
       }
    case actions.RECYCLE_LIST_ACTION:
      return{
        ...state,
        binList: state.binList.filter(item => item.uuid != action.payload),
        recycleList: state.recycleList.filter(item => item != action.payload),
      }
    case actions.RECYCLE_BIN_ACTION:
      let uuidArray = [];
       action.payload.map(item => {
       uuidArray.push(item.uuid);
       })
      return{
        ...state,
        binList:state.binList.filter(item => uuidArray.indexOf(item.uuid) == -1),
        recycleList: [],
      }
      case actions.RECYCLE_BIN_LOADER:
        return {
          ...state,
          recycleBinLoader: action.payload
        }
    default:
      return state
  }
}
