export const addAfAssembly = {
  event_id: '',
  shop_visit_date: null,
  cost: '',
  removal_reason: '',
  workscope_details: '',
  shop_visit_facility: '',
  facility_location: '',
  tsn: null,
  csn:null
};
export const addAfClaim = {
	claim_amount: '',
	description: '',
  remarks: '',
  submission_date: null,
  shop_visit_type: ''
};
export const addEngineAssembly = {
  event_id: '',
  workscope_details: '',
  shop_visit_facility: '',
  facility_location: '',
  shop_visit_date: null,
  cost: '',
  removal_reason: '',
  tsn: null,
  csn:null
};

