import React, { Component } from 'react';
import { ContrListCol } from '../../Elements';
export default class IndCondRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const  { inductionCond } = this.props;
    const inductionCondObj = [
      { value: inductionCond.asset_conditions_for_contract ? inductionCond.asset_conditions_for_contract.name ? inductionCond.asset_conditions_for_contract.name : '--' : '--' },
      { value: inductionCond.terms ? inductionCond.terms.length > 140 ? inductionCond.terms.substring(0, 140) + ' ...': inductionCond.terms :'' }
    ]
    return(
      <tr onClick={this.props.viewInductionCond}>
        { inductionCondObj.map((inductionCond,index) => <ContrListCol colObj={inductionCond} key={index} />)}
      </tr>
    )
  }
}
