import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { LoginSideBar, ForgotPasswordForm } from '../components';
import { checkLoginForm } from '../../formValidator/loginForm';
import { forgotPwdAc } from '../actionCreators';
import '../assets/styles/login.scss';
import { FORM_SUBMIT_LOADER } from '../actions';
class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      errors: {}
    }
  }
  componentDidMount(){
    this.props.formSubmitLoader(false);
  }
  forgotPassword = (e) => {
    e.preventDefault();
    const validateNewInput = checkLoginForm({email: e.target.email.value});
    if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === ''})){
      this.props.formSubmitLoader(true);
      this.props.requestEmail({email: e.target.email.value});
      this.setState({
        errors: {}
      });
    }else{
      this.setState({
        errors: validateNewInput
      });
    }
  }
  render() {
    const { loginLoader } = this.props;
    return(
      <Row className="flex-not-centered login-wrap">
        <LoginSideBar />
        <ForgotPasswordForm loginLoader={loginLoader} forgotPassword={this.forgotPassword} errors={this.state.errors} />
      </Row>
    )
  }
}

const mapStateToProps = state => ({
  loginLoader: state.userAssetReducer.loginLoader
});
const mapDispatchToProps = dispatch => {
  return {
    requestEmail: (data) => dispatch(forgotPwdAc(data)),
    formSubmitLoader: (flag) => dispatch({
      type:FORM_SUBMIT_LOADER,
      payload: flag
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
