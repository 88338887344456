import * as actions from '../actions';
import { globalGetService, globalExportService } from '../../../../globalServices/';
import { downloadFileType } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
import { CTR_SHORT_INFO } from '../../Listing/actions'
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const loadAssetViewAc = (params) => {
  return (dispatch) => {
    globalGetService('console/'+params.type+'/' + params.aircraft_slug + '/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        });
        let assetInfo = response.data.data;
        let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        trackActivity('Page Visited',{
          page_title: 'Dashboard',
          application_module: 'Contracts',
          asset: asset_serial_number,
        })
        dispatch(contrDbCashFlowAc(response.data.data.contract.slug));
        dispatch(contrDbInvoiceListAc(response.data.data.contract.slug, {}));
        dispatch(contrDbLeaseHistoryAc(response.data.data.id,{asset_type: response.data.data.asset_type}));
        dispatch(contrDbUtilizationInfoAc(response.data.data.contract.slug));
        dispatch(contrDbFlaggedCondAc(response.data.data.contract.slug, {flag:1}));
      }
    })
  }
}

export const contrDbCashFlowAc = (contract_slug) => {
  return (dispatch) => {
    dispatch({
      type: actions.CASHFLOW_WIDGET_LOADER,
      payload: true
    })
    globalGetService('contracts/'+contract_slug+'/cashflow-dashboard-widget/', {})
    .then(response => {
      dispatch({
        type: actions.CASHFLOW_WIDGET_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_DB_CASH_FLOW_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const contrDbInvoiceListAc = (contract_slug) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_WIDGET_LOADER,
      payload: true,
      loaderType: 'invoiceWidgetLoader'
    });
    globalGetService('contracts/'+contract_slug+'/invoice-dashboard-widget/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_DB_INVOICE_LIST,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.CONTRACT_WIDGET_LOADER,
        payload: false,
        loaderType: 'invoiceWidgetLoader'
      });
    })
  }
}

export const contrDbLeaseHistoryAc = (id, params={}) => {
  return (dispatch) => {
    globalGetService('/console/asset-timeline/' + id + '/',params)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LEASE_HISTORIES,
          payload: response.data.data
        })
      }
    })
  }
}

export const contrDbUtilizationInfoAc = (contract_slug) => {
  return (dispatch) => {
    globalGetService('/contracts/'+contract_slug+'/utilizations/average/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_MAJOR_ASSEMBLY_SLIDER,
          payload: response.data.data
        })
      }
    })
  }
}

export const contrDbFlaggedCondAc = (contract_slug, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_WIDGET_LOADER,
      loaderType: 'flaggedWidgetLoader',
      payload: true
    });
    globalGetService('/contracts/'+contract_slug+'/return-vs-delivery-condition/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        if(Object.keys(queryParams).length){
          dispatch({
            type: actions.CONTR_DB_FLAGGED_COND,
            payload: response.data.data
          });
        }else{
          dispatch({
            type: actions.COMPARE_RETURN_COND,
            payload: response.data.data
          })
        }
      }
      dispatch({
        type: actions.CONTRACT_WIDGET_LOADER,
        loaderType: 'flaggedWidgetLoader',
        payload: false
      });
    })
  }
}

export const exportReturnCondnAc = (contract) => {

  return (dispatch) => {
    globalExportService('contracts/'+contract.contract.slug+'/return-vs-delivery-condition/',{download: 'pdf'})
    .then(response => {
      if (contract.asset_type === 2){
        downloadFileType(response, 'ESN'+contract.esn+'_'+contract.contract.lessee.name+'_return_condition.', 'pdf')
      }else if(contract.asset_type === 3){
        downloadFileType(response, 'APU'+contract.serial_number+'_'+contract.contract.lessee.name+'_return_condition.', 'pdf')
      }else if(contract.asset_type === 4){
        downloadFileType(response, 'LG'+contract.serial_number+'_'+contract.contract.lessee.name+'_return_condition.', 'pdf')
      }else if(contract.asset_type === 5){
        downloadFileType(response, 'Propeller'+contract.serial_number+'_'+contract.contract.lessee.name+'_return_condition.', 'pdf')
      }else if(contract.asset_type === 1){
        downloadFileType(response, 'MSN'+contract.msn+'_'+contract.contract.lessee.name+'_return_condition.', 'pdf')
      }else if (contract.asset_type === 6) {
        downloadFileType(response, 'FAN' + contract.esn + '_' + contract.contract.lessee.name + '_return_condition.', 'pdf')
      }else if (contract.asset_type === 7) {
        downloadFileType(response, 'HPC' + contract.esn + '_' + contract.contract.lessee.name + '_return_condition.', 'pdf')
      }else if (contract.asset_type === 8) {
        downloadFileType(response, 'HPT' + contract.esn + '_' + contract.contract.lessee.name + '_return_condition.', 'pdf')
      }else if (contract.asset_type === 9) {
        downloadFileType(response, 'LPT' + contract.esn + '_' + contract.contract.lessee.name + '_return_condition.', 'pdf')
      }
    });
  }
}

export const exportLeaseHistoryAc = (ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_WIDGET_LOADER,
      payload: true,
      loaderType: 'flaggedWidgetLoader'
    });
    globalExportService('console/asset-timeline/'+ctrShortInfo.id + '/',{download: 'pdf', asset_type: ctrShortInfo.asset_type})
    .then(response => {
      dispatch({
        type: actions.CONTRACT_WIDGET_LOADER,
        payload: false,
        loaderType: 'flaggedWidgetLoader'
      });
      if (ctrShortInfo.asset_type === 2){
        downloadFileType(response, 'ESN'+ctrShortInfo.esn+'_lease_history.', 'pdf')
      }else if(ctrShortInfo.asset_type === 3){
        downloadFileType(response, 'APU'+ctrShortInfo.serial_number+'_lease_history.', 'pdf')
      }else if(ctrShortInfo.asset_type === 4){
        downloadFileType(response, 'LG'+ctrShortInfo.serial_number+'_lease_history.', 'pdf')
      }else if(ctrShortInfo.asset_type === 5){
        downloadFileType(response, 'Propeller'+ctrShortInfo.serial_number+'_lease_history.', 'pdf')
      }else if(ctrShortInfo.asset_type === 1){
        downloadFileType(response, 'MSN'+ctrShortInfo.msn+'_lease_history.', 'pdf')
      } else if (ctrShortInfo.asset_type === 6) {
        downloadFileType(response, 'FAN' + ctrShortInfo.esn + '_lease_history.', 'pdf')
      } else if (ctrShortInfo.asset_type === 7) {
        downloadFileType(response, 'HPC' + ctrShortInfo.esn + '_lease_history.', 'pdf')
      } else if (ctrShortInfo.asset_type === 8) {
        downloadFileType(response, 'HPT' + ctrShortInfo.esn + '_lease_history.', 'pdf')
      } else if (ctrShortInfo.asset_type === 9) {
        downloadFileType(response, 'LPT' + ctrShortInfo.esn + '_lease_history.', 'pdf')
      }
    });
  }

}
