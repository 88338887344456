import * as actions from '../actions';
export default function( state={
  airframeEventList: [],
  airframeEventHistory: [],
  afClaims:[],
  eventTypes: [],
  assetInfoObj: {},
  shopVisit: {
    flag: false,
    type: '',
    data: {}
  },
  claimInfo: {
    flag: false,
    type: '',
    data: {}
  },
  payoutInfo: {
    flag: false,
    type:'',
    data: {}
  },
  airframeLoader: false,
  maintenanceUsage: {}
},action){
  switch (action.type) {
    case actions.AIRFRAME_EVENT_LIST:
      return {
        ...state,
        airframeEventList: action.payload
      }
    case actions.MAINTENANCE_USAGE:
      return {
        ...state,
        maintenanceUsage: action.payload
      }
    case actions.AF_SHOP_VIST_ADD_SUCCESS:
      return {
        ...state,
        airframeEventHistory: [action.payload, ...state.airframeEventHistory]
      }
    case actions.AIRFRAME_EVENT_HISTORY:
      return {
        ...state,
        airframeEventHistory: action.payload
      }
    case actions.AF_SHOP_VISIT_CRUD:
      return {
        ...state,
        shopVisit: action.payload
      }
    case actions.AF_CLAIM_CRUD:
      return {
        ...state,
        claimInfo: action.payload
      }
    case actions.AF_CLAIM_ADD_SUCCESS:
      return {
        ...state,
        afClaims: [action.payload, ...state.afClaims]
      }
    case actions.AF_CLAIM_EDIT_SUCCESS:
      return {
        ...state,
        afClaims: state.afClaims.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.AIRFRAME_LOADER:
      return {
        ...state,
        airframeLoader: action.payload
      }
    case actions.AF_CLAIM_LIST:
      return {
        ...state,
        afClaims: action.payload
      }
    case actions.AF_PAYOUT_CRUD:
      return {
        ...state,
        payoutInfo: action.payload
      }
    case actions.EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.payload
      }
    case actions.ASSET_INFO:
      return {
        ...state,
        assetInfoObj: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        shopVisit: {
          ...state.shopVisit,
          type: 'edit',
          flag: true
        }
      }
    default:
      return state;
  }
}
