import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {BannerComponent, DescriptionComponent, InvestmentComponent, FooterComponent, PlansComponent, FeatureComponent, ManageComponent}  from '../components';
import '../assets/management.scss';

export default class AircraftManagement extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const manageData = [
            'Accurately manages all lease touchpoint',
            'Provides a fully auditable records trail',
            'Offers fleet cashflow forecasting',
            'Enables the fast mobilisation of multiple assets for transition',
            "Mitigate risk through the accurate and AI enhanced management of assets across the asset's lifecycle",
        ];

        const features = [
            {
                title : 'Contract Management',
                img : 'landing/contact_management1.png',
                description : [
                    'Complete fleet management; aircraft, engines, APUs and landing gears',
                    'End-to-end lease and contract management for all assets',
                    'Management of lease terms and conditions including delivery and redelivery requirements',
                    'Unified view of the assets'
                ]
            },
            {
                title : 'Records Management',
                img : 'landing/record_management.png',
                description : [
                    'Interconnected data links to other modules for seamless access to scanned records for assets',
                    'Robust "search" features optimized using Natural Language Processing(NLP), Artificial Intelligence(AI) and Machine Learning(ML)',
                    'Intuitive interface and data indexing'
                ]
            },
            {
                title: 'Analytics & Valuation',
                img : 'landing/group_3.png',
                description : [
                    'Integrate data points from market trends and insights',
                    'Complex data analysis will be used to identify patterns, trends, insights and generate reports',
                    'Utilize available data points to provide a data and analytics driven valuation',
                    'Ability to generate desktop valuation reports through an online interface'
                ]
            }
        ]

        return(
            <div className="management-container sparta-staticpage-container">
                <BannerComponent
                title="Digitalisation; now revolutionising asset management."
                subtitle="Are you being left behind?"
                link="/signup" />
                <DescriptionComponent
                  description= "A digital asset manager, an extension of the human workplace.  Coupling Acumen's depth of knowledge in the aviation asset management sector with and innate inquisitive and problem-solving approach, it has successfully developed a universal and integrated asset management platform.  This platform uses embedded machine learning (ML) and artificial intelligence (AI) technologies and utilises full form robotic process automation (RPA)"
                />
                <InvestmentComponent />
                <ManageComponent
                    title="How does SPARTA help the aviation & aerospace sectors manager their assets?"
                    data={manageData}
                />
                <FeatureComponent features={features} />
                <PlansComponent />
                <FooterComponent />
            </div>
        )
    }
}
