import React from 'react';
class TextNumberField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: ''
    }
  }
  componentDidMount(){

    this.setState({
      value: this.props.value
    })
  }
  componentWillReceiveProps(nextProps){

    if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value
      })
    }
  }
  updateForm = (value) => {

    this.setState({
      value: value,
     // error: ''
    });


  }
  onBlur = () => {
    this.props.updateForm(this.state.value);

  }
  render(){
    const { type, isDisabled } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
      <div>
        <div className="form-group-edit">
          <label className="label">{this.props.children}</label>
          { type == 'view' ? <h6>{this.props.value ? this.props.value:'--'}</h6> :
            <input
              type="number"
              placeholder= {this.props.placeholder}
              value={this.state.value}
              onChange={(evt) => this.updateForm(evt.target.value)}
              onBlur={this.onBlur}
              disabled = {isDisabled}
              max= {this.props.max}
              min = {this.props.min}
            />
          }
        </div>
        {this.props.error != '' ? <h6 className="error-msg" >{this.props.error}</h6> : null}
      </div>
    )
  }
}
export default TextNumberField;
