import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { getAccessToken } from '../../../utils';

let flag = 1;
class FleetScrollHeader  extends Component {
  constructor(props){
    super(props);
    this.state = {
      positionHeader: 0
    }
}

componentDidMount(){
  document.addEventListener('scroll', this.handleScroll, true);
}

componentWillUnmount(){
  window.removeEventListener('scroll', this.handleScroll);
}

handleScroll = (e) => {
   let left = e.srcElement.scrollLeft;

  if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight) {
    flag = 1
  } else if (e.srcElement.scrollTop <= 0) {
    flag = 0
  } else {
    flag = 1;
  }


   if(!e.srcElement.scrollTop && flag){

    this.setState(prevState => ({
      ...prevState,
      positionHeader : left
   }));
   flag = 1;

  }
}

  render() {
    const { cashSimulation,fleetViewWidth  } = this.props;

      return(
        <div style={{ width: ( window.innerWidth - (300 + 175) + 'px'), overflow: 'hidden' }}>
            <div className="flex-shrink-flow item-block" style={{ width: fleetViewWidth, flexBasis: fleetViewWidth,  position: 'relative', right: this.state.positionHeader, borderBottom: 0, borderTop: '1px solid #ccc' }}>
              {
              cashSimulation.cashflow_data.map((cashSimulation, index) =>
                <div key={index} className="flex-centered border-right" style={{ width: '220px', background: '#fff', flexBasis: '300'}}>
                  <div className="first-block">
                      <span className="caption-ui"> { cashSimulation.model ? cashSimulation.model + '-' : null }{cashSimulation.aircraft_type && cashSimulation.aircraft_type.name  ? cashSimulation.aircraft_type.name : null }</span>
                        <p>{cashSimulation.msn}</p>
                      <Link to={"/fleet-cashflow/"+cashSimulation.asset_type+"/"+cashSimulation.slug+"/portfolio/" + this.props.params.slug}>View Detailed Info</Link>
                      </div>
                      <div className="second-block">
                        {
                          cashSimulation.lessee && cashSimulation.lessee.logo ?
                              <img src={cashSimulation.lessee.logo}  alt="img"/> : cashSimulation.lessee && cashSimulation.lessee.name ? cashSimulation.lessee.name : ''
                        }
                    </div>
                  </div>
                )
              }
          </div>
        </div>
      )
    }
  }

export default withRouter(FleetScrollHeader)
