import React, {Component} from 'react';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { imgStoragePath } from '../../constants'
import { getLocalStorageInfo } from '../../utils'
export default class AssetsTabs extends Component{
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false
        };
      }
      toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
      }
    render(){
      const { assetCounts } = this.props
      var totalAssets = 0
      if(assetCounts){
        Object.keys(assetCounts).map(item => {
          totalAssets = totalAssets + assetCounts[item]
          return item
        })
      }
        const userInfo = getLocalStorageInfo().user.permission
        var showTabs = true
        // Object.keys(userInfo).map(key => {
        //   showTabs = userInfo[key].is_saas ? false : showTabs
        //   return key
        // })
        let lessorIds = []
    		 if(window.location.href.includes('qa')){
    			 lessorIds = [241, 86]
    		 }else if(window.location.href.includes('dev')){
    			 lessorIds = [289, 89]
    		 }else{
    			 lessorIds = [86]
    		 }
        // Object.keys(userInfo).map(key => {
        //   showTabs = userInfo[key].is_saas ? false : showTabs
        //   return key
        // })
        if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
          showTabs = true
        }
        return(
            <Row>
                <Col md="12" >
                    <ul className="list-inline tab-left">
                        <li onClick = {() => this.props.updateAssetTab('all')} className={this.props.activeTab == 'all' ? "list-inline-item active" : "list-inline-item"}>
                            All ({totalAssets})
                        </li>
                        <li onClick = {() => this.props.updateAssetTab('1')} className={this.props.activeTab == '1' ? "list-inline-item active" : "list-inline-item"}>
                            Aircraft ({assetCounts && assetCounts.aircraft ? assetCounts.aircraft : 0})
                        </li>
                        <li onClick = {() => this.props.updateAssetTab('2')} className={this.props.activeTab == '2' ? "list-inline-item active" : "list-inline-item"}>
                            Engine ({assetCounts && assetCounts.engine ? assetCounts.engine : 0})
                        </li>
                        {
                          showTabs ?
                          <li onClick = {() => this.props.updateAssetTab('3')} className={this.props.activeTab == '3' ? "list-inline-item active" : "list-inline-item"}>
                              APU ({assetCounts && assetCounts.apu ? assetCounts.apu : 0})
                          </li>
                          :null
                        }
                        {
                          showTabs ?
                          <li onClick = {() => this.props.updateAssetTab('4')} className={this.props.activeTab == '4' ? "list-inline-item active" : "list-inline-item"}>
                              LG ({assetCounts && assetCounts.lg ? assetCounts.lg : 0})
                          </li>
                          :null
                        }
                        {
                          showTabs ?
                          <li onClick = {() => this.props.updateAssetTab('5')} className={this.props.activeTab == '5' ? "list-inline-item active" : "list-inline-item"}>
                              Propeller ({assetCounts && assetCounts.propeller ? assetCounts.propeller : 0})
                          </li>
                          :null
                        }

                          <li onClick = {() => this.props.updateAssetTab('6')} className={this.props.activeTab == '6' ? "list-inline-item active" : "list-inline-item"}>
                              Fan ({assetCounts && assetCounts.fans ? assetCounts.fans : 0})
                          </li>

                          <li onClick = {() => this.props.updateAssetTab('7')} className={this.props.activeTab == '7' ? "list-inline-item active" : "list-inline-item"}>
                              HPC ({assetCounts && assetCounts.hpc ? assetCounts.hpc : 0})
                          </li>

                          <li onClick = {() => this.props.updateAssetTab('8')} className={this.props.activeTab == '8' ? "list-inline-item active" : "list-inline-item"}>
                              HPT ({assetCounts && assetCounts.hpt ? assetCounts.hpt : 0})
                          </li>


                          <li onClick = {() => this.props.updateAssetTab('9')} className={this.props.activeTab == '9' ? "list-inline-item active" : "list-inline-item"}>
                              LPT ({assetCounts && assetCounts.lpt ? assetCounts.lpt : 0})
                          </li>

                    </ul>
                    <hr/>
                    <ul className="list-inline tab-right flex-centered">
                        <li className="list-inline-item download-block">
                            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle caret size="sm">
                                   <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export Asset List </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem  onClick={() => this.props.downLoadAsset('pdf', this.props.assetFilter)}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                                    <DropdownItem onClick={() => this.props.downLoadAsset('xls', this.props.assetFilter)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </li>
                        <li className="list-inline-item count-block" style={{display: 'none'}}>
                            <span> {this.props.total} items </span>
                          </li>
                    </ul>
                </Col>

            </Row>

        );
    }

}
