import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared'
import AddEditIndCond from '../components/AddEditIndCond';
import IndCondRow from '../components/IndCondRow';
import { inductionListAc, addEditInductionCondAc, contractCondnAc, inductionConditionCrudAc } from '../actionCreators';
import { INDUCTION_COND_CRUD, TOGGLE_VIEW_EDIT } from '../actions';
import { sExportContractsAc, sAuditLogAc } from '../../../../shared/actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
class InductionCondition extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.props.fetchInductionCondList();
    this.props.fetchcontractCondnList();
  }
  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/induction-condition';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }
  }
  render() {
    const { inductionCondList, assetViewId, inductionCondLoader, contractCondn, inductionCondCrud, auditLogView } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Contracts" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Induction Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <ContractHeadertext
            title="Induction Conditions"
            desc="Section holds induction conditions applicable under the Contract"
            addNew={getLocalStorageInfo().user.permission['contracts']['induction_conditions'].indexOf('C') != -1 ? '+  Add Induction Condition':'' }
            toggleAddEvent={() => this.props.inductionConditionCrud('add', {})}
            addAudit= {auditLogView ?  "Revision History" : null}
            toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctinductionconditionitems', 0, null, 'add', this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Induction Condition', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Induction Condition'})}}
         />
          <div className="list-container">
            { inductionCondLoader ? <ListLoader />:null }
            <div className="table-container">
              <table>
                <CtrTableHd theads={['Clause', 'Terms']}/>
                <tbody>
                  {inductionCondList.map((inductionCond,index) => <IndCondRow key={index} inductionCond={inductionCond}  viewInductionCond={() => this.props.inductionConditionCrud('view', inductionCond.id)} /> )}
                </tbody>
              </table>
              { !inductionCondLoader && !inductionCondList.length ?
                <NoRecordFound description="Get Started by clicking '+ Add Induction Condition' button above" />:null
              }
            </div>
          </div>
        </div>
        <EditSidebar
          title={
            inductionCondCrud.type != '' ?
            (inductionCondCrud.type == 'add') ? 'Add Induction Condition':
            (inductionCondCrud.type == 'edit') ? 'Edit Induction Condition': 'View Induction Condition' : ''
            }
          editSideBar={inductionCondCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
        >
          {
            Object.keys(inductionCondCrud.data).length && inductionCondCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctinductionconditionitems', 1, inductionCondCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Induction Condition', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Induction Condition', item_id:inductionCondCrud.data.id})}}
                type={inductionCondCrud.type}
                changeViewMode={()=>this.props.changeViewMode(inductionCondCrud.type)}
                editAble = {getLocalStorageInfo().user.permission['contracts']['induction_conditions'].indexOf('U') != -1 ? true:false}
              />
              <AddEditIndCond
                type={inductionCondCrud.type}
                contractCondn={contractCondn}
                inductionCondObj={inductionCondCrud.data}
                addEditInductionCond={(data, type)=>this.props.addEditInductionCond(data, type, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                inductionCondLoader={inductionCondLoader}
              />
            </div>:null
          }
        </EditSidebar>

        <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  inductionCondList: state.ContrInducCondReducer.inductionCondList,
  inductionCondLoader: state.ContrInducCondReducer.inductionCondLoader,
  contractCondn: state.ContrInducCondReducer.contractCondn,
  inductionCondCrud: state.ContrInducCondReducer.inductionCondCrud,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchInductionCondList: () => dispatch(inductionListAc(ownProps.params)),
    addEditInductionCond: (data, type, assetViewId) => dispatch(addEditInductionCondAc(ownProps.params, data, type, assetViewId)),
    fetchcontractCondnList : () => dispatch(contractCondnAc(ownProps.params)),
    inductionConditionCrud: (type, id) => dispatch(inductionConditionCrudAc(ownProps.params, type, id)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    toggleAuditlogFn: (application, content_type, action, objectId, type,paramKey= null,paramvalue= null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramKey,paramvalue)),
    toggleEditBar: (data) => dispatch({
      type: INDUCTION_COND_CRUD,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(InductionCondition, ['contracts', 'induction_conditions', 'R'])));
