import React, { Component } from 'react';
import { Row,Col, Progress } from 'reactstrap';
export default class InvoiceTotal extends Component{
 constructor(props){
  super(props);
 }
 render(){
  const { type, size, title, t1, t2 } = this.props;
  if(type == 1){
   return(
    <Col md={size} className="padding-right-0">
    <div className="invoice-inner-block">
      <h5 className="para">{title}</h5>
      <h6 className="caption-ui">{t1}</h6>
      <p>$ 0</p>
      {t2 != '' ? <h6 className="caption-ui">{t2}</h6>:null}
      {t2 != '' ? <p className="margin-0">$ 0</p>:null}
    </div>
  </Col>
   )
  }else{
   return(
    <Col md={size} className="padding-right-0">
      <div className="invoice-inner-block">
        <h5 className="para invoice-space-block"> {title}</h5>
        <Row>
          <Col md="6">
           <h6 className="caption-ui">Overdue</h6>
           <p className="space-block">$ 0</p>
          </Col>
          <Col md="6" className="text-right">
           <h6 className="caption-ui">Not Due Yet</h6>
           <p className="space-block">$ 0</p>
          </Col>
        </Row>
        <Progress />
      </div>
    </Col>
   )
  }
 }
}
