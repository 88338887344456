import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import { quesObj }from '../ProjectFileObj';
import { FieldTextarea } from '../../Elements';
import { fetchQuestionsAc,submitAnswersAc, submitAnswerStatusAc, getUserDetailAc,exportCorAc,clientCorShowAc } from '../actionCreators';
import { ListLoader } from '../../../../shared';
import { trackActivity } from '../../../../utils/mixpanel'
import {UPDATE_ANSWERE} from '../actions';
import { imgStoragePath } from '../../../../constants';
import StatusDetails  from '../components/StatusDetails';

class ProjectCorForm extends Component{
  constructor(props){
    super(props);
    this.state = {
       activeTab:'all',
    }
  }

  componentDidMount(){
    this.props.fetchQuestions();
    trackActivity('Page Visited',{
      application_module: 'Inspection',
      page_title: 'COR Form'
    })
  }

  updateAnsweres=(sectionIndex,questionIndex,userId,key,value)=>{
     this.props.updateAnswere({sectionIndex:sectionIndex,questionIndex:questionIndex,userId:userId,key:key,value:value})
  }

  render(){
    const {questionsData,questionsLoader,exportLoader} = this.props;

    return(
      <div className="technical-inspection-cn project-cor">
       <Row className="view-header">
            <Col md="9">
              <ul className="list-inline project-edit flex-centered">
                <li className="list-inline-item ">
                  <Link to={"/technical/project/view/"+this.props.params.project_slug} className="project-nav">
                    <img width="18" src={imgStoragePath+"back_arrow.png"} />
                  </Link>
                </li>
                <li className="list-inline-item" style={{minWidth: '300px'}}>
                  <div className="">
                    <h4>COR</h4>
                  </div>
                </li>
               </ul>
              </Col>
              <Col md="2" style={{textAlign:'right',color:'#2F8CFE',cursor:'pointer',marginTop:'4px'}}>
              <label className="for-label" style={{opacity: '1.0'}}>
               Access to Client
                <input  type="checkbox"  style={{marginLeft:'4px'}} value={questionsData?questionsData.client_cor_show:false} checked={questionsData?questionsData.client_cor_show:false}
                  onChange = {(e) => this.props.updateClientStatus(e.target.checked)}
                />
              </label>
            </Col>
             <Col md="1">
             <span style={{float:'left',color:'#2F8CFE',cursor:'pointer'}} onClick={()=>this.props.exportCor('pdf')} ><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/> Export </span>
            </Col>
          </Row>
        <div className="projects-cor">
        {questionsLoader ?
        <ListLoader/>:null}
         {exportLoader ?
        <ListLoader/>:null}
         {!questionsLoader && questionsData.sections ?
         <Row className="main-row">
          <Col md="6" style={{padding:'0px'}}>
            <div className="list-heading">
              <h4>
                <span>COR Form Details</span>
                <StatusDetails/>
                </h4>
            </div>
            {questionsData.sections.map((section,sectionIndex)=>
          <div  style={{background:'#FFFFFF'}}>
           <div className="genral-block">
           <h3>{section.name}</h3>
           </div>
           {section.questions.map((question,index)=>
           <div  className="question-block">
            <h4>{question.question}</h4>
           </div>
           )}
          </div>
          )}
          </Col>
          <Col md="6" className="answer-wrapper" style={{padding:'0px'}}>
          <Row className="flex-wraper">
          {questionsData.users.map((user,index)=>
          <Col md="12" className="padding-0">
           <div>
            <div className="list-heading user-name-block flex-centered" style={{justifyContent:'center'}}>
            <div>
            <img src={user.profile_pic ? user.profile_pic : imgStoragePath+'user.png' } alt=""/>
            </div>
            <div>
            <h5 className="text-center">{user.name}</h5>
            </div>
            </div>

           {questionsData.sections.map((section,sectionIndex)=>
           <div  style={{background:'#FFFFFF'}}>
           <div className="genral-block">
           <h3 style={{opacity:'0'}}>
           {section.name}
           </h3>
           </div>
           {section.questions.map((question,questionIndex)=>
           <div className="col-md-12  flex-centered" style={{padding:'0px'}}>
           <div className="col-md-6 answere-block flex-centered">
           <ul className="list-inline flex-centered">
           <li className="no-concern-block" onClick={()=>this.updateAnsweres(sectionIndex,questionIndex,user.id,'rating',1)}>
           <label className={question.answers.length? question.answers.filter(answere => answere.user_id == user.id && answere.rating==1).length >0 ?  "rating-radio  active ":"rating-radio":"rating-radio"}>
           <span>1</span>
           </label></li>
           <li className="minor-block " onClick={()=>this.updateAnsweres(sectionIndex,questionIndex,user.id,'rating',2)}>
           <label className={question.answers.length ? question.answers.filter(answere => answere.user_id == user.id && answere.rating==2).length >0 ?  "rating-radio  active ":"rating-radio":"rating-radio"}>
           <span>2</span>
           </label></li>
           <li className="immediate-block" onClick={()=>this.updateAnsweres(sectionIndex,questionIndex,user.id,'rating',3)}>
           <label className={question.answers.length ? question.answers.filter(answere => answere.user_id == user.id && answere.rating==3).length >0 ?  "rating-radio  active ":"rating-radio":"rating-radio"}>
           <span>3</span>
           </label></li>
            <li className="immediate-block" onClick={()=>this.updateAnsweres(sectionIndex,questionIndex,user.id,'rating',4)}>
            <label className={question.answers.length ? question.answers.filter(answere => answere.user_id == user.id && answere.rating==4).length >0 ?  "rating-radio  active ":"rating-radio":"rating-radio"}>
            <span>4</span>
            </label>
            </li>
            <li className="immediate-block" onClick={()=>this.updateAnsweres(sectionIndex,questionIndex,user.id,'rating',5)}>
            <label className={question.answers.length ? question.answers.filter(answere => answere.user_id == user.id && answere.rating==5).length >0 ?  "rating-radio  active ":"rating-radio":"rating-radio"}>
            <span>5</span>
            </label>
            </li>
            </ul>
           </div>
           <div className="col-md-6 answere-block flex-centered">
           <FieldTextarea
               value={question.answers.length? question.answers.filter(answere => answere.user_id == user.id).length?  question.answers.filter(answere => answere.user_id == user.id)[0].comments:'':''}
               type={'edit'}
               keyParam="comments"
               updateField={(value)=>this.updateAnsweres(sectionIndex,questionIndex,user.id,'comments',value)}
              />
           </div>
           </div>
           )}
          </div>
          )}
          </div>
          <div className="list-heading user-name-block flex-centered" style={{justifyContent:'center'}}>
          {user.can_submit && user.feedback_status==0?
          <div>
          <button type="submit" className="primary-btn" onClick={()=> this.props.submitAnswers(questionsData)}>Submit</button>
          </div>:user.can_accept && user.feedback_status==1?
          <div>
          <div>
          <button type="submit" style={{marginRight:'20px'}} className="primary-btn" onClick={()=> this.props.submitAnswerStatus({user_id:user.id,status:1})}>Accept</button>
          <button type="submit" className="btn btn-reject" onClick={()=> this.props.submitAnswerStatus({user_id:user.id,status:0})}>Reject</button>
          </div>
          </div>
          :!user.can_accept && user.feedback_status==1?
          <div>
          <button type="submit" className="btn btn-text">Submitted</button>
          </div>
          :user.can_submit && user.feedback_status==2?
          <div>
          <div>
          <button type="submit" style={{marginRight:'20px'}} className="primary-btn" onClick={()=> this.props.submitAnswers(questionsData)}>Save Changes</button>
          <button type="submit" className="btn btn-text">Accepted</button>
          </div>
          </div>:!user.can_submit&&user.feedback_status==2 ?
          <div>
          <button type="submit" className="btn btn-text">Accepted</button>
          </div>:null
          }
          </div>
          </Col>
          )}
          </Row>
          </Col>
        </Row>: null
         }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  questionsData: state.PorjectsReducer.questionsData,
  questionsLoader: state.PorjectsReducer.questionsLoader,
  exportLoader: state.PorjectsReducer.exportLoader,

});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchQuestions: () => dispatch(fetchQuestionsAc(ownProps.params)),
    submitAnswers: (answers) => dispatch(submitAnswersAc(ownProps.params,answers)),
    submitAnswerStatus: (data) => dispatch(submitAnswerStatusAc(ownProps.params,data)),
    exportCor: (fileType) => dispatch(exportCorAc(ownProps.params,fileType)),
    updateClientStatus: (data) => dispatch(clientCorShowAc(ownProps.params,data)),
    updateAnswere: (data) => dispatch({
      type: UPDATE_ANSWERE,
      payload: data
    })

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCorForm);
