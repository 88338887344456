import React, { Component } from 'react';
export default class AppliedFilterLabel extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <li>
        {this.props.children ? this.props.children:''}
        <span onClick={this.props.clearFilterValue}>x</span>
      </li>
    )
  }
}
