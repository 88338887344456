import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
export default class DashBoardFlaggedCond extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { returnCond } = this.props;
    return(
      <tr>
        <td width="20%">{returnCond.name}</td>
        <td width="40%">
          <ul className="">
            {
              returnCond.return_condition.map((return_condition,index) =>
              <li key={index}>{return_condition.terms.length > 120 ? return_condition.terms.substring(0,117) + ' ...': return_condition.terms}</li>
            )}
          </ul>
        </td>
        <td width="40%">
          <ul>
            {
              returnCond.delivery_condition.map((delivery_condition,index) =>
              <li key={index}>{delivery_condition.terms}</li>
            )}
          </ul>

        </td>
      </tr>
    )
  }
}
