import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class InShopUnServiceable
 extends Component {
  constructor(props) {
    super(props);

  }

  updateForm = (type, value) => {
    this.props.updateDetailData(type, value);
  }

  render() {
    const { type, assemblyName, inShopUnserv, techConstants } = this.props;
    return (
      <Row>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Expected Release Date of the {assemblyName}?</FieldLabel>
               <FieldDate
                  value={inShopUnserv.expected_release_date_of_the_engine}
                  type={type}
                  keyParam="expected_release_date_of_the_engine"
                  updateField={this.updateForm}
                />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Induction Date of {assemblyName}</FieldLabel>
              <FieldDate
                value={inShopUnserv.induction_date_of_engine}
                type={type}
                keyParam="induction_date_of_engine"
                updateField={this.updateForm}
              />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Work Scope Status</FieldLabel>
            <FieldSelect
              placeholder='Select Work Scope Status'
              keyParam="work_scope_status"
              value={inShopUnserv.work_scope_status}
              type={type}
              options={techConstants.filter(item => item.type === 'workscope_status')}
              updateField={this.updateForm}
              labelKey='label'
              valueKey='value'
              name=""
            />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Facility Name</FieldLabel>
            <FieldInput
                value={inShopUnserv.shop_visit_name}
                type={type}
                keyParam="shop_visit_name"
                updateField={this.updateForm}
                />
          </FieldGroup>
        </FieldCol>
        <FieldCol md="6">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Facility Location</FieldLabel>
            <FieldInput
                value={inShopUnserv.shop_visit_location}
                type={type}
                keyParam="shop_visit_location"
                updateField={this.updateForm}
                />
          </FieldGroup>
        </FieldCol>
      </Row>
    )
  }
}
