import * as actions from '../actions';
export default function(state = {
  subLeaseList: [],
  subLeaseCrud: {
    flag: false,
    type: '',
    data: {}
  },
  subLeaseLoader: true
},action){
  switch(action.type){
    case actions.SUBLEASE_LIST:
      return{
        ...state,
        subLeaseList: action.payload
      }
    case actions.ADD_SUBLEASE_LIST:
      return {
        ...state,
        subLeaseList: [ action.payload, ...state.subLeaseList]
      }
    case actions.EDIT_SUBLEASE_SUCCESS:
      return {
        ...state,
        subLeaseList: state.subLeaseList.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.SUBLEASE_CRUD:
      return {
        ...state,
        subLeaseCrud:action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        subLeaseCrud: {
          ...state.subLeaseCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.SUBLEASE_LOADER:
      return {
        ...state,
        subLeaseLoader: action.payload
      }
    default:
      return state
  }
}
