import React from 'react';
import { Col } from 'reactstrap'
const ContractStats = (props) => {
  return(
    <Col md="3" className={props.classAtr}>
      <div className="contract-stats-card flex-centered" >
      <div>
         <span className="status-strap" style={props.style}></span>
          { props.dbContractLoader ? 
          <span style={{color: '#ffffff'}}>Loading...</span> 
          :<h2>{props.value ? props.value: 0}</h2> } 
          <p>{props.label}</p>
        </div> 
        </div> 
    </Col>
  )
}
export default ContractStats;
