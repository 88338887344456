import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {te001,te002,te003,te004,te005,te006} from '../../technicalInspection/Forms/AddFormObjects'

export default class 
PrerequisiteWrapper extends Component {
  constructor(props) {
    super(props);
    this.state={
      requisites:["Lorem Ipsum is simply dummy text of the printing and typesetting industry",
      "Lorem Ipsum is that it has a more-or-less normal distribution of letters",
      "Lorem Ipsum is that it has a more-or-less normal distribution of letters",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
      "Lorem Ipsum is that it has a more-or-less normal distribution of letters",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
      "Lorem Ipsum is that it has a more-or-less normal distribution of letters"],
      tempRequisites1:[],
      tempRequisites2:[]
    } 
  }

componentDidMount(){
  this.getListList(this.props.form);
}

  componentWillReceiveProps(nextProps){
    this.getListList(nextProps.form);
  }

getListList = (form) => {
  switch(form) {
    case 'te001':
    return this.setState(prevState =>({
      ...prevState,
      tempRequisites1:te001,
    }));
    case 'te002':
    return this.setState(prevState =>({
      ...prevState,
      tempRequisites1:te002,
    }));
    case 'te003':
    return this.setState(prevState =>({
      ...prevState,
      tempRequisites1:te003,
    }));
    case 'te004':
    return this.setState(prevState =>({
      ...prevState,
      tempRequisites1:te004,
    }));
    case 'te005':
    return this.setState(prevState =>({
      ...prevState,
      tempRequisites1:te005,
    }));
    case 'te006':
    return this.setState(prevState =>({
      ...prevState,
      tempRequisites1:te006,
    }));
   default:
   return this.setState(prevState =>({
    ...prevState,
    tempRequisites1:this.state.requisites,
   }));
  }   
}


  render() {
  // const { } = this.props;
  return(
    <div className="prerequisite">
       <ul className="list-unstyled">
       {this.state.tempRequisites1.map((requisite,index)=>
          this.props.form=='te003'?
          index===0?
          <li>
            <span>1.To check for the documents received against the <a style={{color:'#368ffa'}} onClick={()=>this.props.togglePreagain()}>list of engine documents</a></span>
           </li>:
           <li>
            {index+1}. {requisite}
           </li>:
           <li>
           {index+1}. {requisite}
          </li>
         )
       }
       </ul>
    </div>
    );
  }
}
