import * as actions from '../actions';
import { globalGetService, globalPutService, globalFileUploadPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage } from '../../../../../utils';
import { getFormDetailsAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
export const fetchT003DataAc = (props, queryParam={}) =>  {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    dispatch({
      type: actions.AUDIT_SECTION_LIST,
      payload: []
    })
    let responseData = []
    globalGetService('technical/workorder/'+props.params.workOrderSlug+'/qib-sections/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        responseData = response.data.data.map(section => {
            let sub_sections = section.sub_sections.map(subSection => {
              let questions = subSection.questions.map(question => {
                if(subSection.questions.filter(ques => ques.parent_id === question.id).length > 1 && question.parent_id === null){
                  return {...question, appearance: false}
                }else{
                  return {...question, appearance: true}
                }
              })
              return {...subSection, questions: questions}
            })
            return {...section, sub_sections: sub_sections}
          })
        setTimeout(() => {
          dispatch({
            type: actions.AUDIT_SECTION_LIST,
            payload: responseData
          })
          dispatch(triggerLoader(false));
        }, 1000)
      }
    })
  }
}

export const importReport = (props, file) =>{
  let formData = new FormData()
  formData.append('file', file)
  return(dispatch) =>{
    dispatch(triggerLoader(true));
    globalPostService(`/technical/workorder/${props.params.workOrderSlug}/qib-sections/?import=xls`, formData)
    .then(response => {
      dispatch(triggerLoader(false));
      if(response.data.statusCode === 200){
        dispatch(fetchT003DataAc(props))
      }
    })
  }
}

export const auditCheckUpdateAnsAc = (props,  sectionId, subSectionId, ansData, crudType, questionIndex, questionId, list) => {
  let answer = {}
  console.log('list.answer', list.answer)
  Object.keys(list.answer).map(key => {
    answer = {...answer, [key]: list.answer[key] === '' ? null :list.answer[key]}
  })
  let answerID = answer.id
  delete answer.id
  return (dispatch) => {
    dispatch(triggerLoader(true));
    if(list.answer.id){
      globalPutService('/technical/workorder/'+props.params.workOrderSlug+'/qib-sections/' + answerID +'/', {...answer, question_id: list.id})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LIST_UPDATE,
            payload: { data: response.data.data, sectionId:sectionId,  subSectionId: subSectionId, questionId: questionIndex}
          })
          toastFlashMessage(response.data.message, 'success');
        }
        dispatch(triggerLoader(false));

      })
    }else{
      globalPostService('/technical/workorder/'+props.params.workOrderSlug+'/qib-sections/', {...answer, question_id: list.id})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LIST_UPDATE,
            payload: { data: response.data.data, sectionId:sectionId,  subSectionId: subSectionId, questionId: questionIndex}
          })
          toastFlashMessage(response.data.message, 'success');
        }
        dispatch(triggerLoader(false));

      })
    }
    dispatch(usageDetailAc())
    dispatch(getFormDetailsAc(props));
  }
}

export const fetchAuditDocsListAc = (props, questionId) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    globalGetService('/technical/workorder/'+props.params.workOrderSlug+'/configuration/'+questionId+'/list-files/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: 'FETCH_FILE_LIST',
          payload: response.data.data
        })
      }
      dispatch(triggerLoader(false));

    })
  }
}
export const updateAuditDocsListAc = ( props, answerId, fileArr, questionId, sectionIndex, subSectionId, questionIndex) => {
  let formData = new FormData();    //formdata object
  formData.append('files', [fileArr]);
  formData.append('question_id', questionId);
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }
  return (dispatch) => {
  dispatch(triggerLoader(true));
  if(answerId == '' || answerId == null){
    globalFileUploadService('/technical/workorder/'+props.params.workOrderSlug+'/document-sections/', formData, config)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LIST_UPDATE,
          payload: { data: response.data.data, sectionId:sectionIndex,  subSectionId: subSectionId, questionId: questionIndex}
        })
        toastFlashMessage(response.data.message, 'success');
        trackActivity('File(s) Linked', {
          page_title: `T003`,
          application_module: 'Inspection',
          workorder_slug: props.params.workOrderSlug,
          form_slug: props.params.formSlug,
          question_index: questionIndex,
          file_id:fileArr[0],
        })
        dispatch({
          type: actions.LINKED_DOCS_EMPTY,
          payload: []
        })
      }
      dispatch(triggerLoader(false));
    })
  }else{
      globalFileUploadPutService('/technical/workorder/'+props.params.workOrderSlug+'/document-sections/'+answerId+'/', formData, config)
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LIST_UPDATE,
            payload: { data: response.data.data, sectionId:sectionIndex,  subSectionId: subSectionId, questionId: questionIndex}
          })
          toastFlashMessage(response.data.message, 'success');
          dispatch({
            type: actions.LINKED_DOCS_EMPTY,
            payload: []
          })

        }
        dispatch(triggerLoader(false));
      })
    }
    dispatch(getFormDetailsAc(props));
  }
}

export const triggerLoader = (flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.AUDIT_LOADER,
      payload: flag
    })
  }
}
