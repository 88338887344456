import React from 'react';
import { imgStoragePath } from '../../../../../constants';
const List = ({item, index, fetchSectionInformation}) => {
  return (
    <tr onClick={() => fetchSectionInformation(item.id)} style={{cursor: 'pointer'}}>
      <td>{index+1}</td>
      <td>{item.name}</td>
      <td>{item.open_item}</td>
      <td>{item.close_item}</td>
      <td>{item.reopen_item}</td>
      <td>{item.total_item}</td>
    </tr>
  )
}
export default List;
