import React, { Component } from 'react';
import { Link } from 'react-router';
import {  ContentDrop } from '../../../Elements';
import { imgStoragePath } from '../../../../../constants';
export default class CoverImageUpload extends Component {
  constructor(props){
		super(props);
		this.state = {
			file:{}
		}
	}
	coverUpload = (image) =>{
    this.setState(prevState => ({
			...prevState,
			file: image
		}))
		this.props.changeCoverImage({data: URL.createObjectURL(image[0]), flag: true});
	}
  render(){
    const { coverImage, formInfo } = this.props;
	  return(
      <div>
        <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px'}}>
          { coverImage.flag ?
            <ul className="list-inline flex-centered">
              { coverImage.data ?
                <li style={{ background: '#ddd'}} className="cover-photo-block">
                  { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                    <span className="cover-photo-hover overlay">
                      <b>
                        <img src={imgStoragePath + "edit_img.png"} width="20" />Change Picture
  		                  <input type="file" onChange={(evt) => {evt.preventDefault();evt.stopPropagation();this.coverUpload(evt.target.files)}}/>
                      </b>
                    </span>:null
                  }
			            <img  style={{ width: '100% '}} src={coverImage.data} alt="img"/>
	             </li> : null
             }
             { (formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save) && !coverImage.data ?
               <li className="list-inline-item dropable-zone cover-photo" onDrop={(evt) => this.coverUpload(evt.dataTransfer.files) }>
                <ContentDrop
                  mutlipleEnabled={false}
                  acceptExtensions={'image/*'}
	                filesUpload={(evt) => this.coverUpload(evt.target.files)}
                />
              </li> : null
            }
          </ul>
				: null
      }
		</div>
		<div className="edit-sidebar-footer">
			<ul className="list-inline">
        { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
          <li className="list-inline-item">
  					<input className="primary-btn"
    					onClick={() => this.props.updateImgFn(this.state.file)}
    					disabled = {Object.keys(this.state.file).length ? false : true}
    					type="button"
    					value="Save Changes"
            />
  				</li>:null
        }
				<li className="list-inline-item">
					<Link onClick={() => this.props.changeCoverImage({data: {}, flag: false})}> Cancel</Link>
				</li>
			</ul>
		</div>
		</div>
		)
	}
}
