export const PORTFOLIO_LIST = 'PORTFOLIO_LIST';
export const PORTFOLIO_CRUD = 'PORTFOLIO_CRUD';
export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const ADD_PORTFOLIO_LIST = 'ADD_PORTFOLIO_LIST';
export const EDIT_PORTFOLIO_LIST = 'EDIT_PORTFOLIO_LIST';
export const DEL_PORTFOLIO_LIST = 'DEL_PORTFOLIO_LIST';
export const ASSETS_LIST = 'ASSETS_LIST';
export const GET_ASSETS_LIST = 'GET_ASSETS_LIST';
export const ASSET_FILTER_UPDATE = 'ASSET_FILTER_UPDATE';
export const ASSET_SORT_UPDATE = 'ASSET_SORT_UPDATE';
export const FLEET_LIST_LOADER = 'FLEET_LIST_LOADER';
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const ADD_TO_COMPARE_ALL = 'ADD_TO_COMPARE_ALL';
export const DEL_PORTFOLIO_ASSET = 'DEL_PORTFOLIO_ASSET';
export const ADD_PORTFOLIO_ASSET = 'ADD_PORTFOLIO_ASSET';
export const EMPTY_COMPARE_LIST = 'EMPTY_COMPARE_LIST';
export const GET_PORTFOLIO_CONSTANTS = 'GET_PORTFOLIO_CONSTANTS';
export const FLEET_CASHFLOW_SIMULATION = 'FLEET_CASHFLOW_SIMULATION';
export const FLEET_CASHFLOW_VIEW = 'FLEET_CASHFLOW_VIEW';
export const FLEET_UPDATE_FILTER = 'FLEET_UPDATE_FILTER';
export const FLEET_VIEW_UPDATE_FILTER = 'FLEET_VIEW_UPDATE_FILTER';
export const PORTFOLIO_LIST_LOADER = 'PORTFOLIO_LIST_LOADER';
export const FLEET_VIEW_USAGE = 'FLEET_VIEW_USAGE';
