import React from 'react';
const UpcomingNotFound = ({}) => {
  return(
    <div style={{background: '#fff'}}>
      <div style={{ background: '#fff', padding: '20px', width: '580px', margin:'0 auto'}}>
        <h5 style={{color: '#ffae42', fontSize: '16px', marginBottom: '20px'}}>CAUTION. There are important data points missing. Please update the following fields in the "Technical Specifications":</h5>
        <ul style={{marginLeft: '20px'}}>
          <li style={{fontSize: '14px'}}>Date of Manufacture</li>
          <li style={{fontSize: '14px'}}>Time Since New (TSN)</li>
          <li style={{fontSize: '14px'}}>Cycles Since New (CSN)</li>
          <li style={{fontSize: '14px'}}>Average Monthly Hours</li>
          <li style={{fontSize: '14px'}}>Average Monthly Cycles</li>
          <li style={{fontSize: '14px'}}>Region Of Operation</li>
          <li style={{fontSize: '14px'}}>TSN / CSN of asset at last Shop Visit</li>
        </ul>
      </div>
    </div>
  )
}

export default UpcomingNotFound
