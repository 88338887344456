import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,  Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { DragDropFiles, FilesUploaded, List } from '../components';
import { imgStoragePath } from '../../../../constants';
import { EditSidebarIcon } from '../../../contracts/Elements';
import CautionReadMore from '../../../../shared/components/CautionReadMore';
import { SecondarySideBar, SecondaryHeader, ListLoader,NoRecordFound } from '../../../../shared';
import { uploadADFileAc, adLogsListAc, deleteAdLogsAc } from '../actionCreators';
import { AD_FILES_MODAL } from '../actions';
class ADReviewLogs extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      dropdownOpen: false,
      file: [],
      sameKindFile: false,
      readMoreFlag:false,
    }
  }
  componentDidMount(){
    this.props.adLogsList();
  }
  confirmModal = (file) => {
    let fileInfo = [];
    file.map(f => {
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        if(file.length==1){
          fileInfo.push({file: f, fileType: 'ad_uri' })
        }else{
          fileInfo.push({file: f, fileType: '' })
        }

      }else{
        fileInfo.push({ file: f, error: true });

      }
    })
    this.setState(prevState => ({
      ...prevState,
      file: fileInfo,
    }));
    this.props.toggleModalFn(!this.props.modalStatus);
  }

  toggleReadMore=()=>{
    this.setState(prevState => ({
      ...prevState,
      readMoreFlag: !prevState.readMoreFlag,
    }));
  }

  confirmClickModal = (file) => {
    if(!file.length){
      return false;
    }
    let fileInfo = [];
    for(var i=0; i<file.length; i++) {
      var f = file[i];
      if((f.name.toLowerCase()).substr(((f.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
        if(file.length==1){
          fileInfo.push({file: f, fileType: 'ad_uri' })
        }else{
          fileInfo.push({file: f, fileType: '' })
        }
      }else{
        fileInfo.push({ file: f, error: 1 });
      }
    }

    this.setState(prevState => ({
      ...prevState,
      file: fileInfo
    }));
    this.props.toggleModalFn(!this.props.modalStatus);
  }
  replaceFile = (index, fileDetail) => {
    if((fileDetail.name.toLowerCase()).substr(((fileDetail.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
      this.setState(prevState => ({
        ...prevState,
        file: prevState.file.map((file, key) => key == index ?  { file: fileDetail, fileType : '', error: '' }  : file),
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        file: prevState.file.map((file, key) => key == index ?  { file: fileDetail, error: 1 }  : file),
      }))
    }
  }
  deleteFn = (index) => {
    var file = this.state.file.filter(( obj, key ) => {
      return key !== index;
    });

    this.setState(prevState => ({
      ...prevState,
      file: file,
    }));
    if(this.state.file.length <= 1){
      this.props.toggleModalFn(!this.props.modalStatus);
    }
  }
  updateForm = (fileType, value, index) =>{
    this.setState(prevState => ({
      ...prevState,
      file: prevState.file.map((file, key) => key == index ? { ...file, [fileType] : value, error: '' } : file),
    }))
  }
  uploadMLFile = (params, file) => {
    let submitFileinfo =  file.map(file => file.fileType == '' ? { ...file, error : 2 } : file)
    this.setState(prevState => ({
      ...prevState,
      file: submitFileinfo,
    }))
    if(!submitFileinfo.filter(obj => obj.error && obj.error == 2 ).length){
      if(file.length > 1 && !(file[0].fileType != '' && file[file.length - 1].fileType != '') && file[0].fileType == file[file.length - 1].fileType){
        this.props.uploadMLFile(params, file);
      }else{
        this.props.uploadMLFile(params, file);
      }
    }
  }
  render(){
    const { file, modal } = this.state;
    const { adModalStatuss, adProgressPercentage, adLogsListing, assetDetail, adMLLoader } = this.props;
    return(
      <div className="">
        <SecondaryHeader assetInfo={assetDetail} />
        <SecondarySideBar />
        { adMLLoader ? <ListLoader />: null }
        <div className="" style={{margin: '54px -15px 0 195px',  padding: '30px'}}>
          <div className="">
            <div className="mluploaded-file data-log-list">
              <DragDropFiles
                confirmModal={this.confirmModal}
                confirmClickModal={this.confirmClickModal}
              />
              <p className="notice-info" style={{marginLeft:'5px',marginRight:'5px'}}>
                <img className="caution-img" src={imgStoragePath+"caution_sign.png"} alt="img"/>
                <span>CAUTION!</span> Please read the instructions for the optimum results
                <Link onClick={()=>this.toggleReadMore()} className="pull-right">Read More &rarr;</Link>
              </p>
              <h4 className="para">Log History</h4>
              { adLogsListing&&adLogsListing.length? adLogsListing.map((item, index) =>
                <List key={index} item={item} deleteFile={this.props.deleteAdLogs} />
              ):<NoRecordFound style={{margin:'5px'}}/>}
            </div>
            <Modal size="lg" centered={true} className="mluploaded-file" isOpen={adModalStatuss} toggle={this.props.toggleModalFn}>
              <ModalBody>
                <div>
                  <div className="upload-image-block">
                    <img src={imgStoragePath+"upload _arrow.png"} alt="img"/>
                    <span>You are uploading <b>{file ? file.length: 0}</b> File(s)</span>
                  </div>
                  <div>
                    { file.map((fileDetail, index) =>
                      <FilesUploaded
                        key={index}
                        index={index}
                        oemUpload={file && file.length==1 ? true : false}
                        fileDetail={fileDetail}
                        replaceFile = {this.replaceFile}
                        updateField={this.updateForm}
                        deleteFn={this.deleteFn}
                        progressPercentage={adProgressPercentage}
                      />
                    )}
                  </div>
                  { file && file.length == 1  ?
                    <Row className="margin-bottom-10 awiting-for-approval">
                    <Col md="12">
                      <p>
                          Please upload both Operator and OEM AD status reports together. If a single file is uploaded, Operator AD status report will be selected by default since it is used as a reference. Later OEM AD status report can be uploaded to same log.
                      </p>
                    </Col>
                  </Row>:null
                  }
                  { file.length > 2 ? <p style= {{ color: '#ff0000', fontSize: '14px', marginRight:'6px' }}>You can upload only 2 files at a time</p> : file.length > 1 && (file[0].fileType != '' && file[file.length - 1].fileType != '' && file[0].fileType == file[file.length - 1].fileType) ? <p style= {{ color: '#ff0000', fontSize: '14px',  marginRight:'6px'  }}>Both File cannot be same Type</p> : file.length == 1 && file[0].fileType == 'oem_uri'?<p style= {{ color: '#ff0000', fontSize: '14px', marginRight:'6px' }}>You can't upload OEM AD status report only</p>:''}
                </div>
              </ModalBody>
              <div className="confirm-blk">
                <button className="primary-btn"  disabled = {(file.filter(obj => obj.error && ( obj.error == 1 || obj.error == 2) ).length || file.length > 2 || adProgressPercentage > 0 || (file.length > 1 && (file[0].fileType != '' && file[file.length - 1].fileType != '' && file[0].fileType == file[file.length - 1].fileType))||(file.length == 1 && file[0].fileType == 'oem_uri')) ? true : false }   onClick={() => this.uploadMLFile(this.props.params, file)}>Confirm</button>
                <span className="cancel-modal" onClick={this.props.toggleModalFn}>Cancel</span>
                <p className="cstm-progress-bar" style={{ width: adProgressPercentage+ '%', display: 'none' }}></p>
              </div>
            </Modal>
            <EditSidebarIcon
                    title="AI Assistant - Instructions"
                    editSideBar={this.state.readMoreFlag}
                    toggleEditSideBar={() => this.toggleReadMore()}
                >
                  <CautionReadMore
                  contentflag={true}
                  />
              </EditSidebarIcon>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  adModalStatuss: state.ADReducer.adModalStatus,
  adLogsListing: state.ADReducer.adLogsListing,
  adProgressPercentage: state.ADReducer.adProgressPercentage,
  adMLLoader: state.ADReducer.adMLLoader,
  assetDetail: state.ADReducer.assetDetail
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleModalFn: () => dispatch({
      type: AD_FILES_MODAL,
      payload: ''
    }),
    uploadMLFile: (params, file) => dispatch(uploadADFileAc(params, file)),
    adLogsList: () => dispatch(adLogsListAc(ownProps.params)),
    deleteAdLogs: (id) => dispatch(deleteAdLogsAc(ownProps.params, id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ADReviewLogs);
