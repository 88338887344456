import React, { Component } from 'react';
export default class AccrulsWrapper extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="mr-graph-block" style={{marginLeft: '60px'}}>
        {this.props.children}
      </div>
    )
  }
}
