export const PROGRAM_SCHEDULER_LIST = 'PROGRAM_SCHEDULER_LIST';
export const PROGRAM_PLANNER_STATUS='PROGRAM_PLANNER_STATUS';
export const PROGRAM_PLANNER_ASSETS='PROGRAM_PLANNER_ASSETS';
export const PROGRAM_PLANNER_CONFIRM_ASSETS='PROGRAM_PLANNER_CONFIRM_ASSETS';
export  const INSPECTION_TYPE='INSPECTION_TYPE';
export const UPDATE_SCHEDULER_LIST='UPDATE_SCHEDULER_LIST';
export const UPDATE_ASSETS_LIST='UPDATE_ASSETS_LIST';
export const UPDATE_CONFIRM_LIST='UPDATE_CONFIRM_LIST';
export const UPDATE_SELECTED_ASSETS='UPDATE_SELECTED_ASSETS';
export const UPDATE_ASSETS_COMMENTS='UPDATE_ASSETS_COMMENTS';
export const UPDATE_CONFIRM_COMMENTS='UPDATE_CONFIRM_COMMENTS';
export const UPDATE_SCHEDULER_COMMENTS='UPDATE_SCHEDULER_COMMENTS';
export const TOGGLE_PROJECT_SIDEBAR='TOGGLE_PROJECT_SIDEBAR';
export const PROJECT_MEMBERS='PROJECT_MEMBERS';
export const GET_GEN_INFO='GET_GEN_INFO';
export const DELETE_CONFIRM_COMMENTS='DELETE_CONFIRM_COMMENTS';
export const DELETE_ASSETS_COMMENTS='DELETE_ASSETS_COMMENTS';
export const STATUS_PLANNER_LOADER='STATUS_PLANNER_LOADER';
export const PROGRAM_TRACKER_LIST='PROGRAM_TRACKER_LIST';
export const UPDATE_TRACKER_COMMENTS='UPDATE_TRACKER_COMMENTS';
export const COMMON_PLANNER_LOADER='COMMON_PLANNER_LOADER';
export const LESSE_LIST='LESSE_LIST';
export const AIRCARAFT_LIST='AIRCARAFT_LIST';
export const ENGINE_LIST='ENGINE_LIST';
export const CLEAR_SELECTED_IDS='CLEAR_SELECTED_IDS';
