import axiosInstance from '../../../../utils/httpInterceptors';
import sftpAxiosInst from '../../../../utils/sftpInterceptor';
export const mrFinanceGraphApi = (id) => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/plot-mr-projection/',
        params: {
          log_id: id
        }
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}
export const mrFinanceLifeOfWingsApi = (id) => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/plot-engine-life-on-wing/' + id + '/'
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}

export const getSimulateResultApi = (id, data) => {
  const newdata = JSON.parse(JSON.stringify(data));
  if(newdata.apu){
    delete newdata['apu']['plots'];
  }
  if(newdata.landing_gear){
    delete newdata['landing_gear']['plots'];
  }
  if(newdata.airframe){
    Object.keys(newdata.airframe).map(label => {
      delete newdata.airframe[label].plots;
    })
  }
  if(newdata.engines){
    Object.keys(newdata.engines.engine_data).map(label => {
      delete newdata.engines.engine_data[label].plots;
    })
  }
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/simulate/' + id + '/',
        data: newdata
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}
export const graphSummeryRecordApi = (id) => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/export-mr-projection-summary/',
        responseType: 'blob',
        params: {
          download: 'xls',
          log_id:id
        }
      })
      .then(response => {

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MR_Finance_Report_'+ id + '.xls');
        document.body.appendChild(link);
        link.click();

      });
    }
  )
}
export const monthlyBreakDownExportPostApi = (id,data) => {
  const newdata = JSON.parse(JSON.stringify(data));
  if(newdata.apu){
    delete newdata['apu']['plots'];
  }
  if(newdata.landing_gear){
    delete newdata['landing_gear']['plots'];
  }
  if(newdata.airframe){
    Object.keys(newdata.airframe).map(label => {
      delete newdata.airframe[label].plots;
    })
  }
  if(newdata.engines){
    Object.keys(newdata.engines.engine_data).map(label => {
      delete newdata.engines.engine_data[label].plots;
    })
  }
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/simulate/' + id + '/',
        responseType: 'blob',
        data: newdata
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Monthly_Cash_Flow_Report_'+ id + '.xls');
        document.body.appendChild(link);
        link.click();
        resolve(response);
      });
    }
  )
}

export const monthlyAccuralExportPostApi = (id,data) => {
  const newdata = JSON.parse(JSON.stringify(data));
  if(newdata.apu){
    delete newdata['apu']['plots'];
  }
  if(newdata.landing_gear){
    delete newdata['landing_gear']['plots'];
  }
  if(newdata.airframe){
    Object.keys(newdata.airframe).map(label => {
      delete newdata.airframe[label].plots;
    })
  }
  if(newdata.engines){
    Object.keys(newdata.engines.engine_data).map(label => {
      delete newdata.engines.engine_data[label].plots;
    })
  }
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'POST',
        url: 'contracts/' + id + '/simulation/',
        responseType: 'blob',
        data: newdata
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Monthly_Cash_Flow_Report' + '.xls');
        document.body.appendChild(link);
        link.click();
        resolve(response);
      });
    }
  )
}

export const monthlyBreakDownExportApi = (id, filter=null) => {
  return new Promise(
    function(resolve,reject) {
      let params = {};
      params ={
        ...params,
        download: 'xls'
      }
      if(filter != null) {
        if(filter.month && filter.month != ''){
          params = {
            ...params,
            year: filter.month
          }
        }
        if(filter.name && filter.name != ''){
          let str = filter.name.split(/[# ]+/).join('');
          params = {
            ...params,
            reserve_account: str
          }
        }
      }
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/mr-cashflow-breakup/' + id + '/',
        responseType: 'blob',
        params: params
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Monthly_Cash_Flow_Report_'+ id + '.xls');
        document.body.appendChild(link);
        link.click();
      });
    }
  )
}
export const cashFlowListApi = (id) => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/mr-cashflow-breakup/' + id + '/',
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}
export const cashFlowListPostApi = (id,data) => {
  const newdata = JSON.parse(JSON.stringify(data));
  if(newdata.apu){
    delete newdata['apu']['plots'];
  }
  if(newdata.landing_gear){
    delete newdata['landing_gear']['plots'];
  }
  if(newdata.airframe){
    Object.keys(newdata.airframe).map(label => {
      delete newdata.airframe[label].plots;
    })
  }
  if(newdata.engines){
    Object.keys(newdata.engines.engine_data).map(label => {
      delete newdata.engines.engine_data[label].plots;
    })
  }
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/simulate/' + id + '/',
        data:newdata
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}

export const deRateVariableApi = () => {
  return new Promise(
    function(resolve,reject) {
      axiosInstance({
        method: 'GET',
        url: 'mr-calculator/variable-conditions/1/',
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}
export const calculateDeRateApi = (data,id) => {
  return new Promise(
    function(resolve,reject) {
      let newData = Object.assign({}, data);
      newData = {
        ...newData,
        derates: data.derates.sort(function(a, b){return a - b})
      }
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/loi-mr/' + id + '/',
        data: newData
      })
      .then(response => {
        resolve(response);
      });
    }
  )
}
export const exportDeRateApi = (data, id) => {
  return new Promise(
    function(resolve,reject) {
      let newData = Object.assign({}, data);
      newData = {
        ...newData,
        derates: data.derates.sort(function(a, b){return a - b})
      }
      axiosInstance({
        method: 'POST',
        url: 'mr-calculator/loi-mr/' + id + '/',
        responseType: 'blob',
        data: newData
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'LOI_table'+ id + '.xls');
        document.body.appendChild(link);
        link.click();
      });
    }
  )
}
