import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const CashSummaryGraph = (props) => {
  const options = {
    chart: {
      type: 'line',
      zoomType: 'x'
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'xLabel'
      }
    },
    yAxis:{
      title: {
        text: 'yLabel'
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    series:  [{
      showInLegend: false,
      name: 'legend',
      data: [
                [1412199680000, 7.0],
                [1412199980000, 6.9],
                [1412200580000, 9.5],
                [1412200880000, 14.5],
                [1412201180000, 18.4],
                [1412201480000, 21.5],
                [1412201780000, 25.2],
                [1412202080000, 26.5],
                [1412202380000, 23.3],
                [1412202680000, 18.3],
                [1412202980000, 13.9],
                [1412203280000, 9.6]
            ]
    }],
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ]
        }
      }
    }
  }
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default CashSummaryGraph;
