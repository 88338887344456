import * as actions from '../actions';

export default function( state = {
  auditReportsCrud:{
    type: 'edit',
    title: '',
    flag: false,
    data: {}
  },
  auditFindingCrud: {
    type: 'add',
    title: '',
    flag: false,
    data: {}
  },
  auditsSections:{},
  coverImage :{
    data: {},
    flag: false
  },
  formIntroduction :{
    data: {},
    flag: false
  },
  questions : [],
  recordReviews: {
    data : {
      answer: [{
        title: '',
        value: ''
      }]
    },
    flag: false
  },
  inspectionSummary: {
    data:[
      {index:1, title: 'Test', flag: false, content: 'Test Content'},
      {index:2, title: 'Test1', flag: false, content: 'Test Content'},
      {index:3, title: 'Test2', flag: false, content: 'Test Content'},
      {index:4, title: 'Test3', flag: false, content: 'Test Content'},
    ],
    flag: false
  },
  auditFindings: {
    data:[
      {index:1, title: 'Test', flag: false, content: 'Test Content'},
      {index:2, title: 'Test1', flag: false, content: 'Test Content'},
      {index:3, title: 'Test2', flag: false, content: 'Test Content'},
      {index:4, title: 'Test3', flag: false, content: 'Test Content'},
    ],
    flag: false
  },
  findingCrud:{
    flag: false,
    imageUrl: '',
    title: '',
    imageId: 0
  },
  findingLoader: false
}, action ){
  switch (action.type) {
    case actions.FINDING_LOADER:
    return{
      ...state,
      findingLoader: action.payload
    }
    case actions.AUDITS_SECTIONS:
      return {
        ...state,
        auditsSections: action.payload
      }
    case actions.COVER_IMAGE:
      return {
        ...state,
        coverImage: action.payload
      }


    case actions.LOPA_IMAGE:
      return {
        ...state,
        auditReportsCrud : {
          ...state.auditReportsCrud,
          data: [...state.auditReportsCrud.data].map((auditReport,index) => index == 0 ? {
            ...auditReport,
            lopa: {
              ...auditReport.lopa,
              image:action.payload
            }
          } : auditReport
        )
        }
      }
      

    case actions.FORM_INTRODUCTION:
      return {
        ...state,
        formIntroduction: action.payload.data
      }
    case actions.RECORD_REVIEW:
      return {
        ...state,
        recordReviews: action.payload.data
      }
    case actions.REMOVE_REVIEW_TOGGLE:
      return {
        ...state,
        questions : [...state.questions].map(data =>  data.id ==  action.payload.questionId ? {
          ...data,
          answer : {
            ...data.answer,
            value  : [...data.answer.value].filter((item, key) => key != action.payload.answerIndex )
           }
        } : data )
      }
    case actions.UPDATE_TITLE: 
      return {
        ...state,
        questions : state.questions.map(data =>  data.id ==  action.payload.sectionId ? 
          { ...data, [action.payload.keyParam] :action.payload.value } : data )
      }

    case actions.ADD_REVIEW_TOGGLE:
      return {
        ...state,
        questions : [...state.questions].map(data =>  data.id ==  action.payload.type ? {
          ...data,
          answer : {
            ...data.answer,
            value : [...data.answer.value, action.payload.data]
          }  
        } : data )
      }
    case actions.RECORD_REVIEW_TOGGLE:
      return {
        ...state,
        questions : [...state.questions].map(data =>  data.id ==  action.payload.questionId ? {
          ...data,
          answer : {
            ...data.answer,
            value  : [...data.answer.value].map((item, key) => key == action.payload.answerIndex ? {
            ...item, flag: !item.flag
          } : item )
         }
        } : data )
      }
    case actions.QUESTION_UPDATE :
     return {
      ...state,
      questions : [...state.questions].map(data =>  data.id ==  action.payload.questionId ?  action.payload.data.data : data ),

      formIntroduction : {
        ...state.formIntroduction,
        flag: false
      },
      recordReviews : {
        ...state.formIntroduction,
        flag: false
      }
    }
    case actions.UPDATE_REVIEW_TOGGLE:
    return {
      ...state,
      questions : [...state.questions].map(data =>  data.id ==  action.payload.questionId ? {
        ...data,
        answer : {
          ...data.answer,
          value : [...data.answer.value].map((item, key) => key == action.payload.index ? {
            ...item,
            [action.payload.keyParam]: action.payload.data 
          } : item )
        } 
        } : data )
    }
    case actions.AUDITS_SECTIONS_INFO:
    
      return {
        ...state,
        auditReportsCrud: action.payload
      }
    case actions.AUDITS_FINDING_INFO:
      return {
        ...state,
        auditFindingCrud: action.payload
      }
    case actions.UPDATE_FINDINGS_T004:
      return {
        ...state,
        auditReportsCrud : {
          ...state.auditReportsCrud,
          data: [...state.auditReportsCrud.data].map(auditReport => auditReport.id == action.payload.sectionId ? {
            ...auditReport,
            inspection_findings: [...auditReport.inspection_findings].map(findingData => findingData.id == action.payload.data.id ? action.payload.data : findingData )
          } : auditReport
        )
        },
        auditFindingCrud : {
          ...state.auditFindingCrud,
          flag: false,
          title: '',
        }
      }
    case actions.TOOGLE_AUDIT_TAB:
      return {
        ...state,
            auditReportsCrud : {
          ...state.auditReportsCrud,
          flag: false,
          title: '',
        }
      }
    case actions.ADD_FINDINGS_T004:
      return {
        ...state,
        auditReportsCrud : {
          ...state.auditReportsCrud,
          data: [...state.auditReportsCrud.data].map(auditReport => auditReport.id == action.payload.sectionId ? {
            ...auditReport,
            inspection_findings: [...auditReport.inspection_findings,action.payload.data]
          } : auditReport
         )
        },
        auditFindingCrud : {
          ...state.auditFindingCrud,
          flag: false,
          title: '',
        }
      }   
     case actions.DELETE_FINDING_T004:
     return {
      ...state,
      auditReportsCrud: {
        ...state.auditReportsCrud,
        data: [...state.auditReportsCrud.data].map(auditReport => auditReport.id == action.payload.sectionId ? {
          ...auditReport,
          inspection_findings: [...auditReport.inspection_findings].filter(findingData => findingData['id'] != action.payload.findingId )
        } : auditReport
        )
      }
    }
    case actions.CHANGE_IMAGE:
    return {
      ...state,
      auditFindingCrud: {
        ...state.auditFindingCrud,
        data: {
          ...state.auditFindingCrud.data,
          images: [...state.auditFindingCrud.data.images].map(image =>  image.id == action.payload.id ?
            action.payload
            :  image)
        },
      },
    }
    case actions.UPDATE_IMAGE_IN_EDIT:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: action.payload
          },
        },
      }
    case actions.DELETE_IMAGE:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: [...state.auditFindingCrud.data.images].filter(image =>  image.id != action.payload )
          },
        },
      }
      case actions.DELETE_ADD_IMAGE: 
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: [...state.auditFindingCrud.data.images].filter((image, index) =>  index != action.payload.picIndex )
          },
        },
      }
      case actions.FETCH_QUESTIONS:
 

      let resData = action.payload.data.map((questionObj, index) => questionObj.answer && Object(questionObj.answer).length ? {
        ...questionObj,
        answer: {
          ...questionObj.answer,
          value : [...questionObj.answer.value].map(ansValue => ansValue.flag ?  {
            ...ansValue,
            flag: false
          } : ansValue )
        } 
      }: questionObj);

      return{
        ...state,
        questions: resData
      }
      case actions.REMOVE_COMPONENT_t004:
        return {
          ...state,
          auditReportsCrud : {
            ...state.auditReportsCrud.data,
            data :   [...state.auditReportsCrud.data].map(section => section.id == action.payload.sectionId ?  {
              ...section,
              component_questions : [...section.component_questions].filter(component => component.id != action.payload.id)
            } : section ),
            flag: true
          }
        }
      case actions.TOGGLE_T004_T007:
          return {
            ...state,
            auditFindingCrud: {
              type: 'add',
              title: '',
              flag: false,
              data: {}
            }
          }
    default:
      return state;
  }
}
