import React, {Component}  from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory, withRouter } from 'react-router';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DropDownField from './DropDownField';
import { imgStoragePath } from '../../../constants';
import { ToolTipHover, ListLoader } from '../../../shared';
import { dateTransformAllSet, getLocalStorageInfo } from '../../../utils';
import UserInfoModal from './UserInfoModal';
import { getUserDetailAc } from '../Projects/actionCreators';
import { CLOSE_USER_MODAL } from '../Projects/actions';
import { EditFormBar } from './';
import moment from 'moment';
import { trackActivity } from '../../../utils/mixpanel';
class FormHeader extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      dropdownOpen: false,
      headerHeight: 0,
      techMSNswitcherHeight: 0,
      formHeaderHeight: 0,
      modal: false,
      remarks: '',
      remarksError: '',
      formLogsFlag: false
  	};
  	this.toggle = this.toggle.bind(this);
    this.toggleModel = this.toggleModel.bind(this);
  }
  componentDidMount(){
    let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
    let techMSNswitcherHeight = document.body.contains(document.getElementById('techMsn-switcher')) ? document.getElementById('techMsn-switcher').clientHeight : 0 ;
    let formHeaderHeight =  document.body.contains(document.getElementById('form-header')) ? document.getElementById('techMsn-switcher').clientHeight : 0 ;
    this.setState({
      headerHeight: headerHeight,
      techMSNswitcherHeight: techMSNswitcherHeight,
      formHeaderHeight: formHeaderHeight
    })
  }
	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
  toggleModel() {
    this.setState({
      modal: !this.state.modal,
      remarks: '',
      remarksError: ''
    });
  }
  dowloadFormLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      formLogsFlag: !prevState.formLogsFlag
    }))
  }
  changeForm = (form) =>{
    var location = `/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${form.slug}/${form.name.toLowerCase()}`;
    browserHistory.push(location);
  }
  reviewForm = () =>{
    var location = `/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/review/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/${this.props.formInfo.current_form.name.toLowerCase()}`;
    browserHistory.push(location);
  }
  rejectConfirm = (status) => {
    this.toggleModel();
  }
  handleRemarks = (e) => {
    let value = e.target.value;
    this.setState({
      remarks: value,
      remarksError:''
    })
  }
  updateRejectStatus = () => {
    if(this.state.remarks != ''){
      this.props.updateFormStatus({status: 3, remarks: this.state.remarks});
      this.toggleModel();
    }else{
      this.setState({
        remarksError: 'Please enter the Remarks'
      })
    }
  }
  render(){
    let disableDropDown = this.props.formInfo && this.props.formInfo.list && this.props.formInfo.list.length ?
    this.props.formInfo.list.length > 1 ? false:true:true;
    const { techMSNswitcherHeight,headerHeight, formHeaderHeight } = this.state;
    const userInfo = getLocalStorageInfo().user
    return(
      <div className="form-header" id="form-header">
      {this.props.projectLoader?<ListLoader/>:null}
        <Row>
          <Col md= { this.props.params.type=='aircraft' && this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.show_review && this.props.formInfo.current_form.status && this.props.formInfo.current_form.status.id == 7  && this.props.formInfo.current_form.name?
              this.props.formInfo.current_form.name.indexOf('T008')!=-1||this.props.formInfo.current_form.name.indexOf('T011')!=-1?"8":"6":"8"} className="padding-left-0 flex-centered">
            <div className="select-form-block">
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                { this.props.formInfo && this.props.formInfo.list && this.props.formInfo.list.length ?
                  this.props.formInfo.list.map(item =>
                    item.id == this.props.formInfo.current_form.id ?
                    <DropdownToggle key={'menu_'+item.id} caret disabled={disableDropDown}>
                      {item.name}
                      <p>{item.title}</p>
                    </DropdownToggle>
                    :null
                  )
                  :null
                }
                <DropdownMenu
                  style={{  height: window.innerHeight - (headerHeight + techMSNswitcherHeight + formHeaderHeight + 20 ) }}
                  >
                  { this.props.formInfo && this.props.formInfo.list && this.props.formInfo.list.length ?
                    this.props.formInfo.list.map(item =>
                      item.id != this.props.formInfo.current_form.id ?
                      <DropdownItem key={"item_"+item.id} onClick={() => this.changeForm(item)} style={{cursor: 'pointer'}}>
                        <Link>
                          {item.name}
                          <span>{item.title}</span>
                        </Link>
                      </DropdownItem>
                      :null
                    ):null
                  }
                </DropdownMenu>
              </Dropdown>
              <ul className="list-inline flex-centered">
                <li className="list-inline-item" style={{width: this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.status.label == "Completed" ? "100%" : this.props.formInfo.current_form && this.props.formInfo.current_form.progress && this.props.formInfo.current_form.progress+'%',backgroundColor: '#2dbd17'  }}  >
                </li>
              </ul>
            </div>
						<ul className="export-block  list-inline flex-centered">
              { this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.status && this.props.formInfo.current_form.status.id !== 1 && this.props.formInfo.current_form.permissions.can_save ?
                this.props.saveButton ?
                <li>{this.props.saveButton}</li>
                :null:null
              }
              { this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_submit ?
                <li>
                  <button className="primary-btn" onClick={() => {this.props.updateFormStatus({status: 5}); trackActivity('Item Submitted', { page_title: this.props.formInfo.current_form && this.props.formInfo.current_form.name, application_module: 'Inspection',  item_type:this.props.formInfo.current_form && this.props.formInfo.current_form.title })}}>Submit</button>
                </li>
                :null
              }
              {this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_accept ?
                <li>
                  <button className="primary-btn" onClick={() => {this.props.updateFormStatus({status: 6}); trackActivity('Item Accepted', { page_title: this.props.formInfo.current_form && this.props.formInfo.current_form.name, application_module: 'Inspection',  item_type:this.props.formInfo.current_form && this.props.formInfo.current_form.title })}}>Accept</button>
                </li>
                :null
              }
              {this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_accept ?
                <li>
                  <button style={{border: '1px solid #c31b1b', backgroundColor: '#fd4949'}} className="primary-btn" onClick={() => {this.rejectConfirm(3); trackActivity('Item Rejected', { page_title: this.props.formInfo.current_form && this.props.formInfo.current_form.name, application_module: 'Inspection',  item_type:this.props.formInfo.current_form && this.props.formInfo.current_form.title })}}>Reject</button>
                </li>
                :null
              }
              {this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.can_publish ?
                <li>
                  <button className="primary-btn" onClick={() => {this.props.updateFormStatus({status: 7}); trackActivity('Item Published', { page_title: this.props.formInfo.current_form && this.props.formInfo.current_form.name, application_module: 'Inspection',  item_type:this.props.formInfo.current_form && this.props.formInfo.current_form.title })}}>Publish</button>
                </li>
                :null
              }
    					<li className="list-inline-item download-block">
                {this.props.exportReport}
                { this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.download_report.length ?
                  <p onClick={this.props.toggleDownloadedLogs} style={{fontSize: '10px', cursor: 'pointer'}}>Last Downloaded By {this.props.formInfo.current_form.download_report[0].user} &#9432;</p>:null
                }
    					</li>
    					{
                this.props.showImport && (userInfo && userInfo.permission && userInfo.permission.technical && userInfo.permission.technical.t011_import && userInfo.permission.technical.t011_import.indexOf('IMP') != -1 && this.props.importReport && this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.status && [2,3].includes(this.props.formInfo.current_form.status.id)) || window.location.href.includes('t013') ?
                <li className="list-inline-item download-block">
                  <div className="dropdown">
                    <span className="export-dropdown" onClick={this.props.importReport}><img className="export-icn" src={imgStoragePath + 'import_icon.png'}/>Import Report</span>
                  </div>
                  { this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.upload_report && this.props.formInfo.current_form.upload_report.length ?
                    <p onClick={this.props.showUploadLogs} style={{fontSize: '10px', cursor: 'pointer'}}>Last Uploaded By {this.props.formInfo.current_form.upload_report[0].user} &#9432;</p>:null
                  }
      					</li>:null
              }
              { this.props.showActivity ?
                <li className="list-inline-item download-block">
                  <div className="dropdown">
                    <span className="export-dropdown" onClick={() => this.props.showActivityLogs()}> <i className="fa fa-users" style={{marginRight: '5px'}}/>{this.props.exportActivityLogName}</span>
                  </div>
                  <p style={{fontSize: '10px'}}>&nbsp;</p>
                </li>
                :null
              }
						</ul>
          </Col>
          <Col md= { this.props.params.type=='aircraft' && this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.show_review && this.props.formInfo.current_form.status && this.props.formInfo.current_form.status.id == 7  && this.props.formInfo.current_form.name?
              this.props.formInfo.current_form.name.indexOf('T008')!=-1||this.props.formInfo.current_form.name.indexOf('T011')!=-1?"4":"6":"4"}className="detail-block flex-not-centered">
            <ul className="list-inline flex-centered">
              <li className="list-inline-item" style={{width:'100px',margin:'0px 10px'}} onClick = {() => this.props.getUserDetailFn(this.props.formInfo.current_form.engineer.slug, true)}>
                <h6>Project Engineer</h6>
		            { this.props.formInfo.current_form && this.props.formInfo.current_form.engineer && this.props.formInfo.current_form.engineer.name != undefined && this.props.formInfo.current_form.engineer.name != null?
                  <p id={`project-${this.props.formInfo.current_form.engineer.id}`}>{this.props.formInfo.current_form.engineer.name.length > 12 ? this.props.formInfo.current_form.engineer.name.substring(0,10) + '..': this.props.formInfo.current_form.engineer.name}</p>
                  :<p>--</p>
                }

            { this.props.formInfo.current_form && this.props.formInfo.current_form.engineer && this.props.formInfo.current_form.engineer.name != undefined && this.props.formInfo.current_form.engineer.name != null?
              <ToolTipHover placement="top" tagetId={`project-${this.props.formInfo.current_form.engineer.id}`}>
                    { this.props.formInfo.current_form.engineer.name }
              </ToolTipHover>
              :null
            }

	            </li>
              <li className="list-inline-item lesse-block" style={{width:'100px',margin:'0px 10px'}}>
                <h6>Last Activity</h6>
  					    { this.props.formInfo.current_form && this.props.formInfo.current_form.updated_at != undefined && this.props.formInfo.current_form.updated_at != null ?
                  <p>{`${moment(this.props.formInfo.current_form.updated_at).format('MMM DD, YYYY')}`}</p>
                  :<p>--</p>
                }
              </li>
              <li className="list-inline-item" style={{width:'100px',margin:'0px 10px'}}>
                <h6>Form Status</h6>
	              { this.props.formInfo.current_form && this.props.formInfo.current_form.status != undefined && this.props.formInfo.current_form.status != null ?
                  <p>{this.props.formInfo.current_form.status.label}</p>
                  :<p>--</p>
                }
		          </li>
              { this.props.params.type=='aircraft' && this.props.formInfo && this.props.formInfo.current_form && this.props.formInfo.current_form.permissions.show_review && this.props.formInfo.current_form.status && this.props.formInfo.current_form.status.id == 7  && this.props.formInfo.current_form.name?
              this.props.formInfo.current_form.name.indexOf('T008')!=-1||this.props.formInfo.current_form.name.indexOf('T011')!=-1 || this.props.formInfo.current_form.name.indexOf('T013')!=-1? null:
              <li style={{width:'122px',margin:'0px 20px 0px 10px'}}>
                <p className="review-edit-btn">
                  <Link onClick={()=>this.reviewForm()} className>Review</Link>
                  <Link to=""className="active">Edit</Link>
                </p>
              </li>:null
              }
            </ul>
          </Col>
        </Row>

        <Modal isOpen={this.state.modal} toggle={this.toggleModel} className={this.props.className}>
          <ModalHeader toggle={this.toggleModel}>Reason of Rejection</ModalHeader>
          <ModalBody>
            <textarea style={{border: '1px solid #d7d7d7', width: '100%', height: '100px'}} onChange={this.handleRemarks}  value={this.state.remarks}>
            </textarea>
            <h6 className="error-msg">{this.state.remarksError}</h6>
            <p>
              <button onClick={this.updateRejectStatus} style={{float: 'right'}} className="primary-btn">SAVE</button>
            </p>
          </ModalBody>
        </Modal>

        <UserInfoModal
  				userDetail = {this.props.userDetail}
  				closeUserDetailModal = {this.props.closeUserDetailModal}
	       />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.PorjectsReducer.userDetail,
  projectLoader: state.PorjectsReducer.projectLoader

});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return {
    getUserDetailFn : (userSlug, flag) => dispatch(getUserDetailAc(userSlug, flag)),
    closeUserDetailModal: (data, flag) => dispatch({
      type: CLOSE_USER_MODAL,
      payload: {data: data, flag: flag}
    })
  }
}
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormHeader));
