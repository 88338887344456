import * as actions from '../actions';
export default function(state = {
  obligationList: [],
  approvedByUsers:[],
  obligationCrud: {
    type: '',
    data: {},
    flag: false
  },
  obligationsLoader: true,
}, action){
switch (action.type) {
  case actions.CONTR_OBLIGATIONS_LIST:
    return{
      ...state,
      obligationList: action.payload
    }
  case actions.ADD_OBLIGATION_SUCCESS:
    return {
      ...state,
      obligationList: [ action.payload, ...state.obligationList]
    }
  case actions.EDIT_OBLIGATION_SUCCESS:
    return {
      ...state,
      obligationList: state.obligationList.map(list => list.id == action.payload.id ? action.payload : list)
    }
  case actions.OBLIGATION_CRUD:
    return {
      ...state,
      obligationCrud: action.payload
    }
  case actions.TOGGLE_VIEW_EDIT:
    return {
      ...state,
      obligationCrud: {
        ...state.obligationCrud,
        type: (action.payload == 'view' ? 'edit': 'view')
      }
    }
  case actions.OBLIGATIONS_LOADER:
    return {
      ...state,
      obligationsLoader: action.payload
    }
    case actions.GET_APPROVED_BY_USER:
      return {
        ...state,
        approvedByUsers:action.payload
      }
  default:
    return state;
  }
}
