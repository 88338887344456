import * as actions from '../actions';

export default function( state = {
  itemSideBar:false,
  mrSectionLoader:false,
  mrSectionDet: {},
  error:[]
}, action ){

  switch (action.type) {

    case  actions.GET_MRSECTION_DETAILS:
     return {
      ...state,
      mrSectionDet: action.payload
    }

    case actions.ADD_TE006_SECTION:
    return{
       ...state,
       mrSectionDet : {
         ...state.mrSectionDet,
         sections : [...state.mrSectionDet.sections, action.payload]
       }
     }
    case actions.MR_DELETE:
    return {
      ...state,
      mrSectionDet: {
        ...state.mrSectionDet,
        attachments: [...state.mrSectionDet.attachments].filter((attachments, index) => index != action.payload)
      }
    }
    case actions.MR_SECTION_DELETE:
    return {
      ...state,
      mrSectionDet: {
        ...state.mrSectionDet,
        sections: [...state.mrSectionDet.sections].filter((sections, index) => index != action.payload)
      }
    }
    case actions.MRSECTION_DETLS_LOADER:
      return {
        ...state,
        mrSectionLoader: action.payload
      }

    case actions.ATTACH_UPLOAD_FILE:
      return{
       ...state,
       mrSectionDet : {
         ...state.mrSectionDet,
           attachments : [...state.mrSectionDet.attachments, action.payload]
       }
     }
     case actions.UPDATE_TE006_FORM:
      if(action.payload.sectionType == 'details'){
          return{
          ...state,
          mrSectionDet: {
            ...state.mrSectionDet,
            details: {
              ...state.mrSectionDet.details,
              [action.payload.type]: action.payload.value
            }
          }
        }
      }else if (action.payload.sectionType == 'attachment'){
        return{
          ...state,
          mrSectionDet: {
            ...state.mrSectionDet,
            attachments:[...state.mrSectionDet.attachments].map((mrDetails, index)=>index==action.payload.index ?{
              ...mrDetails,
              [action.payload.type]: action.payload.value
             }:mrDetails
             )
          }
        }
      } else{
        return{
          ...state,
          mrSectionDet: {
            ...state.mrSectionDet,
            sections:[...state.mrSectionDet.sections].map((mrSections, index)=>index==action.payload.index ?{
              ...mrSections,
              [action.payload.type]: action.payload.value
             }:mrSections
             )
          }
        }
      }
      case actions.ERROR_MESSAGE_06:
      return{
        ...state,
        error: action.payload
      }
      case actions.CLEAR_ERR_MESSGAE_06:
      if(action.payload.sectionType == 'details'){
        return{
          ...state,
          error: [...state.error].map((dataGenDet, index) => index == 0 ?
            [...dataGenDet].map((data, index) => data&&Object.keys(data).includes(action.payload.type) ? {
              ...data,
              [action.payload.type] : ''
            } : data
          )
          : dataGenDet )
        }
      }

      if(action.payload.sectionType == 'section'){
        return{
          ...state,
          error: [...state.error].map((compoDet, compIndex) => compIndex == 1 ?
            [...compoDet].map((data, index) => index == action.payload.index  ? {
              ...data,
              [action.payload.type] : ''
            } : data
          )
          : compoDet )
        }
      }


    default:
      return state;
  }
}
