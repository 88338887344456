import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { imgStoragePath } from '../../../constants';
import { globalPostService} from '../../../globalServices';
class EmailVerification extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };
  }

  componentDidMount(){
    const data = {
      uSlug: this.props.params.token.split('_')[0],
      token: this.props.params.token.split('_')[1]
    }
    globalPostService('api/user/activate/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState({
          statusCode: response.data.statusCode,
          statusMsg: response.data.message
        })
      }else{
        this.setState({
          statusCode: response.data.statusCode,
          statusMsg: response.data.message
        });
      }
    });
  }

  render(){
    return(
      <div>
        {
          !this.state.statusCode?
            <div className="email-verified text-center">
              <i className="fa fa-spinner fa-spin" style={{ fontSize: '60px', padding: '45px 0 30px' }}></i>
              {/* <img style={{ height: '100px' }} className="loader-gif" src={imgStoragePath+"loader_v2.gif"} alt="icn" /> */}
              <p className="paragraph" style={{ paddingBottom: '45px' }}>
                Verifying...
              </p>
            </div>
          :this.state.statusCode == 200 ?
            <div className="email-verified text-center">
                  <img src={imgStoragePath+'phase-2/success.png'} />
                  <h2 className="form-title">Email verified successfully!</h2>
                  <p className="paragraph">
                    You have successfully verified your account, now you <br/>
                    can login with your user credentials.
                  </p>
                  <Link to="/login" className="ok-btn">Sign In</Link>
            </div>
          : <div className="email-verified text-center">
              <img src={imgStoragePath+'phase-2/failed.svg'} />
              <h2 className="form-title">Email verification failed!</h2>
              <p className="paragraph">
                { this.state.statusMsg }
              </p>
              <Link to="/login" className="ok-btn">Ok</Link>
            </div>
        } 
      </div>
    )
  }
}

export default  withRouter(EmailVerification)
