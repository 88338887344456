import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,  Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { DragDropFiles, FilesUploaded, List } from '../components';
import { imgStoragePath } from '../../../../constants';
import { EditSidebarIcon } from '../../../contracts/Elements';
import CautionReadMore from '../../../../shared/components/CautionReadMore';
import { SecondarySideBar, SecondaryHeader, ListLoader,NoRecordFound } from '../../../../shared';
import { uploadADFileAc, adLogsListAc, deleteAdLogsAc } from '../actionCreators';
import { AD_FILES_MODAL } from '../actions';
class TechSpecsLogs extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      dropdownOpen: false,
      file: [],
      sameKindFile: false,
      readMoreFlag:false,
    }
  }
  componentDidMount(){
    this.props.adLogsList();
  }

  toggleReadMore=()=>{
    this.setState(prevState => ({
      ...prevState,
      readMoreFlag: !prevState.readMoreFlag,
    }));
  }

  confirmClickModal = (file) => {
    if(!file.length){
      return false;
    }
    let fileInfo = [];
    for(var i=0; i<file.length; i++) {
      var f = file[i];
      fileInfo.push(f);
    }
    this.props.uploadMLFile(fileInfo);
  }
  replaceFile = (index, fileDetail) => {
    if((fileDetail.name.toLowerCase()).substr(((fileDetail.name.toLowerCase()).lastIndexOf('.') + 1)) == 'pdf'){
      this.setState(prevState => ({
        ...prevState,
        file: prevState.file.map((file, key) => key == index ?  { file: fileDetail, fileType : '', error: '' }  : file),
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        file: prevState.file.map((file, key) => key == index ?  { file: fileDetail, error: 1 }  : file),
      }))
    }
  }
  deleteFn = (index) => {
    var file = this.state.file.filter(( obj, key ) => {
      return key !== index;
    });

    this.setState(prevState => ({
      ...prevState,
      file: file,
    }));
    if(this.state.file.length <= 1){
      this.props.toggleModalFn(!this.props.modalStatus);
    }
  }
  updateForm = (fileType, value, index) =>{
    this.setState(prevState => ({
      ...prevState,
      file: prevState.file.map((file, key) => key == index ? { ...file, [fileType] : value, error: '' } : file),
    }))
  }
  uploadMLFile = (params, file) => {
    let submitFileinfo =  file.map(file => file.fileType == '' ? { ...file, error : 2 } : file)
    this.setState(prevState => ({
      ...prevState,
      file: submitFileinfo,
    }))
    if(!submitFileinfo.filter(obj => obj.error && obj.error == 2 ).length){
      if(file.length > 1 && !(file[0].fileType != '' && file[file.length - 1].fileType != '') && file[0].fileType == file[file.length - 1].fileType){
        this.props.uploadMLFile(params, file);
      }else{
        this.props.uploadMLFile(params, file);
      }
    }
  }
  render(){
    const { file, modal } = this.state;
    const { adModalStatuss, adProgressPercentage, adLogsListing, assetDetail, adMLLoader } = this.props;
    return(
      <div className="">
        <SecondaryHeader assetInfo={assetDetail} technical={true}/>
        <div className="sparta-secondary-sidbar">
          <ul className="list-unstyled">
            <li>
              <Link className={this.props.location.pathname.includes('techspecs-logs') || this.props.location.pathname.includes('techspecs-logs') ? 'active': ''} to={'/'+this.props.params.type +'/'+this.props.params.aircraft_slug+'/techspecs-logs'}>Technical Specs Logs</Link>
            </li>
          </ul>
        </div>
        { adMLLoader ? <ListLoader />: null }
        <div className="" style={{margin: '54px -15px 0 195px',  padding: '30px'}}>
          <div className="">
            <div className="mluploaded-file data-log-list">
              <DragDropFiles
                confirmModal={(files) => this.props.uploadMLFile(files)}
                confirmClickModal={(files) => this.confirmClickModal(files)}
              />
              <p className="notice-info" style={{marginLeft:'5px',marginRight:'5px'}}>
                <img className="caution-img" src={imgStoragePath+"caution_sign.png"} alt="img"/>
                <span>CAUTION!</span> Please read the instructions for the optimum results
                <Link onClick={()=>this.toggleReadMore()} className="pull-right">Read More &rarr;</Link>
              </p>
              <h4 className="para">Log History</h4>
              { adLogsListing&&adLogsListing.length? adLogsListing.map((item, index) =>
                <List key={index} item={item} deleteFile={this.props.deleteAdLogs} />
              ):<NoRecordFound style={{margin:'5px'}}/>}
            </div>
            <EditSidebarIcon
                    title="AI Assistant - Instructions"
                    editSideBar={this.state.readMoreFlag}
                    toggleEditSideBar={() => this.toggleReadMore()}
                >
                  <CautionReadMore
                  contentflag={false}
                  />
              </EditSidebarIcon>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  adModalStatuss: state.ADReducer.adModalStatus,
  adLogsListing: state.ADReducer.adLogsListing,
  adProgressPercentage: state.ADReducer.adProgressPercentage,
  adMLLoader: state.ADReducer.adMLLoader,
  assetDetail: state.ADReducer.assetDetail
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleModalFn: () => dispatch({
      type: AD_FILES_MODAL,
      payload: ''
    }),
    uploadMLFile: (file) => dispatch(uploadADFileAc(ownProps.params, file)),
    adLogsList: () => dispatch(adLogsListAc(ownProps.params)),
    deleteAdLogs: (id) => dispatch(deleteAdLogsAc(ownProps.params, id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TechSpecsLogs);
