import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import {  GetInputField } from '../../Elements';
import CKeditor from "../../../../shared/CKEditor";

export default class EngineHistory  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = (evt) => {
    this.props.updateForm({type: 'operation', value:evt.editor.getData(), sectionType: 'EngineCurrentDetails', index:this.props.index})
  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
     <Row  style={{margin: '0px 15px'}}>
      <Row  style={{width: '100%'}}>
         <Col md="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Manufacturing Date <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: content.engine_manufacturing_date,
              isDisabled: this.props.editFeature?true:false,
              error:  error.engine_manufacturing_date,
              updateForm: (value) => this.props.updateForm({type: 'engine_manufacturing_date', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})

              }
            )
          }
         </Col>

         <Col md="4"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'string',
              <label className="for-label">Constructed Model </label>,
              { type: 'edit',
              value: content.constructed_model,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'constructed_model', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})

              }
            )
          }
         </Col>
         <Col md="4"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'date',
              <label className="for-label">Date Of Installation </label>,
              { type: 'edit',
              value:   content.date_of_installation,
              error:   error.date_of_installation,
              minDate: content.engine_manufacturing_date,
              updateForm: (value) => this.props.updateForm({type: 'date_of_installation', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})

              }
            )
          }
         </Col>

      </Row>
      <Row style={{width: '100%'}}>
          <Col>
          <label className="for-label" style={{marginBottom:'5px'}}>Engine Operations</label>
          <CKeditor
            events={{"change": this.updateForm}}
            content={content.operation}
          />
          </Col>
         </Row>
    </Row>
    </div>
    );
  }
}
