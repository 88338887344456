import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';
import { CtrTableHd } from '../../../contracts/Elements/';
import Select from 'react-select'
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { getLocalStorageInfo } from '../../../../utils';
import {toastFlashMessage, showCurrencyFormat} from '../../../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate, FieldDateIndex } from '../../Elements';
import { pbhRangeCondition, currencyList ,rentalTypeDropdown, intervalDropdown, escalationIntervalDropdwn, fieldDateFormat ,imgStoragePath,securityGroups,displayDateFormat, backendDateFormat, days} from  '../../../../constants'
import { checkString, checkValidDate, checkNumber,checkDateDiff } from '../../../../formValidator';
const decimalRegex= /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
const numericWithHyphen =  /^[\d\-]+$/
const numberWithDot =  /^[0-9]*(\.\d{0,2})?$/
export default class EditRentalForm  extends Component {
  constructor(props){
    super(props);
    this.state = {
      leaseRental: {},
      sheetInfo: {},
      error: {},
      validationArray: {},
      payToggleState:false,
      updateSheetFlag:false,
      fileSheet:'',
      paymentList:[{},{},{},{}],
      adminFlag:false,
      contracterFlag:false,
      requireBanking: false,
      flight_hour_pbh: false,
      block_hour_pbh: false,
      flight_cycle_pbh: false,
      flight_hour_flexi: false,
      block_hour_flexi: false,
      flight_cycle_flexi: false,
      showLess: true,
      showGreat: true,
      errorRate:''
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.rentalView));
    let dataSheet = JSON.parse(JSON.stringify(this.props.sheetInfo));
    delete data['rental_type'];
    delete data['rental_interval'];
    delete data['rental_escalation_interval'];
    delete data['lease_type'];
    delete data['lease_type_accounting'];
    let pbh_condition = []
    if(this.props.rentalView.pbh_condition && this.props.rentalView.pbh_condition.length){
      this.props.rentalView.pbh_condition.map(condition => {
        if(this.props.rentalView.unit_of_measure && this.props.rentalView.unit_of_measure.value === 1){
          pbh_condition.push({
              type: 1,
              min: condition.min,
              max: condition.max,
              ratio_range: condition.ratio_range,
              rate: condition.rate,
              rental_rate_type: condition.rental_rate_type,
              remarks: parseInt(condition.ratio_range) === 1 ? 'max' : parseInt(condition.ratio_range) === 6 ? 'min' : 'min,max'
          })
        }else if(this.props.rentalView.unit_of_measure && this.props.rentalView.unit_of_measure.value === 2){
          pbh_condition.push({
              type: 2,
              min: condition.min,
              max: condition.max,
              ratio_range: condition.ratio_range,
              rate: condition.rate,
              rental_rate_type: condition.rental_rate_type,
              remarks: parseInt(condition.ratio_range) === 1 ? 'max' : parseInt(condition.ratio_range) === 6 ? 'min' : 'min,max'
          })
        }else if(this.props.rentalView.unit_of_measure && this.props.rentalView.unit_of_measure.value === 4){
          pbh_condition.push({
              type: 4,
              min: condition.min,
              max: condition.max,
              ratio_range: condition.ratio_range,
              rate: condition.rate,
              rental_rate_type: condition.rental_rate_type,
              remarks: parseInt(condition.ratio_range) === 1 ? 'max' : parseInt(condition.ratio_range) === 6 ? 'min' : 'min,max'
          })
        }else if(this.props.rentalView.unit_of_measure && this.props.rentalView.unit_of_measure.value === 3){
          pbh_condition.push({
              type: condition.type,
              min: condition.min,
              max: condition.max,
              ratio_range: condition.ratio_range,
              rate: condition.rate,
              rental_rate_type: condition.rental_rate_type,
              remarks: parseInt(condition.ratio_range) === 1 ? 'max' : parseInt(condition.ratio_range) === 6 ? 'min' : 'min,max'
          })
        }
      })
    }
    data = {
      ...data,
      rental_type : this.props.rentalView.rental_type && this.props.rentalView.rental_type.value ? this.props.rentalView.rental_type.value : null,
      interval : this.props.rentalView.interval && this.props.rentalView.interval.value ? this.props.rentalView.interval.value : null,
      escalation_interval : this.props.rentalView.escalation_interval && this.props.rentalView.escalation_interval.value ? this.props.rentalView.escalation_interval.value : null,
      unit_of_measure : this.props.rentalView.unit_of_measure && this.props.rentalView.unit_of_measure.value ? this.props.rentalView.unit_of_measure.value : null,
      pbh_condition: pbh_condition,
      range_calculation_type: this.props.rentalView.range_calculation_type === true ? true :false,
    }
    this.setState({
      leaseRental: data,
      sheetInfo:dataSheet
    });
    if(this.props.type=='edit'){
      this.setState({
        payToggleState:true
      })
    }
    this.props.rentalView.requested_data && Object.keys(this.props.rentalView.requested_data).length ?
    getLocalStorageInfo().user.is_root_user?
    this.setState({
      adminFlag: true
    }):
    getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
    this.setState({
      adminFlag: true
    }):
    this.setState({
      contracterFlag: true
    }):
    this.setState({
      contracterFlag: false,
      adminFlag:false
    })


  }

  capitalizeFirstLetter=(str)=> {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  componentWillReceiveProps(nextProps){
    if(this.props.sheetInfo != nextProps.sheetInfo){
    this.updateSheetState(nextProps.sheetInfo)
    }
    if(this.props.type != nextProps.type){
    if(nextProps.type=='edit'){
      this.setState({
        payToggleState:true
      })
    }
  }
  }

  updateSheetState = (sheetInfo) => {
    this.setState({
      sheetInfo: sheetInfo
    });
  }

  addRatio = (unit_of_measure) => {
    this.setState(prevState => ({
      ...prevState,
      leaseRental: {
        ...prevState.leaseRental,
        pbh_condition: [
          ...prevState.leaseRental.pbh_condition,
          {
            type: unit_of_measure,
            min: 0,
            max: 0,
            ratio_range: '',
            rate: '1',
            rental_rate_type: 1,
            remarks: ''
          }
        ]
      },
    }))
  }

  addFlexiRatio = (unit_of_measure, type) => {
    this.setState(prevState => ({
      ...prevState,
      leaseRental: {
        ...prevState.leaseRental,
        pbh_condition: [
          ...prevState.leaseRental.pbh_condition,
          {
            type: unit_of_measure,
            min: 0,
            max: 0,
            ratio_range: type === 'range' ? '4' : type === 'min' ? '6' : '1' ,
            rate: 0,
            rental_rate_type: type !== 'range' ? 1 : 2,
            remarks: type !== 'range' ? type :'min,max'
          }
        ]
      },
    }))
  }

  updateRangeConditon = (key, value, index) =>{
    if(key === 'ratio_range'){
      let condition = {
        1: 'max',
        6: 'min',
        4: 'min,max'
      }
      let dynamicValue = ''
      if(value === 1){
        this.setState(prevState => ({
          ...prevState,
          leaseRental: {
            ...prevState.leaseRental,
            pbh_condition: prevState.leaseRental.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? {...range,[key]: value, remarks: condition[value], max: 0, min: 0} : range)
          },
        }))
      }else if(value === 6){
        this.setState(prevState => ({
          ...prevState,
          leaseRental: {
            ...prevState.leaseRental,
            pbh_condition: prevState.leaseRental.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? {...range,[key]: value, remarks: condition[value], min: 0, max: 0} : range)
          },
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          leaseRental: {
            ...prevState.leaseRental,
            pbh_condition: prevState.leaseRental.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? {...range,[key]: value, remarks: condition[value], min: 0, max: 0} : range)
          },
        }))
      }
      setTimeout(() => {
        if(this.state.leaseRental.rental_type === 4){
          if(this.state.leaseRental.pbh_condition.filter(item => item.ratio_range === 1).length){
            this.setState({
              showLess: false
            })
          }
          if(this.state.leaseRental.pbh_condition.filter(item => item.ratio_range === 6).length){
            this.setState({
              showGreat: false
            })
          }
        }
      })
    }else{
      this.setState(prevState => ({
        ...prevState,
        leaseRental: {
          ...prevState.leaseRental,
          pbh_condition: prevState.leaseRental.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? {...range,[key]: value} : range)
        },
      }))
    }
  }

  removeRatio = (index) => {
    this.setState(prevState => ({
      ...prevState,
      leaseRental: {
        ...prevState.leaseRental,
        pbh_condition: prevState.leaseRental.pbh_condition.filter((range, rangeIndex) => index !== rangeIndex)
      },
    }))
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      leaseRental: {
        ...prevState.leaseRental,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }), () => {
      let flag = false;
      ['bank_code','bank_iban', 'bank_name', 'bank_note', 'bank_reference', 'bank_sort_code', 'bank_account_name', 'bank_account_number', 'bank_swift_code'].map(key => {
        if(this.state.leaseRental[key] !== '' && this.state.leaseRental[key] != null){
          flag = true
        }
      })
      this.setState({
        requireBanking: flag
      })
    })

    if(type=='end_date'){
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          [type]: checkDateDiff({value:this.state.leaseRental.start_date,required:true},{value:value,required:true})
        }
      }))
    }
    if( type === 'unit_of_measure'){
      this.setState(prevState => ({
        ...prevState,
        leaseRental: {
          ...prevState.leaseRental,
          pbh_condition: []
        },
        error: {
          ...prevState.error,
          pbh_condition: []
        }
      }))
    }

  }

  updateEditedForm = (type, value) => {

    this.setState(prevState => ({
      ...prevState,
      leaseRental: {
        ...prevState.leaseRental,
        requested_data:{
          ...prevState.leaseRental.requested_data,
          [type]: value
        }
      }
    }))
  }


  checkmap = () => {
    let vaildArray=[];
   this.state.sheetInfo.map((item, index) => {
    let obj={};
    if(decimalRegex.test(item.period)){
      obj.period = true;
    }else{
     obj.period = true;
    }
    if(checkValidDate({value: item.payment_date, required: true, minLength: '', maxLength: '', message: 'Please Select The Start Date'})==''){
     obj.payment_date = true;
   }else{
    obj.payment_date = false;
   }
   if(decimalRegex.test(item.reference_value)){
    obj.reference_value = true;
   }else{
    obj.reference_value = false;
   }
   if(decimalRegex.test(item.rent)){
    obj.rent = true;
    }else{
    obj.rent = false;
    }
   vaildArray.push(obj)
 })
return vaildArray;
}


checkmapDates = (position) => {
  let vaildArray=[];
 this.state.sheetInfo.map((item, index) => {
  let obj={};
  if(decimalRegex.test(item.period)){
    obj.period = true;
  }else{
   obj.period = true;
  }
  if(position==index){
    obj.payment_date = false;
  }else{
   if(checkValidDate({value: item.payment_date, required: true, minLength: '', maxLength: '', message: 'Please Select The Start Date'})==''){
   obj.payment_date = true;
   }else{
   obj.payment_date = false;
  }
  }
 if(decimalRegex.test(item.reference_value)){
  obj.reference_value = true;
 }else{
  obj.reference_value = false;
 }
 if(decimalRegex.test(item.rent)){
  obj.rent = true;
  }else{
  obj.rent = false;
  }

 vaildArray.push(obj)
})

this.setState({
  validationArray: vaildArray
});
}


  updateSheetTable = (type, value, index) => {
    if(type=='payment_date'){
      if(checkValidDate({value: value, required: true, minLength: '', maxLength: '', message: 'Please Enter The End Date'})==''){
        if(index>0 && index!=(this.state.sheetInfo.length-1)){
          let  days1 = moment(value).diff(moment(this.state.sheetInfo[index-1].payment_date),'days');
          let  days2 = moment(this.state.sheetInfo[index+1].payment_date).diff(moment(value),'days');
            if(days1>0){
              this.updateSheetPeriod('period',days1,index);
            }else{
              this.updateSheetPeriod('period',0,index);
              this.checkmapDates(index);
            }
            if(days2>0){
              this.updateSheetPeriod('period',days2,index+1);
            }else{
              this.updateSheetPeriod('period',0,index+1);
              this.checkmapDates(index);
            }
        }
        if(index==0 && this.state.sheetInfo.length>1){
          let  days2 = moment(this.state.sheetInfo[index+1].payment_date).diff(moment(value),'days');
            if(days2>0){
              this.updateSheetPeriod('period',days2,index+1);
            }else{
              this.updateSheetPeriod('period',0,index+1);
              this.checkmapDates(index);
            }
        }
        if(index==(this.state.sheetInfo.length-1) && this.state.sheetInfo.length>1){
          let  days1 = moment(value).diff(moment(this.state.sheetInfo[index-1].payment_date),'days');
          if(days1>0){
            this.updateSheetPeriod('period',days1,index);
          }else{
            this.updateSheetPeriod('period',0,index);
            this.checkmapDates(index);
          }
        }
      }
    }
    this.setState(prevState => ({
      ...prevState,
      sheetInfo: prevState.sheetInfo.map((info,infoIndex)=> infoIndex == index ? { ...info, [type]: value } : info),

    }))
  }

  updateSheetPeriod=(type,value,index)=>{

    this.setState(prevState => ({
      ...prevState,
      sheetInfo: prevState.sheetInfo.map((info,infoIndex)=> infoIndex == index ? { ...info, [type]: value } : info),

    }))

  }

  updateRentalInvoiceAccept = (e, flag) => {
    e.preventDefault();
    let validArray=[];
    const leaseRental = this.state.leaseRental;
    var validateNewInput = {
      rental_type: checkString({value: leaseRental.rental_type, required: true, minLength: '', maxLength: '', message: 'Please Select The Rental Type'}),
      margin: checkString({value: leaseRental.margin, required:leaseRental.rental_type == 2? leaseRental.is_additional_rate? true :false :false, minLength: '', maxLength: '', message: 'Please Select The Dollar Margin Rate'}),
      interval: checkString({value: leaseRental.interval, required:leaseRental.rental_type == 2 || leaseRental.rental_type === 3 || leaseRental.rental_type === 4? false : true, minLength: '', maxLength: '', message: 'Please Select The Interval'}),
      amount: checkString({value: leaseRental.amount, required:leaseRental.rental_type == 2 || leaseRental.rental_type == 4 || leaseRental.rental_type == 3? false : true, minLength: '', maxLength: '', message: 'Please Enter The Amount'}),
      start_date: checkValidDate({value: leaseRental.start_date, required: true, minLength: '', maxLength: '', message: 'Please Select The Start Date'}),
      end_date: checkValidDate({value: leaseRental.end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter The End Date'}),
      end_date:checkDateDiff({value:leaseRental.start_date,required:true},{value:leaseRental.end_date,required:true}),
    };
    // if(this.state.requireBanking){
    //   validateNewInput = {
    //     ...validateNewInput,
    //     bank_account_number: checkString({value: leaseRental.bank_account_number, required: true, minLength: '', maxLength: '', message: 'Please Enter The Bank Account Number'}),
    //     bank_account_name: checkString({value: leaseRental.bank_account_name, required: true, minLength: '', maxLength: '', message: 'Please Enter The Bank Account Name'}),
    //
    //     bank_name_edit: checkString({value: leaseRental.requested_data.bank_name, required:leaseRental.requested_data&&leaseRental.requested_data.hasOwnProperty('bank_name')?true:false, minLength: '', maxLength: '', message: 'Please Enter The Bank Name'}),
    //     bank_account_number_edit: checkString({value: leaseRental.requested_data.bank_account_number, required: leaseRental.requested_data&&leaseRental.requested_data.hasOwnProperty('bank_account_number')?true:false, minLength: '', maxLength: '', message: 'Please Enter The Bank Account Number'}),
    //     bank_account_name_edit: checkString({value: leaseRental.requested_data.bank_account_name, required: leaseRental.requested_data&&leaseRental.requested_data.hasOwnProperty('bank_account_name')?true:false, minLength: '', maxLength: '', message: 'Please Enter The Bank Account Name'}),
    //   }
    // }
    if(leaseRental.rental_type === 3 || leaseRental.rental_type === 4){
      validateNewInput = {
        ...validateNewInput,
        unit_of_measure: typeof leaseRental.unit_of_measure !== 'number' ? "Please select unit of measure" :'',
        pbh_condition: leaseRental.pbh_condition.length > 0 ? '' : 'Please add atleast one condition'
      }
    }

    if(leaseRental.rental_type == 2 ){
      if (this.state.sheetInfo && this.state.sheetInfo.length > 0 ){
      validArray= this.checkmap();
      }else{
        toastFlashMessage('Please attach the payment sheet','error');
        return;
      }
    }
    if(leaseRental.rental_type !== 3 && leaseRental.rental_type !== 4){
      delete leaseRental.pbh_condition
      delete leaseRental.unit_of_measure
    }else{
      let pbhs = []
      let unitMeasure = {
        1: '_fh',
        2: '_fc',
        3: '_bh'
      }
      leaseRental.pbh_condition.map(rental => {
        pbhs.push({
          ['min' + unitMeasure[leaseRental.unit_of_measure]]: rental.min,
          ['max' + unitMeasure[leaseRental.unit_of_measure]]: rental.max,
          rental_rate_type: rental.rental_rate_type,
          rate: rental.rate
        })
      })
    }



    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {

      if(leaseRental.rental_type == 2 ){
      if((validArray.filter(item => !(item.period && item.payment_date && item.reference_value && item.rent))).length==0){
       this.updateRentalCustomLease(true,flag);
      }else{
        this.setState({
          validationArray: validArray
        });
      }
     }else{
      let data = Object.assign({}, this.state.leaseRental);
      data = {
        ...data,
        change_request:flag?1:2
      }
      if(!flag){
        this.props.toggleReason(data,true,this.props.type);
      }else{
      this.props.addEditLeaseRental(data, this.props.type);
      }
    }
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }




  editPayment = () => {
    this.setState({
      payToggleState:!this.state.payToggleState
    });
  }

  updateRentalCustomLease = (check=false,flag) => {

    let data = Object.assign({}, this.state.leaseRental);
    data = {
      ...data,
      path:this.props.path,
      rental_payment_schedule:this.state.sheetInfo
    }
    if(check){
      data = {
        ...data,
        change_request:flag?1:2
      }
      if(flag==1){
        this.props.addEditLeaseRental(data, this.props.type);
      }else{
        this.props.toggleReason(data,true,this.props.type);
      }

    }else{
      this.props.addEditLeaseRental(data, this.props.type);
    }


  }

  updatePdf=(sheet)=>{
    if(sheet.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      this.setState({
        updateSheetFlag:true,
        fileSheet:sheet
      });
      this.uploadPdf(sheet);
   }else{
    toastFlashMessage('Please add proper sheet format file', 'error');
   }
  }

  uploadPdf=(sheet)=>{
    var formData = new FormData();
    formData.append("file", sheet);
    formData.append("currency", this.state.leaseRental.currency);
    this.props.updateSheet(formData);
  }

  updateRental = (e) => {
    e.preventDefault();
    let validArray=[];
    var leaseRental = this.state.leaseRental;
    let validateNewInput = {
      rental_type: checkString({value: leaseRental.rental_type, required: true, minLength: '', maxLength: '', message: 'Please Select The Rental Type'}),
      margin: checkString({value: leaseRental.margin, required:leaseRental.rental_type == 2? leaseRental.is_additional_rate? true :false :false, minLength: '', maxLength: '', message: 'Please Select The Dollar Margin Rate'}),
      interval: checkString({value: leaseRental.interval, required:leaseRental.rental_type == 2|| leaseRental.rental_type === 3 || leaseRental.rental_type === 4? false : true, minLength: '', maxLength: '', message: 'Please Select The Interval'}),
      amount: checkString({value: leaseRental.amount, required:leaseRental.rental_type == 2 || leaseRental.rental_type == 4 || leaseRental.rental_type == 3? false : true, minLength: '', maxLength: '', message: 'Please Enter The Amount'}),
      start_date: checkValidDate({value: leaseRental.start_date, required: true, minLength: '', maxLength: '', message: 'Please Select The Start Date'}),
      end_date: checkValidDate({value: leaseRental.end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter The End Date'}),
    };
    // if(this.state.requireBanking){
    //   validateNewInput = {
    //     ...validateNewInput,
    //     bank_account_number: checkString({value: leaseRental.bank_account_number, required: true, minLength: '', maxLength: '', message: 'Please Enter The Bank Account Number'}),
    //     bank_account_name: checkString({value: leaseRental.bank_account_name, required: true, minLength: '', maxLength: '', message: 'Please Enter The Bank Account Name'}),
    //     bank_name: checkString({value: leaseRental.bank_name, required: true, minLength: '', maxLength: '', message: 'Please Enter The Bank Name'}),
    //   }
    // }
    if(leaseRental.pbh_condition && leaseRental.pbh_condition.length){
      if(this.state.error.rate){
        if( this.state.error.rate != '' || this.state.error.rate != undefined || this.state.error.rate != null ){
          validateNewInput = {
            ...validateNewInput,
            rate:'Please enter Rate greater than 0'
          }
        }
      }

    }
    if(leaseRental.rental_type === 3 || leaseRental.rental_type === 4){
      validateNewInput = {
        ...validateNewInput,
        unit_of_measure: typeof leaseRental.unit_of_measure !== 'number' ? "Please select unit of measure" :'',
        pbh_condition: leaseRental.pbh_condition.length > 0 ? '' : 'Please add atleast one condition'
      }
    }
    if(leaseRental.rental_type !== 3 && leaseRental.rental_type !== 4){
      delete leaseRental.pbh_condition
      delete leaseRental.unit_of_measure
    }else{
      let pbhs = []
      let unitMeasure = {
        1: '_fh',
        2: '_fc',
        3: '_bh'
      }
      leaseRental.pbh_condition.map(rental => {
        pbhs.push({
          type: rental.type,
          min_fh: rental.min,
          max_fh: rental.max,
          rental_rate_type: rental.rental_rate_type,
          rate: rental.rate
        })
      })
    }
    // if(leaseRental.rental_type == 3){
    // let validIntervalArray=this.checkChildIntervalMap();
    // }
    if(leaseRental.rental_type == 2 ){
      if (this.state.sheetInfo && this.state.sheetInfo.length > 0 ){
      validArray= this.checkmap();
      }else{
        toastFlashMessage('Please attach the payment sheet','error');
        return;
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {

      if(leaseRental.rental_type == 2){
      if((validArray.filter(item => !(item.period && item.payment_date && item.reference_value && item.rent))).length==0){
       this.updateRentalCustomLease();
      }else{
        this.setState({
          validationArray: validArray
        });
      }
     }else{
      this.props.addEditLeaseRental(this.state.leaseRental, this.props.type);
    }
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }


  checkChildIntervalMap = () => {
    let vaildArray=[];
    this.state.leaseRental.pbh_condition.map((interval, index) => {
    let obj={};
    if(interval.remarks.includes('min')){
      if((checkNumber({value: interval.min, required: true, minLength: '', maxLength: '', message: 'Please Enter Min ratio'}))==''){
        obj.min = true;
      }else{
       obj.min = false;
      }
    }else{
      obj.min = true
    }
    if((checkString({value: interval.ratio_range, required: true, minLength: '', maxLength: '', message: 'Please Enter Ratio Range'}))==''){
     obj.ratio_range = true;
    }else{
      obj.ratio_range = false;
    }
    if(interval.remarks.includes('max')){
      if((checkNumber({value: interval.max, required: true, minLength: '', maxLength: '', message: 'Please Enter Max Ratio'}))==''){
        obj.max = true;
      }else{
        obj.max = false;
      }
    }else{
      obj.max = true;
    }
    if((checkNumber({value: interval.min, required: true, minLength: '', maxLength: '', message: 'Please Enter Min ratio'}))=='' &&
       (checkNumber({value: interval.max, required: true, minLength: '', maxLength: '', message: 'Please Enter Max Ratio'}))=='' &&
       (checkString({value: interval.ratio_range, required: true, minLength: '', maxLength: '', message: 'Please Enter Ratio Range'}))==''){
      if(this.checkValidationRatio(interval.min,interval.max,interval.ratio_range)){
        obj.ratio_range_validation = true;
      }else{
        obj.ratio_range_validation = false;
      }
    }else{
      obj.ratio_range_validation =true;
    }
      vaildArray.push(obj)
    })
      return vaildArray;
    }

  render() {
    const { leaseRental, error,contracterFlag,adminFlag, mrRate, errorRate } = this.state;
    const { type } = this.props;
    return (
      <form onSubmit= {(e) => this.updateRental(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
        <Row>
          <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}>Rental Currency</FieldLabel>
                <FieldSelect
                  placeholder='Select Rental Currency'
                  keyParam="currency"
                  value={leaseRental.currency}
                  type={type}
                  options={currencyList}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = { Object.keys(leaseRental).length && type != 'add' ? this.props.rentalView.currency.label : ''}
                />
                <h6 className="error-msg">{error.rental_currency}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}>Rental Type</FieldLabel>
                <FieldSelect
                  placeholder='Select Rental Type'
                  keyParam="rental_type"
                  value={leaseRental.rental_type}
                  type={type}
                  options={rentalTypeDropdown}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = { Object.keys(leaseRental).length && type != 'add' ? this.props.rentalView.rental_type.label : ''}
                />
                <h6 className="error-msg">{error.rental_type}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}>Rental Start Date</FieldLabel>
                <FieldDate
                  value={leaseRental.start_date}
                  type={type}
                  keyParam="start_date"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.start_date}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}>Rental End Date</FieldLabel>
                <FieldDate
                  value={leaseRental.end_date}
                  type={type}
                  keyParam="end_date"
                  updateField={this.updateForm}
                  minDate={leaseRental.start_date ? moment(leaseRental.start_date) : moment()}
                />
                <h6 className="error-msg">{error.end_date}</h6>

              </FieldGroup>
            </FieldCol>
            </Row>
            {
              leaseRental.rental_type === 3 ?
              <Row>
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Escalation Interval</FieldLabel>
                  <FieldSelect
                    placeholder='Select Escalation Interval'
                    keyParam="escalation_interval"
                    value={leaseRental.escalation_interval}
                    type={type}
                    options={escalationIntervalDropdwn}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                    name = { Object.keys(leaseRental).length && type != 'add' ? this.props.rentalView.escalation_interval.label : ''}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Escalation (%)</FieldLabel>
                  <FieldInput
                    value={leaseRental.escalation_percentage}
                    type={type}
                    keyParam="escalation_percentage"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Escalation Start Date</FieldLabel>
                  <FieldDate
                    value={leaseRental.escalation_start_date}
                    type={type}
                    keyParam="escalation_start_date"
                    updateField={this.updateForm}
                    minDate={leaseRental.start_date ? moment(leaseRental.start_date) : moment()}
                    maxDate={leaseRental.end_date ? moment(leaseRental.end_date) : moment()}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6">
              </FieldCol>
              </Row>
              :null
            }
            <Row>
              {
                leaseRental.rental_type === 3 || leaseRental.rental_type === 4 ?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>Unit Of Measure</FieldLabel>
                      {
                        type != 'view' ?
                          <Select
                            name="form-field-name"
                            placeholder="Select Unit Of Measure"
                            className="custom-select-block"
                            value={leaseRental.unit_of_measure}
                            options={[
                              {
                                id: 1,
                                label: 'Flight Hour'
                              },
                              {
                                id: 2,
                                label: 'Flight Cycle'
                              },
                              {
                                id: 4,
                                label: 'Block Hour'
                              },
                              {
                                id: 3,
                                label: 'Whichever is Greater'
                              },
                            ]}
                            labelKey="label"
                            valueKey="id"
                            type={type}
                            onChange={(target) => this.updateForm('unit_of_measure',target && target.id ? target.id : '')}
                          /> :
                          <h6>{leaseRental.unit_of_measure ? {1: 'Flight Hour', 2: 'Flight Cycle' , 3: 'Whichever is Greater', 4: 'Block Hour'}[leaseRental.unit_of_measure] : '--'}</h6>
                      }
                      <h6 className="error-msg">{error.unit_of_measure}</h6>
                  </FieldGroup>
                </FieldCol>
                :null
              }
              {
                leaseRental.rental_type === 3 ?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>Range Condition To Be Considered</FieldLabel>
                    <FieldLabel className="label" style={{display: 'block'}}>
                      <span onClick={() => type !== 'view' ? this.updateForm('range_calculation_type', false) : null} style={{cursor: 'pointer'}}><input disabled={type === 'view' ? true : false} style={{marginRight: '5px'}} checked={leaseRental.range_calculation_type === false} type="radio"/>Either Off</span>
                      <span onClick={() => type !== 'view' ? this.updateForm('range_calculation_type', true) : null} style={{marginLeft: '20px', cursor: 'pointer'}}><input disabled={type === 'view' ? true : false} style={{marginRight: '5px'}} checked={leaseRental.range_calculation_type === true} type="radio"/>All</span>
                    </FieldLabel>
                  </FieldGroup>
                </FieldCol>
                :null
              }
              {
                leaseRental.rental_type === 3 ?
                  leaseRental.unit_of_measure === 1 || leaseRental.unit_of_measure === 3 ?
                  <Col md="12"  >
                    <h3 style={{borderBottom: 'none', marginBottom: '0px'}}>Flight Hour <img src={imgStoragePath + 'inv_info_icon.png'} id='flight_hour_pbh' onClick={() => this.setState({flight_hour_pbh: !this.state.flight_hour_pbh})} style={{width: '15px', position: 'reset'}}/></h3>
                    {
                      this.type !== 'view' ?
                      <Popover placement ="top" isOpen={this.state.flight_hour_pbh} target="flight_hour_pbh" toggle={() => this.setState({flight_hour_pbh: !this.state.flight_hour_pbh})}>
                        <PopoverBody>
                          <h6>Example of Range Condition in continuity:</h6>
                          <table style={{ width: '100%' }}>
                            <tr style={{ border: '1px solid black' }}>
                              <th style={{padding: '5px'}}>Range Condition</th>
                              <th>Min</th>
                              <th>Max</th>
                            </tr>
                            <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                              <td style={{padding: '5px'}}>Less Than Equal To</td>
                                <td>
                                   0
                                </td>
                                <td>
                                   50
                                </td>
                            </tr>
                            <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                              <td style={{padding: '5px'}}>In Between Equal To</td>
                              <td>50.01</td>
                              <td>100</td>
                            </tr>
                            <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                              <td style={{padding: '5px'}}>Greater Than Equal To</td>
                              <td>100.01</td>
                              <td>0</td>
                            </tr>
                          </table>
                        </PopoverBody>
                      </Popover>
                      :null
                    }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            leaseRental.pbh_condition.map((rCondition, rIndex) =>
                            rCondition.type === 1 ?
                              <tr>
                                <td>
                                  {
                                    <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                      <input type="number" value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?   this.updateRangeConditon('min', evt.target.value,rIndex) : evt.preventDefault()}/>
                                    :<h6>{rCondition.min}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                      <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('max', evt.target.value,rIndex):evt.preventDefault()}/>
                                    :<h6>{rCondition.max}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                    <Select
                                      name="form-field-name"
                                      placeholder="Select Rate Type"
                                      className="custom-select-block"
                                      value={rCondition.rental_rate_type}
                                      options={[
                                        {
                                          id: 1,
                                          label: 'Fixed'
                                        },
                                        {
                                          id: 2,
                                          label: 'Per Unit'
                                        },
                                      ]}
                                      labelKey="label"
                                      valueKey="id"
                                      type={type}
                                      onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                    />
                                  :<h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                      <Fragment>
                                        <input
                                          value={rCondition.rate}
                                          onChange={(evt) => numberWithDot.test(evt.target.value) ?  this.updateRangeConditon('rate', evt.target.value,rIndex) : evt.preventDefault()}
                                          type="text"
                                          onBlur={() => parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? this.setState({error: {...error, rate:"Please enter Rate greater than 0"}}) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({error: {...error, rate:""}}) : null}
                                        />
                                      { parseFloat(rCondition.rate) <= 0 || rCondition.rate == ""  ? <h6 className="error-msg">{error.rate}</h6> : null }

                                      </Fragment>
                                    :<h6>{rCondition.rate}</h6>
                                  }
                                </td>
                                {
                                  type != 'view' ? <td onClick={() => this.removeRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                                }
                              </tr>:null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 1 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'max')}>+ Add Min Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 1 && item.remarks === 'min').length === 0 ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'min')}>+ Add Max Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view' ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'range')}>+ Add Range Rental Rate</span>
                        :null
                      }
                    </div>
                    <h6 className="error-msg">{error.pbh_condition}</h6>
                  </Col>
                  :null
                :null
              }
              {
                leaseRental.rental_type === 3 ?
                  leaseRental.unit_of_measure === 2 || leaseRental.unit_of_measure === 3 ?
                  <Col md="12"  >
                    <h3 style={{borderBottom: 'none', marginBottom: '0px'}}>Flight Cycles <img src={imgStoragePath + 'inv_info_icon.png'} id='flight_cycle_pbh' onClick={() => this.setState({flight_cycle_pbh: !this.state.flight_cycle_pbh})} style={{width: '15px', position: 'reset'}}/></h3>
                      {
                        type !== 'view' ?
                        <Popover placement ="top" isOpen={this.state.flight_cycle_pbh} target="flight_cycle_pbh" toggle={() => this.setState({flight_cycle_pbh: !this.state.flight_cycle_pbh})}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
<table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{padding: '5px'}}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Less Than Equal To</td>
                                  <td>
                                     0
                                  </td>
                                  <td>
                                     50
                                  </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        :null
                      }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            leaseRental.pbh_condition.map((rCondition, rIndex) =>
                            rCondition.type === 2 ?
                            <tr>
                              <td>
                                {
                                <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('min', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.min}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('max', evt.target.value,rIndex): evt.preventDefault()}/>
                                  :<h6>{rCondition.max}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Select
                                    name="form-field-name"
                                    placeholder="Select Rate Type"
                                    className="custom-select-block"
                                    value={rCondition.rental_rate_type}
                                    options={[
                                      {
                                        id: 1,
                                        label: 'Fixed'
                                      },
                                      {
                                        id: 2,
                                        label: 'Per Unit'
                                      },
                                    ]}
                                    labelKey="label"
                                    valueKey="id"
                                    type={type}
                                    onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                  />
                                :<h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <Fragment>
                                      <input
                                        value={rCondition.rate}
                                        onChange={(evt) => numberWithDot.test(evt.target.value)?  this.updateRangeConditon('rate', evt.target.value,rIndex): evt.preventDefault()}
                                        type="text"
                                        onBlur={() => rCondition.rate <= 0 || rCondition.rate == "" ? this.setState({error: {...error, rate:"Please enter Rate greater than 0"}}) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({error: {...error, rate:""}}) : null}
                                      />
                                    { parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? <h6 className="error-msg">{error.rate}</h6> : null }
                                    </Fragment>
                                  :<h6>{rCondition.rate}</h6>
                                }
                              </td>
                              {
                                type != 'view' ? <td onClick={() => this.removeRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                              }
                            </tr>:null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 2 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'max')}>+ Add Min Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 2 && item.remarks === 'min').length === 0 ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'min')}>+ Add Max Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view' ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'range')}>+ Add Range Rental Rate</span>
                        :null
                      }
                    </div>
                    <h6 className="error-msg">{error.pbh_condition}</h6>
                  </Col>
                  :null
                :null
              }
              {
                leaseRental.rental_type === 3 ?
                  leaseRental.unit_of_measure === 4 ?
                  <Col md="12"  >
                    <h3 style={{borderBottom: 'none', marginBottom: '0px'}}>Block Hours <img src={imgStoragePath + 'inv_info_icon.png'} id='block_hour_pbh' onClick={() => this.setState({block_hour_pbh: !this.state.block_hour_pbh})} style={{width: '15px', position: 'reset'}}/></h3>
                      {
                        type !== 'view' ?
                        <Popover placement ="top" isOpen={this.state.block_hour_pbh} target="block_hour_pbh" toggle={() => this.setState({block_hour_pbh: !this.state.block_hour_pbh})}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
<table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{padding: '5px'}}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Less Than Equal To</td>
                                  <td>
                                     0
                                  </td>
                                  <td>
                                     50
                                  </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        :null
                      }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            leaseRental.pbh_condition.map((rCondition, rIndex) =>
                            rCondition.type === 4 ?
                            <tr>
                              <td>
                                {
                                  <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('min', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.min}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ? this.updateRangeConditon('max', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.max}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Select
                                    name="form-field-name"
                                    placeholder="Select Rate Type"
                                    className="custom-select-block"
                                    value={rCondition.rental_rate_type}
                                    options={[
                                      {
                                        id: 1,
                                        label: 'Fixed'
                                      },
                                      {
                                        id: 2,
                                        label: 'Per Unit'
                                      },
                                    ]}
                                    labelKey="label"
                                    valueKey="id"
                                    type={type}
                                    onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                  />
                                :<h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Fragment>

                                    <input
                                      value={rCondition.rate}
                                      onChange={(evt) => numberWithDot.test(evt.target.value) ?  this.updateRangeConditon('rate', evt.target.value,rIndex) : evt.preventDefault()}
                                      type="text"
                                      onBlur={() => rCondition.rate <= 0 || rCondition.rate == "" ? this.setState({error: {...error, rate:"Please enter Rate greater than 0"}}) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({error: {...error, rate:""}}) : null}
                                    />
                                  { parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ?  <h6 className="error-msg">{error.rate}</h6> : null }
                                  </Fragment>
                                  :<h6>{rCondition.rate}</h6>
                                }
                              </td>
                              {
                                type != 'view' ? <td onClick={() => this.removeRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                              }
                            </tr>:null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 4 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(4, 'max')}>+ Add Min Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 4 && item.remarks === 'min').length === 0 ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(4, 'min')}>+ Add Max Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view' ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(4, 'range')}>+ Add Range Rental Rate</span>
                        :null
                      }
                    </div>
                    <h6 className="error-msg">{error.pbh_condition}</h6>
                  </Col>
                  :null
                :null
              }
              {
                leaseRental.rental_type === 4 ?
                  leaseRental.unit_of_measure === 1 || leaseRental.unit_of_measure === 3 ?
                  <Col md="12"  >
                    <h3 style={{borderBottom: 'none', marginBottom: '0px'}}>Flight Hour <img src={imgStoragePath + 'inv_info_icon.png'} id='flight_hour_flexi' onClick={() => this.setState({flight_hour_flexi: !this.state.flight_hour_flexi})} style={{width: '15px', position: 'reset'}}/></h3>
                      {
                        type !== 'view' ?
                        <Popover placement ="top" isOpen={this.state.flight_hour_flexi} target="flight_hour_flexi" toggle={() => this.setState({flight_hour_flexi: !this.state.flight_hour_flexi})}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
<table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{padding: '5px'}}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Less Than Equal To</td>
                                  <td>
                                     0
                                  </td>
                                  <td>
                                     50
                                  </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        :null
                      }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            leaseRental.pbh_condition.map((rCondition, rIndex) =>
                            rCondition.type === 1 ?
                              <tr>
                                <td>
                                  {
                                  <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                      <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('min', evt.target.value,rIndex) : evt.preventDefault()}/>
                                    :<h6>{rCondition.min}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                      <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('max', evt.target.value,rIndex) : evt.preventDefault()}/>
                                    :<h6>{rCondition.max}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                    <Select
                                      name="form-field-name"
                                      placeholder="Select Rate Type"
                                      className="custom-select-block"
                                      value={rCondition.rental_rate_type}
                                      disabled={rCondition.ratio_range !== '4'}
                                      options={[
                                        {
                                          id: 1,
                                          label: 'Fixed'
                                        },
                                        {
                                          id: 2,
                                          label: 'Per Unit'
                                        },
                                      ]}
                                      labelKey="label"
                                      valueKey="id"
                                      type={type}
                                      onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                    />
                                  :<h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                  }
                                </td>
                                <td>
                                  {
                                    type != 'view' ?
                                    <Fragment>

                                      <input
                                        value={rCondition.rate}
                                        onChange={(evt) => numberWithDot.test(evt.target.value)?  this.updateRangeConditon('rate', evt.target.value,rIndex) : evt.preventDefault()}
                                        type="text"
                                        onBlur={() => parseInt(rCondition.rate) <= 0 || rCondition.rate == "" ? this.setState({error: {...error, rate:"Please enter Rate greater than 0"}}) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({error: {...error, rate:""}}) : null}
                                      />
                                    { parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? <h6 className="error-msg">{error.rate}</h6> : null }
                                    </Fragment>
                                    :<h6>{rCondition.rate}</h6>
                                  }
                                </td>
                                {
                                  type != 'view' ? <td onClick={() => this.removeRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                                }
                              </tr>:null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 1 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'max')}>+ Add Min Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 1 && item.remarks === 'min').length === 0 ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'min')}>+ Add Max Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view' ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'range')}>+ Add Range Rental Rate</span>
                        :null
                      }
                    </div>
                    <h6 className="error-msg">{error.pbh_condition}</h6>
                  </Col>
                  :null
                :null
              }
              {
                leaseRental.rental_type === 4 ?
                  leaseRental.unit_of_measure === 2 || leaseRental.unit_of_measure === 3 ?
                  <Col md="12"  >
                    <h3 style={{borderBottom: 'none', marginBottom: '0px'}}>Flight Cycles <img src={imgStoragePath + 'inv_info_icon.png'} id='flight_cycle_flexi' onClick={() => this.setState({flight_cycle_flexi: !this.state.flight_cycle_flexi})} style={{width: '15px', position: 'reset'}}/></h3>
                      {
                        type !== 'view' ?
                        <Popover placement ="top" isOpen={this.state.flight_cycle_flexi} target="flight_cycle_flexi" toggle={() => this.setState({flight_cycle_flexi: !this.state.flight_cycle_flexi})}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
<table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{padding: '5px'}}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Less Than Equal To</td>
                                  <td>
                                     0
                                  </td>
                                  <td>
                                     50
                                  </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        :null
                      }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            leaseRental.pbh_condition.map((rCondition, rIndex) =>
                            rCondition.type === 2 ?
                            <tr>
                              <td>
                                {
                                  <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('min', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.min}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('max', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.max}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Select
                                    name="form-field-name"
                                    placeholder="Select Rate Type"
                                    className="custom-select-block"
                                    value={rCondition.rental_rate_type}
                                    disabled={rCondition.ratio_range !== '4'}
                                    options={[
                                      {
                                        id: 1,
                                        label: 'Fixed'
                                      },
                                      {
                                        id: 2,
                                        label: 'Per Unit'
                                      },
                                    ]}
                                    labelKey="label"
                                    valueKey="id"
                                    type={type}
                                    onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                  />
                                :<h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Fragment>

                                    <input
                                      value={rCondition.rate}
                                      onChange={(evt) => numberWithDot.test(evt.target.value) ?  this.updateRangeConditon('rate', evt.target.value,rIndex) : evt.preventDefault()}
                                      type="text"
                                      onBlur={() => parseInt(rCondition.rate) <= 0 || rCondition.rate == "" ? this.setState({error: {...error, rate:"Please enter Rate greater than 0"}}) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({error: {...error, rate:""}}) : null}
                                    />
                                  { parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? <h6 className="error-msg">{error.rate}</h6> : null }
                                  </Fragment>
                                  :<h6>{rCondition.rate}</h6>
                                }
                              </td>
                              {
                                type != 'view' ? <td onClick={() => this.removeRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                              }
                            </tr>:null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 2 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'max')}>+ Add Min Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 2 && item.remarks === 'min').length === 0 ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'min')}>+ Add Max Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view' ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'range')}>+ Add Range Rental Rate</span>
                        :null
                      }
                    </div>
                    <h6 className="error-msg">{error.pbh_condition}</h6>
                  </Col>
                  :null
                :null
              }
              {
                leaseRental.rental_type === 4 ?
                  leaseRental.unit_of_measure === 4 ?
                  <Col md="12"  >
                    <h3 style={{borderBottom: 'none', marginBottom: '0px'}}>Block Hours <img src={imgStoragePath + 'inv_info_icon.png'} id='block_hour_flexi' onClick={() => this.setState({block_hour_flexi: !this.state.block_hour_flexi})} style={{width: '15px', position: 'reset'}}/></h3>
                      {
                        type !== 'view' ?
                        <Popover placement ="top" isOpen={this.state.block_hour_flexi} target="block_hour_flexi" toggle={() => this.setState({block_hour_flexi: !this.state.block_hour_flexi})}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
<table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{padding: '5px'}}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Less Than Equal To</td>
                                  <td>
                                     0
                                  </td>
                                  <td>
                                     50
                                  </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{padding: '5px'}}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        :null
                      }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            leaseRental.pbh_condition.map((rCondition, rIndex) =>
                            rCondition.type === 4 ?
                            <tr>
                              <td>
                                {
                                  <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('min', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.min}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                    <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ?  this.updateRangeConditon('max', evt.target.value,rIndex) : evt.preventDefault()}/>
                                  :<h6>{rCondition.max}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Select
                                    name="form-field-name"
                                    placeholder="Select Rate Type"
                                    className="custom-select-block"
                                    value={rCondition.rental_rate_type}
                                    options={[
                                      {
                                        id: 1,
                                        label: 'Fixed'
                                      },
                                      {
                                        id: 2,
                                        label: 'Per Unit'
                                      },
                                    ]}
                                    labelKey="label"
                                    valueKey="id"
                                    disabled={rCondition.ratio_range !== '4'}
                                    type={type}
                                    onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                  />
                                :<h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                }
                              </td>
                              <td>
                                {
                                  type != 'view' ?
                                  <Fragment>

                                    <input
                                      value={rCondition.rate}
                                      onChange={(evt) => numberWithDot.test(evt.target.value) ?  this.updateRangeConditon('rate', evt.target.value,rIndex) : evt.preventDefault()}
                                      type="text"
                                      onBlur={() => parseInt(rCondition.rate) <= 0 || rCondition.rate == "" ? this.setState({error: {...error, rate:"Please enter Rate greater than 0"}}) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({error: {...error, rate:""}}) : null}
                                    />
                                  { parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? <h6 className="error-msg">{error.rate}</h6> : null }
                                  </Fragment>
                                  :<h6>{rCondition.rate}</h6>
                                }
                              </td>
                              {
                                type != 'view' ? <td onClick={() => this.removeRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                              }
                            </tr>:null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 4 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(4, 'max')}>+ Add Min Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view'  && leaseRental.pbh_condition.filter(item => parseInt(item.type) === 4 && item.remarks === 'min').length === 0 ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(4, 'min')}>+ Add Max Rental Rate</span>
                        :null
                      }
                      {
                        type !== 'view' ?
                          <span style={{marginLeft: '20px'}} className="add-mr-int" onClick={() => this.addFlexiRatio(4, 'range')}>+ Add Range Rental Rate</span>
                        :null
                      }
                    </div>
                    <h6 className="error-msg">{error.pbh_condition}</h6>
                  </Col>
                  :null
                :null
              }
            </Row>
            {leaseRental.rental_type == 1 ?
            <Row>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}> Interval</FieldLabel>
                <FieldSelect
                  placeholder='Select  Interval'
                  keyParam="interval"
                  value={leaseRental.interval}
                  type={type}
                  options={leaseRental.rental_type !== 4 ? intervalDropdown.filter(item => item.value !== 4) : intervalDropdown}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = { Object.keys(leaseRental).length && type != 'add' ? this.props.rentalView.interval.label : ''}
                />
               <h6 className="error-msg">{error.interval  }</h6>

              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label" required={true}>Rate	({leaseRental.currency ? leaseRental.currency:'USD'})</FieldLabel>
                <FieldInput
                  value={leaseRental.amount}
                  type={type}
                  keyParam="amount"
                  updateField={this.updateForm}
                  currency={true}
                  currencyType={leaseRental.currency}
                />
                <h6 className="error-msg">{error.amount}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Escalation Interval</FieldLabel>
                <FieldSelect
                  placeholder='Select Escalation Interval'
                  keyParam="escalation_interval"
                  value={leaseRental.escalation_interval}
                  type={type}
                  options={escalationIntervalDropdwn}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = { Object.keys(leaseRental).length && type != 'add' ? this.props.rentalView.escalation_interval.label : ''}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Escalation (%)</FieldLabel>
                <FieldInput
                  value={leaseRental.escalation_percentage}
                  type={type}
                  keyParam="escalation_percentage"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Escalation Start Date</FieldLabel>
                <FieldDate
                  value={leaseRental.escalation_start_date}
                  type={type}
                  keyParam="escalation_start_date"
                  updateField={this.updateForm}
                  minDate={leaseRental.start_date ? moment(leaseRental.start_date) : moment()}
                  maxDate={leaseRental.end_date ? moment(leaseRental.end_date) : moment()}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
            </FieldCol>
            </Row>
            :  leaseRental.rental_type == 2 ?
            <Row>
            <FieldCol md="12">
            <FieldGroup className="form-group">
              <span  className="file_attachment" onClick={() => ''}>
              <img className="img_attachment"  src={imgStoragePath+"attachment.png"} alt="img"/>
               {type == 'view' ? 'Payment Schedule':type=='add'?'Attach Payment Schedule': 'Reattach Payment Schedule'} {type != 'view'?<input type="file" onChange={(e)=> this.updatePdf(e.target.files[0])}/>:null}
               { type !='add'?
                 <Link target="_blank" style={{fontSize:'12px'}} to={leaseRental.url}>  <img style={{width:'15px',marginRight:'1px',marginLeft:'15px'}} src={imgStoragePath+"view_icon_blue.png"} alt="img"/> Export</Link>
                : null
               }
              </span>
              <div>
             <span style={{fontSize:'10px',color:'#909192'}}> {leaseRental.currency=='USD'? 'Attachment should contain - Period, Payment Date, Basic Rent, Dollar Reference Value - ':'Attachment should contain - Period, Payment Date, Basic Rent - '}
             <Link target="_blank" to={'https://sparta-aims.ams3.digitaloceanspaces.com/others/static/contracts/payment_schedule_sample.xlsx'}>Sample rent payment schedule for upload</Link>
             </span>
             </div>
             {this.props.sheetLoader ? <p style={{fontSize:'14px',color:'#000000', marginLeft:'10px'}}>Loading ...</p>:null}
             { this.state.sheetInfo && this.state.sheetInfo.length > 0 ?
             <div>
               { type != 'view' ?
             <div>
             <Link className="edit-table-block" onClick = {() => this.editPayment()}>
            {this.state.payToggleState ?  <img style={{width:'12px',marginRight:'5px'}} src={imgStoragePath+"view_icon_blue.png"} alt="img"/> : <img   src={imgStoragePath+"icon_editblock.png"} alt="img"/> }
            {this.state.payToggleState ? 'View Payment Schedule':'Edit Payment Schedule'}</Link>
           </div>:null
               }
          <div className="contr-table-blk">
          <div className="table-container">
          <form>
          <div className="table-responsive table-height" style={{ height: '250px'}}>
          <table className="table-sticky common-table">
            {leaseRental.currency=='USD'?
  	          <CtrTableHd theads={['Period', 'Payment Date','Basic Rent ('+leaseRental.currency+')','Dollar Reference Value']}/> :
              <CtrTableHd theads={['Period', 'Payment Date','Euro Rent ('+leaseRental.currency+')']}/>
              }
              <tbody>
  	            {
                this.state.sheetInfo.map((payment, childIndex) =>
  	              <tr key={childIndex}>
  	                <td>
                       {/*this.state.payToggleState ? <input type = "text" value={payment.period} onChange = {(e) => this.updateSheetTable('period',e.target.value,childIndex)} /> : payment.period */}
                       {payment.period>0 ?payment.period:0}
                       {this.state.validationArray.length>0 ?
                          this.state.validationArray[childIndex].period == false ?
                          <h6 className="error-msg">Please enter vaild values</h6>:null:null}
                    </td>
  	                <td>
                      <FieldDateIndex
                       value={payment.payment_date}
                       type={this.state.payToggleState ? 'edit':'view'}
                       keyParam="payment_date"
                       updateField={this.updateSheetTable}
                       index={childIndex}
                       />
                         {/*<h6 className="error-msg">{error[childIndex] ? error[childIndex].tsn : null}</h6>*/}
                         {this.state.validationArray.length>0 ?
                          this.state.validationArray[childIndex].payment_date == false ?
                          <h6 className="error-msg">Please enter vaild values</h6>:null:null}
                     </td>
  	                <td>
                      {this.state.payToggleState ? <input type = "text" value={payment.rent} onChange = {(e) => this.updateSheetTable('rent',e.target.value,childIndex)} /> :
                      <NumericLabel params={showCurrencyFormat(leaseRental.currency)}>{payment.rent}</NumericLabel>  }

                          {/*<h6 className="error-msg">{ error[childIndex] ?  error[childIndex].csn : null}</h6>*/}
                          {this.state.validationArray.length>0 ?
                          this.state.validationArray[childIndex].rent == false ?
                          <h6 className="error-msg">Please enter vaild values</h6>:null:null}
                    </td>
                    {leaseRental.currency=='USD'?
                    <td>{this.state.payToggleState ? <input type = "text" value={payment.reference_value} onChange = {(e) => this.updateSheetTable('reference_value',e.target.value,childIndex)} /> :
                      <NumericLabel params={showCurrencyFormat(leaseRental.currency)}>{payment.reference_value}</NumericLabel>}
                       {this.state.validationArray.length>0 ?
                          this.state.validationArray[childIndex].reference_value == false ?
                          <h6 className="error-msg">Please enter vaild values</h6>:null:null}
                   </td>:null
                    }
  	              </tr>
  	              )
  	            }
  	          </tbody>
            </table>
            </div>
          </form>
          </div>
        </div>
        </div>:null
             }
             </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
            {leaseRental.currency == 'USD' ?
            <FieldGroup className="form-group">
              <span className="checkbox-blk">
              <input checked={leaseRental.is_additional_rate} type="checkbox"  onClick = {() => type != 'view' ? this.updateForm('is_additional_rate',!leaseRental.is_additional_rate) : ''}/>
              Additional Rent Applicable
              </span>
              {/*<p style={{fontSize:'10px',color:'#909192'}}>Formula:(Preceding Month Dollar Reference Value X(Dollar Margin + LIBOR)X Period)</p>*/}
             </FieldGroup>:null
            }
            </FieldCol>
            <FieldCol md="6">
             {leaseRental.currency == 'USD' ? leaseRental.is_additional_rate ?
            <FieldGroup className="form-group">
              <FieldLabel className="label">Dollar Margin (%)</FieldLabel>
              <FieldInput
                value={leaseRental.margin}
                type={type}
                keyParam="margin"
                updateField={this.updateForm}
              />
              <h6 className="error-msg">{error.margin}</h6>
            </FieldGroup>:null:null
            }
          </FieldCol>
          </Row>:null
            }
            <Row>
              <FieldCol md="12" style={{padding: '20px'}}>
                <span className="warning-info-text">Please Note : Individual association of Bank Details associated with MR and / or Rental account is discontinued. Bank details would be associated with Lessor Instance, which can be configured under “Configure”. Please contact <a href="mailto:support@sparta.aero">support@sparta.aero</a> in case of any queries.</span>
              </FieldCol>
            </Row>
            <Row style={{display: 'none'}}>
            <FieldCol md="12">
            <h3 style={{borderBottom:'none',margin: '10px 15px 28px 25px'}}>Bank Account Details Applicable</h3>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_name') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label" required={this.state.requireBanking}>Bank Name</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_name}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_name"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.bank_name}</h6>
              </FieldGroup>
            </FieldCol>
            { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_name')?
            <FieldCol md="12">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                <FieldLabel className="label" required={true}>Bank Name (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_name}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_name"
                  updateField={this.updateEditedForm}
                />
                <h6 className="error-msg">{error.bank_name_edit}</h6>
              </FieldGroup>
            </FieldCol>:null}
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_account_name') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label" required={this.state.requireBanking}>Account Name</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_account_name}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_account_name"
                  updateField={this.updateForm}
                />
                 <h6 className="error-msg">{error.bank_account_name}</h6>
              </FieldGroup>
              </FieldCol>
              { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_account_name') ?
              <FieldCol md="12">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label" required={true}>Account Name (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_account_name}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_account_name"
                  updateField={this.updateEditedForm}
                />
                 <h6 className="error-msg">{error.bank_account_name_edit}</h6>
              </FieldGroup>
            </FieldCol>:null}
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_account_number') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label" required={this.state.requireBanking}>Bank Account Number</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_account_number}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_account_number"
                  updateField={this.updateForm}
                />
                 <h6 className="error-msg">{error.bank_account_number}</h6>
              </FieldGroup>
            </FieldCol>
            { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_account_number') ?
            <FieldCol md="12">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                <FieldLabel className="label" required={true}>Bank Account Number (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_account_number}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_account_number"
                  updateField={this.updateEditedForm}
                />
                 <h6 className="error-msg">{error.bank_account_number_edit}</h6>
              </FieldGroup>
            </FieldCol>:null}
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_swift_code') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">SWIFT Code</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_swift_code}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_swift_code"
                  updateField={this.updateForm}
                />
                 <h6 className="error-msg">{error.bank_swift_code}</h6>
              </FieldGroup>
              </FieldCol>
              { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_swift_code')?
              <FieldCol md="12">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                <FieldLabel className="label">SWIFT Code (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_swift_code}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_swift_code"
                  updateField={this.updateEditedForm}
                />
                 <h6 className="error-msg">{error.bank_swift_code}</h6>
              </FieldGroup>
            </FieldCol>:null}
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_iban') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">{getLocalStorageInfo().defaultLessor.id==14?"ABA Code":"IBAN Code"}</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_iban}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_iban"
                  updateField={this.updateForm}
                />
                 <h6 className="error-msg">{error.bank_iban}</h6>
              </FieldGroup>
            </FieldCol>
            { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_iban')?
            <FieldCol md="12">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                <FieldLabel className="label">{getLocalStorageInfo().defaultLessor.id==14?"ABA Code (Edited)":"IBAN Code (Edited)"} <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_iban}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_iban"
                  updateField={this.updateEditedForm}
                />
                 <h6 className="error-msg">{error.bank_iban}</h6>
              </FieldGroup>
            </FieldCol>:null}
            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_sort_code') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">SORT Code</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_sort_code}
                  type={type}
                  maxLength={20}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_sort_code"
                  updateField={this.updateForm}
                  maxLength={20}
                />
                 <h6 className="error-msg">{error.bank_sort_code}</h6>
              </FieldGroup>
            </FieldCol>
            { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_sort_code') ?
            <FieldCol md="6">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                <FieldLabel className="label">SORT Code (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_sort_code}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_sort_code"
                  updateField={this.updateEditedForm}
                  maxLength={20}
                />
                 <h6 className="error-msg">{error.bank_sort_code}</h6>
              </FieldGroup>
            </FieldCol>:null}
            { [14,242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <Fragment>
                <FieldCol md="12">
                  <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('hub_number') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                    <FieldLabel className="label">Hub Account</FieldLabel>
                    <FieldInput
                      value={leaseRental.hub_number}
                      type={type}
                      disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                      keyParam="hub_number"
                      updateField={this.updateForm}
                      maxLength={20}
                    />
                  <h6 className="error-msg">{error.hub_number}</h6>
                  </FieldGroup>
                </FieldCol>
                { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('hub_number') ?
                <FieldCol md="6">
                  <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                    <FieldLabel className="label">Hub Account (Edited) <span className="highter"></span></FieldLabel>
                    <FieldInput
                      value={leaseRental.requested_data.hub_number}
                      type={type}
                      disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                      keyParam="hub_number"
                      updateField={this.updateEditedForm}
                      maxLength={20}
                    />
                  <h6 className="error-msg">{error.hub_number}</h6>
                  </FieldGroup>
                </FieldCol>:null}
                <FieldCol md="12">
                  <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('vat_number') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                    <FieldLabel className="label">VAT Number</FieldLabel>
                    <FieldInput
                      value={leaseRental.vat_number}
                      type={type}
                      disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                      keyParam="vat_number"
                      updateField={this.updateForm}
                    />
                  <h6 className="error-msg">{error.vat_number}</h6>
                  </FieldGroup>
                </FieldCol>
                { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('vat_number') ?
                <FieldCol md="6">
                  <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                    <FieldLabel className="label">VAT Number (Edited) <span className="highter"></span></FieldLabel>
                    <FieldInput
                      value={leaseRental.requested_data.vat_number}
                      type={type}
                      disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                      keyParam="vat_number"
                      updateField={this.updateEditedForm}
                    />
                  <h6 className="error-msg">{error.vat_number}</h6>
                  </FieldGroup>
                </FieldCol>:null}
              </Fragment> : null
            }

            <FieldCol md="12">
              <FieldGroup className="form-group" style={adminFlag && leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_note') ?{opacity:'0.3',pointerEvents:'none'}:contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}}>
                <FieldLabel className="label">Footer Note</FieldLabel>
                <FieldInput
                  value={leaseRental.bank_note}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_note"
                  updateField={this.updateForm}
                />
                 <h6 className="error-msg">{error.bank_note}</h6>
              </FieldGroup>
            </FieldCol>
            { leaseRental.requested_data && leaseRental.requested_data.hasOwnProperty('bank_note') ?
            <FieldCol md="12">
              <FieldGroup className="form-group" style={contracterFlag?{pointerEvents:'none',opacity:'0.3'}:{opacity:1}} >
                <FieldLabel className="label">Footer Note (Edited) <span className="highter"></span></FieldLabel>
                <FieldInput
                  value={leaseRental.requested_data.bank_note}
                  type={type}
                  disable={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.lease_rental_rate && getLocalStorageInfo().user.permission.contracts.lease_rental_rate.indexOf('BNK') !== -1 ? false : true}
                  keyParam="bank_note"
                  updateField={this.updateEditedForm}
                />
                 <h6 className="error-msg">{error.bank_note}</h6>
              </FieldGroup>
            </FieldCol>:null}
          </Row>
        </div>
        {
        type != 'view' ?
        leaseRental.requested_data && Object.keys(leaseRental.requested_data).length ?
          getLocalStorageInfo().user.is_root_user?
          <div className="submit-block">
          <Link style={{marginRight:'10px'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,true)} className="primary-btn" >Accept</Link>
          <Link style={{marginRight:'10px',background:'#ff5e5e',border: '1px solid #ff5e5e'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,false)} className="primary-btn" >Reject</Link>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          {type == 'edit' ?
          <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} /> Review Revision History</Link>
          :null}
          {leaseRental.requested_data?leaseRental.requested_data&&leaseRental.requested_data.created_by&&leaseRental.requested_data.created_by.id==getLocalStorageInfo().user.id?
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {'You at '+ moment(leaseRental.requested_data.created_at).format(displayDateFormat)}
          </div>:
          <div style={{fontSize: '12px',
           marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {leaseRental.requested_data&&leaseRental.requested_data.created_by?this.capitalizeFirstLetter(leaseRental.requested_data.created_by.first_name+' '+leaseRental.requested_data.created_by.last_name)+' at '+ moment(leaseRental.requested_data.created_at).format(displayDateFormat):'--'}
          </div>:null
          }
          </div>:
          getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
          <div className="submit-block">
          <Link style={{marginRight:'10px'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,true)} className="primary-btn" >Accept</Link>
          <Link style={{marginRight:'10px',background:'#ff5e5e',border: '1px solid #ff5e5e'}} onClick ={(e)=>this.updateRentalInvoiceAccept(e,false)} className="primary-btn" >Reject</Link>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          {type == 'edit' ?
          <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} /> Review Revision History</Link>
          :null}
          {leaseRental.requested_data?leaseRental.requested_data&&leaseRental.requested_data.created_by&&leaseRental.requested_data.created_by.id==getLocalStorageInfo().user.id?
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {'You at '+ moment(leaseRental.requested_data.created_at).format(displayDateFormat)}
          </div>:
          <div style={{fontSize: '12px',
           marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {leaseRental.requested_data&&leaseRental.requested_data.created_by?this.capitalizeFirstLetter(leaseRental.requested_data.created_by.first_name+' '+leaseRental.requested_data.created_by.last_name)+' at '+ moment(leaseRental.requested_data.created_at).format(displayDateFormat):'--'}
          </div>:null
          }
          </div>:
          <div className="submit-block">
          <Link  style={{marginRight:'10px',opacity:'0.5'}}  className="primary-btn" >In Review</Link>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          {type == 'edit' ?
          <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} /> Review Revision History</Link>
          :null}
          {leaseRental.requested_data?leaseRental.requested_data&&leaseRental.requested_data.created_by&&leaseRental.requested_data.created_by.id==getLocalStorageInfo().user.id?
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {'You at '+ moment(leaseRental.requested_data.created_at).format(displayDateFormat)}
          </div>:
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {leaseRental.requested_data&&leaseRental.requested_data.created_by?this.capitalizeFirstLetter(leaseRental.requested_data.created_by.first_name+' '+leaseRental.requested_data.created_by.last_name)+' at '+ moment(leaseRental.requested_data.created_at).format(displayDateFormat):'--'}
          </div>:null
          }
          </div>:
          getLocalStorageInfo().user.is_root_user?
          <div className="submit-block">
              <input type="submit" className="primary-btn" value="Save Changes"/>
              <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
              {type == 'edit' ?
              <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} /> Review Revision History</Link>
              :null}
              {leaseRental.bank_details_last_updated_by?leaseRental.bank_details_last_updated_by&&leaseRental.bank_details_last_updated_by.id&&leaseRental.bank_details_last_updated_by.id==getLocalStorageInfo().user.id?
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {'You at '+ moment(leaseRental.bank_details_last_updated_by).format(displayDateFormat)}
          </div>:
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {leaseRental.bank_details_last_updated_by&&leaseRental.bank_details_last_updated_by.first_name?this.capitalizeFirstLetter(leaseRental.bank_details_last_updated_by.first_name+' '+leaseRental.bank_details_last_updated_by.last_name)+' at '+ moment(leaseRental.rental_last_updated_at).format(displayDateFormat):'--'}
          </div>:null
          }
           </div>:
           getLocalStorageInfo().user.security_groups.filter(data => data.id== securityGroups.lessor_admin).length==1?
           <div className="submit-block">
              <input type="submit" className="primary-btn" value="Save Changes"/>
              <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
              {type == 'edit' ?
              <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} /> Review Revision History</Link>
              :null}
              {leaseRental.bank_details_last_updated_by?leaseRental.bank_details_last_updated_by&&leaseRental.bank_details_last_updated_by.id&&leaseRental.bank_details_last_updated_by.id==getLocalStorageInfo().user.id?
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {'You at '+ moment(leaseRental.bank_details_last_updated_by).format(displayDateFormat)}
          </div>:
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {leaseRental.bank_details_last_updated_by&&leaseRental.bank_details_last_updated_by.first_name?this.capitalizeFirstLetter(leaseRental.bank_details_last_updated_by.first_name+' '+leaseRental.bank_details_last_updated_by.last_name)+' at '+ moment(leaseRental.rental_last_updated_at).format(displayDateFormat):'--'}
          </div>:null
          }
           </div>:
         <div className="submit-block">
         <input type="submit" className="primary-btn" value="Submit"/>
         <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
         {type == 'edit' ?
         <Link className="auditlog-btn" style={{float:'right',marginTop:'6px'}}onClick={this.props.toggleRevisionHistory} ><img className="audit-img" style={{ position: 'relative', bottom: '1px' }} src={imgStoragePath+'audit_icon.png'} /> Review Revision History</Link>
         :null}
         {leaseRental.bank_details_last_updated_by?leaseRental.bank_details_last_updated_by&&leaseRental.bank_details_last_updated_by.id&&leaseRental.bank_details_last_updated_by.id==getLocalStorageInfo().user.id?
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {'You at '+ moment(leaseRental.bank_details_last_updated_by).format(displayDateFormat)}
          </div>:
          <div style={{fontSize: '12px',
          marginTop: '5px',color:'#877a7a'}}>
          Last Edited by: {leaseRental.bank_details_last_updated_by&&leaseRental.bank_details_last_updated_by.first_name?this.capitalizeFirstLetter(leaseRental.bank_details_last_updated_by.first_name+' '+leaseRental.bank_details_last_updated_by.last_name)+' at '+ moment(leaseRental.rental_last_updated_at).format(displayDateFormat):'--'}
          </div>:null
          }
        </div>: null
         }

      </form>
   )
 }
}
