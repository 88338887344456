import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService,globalDeleteService } from '../../../../globalServices/';
import { addDeliveryCondition } from '../../contractAddObject';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
export const deliveryCondListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.DEL_COND_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/delivery-condition-items/', {})
    .then(response => {
      dispatch({
        type: actions.DEL_COND_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.DELIVERY_COND_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const deliveryConditionCrudAc = (params, type, id) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.DELIVERY_COND_CRUD,
        payload: {type: type, data:addDeliveryCondition , flag: true }
      });
    }else{
      dispatch({
        type: actions.DEL_COND_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/delivery-condition-items/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.DEL_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.DELIVERY_COND_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}


export const deliveryConditionDeleteAc = (params, id) => {
  return(dispatch) => {
      dispatch({
        type: actions.DEL_COND_LOADER,
        payload: true
      });
      globalDeleteService('contracts/'+params.contract_slug+'/delivery-condition-items/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.DEL_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.DELIVERY_COND_DELETE,
            payload: id
          });
        }
      })

  }
}

export const addEditDeliveryCondAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    dispatch({
      type: actions.DEL_COND_LOADER,
      payload: true
    });
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/delivery-condition-items/', data)
      .then(response => {
        dispatch({
          type: actions.DEL_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.DELIVERY_COND_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.DELIVERY_COND_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Delivery Condition`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Delivery Condition`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/delivery-condition-items/' + data.id + '/', data)
      .then(response => {
        dispatch({
          type: actions.DEL_COND_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.DELIVERY_COND_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.DELIVERY_COND_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Delivery Condition`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Delivery Condition`,
            item_id: data.id
          })
        }
      })
    }
  }
}
