import * as actions from '../actions';
export default function( state = {
  inductionCondList: [],
  contractCondn: [],
  inductionCondCrud: {
    flag: false,
    type: '',
    data: {}
  },
  inductionCondLoader: true
}, action){
  switch (action.type) {
    case actions.INDUCTION_COND_LIST:
      return {
        ...state,
        inductionCondList: action.payload
      }
    case actions.INDUCTION_COND_ADD_SUCCESS:
      return {
        ...state,
        inductionCondList: [action.payload, ...state.inductionCondList]
      }
    case actions.INDUCTION_COND_EDIT_SUCCESS:
      return {
        ...state,
        inductionCondList: state.inductionCondList.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.INDUCTION_COND_CRUD:
      return {
        ...state,
        inductionCondCrud:action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        inductionCondCrud: {
          ...state.inductionCondCrud,
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.INDUCTION_COND_LOADER:
      return {
        ...state,
        inductionCondLoader: action.payload
      }
    case actions.CONTRACT_CONDN_LIST:
      return {
        ...state,
        contractCondn: action.payload
      };
    default:
      return state;
  }
}
