import { globalGetService, globalDownloadGetService,globalPostService, globalUnlimitedDownloadGetService, globalDeleteService } from '../../../../globalServices';
import * as actions from '../actions';
import { downloadFileType, toastFlashMessage} from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'

export const sortMiniPackListAc = (params, uuid, sortedFiles) => {
  return (dispatch) =>{
    dispatch({
      type: actions.RESOURCE_LOADER,
      payload: true
    });
    let url = `/records/${params.type}/${params.aircraft_slug}/minipack-data-room/${uuid}/`
    globalPostService(url,sortedFiles)
    .then(response => {
      dispatch({
        type: actions.RESOURCE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch(getMiniPackListAc(params, 'files', uuid))
      }
    })
  }
}
export const getMiniPackListAc = (params, type, uuid, download) => {
  return (dispatch) =>{
    dispatch({
      type: actions.RESOURCE_LOADER,
      payload: true
    });
    let url = `/records/${params.type}/${params.aircraft_slug}/get-minipack-folders/`
    if(type == 'files'){
      url = `/records/${params.type}/${params.aircraft_slug}/minipack-data-room/${uuid}/`
    }
    globalGetService(url, download)
    .then(response => {
      dispatch({
        type: actions.RESOURCE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        if(download && Object.keys(download).length){
          toastFlashMessage(response.data.message, 'success')
        }else{
          dispatch({
            type: actions.DATAKIT_LIST,
            payload: {
              data: response.data.data,
              pagination: response.data.pagination,
              type
            }
          })
        }
        // dispatch({
        //   type: actions.PAGINATION,
        //   payload: response.data.pagination
        // });
      }
    })
  }
}

export const loadResourcesRecordsAc = (params, uuid) => {
    return (dispatch) => {
      dispatch({
          type: actions.LOAD_RES_RECORDS,
          payload: {},
        });
      globalGetService('records/' +params.type+'/'+params.aircraft_slug+'/resources/'+ uuid +'/', {})
      .then(response => {

        if(response.data.statusCode == 200){
            dispatch({
                type: actions.LOAD_RES_RECORDS,
                payload: response.data.data,
              });
        }
      })
    }
  }

  export const downloadDriveToolAc = (params, type) => {
    return (dispatch) => {
      // while downloading drive tool this api is failing - commented by shabeeb
      // dispatch({
      //   type: actions.RESOURCE_LOADER,
      //   payload: true
      // })
      // globalPostService('/records/activity_log_capture?slug='+ params.aircraft_slug, {category: type, uuid: []})
      // .then(response => {
      //   dispatch({
      //     type: actions.RESOURCE_LOADER,
      //     payload: false
      //   })
      // })
    }
  }

  export const downLoadResourcesTempAC = (params, template, uuid, type, name) => {
    return (dispatch) => {
      dispatch({
        type: actions.RESOURCE_LOADER,
        payload: true
      })
      globalUnlimitedDownloadGetService('records/' +params.type+'/'+params.aircraft_slug+'/resources/'+ uuid +'/', {download:template})
      .then(response => {
        dispatch({
          type: actions.RESOURCE_LOADER,
          payload: false
        })
        if(response.headers['content-type'] == 'application/json'){
          dispatch({
            type: actions.BULK_DATA_DWN_FLAG,
            payload: true
          })
          // toastFlashMessage("Can not download file", "error");
        }else {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: response.type}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',name + '.' + type);
          document.body.appendChild(link);
          link.click();
          trackActivity('Report Exported',{
  					file_name: name.replaceAll('_', ' ').toUpperCase(),
  					file_type: type.toUpperCase(),
  					application_module: "Records",
            page_title: 'Resources'
  				})
        }
      })
    }
  }

  export const getDataKitListAc = (params, pageNumber) => {
    return (dispatch) => {
      dispatch({
        type: actions.RESOURCE_LOADER,
        payload: true
      });
      globalGetService('records/' +params.type+'/'+ params.aircraft_slug + '/action-dock/get-files/',{ category_type: params.id, page: pageNumber})
      .then(response => {
        dispatch({
          type: actions.RESOURCE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){

          dispatch({
            type: actions.DATAKIT_LIST,
            payload: {
              data: response.data.data,
              pagination: response.data.pagination
            }
          })
          dispatch({
            type: actions.PAGINATION,
            payload: response.data.pagination
          });
        }
      })

    }
  }

  export const removeAllFileAc = (params, category) =>{
    return(dispatch => {
      globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/change-file-category/',{category: category, flag: false, remove_all: true})
      .then((response) => {
        if(response.data.statusCode == 200 ){
          toastFlashMessage(response.data.message, 'success');
          dispatch(getDataKitListAc(params,1));
        }
      })
    });
  }
  export const removeMiniPackFileAc = (params, uuid, fileIds, oldFile) =>{
    return(dispatch => {
      if(oldFile){
        globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/change-file-category/',{category: 3, flag: false, files: fileIds})
        .then((response) => {
          if(response.data.statusCode == 200 ){
            toastFlashMessage(response.data.message, 'success');
            dispatch(getMiniPackListAc(params));
          }
        })
      }else{
        globalDeleteService(`/records/${params.type}/${params.aircraft_slug}/minipack-data-room/${uuid}/?file=${fileIds}`)
        .then((response) => {
          if(response.data.statusCode == 200 ){
            toastFlashMessage(response.data.message, 'success');
            dispatch(getMiniPackListAc(params, 'files', uuid));
          }
        })
      }
    });
  }

  export const removeFileAc = (params, uuid, category) =>{
    let uuids = [];
    uuids.push(uuid);
    return(dispatch => {
      globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/change-file-category/',{category: category, flag: false, files: uuids})
      .then((response) => {
        if(response.data.statusCode == 200 ){
          toastFlashMessage(response.data.message, 'success');
          dispatch({
            type: actions.REMOVE_LIST,
            payload: {
              uuid
            }
          });
        }
      })
    });
  }
