import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class AddEditMjrAssyfields extends Component {
  constructor(props) {
    super(props);

  }

  updateForm = (type, value) => {
   this.props.updateDetailData(type, value);
  }

  render() {
    const { type, mjrAssy, assemblyName } = this.props;
    return (
      <Row>
      <FieldCol md="4">
        <FieldGroup className="form-group">
          <FieldLabel className="label">TSN of {assemblyName} at Removal</FieldLabel>
          <FieldInput
            value={mjrAssy.tsn_at_removal}
            type={type}
            keyParam="tsn_at_removal"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="4">
        <FieldGroup className="form-group">
          <FieldLabel className="label">CSN of {assemblyName} at Removal</FieldLabel>
          <FieldInput
            value={mjrAssy.csn_at_removal}
            type={type}
            keyParam="csn_at_removal"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="4">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Date of {assemblyName} Removal</FieldLabel>
            <FieldDate
                  value={mjrAssy.date_of_removal}
                  type={type}
                  keyParam="date_of_removal"
                  updateField={this.updateForm}
                />
        </FieldGroup>
      </FieldCol>

      <FieldCol md="6">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Reason for Removal</FieldLabel>
          <FieldInput
            value={mjrAssy.reason_for_removal}
            type={type}
            keyParam="reason_for_removal"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>
      <FieldCol md="12">
        <FieldGroup className="form-group">
          <FieldLabel className="label">Notes</FieldLabel>
          <FieldTextarea
            value={mjrAssy.remarks}
            type={type}
            keyParam="remarks"
            updateField={this.updateForm}
          />
        </FieldGroup>
      </FieldCol>

      <FieldCol md="6">
      {type == 'view' ?
        <div className="form-group">
          <label className="label">Preservation Performed</label>
          {mjrAssy.preservation_performed}
        </div>
        :
        <div className="form-group">
          <label className="label">Preservation Performed</label>

          <div className="flex-centered radio-button">
            <label className="label">
              <input
                type="radio"
                name="preservation_performed"
                value="yes"
                checked={mjrAssy.preservation_performed == 1 ? "checked" : null}
                onChange={(e) => this.updateForm('preservation_performed', true)}
              />
             <span>Yes</span>
             </label>
          <label className="label">
            <input
              type="radio"
              value="no"
              name="preservation_performed"
              checked={mjrAssy.preservation_performed == 0 ? "checked" :  null}
              onChange={(e) => this.updateForm('preservation_performed', false)}
            />
            <span>No</span>
          </label>
          </div>
      </div>

      }
      </FieldCol>

      </Row>
    )
  }
}
