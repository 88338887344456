import React from 'react';
import { Row, Col } from 'reactstrap';
import { checkFloat } from '../../../formValidator';
class FloatInputField extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      value: this.props.value,
      error: ''
    }
  }
  componentDidMount(){

  }
  componentWillReceiveProps(nextProps){

  }
  onChange = (value) => {
    this.setState(prevState => ({
      ...prevState,
      error: checkFloat({value: value, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid  value'}),
      value: value
    }))
  }
  render(){
    const { type } = this.props;
    if(this.state.type == ''){
      return null
    }
    return(
        <div className="tech-sum-item">
          <label className="label">
            {this.props.children}
            {
              this.props.required ? <sup className="reqStar"> * </sup> :null
            }
            {this.props.error != '' ? <span className="reqStar">{this.props.error}</span> : null}
          </label>
          {type == 'View' ?
            <h6>{this.props.value}</h6> :
          <input
            type="text"
            placeholder='placeholder'
            value={this.state.value}
            onChange = {(evt) => this.onChange(evt.target.value)}
            onBlur={(evt) => this.props.onBlur(evt.target.value)}
          />}
        </div>
    )
  }
}
export default FloatInputField;
