import React from 'react';
import moment from 'moment';
import { imgStoragePath  } from '../../../../constants';
import { Activities } from '../components';
const ActivityList = ({item, index}) => {
  return (
    <tr>
      <td width="450">
        <Activities
          name={item.modified_by.name}
          fileName={item.new_name}
          type={item.folder ? 'folder':'file'}
          count={item.count}
          id={item.activity_type.id}
          userId={item.modified_by.id}
          fromFolder={item.from_folder}
          toFolder={item.to_folder}
          oldName={item.old_name}
        />
      </td>
      <td><img className="profile-pic" src={item.modified_by.profile_pic != null ? `${item.modified_by.profile_pic}` : `${imgStoragePath}user.png`} alt="img"/><span>{item.modified_by.name}</span></td>
      <td>{moment.utc(item.activity_time).format('HH:MM A MMMM DD, YYYY')} UTC</td>
      <td>{item.device_info ? item.device_info.browser_type : '--'}</td>
      <td>{item.device_info ? item.device_info.device_type : '--'}</td>
      <td>{item.device_info ? item.device_info.os_type : '--'}</td>
      <td>{item.device_info ? item.device_info.ip : '--'}</td>
    </tr>
  )
}
export default ActivityList;
