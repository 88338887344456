import * as actions from '../actions';
import { globalPutService, globalExportService, globalPostService, globalDeleteService,globalUnlimitedDownloadGetService, globalGetService } from '../../../globalServices';
import { toastFlashMessage, downloadFileType } from '../../../utils'
import { browserHistory } from 'react-router'
import { getLocalStorageInfo} from '../../../utils'


export const downLoadLessorTempAC = (assetType) => {
  return (dispatch) => {
    dispatch({
      type: actions.LESSOR_LOADER,
      payload: true
    })
    globalUnlimitedDownloadGetService('console/lessor-template-download/'+assetType+'/', {})
    .then(response => {
      if(response.data.statusCode){
        toastFlashMessage("Can not download file", "error");
      }else {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: response.type}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',assetType + '_lessor_temp.xlsx');
        document.body.appendChild(link);
        link.click();
      }
      dispatch({
        type: actions.LESSOR_LOADER,
        payload: false
      })
    })
  }
}

export const uploadLessorTempAc = (formdata) => {
  return(dispatch) => {
    dispatch({
      type: actions.LESSOR_LOADER,
      payload: true
    })
   globalPutService('/console/update-lessor/'+getLocalStorageInfo().defaultLessor.slug+'/',formdata)
     .then(response => {
      dispatch({
        type: actions.LESSOR_LOADER,
        payload: false
      })
    if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, "success");
       }
     })
  }
}

export const editLessorAc = (formdata, message, messageType) => {
  return(dispatch) => {
    dispatch({
      type: actions.LESSOR_PROCESSING,
      payload: true,
    })
   globalPutService('/console/update-lessor/'+getLocalStorageInfo().defaultLessor.slug+'/',formdata)
     .then(response => {
      dispatch({
        type: actions.LESSOR_PROCESSING,
        payload: false,
      })
    if(response.data.statusCode == 200){
        
        if(messageType && message){
          toastFlashMessage(message, messageType);
        }else{
          toastFlashMessage(response.data.message, "success");
        }

        let userDetail = getLocalStorageInfo();
        userDetail = {
          ...userDetail,
          defaultLessor: {
            ...userDetail.defaultLessor,
            name: response.data.data.name,
            contact_address: response.data.data.contact_address?response.data.data.contact_address:'',
            country:response.data.data.country?response.data.data.country:'',
            logo:response.data.data.logo?response.data.data.logo:'',
            introduction:response.data.data.introduction?response.data.data.introduction:'',
            disclaimer:response.data.data.disclaimer?response.data.data.disclaimer:'',
            confidentiality:response.data.data.confidentiality?response.data.data.confidentiality:'',
            email:response.data.data.email?response.data.data.email:'',
            phone_number:response.data.data.phone_number?response.data.data.phone_number:'',
            bank_name:response.data.data.bank_name?response.data.data.bank_name:'',
            bank_account_name:response.data.data.bank_account_name?response.data.data.bank_account_name:'',
            bank_account_number:response.data.data.bank_account_number?response.data.data.bank_account_number:'',
            bank_code:response.data.data.bank_code?response.data.data.bank_code:'',
            bank_iban:response.data.data.bank_iban?response.data.data.bank_iban:'',
            bank_sort_code:response.data.data.bank_sort_code?response.data.data.bank_sort_code:'',
            bank_swift_code:response.data.data.bank_swift_code?response.data.data.bank_swift_code:'',
            bank_reference:response.data.data.bank_reference?response.data.data.bank_reference:'',
            bank_note:response.data.data.bank_note?response.data.data.bank_note:'',
            footer_note:response.data.data.footer_note?response.data.data.footer_note:'',
            spv:response.data.data.spv?response.data.data.spv:'',
            summary_tnc:response.data.data.summary_tnc?response.data.data.summary_tnc:'',
            jurisdiction:response.data.data.jurisdiction?response.data.data.jurisdiction:'',
            distribution_emails: response.data.data.distribution_emails.length ? response.data.data.distribution_emails :[],
            hub_number:response.data.data.hub_number?response.data.data.hub_number : '',
            vat_number:response.data.data.vat_number? response.data.data.vat_number:'',
            owners:response.data.data.owner? response.data.data.owner: []

          }
        }
        localStorage.setItem('userInfo', JSON.stringify(userDetail));

        dispatch({
          type: actions.TYPE_LESSOR_UPDATE,
          payload: 'view'
        })
        dispatch({
          type: actions.UPDATE_LESSOR_DATA,
          payload: userDetail.defaultLessor
        })
       }
     })
  }
}

export const updateAutoPublishAc = (flag) => {
  return(dispatch) => {
    dispatch({
      type: actions.LESSOR_PROCESSING,
      payload: true,
    })
    globalPostService('/console/auto-file-publish/', flag)
    .then(response => {
      if(response.data.statusCode == 200){
        let userDetail = getLocalStorageInfo();
        userDetail = {
          ...userDetail,
          defaultLessor: {
            ...userDetail.defaultLessor,
            file_auto_publish: flag.file_auto_publish == 1 ? true : false
          }
        }
        localStorage.setItem('userInfo', JSON.stringify(userDetail));
        toastFlashMessage(response.data.message, 'success')
      }
      dispatch({
        type: actions.LESSOR_PROCESSING,
        payload: false,
      })
    })
  }
}
export const getOwnerAc = () => {
  return(dispatch) => {
    dispatch({
      type:actions.LESSOR_PROCESSING,
      payload:true
    });
    globalGetService(`console/owner/`)
    .then(response => {
      dispatch({
        type:actions.LESSOR_PROCESSING,
        payload:false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.OWNERS_LIST,
          payload:response.data.data.sort((a,b) => {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
             var nameB = b.name.toUpperCase(); // ignore upper and lowercase
             if (nameA < nameB) {
               return -1;
             }
             if (nameA > nameB) {
               return 1;
             }

             // names must be equal
             return 0;
          })
        })
      }
    })
  }
}

export const getBankingDetailsDropdownAc = (value) => {
  return(dispatch) =>{
    globalGetService(`console/bank-details/`, {bank_name: value})
    .then(response => {
      if(response.data.statusCode == 200){
        let DropdownMenu = response.data.data
        let newDrop = response.data.data.map(item  => ({id: item.lessor.id, name:item.lessor.name, lessor_level:item.lessor_level}) )
        if(value){
          dispatch({
            type: actions.BANKING_DETAILS_LIST,
            payload: response.data.data.sort((a,b) => {
              var nameA = a.name.toUpperCase(); // ignore upper and lowercase
               var nameB = b.name.toUpperCase(); // ignore upper and lowercase
               if (nameA < nameB) {
                 return -1;
               }
               if (nameA > nameB) {
                 return 1;
               }

               // names must be equal
               return 0;
            })
          })
        }else {
          dispatch({
            type: actions.BANK_DROPDOWN,
            payload: response.data.data.sort((a,b) => {
              var nameA = a.name.toUpperCase(); // ignore upper and lowercase
               var nameB = b.name.toUpperCase(); // ignore upper and lowercase
               if (nameA < nameB) {
                 return -1;
               }
               if (nameA > nameB) {
                 return 1;
               }

               // names must be equal
               return 0;
            })
          })
        }
      }
    })
  }
}


export const getOwnerDropdownListAc = () => {
  return(dispatch) => {
    globalGetService(`console/owner/?dropdown=true`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.OWNER_DROPDOWN,
          payload: response.data.data.sort((a,b) => {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
             var nameB = b.name.toUpperCase(); // ignore upper and lowercase
             if (nameA < nameB) {
               return -1;
             }
             if (nameA > nameB) {
               return 1;
             }

             // names must be equal
             return 0;
          })
        })
      }
    })
  }
}


export const addEditOwnerAc = (data) => {
  return(dispatch) => {
    dispatch({
      type: actions.LESSOR_PROCESSING,
      payload:true
    });
    if(data.id){
      globalPutService(`console/owner/${data.id}/`, data)
      .then(response => {
        dispatch({
          type:actions.LESSOR_PROCESSING,
          payload:false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.EDIT_OWNER,
            payload:response.data.data,
            ownerID:data.id
          })
          dispatch(getOwnerAc())
          dispatch(getOwnerDropdownListAc())
          toastFlashMessage(response.data.message, 'success')
        }

      })
    }else {
      globalPostService(`console/owner/`, data)
      .then(response => {
        dispatch({
          type:actions.LESSOR_PROCESSING,
          payload:false
        })
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.ADD_OWNER,
            payload:response.data.data
          })
          dispatch(getOwnerAc())
          dispatch(getOwnerDropdownListAc())
          toastFlashMessage(response.data.message, 'success')
        }
      })
    }

  }
}
export const deleteOwnerAc = (id) => {
  return(dispatch) => {
    dispatch({
      type:actions.LESSOR_PROCESSING,
      payload:true
    });
    globalDeleteService(`console/owner/${id}/`)
    .then(response => {
      dispatch({
        type:actions.LESSOR_PROCESSING,
        payload:false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.DELETE_OWNER,
          payload:response.data.data,
          ownerID:id
        })
        dispatch(getOwnerAc())
        dispatch(getOwnerDropdownListAc())
        toastFlashMessage(response.data.message, 'success')
      }
    })
    }
  }

export const deleteBankingDetailAc = (id) => {
  return (dispatch) => {
    dispatch({
      type: actions.LESSOR_PROCESSING,
      payload: true
    });
    globalDeleteService(`console/bank-details/${id}/`)
      .then(response => {
        dispatch({
          type: actions.LESSOR_PROCESSING,
          payload: false
        });
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.BANKING_DETAILS_LIST,
            payload: response.data.data
          })
          dispatch(getBankingDetailsAc())
          dispatch(getBankingDetailsDropdownAc())
          toastFlashMessage(response.data.message, 'success')
        }
      })
  }
}

  export const addEditBankingInfoAc = (data) => {
    return(dispatch) => {
      dispatch({
        type:actions.LESSOR_PROCESSING,
        payload:true
      });
      if(data.id){
        globalPutService(`console/bank-details/${data.id}/`, data)
        .then(response => {
          dispatch({
            type:actions.LESSOR_PROCESSING,
            payload:false
          })
          if(response.data.statusCode == 200){
            dispatch({
              type:actions.EDIT_BANKING_DETAILS,
              payload:response.data.data
            })
            toastFlashMessage(response.data.message, 'success')
            dispatch(getBankingDetailsAc())
            dispatch(getOwnerDropdownListAc())
            dispatch(getBankingDetailsDropdownAc())
          }
        })
      }else {
        globalPostService(`console/bank-details/`, data)
        .then(response => {
          dispatch({
            type:actions.LESSOR_PROCESSING,
            payload:false
          })
          if(response.data.statusCode == 200){
            dispatch({
              type:actions.ADD_BANKING_DETAILS,
              payload:response.data.data
            })
            dispatch(getBankingDetailsAc())
            dispatch(getBankingDetailsDropdownAc())
            dispatch(getOwnerDropdownListAc())
            toastFlashMessage(response.data.message, 'success')
          }
        })
      }
    }
  }
  export const getBankingDetailsAc = (id, lessorLevel, bank, status) => {
    return(dispatch) => {
      dispatch({
        type:actions.LESSOR_PROCESSING,
        payload:true
      });
      globalGetService(`console/bank-details/`, {lessor_id: id, lessor_level:lessorLevel, bank_name:bank, status})
      .then(response => {
        dispatch({
          type:actions.LESSOR_PROCESSING,
          payload:false
        })
        if(response.data.statusCode == 200){
          dispatch({
            type:actions.BANKING_DETAILS_LIST,
            payload:response.data.data.sort((a,b) => {
              var nameA = a.name.toUpperCase(); // ignore upper and lowercase
               var nameB = b.name.toUpperCase(); // ignore upper and lowercase
               if (nameA < nameB) {
                 return -1;
               }
               if (nameA > nameB) {
                 return 1;
               }

               // names must be equal
               return 0;
            })
          })
        }
      })
    }
  }
  //  export const getBankingDetailsDropdownListAc = () => {
  //   return(dispatch) => {
  //     globalGetService(`console/bank-details/?dropdown=true`)
  //     .then(response => {
  //       if(response.data.statusCode == 200){
  //         dispatch({
  //           type:actions.BANKING_DROPDOWN_LIST,
  //           payload:response.data.data
  //         })
  //       }
  //     })
  //   }
  // }
  //  export const getOwnerDropdownListAc = () => {
  //   return(dispatch) => {
  //     globalGetService(`console/owner/?dropdown=true`)
  //     .then(response => {
  //       if(response.data.statusCode == 200){
  //         dispatch({
  //           type:actions.OWNER_DROPDOWN_LIST,
  //           payload:response.data.data
  //         })
  //       }
  //     })
  //   }
  // }
