export const FINDING_LIST = 'FINDING_LIST';
export const AUDITS_FINDING_INFO = 'AUDITS_FINDING_INFO';
export const AUDITS_SECTIONS_INFO = 'AUDITS_SECTIONS_INFO';
export const UPDATE_FINDINGS = 'UPDATE_FINDINGS';
export const ADD_FINDINGS_T007 = 'ADD_FINDINGS_T007';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const EDIT_IMAGE = 'EDIT_IMAGE';
export const CHANGE_IMAGE = 'CHANGE_IMAGE';
export const DELETE_FINDING = 'DELETE_FINDING';
export const EDIT_FINDING = 'EDIT_FINDING';
export const FINDING_LOADER = 'FINDING_LOADER';
export const UPDATE_IMAGE_IN_EDIT = 'UPDATE_IMAGE_IN_EDIT';
export const UPDATE_ADD_IMAGE = 'UPDATE_ADD_IMAGE';
export const DELETE_ADD_IMAGE = 'DELETE_ADD_IMAGE';
export const TOGGLE_T007_T004 = "TOGGLE_T007_T004";


