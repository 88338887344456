import React, {Component} from 'react';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils';
export default class LogList extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(e, modelOpen = null) {
    if(modelOpen){
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({
      modal: !this.state.modal
    });
  }
  delLog = (id) => {
    this.toggle();
    this.props.delLog(id);
  }
  render(){
    const { log } = this.props;
    return(
      <Link to={"/log/"+ log.id} style={{position: 'relative', 'display': 'block'}}>
      {
        log.use_acumen_algorithm  && getLocalStorageInfo().defaultLessor.id == 24?
        <span className="data-points-heading">Based On Acumen Data Points</span>:null
      }
        <Row className="logList">
          <Col md="1">
            <p className="para-ui">
              <Link >{log.id}</Link>
            </p>
          </Col>
          <Col md="2">
            <p className="para-ui" >
              {moment(log.updated_at).format(displayDateFormat)}
            </p>
          </Col>
          <Col md="1">
            <p className="para-ui" >
              {log.aircraft_type ? log.aircraft_type:'--'}
            </p>
          </Col>
          <Col md="2">
            <p className="para-ui" >
              {log.engine_type ? log.engine_type:'--'}
            </p>
          </Col>
          <Col md="2">
            <p className="para-ui" >
              {log.region_country ? log.region_country + ',' : ''}
              {log.region_country ? <br/> : ''}
              {log.region ? log.region:'--'}
            </p>
          </Col>
          <Col md="2">
            <p className="para-ui" >
              {log.utilization_ratio ? log.utilization_ratio + ':1':'--'}
            </p>
          </Col>
          <Col md="2">
            <ul className="list-inline" style={{position: 'relative'}}>
              {
                log.is_airframe ?
                <li className="list-inline-item">
                  <p className="para-ui" >
                    <img className="tick-icon" width="10" src={imgStoragePath + (log.is_airframe ? "logs-check.png": "logs-cross.png")} alt="img"/>
                      Airframe
                  </p>
                </li>:null
              }
              {
                log.is_engine ?
                <li className="list-inline-item">
                  <p className="para-ui">
                    <img className="tick-icon" width="10" src={imgStoragePath + (log.is_engine ? "logs-check.png":"logs-cross.png")} alt="img"/>
                    Engines
                  </p>
                </li>:null
              }
              {
                log.is_lg ?
                <li className="list-inline-item">
                  <p className="para-ui">
                    <img className="tick-icon" width="10" src={imgStoragePath + (log.is_lg ? "logs-check.png":"logs-cross.png")} alt="img"/>
                    LG
                  </p>
                </li>:null
              }
              {
                log.is_apu ?
                <li className="list-inline-item">
                  <p className="para-ui">
                    <img className="tick-icon" width="10" src={imgStoragePath + (log.is_apu?"logs-check.png":"logs-cross.png")} alt="img"/>
                    APU
                  </p>
                </li>:null
              }
              <li className="list-inline-item" onClick={(e) => this.toggle(e, true)} style={{position: 'absolute', zIndex: 999, right: '-15px'}}>
                <img width="10" className="delete-icon"  src={imgStoragePath+"del_icon.png"} alt="img"/>
              </li>
            </ul>
          </Col>
        </Row>
        <Modal isOpen={this.state.modal} centered={true} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Delete Log</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to delete Log:<b>{log.id}</b></h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => this.delLog([log.id])}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={this.toggle}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      </Link>
      )
  }
}
