import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL } from '../../../../constants';
const LLPReplaceModal = ({llps}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return(
    <Fragment>
      <span onClick={toggle} style={{fontSize:'12px', color:'#0056b3',textDecoration:'underline', cursor:'pointer'}}>View</span>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>LLP's List</ModalHeader>
        <ModalBody>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>LLP Type</th>
                  <th>Cycle Remaining</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {llps.map((item, index) =>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.remaining_cycles}</td>
                    <td>
                      { item.listprice ?
                        <NumericLabel params={prettyNumberParamsL}>
                          {item.listprice}
                        </NumericLabel>:'--'
                      }
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}
export default LLPReplaceModal;
