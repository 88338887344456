import React, { Component } from 'react';
import { Link, withRouter, browserHistory } from 'react-router';
import { imgStoragePath } from '../../../constants';
import { ToolTipHover } from '../../../shared';
import Switcher from '../../../shared/components/Switcher';
import { getLocalStorageInfo } from '../../../utils';
class SecondaryTechHeaderMid extends Component{
	constructor(props){
		super(props);
	}
  render(){
  	return(
			<div className="heading-block">
				<h6 className="para">
				{getLocalStorageInfo().user.permission.records &&
                        <Switcher app="Technical" list={['Records']} />
                      }
					{/* {getLocalStorageInfo().user.permission.records && <Switcher app="Technical" list={["Records"]} /> } */}
					<p id="work-order-name" style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => browserHistory.push('/technical/project/view/' +this.props.projectInfo.slug)}>
          	{ this.props.projectInfo && this.props.projectInfo.name != undefined && this.props.projectInfo.name != null ?
          		this.props.projectInfo.name.length > 35 ?  this.props.projectInfo.name.substring(0,32)+'...':this.props.projectInfo.name : '--'
            }
          </p>
					{ this.props.projectInfo && this.props.projectInfo.name != undefined && this.props.projectInfo.name != null ?
						this.props.projectInfo.name.length > 35 ?
						<ToolTipHover placement="top" tagetId='work-order-name'>
							{this.props.projectInfo.name}
						</ToolTipHover>
					  :null:null
					}

				</h6>
				<Link to={`/technical-specification/${this.props.params.type}/${this.props.params.aircraft_slug}`}>
					<img width="15" src={ this.props.activeAsset && this.props.activeAsset.asset_type != undefined && this.props.activeAsset.asset_type == 1 ? imgStoragePath+"icon_Aircraft.png":imgStoragePath+"icon_engine.png"} alt="img"/>
					Technical Specification
				</Link>
			</div>
      )
    }
}

export default withRouter(SecondaryTechHeaderMid)
