export const UTILS_LIST = 'UTILS_LIST';
export const RENTAL_UTILS_LIST = 'RENTAL_UTILS_LIST';
export const UTILS_INITIATE = 'UTILS_INITIATE';
export const UTILS_VIEW = 'UTILS_VIEW';
export const UTILS_EDIT = 'UTILS_EDIT';
export const UTILS_TRENDS = 'UTILS_TRENDS';
export const UTILS_AVERAGE = 'UTILS_AVERAGE';
export const ULTILIZATION_BASIC_INFO = 'ULTILIZATION_BASIC_INFO';
export const UPDATE_UTIL_FIELD = 'UPDATE_UTIL_FIELD';
export const CHANGE_UTIL_GRAPH = 'CHANGE_UTIL_GRAPH';
export const CLOSE_UTILS_BAR = 'CLOSE_UTILS_BAR';
export const UTILS_LOADER = 'UTILS_LOADER'
export const UTILS_MAJOR_ASSMEBLY = 'UTILS_MAJOR_ASSMEBLY';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const UTIL_CRUD_OPERN = 'UTIL_CRUD_OPERN';
export const TOGGLE_TAB='TOGGLE_TAB';
export const IMPORT_UTILS = 'IMPORT_UTILS'
