import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment'
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
import { Table } from 'reactstrap';
import { checkString, checkValidDate, checkIntNumber, checkNumber, checkFloat } from '../../../../formValidator';
import { getLocalStorageInfo, toastFlashMessage } from '../../../../utils'
import { ToolTipHover, InfoIcon } from '../../../../shared'
import { globalPostService } from '../../../../globalServices'
import { uploadLesseeLogoAc } from '../../../../shared/actionCreators';
import Select , { Creatable } from 'react-select';
import { imgStoragePath } from '../../../../constants';
const onlyNumeric =  /^(\s*|\d+)$/
class EditGenInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      overview: {},
      error: {},
      cover_photo: '',
      newLessee:false
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }
  componentDidMount(){
    let data = Object.assign({}, this.props.overviewObj);
    let maintanenceList = [];
    let maintanenceData =  data.maintenance_event_program_group;
    if(maintanenceData){
      Object.keys(maintanenceData).map((item,index) => {
        maintanenceList.push({label: maintanenceData[item].replace(/,/g, '/') , value: item})
      });
    }
    delete data['manufacturer'];
    delete data['maintenance_event_program_group'];
    delete data['lessor'];
    delete data['lessor_name']
    delete data['owner']
    data = {
      ...data,
      manufacturer: this.props.overviewObj.manufacturer && this.props.overviewObj.manufacturer.id ? this.props.overviewObj.manufacturer.id  : null,
      maintenance_event_program_group: maintanenceList && maintanenceList[0] && maintanenceList[0].value ? maintanenceList[0].value : null,
      weight_unit : this.props.overviewObj.manufacturer && this.props.overviewObj.weight_unit.value ? this.props.overviewObj.weight_unit.value  : null,
      weight_unit_name : this.props.overviewObj.manufacturer && this.props.overviewObj.weight_unit.label ? this.props.overviewObj.weight_unit.label  : null,
      aircraft_operation : this.props.overviewObj.aircraft_operation && this.props.overviewObj.aircraft_operation.value ? this.props.overviewObj.aircraft_operation.value  : null,
      aircraft_type:  this.props.overviewObj.aircraft_type && this.props.overviewObj.aircraft_type.id ? this.props.overviewObj.aircraft_type.id  : null,
      credit_classification: this.props.overviewObj.credit_classification && this.props.overviewObj.credit_classification.value ? this.props.overviewObj.credit_classification.value  : null,
      creditTypeName: this.props.overviewObj.credit_classification && this.props.overviewObj.credit_classification.label ? this.props.overviewObj.credit_classification.label  : null,
      lessee: this.props.overviewObj.lessee && this.props.overviewObj.lessee.id ? this.props.overviewObj.lessee.id   : null,
      lesseeName: this.props.overviewObj.lessee && this.props.overviewObj.lessee.value ? this.props.overviewObj.lessee.value  : null,
      operator: this.props.overviewObj.operator && this.props.overviewObj.operator.id ? this.props.overviewObj.operator.id   : null,
      operatorName: this.props.overviewObj.operator && this.props.overviewObj.operator.value ? this.props.overviewObj.operator.value  : null,
      ownership_type: this.props.overviewObj.ownership_type && this.props.overviewObj.ownership_type.value ? this.props.overviewObj.ownership_type.value  : 1,
      portfolio: this.props.overviewObj.portfolio ? this.props.overviewObj.portfolio : {},
      time_unit: this.props.overviewObj.time_unit ? this.props.overviewObj.time_unit : 1,
      off_lease_status: this.props.overviewObj.off_lease_status && this.props.overviewObj.off_lease_status ? this.props.overviewObj.off_lease_status.value : null,
      lessor_name_id: this.props.overviewObj.lessor_name && this.props.overviewObj.lessor_name.id ? this.props.overviewObj.lessor_name.id  : getLocalStorageInfo().defaultLessor.id,
      lessor_level: this.props.overviewObj.lessor_name && this.props.overviewObj.lessor_name.lessor_level ?this.props.overviewObj.lessor_name.lessor_level : 1 ,
      owner_id:this.props.overviewObj.owner && this.props.overviewObj.owner.id ? this.props.overviewObj.owner.id : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.id : '',
      owner_level: this.props.overviewObj.owner && this.props.overviewObj.owner.lessor_level? this.props.overviewObj.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
      delivered_basic_empty_weight_kgs: this.props.overviewObj.delivered_basic_empty_weight_kgs ?  this.props.overviewObj.delivered_basic_empty_weight_kgs : this.props.overviewObj.delivered_basic_empty_weight ? parseInt(this.props.overviewObj.delivered_basic_empty_weight/2.205) : null,
      delivered_max_zero_fuel_weight_kgs: this.props.overviewObj.delivered_max_zero_fuel_weight_kgs ?  this.props.overviewObj.delivered_max_zero_fuel_weight_kgs : this.props.overviewObj.delivered_max_zero_fuel_weight ? parseInt(this.props.overviewObj.delivered_max_zero_fuel_weight/2.205) : null,
      delivered_max_take_off_weight_kgs: this.props.overviewObj.delivered_max_take_off_weight_kgs ?  this.props.overviewObj.delivered_max_take_off_weight_kgs : this.props.overviewObj.delivered_max_take_off_weight ? parseInt(this.props.overviewObj.delivered_max_take_off_weight/2.205) : null,
      delivered_max_landing_weight_kgs: this.props.overviewObj.delivered_max_landing_weight_kgs ?  this.props.overviewObj.delivered_max_landing_weight_kgs : this.props.overviewObj.delivered_max_landing_weight ? parseInt(this.props.overviewObj.delivered_max_landing_weight/2.205) : null,
      delivered_max_taxi_weight_kgs: this.props.overviewObj.delivered_max_taxi_weight_kgs ?  this.props.overviewObj.delivered_max_taxi_weight_kgs : this.props.overviewObj.delivered_max_taxi_weight ? parseInt(this.props.overviewObj.delivered_max_taxi_weight/2.205) : null,
      purchase_basic_empty_weight_kgs: this.props.overviewObj.purchase_basic_empty_weight_kgs ?  this.props.overviewObj.purchase_basic_empty_weight_kgs : this.props.overviewObj.purchase_basic_empty_weight ? parseInt(this.props.overviewObj.purchase_basic_empty_weight/2.205) : null,
      purchase_max_zero_fuel_weight_kgs: this.props.overviewObj.purchase_max_zero_fuel_weight_kgs ?  this.props.overviewObj.purchase_max_zero_fuel_weight_kgs : this.props.overviewObj.purchase_max_zero_fuel_weight ? parseInt(this.props.overviewObj.purchase_max_zero_fuel_weight/2.205) : null,
      purchase_max_take_off_weight_kgs: this.props.overviewObj.purchase_max_take_off_weight_kgs ?  this.props.overviewObj.purchase_max_take_off_weight_kgs : this.props.overviewObj.purchase_max_take_off_weight ? parseInt(this.props.overviewObj.purchase_max_take_off_weight/2.205) : null,
      purchase_max_landing_weight_kgs: this.props.overviewObj.purchase_max_landing_weight_kgs ?  this.props.overviewObj.purchase_max_landing_weight_kgs : this.props.overviewObj.purchase_max_landing_weight ? parseInt(this.props.overviewObj.purchase_max_landing_weight/2.205) : null,
      purchase_max_taxi_weight_kgs: this.props.overviewObj.purchase_max_taxi_weight_kgs ?  this.props.overviewObj.purchase_max_taxi_weight_kgs : this.props.overviewObj.purchase_max_taxi_weight ? parseInt(this.props.overviewObj.purchase_max_taxi_weight/2.205) : null,
      operating_basic_empty_weight_kgs: this.props.overviewObj.operating_basic_empty_weight_kgs ?  this.props.overviewObj.operating_basic_empty_weight_kgs : this.props.overviewObj.operating_basic_empty_weight ? parseInt(this.props.overviewObj.operating_basic_empty_weight/2.205) : null,
      operating_max_zero_fuel_weight_kgs: this.props.overviewObj.operating_max_zero_fuel_weight_kgs ?  this.props.overviewObj.operating_max_zero_fuel_weight_kgs : this.props.overviewObj.operating_max_zero_fuel_weight ? parseInt(this.props.overviewObj.operating_max_zero_fuel_weight/2.205) : null,
      operating_max_take_off_weight_kgs: this.props.overviewObj.operating_max_take_off_weight_kgs ?  this.props.overviewObj.operating_max_take_off_weight_kgs : this.props.overviewObj.operating_max_take_off_weight ? parseInt(this.props.overviewObj.operating_max_take_off_weight/2.205) : null,
      operating_max_landing_weight_kgs: this.props.overviewObj.operating_max_landing_weight_kgs ?  this.props.overviewObj.operating_max_landing_weight_kgs : this.props.overviewObj.operating_max_landing_weight ? parseInt(this.props.overviewObj.operating_max_landing_weight/2.205) : null,
      operating_max_taxi_weight_kgs: this.props.overviewObj.operating_max_taxi_weight_kgs ?  this.props.overviewObj.operating_max_taxi_weight_kgs : this.props.overviewObj.operating_max_taxi_weight ? parseInt(this.props.overviewObj.operating_max_taxi_weight/2.205) : null,

    }
    // delivered_basic_operating_weight_kgs: this.props.overviewObj.delivered_basic_operating_weight_kgs ?  this.props.overviewObj.delivered_basic_operating_weight_kgs : this.props.overviewObj.delivered_basic_operating_weight ? parseInt(this.props.overviewObj.delivered_basic_operating_weight/2.205) : 0,
    this.setState({
      overview: data
    });
  }

  uploadCoverImage = (image) =>{
    this.setState({
      cover_photo: image
    })
  }

  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      overview: {
        ...prevState.overview,
        location : address
      }
    }));
  };

  handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          overview: {
            ...prevState.overview,
            location : address,
            latitude: latLng['lat'],
            longitude: latLng['lng'],
          }
      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );

          this.setState(prevState => ({
            ...prevState,
            overview: {
              ...prevState.overview,
                country: place.length ? place[0]['long_name']: address
              }
            }))
           })
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
            overview: {
              ...prevState.overview,
                country_code: place.length ? place[0]['short_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));
  };

  updateWeightForm = (key, value) => {
    if(value.indexOf('.') !== -1 && value.substring(value.indexOf('.') + 1).length > 3){
      return
    }
    if(key.includes('_kgs')){
      let lbKey = key.substr(0,key.indexOf('_kg'))
      this.setState(prevState => ({
        ...prevState,
        overview: {
          ...prevState.overview,
          [key]: value,
          [lbKey]:parseFloat(parseFloat(value * 2.205).toFixed(3))
        },
        error: {
          ...prevState.error,
          [key]: '',
          [lbKey]: '',
        }
      }))
    }else{
      let kgKey = key + '_kgs'
      this.setState(prevState => ({
        ...prevState,
        overview: {
          ...prevState.overview,
          [key]: value,
          [kgKey]: parseFloat(parseFloat(value/2.205).toFixed(3))
        },
        error: {
          ...prevState.error,
          [key]: '',
          [kgKey]: ''
        }
      }))
    }
  }

  updateForm = (type, value) => {
    if(type == 'status' && value !=2){
        this.setState(prevState => ({
          ...prevState,
          overview:{
            ...prevState.overview,
            off_lease_status:null
          }
        }))
    }
    if(type== "lessor_name_id"){
    let data= this.props.ownerList.find(item => item.id == value )
      this.setState(prevState => ({
        ...prevState,
        overview:{
          ...prevState.overview,
          lessor_name_id:data && data.id ? data.id :'',
          lessor_level:data && data.lessor_level ? data.lessor_level :''
        }
      }))
    }
    if(type== "owner_id"){
      let data= this.props.ownerList.find(item => item.id == value )
      this.setState(prevState => ({
        ...prevState,
        overview:{
          ...prevState.overview,
          owner_id:data && data.id ? data.id : '',
          owner_level:data && data.lessor_level ? data.lessor_level :''
        }
      }))
    }else {
      this.setState(prevState => ({
        ...prevState,
        overview: {
          ...prevState.overview,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    }
    if(type == 'aircraft_type'){
      this.props.fetchMaintenanceGrp(value);
    }else if(type == 'portfolio'){
      this.setState(prevState => ({
        ...prevState,
        overview: {
          ...prevState.overview,
          portfolio: typeof value == 'string' && value != '' ? {id: value, new: true} : {id: value},
        },
      }))
    }
  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          overview:{
            ...prevState.overview,
            lessee:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          overview:{
            ...prevState.overview,
            lessee:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        overview:{
          ...prevState.overview,
          lessee:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      overview:{
        ...prevState.overview,
        lessee:{
          ...prevState.overview.lessee,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          overview:{
            ...prevState.overview,
            lessee:{
              ...prevState.overview.lessee,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }
  editOverview = (e) => {
    e.preventDefault();
    var validateNewInput = {
      aircraft_type: checkString({value: this.state.overview.aircraft_type, required: true, minLength: '', maxLength: '', message: 'Please select Aircraft Type'}),
      time_unit: checkString({value: this.state.overview.time_unit, required: false, minLength: '', maxLength: '', message: 'Please select Aircraft Type'}),
      maintenance_event_program_group: checkString({value: this.state.overview.maintenance_event_program_group, required: false, minLength: '', maxLength: '', message: 'Please select Maintenance Program group'}),
      lessor_name_id: checkString({value: this.state.overview.lessor_name_id, required: parseInt(this.state.overview.status) !== 4, minLength: '', maxLength: '', message: 'Please Select Lessor Name'}),
      msn: checkString({value: this.state.overview.msn, required: true, minLength: '', maxLength: '', message: 'Please enter Aircraft Serial Number (MSN)'}),
      status: checkString({value: this.state.overview.status, required: true, minLength:'', maxLength:'', message:'Please Select Aircraft Lease Status'}),
      purchase_basic_empty_weight: checkFloat({value: this.state.overview.purchase_basic_empty_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_basic_empty_weight: checkFloat({value: this.state.overview.operating_basic_empty_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_landing_weight: checkFloat({value: this.state.overview.purchase_max_landing_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_landing_weight: checkFloat({value: this.state.overview.operating_max_landing_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_take_off_weight: checkFloat({value: this.state.overview.purchase_max_take_off_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_take_off_weight: checkFloat({value: this.state.overview.operating_max_take_off_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_taxi_weight: checkFloat({value: this.state.overview.purchase_max_taxi_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_taxi_weight: checkFloat({value: this.state.overview.operating_max_taxi_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_zero_fuel_weight: checkFloat({value: this.state.overview.purchase_max_zero_fuel_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_zero_fuel_weight: checkFloat({value: this.state.overview.operating_max_zero_fuel_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),

      purchase_basic_empty_weight_kgs: checkFloat({value: this.state.overview.purchase_basic_empty_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_basic_empty_weight_kgs: checkFloat({value: this.state.overview.operating_basic_empty_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_landing_weight_kgs: checkFloat({value: this.state.overview.purchase_max_landing_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_landing_weight_kgs: checkFloat({value: this.state.overview.operating_max_landing_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_take_off_weight_kgs: checkFloat({value: this.state.overview.purchase_max_take_off_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_take_off_weight_kgs: checkFloat({value: this.state.overview.operating_max_take_off_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_taxi_weight_kgs: checkFloat({value: this.state.overview.purchase_max_taxi_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_taxi_weight_kgs: checkFloat({value: this.state.overview.operating_max_taxi_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      purchase_max_zero_fuel_weight_kgs: checkFloat({value: this.state.overview.purchase_max_zero_fuel_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      operating_max_zero_fuel_weight_kgs: checkFloat({value: this.state.overview.operating_max_zero_fuel_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),

      delivered_basic_empty_weight: checkFloat({value: this.state.overview.delivered_basic_empty_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      deliveredg_basic_empty_weight_kgs: checkFloat({value: this.state.overview.delivered_basic_empty_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_landing_weight: checkFloat({value: this.state.overview.delivered_max_landing_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_landing_weight_kgs: checkFloat({value: this.state.overview.delivered_max_landing_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_take_off_weight: checkFloat({value: this.state.overview.delivered_max_take_off_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_take_off_weight_kgs: checkFloat({value: this.state.overview.delivered_max_take_off_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_taxi_weight: checkFloat({value: this.state.overview.delivered_max_taxi_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_taxi_weight_kgs: checkFloat({value: this.state.overview.delivered_max_taxi_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_zero_fuel_weight: checkFloat({value: this.state.overview.delivered_max_zero_fuel_weight, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),
      delivered_max_zero_fuel_weight_kgs: checkFloat({value: this.state.overview.delivered_max_zero_fuel_weight_kgs, required: false, minLength: '', maxLength: '', message: 'Please enter Weight in numbers'}),

      tsn: checkNumber({value: this.state.overview.tsn, required: false, minLength: '', maxLength: '', message: 'Please enter TSN in numbers'}),
      csn: checkNumber({value: this.state.overview.csn, required: false, minLength: '', maxLength: '', message: 'Please enter CSN in numbers'}),
      tslsv: checkNumber({value: this.state.overview.tslsv, required: false, minLength: '', maxLength: '', message: 'Please enter TSLSV in numbers'}),
      cslsv: checkNumber({value: this.state.overview.cslsv, required: false, minLength: '', maxLength: '', message: 'Please enter CSLSV in numbers'}),
      average_monthly_hours: checkFloat({value: this.state.overview.average_monthly_hours, required: false, minLength: '', maxLength: '', message: 'Please enter Avg. Monthly hours in numbers'}),
      average_monthly_cycles: checkNumber({value: this.state.overview.average_monthly_cycles, required: false, minLength: '', maxLength: '', message: 'Please enter Avg. Monthly cycles in numbers'}),
      credit_classification: checkString({value: this.state.overview.credit_classification, required: false, minLength: '', maxLength: '', message: 'Please Select Credit Classification'}),
      date_of_manufacture: checkValidDate({value: this.state.overview.date_of_manufacture, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
      last_utilization_updated:checkValidDate({value: this.state.overview.last_utilization_updated, required: false, minLength: '', maxLength: '', message: 'Please enter Last Utilization Updated'}),
    };

    if(this.state.overview.ownership_type == 2 && ( this.state.overview.portfolio == undefined || this.state.overview.portfolio == null || this.state.overview.portfolio.id == undefined || this.state.overview.portfolio.id == null || this.state.overview.portfolio.id == '' )){
      validateNewInput = {...validateNewInput, portfolio: 'Portfolio is required'}
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      let formData = new FormData()
      if(this.state.cover_photo){
        formData.append('file', this.state.cover_photo)
        globalPostService(`console/upload-asset-cover/aircraft/${this.props.params.aircraft_slug}/`, formData)
        .then(response => {
          if(response.data.statusCode == 200){
            this.updateForm('cover_photo', response.data.data.url)
            setTimeout(() => {
              this.props.toggleEditSideBar();
              this.props.editOverview({...this.state.overview, cover_photo: response.data.data.path});

            }, )
          }
        })
      }else{
        this.props.toggleEditSideBar();
        let payload = Object.assign({}, this.state.overview)
        if(this.state.overview.off_lease_status == "" || this.state.overview.off_lease_status == {}) {
          payload = {
            ...payload,
            off_lease_status:null
          }
        }
        this.props.editOverview(payload);

      }
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { overview, error, newLessee } = this.state;
    const userInfo = getLocalStorageInfo()
    const { type, aircraftTypes, sWeightUnit, maintenanceProgGrp, sAircarftOperatn, creditClassification, sLesseeList, sOperatorList,techConstants,ownerList } = this.props;
    return (
      <form onSubmit={(e) => this.editOverview(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
          <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Serial Number (MSN)
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={overview.msn}
                  type={type}
                  keyParam="msn"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.msn}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Aircraft Type
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Aircraft'
                  keyParam="aircraft_type"
                  value={overview.aircraft_type}
                  type={type}
                  options={aircraftTypes}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                  maintenanceProgGrp = {this.fetchMaintenanceGrp}
                />
                <h6 className="error-msg">{error.aircraft_type}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Aircraft Model
                </FieldLabel>
                <FieldInput
                  value={overview.model}
                  type={type}
                  keyParam="model"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Maintenance Program Group
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Maintenance Program Group'
                  keyParam="maintenance_event_program_group"
                  value={overview.maintenance_event_program_group}
                  type={type}
                  options={maintenanceProgGrp}
                  updateField={this.updateForm}
                  labelKey = "label"
                  valueKey = "value"
                />
                <h6 className="error-msg">{error.maintenance_event_program_group}</h6>

              </FieldGroup>
            </FieldCol>



            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Date of Manufacture
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldDate
                  keyParam="date_of_manufacture"
                  value={overview.date_of_manufacture}
                  type={type}
                  updateField={this.updateForm}
                  maxDate={moment()}
                  readOnly={true}
                />
               <h6 className="error-msg">{error.date_of_manufacture}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Date of Purchase
                </FieldLabel>
                <FieldDate
                  keyParam="purchase_date"
                  value={overview.purchase_date}
                  type={type}
                  updateField={this.updateForm}
                  maxDate={moment()}
                  readOnly={true}
                  minDate={moment(overview.date_of_manufacture)}
                />
              <h6 className="error-msg">{error.purchase_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Registration Number
                </FieldLabel>
                <FieldInput
                  value={overview.current_registration_number}
                  type={type}
                  keyParam="current_registration_number"
                  updateField={this.updateForm}
                />
               <h6 className="error-msg">{error.current_registration_number}</h6>
              </FieldGroup>
            </FieldCol>
            </Row>

            <Row>

            <FieldCol md="3">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Aircraft Operation
                </FieldLabel>
                <FieldSelect
                  placeholder='Select operation'
                  keyParam="aircraft_operation"
                  value={overview.aircraft_operation}
                  type={type}
                  options={sAircarftOperatn}
                  updateField={this.updateForm}
                  labelKey = "label"
                  valueKey = "value"
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="3">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Line Number
                </FieldLabel>
                <FieldInput
                  value={overview.line_number  ? overview.line_number : ''}
                  type={type}
                  keyParam="line_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="3">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Variable Number
                </FieldLabel>
                <FieldInput
                  value={overview.variable_number  ? overview.variable_number : ''}
                  type={type}
                  keyParam="variable_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="3">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  AOC Regulation
                </FieldLabel>
                <FieldInput
                  value={overview.aoc_regulation  ? overview.aoc_regulation : ''}
                  type={type}
                  keyParam="aoc_regulation"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                 Credit Classification
                 {/* { type != 'view' ? <sup className="reqStar"> * </sup>:null} */}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Credit Classification'
                  keyParam="credit_classification"
                  value={overview.credit_classification}
                  type={type}
                  options={creditClassification}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name={overview.creditTypeName}
                />
                <h6 className="error-msg">{error.credit_classification}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                 Country
                 {/* { type != 'view' ? <sup className="reqStar"> * </sup>:null} */}
                </FieldLabel>
                <FieldInput
                  value={overview.country  ? overview.country : ''}
                  type={type}
                  keyParam="country"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.country}</h6>
              </FieldGroup>
            </FieldCol>
            {
              parseInt(overview.status) !== 4 ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Lessor Name { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                  <InfoIcon id={"lessor_name"} />
                  <ToolTipHover placement="top" tagetId={"lessor_name"}>
                    Please specify the Lessor or Owner Entity Name. For example XYZ Leasing 123 Limited
                  </ToolTipHover>
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Lessor'
                    keyParam="lessor_name_id"
                    value={overview.lessor_name_id}
  									type={type}
                    options={ownerList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey="id"
                  />
                <h6 className="error-msg">{error.lessor_name_id}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }
            {
              parseInt(overview.status) !== 4 ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Owner
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Owner'
                    keyParam="owner_id"
                    value={overview.owner_id}
  									type={type}
                    options={ownerList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='id'
                  />
                </FieldGroup>
              </FieldCol>
              :null
            }
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Lease Status<sup className="reqStar"> * </sup>
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Status'
                  keyParam="status"
                  value={overview.status}
									type={type}
                  options={getLocalStorageInfo().defaultLessor.id === 348 ? [{label: 'Operational', value: '1'}, {label: 'AOG', value: '3'}] : [{label: 'On Lease', value: '1'}, {label: 'Off Lease', value: '2'}, {label: 'On Ground', value: '3'}, {label: 'Written Off / Sold', value: '4'}]}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={overview.position_name}
                />
              <h6 className="error-msg">{error.status}</h6>
              </FieldGroup>
            </FieldCol>
            {
              overview.status == 2 ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Off Lease Status
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Off Lease Status'
                    keyParam="off_lease_status"
                    value={overview.off_lease_status}
  									type={type}
                    options={techConstants.filter(item => item.type=="off_lease_status")}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                  />
                </FieldGroup>
              </FieldCol> : null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                      Lessee
                </FieldLabel>
                <Creatable
                  value={overview.lessee}
                  options={sLesseeList}
                  isClearable={true}
                  onChange={this.updateLesseeForm}
                  className="custom-select-block"
                  placeholder='Add or Select Lessee'
                  valueKey="id"
                  labelKey="name"
                  type={type}
                />
              </FieldGroup>
            </FieldCol>
            {
              newLessee ?
              <Fragment>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Contact Address
                    </FieldLabel>
                    <FieldInput
                      value={overview.lessee ? overview.lessee.contact_address:'' }
                      type={type}
                      keyParam="contact_address"
                      updateField={this.updateNewLessee}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Country
                    </FieldLabel>
                    <FieldInput
                      value={overview.lessee ? overview.lessee.country:'' }
                      type={type}
                      keyParam="country"
                      updateField={this.updateNewLessee}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                      Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                      <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                    </FieldLabel>
                  </FieldGroup>
                </FieldCol>
              </Fragment> : null
            }
            <Col md="12">
              <div className="form-group">
               <label className="label" style={{ cursor: 'pointer'}}>
                  <input type="checkbox"
                   value={overview.same_operator_lessee}
                   onChange = {(e) => this.updateForm('same_operator_lessee', e.target.checked)}
                   style={{ marginRight: '4px'}}
                   disabled= {overview.lessee ? false : true}
                   checked = {overview.same_operator_lessee}
                  />
                <span>Operator is same as Lessee</span>
               </label>
              </div>
            </Col>
            {
              !overview.same_operator_lessee ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Operator
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Operator'
                    keyParam="operator"
                    value={overview.operator}
                    type={type}
                    options={sOperatorList}
                    updateField={this.updateForm}
                    labelKey="name"
                    valueKey="id"
                    name={overview.operatorName}
                  />
                </FieldGroup>
              </FieldCol> : null
            }

            <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  First Delivery Date
                </FieldLabel>
                <FieldDate
                  keyParam="first_delivery_date"
                  value={overview.first_delivery_date}
                  type={type}
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  First Flight Date
                </FieldLabel>
                <FieldDate
                  keyParam="first_flight_date"
                  value={overview.first_flight_date}
                  type={type}
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Delivery Date to Current Operator
                </FieldLabel>
                <FieldDate
                  keyParam="delivery_date_to_current_operator"
                  value={overview.delivery_date_to_current_operator}
                  type={type}
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <div className="tech-spec-utilization-edit-block">
                <h6 style={{marginTop:'0'}}>Utilization Details</h6>
                <Row>
                  <FieldCol md="4">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Utilization as of date
                    </FieldLabel>
                    <FieldDate
                      keyParam="last_utilization_updated"
                      value={overview.last_utilization_updated}
                      type={type}
                      updateField={this.updateForm}
                      maxDate ={ moment()}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="4">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                     Utilization Unit Type
                    </FieldLabel>
                    <FieldSelect
                      placeholder='Select Unit Type'
                      keyParam="time_unit"
                      value={overview.time_unit}
                      type={type}
                      options = {this.props.techConstants.filter(item => item.type == 'utilization_unit')}
                      updateField={this.updateForm}
                      labelKey= 'label'
                      valueKey='value'
                    />
                  <h6 className="error-msg">{error.time_unit}</h6>
                  </FieldGroup>
                </FieldCol>
                </Row>
                <Row>
                  <FieldCol md="3">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        TSN
                      </FieldLabel>
                      <FieldInput
                        value={overview.tsn}
                        type={type}
                        keyParam="tsn"
                        updateField={this.updateForm}
                      />
                    <h6 className="error-msg">{error.tsn}</h6>
                    </FieldGroup>
                  </FieldCol>

                  <FieldCol md="3">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        CSN
                      </FieldLabel>
                      <FieldInput
                        value={overview.csn}
                        type={type}
                        keyParam="csn"
                        updateField={this.updateForm}
                      />
                    <h6 className="error-msg">{error.csn}</h6>
                    </FieldGroup>
                  </FieldCol>

                  <FieldCol md="3">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                      Avg. Monthly Hours
                      </FieldLabel>
                      <FieldInput
                        value={overview.average_monthly_hours}
                        type={type}
                        keyParam="average_monthly_hours"
                        updateField={this.updateForm}
                      />
                    <h6 className="error-msg">{error.average_monthly_hours}</h6>
                    </FieldGroup>
                  </FieldCol>

                  <FieldCol md="3">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                       Avg. Monthly Cycles
                      </FieldLabel>
                      <FieldInput
                        value={overview.average_monthly_cycles}
                        type={type}
                        keyParam="average_monthly_cycles"
                        updateField={this.updateForm}
                      />
                    <h6 className="error-msg">{error.average_monthly_cycles}</h6>
                    </FieldGroup>
                  </FieldCol>
                </Row>
              </div>
            </FieldCol>
              <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    ETOPS (Extended Range Twin Engine Operations)
                    <i class="fa fa-info-circle" aria-hidden="true" id="ETOPS1" style={{paddingLeft: '3px'}}></i>
                    <UncontrolledTooltip placement="right" target="ETOPS1">
                    Specify "Yes" or "No"
                    </UncontrolledTooltip>
                  </FieldLabel>
                  <FieldInput
                    value={overview.etops  ? overview.etops : ''}
                    type={type}
                    keyParam="etops"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  ETOPS Aircraft Model Certification
                  <i class="fa fa-info-circle" aria-hidden="true" id="ETOPS2" style={{paddingLeft: '3px'}}></i>
                  <UncontrolledTooltip placement="right" target="ETOPS2">
                    Specify the Certification Standard For eg 60 Min or 120 Min or 180 min
                  </UncontrolledTooltip>
                </FieldLabel>
                <FieldInput
                  value={overview.etops_aircraft_model_certification  ? overview.etops_aircraft_model_certification : ''}
                  type={type}
                  keyParam="etops_aircraft_model_certification"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  ETOPS Current Config Capacity
                  <i class="fa fa-info-circle" aria-hidden="true" id="ETOPS3" style={{paddingLeft: '3px'}}></i>
                  <UncontrolledTooltip placement="right" target="ETOPS3">
                  Specify the Current Operation Standard For eg 60 Min or 120 Min or 180 min<br/>
                  Note: Certification and current configuration can be different.
                  </UncontrolledTooltip>
                </FieldLabel>
                <FieldInput
                  value={overview.etops_current_config_capacity_cmp_revision  ? overview.etops_current_config_capacity_cmp_revision : ''}
                  type={type}
                  keyParam="etops_current_config_capacity_cmp_revision"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  ETOPS Operational Standard
                </FieldLabel>
                <FieldInput
                  value={overview.etops_operational_standard  ? overview.etops_operational_standard : ''}
                  type={type}
                  keyParam="etops_operational_standard"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Fuel Capacity (Ltr)
                  </FieldLabel>
                  <FieldInput
                    keyParam="fuel_capacity"
                    value={overview.fuel_capacity}
                    type={type}
                    updateField={(key, value) => {this.updateForm(key, value);this.updateForm('fuel_capacity_gal', parseFloat(parseFloat(value * 0.2642).toFixed(3)))}}
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md="4" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Fuel Capacity (Gallons)
                  </FieldLabel>
                  <FieldInput
                    keyParam="fuel_capacity_gal"
                    value={overview.fuel_capacity_gal  ? overview.fuel_capacity_gal : overview.fuel_capacity ? parseFloat(overview.fuel_capacity * 0.2642).toFixed(3) : ''}
                    type={type}
                    updateField={(key, value) => {this.updateForm(key, value);this.updateForm('fuel_capacity', parseFloat(parseFloat(value * 3.78541).toFixed(3)))}}
                  />
                </FieldGroup>
              </FieldCol>


              <FieldCol md="4" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Fuel Tank Inerting System
                  </FieldLabel>
                  <FieldInput
                    keyParam="ftis"
                    value={overview.ftis}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md="4" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Fuel Density
                  </FieldLabel>
                  <FieldInput
                    keyParam="fuel_capacity_density"
                    value={overview.fuel_capacity_density}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md="6" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Additional Center Tanks
                  </FieldLabel>
                  <FieldInput
                    keyParam="additional_center_tanks"
                    value={overview.additional_center_tanks}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="6" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    ACT Provisions
                    <i class="fa fa-info-circle" aria-hidden="true" id="ACT" style={{paddingLeft: '3px'}}></i>
                   <UncontrolledTooltip placement="right" target="ACT">
                    Is asset equipped with ACT Provisions - Yes / No
                    </UncontrolledTooltip>
                  </FieldLabel>
                  <FieldInput
                    keyParam="act_provisions"
                    value={overview.act_provisions}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    RVSM (Reduced Vertical Separation Minimum)
                    <i class="fa fa-info-circle" aria-hidden="true" id="RVSM" style={{paddingLeft: '3px'}}></i>
                    <UncontrolledTooltip placement="right" target="RVSM">
                      Specify "Complied" or "Not Complied"
                    </UncontrolledTooltip>
                  </FieldLabel>
                  <FieldInput
                    value={overview.rvsm  ? overview.rvsm : ''}
                    type={type}
                    keyParam="rvsm"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
            <FieldCol md="6" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Landing Capability
                </FieldLabel>
                <FieldInput
                  value={overview.landing_capability  ? overview.landing_capability : ''}
                  type={type}
                  keyParam="landing_capability"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Cockpit Door Surveillance System
                  </FieldLabel>
                  <FieldInput
                    keyParam="cdss"
                    value={overview.cdss}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>

          </Row>
          <Row>




            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Location</FieldLabel>
                  {
                    window.google !== undefined && window.google !== null ?
                    <PlacesAutocomplete
                      value={overview.location}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                    >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{position: 'relative'}}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Location',
                            className: 'location-search-input',
                          })}
                        />
                        {
                          overview.location ?
                          <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div> :  null
                        }
                      </div>
                    )}
                  </PlacesAutocomplete>
                    :<FieldInput
                      value={overview.location }
                      type={type}
                      keyParam="location"
                      updateField={this.updateForm}
                    />
                  }
              </FieldGroup>
            </FieldCol>

            {
              userInfo.defaultLessor.lessor_type == 1 ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Ownership</FieldLabel>
                  <div>
                    <label className="label" style={{ cursor: 'pointer', marginRight: '10px'}}>
                       <input type="radio"
                        value={overview.ownership_type}
                        onChange = {(e) => this.updateForm('ownership_type', 1)}
                        style={{ marginRight: '4px'}}
                        checked = {overview.ownership_type === 1 ? true : false}
                       />
                     <span>Owned</span>
                    </label>
                    <label className="label" style={{ cursor: 'pointer'}}>
                       <input type="radio"
                        value={overview.ownership_type}
                        onChange = {(e) => this.updateForm('ownership_type', 2)}
                        style={{ marginRight: '4px'}}
                        checked = {overview.ownership_type === 2 ? true : false}
                       />
                     <span>Managed</span>
                    </label>
                  </div>
                </FieldGroup>
              </FieldCol>
              :null
            }
            {
              overview.ownership_type == 2 ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Portfolio { type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                    <FieldSelect
                      placeholder='Create/Select Portfolio'
                      keyParam="portfolio"
                      value={overview.portfolio ? overview.portfolio.id : ''}
                      type={type}
                      options = {this.props.portfolioList}
                      updateField={this.updateForm}
                      labelKey= 'name'
                      valueKey='id'
                      creatable={true}
                    />
                  <h6 className="error-msg">{error.portfolio}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }
          </Row>
          <div className="table-block tech-spec-table-block">
            <Row>
              <FieldCol md="4" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Date Last Weighing
                  </FieldLabel>
                  <FieldDate
                    keyParam="date_last_weighing"
                    value={overview.date_last_weighing}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="4" >
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Weight Variant
                  </FieldLabel>
                  <FieldInput
                    keyParam="weight_variant"
                    value={overview.weight_variant}
                    type={type}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
            </Row>

          <Table>
            <thead>
                <tr>
                  <th style={{width: '16%'}}></th>
                  <th style={{width: '28%'}}>
                    Purchased
                    <table style={{marginBottom: '0px', marginTop: '10px'}}>
                      <thead>
                        <tr>
                          <th style={{width: '50%'}}>Lbs</th>
                          <th>Kgs</th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                  <th style={{width: '28%'}}>
                    Delivered
                    <table style={{marginBottom: '0px', marginTop: '10px'}}>
                      <thead>
                        <tr>
                          <th style={{width: '50%'}}>Lbs</th>
                          <th>Kgs</th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                  <th style={{width: '28%'}}>
                    Current
                    <table style={{marginBottom: '0px', marginTop: '10px'}}>
                      <thead>
                        <tr>
                          <th style={{width: '50%'}}>Lbs</th>
                          <th>Kgs</th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"> Basic Empty Weight</th>
                  <td style={{padding: '0px'}}>
                    <table style={{marginBottom: '0px'}}>
                      <tr>
                        <td>
                          <FieldInput
                            value={overview.purchase_basic_empty_weight  ? overview.purchase_basic_empty_weight : null}
                            type={type}
                            keyParam="purchase_basic_empty_weight"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_basic_empty_weight}</h6>
                        </td>
                        <td>
                          <FieldInput
                            value={overview.purchase_basic_empty_weight_kgs  ? overview.purchase_basic_empty_weight_kgs : null}
                            type={type}
                            keyParam="purchase_basic_empty_weight_kgs"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_basic_empty_weight_kgs}</h6>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.delivered_basic_empty_weight  ? overview.delivered_basic_empty_weight : null}
                          type={type}
                          keyParam="delivered_basic_empty_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_basic_empty_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.delivered_basic_empty_weight_kgs  ? overview.delivered_basic_empty_weight_kgs : null}
                          type={type}
                          keyParam="delivered_basic_empty_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_basic_empty_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.operating_basic_empty_weight  ? overview.operating_basic_empty_weight : null}
                          type={type}
                          keyParam="operating_basic_empty_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_basic_empty_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.operating_basic_empty_weight_kgs  ? overview.operating_basic_empty_weight_kgs : null}
                          type={type}
                          keyParam="operating_basic_empty_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_basic_empty_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> Max Landing Weight	</th>
                  <td style={{padding: '0px'}}>
                    <table style={{marginBottom: '0px'}}>
                      <tr>
                        <td>
                          <FieldInput
                            value={overview.purchase_max_landing_weight  ? overview.purchase_max_landing_weight : null}
                            type={type}
                            keyParam="purchase_max_landing_weight"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_max_landing_weight}</h6>
                        </td>
                        <td>
                          <FieldInput
                            value={overview.purchase_max_landing_weight_kgs  ? overview.purchase_max_landing_weight_kgs : null}
                            type={type}
                            keyParam="purchase_max_landing_weight_kgs"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_max_landing_weight_kgs}</h6>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.delivered_max_landing_weight  ? overview.delivered_max_landing_weight : null}
                          type={type}
                          keyParam="delivered_max_landing_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_max_landing_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.delivered_max_landing_weight_kgs  ? overview.delivered_max_landing_weight_kgs : null}
                          type={type}
                          keyParam="delivered_max_landing_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_max_landing_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.operating_max_landing_weight  ? overview.operating_max_landing_weight : null}
                          type={type}
                          keyParam="operating_max_landing_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_max_landing_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.operating_max_landing_weight_kgs  ? overview.operating_max_landing_weight_kgs : null}
                          type={type}
                          keyParam="operating_max_landing_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_max_landing_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                </tr>
                <tr>
                  <th scope="row"> Max Take Off Weight</th>
                  <td style={{padding: '0px'}}>
                    <table style={{marginBottom: '0px'}}>
                      <tr>
                        <td>
                          <FieldInput
                            value={overview.purchase_max_take_off_weight  ? overview.purchase_max_take_off_weight : null}
                            type={type}
                            keyParam="purchase_max_take_off_weight"
                            style={{textAlign: 'center'}}
                            updateField={this.updateWeightForm}
                          />
                           <h6 className="error-msg">{error.purchase_max_take_off_weight}</h6>
                        </td>
                        <td>
                          <FieldInput
                            value={overview.purchase_max_take_off_weight_kgs  ? overview.purchase_max_take_off_weight_kgs : null}
                            type={type}
                            keyParam="purchase_max_take_off_weight_kgs"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_max_take_off_weight_kgs}</h6>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.delivered_max_take_off_weight  ? overview.delivered_max_take_off_weight : null}
                          type={type}
                          keyParam="delivered_max_take_off_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_max_take_off_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.delivered_max_take_off_weight_kgs  ? overview.delivered_max_take_off_weight_kgs : null}
                          type={type}
                          keyParam="delivered_max_take_off_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_max_take_off_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.operating_max_take_off_weight  ? overview.operating_max_take_off_weight : null}
                          type={type}
                          keyParam="operating_max_take_off_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_max_take_off_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.operating_max_take_off_weight_kgs  ? overview.operating_max_take_off_weight_kgs : null}
                          type={type}
                          keyParam="operating_max_take_off_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_max_take_off_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Max Taxi Weight</th>
                  <td style={{padding: '0px'}}>
                    <table style={{marginBottom: '0px'}}>
                      <tr>
                        <td>
                          <FieldInput
                            value={overview.purchase_max_taxi_weight  ? overview.purchase_max_taxi_weight : null}
                            type={type}
                            keyParam="purchase_max_taxi_weight"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_max_taxi_weight}</h6>
                        </td>
                        <td>
                          <FieldInput
                            value={overview.purchase_max_taxi_weight_kgs  ? overview.purchase_max_taxi_weight_kgs : null}
                            type={type}
                            keyParam="purchase_max_taxi_weight_kgs"
                            updateField={this.updateWeightForm}
                            style={{textAlign: 'center'}}
                          />
                           <h6 className="error-msg">{error.purchase_max_taxi_weight_kgs}</h6>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.delivered_max_taxi_weight  ? overview.delivered_max_taxi_weight : null}
                          type={type}
                          keyParam="delivered_max_taxi_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_max_taxi_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.delivered_max_taxi_weight_kgs  ? overview.delivered_max_taxi_weight_kgs : null}
                          type={type}
                          keyParam="delivered_max_taxi_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.delivered_max_taxi_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                  <td style={{padding: '0px'}}>
                  <table style={{marginBottom: '0px'}}>
                    <tr>
                      <td>
                        <FieldInput
                          value={overview.operating_max_taxi_weight  ? overview.operating_max_taxi_weight : null}
                          type={type}
                          keyParam="operating_max_taxi_weight"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_max_taxi_weight}</h6>
                      </td>
                      <td>
                        <FieldInput
                          value={overview.operating_max_taxi_weight_kgs  ? overview.operating_max_taxi_weight_kgs : null}
                          type={type}
                          keyParam="operating_max_taxi_weight_kgs"
                          updateField={this.updateWeightForm}
                          style={{textAlign: 'center'}}
                        />
                         <h6 className="error-msg">{error.operating_max_taxi_weight_kgs}</h6>
                      </td>
                    </tr>
                  </table>
                  </td>
                </tr>
                <tr>
              <th colSpan="1" scope="row">Max Zero Fuel Weight</th>
              <td style={{padding: '0px'}}>
                <table style={{marginBottom: '0px'}}>
                  <tr>
                    <td>
                      <FieldInput
                        value={overview.purchase_max_zero_fuel_weight  ? overview.purchase_max_zero_fuel_weight : null}
                        type={type}
                        keyParam="purchase_max_zero_fuel_weight"
                        updateField={this.updateWeightForm}
                        style={{textAlign: 'center'}}
                      />
                       <h6 className="error-msg">{error.purchase_max_zero_fuel_weight}</h6>
                    </td>
                    <td>
                      <FieldInput
                        value={overview.purchase_max_zero_fuel_weight_kgs  ? overview.purchase_max_zero_fuel_weight_kgs : null}
                        type={type}
                        keyParam="purchase_max_zero_fuel_weight_kgs"
                        updateField={this.updateWeightForm}
                        style={{textAlign: 'center'}}
                      />
                       <h6 className="error-msg">{error.purchase_max_zero_fuel_weight_kgs}</h6>
                    </td>
                  </tr>
                </table>
              </td>
              <td style={{padding: '0px'}}>
              <table style={{marginBottom: '0px'}}>
                <tr>
                  <td>
                    <FieldInput
                      value={overview.delivered_max_zero_fuel_weight  ? overview.delivered_max_zero_fuel_weight : null}
                      type={type}
                      keyParam="delivered_max_zero_fuel_weight"
                      updateField={this.updateWeightForm}
                      style={{textAlign: 'center'}}
                    />
                     <h6 className="error-msg">{error.delivered_max_zero_fuel_weight}</h6>
                  </td>
                  <td>
                    <FieldInput
                      value={overview.delivered_max_zero_fuel_weight_kgs  ? overview.delivered_max_zero_fuel_weight_kgs : null}
                      type={type}
                      keyParam="delivered_max_zero_fuel_weight_kgs"
                      updateField={this.updateWeightForm}
                      style={{textAlign: 'center'}}
                    />
                     <h6 className="error-msg">{error.delivered_max_zero_fuel_weight_kgs}</h6>
                  </td>
                </tr>
              </table>
              </td>
              <td style={{padding: '0px'}}>
              <table style={{marginBottom: '0px'}}>
                <tr>
                  <td>
                    <FieldInput
                      value={overview.operating_max_zero_fuel_weight  ? overview.operating_max_zero_fuel_weight : null}
                      type={type}
                      keyParam="operating_max_zero_fuel_weight"
                      updateField={this.updateWeightForm}
                      style={{textAlign: 'center'}}
                    />
                     <h6 className="error-msg">{error.operating_max_zero_fuel_weight}</h6>
                  </td>
                  <td>
                    <FieldInput
                      value={overview.operating_max_zero_fuel_weight_kgs  ? overview.operating_max_zero_fuel_weight_kgs : null}
                      type={type}
                      keyParam="operating_max_zero_fuel_weight_kgs"
                      updateField={this.updateWeightForm}
                      style={{textAlign: 'center'}}
                    />
                     <h6 className="error-msg">{error.operating_max_zero_fuel_weight_kgs}</h6>
                  </td>
                </tr>
              </table>
              </td>
            </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
     </form>
   )
 }
}

export default withRouter(EditGenInfo)
