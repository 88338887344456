import React, { Component } from 'react';
import { Link } from 'react-router';
import { localTimeFn } from '../../../../utils';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
export default class ExtractPointInfo extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { dataPoint, dataIds, issueContent, fileType } = this.props;
    return(
      <div className="flex-not-centered log-detail-blk lg-status-action">
        <div className="custom-width" onClick={dataPoint.status !=0 ? (e) => e.preventDefault(): this.props.updateIds}>
          { dataPoint.status == 0 ?
            <div className="checkbox-blk">
              <input checked={dataIds.includes(dataPoint.id)} type="checkbox"/>
            </div>
            :null
          }
          <h6>
            {dataPoint.label}
            { dataPoint.page_number ?
              <InfoIcon id={"data-point-"+dataPoint.id} />:null
            }
            { dataPoint.page_number ?
              <ToolTipHover placement="top" tagetId={"data-point-"+dataPoint.id}>
                {dataPoint.page_number ? 'Data available in page no. ' + dataPoint.page_number + ' ':''} {dataPoint.position ? 'at ' + dataPoint.position + ' position':''}
              </ToolTipHover>:null
            }
          </h6>
          <p>{dataPoint.value}</p>
        </div>
        { !issueContent ?
          dataPoint.status == 0 ?
          <div className="action-block">
            <Link style={{display: 'none'}} className="ignore-log caption-ui" onClick={() => this.props.changeStatus('3', [dataPoint.id])}>Ignore</Link>
            <Link className="reject-log caption-ui" onClick={() => this.props.changeStatus('2', [dataPoint.id])}>Reject</Link>
            <Link className="approve-log caption-ui" onClick={() => this.props.changeStatus('1', [dataPoint.id])}>Approve</Link>
            <Link className="caption-ui" onClick={this.props.toggleEditSideBar} style={{color: '#2f8cff'}}>Edit</Link>
          </div>:
          dataPoint.status == 1 ?
          <div className="action-block">
            { dataPoint.applied ?
              <span className="applied-btn" style={{marginRight: '0'}}> { 'Applied on ' + localTimeFn(dataPoint.applied)  } </span> :
                dataPoint.remarks ? <span style={{ fontSize: '10px', color: '#ff0000'}}> {dataPoint.remarks} </span>  :
              <span className="applied-btn">
                <img src={imgStoragePath+"green-tick.png"} id={"approved-"+dataPoint.id} width="20" alt="img"/>
                <ToolTipHover placement="top" tagetId={"approved-"+dataPoint.id}>
                  Approved
                </ToolTipHover>
              </span>
            }
          </div>
          :dataPoint.status == 2 || dataPoint.status == 3 ?
          <div className="action-block">
            <img src={imgStoragePath+"cross_red.png"} id={"rejected-"+dataPoint.id} width="20" alt="img"/>
            <ToolTipHover placement="top" tagetId={"rejected-"+dataPoint.id}>
              Rejected
            </ToolTipHover>
          </div>:
          dataPoint.status == 3 ?
          <div className="action-block" style={{display: 'none'}}>
            <img src={imgStoragePath+"warning.png"} id={"ignored-"+dataPoint.id} width="20" alt="img"/>
            <ToolTipHover placement="top" tagetId={"ignored-"+dataPoint.id}>
              Ignored
            </ToolTipHover>
          </div>: null
            : null
        }

      </div>
    )
  }
}
