import * as actions from '../actions';
import { globalGetService, globalPostService, globalFileUploadService, globalDeleteService, globalPutService, globalExportService } from '../../../../globalServices/';
import { toastFlashMessage, downloadFileType } from '../../../../utils';
import { browserHistory } from 'react-router';
export const uploadADFileAc = (params, files) => {
  return (dispatch) => {
    if(files.length > 1){
      toastFlashMessage('You can only upload one file at a time', 'error')
      dispatch({
        type: actions.AD_FILES_MODAL,
        payload: false
      });
      return
    }
    const formData = new FormData();
    formData.append('aircraft_slug', params.aircraft_slug);
    files.map((file, index) => {
      formData.append('technical_auto_filler_file', file);
    });

  dispatch({
    type: actions.UPDATE_AD_LOADER,
    payload: true
  });
  globalFileUploadService('/ml/upload-ml-files/',  formData)
  .then(response => {
      dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: false
      });
      if(response.data.statusCode == 200){
        dispatch(adLogsListAc(params));
        dispatch({
          type: actions.AD_FILES_MODAL,
          payload: false
        });
      }
    })

  }
}
export const adLogsListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    globalGetService('/ml/console/'+params.aircraft_slug+'/ml-tech-auto-filler-files/', {})
    .then(response => {
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AD_LOGS_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const deleteAdLogsAc = (params, id) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    globalDeleteService('/ml/console/'+params.aircraft_slug+'/ml-tech-auto-filler-files/'+id+'/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(adLogsListAc(params));
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
export const adDataPointDetailAc = (props) => {
  return(dispatch) => {
    globalGetService('/ml/ad-files/'+props.params.id+'/details/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AD_POINTS_INFO,
          payload: response.data.data
        })
      }
    })
  }
}

export const linkFilesToT011Ac = (props) => {
  return(dispatch) => {
    dispatch({
      type: actions.AD_LINK_LOADER,
      payload: true
    })
    globalPostService(`/ml/ad-files/${props.params.id}/link-gap-report/`, {})
    .then(response => {
      dispatch({
        type: actions.AD_LINK_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.UPDATE_AD_LINK,
          payload: response.data.data
        })

      }
    })
  }
}

export const adDataPointsListAc = (props, filter={}, type='') => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    let filterObj = Object.assign({}, filter);
    if(filterObj.issue_date){
      let issueDate = filter.issue_date.split(',');
      delete filterObj['issue_date'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            issue_start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            issue_end_date:date
          }
        }
      })
    }
    if(filterObj.publish_date){
      let publishDate = filter.publish_date.split(',');
      delete filterObj['publish_date'];
      publishDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            publish_start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            publish_end_date:date
          }
        }
      })
    }
    globalGetService('/ml/ad-files/'+props.params.id+'/ad-points/', filterObj)
    .then(response => {
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type :actions.AD_DATA_POINTS,
          filter: filter,
          payload: response.data.data
        })
      }
    })
  }
}
export const uploadOEMFileAc = (params, files) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append('aircraft_slug', params.aircraft_slug);
    files.map((file, index) => {
      formData.append(file.fileType, file.file);
    });

    var config = {
      onUploadProgress: function(progressEvent) {
          var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        }
    };

  dispatch({
    type: actions.UPDATE_AD_LOADER,
    payload: true
  });
  globalFileUploadService('ml/upload-oem-files/'+params.id+'/',  formData, config)
  .then(response => {
      dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: false
      });
      if(response.data.statusCode == 200){
        dispatch(adLogsListAc(params));
        dispatch({
          type: actions.AD_FILES_MODAL,
          payload: false
        });
        browserHistory.push('/aircraft/'+params.aircraft_slug+'/airworthiness-list');
      }
    })

  }
}

export const easaFaaExportAc = (props, query={}, fileName) => {

  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    let url = props.params.id ? 'ml/ad-files/'+props.params.id+'/ad-points/' : 'ml/console/PZXmfh/ad-model-points/';
    globalExportService(url, query)
    .then(response => {
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
      downloadFileType(response, fileName+'.', query.download)
    })
  }
}


export const getTechSpecsDataPointsAc = (params) => {

  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    globalGetService(`/ml/file/${params.id}/data-points/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHSPECS_DATA_POINTS_PLOT,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
    })
  }
}
export const fetchEasaListAc = (props, filter={}) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    let filterObj = Object.assign({}, filter);
    if(filterObj.issue_date){
      let issueDate = filter.issue_date.split(',');
      delete filterObj['issue_date'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            issue_start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            issue_end_date:date
          }
        }
      })
    }
    if(filterObj.publish_date){
      let publishDate = filter.publish_date.split(',');
      delete filterObj['publish_date'];
      publishDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            publish_start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            publish_end_date:date
          }
        }
      })
    }
    globalGetService('ml/console/'+props.params.aircraft_slug+'/ad-model-points/', filterObj)
    .then(response => {
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.EASA_DATA_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const dataPointToggleStatusAc = (params, type, ids) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    globalPostService('ml/file/' + params.id + '/data-point/status/' , {data_point_id: ids, status: type})
    .then(response => {

      if(response.data.statusCode == 200 ){
        toastFlashMessage(response.data.message, 'success');
        dispatch(getTechSpecsDataPointsAc(params))
        dispatch({
          type: actions.UPDATE_AD_LOADER,
          payload: false
        });
      }
    })
  }
}

export const editDataPointAc = (params, id, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    globalPostService('/ml/file/'+id+'/data-point/update/', {data_point_id:data.id, value: (data.options ? data.option_value : data.value) })
    .then(response => {
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
      dispatch({
        type: actions.TOGGLE_DATA_EXT_BAR,
        payload: {flag: false, data: {}, type: 'edit'}
      });
      dispatch(getTechSpecsDataPointsAc(params))
      toastFlashMessage(response.data.message, 'success');
    })
  }
}

export const submitDataPointAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_AD_LOADER,
      payload: true
    });
    globalGetService('ml/file/'+params.id+'/data-points/apply/',{})
    .then(response => {
      dispatch({
        type: actions.UPDATE_AD_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        browserHistory.push('/'+params.type+'/'+params.aircraft_slug+'/techspecs-logs/')
      }
    })
  }
}
