import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Col } from 'reactstrap';
import { EditFormBar, DropFiles, CollapseWrapper, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, DownloadedLogs } from '../../../Elements';
import { AuditCheckList, LinkRecords }  from '../components';
import { fetchT003DataAc, auditCheckUpdateAnsAc, fetchAuditDocsListAc, updateAuditDocsListAc } from '../actionCreators';
import { STATUS_UPDATE, LINKED_DOCS_EMPTY } from '../actions';
import { MSNSwitcherHeader, ListLoader,ExportFiles, NoRecordFound, LicenceModal } from '../../../../../shared';
import {downLoadTechFormAc} from '../../../../../shared/actionCreators'
import { noteFormsText } from '../../../../../constants';
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
import { BUY_LICENCE } from '../../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../../utils'

class InspectionFormT003 extends Component {
  constructor(props){
    super(props);
    this.state = {
      editSideBar: false,
      editSideBarTitle: '',
      regionOpen: false,
      childeIndex: '',
      index: '',
      questionIndex: '',
      questionId: '',
      asnData: {},
      modal: false,
      downloadFormLogs: false,
      statusModal:false
    };
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount(){
    this.props.fetchT003Data();
    this.props.getFormDetails(1, 'T003');
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
  }

  auditCheckUpdateAns = (index, childeIndex, answer, crudType, questionIndex, questionId, files, existFileArr) =>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.auditCheckUpdateAns(index, childeIndex, answer, crudType, questionIndex, questionId, files, existFileArr)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleModal=()=> {
    this.setState({
      statusModal: !this.state.statusModal
    });
  }

  updateFormStatus=(data)=>{
    if(data.status==7){
      this.toggleModal();
    }else{
      this.props.updateFormStatus(data);
    }
  }

  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggleEditSideBar = (title = '', questionId = '',  index = '', childeIndex = '', questionIndex = '', asnData = {}) => {
    if(questionId){
      this.setState(prevState =>({
        ...prevState,
        editSideBarTitle: title,
        index: index,
        childeIndex: childeIndex,
        questionIndex: questionIndex,
        questionId:questionId,
        asnData: asnData
      }));
      this.props.fetchAuditDocsList(questionId);
    }else{
      this.setState(prevState =>({
        ...prevState,
        editSideBarTitle: '',
        index: '',
        childeIndex: '',
        questionIndex: '',
        questionId:'',
        asnData: []
      }));
      this.props.emptyLinkedDocs();
    }
  }
  render(){
    const { formT003FormDataList, auditLoader, auditLinkrecords, techExportLoader, formStatusLoader, editSideBar, formInfo } = this.props;
    return(
      <div className="technical-inspection-cn">
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('document-sections','MSN_'+formInfo.asset.msn+'_'+formInfo.current_form.name,fileType)}
          exportName={'Export Report '}
          files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}, {img: 'excel_icon.png', label: 'Excel', type: 'xls'}]} />}
          updateFormStatus={(data) => this.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
        />
        { auditLoader || techExportLoader || formStatusLoader ? <ListLoader drawer="primary-nav"/> : null}
        <div className="form-t003-cn">
        <Col sm="12">
            <p className="notice-info" style={{margin:'10px -15px'}}>{noteFormsText}</p>
         </Col>
          {formT003FormDataList.map((auditSection, index) => {
            return(
              <div key={index}>
                <h2>{auditSection.name}</h2>
                {auditSection.sub_sections.map((section,childeIndex) => {
                  return (
                    <CollapseWrapper key={childeIndex} index={childeIndex} section={section} subSection={auditSection.sub_section_series} >
                      <table style={{width: '100%'}}>
                        <thead>
                          <tr>
                            { ['Document Name', 'Status', 'Remarks', ''].map((hd,hdIndex) =>
                              <th key={hdIndex}>{hd}</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {section.questions.map((list, listIndex) =>
                            <AuditCheckList
                             listindex={listIndex}
                              key={listIndex}
                              list={list}
                              id = {listIndex}
                              section={section}
                              toggleEditSideBar={() => this.toggleEditSideBar(list.name, formT003FormDataList[index]['sub_sections'][childeIndex]['questions'][listIndex]['id'], index, childeIndex, listIndex)}
                              changeRemark = {(e) => this.props.changeRemark(index, childeIndex, listIndex, 'remarks', e )}
                              changeStatus = {(e) => this.props.changeStatus( index, childeIndex, listIndex, 'value', e )}
                              saveData = {(crudType, questionIndex, questionId, file, existFileArr) => this.auditCheckUpdateAns(index, childeIndex, formT003FormDataList[index]['sub_sections'][childeIndex]['questions'][listIndex]['answer'], crudType, questionIndex, questionId, file, existFileArr) }
                              auditLoader = {auditLoader}
                              deleteFiles = {(e) => this.props.deleteFiles( index, childeIndex, listIndex, 'files', e )}
                              formInfo={formInfo}
                            />
                          )}
                        </tbody>
                      </table>
                    </CollapseWrapper>
                  )
                })}
            </div>
            )
          })}
          { !auditLoader && !formT003FormDataList.length ?<NoRecordFound/>:  null }
        </div>

        <EditFormBar
          title={this.state.editSideBarTitle}
          titleContent={ <div style={{marginRight: '15px'}}><h3>{this.state.editSideBarTitle}</h3></div>}
          toggleEditSideBar={this.toggleEditSideBar}
          isOpen={editSideBar}
          path={auditLinkrecords.path}
          // isOpen = {this.props.auditLinkrecords.flag}
          style={editSideBar ? {width: '600px'} : {width: '0px'}}

        >
        {
          editSideBar && Object.keys(auditLinkrecords).length ?
          <LinkRecords
             auditLinkrecords= {auditLinkrecords}
             updateLinkRecords= {(answerId, fileArr) => this.props.updateAuditDocsList(answerId, fileArr, this.state.questionId, this.state.index, this.state.childeIndex, this.state.questionIndex )}
             index=  {this.state.index}
             childeIndex= {this.state.childeIndex}
             questionIndex=  {this.state.questionIndex}
             questionId = {this.state.questionId}
             toggleEditSideBar={this.toggleEditSideBar}
             auditLoader = {auditLoader}

        /> : null
        }
        </EditFormBar>
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>Change Status</ModalHeader>
            <ModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>Change</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="2"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }

        <Modal isOpen={this.state.statusModal} toggle={this.toggleModal} className={this.props.className}>
         <ModalHeader toggle={this.toggleModal}>How do you want to publish ?</ModalHeader>
          <ModalBody>
            Please select Publish &amp; Apply to Asset - If you want to publish the form and apply the data points to asset technical specifications.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=>{this.toggleModal();this.props.updateFormStatus({status:7,update_on_tech_specs:true})}}>Publish &amp; Apply to asset</Button>
            <Button color="primary" onClick={()=>{this.toggleModal();this.props.updateFormStatus({status:7})}}>Publish Only</Button>
            <Button color="secondary" style={{background:'#dc3545'}} onClick={this.toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formT003FormDataList:state.FormT003Reducer.formT003FormDataList,
  auditLoader: state.FormT003Reducer.auditLoader,
  auditLinkrecords: state.FormT003Reducer.auditLinkrecords,
  techExportLoader:state.sharedReducers.techExportLoader,
  formInfo: state.FormT001Reducer.formInfo,
  formStatusLoader:state.FormT001Reducer.formStatusLoader,
  editSideBar:state.FormT003Reducer.editSideBar,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.DataRoomReducer.storageUsage,
});
const mapDispatchToProps = (dispatch, ownProps) =>{
  return{
    fetchT003Data: () => dispatch(fetchT003DataAc(ownProps)),
    auditCheckUpdateAns: ( sectionId, subSectionId, ansData,crudType, questionIndex, questionId, file, existFileArr) => dispatch(auditCheckUpdateAnsAc(ownProps, sectionId, subSectionId, ansData, crudType,  questionIndex, questionId, file, existFileArr )),
    fetchAuditDocsList: (questionId) => dispatch(fetchAuditDocsListAc(ownProps, questionId)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    updateAuditDocsList: (  crudType, fileArr, questionId, sectionId, subSectionId, questionIndex) => dispatch(updateAuditDocsListAc(ownProps, crudType, fileArr, questionId, sectionId, subSectionId, questionIndex)),
    changeRemark:(index, childIndex,listIndex,key,value) => dispatch ({
      type:STATUS_UPDATE,
      payload:{index,childIndex,listIndex,key,value}
    }),
    changeStatus:(index,childIndex,listIndex,key,value) => dispatch ({
      type:STATUS_UPDATE,
      payload:{index,childIndex,listIndex,key,value}
    }),
    emptyLinkedDocs: () => dispatch({
      type: LINKED_DOCS_EMPTY,
      payload:[]
    }),
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT003);
