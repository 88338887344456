import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeEmptyKey } from '../../utils'
import { assetsListAc } from '../actionCreators';
import { ASSET_FILTER_UPDATE, LIST_LOADER } from '../actions';
import { assetStatus, imgStoragePath } from '../../constants';
import { browserHistory } from 'react-router';
class AssetAppliedFilter extends Component {
  constructor(props){
    super(props);
  }
  displayFilterTag = (tagType, tagValue) => {
    switch(tagType){
      case 'engine_type':
        return  this.tagLabel('Engine Type : ', tagValue, this.props.engineTypes, 'name','id');
      case 'ownership_type':
        return  this.tagLabel('Ownership Type : ', tagValue, [{label: 'Owned', value: '1'}, {label: 'Managed', value: '2'}], 'label','value');
      // case 'asset_type':
      // return  this.tagLabel('Asset Type : ', tagValue, [{id: 1, name: 'Aircraft'}, {id: 2, name: 'Engine'}, {id: 3, name: 'APU'}, {id: 4, name: 'LG'},{id: 5, name: 'Propeller'}], 'name', 'id');
      break;
      case 'aircraft_type':
      return  this.tagLabel('Aircraft Type : ', tagValue, this.props.aircraftTypes, 'name', 'id');
      case 'lessee' :
      return  this.tagLabel('Lessee : ', tagValue, this.props.clients, 'name', 'slug');
      case 'serial_number':
      return 'Serial No. : ' +  tagValue;
      case 'application':
      return  this.tagLabel('Application : ', tagValue, [{id: 3, name: 'Contracts'}, {id: 6, name: 'Records'}, {id: 2, name: 'Maintenance'}], 'name', 'id');
      case 'status':
      let str = 'Status : ';
        Object.keys(assetStatus).map((item,index) => {

          if(tagValue.split(',').indexOf(item.toString()) != -1){
            str += assetStatus[item].label ;
          }
        });
        return str;
      case 'registration':
      return 'Registration : ' +  tagValue;
      case 'portfolio':
      return 'Portfolio : ' +  tagValue;
      case 'lessor_name':
      return 'Lessor : ' +  tagValue;
      case 'owner':
        return 'Owner : ' + tagValue;
      default:
      break;
    }
  }

  tagLabel = (label, tagValue, list, name, slug) => {
    let str = label;
    list.map((data) => {
      if(typeof tagValue === 'string' && tagValue.split(',').indexOf(data[slug].toString()) != -1){
        if(tagValue.split(',').length > 1){
          if(str.split(',').length === tagValue.split(',').length){
            str += data[name]
          }else{
            str += data[name] + ','
          }
        }else{
          str += data[name]
        }
      }
    });
    return str;
  }
  removeAssetFilter = (type, value) => {
    let queryFilter = Object.assign({}, this.props.assetFilter);
    queryFilter = {
      ...queryFilter,
      [type]: '',
      page: 1
    };
    const filterParams = removeEmptyKey(queryFilter);
    this.props.setLoader();
    this.props.updateAssetFilter('', type);
    this.props.fetchAssetLists(filterParams, false);
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    location.query= filterParams;
    browserHistory.push(location);
  }
  loadFiltertag = (filter,index, value) => {
    if(['page', 'sort', 'sort_by', 'asset_type' ].indexOf(filter) == -1){
      return (<li key={index}>{this.displayFilterTag(filter, value)} <span onClick={() => this.removeAssetFilter(filter, '')}><img className="close-block" src={imgStoragePath+"black_close.png"} alt="close"/></span></li>)
    }else{
      return null
    }
  }
  render() {
    const filters = removeEmptyKey(Object.assign({}, this.props.assetFilter));
    return(
      <ul className="list-inline applied-filter">
        {Object.keys(filters).map((filter, index) =>
          this.loadFiltertag(filter,index, filters[filter])
        )}
      </ul>
    )
  }
}
const mapStateToProps = state => ({
  assetFilter: state.userAssetReducer.assetFilter,
  engineTypes: state.userAssetReducer.engineTypes,
  aircraftTypes: state.userAssetReducer.aircraftTypes,
  clients: state.userAssetReducer.clients,
  operators: state.userAssetReducer.operators,
  operators: state.userAssetReducer.operators,
  application: state.userAssetReducer.application,
  moduleTypes: state.userAssetReducer.moduleTypes
})
const mapDispatchToProps = dispatch => {
  return {
    setLoader: () => dispatch({
      type: LIST_LOADER,
      payload: true
    }),
    updateAssetFilter: (value, type) => dispatch({
      type: ASSET_FILTER_UPDATE,
      payload: {value, type},
    }),
    fetchAssetLists: (filterParams, allTypeFlag) => dispatch(assetsListAc(filterParams, allTypeFlag))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetAppliedFilter);
