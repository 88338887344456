import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Link } from 'react-router';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Progress } from 'reactstrap';
import { StorageList } from '../components';
import { imgStoragePath } from '../../../constants';
import { TableUIComp, ListLoader, BuyLicenseModal } from '../../../shared';
import { usageDetailAc } from '../../../applications/records/DataRoom/actionCreators'
import { getStorageUsageListAc } from '../actionCreators'
import { getFileSize, getLocalStorageInfo } from '../../../utils'

class StorageListing extends Component{
    constructor(props){
        super(props);
        this.state = {
          buyLicence: false
        }
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    sortTable = (sortKey) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
          query: {
            ...location.query,
            sort: sortKey,
            page:1,
            per_page: this.props.storageUsageList.pagination.per_page,
            sort_by: location.query.sort && location.query.sort ==  sortKey ? location.query['sort_by'] == 'asc' ? 'desc': 'asc' : 'asc'
          }
        }

      browserHistory.push(location);
      this.props.getStorageUsageList(location.query);
    }

    changePerPage=(count) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
        query: {
          ...location.query,
          'per_page': count,
            'page': 1
        }
      }
      browserHistory.push(location);
      this.props.getStorageUsageList(location.query);

    }

    changePagination = (type, currentPage) => {
      let location = browserHistory.getCurrentLocation();
      location = {
        ...location,
        query: {
          ...location.query,
          'page': currentPage
        }
      }
      browserHistory.push(location);
      this.props.getStorageUsageList(location.query);
    }
    changeFilterFn = (filterParams) => {
      this.props.getStorageUsageList(filterParams);
    }

    componentDidMount(){
      if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
  			this.props.usageDetail()
  		}
      this.props.getStorageUsageList()
    }

    render(){
      const { storageUsageLoader, storageUsage, storageUsageList } = this.props
      const userDetail = getLocalStorageInfo()
      const menuList = {
        'Serial No': {
          'inputType': 'text',
          'keyParam': 'serial_number',
          'placeholder': 'Search by MSN/ESN',
          'label': 'Serial No.'
        },
        'Asset Type':{
          'inputType': 'dropdown',
          'keyParam': 'asset_type',
          'placeholder': 'Select Asset Type',
          'options': [{label:'Aircraft',value:'1'},{label:'Engine',value:'2'}],
          'labelKey': 'label',
          'valueKey': 'value',
          'label': 'Asset Type'
        }
      }
        return(
            <div className="billing-container storage-container">
                <div className="container-fluid billing-content-wrapper">
                    <h1 className="page-title">Storage</h1>
                    <p className="block-subs">Offers the user with detailed information on the storage space available on SPARTA</p>
                    <Row>
                        <Col md="8">
                            <ul className="list-inline tab-right flex-centered export-list">
                                <li className="list-inline-item download-block">
                                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                        <DropdownToggle caret size="sm">
                                        <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export List </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                                            <DropdownItem><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </li>
                                <li className="list-inline-item count-block">
                                    <span> {storageUsageList && storageUsageList.usage && storageUsageList.usage.length ? storageUsageList && storageUsageList.usage && storageUsageList.usage.length : 0} item(s) </span>
                                </li>
                            </ul>
                            <div className="storage-listing">
                                <TableUIComp
                                    theads={[{label:'Asset', sortKey: ''}, {label:'Records Data Room Size', sortKey: 'records_size'}, {label:'Inspection Data Size', sortKey: 'inspection_size'},{label:'Attachments and Others', sortKey: ''}]}
                                    bulkOperation={false}
                                    content={storageUsageList && storageUsageList.usage && storageUsageList.usage.length ? storageUsageList.usage.map((item, index) => <StorageList key={index} asset={item}/>) : null}
                                    filter={{menuList: menuList, appliedFilter: {}, displayFilter: true}}
                                    pagination={storageUsageList.pagination}
                                    sortTable={this.sortTable}
                                    changePerPage ={this.changePerPage}
                                    changePagination = {this.changePagination}
                                    closeFilter = {this.closeFilter}
                                    searchQuery = {browserHistory.getCurrentLocation().query}
                                    changeFilterFn={this.changeFilterFn}
                                    recordsFound = {storageUsageLoader ? false : storageUsageList && storageUsageList.usage && storageUsageList.usage.length ? false:true}
                                    queryParamsEnabled={true}
                                    tableHeight = {window.innerHeight - 255}
                                />
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="current-amount-due storage-status">
                                <Row className="flex-centered">
                                    <Col md="12" >
                                        <h3>Storage Status <span className="storage-usage-stat">
                                          {
                                            storageUsage && Object.keys(storageUsage).length ?
                                              `${getFileSize(storageUsage.used)} / ${getFileSize(storageUsage.available)}`
                                            :'0/0'
                                          }
                                        </span></h3>
                                      <Progress value={(storageUsage.used / (storageUsage.available) ) * 100}></Progress>
                                    </Col>
                                    {
                                       userDetail.user.permission.console && userDetail.user.permission.console.license ?
                                       <Col md="12" style={{marginTop: '20px'}}>
                                           <a onClick={(evt) => {evt.preventDefault();evt.stopPropagation(); this.setState({buyLicence: true})}}>Buy More Storage</a>
                                       </Col>
                                       :null
                                    }
                                </Row>
                            </div>
                            <div className="billing-detail">
                                <h3><img src={imgStoragePath+'phase-2/info.png'} />About Storage Usage</h3>
                                <p>
                                    You can choose between monthly or yearly plan for data storage required.
                                    <br/>
                                    <br/>
                                    Storage includes Records of the assets, Inspection pictures and documents and other attachments for the asset.
                                    Deactivating license would block the access to the data on the platform.
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                  storageUsageLoader ? <ListLoader/> : null
                }
                <BuyLicenseModal isOpen={this.state.buyLicence} toggle={() => this.setState({buyLicence: false})} planId="2"/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
  storageUsageList: state.StorageListingReducer.storageUsageList,
  storageUsageLoader: state.StorageListingReducer.storageUsageLoader,
  storageUsage: state.DataRoomReducer.storageUsage,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
      getStorageUsageList: (filters) => dispatch(getStorageUsageListAc(filters)),
      usageDetail: () => dispatch(usageDetailAc())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorageListing);
