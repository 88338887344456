import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Input } from 'reactstrap';
import { withRouter, Link } from 'react-router';
import * as actions from '../actions';
import { removeEmptyKey, getLocalStorageInfo } from '../../../utils'
import { assetsListAc, deleteAssetsFromListAc, getFleetUsageAc } from '../actionCreators';
import FilterBar from '../../../shared/FilterBar';
import AddEditPortfolio from '../components/AddEditPortfolio';
import FieldHeading  from '../components/FieldHeading';
import AssetsList  from '../components/AssetsList';
import ShortHead from '../elements/ShortHead';
import { NoRecordFound  } from '../../../shared';
import { getPortfolioAssetsAc } from '../actionCreators'
import '../assets/styles/fleet.scss';
import '../../../User/assets/styles/assetListing.scss';
import { ListLoader } from '../../../shared/';
import NumericLabel from 'react-pretty-numbers';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import {  prettyNumberParamsL, displayDateFormat } from '../../../constants';

class PortfolioContainer extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false,
      appliedFilter: {},
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  componentDidMount(){
    this.props.getPortfolioAssets();
    this.props.emptyComareList();
    if(getLocalStorageInfo().user.permission.fleet_cash_flow && getLocalStorageInfo().user.permission.fleet_cash_flow.is_saas){
			this.props.getFleetUsage();
		}
  }

  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'transaction_reference_id':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Reserve Account: ', value: args[1].name,
              filterData: args[1].id
            }
          }
        }
        break;
      case 'transaction_type':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Transaction Type: ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'date':
        let date = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
        filterParams = {
          ...filterParams,
          [args[0]]: {
            name:'Date',
            value: date,
            startDate: args[1] ? args[1]:'',
            endDate: args[2]?args[2]:''
          }
        }
      default:
       break;
    }
    this.setState({
      appliedFilter: filterParams,
      page: 1
    });
  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    this.setState({
      appliedFilter: filterParams,
      page:1
    });
  }

  render(){
    const { fleetViewUsage, checkedList,portfolioCrud,listLoader,compareList,portfolio,addToCompareAllAssets,addAssetList } = this.props;
    const filterMenu = {
      'Aircraft Type':{
        'inputType': 'dropdown',
        'keyParam': 'transaction_type',
        'placeholder': 'Select Aircraft Type',
        'options': [{label: 'MR Transaction', value: '1'}, {label: 'Rental Transaction', value: '2'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Region':{
        'inputType': 'dropdown',
        'keyParam': 'transaction_type',
        'placeholder': 'Select Region',
        'options': [{label: 'MR Transaction', value: '1'}, {label: 'Rental Transaction', value: '2'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Lessee':{
        'inputType': 'dropdown',
        'keyParam': 'transaction_type',
        'placeholder': 'Select Lessee',
        'options': [{label: 'MR Transaction', value: '1'}, {label: 'Rental Transaction', value: '2'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      },
      'Age': {
        'inputType': 'range',
        'keyParam': 'age',
        'placeholder': 'Enter Age'
      },
      'Reserve Balance': {
        'inputType': 'range',
        'keyParam': 'age',
        'placeholder': 'Enter Reserve Balance'
      }
    };
    const filterData = {};
    var addFlag = "true"
    if(getLocalStorageInfo().user.permission.fleet_cash_flow && getLocalStorageInfo().user.permission.fleet_cash_flow.is_saas){
      if(fleetViewUsage.available != -1 && fleetViewUsage.assets_count == 5){
        addFlag = 'false'
      }
    }
    return(
      <div className="asset-listing-wrap fleet-list" >
        <ShortHead
          portfolio={portfolio}
          backUrl={`/fleet-cashflow/portfolios/`}
          totalAsset={portfolio.total_asset}
          addFlag="true"
          type="view"
          compareList={this.props.compareList}
          totalValue={portfolio.assets.reduce( (total, next) => total + next.asset.cumulative_cash_flows, 0 )}
          addAssetList={addAssetList} assetIncluded={portfolio.no_of_asset}
          deleteAssetsFromList={this.props.deleteAssetsFromList}
        />
        <div className="asset-listing-body">
          <h1>Assets</h1>
          <div className="fleet-cashflow-data">
            <ul className="list-inline">

              <li className="list-inline-item">
                  <h6 className="caption-ui">Revenue</h6>
                  <span>
                    {portfolio.assets.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{portfolio.assets.reduce((total,next) => total + (Object.keys(next.asset).length ? next.asset.cash_inflow : 0), 0)}</NumericLabel>:0}
                  </span>
              </li>
              <li className="list-inline-item">
                  <h6 className="caption-ui">Expense</h6>
                  <span>
                    {portfolio.assets.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{portfolio.assets.reduce((total,next) => total + (Object.keys(next.asset).length ? next.asset.cash_outflow : 0), 0)}</NumericLabel>:0}
                  </span>
              </li>
              <li className="list-inline-item">
                  <h6 className="caption-ui">Total</h6>
                  <span>
                    { portfolio.assets.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{portfolio.assets.reduce((total,next) => total + (Object.keys(next.asset).length ? next.asset.cumulative_cash_flows : 0), 0)}</NumericLabel>:0}
                  </span>
              </li>
              <li className="list-inline-item">
                  <h6 className="caption-ui">Value Adjustment From Half Life</h6>
                  <span>
                    {portfolio.assets.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{portfolio.assets.reduce((total,next) => total + (Object.keys(next.asset).length ? next.asset.half_life_adjustment : 0), 0)}</NumericLabel>:0}
                  </span>
              </li>
            </ul>
          </div>
          <div className="fleet-assets-wrapper">
            <div className="asset-list-filter-sort">
            {/* <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn}/> */}
            <Row className="filter-block">
              <Col md="2" >
              <FormGroup check>
                <h6>
                  {
                    portfolio.assets && portfolio.assets.length && portfolio.assets.filter(asset => asset.asset.ownership_type == 2).length == 0 ?
                      <Input type="checkbox" checked={addToCompareAllAssets} onClick={evt => this.props.addToCompareAll('existing',evt.target.checked)} style={{marginLeft:'-15px'}}/>
                    :null
                  }
                    Model & MSN
                </h6>
              </FormGroup>
              </Col>

              <Col md="2">
                <h6>
                  <span>
                    Lessee
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span>
                    Revenue
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span>
                    Expense
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span>
                    Cumulative Cash Flow
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span>
                    Value Adjustment From Half Life
                  </span>
                </h6>
              </Col>
            </Row>
          </div>
            <div className="fleet-asset-list" style={{height: window.innerHeight - 320, overflow: 'auto'}}>
            {listLoader ? <ListLoader/> : null}
            {
                  portfolio.assets.length > 0 ?
                          portfolio.assets.map((asset,index) =>
                            Object.keys(asset.asset).length ? <AssetsList checkedList={checkedList} key={asset.asset.msn} index={index} assetList={asset} type="existing" addToCompare={this.props.addToCompare} /> : null
                          )
                    :!listLoader ? <NoRecordFound description="Get Started by adding assets into portfolio" key="1"><Link to={`/fleet-cashflow/portfolios/${portfolio.slug}/add/asset`}><Button size="sm" color="primary" className="add-new">+ Add Assets</Button></Link></NoRecordFound> : null
            }
          </div>
          </div>
          { portfolio.assets.length >= 1 ?
            <div className="portfolio-footer">
              <Row>
                <Col md="12">
                  <Link color="primary" size="sm" className="add-new float-right primary-btn" to = {'/fleet-cashflow/portfolios/'+this.props.params.slug+'/simulation'}>View All Comparison</Link>
                </Col>
              </Row>
            </div>:null
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state,ownProps) => ({
  portfolio: state.FleetAnalysisReducer.portfolio,
  listLoader: state.FleetAnalysisReducer.listLoader,
  compareList: state.FleetAnalysisReducer.compareList,
  addAssetList: state.FleetAnalysisReducer.addAssetList,
  checkedList: state.FleetAnalysisReducer.checkedList,
  addToCompareAllAssets: state.FleetAnalysisReducer.addToCompareAll,
  fleetViewUsage: state.FleetAnalysisReducer.fleetViewUsage,
});

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    getPortfolioAssets: () => dispatch(getPortfolioAssetsAc(ownProps.params)),
    addToCompare: (asset,index,operationType) => dispatch({
      type: actions.ADD_TO_COMPARE,
      operationType,
      payload: {
        index,
        data:{
          asset_id:asset.id
        }
      }
    }),
    addToCompareAll: (operation,flag) => dispatch({
      type: actions.ADD_TO_COMPARE_ALL,
      payload: flag,
      operation
    }),
    deleteAssetsFromList: (data) => dispatch(deleteAssetsFromListAc(ownProps.params,data)),
    emptyComareList: () => dispatch({
      type: actions.EMPTY_COMPARE_LIST,
      payload:false
    }),
    getFleetUsage: () => dispatch(getFleetUsageAc()),
  }

}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(PortfolioContainer, ['fleet_cash_flow','dashboard','R'])));
