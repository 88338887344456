import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../constants';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';

export default  class MRCalculator extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    render(){
        const{ planDetail, selectedPlans } = this.props.props;
        return(
                <div className="users-license mr-calculator">
                    <h2 className="block-title">MR Calculator License</h2>
                    <p className="block-title-tag">One-time license purchase per query / unlimited plan</p>
                    <Row>
                    {
                        planDetail.plans ?
                        planDetail.plans.map((plan, key) =>
                        <Col md="6" className="mr-pricing-grid" key={key}>
                            <Row className="pricing-outer">
                                <Col md="6" className="text-center pricing-grid flex-centered">
                                    {
                                        plan.details ?
                                        plan.details.map((detail, key2) =>
                                            <div key={key2}>
                                                <p className="title">
                                                  <span>
                                                    {plan.quantity != -1 ?
                                                        (plan.name == 'Aircraft' || plan.name == 'Aircraft & Engine') ?
                                                            <img src={imgStoragePath+'phase-2/aircraft.png'} />
                                                        :
                                                            <img src={imgStoragePath+'phase-2/engine.png'} />

                                                        :null
                                                    }
                                                  </span>
                                                    {plan.quantity != -1 ?
                                                    <span>
                                                        {plan.name}
                                                        <span className="pricing-desctiption">{plan.description}</span>
                                                    </span>:null
                                                    }
                                                </p>
                                                {plan.quantity != -1 ?
                                                  <div style={{ paddingLeft: '10px' }}>
                                                    <p className="price" key={key2}>
                                                        $<NumericLabel className="price-label">{detail.amount}</NumericLabel>
                                                        <span>{detail.interval_type.value == 1 ? "Per Month," : detail.interval_type.value == 2 ? "Per Year," : null} Per {plan.units.label}</span>
                                                    </p>
                                                  </div>
                                                    :
                                                    <div style={{ paddingLeft: '10px' }}>
                                                        <p className="title text-left">
                                                          {plan.name}
                                                          <span style={{display: 'block',fontSize: '12px',lineHeight: 'normal',fontWeight: '400',color: '#9d9d9d',fontFamily: 'Conv_IBMPlexSans-Regular'}}>{plan.description}</span>
                                                        </p>
                                                        {
                                                          plan.quantity != -1 ?
                                                          <p className="price text-left">
                                                              $<NumericLabel className="price-label">{detail.amount}</NumericLabel>
                                                            <span>Billed {detail.interval_type.label},<br/> Per Year ( {plan.plan_type.label} )</span>
                                                          </p>
                                                          :null
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                        :null
                                    }
                                </Col>
                                <Col md="6" className="users-grid flex-centered">
                                    {   plan.details.map((detail, key2) =>
                                            <div key={key2}>
                                                {plan.quantity != -1 ?
                                                <ul className="list-inline pricing-calculations">
                                                    <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount}</NumericLabel></li>
                                                    <li className="list-inline-item">X</li>
                                                    <li className="list-inline-item"><span>{plan.quantity}</span> {plan.units.label}</li>
                                                    <li className="list-inline-item caret-list">
                                                        <i className="fa fa-caret-up" onClick={()=> this.props.increment(key, ++plan.quantity)}></i>
                                                        <i className="fa fa-caret-down" onClick={ plan.quantity > 1  ? ()=> this.props.decrement(key, --plan.quantity) : null } style={plan.quantity == 1 ? {color: "#dddddd", cursor:"not-allowed"}:{}}></i>
                                                    </li>
                                                    <li className="list-inline-item">=</li>
                                                </ul>:null}
                                                {plan.quantity != -1 ?
                                                <ul className="list-inline total-price">
                                                    <li className="list-inline-item">$<NumericLabel className="price-label">{detail.amount * plan.quantity}</NumericLabel></li>
                                                    <li className="list-inline-item">Billed {detail.interval_type.label} <br/>( {plan.plan_type.label} )</li>
                                                </ul>:null}
                                                {
                                                plan.details && planDetail.id == 2 && plan.is_subscription ?
                                                <Link disabled={!this.props.showCartButton} onClick={() => this.props.addLicenceToCart(detail.id, plan.quantity)} className="buy-license">Buy Now</Link>
                                                :
                                                selectedPlans && selectedPlans.length && selectedPlans[0].quantity == -1 && selectedPlans[0].status.value == 1 ?
                                                <Link disabled={true} onClick={() => null} style={{background: 'rgb(210, 207, 207)', cursor: 'not-allowed', border: '1px solid #a0a0a0'}} className="buy-license">Buy Now</Link>
                                                :plan.quantity != -1 ? <Link disabled={!this.props.showCartButton} onClick={() => this.props.addLicenceToCart(detail.id, plan.quantity)} className="buy-license">Buy Now</Link> : <a disabled={!this.props.showCartButton} target="_blank" href="mailto:sales@acumenaviation.ie" className="buy-license">Contact Sales</a>
                                                }
                                            </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>):null
                    }
                    {planDetail.plans && planDetail.plans.length ?
                        <Col md="6" style={this.props.props.modal ? {display: 'none'} : {}} className="mr-pricing-grid">
                            <Row className="contact-sales">
                                <Col md="12">
                                    <img src={imgStoragePath+'phase-2/email.png'} />
                                    <div className="sales-content">
                                        <p>
                                            Looking for a custom pricing or
                                            an enterprise plan?
                                            Please contact our
                                            sales team.
                                        </p>
                                        <a href="mailto:sales@acumenaviation.ie" target="_blank">Contact Sales</a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    :null}
                </Row>
                </div>
            )
        }
}
