import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import moment from 'moment'
import ShowMoreText from 'react-show-more-text';
import { Link, browserHistory } from 'react-router';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { LicenseSidebar, ReadMore, ReferralHistory, Plans, PaymentFailed, CurrentPlan } from '../components';
import { imgStoragePath } from '../../../constants';
import { plansListAc, selectedPlansListAc, addLicenceToCartAc, getReferralHistoryAc, deactivatePlanAc, editSelectedPlanAc } from '../actionCreators';
import { INCREMENT, DECREMENT, INTERVAL_TOGGLE } from '../actions';
import { ListLoader } from '../../../shared'
import { getFileSize, getLocalStorageInfo, toastFlashMessage } from '../../../utils'
import { NoRecordFound } from '../../../shared'
import { licences, displayDateTimeFormat, freeTrialRedirects } from '../../../constants'
import { DeleteModal } from  '../../../applications/technicalInspection/Elements';
import { globalPostService } from '../../../globalServices';
const requestTypeTitle = {
  'user': 'Interested in availing extra user licenses for FREE?',
  'storage': 'Interested in availing extra storage space for FREE?',
  'inspections': 'Interested in availing extra inspection licences for FREE?',
  'asset-data-transfer':'Interested in availing extra asset transfer licences for FREE?',
  'maintenance': 'Interested in availing extra maintenance licences for FREE?',
  'contracts': 'Interested in availing extra contracts licences for FREE?'
};
const moduleTypes = ['user', 'storage', 'inspections', 'asset-data-transfer', 'maintenance', 'contracts'];
class Users extends Component{
  constructor(props){
    super(props);
    this.state = {
      loader: false,
      readMore: false,
      referralHistory: false,
      intervalType: 1,
      paymentModal: false,
      copyCodeText: "Copy Code",
      cartModal: false,
      deactivateModalFlag: false,
      editModalFlag: false,
      deactivateLicence: '',
      planId: 0,
      newQuantity: 1,
      currentPlan: {
        plan: {},
        status: {}
      },
      licenceReadMore : false,
      licenceReadFlag : false
    }
  }
  componentDidMount(){
    licences.map(item => {
      if(item.name.toLowerCase() == this.props.routeParams.id){
        this.props.fetchPlans(item.id);
        this.setState({planId: item.id})
      }
    });
    this.props.getReferralHistory();
  }

  componentWillReceiveProps(nextProps, state){
    if(nextProps.params.id != this.props.params.id){
      licences.map(item => {
        if(item.name.toLowerCase() == nextProps.params.id){
          this.props.fetchPlans(item.id);
          this.setState({planId: item.id, licenceReadMore:false});
        }
      });
      this.props.getReferralHistory()
    }
  }

  handlePageChange = (page) => {
    licences.map(item => {
      if(item.name.toLowerCase() == this.props.routeParams.id){
        this.props.fetchPlans(item.id, {page});
        this.setState({planId: item.id})
      }
    });
  }

  handlePlansChange = (licenseId) => {
    this.props.fetchPlans(licenseId);
  }

  readInfo = () => {
    this.setState({
      readMore : !this.state.readMore
    });
  }

  toggleLicenseHeight = () => {
    this.setState({
      licenceReadMore : !this.state.licenceReadMore
    })
  }
  referralHistoryToggle = () => {
    this.setState({
      referralHistory : !this.state.referralHistory
    });
  }
  copyCode = () => {
    this.setState({
      copyCodeText : "Copied!"
    });
    let textField = document.createElement('textarea');
    textField.innerText = document.getElementById('referCode').innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setTimeout(() =>
    this.setState({
      copyCodeText : "Copy Code"
    })
    ,2000)
  }

  requestModAccess = (module) => {
    this.setState({loader: true});
    globalPostService('api/saas_request/', {moduleType: module})
    .then(response => {
      this.setState({loader: false});
      if(response.data.statusCode == 200){
        toastFlashMessage('Your request has been accepted, will activate and notify you soon', 'success');
      }
    });
  }

  render(){
    const{ planDetail, selectedPlansDetail, licenseLoader, referralHistoryList } = this.props;
    const { loader } = this.state;
    const location = browserHistory.getCurrentLocation();
    let pheight = document.querySelector(".license-detail");
    let cheight = document.querySelector(".license-inner");
    if(pheight && cheight){
      pheight = pheight.clientHeight;
      cheight = cheight.clientHeight;
    }

    return(
      <div className="license-users">
        <LicenseSidebar licenseId={ this.props.routeParams.id ? this.props.routeParams.id : 1 } handlePlansChange={this.handlePlansChange} />
        { !licenseLoader ?
          <div className="container-fluid license-content-wrapper">
            <Row>
              <Col md="8">
                <Plans
                  planDetail={planDetail}
                  addLicenceToCart={this.props.addLicenceToCart}
                  extra_free_plan_approved={ selectedPlansDetail.hasOwnProperty('license') ? selectedPlansDetail.license.extra_free_plan_approved:false }
                  selectedPlans={selectedPlansDetail.hasOwnProperty('license') && selectedPlansDetail.license.hasOwnProperty('selected_plans') ? selectedPlansDetail.license.selected_plans : []}
                />
                { selectedPlansDetail.hasOwnProperty('license') ?
                  <div className="current-plans">
                    { selectedPlansDetail.license.hasOwnProperty('selected_plans') && selectedPlansDetail.license.selected_plans.length ?
                      <span>
                        <h2 className="block-title">Current Plans</h2>
                        { selectedPlansDetail.license.hasOwnProperty('enable_auto_pay') && selectedPlansDetail.license.enable_auto_pay ?
                          <p className="notice-info" style={{margin:'10px -10px 10px 0px'}}>
                            <span>Note:</span>
                            The license(s) purchased will be auto renewed at the next billing date. If you choose to opt out of Auto renewals, please click
                            <span>
                              <Link to={"/billing/payment-methods"} style={{float:'none'}} >here.</Link>
                            </span>
                          </p>:
                          null
                        }
                      </span>
                      :null
                    }
                    <Row className="current-plans-row">
                      { selectedPlansDetail.license.hasOwnProperty('selected_plans') && selectedPlansDetail.license.selected_plans.length ?
                        selectedPlansDetail.license.selected_plans.map((current_plan, key) =>
                        <CurrentPlan editLicence={() => this.setState({currentPlan:current_plan ,editModalFlag: true, newQuantity: current_plan.quantity})} plan={current_plan}key={key} deactivateLicence={() => this.setState({deactivateLicence:current_plan.id ,deactivateModalFlag: true})}/>
                        ):null
                      }
                    </Row>
                    <Row className="current-plans-row" style={{marginTop: '15px', textAlign: 'centeer', justifyContent: 'center'}}>
                      { selectedPlansDetail.license.hasOwnProperty('selected_plans') && selectedPlansDetail.license.selected_plans.length && selectedPlansDetail.pagination && Object.keys(selectedPlansDetail.pagination).length ?
                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={10}
                          totalItemsCount={(selectedPlansDetail.pagination && selectedPlansDetail.pagination.total) ? selectedPlansDetail.pagination.total:0}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                        :null
                      }
                    </Row>
                  </div>:null
                }
              </Col>
              <Col md="4">
                { moduleTypes.includes(this.props.routeParams.id) ?
                  <div className="text-center" style={{background: 'rgba(181, 177, 177, 0.4)', padding: '50px 10px', marginTop:'55px', display: 'none'}}>
                    <h4 style={{fontSize: '18px', marginBottom:'20px'}}>{requestTypeTitle[this.props.routeParams.id]}</h4>

                    <button
                      onClick={() => selectedPlansDetail.license && selectedPlansDetail.license.extra_free_plan_approved ? null: this.requestModAccess(this.props.routeParams.id)  }
                      style={{padding: '6px 20px', background: (selectedPlansDetail.license && selectedPlansDetail.license.extra_free_plan_approved) ?'#C0C0C0':'#343a40', border: '1px solid #343a40', color: '#fff', borderRadius: '4px', cursor: (selectedPlansDetail.license && selectedPlansDetail.license.extra_free_plan_approved) ? 'not-allowed':'pointer'}}>
                      { loader ? 'Processing...':'Submit Request'}
                    </button>
                  </div>:null
                }
                <div className="license-detail" style={getLocalStorageInfo().defaultLessor.lessor_type == 1 ? {marginTop: '55px'} : {}}>
                  <h3> <img src={imgStoragePath+'phase-2/info.png'} /> Details about License
                    {
                      // this.props.params.id != 'asset-data-transfer' && selectedPlansDetail.license && selectedPlansDetail.license.selected_plans && selectedPlansDetail.license.selected_plans.length == 1 ?
                      // selectedPlansDetail.license.selected_plans[0] && selectedPlansDetail.license.selected_plans[0].usage && selectedPlansDetail.license.selected_plans[0].usage.remaining > 0 ?
                      // <Link style={{ float: 'right', padding: '5px 10px', position: 'relative', bottom: '4px' }} to={freeTrialRedirects[this.props.routeParams.id]} className="primary-btn">Try it for free</Link>
                      // :null
                      // :null
                    }

                  </h3>
                  { planDetail.description ?
                    <ShowMoreText
                      lines={8}
                      more='+ Read More'
                      less='- Read Less'
                      anchorClass='show-btn'
                    >
                      <div dangerouslySetInnerHTML={{__html:  planDetail.description ? planDetail.description : ""}} ref="licenseChild" className="license-inner"></div>
                    </ShowMoreText>:null
                  }
                </div>
                { getLocalStorageInfo().defaultLessor.lessor_type != 1 ?
                  <div className="refer-fried-wrapper">
                    <div className="refer-content-wrapper">
                      <img src={imgStoragePath+'phase-2/refer.png'} />
                      <div className="refer-friend-inner">
                        <h3>Refer a friend</h3>
                        <p>
                          Invite other users to SPARTA and avail free benefits,
                          like extra storage, inspections and users.
                          Read more about our referral program <a href="javascript:void(0)" onClick={() => this.readInfo()}>here</a>.
                        </p>
                        <p className="code-block">Your referral <br/>Program Code
                          <span className="referal-code" id="referCode">
                            { getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.referral_code ? getLocalStorageInfo().defaultLessor.referral_code:''}
                          </span>
                        </p>
                      </div>
                    </div>
                    <Row className="refer-links">
                      <Col md="4">
                        <a href={"mailto:?subject=Invitation to join SPARTA&body=Hi,\nPlease find my referal code " + getLocalStorageInfo().defaultLessor.referral_code} target="_blank">Email a Friend</a>
                      </Col>
                      <Col md="4">
                        <a href="javascript:void(0)" onClick={this.state.copyCodeText == 'Copy Code' ? () => this.copyCode() : null}>{this.state.copyCodeText}</a>
                      </Col>
                      <Col md="4">
                        <a href="javascript:void(0)" onClick={() => this.referralHistoryToggle()}>Referral History</a>
                      </Col>
                    </Row>
                  </div>
                  :null
                }
              </Col>
            </Row>
          </div>:
          null
        }
        <ReadMore
          toggleSideBar={this.readInfo}
          readInfo = {this.state.readMore}
        />
        <ReferralHistory
          toggleSideBar={this.referralHistoryToggle}
          readInfo = {this.state.referralHistory}
          referralHistoryList = {referralHistoryList}
        />
        <PaymentFailed />
        { this.props.licenseLoader ? <ListLoader/> :null }
        <DeleteModal isOpen={this.state.deactivateModalFlag} toggle={() => this.setState({deactivateModalFlag: false})} title={"Deactivate License"} className="modal-tform-delete-pictures modal-dialog-centered">
          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to deactivate this licence ?</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.setState({deactivateModalFlag: false});this.props.deactivatePlan({selected_plan_id: this.state.deactivateLicence}, this.state.planId)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.setState({deactivateModalFlag: false})}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <DeleteModal isOpen={this.state.editModalFlag} toggle={() => this.setState({editModalFlag: false})} title={"Edit License"} className="modal-tform-delete-pictures modal-dialog-centered edit-licence-container">
          { this.state.editModalFlag ?
            <Row>
              <Col md="4">
                <h4>Plan</h4>
                <p>{this.state.currentPlan.plan.name} (<span style={{fontSize: '12px'}}>{ this.state.currentPlan.plan.plan_type.label }</span>)</p>
              </Col>
              <Col md="4">
                { browserHistory.getCurrentLocation().pathname.includes('user') ?
                  <h4>No. of {this.state.currentPlan.plan.units.label}</h4>
                  :browserHistory.getCurrentLocation().pathname.includes('storage') ? <h4>Storage</h4> :<h4>Quantity</h4>
                }
                <h6 class="transaction">{this.state.newQuantity} {this.state.currentPlan.plan.units.label}
                  <span class="caret-list">
                    <i class="fa fa-caret-up" onClick={() => this.state.newQuantity == this.state.currentPlan.quantity ? null : this.setState({newQuantity: this.state.newQuantity + 1})} style={this.state.newQuantity == this.state.currentPlan.quantity ? {cursor: 'not-allowed', color: 'grey'} : {}}></i>
                    <i class="fa fa-caret-down" onClick={() => this.state.newQuantity == 1 || (this.state.newQuantity == this.state.currentPlan - this.state.currentPlan.usage.remaining) ? null : this.setState({newQuantity: this.state.newQuantity - 1})} style={this.state.newQuantity == 1 || (this.state.newQuantity == this.state.currentPlan.quantity - this.state.currentPlan.usage.remaining) ? {cursor: 'not-allowed', color: 'grey'} : {}}></i>
                  </span>
                </h6>
              </Col>
            </Row>
            :null
          }
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => {this.setState({editModalFlag: false});this.props.editSelectedPlan({selected_plan_id: this.state.currentPlan.id, quantity: this.state.newQuantity}, this.state.planId)}}>Update</button>
            </li>
            <li className="list-inline-item">
              <Link onClick={() => this.setState({editModalFlag: false})}>Cancel</Link>
            </li>
          </ul>
        </DeleteModal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  planDetail: state.LicenseReducer.plansList,
  selectedPlansDetail : state.LicenseReducer.selectedPlansList,
  licenses: state.LicenseReducer.licensList,
  licenseLoader: state.LicenseReducer.licenseLoader,
  referralHistoryList: state.LicenseReducer.referralHistory,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
  return {
    fetchPlans : (licenseId, filters) => dispatch(plansListAc(licenseId, filters)),
    fetchSelectedPlans : (licenseId, filters) => dispatch(selectedPlansListAc(licenseId, filters)),
    increment : (key, quantity) =>  dispatch({
      type: INCREMENT,
      payload: {key, quantity}
    }),
    decrement : (key, quantity) =>  dispatch({
      type: DECREMENT,
      payload: {key, quantity}
    }),
    intervalToggle : (key, type) => dispatch({
      type: INTERVAL_TOGGLE,
      payload: {key, type}
    }),
    addLicenceToCart: (licenceId, quantity, previous_quantity) => dispatch(addLicenceToCartAc(licenceId, quantity, previous_quantity)),
    getReferralHistory: () => dispatch(getReferralHistoryAc()),
    deactivatePlan: (card, planId) => dispatch(deactivatePlanAc(card, planId)),
    editSelectedPlan: (selectedPlan, planId) => dispatch(editSelectedPlanAc(selectedPlan, planId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
