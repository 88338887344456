import * as actions from '../actions';
import { globalGetService } from '../../../../globalServices';
import {
  mrFinanceGraphApi,
  mrFinanceLifeOfWingsApi,
  graphSummeryRecordApi,
  monthlyBreakDownExportApi,
  cashFlowListApi,
  getSimulateResultApi,
  deRateVariableApi,
  calculateDeRateApi,
  exportDeRateApi,
  monthlyBreakDownExportPostApi,
  cashFlowListPostApi
} from '../services';

export const mrFinanceGraphAc = (id) => {
  return (dispatch) => {
    mrFinanceGraphApi(id)
    .then(response => {
      if(response.data.statusCode == 200)
      dispatch({
        type: actions.MR_FINANCE_GRAPH,
        payload: response.data.data
      })
    })
  }
}

export const mrFinanceLifeOfWingsAc = (id) => {
  return (dispatch) => {
    mrFinanceLifeOfWingsApi(id)
    .then(response => {
      if(response.data.statusCode == 200)
      dispatch({
        type: actions.MR_FINANCE_LOW,
        payload: Object.keys(response.data.data).length ? response.data.data : {}
      })
    })
  }
}

export const getSimulateResultAc = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.SIMULATE_LOADER,
      payload: ''
    });
    getSimulateResultApi(id,data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UPDATE_SIML_RESULT,
          payload: response.data.data
        })
      }
    })
  }
}

export const cashFlowListPostAc = (id,data) => {
  data={
    ...data,
    monthly_cash_flow:true
  }
  return (dispatch) => {
    dispatch({
      type: actions.SIMULATE_LOADER,
      payload: ''
    });
    cashFlowListPostApi(id,data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASH_FLOW_MONLTY_BREAKUP_LIST,
          payload: response.data.data
        })
        dispatch({
          type: actions.MONTHLY_TOGGLE_DATA,
          payload: true
        })
      }
    })
  }
}

export const cashFlowListAc = (id) => {
  return (dispatch) => {
    cashFlowListApi(id)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASH_FLOW_MONLTY_BREAKUP_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const monthlyBreakDownExportPostAc = (id,data, filter=null) => {
  data={
    ...data,
    monthly_cash_flow:true,
    download:true
  }
  if(filter != null) {
    if(filter.month && filter.month != ''){
      data = {
        ...data,
        year: filter.month
      }
    }
    if(filter.name && filter.name != ''){
      let str = filter.name.split(/[# ]+/).join('');
      data = {
        ...data,
        reserve_account: str
      }
    }
  }
  return (dispatch) => {
    dispatch({
      type: actions.SIMULATE_LOADER,
      payload: ''
    });
    monthlyBreakDownExportPostApi(id,data)
    .then(response => {
          dispatch({
            type: actions.SIMULATE_LOADER_STOP,
            payload: ''
          })
    })
  }
}


export const monthlyBreakDownExportAc = (id, filter) => {
  return (dispatch) => {
    monthlyBreakDownExportApi(id, filter);
  }
}
export const mrCutsomRegionAc = (region_id, engine_type) => {
  return (dispatch) => {
    globalGetService('console/regions/',{region_id: region_id,engine_type: engine_type})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MR_CUSTOM_REGION,
          payload: response.data.data
        })
      }
    })
  }
}

export const deRateVariableAc = () => {
  return (dispatch) => {
    deRateVariableApi()
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.DE_RATE_VAR,
          payload: response.data.data
        })
      }
    })
  }
}
export const calculateDeRateAc = (data,id) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_DE_RATE_OP,
      payload: { engineNo: data.engine_no, flag: true, data: [] }
    });
    calculateDeRateApi(data,id)
    .then(response => {

      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UPDATE_DE_RATE_OP,
          payload: { engineNo: data.engine_no, flag: false, data: response.data.data }
        })
      }
    })
  }
}
export const graphSummeryRecordAc = (id) => {
  return (dispatch) => {
    graphSummeryRecordApi(id)
  }
}
export const exportDeRateAc = (data, id) => {
  return (dispatch) => {
    exportDeRateApi(data, id);
  }
}
