export const ENGINE_EVENT_LIST = 'ENGINE_EVENT_LIST'
export const ENGINE_EVENT_HISTORY = 'ENGINE_EVENT_HISTORY';
export const ENGINE_SHOP_VISIT_CRUD = 'ENGINE_SHOP_VISIT_CRUD';
export const ENGINE_SHOP_VIST_ADD_SUCCESS = 'ENGINE_SHOP_VIST_ADD_SUCCESS';
export const ENGINE_CLAIM_LIST = 'ENGINE_CLAIM_LIST';
export const ENGINE_CLAIM_ADD_SUCCESS = 'ENGINE_CLAIM_ADD_SUCCESS';
export const ENGINE_CLAIM_EDIT_SUCCESS = 'ENGINE_CLAIM_EDIT_SUCCESS';
export const ENGINE_CLAIM_CRUD = 'ENGINE_CLAIM_CRUD';
export const ENGINE_PAYOUT_CRUD = 'ENGINE_PAYOUT_CRUD';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const ENGINE_LOADER = 'ENGINE_LOADER';
export const ENGINE_LLP_LIST = 'ENGINE_LLP_LIST';
export const ADD_WORKSCOPE_ITEM = 'ADD_WORKSCOPE_ITEM';
export const ADD_WORKSCOPE_MODULE = 'ADD_WORKSCOPE_MODULE';
export const LLP_INFO = 'LLP_INFO';
export const ENGINE_LLP_ADD_SUCCESS = 'ENGINE_LLP_ADD_SUCCESS';
export const DEL_WORKSCOPE_ITEM = 'DEL_WORKSCOPE_ITEM';
export const ENGINE_LLP_ASSY_CRUD = 'ENGINE_LLP_ASSY_CRUD';
export const ENGINE_LLP_EDIT_SUCCESS= 'ENGINE_LLP_EDIT_SUCCESS';
export const CLOSE_ENGINE_LLP_SIDEBAR = 'CLOSE_ENGINE_LLP_SIDEBAR';
export const DELETE_ENGINE_LLP = 'DELETE_ENGINE_LLP';
export const UPDATE_WORK_SCOPE = 'UPDATE_WORK_SCOPE';
export const UPDATE_ENGINE_WORK_SCOPES = 'UPDATE_ENGINE_WORK_SCOPES';
export const GET_ENGINE_WORKSCOPES = 'GET_ENGINE_WORKSCOPES';
export const ADD_WORKSCOPE_SUBMODULE = 'ADD_WORKSCOPE_SUBMODULE';
export const TOGGLE_VIEW_EDIT_ENGINE = 'TOGGLE_VIEW_EDIT_ENGINE';
