import React from 'react';
import { Row, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkValidDate } from '../../../formValidator';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../constants';
import moment from 'moment';
class DateInputField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: '',
      value: this.props.value,
    }
  }

  componentDidMount(){
    this.setState({
      value: this.props.value
    })
  }
  componentWillReceiveProps(nextProps){

    if(this.props.value != nextProps.value){
      this.setState({
        value: nextProps.value
      })
    }
  }
  updateForm = (value) => {
    this.setState({
      value: value == 'Invalid date' ? null: value,
    })
  }

  onBlur = () => {
    this.props.updateForm(this.state.value);
  }

  render(){
    const { type, isDisabled, maxDate, minDate } = this.props;
    return(
      <div className="tech-sum-item">
        {this.props.children}
        { type == 'view' ? <h6>{this.props.value ? moment(this.props.value).format(displayDateFormat):'--'}</h6> :
          <DatePicker
            dateFormat = {fieldDateFormat}
            selected={this.state.value ? moment(this.state.value):null}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            onChange={(e) => this.updateForm(moment(e).format(backendDateFormat))}
            onBlur={this.onBlur}
            maxDate= {maxDate ? moment(maxDate) : null}
            minDate = {minDate ? moment(minDate) : null}
            disabled = {isDisabled}

          />
        }
       {this.props.error != '' ? <h6 className="error-msg">{this.props.error}</h6> : null}

      </div>
    )
  }
}
export default DateInputField;
