import React from 'react';

const ReportHeader = (props) =>{
  return(
    <tr>
      {
        props.items.map((item, index) => <td key={index}>{(item.length && item.indexOf('https') > 0) ? <a href={item} target='_blank'>Enabled</a> : item}</td>)
      }
    </tr>
  )
}

export default ReportHeader;
