import React from 'react';
const ContractBarLoader = () => {
  return(
    <div>
      <div className="contract-loader" style={{position:'fixed', top:'30px', left: '70px', right: 0, zIndex: 99, background: '#ffffff', overflow: 'hidden', padding: '19.4px 0', boxShadow: '0 4px 4px -4px rgba(0,0,0,.61)'}}>
        <p className="text-center">Loading...</p>
      </div>
    </div>
  )
}
export default ContractBarLoader;
