import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import DropToUpload from 'react-drop-to-upload';
import { imgStoragePath } from '../../constants'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';

export default class ImportAsset extends Component {
  constructor(props){
    super(props);
    this.state = {
      asset: '',
      error:{},
      file: {},
    }
  }

  updateAssetFile = (file) =>{
    this.setState({
      file: file
    })
  }

  handleDrop = (files) => {
    this.setState({
      file: files[0]
    })
    this.setState({
      handleDrag: false
    })
  }
  onDragOver = () => {
    this.setState({
      handleDrag: true

    })
  }
  onDragLeave = () => {
    this.setState({
      handleDrag: false

    })
  }

  componentDidMount(){

  }

 createAsset = (e) => {
    e.preventDefault();
    const validateNewInput = {
      asset: this.state.asset.trim() == '' ? 'Asset Type is required' : '',
      file: this.state.file.name && this.state.file.name != '' ? '' : 'Asset Transfer Key is required'
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.toggleEditSideBar();
      this.props.importAsset(this.state.file);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { asset, error, file } =  this.state;
    return(
      <form onSubmit={(e) => this.createAsset(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'none',marginBottom:'60px'}}>
          <Row className="import-asset-con">
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
              Asset Type
              <sup className="reqStar"> * </sup>
               </FieldLabel>
               <FieldSelect
                placeholder='Asset Type'
                keyParam="asset_type"
                type='add'
                value={[{value: 'aircraft', label: 'Aircraft'},{value: 'engine', label: 'Engine'}].filter(item => item.value == this.state.asset).length ? [{value: 'aircraft', label: 'Aircraft'},{value: 'engine', label: 'Engine'}].filter(item => item.value == this.state.asset)[0] : {}}
                options={[{value: 'aircraft', label: 'Aircraft'},{value: 'engine', label: 'Engine'}]}
                updateField={(key, value) =>  this.setState({asset: value})}
                labelKey='label'
                valueKey='value'
                name=""
              />
             <h6 className="error-msg">{error.asset}</h6>
            </FieldGroup>
          </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Asset Transfer Key File<sup className="reqStar"> * </sup>
                  <span style={{fontSize: '12px', color: '#2f8cfe', display: 'block', wordBreak: 'break-all', marginRight: '30px'}}>{this.state.file.name}</span>
                </FieldLabel>
                <DropToUpload
                  onDrop={ (...args) => {} }
                  onOver= { this.onDragOver}
                  onLeave = {this.onDragLeave}
                >
                  <div className="ml-modal-upload">
                    <input
                      type="file"
                      style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', opacity: 0}}
                      onChange={(e) => this.updateAssetFile(e.target.files[0])}
                      onDrop={(evt) => this.handleDrop(evt.dataTransfer.files)}
                    />
                    <h5>
                      <img src={imgStoragePath + 'content_attachment.png'} height="30"/>
                      Drag and Drop Key file here or click here to choose the Key file.
                    </h5>
                  </div>
                </DropToUpload>
              <h6 className="error-msg">{error.file}</h6>
              </FieldGroup>
            </FieldCol>
            <Col md="12">
              <span style={{margin: '10px'}} className="warning-info-text">
                Don't have access to Transfer Key? Please consult asset owner to provide you the same. Asset Transfer key is a security file which allow you to transfer asset from one owner to another owner within SPARTA. You are required to have asset transfer license to do so.
              </span>
            </Col>
          </Row>
        </div>
        <div className="submit-block">
          <input type="submit" disabled={file.name && file.name != '' && asset != '' ? false : true} className="primary-btn" value="Import Asset"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
      </form>
    )
  }
}
