import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, ButtonDropdown, FormGroup, Input } from 'reactstrap';
import { withRouter, Link } from 'react-router';
import FilterBar from '../../../shared/FilterBar';
import * as actions from '../actions';
import { removeEmptyKey } from '../../../utils'
import { addAssetsToListAc, getAssetsListAc, getPortfolioAssetsAc } from '../actionCreators';
import AddEditPortfolio from '../components/AddEditPortfolio';
import FieldHeading  from '../components/FieldHeading';
import AssetsList  from '../components/AssetsList';
import ShortHead from '../elements/ShortHead';
import { NoRecordFound  } from '../../../shared';
import { creditClassification, prettyNumberParamsL } from '../../../constants';
import '../assets/styles/fleet.scss';
import '../../../User/assets/styles/assetListing.scss';
import { ListLoader } from '../../../shared/';
import { sRegionListAc, sArctTypesAc, sLesseeListAc } from '../../../shared/actionCreators';
import NumericLabel from 'react-pretty-numbers';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
class PortfolioContainer extends Component{
  constructor(props){
    super(props);
    this.state = {
      dropdownOpen: false,
      cumulative_cash_flows: 0,
      val_adj_half_life: 0,
      appliedFilter: {},
      sortBy: 'desc',

    };
    this.toggle = this.toggle.bind(this);
    this.addToCompare = this.addToCompare.bind(this);
    this.updateAssetSort = this.updateAssetSort.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  addToCompare(asset,index,flag,operationType){
    if(flag == true){
      this.setState(prevState => ({
        ...prevState,
        cumulative_cash_flows: prevState.cumulative_cash_flows + asset.cumulative_cash_flows,
        val_adj_half_life: prevState.val_adj_half_life + asset.half_life_adjustment,
      }));
    }else {
      this.setState(prevState => ({
        ...prevState,
        cumulative_cash_flows: prevState.cumulative_cash_flows - asset.cumulative_cash_flows,
        val_adj_half_life: prevState.val_adj_half_life - asset.half_life_adjustment,
      }));
    }
    this.props.addToCompare(asset,index,flag,operationType);
  }

  componentDidMount(){
    this.props.emptyComareList();
    const filterParams = {};
    this.props.getAssetsList(filterParams);
    // this.props.getPortfolioAssets();
    this.props.sRegionList();
    this.props.sArctTypes();
    this.props.sLesseeList();
  }

  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'aircraft_type':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              keyParam: 'aircraft_type',
              name: 'Aircraft Type ', value: args[1].name,
              filterData: args[1].id
            }
          }
        }
        break;
      case 'notional_mr':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              keyParam: 'notional_mr',
              name: 'MR Type ', value: args[1].name,
              filterData: args[1].id
            }
          }
        }
        break;
      case 'region':
        if(args[1]){

          filterParams = {
            ...filterParams,
            [args[0]]: {
              keyParam: 'region',
              name: 'Region ', value: args[1],
              filterData: args[1]
            }
          }
        }
        break;
      case 'credit_classification':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              keyParam: 'credit_classification',
              name: 'Credit Risk Category ', value: args[1].value,
              filterData: args[1].id
            }
          }
        }
        break;
        case 'lessee':
          if(args[1]){
            filterParams = {
              ...filterParams,
              [args[0]]: {
                keyParam: 'lessee',
                name: 'Lessee ', value: args[1].name,
                filterData: args[1].slug
              }
            }
          }
          break;
        case 'age':
          let age = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name:'Age',
              value: age,
              min_age: args[1] ? args[1]:'',
              max_age: args[2]?args[2]:''
            }
          }
          break;
        case 'reserve_balance':
          let reserve_balance = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name:'Reserve Balance',
              value: reserve_balance,
              min_reserve_balance: args[1] ? args[1]:'',
              max_reserve_balance: args[2]?args[2]:''
            }
          }
          break;
        case 'rental':
          let rental = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name:'Lease Rental',
              value: rental,
              min_rental: args[1] ? args[1]:'',
              max_rental: args[2]?args[2]:''
            }
          }
          break;
      default:
       break;
    }
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.getAssetsList(getQueryStr);
    this.setState({
      appliedFilter: filterParams,
      page: 1
    });
  }

  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if(key == 'date'){
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      }else if(key == 'age'){
        queryFilter['min_age'] = params[key].min_age
        queryFilter['max_age'] = params[key].max_age
      }else if(key == 'reserve_balance'){
        queryFilter['min_reserve_balance'] = params[key].min_reserve_balance
        queryFilter['max_reserve_balance'] = params[key].max_reserve_balance
      }else if(key == 'rental'){
        queryFilter['min_rental'] = params[key].min_rental
        queryFilter['max_rental'] = params[key].max_rental
      }else{
        queryFilter[key] = params[key].filterData
      }
    });
    return queryFilter;
  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.getAssetsList(getQueryStr);
    this.setState({
      appliedFilter: filterParams,
    });
  }

  updateAssetSort(type){
    let queryFilter = Object.assign({}, this.state.appliedFilter);
    let sortBy = (this.state.sortBy == 'asc') ? 'desc': 'asc';
    queryFilter = {
      ...queryFilter,
      sort: {filterData:type},
      sort_by: {filterData:sortBy}
    };
    this.setState({
      sortBy
    });
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(queryFilter));
    this.props.getAssetsList(getQueryStr);
  }

  render(){
    const { checkedList, portfolioList, portfolioCrud, assetLists, listLoader, compareList, portfolio, addToCompareAllAssets, addAssetList, clients, aircraftTypes, regions } = this.props;
    const filterMenu = {
      'By Aircraft Type':{
        'inputType': 'dropdown',
        'keyParam': 'aircraft_type',
        'placeholder': 'Select Aircraft Type',
        'options': aircraftTypes,
        'labelKey': 'name',
        'valueKey': 'id',
      },
      'By Region':{
        'inputType': 'text',
        'keyParam': 'region',
        'placeholder': 'Enter Region'
      },
      'By Lessee':{
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'placeholder': 'Select Lessee',
        'options': clients,
        'labelKey': 'name',
        'valueKey': 'id',
      },
      'By Credit Risk Category':{
        'inputType': 'dropdown',
        'keyParam': 'credit_classification',
        'placeholder': 'Select Credit Category',
        'options': creditClassification,
        'labelKey': 'label',
        'valueKey': 'id',
      },
      'By Age': {
        'inputType': 'range',
        'keyParam': 'age',
        'placeholderMin': 'Enter Min Age',
        'placeholderMax': 'Enter Max Age'
      },
      'By Reserve Balance (In Millions)': {
        'inputType': 'range',
        'keyParam': 'reserve_balance',
        'placeholderMin': 'Enter Min Reserve Balance',
        'placeholderMax': 'Enter Max Reserve Balance',
      },
      'By MR Type' :{
        'inputType': 'dropdown',
        'keyParam': 'notional_mr',
        'options': [{label: 'Notional MR', id: 'yes', name: 'Notional MR'}, {label: 'Non Notional MR', id: 'no', name: 'Non Notional MR'}],
        'labelKey': 'label',
        'valueKey': 'id',
      }
      // 'By Lease Rentals (In Thousands)': {
      //   'inputType': 'range',
      //   'keyParam': 'rental',
      //   'placeholderMin': 'Enter Min Rental',
      //   'placeholderMax': 'Enter Max Rental',
      // }
    };
    const filterData = {};
    return(
      <div className="asset-listing-wrap fleet-list">
        <ShortHead backUrl={`/fleet-cashflow/portfolio/${portfolio.slug}`} addAssetsToList={this.props.addAssetsToList} totalAsset={portfolio.total_asset} type="new" portfolio={portfolio} addFlag="false" compareList={compareList} addAssetList={addAssetList} assetIncluded={portfolio.no_of_asset}/>
        <div className="asset-listing-body">
          <div className="fleet-cashflow-data">
            <ul className="list-inline">

              <li className="list-inline-item">
                  <h6 className="caption-ui">Revenue</h6>
                  <span>
                    {assetLists.asset.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{assetLists.asset.reduce((total,next) => total + next.cash_inflow, 0)}</NumericLabel>:0}
                  </span>
              </li>
              <li className="list-inline-item">
                  <h6 className="caption-ui">Expense</h6>
                  <span>
                    {assetLists.asset.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{assetLists.asset.reduce((total,next) => total + next.cash_outflow, 0)}</NumericLabel>:0}
                  </span>
              </li>
              <li className="list-inline-item">
                  <h6 className="caption-ui">Total</h6>
                  <span>
                    { assetLists.asset.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{assetLists.asset.reduce((total,next) => total + next.cumulative_cash_flows, 0)}</NumericLabel>:0}
                  </span>
              </li>
              <li className="list-inline-item">
                  <h6 className="caption-ui">Value Adjustment From Half Life</h6>
                  <span>
                    {assetLists.asset.length > 0 ?
                    <NumericLabel params={prettyNumberParamsL}>{assetLists.asset.reduce((total,next) => total + next.half_life_adjustment, 0)}</NumericLabel>:0}
                  </span>
              </li>
            </ul>
          </div>
          <div className="asset-list-filter-sort">
            <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn}/>
            <Row className="filter-block">
              <Col md="2" >
              <FormGroup check>
                <h6>
                    <Input type="checkbox" checked={addToCompareAllAssets} onClick={evt => this.props.addToCompareAll('new',evt.target.checked)} style={{marginLeft:'-15px'}}/>
                    Model & MSN
                </h6>
              </FormGroup>
              </Col>

              <Col md="2">
                <h6>
                  <span onClick={() => this.updateAssetSort('lessee')}>
                    Lessee
                    <sup className="asc-sort" style={(this.state.appliedFilter.sort == 'lessee' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                    <sub className="desc-sort" style={(this.state.appliedFilter.sort == 'lessee' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span onClick={() => this.updateAssetSort('revenue')}>
                    Revenue
                    <sup className="asc-sort" style={(this.state.appliedFilter.sort == 'revenue' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                    <sub className="desc-sort" style={(this.state.appliedFilter.sort == 'revenue' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span>
                    Expense
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span onClick={() => this.updateAssetSort('cumulative_cashflow')}>
                    Cumulative Cash Flow
                    <sup className="asc-sort" style={(this.state.appliedFilter.sort == 'cumulative_cashflow' && filterData.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                    <sub className="desc-sort" style={(this.state.appliedFilter.sort == 'cumulative_cashflow' && filterData.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                  </span>
                </h6>
              </Col>
              <Col md="2">
                <h6>
                  <span onClick={() => this.updateAssetSort('life')}>
                    Value Adjustment From Half Life
                  </span>
                </h6>
              </Col>
            </Row>
          </div>
          <div style={{height: window.innerHeight - 290, overflow: 'auto'}}>
          {
            listLoader ? <ListLoader drawer="primary-nav"/> : null
          }
            {
                   assetLists.asset.length > 0 ?
                    assetLists.asset.map((assetList,index) => <AssetsList checkedList={checkedList} key={assetList.msn} checked={addToCompareAllAssets} type="new" index={index} assetList={assetList} addToCompare={this.addToCompare} />)
                    :! listLoader ? <NoRecordFound description="" key="1"/> : null

            }
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state,ownProps) => ({
  assetLists: state.FleetAnalysisReducer.assetLists,
  portfolio: state.FleetAnalysisReducer.portfolio,
  listLoader: state.FleetAnalysisReducer.listLoader,
  compareList: state.FleetAnalysisReducer.compareList,
  addAssetList: state.FleetAnalysisReducer.addAssetList,
  checkedList: state.FleetAnalysisReducer.checkedList,
  addToCompareAllAssets: state.FleetAnalysisReducer.addToCompareAll,
  clients: state.sharedReducers.sLesseeList,
  regions: state.sharedReducers.sRegionList,
  aircraftTypes: state.sharedReducers.aircraftTypes,
});

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    getAssetsList: (filterParams) => dispatch(getAssetsListAc(filterParams,ownProps.params)),
    addToCompare: (asset,index,operationType) => dispatch({
      type: actions.ADD_TO_COMPARE,
      operationType,
      payload: {
        index,
        data:{
          asset_id:asset.id,
          asset_type:asset.asset_type,
          status:asset.status
        }
      }
    }),
    addToCompareAll: (operation,flag) => dispatch({
      type: actions.ADD_TO_COMPARE_ALL,
      payload: flag,
      operation
    }),
    addAssetsToList: (data) => dispatch(addAssetsToListAc(ownProps.params,data)),
    emptyComareList: () => dispatch({
      type: actions.EMPTY_COMPARE_LIST,
      payload:false
    }),
    sRegionList: () => dispatch(sRegionListAc()),
    getPortfolioAssets: () => dispatch(getPortfolioAssetsAc(ownProps.params)),
    sArctTypes: () => dispatch(sArctTypesAc()),
    sLesseeList: () => dispatch(sLesseeListAc()),
    fetchFilterParams: (filterParams, allTypeFlag) => dispatch(getAssetsListAc(filterParams, allTypeFlag)),
  }

}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(PortfolioContainer, ['fleet_cash_flow','dashboard','R'])));
