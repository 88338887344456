import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { imgStoragePath } from '../../../constants'
export default class ContrInputField extends Component{
  constructor(props){
    super(props);
    this.state = {
      fieldFocus : false,
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
    if(this.props.error){
      this.props.updateErrorCode(this.props.keyParam);
    }
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }

  render(){
    return(
      <Col md={this.props.md ? this.props.md:'12'}>
        <div className="form-group">
          <label className="label">{this.props.label}
          { this.props.valReq && this.props.type != 'view' ? <sup className="reqStar"> * </sup> : null } { this.props.valInfo &&  this.props.type != 'view' ? <img src={imgStoragePath+"info_icon.png"} alt="info"/> : null}
          </label>
          { this.props.type != 'view' ?
            <input type="text"  value={this.props.value ? this.props.value:''} onChange={(e) => this.props.updateField(this.props.keyParam, e.target.value)}
            onFocus={() => this.handleFocus()}  onBlur={() => this.handleBlur()}
            />
            :
            <h6>{this.props.value}</h6>
           }
            {
             this.props.error ?
             <h6 className="error-msg">{this.props.error}</h6>
            : null
           }
        </div>
      </Col>
    )
  }
}
