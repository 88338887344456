import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
import OffWingServiceable from './OffWingServiceable';
import InShopUnServiceable from './InShopUnServiceable';
import InShopServiceble from './InShopServiceble';
import YetToBeSentFields from './YetToBeSentFields';
import OffWingUnserviceble from './OffWingUnserviceble';
import AddEditMjrAssyfields from './AddEditMjrAssyfields';
import { getLocalStorageInfo } from '../../../../utils';
import moment from 'moment';

export default class EditLandingGear extends Component {
  constructor(props){
    super(props);
    this.state = {
      lg: {},
      error:{},
      off_wing_status : false,
      on_wing_status : false,
    }
  }
  componentDidMount(){
    let data = Object.assign({}, this.props.lgSummeryObj);
    if(this.props.type == 'edit'){
      delete data['position'];
      delete data['status'];
      delete data['state'];
      delete data['off_wing_status'];
      delete data['lessor_name'];
      delete data['owner'];
      delete data['off_lease_status']
      data = {
        ...data,
        aircraft: this.props.lgSummeryObj.aircraft.id,
        aircraft_name : this.props.lgSummeryObj.aircraft && this.props.lgSummeryObj.aircraft.msn ? this.props.lgSummeryObj.aircraft.msn : '',
        position:  this.props.lgSummeryObj.position && this.props.lgSummeryObj.position ? this.props.lgSummeryObj.position : null,
        state: this.props.lgSummeryObj.state && this.props.lgSummeryObj.state.value ?  this.props.lgSummeryObj.state.value : null,
        off_wing_status:  this.props.lgSummeryObj.off_wing_status &&  this.props.lgSummeryObj.off_wing_status.value ? this.props.lgSummeryObj.off_wing_status.value : '',
        on_wing_lessor : (this.props.lgSummeryObj && this.props.lgSummeryObj.on_wing_lessor) ? this.props.lgSummeryObj.on_wing_lessor : (this.props.overview.lessor && this.props.overview.lessor.name ) ? this.props.overview.lessor.name : null,
        on_wing_msn : (this.props.lgSummeryObj && this.props.lgSummeryObj.on_wing_msn) ? this.props.lgSummeryObj.on_wing_msn : this.props.overview.msn ,
        position: this.props.lgSummeryObj.position && this.props.lgSummeryObj.position.value ?  this.props.lgSummeryObj.position.value : null,
      }
    }else{
      data = {
        ...data,
        on_wing_lessor : (this.props.overview.lessor && this.props.overview.lessor.name ) ? this.props.overview.lessor.name : null,
        on_wing_msn :  this.props.overview.msn ,
      }
    }
    this.setState({
      lg: data
    });

    if(data.on_wing_status){
      this.setState({
        on_wing_status: true
      })
    }

    if(data.off_wing_status){
      this.setState({
        off_wing_status: true
      })
    }
  }


  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      lg: {
        ...prevState.lg,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))

    if( type == 'on_wing_status' && !value) {
      this.setState(prevState => ({
        ...prevState,
        lg: {
          ...prevState.lg,
          on_wing_msn: '',
          on_wing_lessor: '',
        }
      }))
    }else if(type == 'on_wing_status' && value) {
      this.setState(prevState => ({
        ...prevState,
        lg: {
          ...prevState.lg,
          off_wing_status: {},
          on_wing_msn: this.state.lg.on_wing_msn,
          on_wing_lessor: this.state.lg.on_wing_lessor,

        },
        off_wing_status : false

      }))
    }else if(type == 'off_wing_status'){
      this.setState(prevState => ({
        ...prevState,
        lg: {
          ...prevState.lg,

        },
        off_wing_status : false
      }))
    }
    // if(type == 'last_utilization_updated'){

    //   this.setState(prevState => ({
    //       ...prevState,
    //     'last_utilization_updated': value == "Invalid date" ? null: value,
    //   }))
    // }

    if(value){
      this.setState({
        [type]: true
      })
    }else {
      this.setState({
        [type]: false
      })

    }
  }


  updateDetailData = (type, value) => {

    this.setState(prevState => ({
      ...prevState,
      lg: {
        ...prevState.lg,
        off_wing_detail: {
          ...prevState.lg.off_wing_detail,
            [type]: value
          }
      }
    }));
  }

  addEditLgSummery = (e) => {
    e.preventDefault();
    let validateNewInput = {
      serial_number: checkString({value: this.state.lg.serial_number, required: true, minLength: '', maxLength: '', message: 'Please enter Serial Number'}),
      position: checkString({value: this.state.lg.position, required: true, minLength: '', maxLength: '', message: 'Please select Position'}),
      tsn: checkNumber({value: this.state.lg.tsn, required: '', minLength: '', maxLength: '', message: 'Please enter TSN in numbers'}),
      csn: checkNumber({value: this.state.lg.csn, required: '', minLength: '', maxLength: '', message: 'Please enter CSN in numbers'}),
      average_monthly_hours: checkNumber({value: this.state.lg.average_monthly_hours, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly hours in numbers'}),
      average_monthly_cycles: checkNumber({value: this.state.lg.average_monthly_cycles, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly cycles in numbers'}),
      tslsv: checkNumber({value: this.state.lg.tslsv, required: '', minLength: '', maxLength: '', message: 'Please enter TSLSV in numbers'}),
      cslsv: checkNumber({value: this.state.lg.cslsv, required: '', minLength: '', maxLength: '', message: 'Please enter CSLSV in numbers'}),
      part_number: checkString({value: this.state.lg.part_number, required: true, minLength: '', maxLength: '', message: 'Please enter Part Number'}),
      date_of_manufacture:checkValidDate({value: this.state.lg.date_of_manufacture, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
      // last_utilization_updated:checkValidDate({value: this.state.lg.last_utilization_updated, required: false, minLength: '', maxLength: '', message: 'Please enter Last Utilization Updated'}),

    };


    if(this.state.lg.is_titled || this.state.lg.is_fitted){
      validateNewInput = {
        ...validateNewInput,
        titled_fitted: '',
      }
    }else{
      validateNewInput = {
        ...validateNewInput,
        titled_fitted: 'Please Specify Landing gear is Titled or Fitted',
      }
    }


    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      let payload = Object.assign({}, this.state.lg)
      this.props.addEditTechSummary(payload, this.props.type, 'lgList','lgCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { lg, error } = this.state;
    const { type, manufacturerList, techConstants, showAdvanced, ownerList } = this.props;

    if(!Object.keys(lg).length){
      return <h4 className="text-center">Loading ...</h4>
    }
    return (
      <form onSubmit={(e) => this.addEditLgSummery(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
           <Row>
             <FieldCol md="4">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Manufacturer
                 </FieldLabel>
                 <FieldInput
                   value={lg.manufacturer}
                   type={type}
                   keyParam="manufacturer"
                   updateField={this.updateForm}
                 />
               </FieldGroup>
             </FieldCol>

             <FieldCol md="4">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Serial Number
                   { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                 </FieldLabel>
                 <FieldInput
                   value={lg.serial_number}
                   type={type}
                   keyParam="serial_number"
                   updateField={this.updateForm}
                 />
               <h6 className="error-msg">{error.serial_number}</h6>
               </FieldGroup>
             </FieldCol>

             <FieldCol md="4">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                 Part Number
                 { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                 </FieldLabel>
                 <FieldInput
                   value={lg.part_number}
                   type={type}
                   keyParam="part_number"
                   updateField={this.updateForm}
                 />
                <h6 className="error-msg">{error.part_number}</h6>

               </FieldGroup>
             </FieldCol>

             <FieldCol md="4">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Position
                   { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                 </FieldLabel>
                 <FieldSelect
                   placeholder='Select Position'
                   keyParam="position"
                   value={lg.position}
                   type={type}
                   options={techConstants.filter(item => item.type=="lg_position")}
                   updateField={this.updateForm}
                   labelKey= 'label'
                   valueKey='value'
                 />
               <h6 className="error-msg">{error.position}</h6>
               </FieldGroup>
             </FieldCol>

             <FieldCol md="4">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">Date of Manufacture
                 { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                 </FieldLabel>
                 <FieldDate
                   value={lg.date_of_manufacture}
                   type={type}
                   keyParam="date_of_manufacture"
                   updateField={this.updateForm}
                   maxDate={moment()}
                   readOnly={true}
                 />
                 <h6 className="error-msg">{error.date_of_manufacture}</h6>
               </FieldGroup>
             </FieldCol>
             <FieldCol md="12">
               <div className="tech-spec-utilization-edit-block">
                 <h6 style={{marginTop:'0'}}>Landing Gear Utilization Details</h6>
                 <Row>
                   <FieldCol md="4">
                   <FieldGroup className="form-group">
                     <FieldLabel className="label">
                       Utilization as of date
                       {/* { type != 'view' ? <sup className="reqStar"> * </sup>:null} */}

                     </FieldLabel>
                     <FieldDate
                       keyParam="last_utilization_updated"
                       value={lg.last_utilization_updated}
                       type={type}
                       updateField={this.updateForm}
                       maxDate ={ moment()}
                     />
                     {/* <h6 className="error-msg">{error.last_utilization_updated}</h6> */}
                   </FieldGroup>
                 </FieldCol>
                 </Row>

                 <Row>
                   <FieldCol md="3">
                   <FieldGroup className="form-group">
                     <FieldLabel className="label">
                       TSN
                     </FieldLabel>
                     <FieldInput
                       value={lg.tsn}
                       type={type}
                       keyParam="tsn"
                       updateField={this.updateForm}
                     />
                     <h6 className="error-msg">{error.tsn}</h6>
                   </FieldGroup>
                 </FieldCol>

                 <FieldCol md="3">
                   <FieldGroup className="form-group">
                     <FieldLabel className="label">
                       CSN
                     </FieldLabel>
                     <FieldInput
                       value={lg.csn}
                       type={type}
                       keyParam="csn"
                       updateField={this.updateForm}
                     />
                     <h6 className="error-msg">{error.csn}</h6>
                   </FieldGroup>
                 </FieldCol>

                 <FieldCol md="3">
                   <FieldGroup className="form-group">
                     <FieldLabel className="label">
                     Avg. Monthly Hours
                     </FieldLabel>
                     <FieldInput
                       value={lg.average_monthly_hours}
                       type={type}
                       keyParam="average_monthly_hours"
                       updateField={this.updateForm}
                     />
                     <h6 className="error-msg">{error.average_monthly_hours}</h6>
                   </FieldGroup>
                 </FieldCol>

                 <FieldCol md="3">
                   <FieldGroup className="form-group">
                     <FieldLabel className="label">
                     Avg. Monthly Cycles
                     </FieldLabel>
                     <FieldInput
                       value={lg.average_monthly_cycles}
                       type={type}
                       keyParam="average_monthly_cycles"
                       updateField={this.updateForm}
                     />
                     <h6 className="error-msg">{error.average_monthly_cycles}</h6>
                   </FieldGroup>
                 </FieldCol>


                 </Row>
               </div>
             </FieldCol>
             <Col md="6">
               <div className="form-group">
                 <label className="label">
                 {
                   showAdvanced ?
                   <input
                     type="checkbox"
                     checked={lg.is_titled}
                       onChange={(e) => {this.updateForm('is_titled', true);this.updateForm('is_fitted', false);this.updateForm('on_wing_status', false);}}
                     />
                   :<input
                     type="checkbox"
                     checked={lg.is_titled}
                     onChange={(e) => this.updateForm('is_titled', e.target.checked)}
                     />
                 }
                   <span>
                      Is Landing gear titled on this MSN ?
                      <i class="fa fa-info-circle" aria-hidden="true" id="lg-field1" style={{paddingLeft: '4px'}}></i>
                      <UncontrolledTooltip placement="right" target="lg-field1">
                        Tick if the major assembly is Titled
                      </UncontrolledTooltip>
                    </span>
                 </label>

               </div>
             </Col>


             <Col md="6">
               <div className="form-group" >
                 <label className="label">
                 {
                   showAdvanced ?
                   <input
                     type="checkbox"
                     checked={lg.is_fitted}
                     onChange={(e) => {this.updateForm('is_fitted', true);this.updateForm('is_titled', false);this.updateForm('on_wing_status', true);this.updateForm('on_wing_msn',  this.props.overview.msn);this.updateForm('on_wing_lessor',  this.props.overview.lessor &&  this.props.overview.lessor.name ? this.props.overview.lessor.name : '')}}
                     />
                   :<input
                     type="checkbox"
                     checked={lg.is_fitted}
                     onChange={(e) => this.updateForm('is_fitted', e.target.checked)}
                     />
                 }
                   <span>
                    Is Landing Gear fitted on this MSN ?
                    <i class="fa fa-info-circle" aria-hidden="true" id="lg-field2" style={{paddingLeft: '4px'}}></i>
                    <UncontrolledTooltip placement="right" target="lg-field2">
                      Tick if the major assembly is temporarily installed
                    </UncontrolledTooltip>
                  </span>
                </label>
               </div>
             </Col>
             <Col md="12" style={{ margin: '-26px 19px 15px'}}>
               <h6 className="error-msg">{error.titled_fitted}</h6>
             </Col>

             </Row>


            <div className="tech-spec-off-wing-block">
              <Row>

               <Col md="12">
                 <div className="form-group"  style={{margin: "20px 0px"}}>
                   <label className="label">
                   {showAdvanced && lg.is_fitted ?
                     <input
                     type="checkbox"
                     checked={lg.on_wing_status}
                     />
                     :<input
                     type="checkbox"
                     checked={lg.on_wing_status}
                     onChange={(e) => this.updateForm('on_wing_status', e.target.checked)}
                     />
                   }
                   <span>
                      On MSN Status
                      <i class="fa fa-info-circle" aria-hidden="true" id="lg-field3" style={{paddingLeft: '4px'}}></i>
                      <UncontrolledTooltip placement="right" target="lg-field3">
                        Tick if the Titled major assembly is installed on  aircraft
                      </UncontrolledTooltip>
                    </span>
                   </label>
                 </div>
               </Col>

               <FieldCol md="12" style={ ! this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                 <FieldGroup className="form-group">
                   <FieldLabel className="label">
                     Off Wing Status
                   </FieldLabel>
                   <FieldSelect
                     placeholder='Select Off MSN Status'
                     keyParam="off_wing_status"
                     value={lg.off_wing_status}
                     type={type}
                     options={techConstants.filter(item => item.type=="off_wing_status")}
                     updateField={this.updateForm}
                     labelKey= 'label'
                     valueKey='value'
                     on_wing_status = {this.state.on_wing_status}
                   />
                 </FieldGroup>
               </FieldCol>

               <FieldCol md="6" style={ this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      On MSN
                    </FieldLabel>
                    <FieldInput
                      value={lg.on_wing_msn}
                      type={type}
                      disable={showAdvanced && lg.is_fitted}
                      keyParam="on_wing_msn"
                      updateField={this.updateForm}
                    />
                  </FieldGroup>
               </FieldCol>

                <FieldCol md="6" style={ this.state.on_wing_status ? { display: 'block'} : { display : 'none'}}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    On MSN Landing Gear Owner
                    </FieldLabel>
                    <FieldInput
                      value={lg.on_wing_lessor }
                      type={type}
                      disable={showAdvanced && lg.is_fitted}
                      keyParam="on_wing_lessor"
                      updateField={this.updateForm}
                    />
                  </FieldGroup>
                </FieldCol>

              </Row>

              { lg.off_wing_status && this.state.off_wing_status  ?
                  <AddEditMjrAssyfields updateDetailData={this.updateDetailData} mjrAssy = {lg.off_wing_detail} type={type}/>
                 : null
              }
              {
                  lg.off_wing_status == 1 ?
                 <YetToBeSentFields  assemblyName="Landing Gear"  updateDetailData={this.updateDetailData} yetToBeSend = {lg.off_wing_detail} type={type}/> : null
              }
              {
                 lg.off_wing_status == 2 ?
                 <InShopServiceble  assemblyName="Landing Gear" techConstants={techConstants}  updateDetailData={this.updateDetailData} inShopserv = {lg.off_wing_detail} type={type}/> : null
              }
              {
                 lg.off_wing_status == 3 ?
                <InShopUnServiceable  assemblyName="Landing Gear" techConstants={techConstants}  updateDetailData={this.updateDetailData} inShopUnserv = {lg.off_wing_detail} type={type}/> : null
              }
              {
                 lg.off_wing_status == 4 ?
                <OffWingServiceable  assemblyName="Landing Gear" techConstants={techConstants}  updateDetailData={this.updateDetailData} offWingServ = {lg.off_wing_detail} type={type}/> : null
              }
              {
                lg.off_wing_status == 5 ?
                <OffWingUnserviceble  assemblyName="Landing Gear" techConstants={techConstants}  updateDetailData={this.updateDetailData} offWingUnServ = {lg.off_wing_detail} type={type}/> : null
              }

          </div>


        </div>
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
     </form>
   )

 }
}
