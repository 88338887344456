import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkFloat } from '../../../../formValidator';
export default class EditDebitEquity extends Component {
  constructor(props){
    super(props);
    this.state = {
      finance:{},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.financeObj));
    this.setState({
      finance: data,
    });
  }
  handleOnBlur = (type, value) => {
    if(value && checkFloat(value) == ''){
      const { finance } = this.state;
      let equityRatio= finance.equity_ratio, debitRatio = finance.debit_ratio, equityValue = finance.equity_value, debitValue = finance.debit_value;
      switch (type) {
        case 'equity_ratio':
          if(value <= 100){
            debitRatio = 100 - value;
            equityValue = 0;
            debitValue = 0;
            if(finance.purchase_value){
              equityValue = finance.purchase_value - finance.purchase_value*(100-value)/100;
              debitValue = finance.purchase_value - equityValue;
            }
            this.updateRatioAndRate(debitRatio, value, debitValue, equityValue);
            if(finance.tax_rate && finance.debit_ratio && finance.interest_rate && finance.return_equity){
              this.updateWACC(value, finance.debit_ratio, finance.tax_rate, finance.interest_rate, finance.return_equity);
            }
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                debit_ratio: ''
              }
            }));
          }else{
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                [type]: 'Please enter less then or equal to 100'
              }
            }))
          }
          break;
        case 'debit_ratio':
          if(value <= 100){
            equityRatio = 100 - value;
            equityValue = 0;
            debitValue = 0;
            if(finance.purchase_value){
              debitValue = finance.purchase_value - finance.purchase_value*(100-value)/100;
              equityValue = finance.purchase_value - debitValue;
            }
            this.updateRatioAndRate(value, equityRatio, debitValue, equityValue);
            if(finance.tax_rate && finance.equity_ratio && finance.interest_rate && finance.return_equity){
              this.updateWACC(finance.equity_ratio, value, finance.tax_rate, finance.interest_rate, finance.return_equity);
            }
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                equity_ratio: ''
              }
            }));
          }else{
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                [type]: 'Please enter less then or equal to 100'
              }
            }))
          }

          break;
        case 'equity_value':
          if(finance.purchase_value){
            if(parseFloat(value) <= parseFloat(finance.purchase_value)){
              debitValue = finance.purchase_value - value;
              equityRatio = value/finance.purchase_value*100;
              debitRatio = 100 - equityRatio;
              this.updateRatioAndRate(debitRatio, equityRatio, debitValue, value);
              this.setState(prevState => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  debit_value: ''
                }
              }));
            }else{
              this.setState(prevState => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  [type]: 'Should be less or equal to Asset Value'
                }
              }))
            }
          }

          break;
        case 'debit_value':
          if(finance.purchase_value){
            if(parseFloat(value) <= parseFloat(finance.purchase_value)){
              equityValue = finance.purchase_value - value;
              debitRatio = value/finance.purchase_value*100;
              equityRatio = 100 - debitRatio;
              this.updateRatioAndRate(debitRatio, equityRatio, value, equityValue);
              this.setState(prevState => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  equity_value: ''
                }
              }));
            }else{
              this.setState(prevState => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  [type]: 'Should be less or equal to Asset Value'
                }
              }))
            }
          }

          break;
        case 'purchase_value':
          if(finance.equity_ratio || finance.debit_ratio){
            if(finance.equity_ratio){
              debitRatio = 100 - finance.equity_ratio;
              equityValue = value - value*((100-finance.equity_ratio)/100);
              debitValue = value - equityValue;
              this.updateRatioAndRate(debitRatio, finance.equity_ratio, debitValue, equityValue);
            }else{
              equityRatio = 100 - finance.debit_ratio;
              debitValue = value - value*((100-finance.debit_ratio)/100);
              equityValue = value - debitValue;
              this.updateRatioAndRate(finance.debit_ratio, equityRatio, debitValue, equityValue);
            }
          }else if (finance.equity_value || finance.debit_value) {
            if(finance.equity_value){
              debitValue = value - finance.equity_value;
              equityRatio = finance.equity_value/value*100;
              debitRatio = 100 - equityRatio;
              this.updateRatioAndRate(debitRatio, equityRatio, debitValue, finance.equity_value);
            }else{
              equityValue = value - finance.debit_value;
              debitRatio = finance.debit_value/value*100;
              equityRatio = 100 - debitRatio;
              this.updateRatioAndRate(debitRatio, equityRatio, finance.debit_value, equityValue);
            }
          }

          break;

        case 'tax_rate':
          if(finance.equity_ratio && finance.debit_ratio && finance.interest_rate && finance.return_equity && value <= 100){
            this.updateWACC(finance.equity_ratio, finance.debit_ratio, value, finance.interest_rate, finance.return_equity);
          }
          break;
        case 'interest_rate':
          if(finance.equity_ratio && finance.debit_ratio && finance.tax_rate && finance.return_equity && value <= 100){
            this.updateWACC(finance.equity_ratio, finance.debit_ratio, finance.tax_rate, value, finance.return_equity);
          }
          break;
        case 'return_equity':
          if(finance.equity_ratio && finance.debit_ratio && finance.interest_rate && finance.tax_rate && value <= 100){
            this.updateWACC(finance.equity_ratio, finance.debit_ratio, finance.tax_rate, finance.interest_rate, value);
          }
          break;
        default:

      }
    }else{
      const errorType = {
        purchase_value: 'Please enter Purchase Value of Asset',
        tax_rate: 'Please enter Tax Rate',
        equity_ratio: 'Please enter Equity Ratio',
        debit_ratio: 'Please enter Debt Ratio',
        equity_value: 'Please enter Equity Value',
        debit_value: 'Please enter Debt value of Asset',
        interest_rate: 'Please enter Interest Rate on Debt p.a',
        return_equity: 'Please enter Expected Return On Equity'
      }
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          [type]: errorType[type]
        }
      }))
    }
  }

  updateWACC = (equityRatio, debitRatio, taxRate, interestRate, returnEquity) => {
    let value = (equityRatio/100*returnEquity/100)+(debitRatio/100*interestRate/100)*(1-taxRate/100);
    this.setState(prevState => ({
      ...prevState,
      finance: {
        ...prevState.finance,
        weighted_average_capital: parseFloat(value*100).toFixed(3)
      }
    }))
  }
  updateRatioAndRate = (debitRatio, equityRatio, debitValue, equityValue) => {
    this.setState(prevState => ({
      ...prevState,
      finance: {
        ...prevState.finance,
        equity_ratio: parseFloat(equityRatio).toFixed(2),
        debit_ratio:  parseFloat(debitRatio).toFixed(2),
        equity_value: equityValue ?  parseFloat(equityValue).toFixed(2):0,
        debit_value: debitValue ? parseFloat(debitValue).toFixed(2):0
      }
    }));
  }
  updateForm = (type, value) => {
    if (isNaN(value) || parseFloat(value) * 100 % 1 > 0) {
      return false;
    }
    this.setState(prevState => ({
      ...prevState,
      finance: {
        ...prevState.finance,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }));
  }
  addEditFinance = (e) => {
    e.preventDefault();
    const { finance } = this.state;
    let interest_rate = checkFloat({value:finance.interest_rate, required:true, message: 'Please enter Interest Rate on Debt p.a'});
    let return_equity = checkFloat({value:finance.return_equity, required:true, message: 'Please enter Expected Return on Equity p.a'})
    let equity_ratio = checkFloat({value:finance.equity_ratio, required:true, message: 'Please enter Equity Ratio'})
    let debit_ratio = checkFloat({value:finance.debit_ratio, required:true, message: 'Please enter Debt Ratio'})
    let tax_rate = checkFloat({value:finance.tax_rate, required:true, message: 'Please enter Tax Rate'})
    let equity_value = checkFloat({value:finance.equity_value, required:true, message: 'Please enter Equity Value'})
    let debit_value = checkFloat({value:finance.debit_value, required:true, message: 'Please enter Debt value of Asset'})
    let purchase_value = checkFloat({value:finance.purchase_value, required:true, message: 'Please enter Purchase value of Asset'})
    var validateNewInput = {
      purchase_value: purchase_value != '' ? purchase_value: parseFloat(finance.purchase_value) <= 1000000000000 ? '': 'Should be less or equal to 1 trillion' ,
      equity_value: equity_value != '' ?  equity_value : parseFloat(finance.equity_value) <= parseFloat(finance.purchase_value) ? '':'Should be less or equal to Asset Value' ,
      debit_value: debit_value != '' ? debit_value: parseFloat(finance.debit_value) <= parseFloat(finance.purchase_value) ? '':'Should be less or equal to Asset Value' ,
      interest_rate: interest_rate != '' ? interest_rate: parseFloat(finance.interest_rate) <= 100 ? '':'Please enter less then or equal to 100',
      return_equity: return_equity != '' ? return_equity: parseFloat(finance.return_equity) <= 100 ? '':'Please enter less then or equal to 100',
      equity_ratio: equity_ratio != '' ? equity_ratio: parseFloat(finance.equity_ratio) <= 100 ? '':'Please enter less then or equal to 100',
      debit_ratio: debit_ratio != '' ? debit_ratio: parseFloat(finance.debit_ratio) <= 100 ? '':'Please enter less then or equal to 100',
      tax_rate: tax_rate != '' ? tax_rate: parseFloat(finance.tax_rate) <= 100 ? '':'Please enter less then or equal to 100',
      weighted_average_capital: checkFloat({value:finance.weighted_average_capital, required:true, message: 'Please enter Weighted Average Cost of Capital'}),
    };
    if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.editFinanceFn(this.state.finance);
    }else{
      this.setState({
        error: validateNewInput
      })
    }
  }
  render(){
    const { finance, error } = this.state;
    const { type } = this.props;
    return(
      <form onSubmit={(e) => this.addEditFinance(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Purchase Value of Asset <sup className="reqStar"> * </sup>
                </FieldLabel>
                <FieldInput
                  value={finance.purchase_value}
                  type={type}
                  keyParam="purchase_value"
                  updateField={this.updateForm}
                  handleOnBlur={this.handleOnBlur}
                />
              <h6 className="error-msg">{error.purchase_value}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Tax Rate(%) <sup className="reqStar"> * </sup>
                </FieldLabel>
                <FieldInput
                  value={finance.tax_rate}
                  type={type}
                  keyParam="tax_rate"
                  updateField={this.updateForm}
                  handleOnBlur={this.handleOnBlur}
                />
              <h6 className="error-msg">{error.tax_rate}</h6>
              </FieldGroup>
            </FieldCol>
            <Col md="12">
              <Row>
                <Col md="6">
                  <span style={{paddingLeft: '22px'}}>
                    <FieldLabel className="label">
                      Debt / Equity Ratio {type != 'view'?<sup className="reqStar"> * </sup>:null}
                    </FieldLabel>
                  </span>
                  <ul classname="list-inline">
                    <li className="list-inline-item" style={{width:'130px'}}>
                      <FieldGroup className="form-group">
                        <FieldInput
                          value={finance.debit_ratio}
                          type={type}
                          keyParam="debit_ratio"
                          updateField={this.updateForm}
                          handleOnBlur={this.handleOnBlur}
                        />
                      </FieldGroup>
                    </li>
                    <li className="list-inline-item">:</li>
                    <li className="list-inline-item" style={{width:'130px'}}>
                      <FieldGroup className="form-group">
                        <FieldInput
                          value={finance.equity_ratio}
                          type={type}
                          keyParam="equity_ratio"
                          updateField={this.updateForm}
                          handleOnBlur={this.handleOnBlur}
                        />
                      </FieldGroup>
                    </li>
                  </ul>
                </Col>
              </Row>
              <div style={{marginTop: '-10px', paddingLeft: '22px'}}>
                <h6 className="error-msg">{error.debit_ratio}</h6>
                <h6 className="error-msg">{error.equity_ratio}</h6>
              </div>
            </Col>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Debt Value
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.debit_value}
                  type={type}
                  keyParam="debit_value"
                  updateField={this.updateForm}
                  handleOnBlur={this.handleOnBlur}
                />
              <h6 className="error-msg">{error.debit_value}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Equity Value
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.equity_value}
                  type={type}
                  keyParam="equity_value"
                  updateField={this.updateForm}
                  handleOnBlur={this.handleOnBlur}
                />
              <h6 className="error-msg">{error.equity_value}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Interest Rate on Debt p.a(%)
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.interest_rate}
                  type={type}
                  keyParam="interest_rate"
                  updateField={this.updateForm}
                  handleOnBlur={this.handleOnBlur}
                />
              <h6 className="error-msg">{error.interest_rate}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Expected Return on Equity p.a(%)
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={finance.return_equity}
                  type={type}
                  keyParam="return_equity"
                  updateField={this.updateForm}
                  handleOnBlur={this.handleOnBlur}
                />
              <h6 className="error-msg">{error.return_equity}</h6>
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        { type != 'view' ?
          <div className="submit-block">
             <input type="submit" className="primary-btn" value="Save Changes"/>
             <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
    )
  }
}
