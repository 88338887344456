import React, { Component } from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { Row, Col } from 'reactstrap'
import DatePicker from "react-datepicker"
import { fieldDateFormat } from '../../../constants'
import moment from 'moment'

export default class SearchFilter extends Component{
  constructor(){
    super()
  }

  render(){
    var { fileTypes, filters, filterOptions } = this.props
    var assets = []
    if(filterOptions.aircraft && filterOptions.aircraft.length){
      filterOptions.aircraft.map(asset => {
        assets.push({...asset, type: 1, label: "MSN " + asset.name})
      })
    }
    if(filterOptions.engine && filterOptions.engine.length){
      filterOptions.engine.map(asset => {
        assets.push({...asset, type: 2, label: "ESN " + asset.name})
      })
    }
    if(filterOptions.apu && filterOptions.apu.length){
      filterOptions.apu.map(asset => {
        assets.push({...asset, type: 3, label: "APU " + asset.name})
      })
    }
    if(filterOptions.lg && filterOptions.lg.length){
      filterOptions.lg.map(asset => {
        assets.push({...asset, type: 4, label: "LG " + asset.name})
      })
    }
    if(filterOptions.propeller && filterOptions.propeller.length){
      filterOptions.propeller.map(asset => {
        assets.push({...asset, type: 5, label: "PROPELLER " + asset.name})
      })
    }
    return(
      <div className="SearchFilter">
        <h6>Advance or Narrow down your search results</h6>
        <div className="filterContainer">
            <div className="filterField">
              <label>Assets</label>
              <Select
                className="custom-select-block"
                name="form-field-name"
                value={filters.multi_asset}
                valueKey="id"
                labelKey="label"
                multi = {true}
                options={assets}
                onChange={(target) => this.props.setFilter('multi_asset', target)}
              />
            </div>
            <div className="filterField">
              <label>File Type</label>
              <Select
                className="custom-select-block"
                name="form-field-name"
                value={filters.filter}
                valueKey="id"
                labelKey="label"
                multi = {true}
                options={filterOptions.file_type}
                onChange={(target) => this.props.setFilter('filter', target)}
              />
            </div>
            <div className="filterField">
              <label>Author</label>
              <Select
                className="custom-select-block"
                name="form-field-name"
                valueKey="id"
                labelKey="name"
                value={filters.user_id}
                multi = {true}
                options={filterOptions.user}
                onChange={(target) => this.props.setFilter('user_id', target)}
              />
            </div>
            <div className="filterField">
              <label>Created Date</label>
              <DatePicker
                dateFormat = {fieldDateFormat}
                selected={ filters.from_date }
                onChange={(e) => this.props.setFilter('from_date', moment(e))}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                maxDate={moment()}
                placeholderText="Start Date"
              />
            </div>
            <div className="filterField">
              <label>&nbsp;</label>
              <DatePicker
                dateFormat = {fieldDateFormat}
                selected={filters.to_date }
                onChange={(e) => this.props.setFilter('to_date', moment(e))}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
                placeholderText="End Date"
                minDate={filters.from_date}
                maxDate={moment()}
              />
            </div>
            <div className="filterField" style={{width: '100px'}}>
              <label>&nbsp;</label>
              {
                typeof filters === 'object' && Object.keys(filters).filter(key => filters[key] !== '' && (filters[key].length || Object.keys(filters[key]).length > 0)).length ?
                  <input type="button" onClick={() => this.props.advanceFilter()} class="primary-btn" value="Apply Filter"/>
                :null
              }
            </div>
            <div className="filterField" style={{width: '100px'}}>
              <label>&nbsp;</label>
              {
                typeof filters === 'object' && Object.keys(filters).filter(key => filters[key] !== '' && filters[key].length > 0 || Object.keys(filters[key]).length > 0).length ?
                  <a onClick={() => this.props.setFilter('','', true)}>Clear Filter</a>
                :null
              }
            </div>
        </div>
      </div>
    )
  }
}
