export const GET_PRJ_TRACKER_DETLS = 'GET_PRJ_TRACKER_DETLS';
export const GET_PRJ_TRACKER_DETLS_EXPANSION = 'GET_PRJ_TRACKER_DETLS_EXPANSION';
export const TRACKER_LOADER = 'TRACKER_LOADER';
export const GET_COR_TRACKER_DETLS='GET_COR_TRACKER_DETLS';
export const GET_COR_TRACKER_DETLS_EXP='GET_COR_TRACKER_DETLS_EXP';
export const GET_MAN_POWER_TRACKER_DETLS='GET_MAN_POWER_TRACKER_DETLS';
export const GET_MAN_POWER_TRACKER_DETLS_EXPANSION='GET_MAN_POWER_TRACKER_DETLS_EXPANSION';
export const TRACKER_EXPORT_LOADER='TRACKER_EXPORT_LOADER';
export const INFINITE_LOADER = 'INFINITE_LOADER';
export const UPDATE_REMARKS='UPDATE_REMARKS';
export const REMARKS_LOADER='REMARKS_LOADER';
export const UTILIZATION_GRAPH='UTILIZATION_GRAPH';

