import React, { Component} from 'react';
import { imgStoragePath } from '../../constants';
class NoRecordFound extends Component{
  constructor(props){
    super(props);
  }
  render(){return(
    <div className="no-records-found" style={this.props.style}>
      <img src={imgStoragePath+"not_found_icon.png"} alt={this.props.title ? this.props.title : 'No Data Found'} />
      <h5>{this.props.title ? this.props.title : 'No Data Found!'}</h5>
      <p dangerouslySetInnerHTML={{ __html: this.props.description}}></p>
      {this.props.children}
    </div>
  )}
}
export default NoRecordFound;
