import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import MjrAssyList from './MjrAssyList';
import YetTobeSentList from './YetTobeSentList';
import InShopUnservList from './InShopUnservList';
import InShopServList from './InShopServList';
import OffWingServList from './OffWingServList';
import OffWingUnservList from './OffWingUnservList';
import { addLg } from '../createTechSummary';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
export default class LandingGearTab extends Component{
  constructor(props){
    super(props);
  }
 render(){
   const { landingGear, index, asset_slug, techConstants } = this.props;
  return(
  <div className="horizontal-card card-block margin-0">
    {
      getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['landing_gears'].indexOf('U') != -1 ?
        <img onClick={() => this.props.viewLg('edit',landingGear, 'lgCrud',addLg, index)} src={imgStoragePath+"icon_editblock.png"} alt="img" className="blue-img" width="16" />:null
    }
    {getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['landing_gears'].indexOf('D') != -1) && landingGear.is_fitted && this.props.showTabs ?
      <img onClick={() => this.props.deleteMsnEngine()} style={{color: 'red', marginRight: '10px'}} src={imgStoragePath+"delete_red.png"} alt="img" className="blue-img" width="16" />
      :null
    }
    <Row>
    <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={landingGear.manufacturer}/>
    <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={landingGear.serial_number}/>
    <GenTabField grid="3" classText = "horizontal-inner-block " label="Part Number" text={landingGear.part_number}/>
    <GenTabField grid="3" classText = "horizontal-inner-block " label="Position" text={landingGear.position.label}/>

    <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Landing Gear titled for this MSN ?" text={landingGear.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
    <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Landing Gear fitted on this MSN ?" text={landingGear.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
    <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Landing Gear on MSN ?"   text={landingGear.on_wing_status ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
      {
        landingGear.on_wing_lessor  ?
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Landing Gear MSN"   text={landingGear.on_wing_msn}/>
        : null
      }
      {
        landingGear.on_wing_msn   ?
        <GenTabField grid="3" classText = "horizontal-inner-block " label="MSN Landing Gear Owner"   text={landingGear.on_wing_lessor}/>
        : null
      }
    </Row>
    {
            landingGear.off_wing_status.value ?
            <MjrAssyList dataList={landingGear} />
            : null
          }
          {
            landingGear.off_wing_status.value == 1 ?
          <YetTobeSentList dataList={landingGear} /> : null
          }

          {
          landingGear.off_wing_status.value == 2 ?
          <InShopServList techConstants={techConstants} dataList={landingGear} /> : null
          }

          {
          landingGear.off_wing_status.value == 3 ?
          <InShopUnservList techConstants={techConstants} dataList={landingGear} /> : null
          }

          {
          landingGear.off_wing_status.value == 4 ?
          <OffWingServList techConstants={techConstants} dataList={landingGear} /> : null
          }

          {
          landingGear.off_wing_status.value == 5 ?
          <OffWingUnservList techConstants={techConstants} dataList={landingGear} /> : null
          }
   </div>
  )
  }
}
