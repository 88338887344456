import React, { Component } from 'react';
export default class DashboardInvoiceHd extends Component {
  constructor(props){
    super(props)
  }
  shouldComponentUpdate(){
    return false;
  }
  render(){
    return(
      <thead>
        <tr>
          <th>Type</th>
          <th>Invoice #</th>
          <th>Invoice Date</th>
          <th>Invoice Due Date</th>          
          <th>Amount (USD)</th>
          <th>Status</th>
        </tr>
      </thead>
    )
  }
}
