import React from 'react';
import { GetInputField } from'../../Elements';
import { displayDateTimeFormat,imgStoragePath } from '../../../../constants';

import moment from 'moment';

const ConfirmList = (props) => {
  
  return (
    <tr >
      {props.data.asset_type && props.data.asset_type==2 ?
      <td>{props.data.esn?props.data.esn:'--'}</td>:
      <td>{props.data.msn?props.data.msn:'--'}</td>
      }
      <td>{ GetInputField(
                    'date',
                    null,
                    { type: 'edit',
                    value: props.data.inspection_due_date,
                    updateForm: (value) =>  props.updateForm('inspection_due_date', value, props.data.id,'confirm')
                    }
                  )}</td>
      <td>{props.data.quarter?props.data.quarter:'--'}</td>
      <td>{
                  GetInputField(
                    'dropdown',
                    null,
                    { type: 'edit',
                    value: props.data.risk_category&&props.data.risk_category.value ?props.data.risk_category.value:0,
                    options: props.techConstants.length?props.techConstants.filter(item => item.type == 'risk_level'):[],
                    labelKey: 'label',
                    valueKey: 'value',
                    updateForm: (value) =>  props.updateForm('risk_category', value, props.data.id,'confirm')
                    }
                  )
                }</td>
    <td>{props.data.lessee?props.data.lessee:'--'}</td>
    <td>{
      // props.data.inspection_type&&props.data.inspection_type.length ?'--':'--'
      GetInputField(
        'multiDropDown',
        null,
        { type: 'edit',
        value: props.data.inspection_type.length?props.data.inspection_type:"",
        options:props.inspectionType.length? props.inspectionType:[],
        labelKey: 'name',
        valueKey: 'id',
        updateForm: (value) => props.updateForm('inspection_type', value, props.data.id, 'confirm')
        }
      )
      }</td>
      <td>{ GetInputField(
                    'date',
                    null,
                    { type: 'edit',
                    value: props.data.last_inspection_date,
                    updateForm: (value) =>  props.updateForm('last_inspection_date', value, props.data.id,'confirm')
                    }
                  )}</td>

      <td>{props.data.date_of_manufacture?moment(props.data.date_of_manufacture).format(displayDateTimeFormat):'--'}</td>
      <td>{props.data.lease_expiry_date?moment(props.data.lease_expiry_date).format(displayDateTimeFormat):'--'}</td>
      <td className="crud-block">
        <ul className="list-inline table-action-col">
         <li className="list-inline-item" onClick={() => props.toggleComments(props.data.id,'confirm')}> <b className="comment-count comment-icon " style={{marginRight:'10px'}}><span>{props.data.comments&&props.data.comments.length?props.data.comments.length:'0'}</span></b></li>
         <li className="list-inline-item" onClick={() => props.toggleDelModal(props.data.id,'confirm')}> <img src={imgStoragePath + "delete_red.png"} style={{width:'15px',marginBottom:'10px'}} /></li>
        </ul>
      </td>
    </tr>
  )
}
export default ConfirmList;
