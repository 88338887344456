import React from 'react';
const Observations = (props) => {
    return (
    <tr>
      <td>
        <label className="for-label">{props.item.label}</label>
        <ul className="list-inline">
          { [1, 2, 3].map((label,index) =>
            <li className="list-inline-item">
              <label className="flex-centered">
                <input type="radio" checked = {label == props.answer.value} name={"audit-status-"+props.findingId} onClick={(e) =>  (props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save) ? props.updateObservations(props.findingId, label  , 'value'):null }/>
                <span className= { label == props.answer.value ?  "custom-radio-btn active" : "custom-radio-btn" } >
                  { label == props.answer.value ?  <b></b> : null }
                </span>
                { label == 1 ?  'Poor' : label == 2 ?  'Average' : 'Good'}
              </label>
            </li>
          )}
        </ul>
      </td>
      <td>
        <label className="for-label margin-0">Enter Remarks</label>
        { props.formInfo && props.formInfo.current_form && props.formInfo.current_form.permissions.can_save ?
          <textarea value={props.item.answer.remarks ? props.item.answer.remarks : ''} onChange={(e) =>  props.updateObservations(props.findingId, e.target.value, 'remarks')}/>:
          <p>{props.item.answer.remarks ? props.item.answer.remarks : '--'}</p>
        }
        { props.item.answer.value == 1 && ( !props.item.answer.remarks  || (props.item.answer.remarks && ( props.item.answer.remarks == "" || props.item.answer.remarks == null)) ) ?
            <h6 id="error-msg" className="error-msg">Please Enter Remarks</h6>
          : null
        }
      </td>
    </tr>
  )
}

export default Observations;
