import React, { Component } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col, Button, Popover, PopoverBody} from 'reactstrap';
import { imgStoragePath } from '../../../../../constants';

export default class AssetPopover extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      modalTitle: '',
      modal: false,
      delModal: false,
    };
  }

  toggle() {    
		this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }


  render() {
    const {  tagetId, placement, fileNames } = this.props;
    return (
        <span>
       <span>
        <img width="12" className="img_eye" id={tagetId} onClick={this.toggle} src={ imgStoragePath +'inv_info_icon.png'} alt="img" style={{ display: 'inline-block', marginLeft: '10px'}}/>
        <Popover placement ={placement} isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
            <PopoverBody>
						<div className="project-tracker-popover">
                        <div className="improper-files-heading">
                          <img style={{position:'absolute',top:'10px',right:'50%',width:'30px'}} src={imgStoragePath+"warning.png"}/>
                           <h2 style={{marginTop:'40px',fontSize:'16px',marginBottom:'10px'}}>File name should match {fileNames && fileNames.length == 1 ? '' : 'any of the'} following:</h2>
                           {
             fileNames && fileNames.length ?
              <div className='records-list-wrap' style={{margin: '0px'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { fileNames.length ?
                    fileNames.map((file) => <div className="record-list convention-list">{file}</div>) :null
                  }
                </div>
              </div>
              :null
            }
                           </div>
							</div>
            </PopoverBody>
        </Popover>
				</span> 
   
     </span>

    );
  }
}
