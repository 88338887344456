import * as actions from '../actions';
export default function(state={
  reportsInfo: {},
  exportList: [],
  filterLoader: false,
  reportLoader: true,
}, action){
  switch (action.type) {
    case actions.REPORTS_INFO:
      return {
        ...state,
        reportsInfo: action.payload
      }
    case actions.FILTER_LOADER:
      return {
        ...state,
        filterLoader: action.payload
      }
    case actions.REPORT_LOADER:
      return {
        ...state,
        reportLoader: action.payload
      }
    case actions.ADD_TO_EXPORT:
      return {
        ...state,
        exportList: state.exportList.filter(item => item.uuid == action.payload.uuid).length ? state.exportList.filter(item => item.uuid != action.payload.uuid) : [...state.exportList, action.payload]
      }
    case actions.CLEAR_EXPORT:
      return {
        ...state,
        exportList: action.payload
      }
    case actions.EXPORT_ALL:
      return{
        ...state,
        exportList: state.exportList.length == action.payload.length ? [] : action.payload.map((item) => {return {uuid: item.uuid, type: item.type, level: action.level}})
      }
    default:
      return state;
  }
}
