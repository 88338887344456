import React from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
const AfViewWrapper = (props) => {
  return(
    <div className="shop-visit-details">

      <Row className="title-info">
        <Col md="8">
          <Link to={props.backLink}><img src={imgStoragePath+"back_arw.png"} alt="" /></Link>
          <div style={{marginLeft: '26px'}}>
            <h4>{props.shopVisit.name}</h4>
            <p>{props.assemblyType=='airframe'? 'Event': 'Shop Visit'} Date : {moment(props.shopVisit.shop_visit_date).format(displayDateFormat)}</p>
          </div>
        </Col>
        <Col md="4"></Col>
      </Row>
      {props.children}
    </div>
  )
}
export default AfViewWrapper;
