import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
class SecondarySideBar extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  render() {
    return(
      <div className="sparta-secondary-sidbar">
        <ul className="list-unstyled">
          <li>
            <Link className={this.props.location.pathname.includes('airworthiness-list') || this.props.location.pathname.includes('airworthiness-detail') ? 'active': ''} to={'/'+this.props.params.type +'/'+this.props.params.aircraft_slug+'/airworthiness-list'}>AD Review Logs</Link>
          </li>
          <li>
            <Link className={this.props.location.pathname.includes('easa-list') ? 'active': ''} to={'/'+this.props.params.type +'/'+this.props.params.aircraft_slug+'/easa-list'}>EASA/FAA Database</Link>
          </li>
        </ul>
      </div>
    )
  }
}
export default withRouter(SecondarySideBar);
