
import React, {Component} from 'react';
import { Link } from 'react-router';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { imgStoragePath } from '../../../../constants';
import { localTimeFn } from '../../../../utils';
export default class EasaList extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  render(){
    const { item } = this.props;
    return(
      <tr>
        <td>
          {item.document_number}
          <img style={{width: '16px', marginLeft: '6px'}} id={'TooltipExample_'+item.document_number} src={imgStoragePath + 'blue_tooltip.png'} />
          <Tooltip autohide={false} innerClassName="ad-model-tooltip" placement="right" isOpen={this.state.tooltipOpen} target={'TooltipExample_' +item.document_number} toggle={this.toggle}>
            <ul className="list-unstyled">
              <li>
                <label className="for-label">Title</label>
                <h6>{item.title ? item.title:'--'}</h6>
              </li>
              <li>
                <label className="for-label">Url</label>
                <h6>
                  <a href={item.url ? item.url:''} target="_blank">{item.url ? item.url:'--'}</a>
                </h6>
              </li>
              <li>
                <label className="for-label">Last Update Date</label>
                <h6>{item.effective_date ? localTimeFn(item.effective_date): '--'}</h6>
              </li>
              <li>
                <label className="for-label">Subject</label>
                <h6>{item.subject ? item.subject: '--'}</h6>
              </li>
              <li>
                <label className="for-label">Type</label>
                <h6>{item.source ? item.source:'--'}</h6>
              </li>
            </ul>
          </Tooltip>
        </td>
        <td>{item.ad_sub_type ? item.ad_sub_type:'--'}</td>
        <td>{item.issue_date? localTimeFn(item.issue_date): '--'}</td>
        <td>{item.effective_date? localTimeFn(item.effective_date): '--'}</td>
        <td>{item.source ? item.source:'--'}</td>
      </tr>
    )
  }
}
