import React, { Component } from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import { downloadBaseUrl, imgStoragePath } from '../../../constants';
export default class ResourceFolder extends Component{
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }
  render (){
    const { name, size, hide, tooltipId} = this.props;
    const assetTypes = {
      'Engine or Module Assets' : 2,
      'Aircraft' : 1,
      'APU' : 3,
      'LG': 4,
      'Propeller': 5
    }
    return (
      hide? null:
      <Col  md="4" style={{ cursor: 'default'}}>
        <div className="folder-card">
          <img width="30" src={imgStoragePath+"folder_icn2.png"} alt="" />
          <div className="folder-info" onClick={this.props.downloadFn}>
            { size == '0 bytes'? <h4 style={{paddingTop:'5px'}}>{name}</h4>:<h4>{name}</h4> }
            <p>{size == '0 bytes'? '':size}</p>
          </div>
            <span className="rec-upload-icon" id={tooltipId} ><img  src={imgStoragePath+"phase-2/upload_template.png"}  alt="" /><input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e)=>this.props.uploadTemp(e.target.files[0],assetTypes[name])}/>
              <UncontrolledTooltip placement="top" target={tooltipId}>
                Upload
              </UncontrolledTooltip>
            </span>
            <img onClick={this.props.downloadFn} className="rec-download-icon" src={imgStoragePath+"phase-2/download_template.png"}  alt="" />

          {
            this.props.content ?
            <div className="notice-info config-card-info" style={{marginTop: '10px', display: 'none', marginBottom: '0px', marginLeft: '0px', textAlign: 'center'}}>
              <p>{this.props.content}</p>
            </div>
            :null
          }
        </div>
      </Col>
    )
   }
}
