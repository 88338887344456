import React, {Component, useState} from 'react';
import {Button, Modal,ModalBody} from 'reactstrap'
import '../assets/styles/records_a.scss';
class DownloadPopup extends Component {
    constructor(props){
        super(props)
    }
    render(){
        return (
        <div>
            <Modal  isOpen={this.props.flag} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }} size='sm' style={{maxWidth: "400px !important"}} className="bulk-data-modal-width">
                <ModalBody style={{height:"150px",padding:"30px 40px 28px 41px", textAlign:"center"}} onClick={this.props.bulkPopupToggle}>
                Your downloadable data size is larger than 500MB. Download link will be sent to the registered mail.
                <div style={{textAlign: 'center',marginTop:"10px", width:"528"}} onClick={this.props.bulkPopupToggle}>
                    <Button color="primary" style={{padding:"5px 20px"}} onClick={this.props.bulkPopupToggle} >OK</Button>
                </div>
                </ModalBody>
            </Modal>
        </div>
        )
    }
}
export default DownloadPopup
