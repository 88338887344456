import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import { getFileSize } from '../../../utils';
import { imgStoragePath, fileIcons } from '../../../constants';
class SearchCard extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { item } = this.props;
    const category = {
      1: 'technical',
      2: 'discrepancies',
      3: 'contracts',
      4: 'inspections'
    }
    return(
      <Col md="4">
        <div>
          <div className={item.type == 'file' ? 'preview-file search-card' : 'search-card'}>
            <div className="folder-info">
              <img src={ item.type == 'file' ? this.props.fileIcons[item.extension] :imgStoragePath+"folder_icn2.png"} alt="" style={item.type == 'file' ? {width : '20px', height: '20px'} : { width:'20px' }} />
            <h4><Link target={ item.type == 'file' ? '_blank' : ''} to={item.type == 'file' && item.extension == 'pdf' ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${item.path}#search=${this.props.searchTerm}` : item.type == 'file' && (item.extension == 'csv' || item.extension == 'xls' || item.extension == 'xlsx') ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${item.path}` : "/"+item.asset_type+"/"+item.asset_slug+"/records/data-room/" + category[item.category]+ '/' +item.uuid}>{item.name}</Link></h4>
            </div>
            {
              item.type == 'file' ?
              <div className="occurence-info">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span>Size:&nbsp;
                      {
                        (item.size!=0 && item.size!=null)?
                        `${getFileSize(item.size)}`: '0 bytes'
                      }
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <span>Occurence Count:&nbsp;
                      {
                        (item.occurrence_count!=0 && item.occurrence_count!=null)?
                        `${item.occurrence_count}`: 0
                      }
                    </span>
                  </li>
                  <li onClick={this.props.onDoubleClick} className="list-inline-item">
                    <span><i className="fa fa-eye"></i> Preview
                    </span>
                  </li>
                </ul>
            </div>
            : <div className="occurence-info">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span>Size:&nbsp;
                      {
                        (item.size!=0 && item.size!=null)?
                        `${getFileSize(item.size)}`: '0 bytes'
                      }
                    </span>
                  </li>
                  <li className="list-inline-item" style={{float: 'right'}}>
                    <span>Files:&nbsp;
                      {
                        (item.file_count!=0 && item.file_count!=null)?
                        `${item.file_count}`: 0
                      }
                    </span>
                  </li>
                </ul>
            </div>
            }
          </div>
        </div>
        </Col>
    )
  }
}
export default withRouter(SearchCard)
