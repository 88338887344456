import React, {Component} from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
export default class FilterDropdown extends Component {
    constructor(props){
        super(props);
        this.assetFilterChange = this.assetFilterChange.bind(this);
    }
    assetFilterChange(selectedOption){
        
        this.props.changeFilter(selectedOption, this.props.type, this.props.valueKey);
    }
    render(){
        return(
            <Select
                name="form-field-name"
                value={this.props.selectedValue}
                onChange={this.assetFilterChange}
                options={this.props.values}
                placeholder = {this.props.placeholder}
                matchProp = "any"
                labelKey = {this.props.labelKey}
                valueKey	= {this.props.valueKey}
            />
        )
    }
}
