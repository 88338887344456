import React, { Component } from 'react';
import { NoRecordFound } from '../../../../shared';

let data = [];

export default class EngineLLPAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      llp_list: []
    }
  }

  updateForm = (value, type) => {
    if(type){
       data.push(value)
    }else{
      var index = data.indexOf(value);
      data.splice(index, 1);
    }
    this.setState(prevState => ({
      ...prevState,
      llp_list: data
    }))
  }

  engineAddLLP = (e) => {
    e.preventDefault()
    this.props.engineAddLLP({llp_list: this.state.llp_list});
  }

  render() {
    const { engineLLPinfo } = this.props;
     return (
       <form onSubmit={(e) => this.engineAddLLP(e)}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Cycles Remaining</th>
            <th>Description</th>
            <th>List Price</th>
          </tr>
        </thead>
        <tbody>
          {
            engineLLPinfo.map((llpInfo, index) =>
            llpInfo.status == 1 ?
            <tr>
              <td> <input onChange={(e) => this.updateForm(llpInfo.id, e.target.checked)} type="checkbox" value={llpInfo.id}/></td>
              <td>{llpInfo.cycles_remaining}</td>
              <td>{llpInfo.description}</td>
              <td>{llpInfo.list_price}</td>
          </tr>:null
          )}
        </tbody>
      </table>
      {!engineLLPinfo.length ? <NoRecordFound description=""/>:null}
        {
          engineLLPinfo.length ?
          <div className="modal-footer">
          <button >Save</button>
        </div> :  null
        }

      </form>
    )
  }
}
