export const MR_ACCRUALS_GRAPH = 'MR_ACCRUALS_GRAPH';
export const ACCRUAL_PROGRAM_UPDATE = 'ACCRUAL_PROGRAM_UPDATE';
export const MR_FINANCE_GRAPH = 'MR_FINANCE_GRAPH';
export const MR_FINANCE_LOW = 'MR_FINANCE_LOW'
export const AIRFRAME_EVENT_CHANGE = 'AIRFRAME_EVENT_CHANGE';
export const UPDATE_AIRFRAME_PROGRAM = 'UPDATE_AIRFRAME_PROGRAM';
export const UPDATE_ENGINE_NUMBER = 'UPDATE_ENGINE_NUMBER';
export const GRAPH_WIDGET_SUM = 'GRAPH_WIDGET_SUM';
export const START_GRAPH_SIMULATION = 'START_GRAPH_SIMULATION';
export const UPDATE_SIMULATION_FIELDS = 'UPDATE_SIMULATION_FIELDS';
export const CASH_FLOW_MONLTY_BREAKUP_LIST = 'CASH_FLOW_MONLTY_BREAKUP_LIST';
export const EMPTY_CASH_FLOW_MONLTY_BREAKUP_LIST = 'EMPTY_CASH_FLOW_MONLTY_BREAKUP_LIST';
export const UPDATE_SIML_RESULT = 'UPDATE_SIML_RESULT';
export const SIMULATE_LOADER = 'SIMULATE_LOADER';
export const RESET_SIMULATION = 'RESET_SIMULATION';
export const MR_CUSTOM_REGION = 'MR_CUSTOM_REGION';
export const MR_CUSTOM_REGION_CHA = 'MR_CUSTOM_REGION_CHA'
export const CANCEL_REGION_BAR = 'CANCEL_REGION_BAR';
export const DE_RATE_VAR = 'DE_RATE_VAR';
export const UPDATE_DE_RATE_OP = 'UPDATE_DE_RATE_OP';
export const CLEAR_SIMULATION_DATA = 'CLEAR_SIMULATION_DATA';
export const MONTHLY_TOGGLE_DATA='MONTHLY_TOGGLE_DATA';
export const SIMULATE_LOADER_STOP='SIMULATE_LOADER_STOP';
