import * as actions from '../actions';
export default function( state = {
  insuraneList: [],
  claimList: [],
  insuranceCrud: {
    type: '',
    data: {},
    flag: false
  },
  claimCrud: {
    type: '',
    data: {},
    flag: false
  },
  insuranceLoader: true,
  claimLoader: true,
}, action){
  switch (action.type) {
    case actions.INSURANCE_LIST:
      return {
        ...state,
        insuraneList: action.payload
      }
    case actions.INSURANCE_ADD_SUCCESS:
      return {
        ...state,
        insuraneList: [action.payload, ...state.insuraneList]
      }
    case actions.INSURANCE_EDIT_SUCCESS:
      return {
        ...state,
        insuraneList: state.insuraneList.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.CLAIM_LIST:
      return {
        ...state,
        claimList: action.payload
      }
    case actions.CLAIM_ADD_SUCCESS:
      return {
        ...state,
        claimList: [action.payload, ...state.claimList]
      }
    case actions.CLAIM_EDIT_SUCCESS:
      return {
        ...state,
        claimList: state.claimList.map(list => list.id == action.payload.id ? action.payload:list)
      }
    case actions.INSURANCE_CRUD:
      return {
        ...state,
        [action.actionType]: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        [action.actionType]: {
          ...state[action.actionType],
          type: (action.payload == 'view' ? 'edit': 'view')
        }
      }
    case actions.INSURANCE_CLAIM_LOADER:
      return {
        ...state,
        insuranceLoader: action.payload
      }
    default:
      return state;
  }
}
