import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { FieldGroup, FieldTextarea, ContractBarLoader, EditSidebar, ShortHead } from '../../../contracts/Elements';
import { AssemblySideBar } from '../../Elements';
import AfViewWrapper from '../../AirframeAssembly/components/AfViewWrapper';
import AfViewCard from '../../AirframeAssembly/components/AfViewCard';
import AfClaimInfo from '../../AirframeAssembly/components/AfClaimInfo';
import AfClaimPayout from '../../AirframeAssembly/components/AfClaimPayout';
import AddEditShopVisit from '../../AirframeAssembly/components/AddEditShopVisit';
import AddEditClaims from '../../AirframeAssembly/components/AddEditClaims';
import AddEditPayout from '../../AirframeAssembly/components/AddEditPayout';
import { apuShopVisitCrudAc, addEditApuShopVisitAc, apuClaimsListAc, apuClaimInfoCrudAc, apuSVStatusAc, addEditApuClaimAc, apuClaimStatusAc } from '../actionCreators';
import { TOGGLE_VIEW_EDIT, APU_SHOP_VISIT_CRUD, APU_CLAIM_CRUD, APU_PAYOUT_CRUD } from '../actions';
import { eventTypeAc, assetInfoAc } from '../../AirframeAssembly/actionCreators';
import { checkString } from '../../../../formValidator';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import { ListLoader } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
class ApuView extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      claimModal:false,
      shopVisitReason: '',
      claim:'',
      error: {}
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleClaim = (claim_id) => {
    if(claim_id != undefined){
      this.setState({
        claimModal: !this.state.claimModal,
        claim:claim_id
      });
    }
    else{
      this.setState({
        claimModal: !this.state.claimModal,
        claim:''
      });
    }
  }

  updateSvReson = (e) => {
    let value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      shopVisitReason: value,
      error: {}
    }));
  }

  rejectClaim = (e) => {
    e.preventDefault();
    const validateNewInput = {
      shopVisitReason: checkString({value: this.state.shopVisitReason, required: true, minLength: '', maxLength: '', message: 'Please Enter Reason To Reject'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.changeClaimStatus(this.state.claim,{status:4, reason_for_rejection: this.state.shopVisitReason});
      this.toggleClaim();
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  rejectSv = (e) => {
    e.preventDefault();
    const validateNewInput = {
      shopVisitReason: checkString({value: this.state.shopVisitReason, required: true, minLength: '', maxLength: '', message: 'Please Enter Reason To Reject'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.changeSVStatus({status: 4, reason_for_rejection: this.state.shopVisitReason});
      this.toggle();
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  componentDidMount(){
    this.props.eventDetails();
    this.props.eventType();
    this.props.fetchClaims();
    this.props.fetchAssetInfo();
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Major Assemblies APU',
      item_type: 'Event Detail'
    })
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/'+(evt.asset_type == '1' ? 'apu':'engines');
    browserHistory.push(url);
    this.props.params.type = evt.asset_type == '1' ? assetsType[evt.asset_type]: 'engine';
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { error } = this.state;
    const { apuShopVisit, apuClaimInfo, eventTypes, assetInfoObj, apuClaims, apuPayoutInfo, apuLoader } = this.props;
    return(
      <div style={{marginLeft: '215px', marginTop: '94px'}}>
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} headingText="Maintenance" changeMsn={this.changeMsn}/>:<ContractBarLoader />
        }
        { apuLoader ? <ListLoader />:null }
        <AssemblySideBar params={this.props.params} />
          <div className="assembly-view-layout" style={{padding: '0 15px', marginLeft: '60px'}}>
            {
              Object.keys(apuShopVisit.data).length ?
              <div>
                <AfViewWrapper shopVisit={apuShopVisit.data} backLink={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/maintenance/apu'}>
                  <AfViewCard loader={apuLoader} changeSVStatus={this.props.changeSVStatus} editAssembly={this.props.editAssembly} toggle={this.toggle} shopVisit={apuShopVisit.data}  />
                </AfViewWrapper>
                {
                  apuShopVisit.data.status.value == 3 ?
                  <div className="add-claims-section">
                    <div className="add-claim-cta">
                      {
                        getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                        <h4>MR Claim</h4>
                        :null
                      }
                        {
                          getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                          <button onClick={() => this.props.claimInfoCrud('add', {})} className="btn-primary add-new">+ Add Claim</button>
                          :null
                        }
                    </div>
                    {
                      apuClaims.map((claim,index) =>
                      <div key={index} className="card-body">
                        { (claim.status.value == 1 ||  claim.status.value ==2) && getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                          <span onClick={() => this.props.toggleClaimEditBar({type: 'edit', data: claim, flag: true})} className="edit-icon">
                            <img src={imgStoragePath+"icon_editblock.png"} alt="icon" />
                          </span>:null
                        }
                        {
                          getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                          <div className="shop-visit-status">
                            <ul className="list-inline">
                              {
                                claim.status.value == 1 ?
                                <li className="list-inline-item">
                                  <span>Claim is Submitted </span>
                                  <button disabled={apuLoader} onClick={() => {this.props.changeClaimStatus(claim.id, {status: 2}); trackActivity('Item Status Changed', { page_title: 'Major Assemblies APU', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Move to Review' })}} className="btn-primary add-new">{apuLoader ? 'Processing...': 'Move to Review'}</button>
                                </li>:null
                              }
                              {
                                claim.status.value == 2 ?
                                <li className="list-inline-item">
                                  <span>Claim is Submitted </span>
                                  <button disabled={apuLoader} onClick={() => {this.props.changeClaimStatus(claim.id, {status: 3}); trackActivity('Item Status Changed', { page_title: 'Major Assemblies APU', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Approve' })}} className="btn-primary add-new">{apuLoader ? 'Processing...':'Approve'}</button>
                                  <button className="btn-primary add-new" onClick={() => {this.toggleClaim(claim.id); trackActivity('Item Status Changed', { page_title: 'Major Assemblies APU', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Reject' })}}>Reject</button>
                                </li>:null
                              }
                            </ul>
                          </div>
                          :null
                        }
                        <div>
                          <AfClaimInfo claim={claim} />
                        </div>
                        {
                          claim.status.value == 3 && getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                          <div className="claim-payout-detail">
                            {
                              claim.payout_status.value == 1 ?
                              <span className="edit-icon" onClick={() => this.props.togglePayoutEditBar({type: 'edit', data: claim, flag: true})}><img src={imgStoragePath+"icon_editblock.png"} alt="icon" /></span>:null
                            }
                            <h4>Claim Payout Details</h4>
                            <div className="shop-visit-status">
                              <ul className="list-inline">
                              { claim.payout_status.value == 1 ?
                                <li>
                                  <span>Claim Payout is Pending</span>
                                  <button
                                    disabled={!(claim.amount_paid > 0 && claim.amount_paid <= claim.claim_amount && claim.amount_paid != '')}
                                    className="btn-primary add-new"
                                    onClick={() => {this.props.changeClaimStatus(claim.id, {payout_status: 2}); trackActivity('Item Clicked', { page_title: 'Major Assemblies APU', application_module: 'Maintenance',  item_type:'Verify Amount', item_id:claim.id })}}>
                                    {apuLoader ? 'Processing...':'Verify Amount' }
                                  </button>
                                </li>
                                :null
                              }
                              {
                                claim.payout_status.value == 2 ?
                                <li>
                                  <span>Claim Payout Amount is Verified</span>
                                  <button
                                    disabled={!(claim.amount_paid > 0 && claim.amount_paid <= claim.claim_amount && claim.amount_paid != '')}
                                    className="btn-primary add-new"
                                    onClick={() => {this.props.changeClaimStatus(claim.id, {payout_status: 3}); trackActivity('Item Clicked', { page_title: 'Major Assemblies APU', application_module: 'Maintenance',  item_type:'Marked as Paid', item_id:claim.id })}}>
                                    {apuLoader ? 'Processing...': 'Mark As Paid'}
                                  </button>
                                </li>
                                :null
                              }
                              </ul>
                            </div>
                            <AfClaimPayout loader={apuLoader} claim={claim} />
                          </div>:null
                        }

                      </div>
                    )}
                  </div>:null
                }
                <EditSidebar
                  title={
                    apuShopVisit.type != '' ?
                    (apuShopVisit.type == 'add') ? 'Add Shop Visit for APU':
                    (apuShopVisit.type == 'edit') ? 'Edit Shop Visit for APU': 'View Shop Visit for APU' : ''
                    }
                  editSideBar={apuShopVisit.flag && apuShopVisit.type == 'edit'}
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: apuShopVisit.data, flag: false})}
                  >
                  {
                    Object.keys(apuShopVisit.data).length && apuShopVisit.flag && apuShopVisit.type == 'edit' ?
                    <div>
                      <AddEditShopVisit
                        type={apuShopVisit.type}
                        shopVisitObj={apuShopVisit.data}
                        eventTypes={eventTypes}
                        addEditShopVisit={(data, type)=>this.props.addEditShopVisit(data, type, assetInfoObj)}
                        assemblyType="apu"
                        toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: apuShopVisit.data, flag: false})}
                        loader={apuLoader}
                      />
                    </div>:null
                  }
                </EditSidebar>
                <EditSidebar
                  title={
                    apuClaimInfo.type != '' ?
                      (apuClaimInfo.type == 'add') ? 'Add Claim':
                      (apuClaimInfo.type == 'edit') ? 'Edit Claim': 'View Claim' : ''
                    }
                    toggleEditSideBar={() => this.props.toggleClaimEditBar({type: '', data: {}, flag: false})}
                    editSideBar={apuClaimInfo.flag}
                  >
                  {
                    Object.keys(apuClaimInfo.data).length && apuClaimInfo.flag ?
                    <div>
                      <AddEditClaims
                        type={apuClaimInfo.type}
                        claimObj={apuClaimInfo.data}
                        addEditClaims={(data, type)=>this.props.addEditClaims(data, type, assetInfoObj)}
                        assemblyType="apu"
                        toggleEditSideBar={() => this.props.toggleClaimEditBar({type: '', data: {}, flag: false})}
                        loader={apuLoader}
                        assetInfoObj={assetInfoObj}
                      />
                    </div>:null
                  }
                </EditSidebar>
                <EditSidebar
                  title="Edit Payout Detail"
                  toggleEditSideBar={() => this.props.togglePayoutEditBar({type: '', data: {}, flag: false})}
                  editSideBar={apuPayoutInfo.flag}
                  >
                  {
                    Object.keys(apuPayoutInfo.data).length && apuPayoutInfo.flag && apuPayoutInfo.type == 'edit' ?
                    <AddEditPayout
                      type="edit"
                      payoutObj={apuPayoutInfo.data}
                      addEditClaims={(data, type)=>this.props.addEditClaims(data, type, assetInfoObj)}
                      assemblyType="apu"
                      toggleEditSideBar={() => this.props.togglePayoutEditBar({type: '', data: {}, flag: false})}
                      loader={apuLoader}
                     />:null
                  }
                </EditSidebar>
              </div>:null
            }
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="sv-reject-modal">
            <ModalHeader toggle={this.toggle}>Reason For Rejection</ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => this.rejectSv(e)}>
                <FieldGroup className="form-group">
                  <textarea onChange={this.updateSvReson}></textarea>
                  <h6 className="error-msg">{error.shopVisitReason}</h6>
                </FieldGroup>
                <input disabled={apuLoader} type="submit" className="btn-primary add-new" value={apuLoader ? 'Processing...':'Save'} />
              </form>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.claimModal} toggle={this.toggleClaim} className="sv-reject-modal">
            <ModalHeader toggle={this.toggleClaim}>Reason For Rejection</ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => this.rejectClaim(e)}>
                <FieldGroup className="form-group">
                  <textarea onChange={this.updateSvReson}></textarea>
                  <h6 className="error-msg">{error.shopVisitReason}</h6>
                </FieldGroup>
                <input disabled={apuLoader} type="submit" className="btn-primary add-new" value={apuLoader ? 'Processing...': 'Save'} />
              </form>
            </ModalBody>
          </Modal>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  apuShopVisit: state.ApuAssemblyReducer.apuShopVisit,
  apuClaims: state.ApuAssemblyReducer.apuClaims,
  apuClaimInfo: state.ApuAssemblyReducer.apuClaimInfo,
  apuPayoutInfo: state.ApuAssemblyReducer.apuPayoutInfo,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  apuLoader: state.ApuAssemblyReducer.apuLoader,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    eventDetails: () => dispatch(apuShopVisitCrudAc(ownProps.params, 'view')),
    fetchClaims: () => dispatch(apuClaimsListAc(ownProps.params, {shop_visit_id: ownProps.params.id})),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'apu')),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params)),
    addEditShopVisit: (data, type, assetInfoObj) => dispatch(addEditApuShopVisitAc(ownProps.params, data, type, assetInfoObj)),
    claimInfoCrud: (type, id) => dispatch(apuClaimInfoCrudAc(ownProps.params, type, id)),
    addEditClaims: (data, type, assetInfoObj) => dispatch(addEditApuClaimAc(ownProps.params, data, type, assetInfoObj)),
    changeSVStatus: (data) => dispatch(apuSVStatusAc(ownProps.params, data)),
    changeClaimStatus: (id, data) => dispatch(apuClaimStatusAc(ownProps.params, id, data)),
    editAssembly: () => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: ''
    }),
    toggleEditBar: (data) => dispatch({
      type: APU_SHOP_VISIT_CRUD,
      payload: data
    }),
    toggleClaimEditBar: (data) => dispatch({
      type: APU_CLAIM_CRUD,
      payload: data
    }),
    togglePayoutEditBar: (data) => dispatch({
      type: APU_PAYOUT_CRUD,
      payload: data
    })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ApuView, ['maintenance','apu','R']));
