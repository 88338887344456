import React, { Component } from 'react';
import GraphFieldType from '../containers/GraphFieldType';
import NumericLabel from 'react-pretty-numbers';
import { imgStoragePath } from '../../../../constants';
export default class SummeryCardRowsData extends Component{
  constructor(props){
    super(props);
  }
  render() {
    const { rowRecord, eventType, type, label } = this.props;
    const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true}
    return(
      <tr>
        <td className="left-blk label-icon">
          <img src={imgStoragePath + (type == 'airframe' ? 'airframe_marker.png' : (type == 'apu') ? 'apu_marker.png' : 'lg_marker.png')} alt="icon" /> {rowRecord.name}
        </td>
        <td className="left-blk">
          $<GraphFieldType type={type} eventType={eventType} label={label} inputTpye="text" value={rowRecord.mr} />

        </td>
        <td className="left-blk">
          <GraphFieldType type={type} eventType={eventType} label={label} inputTpye="text" value={rowRecord.escalation_percentage} />%
        </td>
        <td className="left-blk" style={{width: '200px'}}>
          <GraphFieldType type={type} eventType={eventType} label={label} inputTpye="date" value={rowRecord.events[eventType].eventDate} />
        </td>
        <td className="right-blk">
          <NumericLabel params={prettyNumberParams}>{rowRecord.events[eventType].eventCost}</NumericLabel>

        </td>
        <td className="right-blk">
          <NumericLabel params={prettyNumberParams}>{rowRecord.events[eventType].fundCollected}</NumericLabel>

        </td>
        <td className="right-blk">
          <NumericLabel params={prettyNumberParams}>{ rowRecord.events[eventType].surPlus}</NumericLabel>
        <img className="profile-loss-icon" src={ imgStoragePath + (rowRecord.events[eventType].surPlus >= 0 ? 'profit_indicator.png' : 'loss_indicator.png')} alt="icon" />
        </td>
      </tr>
    )
  }
}
