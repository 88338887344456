import React, { Component } from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import {escalationIntervalLst , intervalList, displayDateFormat} from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import { ContrListCol } from '../../Elements';
export default class ReserveRateRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { rate, index } = this.props;
    const rateObj = [
      { value: rate.name ? rate.name:'--' },
      { value: rate.interval &&  rate.interval ? rate.interval.label : '--' },
      { value: rate.reserve_rate_per_interval >=0 ? <NumericLabel params={showCurrencyFormat(rate.currency)}>{rate.reserve_rate_per_interval}</NumericLabel>:'--' },
      { value: rate.rate_start_date ? moment(rate.rate_start_date).format(displayDateFormat) : '--'},
      { value: rate.rate_end_date ? moment(rate.rate_end_date).format(displayDateFormat) : '--'},
      { value: rate.rate_escalation_percentage ?  rate.rate_escalation_percentage + '%': '--' },
      { value: rate.rate_escalation_interval && rate.rate_escalation_interval.label ? rate.rate_escalation_interval.label : '--' }
    ]
    return(
      <tr style={{opacity:rate.status==0?0.5:1}} onClick={() => this.props.viewMrRate(rate.id, index)}>
        { rateObj.map((rate,index) => <ContrListCol colObj={rate} key={index}/> )}
      </tr>
    )
  }
}
