import * as actions from '../actions';
export default function(state={
  bulkDownloadPopup: false,
  ctrShortInfo: {},
  recordDirTree: {},
  shareKey:'',
  shareValidity:'',
  directoryLoader: false,
  downloadLoader:false,
  folderDirTree: {},
  progressPercentage: 0,
  popoverLoader: false,
  uploadFiles:[],
  validatedFiles: [],
  userEmails: [],
  userSelectedEmails:[],
  userSelectedPermission:true,
  userSelectedEmailLIst:[],
  recordDetail: {},
  aiSupportedFolder: [],
  checkModal:false,
  checkFileDetails:{},
  aiSupportedFolderFlag: false,
  expansionLoader: false,
  storageUsage:{},
  miniPackFolders: []
}, action){
  switch (action.type) {
    case actions.ASSET_DIR_TREE:
      return {
        ...state,
        recordDirTree: action.payload
      }
    case actions.MINPACK_FOLDERS:
      return {
        ...state,
        miniPackFolders: action.payload
      }
    case actions.STORAGE_USAGE:
      return {
        ...state,
        storageUsage: action.payload
      }
    case actions.DIRECTORY_EXPANSION_LOADER:
      return {
        ...state,
        expansionLoader: action.payload
      }
    case actions.ASSET_DIR_TREE_EXPAND:
      return {
        ...state,
        recordDirTree: {...state.recordDirTree, list: [...state.recordDirTree.list, ...action.payload.list], pagination: action.payload.pagination}
      }
    case actions.AI_SUPPORTED_FOLDERS_FLAG:
      return {
        ...state,
        aiSupportedFolderFlag: action.payload
      }
    case actions.AI_SUPPORTED_FOLDERS:
      return {
        ...state,
        aiSupportedFolder: action.payload
      }
    case actions.RECORD_DETAIL:
      return {
        ...state,
        recordDetail: action.payload
      }
    case actions.CREATE_FOLDER:
      return {
        ...state,
        recordDirTree: {...state.recordDirTree, list: [...state.recordDirTree.list, action.payload]}
      }
    case actions.SHARE_DATA:
      let recordDirTree = Object.assign([], state.recordDirTree);
      if(action.minipack){
        return {
          ...state,
          shareKey: action.payload.shareKey,
          shareValidity: action.payload.shareValidity,
        }
      }else{
        return {
          ...state,
          shareKey: action.payload.shareKey,
          shareValidity: action.payload.shareValidity,
          recordDirTree: {
            ...state.recordDirTree,
            list: state.recordDirTree.list.map(item => item.uuid == action.itemId ? { ...item, share_enabled: action.status, share_key: action.payload.shareKey, valid_till: action.payload.shareValidity}  :item)
          }
        }
      }
    case actions.SHARE_DATA_USER:
      return {
        ...state,
        recordDirTree: {
          ...state.recordDirTree,
          list: state.recordDirTree.list.map(item => item.uuid == action.itemId ? { ...item, user_shared_folder: action.status}  :item)
        }
      }
    case actions.SHARE_KEY:
      return {
        ...state,
        shareKey: action.payload.shareKey,
        shareValidity: action.payload.shareValidity,
      }
    case actions.DELETE_RECORD:
      return {
        ...state,
        recordDirTree: {
          ...state.recordDirTree,
          list: state.recordDirTree.list.filter(item => item.uuid != action.itemId)
        }
      }
    case actions.MOVE_RECORD:
      return {
        ...state,
        recordDirTree: {
          ...state.recordDirTree,
          list: state.recordDirTree.list.filter(item => item.uuid != action.payload)
        },
        folderDirTree:{}
      }
    case actions.DIRECTORY_LOADER:
      return {
        ...state,
        directoryLoader: action.payload
      }
      case actions.DOWNLOAD_LOADER:
      return {
        ...state,
        downloadLoader: action.payload
      }

    case actions.FOLDER_TO_MOVE:
      return {
        ...state,
        folderDirTree: action.payload
      }
    case actions.PROGRESS_PERCENTAGE:
      return{
        ...state,
        progressPercentage: parseFloat(action.payload) > parseFloat(state.progressPercentage) ?  action.payload : state.progressPercentage
      }
    case actions.FILES_UPLOAD:
      let uploadFiles = Object.assign([],action.payload);
      return{
        ...state,
        uploadFiles
      }
    case actions.POPOVER_LOADER:
      return{
        ...state,
        popoverLoader: action.payload
      }
    case actions.GET_VALIDATED_FILES:
      return{
        ...state,
        validatedFiles: action.payload
      }
    case actions.UPLOAD_FILE_DATA_ROOM:
      return{
        ...state,
        recordDirTree: {...state.recordDirTree, list: [
          ...state.recordDirTree.list,...action.payload
        ]}
      }
    case actions.FILE_FOLDER_CHECK:
      return{
        ...state,
        checkFileDetails:action.payload.data,
        checkModal:true
      }
    case actions.CANCEL_FILE_FOLDER_CHECK:
      return{
        ...state,
        checkModal:false
      }

      case actions.USER_SHARE_EMAIL_LIST:
      return{
        ...state,
        userEmails:action.payload
      }
      case actions.USER_SELECTED_EMAIL_LIST:
      return{
        ...state,
        userSelectedEmails:action.payload
      }
      case actions.USER_SELECTED_EMAIL_OBJECT:
      return{
        ...state,
        userSelectedEmailLIst:action.payload
      }
      case actions.UPDATE_SELECTED_EMAILS:
      return{
        ...state,
        userSelectedEmails:action.payload
      }
      case actions.UPDATE_SELECTED_PERMISSION:
      return{
        ...state,
        userSelectedPermission:action.payload
      }
      case actions.BULK_DOWNLOAD_POPUP:
        return {
          ...state,
          bulkDownloadPopup: action.payload
        }
    default:
      return state
  }
}
