import * as actions from '../actions';
import { globalGetService, globalPostService } from '../../../globalServices';
import { toastFlashMessage } from '../../../utils'
import { getNotificationBarDataAc } from '../../../shared/actionCreators'
import { browserHistory } from 'react-router'

export const getNotificationListAc = (filters) =>{
  return(dispatch =>{
    dispatch({
      type: actions.NOTIFICATION_LOADER,
      payload: true
    })
    let filterObj = Object.assign({}, filters);
    if(filterObj.created_at){
      let issueDate = filters.created_at.split(',');
      delete filterObj['created_at'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            end_date:date
          }
        }
      })
    }
    globalGetService('/notifications/', filterObj)
    .then(response => {
      dispatch({
        type: actions.NOTIFICATION_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.NOTIFICATION_LIST,
          payload: response.data.data
        })
      }
    })
  })
}

export const markNotificationReadAc = (notitficationId, filters) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.NOTIFICATION_LOADER,
      payload: true
    })
    globalPostService(`/notifications/edit/`, {notification_id: notitficationId,status: 1})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(getNotificationBarDataAc())
        dispatch(getNotificationListAc(filters))
      }
      dispatch({
        type: actions.NOTIFICATION_LOADER,
        payload: false
      })
    })
  }
}


export const markAllNotificationReadAc = () =>{
  return(dispatch) =>{
    dispatch({
      type: actions.NOTIFICATION_LOADER,
      payload: true
    })
    globalPostService(`/notifications/edit/`, {mark_all_read: true})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(getNotificationBarDataAc())
        dispatch(getNotificationListAc())
      }
      dispatch({
        type: actions.NOTIFICATION_LOADER,
        payload: false
      })
    })
  }
}

export const acceptRejectRequestAc = (requestId, status) => {
  return(dispatch) =>{
    dispatch({
      type: actions.NOTIFICATION_LOADER,
      payload: true
    })
    globalPostService(`console/asset-request/${requestId}/`, status)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(getNotificationBarDataAc())
        dispatch(getNotificationListAc())
        toastFlashMessage(response.data.message, 'success')
      }
    })
  }
}
