import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FieldGroup, FieldTextarea, ContractBarLoader, EditSidebar, ShortHead } from '../../../contracts/Elements';
import { AssemblySideBar } from '../../Elements';
import AddEditClaims from '../components/AddEditClaims';
import AfViewWrapper from '../components/AfViewWrapper';
import AfViewCard from '../components/AfViewCard';
import AfClaimInfo from '../components/AfClaimInfo';
import AfClaimPayout from '../components/AfClaimPayout';
import AddEditShopVisit from '../components/AddEditShopVisit';
import AddEditPayout from '../components/AddEditPayout';
import { afClaimInfoCrudAc, addEditAfClaimAc, addEditAfShopVisitAc, airframeShopVisitCrudAc, afSVStatusAc, afClaimsListAc, afClaimStatusAc, addEditAfPayoutAc, eventTypeAc, assetInfoAc } from '../actionCreators';
import { AF_CLAIM_CRUD, AF_PAYOUT_CRUD, TOGGLE_VIEW_EDIT, AF_SHOP_VISIT_CRUD } from '../actions';
import { checkString } from '../../../../formValidator';
import { browserHistory } from 'react-router'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import { ListLoader } from '../../../../shared';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
class AirframeView extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      claimModal: false,
      shopVisitReason: '',
      claim:'',
      error:{}
    };
    this.toggle = this.toggle.bind(this);
    this.toggleClaim = this.toggleClaim.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleClaim = (claim_id) => {
    if(claim_id != undefined){
      this.setState({
        claimModal: !this.state.claimModal,
        claim:claim_id
      });
    }
    else{
      this.setState({
        claimModal: !this.state.claimModal,
        claim:''
      });
    }
  }

  updateSvReson = (e) => {
    let value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      shopVisitReason: value,
      error: {}
    }));
  }
  rejectSv = (e) => {
    e.preventDefault();
    const validateNewInput = {
      shopVisitReason: checkString({value: this.state.shopVisitReason, required: true, minLength: '', maxLength: '', message: 'Please Enter Reason To Reject'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.changeSVStatus({status: 4, reason_for_rejection: this.state.shopVisitReason});
      this.toggle();
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  rejectClaim = (e) => {
    e.preventDefault();
    const validateNewInput = {
      shopVisitReason: checkString({value: this.state.shopVisitReason, required: true, minLength: '', maxLength: '', message: 'Please Enter Reason To Reject'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.changeClaimStatus(this.state.claim,{status:4, reason_for_rejection: this.state.shopVisitReason});
      this.toggleClaim();
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  componentDidMount(){
    this.props.eventDetails();
    this.props.eventType();
    this.props.fetchAssetInfo();
    this.props.fetchClaims();
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Major Assemblies Airframe',
      item_type: 'Event Detail'
    })
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/'+(evt.asset_type == '1' ? 'airframe':'engines');
    browserHistory.push(url);
    this.props.params.type = evt.asset_type == '1' ? assetsType[evt.asset_type]: 'engine';
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { error } = this.state;
    const { claimInfo, payoutInfo, afClaims, shopVisit, eventTypes, assetInfoObj, airframeLoader } = this.props;
    return(
      <div style={{marginLeft: '215px', marginTop: '94px'}}>
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} changeMsn={this.changeMsn} headingText="Maintenance" />:<ContractBarLoader />
        }
        <AssemblySideBar params={this.props.params} />
        { airframeLoader ? <ListLoader /> :null}
        <div className="assembly-view-layout" style={{padding: '0 15px',marginLeft:'60px'}}>

          { Object.keys(shopVisit.data).length ?
            <div>
              <AfViewWrapper assemblyType="airframe" shopVisit={shopVisit.data} backLink={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/maintenance/airframe'}>
                <AfViewCard loader={airframeLoader} toggle={this.toggle} assemblyType="airframe" changeSVStatus={this.props.changeSVStatus} editAssembly={this.props.editAssembly} shopVisit={shopVisit.data}  />
              </AfViewWrapper>
              { shopVisit.data.status.value == 3 ?
                <div className="add-claims-section">
                  <div className="add-claim-cta">
                    {
                      getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                      <h4>MR Claim</h4>
                      :null
                    }
                    {
                      getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                      <button onClick={() => this.props.claimInfoCrud('add', {})} className="btn-primary add-new">+ Add Claim</button>
                      :null
                    }
                  </div>
                  {
                    afClaims.map((claim,index) =>
                    <div key={index} className="card-body">
                      { (claim.status.value == 1 ||  claim.status.value ==2) && getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                        <span onClick={() => this.props.toggleClaimEditBar({type: 'edit', data: claim, flag: true})} className="edit-icon"><img src={imgStoragePath+"icon_editblock.png"} alt="icon" /></span>:null
                      }
                      {
                        getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                        <div className="shop-visit-status">
                          <ul className="list-inline">
                            { claim.status.value == 1 ?
                              <li className="list-inline-item">
                                <span>Claim is Submitted </span>
                                <button disabled={airframeLoader} onClick={() => {this.props.changeClaimStatus(claim.id, {status: 2}); trackActivity('Item Status Changed', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Move to Review' })}} className="btn-primary add-new">{ airframeLoader ? 'Processing...': 'Move to Review'}</button>
                              </li>:null
                            }
                            { claim.status.value == 2 ?
                              <li className="list-inline-item">
                                <span>Claim is Submitted </span>
                                <button disabled={airframeLoader} onClick={() => {this.props.changeClaimStatus(claim.id, {status: 3}); trackActivity('Item Status Changed', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Approve' })}}  className="btn-primary add-new">{airframeLoader ? 'Processing...': 'Approve'}</button>
                                <button className="btn-primary add-new" onClick={() => {this.toggleClaim(claim.id); trackActivity('Item Status Changed', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Claim', item_id:claim.id, status:'Reject' })}}>Reject</button>
                              </li>:null
                            }
                          </ul>
                        </div>
                        :null
                      }
                      <div>
                        <AfClaimInfo claim={claim} />
                      </div>
                      { claim.status.value == 3 && getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.claims && (getLocalStorageInfo().user.permission.maintenance.claims.indexOf('C') !== -1 || getLocalStorageInfo().user.permission.maintenance.claims.indexOf('U') !== -1) ?
                        <div className="claim-payout-detail">
                          { claim.payout_status.value == 1 ?
                            <span className="edit-icon" onClick={() => this.props.togglePayoutEditBar({type: 'edit', data: claim, flag: true})}><img src={imgStoragePath+"icon_editblock.png"} alt="icon" /></span>:null
                          }
                          <h4>Claim Payout Details</h4>
                          <div className="shop-visit-status">
                            <ul className="list-inline">
                              { claim.payout_status.value == 1 ?
                                <li>
                                  <span>Claim Payout is Pending</span>
                                  <button
                                    disabled={!(claim.amount_paid > 0 && claim.amount_paid <= claim.claim_amount && claim.amount_paid != '') }
                                    className="btn-primary add-new"
                                    onClick={() => {this.props.changeClaimStatus(claim.id, {payout_status: 2}); trackActivity('Item Clicked', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Verify Amount', item_id:claim.id })}}>
                                    {airframeLoader ? 'Processing...':'Verify Amount'}
                                  </button>
                                </li>
                                :null
                              }
                              { claim.payout_status.value == 2 ?
                                <li>
                                  <span>Claim Payout Amount is Verified</span>
                                  <button
                                    disabled={!(claim.amount_paid > 0 && claim.amount_paid <= claim.claim_amount && claim.amount_paid != '') }
                                    className="btn-primary add-new"
                                    onClick={() => {this.props.changeClaimStatus(claim.id, {payout_status: 3}); trackActivity('Item Clicked', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Marked as Paid', item_id:claim.id })}}>
                                    {airframeLoader ? 'Processing...': 'Mark As Paid'}
                                  </button>
                                </li>
                                :null
                              }
                              </ul>
                            </div>
                          <AfClaimPayout claim={claim} />
                        </div>:null
                      }
                    </div>
                  )}
                </div>:null
              }
              <EditSidebar
                title={
                  shopVisit.type != '' ?
                  (shopVisit.type == 'add') ? 'Add Maintenance Event':
                  (shopVisit.type == 'edit') ? 'Edit Maintenance Event': 'View Maintenance Event' : ''
                  }
                editSideBar={shopVisit.flag && shopVisit.type == 'edit'}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: shopVisit.data, flag: false})}
                >
                {
                  Object.keys(shopVisit.data).length && shopVisit.flag && shopVisit.type == 'edit' ?
                  <div>
                    <AddEditShopVisit
                      type={shopVisit.type}
                      shopVisitObj={shopVisit.data}
                      eventTypes={eventTypes}
                      assemblyType='airframe'
                      addEditShopVisit={(data, type)=>this.props.addEditShopVisit(data, type, assetInfoObj)}
                      toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: shopVisit.data, flag: false})}
                      loader={airframeLoader}
                    />
                  </div>:null
                }
              </EditSidebar>
              <EditSidebar
                title={
                  claimInfo.type != '' ?
                    (claimInfo.type == 'add') ? 'Add Claim':
                    (claimInfo.type == 'edit') ? 'Edit Claim': 'View Claim' : ''
                  }
                  toggleEditSideBar={() => this.props.toggleClaimEditBar({type: '', data: shopVisit.data, flag: false})}
                  editSideBar={claimInfo.flag}
                >
                {
                  Object.keys(claimInfo.data).length && claimInfo.flag ?
                  <div>
                    <AddEditClaims
                      type={claimInfo.type}
                      claimObj={claimInfo.data}
                      addEditClaims={(data, type)=>this.props.addEditClaims(data, type, assetInfoObj)}
                      assemblyType="airframe"
                      toggleEditSideBar={() => this.props.toggleClaimEditBar({type: '', data: shopVisit.data, flag: false})}
                      loader={airframeLoader}
                      assetInfoObj={assetInfoObj}
                    />
                  </div>:null
                }
              </EditSidebar>
              <EditSidebar
                title="Edit Payout Detail"
                toggleEditSideBar={() => this.props.togglePayoutEditBar({type: '', data: {}, flag: false})}
                editSideBar={payoutInfo.flag}
                >
                {
                  Object.keys(payoutInfo.data).length && payoutInfo.flag && payoutInfo.type == 'edit' ?
                  <AddEditPayout
                    type="edit"
                    payoutObj={payoutInfo.data}
                    addEditClaims={(data, type)=>this.props.addEditClaims(data, type, assetInfoObj)}
                    assemblyType="lg"
                    toggleEditSideBar={() => this.props.togglePayoutEditBar({type: '', data: {}, flag: false})}
                    loader={airframeLoader}
                   />:null
                }
              </EditSidebar>

            </div>:null
          }
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="sv-reject-modal">
          <ModalHeader toggle={this.toggle}>Reason For Rejection</ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.rejectSv(e)}>
              <FieldGroup className="form-group">
                <textarea onChange={this.updateSvReson}></textarea>
                <h6 className="error-msg">{error.shopVisitReason}</h6>
              </FieldGroup>
              <input type="submit" disabled={airframeLoader} className="btn-primary add-new" value={airframeLoader ? 'Processing...': 'Save'} />
            </form>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.claimModal} toggle={this.toggleClaim} className="sv-reject-modal">
          <ModalHeader toggle={this.toggleClaim}>Reason For Rejection</ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.rejectClaim(e)}>
              <FieldGroup className="form-group">
                <textarea onChange={this.updateSvReson}></textarea>
                <h6 className="error-msg">{error.shopVisitReason}</h6>
              </FieldGroup>
              <input type="submit" className="btn-primary add-new" value="Save" />
            </form>
          </ModalBody>
        </Modal>
      </div>

    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  shopVisit: state.AirframeAssemblyReducer.shopVisit,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  claimInfo: state.AirframeAssemblyReducer.claimInfo,
  payoutInfo: state.AirframeAssemblyReducer.payoutInfo,
  afClaims: state.AirframeAssemblyReducer.afClaims,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  airframeLoader: state.AirframeAssemblyReducer.airframeLoader,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params)),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'airframe')),
    eventDetails: () => dispatch(airframeShopVisitCrudAc(ownProps.params, 'view')),
    addEditShopVisit: (data, type, assetInfoObj) => dispatch(addEditAfShopVisitAc(ownProps.params, data, type, assetInfoObj)),
    fetchClaims: () => dispatch(afClaimsListAc(ownProps.params, {shop_visit_id: ownProps.params.id})),
    addEditClaims: (data, type, assetInfoObj) => dispatch(addEditAfClaimAc(ownProps.params, data, type, assetInfoObj)),
    claimInfoCrud: (type, id) => dispatch(afClaimInfoCrudAc(ownProps.params, type, id)),
    changeSVStatus: (data) => dispatch(afSVStatusAc(ownProps.params, data)),
    changeClaimStatus: (id, data) => dispatch(afClaimStatusAc(ownProps.params, id, data)),
    editAssembly: () => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: ''
    }),
    toggleEditBar: (data) => dispatch({
      type: AF_SHOP_VISIT_CRUD,
      payload: data
    }),
    toggleClaimEditBar: (data) => dispatch({
      type: AF_CLAIM_CRUD,
      payload: data
    }),
    togglePayoutEditBar: (data) => dispatch({
      type: AF_PAYOUT_CRUD,
      payload: data
    })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(AirframeView, ['maintenance', 'airframe', 'R']));
