import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';

export default class GeneralDetail extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { generalDetails, error, detailErr } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row> 
				<Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Report Date<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
               value: generalDetails.report_date,
               error: detailErr.report_date,
               updateForm: (value) => this.props.updateForm({ type: 'report_date', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Report Issued Place<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: generalDetails.report_issued_place,
              error: detailErr.report_issued_place,
              updateForm: (value) => this.props.updateForm({ type:'report_issued_place', value:value, sectionType: 'details'})
              }
            )
          }
         </Col>
      </Row>
    </div>
    );
  }
}
