import React from 'react';
import { imgStoragePath } from '../../../../constants';
import {  ExportFiles } from '../../../../shared';
const BulkOptConfirm = (props) => {
  return(
    <div className={"add-bulk-operations "}>
      <ul className="list-inline flex-centered"> 
       {<li className="list-inline-item operation-cta">
          <ExportFiles exportFile={(fileType) => props.assetExport('confirm',fileType)} files={[{img:'excel_icon.png', label: 'Excel', type:'xls'}]} />
      </li>}
      </ul>
      <button  onClick={() => props.toggleSidebar()} className="aims-primary-btn">{props.addText ? props.addText : 'Pass Add Text'}</button>
    </div>
  )
}
export default BulkOptConfirm;
