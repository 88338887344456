import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { DeleteModal,
  PrerequisiteWrapper,
  EditFormBar,
  SecondaryTechHeaderRH,
  SecondaryTechHeaderMid,
  FormHeader,
  ImageAnnotator,
  DownloadedLogs,
  ImageSlider
  } from '../../../Elements';
import { imgStoragePath } from '../../../../../constants';
import CKeditor from "../../../../../shared/CKEditor";
import { downLoadTechEngineFormAc } from '../../../../../shared/actionCreators'
import { getFormDetailsAc,updateFormStatusAc } from '../../T001/actionCreators';
import { ExportFiles, MSNSwitcherHeader, ListLoader, TableUIComp, NoRecordFound, LicenceModal } from '../../../../../shared';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { fetchTableInspectionAc, AddEditDisAssemblyAc, updateTableInspectionAc, GenUrlAc, deleteShopVisitAc, editViewDisAssemBlkAc, saveEditedImageAc, deletePicAc, editPlatePicAc } from '../actionCreators';
import classnames from 'classnames';
import { arrayMove } from 'react-sortable-hoc';
import { GeneralDetail, LastShopVisit, BulkOperation, ShopvisitFileList, LLPData, DisassemblyFinding, AddEditDisAssembly} from '../components';
import { UPDATE_GEN_IP_FIELDS,
  UPDATE_SV_IP_FIELDS, LLP_IP_UPDATE, UPDATE_SV_DETAIL_UPDATE,
  UPDATE_SV_DESC, ADD_LLP_DETAILS, TOGGLE_SV,
  DISS_ASSEM_SIDEBAR, UPDATE_DISS_ASSEM_FIELD,
  ADD_SV_WORKSCOPE_ITEM, ADD_SV_WORKSCOPE_MODULE,
  ADD_SV_WORKSCOPE_SUBMODULE, UPDATE_SV_WORK_SCOPE,
  DEL_SV_WORKSCOPE_ITEM, EDIT_DA_IMAGE, UPDATE_SV_ERR, SORT_PICTURES_TE005
} from '../actions';
import { checkString, checkEmail, checkValidDate, checkFloat, checkIntNumber } from '../../../../../formValidator';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators';
import { BUY_LICENCE } from '../../../../../shared/actions';
import { getLocalStorageInfo } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';

class InspectionFormTE005 extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: '1',
      classCustom : '',
      llpDetailObj : {
        delAttachmentModal: false,
        deleteId: '',
        deleteIndex: '',
        preReqModal: false,
        deleteType: '',
        description : '',
        part_number: '',
        serial_number: '',
        csn: '',
        cycles_remaining: '',
        status: '',
        remarks:'',
        engine_work_order: ''
      },
      dissAssemblyFinding : {
        id: '',
        title: '',
        remarks: '',
        finding_pictures: []
    },
    downloadFormLogs: false,
    imageSlider: {
      images: [],
      startIndex: 0
    },
    sliderModal: false
  }
}


  componentDidMount(){
    this.props.getFormDetails(2, 'TE005');
    this.props.fetchTableInspectionFn();
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
  }

  GenUrlFn = (files, type) =>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.GenUrlFn(files, type)
  }

  openSlider = (pictures, startIndex) => {
    let items = [];

    if(pictures){
      pictures.map(picture => {
        items.push({original: picture.image, thumbnail: picture.image})
      });
    }
    this.setState(prevState => ({
      ...prevState,
      sliderModal: !prevState.modal,
      imageSlider: {
        ...prevState.imageSlider,
        images: items,
        startIndex: startIndex
      }
    }));
  }

  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggleDelAttachmentModal = (id=null, type=null, index) => {
    this.setState({
      delAttachmentModal: !this.state.delAttachmentModal,
      deleteType: type,
      deleteId: id,
      deleteIndex: index
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  togglePrerequisiteModal = () => {
    this.setState({
      preReqModal: !this.state.preReqModal,
    });
  }
  updateTableInspection = () => {
    const validateNewInput = {
      shop_visit_number: checkString({value: this.props.tableInspectionDetails.shop_visit.shop_visit_number, required:Object.keys(this.props.tableInspectionDetails.shop_visit).length?true:false, minLength: '', maxLength: '', message: 'Please enter Shop Visit Number'}),
      date_of_removal: checkValidDate({value: this.props.tableInspectionDetails.shop_visit.date_of_removal, required: false, minLength: '', maxLength: '', message: 'Please select valid Date of Removal'}),
			tsn: checkFloat({value: this.props.tableInspectionDetails.shop_visit.tsn, required: false, minLength: '', maxLength: '', message: 'Please enter valid tsn'}),
      csn: checkIntNumber({value: this.props.tableInspectionDetails.shop_visit.csn, required: false, minLength: '', maxLength: '', message: 'Please enter valid csn'}),
      tslsv: checkFloat({value: this.props.tableInspectionDetails.shop_visit.tslsv, required: false, minLength: '', maxLength: '', message: 'Please enter valid tslsv'}),
      cslsv: checkIntNumber({value: this.props.tableInspectionDetails.shop_visit.cslsv, required: false, minLength: '', maxLength: '', message: 'Please enter valid cslsv'}),
      tsr: checkFloat({value: this.props.tableInspectionDetails.shop_visit.tsr, required: false, minLength: '', maxLength: '', message: 'Please enter valid tsr'}),
			csr: checkIntNumber({value: this.props.tableInspectionDetails.shop_visit.csr, required: false, minLength: '', maxLength: '', message: 'Please enter valid csr'}),
			tso: checkFloat({value: this.props.tableInspectionDetails.shop_visit.tso, required: false, minLength: '', maxLength: '', message: 'Please enter valid tso'}),
      cso: checkIntNumber({value: this.props.tableInspectionDetails.shop_visit.cso, required: false, minLength: '', maxLength: '', message: 'Please enter valid cso'}),
      tshi: checkFloat({value: this.props.tableInspectionDetails.shop_visit.tshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid tshi'}),
      cshi: checkIntNumber({value: this.props.tableInspectionDetails.shop_visit.cshi, required: false, minLength: '', maxLength: '', message: 'Please enter valid cshi'}),
      induction_date: checkValidDate({value: this.props.tableInspectionDetails.shop_visit.induction_date, required: false, minLength: '', maxLength: '', message: 'Please select valid Induction Date'}),
      arc_date: checkValidDate({value: this.props.tableInspectionDetails.shop_visit.arc_date, required: false, minLength: '', maxLength: '', message: 'Please select valid Arc Date'}),
      date_of_reinstallation: checkValidDate({value: this.props.tableInspectionDetails.shop_visit.date_of_reinstallation, required: false, minLength: '', maxLength: '', message: 'Please select valid Date of Reinstallation'}),
    };

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateTableInspectionFn(this.props.tableInspectionDetails)
		}else{
			this.props.updateShopVisitErr(validateNewInput);
		}
  }

  updateGenCkeditor = (evt, type, sectionType) => {
    var newContent = evt.editor.getData();
    this.props.updategenDetailFn({type: type, sectionType: 'sectionType', value: newContent})
  }

  updateCkeditor = (evt, type, sectionType) => {
    var newContent = evt.editor.getData();
    this.props.updateSvDetailFn({type: type, sectionType: sectionType, value: newContent})
  }

  scrollToBottom() {
    this.te005End.scrollIntoView({ behavior: 'smooth' });
 }


  sortFiles = (args, pictures) =>{
   if(args[0].oldIndex != args[0].newIndex){
      let sortedPictures = [];
       pictures = arrayMove(pictures, args[0].oldIndex, args[0].newIndex);
       pictures.map(picture => sortedPictures.push(picture))
       this.props.sortPictures(sortedPictures);
     }

     this.setState(prevState => ({
      ...prevState,
      classCustom: ''
    }))
  }

  sortStart = () => {
    this.setState(prevState => ({
      ...prevState,
      classCustom: 'hideActionblock'
    }))
  }

  saveImage = () =>{
    document.querySelector("#annotator").contentWindow.document.querySelector('#btn-download').click();
    setTimeout(() => {
      // this.setState(prevState => ({
      //   ...prevState,
      //   editedImage: document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src')
      // }))
      this.props.saveEditedImage(this.props.findngImgCrud.data.data.id, document.querySelector("#annotator").contentWindow.document.querySelector('#editedImage').getAttribute('src'), this.props.findngImgCrud.index, this.props.disAssemblyCrud)
    }, 1000)
	 }


  render(){
    const { tableInspectionDetails, disAssemblyCrud, tblInpLoader,techExportLoader, findngImgCrud,  svErr,formStatusLoader, formInfo} = this.props;

    if(!Object.keys(tableInspectionDetails).length){
      return false
    }
    return(
      <div className="technical-inspection-cn">
       <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={this.props.formInfo.project} />}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={this.props.formInfo.project} activeAsset={this.props.formInfo.asset}/>}
          activeAsset={this.props.formInfo.asset}
          projectSlug = {Object.keys(this.props.formInfo).length ? this.props.formInfo.project.slug: ''}
        />
        <FormHeader
          formInfo={this.props.formInfo}
          saveButton = {<Link  style={tblInpLoader ?  { pointerEvents : 'none', opacity: '0.5'} : { pointerEvents : 'auto', opacity: '1'}} onClick={() => this.updateTableInspection()} className="primary-btn"> { tblInpLoader ? 'Processing...' : 'Save Changes'}  </Link>}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('table-inspections','ESN_'+this.props.formInfo.asset.esn+'_'+this.props.formInfo.current_form.name,fileType)}
          exportName={'Export Report '}
          files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'}]} />}
          updateFormStatus={(data) => this.props.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
         />
        <div className="form-te005-cn">
          <div className="technical-inspection-ee">
          {formStatusLoader||
          techExportLoader ?
          <ListLoader/>
          : null
          }
          <h4 className="tech-heading-block flex-centered">
            General Details
            <span onClick={() => {this.togglePrerequisiteModal(); trackActivity('Read Prerequisite Clicked', { page_title: 'TE005', application_module: 'Inspection',  item_type:'Table Inspection' })} } className="pre-requisite flex-centered">

             <img  src={imgStoragePath + "read_pre_icon.png"} alt="img" className="blue-img" />
             Read Prerequisite
            </span>
          </h4>

            <GeneralDetail
              generalDetail = {tableInspectionDetails.general_details}
              updateForm = {this.props.updategenDetailFn}
            />

              <div className='tableInspec lsv-block'>
              <h4 className="tech-heading-block flex-centered">
                Last Shop Visit
                { this.props.formInfo && this.props.formInfo.list && this.props.formInfo.list.length && this.props.formInfo.list.filter(item => item.name=="TE004").length?
                <Link className="view-all" to={`/engine/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.formInfo.list.filter(item => item.name=="TE004")[0].slug}/te004`}>View all shop visits</Link>:null}
                </h4>
                <div className="general-detail-block" style={{background: '#fff'}}>
                <LastShopVisit
                type = {this.props.toggleSvFlag ? 'edit' : 'view'}
                shopVisit = {tableInspectionDetails.shop_visit}
                updateForm = {this.props.shopVisitfieldUpdateFn}
                editViewBlk = {() => this.props.editViewBlk(!this.props.toggleSvFlag)}
                svErr = {svErr}
               />
                  <TableUIComp
                    noStickyHeader={true}
                    hover={true}
                    tableBulkOp = {<BulkOperation GenUrlFn = {this.GenUrlFn} addText= "Create"   addComponent= {() => this.props.addComponent(this.state.componentStruct)}   />}
                    filter={false}
                    tableHeight= "auto"
                    theads={[{label:'File Name', sortKey: ''}, {label:'Description', sortKey: ''}, {label:'Uploaded on', sortKey: '' },{label:'Uploaded by', sortKey: ''}, {label: 'Action', sortKey: ''}]}
                    bulkOperation={false}
                    pagination={false}
                    content={tableInspectionDetails.shop_visit && tableInspectionDetails.shop_visit.attachments && tableInspectionDetails.shop_visit.attachments.length ? tableInspectionDetails.shop_visit.attachments.map((shopVisit, index) => <ShopvisitFileList index={index} shopVisit = {shopVisit} key={index} index={index} updateForm = {this.props.updateDesc} toggleDelModal={this.toggleDelAttachmentModal}/>)  : null}
                    // content={boroscopeInspDet.components_inspected.map((componentInspected, index) => <InspectedComponents  error= { error && error[1] ? error[1][index] : ''} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, componentInspected.id)} key={index} index={index} content={componentInspected} updateForm={this.updateForm}/>) }
                    recordsFound = {tableInspectionDetails.shop_visit && tableInspectionDetails.shop_visit.attachments && tableInspectionDetails.shop_visit.attachments.length ? false : true}
                  />
                </div>
              </div>
             </div>

          <div className="edit-view-tech-spec" style={{margin: '0'}}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); trackActivity('Item Clicked', { page_title: 'TE005', application_module: 'Inspection',  item_type:'Executive Summary' })}}
            >
              Executive Summary
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); trackActivity('Item Clicked', { page_title: 'TE005', application_module: 'Inspection',  item_type:'Disassembly Findings' })}}
            >
              Disassembly Findings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); trackActivity('Item Clicked', { page_title: 'TE005', application_module: 'Inspection',  item_type:'LLP Details' })}}
            >
              LLP Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); trackActivity('Item Clicked', { page_title: 'TE005', application_module: 'Inspection',  item_type:'Workscope' })}}
            >
              Workscope
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
             <CKeditor
                events={{"change": (e) => this.updateGenCkeditor(e, 'executive_summary', 'general_details')}}
                content= {tableInspectionDetails.general_details.executive_summary}
              />
          </TabPane>
          <TabPane tabId="2" className="tableInspec dis-assembly">
            <TableUIComp
                noStickyHeader={true}
                hover={true}
                tableBulkOp = {<div className="bulk-operation" > <Link  onClick={() => this.props.dispatch({type: DISS_ASSEM_SIDEBAR, payload: {type: 'add', flag: true, data: this.state.dissAssemblyFinding}})}><button class="btn btn-primary" style={{padding:'4px 10px 6px'}}><span style={{fontSize:'14px', color:'#ffffff'}}>Add Finding</span></button></Link></div>}
                filter={false}
                tableHeight= "auto"
                theads={[{label:'Sl No.', sortKey: ''}, {label:'Title', sortKey: ''}, {label:'Description', sortKey: '' }, {label: '', sortKey: ''}]}
                bulkOperation={false}
                pagination={false}
                content={tableInspectionDetails.disassembly_findings.map((disAssemblyFnding, index) => <DisassemblyFinding deleteImgFn = {this.props.deleteImgFn} toggleDelModal={this.toggleDelAttachmentModal}  editViewDisAssemBlk={this.props.editViewDisAssemBlk} disAssemblyFnding = {disAssemblyFnding} toggleComDelModal={() => this.toggleComDelModal('Are you sure to delete?', index, '')} key={index} index={index} updateForm={this.updateForm}/>) }
                recordsFound = {tableInspectionDetails.disassembly_findings.length ? false : true}
              />
          </TabPane>
          <TabPane tabId="3" className="tableInspec llp-detail-block">
            <TableUIComp
                noStickyHeader={true}
                hover={false}
                tableBulkOp = {<div className="bulk-operation" > <Link onClick={() => {this.props.llpDetails(this.state.llpDetailObj);this.scrollToBottom()}}><button class="btn btn-primary" style={{padding:'4px 10px 6px'}}><span style={{fontSize:'14px', color:'#ffffff'}}>Add Item</span></button></Link></div>}
                filter={false}
                tableHeight= "auto"
                tableScroll='none'
                theads={[{label:'Sl No.', sortKey: ''}, {label:'LLP Description', sortKey: ''}, {label:'P/N', sortKey: '' },{label:'S/N', sortKey: ''}, {label: 'LLP CSN', sortKey: ''},  {label: 'Cycles Remaining', sortKey: ''},  {label: 'Status', sortKey: ''},  {label: 'Comments', sortKey: ''}, {label: '', sortKey: ''}]}
                bulkOperation={false}
                pagination={false}
                content={tableInspectionDetails.llp_details.map((llpData, index) => <LLPData toggleDelModal={this.toggleDelAttachmentModal} index={index} llpDataList = {llpData} key={index} index={index} updateForm={this.props.llpFieldUpdateFn}  />) }
                recordsFound = {tableInspectionDetails.llp_details.length ? false : true}
              />
              <p style={{marginTop:'-10px',marginBottom:'10px'}}>LLP summary</p>
             <CKeditor
                  events={{"change": (e) => this.updateGenCkeditor(e, 'llp_summary', 'general_details')}}
                  content= {tableInspectionDetails.general_details.llp_summary}
                />
          </TabPane>
          <TabPane tabId="4">
            <div className="">
              <div>
                <div className="workscope-button row">
                <div className="col-md-12">
                    <Link className="add-item"  onClick={() => {this.props.addWorkScopeModule();this.scrollToBottom()}} style={{cursor: 'pointer'}}>
                    <button class="btn btn-primary" style={{padding:'4px 10px 6px'}}><span style={{fontSize:'14px', color:'#ffffff'}}>Add Item</span></button>
                    </Link>
                  </div>
              </div>
                <div className="asset-list-filter-sort">
                  <Row className="workscope-row filter-block workscope-table-heading ">
                      <Col md="2" className="flex-centered border-right border-left">
                        <h6><span style={{cursor:'default'}}>Major Module</span></h6>
                      </Col>
                      <Col md="10" className="flex-centered padding-0 ">
                        <Row className="workscope-sub-row margin-0">
                            <Col md="3" className="submodule-col flex-centered border-right ">
                              <h6><span style={{cursor:'default'}}>Sub Module</span></h6>
                            </Col>
                            <Col md="9" className="flex-centered padding-right-0">
                              <Row className="workscope-sub-row flex-not-centered margin-0 ">
                                <Col md="2" className="item-col flex-centered border-right">
                                  <h6><span style={{cursor:'default'}}>Module No</span></h6>
                                 </Col>
                                <Col md="5" className="item-col flex-centered border-right ">
                                  <h6><span style={{cursor:'default'}}>Module Description</span></h6>
                                </Col>
                                <Col md="4" className="item-col workscope-level border-right ">
                                  <h6><span style={{cursor:'default'}}>Workscope Level</span></h6>
                                  <Row>
                                      <Col md="6" className="item-col border-right ">
                                        <h6><span style={{cursor:'default'}}>Initial</span></h6>
                                      </Col>
                                      <Col md="6" className="item-col">
                                        <h6><span style={{cursor:'default'}}>Final</span></h6>
                                      </Col>
                                  </Row>
                                </Col>
                                <Col md="1" className="border-right"></Col>
                              </Row>
                            </Col>
                        </Row>
                      </Col>
                  </Row>
                </div>
                <div className="engine-workscope-table">
                  {tableInspectionDetails.general_details.workscope_modules&&
                    tableInspectionDetails.general_details.workscope_modules.length ?
                    tableInspectionDetails.general_details.workscope_modules.map((workScope, index) =>
                    <Row className="workscope-row" key={index}>
                    <Col md="2" className="module-col border-left">
                      <div className="module-content">
                        <input type="text" value={workScope.name} onChange={evt => this.props.updateWorkScopes('majorModule','name',evt.target.value,index)}/>
                       <span style={{cursor: 'pointer'}} onClick={() => this.props.addWorkscopeSubModule(index)} className="add-new-row">+ Add a Sub Module</span>
                      </div>
                    </Col>
                     <Col md="10" className="padding-0">
                        {
                          workScope.subModule.map((sb, childIndex) =>
                          <Row key={childIndex} className="workscope-sub-row margin-0">
                            <Col md="3" className="submodule-col border-right">
                              <div className="submodule-content">
                                <input type="text" value={sb.name} onChange={evt => this.props.updateWorkScopes('childModule','name',evt.target.value,index,childIndex)}/>
                              </div>
                            </Col>
                            <Col md="9" className="flex-centered padding-right-0">
                              {
                                sb.items.map((item, itemIndex) =>
                                <Row key={itemIndex} className="workscope-item-row flex-not-centered margin-0">
                                  <Col md="2" className="item-col flex-centered border-right">
                                    <input type="text" value={item.moduleNo} onChange={evt => this.props.updateWorkScopes('itemModule','moduleNo',evt.target.value,index,childIndex,itemIndex)}/>
                                    {(sb.items.length - 1) == itemIndex ?
                                      <span onClick={() => this.props.addWorkScopeItem(index, childIndex)} className="add-new-row">+Add Another</span>:null
                                    }
                                  </Col>
                                  <Col md="5" className="item-col flex-centered border-right">
                                    <input type="text" value={item.moduleDescription} onChange={evt => this.props.updateWorkScopes('itemModule','moduleDescription',evt.target.value,index,childIndex,itemIndex)}/>
                                  </Col>
                                  <Col md="4" className="item-col workscope-level border-right flex-not-centered">
                                    <Row>
                                      <Col md="6" className="border-right flex-centered">
                                        <input type="text" value={item.initialLevel} onChange={evt => this.props.updateWorkScopes('itemModule','initialLevel',evt.target.value,index,childIndex,itemIndex)}/>
                                      </Col>
                                      <Col md="6" className="flex-centered">
                                        <input type="text" value={item.finalLevel} onChange={evt => this.props.updateWorkScopes('itemModule','finalLevel',evt.target.value,index,childIndex,itemIndex)}/>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="1" className="item-col flex-centered flex-center border-right">
                                    <a onClick={() => this.props.delWorkScopeItem(index, childIndex,itemIndex)} className="del-row">
                                     <img width="12"  className="" src={imgStoragePath+"delete_red.png"}/>
                                    </a>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>
                   </Row>
                    ) : null
                  }
                  {
                    tableInspectionDetails.general_details.workscope_modules && tableInspectionDetails.general_details.workscope_modules.length ? null : <NoRecordFound/>
                  }
                </div>
              </div>
            </div>
          </TabPane>
          <div ref={el => { this.te005End = el; }} />
        </TabContent>
       </div>
       </div>


       <EditFormBar
          titleContent= "Add Disassembly Finding"
          toggleEditSideBar={() => this.props.dispatch({type: DISS_ASSEM_SIDEBAR, payload: {type: '', flag: false, data: {}}})}
          isOpen={disAssemblyCrud.flag}
          style={disAssemblyCrud.flag ? {width: '840px'} : {width: '0px'}}
          >
          { disAssemblyCrud.flag ?
            <AddEditDisAssembly
            disAssemblyCrud = {disAssemblyCrud}
            updateForm = {this.props.updateDisasemblyFields}
            uploadDissAssembFiles = {this.GenUrlFn}
            addEditDisAsemFinding = {this.props.updateDisasemblyFn}
            toggleEditSideBar={() => this.props.dispatch({type: DISS_ASSEM_SIDEBAR, payload: {type: '', flag: false, data: {}}})}
            onSortEnd={(...args) => this.sortFiles(args, disAssemblyCrud.data.finding_pictures)}
            onSortStart	 = {(evt) => {evt.preventDefault();this.sortStart()}}
            axis="xy"
            classCustom	 = {this.state.classCustom}
            openSlider={this.openSlider}
            editImageFn = {this.props.editPlateImage}
            deleteImgFn = {this.props.deleteImgFn}
            tblInpLoader = {tblInpLoader}
            sortPictures={this.props.sortPictures}
            />
            : null
          }
        </EditFormBar>


        {/* image annotator */}

        <EditFormBar
          titleContent= "Edit Image"
          // { <div><h3>Edit {editBoroscpPicCrud.data.name} Picture</h3></div>}
          toggleEditSideBar={() => this.props.editImageFn({flag: false, data: {}})}
          isOpen={findngImgCrud.flag}
          style={findngImgCrud.flag ? {width: '840px'} : {width: '0px'}}
          >
          { findngImgCrud.flag ?
            <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px',  paddingBottom: '90px'}}>
              <ImageAnnotator style={{marginLeft: '20px'}} markup={false}
              image={findngImgCrud.data.image} width="800" markup={true} height="500" color="ff0000" fontSize="12"
              />
            </div>:null
          }
          <div className="edit-sidebar-footer" style={findngImgCrud.flag ? {width: '840px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 840) + 'px', zIndex: '1001'} : {width: '0px', left: window.innerWidth + 'px'}}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button   style={tblInpLoader ?  { pointerEvents : 'none', opacity: '0.5'} : { pointerEvents : 'auto', opacity: '1'}} onClick={this.saveImage} type="submit" className="btn btn-primary">{tblInpLoader ? 'Processing...':'Save Changes'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={() => this.props.editImageFn({flag: false, data: {}, index: ''})}> Cancel</Link>
                </li>
              </ul>
            </div>
        </EditFormBar>

          {/* pre requiste modal */}
        <DeleteModal isOpen={this.state.preReqModal}
         toggle={() => this.togglePrerequisiteModal()}
         title={'Prerequisite'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <PrerequisiteWrapper form={'te005'}/>
        </DeleteModal>

       <DeleteModal isOpen={this.state.delAttachmentModal} toggle={() => this.toggleDelAttachmentModal('')} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelAttachmentModal('');this.props.deleteAttachmentFn(this.state.deleteId, this.state.deleteType, this.state.deleteIndex)}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelAttachmentModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
        {
          tblInpLoader ?
          <ListLoader/>
          : null
        }
        {
          this.state.sliderModal ?
          <Modal isOpen={this.state.sliderModal} toggle={() => this.setState({sliderModal: false})} className="modal-tform-pictures">
            <ModalHeader toggle={() => this.setState({sliderModal: false})}>{this.state.modalTitle}</ModalHeader>
            <ModalBody>
              <ImageSlider imageSlider={this.state.imageSlider} />
            </ModalBody>
          </Modal>
          :null
        }
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="2"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
     </div>
    )
  }
}
const mapStateToProps = (state) => ({
  formInfo: state.FormT001Reducer.formInfo,
  tableInspectionDetails: state.FormTE005Reducer.tableInspectionDetails,
  toggleSvFlag: state.FormTE005Reducer.toggleSvFlag,
  disAssemblyCrud: state.FormTE005Reducer.disAssemblyCrud,
  tblInpLoader: state.FormTE005Reducer.tblInpLoader,
  findngImgCrud: state.FormTE005Reducer.findngImgCrud,
  techExportLoader:state.sharedReducers.techExportLoader,
  svErr : state.FormTE005Reducer.svErr,
  formStatusLoader:state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.DataRoomReducer.storageUsage,
});
const mapDispatchToProps = (dispatch,ownProps) =>{

  return{
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    fetchTableInspectionFn: () =>  dispatch(fetchTableInspectionAc(ownProps)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data,2)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechEngineFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    updategenDetailFn: (obj) => dispatch({
      type: UPDATE_GEN_IP_FIELDS,
      payload: obj
    }),
    shopVisitfieldUpdateFn : (obj) =>  dispatch({
      type:  UPDATE_SV_IP_FIELDS,
      payload: obj
    }),
    llpFieldUpdateFn: (obj) => dispatch({
      type: LLP_IP_UPDATE,
      payload: obj
    }),
    updateSvDetailFn: (obj) => dispatch({
      type: UPDATE_SV_DETAIL_UPDATE,
      payload: obj
    }),
    updateTableInspectionFn: (obj) => dispatch(updateTableInspectionAc(ownProps, obj)),
    GenUrlFn: (files, type) => dispatch(GenUrlAc(ownProps, files, type)),
    updateDesc:  (data) => dispatch({
      type: UPDATE_SV_DESC,
      payload: data
    }),
    llpDetails : (data) =>  dispatch({
      type: ADD_LLP_DETAILS,
      payload: data
    }),
    sortPictures: (data) => dispatch({
      type: SORT_PICTURES_TE005,
      payload: data
    }),
    deleteAttachmentFn: (id, type, index) => dispatch(deleteShopVisitAc(ownProps, id, type, index)),
    editViewBlk : (flag) => dispatch({
      type: TOGGLE_SV,
      payload: flag
    }),
    updateDisasemblyFields: (data) => dispatch({
      type: UPDATE_DISS_ASSEM_FIELD,
      payload: data
    }),
    addWorkScopeItem: (index, childIndex) => dispatch({
      type: ADD_SV_WORKSCOPE_ITEM,
      payload:{index, childIndex}
    }),
    addWorkScopeModule: () => dispatch({
      type: ADD_SV_WORKSCOPE_MODULE,
      payload:{}
    }),
    addWorkscopeSubModule: (index) => dispatch({
      type: ADD_SV_WORKSCOPE_SUBMODULE,
      payload: {index}
    }),
    updateWorkScopes:(type,key,value,index,childIndex,itemIndex) => dispatch({
      type: UPDATE_SV_WORK_SCOPE,
      payload: {type,key,value,index,childIndex,itemIndex}
    }),
    delWorkScopeItem: (index, childIndex,itemIndex) => dispatch({
      type: DEL_SV_WORKSCOPE_ITEM,
      payload:{index, childIndex,itemIndex}
    }),
    updateDisasemblyFn: (data) => dispatch(AddEditDisAssemblyAc(ownProps, data)),
    editViewDisAssemBlk: (id,type) => dispatch(editViewDisAssemBlkAc(ownProps, id,type)),
    editImageFn: (data) => dispatch({
      type: EDIT_DA_IMAGE,
      payload: data
    }),
    saveEditedImage: (id, src, index, crudObj) => dispatch(saveEditedImageAc(ownProps, id, src, index, crudObj)),
    deleteImgFn: (id, index) =>  dispatch(deletePicAc(ownProps, id, index)),
    updateShopVisitErr: (errData) => dispatch({
      type: UPDATE_SV_ERR,
      payload: errData
    }),
    editPlateImage: (data) => dispatch(editPlatePicAc(ownProps, data)),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormTE005);
