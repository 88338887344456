import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import moment from 'moment'
import '../../../maintenance/MRFinanaceRpt/assets/styles/logListing.scss';
import { assetStatus, displayDateFormat } from '../../../../constants';
import { getAccessToken } from '../../../../utils';
export default class ListingRow extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { list, params } = this.props;
    return(
      <Link to={"/" +params.type+ "/" +params.aircraft_slug+ '/contract/list/' +list.slug} className="contract-element">
        <Row className="logList">
          <Col md="4" xs="4">
            <p className="para-ui">
              {list.lease_type && list.lease_type.value ? list.lease_type.label : '---'}
            </p>
          </Col>
          <Col md="2" xs="2">
            <p className="para-ui" >
              <img src={list.lessee.logo} style={{maxWidth: '75px'} } alt={list.lessee.name}/>
            </p>
          </Col>
          <Col md="4" xs="4">
            <p className="para-ui" >
              {
                moment(list.contractual_start_date).format(displayDateFormat) + ' '
              }
              to
              {
                ' ' + moment(list.contractual_end_date).format(displayDateFormat)
              }
            </p>
          </Col>
          <Col md="2" xs="2">
            <span className={"asset-status status-block status-" + list.lease_status.value } >
              {list.lease_status.label}
            </span>
          </Col>
        </Row>
      </Link>
    )
  }
}
