import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TabPane } from 'reactstrap';
import { AddEditPropeller,PropellersList,TableHeadingTechSummary, GenericAssemblyLLPs } from '../components';
import { fetchTechSummaryListAc, updateProperllerAc, techSummaryCrudAc, deleteMsnAssetAc, exportPropellerLLpsAc, importPropellerLLPsAc, deletePropellerLLPAc } from '../actionCreators';
import { EditSidebar, CtrTableHd, FormModeType, FieldSelect, FieldCol, FieldGroup, FieldLabel } from '../../../contracts/Elements'
import { TOGGLE_TECH_SMRY_VIEW,TECH_SMRY_CRUD } from '../actions';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { NoRecordFound  } from '../../../../shared';
import { addPropeller } from '../createTechSummary';
import { getLocalStorageInfo } from '../../../../utils';
import { Row, UncontrolledTooltip } from 'reactstrap'
import { globalGetService, globalPostService } from '../../../../globalServices';
import { DeleteModal } from '../../../technicalInspection/Elements';
class PropellersContent extends Component{
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			engines: [],
			linkEnigne: {
				flag: false,
				engine: {
					stand_alone_link: true,
					stand_alone_asset_id: null,
					position: 1
				}
			},
			deleteEngine:{
				flag: false,
				engine: {}
			}
		}
	}

	getEnignesList = () =>{
		globalGetService(`console/stand-alone-asset/`, {asset_type: 'propeller'})
		.then(response => {
			if(response.data.statusCode === 200){
				this.setState({
					engines: response.data.data.propeller
				})
			}
		})
	}

	deleteMsnEngine = () =>{
		this.props.deleteMsnEngine(this.state.deleteEngine.engine.slug)
		this.setState({
			deleteEngine: {flag: false, engine:{}}
		})
	}

	linkEnigne = () =>{
		if(this.state.linkEnigne.engine.stand_alone_asset_id === null || this.state.linkEnigne.engine.stand_alone_asset_id === ""){
			this.setState({error: 'Please select Propeller'})
		}
		if(this.state.linkEnigne.engine.position === null || this.state.linkEnigne.engine.position === ""){
			this.setState({positionError: 'Please select Propeller Gear position'})
		}
		setTimeout(() => {
			if(this.state.error != '' || this.state.positionError != ''){
				return
			}else{
				setTimeout(() => {
					globalPostService(`console/aircraft/${this.props.asset_slug}/propeller/`, this.state.linkEnigne.engine)
					.then(response => {
						if(response.data.statusCode === 200){
							this.setState({linkEnigne: {flag: false, engine: {
								stand_alone_link: true,
								stand_alone_asset_id: null,
							}}})
							this.getEnignesList()
							this.props.fetchTechSummaryList(this.props.asset_slug, 'propellerList');
						}
					})
				}, 600)
			}
		}, 300)
	}

	updateEngineField = (key, value) => {
		this.setState({
			error: '',
			positionError: ''
		})
		this.setState({
			linkEnigne:{
				...this.state.linkEnigne,
				engine: {...this.state.linkEnigne.engine, [key]: value}
			}
		})
	}

  componentDidMount() {
		this.props.fetchTechSummaryList(this.props.asset_slug, 'propellerList');
		this.getEnignesList()
		this.props.getOwnerList()
  }
	render(){
		const { propellerList, techSmryView, asset_slug, techSpecsLoader, propellerCrud, techConstants, ownerList } = this.props;
		var showTabs = false
		let lessorIds = []
		 if(window.location.href.includes('qa')){
			 lessorIds = [241, 86]
		 }else if(window.location.href.includes('dev')){
			 lessorIds = [289,89]
		 }else{
			 lessorIds = [86]
		 }
		if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
			showTabs = true
		}
		return(
			<TabPane tabId="5" className="general-block">
			 <p style={{height: '50px'}}>
			 <span style={{float: 'right'}}>
			 {
				  showTabs ?
					<button style={{marginRight: '20px'}} className="btn btn-primary add-new" onClick={() => this.setState({linkEnigne: {flag: true, engine: {stand_alone_link: true,
	 			 stand_alone_asset_id: null,is_fitted: true,
				 is_titled: false, position: 1}}, error: '', positionError: ''})}> + Link Propeller</button>
				 :null
			 }
			 {
				!showTabs && getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['avionics'].indexOf('C') != -1) &&
				 <button style={{marginRight: '20px', float: 'right'}} className="btn btn-primary add-new" onClick={() => this.props.techSummaryCrud('add', {}, 'propellerCrud',addPropeller)}> + Add Propeller</button>
			 }
			 </span>
			 </p>
				<div className="contr-table-blk">
					<TableHeadingTechSummary techSummaryCrud={() => this.props.techSummaryCrud('add', {}, 'propellerCrud',addPropeller)} title='Propellers' addButton={false}/>
					<div className="table-container">
						<table className="common-table">
							<CtrTableHd theads={['Serial Number', 'Part Number', 'Model', 'Manufacturer', 'Is Fitted', 'Is Titled', 'Installed Position','']}/>
							<tbody>
								{propellerList.map((propeller, index) =>
									<PropellersList
										showTabs={showTabs}
										deleteMsnEngine={() => this.setState({deleteEngine: {flag: true, engine: propeller}})}
										key={index}
										index={index}
										asset_slug={asset_slug}
										propeller={propeller}
										viewAvionic={() => this.props.techSummaryCrud('edit', {...propeller, id: propeller.slug}, 'propellerCrud')} />
								)}
							</tbody>
						</table>
					</div>
					{!techSpecsLoader && !propellerList.length ?
						<NoRecordFound description="Get Started by clicking '+ Add' button above" /> :  null
					}
				</div>
				{propellerList.map((propeller, index) =>
					<GenericAssemblyLLPs
						llp={propeller.llps}
						partNumber={propeller.part_number}
						assemblyType='propeller'
						assemblySlug={propeller.slug}
						heading="propeller"
						assembly={propeller}
					/>
				)}

				<EditSidebar
				title={
					propellerCrud.type != '' ?
					(propellerCrud.type == 'add') ? 'Add Propeller':
					(propellerCrud.type == 'edit') ? 'Edit Propeller': 'View Propeller' : ''
					}
				editSideBar={propellerCrud.flag}
				toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'propellerCrud')}>
					<div>
						{Object.keys(propellerCrud.data).length && propellerCrud.flag ?
							<AddEditPropeller
                techConstants={techConstants}
								addEditAvionic={this.props.addEditTechSummary}
								type={propellerCrud.type}
								propellerCrud={propellerCrud.data}
								updateForm={this.props.toggleEditBar}
								overview={this.props.overview}
								showAdvanced={showTabs}
								toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'propellerCrud')}
								ownerList={ownerList}
							/>

							:null
						}
					</div>
				</EditSidebar>
				<DeleteModal isOpen={this.state.linkEnigne.flag} toggle={() => this.setState({linkEnigne: {flag: false, engine: {stand_alone_link: true,
				stand_alone_asset_id: null,
				position: 1}}})} title="Link Propeller" className="modal-tform-delete-pictures">
					<div>
					<Row>
					<FieldCol md="12">
						<FieldGroup className="form-group">
							<FieldLabel className="label">Propeller</FieldLabel>
								<FieldSelect
									placeholder='Select Propeller'
									keyParam="stand_alone_asset_id"
									value={this.state.linkEnigne.engine.stand_alone_asset_id}
									type='edit'
									options={this.state.engines}
									updateField={this.updateEngineField}
									labelKey= 'serial_number'
									valueKey='id'
								/>
								<h6 className="error-msg">{this.state.error}</h6>
							</FieldGroup>
						 </FieldCol>
						 <FieldCol md="12">
							<FieldGroup className="form-group">
								<FieldLabel className="label">Propeller Position</FieldLabel>
									<FieldSelect
										placeholder='Select Position'
										keyParam="position"
										value={this.state.linkEnigne.engine.position}
										type='edit'
										options={this.props.techConstants.filter(item => item.type === 'engine_position')}
										updateField={this.updateEngineField}
										labelKey= 'label'
										valueKey='value'
									/>
									<h6 className="error-msg">{this.state.positionError}</h6>
								</FieldGroup>
							 </FieldCol>
							 <FieldCol md="6">
							 <div className="form-group" style={{display: "block",margin: "25px 0px"}}>
								 <label className="label">
									 <input
										 type="checkbox"
										 checked={this.state.linkEnigne.engine.is_fitted}
										  onChange={(e) =>{ this.updateEngineField('is_fitted',true); setTimeout(() => this.updateEngineField('is_titled', false),300)}}
									 />
									 <span>
										 Is Propeller fitted on this MSN ?
										 <i class="fa fa-info-circle" aria-hidden="true" id="engine-field2" style={{paddingLeft: '4px'}}></i>
										 <UncontrolledTooltip placement="right" target="engine-field2">
											 Tick if the major assembly is temporarily installed
										 </UncontrolledTooltip>
									 </span>
								 </label>

							 </div>
									</FieldCol>
							 <FieldCol md="6">
							 <div className="form-group" style={{display: "block",margin: "25px 0px"}}>
								 <label className="label">
									 <input
										 type="checkbox"
										 checked={this.state.linkEnigne.engine.is_titled}
										 onChange={(e) =>{ this.updateEngineField('is_titled', true); setTimeout(() => this.updateEngineField('is_fitted', false),300)}}
									 />
									 <span>
										 Is Propeller titled on this MSN ?
										 <i class="fa fa-info-circle" aria-hidden="true" id="engine-field3" style={{paddingLeft: '4px'}}></i>
										 <UncontrolledTooltip placement="right" target="engine-field3">
												 Tick if the major assembly is Titled
										 </UncontrolledTooltip>
									 </span>
								 </label>

							 </div>
									</FieldCol>
						 </Row>
					</div>
					<ul className="list-inline" style={{textAlign: 'right'}}>
							<li className="list-inline-item">
								<button type="button" className="btn btn-primary" onClick={() => this.linkEnigne()}>Add</button>
							</li>
							<li className="list-inline-item">
								<button type="button" className="btn btn-secondary" onClick={() => this.setState({linkEnigne: {flag: false, engine: {
									stand_alone_link: true,
									stand_alone_asset_id: null,
									position: 1
								}}})}>Cancel</button>
							</li>
					</ul>
					</DeleteModal>
					<DeleteModal isOpen={this.state.deleteEngine.flag} toggle={() => this.setState({deleteEngine: {flag: false, engine: {}}})} title={`Remove Propeller ${this.state.deleteEngine.engine.serial_number}`} className="modal-tform-delete-pictures">
 				 <ul className="list-inline" style={{textAlign: 'right'}}>
 						 <li className="list-inline-item">
 							 <button type="button" className="btn btn-danger" onClick={() => this.deleteMsnEngine()}>Yes</button>
 						 </li>
 						 <li className="list-inline-item">
 							 <button type="button" className="btn btn-secondary" onClick={() => this.setState({deleteEngine: {flag: false, engine: {}}})}>Cancel</button>
 						 </li>
 				 </ul>
 				 </DeleteModal>
			</TabPane>
		)
	}
}
const mapStateToProps = state => ({
	techSmryView: state.TechSummaryReducer.techSmryView,
	propellerList: state.TechSummaryReducer.propellerList,
	propellerCrud: state.TechSummaryReducer.propellerCrud,
	techConstants: state.sharedReducers.techConstants,
	ownerList: state.confReducer.ownerDropdownList
});
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
		fetchTechSummaryList: (asset_slug, type) => dispatch(fetchTechSummaryListAc(asset_slug, type)),
		getOwnerList:() =>  dispatch(getOwnerDropdownListAc()),
		deleteMsnEngine: (engineId) => dispatch(deleteMsnAssetAc(ownProps.params,engineId, 'propeller')),
    addEditTechSummary : (data, operation, type, viewType) => dispatch(updateProperllerAc(ownProps.params , data, operation, type, viewType)),
    techSummaryCrud: (type, id, actionType,dataObject) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject)),
		exportLLPs:(propeller_slug) => dispatch(exportPropellerLLpsAc(ownProps.params, propeller_slug)),
		deleteLLP:(propeller_slug, id) => {dispatch(deletePropellerLLPAc(ownProps.params, propeller_slug,id))},
		importLLPs:(file, propeller_slug) => dispatch(importPropellerLLPsAc(ownProps.params,file, propeller_slug)),
    changeViewMode: (type,actionType) => dispatch({
        type:TOGGLE_TECH_SMRY_VIEW,
        payload:{type},
        actionType
      }
    ),
    toggleEditBar: (data, actionType) => dispatch({
      type:TECH_SMRY_CRUD,
      payload:data,
      actionType
    }),
	}
}
 export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropellersContent));
