
import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { checkString } from '../../../../formValidator';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
export default class AddEditEquipement extends Component{
  constructor(props){
    super(props);
    this.state = {
      equipment: {},
      error:{}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.equipmentObj));
    this.setState({
      equipment: data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      equipment: {
        ...prevState.equipment,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }
  addEditEquipment = (e) => {
    e.preventDefault();
    const validateNewInput = {
      equipment: checkString({value: this.state.equipment.equipment, required: true, minLength: '', maxLength: '', message: 'Please enter Equipment'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.addEditTechSummary(this.state.equipment, this.props.type, 'equipmentList', 'equipmentCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }
  render(){
    const { equipment, error } = this.state;
    const { type } = this.props;
    if(!Object.keys(equipment).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditEquipment(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Equipment
                  { type != 'view' ? <sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldInput
                  value={equipment.equipment}
                  type={type}
                  keyParam="equipment"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.equipment}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Part Number</FieldLabel>
                <FieldInput
                  value={equipment.part_number}
                  type={type}
                  keyParam="part_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Serial Number</FieldLabel>
                <FieldInput
                  value={equipment.serial_number}
                  type={type}
                  keyParam="serial_number"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Manufacturer</FieldLabel>
                <FieldInput
                  value={equipment.manufacturer}
                  type={type}
                  keyParam="manufacturer"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Equipment Type</FieldLabel>
                <FieldInput
                  value={equipment.equipment_type}
                  type={type}
                  keyParam="equipment_type"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

          </Row>
        </div>
        { type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
