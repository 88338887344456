import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';

export default class GenralDetail02Wrapper  extends Component {
  constructor(props) {
    super(props);
  }


  updateForm = () => {

  }
  render() {
  const { accessoriesDetails,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
      <Row>
         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Place of Inspection <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: accessoriesDetails.place_of_inspection,
              error: error && error.place_of_inspection ? error.place_of_inspection : '',
                updateForm: (value) => this.props.updateForm({type: 'place_of_inspection', value: value, sectionType:'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Date of Inspection <sup className="reqStar"> * </sup></label>,
              { type: 'edit',
                value: accessoriesDetails.date_of_inspection,
                error: error && error.date_of_inspection ? error.date_of_inspection : '',
                updateForm: (value) => this.props.updateForm({ type: 'date_of_inspection', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>


         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Report Date<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
               value: accessoriesDetails.report_date,
               error: error && error.report_date ? error.report_date : '',
                updateForm: (value) => this.props.updateForm({ type: 'report_date', value: value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '40px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">Report Issued Place<sup className="reqStar"> * </sup></label>,
              { type: 'edit',
              value: accessoriesDetails.report_issued_place,
              error: error && error.report_issued_place ? error.report_issued_place : '',
                updateForm: (value) => this.props.updateForm({ type:'report_issued_place', value:value, sectionType: 'genDetails'})
              }
            )
          }
         </Col>
      </Row>
    </div>
    );
  }
}
