import React, { Component } from 'react';
import { Row, Col, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { browserHistory, Link } from 'react-router';
import  GetInputField  from '../../../applications/technicalInspection/Elements/GetInputField';
import { globalSignUpPostService} from '../../../globalServices';
import { imgStoragePath } from '../../../constants';
import { checkString, checkEmail } from '../../../formValidator';
import { toastFlashMessage } from '../../../utils';
import ReCAPTCHA from "react-google-recaptcha";
// import TextareaField from '../../../Elements/TextareaField';
export default class SignupForm extends Component {
  constructor(props){
    super(props);
    this.state = {
        userInfo: {
            is_lessor: true,
            is_newsfeed_lessor:false,
            company_name: '',
            company_domain: '',
            full_name: '',
            email:'',
            contact_no:'',
            password: '',
            confirm_password:'',
            referral_code: '',
        },
        captcha: false,
        captchaError: false,
        error:{},
        loader: false,
        tooltipOpen: false,
        showPassword:false,
        showCnfPassword:false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  componentDidMount(){
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });
  }
  handleUserType = (key, value) => {
    if(key == 'is_contractor'){
      this.setState(prevState => ({
        ...prevState,
        userInfo:{
          ...prevState.userInfo,
          is_lessor:false,
          is_newsfeed_lessor: false
        }
      }))
    }
    if(key == 'is_newsfeed_lessor'){
      this.setState(prevState => ({
        ...prevState,
        userInfo:{
          ...prevState.userInfo,
          is_lessor:false,
          is_newsfeed_lessor:true
        }
      }))
    }
    if(key == 'is_lessor'){
      this.setState(prevState => ({
        ...prevState,
        userInfo:{
          ...prevState.userInfo,
          is_lessor:true,
          is_newsfeed_lessor:false
        }
      }))
    }
  }

  handleUserChange = (key, value) =>{
    if(key=='email'){
      this.setState(prevState => ({
          ...prevState,
          error: {
              ...prevState.error,
              email_company: ''
          }
      }))
    }
    this.setState(prevState => ({
        ...prevState,
        userInfo: {
            ...prevState.userInfo,
            [key]: value
        },
        error: {
            ...prevState.error,
            [key]: ''
        }
    }))
  }

  signupUser = (e) => {
    e.preventDefault();
    this.setState({
        error: {}
    });
    var { userInfo } = this.state;
    let validate = {};
    if (userInfo.is_lessor){
        validate = {
            company_name: checkString({value: userInfo.company_name, required: true, minLength: '', maxLength: '', message: 'Please enter Company Name'}),
            company_domain: checkString({value: userInfo.company_domain, required: true, minLength: '', maxLength: '', message: 'Please enter Website'}),
        }

    }
    if (userInfo.company_name) {
        validate = {
            company_name: checkString({value: userInfo.company_name, required: true, minLength: '', maxLength: '30', message: 'Company name charecter length should be less than 30'})
        }
    }

    validate = {
        ...validate,
        full_name: checkString({value: userInfo.full_name, required: true, minLength: '', maxLength: '', message: 'Please enter Full Name'}),
        email: checkEmail({value: userInfo.email.trim(), required: true, minLength: '', maxLength: '', message: 'Please enter a valid Email Address'}),
        email_company: userInfo.is_lessor ? '' :'',
        password: checkString({value: userInfo.password, required: true, minLength: '', maxLength: '', message: 'Please enter Password'}),
        confirm_password: checkString({value: userInfo.confirm_password, required: true, minLength: '', maxLength: '', message: 'Please enter Confirm Password'}),
    };

    if(!validate.password){
        let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        if(!passwordRegex.test(userInfo.password)){
            validate = {
                ...validate,
                password: 'Password does not meet the policy requirements'
            }
        }
    }

    if(!this.state.captcha){
      this.setState({captchaError: true})
    }

    if(!validate.password && !validate.confirm_password){
        if(userInfo.password !== userInfo.confirm_password){
            validate = {
                ...validate,
                confirm_password: 'Passwords do not match. Try Again'
            }
        }
    }

    if(userInfo.contact_no){
        let contactNoReg = /^[+]?([0-9]*[\.\s\-\(\)]|[0-9]+){3,24}$/;
        // let contactNoReg = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
        if(!contactNoReg.test(userInfo.contact_no)){
            validate = {
                ...validate,
                contact_no: 'Please enter valid Mobile Number'
            }
        }
    }

    if (Object.keys(validate).every((k) => { return validate[k] === '' })  && this.state.captcha ) {
        this.setState(prevState => ({
            ...prevState,
            loader: true,
        }));
        globalSignUpPostService('/api/sign-up/',{...userInfo, company_domain: userInfo.company_domain.toLowerCase(), email: userInfo.email.toLowerCase()})
        .then( response => {
            this.setState(prevState => ({
                ...prevState,
                loader: false,
            }));
            if(response.data.statusCode == 200){
                toastFlashMessage(response.data.message, 'success');
                browserHistory.push('/otp-verification/' + response.data.data.token)
                localStorage.setItem('userEmail', userInfo.email)
                this.setState({
                    userInfo: {
                        is_lessor: true,
                        company_name: '',
                        company_domain: '',
                        full_name: '',
                        email:'',
                        contact_no:'',
                        password: '',
                        confirm_password:'',
                        referral_code: '',
                    },
                    error:{},
                    loader: false,
                    tooltipOpen: false
                });
                this.props.signupTypeChange(1);
            }else if(response.data.statusCode == 1008){
                this.setState({
                    error: response.data.validation_error
                });
            }else if(response.data.statusCode != 200){
                toastFlashMessage(response.data.message, 'error', 10000)
            }

        });
    }else{
        this.setState({
            error: validate
        });
    }
  }

  render(){
    const { userInfo, error } = this.state;
    const { signupTypeChange } = this.props;

    return(
      <div className="signup-form">
        <h2 className="form-title">Sign up for a free account</h2>
        <div>
          <form onSubmit={this.signupUser} noValidate>
            <ul className="list-unstyled nav-tabs row">
              <li className="col-md-4">
                <label>
                  <input type="radio"
                    onChange={(e) => {{this.handleUserType('is_lessor',true)}; {signupTypeChange('1')}}}
                    checked={userInfo.is_lessor}
                    name="is_lessor"
                    style={{position: "relative",top: "2px"}}
                  />
                  I am a Lessor/ <br /> Asset Manager/Airline
                </label>
              </li>
              <li className="col-md-4">
                <label>
                  <input
                    type="radio"
                    onChange={(e) => {{this.handleUserType('is_contractor',true)}; {signupTypeChange('2')}}}
                    checked={!userInfo.is_lessor && !userInfo.is_newsfeed_lessor}
                    name="is_lessor"
                    style={{position: "relative",top: "2px"}}
                  />
                  I am an Inspector/<br />Servicer/MRO
                </label>
              </li>
            </ul>
            { userInfo.is_lessor ?
              <Row>
                <Col md="6">
                  <div>
                    <div className="form-group-edit" style={{marginTop:"-8px"}}>
                      <label className="label" style={{position:"relative", top:"2px"}}>Company Name</label>
                        <input
                          type="text"
                          value={userInfo.company_name}
                          onChange={(evt) => this.handleUserChange('company_name', evt.target.value)}
                          maxLength={30}
                        />
                   </div>
                   {this.state.error != '' ? <h6 className="error-msg" >{this.state.error.company_name}</h6> : null}
                  </div>
                </Col>
                <Col md="6">
                  {
                    GetInputField(
                        'string',
                        <label className="for-label" style={{cursor:"default"}}>Website</label>,
                        { type: "add",
                        value: userInfo.company_domain,
                        error: error.company_domain,
                        updateForm: (value) => this.handleUserChange('company_domain', value)
                        }
                    )
                  }
                </Col>
                <Col md="12">
                  {
                    GetInputField(
                        'string',
                        <label className="for-label" style={{cursor:"default"}}>Email Address (Company)</label>,
                        {
                            type: "add",
                            value: userInfo.email,
                            error: error.email?error.email:error.email_company,
                            updateForm: (value) => this.handleUserChange('email', value)
                        }
                    )
                  }
                </Col>
                <Col md="12">
                    <p style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.03)', margin: '0 0 25px'}}></p>
                </Col>
              </Row>: null }
              <Row>
                <Col md="6">
                    {
                        GetInputField(
                            'string',
                            <label className="for-label" style={{cursor:"default"}}>Full Name</label>,
                            {
                                type: "add",
                                value: userInfo.full_name,
                                error: error.full_name,
                                updateForm: (value) => this.handleUserChange('full_name', value)
                            }
                        )
                    }
                </Col>
                { userInfo.is_lessor ?
                <Col md="6">
                    {
                      GetInputField(
                        'string',
                        <label className="for-label" style={{cursor:"default"}}>Mobile Number <span>(Optional)</span></label>,
                        {
                            type: "add",
                            value: userInfo.contact_no,
                            error: error.contact_no,
                            pattern: "[0-9]",
                            updateForm: (value) => this.handleUserChange('contact_no', value)
                        }
                      )
                    }
                </Col>:
                <Col md="6">
                  {
                    GetInputField(
                        'string',
                        <label className="for-label" style={{cursor:"default"}}>Email Address</label>,
                        {
                            type: "add",
                            value: userInfo.email,
                            error: error.email,
                            updateForm: (value) => this.handleUserChange('email', value)
                        }
                    )
                  }
                </Col>
                }
                <Col md="6" className="password-grid">
                  <label style={{cursor:"default"}}>
                    Password
                    <span id="passwordToolTip"><img src={imgStoragePath+'phase-2/help.svg'} style={{ position: 'relative', bottom: '2px', left: '2px' }} /></span>
                    <UncontrolledTooltip className="text-left" placement="right" target="passwordToolTip">
                      <div style={{ textAlign: "left" }}>
                        Password rules  <br/>
                        <ul className="list-unstyled password-tooltip">
                          <li>Password must contain minimum of 8 characters</li>
                          <li>Password must contain at least 1 digit</li>
                          <li>Password must contain at least 1 uppercase character</li>
                          <li>Password must contain at least 1 lowercase character</li>
                          <li>Password must contain at least 1 special character</li>
                        </ul>
                      </div>
                    </UncontrolledTooltip>
                  </label>
                  <input
                      type={this.state.showPassword ? "text" : "password" }
                      value={userInfo.password}
                      autoComplete="new-password"
                      onChange={(e) => {this.handleUserChange('password', e.target.value)}}
                  />
                  {!this.state.showPassword ?<i  onClick={(e) => {this.setState({showPassword: !this.state.showPassword})}} className="fa fa-eye-slash" aria-hidden="true"></i> : <i  onClick={(e) => {this.setState({showPassword: !this.state.showPassword})}} className="fa fa-eye" aria-hidden="true"></i>}
                  {error.password ? <h6 className="error-msg">{error.password}</h6> : null }
                </Col>
                <Col md="6" className="password-grid">
                    <label style={{cursor:"default"}}>Confirm Password</label>
                    <input
                        type={this.state.showCnfPassword ? "text" : "password" }
                        value={userInfo.confirm_password}
                        onChange={(e) => {this.handleUserChange('confirm_password', e.target.value)}}
                    />
                    {!this.state.showCnfPassword ? <i onClick={(e) => {this.setState({showCnfPassword: !this.state.showCnfPassword})}} className="fa fa-eye-slash" aria-hidden="true"></i> : <i onClick={(e) => {this.setState({showCnfPassword: !this.state.showCnfPassword})}} className="fa fa-eye" aria-hidden="true"></i>}
                    {error.confirm_password ? <h6 className="error-msg">{error.confirm_password}</h6> : null }
                </Col>
                { !userInfo.is_lessor ?
                <Col md="6">
                  {
                    GetInputField(
                      'string',
                      <label className="for-label" style={{cursor:"default"}}>Mobile Number <span>(Optional)</span></label>,
                      {
                          type: "add",
                          value: userInfo.contact_no,
                          error: error.contact_no,
                          pattern: "[0-9]",
                          updateForm: (value) => this.handleUserChange('contact_no', value)
                      }
                    )
                  }
                </Col>:null
                  }
                  <Col md="6">
                    {
                      GetInputField(
                        'string',
                        <label className="for-label" style={{cursor:"default"}}>Referral Code <span id="referalCodeToolTip">(Optional) <img src={imgStoragePath+'phase-2/help.svg'} style={{ position: 'relative', bottom: '1px', left: '1px' }} /></span>
                            <UncontrolledTooltip placement="right" target="referalCodeToolTip">
                                The referral code should be applied when creating the account, as it cannot be applied later.
                            </UncontrolledTooltip>
                        </label>,
                        {
                            type: "add",
                            value: userInfo.referral_code,
                            error: error.referral_code,
                            updateForm: (value) => this.handleUserChange('referral_code', value)
                        }
                      )
                    }
                  </Col>
                  {userInfo.is_lessor ? <Col md="12" className="text-center">
                    <ReCAPTCHA
                      sitekey="6LeFiv8UAAAAAIbm4Fh6CCiIaOE7nL3jTACK6XIW"
                      onChange={() => this.setState({captcha: true, captchaError: ''})}
                    />
                    {
                      this.state.captchaError && <h6 style={{marginTop: '20px', textAlign: 'left'}} className="error-msg">Captcha verification required.</h6>
                    }
                  </Col>:
                  <Col md="12" className="text-center">
                      <ReCAPTCHA
                        sitekey="6LeFiv8UAAAAAIbm4Fh6CCiIaOE7nL3jTACK6XIW"
                        onChange={() => this.setState({captcha: true, captchaError: ''})}
                      />
                      {
                        this.state.captchaError && <h6 style={{marginTop: '20px', textAlign: 'left'}} className="error-msg">Captcha verification required.</h6>
                      }
                  </Col>}
                  <Col md="12" className="text-center">
                      <p className="terms-and-conditions">
                          By clicking Sign Up, you agree to our
                          <a href="/termscondition" > Terms, </a>
                          <a href="/data-policy" >Data Policy </a> and <br/>
                          <a href="https://www.acumen.aero/privacy-policy/" target="_blank">Privacy Policy</a>.
                      </p>
                      <input type="submit" style={ this.state.loader ? { pointerEvents: 'none' } : {pointerEvents: 'unset'}}   value={ this.state.loader ? "Processing..." : "Sign Up"  }/>
                  </Col>
              </Row>
            </form>
          </div>
      </div>
    )
  }
}
