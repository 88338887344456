import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addAfAssembly, addAfClaim } from '../../mntAddObject'
import { browserHistory } from 'react-router'
export const airframeUpcomingEventsAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.AIRFRAME_LOADER,
      payload: true
    });
    globalGetService('maintenance/asset-type/airframe/asset/'+params.aircraft_slug+'/new-upcoming-events/', {})
    .then(response => {
      dispatch({
        type: actions.AIRFRAME_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AIRFRAME_EVENT_LIST,
          payload: response.data.data.upcoming_events
        });
      }
    });
  }
}

export const airframeEventHistoryAc = (params, queryParam={}) => {
  return (dispatch) => {
    globalGetService('maintenance/asset-type/airframe/asset/'+params.aircraft_slug+'/shopvisit/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AIRFRAME_EVENT_HISTORY,
          payload: response.data.data
        });
      }
    });
  }
}

export const addEditAfShopVisitAc = (params, data, type, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.AIRFRAME_LOADER,
      payload: true
    });
    if(type == 'add'){
      globalPostService('maintenance/asset-type/airframe/asset/'+params.aircraft_slug+'/shopvisit/',data)
      .then(response => {
        dispatch({
          type: actions.AIRFRAME_LOADER,
          payload: false
        });
        dispatch(getMaintenanceUsageAc(params))
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AF_SHOP_VIST_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.AF_SHOP_VISIT_CRUD,
            payload: {type: '', data: {} , flag: false }
          });
          browserHistory.push(`/aircraft/${params.aircraft_slug}/maintenance/airframe/view/${response.data.data.id}`)
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Major Assemblies Airframe`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Event`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('maintenance/asset-type/airframe/asset/'+params.aircraft_slug+'/shopvisit/' + data.id +'/',data)
      .then(response => {
        dispatch({
          type: actions.AIRFRAME_LOADER,
          payload: false
        });
        dispatch(getMaintenanceUsageAc(params))
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AF_SHOP_VISIT_CRUD,
            payload: {type: '', data: response.data.data , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Major Assemblies Airframe`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Event`,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const airframeShopVisitCrudAc = (params, type, id) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.AF_SHOP_VISIT_CRUD,
        payload: {type: type, data: addAfAssembly , flag: true }
      });
    }else{
      dispatch({
        type: actions.AIRFRAME_LOADER,
        payload: true
      });
      globalGetService('maintenance/asset-type/airframe/asset/'+params.aircraft_slug+'/shopvisit/' + params.id + '/',{})
      .then(response => {
        dispatch({
          type: actions.AIRFRAME_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AF_SHOP_VISIT_CRUD,
            payload:{type: 'view', data:response.data.data , flag: false }
          });
        }
      })
    }
  }
}

export const afClaimsListAc = (params, queryParam={}) => {
  return (dispatch) => {
    globalGetService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/', queryParam)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AF_CLAIM_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const afClaimInfoCrudAc = (params, type, id) => {
  return (dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.AF_CLAIM_CRUD,
        payload: {type: type, data:addAfClaim , flag: true }
      });
    }else{
      dispatch({
        type: actions.AIRFRAME_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/induction-condition-items/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.AIRFRAME_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AF_CLAIM_CRUD,
            payload: {type: type, data:response.data.data , flag: true }
          });
        }
      })
    }
  }
}
export const addEditAfClaimAc = (params, data, type, assetInfoObj) => {
  return (dispatch) => {
    dispatch({
      type: actions.AIRFRAME_LOADER,
      payload: true
    });
    if(type == 'add'){
      let claim = data;
      claim = {
        ...data,
        shop_visit_id: params.id
      }
      globalPostService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/',claim)
      .then(response => {
        dispatch({
          type: actions.AIRFRAME_LOADER,
          payload: false
        });
        dispatch(getMaintenanceUsageAc(params))
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AF_CLAIM_ADD_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.AF_CLAIM_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Major Assemblies Airframe`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Claim`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('maintenance/'+params.type+'/asset/'+params.aircraft_slug+'/claims/' + data.id + '/', data)
      .then(response => {
        dispatch({
          type: actions.AIRFRAME_LOADER,
          payload: false
        });
        dispatch(getMaintenanceUsageAc(params))
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.AF_CLAIM_EDIT_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.AF_CLAIM_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          dispatch({
            type: actions.AF_PAYOUT_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Major Assemblies Airframe`,
            application_module: `Maintenance`,
            asset: assetInfoObj.asset_type === 1 ? `MSN ${assetInfoObj.msn}` : [3,4,5].includes(assetInfoObj.asset_type) ? `${assetTypeNames[assetInfoObj.asset_type]} ${assetInfoObj.serial_number}` : `ESN ${assetInfoObj.esn}`,
            aircraft_slug: params.aircraft_slug,
            item_type: `Claim`,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const airframeToggleStatus = (data, params = {}) => {
  return (dispatch) => {
    dispatch({
      type: actions.AIRFRAME_LOADER,
      payload: true
    });
    globalPutService('contracts/'+params.contract_slug+'/induction-condition-items/' + data.id + '/', data)
    .then(response => {
      dispatch({
        type: actions.AIRFRAME_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AF_CLAIM_EDIT_SUCCESS,
          payload: response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}

export const afSVStatusAc = (params, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.AIRFRAME_LOADER,
      payload: true
    });
    globalPostService('maintenance/shopvisit/'+params.id+'/update-status/', data)
    .then(response => {
      dispatch({
        type: actions.AIRFRAME_LOADER,
        payload: false
      });
      dispatch(getMaintenanceUsageAc(params))
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AF_SHOP_VISIT_CRUD,
          payload: {type: '', data: response.data.data , flag: false }
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}

export const afClaimStatusAc = (params, id, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.AIRFRAME_LOADER,
      payload: true
    });
    globalPostService('maintenance/claim/'+id+'/update-status/', data)
    .then(response => {
      dispatch({
        type: actions.AIRFRAME_LOADER,
        payload: false
      });
      dispatch(getMaintenanceUsageAc(params))
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AF_CLAIM_EDIT_SUCCESS,
          payload: response.data.data
        });
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}

export const eventTypeAc = (params, type) => {
  return(dispatch) => {
    globalGetService('maintenance/asset-type/'+type+'/asset/'+params.aircraft_slug+'/events/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.EVENT_TYPES,
          payload: response.data.data
        });
      }
    })
  }
}
export const assetInfoAc = (params, application) => {
  return (dispatch) => {
    globalGetService('console/contract/'+params.type+'/'+params.aircraft_slug + '/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ASSET_INFO,
          payload: response.data.data
        })
        let assetTypeNames = {
          3: 'APU',
          4: 'LG',
          5: 'PROPELLER'
        }
        trackActivity('Page Visited',
          {
            application_module: 'Maintenance',
            page_title: application,
            asset: response.data.data.asset_type === 1 ? `MSN ${response.data.data.msn}` : [3,4,5].includes(response.data.data.asset_type) ? `${assetTypeNames[response.data.data.asset_type]} ${response.data.data.serial_number}` : `ESN ${response.data.data.esn}`,
          }
        )
      }
    })
  }
}

export const getMaintenanceUsageAc = (params) =>{
  return(dispatch) =>{
    globalGetService(`/console/${params.type}/${params.aircraft_slug}/license/maintenance/usage/`)
    .then(response =>{
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MAINTENANCE_USAGE,
          payload: response.data.data
        })
      }
    })
  }
}
