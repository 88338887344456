import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { LicenseSidebar } from '../components';
import { imgStoragePath } from '../../../constants';

class Storage extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){
        return(
            <div className="license-users">
                <LicenseSidebar />
                <div className="container-fluid license-content-wrapper">
                    <Row>
                        <Col md="8">
                            <div className="users-license">
                                <h2 className="block-title">Storage License</h2>
                                <Row>
                                    <Col md="7">
                                        <Row className="pricing-outer">
                                            <Col md="6" className="text-center pricing-grid">
                                                <ul className="list-inline switcher">
                                                    <li className="list-inline-item">Monthly</li>
                                                    <li className="list-inline-item">
                                                        <label className="switch">
                                                            <input type="checkbox" />
                                                            <div className="slider round"></div>
                                                        </label>
                                                    </li>
                                                    <li className="list-inline-item">Yearly</li>
                                                </ul>
                                                <p className="price">
                                                    $6
                                                    <span>Per Month, Per Tera Byte</span>
                                                </p>
                                            </Col>
                                            <Col md="6" className="users-grid">
                                                <ul className="list-inline pricing-calculations">
                                                    <li className="list-inline-item">$6</li>
                                                    <li className="list-inline-item">X</li>
                                                    <li className="list-inline-item"><span>1</span> TB</li>
                                                    <li className="list-inline-item caret-list">
                                                        <i className="fa fa-caret-up"></i>
                                                        <i className="fa fa-caret-down"></i>
                                                    </li>
                                                    <li className="list-inline-item">=</li>
                                                </ul>
                                                <ul className="list-inline total-price">
                                                    <li className="list-inline-item">$6</li>
                                                    <li className="list-inline-item">Billed Monthly <br/>( Prepaid )</li>
                                                </ul>
                                                <Link to="/" className="buy-license">Buy License</Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="5" className="flex-not-centered">
                                        <Row className="contact-sales">
                                            <Col md="12">
                                                <img src={imgStoragePath+'phase-2/email.png'} />
                                                <div className="sales-content">
                                                    <p>
                                                        Looking for a custom pricing or
                                                        an enterprise plan?
                                                        Please contact our
                                                        sales team.
                                                    </p>
                                                    <a href="mailto:sales@acumenaviation.ie" target="_blank">Contact Sales</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="current-plans">
                                <h2 className="block-title">Current Plans</h2>
                                <Row className="current-plans-row">
                                    <Col md="12">
                                        <div className="wrapper">
                                            <Row>
                                                <Col md="3">
                                                    <h4>Plan</h4>
                                                    <p>Free</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>No. of Users</h4>
                                                    <p>1</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>Last Billed Date</h4>
                                                    <p>1st April 2019</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>Next Billing Date</h4>
                                                    <p>1st April 2019</p>
                                                </Col>
                                                <Col md="1">
                                                    <h4>Status</h4>
                                                    <p>Active</p>
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <img className="edit-icon" src={imgStoragePath+'phase-2/edit.png'} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="wrapper">
                                            <Row>
                                                <Col md="3">
                                                    <h4>Plan</h4>
                                                    <p>Monthly, Recurring</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>No. of Users</h4>
                                                    <p>10</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>Last Billed Date</h4>
                                                    <p>1st April 2019</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>Next Billing Date</h4>
                                                    <p>1st April 2019</p>
                                                </Col>
                                                <Col md="1">
                                                    <h4>Status</h4>
                                                    <p>Active</p>
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <img className="edit-icon" src={imgStoragePath+'phase-2/edit.png'} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="wrapper">
                                            <Row>
                                                <Col md="3">
                                                    <h4>Referral Program</h4>
                                                    <p>Monthly, Recurring</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>Credited Users</h4>
                                                    <p>10</p>
                                                </Col>
                                                <Col md="2">
                                                    <h4>Spent Users</h4>
                                                    <p>1st April 2019</p>
                                                </Col>
                                                <Col md="3">
                                                    <h4>Last Referral</h4>
                                                    <p>Alok (1st April 2019)</p>
                                                </Col>
                                                <Col md="2" className="text-center">
                                                    <img className="edit-icon" src={imgStoragePath+'phase-2/history.png'} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="refer-fried-wrapper">
                                <div className="refer-content-wrapper">
                                    <img src={imgStoragePath+'phase-2/refer.png'} />
                                    <div className="refer-friend-inner">
                                        <h3>Refer a friend</h3>
                                        <p>
                                            Invite other users to SPARTA and avail free benefits,
                                            like extra storage, inspections and users.
                                            Read more about our referral program <a href="javascript:void(0);">here</a>.
                                        </p>
                                        <p className="code-block">
                                            Your referral <br/>
                                            Program Code
                                            <span className="referal-code">
                                                8DF12G
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <Row className="refer-links">
                                    <Col md="4">
                                        <a href="">Email a Friend</a>
                                    </Col>
                                    <Col md="4">
                                        <a href="">Copy Code</a>
                                    </Col>
                                    <Col md="4">
                                        <a href="">Referral History</a>
                                    </Col>
                                </Row>
                            </div>
                            <div className="license-detail">
                                <h3><img src={imgStoragePath+'phase-2/info.png'} />Details about License</h3>
                                <p>
                                    You can choose between monthly or yearly plan for data storage required.
                                    <br/>
                                    <br/>
                                    Storage includes Records of the assets,
                                    Inspection pictures and documents and other
                                    attachments for the asset.
                                    <br/>
                                    <br/>
                                    Deactivating license would block the access to the data on the platform.
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Storage
