import React, { Component } from 'react';
import { Link } from 'react-router';
import DropToUpload from 'react-drop-to-upload';
import { imgStoragePath } from '../../../../constants';
export default class DragDropFiles extends Component{
  constructor(props){
    super(props);
    this.state = {
      handleDrag: false
    }
    this.handleDrop = this.handleDrop.bind(this);
  }
  handleDrop(files) {
    this.props.confirmModal(files);
    this.setState({
      handleDrag: false
    })
  }
  onDragOver = () => {
    this.setState({
      handleDrag: true

    })
  }
  onDragLeave = () => {
    this.setState({
      handleDrag: false
    })
  }
  render(){
    return(
      <DropToUpload
        onDrop={this.handleDrop }
        onOver={this.onDragOver}
        onLeave={this.onDragLeave}
      >
        <div className="drag-drop-block" style={ this.state.handleDrag ?  { background : 'rgba(0,0,0,0.3)', transition: 'all 0.4s ease'} : {background: 'transparent'}}>
          <div>
            <p className="caption-ui">
              <p style={{textAlign:'center'}}>
              {this.props.oemUpload ? 'Drag and Drop OEM AD status report to run AD Validator OR' : 'Drag and Drop Operator AD status report, OEM AD status report (optional ) to run AD Validator OR'}
              <Link className="primary-btn">{this.props.oemUpload ? 'Upload File' : 'Upload Files'}
                <input type="file" onChange={(e) => this.props.confirmClickModal(e.target.files)} multiple/>
              </Link>
              </p>
              <p style={{margin: '6px 0 12px 0',display:'none'}}>or</p>
            </p>
          </div>
        </div>
      </DropToUpload>
    )
  }
}
