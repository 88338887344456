import React, { Component } from 'react';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../constants';

export default  class EmptyCart extends Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    render(){
        return(
           <div className="empty-cart text-center flex-centered">
                <div>
                    <img src={imgStoragePath+'phase-2/empty-cart.jpg'} />
                    <p>
                        Your License Cart is Empty, <br/>
                        Please add licenses to purchase
                    </p>
                    <Link to="/licenses/user">Buy Now</Link>
                </div>
           </div>
        )
    }
}
