import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { browserHistory, Link } from 'react-router';
import  GetInputField  from '../../../applications/technicalInspection/Elements/GetInputField';
import { globalPostService} from '../../../globalServices';
import { checkLoginForm } from '../../../formValidator/loginForm';
import { toastFlashMessage } from '../../../utils';
class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
        userInfo: {
            email:'',
        },
        error: {},
        loader: false,
    };
  }

  handleUserChange = (key, value) =>{
    value = value.trim();
    this.setState(prevState => ({
        ...prevState,
        userInfo: {
            ...prevState.userInfo,
            [key]: value
        },
        error: {
            ...prevState.error,
            [key]: '',
            emailMsg: '',
        }
    }))
  }

  forgotFormSubmit = (e) => {
    e.preventDefault();
    const { userInfo } = this.state;
    const validateNewInput = checkLoginForm({email: userInfo.email});
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.setState(prevState => ({
            ...prevState,
            loader: true,
        }));
        globalPostService('api/password/reset/',userInfo)
        .then( response => {
            this.setState(prevState => ({
                ...prevState,
                loader: false,
            }));
            if(response.data.statusCode == 200){
                  toastFlashMessage('Password Reset Link Sent', 'success');
                  browserHistory.push('/login');
            }else if(response.data.statusCode == 1008){
              this.setState({
                error: {
                  emailMsg : response.data.validation_error.email ? response.data.validation_error.email : "",
                }
            });
            }
        });
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  render(){
    const { userInfo, error } = this.state;
    return(
      <div className="login-form">
            <Link to="/login" className="back-to-login">← Back to Login</Link>
            <h2 className="form-title">Request Reset Password </h2>
            <div>
                <form onSubmit={this.forgotFormSubmit}>
                    <Row>
                        <Col md="12" style={{margin:"10px 0px"}}>
                            {
                                GetInputField(
                                    'string',
                                    <label className="for-label">Registered Email Address</label>,
                                    { type: "add",
                                      value: userInfo.email,
                                      error: error.emailMsg,
                                      updateForm: (value) => this.handleUserChange('email', value)
                                    }
                                )
                            }
                        </Col>
                        <Col md="12" className="text-center" style={{marginTop:"-10px"}}>
                            <input type="submit" style={ this.state.loader ? { pointerEvents: 'none' } : {pointerEvents: 'unset'}}   value={ this.state.loader ? "Processing..." : "Send Link"  } />
                        </Col>
                    </Row>
                </form>
            </div>
      </div>
    )
  }
}

export default  ForgotPassword
