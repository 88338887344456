import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import { TextInputField,DropDownField } from '../../../Elements';

export default class ItemInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
  const { } = this.props;
  return(
    <div>
      <Row>
       <Col sm="12">
         <TextInputField type={'edit'} value={''} >Description</TextInputField>
         <TextInputField type={'edit'} value={''} >Accumen Remarks</TextInputField>
         <DropDownField type={'edit'} >Status</DropDownField>
         <TextInputField type={'edit'} value={''} >Comments/Notes</TextInputField>
         <TextInputField type={'edit'} value={''} >Ref.Documents</TextInputField>
         <TextInputField type={'edit'} value={''} >Operator Comments</TextInputField>
       </Col>
      </Row>
    </div>
    );
  }
}
