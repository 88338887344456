import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { utilizationFormat } from '../../../../constants';
export default class UtilizationList extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { utilAv } = this.props;
    return (
      <tr>
        <td><span>{utilAv.name}</span></td>
        <td>
          <NumericLabel params={utilizationFormat}>
            {utilAv.hours}
          </NumericLabel>
        </td>
        <td>
          {utilAv.cycles}
        </td>
        <td>
          <NumericLabel params={utilizationFormat}>
            {utilAv.tsn}
          </NumericLabel>
        </td>
        <td>
          {utilAv.csn}
        </td>
      </tr>
    )
  }
}
