import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, browserHistory } from 'react-router';
import * as actions from '../actions';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { getSearchResultAc } from '../actionCreators';
import { PREVIEW_RECORD, GLOBAL_SEARCH_LOADER } from '../actions';
import { NoRecordFound, ListLoader  } from '../../';
import { getFileSize, getLocalStorageInfo } from '../../../utils';
import { SearchCard, RecordsSearchCard, SearchFilter } from '../components';
import Pagination from "react-js-pagination";
import FileInfo from '../../../applications/records/DataRoom/components/FileInfo';
import { getRecordDetailsAc } from '../../../applications/records/DataRoom/actionCreators';
import '../assets/scss/search.scss';
import { imgStoragePath, imageExtensions } from '../../../constants';
import { globalGetService } from '../../../globalServices';
import { sGlobalConstantAc } from '../../actionCreators';
import moment from 'moment'
class GlobalSearch extends Component{
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      activeTab: '1',
      page: 1,
      globalFilter: {
        level: '',
        application: '',
        searchText: ''
      },
      searchFilter: {
        from_date: '',
        to_date: '',
        filter: [],
        user_id: [],
        multi_asset: []
      },
      recordPreview: false,
    }
    this.toggle = this.toggle.bind(this);
  }

  setFilter = (key, value, flag) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    if(flag){
      this.setState(prevState => ({
        ...prevState,
        searchFilter: {
          from_date: '',
          to_date: '',
          filter: [],
          user_id: [],
          multi_asset: []
        },
      }), () => this.props.getSearchResult(location.query.searchText, location.query.application, location.query.level ,1, {}, true))
    }else if(key === 'start_date'){
      if(moment(value).diff(moment(this.state.searchFilter.end_date), 'days') > 0){
        this.setState(prevState => ({
          ...prevState,
          searchFilter:{...prevState.searchFilter, start_date:value, end_date: value}
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          searchFilter:{...prevState.searchFilter, [key]:value}
        }))
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        searchFilter:{...prevState.searchFilter, [key]:value}
      }))
    }
  }

  advanceFilter = (page) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    let searchFilter = {}
    searchFilter.user_id = ''
    this.state.searchFilter.user_id.map(user => {
      searchFilter.user_id = searchFilter.user_id != '' ? searchFilter.user_id + ',' + user.id : user.id
    })
    searchFilter.filter = ''
    this.state.searchFilter.filter.map(user => {
      searchFilter.filter = searchFilter.filter.length ? searchFilter.filter + ',' + user.id : user.id
    })
    searchFilter.multi_asset = []
    this.state.searchFilter.multi_asset.map(user => {
      searchFilter.multi_asset = searchFilter.multi_asset.length ? searchFilter.multi_asset + ',' + `(${user.type},${user.id})` :  `(${user.type},${user.id})`
    })
    if(this.state.searchFilter.from_date && this.state.searchFilter.from_date != ''){
      searchFilter.from_date = moment(this.state.searchFilter.from_date).format('YYYY-MM-DD')
    }
    if(this.state.searchFilter.to_date && this.state.searchFilter.to_date != ''){
      searchFilter.to_date = moment(this.state.searchFilter.to_date).format('YYYY-MM-DD')
    }

    this.props.getSearchResult(location.query.searchText, location.query.application, location.query.level ,page ? page: 1, searchFilter, true);
  }

  toggleFolderInfo = () => {
    this.setState({
      recordPreview: false,
    });
    this.props.previewRecord({});
  }

  previewRecord = (item) => {
    this.props.previewRecord({});
    this.setState(prevState => ({
      ...prevState,
      recordPreview: true
    }));
    let url = ''
    if(item.type == "file"){
      if(imageExtensions.indexOf(item.extension) != -1)
      {
        this.setState(prevState => ({
          ...prevState,
          previewCard: item,
          recordPreview: true,
          navigationTree: false
        }));
        this.props.getRecordDetails({type: item.type, uuid: item.uuid});
        this.props.previewRecord(item)
      }else if(item.extension == 'pdf'){
        this.setState(prevState => ({
          ...prevState,
          previewCard: item,
          recordPreview: true,
          navigationTree: false
        }));
        this.props.getRecordDetails({type: item.type, uuid: item.uuid});
        this.props.previewRecord(item)
      }else if(item.extension == 'csv' || item.extension == 'xls' || item.extension == 'xlsx'){
        this.setState(prevState => ({
          ...prevState,
          previewCard: item,
          recordPreview: true,
          navigationTree: false
        }));
        this.props.getRecordDetails({type: item.type, uuid: item.uuid});
        this.props.previewRecord(item)
      }else {
        url = decodeURIComponent(`${item.path}`);
        window.open(url, '_blank').focus();
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        previewCard: item,
        recordPreview: true,
        navigationTree: false
      }));
      this.props.getRecordDetails({type: item.type, uuid: item.uuid});
    }
  }

  handlePageChange = (page) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    if(location.query.searchText != undefined && location.query.searchText != null){
        this.advanceFilter(page)
    }else {
      this.advanceFilter(page)
    }
    this.setState(prevState => ({
      ...prevState,
      globalFilter: location.query
    }));
  }

  componentWillReceiveProps(nextProps, nextState){
    let location = Object.assign({}, browserHistory.getCurrentLocation());
    if(this.state.searchText !== location.query.searchText || this.props.location.query.level !== nextProps.location.query.level){
      this.setState({
        searchFilter: {
          from_date: '',
          to_date: '',
          filter: [],
          user_id: [],
          multi_asset: []
        },
        searchText: location.query.searchText
      }, () => this.props.getSearchResult(nextProps.location.query.searchText, nextProps.location.query.application, nextProps.location.query.level ,1))
    }
  }

  componentDidMount(){
    let location = Object.assign({}, browserHistory.getCurrentLocation());
    this.setState(prevState => ({
      ...prevState,
      globalFilter: location.query,
      searchText: location.query.searchText
    }));
    if(this.props.searchResult.data.results.length <= 0){
      this.props.getSearchResult(location.query.searchText, location.query.application, location.query.level ,1);
    }
    this.props.fetchTechConstant({"constant_types":["file_extension"]})
  }

  moveBack = () =>{
    let location = browserHistory.getCurrentLocation();
    if(this.props.params.aircraft_slug == 'fleet'){
      browserHistory.push('/assets-listing');
    }else if(location.query.application == 'Records'){
      browserHistory.goBack()
    }else{
      browserHistory.push('/asset-view/' +this.props.params.type+ '/' +this.props.params.aircraft_slug)
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render(){
    const { searchResult, techConstants, gloableSearchLoader, recordDetail, filterOptions } = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    return(
      <div className="global-search-page" style={{marginLeft: '70px'}}>
        {gloableSearchLoader ?<ListLoader/>:null}
        {!gloableSearchLoader && (searchResult.data && searchResult.data.results) ?
          <div className="global-search-result-info" >
            <p>
              <Link onClick={() => browserHistory.goBack()} to="">
                <img src={imgStoragePath+"back_arrow.png"} alt="back" /> Back
              </Link>
            </p>
            {
              searchResult.data.pagination.total > 0 ?
              <h2>Search result for <span>"{location.query.searchText}"</span> in your {location.query.level}</h2>
              :<h2>Did you mean <strong>{location.query.searchText}</strong> ? Try again with different text.</h2>
            }
          {
            searchResult.data.pagination.total > 0 ? <h6>showing {(searchResult.data.pagination.current_page - 1) * 10 + 1 } to { searchResult.data.pagination.total > 10 ? searchResult.data.pagination.current_page * 10 <= searchResult.data.pagination.total ? searchResult.data.pagination.current_page * 10 : searchResult.data.pagination.total : searchResult.data.pagination.total} of {searchResult.data.pagination.total} results</h6>
          : null
          }
          </div>:null
        }
        {
          !gloableSearchLoader && filterOptions && typeof filterOptions === 'object' && Object.keys(filterOptions).length && searchResult.data.pagination && searchResult.data.pagination.total > 0 ?
          <SearchFilter filterOptions={filterOptions} filters={this.state.searchFilter} advanceFilter={() => this.advanceFilter()} fileTypes={techConstants.filter(item => item.type === "file_extension")} setFilter={(key, value, flag) => this.setFilter(key, value, flag)}/>
          :null
        }
        <div className="search-result-container">
          {
            // <img src={imgStoragePath+"maintenance.png"} style={{marginTop: '40px'}}/>
            // <br/>
            // <span className="warning-info-text" style={{fontSize: '16px', marginBottom: '10px',marginTop: '40px', color: 'red', display: 'inline-block'}}><img className="warning-img" style={{ marginRight: '5px'}} src={imgStoragePath+"warning.png"} alt="img" width="15"/>Sorry! This feature is under maintenance. Please try again tomorrow. Apologies for the inconvenience.</span>
          }
          {!gloableSearchLoader ?
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                    >
                    Records
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {
                    searchResult.data && searchResult.data.results && searchResult.data.results.length ?
                    searchResult.data.results.map((item) => <RecordsSearchCard fileIcons={fileIcons} previewFile={() => this.previewRecord(item)} level={location.query.level} searchTerm={location.query.searchText} item={item} key={item.uuid}/>)
                    : searchResult.data.results.length == 0 && !gloableSearchLoader ? <NoRecordFound/> : ''
                  }
                </TabPane>
              </TabContent>
              {
                searchResult.data && searchResult.data.pagination && searchResult.data.results && searchResult.data.results.length && !gloableSearchLoader ?
                <Pagination
                 activePage={searchResult.data.pagination.current_page ? parseInt(searchResult.data.pagination.current_page):1}
                 itemsCountPerPage={10}
                 totalItemsCount={searchResult.data.pagination.total}
                 pageRangeDisplayed={5}
                 onChange={this.handlePageChange}
               />:null
              }
            </div>:null
          }
          {
            (this.props.previewCard && Object.keys(this.props.previewCard).length && this.props.previewCard.type == 'file' && searchResult.data.results.length) && !gloableSearchLoader ?
            <FileInfo recordDetail={recordDetail} previewInfo={this.props.previewCard} searchKey={location.query.searchText} style={this.state.recordPreview ? {width: "750px",top: '40px'} : {width: "0px"}} toggleFolderInfo={this.toggleFolderInfo} /> :
            <FileInfo recordDetail={recordDetail} previewInfo={this.props.previewCard} style={{width: '0px', top: '40px'}} toggleFolderInfo={this.toggleFolderInfo} />
          }
          </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchResult: state.globalSearchReducer.searchResult,
  filterOptions: state.globalSearchReducer.filterOptions,
  previewCard: state.globalSearchReducer.previewCard,
  gloableSearchLoader: state.globalSearchReducer.gloableSearchLoader,
  recordDetail: state.DataRoomReducer.recordDetail,
  techConstants: state.sharedReducers.techConstants,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSearchResult: (query, application, level, pageNumber, filters, noFilter) => dispatch(getSearchResultAc(ownProps.params, query, application, level, pageNumber, filters, noFilter)),
    previewRecord: (item) =>dispatch({
      type: PREVIEW_RECORD,
      payload: item
    }),
    toggleLoader: (flag) => dispatch({
      type: GLOBAL_SEARCH_LOADER,
      payload: flag
    }),
    getRecordDetails: (record) => dispatch(getRecordDetailsAc(ownProps.params,record)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch)
