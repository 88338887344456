import React, { Component } from 'react';
import FilterList from './components/FilterList';
import FilterTypes from './components/FilterTypes';
import AddFilterBtn from './components/AddFilterBtn';
import FilterTypeDropdown from './components/FilterTypeDropdown';
import FilterLabel from './components/FilterLabel';
import FilterCard from './components/FilterCard';
import AppliedFilter from './components/AppliedFilter';
import AppliedFilterLabel from './components/AppliedFilterLabel';
import FilterField from './components/FilterField';
import FilterCardTitle from './components/FilterCardTitle';
import { imgStoragePath } from '../../constants';
export default class FilterBar extends Component{
  constructor(props){
    super(props);
    this.state = {
      toggleFilter: false,
      selectedFilterTitle:'',
    }
  }

  toggleFilterList = () => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: !prevState.toggleFilter
    }))
  }

  selectFilter = (value) => {
    this.setState(prevState => ({
      ...prevState,
      selectedFilterTitle: value,
      toggleFilter: false
    }))
  }

  closeFilter = () => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: false,
      selectedFilterTitle: ''
    }))
  }

  changeFilterFn = (...args) => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: false,
      selectedFilterTitle: ''
    }));
    if(this.props.filterMenu[this.state.selectedFilterTitle].inputType=="date" || this.props.filterMenu[this.state.selectedFilterTitle].inputType=="range"){
      this.props.changeFilterFn(args[0],args[1],args[2]);
    }else {
      this.props.changeFilterFn(args[0],args[1]);
    }
  }

  render(){
    const {filterMenu,appliedFilter,clearFilterValue} = this.props;
    return(
      <FilterList className="add-filter-type">
        <FilterTypes className="add-filter">
          <AddFilterBtn toggleFilterList={this.toggleFilterList} appliedFilter={appliedFilter} isFilter={this.state.toggleFilter} />
          <FilterTypeDropdown className="list-unstyled filter-list" isFilter={this.state.toggleFilter} filterMenu={filterMenu}>
            { Object.keys(filterMenu).map((label, index) =>
              <FilterLabel key={index} selectFilter={() => this.selectFilter(label)} key={index} className="para-ui th-highlight">{label}</FilterLabel>
            )}
          </FilterTypeDropdown>
          { this.state.selectedFilterTitle ?
            <FilterCard className="match-block">
              <FilterCardTitle>
                <h4>
                  {this.state.selectedFilterTitle}
                  <span onClick={() => this.closeFilter()}><img width="13" src={imgStoragePath+"white_close.png"}  alt="img"/></span>
                </h4>
              </FilterCardTitle>
              <FilterField changeFilterFn={this.changeFilterFn} applyFilterType={filterMenu[this.state.selectedFilterTitle]} />
            </FilterCard>:null
          }
          <AppliedFilter className="list-inline applied-filter">
            { Object.keys(appliedFilter).map((label, index) =>
              <AppliedFilterLabel key={index} clearFilterValue={() => clearFilterValue(label)}>
                {appliedFilter[label].name} : {appliedFilter[label].value}
              </AppliedFilterLabel>
            )}
          </AppliedFilter>
        </FilterTypes>
      </FilterList>
    )
  }
}
