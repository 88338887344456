
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router';
export default class ForgotPasswordForm extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { loginLoader } = this.props;
    return(
    <Col md="6" className="flex-centered rh-block">
        <div className="form-block">
          <div className="reset-block">
                <h6 className="caption-ui back-icon"><Link to="/">← Back to Login</Link></h6>
                <h2 className="reset-password-heading">Request Reset Password Link</h2>
                <h6 className="caption-ui send-link-block">
                      We will send a link to your email account to reset your password
                </h6>
            </div>
          <form onSubmit={(e) => this.props.forgotPassword(e)}>
            <div className="form-group">
              <label>Registered Email Address</label>
              <input className="" type="text" name="email"  />
              <h6 className="error-msg">{this.props.errors.emailMsg}</h6>
            </div>
            <div className="input-group">
              <input type="submit" disabled={loginLoader} value={loginLoader ? 'Processing': 'SEND LINK'} />
            </div>
          </form>
        </div>
      </Col>
    )
  }
}
