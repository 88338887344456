import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../constants';
class SecondaryHeader extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { params, assetInfo } = this.props;
    return(
      <div className="sparta-secondary-header">
        <Row>
          <Col md="6 asset-switcher-and-apps">
            <ul className="list-inline flex-centered">
              <li className="list-inline-item">
                <Link to={'/asset-view/' +params.type+'/'+params.aircraft_slug}>
                  <img src={imgStoragePath +'back_arrow.png'} alt="Back"/>
                </Link>
              </li>
              <li className="list-inline-item asset-sw">
                <h3>{assetInfo.name?assetInfo.name:''} : <span>{assetInfo.aircraft_type ? assetInfo.aircraft_type:''}</span></h3>
              </li>
              <li className="list-inline-item application-type">
                {
                  this.props.technical ?
                  <h4>Technical Specs Extractor</h4>
                  :<h4>Airworthiness Directive Validator</h4>
                }
                <Link to={'/technical-specification/'+params.type+'/'+params.aircraft_slug}><img src={imgStoragePath+'aircraft.png'} alt="" /> Technical Specification</Link>
              </li>
            </ul>
          </Col>
          <Col md="5 asset-switcher-and-apps">
            <ul className="list-inline flex-centered" style={{float: 'right'}}>
              <li className="list-inline-item application-type" style={{borderLeft: 'none'}}>
                <h4><br/></h4>
                {
                  this.props.downloadTechSpecs ?
                    <Link target="_blank" to={this.props.downloadTechSpecs}><img src={imgStoragePath+"downloadresources.png"} alt="" />Download File</Link>
                  :null
                }
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(SecondaryHeader);
