import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class MjrAssShopVisitHeader extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { theads } = this.props
    return(
      <div className="asset-list-filter-sort">
        <Row className="filter-block">
          {
            theads.map((item, index) => 
              <Col md={item.size} key={index}>
                <h6>{ item.label }</h6>
            </Col>
          )
         }
        </Row>
    </div>
    )
  }
}