
import React from 'react';
import {  Row, Col } from 'reactstrap';
export default function MaintenanceRpt({exportObj, onChangeExportFeature}){
  return(
    <div className="export-module-card">
      <div className="rpt-card-header">
        <h2>Maintenance</h2>
      </div>
      <div className="rpt-card-body">
        <div className="export-items">
          <div className="">
            <Row className="flex-centered">
              <Col xs="5">
                <label className="flex-centered export-label">
                  <input type="checkbox"
                    onChange={(e) => onChangeExportFeature(e.target.checked, 'upcoming_events')}
                    checked={exportObj.maintenance && exportObj.maintenance.upcoming_events && exportObj.maintenance.upcoming_events.export ? true:false}
                  />
                  <span>Upcoming Events</span>
                </label>
              </Col>
              <Col xs="4">
                <div className="export-avial">
                  <h6>Export Format</h6>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <label>
                        <input name="format" checked={true} type="radio" />
                        <span>PDF</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
