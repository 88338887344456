import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody,ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, CardBody, Card  } from 'reactstrap';
import { EditFormBar, DeleteModal, UserInfoModal } from '../../Elements';
import {createProject, aircraft} from '../../Forms/AddFormObjects';
import AddUserType from '../components/AddUserType';
import AddEditProject from '../components/AddEditProject';
import AssetInfoWrapper from '../components/AssetInfoWrapper';
import AddEditWorkOrder from '../components/AddEditWorkOrder';
import AddEditApuWorkOrder from '../components/AddEditApuWorkOrder';
import DateDisplayCard from '../components/DateDisplayCard';
import HelpTextInspectionType from  '../components/HelpTextInspectionType';
import { assetsType } from '../../../../constants';
import { dateTransformAllSet, getLocalStorageInfo} from '../../../../utils'
import moment from 'moment';
import { sLesseeListAc, getMsnSwitcherAc, sManufacturerAc, sGlobalConstantAc } from '../../../../shared/actionCreators';
import { NoRecordFound, ToolTipHover } from '../../../../shared';
import {  aircraftTypeAc,
          exportt012Ac,
          getT012FormDataAc,
          projectViewCrudAc,
          workOrderAddEditAc,
          fetchProjectUserAc,
          workOrderViewCrudAc,
          updateT012FormStatusAc,
          technicalInspectionTypeAc,
          addEditProjectAc,
          closeProjectAc,
          fetchBluePrintsAc,
          fetchProjectFormAc,
          msnDetailsAc,
          projectViewAc,
          removeEngineerAc,
          changeUserAc,
          addUsersAc,
          launchWorkOrderAc,
          getUserDetailAc,
          addEngineerToFormAc,
          getUsageDetailAc,
          launcht012Ac,
          fetchApuFormsAc,
          getWorkOrderDetailAc,} from '../actionCreators';
import { UPDATE_USER_TYPES, SELECTED_USER, CLOSE_USER_MODAL, WORKORDER_SIDEBAR, T012_DATA } from '../actions';
import { ListLoader, ExportFiles } from  '../../../../shared';
import { imgStoragePath, displayDateFormat } from '../../../../constants';
import CKeditor from "../../../../shared/CKEditor";
import { trackActivity } from '../../../../utils/mixpanel';

class ProjectView extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      modalTitle: '',
      userList: [],
      usersList: [],
      delModal: false,
      delteModalTitle: '',
      selectedUserIds: [],
      actionType: 'change',
      t012TitleError: '',
      removeUser: '',
      selectedUser: [],
      formSlug: '',
      projectSlug: '',
      addToAllForm: false,
      projectStatusFlag: false,
      workOrderInfoFlag: false,
      dropdownOpen: false,
      workAssetType:1,
      pathType:0,
      workorderMessage: false,
      collapse: false,
      editT012Title: false,
      remarks: '',
      remarksError: '',
      modal: false
    };
  }

  workOrderView = (obj, type) => {
    if((getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.is_saas && this.props.projectViewData.work_orders && this.props.projectViewData.work_orders.length >= 1) && obj.type =='add'){
      this.setState({workorderMessage: true})
      return
    }
    if(type != 'aircraft'){
      this.props.workOrderSidebar(obj)
    }else{
      this.props.workOrderView(obj)
    }
  }

  componentDidMount(){
    this.props.fetchProjectDetail();
    if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.is_saas){
			this.props.getUsageDetail()
		}
    this.props.fetchProjectLead({role: 'lead'}, 'projectLead');
    this.props.fetchProjectEngineer({role: 'engineer'}, 'projectEngineer');
    this.props.fetchProjectEngineer({role: 'member'}, 'projectMembers');
    this.props.fetchTechnicalInspection();
    this.props.fetchForms();
    this.props.fetchApuForms();
    this.props.fetchLesseeList();
    this.props.fetchAircraftType();
    this.props.fetchBluePrints();
    this.props.fetchManufactureList();
    this.props.getMsnSwitcher();
    this.props.fetchProjectDepartment({constant_types:['project_departments','project_name']});
  }

  toggleDelModal = (user) => {
    this.setState(prevState =>({
      ...prevState,
      delModal: !prevState.delModal,
      delteModalTitle: user.project_role === 2 ? `Project Engineer ${user.name} may be Assigned to One/More Forms, are you sure to remove ?` : 'Are you sure you want to remove?',
      selectedUser: user
    }));
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleWorkorderInfo=()=> {
    this.setState(prevState => ({
      ...prevState,
      workOrderInfoFlag: !prevState.workOrderInfoFlag
    }))
  }

  addToAllFormStatus = (status) =>{
    this.setState(prevState => ({
      ...prevState,
      addToAllForm: !prevState.addToAllForm
    }))
  }
  formViewFn = (formSlug, formName, workOrderSlug, assetType, assetSlug) => {
    browserHistory.push(`/${assetsType[assetType]}/${assetSlug}/technical/${workOrderSlug}/forms/${formSlug}/${formName.toLowerCase()}`);
  }

  toggleModel = () => {
    this.setState({modal: !this.state.modal})
  }

  rejectConfirm = (status) => {
    if(this.props.t012.title.trim() != ''){
      this.setState({modal: true, remarks: '', remarksError: ''})
    }else{
      this.setState({t012TitleError: 'Please enter Form Type'})
    }
  }
  handleRemarks = (e) => {
    let value = e.target.value;
    this.setState({
      remarks: value,
      remarksError:''
    })
  }

  updateT012FormStatus = (data) => {
    if(this.props.t012.title.trim() == ''){
      this.setState({
        t012TitleError: 'Please enter Form Type'
      })
    }else{
      this.props.updateT012FormStatus(data);
    }
  }

  updateRejectStatus = () => {
    if(this.state.remarks.trim() != '' && this.props.t012.title.trim() != ''){
      this.props.updateT012FormStatus({status: 3, remarks: this.state.remarks});
      this.toggleModel();
    }else if(this.props.title.t012.trim() == ''){
      this.setState({
        t012TitleError: 'Please enter Form Type'
      })
    }else if(this.state.remarks.trim() == ''){
      this.setState({
        remarksError: 'Please enter the Remarks'
      })
    }
  }

  reviewLink = (e,formSlug, formName, workOrderSlug, assetType, assetSlug) => {
    e.preventDefault();
    e.stopPropagation();
    browserHistory.push(`/${assetsType[assetType]}/${assetSlug}/technical/review/${workOrderSlug}/forms/${formSlug}/${formName.toLowerCase()}`);
  }
  formCorFn = () => {
    browserHistory.push(`/technical/project/view/${this.props.params.project_slug}/project-cor-form`);
  }
  formFeedbackFn = () => {
    browserHistory.push(`/technical/project/view/${this.props.params.project_slug}/project-feedback-form`);
  }
  formCreateEngine = (type) => {
    if((getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.is_saas && this.props.projectViewData.work_orders && this.props.projectViewData.work_orders.length >= 1) && type == 'add'){
      this.setState({workorderMessage: true})
      return
    }
    browserHistory.push(`/technical/project/create-engine/${this.props.params.project_slug}`);
  }

  fetchUserType = (e, type, name, userId = null, actionType, formSlug, projectSlug,wType) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      ...prevState,
      modalTitle: name,
      selectedUserIds: [],
      pathType:type,
      actionType: actionType,
      removeUser: userId,
      addToAllForm: false,
      workAssetType:wType
    }));

    let leadData = this.props.projectLead;
    let projEngineerData = this.props.projectEngineer;
    let projMembers = this.props.projectMembers;
    let usersData = [];
    if(type == 1){
      this.props.projectViewData.leads.map(item => usersData.push(item.id));
      leadData = leadData.filter(data => usersData.indexOf(data.id) == -1)
      this.setState(prevState => ({
        ...prevState,
        userList: leadData,
        usersList: leadData,
      }));
      this.props.toggleEditAddUsers(true);
    }
    if(type == 2){
      this.props.projectViewData.engineers.map(item => usersData.push(item.id));
      if(userId){
        projEngineerData = projEngineerData.filter(data =>
          data.id != userId
        )
      }else{
        projEngineerData = projEngineerData.filter(data => usersData.indexOf(data.id) == -1)
      }
      this.setState(prevState => ({
        ...prevState,
        userList: projEngineerData,
        usersList: projEngineerData,
      }));
      this.props.toggleEditAddUsers(true);
    }
    if(type == 3){
      this.props.projectViewData.members.map(item => usersData.push(item.id));
      projMembers = projMembers.filter(data => usersData.indexOf(data.id) == -1)

      this.setState(prevState => ({
        ...prevState,
        userList: projMembers,
        usersList: projMembers,
      }));
      this.props.toggleEditAddUsers(true);
    }
    if(type == 4){
      this.setState(prevState => ({
        ...prevState,
        userList: this.props.projectViewData.engineers,
        usersList: this.props.projectViewData.engineers,
        formSlug: formSlug,
        projectSlug: projectSlug,
      }));
      this.props.toggleEditAddUsers(true);
    }
  }


  searchUser = (value) =>{
    this.setState(prevState =>({
      ...prevState,
      userList: prevState.usersList
    }))
    if(value != ''){
      this.setState(prevState =>({
        ...prevState,
        userList: prevState.userList.filter(user => user.name.toLowerCase().includes(value.toLowerCase()))
      }))
    }else {
      this.setState(prevState =>({
        ...prevState,
        userList: prevState.usersList
      }))
    }
  }
  selectedUser = (id) => {
    if(this.state.actionType == 'change' || this.state.actionType == 'addEngineerToForm'){
      this.setState(prevState => ({
        ...prevState,
        selectedUserIds: [id]
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        selectedUserIds: [...prevState.selectedUserIds].includes(id) ? [...prevState.selectedUserIds].filter(data => data != id) : [...prevState.selectedUserIds, id]
      }))
    }

  }
  // workOrderView = (e, data) => {
  //
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.props.workOrderView(data);
  // }

  editEsnView=(e, data)=>{
    e.preventDefault();
    e.stopPropagation();
    browserHistory.push(`/technical/project/${this.props.params.project_slug}/engine/edit/${data.slug}`);
  }

  unarchiveProject = () =>{
    this.setState({
      projectStatusFlag: !this.state.projectStatusFlag,
    })
  }

  updateT012Data = (evt) =>{
    var newContent = evt.editor.getData();
    let data = this.props.t012
    data = {...data, comments: newContent}
    this.props.updateT012Data(data)
  }

  getT012FormData = (t012) => {

    if(t012.title && t012.title.trim() != ''){
      this.props.getT012FormData(t012)
      this.setState({editT012Title: false})
    }else{
      this.setState({t012TitleError: 'Please enter Form Type'})
    }
  }


  updateT012Title = (value) =>{
    let data = this.props.t012
    data = {...data, title: value}
    this.setState({t012TitleError: ''})
    this.props.updateT012Data(data)
  }


  render(){
    const { t012, apuForms, inspUsage, apuWorkOrderCrud, addUserListFlag, projectCrud, projectLoader, workOrderCrud, projectEngineer, projectLead, projectViewData, techConstants } = this.props;
    const userInfo = getLocalStorageInfo().user.permission
    var showTabs = true
    // Object.keys(userInfo).map(key => {
    //   showTabs = userInfo[key].is_saas ? false : showTabs
    //   return key
    // })
    return(
      <div className="technical-inspection-cn project-view">
        { projectViewData.archive_status == 1 ?
          <div className="archived-project-banner">Project is on read-only mode as it is archived, if you want to modify <Link onClick={() => this.unarchiveProject()}>Click Here</Link> to unarchive this project</div>
          :null
        }
        <div className="technical-project-view">
          { projectLoader ? <ListLoader/> : null }
          <Row className="view-header" style={{height: "70px"}} >
            <Col md="9" style={{paddingLeft: '0'}}>
              <ul className="list-inline project-edit flex-centered">
                <li className="list-inline-item ">
                  <Link to="/technical/projects?project_status=all" className="project-nav">
                    <img width="18" src={imgStoragePath+"back_arrow.png"} />
                  </Link>
                </li>
                <li className="list-inline-item" style={{minWidth: '300px'}}>
                  <div className="">
                    <h2 id="project-name">
                      { projectViewData.name && projectViewData.name.length > 37 ? projectViewData.name.substring(0,40)+'...': projectViewData.name}
                    </h2>
                    { projectViewData.name && projectViewData.name.length > 37 ?
                      <ToolTipHover placement="top" tagetId='project-name'>
                        {projectViewData.name}
                      </ToolTipHover>:null
                    }
                    <p id={'project'+ projectViewData['id']}>
                      <span style={{width: projectViewData.status && projectViewData.status.label == "Completed" ? "100%":`${projectViewData.progress}%`}}></span>
                    </p>
                     <ToolTipHover placement="top" tagetId={ 'project'+ projectViewData['id']}>
                        {projectViewData.status && projectViewData.status.label == "Completed" ? "100%":`${projectViewData.progress}%`}
                      </ToolTipHover>
                  </div>
                </li>

                { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects && getLocalStorageInfo().user.permission.technical.projects.includes('U') ?
                  <li className="list-inline-item">
                    <button
                      onClick= {() => this.props.toggleEditAddSide({type: 'edit', flag: true, data: {}}, this.props.params.project_slug)}
                      >
                      <img className="edit-proj" src={imgStoragePath+"icon_editblock.png"} />
                      Edit Project
                    </button>
                  </li>
                  :null
                }
                <li className="list-inline-item">
                <div className="download-links" style={{position:"relative",top:"-10px"}}>
                      <p style={{fontSize:'13px',fontWeight:'300', position:"relative", background: "#fff"}}>Access Sparta Technical App on</p>
                      <span style={{display: 'block',marginTop:'8px'}}>
                      <a href="https://itunes.apple.com/us/app/sparta-acumen-aviation/id1270942972?mt=8" target="_blank" style={{display:'inline-block',padding:'0px',border:'none'}} onClick={()=>trackActivity('Item Clicked', { page_title: 'Project Listing', application_module: 'Inspection',  item_type:'Inspection App (iOS)'})}>
                      <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
                      <a href="https://play.google.com/store/apps/details?id=acumenaviation.sparta.app"  target="_blank" style={{display:'inline-block',padding:'0px',border:'none'}} onClick={()=>trackActivity('Item Clicked', { page_title: 'Project Listing', application_module: 'Inspection',  item_type:'Inspection App (Android)'})}>
                      <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a>
                      </span>
                   </div>
                </li>
              </ul>
            </Col>
            <Col md="3">
              <ul className="list-inline lessee-info">
                {
                  projectViewData.lessee && projectViewData.lessee.logo ?
                  <li className="list-inline-item">
                    <label className="for-label">Lessee</label>
                    <img src={projectViewData.lessee.logo} alt=" " />
                  </li>
                  :projectViewData.lessee && projectViewData.lessee.name ?
                  <li className="list-inline-item">
                    <label className="for-label">Lessee</label>
                    <h6>{projectViewData.lessee.name}</h6>
                  </li>
                  :''
                }
                <li className="list-inline-item">
                  <label className="for-label project-status">Project Status</label>
                  <h6 className= {projectViewData.status && projectViewData.status.id ? "projectform-status status"+projectViewData.status.id : 'projectform-status status'}>
                    { projectViewData.status && projectViewData.status.label ?
                        projectViewData.status.label
                      : '--'
                    }
                  </h6>
                </li>
              </ul>
            </Col>
          </Row>
          <div className="">
            {
              projectViewData.project_form !== 1 ?
              <Row>
                <Col md="7">
                  <h4 className="project-title-add">Assets in the project ({projectViewData.work_orders && projectViewData.work_orders.length ? projectViewData.work_orders.length : 0 })

                  </h4>
                </Col>
                <Col md="1">
                  <h4 className="project-title-add">
                  <span className="download-block" style={{position: 'relative', right: '25px'}}>
                  {
                    getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.work_orders && getLocalStorageInfo().user.permission.technical.work_orders.indexOf('C') != -1 && projectViewData.project_form === 0 && projectViewData.status && projectViewData.status.id != 7 ?
                    <ButtonDropdown   isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                      <DropdownToggle caret size="sm">
                         <span> Add Asset </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                          <DropdownItem onClick={() => this.workOrderView({type: 'add', flag: true, data: aircraft}, 'aircraft')}><img src={imgStoragePath+"icon_Aircraft.png"} alt="img"/>Aircraft</DropdownItem>
                          {
                            showTabs ?
                            <React.Fragment>
                              <DropdownItem  onClick={() => this.workOrderView({type: 'add', flag: true, data: {asset_type: 3}},'apu')}><img src={imgStoragePath+"icon_apu.svg"} alt="img"/>APU</DropdownItem>
                              <DropdownItem  onClick={() => this.workOrderView({type: 'add', flag: true, data: {asset_type: 4}},'lg')}><img src={imgStoragePath+"icon_lg.svg"} alt="img"/>LG</DropdownItem>
                              <DropdownItem  onClick={() => this.workOrderView({type: 'add', flag: true, data: {asset_type: 5}}, 'propeller')}><img src={imgStoragePath+"icon_propeller.svg"} alt="img"/>PROPELLER</DropdownItem>
                            </React.Fragment>
                            :null
                          }
                          <DropdownItem onClick={() => this.formCreateEngine('add')} ><img src={imgStoragePath+"icon_engine.png"} alt="img"/>Engine</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    :null
                  }
                  </span>
                  </h4>
                </Col>
              </Row>
              :null
            }
            <Row>
              <Col md="8">
              { projectViewData.project_form === 1 && projectViewData.status.id == 1 ? <div className="launch-work-order" style={{height: '50px'}}>
                <span style={{display: 'none', position: 'absolute'}}><strong style={{fontSize: '16px'}}>Project Remarks: </strong><span style={{fontSize: '12px'}}>{projectViewData.project_remarks ? projectViewData.project_remarks : '--'}</span></span>
                <button onClick= {() => this.props.launcht012()}>Launch</button>
              </div>:null}
                {
                  projectViewData.project_form === 1 ?
                  <div className="project-view-asset">
                  <table style={this.state.collapse ? {boxShadow: '0 1px 2px rgba(0,0,0,0.3)'}:{}} className="header-info">
                    <tbody>
                      <tr>
                        <td style={{width: '360px'}}>
                          <div className="progess-info-widget" style={{marginLeft: '0px'}}>
                            <label className="for-label">
                              Form Type<span className="reqStar"></span>
                            </label>
                            {
                              this.state.editT012Title ?
                                <div>
                                  <input disabled={t012.permissions && !t012.permissions.can_save} style={{width: '100%', borderBottom: '2px solid rgb(202, 198, 198)'}} type="text" value={t012.title} onChange={(evt) => this.updateT012Title(evt.target.value)}/><span style={{color: '#308cff', fontSize: '12px'}} onClick={() => {this.getT012FormData(t012);this.setState({editT012Title: false});}}>Save</span>
                                </div>
                              :<h6 style={{width: '100%'}}>{t012.title ? t012.title : ''} {t012.permissions && t012.permissions.can_save && t012.status && ![6,7].includes(t012.status.id) && <img style={{width: '12px', float: 'right'}} onClick={() => this.setState({editT012Title: true})} className="" src={imgStoragePath+"icon_editblock.png"} />}</h6>
                            }
                            <h6 className="error-msg">{this.state.t012TitleError}</h6>
                          </div>
                        </td>
                        <td style={{width:'132px'}} onClick={() => this.setState({collapse: !this.state.collapse})}>
                          <label className="for-label">Status</label>
                            <h6 className= {t012.status && t012.status.id ? "projectform-status status"+t012.status.id : 'projectform-status status'}>{t012.status && t012.status.label ? t012.status.label  : '--'}</h6>
                          </td>
                          <td style={{width:'125px'}} onClick={() => this.setState({collapse: !this.state.collapse})}>
                            <label className="for-label">Last Activity</label>
                            <h6>{ t012.updated_at ? dateTransformAllSet(t012.updated_at):'--'}</h6>
                          </td>
                          <td  style={{width:'132px'}} onClick={() => this.setState({collapse: !this.state.collapse})}>
                            <label className="for-label">Project Engineer</label>
                            <h6 onClick = {(e) => { e.stopPropagation(); this.props.getUserDetailFn(t012.engineer.slug, true) } }>
                              { projectViewData.status && projectViewData.status.id != 0 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_engineer && getLocalStorageInfo().user.permission.technical.project_engineer.includes('AS') ?
                                 !(t012.engineer && Object.keys(t012.engineer).length) ?
                                  <span  onClick = {(e) => this.fetchUserType(e, '4', 'Select Project Engineer', 5, 'addEngineerToForm', 't012', projectViewData.slug, '' )} style={{ color: '#2f8cff', fontSize: '12px'}}>Assign</span>
                                  : null : null
                              }
                              { projectViewData.status && projectViewData.status.id != 0 && projectViewData.archive_status == 0 ?
                                  t012.engineer && t012.engineer[0] && t012.engineer[0].profile_pic ?
                                  <img className="assigned-engineer" src={t012.engineer[0].profile_pic} />
                                  :   null
                                :null
                              }
                              { t012.engineer && t012.engineer[0] && t012.engineer[0].name ? t012.engineer[0].name : null   }
                              { projectViewData.status && projectViewData.status.id != 0 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_engineer && getLocalStorageInfo().user.permission.technical.project_engineer.includes('AS') ?
                                <Link onClick = {(e) => this.fetchUserType(e, '4', 'Project Engineer', 5, 'addEngineerToForm', 't012', projectViewData.slug,'' )} className="change-engineer">
                                  {
                                    t012.engineer && t012.engineer[0] ? Object.keys(t012.engineer[0]).length && t012.status && t012.status.id != 7 ? <img style={{width: '12px'}} className="" src={imgStoragePath+"icon_editblock.png"} /> : null : null
                                  }
                                 </Link>
                                :null
                              }
                            </h6>
                        </td>
                        <td>
                          <ExportFiles exportFile={(fileType) => this.props.exportReport(projectViewData.name)} exportName={'Export'} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'}]} />
                        </td>
                        {
                          // <td align="center" onClick={() => this.setState({collapse: !this.state.collapse})}>
                          //   <img className="collapse-img" style={this.state.collapse ? { transform: 'rotate(0deg)'} : { transform: 'rotate(180deg)' }} src={imgStoragePath + 'up_arrow.png'} alt="img"/>
                          // </td>
                        }
                      </tr>
                    </tbody>
                  </table>
                    <Collapse isOpen={true}>
                    <ul className="export-block  list-inline flex-centered" style={{margin: '10px'}}>
                    { t012.permissions && t012.permissions.can_submit && !this.state.editT012Title ?
                      <li style={{marginLeft: '10px'}}>
                        <button className="primary-btn" onClick={() => this.updateT012FormStatus({status: 5})}>Submit</button>
                      </li>
                      :null
                    }
                    { t012.permissions && t012.permissions.can_save && t012.status && ![6,7].includes(t012.status.id) && !this.state.editT012Title ?
                      <li style={{marginLeft: '10px'}}>
                        <button className="primary-btn" onClick= {() => this.getT012FormData(t012)}>Save</button>
                      </li>
                      :null
                    }
                    {t012.permissions && t012.permissions.can_accept && !this.state.editT012Title ?
                      <li style={{marginLeft: '10px'}}>
                        <button className="primary-btn" onClick={() => this.updateT012FormStatus({status: 6})}>Accept</button>
                      </li>
                      :null
                    }
                    {t012.permissions && t012.permissions.can_accept && !this.state.editT012Title ?
                      <li style={{marginLeft: '10px'}}>
                        <button style={{border: '1px solid #c31b1b', backgroundColor: '#fd4949'}} className="primary-btn" onClick={() => this.rejectConfirm()}>Reject</button>
                      </li>
                      :null
                    }
                    {t012.permissions && t012.permissions.can_publish && !this.state.editT012Title ?
                      <li style={{marginLeft: '10px'}}>
                        <button className="primary-btn" onClick={() => this.updateT012FormStatus({status: 7})}>Publish</button>
                      </li>
                      :null
                    }
                    </ul>
                      <Card>
                        <CardBody>
                        {
                          t012.permissions && t012.permissions.can_save && t012.status && ![6,7].includes(t012.status.id) ?
                          <CKeditor
                            events={{"change": this.updateT012Data}}
                            content={this.props.t012.comments}
                          />
                          :<div style={{padding: '10px'}} dangerouslySetInnerHTML={{__html: this.props.t012.comments}}></div>
                        }
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                  :null
                }
                { projectViewData.work_orders && projectViewData.work_orders.length  ?
                  projectViewData.work_orders.map((projectData, index) =>
                  <AssetInfoWrapper
                    key={`asset_${index}`}
                    projectViewData = {projectData}
                    workorderEdit= {(e) => this.workOrderView({type: 'edit', flag: true, data: projectData }, projectData.asset_type == 1 ? 'aircraft' : '')}
                    esnEdit={(e)=> this.editEsnView(e, {slug:projectData.slug})}
                    archiveStatus={projectViewData.archive_status}
                    showEdit={this.props.msnSwitcher.length && projectViewData.status && projectViewData.status.id != 7 ? true : false}
                  >
                    <div>
                      { projectData.forms && projectData.forms.length && projectData.status.id == 1 ?
                      <div className="launch-work-order">
                        <button onClick= {() => this.props.launchWorkOrder(projectData.slug,projectData.asset.type)}>Launch</button>
                      </div> :null}
                      <table className="forms-attach-list">
                        <tbody>
                          { projectData.forms && projectData.forms.length ? projectData.forms.map((form, childIndex) =>
                            <tr key={`asset_${index}_${childIndex}`} style={projectViewData.current_user_role=='client'&&form.status.id != 7?{opacity: '0.3'}:projectViewData.current_user_role=='operator'&&form.name!='T011'&&form.status.id != 7?{opacity: '0.3'}:projectViewData.current_user_role=='operator'&&form.name =='T011'&&form.status.id <2?{opacity: '0.3'}:{opacity: '1'}} onClick={() => projectViewData.current_user_role=='client'&&form.status.id !=7? null :projectViewData.current_user_role=='operator'&&form.name!='T011'&&form.status.id != 7?null:projectViewData.current_user_role=='operator'&&form.name =='T011'&&form.status.id <2?null:this.formViewFn(form.slug, form.name, projectData.slug, projectData.asset.type, projectData.asset.slug)}>
                              <td style={{width:'300px'}}>
                                <div className="progess-info-widget">
                                  <label className="for-label">Form Type</label>
                                  <h6>{form.name + ' - '+ form.title}</h6>
                                  <p id={ 'form'+ form['id']+'-'+projectData['id']}><span style={{width:form.status && form.status.label == "Completed" ? "100%":form.progress + '%' }}></span></p>
                                  <ToolTipHover placement="top" tagetId={ 'form'+ form['id']+'-'+projectData['id']}>
                                       {form.status && form.status.label == "Completed" ? "100%":form.progress + '%'}
                                   </ToolTipHover>
                                </div>
                              </td>
                              <td>
                                <label className="for-label">Status</label>
                                <h6 className= {form.status && form.status.id ? "projectform-status status"+form.status.id : 'projectform-status status'}>{form.status && form.status.label ? form.status.label  : '--'}</h6>
                              </td>
                              <td>
                                <label className="for-label">Last Activity</label>
                                <h6>{form.updated_at ? dateTransformAllSet(form.updated_at)  : '--'}</h6>
                              </td>
                              <td>
                                <label className="for-label">Project Engineer</label>
                                <h6 onClick = {(e) => { e.stopPropagation(); this.props.getUserDetailFn(form.engineer.slug, true) } }>
                                  { projectViewData.status && projectViewData.status.id != 0 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_engineer && getLocalStorageInfo().user.permission.technical.project_engineer.includes('AS') ?
                                     !(form.engineer && Object.keys(form.engineer).length) ?
                                      <span  onClick = {(e) => this.fetchUserType(e, '4', 'Select Project Engineer', 5, 'addEngineerToForm', form.slug, projectData.slug,projectData.asset.type )} style={{ color: '#2f8cff', fontSize: '12px'}}>Assign</span>
                                      : null : null
                                  }
                                  { projectViewData.status && projectViewData.status.id != 0 && projectViewData.archive_status == 0 ?
                                      form.engineer && form.engineer.profile_pic ?
                                      <img className="assigned-engineer" src={form.engineer.profile_pic} />
                                      :   null
                                    :null
                                  }
                                  { form.engineer && form.engineer.name ? form.engineer.name : null   }
                                  { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_engineer && getLocalStorageInfo().user.permission.technical.project_engineer.includes('AS') ?
                                    <Link onClick = {(e) => this.fetchUserType(e, '4', 'Project Engineer', 5, 'addEngineerToForm', form.slug, projectData.slug,projectData.asset.type )} className="change-engineer">
                                      { Object.keys(form.engineer).length ? <img  className="" src={imgStoragePath+"icon_editblock.png"} /> : null }
                                     </Link>
                                    :null
                                  }
                                </h6>
                              </td>
                              <td>
                                {form.issues_count?
                              <span  className="review-wrapper" onClick={(e)=>this.reviewLink(e,form.slug, form.name, projectData.slug, projectData.asset.type, projectData.asset.slug)}>
                              <span style={{position:'relative'}}>
                              <img src="https://dev.sparta.aero:8100/img/commentDefault.svg" alt="img" className="hover-hide"/>
                              <span className="issue-count">
                              <b> {form.issues_count}</b>
                              </span>
                              </span>
                              <span className="review-title">View Review</span>
                              </span>:null}
                              </td>
                            </tr>
                          ) : <tr><td className="no-forms-attached" colSpan="4">No Forms Attached</td></tr>}
                        </tbody>
                      </table>
                      { projectData.forms && projectData.forms.length && projectData.forms.length > 6 && projectData.status.id == 1 ?
                      <div className="launch-work-order">
                        <button onClick= {() => this.props.launchWorkOrder(projectData.slug,projectData.asset.type)}>Launch</button>
                      </div> :null}
                    </div>
                  </AssetInfoWrapper>
                ) :  projectViewData.work_orders && projectViewData.work_orders.length == 0 && projectViewData.project_form === 0 ? <NoRecordFound/> : null
                }
              </Col>
              <Col md="4">
                { projectViewData && projectViewData.start_date != undefined && projectViewData.start_date != null ?
                  <Row>
                    <DateDisplayCard label="START" flag={projectViewData.end_date?true:false} date={projectViewData.start_date} />
                    <DateDisplayCard label="END" flag={projectViewData.end_date?true:false} date={projectViewData.expected_end_date} />
                   {projectViewData.end_date?
                    <DateDisplayCard label="COMPLETED" flag={projectViewData.end_date?true:false} date={projectViewData.end_date} />:null}
                  </Row>
                  :null
                }
                { projectViewData.cor_required ?
                 projectViewData.current_user_role == 'operator'?
                  null:
                 projectViewData.current_user_role == 'client' ?
                 projectViewData.client_cor_show ?
                  <Row>
                    <Col md="12">
                      <div className="user-manages-card" style={{align:'left',cursor:'pointer'}} onClick={()=>this.formCorFn()}>
                        <span>COR (Confidential Operator Report)<i class="fa fa-angle-right" style={{float: 'right',marginTop:'4px'}} aria-hidden="true"></i></span>
                      </div>
                    </Col>
                  </Row>:null
                  :<Row>
                    <Col md="12">
                      <div className="user-manages-card" style={{align:'left',cursor:'pointer'}} onClick={()=>this.formCorFn()}>
                        <span>COR (Confidential Operator Report)<i class="fa fa-angle-right" style={{float: 'right',marginTop:'4px'}} aria-hidden="true"></i></span>
                      </div>
                    </Col>
                  </Row> :null
                }
                { projectViewData.user_feedback && projectViewData.engineers.length > 0 ?
                  <Row>
                    <Col md="12">
                      <div className="user-manages-card" style={{align:'left',cursor:'pointer'}} onClick={()=>this.formFeedbackFn()}>
                        <span>Feedback<i class="fa fa-angle-right" style={{float: 'right',marginTop:'4px'}} aria-hidden="true"></i></span>
                      </div>
                    </Col>
                  </Row>:null
                }
                <Row>
                  <Col md="12">
                    <div className="user-manages-card">
                      <h4>Project Leads
                        { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_lead && getLocalStorageInfo().user.permission.technical.project_lead.includes('AD') ?
                          <button onClick={(e) => {this.fetchUserType(e, '1', 'Select Project Leads', '', 'addLead' );console.log(e)}}> + Add</button>
                          :null
                        }
                      </h4>
                      <ul className="list-unstyled">
                        { projectViewData.leads ? projectViewData.leads.map((lead, index) =>
                          <li key={`lead_${index}`} className="flex-centered">
                            { lead.profile_pic ?
                                <img  className="user-img" src={lead.profile_pic} alt="" />
                              : <img src={imgStoragePath + 'user.png'} alt="logo"/>
                            }
                            <div className="">
                              <h5 style={{cursor: 'pointer'}} onClick = {() => this.props.getUserDetailFn(lead.slug, true)}>
                                {lead.name ? lead.name : '--'}
                                { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_lead && getLocalStorageInfo().user.permission.technical.project_lead.includes('AS') ?
                                  <Link onClick = {(e) => { e.preventDefault();e.stopPropagation();this.fetchUserType(e, '1', 'Project Lead', lead, 'change' )}} className="change-lead">
                                    <span style={{color: '#3287fb'}}>Change</span>
                                  </Link>:null
                                }
                                { projectViewData.leads.length > 1 && projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_lead && getLocalStorageInfo().user.permission.technical.project_lead.includes('RM') ?
                                  <Link style={{top: '0px'}} onClick = {(e) =>{ e.preventDefault();e.stopPropagation(); this.toggleDelModal(lead)}} className="remove-member">
                                    <span>Remove</span>
                                  </Link>
                                  :null
                                }
                              </h5>
                              <p>{lead.designation ? lead.designation:''}</p>
                            </div>
                         </li>
                        ) : null }
                      </ul>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="user-manages-card">
                      <h4>Project Engineers
                        { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_engineer && getLocalStorageInfo().user.permission.technical.project_engineer.includes('AD') ?
                          <button  onClick={(e) => this.fetchUserType(e, '2', 'Select Project Engineers', '', 'addEngineer' )}> + Add</button>
                          :null
                        }
                      </h4>
                      <ul className="list-unstyled">
                        { projectViewData.engineers ?
                           projectViewData.engineers.map((engineer, index) =>
                           <li key={`eng_${index}`} className="flex-centered">
                              { engineer.profile_pic ?
                                  <img  className="user-img" src={engineer.profile_pic} alt="" />
                                : <img src={imgStoragePath + 'user.png'} alt="logo"/>
                              }
                              <div className="">
                                <h5 style={{ cursor: 'pointer '}} onClick = {() => this.props.getUserDetailFn(engineer.slug, true)}>
                                  {engineer.name ? engineer.name : '--'}
                                  { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_engineer && getLocalStorageInfo().user.permission.technical.project_engineer.includes('RM') ?
                                    <Link onClick = {(e) =>{ e.preventDefault();e.stopPropagation();this.toggleDelModal(engineer)}} className="remove-member">
                                      <span>Remove</span>
                                    </Link>:null
                                  }
                                </h5>
                                <p>{engineer.designation ?engineer.designation:'' }</p>
                              </div>
                            </li>
                            ):null
                        }
                      </ul>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="user-manages-card">
                      <h4>Current Lessee Engineer
                        { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.third_party && getLocalStorageInfo().user.permission.technical.third_party.includes('AD') ?
                            <button  onClick={(e) => this.fetchUserType(e, '3', 'Current Lessee Engineer', '', 'addCurrentLessee' )} > + Add</button>
                          :null
                        }
                      </h4>
                      <ul className="list-unstyled">
                        { projectViewData.members ?
                            projectViewData.members.filter(item => [1].includes(item.operator_level)).map((member, index) =>
                            <li key={`eng_${index}`} className="flex-centered">
                             { member.profile_pic ?
                                 <img  className="user-img" src={member.profile_pic} alt="" />
                               : <img src={imgStoragePath + 'user.png'} alt="logo"/>
                             }
                             <div className="">
                              <h5>{member.name ? member.name : '--'}
                              { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.third_party && getLocalStorageInfo().user.permission.technical.third_party.includes('RM') ?
                                <Link onClick = {() => this.toggleDelModal(member)} className="remove-member">
                                  <span>Remove</span>
                                </Link>:null
                              }
                              </h5>
                              <p>{member.designation ? member.designation:''}</p>
                            </div>
                          </li>)
                          :null
                        }
                      </ul>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="user-manages-card">
                      <h4>Next Lessee Engineer
                        { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.third_party && getLocalStorageInfo().user.permission.technical.third_party.includes('AD') ?
                            <button  onClick={(e) => this.fetchUserType(e, '3', 'Next Lessee Engineer', '', 'addNextLessee' )} > + Add</button>
                          :null
                        }
                      </h4>
                      <ul className="list-unstyled">
                        { projectViewData.members ?
                            projectViewData.members.filter(item => [2].includes(item.operator_level)).map((member, index) =>
                            <li key={`eng_${index}`} className="flex-centered">
                             { member.profile_pic ?
                                 <img  className="user-img" src={member.profile_pic} alt="" />
                               : <img src={imgStoragePath + 'user.png'} alt="logo"/>
                             }
                             <div className="">
                              <h5>{member.name ? member.name : '--'}
                              { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.third_party && getLocalStorageInfo().user.permission.technical.third_party.includes('RM') ?
                                <Link onClick = {() => this.toggleDelModal(member)} className="remove-member">
                                  <span>Remove</span>
                                </Link>:null
                              }
                              </h5>
                              <p>{member.designation ? member.designation:''}</p>
                            </div>
                          </li>)
                          :null
                        }
                      </ul>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="user-manages-card">
                      <h4>Clients/Third Party
                        { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.third_party && getLocalStorageInfo().user.permission.technical.third_party.includes('AD') ?
                            <button  onClick={(e) => this.fetchUserType(e, '3', 'Clients/Operators/Third Party', '', 'addMember' )} > + Add </button>
                          :null
                        }
                      </h4>
                      <ul className="list-unstyled">
                        { projectViewData.members ?
                            projectViewData.members.filter(item => ![1,2].includes(item.operator_level)).map((member, index) =>
                            <li key={`eng_${index}`} className="flex-centered">
                             { member.profile_pic ?
                                 <img  className="user-img" src={member.profile_pic} alt="" />
                               : <img src={imgStoragePath + 'user.png'} alt="logo"/>
                             }
                             <div className="">
                              <h5>{member.name ? member.name : '--'}
                              { projectViewData.status && projectViewData.status.id != 0 && projectViewData.status.id != 7 && projectViewData.archive_status == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.third_party && getLocalStorageInfo().user.permission.technical.third_party.includes('RM') ?
                                <Link onClick = {() => this.toggleDelModal(member)} className="remove-member">
                                  <span>Remove</span>
                                </Link>:null
                              }
                              </h5>
                              <p>{member.designation ? member.designation:''}</p>
                            </div>
                          </li>)
                          :null
                        }
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <EditFormBar
            titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>
             {this.state.modalTitle} </h3></div>}
              path={getLocalStorageInfo().defaultLessor.lessor_type !=1 ? this.state.pathType=='1'?'To add more Project Lead, visit the Licenses page for options':this.state.pathType=='2'?'To add more Project Engineers, Visit the Licenses page for options':null:null}
            toggleEditSideBar={() => this.props.toggleEditAddUsers(false)}
            isOpen={addUserListFlag}
            style={addUserListFlag ? {width: '400px'} : {width: '0px'}}
            >
            {addUserListFlag ?
              <AddUserType
                selectedUser = {this.selectedUser}
                selectedMember = {this.selectedMember}
                userList={this.state.userList}
                formSlug={this.state.formSlug}
                selectedUserIds = {this.state.selectedUserIds}
                toggleEditSideBar={() => this.props.toggleEditAddUsers(false)}
                actionType={this.state.actionType}
                changeUser={() => this.props.changeUser(this.state.removeUser.id, this.state.selectedUserIds, projectViewData.project_form)}
                addLeads={this.props.addUsers}
                addEngineers={(id, type, flag) => {this.props.addUsers(id, type, flag,this.state.formSlug === 't012' ? true : false);this.setState({formSlug: ''})}}
                addEngineerToForm={(selectedUserIds, formSlug, projectSlug, addToAllForm,workAssetType) => this.props.addEngineerToForm(selectedUserIds, formSlug, projectSlug, addToAllForm,workAssetType, this.state.formSlug === 't012' ? true : false)}
                workAssetType={this.state.workAssetType}
                searchUser={this.searchUser}
                projectSlug={this.state.projectSlug}
                addToAllForm={this.state.addToAllForm}
                addToAllFormStatus={this.addToAllFormStatus}
                />
              :null
            }
          </EditFormBar>


            {/* edit project sidebar */}
         <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {projectCrud.type +' Project'} </h3></div>}
          toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
          isOpen={projectCrud.flag}
          style={projectCrud.flag ? {width: '900px'} : {width: '0px'}}
          >
          {projectCrud.flag ?
            <AddEditProject
              project={projectCrud}
              toggleEditAddSide={this.props.toggleEditAddSide}
              updateSideBar= {this.props.updateSideBar}
              toggleEditSideBar={() => this.props.toggleEditAddSide({type: 'add', flag: false, data: {}})}
              projectLoader={projectLoader}
              inspectionType = {this.props.inspectionType}
              projectEngineer= {this.props.projectEngineer}
              projectLead = {this.props.projectLead}
              workOrderInfo = {this.toggleWorkorderInfo}
              sLesseeList = {this.props.sLesseeList}
              closeProject = {this.props.closeProject}
              addEditProject= {this.props.addEditProject}
              projectDepartment = {techConstants.filter(item => item.type == 'project_departments')}
              projectNames = {techConstants.filter(item => item.type == 'project_name')}
              />
            :null
          }
        </EditFormBar>

          {/* add/edit asset(workorder) */}
       <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {workOrderCrud.type +' MSN '} </h3></div>}
          toggleEditSideBar={() => this.props.workOrderView({type: 'add', flag: false, data: {}})}
          isOpen={workOrderCrud.flag}
          style={workOrderCrud.flag ? {width: '600px'} : {width: '0px'}}
          >
          {
            workOrderCrud.flag ?
            <AddEditWorkOrder
              workorder = {workOrderCrud}
              toggleEditSideBar={() => this.props.workOrderView({type: 'add', flag: false, data: {}})}
              msnSwitcher = {this.props.msnSwitcher}
              aircraftTypeList = {this.props.aircraftTypeList}
              bluePrintsInfo= {this.props.bluePrintsInfo}
              sManufacruerList = {this.props.sManufacruerList}
              formList = {this.props.formList}
              inspectionType= {this.props.inspectionType}
              fetchForms = {this.props.fetchForms}
              fetchMsnDetails = {this.props.fetchMsnDetails}
              msnDetails = {this.props.msnDetails}
              workOrderAddEdit = {this.props.workOrderAddEdit}
              workOrderInfo = {this.toggleWorkorderInfo}
              updatedForm= {this.props.updatedForm}
              projectLoader = {this.props.projectLoader}
              projectViewData = {this.props.projectViewData}
              />
            :null
          }
        </EditFormBar>
       <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> {`${apuWorkOrderCrud.type} ${apuWorkOrderCrud.data.asset_type == 3 ? ' APU ' : apuWorkOrderCrud.data.asset_type == 4 ? 'LG ' : 'PROPELLER '}`} </h3></div>}
          toggleEditSideBar={() => this.props.workOrderSidebar({type: 'add', flag: false, data: {}})}
          isOpen={apuWorkOrderCrud.flag}
          style={apuWorkOrderCrud.flag ? {width: '600px'} : {width: '0px'}}
          >
          {
            apuWorkOrderCrud.flag ?
            <AddEditApuWorkOrder
              workorder = {apuWorkOrderCrud}
              toggleEditSideBar={() => this.props.workOrderSidebar({type: 'add', flag: false, data: {}})}
              msnSwitcher = {this.props.msnSwitcher}
              aircraftTypeList = {this.props.aircraftTypeList}
              bluePrintsInfo= {this.props.bluePrintsInfo}
              formList = {this.props.apuForms}
              inspectionType= {this.props.inspectionType}
              fetchForms = {this.props.fetchApuForms}
              fetchMsnDetails = {this.props.fetchMsnDetails}
              msnDetails = {this.props.msnDetails}
              workOrderAddEdit = {this.props.workOrderAddEdit}
              workOrderInfo = {this.toggleWorkorderInfo}
              updatedForm= {this.props.updatedForm}
              projectLoader = {this.props.projectLoader}
              projectViewData = {this.props.projectViewData}
              />
            :null
          }
        </EditFormBar>
        <DeleteModal isOpen={this.state.workOrderInfoFlag}
         toggle={() => this.toggleWorkorderInfo()}
         title={'Nomenclature'} className="modal-tform-delete-pictures modal-dialog-centered pre-requsite-modal">
          <HelpTextInspectionType/>
        </DeleteModal>

        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal([])} title={this.state.delteModalTitle} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => {this.props.removeEngineer(this.state.selectedUser, projectViewData.project_form);this.toggleDelModal([]);}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal([])}>No</button>
              </li>
          </ul>
       </DeleteModal>
       <Modal isOpen={this.state.projectStatusFlag} toggle={() => this.unarchiveProject()} className="modal-dialog-centered modal-tform-delete-pictures">
         <ModalHeader toggle={() => this.unarchiveProject()}>
           'Do you want to UnArchive this project?'
         </ModalHeader>
         <ModalBody>
           <ul className="list-inline">
               <li className="list-inline-item">
                 <button type="button" className="btn btn-danger" onClick={() => {this.props.addEditProject({id: projectViewData.id, archive_status: 0, slug: this.props.params.project_slug }, 'edit');this.unarchiveProject();}}>Yes</button>
               </li>
               <li className="list-inline-item">
                 <button type="button" className="btn btn-primary" onClick={() => this.unarchiveProject()}>No</button>
               </li>
           </ul>
         </ModalBody>
       </Modal>
        </div>
        <Modal isOpen={this.state.workorderMessage} toggle={() => this.setState({workorderMessage: false})} className="transfer-modal">
					<ModalBody>
						<Row className="transfer-modal-body">
							<Col md="12">
								<ul className="list-inline">
									<li className="list-inline-item"><img src={`${imgStoragePath}warning.png`}/></li>
									<li className="list-inline-item"><h2>Add Asset Error</h2></li>
								</ul>
							</Col>
							<Row className="apps-container">
								<Col md="12">
                  {
                    inspUsage.available == inspUsage.used ?
                    <p>
                      You do not have additional licenses to add more assets for inspection. To purchase inspection licenses, click <Link to="/licenses/inspections" className="aims-primary-link">here</Link>
                    </p>
                    :<p>
  										SPARTA licenses allow only 1 asset to be added per inspection. To inspect additional asset(s), launch another inspection.
  									</p>
                  }
								</Col>
							</Row>
						</Row>
						<Row className="transfer-modal-footer">
							<Col md="12">
								<ul className="list-inline">
									<li className="list-inline-item">
										<button type="button" className="aims-primary-button" onClick={() => this.setState({workorderMessage: false})}>Ok</button>
									</li>
								</ul>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
        <Modal isOpen={this.state.modal} toggle={this.toggleModel} className={this.props.className}>
          <ModalHeader toggle={this.toggleModel}>Reason of Rejection</ModalHeader>
          <ModalBody>
            <textarea style={{border: '1px solid #d7d7d7', width: '100%', height: '100px'}} onChange={this.handleRemarks}  value={this.state.remarks}>
            </textarea>
            <h6 className="error-msg">{this.state.remarksError}</h6>
            <p>
              <button onClick={this.updateRejectStatus} style={{float: 'right'}} className="primary-btn">SAVE</button>
            </p>
          </ModalBody>
        </Modal>
        <UserInfoModal
          userDetail = {this.props.userDetail}
          closeUserDetailModal = {this.props.closeUserDetailModal}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  addUserListFlag: state.PorjectsReducer.addUserListFlag,
  projectCrud: state.PorjectsReducer.projectCrud,
  t012: state.PorjectsReducer.t012,
  apuWorkOrderCrud: state.PorjectsReducer.apuWorkOrderCrud,
  projectLoader: state.PorjectsReducer.projectLoader,
  workOrderCrud: state.PorjectsReducer.workOrderCrud,
  apuForms: state.PorjectsReducer.apuForms,
  projectEngineer: state.PorjectsReducer.projectEngineer,
  projectLead: state.PorjectsReducer.projectLead,
  inspectionType: state.PorjectsReducer.inspectionType,
  projectEngineer: state.PorjectsReducer.projectEngineer,
  projectLead: state.PorjectsReducer.projectLead,
  projectMembers: state.PorjectsReducer.projectMembers,
  sLesseeList: state.sharedReducers.sLesseeList,
  msnSwitcher: state.sharedReducers.msnSwitcher,
  aircraftTypeList: state.PorjectsReducer.aircraftTypeList,
  bluePrintsInfo: state.PorjectsReducer.bluePrintsInfo,
  sManufacruerList : state.sharedReducers.sManufacruerList,
  formList: state.PorjectsReducer.formList,
  projectViewData: state.PorjectsReducer.projectViewData,
  msnDetails: state.PorjectsReducer.msnDetails,
  updatedForm: state.PorjectsReducer.updatedForm,
  techConstants:state.sharedReducers.techConstants,
  userDetail: state.PorjectsReducer.userDetail,
  inspUsage: state.PorjectsReducer.inspUsage,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    toggleEditAddUsers: (flag) => dispatch({
      type: UPDATE_USER_TYPES,
      payload: flag
    }),
    toggleEditAddSide: (data, slug) => dispatch(projectViewCrudAc(ownProps, data, slug)),
    workOrderView: (data) => dispatch(workOrderViewCrudAc(ownProps.params, data)),
    workOrderAddEdit: (data, type) => dispatch(workOrderAddEditAc(ownProps.params, data, type)),
    fetchProjectEngineer: (queryParam, type) => dispatch(fetchProjectUserAc(queryParam, type)),
    fetchProjectLead: (queryParam, type) => dispatch(fetchProjectUserAc(queryParam, type)),
    addEditProject: (data, type) => dispatch(addEditProjectAc(ownProps.param, data, type)),
    fetchLesseeList: (id) => dispatch(sLesseeListAc()),
		getMsnSwitcher: () => dispatch(getMsnSwitcherAc()),
    fetchMsnDetails: (aircraftSlug, type) => dispatch(msnDetailsAc(aircraftSlug, type)),
    fetchTechnicalInspection: (type) => dispatch(technicalInspectionTypeAc(type)),
    fetchAircraftType: () => dispatch(aircraftTypeAc()),
    fetchBluePrints: () => dispatch(fetchBluePrintsAc()),
    fetchManufactureList: () => dispatch(sManufacturerAc()),
    closeProject:(projectSlug)=>dispatch(closeProjectAc(projectSlug)),
    fetchForms: (queryParam={}) => dispatch(fetchProjectFormAc(queryParam)),
    fetchApuForms: (queryParam={}) => dispatch(fetchApuFormsAc(queryParam)),
    fetchProjectDetail: () => dispatch(projectViewAc(ownProps.params)),
    removeEngineer: (engineer, project_form) => dispatch(removeEngineerAc(ownProps.params, engineer, project_form)),
    changeUser: (addUser, removeUser, project_form) => dispatch(changeUserAc(ownProps.params, addUser, removeUser, project_form)),
    addUsers: (selectedUsers, projectRole, operator_level, flag) => dispatch(addUsersAc(ownProps.params, selectedUsers, projectRole, operator_level, flag)),
    fetchProjectDepartment: (type) => dispatch(sGlobalConstantAc(type)),
    launchWorkOrder: (workOrderSlug,type) => dispatch(launchWorkOrderAc(ownProps.params,workOrderSlug,type)),
    launcht012: () => dispatch(launcht012Ac(ownProps.params)),
    addEngineerToForm: (userIds, formSlug, projectSlug, addToAllForm,type, flag) => dispatch(addEngineerToFormAc(ownProps.params, userIds, formSlug, projectSlug, addToAllForm,type, flag)),
    getUserDetailFn : (userSlug, flag) => dispatch(getUserDetailAc(userSlug, flag)),
    updateT012FormStatus : (data) => dispatch(updateT012FormStatusAc(ownProps.params, data)),
    exportReport : (name) => dispatch(exportt012Ac(ownProps.params, name)),
    closeUserDetailModal: (data, flag) => dispatch({
      type: CLOSE_USER_MODAL,
      payload: {data: data, flag: flag}
    }),
    getT012FormData: (data) => dispatch(getT012FormDataAc(ownProps.params, data, true)),
    getUsageDetail: () => dispatch(getUsageDetailAc()),
    workOrderSidebar: (data) => dispatch(getWorkOrderDetailAc(data)),
    updateT012Data: (data) => dispatch({
      type: T012_DATA,
      payload: data
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
