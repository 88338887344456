import * as actions from '../actions';
import { globalGetService, globalExportService } from '../../../../globalServices/';
import { downloadFileType } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
import { CTR_SHORT_INFO } from '../../Listing/actions';
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const halfLifeListAc = (params) => {
  return (dispatch) => {
    globalGetService('console/contract/'+params.type+'/'+params.aircraft_slug + '/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        });
        let assetViewId = response.data.data
        trackActivity('Page Visited',{
          page_title: 'Half Life Maintenance Adjustment',
          application_module: 'Contracts',
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
        })
        dispatch(halfLifeApiAc(response.data.data.contract.slug, {}));
      }
    })
  }
}

export const halfLifeApiAc = (params, queryParams = {}) => {
  return (dispatch) => {
    dispatch({
      type: actions.HALF_LIFE_MAINT_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params+ '/half-life-adjustments/', queryParams)
    .then(response => {
      dispatch({
        type: actions.HALF_LIFE_MAINT_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.HALF_LIFE_LIST,
          payload: response.data.data
        })
      }
    })
  }

}

export const halfLifeExportAc = (contractInfo, fileType, filterParam) => {
  let queryParam = {'download': fileType};
  queryParam = {
    ...queryParam,
    projection_date: filterParam
  }
  return (dispatch) => {
    dispatch({
      type: actions.HALF_LIFE_MAINT_LOADER,
      payload: true
    });
    globalExportService('contracts/'+contractInfo.contract.slug+'/half-life-adjustments/',queryParam)
    .then(response => {
      dispatch({
        type: actions.HALF_LIFE_MAINT_LOADER,
        payload: false
      });
      downloadFileType(response, 'half_Life_Maintenance_'+contractInfo.msn, '.'+fileType)
      let assetViewId = contractInfo
      trackActivity('Report Exported',{
        page_title: 'Half Life Maintenance Adjustment',
        application_module: 'Contracts',
        asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
        file_name: 'Half Life Maintenance Adjustment',
        file_type: fileType.toUpperCase()
      })
    });
  }
}
