import React, {Component} from 'react';
import { Row, Col} from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import OperatorLessor from './OperatorLessor';
import { Tooltip } from 'reactstrap';
import { ToolTipHover, InfoIcon } from '../../shared'
import { assetStatus, imgStoragePath, assetsType } from '../../constants';
import { getLocalStorageInfo ,getFileSize} from '../../utils';
export default class AssetsList extends Component{
    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = {
        recordTooltip: false,
        technicalTooltip: false,
        assetViewTooltip: false,
        marksumTooltip: false
      };
    }
    toggle(type) {
      this.setState({
        [type]: !this.state[type]
      });
    }
    render(){
      const { assetList } = this.props;
      let url = "";
      const userInfo = getLocalStorageInfo()
      if(getLocalStorageInfo().user.user_role == 'lessee'){
        if(assetList && assetList.contract && Object.keys(assetList.contract).length){
          url = assetsType[assetList.asset_type] + "/" + assetList.slug + '/contract/utilization';
        }
      }else{
        url = '/asset-view/' + assetsType[assetList.asset_type] + "/" + assetList.slug;
      }

      let clickable = false
      if(assetList.is_active){
        clickable = true
      }
      if(assetList.is_active && (assetList.shared_asset != undefined && assetList.shared_asset != null && assetList.shared_asset == 0)){
        clickable = false
      }
      if((!assetList.is_active && assetList.data_transfer && Object.keys(assetList.data_transfer).length && assetList.data_transfer.value != 1)){
        clickable = true
      }
      return(
        <Link to={clickable ? '/asset-view/' + assetsType[assetList.asset_type] + "/" + assetList.slug : null} className="asset-list" style={clickable ? {cursor: 'pointer'} : {cursor: 'not-allowed', opacity: '0.5'}}>
          <Row className="flex-centered">
              <Col xs="2" md="2">
                <div className="flex-centered msn-block">

                    <div style={{display: 'inline', width: '20%'}}>
                      <Link style={clickable ? {} : {cursor: 'not-allowed', opacity: '0.6'}} to={clickable ? '/asset-view/' + assetsType[assetList.asset_type] + '/' + assetList.slug : null}>
                        <img src={imgStoragePath + (assetList.asset_type == 1 ? "icon_Aircraft.png" : (assetList.asset_type == 2) ? "icon_engine.png" : assetList.asset_type == 3 ? "icon_apu.svg" : assetList.asset_type == 4 ? 'icon_lg.svg' : assetList.asset_type == 5  ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32"/>
                      </Link>
                    </div>
                    <Link  style={clickable ? {display: 'inline', width: '80%',paddingLeft: '5px',} : {display: 'inline', paddingLeft: '5px', width: '80%', cursor: 'not-allowed', opacity: '0.5'}}>

                        <span>
                          {assetList.asset_type == 1 ?
                            assetList.aircraft_type.name + (assetList.model ?'-'+assetList.model:'')
                            :
                            assetList.engine_type ? assetList.engine_type.name : ''}
                          </span>
                        <h4>
                        {assetList.asset_type == 1 ? 'MSN ' + assetList.msn : assetList.asset_type == 2 ? 'ESN ' + assetList.esn : assetList.asset_type == 3 ? 'APU ' + assetList.serial_number : assetList.asset_type == 4 ? 'LG ' + assetList.serial_number : assetList.asset_type == 5 ? 'PROPELLER ' + assetList.serial_number : assetList.asset_type == 6 || assetList.asset_type == 7 || assetList.asset_type == 8 ? assetList.esn.toUpperCase() :  assetList.esn.toUpperCase() }</h4>
                      {
                        assetList.ownership_type !== undefined && assetList.ownership_type.value == 2 && assetList.portfolio.name && assetList.portfolio.name != ''  ?
                        <span style={{marginTop: '3px'}}><span style={{borderRadius: '2px'}} className="portfolio-type">Managed</span><InfoIcon id={assetList.slug + "portfolio"} /></span>
                        :null
                      }
                      {
                        assetList.ownership_type && assetList.ownership_type.value == 2 && assetList.portfolio.name && assetList.portfolio.name != ''?
                        <ToolTipHover placement="top" tagetId={assetList.slug + "portfolio"}>
                          Portfolio: {assetList.portfolio.name}
                        </ToolTipHover>
                        :null
                      }
                    </Link>
                </div>
              </Col>
              <Col xs= "1" md="1" >
                <h6 className="theta">{ assetList.lessor_name && assetList.lessor_name.name ? assetList.lessor_name.name :getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.name :'--'}</h6>
              </Col>
              <Col xs="1" md="1" >
                <h6 className="theta">{assetList.owner && assetList.owner.name ? assetList.owner.name  : getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.name :'--'}</h6>
              </Col>
              <OperatorLessor slug={assetList.slug + 'operator'} obj={assetList.lessee ? assetList.lessee : assetList.operator} />
              <Col xs="1"  md="1">
                <h6 className="theta">{assetList.asset_type == 1 ? assetList.current_registration_number?assetList.current_registration_number:'--' : '--'}</h6>
              </Col>
              <Col  xs="1" md="1">
                {/* <h6 className="theta">{ assetList.country?assetList.country:'--'}</h6> */}
              </Col>
              <Col  xs="1" md="2">
                <h6 className="theta">{
                   (getLocalStorageInfo().user.permission.records) ?
                    getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                    assetList.records_size && assetList.records_size.size ? getFileSize(assetList.records_size.size):0:assetList.records_size.published_size ? getFileSize(assetList.records_size.published_size):0
                    :'--'
                  }</h6>
              </Col>
              <Col  xs="1" md="1">
                <h6 className="theta">
                  <span className={"status-" + assetList.status.value}>{getLocalStorageInfo().defaultLessor.id === 348 ? assetList.status.value === 1 ? 'Operational' : 'AOG' : assetList.status.label ? assetList.status.label : '--'}</span>
                </h6>

              </Col>
              <Col xs="2" md="2">
                <ul className="list-inline asset-initial-icon" style={{float: 'right'}}>
                  {
                    assetList.is_records_enabled && getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.data_room && getLocalStorageInfo().user.permission.records.data_room.indexOf('R') != -1 ?
                    <li className="list-inline-item">
                      <img src={imgStoragePath+'phase-2/data_room_light.png'} />
                    </li>
                    :null
                  }
                  <li className="list-inline-item">
                    <img src={imgStoragePath+'phase-2/tech_spec_light.png'}/>
                  </li>
                  {
                    assetList.asset_type == 1 && userInfo.user.permission && userInfo.user.permission.technical_specs && userInfo.user.permission.technical_specs.asset && userInfo.user.permission.technical_specs.asset.indexOf('D') != -1 ?
                    <li className="list-inline-item">
                      <img src={imgStoragePath+'phase-2/market_report_light.png'} style={{width: '15px', height: '20px'}}/>
                    </li>:null
                  }
                  <li className="list-inline-item">
                    {/* <Link style={clickable ? {} : {cursor: 'not-allowed', opacity: '0.5'}} to={ clickable ? '/asset-view/' + (assetList.asset_type == 1 ? 'aircraft/' : 'engine') + assetList.slug : null}> */}
                      <span style={{ color: '#91c0ff'}} id={assetList.slug + "asset_view"}>...</span>
                      <ToolTipHover placement="top" tagetId={assetList.slug + "asset_view"}>
                        Asset View
                      </ToolTipHover>
                    {/* </Link> */}
                  </li>
                </ul>
                <ul className="list-inline asset-list-cta" style={{float: 'right'}}>
                  {
                    assetList.is_records_enabled && getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.data_room && getLocalStorageInfo().user.permission.records.data_room.indexOf('R') != -1 ?
                    <li className="list-inline-item">
                      <span
                        style={clickable ? {} : {cursor: 'not-allowed', opacity: '0.5'}}
                        to={clickable && userInfo.user.permission && userInfo.user.permission.records && userInfo.user.permission.records.data_room && userInfo.user.permission.records.data_room.indexOf('R') != -1 && assetList.is_records_enabled ?
                          (
                            assetList.asset_type == 1 ? 'aircraft'
                            : assetList.asset_type == 2 ? 'engine'
                            : assetList.asset_type == 3 ? 'apu'
                            : assetList.asset_type == 4 ? 'lg'
                            : assetList.asset_type == 6 ? 'fan'
                            : assetList.asset_type == 7 ? 'hpc'
                            : assetList.asset_type == 8 ? 'hpt'
                            : 'lpt'
                          )+'/'+assetList.slug+'/records/dataRoom/'
                        : null}
                        onClick={(e) => {e.preventDefault(); browserHistory.push(clickable ?
                          (
                            assetList.asset_type == 1 ? 'aircraft'
                            : assetList.asset_type == 2 ? 'engine'
                            : assetList.asset_type == 3 ? 'apu'
                            : assetList.asset_type == 4 ? 'lg'
                            : assetList.asset_type == 5 ? 'propeller'
                            : assetList.asset_type == 6 ? 'fan'
                            : assetList.asset_type == 7 ? 'hpc'
                            : assetList.asset_type == 8 ? 'hpt'
                            : 'lpt'
                          )+'/'+assetList.slug+'/records/dataRoom/' : null) }}>
                        <img style={{width: '20px'}} id={assetList.slug + "dataRoom"} src={imgStoragePath+'dataroom_dark_icon.png'} alt="" />
                        <Tooltip placement="top" isOpen={this.state.recordTooltip} target={assetList.slug + "dataRoom"} toggle={() => this.toggle('recordTooltip')}>
                          Data Room
                        </Tooltip>
                      </span>
                    </li>
                    :null
                  }
                  <li className="list-inline-item">
                    <span
                      style={clickable ? {} : {cursor: 'not-allowed', opacity: '0.5'}}
                      to={clickable &&
                        ((assetList.asset_type == 1 && userInfo.user.permission && userInfo.user.permission.technical_specs && userInfo.user.permission.technical_specs.overview && userInfo.user.permission.technical_specs.overview.indexOf('R') != -1 )
                        || (assetList.asset_type == 2 && userInfo.user.permission && userInfo.user.permission.technical_specs && userInfo.user.permission.technical_specs.engine && userInfo.user.permission.technical_specs.engine.indexOf('R') != -1 ) )
                        && assetList.is_technical_enabled ? 'technical-specification/'+
                        (assetList.asset_type == 1 ? 'aircraft'
                        : assetList.asset_type == 2 ? 'engine'
                        : assetList.asset_type == 3 ? 'apu'
                        : assetList.asset_type == 4 ? 'lg'
                        : assetList.asset_type == 5 ? 'propeller'
                        : assetList.asset_type == 6 ? "fan"
                        : assetList.asset_type == 7 ? "hpc"
                        :  assetList.asset_type == 8  ? "hpt"
                        : 'lpt')+'/'+assetList.slug : null}
                        onClick={(e) => {e.preventDefault(); browserHistory.push(clickable ? 'technical-specification/'+
                        (assetList.asset_type == 1 ? 'aircraft'
                        : assetList.asset_type == 2 ? 'engine'
                        : assetList.asset_type == 3 ? 'apu'
                        : assetList.asset_type == 4 ? 'lg'
                        : assetList.asset_type == 5 ? 'propeller'
                        : assetList.asset_type == 6 ? "fan"
                        : assetList.asset_type == 7 ? "hpc"
                        : assetList.asset_type == 8 ? "hpt"
                        : "lpt")+'/'+assetList.slug : null) }}>
                      <img style={{width: '20px'}} id={assetList.slug + "technical_specs"} src={imgStoragePath+'aircraft.png'} alt="" />
                    <Tooltip placement="top" isOpen={this.state.technicalTooltip} target={assetList.slug + "technical_specs"} toggle={() => this.toggle('technicalTooltip')}>
                        Technical Specification
                      </Tooltip>
                    </span>
                  </li>
                  {
                    assetList.asset_type == 1 && userInfo.user.permission && userInfo.user.permission.technical_specs && userInfo.user.permission.technical_specs.asset && userInfo.user.permission.technical_specs.asset.indexOf('D') != -1 ?
                    <li className="list-inline-item">
                      <span style={clickable ? {} : {cursor: 'not-allowed', opacity: '0.5'}} onClick={(e) => {e.preventDefault();this.props.exportMarkeTechSummery()}}>
                        <img style={{width: '15px', height: '20px'}} id={assetList.slug + "marksumm"} src={imgStoragePath+'phase-2/market_report_dark.png'} alt="" />
                        <Tooltip placement="top" isOpen={this.state.marksumTooltip} target={assetList.slug + "marksumm"} toggle={() => this.toggle('marksumTooltip')}>
                          Marketing Summary
                        </Tooltip>
                      </span>
                    </li>:null
                  }
                  <li className="list-inline-item">
                    <span style={clickable ? {} : {cursor: 'not-allowed', opacity: '0.5'}} to={ clickable ? '/asset-view/' + assetsType[assetList.asset_type] + "/" + assetList.slug : null} onClick={(e) => {e.preventDefault(); browserHistory.push('/asset-view/' + assetsType[assetList.asset_type] + "/" + assetList.slug);} }>
                      <span id={assetList.slug + "_asset_view"}>...</span>
                        <ToolTipHover placement="top" tagetId={assetList.slug + "_asset_view"}>
                          Asset View
                        </ToolTipHover>
                    </span>
                  </li>
                </ul>
              </Col>
          </Row>
        </Link>
      )
    }
}
