import React, { Component } from 'react';
import { Button, Popover, PopoverBody} from 'reactstrap';

export default class FleetSummaryPopover extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,

    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  render() {
    const {  tagetId, placement, fleetObj, title, count } = this.props;
    return (
        <span className="flex-centered" id={tagetId} onClick={this.toggle}>
              <span  className="flex-centered more-type margin-0">  <b style={{ borderRadius: '3px'}} className="model-number">{title} ({count})</b></span>
            {fleetObj.length ? <Popover placement = "left" isOpen={this.state.popoverOpen} target={tagetId} toggle={this.toggle}>
                <PopoverBody className="summary-popover flex-centered">
                  { fleetObj ?
                    fleetObj.map((type, typeIndex) =>
                      type.model && type.model != "" ?
                        <span key={typeIndex} className="flex-centered"> <b className="model-number">{type.model}</b> <b className="model-count">{type.count}</b></span>
                      :null
                    ): null
                  }
                </PopoverBody>
            </Popover> : null}
        </span>
    );
  }
}
