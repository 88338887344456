import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router';
import { Creatable } from 'react-select';
import { checkString, checkEmail } from '../../formValidator'
import { DropDownField, GetInputField } from '../../applications/technicalInspection/Elements';
import { toolsBaseUrl } from '../../constants'
import { getLocalStorageInfo } from '../../utils'

export default class AddUser extends Component{
  constructor(props){
    super(props);
    this.state = {
      user:{},
      error: {},
      type:'edit',
      disabled: false,
      checkType: this.props.showContractor && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type == 1 ? 1 : 4
    }
  }
  componentDidMount(){
    this.setState({
      user:this.props.user
    });
  }
  updateForm = (key,value) => {
    if(key=='type'){
      this.setState(prevState => ({
        ...prevState,
        checkType:value
      }));
    }
    this.setState(prevState => ({
      ...prevState,
      user:{
        ...prevState.user,
        [key]:value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));
  }
  onChangeEmail = (user) => {

    if(user!=null && user!=undefined){
      this.setState(prevState => ({
        ...prevState,
        user: { ...prevState.user, ...user}
      }));
    }
    if(user ==null || user ==undefined){

      this.setState(prevState => ({
        ...prevState,
        user:{
          ...prevState.user,
          email:user,
          first_name:user,
          last_name:user,
          contact_no:user,
          designation:user,
          security_groups:user,
          organisation:user,
          reason_for_creation:user,
          description:user,





        },
        error: {
          email: ''
        }
      }));
    }
  }
  sendInviteUser = (e) => {
    e.preventDefault();
    const validateNewInput = {
      first_name: checkString({value: this.state.user.first_name, required: true, minLength: '', maxLength: '', message: 'Please enter Name'}),
      reason_for_creation: checkString({value: this.state.user.reason_for_creation, required: this.props.type == "new" ? true:false, minLength: '', maxLength: '', message: 'Please enter the Reason'}),
      email: checkEmail({value: this.state.user.email, required: true, minLength: '', maxLength: '', message: 'Please enter valid Email'}),
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.setState({
        disabled: true
      })
      this.props.inviteUser(this.state.user);
    }else{
      this.setState({
        error: validateNewInput,
        disabled: false
      });
    }
  }
  render(){
    const { type,error, user,checkType } = this.state;
    const { userEmails, groupList, downloadLoader } = this.props;
    var showContractor = window.location.href.includes(toolsBaseUrl) ? false : true
    showContractor = this.props.showContractor
    const userInfo = getLocalStorageInfo()
    let customHeight=document.body.contains(document.getElementById('sidebar-header')) ?document.getElementById('sidebar-header').clientHeight : 0;
    return(
      <form onSubmit={(e) => this.sendInviteUser(e)}>
        <div className="sidebar-form"  style={{ height: (window.innerHeight - customHeight) + 'px'}}> {/*(window.innerHeight - document.getElementById('sidebar-header').clientHeight)*/}
          <Row>
          { this.props.type == "new" && userInfo.defaultLessor && userInfo.defaultLessor.lessor_type == 1 ?
            <Col md="12" style={{marginBottom: '20px'}}>
              <label className="for-label">Email {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>
              <Creatable
                value={user.email}
                onChange={this.onChangeEmail}
                className="custom-select-block"
                placeholder="Enter Email"
                valueKey="email"
                labelKey="email"
                options= {userEmails}
                multi={false}
                noResultsText="Type New Email Address"
             />
             <h6 className="error-msg">{error.email}</h6>
            </Col>:
             <Col md="12" style={{marginBottom: '20px'}}>
             {
                GetInputField(
                  'string',
                  <label className="for-label">Email {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: user.email,
                    error: this.state.error.email,
                    updateForm: (value) => this.updateForm('email', value)
                  }
                )
              }
           </Col>}
            <Col md="12" style={{marginBottom: '20px'}}>
               {
                  GetInputField(
                    'string',
                    <label className="for-label">First Name {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                    { type: type,
                      value: user.first_name,
                      error: this.state.error.first_name,
                      updateForm: (value) => this.updateForm('first_name', value)
                    }
                  )
                }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                  GetInputField(
                    'string',
                    <label className="for-label">Last Name </label>,
                    { type: type,
                      value: user.last_name,
                      error: this.state.error.last_name,
                      updateForm: (value) => this.updateForm('last_name', value)
                    }
                  )
                }
            </Col>

            <Col md="12" style={{marginBottom: '20px'}}>
              {
                  GetInputField(
                    'string',
                    <label className="for-label">Contact Number </label>,
                    { type: type,
                      value: user.contact_no,
                      error: this.state.error.contact_no,
                      updateForm: (value) => this.updateForm('contact_no', value)
                    }
                  )
                }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                  GetInputField(
                    'string',
                    <label className="for-label">Designation</label>,
                    { type: type,
                      value: user.designation,
                      error: this.state.error.designation,
                      updateForm: (value) => this.updateForm('designation', value)
                    }
                  )
                }
            </Col>
            { this.props.type == "new"?
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label">Security Groups <span><Link to="/users/permissions/" target="_blank">View Security Permissions</Link></span></label>,
                  { type: type,
                    value: user.security_groups,
                    options: groupList,
                    labelKey: 'name',
                    valueKey: 'id',
                    updateForm: (value) => this.updateForm('security_groups', value)
                  }
              )}
            </Col>:null}
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'textarea',
                  <label className="for-label">Organization</label>,
                  { type: type,
                    value: user.organisation,
                    error: this.state.error.organisation,
                    updateForm: (value) => this.updateForm('organisation', value)
                  }
                )
              }
            </Col>
            { this.props.type == "new"?
              <Col md="12" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'textarea',
                    <label className="for-label">Reason For Creation<sup className="reqStar"> * </sup></label>,
                    { type: type,
                      value: user.reason_for_creation,
                      error: this.state.error.reason_for_creation,
                      updateForm: (value) => this.updateForm('reason_for_creation', value)
                    }
                  )
                }
              </Col>:
              <Col md="12" style={{marginBottom: '20px'}}>
                {
                  GetInputField(
                    'string',
                    <label className="for-label">Description</label>,
                    { type: type,
                      value: user.description,
                      error: this.state.error.description,
                      updateForm: (value) => this.updateForm('description', value)
                    }
                  )
                }
              </Col>
            }
            <Col md="12" style={showContractor && userInfo.defaultLessor.lessor_type == 1 && userInfo.user.permission.console && !userInfo.user.permission.console.is_saas? {marginBottom: '20px'} : {display: 'none'}}>
              <label className="for-label">User Type</label>
              <FormGroup check inline>
                  <Label check className="for-label">
                    <Input
                    type="radio"
                    name="radio1"
                    onChange={(e) => this.updateForm('type', 1)}
                    checked={checkType==1?true:false}
                    />
                    {'Acumen'}
                </Label>
              </FormGroup>
              <FormGroup check inline>
                  <Label check className="for-label">
                    <Input
                    type="radio"
                    name="radio1"
                    onChange={(e) => this.updateForm('type', 2)}
                    checked={checkType==2?true:false}
                    />
                    {'Client'}
                </Label>
              </FormGroup>
              <FormGroup check inline>
                  <Label check className="for-label">
                    <Input
                    type="radio"
                    name="radio1"
                    onChange={(e) => this.updateForm('type', 3)}
                    checked={checkType==3?true:false}
                    />
                    {'Contractor'}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <div style={{height:'60px'}}></div>
        </div>
        <div className="edit-sidebar-footer">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button disabled={this.state.disabled} style={this.state.disabled ? {cursor: 'not-allowed'} : {}} type="submit" className="btn btn-primary">{this.state.disabled ? 'Processing...' :this.props.type=='new' ? 'Add User':'Send Invite'}</button>
            </li>
            <li className="list-inline-item">
              <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
            </li>
           </ul>
        </div>
      </form>
    )
  }
}
