import React, {Component} from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { getLocalStorageInfo } from '../../../../utils';
import '../../../../shared/assets/styles/CommonHeaderBlock.scss';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
export default class ReviewFinanceRptWrapper extends Component{
    constructor(props){
        super(props);
    }
    render(){
        const { generalInfo, reviewMRLoader, engines, mrReportError, logId, pageNo } = this.props;
        let engineGraphFlag = [];

        if(engines){
          Object.keys(engines).map((label, index) => {
            if(!engines[label].workscopes[Object.keys(engines[label].workscopes)[0]].nsv){
              engineGraphFlag.push(index+1);
            }
          });
        }
        return(
            <div className="reviewFinanceRpt-blk">
                <div className="generate-finance-heading" style={{paddingBottom:'20px', paddingLeft: '34px', paddingRight: '30px', marginBottom: '15px',paddingTop:'30px'}}>
                {
                    (generalInfo.use_acumen_algorithm && getLocalStorageInfo().defaultLessor.id == 24) ?
                    <p className="contract-draft-mode" style={{position: 'fixed', botton: '0px', width: '100%', top: this.props.topFixedPos, left:"240px"}}>
                      <span style={{left: '-140px', position: 'relative'}}>This Report is Based On Acumen Data Points</span>
                    </p>:null
                  }
                    <h2 style={{position: 'relative',float:'none', marginBottom: '16px'}} className="beta"><Link to={'/logs?page='+pageNo }><img src={imgStoragePath+"back_arw.png"} alt="" width="16"/></Link>Generate a Finance Report
                      <button style={ engineGraphFlag.length ? {cursor: 'no-drop', background: '#a5cbfa'}:{}} disabled={ reviewMRLoader || engineGraphFlag.length } type="submit" className="review-btn primary-btn" onClick={this.props.genMRFinanceGraph}>{reviewMRLoader?'Processing ...': 'Analyze MR Cash Flow'}</button>
                      { engineGraphFlag.length ? <span style={{position: 'absolute', right: '0px', top: '37px', fontSize: '10px'}}>Update engine <b>{engineGraphFlag.map((label,index) => engineGraphFlag.length - 1 != index ? '#' + label + ' & ' :'#'+label )}</b> usage  details to Review MR Data</span>:null }
                      {Object.keys(mrReportError).length ? <span style={{position: 'absolute', right: '0px', top: '47px', fontSize: '10px', color: '#ff0000'}}>Fields You Enter are not Correct</span>:null }
                    </h2>

                      <div className="review-wrapper-block">
                          <ul style={{float:"none"}} className="list-inline generate-select-boxes">
                              <li className="list-inline-item">
                                  <span style={{ float : 'none', display: 'inline-block', marginBottom: '3px' }}>Log Id</span>
                                  <p className="para-ui">{logId}</p>
                              </li>
                              { generalInfo.aircraftType ?
                                <li className="list-inline-item">
                                    <span style={{ float : 'none', display: 'inline-block', marginBottom: '3px' }}>Aircraft Type</span>
                                    <p className="para-ui">{generalInfo.aircraftType.name}</p>
                                </li> : null
                              }
                            { generalInfo.aircraftType ?
                                <li className="list-inline-item">
                                    <span style={{ float : 'none', display: 'inline-block', marginBottom: '3px' }}>Date of Manufacture</span>
                                    <p className="para-ui">
                                        {moment(generalInfo.aircraft_manufacture_date).format(displayDateFormat)}</p>
                                </li>  : null
                            }
                            { generalInfo.aircraftType ?
                                <li className="list-inline-item">
                                    <span style={{ float : 'none', display: 'inline-block', marginBottom: '3px' }}>Maintenance Program</span>
                                    <p className="para-ui">
                                        {generalInfo.maintenance_program[Object.keys(generalInfo.maintenance_program)[0]].split(',').join('/')}
                                    </p>
                                </li> : null
                            }

                            {
                                generalInfo.airframe_tsn ?
                                <li className="list-inline-item">
                                    <span style={{ float : 'none', display: 'inline-block', marginBottom: '3px' }}>TSN</span>
                                    <p className="para-ui">
                                    {generalInfo.airframe_tsn}
                                    </p>
                                </li>:null
                            }
                            {
                                generalInfo.airframe_csn ?
                                <li className="list-inline-item">
                                    <span style={{ float : 'none', display: 'inline-block', marginBottom: '3px' }}>CSN</span>
                                    <p className="para-ui">
                                    {generalInfo.airframe_csn}
                                    </p>
                                </li>:null
                            }
                          </ul>
                      </div>

                </div>
                {this.props.children}
            </div>

        );
    }
}
