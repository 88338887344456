import * as actions from '../actions';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { toastFlashMessage, getLocalStorageInfo, getDataUri, dataURItoBlob } from '../../../../../utils';
import moment from 'moment';
import { trackActivity } from '../../../../../utils/mixpanel';
import { usageDetailAc } from '../../../../records/DataRoom/actionCreators'
const userInfo = getLocalStorageInfo();
const dissemblyObj = {
  title: " ",
	remarks: " ",
	finding_pictures: []
}

export const fetchTableInspectionAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, {})
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.TABLE_INSPECTION_DETAILS,
					payload: response.data.data
				})
			}
		})
	}
}


export const updateTableInspectionAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService(`/technical/engine-workorder/${props.params.workOrderSlug}/table-inspections/`, data)
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				toastFlashMessage(response.data.message, 'success');
				trackActivity('Table Inspection Details Saved', {
					page_title: `TE005`,
					application_module: 'Inspection',
					workorder_slug: props.params.workOrderSlug,
					form_slug: props.params.formSlug,
				  })
				dispatch({
					type: actions.TABLE_INSPECTION_DETAILS,
					payload: response.data.data
				})
			}
		})
	}
}


export const GenUrlAc = (props, files, type) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		var formData = new FormData();
		if(type == 'attachment'){
			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!(file.type.match('application/pdf.*')||file.type.match('application/vnd.ms-excel.*')||file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.*'))) {
					toastFlashMessage( file.name + ' is not Valid Format', 'error');
					 continue;
				 }
			 formData.append('file', file, file.name);
			}
			formData.append('shop_visit', true);
		}else{
			let file=[];
			for (let i = 0; i < files.length; i++) {
				let file = files[i];
				if (!file.type.match('image.*')) {
					toastFlashMessage( file.name + ' is not Valid Format', 'error');
					 continue;
				 }
		      formData.append('files[]', file, file.name);
			}


		}

		var config = {
			onUploadProgress: function(progressEvent) {
			 var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
					// dispatch({
					//   type: actions.PROGRESS_PERCENTAGE,
					//   payload: percentage
					// });
				}
		}
		globalFileUploadService('/technical/engine-workorder/'+props.params.workOrderSlug+'/file-upload/', formData, config)
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				if(type == 'attachment'){
					dispatch({
						type:actions.SV_UPLOAD_FILE,
						payload: {attachment : response.data.url, name: response.data.name, created_at: moment(), size: response.data.size, user: { id: userInfo.user.id, name: userInfo.user.name, profile_pic: userInfo.user.profile_pic  }}
					})
					trackActivity('File Uploaded', {
						page_title: `TE005`,
						application_module: `Table Inspection`,
						workorder_slug: props.params.workOrderSlug,
						form_slug: props.params.formSlug,
						file_name: files[0].name,
						file_type: files[0].type
					  })
				}else{
					for (let i = 0; i < response.data.data.length; i++) {
					dispatch({
						type: actions.UPDATE_DISS_ASSEM_FIELD,
						payload: {'image': response.data.data[i].url, sectionType: 'dis_finding', description: ''}
					})
				}
				}
			}
      dispatch(usageDetailAc())
		})
	}
}


export const deleteShopVisitAc = (props, id, type, index) => {
	return(dispatch) => {
	if(type == 'llp_details'){
		if(!id){
			dispatch({
				type: actions.LLP_DELETE,
				payload: index
			});
		}else{
			dispatch(triggerLoader(true));
			globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/llp-details/${id}/`,{})
			.then(response => {
				dispatch(triggerLoader(false));
				if(response.data.statusCode == 200){
					dispatch({
						type: actions.LLP_DELETE,
						payload: index
					});

				}
			})
		}
	}else if(type == 'dissasembly') {
		dispatch(triggerLoader(true));
		globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly/${id}/`,{})
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.DIS_ASSEM_DELETE,
					payload: index
				});
				trackActivity('Disassebly Findings Deleted', {
					page_title: `TE005`,
					application_module: 'Inspection',
					item_type:'Table Inspection',
					workorder_slug: props.params.workOrderSlug,
					form_slug: props.params.formSlug,
					item_id: id
				  })
			}
		})
	}else{
		if(!id){
			dispatch({
				type: actions.DELETE_SV_ATTACHMENT,
				payload: index
			});
		}else{
			dispatch(triggerLoader(true));
			globalDeleteService(`technical/shop-visit-attachment/${id}/delete/`,{})
			.then(response => {
				dispatch(triggerLoader(false));
				if(response.data.statusCode == 200){
					dispatch({
						type: actions.DELETE_SV_ATTACHMENT,
						payload: index
					});
					trackActivity('File Deleted', {
						page_title: `TE005`,
						application_module: `Table Inspection`,
						workorder_slug: props.params.workOrderSlug,
						form_slug: props.params.formSlug,
						file_id: id,
					  })
				}
			})
		}
	toastFlashMessage('Shop visit attachment deleted successfully', 'success');
	}
	}
}

export const AddEditDisAssemblyAc = (props, dataObj) => {
	console.log(dataObj.data)
	return(dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/'+props.params.workOrderSlug+'/disassembly/', {data: dataObj.data})
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				if(dataObj.data.id){
					dispatch({
						type: actions.UPDATE_DISS_ASSEM_DATA,
						payload: response.data.data
					});
				}else{
					dispatch({
						type: actions.ADD_DISS_ASSEM_DATA,
						payload: response.data.data
					});
				}
				toastFlashMessage(response.data.message, 'success');
				trackActivity('Disassebly Findings Added', {
					page_title: `TE005`,
					application_module: 'Inspection',
					item_type:'Table Inspection',
					workorder_slug: props.params.workOrderSlug,
					form_slug: props.params.formSlug,
					item_id: dataObj.data.id
				  })
			}
		})
	}
}

export const editViewDisAssemBlkAc = (props, id,type) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/engine-workorder/${props.params.workOrderSlug}/disassembly/${id}/`, {})
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.VIEW_DISS_ASSEM_DATA,
					payload: {data: response.data.data, flag: true, type:type}
				})
				trackActivity('Disassebly Findings Edited', {
					page_title: `TE005`,
					application_module: 'Inspection',
					item_type:'Table Inspection',
					workorder_slug: props.params.workOrderSlug,
					item_id: id
				  })
			}
		})
	}
}

export const saveEditedImageAc = (props, imageId, image, index, crudObj) => {
	var formData = new FormData();
  return (dispatch) =>{
		dispatch(triggerLoader(true));
    dataURItoBlob(image).then(editedImage => {
			formData.append('file', editedImage);
      globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly-picture/${imageId}/`,formData)
      .then(response =>{
				dispatch(triggerLoader(false));
        if(response.data.statusCode == 200 ){
          dispatch({
            type: actions.SAVE_EDITED_DA_IMG,
            payload:{image: response.data.url, imageId: imageId, index: index}
          });
          dispatch({
            type: actions.EDIT_DA_IMAGE,
            payload: { flag: false, data: {} }
          });
        }
        dispatch(editViewDisAssemBlkAc(props, crudObj.data.id, crudObj.type))
        toastFlashMessage(response.data.message, 'success');
      })
    })
  }
}

export const deletePicAc = (props, id, index) => {
	return (dispatch) => {
	if(!id){
		dispatch({
			type: actions.DELETE_DA_IMAGE,
			payload: index
		});
	}else{
		dispatch(triggerLoader(true));
		globalDeleteService(`technical/engine-workorder/${props.params.workOrderSlug}/disassembly-picture/${id}/delete/`,{})
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.DELETE_DA_IMAGE,
					payload: index
				});
				toastFlashMessage(response.data.message, 'success');
			}
		})
	}
}
}
export const editPlatePicAc = (props, data) => {
  return (dispatch) => {
    dispatch(triggerLoader(true));
    let fileInfo = Object.assign({}, data);
    globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/get-pictures-url/`, {pic_id: data.data.id})
    .then(response => {
      if(response.data.statusCode == 200){
        fileInfo = {
          ...fileInfo,
          image: response.data.data
        }
        dispatch({
          type: actions.EDIT_DA_IMAGE,
          payload: {flag: true, data:fileInfo}
        })
      }
      dispatch(triggerLoader(false));
    })
  }
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.TI_LOADER,
			payload: flag
		});
	}
}
