import * as actions from '../actions';
export default function( state = {
  findingSections: [],
  auditFindingCrud: {
    type: 'add',
    title: '',
    flag: false,
    data: {}
  },
  auditReportsCrud: {
    type: 'add',
    title: '',
    flag: false,
    data: []
  },
  findingCrud:{
    flag: false,
    imageUrl: '',
    title: '',
    imageId: 0
  },
  findingLoader: false
},action ){
  switch (action.type) {
    case actions.FINDING_LOADER:
     return{
       ...state,
       findingLoader: action.payload
     }
   case actions.FINDING_LIST:
    return{
      ...state,
      findingSections: action.payload
    }
   case actions.EDIT_FINDING:
   
    return{
      ...state,
      findingCrud: action.payload
    }
   case actions.AUDITS_FINDING_INFO:
    return {
      ...state,
      auditFindingCrud: action.payload
    }
    case actions.AUDITS_SECTIONS_INFO:
      return {
        ...state,
        auditReportsCrud: action.payload
      }
    case actions.ADD_FINDINGS_T007:
      return {
        ...state,
        auditReportsCrud: {
          ...state.auditReportsCrud,
          data: [...state.auditReportsCrud.data].map(auditReport => auditReport.id == action.payload.sectionId ? {
              ...auditReport,
              findings: [action.payload.data, ...auditReport.findings]
            } : auditReport
          ),

        },
        // findingSections : {
        //   ...state.findingSections,
        //   list : [...state.findingSections.list].map(data => data.id == action.payload.sectionId ? {
        //     ...data,
        //     finding_count: [...state.auditReportsCrud.data.findings].filter(findingData => findingData.findings).length
        //    }
        //   : data
        //     )
        // },
        auditFindingCrud : {
          ...state.auditFindingCrud,
          flag: false,
          title: '',
        }
      }
    case actions.UPDATE_FINDINGS:
      return {
        ...state,
        auditReportsCrud: {
          ...state.auditReportsCrud,
          data: [...state.auditReportsCrud.data].map(auditReport => auditReport.id == action.payload.sectionId ? {
              ...auditReport,
              findings: [...auditReport.findings].map(findingData => findingData.id == action.payload.data.id ? action.payload.data : findingData )
            } : auditReport
          ),

        },
        auditFindingCrud : {
          ...state.auditFindingCrud,
          flag: false,
          title: '',
        }
      }
     case actions.CHANGE_IMAGE:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: [...state.auditFindingCrud.data.images].map(image =>  image.id == action.payload.id ?
              action.payload
              :  image)
          },
        },
      }
   case actions.UPDATE_ADD_IMAGE:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: action.payload
          },
        },
      }
    case actions.EDIT_IMAGE:
     return state;
    case actions.DELETE_FINDING:
    return {
      ...state,
      auditReportsCrud: {
        ...state.auditReportsCrud,
        data: [...state.auditReportsCrud.data].map(auditReport => auditReport.id == action.payload.sectionId ? {
            ...auditReport,
            findings: [...auditReport.findings].filter(findingData => findingData.id != action.payload.findingId )
          } : auditReport
        ),
      },
      findingSections : {
        ...state.findingSections,
        list : [...state.findingSections.list].map(data => data.id == action.payload.sectionId ? {
          ...data,
          finding_count: action.payload.finding_count
         }
        : data
          )
      }
    }
    case actions.UPDATE_IMAGE_IN_EDIT:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: action.payload
          },
        },
      }
    case actions.DELETE_IMAGE:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: [...state.auditFindingCrud.data.images].filter(image =>  image.id != action.payload )
          },
        },
      }
     case actions.DELETE_ADD_IMAGE:
      return {
        ...state,
        auditFindingCrud: {
          ...state.auditFindingCrud,
          data: {
            ...state.auditFindingCrud.data,
            images: [...state.auditFindingCrud.data.images].filter((image, index) =>  index != action.payload.picIndex )
          },
        },
      }
      case actions.TOGGLE_T007_T004:
        return {
          ...state,
          auditFindingCrud :{
            type:"add",
            title:'',
            flag:false,
            data:{}
          }
        }


     default:
      return state;
  }
}
