import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import moment from 'moment'
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import {  fieldDateFormat, backendDateFormat } from '../../../../constants';
import { checkString, checkValidDate } from '../../../../formValidator';
export default class AddEditLeaseOption extends Component {
  constructor(props){
    super(props);
    this.state = {
      leaseOption: {},
      error: {}
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.leaseOptionObj));
    delete data['lease_option'];
    data = {
      ...data,
      lease_option : this.props.leaseOptionObj.lease_option && this.props.leaseOptionObj.lease_option.value ? this.props.leaseOptionObj.lease_option.value : '',
      lease_option_name : this.props.leaseOptionObj && this.props.leaseOptionObj.lease_option.label ? this.props.leaseOptionObj.lease_option.label : ''
    }
    this.setState({
      leaseOption: data
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      leaseOption: {
        ...prevState.leaseOption,
        [type]: (value == 'Invalid date' ? null:value)
      },
      error: {
        ...prevState.error,
        [type]:''
      }
    }))
  }

  addEditLeaseOption = (e) => {
    const leaseOption = Object.assign({}, this.state.leaseOption);
    e.preventDefault();
    var validateNewInput = {
      lease_option: checkString({value: leaseOption.lease_option, required: true, minLength: '', maxLength: '', message: 'Please Enter The Lease Option '}),
      details: checkString({value: leaseOption.details, required: true, minLength: '', maxLength: '', message: 'Please Enter The Lease Details '}),
    };

    if(leaseOption.validity_end_date && leaseOption.validity_end_date.trim() != '' && parseInt(moment(leaseOption.validity_end_date).diff(moment(leaseOption.validity_start_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        validity_end_date: 'Validity end date can not be before Validity start date'
      }
    }
    if(leaseOption.notice_period_end_date && leaseOption.notice_period_end_date.trim() != '' && parseInt(moment(leaseOption.notice_period_end_date).diff(moment(leaseOption.notice_period_start_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        notice_period_end_date: 'Notice Period end date can not be before Notice Period start date'
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(leaseOption.end_date && leaseOption.start_date){
        if(moment(leaseOption.end_date).diff(moment(leaseOption.start_date), 'days', true) <= 0){
          this.setState({
            error: {
              start_date: 'Start Date cannot be greater than End Date'
            }
          });
          return;
        }
      }else{
        if(leaseOption.end_date){
          if(!leaseOption.start_date){
            this.setState({
              error: {
                start_date: 'Start Date cannot be Blank'
              }
            });
            return;
          }
        }
      }
      if(leaseOption.notice_period_start_date &&  leaseOption.notice_period_end_date){
        if(moment(leaseOption.notice_period_end_date).diff(moment(leaseOption.notice_period_start_date), 'days', true) <= 0){
          this.setState({
            error: {
              notice_period_start_date: 'Notice Period Start Date cannot be greater than End Date'
            }
          });
          return;
        }
      }else{
        if(leaseOption.notice_period_end_date){
          if(!leaseOption.notice_period_start_date){
            this.setState({
              error: {
                start_date: 'Notice period Start Date cannot be Blank'
              }
            });
            return;
          }
        }
      }
      this.props.addEditLeaseOption(this.state.leaseOption, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render() {
    const { leaseOption, error } = this.state;
    const { type, techConstants } = this.props;
    return (
      <form onSubmit={(e) => this.addEditLeaseOption(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Type <sup className="reqStar"> * </sup></FieldLabel>
                <FieldSelect
                  placeholder='Select Type'
                  keyParam="lease_option"
                  value={leaseOption.lease_option}
                  type={type}
                  options={techConstants.filter(item => item.type=="lease_option_type")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {leaseOption.lease_option_name}
                />
              <h6 className="error-msg">{error.lease_option}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Validity Start Date </FieldLabel>
                <FieldDate
                  value={leaseOption.validity_start_date}
                  type={type}
                  keyParam="validity_start_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.start_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Validity End Date </FieldLabel>
                <FieldDate
                  value={leaseOption.validity_end_date}
                  type={type}
                  keyParam="validity_end_date"
                  updateField={this.updateForm}
                  minDate={moment(leaseOption.validity_start_date)}
                />
              <h6 className="error-msg">{error.end_date}</h6>
              <h6 className="error-msg">{error.validity_end_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Notice Period Start Date </FieldLabel>
                <FieldDate
                  value={leaseOption.notice_period_start_date}
                  type={type}
                  keyParam="notice_period_start_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.notice_period_start_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Notice Period End Date </FieldLabel>
                <FieldDate
                  value={leaseOption.notice_period_end_date}
                  type={type}
                  keyParam="notice_period_end_date"
                  updateField={this.updateForm}
                  minDate={moment(leaseOption.notice_period_start_date)}
                />
              <h6 className="error-msg">{error.notice_period_end_date}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Details <sup className="reqStar"> * </sup></FieldLabel>
                <FieldTextarea
                  value={leaseOption.details}
                  type={type}
                  keyParam="details"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.details}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={leaseOption.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          this.props.type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
    )
  }
}
