  import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
export default class CashTxRow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { cashFlow } = this.props;
    if(cashFlow.type_of_transaction == 1){
      return (
        <Row className="cash-non-cash-trxn-row" onClick={this.props.viewCashTransaction}>
          <Col md="3" xs="3">
            <span className="para-ui">{cashFlow.reference_number ? cashFlow.reference_number : '--'}</span>
          </Col>
          <Col md="3" xs="3">
            <span className="para-ui">{cashFlow.transaction_date ? moment(cashFlow.transaction_date).format(displayDateFormat) : '--'}</span>
          </Col>
          <Col md="3" xs="3">
            <span className="para-ui">{cashFlow.retention_period_start_date && cashFlow.retention_period_end_date ? `${moment(cashFlow.retention_period_start_date).format(displayDateFormat)} To ${moment(cashFlow.retention_period_end_date).format(displayDateFormat)}` : '--'}</span>
          </Col>
          <Col md="3" xs="3">
            <span className="para-ui">
              {cashFlow.amount ?
                    <NumericLabel params={showCurrencyFormat(cashFlow.currency)}>{cashFlow.amount}</NumericLabel>
                  : '--'}
            </span>
          </Col>
        </Row>
    )
    }else{
      return null
    }
  }
}
