import React, { Component } from 'react'
import { PaymentCardList } from '../components'
import { connect } from 'react-redux'
import { BillingSidebar } from '../../billing/components'
import { getSavedCardsAc, defaultCardAc, getCardDetailAc, updateAddressAc, deleteCardAc, toggleAutoDebitAc } from '../actionCreators'
import { NoRecordFound, ListLoader } from '../../../shared'
import { Modal, ModalBody, Row, Col } from 'reactstrap'
import { imgStoragePath } from '../../../constants'
import { CARD_DETAIL, UPDATE_CARD_DETAIL } from '../actions'
import { DeleteModal } from  '../../../applications/technicalInspection/Elements';

class PaymentMethods extends Component{
  constructor(props){
    super(props)
    this.state ={
      error:{},
      deleteCard: {
        flag: false,
        card_token: ''
      }
    }
  }

  submit = () => {
    let validateNewInput = {
      name: this.props.cardDetail.data.name.trim() == '' ? 'First name is required' : '',
      address_line1: this.props.cardDetail.data.address_line1.trim() == '' ? 'Address is required' : '',
      city: this.props.cardDetail.data.city.trim() == '' ? 'City is required' : '',
      state: this.props.cardDetail.data.state.trim() == '' ? 'State is required' : '',
      country: this.props.cardDetail.data.country.trim() == '' ? 'Country is required' : '',
      postal_code: this.props.cardDetail.data.postal_code.trim() == '' ? 'Postal Code is required' : '',
      phone: this.props.cardDetail.data.phone.trim() == '' ? 'Phone is required' : '',
    }

      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.props.updateAddress(this.props.cardDetail.data)
      }else{
        this.setState({
          error: validateNewInput
        })
      }
  }

  updateAddress = (key, value) =>{
    this.props.updateCardDetail({key, value})
  }

  componentDidMount(){
    this.props.getSavedCards()
  }

  render(){
    const { cardsList, cardsLoader, cardDetail } = this.props
    return(
      <div className="billing-container">
        <BillingSidebar/>
        <div className="container-fluid billing-content-wrapper">
            <h1 className="page-title">
              Payment Methods
            </h1>
            <p className="block-subs">Includes the approved Credit / Debit cards that the user has configured for the license purchases</p>
            <div class="projects-tab">
              <ul class="list-inline ui-tabs">
                <li class="list-inline-item "><a class="active">Credit / Debit Cards</a></li>
              </ul>
              {
                !cardsLoader && cardsList && cardsList.list && cardsList.list.length ?
                <span className="switch-blk">
                    <span>{cardsList.enable_auto_pay ? 'Disable' : 'Enable'} auto recurring payment</span>
                    <label className="switch" style={{ marginLeft: '5px' }}>
                      <input type="checkbox" checked={cardsList.enable_auto_pay} onClick={() => this.props.toggleAutoDebit({auto_pay: cardsList.enable_auto_pay == true ? 0 : 1})}/>
                      <div className="slider round">
                        {
                          // cardsList.enable_auto_pay == true ?
                          // <span style={{ fontSize: '12px', position: 'relative', left: '5px', bottom: '1px', color: '#2893ff' }}>ON</span>
                          // :
                          // <span style={{ fontSize: '12px', position: 'relative', left: '24px', bottom: '1px', color: '#a1a1a1'  }}>OFF</span>
                        }
                      </div>
                    </label>
                </span>
                :null
              }
            </div>
            {
              // <span className="warning-info-text" style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
              //   Default card will be charged automatically for License subscription. All major credit / debit cards accepted.
              // </span>
            }
            <div className="payment-modal">
            <div className="cardsList">
              <div className="inner-con row">
              {
                cardsList && cardsList.list && cardsList.list.length ?
                  cardsList.list.map((card, index) =>
                  <div className="col-md-4" style={{height: 'fit-content', minWidth: '360px'}}>
                    <PaymentCardList activeSubscription={cardsList.active_subscription} deleteCard={() => this.setState({deleteCard: {flag: true, card_token: card.id}})} getCardDetail={() => this.props.getCardDetail(card)} key={index} selected={cardsList.default_card_id} selectCard={() => this.props.defaultCard({card_id: card.id, default_source: true})} card={card}/>
                  </div>
                )
                : !cardsLoader ? <div className="col-md-12"><NoRecordFound/></div> : null
              }
              </div>
            </div>
            </div>
        </div>
        {
          cardsLoader ?
            <ListLoader/>
          :null
        }
        <Modal isOpen={cardDetail.flag} toggle={() => this.props.toggleCardCrud({flag: false, data: {brand: 'visa'}})} className="edit-card-modal">
          <img onClick={() => this.props.toggleCardCrud({flag: false, data: {brand: 'visa'}})} src={ imgStoragePath+'black_close.png' } style={{ position: 'absolute', top: '8px', right: '8px', width: '12px', cursor: 'pointer', zIndex: '99' }} />
          <ModalBody>
            <div className="cardsList">
              <div className="inner-con row">
                <div className="card-list-item payment-list-item-row row" style={{marginLeft: '15px', marginBottom: '20px'}}>
                  <div className="col-md-2">
                    <img src={`${imgStoragePath}phase-2/cards/${cardDetail.data.brand.toLowerCase().replace(' ','')}.png`} />
                  </div>
                  <div className="card-number col-md-10">
                      <span>
                        XXXX XXXX XXXX {cardDetail.data.last4}
                      </span>
                      <span className="expiry-date">Expires On: {cardDetail.data.exp_month > 9 ? cardDetail.data.exp_month : '0' + cardDetail.data.exp_month}/{cardDetail.data.exp_year}</span>
                  </div>

                </div>
              </div>
            </div>
            <div className="payment-con checkout">
              <Row>
                <Col md="12"><label className="label">Billing Address<span className="reqStar">*</span></label></Col>
              <Col md="12" className="margin-bottom-20">
                  <input type="text" name="first_name" value={cardDetail.data.name} onChange={(evt) => this.updateAddress('name', evt.target.value)} placeholder="First Name"/>
                  <h6 className="error-msg">{this.state.error.name}</h6>
                </Col>
                <Col md="12" className="margin-bottom-20" >
                  <input type="text" value={cardDetail.data.address_line1} onChange={(evt) => this.updateAddress('address_line1', evt.target.value)} placeholder="Address"/>
                <h6 className="error-msg">{this.state.error.address_line1}</h6>
                </Col>
                <Col md="4" className="margin-bottom-20">
                  <input type="text" value={cardDetail.data.city} onChange={(evt) => this.updateAddress('city', evt.target.value)} placeholder="City"/>
                <h6 className="error-msg">{this.state.error.city}</h6>
                </Col>
                <Col md="4" className="margin-bottom-20">
                  <input type="text" value={cardDetail.data.state} onChange={(evt) => this.updateAddress('state', evt.target.value)} placeholder="State / Region"/>
                <h6 className="error-msg">{this.state.error.state}</h6>
                </Col>
                <Col md="4" className="margin-bottom-20">
                  <input type="text" value={cardDetail.data.postal_code} onChange={(evt) => this.updateAddress('postal_code', evt.target.value)} placeholder="Postal Code"/>
                <h6 className="error-msg">{this.state.error.postal_code}</h6>
                </Col>
                <Col md="6" className="margin-bottom-20">
                  <input type="text" value={cardDetail.data.country} onChange={(evt) => this.updateAddress('country', evt.target.value)} placeholder="Country"/>
                <h6 className="error-msg">{this.state.error.country}</h6>
                </Col>
                <Col md="6" className="margin-bottom-20">
                  <input type="text" value={cardDetail.data.phone} onChange={(evt) => this.updateAddress('phone', evt.target.value)} placeholder="Phone Number"/>
                <h6 className="error-msg">{this.state.error.phone}</h6>
                </Col>
                <Col md="12">
                  <button disabled={cardsLoader} onClick={() => this.submit()} type="button" className="add-new btn-primary">Update</button>
                </Col>
                <Col md="12">
                  <p>* All fields are required</p>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        <DeleteModal isOpen={this.state.deleteCard.flag} toggle={() => this.setState({deleteCard: {flag: false, card_token: ''}})} title={"Remove Card"} className="modal-tform-delete-pictures">
          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to remove this card ?</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.setState({deleteCard: {flag: false, card_token: ''}});this.props.deleteCard({card_token: this.state.deleteCard.card_token});}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.setState({deleteCard: {flag: false, card_token: ''}})}>No</button>
            </li>
          </ul>
       </DeleteModal>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
  cardsList: state.LicenseReducer.cardsList,
  cardsLoader: state.LicenseReducer.cardsLoader,
  cardDetail: state.LicenseReducer.cardDetail,
})

const mapDispatchToProps = (dispatch, ownProps) =>{
  return{
    getSavedCards : () => dispatch(getSavedCardsAc()),
    defaultCard : (card) => dispatch(defaultCardAc(card)),
    getCardDetail: (card) => dispatch(getCardDetailAc(card)),
    toggleCardCrud: (card) => dispatch({
      type: CARD_DETAIL,
      payload: card
    }),
    updateCardDetail: (update) => dispatch({
      type: UPDATE_CARD_DETAIL,
      payload: update
    }),
    updateAddress: (address) => dispatch(updateAddressAc(address)),
    deleteCard: (card) => dispatch(deleteCardAc(card)),
    toggleAutoDebit: (autoPay) => dispatch(toggleAutoDebitAc(autoPay)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods)
