import React, { Component } from 'react';
import JSONViewer from 'react-json-viewer'
import NoRecordFound from './NoRecordFound';
import {  displayDateTimeFormatShort, imgStoragePath } from '../../constants';
import moment from 'moment';

export default class AddDeleteAudit extends Component{
  constructor(props) {
    super(props);

    this.state = {
      tableStatus:  false
    };
  }



  dataShow = () => {
    this.setState(prevState => ({
      ...prevState,
      tableStatus: !this.state.tableStatus
    }))
  }
  render(){
    const { auditLogView } = this.props;
    return(
        <div className="flex-not-centered outer-wrapper" >

          <div className="first-block">
            <div className="height-block">
              { auditLogView.action == 0 ? auditLogView.action == 2 ?
                 <img src={imgStoragePath+"del_audit_icn.png"} alt="img"/> :
                 <img src={imgStoragePath+"add_audit_icn.png"} alt="img"/> : auditLogView.action == 1 ? <img src={imgStoragePath+"edit_audit_icn.png"} alt="img"/> : null
            }
                <div className="vl"></div>
            </div>
          </div>
          <div className="scnd-block">
          <div className="shadow-block">
            <div className="flex-not-centered">
              <div className="sec-inner-frst-block">
                  <h6>
                  { auditLogView.action == 0 ? auditLogView.action == 2 ?
                    'Deleted at' :
                    'Created at' : auditLogView.action == 1 ? 'Updated at':null }
                  </h6>
                  <p>{ auditLogView.timestamp ?  moment(auditLogView.timestamp).format(displayDateTimeFormatShort)   : '--' }</p>
              </div>
              <div className="sec-inner-sec-block">
                  <h6>
                  { auditLogView.action == 0 ? auditLogView.action == 2 ?
                    'Deleted by' :
                    'Created by' : auditLogView.action == 1 ? 'Updated by' : null }
                    </h6>
                  <p>{ auditLogView.user &&  auditLogView.user.name ? auditLogView.user.name : '--' }</p>
              </div>
            </div>
            <div className="show-data">
              <span onClick={this.dataShow}>Show Data 	<i style={ this.state.tableStatus ? { transform: 'rotate(180deg)' }:  { transform: 'rotate(0)' } }>&#9660;</i></span>
              <div className="edit-detail-table" style={ this.state.tableStatus ? {display: 'block'} :  {display: 'none' } }>
                <JSONViewer json={auditLogView.changes}></JSONViewer>

                </div>
                </div>
              </div>
          </div>
      </div>

    )
  }
}
