import React from 'react';
import moment from 'moment';
import ContractHdEl from './ContractHdEl';
import { getAccessToken } from '../../../utils';
import { displayDateFormat } from  '../../../constants';
const ContractLeaseInfo = (props) => {
  return(
    <ul className="list-inline generate-select-boxes flex-centered">
      {!props.leaseInfo.same_operator_lessee ?
        <ContractHdEl title="Operator">
          {props.leaseInfo.operator.logo ?
            <img style={{ height: '28px',maxWidth:'102px' }} src={props.leaseInfo.operator.logo} alt={props.leaseInfo.operator.name} />
            : props.leaseInfo.operator.name
          }
        </ContractHdEl>:null
      }
      <ContractHdEl title="Lessee">
        <span>
        {props.leaseInfo.lessee.logo ?
          <img style={{ height: '28px',maxWidth:'102px' }} src={props.leaseInfo.lessee.logo} alt={props.leaseInfo.lessee.name} />
          : props.leaseInfo.lessee.name
        }
      </span>
      </ContractHdEl>
      <ContractHdEl title="Contractual Start Date">
        <span>
          {props.leaseInfo.contractual_start_date ?
            moment(props.leaseInfo.contractual_start_date).format(displayDateFormat)
            :'--'
          }
        </span>
      </ContractHdEl>
      <ContractHdEl title="Contractual End Date">
        <span>
          {props.leaseInfo.contractual_end_date ?
            moment(props.leaseInfo.contractual_end_date).format(displayDateFormat)
            :'--'
          }
        </span>
      </ContractHdEl>
      <ContractHdEl title="Lease Term">
        <span>{ props.leaseInfo.lease_term ? props.leaseInfo.lease_term + ' Months':'--' }</span>
      </ContractHdEl>
    </ul>
  )
}
export default ContractLeaseInfo
