import React from 'react';
import { imgStoragePath } from '../../../../constants';
import {  ExportFiles } from '../../../../shared';
const BulkOperation = (props) => {

  return(
    <div className={"add-bulk-operations " + (props.selectedIds.length ? 'selected-operations':'')}>
      <ul className="list-inline flex-centered">
        {
          props.selectedIds.length ?
          <li className="list-inline-item"><img onClick={props.cancelBulkOp} src={imgStoragePath + "blue_cross_icon.png"} style={{width: '15px', marginRight: '5px'}} /> {props.selectedIds.length} items selected</li>:null
        }

       { <li className="list-inline-item operation-cta">
          <ExportFiles exportFile={(fileType) => props.assetExport('Assets',fileType)} files={[{img:'excel_icon.png', label: 'Excel', type:'xls'}]} />
      </li>}
      </ul>
      <button style={{opacity: props.selectedIds.length ?1:0.5}} onClick={() => props.toggleSidebar()} className="aims-primary-btn">{props.addText ? props.addText : 'Pass Add Text'}</button>
    </div>
  )
}
export default BulkOperation;
