import React, { Component } from 'react';
import closeIcon from '../../User/assets/img/black_close.png'
class MaintenanceWarning extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div className="maintenance-bar" id="maintenance">
               <p>SPARTA is under maintenance from 12:35PM UTC, May 31st 2021  to 12:35 PM UTC, June 1st 2021, during this period you may face intermittent issues, inconvenience is regretted.
                    <span style={{display: 'none'}} onClick={() => this.props.maintenaceMsg()}><img src={closeIcon} alt="img"/></span>
               </p>
           </div>
           )
    }
}

export default MaintenanceWarning;
