import React, { Component } from 'react';
import { imgStoragePath } from '../../constants';
export default function PermissionModuleTable({paramkey, module, item}){
  return (
    <tr>
      <td style={{textTransform:'capitalize', color:'#838383'}}>
        {  paramkey == "eol_options" ? "EOL Options"
          :paramkey == "ad_validator" ? "AD Validator"
          :paramkey == "apu" ? "APU"
          :paramkey == "stc_mods" ? "STC Mods"
          :paramkey.replace(/_/g, " ")}
      </td>
      <td>{item.permission[module][paramkey].indexOf(1) != -1 ?
            <img src={imgStoragePath+"green-tick.png"} width={17} />
            : <img src={imgStoragePath+"cross_red.png"} width={17} />
          }
      </td>
      <td>{item.permission[module][paramkey].indexOf(2) != -1 ?
            <img src={imgStoragePath+"green-tick.png"} width={17} />
            : <img src={imgStoragePath+"cross_red.png"} width={17} />
          }
      </td>
      <td>{item.permission[module][paramkey].indexOf(3) != -1 ?
            <img src={imgStoragePath+"green-tick.png"} width={17} />
            : <img src={imgStoragePath+"cross_red.png"} width={17} />
          }
      </td>
      { module == "records" ?
        <td>{item.permission[module][paramkey].indexOf(4) != -1 ?
              <img src={imgStoragePath+"green-tick.png"} width={17} />
              : <img src={imgStoragePath+"cross_red.png"} width={17} />
            }
        </td> : null
      }

      <td>{item.permission[module][paramkey].indexOf(5) != -1 ?
            <img src={imgStoragePath+"green-tick.png"} width={17} />
            : <img src={imgStoragePath+"cross_red.png"} width={17} />
          }
      </td>
    </tr>
  )
}
