import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class BreakUpEngineMRCostModel extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.props.breakUpEngineMRCostFn();
  }
  render() {
    const {breakUpEngineMRCost} = this.props;
    return (
      <div className="">
        <Modal isOpen={breakUpEngineMRCost.modalStatus} toggle={this.toggle} className="llp-brekup-modal">
          <div className="modal-header">
            <h6 className="shop-visit-heading caption-ui">Shop Visit #{breakUpEngineMRCost.runId}</h6>
            <h5 className="replace-block">
              Breakup of Engine MR Costs
            </h5>
          </div>
            <ModalBody>
              <h5 className="error-msg text-center">{breakUpEngineMRCost.wrongEntry}</h5>
              <table>
                <thead>
                  <tr>
                    <th className="caption-ui">LLP Type</th>
                    <th className="caption-ui">Natinal Module</th>
                    <th className="caption-ui ">Remaining</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(breakUpEngineMRCost.module_percentages.Core).length ?
                    Object.keys(breakUpEngineMRCost.module_percentages.Core).map((label, index) =>
                      <tr key={index}>
                        <td className="caption-ui">{ index > 0 ? '':'Core'}</td>
                        <td className="caption-ui">
                          {label}
                          <span style={{paddingLeft: '10px'}}>
                            <input style={{background: '#FAFAFA', borderBottom: '1px solid #eaeaea', padding: '2px'}} type="text" onChange={(e) => this.props.updateEngineMRCostFn(e.target.value, 'Core', label)} value={breakUpEngineMRCost.module_percentages.Core[label].percentage} />
                            %
                            <hr/>
                          </span>
                        </td>
                        <td className="caption-ui">$ {breakUpEngineMRCost.module_percentages.Core[label].cost}</td>
                      </tr>
                    ):null
                  }
                  {
                    Object.keys(breakUpEngineMRCost.module_percentages.LPT).length ?
                    Object.keys(breakUpEngineMRCost.module_percentages.LPT).map((label, index) =>
                      <tr key={index}>
                        <td className="caption-ui">{ index > 0 ? '':'LPT'}</td>
                        <td className="caption-ui">
                          {label}
                          <span style={{paddingLeft: '10px'}}>
                            <input style={{background: '#FAFAFA', borderBottom: '1px solid #eaeaea', padding: '2px'}} type="text" onChange={(e) => this.props.updateEngineMRCostFn(e.target.value, 'LPT', label)} value={breakUpEngineMRCost.module_percentages.LPT[label].percentage} />
                            %
                            <hr/>
                          </span>
                        </td>
                        <td className="caption-ui">$ {breakUpEngineMRCost.module_percentages.LPT[label].cost}</td>
                      </tr>
                    ):null
                  }
                  {
                    Object.keys(breakUpEngineMRCost.module_percentages.Fan).length ?
                    Object.keys(breakUpEngineMRCost.module_percentages.Fan).map((label, index) =>
                      <tr key={index}>
                        <td className="caption-ui">{ index > 0 ? '':'FAN'}</td>
                        <td className="caption-ui">
                          {label}
                          <span style={{paddingLeft: '10px'}}>
                            <input style={{background: '#FAFAFA', borderBottom: '1px solid #eaeaea', padding: '2px'}} type="text" onChange={(e) => this.props.updateEngineMRCostFn(e.target.value, 'Fan', label)} value={breakUpEngineMRCost.module_percentages.Fan[label].percentage} />
                            %
                            <hr/>
                          </span>
                        </td>
                        <td className="caption-ui">$ {breakUpEngineMRCost.module_percentages.Fan[label].cost}</td>
                      </tr>
                    ):null
                  }
                </tbody>
              </table>
            </ModalBody>
          <ModalFooter>
            <button onClick={this.props.breakUpEngineCostApiFn}>Save Changes</button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
