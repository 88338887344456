import React, { Component } from 'react';
import { imgStoragePath } from '../../../../../constants';
import { TextInputField,DateInputField, GetInputField } from '../../../Elements';


class InspectedComponents extends Component {
	constructor(props){
		super(props)
	}



	render(){
		const { content } = this.props;
 		return(
			<tr>
				<td>{this.props.index + 1}</td>
				<td>
				{
					GetInputField(
						'textarea',
						null,
						{ type: 'edit',
							value:content.description,
							error: this.props.error && this.props.error.description ?  this.props.error.description : '',
							updateForm: (value) => this.props.updateForm({type: 'description', value:value, sectionType: 'componentInspected', index:this.props.index})
						}
					)
          }
				</td>
				<td>
					{
            GetInputField(
              'textarea',
              null,
              { type: 'edit',
                value:content.findings ? content.findings : '',
                error: '',
                updateForm: (value) => this.props.updateForm({type: 'findings', value: value, sectionType: 'componentInspected', index: this.props.index})
              }
            )
          }
				</td>
				<td>
				{
            GetInputField(
              'dropdown',
              null,
              { type: 'edit',
							 value:content.status ? content.status: '',
								error: '',
								labelKey:'label',
                valueKey: 'value',
								options: [{label: 'Serviceable', value: 1},{label: 'Unserviceable', value: 2},{label: 'Restricted', value: 3}],                
								updateForm: (value) => this.props.updateForm({type: 'status', value: value, sectionType: 'componentInspected', index: this.props.index})
              }
            )
          }
				</td>				
				<td >
					<img width="12" className="" src={imgStoragePath+"delete_red.png"} onClick={this.props.toggleComDelModal}/>
				</td>
		</tr>
		)
	}
}

export default InspectedComponents;