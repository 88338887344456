import React, { Component } from 'react';
import {  Row, Col } from 'reactstrap';
import CKeditor from "../../../../shared/CKEditor";
import {  GetInputField } from '../../Elements';

export default class BoroScopeDetails  extends Component {
  constructor(props) {
    super(props);
  }

  updateForm = (evt) => {
    this.props.updateForm({type: 'inspection_details', value:evt.editor.getData(), sectionType: 'EngineCurrentDetails', index:this.props.index})  
  }
  render() {
  const { content,error } = this.props;
  return(
    <div className="general-detail-block" style={{background: '#fff'}}>
     <Row  style={{margin: '0px 15px'}}>
      <Row style={{width:'100%'}}>
         <Col sm="4"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'date',
              <label className="for-label">Date Of Inspection</label>,
              { type: 'edit',
              value: content.date_of_inspection,
              error:  error.date_of_inspection,
              minDate: content.engine_manufacturing_date,
              updateForm: (value) => this.props.updateForm({type: 'date_of_inspection', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>

         <Col sm="4"  style={{marginBottom: '20px'}}>
         {
            GetInputField(
              'string',
              <label className="for-label">Inspection Performed By</label>,
              { type: 'edit',
              value: content.inspection_performed_by,
              error:  '',
              updateForm: (value) => this.props.updateForm({type: 'inspection_performed_by', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
              }
            )
          }
         </Col>
      </Row>
      <Row style={{marginTop:'20px'}}>
      <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSN</label>,
              { type: 'edit',
              value: content.inspection_tsn,
              error:  error.inspection_tsn,
              updateForm: (value) => this.props.updateForm({type: 'inspection_tsn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSN</label>,
              { type: 'edit',
              value: content.inspection_csn,
              error:  error.inspection_csn,
              updateForm: (value) => this.props.updateForm({type: 'inspection_csn', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
               }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSLSV</label>,
              { type: 'edit',
              value: content.inspection_tslv,
              error:  error.inspection_tslv,
              updateForm: (value) => this.props.updateForm({type: 'inspection_tslv', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSLSV</label>,
              { type: 'edit',
              value: content.inspection_cslv,
              error:  error.inspection_cslv,
              updateForm: (value) => this.props.updateForm({type: 'inspection_cslv', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSR</label>,
              { type: 'edit',
              value: content.inspection_tsr,
              error:  error.inspection_tsr,
              updateForm: (value) => this.props.updateForm({type: 'inspection_tsr', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
               }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSR</label>,
              { type: 'edit',
              value: content.inspection_csr,
              error:  error.inspection_csr,
              updateForm: (value) => this.props.updateForm({type: 'inspection_csr', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSO</label>,
              { type: 'edit',
              value: content.inspection_tso,
              error:  error.inspection_tso,
              updateForm: (value) => this.props.updateForm({type: 'inspection_tso', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSO</label>,
              { type: 'edit',
              value: content.inspection_cso,
              error:  error.inspection_cso,
              updateForm: (value) => this.props.updateForm({type: 'inspection_cso', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
         <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">TSHSI</label>,
              { type: 'edit',
              value: content.inspection_tshi,
              error:  error.inspection_tshi,
              updateForm: (value) => this.props.updateForm({type: 'inspection_tshi', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
          <Col sm="3"  style={{marginBottom: '20px'}}>
           {
            GetInputField(
              'string',
              <label className="for-label">CSHSI</label>,
              { type: 'edit',
              value: content.inspection_cshi,
              error:  error.inspection_cshi,
              updateForm: (value) => this.props.updateForm({type: 'inspection_cshi', value:value, sectionType: 'EngineCurrentDetails', index:this.props.index})
                }
            )
          }
         </Col>
      </Row>
      <Row style={{width:'100%',marginTop:'20px'}}>
      <Col>
       <CKeditor
            events={{"change": this.updateForm}}
            content={content.inspection_details}
          />
        </Col>
      </Row>
    </Row>
    </div>
    );
  }
}
