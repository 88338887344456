import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GEN_MR_REPORT } from '../actions';
import { dateFormat, fieldDateFormat, backendDateFormat } from '../../../../constants';
class MRReviewFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      fieldFocus : false,
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true
    });
    if(this.props.errorMsg){
      this.props.updateErrorCode(this.props.type, this.props.keyParam);
    }
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false
    });
  }

  render() {
    const { inputType, prefix, addSymbol, keyParam, type, label, errorMsg, value, col} = this.props;
    if(this.props.inputType == "text"){
      return(
        <Col md={col}>
          <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'} }>{label}</label>
          <span className="field-container">
            { prefix && addSymbol ? <span className="prefix">$</span> : null }
            <input type="text"
              value={value}
              onChange={(e) => this.props.updateReviewMR(e.target.value==''?null:e.target.value, keyParam, type)}
              onFocus={() => this.handleFocus()}
              onBlur={() => this.handleBlur()}
            />
          { !prefix && addSymbol ? <span className="suffix">%</span> : null}
          </span>
          <h6 className="error-msg">{errorMsg}</h6>
        </Col>
      )
    }else{
      return(
        <Col md={col}>
          <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'} }>{this.props.label}</label>
          <span>
            <DatePicker
              dateFormat = {fieldDateFormat}
              selected = {value && moment(value).isValid() ? moment(value):null}
              onChange={(e) => this.props.updateReviewMR(moment(e).format(backendDateFormat), keyParam, type)}
              onFocus={() => this.handleFocus()}
              onBlur={() => this.handleBlur()}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
             />
          </span>
         <h6 className="error-msg">{errorMsg}</h6>
        </Col>
      )
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateReviewMR: (value, keyParam, type) => dispatch({
      type: GEN_MR_REPORT,
      payload: {value, keyParam, type}
    })
  }
}
export default connect(null, mapDispatchToProps)(MRReviewFields);
