import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router';
export default class LoginForm extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { loginLoader } = this.props;
    return(
      <Col md="6" className="flex-centered rh-block">
        <div className="form-block">
        <div className="login-heading-block">
         <h2 className="wc-login-block">Welcome Back</h2>
          <h6 className="continue-subheading">Sign in to Continue to SPARTA</h6>
        </div>
          <form onSubmit={(e) => this.props.loginSubmit(e)}>
            <div className="form-group">
              <label>Registered Email Address</label>
              <input className="" type="text" name="email"  />
              <h6 className="error-msg">{this.props.errors.emailMsg}</h6>
            </div>
            <div className="form-group">
              <label>Password </label>
              <input className="" type="password" name="password" />
              <h6 className="error-msg">{this.props.errors.passwordMsg}</h6>
              <Link to="/forgot-password" className="forget-pass">Forgot Password ?</Link>
            </div>
            <div className="input-group">
              <input type="submit" disabled={loginLoader} value={loginLoader?'Processing...': 'LOGIN' }/>
              <div className="condition-block">
                   <p>By using SPARTA, you are agreeing to these</p>
                  <Link to="/privacy-policy" className="privacy-policy" target="_blank" >
                  Privacy Policy</Link>
                  <Link to="/terms" target="_blank">Terms and Conditions</Link>
                  <Link to="mailto:support@sparta.aero" target="_blank">Contact Us</Link>
                </div>
            </div>
          </form>
        </div>
      </Col>
    )
  }
}
