import React, {Component} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Collapse,  Card, CardBody} from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
export default class EngineMRWrapper extends Component {
  constructor(props){
    super(props);
    this.state = {
      collapse: true
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  render() {
    return(
      <div className="common-mr-card">
        <h6 className="para"  onClick={this.toggle}>
          Engines
          <img style={this.state.collapse ? {transform: 'rotate(0deg)'}: {transform: 'rotate(180deg)'}} src={imgStoragePath + "collapse_arw.png"} alt="img"/>
        </h6>
        <Collapse className="engine-mr-detail" isOpen={this.state.collapse}>
           {this.props.children}
        </Collapse>
      </div>
    )
  }
}
