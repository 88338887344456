import * as actions from '../actions';
export default function( state = {
  technicalInspection: [],
  aircraft_model: [],
  lessee_list: [],
  engine_model: [],
  technicalInspectionLoader: false
}, action ){

  switch (action.type) {
    case actions.GET_TECHNICAL_SPECS:
      return {
        ...state,
        technicalInspection: action.payload
      }
    case actions.TECHNICAL_SPECS_LOADER:
      return {
        ...state,
        technicalInspectionLoader: action.payload
      }
    case actions.ADD_COMPONENT:
      let componentObj = Object.assign({}, action.payload.component);
      componentObj.answers = [];
      componentObj.allow_dynamic = false;
      componentObj.is_custom_component = true;
      componentObj.component_id = componentObj.id;
      delete componentObj.id;
      delete componentObj.name;
      delete componentObj.custom_components;
      delete componentObj.custom_components;
      delete componentObj.sub_components;
      return {
        ...state,
        technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
        sectionIndex == action.payload.sectionIndex ?
        {...section, component_questions: section.component_questions.map((component, componentIndex) =>
          componentIndex == action.payload.componentIndex ? { ...component, custom_components: [...component.custom_components, componentObj] } :component
        )}
        : section
      )}
    case actions.REMOVE_COMPONENT:
      return {
        ...state,
        technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
        sectionIndex == action.payload.sectionIndex ?
        { ...section, component_questions: section.component_questions.map((component, componentIndex) =>
          componentIndex == action.payload.componentIndex ?
          {...component, custom_components: component.custom_components.filter((subComponent, subComponentIndex) => subComponentIndex != action.payload.subComponentIndex)}
           :component
        )}
        : section
      )}
    case actions.REMOVE_SUB_COMPONENT:
      return {
        ...state,
        technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
        sectionIndex == action.payload.sectionIndex ?
        { ...section, component_questions: section.component_questions.map((component, componentIndex) =>
          componentIndex == action.payload.componentIndex ?
          {...component, sub_components: component.sub_components.map((subComponent, subComponentIndex) =>
            subComponentIndex == subComponentIndex?
            {...subComponent, custom_components: subComponent.custom_components.filter((subComponent, customSubComponentIndex) => customSubComponentIndex != action.payload.customSubComponentIndex)}
           :component
            )}
           :component
        )}
        : section
      )}
    case actions.ADD_SECTION:
      componentObj = Object.assign({}, action.payload.component);
      componentObj.answers = [];
      componentObj.allow_dynamic = false;
      componentObj.is_custom = true;
      componentObj.component_id = componentObj.id;
      delete componentObj.id;
      delete componentObj.name;
      let technicalInspectionArray = Object.assign([], state.technicalInspection);
      technicalInspectionArray[action.payload.sectionIndex].sections.splice(action.payload.index + 1, 0, componentObj);
      return{
        ...state,
        technicalInspection: technicalInspectionArray
      }
    case actions.ADD_SUB_COMPONENT:
      componentObj = Object.assign({}, action.payload.component);
      componentObj.answers = [];
      componentObj.allow_dynamic = false;
      componentObj.is_custom = true;
      componentObj.component_id = componentObj.id;
      delete componentObj.id;
      delete componentObj.name;
      technicalInspectionArray = Object.assign([], state.technicalInspection);
      technicalInspectionArray[action.payload.sectionIndex].component_questions[action.payload.index].splice(action.payload.subIndex + 1, 0, componentObj);
      return{
        ...state,
        technicalInspection: technicalInspectionArray
      }
    case actions.ADD_MAINTENANCE_CHECKS:
      return {
        ...state,
        technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
        sectionIndex == action.payload.sectionIndex ?
        { ...section, component_questions: section.component_questions.map((component, componentIndex) =>
          componentIndex == action.payload.componentIndex ?
          { ...component, sub_components: component.sub_components.map(subComponent => {return {...subComponent, custom_components: [...subComponent.custom_components, {answers:[], specs: subComponent.specs, maintenance_specs: subComponent.maintenance_specs}]}})}
          : component
        )}
         : section
      )}
        return state;
    case actions.UPDATE_TECHNICAL_SPECS:
      // Function To Update/Create the answer
      function updateAnswer(otherInfo, answers, key, value){
        let newAnswers = answers;
        if(answers.filter(answer => answer.label == key).length){
          newAnswers = newAnswers.map(newAnswer => newAnswer.label == key ? {...newAnswer, value: value} : newAnswer)
        }else{
          if(otherInfo.component_configuration_id){
            newAnswers = [...newAnswers, {label: key, value: value, component_configuration_id: otherInfo.component_configuration_id}]
          }else if (otherInfo.sub_component_configuration_id) {
            newAnswers = [...newAnswers, {label: key, value: value, sub_component_configuration_id: otherInfo.sub_component_configuration_id}]
          }else{
            newAnswers = [...newAnswers, {label: key, value: value }]
          }
        }
        return newAnswers;
      }
      // sectionId // IDEA:
      // componentId index
      // subComponentId Index:
      // key
      // value
      if(action.payload.specsType == 'sections'){
        return {
          ...state,
          technicalInspection: state.technicalInspection.map((section, sectionIndex) => action.payload.sectionIndex == sectionIndex ?
          {...section, sections: section.sections.map((sec, componentIndex) => componentIndex == action.payload.componentIndex ?
            {...sec, answers: updateAnswer(action.payload.otherInfo, sec.answers, action.payload.key, action.payload.value)}
            : sec)}
          :section)
        }
      }else{
        if(action.payload.componentIndex == null && action.payload.subComponentIndex == null){
          return{
            ...state,
            technicalInspection: state.technicalInspection.map((section, sectionIndex) => action.payload.sectionIndex == sectionIndex ?
              {...section, answers: updateAnswer(action.payload.otherInfo, section.answers, action.payload.key, action.payload.value)  }:section
            )
          }
        }else if(action.payload.subComponentIndex == null){
          return {
            ...state,
            technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
            sectionIndex == action.payload.sectionIndex ? {...section, component_questions: section.component_questions.map((component,componentIndex) =>
              componentIndex == action.payload.componentIndex ? { ...component, answers: updateAnswer(action.payload.otherInfo, component.answers, action.payload.key, action.payload.value)} :component
            )} : section
          )}
        }else{
          if(action.payload.specsType == 'custom_components'){
            return {
              ...state,
              technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
              sectionIndex == action.payload.sectionIndex ?
              {...section, component_questions: section.component_questions.map((component, componentIndex) =>
                componentIndex == action.payload.componentIndex ?
                {...component, custom_components: component.custom_components.map((subComponent, subComponentIndex) =>
                  subComponentIndex == action.payload.subComponentIndex ?
                  {...subComponent, answers:updateAnswer(action.payload.otherInfo, subComponent.answers, action.payload.key, action.payload.value)}
                   : subComponent
                )}
                :component
              )}
               : section
            )}
          }else if(action.payload.specsType == 'custom_subComponent'){
            return {
              ...state,
              technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
              sectionIndex == action.payload.sectionIndex ?
              { ...section, component_questions: section.component_questions.map((component, componentIndex) =>
                componentIndex == action.payload.componentIndex ?
                { ...component, sub_components: component.sub_components.map((subComponent, subComponentIndex) =>
                  subComponentIndex == action.payload.subComponentIndex ?
                  { ...subComponent,  custom_components: subComponent.custom_components.map((customSubComponent, customSubComponentIndex) =>
                    customSubComponentIndex == action.payload.customSubComponentIndex ?
                    { ...customSubComponent, answers: updateAnswer(action.payload.otherInfo, customSubComponent.answers, action.payload.key, action.payload.value) }
                     :customSubComponent
                  )}
                   : subComponent
                )}
                 : component
              )}
               : section
            )}
          }else{
            return {
              ...state,
              technicalInspection: state.technicalInspection.map((section, sectionIndex) =>
              sectionIndex == action.payload.sectionIndex ?
              {...section, component_questions: section.component_questions.map((component, componentIndex) =>
                componentIndex == action.payload.componentIndex ?
                {...component, sub_components: component.sub_components.map((subComponent, subComponentIndex) =>
                  subComponentIndex == action.payload.subComponentIndex ?
                  {...subComponent, answers:updateAnswer(action.payload.otherInfo, subComponent.answers, action.payload.key, action.payload.value)}
                   : subComponent
                )}
                :component
              )}
               : section
            )}
          }

        }
      }
      case actions.T005_AIRCRAFT_MODEL:
        return {
          ...state,
          aircraft_model: action.payload
        }
      case actions.T005_OPERATOR_LIST:
        return {
          ...state,
          lessee_list: action.payload
        }
      case actions.T005_ENGINE_MODEL:
        return {
          ...state,
          engine_model: action.payload
        }
      default:
      return state;
  }
}
