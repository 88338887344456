import React, { Component } from 'react';
import { Link } from 'react-router';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
import EnggCorAvg from './EnggCorAvg';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
export default class EnggCordetails  extends Component {
  constructor(props){
		super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
	}
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  render() {
		const { engineers, id  } = this.props;
      return(
        <div className="cor-engineer-info">
          {(() => {
            if (engineers.length == 0) {
              return(
                <span className="engine-cor-detail">0 Engineers</span>
              );
            }else if (engineers.length > 1) {
              return(
                <div>
                  <p className="engine-cor-detail">{engineers.length} Engineers <span onClick={this.toggle} id={`engineer-cor_${id}`}> <InfoIcon id="1" /></span></p>

                  <Popover className="cor-popover" placement="bottom" isOpen={this.state.popoverOpen} target={`engineer-cor_${id}`} toggle={this.toggle}>
                    <PopoverBody>
                      <table>
                        <thead>
                          <tr>
                            {['Project Engineers', 'General', 'Physical Inspection', 'Maintenance', 'Technical Records', 'Operation of aircraft', 'GeoPolitical', 'Overall Rating'].map((label, index) =>
                              <th key={index}>{label}</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          { engineers.map((engineer, index) =>
                            <tr>
                              <td className="user-info">
                                <img src={engineer.user.profile_pic ? engineer.user.profile_pic: imgStoragePath+'user.png' } alt="" />
                                <div className="">
                                  <h4>{engineer.user.name}</h4>
                                  <Link style={{display: 'none'}} className="cor-detial-link" to="">COR DETAILS</Link>
                                </div>

                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[0].average_rating ? engineer.cor[0].average_rating+'/5':'-'}
                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[1].average_rating ? engineer.cor[1].average_rating+'/5':'-'}
                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[2].average_rating ? engineer.cor[2].average_rating+'/5':'-'}
                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[3].average_rating ? engineer.cor[3].average_rating+'/5':'-'}
                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[4].average_rating ? engineer.cor[4].average_rating+'/5':'-'}
                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[5].average_rating ? engineer.cor[5].average_rating+'/5':'-'}
                              </td>
                              <td align="center">
                                { engineer.cor && engineer.cor.length && engineer.cor[6].average_rating ? engineer.cor[6].average_rating+'/5':'-'}
                              </td>
                            </tr>
                          )}

                        </tbody>
                      </table>
                    </PopoverBody>
                  </Popover>
                </div>
              )
            }else {
              return(
                <div>
                  <p className="engine-cor-detail"> {engineers[0].user.name}</p>
                  <Link style={{display: 'none'}} to="" className="cor-detial-link">COR DETAILS</Link>
                </div>
              )
            }
          })()}
        </div>
      )
    }
  }
