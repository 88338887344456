import React, { Component } from 'react';
import { Row } from 'reactstrap';
import GenTabField from './GenTabField';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
const shopVisitReqList = {
  1: 'Repair',
  2: 'PRSV',
}
const shopVisitReportStatusList = {
  1: 'Received',
  2: 'Not Received'
}
const workScopeStatusList = {
 1: 'Approved' ,
 2: 'Rejected',
 3: 'Review in Progress',
 4: 'submitted for review'
}

const typeOfShopVisitList = {
  1: 'Performance restoration',
  2: 'repair'
}
export default class MjrAssyList extends Component{
  constructor(props){
    super(props)
  }
 render(){
  const { dataList } = this.props;
  let data = dataList.off_wing_detail;
  return(
    <Row  className="seperator-line">
      {
        dataList.off_wing_status && dataList.off_wing_status.label  ?
        <GenTabField grid="12" classText = "horizontal-inner-block " label="Off Wing Status"   text={dataList.off_wing_status.label}/>
        : null
      }
        <GenTabField grid="3" classText = "horizontal-inner-block " label="TSN at Removal" text={data.tsn_at_removal}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="CSN at Removal" text={data.csn_at_removal}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Date of Removal"  text={data.date_of_removal ? moment(data.date_of_removal).format(displayDateFormat):'--'} />
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Reason for Removal" text={data.reason_for_removal}/>
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Preservation Performed" text={  data.preservation_performed ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} alt="img" width="17"/> }/>
    </Row>
  )
  }
}
