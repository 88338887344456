import React, { Component } from 'react';
import FilterList from './components/FilterList';
import FilterTypes from './components/FilterTypes';
import AddFilterBtn from './components/AddFilterBtn';
import FilterTypeDropdown from './components/FilterTypeDropdown';
import FilterLabel from './components/FilterLabel';
import FilterCard from './components/FilterCard';
import AppliedFilter from './components/AppliedFilter';
import AppliedFilterLabel from './components/AppliedFilterLabel';
import FilterField from './components/FilterField';
import FilterCardTitle from './components/FilterCardTitle';
import { imgStoragePath } from '../../constants';
import { browserHistory } from 'react-router';
export default class TableFiter extends Component{
  constructor(props){
    super(props);
    this.state = {
      toggleFilter: false,
      selectedFilterTitle:'',
      appliedFilter: {}
    }
  }

  componentDidMount(){
    this.queryParamsToFilter()
  }

  queryParamsToFilter = () =>{
    let filterParams = Object.assign({}, browserHistory.getCurrentLocation().query);
    if(this.props.filter.menuList && Object.keys(this.props.filter.menuList).length && this.props.queryParamsEnabled){
      Object.values(this.props.filter.menuList).map(item =>
        {
          let labels = '';
          if(filterParams[item.keyParam]){
          this.setState(prevState =>({
            ...prevState,
            appliedFilter : {
              ...prevState.appliedFilter,
              [item.keyParam]: {...prevState.appliedFilter[item.keyParam],
                name: this.props.filter.menuList[item.label].label, value: filterParams[item.keyParam],
                filterData: this.props.filter.menuList[item.label].inputType != 'dropdown' ?
                  this.props.filter.menuList[item.label].inputType != 'date' ?
                    filterParams[item.keyParam]:
                     filterParams[item.keyParam].split(',').length == 2 ?
                     `${filterParams[item.keyParam].split(',')[0]} to ${filterParams[item.keyParam].split(',')[1]}`
                    : `${filterParams[item.keyParam].split(',')[0]}`
                  : filterParams[item.keyParam].split(',').length > 1 ?
                    filterParams[item.keyParam].split(',').map(innerItem =>{
                      return this.props.filter.menuList[item.label].options.map((option, index) => option[this.props.filter.menuList[item.label].valueKey] == innerItem ? option[this.props.filter.menuList[item.label].labelKey] + ',' : '')
                    })
                    :this.props.filter.menuList[item.label].options.map((option, index) => option[this.props.filter.menuList[item.label].valueKey] == filterParams[item.keyParam] ? option[this.props.filter.menuList[item.label].labelKey] : null)
              }
            }
          }))
        }
      }
      )
    }
    // this.props.changeFilterFn(this.state.appliedFilter)
  }

  clearFilterValue = (label) =>{
    let filterParams = Object.assign({},this.state.appliedFilter);
    let location = browserHistory.getCurrentLocation();
    Object.keys(filterParams).map((item, index) => {if(index == label){delete filterParams[item]; delete location.query[item];}});
    this.setState({
      appliedFilter: filterParams
    });
    location = {
      ...location,
      query:{
        ...location.query,
        page:1
      }
    }
    if(this.props.queryParamsEnabled == true){
      browserHistory.push(location);
      this.props.changeFilterFn(location.query);
    }else {
      let query = {}
      Object.keys(filterParams).forEach(function(key) {
          query = {...query, [key]: filterParams[key].value};
      });
      this.props.changeFilterFn(query);
    }
  }

  toggleFilterList = () => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: !prevState.toggleFilter
    }))
  }

  selectFilter = (value) => {
    this.setState(prevState => ({
      ...prevState,
      selectedFilterTitle: value,
      toggleFilter: false
    }))
  }

  closeFilter = () => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: false,
      selectedFilterTitle: ''
    }))
  }

  changeFilterFn = (...args) => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: false,
      selectedFilterTitle: ''
    }));
    let filterParams = Object.assign({}, this.state.appliedFilter);
    let location = browserHistory.getCurrentLocation();
    switch (args[0]) {
      case 'text':
        if(args[1] && args[3] != ''){
          filterParams = {
            ...filterParams,
            [args[1]]: {
              name: args[2], value: args[3],
              filterData: args[3]
            }
          }
        }
        break;
      case 'number':
        if(args[1] && args[3] != '' && args[3] != null){
          filterParams = {
            ...filterParams,
            [args[1]]: {
              name: args[2], value: args[3],
              filterData: args[3]
            }
          }
        }
        break;
      case 'date':
        if(args[1] && ((args[4] != undefined && args[4] != null) || args[3] != undefined && args[3] != null)){
          filterParams = {
            ...filterParams,
            [args[1]]: {
              name: args[2], value: args[4] != undefined && args[4] != null ? args[3] != undefined && args[3] != null ? args[3] + ',' + args[4]: args[4] : args[3],
              filterData: args[4] != undefined && args[4] != null ? args[3] != undefined && args[3] != null ? args[3] + ' to ' + args[4] : args[4] : args[3]
            }
          }
        }
        break;
      case 'dropdown':
      let values = '';
      let labels = '';
        if(args[4]){
          args[3].map(item => {
            labels = labels.length ? labels + `,${item[args[6]]}` : `${item[args[6]]}`;
            values = values.length ? values + `,${item[args[5]]}` : `${item[args[5]]}` ;
          })
        }else {
          values = args[3][args[5]];
          labels = args[3][args[6]];
        }
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[1]]: {
              name: args[2], value: values,
              filterData: labels
            }
          }
        }
        break;
      case 'dropdown_multi':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[1]]: {
              name: args[2], value: args[3].value,
              filterData: args[3].label
            }
          }
        }
        break;
    }
    location.query[args[1]] = filterParams[args[1]].value;
    location = {
      ...location,
      query:{
        ...location.query,
        page:1
      }
    }
    this.setState({
      appliedFilter: filterParams
    });
    if(this.props.queryParamsEnabled){
      browserHistory.push(location);
      this.props.changeFilterFn(location.query);
    }else {
      let query = {}
      Object.keys(filterParams).forEach(function(key) {
          query = {...query, [key]: filterParams[key].value};
      });
      this.props.changeFilterFn(query);
    }
  }

  render(){
    const { filter, clearFilterValue } = this.props;
    return(
      <FilterList className="add-filter-type">
        <FilterTypes className={this.props.tableSpecialOp ? "add-filter col-md-10" : "add-filter col-md-12"}>
          <AddFilterBtn toggleFilterList={this.toggleFilterList} appliedFilter={filter.appliedFilter} isFilter={this.state.toggleFilter} />
          <FilterTypeDropdown className="list-unstyled filter-list" isFilter={this.state.toggleFilter} filterMenu={filter.menuList}>
            { Object.keys(filter.menuList).map((label, index) =>
              <FilterLabel key={index} selectFilter={() => this.selectFilter(label)} key={index} className="para-ui th-highlight">{label}</FilterLabel>
            )}
          </FilterTypeDropdown>
          { this.state.selectedFilterTitle ?
            <FilterCard className="match-block">
              <FilterCardTitle>
                <h4>
                  {this.state.selectedFilterTitle}
                  <span onClick={() => this.closeFilter()}><img width="13" src={imgStoragePath+"white_close.png"}  alt="img"/></span>
                </h4>
              </FilterCardTitle>
              <FilterField label={filter.menuList[this.state.selectedFilterTitle]['label']} changeFilterFn={this.changeFilterFn} applyFilterType={filter.menuList[this.state.selectedFilterTitle]} />
            </FilterCard>:null
          }
          <AppliedFilter className="list-inline applied-filter">
            {/* { Object.keys(appliedFilter).map((label, index) =>
              <AppliedFilterLabel key={index} clearFilterValue={() => this.clearFilterValue(label)}>
                {appliedFilter[label].name} : {appliedFilter[label].value}
              </AppliedFilterLabel>
            )} */}
            {
              Object.keys(this.state.appliedFilter).length ? Object.values(this.state.appliedFilter).map((item, index) =>
              <AppliedFilterLabel key={index} clearFilterValue={() => this.clearFilterValue(index)}>
                {item.name} : {item.filterData}
              </AppliedFilterLabel>
              )
              :null
            }
          </AppliedFilter>
        </FilterTypes>
        {
          this.props.tableSpecialOp ?
            <div className="col-md-2">
              {
                this.props.tableSpecialOp
              }
            </div>
          :null
        }
      </FilterList>
    )
  }
}
