import * as actions from '../actions';
import { globalGetService, globalPostService, globalDeleteService, globalSignUpPostService, globalPutService } from '../../../globalServices';
import { toastFlashMessage } from '../../../utils'
import { browserHistory } from 'react-router'
import { PAYMENT_FAILED } from '../../../shared/actions'
import { getNotificationBarDataAc } from '../../../shared/actionCreators'
export const licenseListAc = () => {
  return(dispatch) => {
    globalGetService('/console/license/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LICENSE_LIST,
          payload: response.data.data
        });
      }
    })
  }
}

export const plansListAc = (licenseId, filters) => {
  return(dispatch) => {
    dispatch({
      type: actions.PLANS_LIST,
      payload: []
    })
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalGetService('/console/license/'+licenseId+'/plans/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PLANS_LIST,
          payload: response.data.data
        });
        dispatch(selectedPlansListAc(licenseId, filters))
      }
    })
  }
}

export const selectedPlansListAc = (licenseId) => {
  return(dispatch) => {
    dispatch({
      type: actions.SELECTED_PLANS_LIST,
      payload: []
    })
    globalGetService('/console/license/'+licenseId+'/selected-plans/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.SELECTED_PLANS_LIST,
          payload: response.data.data
        });
        var location = browserHistory.getCurrentLocation()
        if((location.pathname.includes('user') || location.pathname.includes('storage')) && response.data.data.license.selected_plans.length && response.data.data.license.selected_plans[0].plan.details && response.data.data.license.selected_plans[0].plan.details.interval_type && response.data.data.license.selected_plans[0].plan.details.interval_type.label.toLowerCase() == 'yearly'){
          dispatch({
            type: actions.INTERVAL_TOGGLE,
            payload: {key: 0}
          })
        }
      }
      setTimeout(() =>dispatch({
        type: actions.LICENSE_LOADER,
        payload: false
      }), 300)
    })
  }
}

export const cartListAc = () => {
  return(dispatch) => {
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalGetService('/console/cart/')
    .then(response => {

      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CART_LIST,
          payload: response.data.data
        });
        dispatch(getSubscriptionDifferenceAc())
      }else{
        dispatch({
          type: actions.LICENSE_LOADER,
          payload: false
        })
      }
    })
  }
}

export const addLicenceToCartAc = (licenceId, quantity, previous_quantity) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService(`/console/cart/${licenceId}/`, {quantity: quantity, previous_quantity: previous_quantity})
    .then(response =>{
      dispatch(getSubscriptionDifferenceAc())
      if(response.data.statusCode == 200){
        browserHistory.push('/license/cart')
      }
    })
  }
}

export const removeItemAc = (licenseId) => {
  return(dispatch) => {
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalDeleteService('/console/cart/'+licenseId + '/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(getNotificationBarDataAc())
        dispatch(cartListAc())
        dispatch(getSubscriptionDifferenceAc())
      }
    })
  }
}

export const getSubscriptionDifferenceAc = () =>{
  return(dispatch) =>{
    dispatch({
      type: actions.INOVICE_PREVIEW,
      payload: []
    })
    globalSignUpPostService('/console/order/preview/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INOVICE_PREVIEW,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.LICENSE_LOADER,
        payload: false
      })
    })
  }
}

export const makePaymentAc = (paymentObj, cardToken) =>{
  return(dispatch) => {
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService('/console/billing-address/', cardToken)
    .then(response =>{
      if(response.data.statusCode == 200){
        globalPostService('/console/order/create/', {...paymentObj, address_id: response.data.data.id})
        .then(innerResponse => {
          if(innerResponse.data.statusCode == 200){
            toastFlashMessage(innerResponse.data.message, 'success')
            let userDetail = JSON.parse(localStorage.getItem('userInfo'));
            userDetail = { ...userDetail, defaultLessor: { ...userDetail.defaultLessor, on_trail:false, trail_expired: false } };
            localStorage.setItem('userInfo', JSON.stringify(userDetail));
            browserHistory.push('/billing/invoice/detail/' + innerResponse.data.data.order_id)
            dispatch(cartListAc())
          }else{
            dispatch({
              type: PAYMENT_FAILED,
              payload: true
            })
            dispatch({
              type: actions.LICENSE_LOADER,
              payload: false
            })
          }
        })
      }else{
        dispatch({
          type: actions.LICENSE_LOADER,
          payload: false
        })
      }
    })
  }
}

export const saveCardAc = (cardToken, invoice, address, stripePreview,flag=true) =>{
  return(dispatch) =>{
    globalPostService('/console/card/add/',{card_token: cardToken,default_source:flag})
    .then(response =>{
      if(invoice && Object.keys(invoice).length){
        dispatch(makePaymentAc({card_id: response.data.data.id, customer: response.data.data.customer, invoice_items: stripePreview}, address))
      }
    })
  }
}


export const getSavedCardsAc = () =>{
  return(dispatch) =>{
    dispatch({
      type: actions.CARDS_LOADER,
      payload: true
    })
    globalGetService('/console/cards/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CARDS_LIST,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.CARDS_LOADER,
        payload: false
      })
    })
  }
}

export const updateCartAc = (planId, quantity) => {
  return(dispatch) =>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService('/console/cart/'+planId+'/', { quantity : quantity })
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(cartListAc())
      }
    })
  }
}

export const defaultCardAc = (card) =>{
  return(dispatch =>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService(`/console/card/edit/`, card)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(getSavedCardsAc())
      }
    })
  })
}

export const getReferralHistoryAc = () =>{
  return(dispatch) =>{
    globalGetService('/console/referral-history/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.REFERRAL_HISTORY,
          payload: response.data.data
        })
      }
    })
  }
}

export const getCardDetailAc = (card) =>{
  return(dispatch) => {
    dispatch({
      type: actions.CARDS_LOADER,
      payload: true
    })
    globalPostService(`/console/get-billing-address/`, {card_id: card.id})
    .then(response => {
      if(response.data.statusCode == 200){
        card = {...card, card_id: card.id}
        delete card.id
        dispatch({
          type: actions.CARD_DETAIL,
          payload: {
            flag: true, data: {...response.data.data, last4: card.last4, brand: card.brand, exp_month: card.exp_month, exp_year: card.exp_year}
          }
        })
      }
      dispatch({
        type: actions.CARDS_LOADER,
        payload: false
      })
    })
  }
}

export const deactivatePlanAc = (plan, planId) =>{
  return(dispatch)=>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService(`/console/deactivate-plan/`, plan)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(plansListAc(planId))
      }
    })
  }
}

export const updateAddressAc = (address) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPutService(`/console/billing-address/${address.id}/ `, {data: address})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CARD_DETAIL,
          payload: {
            flag: false, data: {brand: 'visa'}
          }
        })
        toastFlashMessage(response.data.message, 'success')
        dispatch(getSavedCardsAc())
      }
    })
  }
}

export const deleteCardAc = (card) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService(`/console/card/delete/`, card)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success')
      }
      dispatch(getSavedCardsAc())
    })
  }
}

export const editSelectedPlanAc = (selectedPlan, planId) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.LICENSE_LOADER,
      payload: true
    })
    globalPostService('/console/selected-plan-edit/', selectedPlan)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success')
      }
      dispatch(plansListAc(planId))
    })
  }
}

export const toggleAutoDebitAc = (autoPay) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.CARDS_LOADER,
      payload: true
    })
    globalPostService(`/console/auto-pay/edit/`, autoPay)
    .then(response => {
      dispatch(getSavedCardsAc())
    })
  }
}
